import _, { isFunction } from 'lodash';
import React, {
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, useTheme, Chip } from '@material-ui/core';
import {
  Receipt as ReceiptIcon,
  MoreHoriz as MoreHorizIcon,
  CheckCircle as CheckCircleIcon,
  CancelOutlined as CancelOutlinedIcon,
  Refresh as RefreshIcon,
  RotateLeft as RotateLeftIcon,
} from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { bindTrigger, bindMenu } from 'material-ui-popup-state';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';

import {
  DONE_STATUS,
} from '../../../../utils/hooks/form/dialog/formDialogUtils';

import QCXSimpleConsultPageTemplate from '../../../../templates/simple-consult-page/QCXSimpleConsultPageTemplate';
import QCXListItemIcon from '../../../../shared-components/list-item-icon/QCXListItemIcon';
import QCXPopupState from '../../../../components/popup-state/QCXPopupState';
import QCXRelatorioConciliacaoBancariaDialogForm from '../../../../components/conciliacao-bancaria/QCXRelatorioConciliacaoBancariaDialogForm';
import {
  refresh as refreshAction,
  resetRefresh,
  changeToBatchApprovalMode,
  failure,
  loading,
  resetBackgroundMode,
  resetMode,
  resetModel,
  setRelatedReportModel,
  resetRelatedSelectionConciliacaoBancariaList,
  setRelatedSelectionConciliacaoBancariaList,
  success,
  conciliacaoBancariaActions,
} from '../../../../features/conciliacao-bancaria/conciliacaoBancariaSlice';
import {
  fetchAllAsync,
  generateReportByDateAsync,
} from '../../../../features/conciliacao-bancaria/conciliacaoBancariaThunks';
import {
  selectStatus,
  selectMode,
  selectRefresh,
  selectRelatedReportModel,
  selectConciliacoesBancarias,
  selectRelatedSelectionConciliacaoBancariaList,
} from '../../../../features/conciliacao-bancaria/conciliacaoBancariaSelectors';
import {
  isAlternativeLoadingStatus,
  isApprovalBatchMode,
  isNoneMode,
  isRefresh,
} from '../../../../utils/store/store-utils';
import { conciliacaoBancariaAPI } from '../../../../features/conciliacao-bancaria/conciliacaoBancariaAPI';
import { setErrorFeedback, setSuccessFeedback, setWarningFeedback } from '../../../../features/feedback/feedbackSlice';
import QCXConfirmDialog from '../../../../shared-components/dialog/QCXConfirmDialog';
import useFormDialogSync from '../../../../utils/hooks/form/dialog/useFormDialogSync';
import useOperationMiddleware from '../../../../utils/hooks/operation/middleware/useOperationMiddleware';
import { OperacaoMovimentacaoUtils, OPERACAO_ESTORNO } from '../../../../utils/general/movimentacao/movimentacaoUtils';
import { numerarioImportacaoAPI } from '../../../../features/numerario-importacao/numerarioImportacaoAPI';
import { solicitacaoPagamentoAPI } from '../../../../features/solicitacao-pagamento/solicitacaoPagamentoAPI';
import {
  formatDate,
  normalizeData,
  unnormalizeNumeral,
} from '../../../../utils/general/general-utils';
import { formatBrazilianNumericDecimal } from '../../../../utils/hooks/form/field/formatters';
import ConciliacaoBancariaUtils from '../../../../utils/general/conciliacao-bancaria/ConciliacaoBancariaUtils';
import TituloUtils from '../../../../utils/general/titulo/TituloUtils';

export default function ConciliacaoBancariaConsultPage({ authInfo = {} }) {
  const { t } = useTranslation();

  const theme = useTheme();

  const dispatch = useDispatch();

  const status = useSelector(selectStatus);
  const mode = useSelector(selectMode);
  const refresh = useSelector(selectRefresh);
  const conciliacoes = useSelector(selectConciliacoesBancarias);
  const relatedSelectionConciliacaoBancariaList = useSelector(
    selectRelatedSelectionConciliacaoBancariaList
  );
  const reportRelatedModel = useSelector(selectRelatedReportModel);

  const isAlternativeLoading = useMemo(() => (
    isAlternativeLoadingStatus(status)
  ), [status]);

  const isNone = useMemo(() => (
    isNoneMode(mode)
  ), [mode]);

  const isApprovalBatch = useMemo(() => (
    isApprovalBatchMode(mode)
  ), [mode]);

  const fetchAll = useCallback(() => {
    dispatch(fetchAllAsync());
  }, []);

  const resetModes = useCallback(() => {
    dispatch(resetMode());
    dispatch(resetBackgroundMode());
  }, []);

  const resetModels = useCallback(() => {
    dispatch(resetModel());
  }, []);

  const handleReverseErrorResponse = useCallback((reason) => {
    const responseMessage = reason?.response?.data?.message;

    const mensagem = (
      responseMessage
        ? `: ${responseMessage}.`
        : '.'
    );

    const errorMessage = t(
      'com.muralis.qcx.erro.erroEstornoConciliacaoBancaria',
      { mensagem }
    );

    dispatch(failure());
    dispatch(
      setErrorFeedback({
        message: errorMessage,
      })
    );
  }, []);

  useEffect(() => {
    fetchAll();
    resetModes();
    resetModels();
  }, []);

  useEffect(() => {
    const checkIfIsRefresh = () => {
      if (isRefresh(refresh)) {
        fetchAll();
        dispatch(resetRefresh());
      }
    };

    checkIfIsRefresh();
  }, [refresh]);

  const isEmptySelectionList = useMemo(() => (
    !relatedSelectionConciliacaoBancariaList || (
      _.isArrayLikeObject(relatedSelectionConciliacaoBancariaList)
      && _.isEmpty(relatedSelectionConciliacaoBancariaList)
    )
  ), [relatedSelectionConciliacaoBancariaList]);

  const handleEnableBatchApprovalMode = useCallback((event) => {
    event.stopPropagation();
    dispatch(changeToBatchApprovalMode());
  }, []);

  const handleSelectionModelChange = useCallback(({ selectionModel }) => {
    if (isApprovalBatch) {
      dispatch(
        setRelatedSelectionConciliacaoBancariaList(
          selectionModel
        )
      );
    }
  }, [
    isApprovalBatch,
  ]);

  const numeroProcessoByTitulos = useCallback((titulos) => {
    const processos = (titulos || []).map((titulo) => titulo?.followUp?.numero);
    const distinctProcessos = [...new Set(processos)];

    return distinctProcessos.reduce(
      (previous, current, index, array) => {
        if (index === 0) {
          return current;
        }
        if (array?.length > 1 && index === array?.length - 1) {
          return `${previous} e ${current}`;
        }

        return `${previous}, ${current}`;
      },
      []
    );
  }, []);

  const favorecidoByTitulos = useCallback((titulos) => {
    const favorecidos = (titulos || []).map((titulo) => titulo?.favorecido?.pessoa?.nome);
    const distinctFavorecidos = [...new Set(favorecidos)];

    return distinctFavorecidos.reduce(
      (previous, current, index, array) => {
        if (index === 0) {
          return current;
        }
        if (array?.length > 1 && index === array?.length - 1) {
          return `${previous} e ${current}`;
        }

        return `${previous}, ${current}`;
      },
      []
    );
  }, []);

  const [
    handleEstornarClickMiddleware,
    operationOfEstornarById,
  ] = useOperationMiddleware(
    async (data) => {
      dispatch(loading());

      const payload = [
        {
          operacao: {
            description: OPERACAO_ESTORNO,
          },
          movimentacao: {
            id: data?.id,
          },
        },
      ];

      let response;

      if (data?.tipo === OperacaoMovimentacaoUtils.ENTRADA) {
        response = await numerarioImportacaoAPI.reverseMovimentacao(
          payload
        ).catch(handleReverseErrorResponse);
      } else {
        response = await solicitacaoPagamentoAPI.reverseMovimentacao(
          payload
        ).catch(handleReverseErrorResponse);
      }

      if (response?.status === 201) {
        dispatch(success());
        dispatch(
          setSuccessFeedback({
            message: t('com.muralis.qcx.mensagem.conciliacaoBancariaEstornada'),
          })
        );

        dispatch(conciliacaoBancariaActions.refresh());
      }
    },
    []
  );

  const handleEstornarClick = useCallback(
    (row, popupState) => {
      const {
        id,
        tipo,
        titulos,
      } = row;

      const processo = numeroProcessoByTitulos(titulos);

      const handleEstornarByIdClick = (event) => {
        if (isFunction(event?.stopPropagation)) {
          popupState.close(event);

          event.stopPropagation();

          const configureOperation = () => ({
            options: {
              title: t('com.muralis.qcx.acoes.confirmarOperacaoEstorno').toUpperCase(),
              message: t(
                'com.muralis.qcx.mensagem.desejaEstornarConciliacaoBancaria',
                {
                  processo,
                }
              ),
            },
          });

          const dataOfOperationEstornar = {
            id,
            tipo,
          };

          handleEstornarClickMiddleware(
            configureOperation,
            dataOfOperationEstornar
          );
        }
      };

      return handleEstornarByIdClick;
    },
    [handleEstornarClickMiddleware]
  );

  const [
    handleConciliarClickMiddleware,
    operationOfConciliarById,
  ] = useOperationMiddleware(
    async (data) => {
      dispatch(loading());

      const response = await conciliacaoBancariaAPI.conciliar(
        data?.id
      ).catch((reason) => {
        const responseMessage = reason?.response?.data?.message;

        const mensagem = (
          responseMessage
            ? `: ${responseMessage}.`
            : '.'
        );

        const errorMessage = t(
          'com.muralis.qcx.erro.erroConciliarConciliacaoBancaria',
          {
            mensagem,
            processo: data?.processo,
          }
        );

        dispatch(failure());
        dispatch(
          setErrorFeedback({
            message: errorMessage,
          })
        );
      });

      if (response?.status === 200) {
        dispatch(success());
        dispatch(
          setSuccessFeedback({
            message: t('com.muralis.qcx.mensagem.conciliacaoBancariaConciliada'),
          })
        );

        dispatch(conciliacaoBancariaActions.refresh());
      }
    },
    []
  );

  const handleConciliarClick = useCallback(
    (row, popupState) => {
      const {
        id,
        titulos,
      } = row;

      const processo = numeroProcessoByTitulos(titulos);

      const handleConciliarByIdClick = (event) => {
        if (isFunction(event?.stopPropagation)) {
          popupState.close(event);

          event.stopPropagation();

          const configureOperation = () => ({
            options: {
              title: t('com.muralis.qcx.acoes.confirmarOperacaoConciliacao').toUpperCase(),
              message: t(
                'com.muralis.qcx.mensagem.confirmarOperacaoConcilarConciliacaoBancaria',
                {
                  processo,
                }
              ),
            },
          });

          const dataOfOperationConciliar = {
            id,
            processo,
          };

          handleConciliarClickMiddleware(
            configureOperation,
            dataOfOperationConciliar
          );
        }
      };

      return handleConciliarByIdClick;
    },
    [handleConciliarClickMiddleware]
  );

  const [
    handleDesconciliarClickMiddleware,
    operationOfDesconciliarById,
  ] = useOperationMiddleware(
    async (data) => {
      dispatch(loading());

      const response = await conciliacaoBancariaAPI.desconciliar(
        data?.id
      ).catch((reason) => {
        const responseMessage = reason?.response?.data?.message;

        const mensagem = (
          responseMessage
            ? `: ${responseMessage}.`
            : '.'
        );

        const errorMessage = t(
          'com.muralis.qcx.erro.erroDesconciliarConciliacaoBancaria',
          {
            mensagem,
            processo: data?.processo,
          }
        );

        dispatch(failure());
        dispatch(
          setErrorFeedback({
            message: errorMessage,
          })
        );
      });

      if (response?.status === 200) {
        dispatch(success());
        dispatch(
          setSuccessFeedback({
            message: t('com.muralis.qcx.mensagem.conciliacaoBancariaDesconciliada'),
          })
        );

        dispatch(conciliacaoBancariaActions.refresh());
      }
    },
    []
  );

  const handleDesconciliarClick = useCallback(
    (row, popupState) => {
      const {
        id,
        titulos,
      } = row;

      const processo = numeroProcessoByTitulos(titulos);

      const handleDesconciliarByIdClick = (event) => {
        if (isFunction(event?.stopPropagation)) {
          popupState.close(event);

          event.stopPropagation();

          const configureOperation = () => ({
            options: {
              title: t('com.muralis.qcx.acoes.confirmarOperacaoDesconciliacao').toUpperCase(),
              message: t(
                'com.muralis.qcx.mensagem.confirmarOperacaoDesconcilarConciliacaoBancaria',
                {
                  processo,
                }
              ),
            },
          });

          const dataOfOperationDesconciliar = {
            id,
            processo,
          };

          handleDesconciliarClickMiddleware(
            configureOperation,
            dataOfOperationDesconciliar
          );
        }
      };

      return handleDesconciliarByIdClick;
    },
    [handleDesconciliarClickMiddleware]
  );

  const handleConfirmConciliarEmLote = useCallback(async () => {
    try {
      dispatch(loading());

      const selectedConciliacoesBancarias = relatedSelectionConciliacaoBancariaList
        .map((selectedConciliacaoId) => ({
          id: selectedConciliacaoId,
        }));

      const response = await conciliacaoBancariaAPI.conciliarEmLote(
        selectedConciliacoesBancarias
      );

      if (response?.status === 201) {
        const feedbackMessage = t('com.muralis.qcx.mensagem.loteConciliacoesBancariasConciliado');

        dispatch(success());
        dispatch(setSuccessFeedback({
          message: feedbackMessage,
        }));
        dispatch(refreshAction());
        dispatch(resetMode());
        dispatch(resetRelatedSelectionConciliacaoBancariaList());
      }
    } catch (error) {
      const errorMessage = t('com.muralis.qcx.erro.conciliarConciliacaoBancariaLote');

      dispatch(failure());
      dispatch(
        setErrorFeedback({
          message: errorMessage,
        })
      );
    }
  }, [
    relatedSelectionConciliacaoBancariaList
  ]);

  const handleCancelConciliarEmLote = useCallback((event) => {
    event.stopPropagation();
    dispatch(resetMode());
    dispatch(resetRelatedSelectionConciliacaoBancariaList());
  }, []);

  const [
    handleImprimirClick,
    formDialogImpressaoStatus,
    handleFormDialogImpressaoStatus,
  ] = useFormDialogSync(
    async (event, data) => {
      if (isFunction(event?.stopPropagation)) {
        event.stopPropagation();

        dispatch(setRelatedReportModel({
          ...data,
          tipo: ConciliacaoBancariaUtils.CONCILIACAO_BANCARIA,
        }));
      }
    },
    []
  );
  const handleImprimirByDateSubmit = async (event) => {
    event?.preventDefault?.();
    event?.stopPropagation?.();

    const dataInicial = normalizeData(event?.data?.dataInicial);
    const dataFinal = normalizeData(event?.data?.dataFinal);

    const handlers = {
      onNoContent: () => {
        dispatch(failure());

        const infoMessage = t('com.muralis.qcx.mensagem.nenhumRelatorioGerar');

        dispatch(
          setWarningFeedback({
            message: infoMessage,
          })
        );
      },
      onError: () => {
        dispatch(failure());

        const errorMessage = t('com.muralis.qcx.mensagem.nenhumaConciliacaoBancariaEncontrada', {
          dataInicial,
          dataFinal,
        });

        dispatch(
          setErrorFeedback({
            message: errorMessage,
          })
        );
      },
    };

    dispatch(generateReportByDateAsync({
      dataInicial,
      dataFinal,
      handlers,
    }));

    handleFormDialogImpressaoStatus(DONE_STATUS);
  };

  const columns = useMemo(() => ([
    {
      field: 'processo',
      headerName: t('com.muralis.qcx.NumeroProcesso'),
      headerAlign: 'center',
      align: 'center',
      type: 'string',
      flex: 150,
      valueGetter: ({ row }) => (
        numeroProcessoByTitulos(row?.titulos) || '-'
      ),
    },
    {
      field: 'origem',
      headerName: t('com.muralis.qcx.origem'),
      headerAlign: 'center',
      align: 'center',
      type: 'string',
      flex: 160,
      valueGetter: ({ row }) => (
        row?.titulos[0]?.origem || '-'
      ),
    },
    {
      field: 'favorecidoCliente',
      headerName: t('com.muralis.qcx.favorecidoCliente'),
      headerAlign: 'center',
      align: 'center',
      type: 'string',
      flex: 120,
      valueGetter: ({ row }) => (
        favorecidoByTitulos(row?.titulos) || '-'
      ),
    },
    {
      field: 'solicitante',
      headerName: t('com.muralis.qcx.solicitante'),
      headerAlign: 'center',
      align: 'center',
      type: 'string',
      flex: 140,
      valueGetter: ({ row }) => (
        row?.titulos[0]?.solicitante?.nomeSolicitante || '-'
      ),
    },
    {
      field: 'extrato',
      headerName: t('com.muralis.qcx.extrato'),
      headerAlign: 'center',
      align: 'center',
      type: 'string',
      flex: 140,
      renderCell: ({ row }) => {
        const isEntrada = row?.tipo === OperacaoMovimentacaoUtils.ENTRADA;
        return (
          <Chip
            size="small"
            label={
              isEntrada
                ? t('com.muralis.qcx.modoPlano.credito')
                : t('com.muralis.qcx.modoPlano.debito')
            }
            style={{
              backgroundColor: isEntrada
                ? theme.palette.success.main
                : theme.palette.error.dark,
              color: isEntrada
                ? theme.palette.success.contrastText
                : theme.palette.error.contrastText,
            }}
          />
        );
      },
    },
    {
      field: 'valor',
      headerName: t('com.muralis.qcx.valor'),
      headerAlign: 'center',
      align: 'center',
      type: 'number',
      flex: 150,
      valueGetter: ({ row }) => (
        unnormalizeNumeral(
          row?.valor,
          formatBrazilianNumericDecimal(2)
        )
      ),
    },
    {
      field: 'status',
      headerName: t('com.muralis.qcx.status'),
      headerAlign: 'center',
      align: 'center',
      type: 'string',
      flex: 150,
      valueGetter: ({ row }) => (
        (
          ConciliacaoBancariaUtils.getLabelByValue(row?.conciliada)
          || ConciliacaoBancariaUtils.getLabelByValue(ConciliacaoBancariaUtils.NAO_CONCILIADA)
        ).toUpperCase()
      ),
    },
    {
      field: 'conciliacao',
      headerName: t('com.muralis.qcx.conciliacao'),
      headerAlign: 'center',
      align: 'center',
      type: 'date',
      flex: 150,
      valueGetter: ({ row }) => (
        formatDate(row?.dataConciliacao) || '-'
      ),
    },
    {
      field: 'actions',
      headerName: t('com.muralis.qcx.acoes.label'),
      headerAlign: 'center',
      align: 'center',
      flex: 150,
      renderCell: ({
        row: {
          id,
          tipo,
          conciliada,
          titulos,
        },
      }) => (
        <>
          <QCXPopupState popupId="popup-conciliacao-bancaria">
            {(popupState) => (
              <>
                <IconButton
                  key={`btn-more-options-${id}`}
                  name={`btn-more-options-${id}`}
                  disabled={isApprovalBatch}
                  {...bindTrigger(popupState)}
                >
                  <MoreHorizIcon
                    color={isApprovalBatch ? 'disabled' : 'secondary'}
                    size={20}
                  />
                </IconButton>
                <Menu {...bindMenu(popupState)}>
                  <MenuItem
                    onClick={(handleConciliarClick(
                      {
                        id,
                        titulos,
                      },
                      popupState
                    ))}
                    disabled={
                      ConciliacaoBancariaUtils.isConciliada(conciliada)
                      || TituloUtils.isOrigemTransferencia(titulos[0]?.origem)
                    }
                  >
                    <QCXListItemIcon>
                      <CheckCircleIcon
                        style={{
                          color: 'green',
                        }}
                        fontSize="small"
                      />
                    </QCXListItemIcon>
                    <Typography
                      variant="inherit"
                      style={{
                        fontSize: 12,
                      }}
                    >
                      {t('com.muralis.qcx.acoes.conciliar').toUpperCase()}
                    </Typography>
                  </MenuItem>
                  <MenuItem
                    onClick={(handleDesconciliarClick( // TODO: MÉTODO DESCONCILIAR
                      {
                        id,
                        titulos,
                      },
                      popupState
                    ))}
                    disabled={
                      !ConciliacaoBancariaUtils.isConciliada(conciliada)
                      || TituloUtils.isOrigemTransferencia(titulos[0]?.origem)
                    }
                  >
                    <QCXListItemIcon>
                      <RefreshIcon
                        color="primary"
                        fontSize="small"
                      />
                    </QCXListItemIcon>
                    <Typography
                      variant="inherit"
                      style={{
                        fontSize: 12,
                      }}
                    >
                      {t('com.muralis.qcx.acoes.desconciliar').toUpperCase()}
                    </Typography>
                  </MenuItem>
                  <MenuItem
                    onClick={(handleEstornarClick(
                      {
                        id,
                        tipo,
                        titulos,
                      },
                      popupState
                    ))}
                    disabled={
                      TituloUtils.isOrigemTransferencia(titulos[0]?.origem)
                    }
                  >
                    <QCXListItemIcon>
                      <RotateLeftIcon fontSize="small" color="error" />
                    </QCXListItemIcon>
                    <Typography
                      variant="inherit"
                      style={{
                        fontSize: 12,
                      }}
                    >
                      {t('com.muralis.qcx.acoes.estornar').toUpperCase()}
                    </Typography>
                  </MenuItem>
                </Menu>
              </>
            )}
          </QCXPopupState>
        </>
      ),
    }
  ]), [isApprovalBatch, numeroProcessoByTitulos]);

  const breadcrumbs = useMemo(() => ([
    {
      link: {
        to: '/',
        name: t('com.muralis.qcx.inicio'),
      },
    },
    {
      link: {
        to: t('com.muralis.qcx.url.moduloFinanceiro'),
        name: t('com.muralis.qcx.financeiro.label'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.financeiro.conciliacaoBancaria'),
      },
      default: true,
    },
  ]), []);

  const templateProps = useMemo(() => ({
    page: {
      title: t('com.muralis.qcx.financeiro.conciliacaoBancaria'),
      icon: <ReceiptIcon />,
      breadcrumbs,
    },
    control: {
      others: [
        ...(isNone ? [
          {
            description: 'Relatório',
            color: 'primary',
            onClick: handleImprimirClick,
          },
          {
            description: <CheckCircleIcon size={20} color="white" />,
            ...(_.isEmpty(conciliacoes || [])
              ? {
                disabled: true,
                color: 'disabled',
              } : {
                disabled: false,
                style: {
                  backgroundColor: 'green',
                },
              }),
            onClick: handleEnableBatchApprovalMode,
          },
        ] : []),
        ...(isApprovalBatch ? [
          {
            description: t('com.muralis.qcx.acoes.confirmarSelecao'),
            startIcon: <CheckCircleIcon />,
            color: 'secondary',
            tooltip: {
              description: t('com.muralis.qcx.acoes.confirmarSelecao'),
            },
            onClick: handleConfirmConciliarEmLote,
            disabled: isEmptySelectionList,
          },
          {
            description: <CancelOutlinedIcon />,
            variant: 'outlined',
            color: 'default',
            tooltip: {
              description: t('com.muralis.qcx.acoes.cancelar'),
            },
            onClick: handleCancelConciliarEmLote,
          }
        ] : []),
      ],
    },
    table: {
      columns,
      checkboxSelection: isApprovalBatch,
      selectionModel: relatedSelectionConciliacaoBancariaList,
      onSelectionModelChange: handleSelectionModelChange,
      isRowSelectable: (data) => (
        !ConciliacaoBancariaUtils.isConciliada(data?.row?.conciliada)
        && !TituloUtils.isOrigemTransferencia(data?.row?.titulos[0]?.origem)
      ),
    },
  }), [
    isNone,
    columns,
    breadcrumbs,
    conciliacoes,
    isApprovalBatch,
    isEmptySelectionList,
    handleSelectionModelChange,
    handleCancelConciliarEmLote,
    handleConfirmConciliarEmLote,
    handleEnableBatchApprovalMode,
  ]);

  return (
    <QCXSimpleConsultPageTemplate
      pageProps={templateProps.page}
      controlProps={templateProps.control}
      tableProps={templateProps.table}
      tableData={conciliacoes}
      isLoading={isAlternativeLoading}
      buttonNewText={t('com.muralis.qcx.acoes.nova').toUpperCase()}
      authInfo={authInfo}
      requiredRoles={['conciliacao-bancaria']}
    >
      <QCXConfirmDialog
        key="form-dialog-estorno-conciliacao-bancaria"
        id="form-dialog-estorno-conciliacao-bancaria"
        open={operationOfEstornarById?.active}
        title={operationOfEstornarById?.title}
        content={operationOfEstornarById?.message}
        endContent={operationOfEstornarById?.endMessage}
        onConfirm={operationOfEstornarById.confirm}
        onClose={operationOfEstornarById?.reset}
      />
      <QCXConfirmDialog
        key="form-dialog-conciliar-conciliacao-bancaria"
        id="form-dialog-conciliar-conciliacao-bancaria"
        open={operationOfConciliarById?.active}
        title={operationOfConciliarById?.title}
        content={operationOfConciliarById?.message}
        endContent={operationOfConciliarById?.endMessage}
        onConfirm={operationOfConciliarById.confirm}
        onClose={operationOfConciliarById?.reset}
      />
      <QCXConfirmDialog
        key="form-dialog-desconciliar-conciliacao-bancaria"
        id="form-dialog-desconciliar-conciliacao-bancaria"
        open={operationOfDesconciliarById?.active}
        title={operationOfDesconciliarById?.title}
        content={operationOfDesconciliarById?.message}
        endContent={operationOfDesconciliarById?.endMessage}
        onConfirm={operationOfDesconciliarById.confirm}
        onClose={operationOfDesconciliarById?.reset}
      />
      <QCXRelatorioConciliacaoBancariaDialogForm
        handleFormStatus={handleFormDialogImpressaoStatus}
        handleOnSubmit={handleImprimirByDateSubmit}
        formDialogStatus={formDialogImpressaoStatus}
        initialValues={reportRelatedModel}
      />
    </QCXSimpleConsultPageTemplate>
  );
}
