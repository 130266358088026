import { DrawerRoute } from '../routes/leftDrawerRoutes';

/**
 * It takes a new path and a history object, and if the new path is defined, it pushes the new path to
 * the history object
 * @param {string | undefined} newPath - The new path to navigate to.
 * @param {any} history - This is the history object that is passed to the component by the
 * react-router-dom library.
 */
const handleNavigation = (newPath: string | undefined, history: any) => {
  if (newPath) {
    history.push(newPath);
  }
};

/**
 * If the first part of the path of the route matches the first part of the current path, then the
 * route is active
 * @param {string | undefined} path - The current path of the page.
 * @param {DrawerRoute} route - DrawerRoute - this is the route object that we're passing to the
 * drawer.
 * @returns A boolean value.
 */
const isItemActive = (path: string | undefined, route: DrawerRoute) => {
  const firstPartOfPathRegex = /^\/[^/]*/;
  const routeFirstPath = route.path?.match(firstPartOfPathRegex);
  const currentFirstPath = path?.match(firstPartOfPathRegex);
  if (routeFirstPath && currentFirstPath) {
    return routeFirstPath[0] === currentFirstPath[0];
  } else {
    return false;
  }
};

export { handleNavigation, isItemActive };
