import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@material-ui/core';
import {
  Search as PageviewIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import useOperationController from '../../../../../utils/hooks/store/operation/useOperationController';
import QCXSimpleConsultPageTemplate from '../../../../../templates/simple-consult-page/QCXSimpleConsultPageTemplate';
import ModeloFaturamentoContext from '../../../../../contexts/registrations/modelo-faturamento/ModeloFaturamentoContext';
import {
  fetchAllAsync,
  activateByIdAsync,
  inactivateByIdAsync,
  changeToConsultMode,
  selectModelosFaturamento,
  resetModel,
  changeToCreateMode,
  resetRelatedModeloLancamentoModel,
  resetMode,
  resetBackgroundMode,
  resetSubMode,
} from '../../../../../features/modelo-faturamento/modeloFaturamentoSlice';
import { isAlternativeLoadingStatus } from '../../../../../utils/store/store-utils';
import { userHasDeleteRole } from '../../../../../components/perfil/perfil-utils';

export default function ModeloFaturamentoConsultPage({ authInfo = {} }) {
  const { t } = useTranslation();

  const history = useHistory();

  const {
    status,
  } = useContext(ModeloFaturamentoContext);

  const dispatch = useDispatch();

  const modelosFaturamento = useSelector(selectModelosFaturamento);

  const isAlternativeLoading = useMemo(() => (
    isAlternativeLoadingStatus(status)
  ), [status]);

  const fetchAll = async () => {
    dispatch(fetchAllAsync());
  };

  const resetModes = useCallback(() => {
    dispatch(resetMode());
    dispatch(resetBackgroundMode());
    dispatch(resetSubMode());
  }, []);

  const resetModels = useCallback(() => {
    dispatch(resetModel());
    dispatch(resetRelatedModeloLancamentoModel());
  }, []);

  useEffect(() => {
    fetchAll();
    resetModes();
    resetModels();
  }, []);

  const handleActivateInactivate = useOperationController(
    async (event, id) => {
      event.preventDefault();

      const { active } = modelosFaturamento.find(
        (modeloFaturamento) => modeloFaturamento.id === id
      );

      const isActivation = !active;

      if (isActivation) {
        dispatch(activateByIdAsync(id));
      } else {
        dispatch(inactivateByIdAsync(id));
      }
    }
  );

  const handleClickOnButtonNew = useCallback((event) => {
    event.stopPropagation();
    dispatch(changeToCreateMode());
  }, []);

  const handleClickOnEditButton = useCallback((event, id) => {
    event.stopPropagation();
    dispatch(changeToConsultMode());
    history.push(t('com.muralis.qcx.url.moduloGeraisServicosModelosFaturamentoDetalhes', { id }));
  }, [history]);
  const breadcrumbs = [
    {
      link: {
        to: '/',
        name: t('com.muralis.qcx.inicio'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.cadastros'),
      },
    },
    {
      link: {
        to: t('com.muralis.qcx.url.moduloCadastrosGerais'),
        name: t('com.muralis.qcx.gerais'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.servico.labelPlural'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.modelosFaturamento'),
      },
    },
  ];
  const columns = [
    {
      field: 'id',
      headerName: t('com.muralis.qcx.codigo'),
      headerAlign: 'center',
      align: 'center',
      type: 'string',
      flex: 100,
      valueGetter: ({ row }) => (
        row?.id
      ),
    },
    {
      field: 'description',
      headerName: t('com.muralis.qcx.descricao'),
      flex: 230,
      valueGetter: ({ row }) => (
        row?.description
      ),
    },
    {
      field: 'planoDeConta',
      headerName: t('com.muralis.qcx.empresa.contaContabil'),
      headerAlign: 'center',
      align: 'center',
      flex: 220,
      valueGetter: ({ row }) => (
        row?.planoDeConta?.description || '-'
      ),
    },
    {
      field: 'automatico',
      type: 'boolean',
      headerName: t('com.muralis.qcx.empresa.faturamentoAutomatico'),
      flex: 220,
    },
    {
      field: 'automaticoFilter',
      hide: true,
      headerName: t('com.muralis.qcx.empresa.faturamentoAutomatico'),
      flex: 180,
      valueGetter: ({ row }) => (
        row?.automatico ? t('com.muralis.qcx.eFaturamentoAutomatico') : t('com.muralis.qcx.naoFaturamentoAutomatico')
      ),
    },
    {
      field: 'actions',
      headerName: t('com.muralis.qcx.acoes.label'),
      headerAlign: 'center',
      align: 'center',
      flex: 120,
      renderCell: ({ row: { id, active } }) => (
        <>
          {userHasDeleteRole(authInfo, 'modelo-faturamento') && (
          <IconButton
            key={`stw-active${id}`}
            value={active}
            name={`stw-active-${id}`}
            onClick={(event) => handleActivateInactivate(event, id)}
          >
            <DeleteIcon color="error" />
          </IconButton>
          )}
          <IconButton
            key={`btn-view-${id}`}
            name={`btn-view-${id}`}
            onClick={(event) => handleClickOnEditButton(event, id)}
          >
            <PageviewIcon color="primary" />
          </IconButton>
        </>
      ),
    },
  ];

  const templateProps = {
    page: {
      title: t('com.muralis.qcx.modelosFaturamento'),
      breadcrumbs,
    },
    control: {
      buttonNew: {
        description: t('com.muralis.qcx.faturamento.novoModeloFaturamento'),
        path: t('com.muralis.qcx.url.moduloGeraisServicosModelosFaturamentoNovo'),
        onClick: handleClickOnButtonNew,
      },
    },
    table: {
      columns,
    },
  };

  const filterProps = [
    'id',
    'description',
    'planoDeConta.description',
  ];

  return (
    <QCXSimpleConsultPageTemplate
      pageProps={templateProps.page}
      controlProps={templateProps.control}
      tableProps={templateProps.table}
      tableData={modelosFaturamento}
      isLoading={isAlternativeLoading}
      multipleFilter
      filterProps={filterProps}
      authInfo={authInfo}
      requiredRoles={['modelo-faturamento']}

    />
  );
}
