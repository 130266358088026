import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IDLE_CONTROL } from '../config-control';
import {
  BACKGROUND_CREATE_MODE,
  CONSULT_MODE,
  CREATE_MODE,
  NONE_BACKGROUND_MODE,
  NONE_MODE,
  NONE_SUB_MODE,
  SUB_CONSULT_MODE,
  SUB_CREATE_MODE,
  SUB_UPDATE_MODE,
  UPDATE_MODE,
} from '../mode';
import {
  ALTERNATIVE_LOADING_STATUS,
  FAILURE_STATUS,
  IDLE_STATUS,
  LOADING_STATUS,
  PREPARING_ACTION_STATUS,
  SUCCESS_STATUS,
} from '../status';
import {
  activateById,
  fetchAll,
  fetchByFilter,
  fetchById,
  inactivateById,
} from './unidadeNegocioAPI';
import i18n from '../../i18n';

const initialState = {
  status: IDLE_STATUS,
  mode: {
    main: NONE_MODE,
    background: NONE_BACKGROUND_MODE,
  },
  error: null,
  response: {
    status: null,
    message: null,
    data: null,
  },
  config: {
    control: IDLE_CONTROL,
  },
  list: [],
  model: {
    code: undefined,
    description: undefined,
    sigla: undefined,
    percentuais: undefined,
    insertionDate: undefined,
    active: undefined,
    contatoNfe: {
      email: undefined,
      telefone: undefined,
    },
    grupo: {
      id: '',
    },
    endereco: {
      cep: undefined,
      logradouro: undefined,
      numero: undefined,
      complemento: undefined,
      bairro: undefined,
      cidade: {
        id: undefined,
      },
    },
    pessoa: {
      tipo: undefined,
      nome: undefined,
      nomeResumido: undefined,
      cnpj: undefined,
      incricaoEstadual: undefined,
      incricaoMunicipal: undefined,
      cnae: {
        id: undefined,
      },
    },
  },
};

const fetchAllAsync = createAsyncThunk(
  'unidadeNegocio/fetchAll',
  async () => {
    const { data } = await fetchAll();
    return { data };
  }
);
const fetchByFilterAsync = createAsyncThunk(
  'unidadeNegocio/fetchByFilter',
  async (params) => {
    const { data } = await fetchByFilter(params);
    return { data };
  }
);
const fetchByIdAsync = createAsyncThunk(
  'unidadeNegocio/fetchById',
  async (data) => {
    const response = await fetchById(data);
    return {
      response: {
        status: response.status,
        data: response.data,
      },
    };
  }
);

const activateByIdAsync = createAsyncThunk(
  'unidadeNegocio/activateById',
  async (id) => {
    const { status, data } = await activateById(id);
    return { response: { status, data } };
  }
);

const inactivateByIdAsync = createAsyncThunk(
  'unidadeNegocio/inactivateById',
  async (id) => {
    const { status, data } = await inactivateById(id);
    return { response: { status, data } };
  }
);

const unidadeNegocioSlice = createSlice({
  name: 'unidadeNegocio',
  initialState,
  reducers: {
    changeStatusTo: (state, action) => {
      state.status = action.payload.status;
    },
    loading: (state) => {
      state.status = LOADING_STATUS;
    },
    success: (state) => {
      state.status = SUCCESS_STATUS;
    },
    failure: (state) => {
      state.status = FAILURE_STATUS;
    },
    preparingAction: (state) => {
      state.status = PREPARING_ACTION_STATUS;
    },
    resetStatus: (state) => {
      state.status = IDLE_STATUS;
    },
    changeToCreateMode: (state) => {
      state.mode.main = CREATE_MODE;
    },
    changeToConsultMode: (state) => {
      state.mode.main = CONSULT_MODE;
    },
    changeToUpdateMode: (state) => {
      state.mode.main = UPDATE_MODE;
    },
    resetMode: (state) => {
      state.mode.main = NONE_MODE;
    },
    changeToBackgroundCreateMode: (state) => {
      state.mode.background = BACKGROUND_CREATE_MODE;
    },
    resetBackgroundMode: (state) => {
      state.mode.background = NONE_BACKGROUND_MODE;
    },
    changeToSubCreateMode: (state) => {
      state.mode.sub = SUB_CREATE_MODE;
    },
    changeToSubConsultMode: (state) => {
      state.mode.sub = SUB_CONSULT_MODE;
    },
    changeToSubUpdateMode: (state) => {
      state.mode.sub = SUB_UPDATE_MODE;
    },
    resetSubMode: (state) => {
      state.mode.sub = NONE_SUB_MODE;
    },
    setConfig: (state, action) => {
      state.config = action.payload;
    },
    changeControlTo: (state, action) => {
      state.config.control = action.payload;
    },
    setModel: (state, action) => {
      state.model = {
        ...action.payload,
      };
    },
    resetModel: (state) => {
      state.model = initialState.model;
    },
    addToList: (state, action) => {
      state.list = [...state.list, action.payload.data];
    },
    updateOnList: (state, action) => {
      state.list = state.list.map((current) => {
        if (current.id === action.payload.data?.id) {
          return action.payload.data;
        }
        return current;
      });
    },
    resetList: (state) => {
      state.list = [];
    },
    setResponse: (state, action) => {
      state.response = action.payload;
    },
    clearResponse: (state) => {
      state.response = {
        status: null,
        message: null,
        data: null,
      };
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    clearError: (state) => {
      state.error = initialState.error;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllAsync.pending, (state) => {
        state.status = ALTERNATIVE_LOADING_STATUS;
      })
      .addCase(fetchAllAsync.fulfilled, (state, action) => {
        state.status = IDLE_STATUS;
        state.list = action.payload.data;
      })
      .addCase(fetchAllAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.carregarListaUnidadesNegocio', { erro: action.error.message }),
        };
      })
      .addCase(fetchByFilterAsync.pending, (state) => {
        state.status = ALTERNATIVE_LOADING_STATUS;
      })
      .addCase(fetchByFilterAsync.fulfilled, (state, action) => {
        state.status = IDLE_STATUS;
        state.list = action.payload.data;
      })
      .addCase(fetchByFilterAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.carregarListaUnidadesNegocio', { erro: action.error.message }),
        };
      })
      .addCase(fetchByIdAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(fetchByIdAsync.fulfilled, (state, action) => {
        if (action.payload.response.status === 200) {
          state.model = {
            ...action.payload.response.data,
          };
          state.status = SUCCESS_STATUS;
        }
      })
      .addCase(fetchByIdAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.carregarUnidadeNegocio', { erro: action.error.message }),
        };
      })
      .addCase(activateByIdAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(activateByIdAsync.fulfilled, (state, action) => {
        state.status = SUCCESS_STATUS;
        state.response.status = action.payload.response.status;
        state.response.data = action.payload.response.data;

        const { pessoa } = action.payload.response.data;
        state.response.message = i18n.t('com.muralis.qcx.mensagem.unidadeNegocioAtivada', { unidade: pessoa.nomeResumido });

        if (state.response.status === 200) {
          state.list = state.list
            .map((current) => (
              current.id === action.payload.response.data.id
                ? action.payload.response.data
                : current
            ));
        }
      })
      .addCase(activateByIdAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.ativarUnidadeNegocio', { erro: action.error.message }),
        };
      })
      .addCase(inactivateByIdAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(inactivateByIdAsync.fulfilled, (state, action) => {
        state.status = SUCCESS_STATUS;
        state.response.status = action.payload.response.status;
        state.response.data = action.payload.response.data;

        const { pessoa } = action.payload.response.data;
        state.response.message = i18n.t('com.muralis.qcx.mensagem.unidadeNegocioInativada', { unidade: pessoa.nomeResumido });

        if (state.response.status === 200) {
          state.list = state.list
            .map((current) => (
              current.id === action.payload.response.data.id
                ? action.payload.response.data
                : current
            ));
        }
      })
      .addCase(inactivateByIdAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.inativarUnidadeNegocio', { erro: action.error.message }),
        };
      });
  },
});

const {
  changeStatusTo,
  loading,
  success,
  failure,
  preparingAction,
  resetStatus,
  changeToCreateMode,
  changeToConsultMode,
  changeToUpdateMode,
  resetMode,
  changeToBackgroundCreateMode,
  resetBackgroundMode,
  changeToSubConsultMode,
  changeToSubUpdateMode,
  changeToSubCreateMode,
  resetSubMode,
  setModel,
  setConfig,
  resetModel,
  addToList,
  updateOnList,
  resetList,
  setResponse,
  clearResponse,
  setError,
  changeControlTo,
  clearError,
} = unidadeNegocioSlice.actions;

const selectStatus = (state) => state.unidadeNegocio.status;
const selectMode = (state) => state.unidadeNegocio.mode.main;
const selectBackgroundMode = (state) => state.unidadeNegocio.mode.background;
const selectSubMode = (state) => state.unidadeNegocio.mode.sub;
const selectUnidadesNegocio = (state) => state.unidadeNegocio.list;
const selectUnidadeNegocio = (state) => state.unidadeNegocio.model;
const selectError = (state) => state.unidadeNegocio.error;
const selectConfigControl = (state) => state.unidadeNegocio.config.control;
const selectResponse = (state) => state.unidadeNegocio.response;

export {
  unidadeNegocioSlice,
  changeStatusTo,
  loading,
  success,
  failure,
  preparingAction,
  resetStatus,
  changeToCreateMode,
  changeToConsultMode,
  changeToUpdateMode,
  resetMode,
  changeToBackgroundCreateMode,
  resetBackgroundMode,
  changeToSubConsultMode,
  changeToSubUpdateMode,
  changeToSubCreateMode,
  resetSubMode,
  setModel,
  resetModel,
  addToList,
  updateOnList,
  resetList,
  setResponse,
  clearResponse,
  setError,
  clearError,
  fetchAllAsync,
  fetchByIdAsync,
  activateByIdAsync,
  inactivateByIdAsync,
  setConfig,
  fetchByFilterAsync,
  changeControlTo,
  selectConfigControl,
  selectStatus,
  selectMode,
  selectBackgroundMode,
  selectSubMode,
  selectUnidadeNegocio,
  selectUnidadesNegocio,
  selectError,
  selectResponse,
};

export default unidadeNegocioSlice.reducer;
