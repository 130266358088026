import { useContext, useMemo } from 'react';
import PaginatorControllerContext from '../../../contexts/components/paginator/PaginatorControllerContext';

export default function usePaginatorController() {
  const contextValue = useContext(PaginatorControllerContext);

  const paginatorController = useMemo(() => ({
    next: contextValue?.next,
    previous: contextValue?.previous,
    setPage: contextValue?.setPage,
    markDefaultPageAsUsed: contextValue?.markDefaultPageAsUsed,
    getMetadata: contextValue?.getMetadata,
    changeMetadata: contextValue?.changeMetadata,
  }), [contextValue]);

  return paginatorController;
}
