import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TipoDocumento } from 'quickcomex-api-types';
import { fetchAllAsync, selectTiposDocumentos } from '../../../../../../features/tipo-documento/tipoDocumentoSlice';
import MultiSelectFormInput from '../../../../../../ts/common/components/forms/MultiSelectFormInput';

type Props = {
  label: string;
  name: string;
};

function MultiSelectTiposDocumentos({ label, name }: Props) {
  const dispatch = useDispatch();
  const tipoDocumentos: TipoDocumento[] = useSelector(selectTiposDocumentos);

  const options = useMemo(
    () =>
      tipoDocumentos.map((tipoDocumento) => ({
        ...tipoDocumento,
        id: tipoDocumento.id,
        value: tipoDocumento.description,
      })),
    [tipoDocumentos]
  );

  useEffect(() => {
    dispatch(fetchAllAsync());
  }, []);

  return <MultiSelectFormInput name={name} options={options} label={label} />;
}

export default MultiSelectTiposDocumentos;
