import React from 'react';
import { Link } from 'react-router-dom';

export default function QCXLink({
  children,
  style,
  ...restProps
}) {
  return (
    <Link
      style={{
        ...style,
        color: '#312783', // azul plataforma
      }}
      {...restProps}
    >
      {children}
    </Link>
  );
}
