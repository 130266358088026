import { createAsyncThunk } from '@reduxjs/toolkit';
import { omit } from 'lodash';
import { DECLARACAO_IMPORTACAO_ENDPOINT } from '../../utils/api/domain/domain-endpoint-utils';
import { DEFAULT_TIPO_DI_DUIMP_PARAMETER } from '../../utils/general/fatura/faturaUtils';
import { faturaAPI } from '../fatura/faturaAPI';
import { followUpAPI } from '../follow-up/followUpAPI';
import { finishLoading, startLoading } from '../application-state/applicationStateSlice';

const fetchAllFromFaturaAsync = createAsyncThunk(
  'declaracaoImportacao/fetchAllFromFatura',
  async () => {
    const { data } = await faturaAPI.fetchByFilter([
      DEFAULT_TIPO_DI_DUIMP_PARAMETER
    ]);
    return { data };
  }
);

const fetchByIdFromFaturaAsync = createAsyncThunk(
  'declaracaoImportacao/fetchByIdFromFatura',
  async (data) => {
    const response = await faturaAPI.fetchById(data);

    let followUpResponse;
    if (response.status === 200) {
      followUpResponse = await followUpAPI.fetchById(response?.data?.followUp?.id);
    }

    return {
      response: {
        status: response.status,
        data: { ...response.data, followUp: followUpResponse?.data ?? response.data.followUp },
      },
    };
  }
);

const conferirByIdFromFaturaAsync = createAsyncThunk(
  'declaracaoImportacao/conferirByIdFromFatura',
  async (data, { rejectWithValue }) => {
    try {
      const response = await faturaAPI.conferirBy(
        data?.id
      );

      return {
        response: {
          status: response?.status,
          data: response?.data,
          ...(
            omit(
              response,
              ['request', 'config']
            )
          ),
        },
      };
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

const duplicarByIdFromFaturaAsync = createAsyncThunk(
  'declaracaoImportacao/duplicarById',
  async (data, { rejectWithValue }) => {
    try {
      const response = await faturaAPI.duplicarBy(
        data?.id,
        [DECLARACAO_IMPORTACAO_ENDPOINT],
        data?.payload
      );

      return {
        response: {
          status: response?.status,
          data: response?.data,
          ...(
            omit(
              response,
              ['request', 'config']
            )
          ),
        },
      };
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

const liberarByIdFromFaturaAsync = createAsyncThunk(
  'declaracaoImportacao/liberarByIdFromFatura',
  async (data, { rejectWithValue }) => {
    try {
      const response = await faturaAPI.liberarBy(
        data?.id
      );
      return {
        response: {
          status: response?.status,
          data: response?.data,
          ...(
            omit(
              response,
              ['request', 'config']
            )
          ),
        },
      };
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

const retransmitirByIdFromFaturaAsync = createAsyncThunk(
  'declaracaoImportacao/retransmitirByIdFromFatura',
  async (data, { rejectWithValue }) => {
    try {
      const response = await faturaAPI.retransmitirBy(
        data?.id
      );
      return {
        response: {
          status: response?.status,
          data: response?.data,
          ...(
            omit(
              response,
              ['request', 'config']
            )
          ),
        },
      };
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

const activateByIdFromFaturaAsync = createAsyncThunk(
  'declaracaoImportacao/activateByIdFromFatura',
  async (id) => {
    const { status, data } = await faturaAPI.activateById(id);
    return { response: { status, data } };
  }
);

const inactivateByIdFromFaturaAsync = createAsyncThunk(
  'declaracaoImportacao/inactivateByIdFromFatura',
  async (id) => {
    const { status, data } = await faturaAPI.inactivateById(id);
    return { response: { status, data } };
  }
);

const reabrirByIdAsync = createAsyncThunk(
  'declaracaoUnicaExportacao/reabrirById',
  async (data, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());

      const response = await faturaAPI.reabrirById(data?.id);

      if (response?.status === 200) {
        dispatch(finishLoading());
        dispatch(fetchAllFromFaturaAsync());
      }

      return {
        response: {
          status: response?.status,
          data: response?.data,
          ...omit(response, ['request', 'config']),
        },
      };
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

const declaracaoImportacaoThunks = {
  fetchAllFromFaturaAsync,
  fetchByIdFromFaturaAsync,
  conferirByIdFromFaturaAsync,
  duplicarByIdFromFaturaAsync,
  activateByIdFromFaturaAsync,
  inactivateByIdFromFaturaAsync,
  liberarByIdFromFaturaAsync,
  retransmitirByIdFromFaturaAsync,
  reabrirByIdAsync,
};

export {
  declaracaoImportacaoThunks,
  fetchAllFromFaturaAsync,
  fetchByIdFromFaturaAsync,
  conferirByIdFromFaturaAsync,
  duplicarByIdFromFaturaAsync,
  activateByIdFromFaturaAsync,
  inactivateByIdFromFaturaAsync,
  liberarByIdFromFaturaAsync,
  retransmitirByIdFromFaturaAsync,
  reabrirByIdAsync,
};
