const idiomas = [
  { id: 'ENG', code: 'ENG', description: 'Inglês' },
  { id: 'POR', code: 'POR', description: 'Português' },
  { id: 'SPA', code: 'SPA', description: 'Espanhol' },
  { id: 'ITA', code: 'ITA', description: 'Italiano' },
  { id: 'FRA', code: 'FRA', description: 'Francês' },
  { id: 'NLD', code: 'NLD', description: 'Holandês' },
  { id: 'DEU', code: 'DEU', description: 'Alemão' },
];

async function fetchAll() {
  return { data: idiomas };
}

async function fetchById(id) {
  return { data: idiomas.find((idioma) => idioma.id === id) };
}

const followUpStorageIdiomaAPI = {
  fetchAll,
  fetchById,
};

export { followUpStorageIdiomaAPI, fetchAll, fetchById };
