import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  BACKGROUND_CREATE_MODE,
  CONSULT_MODE,
  CREATE_MODE,
  NONE_BACKGROUND_MODE,
  NONE_MODE,
  NONE_SUB_MODE,
  SUB_CONSULT_MODE,
  SUB_CREATE_MODE,
  SUB_UPDATE_MODE,
  UPDATE_MODE,
} from '../mode';
import {
  ALTERNATIVE_LOADING_STATUS,
  FAILURE_STATUS,
  IDLE_STATUS,
  LOADING_STATUS,
  PREPARING_ACTION_STATUS,
  SUCCESS_STATUS,
} from '../status';
import {
  fetchAll,
  fetchById,
  activateById,
  inactivateById,
} from './armazensAPI';
import i18n from '../../i18n';

const initialState = {
  status: IDLE_STATUS,
  mode: {
    main: NONE_MODE,
    background: NONE_BACKGROUND_MODE,
    sub: NONE_SUB_MODE,
  },
  subMode: NONE_MODE,
  error: null,
  response: {
    status: null,
    message: null,
    data: null,
  },
  list: [],
  model: {
    id: '',
    code: '',
    active: null,
    description: '',
    insertionDate: null,
    importador: null,
    pessoa: {
      tipo: 'PessoaJuridica',
      nome: '',
      nomeResumido: '',
      inscricaoEstadual: '',
      inscricaoMunicipal: '',
    },
    endereco: {
      complemento: '',
      numero: '',
      logradouro: '',
      bairro: '',
      cep: undefined,
      cidade: {
        id: undefined,
        estado: {
          id: undefined,
          pais: {
            id: undefined,
          },
        },
      },
      insertionDate: null,
    },
    recintoAduaneiro: {
      id: '',
    },
    setorLotacao: {
      id: '',
    },
    unidadeLocalRfb: {
      id: '',
    },
    tabelasArmazenagem: [],

  },
};

const fetchAllAsync = createAsyncThunk(
  'armazem/fetchAll',
  async () => {
    const { data } = await fetchAll();
    return { data };
  }
);

const fetchByIdAsync = createAsyncThunk(
  'armazem/fetchById',
  async (data) => {
    const response = await fetchById(data);
    return {
      response: {
        status: response.status,
        data: response.data,
      },
    };
  }
);

const activateByIdAsync = createAsyncThunk(
  'armazem/activateById',
  async (id) => {
    const { status, data } = await activateById(id);
    return { response: { status, data } };
  }
);

const inactivateByIdAsync = createAsyncThunk(
  'armazem/inactivateById',
  async (id) => {
    const { status, data } = await inactivateById(id);
    return { response: { status, data } };
  }
);

const armazemSlice = createSlice({
  name: 'armazem',
  initialState,
  reducers: {
    changeStatusTo: (state, action) => {
      state.status = action.payload.status;
    },
    loading: (state) => {
      state.status = LOADING_STATUS;
    },
    success: (state) => {
      state.status = SUCCESS_STATUS;
    },
    failure: (state) => {
      state.status = FAILURE_STATUS;
    },
    preparingAction: (state) => {
      state.status = PREPARING_ACTION_STATUS;
    },
    resetStatus: (state) => {
      state.status = IDLE_STATUS;
    },
    changeToCreateMode: (state) => {
      state.mode.main = CREATE_MODE;
    },
    changeToConsultMode: (state) => {
      state.mode.main = CONSULT_MODE;
    },
    changeToUpdateMode: (state) => {
      state.mode.main = UPDATE_MODE;
    },
    resetMode: (state) => {
      state.mode.main = NONE_MODE;
    },
    changeToBackgroundCreateMode: (state) => {
      state.mode.background = BACKGROUND_CREATE_MODE;
    },
    resetBackgroundMode: (state) => {
      state.mode.background = NONE_BACKGROUND_MODE;
    },
    changeToSubCreateMode: (state) => {
      state.mode.sub = SUB_CREATE_MODE;
    },
    changeToSubConsultMode: (state) => {
      state.mode.sub = SUB_CONSULT_MODE;
    },
    changeToSubUpdateMode: (state) => {
      state.mode.sub = SUB_UPDATE_MODE;
    },
    resetSubMode: (state) => {
      state.mode.sub = NONE_SUB_MODE;
    },
    setModel: (state, action) => {
      state.model = action.payload;
    },
    addToList: (state, action) => {
      state.list = [...state.list, action.payload.data];
    },
    updateOnList: (state, action) => {
      state.list = state.list.map((current) => {
        if (current.id === action.payload.data?.id) {
          return action.payload.data;
        }
        return current;
      });
    },
    setResponse: (state, action) => {
      state.response = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    clearResponse: (state) => {
      state.response = {
        status: null,
        message: null,
        data: null,
      };
    },
    resetModel: (state) => {
      state.model = initialState.model;
    },
    setTabelasArmazenagems: (state, action) => {
      state.model.tabelasArmazenagem = [...action.payload];
    },

  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllAsync.pending, (state) => {
        state.status = ALTERNATIVE_LOADING_STATUS;
      })
      .addCase(fetchAllAsync.fulfilled, (state, action) => {
        state.status = IDLE_STATUS;
        state.list = action.payload.data;
      })
      .addCase(fetchAllAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroCarregarListaArmazem', { erro: action.error.message }),
        };
      })
      .addCase(fetchByIdAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(fetchByIdAsync.fulfilled, (state, action) => {
        if (action.payload.response.status === 200) {
          state.model = action.payload.response.data;
          state.status = SUCCESS_STATUS;
        }
      })
      .addCase(fetchByIdAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroCarregarArmazem', { erro: action.error.message }),
        };
      })
      .addCase(activateByIdAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(activateByIdAsync.fulfilled, (state, action) => {
        state.status = SUCCESS_STATUS;
        state.response.status = action.payload.response.status;
        state.response.data = action.payload.response.data;

        const { nomeResumido } = action.payload.response.data?.pessoa;
        state.response.message = i18n.t('com.muralis.qcx.mensagem.armazemAtivado', { armazem: nomeResumido });

        if (state.response.status === 200) {
          state.list = state.list
            .map((current) => (
              current.id === action.payload.response.data.id
                ? action.payload.response.data
                : current
            ));
        }
      })
      .addCase(activateByIdAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroAtivarArmazem', { erro: action.error.message }),
        };
      })
      .addCase(inactivateByIdAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(inactivateByIdAsync.fulfilled, (state, action) => {
        state.status = SUCCESS_STATUS;
        state.response.status = action.payload.response.status;
        state.response.data = action.payload.response.data;

        const { nomeResumido } = action.payload.response.data?.pessoa;
        state.response.message = i18n.t('com.muralis.qcx.mensagem.armazemInativado', { armazem: nomeResumido });

        if (state.response.status === 200) {
          state.list = state.list
            .map((current) => (
              current.id === action.payload.response.data.id
                ? action.payload.response.data
                : current
            ));
        }
      })
      .addCase(inactivateByIdAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroInativarArmazem', { erro: action.error.message }),
        };
      });
  },
});

const {
  changeStatusTo,
  loading,
  success,
  failure,
  resetStatus,
  changeToCreateMode,
  changeToConsultMode,
  changeToUpdateMode,
  changeToBackgroundCreateMode,
  changeToSubConsultMode,
  changeToSubCreateMode,
  changeToSubUpdateMode,
  preparingAction,
  resetBackgroundMode,
  resetSubMode,
  resetMode,
  addToList,
  updateOnList,
  setResponse,
  setResponseMessage,
  clearResponse,
  setError,
  resetModel,
  setModel,
  setTabelasArmazenagems,
} = armazemSlice.actions;

const selectArmazens = (state) => state.armazem.list;
const selectStatus = (state) => state.armazem.status;
const selectMode = (state) => state.armazem.mode.main;
const selectSubMode = (state) => state.armazem.mode.sub;
const selectError = (state) => state.armazem.error;
const selectResponse = (state) => state.armazem.response;
const selectArmazem = (state) => state.armazem.model;
const selectBackgroundMode = (state) => state.armazem.mode.background;

export {
  armazemSlice,
  changeStatusTo,
  loading,
  success,
  failure,
  resetStatus,
  changeToCreateMode,
  changeToConsultMode,
  changeToUpdateMode,
  changeToBackgroundCreateMode,
  changeToSubConsultMode,
  changeToSubCreateMode,
  changeToSubUpdateMode,
  preparingAction,
  resetBackgroundMode,
  resetSubMode,
  resetMode,
  addToList,
  updateOnList,
  setResponse,
  setResponseMessage,
  clearResponse,
  setError,
  resetModel,
  setModel,
  fetchAllAsync,
  fetchByIdAsync,
  activateByIdAsync,
  inactivateByIdAsync,
  setTabelasArmazenagems,
  selectArmazens,
  selectArmazem,
  selectStatus,
  selectError,
  selectResponse,
  selectMode,
  selectSubMode,
  selectBackgroundMode,
};

export default armazemSlice.reducer;
