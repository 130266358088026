import { createSlice } from '@reduxjs/toolkit';
import { IDLE_CONTROL } from '../config-control';
import {
  IDLE_STATUS,
  SUCCESS_STATUS,
  LOADING_STATUS,
  ALTERNATIVE_LOADING_STATUS,
  FAILURE_STATUS,
} from '../status';
import {
  fetchAllAsync,
  fetchByIdAsync,
  fetchByFilterAsync,
} from './fundamentacaoEspecificaThunks';
import i18n from '../../i18n';

const initialState = {
  status: IDLE_STATUS,
  error: null,
  config: {
    control: IDLE_CONTROL,
  },
  response: {
    status: null,
    message: null,
    data: null,
  },
  list: [],
};

const fundamentacaoEspecificaSlice = createSlice({
  name: 'fundamentacaoEspecifica',
  initialState,
  reducers: {
    changeStatusTo: (state, action) => {
      state.status = action.payload.status;
    },
    loading: (state) => {
      state.status = LOADING_STATUS;
    },
    success: (state) => {
      state.status = SUCCESS_STATUS;
    },
    failure: (state) => {
      state.status = FAILURE_STATUS;
    },
    resetStatus: (state) => {
      state.status = IDLE_STATUS;
    },
    setConfig: (state, action) => {
      state.config = action.payload;
    },
    changeControlTo: (state, action) => {
      state.config.control = action.payload;
    },
    setResponse: (state, action) => {
      state.response = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    clearResponse: (state) => {
      state.response = {
        status: null,
        message: null,
        data: null,
      };
    },
    setList: (state, action) => {
      state.list = action.payload || state.list;
    },
    resetList: (state) => {
      state.list = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllAsync.pending, (state) => {
        state.status = ALTERNATIVE_LOADING_STATUS;
      })
      .addCase(fetchAllAsync.fulfilled, (state, action) => {
        state.status = IDLE_STATUS;
        state.list = action.payload.data;
      })
      .addCase(fetchAllAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.carregarListaFundamentacaoEspecifica', { erro: action.error.message }),
        };
      })
      .addCase(fetchByIdAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(fetchByIdAsync.fulfilled, (state, action) => {
        if (action.payload.response.status === 200) {
          state.model = action.payload.response.data;
          state.status = SUCCESS_STATUS;
        }
      })
      .addCase(fetchByIdAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.carregarDadosFundamentacaoEspecifica', { erro: action.error.message }),
        };
      })
      .addCase(fetchByFilterAsync.pending, (state) => {
        state.status = ALTERNATIVE_LOADING_STATUS;
      })
      .addCase(fetchByFilterAsync.fulfilled, (state, action) => {
        state.status = IDLE_STATUS;
        state.list = action.payload.data;
      })
      .addCase(fetchByFilterAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.filtrarListaFundamentacoesEspecificas', { erro: action.error.message }),
        };
      });
  },
});

const {
  changeStatusTo,
  loading,
  success,
  failure,
  resetStatus,
  setConfig,
  changeControlTo,
  setResponse,
  setResponseMessage,
  clearResponse,
  setError,
  setList,
  resetList,
} = fundamentacaoEspecificaSlice.actions;

const fundamentacaoEspecificaActions = fundamentacaoEspecificaSlice.actions;

export {
  fundamentacaoEspecificaSlice,
  fundamentacaoEspecificaActions,
  changeStatusTo,
  loading,
  success,
  failure,
  resetStatus,
  setConfig,
  changeControlTo,
  setResponse,
  setResponseMessage,
  clearResponse,
  setError,
  setList,
  resetList,
};

export default fundamentacaoEspecificaSlice.reducer;
