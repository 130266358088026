import { getOptionByValueInList } from '../general-utils';

const FATURA_OCR_NAO_INTEGRADO = 'NAO_INTEGRADO';
const FATURA_OCR_ERRO_INTEGRACAO = 'ERRO_INTEGRACAO';
const FATURA_OCR_AGUARDANDO_PROCESSAMENTO = 'AGUARDANDO_PROCESSAMENTO';
const FATURA_OCR_PROCESSANDO = 'PROCESSANDO';
const FATURA_OCR_INCOMPLETO = 'INCOMPLETO';
const FATURA_OCR_ERRO_TESSERACT = 'ERRO_TESSERACT';
const FATURA_OCR_TEMPO_MAXIMO_EXCEDIDO = 'TEMPO_MAXIMO_EXCEDIDO';
const FATURA_OCR_INVALIDO = 'INVALIDO';
const FATURA_OCR_CONCLUIDO_INCOMPLETO = 'CONCLUIDO_INCOMPLETO';
const FATURA_OCR_CONCLUIDO = 'CONCLUIDO';

const LIST = [
  {
    label: 'com.muralis.qcx.fatura.faturaOcrNaoIntegrado',
    value: FATURA_OCR_NAO_INTEGRADO,
  },
  {
    label: 'com.muralis.qcx.fatura.faturaOcrErroIntegracao',
    value: FATURA_OCR_ERRO_INTEGRACAO,
  },
  {
    label: 'com.muralis.qcx.fatura.faturaOcrAguardandoProcessamento',
    value: FATURA_OCR_AGUARDANDO_PROCESSAMENTO,
  },
  {
    label: 'com.muralis.qcx.fatura.faturaOcrProcessando',
    value: FATURA_OCR_PROCESSANDO,
  },
  {
    label: 'com.muralis.qcx.fatura.faturaOcrIncompleto',
    value: FATURA_OCR_INCOMPLETO,
  },
  {
    label: 'com.muralis.qcx.fatura.faturaOcrErroTesseract',
    value: FATURA_OCR_ERRO_TESSERACT,
  },
  {
    label: 'com.muralis.qcx.fatura.faturaOcrTempoMaximoExcedido',
    value: FATURA_OCR_TEMPO_MAXIMO_EXCEDIDO,
  },
  {
    label: 'com.muralis.qcx.fatura.faturaOcrInvalido',
    value: FATURA_OCR_INVALIDO,
  },
  {
    label: 'com.muralis.qcx.fatura.faturaOcrConcluidoIncompleto',
    value: FATURA_OCR_CONCLUIDO_INCOMPLETO,
  },
  {
    label: 'com.muralis.qcx.fatura.faturaOcrConcluido',
    value: FATURA_OCR_CONCLUIDO,
  },
];

const isFaturaOcrNaoIntegrado = (value) => FATURA_OCR_NAO_INTEGRADO === value;
const isFaturaOcrErroIntegracao = (value) => FATURA_OCR_ERRO_INTEGRACAO === value;
const isFaturaAguardandoProcessamento = (value) => FATURA_OCR_AGUARDANDO_PROCESSAMENTO === value;
const isFaturaProcessando = (value) => FATURA_OCR_PROCESSANDO === value;
const isFaturaOcrIncompleto = (value) => FATURA_OCR_INCOMPLETO === value;
const isFaturaOcrErroTesseract = (value) => FATURA_OCR_ERRO_TESSERACT === value;
const isFaturaOcrTempoMaximoExcedido = (value) => FATURA_OCR_TEMPO_MAXIMO_EXCEDIDO === value;
const isFaturaOcrInvalido = (value) => FATURA_OCR_INVALIDO === value;
const isFaturaOcrConcluidoIncompleto = (value) => FATURA_OCR_CONCLUIDO_INCOMPLETO === value;
const isFaturaOcrConcluido = (value) => FATURA_OCR_CONCLUIDO === value;

const getLabelByValue = (value) => {
  const foundOption = getOptionByValueInList(LIST, value);
  return foundOption && foundOption?.label;
};

const FollowUpOcrStatusUtils = {
  LIST,
  FATURA_OCR_NAO_INTEGRADO,
  FATURA_OCR_ERRO_INTEGRACAO,
  FATURA_OCR_PROCESSANDO,
  FATURA_OCR_INCOMPLETO,
  FATURA_OCR_ERRO_TESSERACT,
  FATURA_OCR_TEMPO_MAXIMO_EXCEDIDO,
  FATURA_OCR_INVALIDO,
  FATURA_OCR_CONCLUIDO_INCOMPLETO,
  FATURA_OCR_CONCLUIDO,
  isFaturaOcrNaoIntegrado,
  isFaturaOcrErroIntegracao,
  isFaturaAguardandoProcessamento,
  isFaturaProcessando,
  isFaturaOcrIncompleto,
  isFaturaOcrErroTesseract,
  isFaturaOcrTempoMaximoExcedido,
  isFaturaOcrInvalido,
  isFaturaOcrConcluidoIncompleto,
  isFaturaOcrConcluido,
  getLabelByValue,
};

export default FollowUpOcrStatusUtils;
