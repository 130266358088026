import { createAsyncThunk } from '@reduxjs/toolkit';
import { DEFAULT_TIPO_DI_DUIMP_PARAMETER } from '../../../../../utils/general/fatura/faturaUtils';
import * as dadosEmbarqueAPI from './dadosEmbarqueAPI';
import { TipoProdutoUtils } from '../../../../../shared-components/select-tipo-produto/tipoProdutoUtils';

const fetchAllFromFaturaAsync = createAsyncThunk(
  'dadosEmbarque/fetchAllFromFatura',
  async () => {
    const { data } = await dadosEmbarqueAPI.fetchByFilter([
      DEFAULT_TIPO_DI_DUIMP_PARAMETER
    ]);
    return { data };
  }
);

const fetchAllAsync = createAsyncThunk(
  'dadosEmbarque/fetchAll',
  async () => {
    const { data } = await dadosEmbarqueAPI.fetchByFilter([
      {
        name: 'servicoProduto',
        value: TipoProdutoUtils.IMPORTACAO,
      }
    ]);

    return { data };
  }
);

const fetchByIdAsync = createAsyncThunk(
  'dadosEmbarque/fetchById',
  async (data) => {
    const response = await dadosEmbarqueAPI.fetchById(data);
    return {
      response: {
        status: response.status,
        data: response.data,
      },
    };
  }
);

const activateByIdAsync = createAsyncThunk(
  'dadosEmbarque/activateById',
  async (id) => {
    const { status, data } = await dadosEmbarqueAPI.activateById(id);
    return { response: { status, data } };
  }
);

const inactivateByIdAsync = createAsyncThunk(
  'dadosEmbarque/inactivateById',
  async (id) => {
    const { status, data } = await dadosEmbarqueAPI.inactivateById(id);
    return { response: { status, data } };
  }
);

const dadosEmbarqueThunks = {
  fetchAllFromFaturaAsync,
  fetchByIdAsync,
  fetchAllAsync,
  activateByIdAsync,
  inactivateByIdAsync,
};

export {
  dadosEmbarqueThunks,
  fetchAllFromFaturaAsync,
  fetchByIdAsync,
  fetchAllAsync,
  activateByIdAsync,
  inactivateByIdAsync,
};
