import React, { useEffect, useMemo, useState } from 'react';
import { createFilterOptions } from '@material-ui/lab/useAutocomplete';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllAsync, selectAcrescimosValoracaoAduaneira } from '../../features/acrescimo-valoracao-aduaneira/acrescimoValoracaoAduaneiraSlice';
import QCXAutocompleteSelect from '../autocomplete-select/QCXAutocompleteSelect';
import { fetchAllAsync as fetchAllAsyncDeducoes, selectdeducaosValoracaoAduaneira } from '../../features/deducao-valoracao-aduaneira/deducaoValoracaoAduaneiraSlice';
import { TIPO_ACRESCIMO } from '../select-tipo-acrescimo-deducao/tipoAcrescimoDeducaoUtils';

export default function QCXSelectAcrescimoValoracaoAduaneiraAutocomplete({
  label,
  name,
  initialValues,
  isAcrescimo,
  ...restProps
}) {
  const dispatch = useDispatch();

  const acrescimos = useSelector(selectAcrescimosValoracaoAduaneira);
  const deducoes = useSelector(selectdeducaosValoracaoAduaneira);

  const list = useMemo(() => {
    console.log(isAcrescimo);
    return initialValues?.ignorableFields?.acrescimoDeducao?.tipo === TIPO_ACRESCIMO ? acrescimos : deducoes;
  }, [acrescimos, deducoes, initialValues, isAcrescimo]);

  const [defaultValue, setDefaultValue] = useState('');

  useEffect(() => {
    dispatch(fetchAllAsync());
    dispatch(fetchAllAsyncDeducoes());
  }, []);

  useEffect(() => {
    const value = list.find((item) => item?.id === _.get(initialValues, name));
    if (value) {
      setDefaultValue(value);
    } else {
      setDefaultValue({ id: null, description: '' });
    }
  }, [list, initialValues]);

  const filterOptions = createFilterOptions({
    stringify: (item) => (
      `${item?.code} - ${item?.description}`
    ),
  });

  return (
    <QCXAutocompleteSelect
      label={label}
      id={name}
      name={name}
      options={list}
      filterOptions={filterOptions}
      defaultValue={defaultValue}
      getOptionLabel={(item) => (
        item?.code && item?.description
          ? `${item?.code} - ${item?.description}`
          : ''
      )}
      {...restProps}
    />
  );
}
