import { createAsyncThunk } from '@reduxjs/toolkit';
import { omit } from 'lodash';

import {
  activateById,
  fetchById,
  inactivateById,
  calculateById,
  fetchByFilter,
} from './faturaAPI';

const fetchAllAsync = createAsyncThunk(
  'fatura/fetchAll',
  async () => {
    const { data } = await fetchByFilter();
    return { data };
  }
);

const fetchByIdAsync = createAsyncThunk(
  'fatura/fetchById',
  async (data) => {
    const response = await fetchById(data);
    return {
      response: {
        status: response.status,
        data: response.data,
      },
    };
  }
);

const activateByIdAsync = createAsyncThunk(
  'fatura/activateById',
  async (id) => {
    const { status, data } = await activateById(id);
    return { response: { status, data } };
  }
);

const inactivateByIdAsync = createAsyncThunk(
  'fatura/inactivateById',
  async (id, { rejectWithValue }) => {
    try {
      const { status, data } = await inactivateById(id);
      return { response: { status, data } };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const calculateByIdAsync = createAsyncThunk(
  'fatura/calculateById',
  async (data, { rejectWithValue }) => {
    try {
      const response = await calculateById(
        data?.id
      );
      return {
        response: {
          status: response?.status,
          data: response?.data,
          ...(
            omit(
              response,
              ['request', 'config']
            )
          ),
        },
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export {
  fetchAllAsync,
  fetchByIdAsync,
  activateByIdAsync,
  inactivateByIdAsync,
  calculateByIdAsync,
};
