import _ from 'lodash';
import { useCallback, useMemo } from 'react';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import QCXFinalFormDialog from '../../shared-components/dialog/QCXFinalFormDialog';
import QCXFormListenerManager from '../../shared-components/form-listener/QCXFormListenerManager';
import QCXFinalDatePickerField from '../../shared-components/final-date-picker-field/QCXFinalDatePickerField';
import QCXFinalRadioField from '../../shared-components/final-radio-field/QCXFinalRadioField';
import ConciliacaoBancariaUtils from '../../utils/general/conciliacao-bancaria/ConciliacaoBancariaUtils';
import { CLOSED_STATUS, isOpenStatus } from '../../utils/hooks/form/dialog/formDialogUtils';

export default function QCXRelatorioConciliacaoBancariaDialogForm({
  handleOnSubmit,
  handleFormStatus,
  formDialogStatus,
  initialValues,
}) {
  const { t } = useTranslation();

  const isFormDialogOpen = useMemo(() => isOpenStatus(formDialogStatus), [formDialogStatus]);

  const handleCloseFormDialogNew = useCallback(() => {
    handleFormStatus(CLOSED_STATUS);
    _.debounce(() => {}, 200)();
  }, [handleFormStatus]);

  return (
    <QCXFinalFormDialog
      key="form-dialog-relatorio-conciliacao-bancaria"
      id="form-dialog-relatorio-conciliacao-bancaria"
      title={t('com.muralis.qcx.relatorios.label')}
      dialogActionsProps={{
        button: {
          submit: {
            description: t('com.muralis.qcx.acoes.imprimir'),
            size: 'small',
            color: 'secondary',
          },
          cancel: {
            description: t('com.muralis.qcx.acoes.cancelar'),
            size: 'small',
          },
        },
      }}
      open={isFormDialogOpen}
      initialValues={initialValues}
      onSubmit={handleOnSubmit}
      onClose={handleCloseFormDialogNew}
    >
      {({ values, form }) => (
        <>
          <QCXFormListenerManager form={form} values={values} onChangeListeners={[]} />
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <QCXFinalRadioField
              id="checkbox-tipo-relatorio-conciliacao-bancaria"
              key="checkbox-tipo-relatorio-conciliacao-bancaria"
              name="tipo"
              label={t('com.muralis.qcx.financeiro.conciliacaoBancaria')}
              value={ConciliacaoBancariaUtils.CONCILIACAO_BANCARIA}
              required
            />
          </Grid>
          <Grid
            container
            spacing={3}
            style={{
              margin: '10px 0',
            }}
            sm={12}
            xs={12}
            md={12}
            lg={12}
          >
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <QCXFinalDatePickerField
                id="date-picker-data-inicial-de-field"
                key="date-picker-data-inicial-de-field"
                name="data.dataInicial"
                label={t('com.muralis.qcx.dataInicial')}
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <QCXFinalDatePickerField
                id="date-picker-data-final-ate-field"
                key="date-picker-data-final-ate-field"
                name="data.dataFinal"
                label={t('com.muralis.qcx.dataFinal')}
                required
              />
            </Grid>
          </Grid>
        </>
      )}
    </QCXFinalFormDialog>
  );
}
