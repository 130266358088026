import React from 'react';
import TextField from '@material-ui/core/TextField';
import QCXRemaningCharacters from './QCXRemaningCharacters';

export default function QCXTextField({
  maxLength,
  visible,
  fullWidth = true,
  inputProps = {},
  debug = false,
  value,
  variant = 'outlined',
  retreat = true,
  size = 'small',
  helperText,
  remaningCharactersInfo = false,
  meta,
  disabled,
  ...restProps
}) {
  const isVisible = !!(visible || visible === undefined);

  return (
    <>
      {!debug && isVisible && (
        <>
          <TextField
            InputLabelProps={{
              shrink: retreat,
              style: {
                textTransform: 'uppercase',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              },
            }}
            fullWidth={fullWidth}
            inputProps={{ maxLength, ...inputProps }}
            value={value}
            variant={variant}
            size={size}
            helperText={helperText}
            disabled={disabled}
            {...restProps}
          />
          {(remaningCharactersInfo && maxLength && value?.length > -1) && (
            <QCXRemaningCharacters
              max={maxLength}
              current={value?.length}
              realignPosition={helperText && helperText?.length > 0}
              visible={!disabled}
            />
          )}
        </>
      )}
      {debug && isVisible && (
        <>
          <TextField
            InputLabelProps={{
              shrink: retreat,
              style: {
                textTransform: 'uppercase',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              },
            }}
            fullWidth={fullWidth}
            inputProps={{ maxLength, ...inputProps }}
            value={value}
            {...restProps}
            helperText={`DEBUG: ${value}`}
            variant={variant}
            disabled={disabled}
          />
        </>
      )}
    </>
  );
}
