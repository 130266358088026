import { createFilterOptions } from '@material-ui/lab/useAutocomplete';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchAllAsync,
  selectConfigControl,
  selectUnidadesdeMedida,
} from '../../features/unidade-medida/unidadeDeMedidaSlice';
import { isAutoLoadControl } from '../../utils/store/store-utils';
import QCXAutocompleteSelect from '../autocomplete-select/QCXAutocompleteSelect';
import useCurrentUnidadeNegocio from '../../utils/hooks/user/useCurrentUnidadeNegocio';

export default function QCXSelectUnidadeMedidaAutocomplete({ label, name, initialValues, ...restProps }) {
  const dispatch = useDispatch();
  const list = useSelector(selectUnidadesdeMedida);
  const configControl = useSelector(selectConfigControl);
  const currentUnidadeDeNegocio = useCurrentUnidadeNegocio();
  useEffect(() => {
    if (isAutoLoadControl(configControl)) {
      dispatch(fetchAllAsync());
    }
  }, [configControl]);

  const [defaultValue, setDefaultValue] = useState('');
  useEffect(() => {
    const value = list.find((item) => item?.id === _.get(initialValues, name));
    if (value) {
      setDefaultValue(value);
    } else {
      setDefaultValue({ id: null, description: '' });
    }
  }, [list, initialValues]);

  // ! Essa função não está sendo utilizada no componente. Por hora, vou comentá-la, mas no futuro, podemos removê-la.
  // const filterOptions = createFilterOptions({
  //   stringify: (item) => `${item?.code} - ${item?.description}`,
  // });

  const getOptionLabel = useCallback(
    (item) => {
      if (item?.aliases?.length > 0) {
        const findTenantAlias = item?.aliases?.find(
          (alias) => alias?.tennantUnidadeDeNegocio?.id === currentUnidadeDeNegocio?.id
        );
        if (findTenantAlias) {
          return `${item?.code} - ${findTenantAlias?.description}`;
        }
      }
      if (item?.code && item?.description) {
        return `${item?.code} - ${item?.description}`;
      }
      return '';
    },
    [list, currentUnidadeDeNegocio]
  );

  return (
    <QCXAutocompleteSelect
      label={label}
      id={name}
      name={name}
      options={list}
      // filterOptions={filterOptions}
      defaultValue={defaultValue}
      getOptionLabel={getOptionLabel}
      {...restProps}
    />
  );
}
