/* eslint-disable no-unused-vars */
import { Grid, makeStyles } from "@material-ui/core";
import { useState, useMemo, ReactNode } from "react";
import { Form } from "react-final-form";
import createDecorator from "final-form-calculate";
import QCXFocusLock from "../../../../../components/focus-lock/QCXFocusLock";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "inherit",
    padding: theme.spacing(2),
  },

  formroot: {
    width: "100%",
  },
}));

export interface BaseCreateFormProps {
  handleSubmit: () => void;
  initialValues: any;
  validate: any;
  fields: any;
  children: ReactNode | Function;
}

export default function BaseCreateForm({
  handleSubmit,
  initialValues = {},
  validate,
  fields,
  children,
  ...restProps
}: BaseCreateFormProps) {
  const classes = useStyles();

  const focusOnErrors = createDecorator();

  const decorators = useMemo(() => [focusOnErrors], [focusOnErrors]);

  return (
    <QCXFocusLock disabled={false}>
      <Form
        className={classes.root}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        decorators={decorators}
        validate={validate}
        {...restProps}
      >
        {({
          form,
          initialValues: initialFormValues,
          values,
          handleSubmit,
          pristine,
          submitting,
          submitSucceeded,
          ...restFinalFormProps
        }) => (
          <form onSubmit={handleSubmit} className={classes.formroot}>
            <Grid container spacing={2}>
              {typeof children === "function"
                ? children({
                    form,
                    initialValues: initialFormValues,
                    values,
                    pristine,
                    submitSucceeded,
                    submitting,
                    ...restFinalFormProps,
                  })
                : children}
            </Grid>
            <Grid
              container
              item
              xs={12}
              justify="flex-end"
              spacing={0}
              style={{ marginTop: "1rem" }}
            ></Grid>
          </form>
        )}
      </Form>
    </QCXFocusLock>
  );
}
