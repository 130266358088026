import { Grid, makeStyles } from '@material-ui/core';
import _, { isEmpty, isFunction } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectCatalogosProdutos } from '../../features/catalogo-produtos/catalogoProdutosSlice';
import { unidadeDeMedidaActions } from '../../features/unidade-medida/unidadeDeMedidaSlice';
import QCXFinalMultilineTextField from '../../shared-components/final-text-field/QCXFinalMultilineTextField';
import QCXFormStepWizard from '../../shared-components/form-step-wizard/QCXFormStepWizard';
import { unnormalizeNumeral } from '../../utils/general/general-utils';
import { forceParseToFixedNumber } from '../../utils/general/parse-utils';
import { formatBrazilianNumericDecimal } from '../../utils/hooks/form/field/formatters';
import QCXFormTitle from '../form-title/QCXFormTitle';
import useExternallyFilling from '../../utils/hooks/form/field/useExternallyFilling';
import { selectRelatedMercadoriaModel } from '../../features/fatura/faturaSelectors';
import { TIPO_FATURA, getPendenciasFaturaOcr } from '../../utils/general/fatura/faturaUtils';
import QCXFaturaAdicionaisFormGroup from './QCXFaturaAdicionaisFormGroup';
import QCXFaturaMercadoriaStandardFormGroup from './QCXFaturaMercadoriaStandardFormGroup';
import useFaturaMercadoriaListeners from '../../utils/general/fatura/faturaMercadoriaListeners';
import { getTipoCalculoValue } from '../../shared-components/select-forma-calculo/formaCalculoUtils';
import QCXCustomRegistrationTabControlTemplate from '../../templates/custom-registration-one-to-many-page/QCXCustomRegistrationTabControlTemplate';
import QCXFaturaForceTabReload from './QCXFaturaForceTabReload';
import QCXFormSubtitle from '../form-title/QCXFormSubtitle';
import QCXFinalAtributoNveBondManager from '../declaracao-importacao/QCXFinalAtributoNveBondManager';
import { nveActions } from '../../features/nve/nveSlice';
import { nveAtributoActions } from '../../features/nve-atributo/nveAtributoSlice';
import { nveEspecificacaoActions } from '../../features/nve-especificacao/nveEspecificacaoSlice';
import { ncmSubitemActions } from '../../features/ncm-subitem/ncmSubitemSlice';
import useCatalogoProdutosAtributosListeners from '../../utils/general/catalogo-produtos/catalogoProdutosAtributosListeners';

const useStyles = makeStyles((theme) => ({
  destaqueNcmFieldLeft: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: '16px',
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: '16px',
    },
  },
}));

export default function QCXFaturaMercadoriaForm({
  isIdle,
  isLoading,
  isPreparingAction,
  isCreate,
  isConsult,
  isUpdate,
  isSubNone,
  isSubCreate,
  isSubConsult,
  isSubUpdate,
  isBackgroundDelete,
  isSubBackgroundCreate,
  isSubBackgroundUpdate,
  isSubBackgroundDelete,
  faturaValues,
  initialValues,
  fatura,
  tipo = TIPO_FATURA,
  defaultItem,
  handleSubmit,
  handleNew,
  handleEdit,
  handleChangeToSubConsult,
  handleChangeModel,
  handleCancelCreate,
  handleCancelUpdate,
  handleChangeToConsult,
  handleRemoveByForm,
  handleRemoveByTable,
  handleClearForm,
  handleChangeToSubNone,
  handleChangeToUpdate,
  handleMetadataUpdate,
  handleLoadingStatus,
  handleResetStatus,
  controlComponentProps,
  tabManagerProps = {},
  formPaginationControlProps = {},
  buttonGroupOptions = {},
  ...restProps
}) {
  const { t } = useTranslation();

  const classes = useStyles();

  const [isFirstFormLoadValues, setIsFirstFormLoadValues] = useState(true);
  const [isFilling, subscribeToFilling] = useExternallyFilling();

  const [isQuantidade, setIsQuantidadeUnidade] = useState(false);
  const [isQuilograma, setIsQuilogramaUnidade] = useState(false);

  const unidadeMedidaList = useSelector(unidadeDeMedidaActions.selectUnidadesdeMedida);
  const listCatalogo = useSelector(selectCatalogosProdutos);
  const ncmList = useSelector(ncmSubitemActions.selectNcmSubitens);

  const mercadorias = useMemo(() => {
    const faturaMercadorias = _.cloneDeep(fatura?.mercadorias || []);
    const faturaPendencias = getPendenciasFaturaOcr(fatura);

    if (faturaPendencias !== null && faturaPendencias.temPendencias) {
      faturaMercadorias.forEach((mercadoria) => {
        const pendenciaMercadoria = faturaPendencias.mercadorias.find(
          (pendencia) => pendencia.idMercadoria === mercadoria.id
        );

        if (pendenciaMercadoria) {
          mercadoria.metadata = pendenciaMercadoria;
          mercadoria.pendencias = pendenciaMercadoria.pendencias;
        }
      });
    }

    return faturaMercadorias;
  }, [fatura]);

  useEffect(() => {
    if (isSubNone) {
      setIsFirstFormLoadValues(true);
    }
  }, [isSubNone]);

  const isDadosEmComumFornecedor = useMemo(() => faturaValues?.dadosComumFornecedor, [faturaValues]);

  const isSobrescreveDadosPartnumber = useMemo(() => faturaValues?.sobrescreveDadosPartnumber, [faturaValues]);

  const isFillCommonDataMode = useMemo(
    () => isSubCreate && isDadosEmComumFornecedor,
    [isSubCreate, isDadosEmComumFornecedor]
  );

  const internalInitialValues = useMemo(
    () =>
      isFillCommonDataMode
        ? {
            ...initialValues,
            partnumberAutocompleteSelector: initialValues?.partnumber,
            relacao: faturaValues?.relacao,
            exportador: faturaValues?.exportador?.id
              ? {
                  id: faturaValues?.exportador?.id,
                }
              : undefined,
            fabricante: faturaValues?.fabricante?.id
              ? {
                  id: faturaValues?.fabricante?.id,
                }
              : undefined,
            paisOrigem: faturaValues?.paisOrigem?.id
              ? {
                  id: faturaValues?.paisOrigem?.id,
                }
              : undefined,
          }
        : {
            partnumberAutocompleteSelector: initialValues?.partnumber,
            partNumberSelector: initialValues?.partnumber,
            ...initialValues,
          },
    [isFillCommonDataMode, initialValues, faturaValues]
  );

  const handleAlternativeSaveMercadoria = useCallback(() => {
    if (isEmpty(controlComponentProps) || !isFunction(controlComponentProps?.handleAlternativeSave)) {
      throw Error('Error: "handleAlternativeSave" is not a function.');
    }

    const { handleAlternativeSave } = controlComponentProps;
    const mercadoriaStepIndex = 3;

    handleAlternativeSave(null, mercadoriaStepIndex);
  }, [controlComponentProps]);

  const tableProps = useMemo(
    () => ({
      title: t('com.muralis.qcx.mercadoria.mercadoriasAdicionais'),
      columns: [
        {
          field: 'id',
          headerName: t('com.muralis.qcx.item.labelSingular'),
          headerAlign: 'center',
          align: 'center',
          flex: 90,
          valueGetter: ({ row }) => row?.item,
        },
        {
          field: 'partnumber',
          headerName: t('com.muralis.qcx.item.partNumber'),
          flex: 200,
          valueGetter: ({ row }) => row?.partnumber || t('com.muralis.qcx.validacao.naoPreenchido'),
        },
        {
          field: 'descricaoResumida',
          headerName: t('com.muralis.qcx.mercadoria.descricaoResumida'),
          flex: 300,
          valueGetter: ({ row }) => row?.descricaoResumida,
        },
        {
          field: 'quantidade',
          headerName: t('com.muralis.qcx.quantidade.label'),
          headerAlign: 'center',
          align: 'center',
          flex: 120,
          valueFormatter: ({ row: { quantidade } }) => unnormalizeNumeral(forceParseToFixedNumber(quantidade), 5),
        },
        {
          field: 'valorTotalMcvMoeda',
          headerName: t('com.muralis.qcx.mercadoria.totalMCV'),
          headerAlign: 'right',
          align: 'right',
          flex: 160,
          valueFormatter: ({ row: { valorTotalMcvMoeda } }) =>
            unnormalizeNumeral(valorTotalMcvMoeda, formatBrazilianNumericDecimal(2)) || '-',
        },
        {
          field: 'valorTotalMoeda',
          headerName: t('com.muralis.qcx.mercadoria.valorTotalItem'),
          headerAlign: 'right',
          align: 'right',
          flex: 180,
          valueFormatter: ({ row: { valorTotalMoeda } }) =>
            unnormalizeNumeral(valorTotalMoeda, formatBrazilianNumericDecimal(2)) || '-',
        },
      ],
    }),
    []
  );

  const localeTableText = useMemo(
    () => ({
      noRowsLabel: t('com.muralis.qcx.mercadoria.nenhumaMercadoriaEncontrada'),
    }),
    []
  );

  const currentTitleSubPage = useMemo(
    () =>
      isSubCreate ? t('com.muralis.qcx.mercadoria.novaMercadoria') : t('com.muralis.qcx.mercadoria.dadosMercadoria'),
    [isSubCreate]
  );

  const modes = useMemo(
    () => ({
      isConsult,
      isSubConsult,
      isSubNone,
      isSubCreate,
      isSubUpdate,
      isFillCommonDataMode,
    }),
    [isConsult, isSubConsult, isSubNone, isSubCreate, isSubUpdate, isFillCommonDataMode]
  );

  const formProps = useMemo(
    () => ({
      faturaValues,
      listCatalogo,
      unidadeMedidaList,
      isFirstFormLoadValues,
      setIsFirstFormLoadValues,
      rootName: 'adicionais',
    }),
    [faturaValues, listCatalogo, unidadeMedidaList, isFirstFormLoadValues, setIsFirstFormLoadValues]
  );

  const quantidadeEstatisticaProps = useMemo(
    () => ({
      isQuantidade,
      isQuilograma,
      setIsQuantidadeUnidade,
      setIsQuilogramaUnidade,
    }),
    [isQuantidade, isQuilograma, setIsQuantidadeUnidade, setIsQuilogramaUnidade]
  );

  const [onBlurObjectHandlers, changeListeners] = useFaturaMercadoriaListeners({
    modes,
    formProps,
    subscribeToFilling,
    isSobrescreveDadosPartnumber,
    quantidadeEstatisticaProps,
    getTipoCalculoValue,
  });

  const catalogoProdutoActions = useMemo(
    () => ({
      nveActions,
      nveAtributoActions,
      nveEspecificacaoActions,
    }),
    [nveActions, nveAtributoActions, nveEspecificacaoActions]
  );

  const catalogoProdutoFormProps = useMemo(
    () => ({
      ncmList,
    }),
    [ncmList]
  );

  const catalogoProdutosAtributosChangeListeners = useCatalogoProdutosAtributosListeners({
    rootName: 'ignorableFields.atributoNve',
    modes,
    actions: catalogoProdutoActions,
    formProps: catalogoProdutoFormProps,
  });

  changeListeners.push(...catalogoProdutosAtributosChangeListeners);

  return (
    <QCXFormStepWizard
      initialValues={internalInitialValues}
      onSubmit={handleSubmit}
      controlComponentProps={controlComponentProps}
      onChangeListeners={changeListeners}
      alternativeMode
      {...restProps}
    >
      {({ form, submitting, submitSucceeded }) => (
        <QCXCustomRegistrationTabControlTemplate
          initialValues={internalInitialValues}
          title={currentTitleSubPage}
          tableProps={tableProps}
          tableData={mercadorias}
          isPreparingAction={isPreparingAction}
          isIdle={isIdle}
          isLoading={isLoading}
          isCreate={isCreate}
          isConsult={isConsult}
          isUpdate={isUpdate}
          isSubNone={isSubNone}
          isSubCreate={isSubCreate}
          isSubConsult={isSubConsult}
          isSubUpdate={isSubUpdate}
          isBackgroundDelete={isBackgroundDelete}
          isSubBackgroundCreate={isSubBackgroundCreate}
          isSubBackgroundUpdate={isSubBackgroundUpdate}
          isSubBackgroundDelete={isSubBackgroundDelete}
          submitting={submitting}
          submitSucceeded={submitSucceeded}
          selectModel={selectRelatedMercadoriaModel}
          defaultItem={defaultItem}
          handleNew={handleNew}
          handleEdit={handleEdit}
          handleChangeToSubConsult={handleChangeToSubConsult}
          handleChangeModel={handleChangeModel}
          handleCancelCreate={handleCancelCreate}
          handleCancelUpdate={handleCancelUpdate}
          handleChangeToConsult={handleChangeToConsult}
          handleRemoveByForm={handleRemoveByForm}
          handleRemoveByTable={handleRemoveByTable}
          handleClearForm={handleClearForm}
          defaultRestartedValues={{
            partnumberAutocompleteSelector: '',
            partNumberSelector: '',
            partnumber: '',
            descricaoResumida: '',
            pesoLiquido: '',
            pesoLiquidoUnitario: '',
            valorUnitarioMoeda: '',
            destaqueNcm: '',
            adicionais: {
              numeroSerie: '',
              marca: '',
              modelo: '',
              dataValidade: '',
              anoFabricacao: '',
              lote: '',
              especificacao: '',
            },
            quantidadeEstatistica: '',
            quantidade: '',
            valorTotalMoeda: '',
            percentualDesconto: '',
            valorTotalMcvMoeda: '',
            tipoCalculo: 'QUANTIDADE_X_VALOR',
            icms: '',
          }}
          handleChangeToSubNone={handleChangeToSubNone}
          handleChangeToUpdate={handleChangeToUpdate}
          handleLoadingStatus={handleLoadingStatus}
          handleResetStatus={handleResetStatus}
          localeTableText={localeTableText}
          filterPropGetter={(row) => row?.partnumber}
          handleAlternativeSave={handleAlternativeSaveMercadoria}
          handleMetadataUpdate={handleMetadataUpdate}
          tabManagerProps={tabManagerProps}
          formPaginationControlProps={formPaginationControlProps}
          buttonGroupOptions={buttonGroupOptions}
          form={form}
        >
          {() => (
            <>
              <QCXFaturaForceTabReload />

              <QCXFaturaMercadoriaStandardFormGroup
                tipo={tipo}
                modes={modes}
                classes={classes}
                isFilling={isFilling}
                quantidadeEstatisticaProps={quantidadeEstatisticaProps}
                onBlurObjectHandlers={onBlurObjectHandlers}
                fatura={fatura}
              />

              <Grid item xs={12} sm={12} md={12} lg={12}>
                <QCXFormTitle title={t('com.muralis.qcx.adicionais')} />
              </Grid>
              <QCXFaturaAdicionaisFormGroup modes={modes} />

              {!(isConsult || isSubNone || isSubCreate) && (
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <QCXFinalMultilineTextField
                    id="text-especificacao-multiline-field"
                    key="text-especificacao-multiline-field"
                    name="adicionais.especificacaoCompleta"
                    label={t('com.muralis.qcx.especificacao.especificacaoCompletaMercadoria')}
                    disabled={isConsult || isSubConsult || isSubNone}
                    rows={5}
                    InputProps={{
                      readOnly: true,
                    }}
                    helperText={
                      !(isConsult || isSubNone || isSubConsult) && t('com.muralis.qcx.mensagem.campoHabilitadoLeitura')
                    }
                  />
                </Grid>
              )}

              <>
                <Grid item className={classes.subtitle} xs={12}>
                  <QCXFormSubtitle title={t('com.muralis.qcx.NVE.NVEatributos')} />
                </Grid>
                <QCXFinalAtributoNveBondManager
                  classes={classes}
                  modes={modes}
                  listProps={{
                    rootName: 'atributos',
                  }}
                />
              </>
            </>
          )}
        </QCXCustomRegistrationTabControlTemplate>
      )}
    </QCXFormStepWizard>
  );
}
