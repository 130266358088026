/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
import { Grid, makeStyles } from '@material-ui/core';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { OnChange } from 'react-final-form-listeners';
import QCXFormTitle from '../form-title/QCXFormTitle';
import QCXFinalCheckboxField from '../../shared-components/final-checkbox-field/QCXFinalCheckboxField';
import QCXFinalTextField from '../../shared-components/final-text-field/QCXFinalTextField';
import QCXFormStepWizard from '../../shared-components/form-step-wizard/QCXFormStepWizard';
import QCXSelectEnquadramentoLegalAutocomplete from '../../shared-components/select-enquadramento-legal/QCXSelectEnquadramentoLegalAutocomplete';
import QCXSelectModalidadeDrawbackAutocomplete from '../../shared-components/select-modalidade-drawback/QCXSelectModalidadeDrawbackAutocomplete';
import QCXSelectRegimeTributacaoAutocomplete from '../../shared-components/select-regime-tributacao/QCXSelectRegimeTributacaoAutocomplete';
import QCXSelectTipoAcordoTarifarioAutocomplete from '../../shared-components/select-tipo-acordo-tarifario/QCXSelectTipoAcordoTarifarioAutocomplete';
import QCXSelectAcordoAladiAutocomplete from '../../shared-components/select-acordo-aladi/QCXSelectAcordoAladiAutocomplete';
import QCXSelectTipoOperacaoAutocomplete from '../../shared-components/select-tipo-operacao/QCXSelectTipoOperacaoAutocomplete';
import QCXSelectCoberturaCambialAutocomplete from '../../shared-components/select-cobertura-cambial/QCXSelectCoberturaCambialAutocomplete';
import QCXSelectInstituicaoFinanciadoraAutocomplete from '../../shared-components/select-instituicao-financiadora/QCXSelectInstituicaoFinanciadoraAutocomplete';
import QCXSelectMotivoImportacaoSemCoberturaCambialAutocomplete from '../../shared-components/select-motivo-importacao-sem-cobertura-cambial/QCXSelectMotivoImportacaoSemCoberturaCambialAutocomplete';
import QCXSelectFundamentoLegalRegimeTributacaoIiAutocomplete from '../../shared-components/select-fundamento-legal-regime-tributacao-ii/QCXSelectFundamentoLegalRegimeTributacaoIiAutocomplete';
import { selectRegimesTributacao } from '../../features/regime-tributacao/regimeTributacaoSlice';
import { selectFundamentosLegaisRegimeTributacaoIi } from '../../features/fundamento-legal-regime-tributacao-ii/fundamentoLegalRegimeTributacaoIiSlice';
import {
  isModalidadeDrawbackIsencaoAcOptions,
  isModalidadeDrawbackSuspensaoOptions,
  isModalidadeDrawbackNaoTem,
} from '../../shared-components/select-modalidade-drawback/modalidadeDrawbackUtils';
import {
  REGIME_TRIBUTACAO_ISENCAO_CODE,
  REGIME_TRIBUTACAO_SUSPENSAO_CODE,
  configureRegimeTributacaoRecolhimentoIntegralMatcher,
} from '../../shared-components/select-regime-tributacao/regimeTributacaoUtils';
import {
  FUNDAMENTO_LEGAL_REGIME_TRB_II_DRAWBACK_CODE,
  FUNDAMENTO_LEGAL_REGIME_TRB_II_UNIAO_ESTADOS_CODE,
} from '../../shared-components/select-fundamento-legal-regime-tributacao-ii/fundamentoLegalRegimeTributacaoIiUtils';
import { isTipoAcordoTarifarioAladi } from '../../shared-components/select-tipo-acordo-tarifario/tipoAcordoTarifarioUtils';
import { isEnquadramentoLegalNacionalizacao } from '../../shared-components/select-enquadramento-legal/enquadramentoLegalUtils';
import QCXSelectModalidadePagamentoAutocomplete from '../../shared-components/select-modalidade-pagamento/QCXSelectModalidadePagamentoAutocomplete';
import QCXFinalNumericIntegerField from '../../shared-components/final-numeric-integer-field/QCXFinalNumericIntegerField';
import {
  isCoberturaCambialComCoberturaAcima360Dias,
  isCoberturaCambialComCoberturaAte180Dias,
  isCoberturaCambialComCoberturaDe180A360Dias,
  isCoberturaCambialSemCobertura,
} from '../../shared-components/select-cobertura-cambial/coberturaCambialUtils';
import QCXFinalControlBoxVinculoProcessoOrgaoAnuente from '../../shared-components/vinculo-processo-orgao-anuente/QCXFinalControlBoxVinculoProcessoOrgaoAnuente';
import QCXMoveFocusInside from '../focus-lock/QCXMoveFocusInside';

const useStyles = makeStyles((theme) => ({
  gridFields: {
    paddingBottom: '16px',
  },
  checkboxGridField: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: '16px',
    },
    paddingBottom: '16px',
  },
}));

export default function QCXLicencaImportacaoBasicasForm({
  initialValues,
  handleSubmit,
  isConsult,
  isLocked,
  controlComponentProps,
  ...restProps
}) {
  const { t } = useTranslation();
  const classes = useStyles();

  const regimesTributacao = useSelector(selectRegimesTributacao);
  const fundamentosLegaisRegimeTributacaoIi = useSelector(selectFundamentosLegaisRegimeTributacaoIi);

  const isRegimeTributacaoRecolhimentoIntegral = useCallback(
    (value) => {
      const isRegimeTributacaoRecolhimentoIntegralMatcher =
        configureRegimeTributacaoRecolhimentoIntegralMatcher(regimesTributacao);

      return isRegimeTributacaoRecolhimentoIntegralMatcher(value);
    },
    [regimesTributacao]
  );

  const handleModalidadeDrawbackChange = (form) => (value) => {
    if (isModalidadeDrawbackSuspensaoOptions(value)) {
      const regimeTributacaoSuspensao = regimesTributacao.find(
        (regimeTributacao) => regimeTributacao?.code === REGIME_TRIBUTACAO_SUSPENSAO_CODE
      );

      form.change('atributosAdicionais.regimeTributacao', undefined);
      form.change('atributosAdicionais.regimeTributacao.id', regimeTributacaoSuspensao?.id);

      const fundamentoLegalDrawback = fundamentosLegaisRegimeTributacaoIi.find(
        (fundamentoLegal) => fundamentoLegal?.code === FUNDAMENTO_LEGAL_REGIME_TRB_II_DRAWBACK_CODE
      );

      form.change('atributosAdicionais.fundamentoLegalRegimeTributacaoIi', undefined);
      form.change('atributosAdicionais.fundamentoLegalRegimeTributacaoIi.id', fundamentoLegalDrawback?.id);

      return;
    }

    if (isModalidadeDrawbackIsencaoAcOptions(value)) {
      const regimeTributacaoIsencao = regimesTributacao.find(
        (regimeTributacao) => regimeTributacao?.code === REGIME_TRIBUTACAO_ISENCAO_CODE
      );

      form.change('atributosAdicionais.regimeTributacao', undefined);
      form.change('atributosAdicionais.regimeTributacao.id', regimeTributacaoIsencao?.id);

      const fundamentoLegalUniaoEstados = fundamentosLegaisRegimeTributacaoIi.find(
        (fundamentoLegal) => fundamentoLegal?.code === FUNDAMENTO_LEGAL_REGIME_TRB_II_UNIAO_ESTADOS_CODE
      );

      form.change('atributosAdicionais.fundamentoLegalRegimeTributacaoIi', undefined);
      form.change('atributosAdicionais.fundamentoLegalRegimeTributacaoIi.id', fundamentoLegalUniaoEstados?.id);

      return;
    }

    if (isModalidadeDrawbackNaoTem(value)) {
      form.change('atributosAdicionais.numeroAtoConcessorio', undefined);
    }
  };

  const handleRegimeTributacaoIdChange = (form) => (value) => {
    if (isRegimeTributacaoRecolhimentoIntegral(value)) {
      form.change('atributosAdicionais.fundamentoLegalRegimeTributacaoIi', undefined);
    }
  };

  const handleCoberturaCambialChange = (form) => (value) => {
    if (isCoberturaCambialSemCobertura(value)) {
      form.change('atributosAdicionais.motivoImportacaoSemCoberturaCambial', undefined);
      form.change('atributosAdicionais.instituicaoFinanciadora', undefined);
      form.change('atributosAdicionais.quantidadeDiasLimitePagamento', undefined);

      return;
    }

    if (isCoberturaCambialComCoberturaAte180Dias(value)) {
      form.change('atributosAdicionais.motivoImportacaoSemCoberturaCambial', undefined);
      form.change('atributosAdicionais.instituicaoFinanciadora', undefined);

      return;
    }

    if (isCoberturaCambialComCoberturaDe180A360Dias(value)) {
      form.change('atributosAdicionais.motivoImportacaoSemCoberturaCambial', undefined);
      form.change('atributosAdicionais.instituicaoFinanciadora', undefined);

      return;
    }

    if (isCoberturaCambialComCoberturaAcima360Dias(value)) {
      form.change('atributosAdicionais.motivoImportacaoSemCoberturaCambial', undefined);
      form.change('atributosAdicionais.modalidadePagamento', undefined);
      form.change('atributosAdicionais.quantidadeDiasLimitePagamento', undefined);
    }
  };

  const handleMaterialUsadoChange = (form) => (value) => {
    if (!value) {
      form.change('atributosAdicionais.enquadramentoLegal', undefined);
    }
  };

  const handleEnquadramentoLegalChange = (form) => (value) => {
    if (isEnquadramentoLegalNacionalizacao(value)) {
      form.change('atributosAdicionais.tipoOperacao', undefined);
    }
  };

  const listenerList = [
    {
      name: 'atributosAdicionais.modalidadeDrawback',
      fn: handleModalidadeDrawbackChange,
    },
    {
      name: 'atributosAdicionais.regimeTributacao.id',
      fn: handleRegimeTributacaoIdChange,
    },
    {
      name: 'atributosAdicionais.coberturaCambial',
      fn: handleCoberturaCambialChange,
    },
    {
      name: 'atributosAdicionais.materialUsado',
      fn: handleMaterialUsadoChange,
    },
    {
      name: 'atributosAdicionais.enquadramentoLegal',
      fn: handleEnquadramentoLegalChange,
    },
  ];

  const fieldsVinculoProcessoProps = useMemo(
    () => ({
      processo: {
        name: 'processo',
        label: t('com.muralis.qcx.processo'),
      },
      orgaoAnuente: {
        name: 'orgaoAnuente.id',
        label: t('com.muralis.qcx.orgao.orgaoAnuente'),
      },
    }),
    []
  );

  const listVinculoProcessoProps = useMemo(
    () => ({
      name: 'atributosAdicionais.vinculos',
    }),
    []
  );

  const ignorableFieldsVinculoProcessoProps = useMemo(
    () => ({
      rootName: 'ignorableFields',
      fields: ['processo', 'orgaoAnuente.id'],
    }),
    []
  );

  return (
    <QCXFormStepWizard
      initialValues={initialValues}
      onSubmit={handleSubmit}
      onChangeListeners={listenerList}
      controlComponentProps={controlComponentProps}
      disablePristine
      {...restProps}
    >
      {({ values, form }) => (
        <>
          <QCXFormTitle title={t('com.muralis.qcx.fichaBasicas')} />
          <Grid container spacing={2} style={{ marginTop: '1rem' }}>
            <Grid item container alignContent="flex-start" xs={12} sm={12} md={6} lg={6}>
              <Grid item className={classes.gridFields} xs={12} sm={12} md={6} lg={6}>
                <QCXMoveFocusInside>
                  <QCXFinalTextField
                    id="text-field-destaque-ncm"
                    key="text-field-destaque-ncm"
                    name="atributosAdicionais.destaqueNcm"
                    label={t('com.muralis.qcx.NCM.destaqueNCM')}
                    maxLength={3}
                    disabled={isConsult}
                  />
                </QCXMoveFocusInside>
              </Grid>
              <Grid item className={classes.checkboxGridField} xs={12} sm={12} md={6} lg={6}>
                <QCXFinalCheckboxField
                  id="checkbox-material-usado-field"
                  key="checkbox-material-usado-field"
                  name="atributosAdicionais.materialUsado"
                  label={t('com.muralis.qcx.materialUsado')}
                  disabled={isConsult}
                />
              </Grid>
              <Grid item className={classes.gridFields} xs={12} sm={12} md={12} lg={12}>
                <QCXSelectModalidadeDrawbackAutocomplete
                  id="autocomplete-select-modalidade-drawback-field"
                  key="autocomplete-select-modalidade-drawback-field"
                  name="atributosAdicionais.modalidadeDrawback"
                  label={t('com.muralis.qcx.modalidadeDrawback')}
                  initialValues={values}
                  disabled={isConsult}
                />
              </Grid>
              <Grid item className={classes.gridFields} xs={12} sm={12} md={12} lg={12}>
                <QCXSelectRegimeTributacaoAutocomplete
                  id="autocomplete-select-regime-tributacao-field"
                  key="autocomplete-select-regime-tributacao-field"
                  name="atributosAdicionais.regimeTributacao.id"
                  label={t('com.muralis.qcx.regimeTributacao')}
                  initialValues={values}
                  disabled={isConsult}
                />
              </Grid>
              {!isRegimeTributacaoRecolhimentoIntegral(values?.atributosAdicionais?.regimeTributacao?.id) &&
                !isModalidadeDrawbackNaoTem(values?.atributosAdicionais?.modalidadeDrawback) && (
                  <Grid item className={classes.gridFields} xs={12} sm={12} md={12} lg={12}>
                    <QCXSelectFundamentoLegalRegimeTributacaoIiAutocomplete
                      id="autocomplete-select-fundamento-legal-regime-tributacao-ii-field"
                      key="autocomplete-select-fundamento-legal-regime-tributacao-ii-field"
                      name="atributosAdicionais.fundamentoLegalRegimeTributacaoIi.id"
                      label={t('com.muralis.qcx.legislacao.fundamentoLegal')}
                      initialValues={values}
                      disabled={isConsult}
                    />
                  </Grid>
                )}
              <Grid item className={classes.gridFields} xs={12} sm={12} md={12} lg={12}>
                <QCXSelectTipoAcordoTarifarioAutocomplete
                  id="autocomplete-select-tipo-acordo-tarifario-field"
                  key="autocomplete-select-tipo-acordo-tarifario-field"
                  name="atributosAdicionais.tipoAcordoTarifario"
                  label={t('com.muralis.qcx.tarifa.tipoAcordoTarifario')}
                  initialValues={values}
                  disabled={isConsult}
                />
                <OnChange name="atributosAdicionais.tipoAcordoTarifario">
                  {(value) => {
                    if (value !== 'ALADI') {
                      form.change('atributosAdicionais.acordoAladi', undefined);
                    }
                  }}
                </OnChange>
              </Grid>
              {isTipoAcordoTarifarioAladi(values?.atributosAdicionais?.tipoAcordoTarifario) && (
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <QCXSelectAcordoAladiAutocomplete
                    id="autocomplete-select-acordo-aladi-field"
                    key="autocomplete-select-acordo-aladi-field"
                    name="atributosAdicionais.acordoAladi.id"
                    label={t('com.muralis.qcx.aladi.acordoALADI')}
                    initialValues={values}
                    disabled={isConsult}
                  />
                </Grid>
              )}
            </Grid>
            <Grid item container alignContent="flex-start" xs={12} sm={12} md={6} lg={6}>
              {values?.atributosAdicionais?.materialUsado && (
                <Grid item className={classes.gridFields} xs={12} sm={12} md={12} lg={12}>
                  <QCXSelectEnquadramentoLegalAutocomplete
                    id="autocomplete-select-enquadramento-legal-field"
                    key="autocomplete-select-enquadramento-legal-field"
                    name="atributosAdicionais.enquadramentoLegal"
                    label={t('com.muralis.qcx.enquadramentoLegal')}
                    initialValues={values}
                    disabled={isConsult}
                  />
                </Grid>
              )}
              {values?.atributosAdicionais?.materialUsado &&
                isEnquadramentoLegalNacionalizacao(values?.atributosAdicionais?.enquadramentoLegal) && (
                  <Grid item className={classes.gridFields} xs={12} sm={12} md={12} lg={12}>
                    <QCXSelectTipoOperacaoAutocomplete
                      id="autocomplete-select-tipo-operacao-field"
                      key="autocomplete-select-tipo-operacao-field"
                      name="atributosAdicionais.tipoOperacao"
                      label={t('com.muralis.qcx.tipoOperacao')}
                      initialValues={values}
                      disabled={isConsult}
                    />
                  </Grid>
                )}
              <Grid item className={classes.gridFields} xs={12} sm={12} md={12} lg={12}>
                <QCXSelectCoberturaCambialAutocomplete
                  id="autocomplete-select-cobertura-cambial-field"
                  key="autocomplete-select-cobertura-cambial-field"
                  name="atributosAdicionais.coberturaCambial"
                  label={t('com.muralis.qcx.coberturaCambial')}
                  initialValues={values}
                  disabled={isConsult}
                />
              </Grid>
              {(isCoberturaCambialComCoberturaAte180Dias(values?.atributosAdicionais?.coberturaCambial) ||
                isCoberturaCambialComCoberturaDe180A360Dias(values?.atributosAdicionais?.coberturaCambial)) && (
                <Grid item className={classes.gridFields} xs={12} sm={12} md={12} lg={12}>
                  <QCXSelectModalidadePagamentoAutocomplete
                    id="autocomplete-select-modalidade-pagamento-field"
                    key="autocomplete-select-modalidade-pagamento-field"
                    name="atributosAdicionais.modalidadePagamento.id"
                    label={t('com.muralis.qcx.modalidadePagamento')}
                    initialValues={values}
                    disabled={isConsult}
                  />
                </Grid>
              )}
              {isCoberturaCambialComCoberturaAte180Dias(values?.atributosAdicionais?.coberturaCambial) && (
                <Grid item className={classes.gridFields} xs={12} sm={12} md={12} lg={12}>
                  <QCXFinalNumericIntegerField
                    id="numeric-integer-field-quantidade-dias-limite-pagamento"
                    key="numeric-integer-field-quantidade-dias-limite-pagamento"
                    name="atributosAdicionais.quantidadeDiasLimitePagamento"
                    label={t('com.muralis.qcx.quantidadeDiasLimitePagamento')}
                    allowNegative={false}
                    disabled={isConsult}
                  />
                </Grid>
              )}
              {isCoberturaCambialComCoberturaAcima360Dias(values?.atributosAdicionais?.coberturaCambial) && (
                <Grid item className={classes.gridFields} xs={12} sm={12} md={12} lg={12}>
                  <QCXSelectInstituicaoFinanciadoraAutocomplete
                    id="autocomplete-select-instituicao-financiadora-field"
                    key="autocomplete-select-instituicao-financiadora-field"
                    name="atributosAdicionais.instituicaoFinanciadora.id"
                    label={t('com.muralis.qcx.instituicaoFinanceira')}
                    initialValues={values}
                    disabled={isConsult}
                  />
                </Grid>
              )}
              {isCoberturaCambialSemCobertura(values?.atributosAdicionais?.coberturaCambial) && (
                <Grid item className={classes.gridFields} xs={12} sm={12} md={12} lg={12}>
                  <QCXSelectMotivoImportacaoSemCoberturaCambialAutocomplete
                    id="autocomplete-select-motivo-importacao-sem-cobertura-cambial-field"
                    key="autocomplete-select-motivo-importacao-sem-cobertura-cambial-field"
                    name="atributosAdicionais.motivoImportacaoSemCoberturaCambial.id"
                    label={t('com.muralis.qcx.motivo')}
                    initialValues={values}
                    disabled={isConsult}
                  />
                </Grid>
              )}
              {!isModalidadeDrawbackNaoTem(values?.atributosAdicionais?.modalidadeDrawback) && (
                <Grid item className={classes.gridFields} xs={12} sm={12} md={12} lg={12}>
                  <QCXFinalTextField
                    id="text-field-destaque-ncm"
                    key="text-field-destaque-ncm"
                    name="atributosAdicionais.numeroAtoConcessorio"
                    label="Número do ROF"
                    maxLength={11}
                    disabled={isConsult}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
          <QCXFormTitle title={t('com.muralis.qcx.processosVinculados')} />
          <Grid container spacing={2} style={{ marginTop: '1rem' }}>
            <QCXFinalControlBoxVinculoProcessoOrgaoAnuente
              isConsult={isConsult}
              fieldProps={fieldsVinculoProcessoProps}
              listProps={listVinculoProcessoProps}
              ignorableFieldsProps={ignorableFieldsVinculoProcessoProps}
            />
          </Grid>
        </>
      )}
    </QCXFormStepWizard>
  );
}
