import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
  Label as LabelIcon,
  DynamicFeed as DynamicFeedIcon,
  AttachMoney,
  Receipt as ReceiptIcon,
  Assignment as AssignmentIcon,
  Description as DescriptionIcon,
  Web as WebIcon,
  FileCopy,
} from '@material-ui/icons';
import { Calculate as CalculateIcon } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import QCXMainLayout from '../../../components/main-layout/QCXMainLayout';
import QCXCardMenu from '../../../shared-components/card-menu/QCXCardMenu';
import { IMPORTACAO_MODULE } from '../../../utils/general/modules';
import { focusLockActions } from '../../../features/focus-lock/focusLockSlice';
import QCXFocusLock from '../../../components/focus-lock/QCXFocusLock';
import NumerarioImportacaoUtils from '../../../utils/general/numerario-importacao/NumerarioImportacaoUtils';

const useStyles = makeStyles((theme) => ({
  cardMenuContainer: {
    marginTop: theme.spacing(0),
  },
  cardMenu: {
    width: theme.spacing(100),
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  subNested: {
    paddingLeft: theme.spacing(8),
  },
  active: {
    display: 'flex!important',
  },
  noActive: {
    display: 'none!important',
    transformOrigin: '0 0 0!important',
  },
}));

export default function ImportPage({ authInfo = {} }) {
  const { t } = useTranslation();
  const classes = useStyles();

  const dispatch = useDispatch();
  const userHasAnyRole = (mainRoles = []) =>
    authInfo.roles.some((role) => mainRoles.find((item) => role.startsWith(item)));
  const {
    location: { pathname: currentLocationPath },
  } = useHistory();

  useEffect(() => {
    const handleFocusLock = () => {
      const currentPaths = currentLocationPath.split('/');

      const match = (modulePaths) => modulePaths.every((path) => currentPaths.includes(path));

      if (match(IMPORTACAO_MODULE)) {
        dispatch(focusLockActions.lock());
      }
    };

    handleFocusLock();
  }, [currentLocationPath]);

  const breadcrumbs = [
    {
      link: {
        to: '/',
        name: t('com.muralis.qcx.inicio'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.importacao.label'),
      },
    },
  ];

  return (
    <QCXMainLayout
      pageIcon={<LabelIcon />}
      pageTitle={t('com.muralis.qcx.importacao.label')}
      breadcrumbs={breadcrumbs}
      authInfo={authInfo}
    >
      <Grid className={classes.cardMenuContainer} item container justify="center" xs={12} sm={12} md={12} xl={12}>
        <QCXFocusLock>
          <Grid container style={{ maxWidth: '1920px' }}>
            {userHasAnyRole(['importacao-follow-up']) && (
              <Grid item justify="center" xs={6} sm={4} md={3} lg={3} xl={2} className={classes.active}>
                <QCXCardMenu
                  title={t('com.muralis.qcx.followup.label')}
                  link={t('com.muralis.qcx.url.importacaoFollowUp')}
                  icon={DynamicFeedIcon}
                />
              </Grid>
            )}

            {userHasAnyRole(['importacao-follow-up']) && (
              <Grid item justify="center" xs={6} sm={4} md={3} lg={3} xl={2} className={classes.active}>
                <QCXCardMenu
                  title={t('com.muralis.qcx.transferenciaLote.label')}
                  link={t('com.muralis.qcx.url.importacaoTransferenciaLote')}
                  icon={FileCopy}
                />
              </Grid>
            )}

            {userHasAnyRole(['importacao-dados-embarque']) && (
              <Grid item justify="center" xs={6} sm={4} md={3} lg={3} xl={2} className={classes.active}>
                <QCXCardMenu
                  title={t('com.muralis.qcx.dadosEmbarque.label')}
                  link={t('com.muralis.qcx.url.dadosEmbarque')}
                  icon={DynamicFeedIcon}
                />
              </Grid>
            )}

            {userHasAnyRole(['importacao-fatura']) && (
              <Grid item justify="center" xs={6} sm={4} md={3} lg={3} xl={2} className={classes.active}>
                <QCXCardMenu
                  title={t('com.muralis.qcx.fatura.labelPlural')}
                  link={t('com.muralis.qcx.url.importacaoFaturas')}
                  icon={ReceiptIcon}
                />
              </Grid>
            )}

            {userHasAnyRole(['importacao-li-lpco']) && (
              <Grid item justify="center" xs={6} sm={4} md={3} lg={3} xl={2} className={classes.active}>
                <QCXCardMenu
                  title={t('com.muralis.qcx.licencasImportacaoLI')}
                  link={t('com.muralis.qcx.url.importacaoLicencaImportacao')}
                  icon={AssignmentIcon}
                />
              </Grid>
            )}

            {userHasAnyRole(['importacao-di-duimp']) && (
              <Grid item justify="center" xs={6} sm={4} md={3} lg={3} xl={2} className={classes.active}>
                <QCXCardMenu
                  title={t('com.muralis.qcx.DIDUIMP.declaracoesImportacaoDIDUIMP')}
                  link={t('com.muralis.qcx.url.importacaoDeclaracoesImportacao')}
                  icon={DescriptionIcon}
                />
              </Grid>
            )}
            {userHasAnyRole(['danfe']) && (
              <Grid item justify="center" xs={6} sm={4} md={3} lg={3} xl={2} className={classes.active}>
                <QCXCardMenu
                  title={t('com.muralis.qcx.DANFE.label')}
                  link={t('com.muralis.qcx.url.importacaoDANFE')}
                  icon={WebIcon}
                />
              </Grid>
            )}
            {userHasAnyRole([NumerarioImportacaoUtils.ROLE]) && (
              <Grid item justify="center" xs={6} sm={4} md={3} lg={3} xl={2} className={classes.active}>
                <QCXCardMenu
                  title={t('com.muralis.qcx.numerarioImportacao.labelPlural')}
                  link={t('com.muralis.qcx.url.importacaoSolicitacaoNumerario')}
                  icon={CalculateIcon}
                />
              </Grid>
            )}
            {userHasAnyRole(['importacao-custeio']) && (
              <Grid item justify="center" xs={6} sm={4} md={3} lg={3} xl={2} className={classes.active}>
                <QCXCardMenu
                  title={t('com.muralis.qcx.custeios.labelPlural')}
                  link={t('com.muralis.qcx.url.importacaoCusteios')}
                  icon={AttachMoney}
                />
              </Grid>
            )}
          </Grid>
        </QCXFocusLock>
      </Grid>
    </QCXMainLayout>
  );
}
