import {
  Card,
  CardContent,
  Grid,
  Grow,
  Typography,
  useTheme,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import { CardsGridItem } from './cardsGrid';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const CardsGridSubmenu = ({
  cover,
  items,
}: {
  cover: CardsGridItem;
  items: CardsGridItem[];
}) => {
  const theme = useTheme();
  const history = useHistory();

  const handleNavigation = (newPath: string | undefined) => {
    if (newPath) {
      history.push(newPath);
    }
  };

  return (
    <Grid container spacing={{ xs: 2, md: 3 }} padding={5}>
      <Grid item xs={12} sm={4} md={3} style={{ maxWidth: '250px' }}>
        <Grow mountOnEnter timeout={500} in>
          <Card
            variant="outlined"
            style={{
              border: `1px solid ${theme.palette.secondary.main}`,
              backgroundColor: theme.palette.primary.main,
              textAlign: 'center',
              padding: '20px 0px',
              cursor: 'pointer',
              height: '200px',
            }}
            sx={{ boxShadow: 3 }}
            onClick={() => handleNavigation(cover.path)}
          >
            <CardContent>
              <>
                <cover.icon
                  color={'secondary'}
                  sx={{ fontSize: 47 }}
                ></cover.icon>
                <Typography fontSize={12} color={'secondary'}></Typography>
                <ExpandLessIcon color={'secondary'} sx={{ fontSize: 32 }} />
              </>
            </CardContent>
          </Card>
        </Grow>
      </Grid>
      {items.map((item, index) => (
        <Grid
          item
          xs={12}
          sm={4}
          md={3}
          key={index}
          style={{ maxWidth: '330px' }}
        >
          <Grow mountOnEnter timeout={500} in>
            <Card
              variant="outlined"
              style={{
                border: `1px solid ${
                  item.disabled ? 'gray' : theme.palette.secondary.main
                }`,
                textAlign: 'center',
                padding: '20px 0px',
                cursor: 'pointer',
                height: '150px',
              }}
              sx={{ boxShadow: 3 }}
              onClick={() => handleNavigation(item.path)}
            >
              <CardContent>
                <>
                  <item.icon
                    color={item.disabled ? 'disabled' : 'secondary'}
                    sx={{ fontSize: 47 }}
                  ></item.icon>
                  <Typography fontSize={12}>
                    {item.title.toUpperCase()}
                  </Typography>
                </>
              </CardContent>
            </Card>
          </Grow>
        </Grid>
      ))}
    </Grid>
  );
};

export default CardsGridSubmenu;
