import React from 'react';
import QCXAlert from './QCXAlert';

export default function QCXInfoAlert(props) {
  return (
    <QCXAlert
      {...props}
      severity="info"
    />
  );
}
