import { SingletonUtils } from '../general-utils';

const PUBLICA = 'PUBLICA';
const NAO_PUBLICA = 'NAO_PUBLICA';

const isNaoPublica = (value) => (
  NAO_PUBLICA === value
);

const isPublica = (value) => (
  PUBLICA === value
);

const parseFromSingletonList = (singletonList = []) => (
  SingletonUtils.fromList(
    isPublica,
    NAO_PUBLICA,
    singletonList
  )
);

const parseToSingletonList = (value) => (
  SingletonUtils.toList(
    isNaoPublica,
    value
  )
);

const EtapaUtils = {
  PUBLICA,
  NAO_PUBLICA,
  isNaoPublica,
  isPublica,
  parseFromSingletonList,
  parseToSingletonList,
};

export default EtapaUtils;
