import React from 'react';
import QCXMoedaMleFormGroup from '../../shared-components/moeda-mle-form-group/QCXMoedaMleFormGroup';
import QCXMoedaDescontoFormGroup from '../../shared-components/moeda-desconto-form-group/QCXMoedaDescontoFormGroup';
import QCXMoedaDespesaFormGroup from '../../shared-components/moeda-despesa-form-group/QCXMoedaDespesaFormGroup';
import QCXMoedaFreteFormGroup from '../../shared-components/moeda-frete-form-group/QCXMoedaFreteFormGroup';
import QCXMoedaSeguroFormGroup from '../../shared-components/moeda-seguro-form-group/QCXMoedaSeguroFormGroup';
import QCXDespesasAcessoriasFormGroup from '../../shared-components/despesas-acessorias-form-group/QCXDespesasAcessoriasFormGroup';
import QCXAfrmmTotaisFormGroup from '../../shared-components/afrmm-totais-form-group/QCXAfrmmTotaisFormGroup';

export default function QCXDeclaracaoImportacaoTotaisFormGroup({
  intl,
  classes,
  isConsult,
  valorValidators,
  submitSucceeded,
  onBlurObjectHandlers,
  initialValues,
}) {
  return (
    <>
      <QCXMoedaMleFormGroup
        isConsult={isConsult}
        handleValorNaMoedaOnBlur={onBlurObjectHandlers?.handleValorNaMoedaOnBlur}
        valorValidators={valorValidators}
        submitSucceeded={submitSucceeded}
        intl={intl}
        required={false}
      />
      <QCXMoedaDescontoFormGroup
        isConsult={isConsult}
        handleValorNaMoedaOnBlur={onBlurObjectHandlers?.handleValorNaMoedaOnBlur}
        valorValidators={valorValidators}
        submitSucceeded={submitSucceeded}
        intl={intl}
        required={false}
      />
      <QCXMoedaDespesaFormGroup
        isConsult={isConsult}
        handleValorNaMoedaOnBlur={onBlurObjectHandlers?.handleValorNaMoedaOnBlur}
        valorValidators={valorValidators}
        intl={intl}
      />
      <QCXMoedaFreteFormGroup
        isConsult={isConsult}
        handleValorNaMoedaOnBlur={onBlurObjectHandlers?.handleValorNaMoedaOnBlur}
        valorValidators={valorValidators}
        intl={intl}
        classes={classes}
      />
      <QCXMoedaSeguroFormGroup
        isConsult={isConsult}
        handleValorNaMoedaOnBlur={onBlurObjectHandlers?.handleValorNaMoedaOnBlur}
        handleValorNaMoedaSeguro={onBlurObjectHandlers?.handleValorNaMoedaSeguro}
        valorValidators={valorValidators}
        intl={intl}
      />
      <QCXDespesasAcessoriasFormGroup
        isConsult={isConsult}
        handleValorEmReaisOnBlur={onBlurObjectHandlers?.handleValorEmReaisOnBlur}
        valorValidators={valorValidators}
        intl={intl}
        classes={classes}
      />
      {initialValues?.atributosAdicionais?.transporte?.viaTransporte?.id === 202202 && (
        <QCXAfrmmTotaisFormGroup
          isConsult={isConsult}
          handleAFRMMValorEmReaisOnBlur={onBlurObjectHandlers?.handleAFRMMValorEmReaisOnBlur}
          intl={intl}
          valorValidators={valorValidators}
          classes={classes}
        />
      )}
    </>
  );
}
