import { createFilterOptions } from '@material-ui/lab/useAutocomplete';
import _ from 'lodash';
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import QCXAutocompleteSelect from '../autocomplete-select/QCXAutocompleteSelect';
import i18n from '../../i18n';

export default function QCXFinalSelectTipoPessoaAlternativaAutocomplete({ label, name, initialValues, ...restProps }) {
  const [defaultValue, setDefaultValue] = useState('');

  const list = useMemo(
    () => [
      {
        label: i18n.t('com.muralis.qcx.tipoPessoa.pessoaJuridica'),
        value: 'PESSOA_JURIDICA',
      },
      {
        label: i18n.t('com.muralis.qcx.tipoPessoa.pessoaFisica'),
        value: 'PESSOA_FISICA',
      },
      // {
      //   label: i18n.t('com.muralis.qcx.fisicasDomiciliadasExterior'),
      //   value: 'FISICAS_DOMICILIADAS_NO_EXTERIOR',
      // },
      // {
      //   label: i18n.t('com.muralis.qcx.orgao.orgaosDiplomaticasRepresentantesOrganismoInternacionais'),
      //   value: 'ORGAOS_DIPLOMATICOS_E_REPRESENTANTES_DE_ORGANISMOS_INTERNACIONAIS',
      // },
    ],
    []
  );

  useEffect(() => {
    const defaultInitialValue = _.get(initialValues, name);

    const option = list.find((item) => item?.value === defaultInitialValue);

    if (option) {
      setDefaultValue(option);
    } else {
      setDefaultValue('');
    }
  }, [list, initialValues]);

  const filterOptions = useMemo(
    () =>
      createFilterOptions({
        stringify: (option) => option.label + option.value,
      }),
    []
  );

  const getOptionLabel = useCallback((item) => item?.label || '', []);

  const getOptionValue = useCallback((item) => item?.value || '', []);

  return (
    <QCXAutocompleteSelect
      label={label}
      id={name}
      name={name}
      options={list}
      filterOptions={filterOptions}
      defaultValue={defaultValue}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      {...restProps}
    />
  );
}
