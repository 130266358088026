import { useMemo } from "react";
import { UseMutateFunction } from "react-query";
import {
  useGetRequest,
  QueriesTypes,
  usePutRequest,
} from "../../../../common/hooks/queries";

export interface ValorHonorario {
  id: number;
  nome: string;
  nomeValor: string;
  data: Date;
  cliente: string;
  valor: number;
  propostaId: number;
  clienteId: number;
}

export const useListValores = ({
  startDate,
  endDate,
  isGlobal,
}: {
  startDate: Date;
  endDate: Date;
  isGlobal: boolean;
}): [ValorHonorario[], boolean, boolean] => {
  const {
    data: valoresData,
    isError,
    isLoading,
  } = useGetRequest(
    QueriesTypes.Indices,
    undefined,
    `dataDeInicio=${startDate.toString()}&dataDeFim=${endDate.toString()}&isGlobal=${
      isGlobal ? "true" : "false"
    }`
  );

  const valores: ValorHonorario[] = useMemo(() => {
    if (valoresData && !isError) {
      return valoresData;
    } else {
      return [];
    }
  }, [valoresData, isError]);

  return [valores, isLoading, isError];
};

export const useApplyIndices = (): [
  UseMutateFunction<any, unknown, { [key: string]: any }>,
  boolean,
  boolean
] => {
  const { isLoading, isError, mutate } = usePutRequest(QueriesTypes.Indices);

  return [mutate, isLoading, isError];
};

export const useListCondicionaisValores = ({
  startDate,
  endDate,
  isGlobal,
}: {
  startDate: Date;
  endDate: Date;
  isGlobal: boolean;
}): [ValorHonorario[], boolean, boolean] => {
  const {
    data: valoresData,
    isError,
    isLoading,
  } = useGetRequest(
    QueriesTypes.Indices,
    undefined,
    `isConditional=true&dataDeInicio=${startDate.toString()}&dataDeFim=${endDate.toString()}&isGlobal=${
      isGlobal ? "true" : "false"
    }`
  );

  const valores: ValorHonorario[] = useMemo(() => {
    if (valoresData && !isError) {
      return valoresData;
    } else {
      return [];
    }
  }, [valoresData, isError]);

  return [valores, isLoading, isError];
};
