import React from 'react';
import QCXSnackbar from './QCXSnackbar';

export default function QCXErrorSnackbar({
  open, content, handleClose, ...restProps
}) {
  return (
    <QCXSnackbar
      open={open}
      onClose={handleClose}
      content={content}
      severity="error"
      {...restProps}
    />
  );
}
