import BasicLayout from '../../common/layouts/basicLayout';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import CardsGrid, { CardsGridItem } from '../../common/components/cardsGrid/cardsGrid';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';

const gridItems: CardsGridItem[] = [
  {
    title: 'Propostas',
    icon: HistoryEduIcon,
    path: '/comercial/propostas',
    requiredRoles: ['propostas-visualizar'],
  },
];

const ComercialPage = () => {
  return (
    <BasicLayout title={'Comercial'} icon={<MonetizationOnIcon color={'secondary'} />}>
      <CardsGrid items={gridItems}></CardsGrid>
    </BasicLayout>
  );
};

export default ComercialPage;
