import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import {
  Box, Grow, ListItem, Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  listItem: {
    boxShadow: `0px 4px 4px -2px rgb(0 0 0 / 20%),
  0px 4px 5px 0px rgb(0 0 0 / 14%), 4px 2px 9px 2px rgb(0 0 0 / 12%)!important`,
    background: '#ffffff!important',
    height: '170px!important',
    textAlign: 'center!important',
    borderRadius: '5px!important',
    margin: '8px!important',
    border: '1px solid #f39200!important',
    transition: 'all 0.3s ease!important',
    fontWeight: 800,
    display: 'flex!important',
    flexDirection: 'column!important',
    justifyContent: 'center!important',
    alignItems: 'center!important',
  },
  iconMenu: {
    color: '#f39200 !important',
    width: '60px!important',
    height: '60px!important',
  },
  disabledIconMenu: {
    color: '#f392006e !important',
    width: '60px!important',
    height: '60px!important',
  },
  expandMoreIcon: {
    color: '#f39200!important',
    width: '34px!important',
    height: '34px!important',
  },
  listItemText: {
    fontSize: '12px!important',
    textTransform: 'uppercase!important',
    fontWeight: 500,
    lineHeight: 1.2,
    fontFamily: 'Arial!important',
    marginTop: theme.spacing(1),
  },
  disabledListItemText: {
    color: '#0000006e !important',
    fontSize: '12px!important',
    textTransform: 'uppercase!important',
    fontWeight: 500,
    lineHeight: 1.2,
    fontFamily: 'Arial!important',
    marginTop: theme.spacing(1),
  },
  activeListItem: {
    background: '#312783!important',
    height: '250px!important',
  },
  active: {
    display: 'flex!important',
  },
  noActive: {
    display: 'none!important',
    transformOrigin: '0 0 0!important',
  },
}));

export default function QCXCardMenu({
  title,
  link,
  icon,
  openMenu = false,
  isSubmenu,
  disabled = false,
  ...restProps
}) {
  const classes = useStyles();

  const key = useMemo(() => (
    title?.replaceAll(' ', '-').toLowerCase()
  ), [title]);

  const Icon = useMemo(() => icon, []);

  const style = useMemo(() => {
    if (isSubmenu) {
      return openMenu
        ? { display: 'flex', transformOrigin: '0 0 0' }
        : { display: 'none' };
    }

    return openMenu
      ? { display: 'none', transformOrigin: '0 0 0' }
      : { display: 'flex' };
  }, [
    openMenu,
    isSubmenu,
  ]);

  return (
    <Grow
      appear={isSubmenu}
      in={isSubmenu ? openMenu : !openMenu}
      style={style}
      timeout={500}
    >
      <ListItem
        button
        classes={{
          root: classes.listItem,
        }}
        key={key}
        component={Link}
        to={link}
        disabled={disabled}
        {...restProps}
      >
        <Box>
          <Icon
            {...(
              disabled
                ? {
                  className: classes.disabledIconMenu,
                  classes: {
                    root: classes.disabledIconMenu,
                  },
                } : {
                  className: classes.iconMenu,
                  classes: {
                    root: classes.iconMenu,
                  },
                }
            )}
          />
        </Box>
        <Typography
          classes={{
            root: disabled
              ? classes.disabledListItemText
              : classes.listItemText,
          }}
        >
          {title}
        </Typography>
      </ListItem>
    </Grow>
  );
}
