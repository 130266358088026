import React from 'react';
import QCXInputAdornment from '../input-adornment/QCXInputAdornment';
import QCXTextField from './QCXTextField';
import QCXCircularProgress from '../progress/QCXCircularProgress';
import i18n from '../../i18n';

export default function QCXReadOnlyTextField({
  key,
  id,
  label,
  value,
  defaultValue,
  reason = i18n.t('com.muralis.qcx.campoHabilitadoLeitura'),
  loading = false,
  disableHelperText = false,
  disabled,
  InputProps = {},
  ...restProps
}) {
  return (
    <QCXTextField
      key={key}
      id={id}
      label={label}
      value={value}
      InputProps={{
        ...InputProps,
        readOnly: true,
        ...(loading
          ? {
            endAdornment: (
              <QCXInputAdornment
                position="end"
                style={{ paddingRight: '12px' }}
              >
                <QCXCircularProgress
                  color="disabled"
                  size={20}
                />
              </QCXInputAdornment>
            ),
          } : {}),
      }}
      defaultValue={defaultValue}
      helperText={!disableHelperText && reason}
      disabled={disabled}
      {...restProps}
    />
  );
}
