import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AUTO_LOAD_CONTROL } from '../config-control';
import {
  BACKGROUND_CREATE_MODE,
  CONSULT_MODE,
  CREATE_MODE,
  NONE_BACKGROUND_MODE,
  NONE_MODE,
  NONE_SUB_MODE,
  SUB_CONSULT_MODE,
  SUB_CREATE_MODE,
  SUB_UPDATE_MODE,
  UPDATE_MODE,
} from '../mode';
import {
  ALTERNATIVE_LOADING_STATUS,
  FAILURE_STATUS,
  IDLE_STATUS,
  LOADING_STATUS,
  PREPARING_ACTION_STATUS,
  SUCCESS_STATUS,
} from '../status';
import {
  activateById,
  fetchAll,
  fetchById,
  inactivateById,
  fetchByFilter,
} from './transportadorInternacionalAPI';
import i18n from '../../i18n';

const initialState = {
  status: IDLE_STATUS,
  mode: {
    main: NONE_MODE,
    background: NONE_BACKGROUND_MODE,
    sub: NONE_SUB_MODE,
  },
  error: null,
  response: {
    status: null,
    message: null,
    data: null,
  },
  config: { control: AUTO_LOAD_CONTROL },
  list: [],
  model: {
    code: null,
    description: null,
    percentuais: null,
    insertionDate: null,
    active: null,
    endereco: {
      cep: null,
      logradouro: null,
      numero: null,
      bairro: null,
      cidade: {
        id: null,
      },
    },
    pessoaJuridica: {
      nome: null,
      insertionDate: null,
      nomeResumido: null,
      cnpj: null,
    },
  },
};

const fetchAllAsync = createAsyncThunk(
  'transportadorInternacional/fetchAll',
  async () => {
    const { data } = await fetchAll();
    return { data };
  }
);

const fetchByFilterAsync = createAsyncThunk(
  'transportadorInternacional/fetchByFilter',
  async (params) => {
    const { data } = await fetchByFilter(params);
    return { data };
  }
);

const fetchByIdAsync = createAsyncThunk(
  'transportadorInternacional/fetchById',
  async (data) => {
    const response = await fetchById(data);
    return {
      response: {
        status: response.status,
        data: response.data,
      },
    };
  }
);

const activateByIdAsync = createAsyncThunk(
  'transportadorInternacional/activateById',
  async (id) => {
    const { status, data } = await activateById(id);
    return { response: { status, data } };
  }
);

const inactivateByIdAsync = createAsyncThunk(
  'transportadorInternacional/inactivateById',
  async (id) => {
    const { status, data } = await inactivateById(id);
    return { response: { status, data } };
  }
);

const transportadorInternacionalSlice = createSlice({
  name: 'transportadorInternacional',
  initialState,
  reducers: {
    changeStatusTo: (state, action) => {
      state.status = action.payload.status;
    },
    loading: (state) => {
      state.status = LOADING_STATUS;
    },
    success: (state) => {
      state.status = SUCCESS_STATUS;
    },
    failure: (state) => {
      state.status = FAILURE_STATUS;
    },
    preparingAction: (state) => {
      state.status = PREPARING_ACTION_STATUS;
    },
    resetStatus: (state) => {
      state.status = IDLE_STATUS;
    },
    changeToCreateMode: (state) => {
      state.mode.main = CREATE_MODE;
    },
    changeToConsultMode: (state) => {
      state.mode.main = CONSULT_MODE;
    },
    changeToUpdateMode: (state) => {
      state.mode.main = UPDATE_MODE;
    },
    resetMode: (state) => {
      state.mode.main = NONE_MODE;
    },
    changeToBackgroundCreateMode: (state) => {
      state.mode.background = BACKGROUND_CREATE_MODE;
    },
    resetBackgroundMode: (state) => {
      state.mode.background = NONE_BACKGROUND_MODE;
    },
    changeToSubCreateMode: (state) => {
      state.mode.sub = SUB_CREATE_MODE;
    },
    changeToSubConsultMode: (state) => {
      state.mode.sub = SUB_CONSULT_MODE;
    },
    changeToSubUpdateMode: (state) => {
      state.mode.sub = SUB_UPDATE_MODE;
    },
    resetSubMode: (state) => {
      state.mode.sub = NONE_SUB_MODE;
    },
    setModel: (state, action) => {
      state.model = {
        ...action.payload,
      };
    },
    resetModel: (state) => {
      state.model = initialState.model;
    },
    addToList: (state, action) => {
      state.list = [...state.list, action.payload.data];
    },
    updateOnList: (state, action) => {
      state.list = state.list.map((current) => {
        if (current.id === action.payload.data?.id) {
          return action.payload.data;
        }
        return current;
      });
    },
    resetList: (state) => {
      state.list = [];
    },
    setResponse: (state, action) => {
      state.response = action.payload;
    },
    clearResponse: (state) => {
      state.response = {
        status: null,
        message: null,
        data: null,
      };
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    clearError: (state) => {
      state.error = initialState.error;
    },
    setConfig: (state, action) => {
      state.config = action.payload;
    },
    changeControlTo: (state, action) => {
      state.config.control = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllAsync.pending, (state) => {
        state.status = ALTERNATIVE_LOADING_STATUS;
      })
      .addCase(fetchAllAsync.fulfilled, (state, action) => {
        state.status = IDLE_STATUS;
        state.list = action.payload.data;
      })
      .addCase(fetchAllAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroCarregarListaTransportadoresInternacionais', { erro: action.error.message }),
        };
      })
      .addCase(fetchByFilterAsync.pending, (state) => {
        state.status = ALTERNATIVE_LOADING_STATUS;
      })
      .addCase(fetchByFilterAsync.fulfilled, (state, action) => {
        state.status = IDLE_STATUS;
        state.list = action.payload.data;
      })
      .addCase(fetchByFilterAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroCarregarListaTransportadoresInternacionais', { erro: action.error.message }),
        };
      })
      .addCase(fetchByIdAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(fetchByIdAsync.fulfilled, (state, action) => {
        if (action.payload.response.status === 200) {
          state.model = {
            ...action.payload.response.data,
          };
          state.status = SUCCESS_STATUS;
        }
      })
      .addCase(fetchByIdAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroCarregarTransportadorInternacional', { erro: action.error.message }),
        };
      })
      .addCase(activateByIdAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(activateByIdAsync.fulfilled, (state, action) => {
        state.status = SUCCESS_STATUS;
        state.response.status = action.payload.response.status;
        state.response.data = action.payload.response.data;

        const description = action.payload.response.data?.description;
        state.response.message = i18n.t('com.muralis.qcx.mensagem.transportadorInternacionalAtivado', { transportador: description });

        if (state.response.status === 200) {
          state.list = state.list
            .map((current) => (
              current.id === action.payload.response.data.id
                ? action.payload.response.data
                : current
            ));
        }
      })
      .addCase(activateByIdAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroAtivarTransportadorInternacional', { erro: action.error.message }),
        };
      })
      .addCase(inactivateByIdAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(inactivateByIdAsync.fulfilled, (state, action) => {
        state.status = SUCCESS_STATUS;
        state.response.status = action.payload.response.status;
        state.response.data = action.payload.response.data;

        const description = action.payload.response.data?.description;
        state.response.message = i18n.t('com.muralis.qcx.mensagem.transportadorInternacionalInativado', { transportador: description });

        if (state.response.status === 200) {
          state.list = state.list
            .map((current) => (
              current.id === action.payload.response.data.id
                ? action.payload.response.data
                : current
            ));
        }
      })
      .addCase(inactivateByIdAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroInativarTransportadorInternacional', { erro: action.error.message }),
        };
      });
  },
});

const {
  changeStatusTo,
  loading,
  success,
  failure,
  preparingAction,
  resetStatus,
  changeToCreateMode,
  changeToConsultMode,
  changeToUpdateMode,
  resetMode,
  changeToBackgroundCreateMode,
  resetBackgroundMode,
  changeToSubConsultMode,
  changeToSubUpdateMode,
  changeToSubCreateMode,
  resetSubMode,
  setModel,
  resetModel,
  addToList,
  updateOnList,
  resetList,
  setResponse,
  clearResponse,
  setError,
  clearError,
  setConfig,
  changeControlTo,
} = transportadorInternacionalSlice.actions;

const selectStatus = (state) => state.transportadorInternacional.status;
const selectMode = (state) => state.transportadorInternacional.mode.main;
const selectBackgroundMode = (state) => state.transportadorInternacional.mode.background;
const selectSubMode = (state) => state.transportadorInternacional.mode.sub;
const selectTransportadoresInternacionais = (state) => state.transportadorInternacional.list;
const selectAgentesTransportadores = (state) => (
  state.transportadorInternacional.list.filter((transportadorInternacional) => (
    transportadorInternacional?.agenteTransportador
  ))
);
const selectTransportadorInternacional = (state) => state.transportadorInternacional.model;
const selectError = (state) => state.transportadorInternacional.error;
const selectResponse = (state) => state.transportadorInternacional.response;
const selectConfigControl = (state) => state.transportadorInternacional.config.control;

export {
  transportadorInternacionalSlice,
  changeStatusTo,
  loading,
  success,
  failure,
  preparingAction,
  resetStatus,
  changeToCreateMode,
  changeToConsultMode,
  changeToUpdateMode,
  resetMode,
  changeToBackgroundCreateMode,
  resetBackgroundMode,
  changeToSubConsultMode,
  changeToSubUpdateMode,
  changeToSubCreateMode,
  resetSubMode,
  setModel,
  resetModel,
  addToList,
  updateOnList,
  resetList,
  setResponse,
  clearResponse,
  setError,
  clearError,
  setConfig,
  changeControlTo,
  fetchAllAsync,
  fetchByFilterAsync,
  fetchByIdAsync,
  activateByIdAsync,
  inactivateByIdAsync,
  selectStatus,
  selectMode,
  selectBackgroundMode,
  selectSubMode,
  selectTransportadorInternacional,
  selectTransportadoresInternacionais,
  selectAgentesTransportadores,
  selectError,
  selectResponse,
  selectConfigControl,
};

export default transportadorInternacionalSlice.reducer;
