import { useMemo } from "react";
import { useGetRequest, QueriesTypes } from "../../../../common/hooks/queries";
import { MercadoriaProposta } from "./mercadoriaProposta.type";
import { CadastroCover } from "./propostasCadastros.type";
import { ServicoCover } from "./propostaServicos.type";

export const useListPropostaMercadoria = (): [
  MercadoriaProposta[],
  boolean,
  boolean
] => {
  const {
    data: mercadoriasData,
    isError,
    isLoading,
  } = useGetRequest(QueriesTypes.MercadoriasProposta);

  const mercadorias: MercadoriaProposta[] = useMemo(() => {
    if (mercadoriasData && !isError) {
      return mercadoriasData;
    } else {
      return [];
    }
  }, [mercadoriasData, isError]);

  return [mercadorias, isLoading, isError];
};

export const useListAllCadastroProposta = (
  clienteId?: number | string
): [CadastroCover[], boolean, boolean] => {
  const {
    data: propostasCadastrosData,
    isError,
    isLoading,
  } = useGetRequest(
    QueriesTypes.CadastrosProposta,
    undefined,
    !!clienteId ? `clientId=${clienteId}` : undefined
  );

  const propostasCadastros: CadastroCover[] = useMemo(() => {
    if (propostasCadastrosData && !isError) {
      return propostasCadastrosData;
    } else {
      return [];
    }
  }, [propostasCadastrosData, isError]);

  return [propostasCadastros, isLoading, isError];
};

export const useListAllServicoProposta = (
  clientId?: number | string
): [ServicoCover[], boolean, boolean] => {
  const {
    data: propostasServicosData,
    isError,
    isLoading,
  } = useGetRequest(
    QueriesTypes.ServicoProposta,
    undefined,
    clientId !== 0 ? `clientId=${clientId}` : undefined
  );

  const propostasServicos: ServicoCover[] = useMemo(() => {
    if (propostasServicosData && !isError) {
      return propostasServicosData;
    } else {
      return [];
    }
  }, [propostasServicosData, isError]);

  return [propostasServicos, isLoading, isError];
};
