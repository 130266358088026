import React from 'react';
import logo from '../../../assets/images/logo-texto.svg';
import './styles.css';
import QCXCircularProgress from '../../../shared-components/progress/QCXCircularProgress';

export default function LoadingKeycloak() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <QCXCircularProgress
          color="primary"
        />
      </header>
    </div>
  );
}
