import { Grid } from '@material-ui/core';
import QCXFinalAlternativeCurrencyField from '../../../shared-components/final-currency-field/QCXFinalAlternativeCurrencyField';

// Não sabia o que o intl faz, então mantive
// Remova se você souber e não precisar
export default function MCVMoedaDUEFormGroup({
  intl,
}) {
  return (
    <>
      <Grid item xs={12} sm={12} md={4} lg={4} />
      <Grid item xs={12} sm={12} md={4} lg={4}>
        <QCXFinalAlternativeCurrencyField
          id="currency-valor-moeda-vmle-field"
          key="currency-valor-moeda-vmle-field"
          name="mle.valorMCVCapaMoeda"
          label="Valor Total MCV na Moeda"
          disabled
        />
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4}>
        <QCXFinalAlternativeCurrencyField
          id="currency-valor-real-moeda-vmle-field"
          key="currency-valor-real-moeda-vmle-field"
          name="mle.valorMCVCapaReal"
          label="Valor Total MCV em Reais"
          intl={intl}
          adorned
          disabled
        />
      </Grid>
    </>
  );
}
