import { createFilterOptions } from '@material-ui/lab/useAutocomplete';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useFormState } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { selectFollowUps } from '../../../../../../features/follow-up/followUpSelectors';
import { fetchAllAsync } from '../../../../../../features/follow-up/followUpThunks';
import QCXAutocompleteSelect from '../../../../../../shared-components/autocomplete-select/QCXAutocompleteSelect';
import { selectDeclaracoesImportacao } from '../../../../../../features/declaracao-importacao/declaracaoImportacaoSelectors';
import { setModel } from '../../../../../../features/declaracao-importacao/declaracaoImportacaoSlice';
import { fetchAllFromFaturaAsync as fetchAllDIs } from '../../../../../../features/declaracao-importacao/declaracaoImportacaoThunks';

export default function QCXSelectFollowUpImportacaoAutocompleteField({
  label,
  name,
  isCreate,
  disabled,
  ...restProps
}) {
  const dispatch = useDispatch();

  const list = useSelector(selectFollowUps);
  const dis = useSelector(selectDeclaracoesImportacao);

  const { initialValues } = useFormState();

  const [defaultValue, setDefaultValue] = useState('');

  useEffect(() => {
    dispatch(fetchAllAsync());
    dispatch(fetchAllDIs());
  }, []);

  const options = useMemo(() => {
    const followUps = dis?.map((di) => {
      if(di.atributosAdicionais.registro){
        const followUp = list.find(
          (fu) => fu?.numero === di.atributosAdicionais.processo
        )
        return followUp ? {id:followUp.id, followUp, di} : [];
      }else{
        return [];
      }
    });
    const flattenedFollowUps = followUps.flat();
    return flattenedFollowUps;
  }, [list, dis, isCreate]);

  useEffect(() => {
    const value = options.find((item) => (
      item?.id === _.get(initialValues, name)
    ));

    if (value) {
      setDefaultValue(value);
    } else {
      setDefaultValue({ id: null, description: '' });
    }
  }, [options, initialValues]);

  const filterOptions = createFilterOptions({
    stringify: (option) => (
      option?.numero || ''
    ),
  });

  return (
    <QCXAutocompleteSelect
      label={label}
      id={name}
      name={name}
      options={options}
      disabled={(isCreate !== undefined && !isCreate) || disabled}
      filterOptions={filterOptions}
      defaultValue={defaultValue}
      getOptionLabel={(item) => (
        item.followUp?.numero || ''
      )}
      onChange={(event, value) => {
        dispatch(setModel(value?.di));
      }}
      {...restProps}
    />
  );
}
