import { debounce } from 'lodash';
import { useCallback, useState } from 'react';

export default function useExternallyFilling(options = {
  default: false,
  timeout: 3000,
  automaticallyReset: false,
}) {
  const [isFilling, setIsFilling] = useState(options?.default || false);

  const subscribeToFilling = useCallback(() => {
    setIsFilling(true);

    const unsubscribeFromFilling = debounce(() => {
      setIsFilling(false);
    }, options?.timeout);

    if (options?.automaticallyReset) {
      unsubscribeFromFilling();
      return undefined;
    }

    return unsubscribeFromFilling;
  }, [
    options,
    setIsFilling,
  ]);

  return [isFilling, subscribeToFilling];
}
