import React, {
  useMemo,
} from 'react';
import {
  Grid,
  Grow,
} from '@material-ui/core';
import { Skeleton } from '@mui/material';
import usePaginatorActionState from '../../utils/hooks/paginator/usePaginatorActionState';
import QCXPaginator from './QCXPaginator';

export default function QCXTimelinePaginatorFragment({
  meta = {
    value: {},
    index: 0,
    array: [],
  },
  loading = false,
  children,
}) {
  const paginatorActionState = usePaginatorActionState();

  const computedKey = useMemo(() => (
    `transition-item-${meta?.value?.id}`
  ), [meta]);

  const calculatedTransitionTimeout = useMemo(() => (
    paginatorActionState.isNext()
      ? 200 + (meta.index * 150)
      : 150 + ((meta.array.length - meta.index) * 100)
  ), [
    meta,
    paginatorActionState,
  ]);

  const computedStyle = useMemo(() => ({
    transformOrigin: paginatorActionState.current,
  }), [paginatorActionState]);

  return (
    <QCXPaginator.Fragment>
      <Grow
        in
        key={computedKey}
        timeout={calculatedTransitionTimeout}
        style={computedStyle}
        unmountOnExit
      >
        <Grid
          item
        >
          {loading
            ? (
              <Skeleton
                variant="rectangular"
                animation="wave"
                width="100%"
                height="100%"
              />
            ) : (
              children
            )}
        </Grid>
      </Grow>
    </QCXPaginator.Fragment>
  );
}
