import {
  Error,
} from '@material-ui/icons';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import QCXFocusLock from '../../../components/focus-lock/QCXFocusLock';
import QCXMainLayout from '../../../components/main-layout/QCXMainLayout';
import { focusLockActions } from '../../../features/focus-lock/focusLockSlice';
import ErroSelecaoUnidadeDeNegocio from './ErroSelecaoUnidadeDeNegocio';
import UnauthorizedError from './UnauthorizedError';

export default function ErrorPage({ authInfo = {}, location }) {
  const { t } = useTranslation();
  const history = useHistory();
  const query = location.search;
  const params = new URLSearchParams(query);
  const error = params.get('e');

  const dispatch = useDispatch();
  useEffect(() => {
    let unmount = false;

    const handleFocusLock = () => {
      if (!unmount) {
        dispatch(focusLockActions.lock());
      }
    };

    handleFocusLock();

    return () => {
      unmount = true;
    };
  }, []);

  const handleClickReturn = useCallback(() => {
    history.push('/');
  }, [history]);

  const getErrorPage = (errorQuery) => {
    switch (errorQuery) {
      case '403': {
        return <UnauthorizedError handleClickReturn={handleClickReturn} />;
      }
      case 'unidadeDeNegocioNaoSelecionada': {
        return <ErroSelecaoUnidadeDeNegocio handleClickReturn={handleClickReturn} />;
      }
      default:
        return <p>  </p>;
    }
  };

  const breadcrumbs = [
    {
      link: {
        to: '/',
        name: t('com.muralis.qcx.inicio'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.erro.label'),
      },
    },
  ];

  return (
    <QCXMainLayout
      pageIcon={<Error />}
      pageTitle={t('com.muralis.qcx.erro.label')}
      breadcrumbs={breadcrumbs}
      authInfo={authInfo}
    >
      <QCXFocusLock>
        {getErrorPage(error)}
      </QCXFocusLock>
    </QCXMainLayout>
  );
}
