import { useEffect } from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  selectUnidadeSelecionada,
  selectRelatedUnidadeNegocioModel,
  fetchUnidadesNegocioByIdAsync,
} from '../../../features/usuario-logado/usuarioLogadoSlice';

export default function useCurrentUnidadeNegocio() {
  const dispatch = useDispatch();

  const selectedId = useSelector(selectUnidadeSelecionada);
  const selectedUnidadeNegocioModel = useSelector(selectRelatedUnidadeNegocioModel);

  useEffect(() => {
    if (selectedId) {
      dispatch(
        fetchUnidadesNegocioByIdAsync(
          selectedId
        )
      );
    }
  }, [selectedId]);

  return selectedUnidadeNegocioModel;
}
