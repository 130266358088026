import { validateQueryParams } from '../../../../../../utils/api/api-utils';
import {
  doGetQCXRequest,
  doPostQCXRequest,
  doPutQCXActivateRequest,
  doPutQCXInactivateRequest,
  doPutQCXRequest,
} from '../../../../../../utils/api/domain/domain-api-utils';
import { FILTER_ENDPOINT, DESVIO_ENDPOINT } from '../../../../../../utils/api/domain/domain-endpoint-utils';

export async function fetchAll() {
  return doGetQCXRequest([
    DESVIO_ENDPOINT
  ]);
}
export async function fetchByFilter(params = []) {
  const validatedParams = validateQueryParams(params);
  if (validatedParams.length) {
    return doGetQCXRequest([
      DESVIO_ENDPOINT, FILTER_ENDPOINT
    ], validatedParams);
  }
  return { data: [] };
}

export async function fetchById(id) {
  return doGetQCXRequest([
    DESVIO_ENDPOINT,
    id
  ]);
}

export async function register(data) {
  return doPostQCXRequest(
    [DESVIO_ENDPOINT],
    data
  );
}

export async function save(data) {
  return doPutQCXRequest(
    [DESVIO_ENDPOINT],
    data
  );
}

export async function activateById(id) {
  return doPutQCXActivateRequest(
    [DESVIO_ENDPOINT],
    id
  );
}

export async function inactivateById(id) {
  return doPutQCXInactivateRequest(
    [DESVIO_ENDPOINT],
    id
  );
}
