import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from '@material-ui/core';
import { useHistory, useParams } from 'react-router';
import debounce from 'lodash/debounce';
import { useDispatch, useSelector } from 'react-redux';
import { validate } from 'uuid';
import {
  Compare as CompareIcon,
} from '@material-ui/icons';
import QCXMainLayout from '../../../../../components/main-layout/QCXMainLayout';
import {
  addToList,
  changeToConsultMode,
  changeToUpdateMode,
  failure,
  fetchByIdAsync,
  loading,
  resetModel,
  selectMode,
  selectTabelaArmazenagem,
  setError,
  setModel,
  setResponse, success, updateOnList,
} from '../../../../../features/tabela-armazenagem/tabelaArmazenagemSlice';
import { register, save } from '../../../../../features/tabela-armazenagem/tabelaArmazenagemAPI';
import QCXTabelaArmazenagemWizardFinalForm from '../../../../../components/tabela-armazenagem/QCXTabelaArmazenagemWizardFinalForm';
import {
  isConsultMode,
  isCreateMode,
  isNoneMode,
  isUpdateMode,
} from '../../../../../utils/store/store-utils';
import { forceUnnormalizeNumeral, isValid, normalizeNumeral } from '../../../../../utils/general/general-utils';
import { formatBrazilianNumericDecimal } from '../../../../../utils/hooks/form/field/formatters';

export default function TabelaArmazenagemRegistrationPage({
  authInfo = {},
}) {
  const { t } = useTranslation();

  const { id } = useParams();

  const history = useHistory();

  const dispatch = useDispatch();

  const [isCreated, setIsCreated] = useState(false);

  const mode = useSelector(selectMode);
  const tabelaArmazenagem = useSelector(selectTabelaArmazenagem);

  const isNone = useMemo(() => (
    isNoneMode(mode)
  ), [mode]);

  const isCreate = useMemo(() => (
    isCreateMode(mode)
  ), [mode]);

  const isConsult = useMemo(() => (
    isConsultMode(mode)
  ), [mode]);

  const isUpdate = useMemo(() => (
    isUpdateMode(mode)
  ), [mode]);

  const handleChangeToConsult = () => {
    dispatch(changeToConsultMode());
  };

  const handleChangeToUpdate = () => {
    dispatch(changeToUpdateMode());
  };

  useEffect(() => {
    if (id) {
      dispatch(fetchByIdAsync(id));
      handleChangeToConsult();
    }
  }, [id]);

  const handleCancelUpdate = () => {
    dispatch(fetchByIdAsync(id));
    handleChangeToConsult();
  };

  const normalize = (unnormalizedData) => {
    const normalizedTecnicalData = {
      ...unnormalizedData,
      gris: normalizeNumeral(unnormalizedData?.gris),
      icms: normalizeNumeral(unnormalizedData?.icms),
      valorAdicionalCarretaLs: normalizeNumeral(
        unnormalizedData?.valorAdicionalCarretaLs
      ),
    };

    const unnormalizedModelosLancamento = tabelaArmazenagem?.modelosLancamento || [];
    const normalizedModelosLancamento = unnormalizedModelosLancamento
      ?.map((current) => {
        const isAnUUID = validate(current?.id);
        return isAnUUID ? {
          id: null,
          despesaReceita: current?.despesaReceita?.id
            ? current?.despesaReceita
            : null,
          description: current?.description,
          moeda: current?.moeda,
          tipoCalculo: current?.tipoCalculo,
          valor: normalizeNumeral(current?.valor),
          tipo: current?.tipo,
        } : current;
      });

    const unnormalizedPeriodos = tabelaArmazenagem?.periodos || [];
    const normalizedPeriodos = unnormalizedPeriodos
      ?.map((current) => {
        const isAnUUID = validate(current?.id);
        return isAnUUID ? {
          id: null,
          code: current?.code,
          periodo: normalizeNumeral(current?.periodo),
          porcentagem: normalizeNumeral(current?.porcentagem),
          quantidadeDias: normalizeNumeral(current?.quantidadeDias),
          active: true,
        } : current;
      });

    const unnormalizedClientes = tabelaArmazenagem?.clientes || [];

    const normalizedData = {
      ...normalizedTecnicalData,
      periodos: normalizedPeriodos,
      modelosLancamento: normalizedModelosLancamento,
      clientes: unnormalizedClientes,
    };

    return normalizedData;
  };

  const unnormalize = (normalizedData) => {
    const unnormalizedTecnicalData = {
      ...normalizedData,
      gris: isValid(normalizedData?.gris)
        ? forceUnnormalizeNumeral(
          normalizedData?.gris,
          formatBrazilianNumericDecimal(2)
        )
        : undefined,
      icms: isValid(normalizedData?.icms)
        ? forceUnnormalizeNumeral(
          normalizedData?.icms,
          formatBrazilianNumericDecimal(2)
        )
        : undefined,
      valorAdicionalCarretaLs: isValid(normalizedData?.valorAdicionalCarretaLs)
        ? forceUnnormalizeNumeral(
          normalizedData?.valorAdicionalCarretaLs,
          formatBrazilianNumericDecimal(2)
        )
        : undefined,
    };

    const unnormalizedData = {
      ...unnormalizedTecnicalData,
    };

    return unnormalizedData;
  };

  const handleDispatchSetModel = useCallback((data) => {
    dispatch(setModel(normalize(data)));
  }, [normalize]);

  const createByStep = async (data, step, next) => {
    const isFirstStep = step === 0;
    const isLastStep = step === 3;

    const executeDebounced = debounce(async () => {
      try {
        if (isFirstStep && !isCreated) {
          const response = await register(data);

          if (response?.status === 201) {
            next();
            dispatch(resetModel());
            dispatch(success());

            const created = response?.data;

            handleDispatchSetModel(created);
            dispatch(addToList({ data: created }));
            dispatch(fetchByIdAsync(created?.id));

            setIsCreated(true);
          }
        } else if (isLastStep) {
          const response = await save(data);

          if (response?.status === 200) {
            dispatch(resetModel());

            const handleResultWithDebounce = debounce(() => {
              history.push(t('com.muralis.qcx.url.moduloOperacionaisTabelaArmazenagem'));

              dispatch(success());

              const saved = response?.data;

              dispatch(setModel(saved));

              dispatch(setResponse({
                status: response.status,
                data: saved,
                message: t('com.muralis.qcx.mensagem.tabelaArmazenagemCadastrada', { tabela: saved?.description }),
              }));

              dispatch(updateOnList({ data: saved }));
            }, 500);

            handleResultWithDebounce();
          }
        } else {
          const response = await save(data);

          if (response?.status === 200) {
            next();
            const saved = response?.data;

            dispatch(success());
            dispatch(setModel(saved));
            dispatch(updateOnList({ data: saved }));
            dispatch(fetchByIdAsync(saved?.id));
          }
        }
      } catch (error) {
        dispatch(failure());
        let errorMessage = t('com.muralis.qcx.erro.erroSalvarTabelaArmazenagem');
        if (error?.response && error?.response?.data) {
          errorMessage = t('com.muralis.qcx.erro.erroSalvarTabelaArmazenagemMensagem', { erro: error?.response.data.message });
        }
        dispatch(setError({
          message: errorMessage,
        }));
      }
    }, 500);

    dispatch(loading());
    executeDebounced();
  };

  const update = async (data) => {
    const executeDebounced = debounce(async () => {
      try {
        const response = await save(data);

        if (response?.status === 200) {
          const handleResultWithDebounce = debounce(() => {
            handleChangeToConsult();
            dispatch(success());

            const saved = response?.data;

            dispatch(setResponse({
              status: response.status,
              data: saved,
              message: t('com.muralis.qcx.mensagem.tabelaArmazengemSalva', { tabela: saved?.description }),
            }));

            dispatch(setModel(saved));
            dispatch(updateOnList({ data: saved }));
            dispatch(fetchByIdAsync(saved?.id));
          }, 500);

          handleResultWithDebounce();
        }
      } catch ({ response }) {
        dispatch(failure());
        dispatch(setError({
          message: t('com.muralis.qcx.erro.erroSalvarTabelaArmazenagemEspecifico', { erro: response?.data?.message, tabela: data?.description }),
        }));
      }
    }, 500);

    dispatch(loading());
    executeDebounced();
  };

  const handleSubmit = async (data, step, next) => {
    const normalizedData = normalize(data);

    if (isUpdate) {
      await update(normalizedData);
      handleChangeToConsult();
    } else {
      await createByStep(normalizedData, step, next);
    }
  };

  const handleAlternativeSave = async (event, step, next) => {
    event.stopPropagation();

    const normalizedData = normalize(tabelaArmazenagem);

    if (isUpdate) {
      await update(normalizedData);
    } else {
      createByStep(normalizedData, step, next);
    }
  };

  const model = useMemo(() => (
    isCreated || !isCreate
      ? unnormalize(tabelaArmazenagem)
      : {}
  ), [isCreate, isCreated, tabelaArmazenagem, unnormalize]);

  const actionName = useMemo(() => {
    if (isCreate || isNone) return t('com.muralis.qcx.acoes.nova');
    if (isConsult) return t('com.muralis.qcx.acoes.visualizar');
    return t('com.muralis.qcx.acoes.alterar');
  }, [isNone, isCreate, isConsult]);

  const breadcrumbs = [
    {
      link: {
        to: '/',
        name: t('com.muralis.qcx.inicio'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.cadastros'),
      },
    },
    {
      link: {
        to: t('com.muralis.qcx.url.moduloOperacionais'),
        name: t('com.muralis.qcx.operacionais'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.armazem.labelPlural'),
      },
    },
    {
      link: {
        to: t('com.muralis.qcx.url.moduloOperacionaisTabelaArmazenagem'),
        name: t('com.muralis.qcx.tabelaArmazenagem.labelPlural'),
      },
    },
    {
      text: {
        name: actionName,
      },
    },
  ];

  const pageTitle = useMemo(() => (
    isCreate
      ? t('com.muralis.qcx.tabelaArmazenagem.novaTabelaArmazenagem')
      : t('com.muralis.qcx.tabelaArmazenagem.tabelaArmazengemExistente', { ID: tabelaArmazenagem?.description || '-' })
  ), [isUpdate, isConsult, tabelaArmazenagem]);

  const breadcrumbsProps = {
    maxItems: 6,
  };

  return (
    <QCXMainLayout
      pageTitle={pageTitle}
      pageIcon={<CompareIcon />}
      breadcrumbs={breadcrumbs}
      breadcrumbsProps={breadcrumbsProps}
      authInfo={authInfo}
    >
      <Container style={{ display: 'flex', padding: '0 1rem 3rem 1rem' }}>
        <QCXTabelaArmazenagemWizardFinalForm
          isConsult={isConsult}
          isUpdate={isUpdate}
          model={model}
          handleChangeModel={handleDispatchSetModel}
          handleSubmit={handleSubmit}
          handleAlternativeSave={handleAlternativeSave}
          handleChangeToConsult={handleChangeToConsult}
          handleChangeToUpdate={handleChangeToUpdate}
          handleCancelUpdate={handleCancelUpdate}
          authInfo={authInfo}
          requiredRoles={['tabela-armazenagem']}
        />
      </Container>
    </QCXMainLayout>
  );
}
