import React from 'react';
import TabPanel from '@material-ui/lab/TabPanel';

export default function QCXTabPanel({
  id,
  key,
  value,
  ...restProps
}) {
  return (
    <TabPanel
      id={id}
      key={key}
      value={value}
      {...restProps}
    />
  );
}
