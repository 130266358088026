import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isFunction } from 'lodash';
import {
  Grid,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { lock, unlock } from '../../features/focus-lock/focusLockSlice';
import QCXDialog from './QCXDialog';
import QCXDialogTitle from './QCXDialogTitle';
import QCXDialogContent from './QCXDialogContent';
import QCXDialogContentText from './QCXDialogContentText';
import QCXDialogActions from './QCXDialogActions';
import QCXButton from '../button/QCXButton';
import QCXFinalFormBase from '../final-form/QCXFinalFormBase';
import QCXBox from '../box/QCXBox';

const useStyles = makeStyles(() => ({
  dialogContentText: {
    marginBottom: '18px',
  },
  dialogActions: {
    padding: '12px 24px',
  },
}));

export default function QCXFinalFormDialog({ ...props }) {
  const { t } = useTranslation();

  const {
    key = 'modal-final-form-dialog',
    id = 'modal-final-form-dialog',
    title,
    beforeContent,
    content,
    open,
    initialValues,
    onSubmit,
    onClose,
    dialogTitleProps = {},
    dialogActionsProps = {
      button: {
        submit: {
          description: t('com.muralis.qcx.acoes.confirmar'),
          size: 'small',
        },
        cancel: {
          description: t('com.muralis.qcx.acoes.cancelar'),
          size: 'small',
        },
      },
    },
    formProps = {},
    disablePristine = false,
    children,
    ...restProps
  } = props;

  const classes = useStyles();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const dispatch = useDispatch();

  useEffect(() => {
    const handleFocusMode = () => {
      if (open) {
        dispatch(unlock());
      }
    };

    handleFocusMode();
  }, [open]);

  const handleInternalClose = useCallback((...args) => {
    onClose(...args);
    dispatch(lock());
  }, [onClose]);

  return (
    <QCXDialog
      key={key}
      id={id}
      open={open}
      onClose={onClose}
      fullScreen={fullScreen}
      aria-labelledby="final-form-dialog-title"
      aria-describedby="final-form-dialog-description"
      disableBackdropClick
      disableRestoreFocus
      {...restProps}
    >
      <QCXFinalFormBase
        initialValues={initialValues}
        onSubmit={onSubmit}
        {...formProps}
      >
        {({
          pristine,
          submitting,
          ...restFinalFormProps
        }) => (
          <>
            {title && (
              <QCXDialogTitle
                id="final-form-dialog-title"
                {...dialogTitleProps}
              >
                {title}
              </QCXDialogTitle>
            )}
            {beforeContent && (
              <QCXBox
                style={{
                  flex: '0 0 auto',
                  margin: '0',
                  padding: '4px 24px',
                }}
              >
                {beforeContent}
              </QCXBox>
            )}
            {(content || children) && (
              <QCXDialogContent>
                {content && (
                  <QCXDialogContentText
                    id="final-form-dialog-description"
                    className={classes.dialogContentText}
                  >
                    {content}
                  </QCXDialogContentText>
                )}
                {(isFunction(children)
                  ? children({
                    pristine,
                    submitting,
                    ...restFinalFormProps,
                  })
                  : children
                )}
              </QCXDialogContent>
            )}
            <QCXDialogActions
              className={classes.dialogActions}
            >
              <Grid
                item
                container
                justify="space-between"
                spacing={1}
              >
                <Grid
                  item
                  xs={12}
                  sm={5}
                >
                  <QCXButton
                    variant="outlined"
                    onClick={handleInternalClose}
                    color="default"
                    size={dialogActionsProps?.button?.cancel?.size}
                    fullWidth
                    {...dialogActionsProps?.button?.cancel}
                  >
                    {dialogActionsProps?.button?.cancel?.children
                    || dialogActionsProps?.button?.cancel?.description}
                  </QCXButton>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={5}
                >
                  <QCXButton
                    variant="contained"
                    color="secondary"
                    size={dialogActionsProps?.button?.submit?.size}
                    type="submit"
                    disabled={(
                      (pristine && !disablePristine)
                      || submitting
                    )}
                    fullWidth
                    autoFocus
                    {...dialogActionsProps?.button?.submit}
                  >
                    {dialogActionsProps?.button?.submit?.children
                    || dialogActionsProps?.button?.submit?.description}
                  </QCXButton>
                </Grid>
              </Grid>
            </QCXDialogActions>
          </>
        )}
      </QCXFinalFormBase>
    </QCXDialog>
  );
}
