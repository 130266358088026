import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { Trans } from 'react-i18next';
import QCXFormStepWizard from '../../shared-components/form-step-wizard/QCXFormStepWizard';
import {
  setRelatedSelectionItemList,
} from '../../features/declaracao-unica-exportacao/declaracaoUnicaExportacaoSlice';
import {
  fetchAllAsync as fetchAllUnidadeDeMedidaAsync,
} from '../../features/unidade-medida/unidadeDeMedidaSlice';
import {
  fetchAllAsync as fetchAllCfopOperacoesFiscaisAsync,
} from '../../features/cfop-operacoes-fiscais/cfopOperacoesFiscaisSlice';
import {
  selectDeclaracaoUnicaExportacao,
  selectRelatedItensXmlDanfeMetadata,
} from '../../features/declaracao-unica-exportacao/declaracaoUnicaExportacaoSelectors';
import QCXImportacaoXmlDanfeDataGridManager from './QCXImportacaoXmlDanfeDataGridManager';
import QCXInfoAlert from '../../shared-components/alert/QCXInfoAlert';

export default function QCXImportacaoXmlDanfeForm({
  isConsult,
  isUpdate,
  isCreate,
  initialValues,
  handleChangeToUpdate,
  handleCancelUpdate,
  controlComponentProps,
  handleSubmit,
  ...restProps
}) {
  const dispatch = useDispatch();

  const [defaultSelectedItens, setDefaultSelectedItens] = useState(true);

  const declaracaoUnicaExportacao = useSelector(selectDeclaracaoUnicaExportacao);
  const itensXmlDanfeMetadata = useSelector(selectRelatedItensXmlDanfeMetadata);

  const created = useMemo(() => (
    declaracaoUnicaExportacao?.id
  ), [declaracaoUnicaExportacao]);

  const unnormalize = useCallback((mercadorias) => {
    const unnormalizedData = mercadorias?.map((mercadoria) => ({
      ...mercadoria,
      ncm: mercadoria?.ncm?.code,
      unidadeDeMedida: mercadoria?.unidadeDeMedida?.description,
      operacaoFiscal: mercadoria?.operacaoFiscal?.code,
      quantidadeInicial: mercadoria?.quantidade,
      valorTotalMoedaInicial: mercadoria?.valorTotalMoeda,
      due: {
        ...mercadoria?.due,
        id: null,
      },
    }));

    return unnormalizedData;
  }, []);

  const mercadoriasProcessadasViaXmlDanfe = useMemo(() => {
    if (!itensXmlDanfeMetadata || isEmpty(itensXmlDanfeMetadata)) {
      return [];
    }

    const mercadoriasProcessadas = unnormalize(itensXmlDanfeMetadata);

    return mercadoriasProcessadas;
  }, [declaracaoUnicaExportacao, itensXmlDanfeMetadata]);

  useEffect(() => {
    if (!created && defaultSelectedItens) {
      const itensPreSelecionados = (mercadoriasProcessadasViaXmlDanfe || [])
        .map((mercadoria) => mercadoria?.id);

      dispatch(setRelatedSelectionItemList(itensPreSelecionados));
      setDefaultSelectedItens(false);
    }
  }, [
    created,
    defaultSelectedItens,
    mercadoriasProcessadasViaXmlDanfe,
    setDefaultSelectedItens,
  ]);

  useEffect(() => {
    if (!created) {
      dispatch(fetchAllUnidadeDeMedidaAsync());
      dispatch(fetchAllCfopOperacoesFiscaisAsync());
    }
  }, [created]);

  const disableUpdate = useMemo(() => (
    created
  ), [created]);

  const readOnlyFormProps = useMemo(() => ({
    disableUpdate,
  }), []);

  return (
    <>
      <QCXFormStepWizard
        isConsult={isConsult}
        isUpdate={isUpdate}
        disablePristine
        initialValues={initialValues}
        handleChangeToUpdate={handleChangeToUpdate}
        handleCancelUpdate={handleCancelUpdate}
        controlComponentProps={{
          ...controlComponentProps,
          readOnlyFormProps,
        }}
        onSubmit={handleSubmit}
        {...restProps}
      >
        {() => (
          <>
            <Grid
              item
              container
              spacing={2}
            >
              <Grid
                item
                xs={12}
              >
                <QCXInfoAlert>
                  <Trans
                    i18nKey="com.muralis.qcx.mensagem.acaoRealizadaApenasUmaVez"
                  >
                    <strong />
                  </Trans>
                </QCXInfoAlert>
              </Grid>
              <Grid
                item
                container
                xs={12}
              >
                <QCXImportacaoXmlDanfeDataGridManager
                  rows={mercadoriasProcessadasViaXmlDanfe}
                  disabled={isConsult}
                />
              </Grid>
            </Grid>
          </>
        )}
      </QCXFormStepWizard>
    </>
  );
}
