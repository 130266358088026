import { Grid } from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import RestoreFromTrash from '@material-ui/icons/RestoreFromTrash';
import { MouseEvent } from 'react';
import { useForm, useFormState } from 'react-final-form';
import QCXIconButton from '../../../../../../shared-components/button/QCXIconButton';
import QCXFinalDateTimePickerField from '../../../../../../shared-components/final-date-time-picker-field/QCXFinalDateTimePickerField';
import QCXSelectEtapaAutocomplete from '../../../../../../shared-components/select-etapa/QCXSelectEtapaAutocomplete';
import QCXTextFieldViewer from '../../../../../../shared-components/text-field/QCXTextFieldViewer';
import EtapaOcorrenciasBondManager from '../../../follow-up/FollowUpListManagerPage/Components/EtapaOcorrenciasBondManager';
// eslint-disable-next-line import/extensions
import useActiveInactiveStatus from '../../../../../../ts/common/hooks/followUpEtapa/useActiveInactiveStatus';
import QCXFinalTextField from '../../../../../../shared-components/final-text-field/QCXFinalTextField';

function EtapaForms() {
  const { values } = useFormState();

  const form = useForm();

  function handleInactivateOrReactivate(event: { preventDefault: () => void }) {
    event.preventDefault();
    if (isActive({ active: values.active })) {
      form.change('active', false);
    } else {
      form.change('active', true);
    }
  }

  const { inactivateReactivateButtonTooltipProps, isActive, inactivateReactivateButtonColor } =
    useActiveInactiveStatus();

  return (
    <>
      <Grid item container spacing={1} xs={12}>
        <Grid item container xs={12} sm={5} alignItems="center" justify="space-evenly">
          <Grid item xs={1}>
            <QCXIconButton
              disabled={false}
              tooltipProps={inactivateReactivateButtonTooltipProps({ active: values.active })}
              onClick={(event: MouseEvent<HTMLButtonElement>) => handleInactivateOrReactivate(event)}
            >
              {isActive({ active: values.active }) ? (
                <DeleteForeverIcon
                  htmlColor={inactivateReactivateButtonColor({ active: values.active })}
                  fontSize="default"
                />
              ) : (
                <RestoreFromTrash
                  htmlColor={inactivateReactivateButtonColor({ active: values.active })}
                  fontSize="default"
                />
              )}
            </QCXIconButton>
          </Grid>
          <Grid item xs={11}>
            <QCXSelectEtapaAutocomplete
              id="select-etapa-field"
              key="select-etapa-field"
              name="etapa"
              label="Etapa"
              disabled={false}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid item sm={2} container alignItems="center">
          <QCXFinalDateTimePickerField
            id="date-picker-data-previsao-field"
            key="date-picker-data-previsao-field"
            name="dataPrevisao"
            label="Previsão"
            disabled={false}
          />
        </Grid>
        <Grid item sm={2} container alignItems="center">
          <QCXFinalDateTimePickerField
            id="date-picker-data-realizado-field"
            key="date-picker-data-realizado-field"
            name="dataConclusao"
            label="Realizado"
            disabled={false}
          />
        </Grid>
        <Grid item container sm={3} alignItems="center">
          <Grid xs={9}>
            <QCXTextFieldViewer
              key="text-field-processo-viewer"
              id="text-field-processo-viewer"
              state={values}
              label="Usuário"
              name="usuario"
              disableHelperText
              disabled={false}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item container xs={12} style={{ paddingTop: '1rem' }}>
        <QCXFinalTextField
          key="text-field-observacao-field"
          id="text-field-observacao-field"
          name="observacao"
          label="Observação"
          disabled={false}
        />
      </Grid>
      <Grid xs={12}>
        <Grid style={{ paddingTop: '1rem' }} xs={12}>
          <EtapaOcorrenciasBondManager isConsult={false} etapaId={values?.etapa?.id} name="ocorrencias" />
        </Grid>
      </Grid>
    </>
  );
}

export default EtapaForms;
