import { Grid } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import QCXFinalAlternativeCurrencyField from '../../shared-components/final-currency-field/QCXFinalAlternativeCurrencyField';
import QCXFinalGRISField from '../../shared-components/final-gris-field/QCXFinalGRISField';
import QCXFinalICMSField from '../../shared-components/final-icms-field/QCXFinalICMSField';
import QCXFinalSelectTipoRedestinacaoAutocomplete from '../../shared-components/select-redestinacao/QCXFinalSelectTipoRedestinacaoAutocomplete';
import QCXFinalSelectTipoCalculoPercentualAutocomplete from '../../shared-components/select-tipo-calculo-percentual/QCXFinalSelectTipoCalculoPercentualAutocomplete';
import QCXFinalSelectTipoEquipamentoAutocomplete from '../../shared-components/select-tipo-equipamento/QCXFinalSelectTipoEquipamentoAutocomplete';
import QCXFinalTextField from '../../shared-components/final-text-field/QCXFinalTextField';
import QCXFormStepWizard from '../../shared-components/form-step-wizard/QCXFormStepWizard';
import QCXSelectTipoContainerAutocomplete from '../../shared-components/select-tipo-container/QCXSelectTipoContainerAutocomplete';
import QCXSelectViaTransporteAutocomplete from '../../shared-components/select-via-transporte/QCXSelectViaTransporteAutocomplete';
import { maxValue, required as requiredValidator } from '../../utils/validators/field/validator';
import QCXSelectMoedaAutocomplete from '../../shared-components/final-select-moeda-field/QCXSelectMoedaAutocomplete';
import QCXFormTitle from '../form-title/QCXFormTitle';

export default function QCXTabelaArmazenagemDadosTecnicosForm({
  initialValues,
  handleSubmit,
  isConsult,
  isUpdate,
  controlComponentProps,
  authInfo = {},
  requiredRoles = [],
}) {
  const { t } = useTranslation();

  return (
    <QCXFormStepWizard
      initialValues={initialValues}
      onSubmit={handleSubmit}
      controlComponentProps={controlComponentProps}
      authInfo={authInfo}
      requiredRoles={requiredRoles}
    >
      {({ values }) => (
        <>
          <QCXFormTitle
            title={t('com.muralis.qcx.dadosTecnicos')}
          />
          <Grid container spacing={2} style={{ marginTop: '1rem' }}>
            {(isConsult || isUpdate) && (
              <Grid item xs={12} sm={6} md={3}>
                <QCXFinalTextField
                  id="text-field-code"
                  key="text-field-code"
                  name="id"
                  label={t('com.muralis.qcx.codigo')}
                  disabled
                />
              </Grid>
            )}
            <Grid item xs={12} sm={6} md={(isConsult || isUpdate) ? 6 : 5}>
              <QCXFinalTextField
                id="text-field-description"
                key="text-field-description"
                name="description"
                label={t('com.muralis.qcx.descricao')}
                maxLength={40}
                disabled={isConsult}
                validate={requiredValidator}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <QCXSelectViaTransporteAutocomplete
                name="viaTransporte.id"
                label={t('com.muralis.qcx.transporte.viaTransporte')}
                disabled={isConsult}
                fieldProps={{ validate: requiredValidator }}
                initialValues={values}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <QCXFinalGRISField
                id="porcentagem-gris-field"
                key="porcentagem-gris-field"
                name="gris"
                label={t('com.muralis.qcx.gris')}
                disabled={isConsult}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <QCXFinalICMSField
                id="porcentagem-icms-field"
                key="porcentagem-icms-field"
                name="icms"
                label={t('com.muralis.qcx.ICMS.label')}
                disabled={isConsult}
                validate={maxValue(99.99)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <QCXFinalSelectTipoEquipamentoAutocomplete
                id="select-tipo-equipamento-field"
                key="select-tipo-equipamento-field"
                name="tipoEquipamento"
                label={t('com.muralis.qcx.tipoEquipamento')}
                disabled={isConsult}
                fieldProps={{ validate: requiredValidator }}
                initialValues={values}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <QCXSelectTipoContainerAutocomplete
                id="select-tipo-container-field"
                key="select-tipo-container-field"
                name="tipoContainer.id"
                label={t('com.muralis.qcx.tipoContainer')}
                disabled={isConsult}
                initialValues={values}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <QCXFinalAlternativeCurrencyField
                id="valor-adicional-carreta-ls-field"
                key="valor-adicional-carreta-ls-field"
                name="valorAdicionalCarretaLs"
                label={t('com.muralis.qcx.valorAdicionalCarretaLS')}
                disabled={isConsult}
                validate={maxValue(999999999999.99)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <QCXFinalSelectTipoRedestinacaoAutocomplete
                id="select-redestinacao-field"
                key="select-redestinacao-field"
                name="redestinacao"
                label={t('com.muralis.qcx.redestinacao.label')}
                disabled={isConsult}
                initialValues={values}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <QCXFinalSelectTipoCalculoPercentualAutocomplete
                id="select-tipo-calculo-percentual-field"
                key="select-tipo-calculo-percentual-field"
                name="calculo"
                label={t('com.muralis.qcx.calculo')}
                disabled={isConsult}
                fieldProps={{ validate: requiredValidator }}
                initialValues={values}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <QCXSelectMoedaAutocomplete
                name="moeda.id"
                label={t('com.muralis.qcx.moeda.label')}
                fieldProps={{ validate: requiredValidator }}
                disabled={isConsult}
                initialValues={values}
                required
              />
            </Grid>
          </Grid>
        </>
      )}
    </QCXFormStepWizard>
  );
}
