import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import _ from 'lodash';
import { createFilterOptions } from '@material-ui/lab/useAutocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { useFormState } from 'react-final-form';
import {
  fetchAllAsync,
} from '../../features/fundamentacao-especifica/fundamentacaoEspecificaThunks';
import {
  selectConfigControl,
  selectFundamentacoesEspecificas,
} from '../../features/fundamentacao-especifica/fundamentacaoEspecificaSelectors';
import QCXAutocompleteSelect from '../autocomplete-select/QCXAutocompleteSelect';
import { isAutoLoadControl } from '../../utils/store/store-utils';

export default function QCXSelectFundamentacaoEspecificaAutocomplete({
  label,
  name,
  ...restProps
}) {
  const dispatch = useDispatch();

  const { values } = useFormState();

  const [defaultValue, setDefaultValue] = useState('');

  const configControl = useSelector(selectConfigControl);
  const list = useSelector(selectFundamentacoesEspecificas);

  useEffect(() => {
    if (isAutoLoadControl(configControl)) {
      dispatch(fetchAllAsync());
    }
  }, [configControl]);

  useEffect(() => {
    const value = list.find((item) => item?.id === _.get(values, name));
    if (value) {
      setDefaultValue(value);
    } else {
      setDefaultValue({ id: null, description: '' });
    }
  }, [list, values]);

  const getOptionLabel = useCallback((item) => (
    item?.code && item?.description
      ? `${item?.code} - ${item?.description}`
      : ''
  ), []);

  const filterOptions = useMemo(() => (
    createFilterOptions({
      stringify: getOptionLabel,
    })
  ), [getOptionLabel]);

  return (
    <QCXAutocompleteSelect
      label={label}
      id={name}
      name={name}
      options={list}
      filterOptions={filterOptions}
      defaultValue={defaultValue}
      getOptionLabel={getOptionLabel}
      {...restProps}
    />
  );
}
