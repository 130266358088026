import arrayMutators from 'final-form-arrays';
import { debounce, isEmpty, isFunction } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router';
import { v4 as uuid } from 'uuid';
import { etapaFollowUpSelectors } from '../../../../../features/etapa-follow-up/etapaFollowUpSelectors';
import { etapaFollowUpActions } from '../../../../../features/etapa-follow-up/etapaFollowUpSlice';
import { etapaFollowUpThunks } from '../../../../../features/etapa-follow-up/etapaFollowUpThunks.ts';
import { saveEtapas } from '../../../../../features/etapa/etapaAPI';
import { followUpAPI } from '../../../../../features/follow-up/followUpAPI';
import { followUpSelectors, selectFollowUp, selectMode } from '../../../../../features/follow-up/followUpSelectors';
import {
  changeToConsultMode,
  changeToCreateMode,
  changeToUpdateMode,
  failure,
  followUpActions,
  loading,
  setError,
  setModel,
  setResponse,
  success,
  updateOnList,
} from '../../../../../features/follow-up/followUpSlice';
import { fetchByIdAsync, followUpThunks } from '../../../../../features/follow-up/followUpThunks';
import QCXConfirmDialog from '../../../../../shared-components/dialog/QCXConfirmDialog';
import QCXFinalForm from '../../../../../shared-components/final-form/QCXFinalForm';
import QCXActionsManagerPageTemplate from '../../../../../templates/actions-manager-page/QCXActionsManagerPageTemplate';
import { MediaType } from '../../../../../utils/api/api-utils';
import useOperationConfirm from '../../../../../utils/hooks/operation/confirm/useOperationConfirm';
import { isConsultMode, isCreateMode, isRefresh, isUpdateMode } from '../../../../../utils/store/store-utils';
import FollowUpListDesvios from './Components/FollowUpListDesvios';
import FollowUpListFieldsViewerManager from './Components/FollowUpListFieldsViewerManager';
import FollowUpListPhases from './Components/FollowUpListPhases';
// eslint-disable-next-line import/no-unresolved, import/extensions
import {
  convertLocalDateTimeStringToUTC,
  convertUTCStringToLocalDateTime,
} from '../../../../../utils/general/dateTime-utils.ts';

export default function FollowUpListManagerPage({ authInfo = {} }) {
  const { t } = useTranslation();
  const location = useLocation();
  const params = useParams();

  const dispatch = useDispatch();

  const followUp = useSelector(selectFollowUp);
  const etapasFollowUp = useSelector(etapaFollowUpSelectors.selectEtapasFollowUp);
  const mode = useSelector(selectMode);

  const isCreate = useMemo(() => isCreateMode(mode), [mode]);

  const isConsult = useMemo(() => isConsultMode(mode), [mode]);

  const isUpdate = useMemo(() => isUpdateMode(mode), [mode]);

  const refreshEtapaFollowUp = useSelector(etapaFollowUpSelectors.selectRefresh);
  const refreshFollowUp = useSelector(followUpSelectors.selectRefresh);
  useEffect(() => {
    if (isRefresh(refreshEtapaFollowUp) || isRefresh(refreshFollowUp)) {
      dispatch(etapaFollowUpThunks.fetchAllByFollowUpIdAsync(followUp?.id));
      dispatch(etapaFollowUpActions.resetRefresh());
      dispatch(followUpThunks.fetchByIdAsync(followUp?.id));
      dispatch(followUpActions.resetRefresh());
    }
  }, [refreshEtapaFollowUp, refreshFollowUp, followUp]);

  const fetchById = useCallback((followUpId) => {
    dispatch(fetchByIdAsync(followUpId));
    dispatch(etapaFollowUpThunks.fetchAllByFollowUpIdAsync(followUpId));
  }, []);

  const unnormalize = useCallback((followUpData, etapasFollowUpArray) => {
    const { referencias, ...rest } = followUpData;

    return {
      ...rest,
      referencias: referencias?.map((r) => ({ id: uuid(), referencia: r })) ?? [],
      etapas: etapasFollowUpArray.map((e) => ({
        ...e,
        open: false,
        dataInicio: convertUTCStringToLocalDateTime(e.dataInicio),
        dataConclusao: convertUTCStringToLocalDateTime(e.dataConclusao),
        dataPrevisao: convertUTCStringToLocalDateTime(e.dataPrevisao),
      })),
    };
  }, []);

  const model = useMemo(() => unnormalize(followUp, etapasFollowUp), [followUp, etapasFollowUp]);

  // Essa solução não é ideal pois fica muito dependente
  // da url da pagina. No entanto é a forma mais rapida de resolver
  // o problema sem necessitar de grandes mudanças, por isso
  // foi adotada
  const isImportacaoPath = useMemo(() => location.pathname.split('/')[1] === 'importacao', [location.pathname]);

  const breadcrumbs = useMemo(
    () => [
      {
        link: {
          to: '/',
          name: t('com.muralis.qcx.inicio'),
        },
      },
      isImportacaoPath
        ? {
            link: {
              to: t('com.muralis.qcx.url.moduloImportacao'),
              name: t('com.muralis.qcx.importacao.label'),
            },
          }
        : {
            link: {
              to: t('com.muralis.qcx.url.moduloExportacao'),
              name: t('com.muralis.qcx.exportacao.label'),
            },
          },
      isImportacaoPath
        ? {
            link: {
              to: t('com.muralis.qcx.url.importacaoFollowUp'),
              name: t('com.muralis.qcx.followup.label'),
            },
          }
        : {
            link: {
              to: t('com.muralis.qcx.url.exportacaoFollowUp'),
              name: t('com.muralis.qcx.followup.label'),
            },
          },
      {
        text: {
          name: 'Lista',
        },
      },
    ],
    [isImportacaoPath]
  );

  const pageTitle = useMemo(
    () => t('com.muralis.qcx.listaFollowUp.listaFollowUp', { numero: model?.numero || '-' }),
    [model]
  );

  const handleChangeToCreate = useCallback(() => {
    dispatch(changeToCreateMode());
  }, []);

  const handleChangeToConsult = useCallback(() => {
    dispatch(changeToConsultMode());
  }, []);

  const handleChangeToUpdate = useCallback((additional = {}) => {
    dispatch(changeToUpdateMode());

    if (!isEmpty(additional) && isFunction(additional?.callback)) {
      additional.callback();
    }
  }, []);

  const handleBasicCancelUpdate = useCallback(
    (additional = {}) => {
      const currentId = followUp?.id;

      if (currentId) {
        fetchById(currentId);
      }

      handleChangeToConsult();

      if (!isEmpty(additional) && isFunction(additional?.callback)) {
        additional.callback();
      }
    },
    [params, followUp, fetchById, handleChangeToConsult]
  );

  const operationOfCancelUpdateOptions = useMemo(
    () => ({
      title: t('com.muralis.qcx.aviso').toUpperCase(),
      message: t('com.muralis.qcx.mensagem.alteracoesRealizadasSeraoPerdidas'),
      endMessage: t('com.muralis.qcx.mensagem.certezaDesejaCancelar'),
    }),
    []
  );

  const [handleCancelUpdate, operationOfCancelUpdate] = useOperationConfirm(
    handleBasicCancelUpdate,
    operationOfCancelUpdateOptions,
    [handleBasicCancelUpdate, operationOfCancelUpdateOptions]
  );

  useEffect(() => {
    const handleFetchById = () => {
      if (!isEmpty(params?.id)) {
        fetchById(params?.id);
      }
    };

    handleFetchById();
  }, [params]);

  const normalize = useCallback((unnormalizedData) => {
    const { etapas, ...followUpData } = unnormalizedData;

    const { referencias, ...restFollowUp } = followUpData;

    const normalizedReferencias = referencias.map((r) => r.referencia);

    const etapasNormalized = {
      followUpEtapaList: etapas.map((e) => ({
        ...e,
        dataInicio: convertLocalDateTimeStringToUTC(e.dataInicio),
        dataPrevisao: convertLocalDateTimeStringToUTC(e.dataPrevisao),
        dataConclusao: convertLocalDateTimeStringToUTC(e.dataConclusao),
      })),
    };

    return {
      normalizedEtapas: etapasNormalized,
      normalizedFollowUp: { ...restFollowUp, referencias: normalizedReferencias },
    };
  }, []);

  const parseToFormData = useCallback((data) => {
    const formData = new FormData();

    const json = JSON.stringify(data);
    const blob = new Blob([json], {
      type: MediaType.APPLICATION_JSON,
    });

    formData.append('data', blob);

    // É necessario passar o campo metadata vazio, pois o back tem esse campo como obrigatório
    const meta = { metadata: [] };
    const jsonMeta = JSON.stringify(meta);
    const blobMeta = new Blob([jsonMeta], {
      type: MediaType.APPLICATION_JSON,
    });

    formData.append('metadata', blobMeta);

    return formData;
  }, []);

  const updateFollowUp = useCallback(
    async (data) => {
      const executeDebounced = debounce(async () => {
        try {
          const formData = parseToFormData(data);

          const response = await followUpAPI.save(formData);

          if (response?.status === 200) {
            const handleResultWithDebounce = debounce(() => {
              handleChangeToConsult();
              dispatch(success());

              const saved = response?.data;

              dispatch(
                setResponse({
                  status: response.status,
                  data: saved,
                  message: t('com.muralis.qcx.mensagem.followUpSalvo', { numero: data?.numero }),
                })
              );

              dispatch(setModel(saved));
              dispatch(updateOnList({ data: saved }));
              dispatch(fetchByIdAsync(saved?.id));
            }, 500);

            handleResultWithDebounce();
          }
        } catch (error) {
          const responseErrorMessage =
            error?.response && error?.response?.data && error?.response?.data?.message
              ? error?.response?.data?.message
              : undefined;

          if (!responseErrorMessage) {
            dispatch(failure());
            dispatch(
              setError({
                message: t('com.muralis.qcx.erro.erroSalvarFollowUp'),
              })
            );
          }
        }
      }, 500);

      dispatch(loading());
      executeDebounced();
    },
    [handleChangeToConsult, parseToFormData]
  );

  const updateEtapas = useCallback(async (etapas) => {
    const executeDebounced = debounce(async () => {
      try {
        const response = await saveEtapas(etapas);

        if (response?.status === 200) {
          const handleResultWithDebounce = debounce(() => {
            handleChangeToConsult();
            dispatch(success());
            dispatch(etapaFollowUpThunks.fetchAllByFollowUpIdAsync(params?.id));
          }, 500);

          handleResultWithDebounce();
        }
      } catch (error) {
        const responseErrorMessage =
          error?.response && error?.response?.data && error?.response?.data?.message
            ? error?.response?.data?.message
            : undefined;

        if (!responseErrorMessage) {
          dispatch(failure());
          dispatch(
            setError({
              message: t('com.muralis.qcx.erro.erroSalvarFollowUp'),
            })
          );
        }
      }
    }, 500);

    dispatch(loading());
    executeDebounced();
  }, []);

  const handleSubmit = useCallback(
    async (data) => {
      const { normalizedFollowUp, normalizedEtapas } = normalize(data);

      if (isUpdate) {
        await updateFollowUp(normalizedFollowUp);
        await updateEtapas(normalizedEtapas);
      }
    },
    [isCreate, isUpdate, normalize, updateFollowUp, updateEtapas]
  );

  return (
    <QCXActionsManagerPageTemplate
      pageTitle={pageTitle}
      breadcrumbs={breadcrumbs}
      subtitle={{
        show: true,
        text: t('com.muralis.qcx.dadosProcesso'),
      }}
      authInfo={authInfo}
    >
      {() => (
        <>
          <QCXFinalForm
            onSubmit={handleSubmit}
            initialValues={model}
            mutators={{
              ...arrayMutators,
            }}
            isCreate={isCreate}
            isUpdate={isUpdate}
            isConsult={isConsult}
            handleChangeToCreate={handleChangeToCreate}
            handleChangeToUpdate={handleChangeToUpdate}
            handleChangeToConsult={handleChangeToConsult}
            handleCancelUpdate={handleCancelUpdate}
            disablePristine
          >
            {() => (
              <>
                <FollowUpListFieldsViewerManager model={model} />
                <FollowUpListPhases />
                <FollowUpListDesvios />
              </>
            )}
          </QCXFinalForm>
          <QCXConfirmDialog
            open={operationOfCancelUpdate?.active}
            title={operationOfCancelUpdate?.title}
            content={operationOfCancelUpdate?.message}
            endContent={operationOfCancelUpdate?.endMessage}
            onConfirm={operationOfCancelUpdate.confirm}
            onClose={operationOfCancelUpdate?.reset}
            buttonGroupOptions={{
              confirm: {
                description: t('com.muralis.qcx.expressao.sim'),
              },
              cancel: {
                description: t('com.muralis.qcx.expressao.nao'),
              },
            }}
          />
        </>
      )}
    </QCXActionsManagerPageTemplate>
  );
}
