const selectFaturas = (state) => state.fatura.list;
const selectFatura = (state) => state.fatura.model;
const selectRelatedMercadoriaModel = (state) => (
  state.fatura.related.model.mercadoria
);
const selectRelatedTransferenciaModel = (state) => (
  state.fatura.related.model.transferencia
);
const selectRelatedTransferenciaItemModel = (state) => (
  state.fatura.related.model.transferenciaItem
);
const selectRelatedEstornoModel = (state) => (
  state.fatura.related.model.estorno
);
const selectRelatedSelectionFaturaList = (state) => (
  state.fatura.related.list.selectionFatura
);
const selectRelatedSelectionAvailableItemList = (state) => (
  state.fatura.related.list.selectionAvailableItem
);
const selectRelatedSelectionItemList = (state) => (
  state.fatura.related.list.selectionItem
);
const selectRelatedSelectionChangedItemList = (state) => (
  state.fatura.related.list.selectionChangedItem
);
const selectStatus = (state) => state.fatura.status;
const selectMode = (state) => state.fatura.mode.main;
const selectBackgroundMode = (state) => state.fatura.mode.background;
const selectSubMode = (state) => state.fatura.mode.sub;
const selectError = (state) => state.fatura.error;
const selectResponse = (state) => state.fatura.response;

const faturaSelectors = {
  selectFaturas,
  selectFatura,
  selectRelatedMercadoriaModel,
  selectRelatedTransferenciaModel,
  selectRelatedTransferenciaItemModel,
  selectRelatedEstornoModel,
  selectRelatedSelectionFaturaList,
  selectRelatedSelectionAvailableItemList,
  selectRelatedSelectionItemList,
  selectRelatedSelectionChangedItemList,
  selectStatus,
  selectMode,
  selectBackgroundMode,
  selectSubMode,
  selectError,
  selectResponse,
};

export {
  faturaSelectors,
  selectFaturas,
  selectFatura,
  selectRelatedMercadoriaModel,
  selectRelatedTransferenciaModel,
  selectRelatedTransferenciaItemModel,
  selectRelatedEstornoModel,
  selectRelatedSelectionFaturaList,
  selectRelatedSelectionAvailableItemList,
  selectRelatedSelectionItemList,
  selectRelatedSelectionChangedItemList,
  selectStatus,
  selectMode,
  selectBackgroundMode,
  selectSubMode,
  selectError,
  selectResponse,
};
