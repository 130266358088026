import { useCallback } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useDispatch, useSelector } from 'react-redux';
import {
  lock,
  unlock,
  showLockFeedback,
  showUnlockFeedback,
  selectMode,
} from '../../../../features/focus-lock/focusLockSlice';
import {
  combineShortcutKeys,
  CTRL_KEY,
  L_KEY,
  SHIFT_KEY,
} from '../../../config/shortcut-keys';
import { isUnlockedFocusMode } from '../../../store/store-utils';

export default function useFocusLockShortcut() {
  const dispatch = useDispatch();

  const mode = useSelector(selectMode);

  const handleFocusModeCallback = useCallback(() => {
    if (isUnlockedFocusMode(mode)) {
      dispatch(lock());
      dispatch(showLockFeedback());
    } else {
      dispatch(unlock());
      dispatch(showUnlockFeedback());
    }
  }, [mode]);

  const focusLockShortCutKeys = combineShortcutKeys(
    [
      CTRL_KEY,
      SHIFT_KEY,
      L_KEY
    ]
  );

  useHotkeys(
    focusLockShortCutKeys,
    handleFocusModeCallback,
    {
      keydown: true,
      enableOnTags: [
        'INPUT',
        'TEXTAREA',
        'SELECT',
      ],
    },
    [handleFocusModeCallback]
  );
}
