import { Grid } from '@material-ui/core';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import QCXFinalForm from '../../shared-components/final-form/QCXFinalForm';
import QCXFinalTextField from '../../shared-components/final-text-field/QCXFinalTextField';
import QCXSelectResponsavelRealizacaoEtapaAutocomplete from '../../shared-components/select-responsavel-realizacao-etapa/QCXSelectResponsavelRealizacaoEtapaAutocomplete';
import QCXFinalCheckboxField from '../../shared-components/final-checkbox-field/QCXFinalCheckboxField';
import QCXSelectEtapaIconAutocomplete from '../../shared-components/select-icon/QCXSelectEtapaIconAutocomplete';
import {
  required as requiredValidator,
} from '../../utils/validators/field/validator';
import QCXFormSubtitle from '../form-title/QCXFormSubtitle';
import EtapaUtils from '../../utils/general/etapa/EtapaUtils';
import QCXSelectEventoEtapaAutocomplete from '../../shared-components/select-evento-etapa/QCXSelectEventoEtapaAutocomplete';
import { ADMINISTRADOR_ROLE, userHasRole } from '../perfil/perfil-utils';
import EtapaOcorrenciasBondManager from '../../pages/module/import/follow-up/FollowUpListManagerPage/Components/EtapaOcorrenciasBondManager';

export default function QCXEtapaFinalForm({
  isCreate,
  isConsult,
  isUpdate,
  model,
  handleSubmit,
  authInfo = {},
  requiredRoles = [],
  ...restProps
}) {
  const { t } = useTranslation();
  const isAdminUser = useMemo(() => (
    userHasRole(authInfo, ADMINISTRADOR_ROLE)
  ), [authInfo]);

  return (
    <QCXFinalForm
      isCreate={isCreate}
      isConsult={isConsult}
      isUpdate={isUpdate}
      initialValues={model}
      handleSubmitValues={handleSubmit}
      authInfo={authInfo}
      requiredRoles={requiredRoles}
      {...restProps}
    >
      {({ values }) => (
        <>
          {!isCreate && (
            <Grid
              item
              xs={12}
              sm={6}
              md={2}
            >
              <QCXFinalTextField
                id="final-text-field-id"
                key="text-field-id"
                label={t('com.muralis.qcx.codigo')}
                name="id"
                disabled
              />
            </Grid>
          )}
          <Grid
            item
            xs={12}
            sm={isCreate ? 4 : 6}
            md={2}
          >
            <QCXFinalTextField
              id="final-text-field-sigla"
              key="final-text-field-sigla"
              label={t('com.muralis.qcx.sigla')}
              name="sigla"
              maxLength={10}
              disabled={isConsult}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={isCreate ? 8 : 12}
            md={isCreate ? 6 : 4}
          >
            <QCXFinalTextField
              id="text-field-description"
              key="text-field-description"
              label={t('com.muralis.qcx.etapa.nomeEtapa')}
              name="description"
              maxLength={40}
              validate={requiredValidator}
              disabled={isConsult}
              required
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
          >
            <QCXSelectResponsavelRealizacaoEtapaAutocomplete
              id="autocomplete-select-field-responsavel"
              key="autocomplete-select-field-responsavel"
              label={t('com.muralis.qcx.responsavelRealizacao')}
              name="responsavel"
              initialValues={values}
              fieldProps={{
                validate: requiredValidator,
              }}
              disabled={isConsult}
              required
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
          >
            <QCXFinalCheckboxField
              id="text-field-publica"
              key="text-field-publica"
              label={t('com.muralis.qcx.publica')}
              name="publica"
              value={EtapaUtils.PUBLICA}
              disabled={isConsult}
            />
          </Grid>

          <Grid item xs={12}>
            <QCXFormSubtitle
              title={(
                isAdminUser
                  ? t('com.muralis.qcx.selecaoIconeVinculacaoEventoEtapa')
                  : t('com.muralis.qcx.selecaoIconeEtapa')
              )}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
          >
            <QCXSelectEtapaIconAutocomplete
              id="autocomplete-select-icon-field"
              key="autocomplete-select-icon-field"
              label={t('com.muralis.qcx.icone')}
              name="icone"
              initialValues={values}
              fieldProps={{
                validate: requiredValidator,
              }}
              disabled={isConsult}
              required
            />
          </Grid>
          {isAdminUser && (
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
            >
              <QCXSelectEventoEtapaAutocomplete
                id="autocomplete-select-evento-etapa-field"
                key="autocomplete-select-evento-etapa-field"
                label={t('com.muralis.qcx.eventoRelacionado')}
                name="crawler"
                initialValues={values}
                disabled={isConsult}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <QCXFormSubtitle title="Ocorrências:" />
          </Grid>
          <Grid item style={{ paddingTop: '1rem' }} xs={12}>
            <EtapaOcorrenciasBondManager isConsult={isConsult} />
          </Grid>
        </>
      )}
    </QCXFinalForm>
  );
}
