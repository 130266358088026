import {
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import {
  MoreHoriz,
  Search,
  FileCopy,
  Block,
  Timeline,
} from "@material-ui/icons";
import { t } from "i18next";
import { bindTrigger, bindMenu } from "material-ui-popup-state";
import React from "react";
import QCXPopupState from "../../../../components/popup-state/QCXPopupState";
import QCXListItemIcon from "../../../../shared-components/list-item-icon/QCXListItemIcon";
import { useHistory } from "react-router";

type Props = {
  id: string;
};

const ActionsButtonCell = ({ id }: Props) => {
  const history = useHistory();

  return (
    <>
      <Tooltip title={"Visualizar Timeline"} arrow>
        <IconButton
          color="secondary"
          key={`btn-timeline-view-${id}`}
          name={`btn-timeline-view-${id}`}
          onClick={(event) => {
            history.push(`/assessoria/followup/${id}/timeline`);
          }}
        >
          <Timeline />
        </IconButton>
      </Tooltip>
      <QCXPopupState popupId="popup-menu-follow-up">
        {(popupState: any) => (
          <>
            <Tooltip title={"Mais opções"} arrow>
              <IconButton
                color="secondary"
                key={`btn-more-options-${id}`}
                name={`btn-more-options-${id}`}
                {...bindTrigger(popupState)}
              >
                <MoreHoriz />
              </IconButton>
            </Tooltip>
            <Menu {...bindMenu(popupState)}>
              <MenuItem
                onClick={() =>
                  history.push(`/assessoria/followup/${id}/detalhes`)
                }
              >
                <QCXListItemIcon>
                  <Search fontSize="small" />
                </QCXListItemIcon>
                <Typography
                  variant="inherit"
                  style={{
                    fontSize: 12,
                  }}
                >
                  {t("com.muralis.qcx.acoes.consultar").toUpperCase()}
                </Typography>
              </MenuItem>
              {true && (
                <>
                  <MenuItem onClick={() => {}} disabled>
                    <QCXListItemIcon>
                      <FileCopy fontSize="small" color="disabled" />
                    </QCXListItemIcon>
                    <Typography
                      variant="inherit"
                      style={{
                        fontSize: 12,
                      }}
                    >
                      {t("com.muralis.qcx.acoes.duplicar").toUpperCase()}
                    </Typography>
                  </MenuItem>
                </>
              )}
              {true && (
                <>
                  <MenuItem onClick={() => {}} disabled>
                    <QCXListItemIcon>
                      <Block fontSize="small" color={"disabled"} />
                    </QCXListItemIcon>
                    <Typography
                      variant="inherit"
                      style={{
                        fontSize: 12,
                      }}
                    >
                      {t("com.muralis.qcx.acoes.cancelar").toUpperCase()}
                    </Typography>
                  </MenuItem>
                </>
              )}
            </Menu>
          </>
        )}
      </QCXPopupState>
    </>
  );
};

export default ActionsButtonCell;
