import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AUTO_LOAD_CONTROL } from '../config-control';
import {
  ALTERNATIVE_LOADING_STATUS,
  FAILURE_STATUS,
  IDLE_STATUS,
  LOADING_STATUS,
  SUCCESS_STATUS,
} from '../status';
import {
  fetchAll,
  fetchById,
} from './tratamentoPrioritarioAPI';
import i18n from '../../i18n';

const initialState = {
  status: IDLE_STATUS,
  error: null,
  response: {
    status: null,
    message: null,
    data: null,
  },
  config: { control: AUTO_LOAD_CONTROL },
  list: [],
};

const fetchAllAsync = createAsyncThunk(
  'tratamentoPrioritario/fetchAll',
  async () => {
    const { data } = await fetchAll();
    return { data };
  }
);

const fetchByIdAsync = createAsyncThunk(
  'tratamentoPrioritario/fetchById',
  async (data) => {
    const response = await fetchById(data);
    return {
      response: {
        status: response.status,
        data: response.data,
      },
    };
  }
);

const tratamentoPrioritarioSlice = createSlice({
  name: 'tratamentoPrioritario',
  initialState,
  reducers: {
    changeStatusTo: (state, action) => {
      state.status = action.payload.status;
    },
    loading: (state) => {
      state.status = LOADING_STATUS;
    },
    success: (state) => {
      state.status = SUCCESS_STATUS;
    },
    failure: (state) => {
      state.status = FAILURE_STATUS;
    },
    resetStatus: (state) => {
      state.status = IDLE_STATUS;
    },
    setResponse: (state, action) => {
      state.response = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    clearResponse: (state) => {
      state.response = {
        status: null,
        message: null,
        data: null,
      };
    },
    resetList: (state) => {
      state.list = [];
    },
    setConfig: (state, action) => {
      state.config = action.payload;
    },
    changeControlTo: (state, action) => {
      state.config.control = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllAsync.pending, (state) => {
        state.status = ALTERNATIVE_LOADING_STATUS;
      })
      .addCase(fetchAllAsync.fulfilled, (state, action) => {
        state.status = IDLE_STATUS;
        state.list = action.payload.data;
      })
      .addCase(fetchAllAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroCarregarListaTratamentoPrioritario', { erro: action.error.message }),
        };
      })
      .addCase(fetchByIdAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(fetchByIdAsync.fulfilled, (state, action) => {
        if (action.payload.response.status === 200) {
          state.model = action.payload.response.data;
          state.status = SUCCESS_STATUS;
        }
      })
      .addCase(fetchByIdAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroCarregarTratamentoPrioritario', { erro: action.error.message }),
        };
      });
  },
});

const {
  changeStatusTo,
  loading,
  success,
  failure,
  preparingAction,
  resetStatus,
  changeControlTo,
  changeToConsultMode,
  changeToCreateMode,
  changeToUpdateMode,
  resetMode,
  changeToBackgroundCreateMode,
  resetBackgroundMode,
  setConfig,
  addToList,
  updateOnList,
  setResponse,
  setResponseMessage,
  clearResponse,
  setError,
  setModel,
  resetModel,
} = tratamentoPrioritarioSlice.actions;

const selectTratamentosPrioritarios = (
  (state) => state.tratamentoPrioritario.list
);
const selectStatus = (state) => state.tratamentoPrioritario.status;
const selectError = (state) => state.tratamentoPrioritario.error;
const selectResponse = (state) => state.tratamentoPrioritario.response;
const selectConfigControl = (state) => state.tratamentoPrioritario.config.control;

const tratamentoPrioritarioActions = {
  ...tratamentoPrioritarioSlice.actions,
  selectConfigControl,
  selectStatus,
  selectError,
  selectResponse,
  selectTratamentosPrioritarios,
  fetchAllAsync,
  fetchByIdAsync,
};
export {
  tratamentoPrioritarioSlice,
  changeStatusTo,
  loading,
  success,
  failure,
  preparingAction,
  resetStatus,
  changeControlTo,
  changeToConsultMode,
  changeToCreateMode,
  changeToUpdateMode,
  resetMode,
  changeToBackgroundCreateMode,
  resetBackgroundMode,
  setConfig,
  addToList,
  updateOnList,
  setResponse,
  setResponseMessage,
  clearResponse,
  setError,
  setModel,
  resetModel,
  fetchAllAsync,
  fetchByIdAsync,
  tratamentoPrioritarioActions,
  selectTratamentosPrioritarios,
  selectStatus,
  selectError,
  selectResponse,
  selectConfigControl,
};

export default tratamentoPrioritarioSlice.reducer;
