import React from 'react';
import Tab from '@material-ui/core/Tab';

export default function QCXTab({
  id,
  key,
  label,
  ...restProps
}) {
  return (
    <Tab
      id={id}
      key={key}
      label={label}
      {...restProps}
    />
  );
}
