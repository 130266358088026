import { getOptionByValueInList } from '../../utils/general/general-utils';
import i18n from '../../i18n';

const TIPO_CONHECIMENTO_AWB = 'AWB';
const TIPO_CONHECIMENTO_HOUSE_AWB = 'HOUSE_AWB';
const TIPO_CONHECIMENTO_DSIC = 'DSIC';
const TIPO_CONHECIMENTO_RWB = 'RWB';
const TIPO_CONHECIMENTO_HOUSE_RWB = 'HOUSE_RWB';
const TIPO_CONHECIMENTO_TIF_DTA = 'TIF_DTA';

const TIPO_CONHECIMENTO_LIST = [
  {
    label: i18n.t('com.muralis.qcx.tipoConhecimento.AWB'),
    value: TIPO_CONHECIMENTO_AWB,
  },
  {
    label: i18n.t('com.muralis.qcx.tipoConhecimento.HOUSEAWB'),
    value: TIPO_CONHECIMENTO_HOUSE_AWB,
  },
  {
    label: i18n.t('com.muralis.qcx.tipoConhecimento.DSIC'),
    value: TIPO_CONHECIMENTO_DSIC,
  },
  {
    label: i18n.t('com.muralis.qcx.tipoConhecimento.RWB'),
    value: TIPO_CONHECIMENTO_RWB,
  },
  {
    label: i18n.t('com.muralis.qcx.tipoConhecimento.HOUSERWB'),
    value: TIPO_CONHECIMENTO_HOUSE_RWB,
  },
  {
    label: i18n.t('com.muralis.qcx.tipoConhecimento.TIFDTA'),
    value: TIPO_CONHECIMENTO_TIF_DTA,
  },
];

const TIPOS_CONHECIMENTO_AEREO = [
  TIPO_CONHECIMENTO_AWB,
  TIPO_CONHECIMENTO_HOUSE_AWB,
  TIPO_CONHECIMENTO_DSIC,
];

const TIPOS_CONHECIMENTO_FERROVIARIO = [
  TIPO_CONHECIMENTO_RWB,
  TIPO_CONHECIMENTO_HOUSE_RWB,
  TIPO_CONHECIMENTO_TIF_DTA,
];

const isTipoConhecimentoAwb = (value) => (
  TIPO_CONHECIMENTO_AWB === value
);

const isTipoConhecimentoHouse = (value) => (
  TIPO_CONHECIMENTO_HOUSE_AWB === value
);

const isTipoConhecimentoDsic = (value) => (
  TIPO_CONHECIMENTO_DSIC === value
);

const isTipoConhecimentoRwb = (value) => (
  TIPO_CONHECIMENTO_RWB === value
);

const isTipoConhecimentoHouseRwb = (value) => (
  TIPO_CONHECIMENTO_HOUSE_RWB === value
);

const isTipoConhecimentoTifDta = (value) => (
  TIPO_CONHECIMENTO_TIF_DTA === value
);

const isSomeTipoConhecimento = (value, valuesToCompare = []) => (
  valuesToCompare.includes(value)
);

const getTipoConhecimentoLabelByValue = (value) => {
  const foundOption = getOptionByValueInList(TIPO_CONHECIMENTO_LIST, value);
  return foundOption && foundOption?.label;
};

export {
  TIPO_CONHECIMENTO_AWB,
  TIPO_CONHECIMENTO_HOUSE_AWB,
  TIPO_CONHECIMENTO_DSIC,
  TIPO_CONHECIMENTO_RWB,
  TIPO_CONHECIMENTO_HOUSE_RWB,
  TIPO_CONHECIMENTO_TIF_DTA,
  TIPO_CONHECIMENTO_LIST,
  TIPOS_CONHECIMENTO_AEREO,
  TIPOS_CONHECIMENTO_FERROVIARIO,
};

export {
  isTipoConhecimentoAwb,
  isTipoConhecimentoHouse,
  isTipoConhecimentoDsic,
  isTipoConhecimentoRwb,
  isTipoConhecimentoHouseRwb,
  isTipoConhecimentoTifDta,
  isSomeTipoConhecimento,
  getTipoConhecimentoLabelByValue,
};
