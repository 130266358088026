import React from 'react';
import { Link } from 'react-router-dom';
import NegativeLogoOnlyIcon from '../../icons/logo/negative-logo-only-icon';

export default function QCXNegativeLogoOnlyIcon({ enableLink = false, to, ...restProps }) {
  return enableLink ? (
    <Link to={to}>
      <NegativeLogoOnlyIcon {...restProps} />
    </Link>
  ) : (
    <NegativeLogoOnlyIcon {...restProps} />
  );
}
