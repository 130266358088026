import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { InfoOutlined as InfoOutlinedIcon } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  previewInfo: {
    display: 'flex',
    alignItems: 'center',
  },
  previewInfoItem: {
    display: 'flex',
    paddingRight: '4px',
    color: theme.palette.grey[600],
  },
}));

export default function QCXHelperText({
  icon = (
    <InfoOutlinedIcon
      fontSize="inherit"
    />
  ),
  children,
  ...restProps
}) {
  const classes = useStyles();

  return (
    <Typography
      className={classes.previewInfo}
      variant="caption"
      {...restProps}
    >
      <span
        className={classes.previewInfoItem}
      >
        {icon}
      </span>
      <span
        className={classes.previewInfoItem}
      >
        {children}
      </span>
    </Typography>
  );
}
