import React, { useMemo } from 'react';
import BaseSelectWithOptions from '../../../../../../../shared-components/dados-embarque/base-components/BaseSelectWithOptions';

export function convertTipoCampoValueToLabel(value) {
  switch (value) {
    case 'STRING':
      return 'Texto';
    case 'INT':
    case 'NUMERO':
      return 'Número';
    case 'DATA':
      return 'Data';
    default:
      throw new Error('Caso não mapeado!');
  }
}

function TipoCampoSelect({ ...props }) {
  const list = useMemo(() => [
    { id: 'STRING', label: 'Texto', value: 'STRING' },
    { id: 'NUMERO', label: 'Número', value: 'NUMERO' },
    { id: 'DATA', label: 'Data', value: 'DATA' }
  ], []);

  return (
    <BaseSelectWithOptions
      list={list}
      {...props}
    />
  );
}

export default TipoCampoSelect;
