import { createFilterOptions } from '@material-ui/lab/useAutocomplete';
import _ from 'lodash';
import React, {
  useEffect,
  useState,
  useMemo,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  fetchAllAsync,
  selectConfigControl,
  selectSetoresLotacao,
} from '../../features/setor-lotacao/setorLotacaoSlice';
import { isAutoLoadControl } from '../../utils/store/store-utils';
import QCXAutocompleteSelect from '../autocomplete-select/QCXAutocompleteSelect';

export default function QCXSelectSetorLotacaoAutocomplete({
  label,
  name,
  recintoAduaneiroId,
  initialValues,
  ...restProps
}) {
  const dispatch = useDispatch();

  const list = useSelector(selectSetoresLotacao);

  const filtredList = useMemo(() => list.filter((value) => value?.recintoAduaneiro?.id === recintoAduaneiroId), [list, recintoAduaneiroId]);

  const map = new Map(filtredList.map((item) => [item?.setor, item]));
  const uniqueList = [...map.values()];

  const configControl = useSelector(selectConfigControl);

  const [defaultValue, setDefaultValue] = useState('');

  useEffect(() => {
    if (isAutoLoadControl(configControl)) {
      dispatch(fetchAllAsync());
    }
  }, []);

  useEffect(() => {
    const value = filtredList.find((item) => item?.id === _.get(initialValues, name));
    if (value) {
      setDefaultValue(value);
    } else {
      setDefaultValue({ id: null, description: '' });
    }
  }, [filtredList, initialValues]);

  const filterOptions = createFilterOptions({
    stringify: (item) => `${item?.setor} - ${item?.description}`,
  });

  return (
    <QCXAutocompleteSelect
      label={label}
      id={name}
      name={name}
      options={uniqueList}
      filterOptions={filterOptions}
      defaultValue={defaultValue}
      getOptionLabel={(item) => (
        item?.setor && item?.description
          ? `${item?.setor} - ${item?.description}`
          : ''
      )}
      {...restProps}
    />
  );
}
