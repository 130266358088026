import { Chip, Grid, useTheme } from '@material-ui/core';
import { isEmpty } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { validate } from 'uuid';
import { fetchById as fetchDespesaReceitaById } from '../../features/despesa-receita/despesaReceitaAPI';
import {
  failure,
  setError,
} from '../../features/modelo-numerario/modeloNumerarioSlice';
import QCXFinalAlternativeCurrencyField from '../../shared-components/final-currency-field/QCXFinalAlternativeCurrencyField';
import QCXSelectMoedaAutocomplete from '../../shared-components/final-select-moeda-field/QCXSelectMoedaAutocomplete';
import QCXFormStepWizard from '../../shared-components/form-step-wizard/QCXFormStepWizard';
import QCXSelectDespesaReceitaAutocomplete from '../../shared-components/select-despesa-receita/QCXSelectDespesaReceitaAutocomplete';
import QCXSelectTipoCalculoAutocomplete from '../../shared-components/select-tipo-calculo/QCXSelectTipoCalculoAutocomplete';
import QCXFinalSelectTipoModeloAutocomplete, { getTipoModeloLabelByValue } from '../../shared-components/select-tipo-modelo/QCXFinalSelectTipoModeloAutocomplete';
import QCXRegistrationOneToManyPageTemplate from '../../templates/registration-one-to-many-page/QCXRegistrationOneToManyPageTemplate';
import { forceUnnormalizeNumeral, isValid } from '../../utils/general/general-utils';
import { formatBrazilianNumericDecimal } from '../../utils/hooks/form/field/formatters';
import { required as requiredValidator } from '../../utils/validators/field/validator';
import {
  VALOR_FIXO_CODE,
  PORCENTAGEM_SOBRE_CIF_CODE,
  PORCENTAGEM_SOBRE_VMLE_CODE,
  PORCENTAGEM_SOBRE_FRETE_CODE,
} from '../../utils/general/tipo-calculo/tipoCalculoUtils';

export default function QCXModeloLancamentoFinalFormManager({
  isConsult,
  isUpdate,
  isSubNone,
  isSubCreate,
  isSubConsult,
  isSubUpdate,
  model,
  list,
  handleSubmit,
  handleNew,
  handleEdit,
  handleCancelCreate,
  handleCancelUpdate,
  handleRemove,
  handleRowClick,
  handleClearForm,
  controlComponentProps,
  ...restProps
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();

  const handleDespesaReceitaChange = useCallback((form) => async (value) => {
    if (!(isSubCreate || isSubUpdate)) {
      return;
    }

    if (value) {
      try {
        const response = await fetchDespesaReceitaById(value);

        if (response?.status === 200) {
          const foundDespesaReceita = response?.data;

          if (foundDespesaReceita && !isEmpty(foundDespesaReceita)) {
            form.change('description', foundDespesaReceita?.description);
            form.change('tipo', foundDespesaReceita?.tipo);

            form.change('tipoCalculo', foundDespesaReceita?.tipoCalculo);
            form.change('tipoCalculo.id', foundDespesaReceita?.tipoCalculo.id);

            form.change('moeda', undefined);
            form.change('moeda.id', foundDespesaReceita?.moeda?.id);
          }
        }
      } catch (error) {
        const defaultMessage = t('com.muralis.qcx.mensagem.impossivelCarregarDadosReceitaDespesaSelecionada');
        let fullMessage = defaultMessage;

        if (error?.response) {
          fullMessage = `${defaultMessage}: ${error?.response?.data?.message}`;
        }

        if (error?.message) {
          fullMessage = `${defaultMessage}: ${error?.message}`;
        }

        dispatch(failure());
        dispatch(setError({
          message: fullMessage,
        }));
      }

      return;
    }

    form.change('description', '');
    form.change('tipo', '');
    form.change('tipoCalculo', undefined);
    form.change('moeda', undefined);
  }, [
    isSubCreate,
    isSubUpdate,
  ]);

  const showValorFieldByTipoCalculoCode = useCallback((code) => (
    [
      VALOR_FIXO_CODE,
      PORCENTAGEM_SOBRE_CIF_CODE,
      PORCENTAGEM_SOBRE_VMLE_CODE,
      PORCENTAGEM_SOBRE_FRETE_CODE,
    ].includes(code)
  ), []);

  const showValorFieldBy = useCallback((values) => (
    showValorFieldByTipoCalculoCode(values?.tipoCalculo?.code)
  ), [showValorFieldByTipoCalculoCode]);

  const listenerList = useMemo(() => ([
    {
      name: 'despesaReceita.id',
      fn: handleDespesaReceitaChange,
    },
  ]), [
    handleDespesaReceitaChange,
  ]);

  const configureRestartFormHandler = useCallback((form) => {
    const handleRestartForm = () => {
      if (form) {
        form.restart({
          tipo: '',
          valor: '',
        });
      }
    };

    return handleRestartForm;
  }, []);

  const configureReinitializeHandler = useCallback((form) => {
    const handleReinitialize = () => {
      if (!isEmpty(form)) {
        form.restart(model);
      }
    };

    return handleReinitialize;
  }, [model]);

  const tableProps = useMemo(() => ({
    title: t('com.muralis.qcx.modelosAdicionados'),
    columns: [
      {
        field: 'id',
        headerName: t('com.muralis.qcx.codigo'),
        headerAlign: 'center',
        align: 'center',
        flex: 150,
        valueFormatter: ({ row }) => (
          validate(row?.id)
            ? '-'
            : row?.id
        ),
      },
      {
        field: 'description',
        headerName: t('com.muralis.qcx.despesa.despesaReceita'),
        flex: 280,
      },
      {
        field: 'tipo',
        headerName: t('com.muralis.qcx.tipoDoModelo'),
        headerAlign: 'center',
        align: 'center',
        type: 'string',
        flex: 200,
        valueGetter: ({ row }) => (
          getTipoModeloLabelByValue(row?.tipo)
        ),
        renderCell: ({ value }) => {
          const isReceita = value === t('com.muralis.qcx.receita');
          return (
            <Chip
              size="small"
              label={value}
              style={{
                backgroundColor: isReceita
                  ? theme.palette.success.main
                  : theme.palette.error.dark,
                color: isReceita
                  ? theme.palette.success.contrastText
                  : theme.palette.error.contrastText,
              }}
            />
          );
        },
      },
      {
        field: 'valor',
        headerName: t('com.muralis.qcx.valor'),
        headerAlign: 'right',
        align: 'right',
        flex: 160,
        valueFormatter: ({ row: { valor } }) => (
          forceUnnormalizeNumeral(
            valor,
            formatBrazilianNumericDecimal(2)
          )
        ),
      },
    ],
  }), []);

  const localeTableText = useMemo(() => ({
    noRowsLabel: t('com.muralis.qcx.mensagem.nenhumModeloEncontrado'),
  }), []);

  return (
    <QCXFormStepWizard
      initialValues={model}
      onSubmit={handleSubmit}
      controlComponentProps={controlComponentProps}
      onChangeListeners={listenerList}
      {...restProps}
    >
      {({ form, values, submitSucceeded }) => (
        <QCXRegistrationOneToManyPageTemplate
          title={t('com.muralis.qcx.despesa.despesasReceitas')}
          addButtonText={t('com.muralis.qcx.acoes.nova').toUpperCase()}
          tableProps={tableProps}
          tableData={list}
          isConsult={isConsult}
          isUpdate={isUpdate}
          isSubNone={isSubNone}
          isSubCreate={isSubCreate}
          isSubConsult={isSubConsult}
          isSubUpdate={isSubUpdate}
          submitSucceeded={submitSucceeded}
          handleNew={handleNew}
          handleEdit={handleEdit}
          handleCancelCreate={handleCancelCreate}
          handleCancelUpdate={handleCancelUpdate}
          handleRemove={handleRemove}
          handleClearForm={handleClearForm}
          handleRestartForm={configureRestartFormHandler(form)}
          handleRowClick={handleRowClick}
          localeTableText={localeTableText}
          filterPropGetter={(row) => row?.description}
          handleReinitialize={configureReinitializeHandler(form)}
        >
          <Grid
            item
            xs={12}
            sm={8}
            md={4}
          >
            <QCXSelectDespesaReceitaAutocomplete
              id="autocomplete-select-field-despesa-receita"
              key="autocomplete-select-field-despesa-receita"
              name="despesaReceita.id"
              label={t('com.muralis.qcx.despesa.despesaReceita')}
              initialValues={values}
              fieldProps={{
                validate: requiredValidator,
              }}
              disabled={isConsult || isSubConsult || isSubNone}
              required
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            md={3}
          >
            <QCXFinalSelectTipoModeloAutocomplete
              id="select-field-tipo"
              key="select-field-tipo"
              name="tipo"
              label={t('com.muralis.qcx.tipoDoModelo')}
              initialValues={values}
              disabled
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={5}
          >
            <QCXSelectTipoCalculoAutocomplete
              id="select-field-tipo-calculo"
              key="select-field-tipo-calculo"
              name="tipoCalculo.id"
              label={t('com.muralis.qcx.tipoCalculo')}
              initialValues={values}
              disabled
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={8}
            md={4}
          >
            <QCXSelectMoedaAutocomplete
              id="select-field-moeda-negociada"
              key="select-field-moeda-negociada"
              name="moeda.id"
              label={t('com.muralis.qcx.moeda.moedaNegociada')}
              fieldProps={{ validate: requiredValidator }}
              initialValues={values}
              disabled={(
                isConsult
                || isSubConsult
                || isSubNone
                || !isValid(values?.despesaReceita?.id)
              )}
              required
            />
          </Grid>
          {showValorFieldBy(values) && (
            <Grid
              item
              xs={12}
              sm={4}
              md={3}
            >
              <QCXFinalAlternativeCurrencyField
                id="currency-field-modelo-lancamento-valor"
                key="currency-field-modelo-lancamento-valor"
                name="valor"
                label={t('com.muralis.qcx.valor')}
                validate={requiredValidator}
                maxLength={21}
                disabled={(
                  isConsult
                  || isSubConsult
                  || isSubNone
                  || !isValid(values?.despesaReceita?.id)
                )}
                required
              />
            </Grid>
          )}
        </QCXRegistrationOneToManyPageTemplate>
      )}
    </QCXFormStepWizard>
  );
}
