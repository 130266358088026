import React from 'react';
import QCXFinalFieldWrapper from '../final-field/QCXFinalFieldWrapper';
import QCXMultilineTextField from '../text-field/QCXMultilineTextField';

export default function QCXFinalMultilineTextField({
  name,
  label,
  type,
  required,
  validate,
  children,
  remaningCharactersInfo,
  ...restProps
}) {
  return (
    <QCXFinalFieldWrapper
      name={name}
      type={type}
      label={label}
      required={required}
      component={QCXMultilineTextField}
      validate={validate}
      remaningCharactersInfo={remaningCharactersInfo}
      {...restProps}
    />
  );
}
