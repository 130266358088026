import {
  Assignment as MdAssignmentIcon,
  Ballot as MdBallotIcon,
  FlightLand as MdFlightLandIcon,
  SurroundSound as MdSurroundSoundIcon,
  Traffic as MdTrafficIcon,
  LocalShipping as MdLocalShippingIcon,
  Receipt as MdReceiptIcon,
  Unarchive as MdUnarchiveIcon,
  AssignmentTurnedIn as MdAssignmentTurnedInIcon,
  AccountBalance as MdAccountBalanceIcon,
  IntegrationInstructions as MdOutlineIntegrationIcon,
  DirectionsBoatFilled as MdDirectionsBoatFilledIcon,
} from '@mui/icons-material';
import {
  GrMoney as GrMoneyIcon,
  GrDeliver as GrDeliverIcon,
  GrInspect as GrInspectIcon,
} from 'react-icons/gr';
import {
  FaWarehouse as FaWarehouseIcon,
  FaFileInvoiceDollar as FaFileInvoiceDollarIcon,
} from 'react-icons/fa';
import {
  RiFileTransferFill as RiFileTransferFillIcon,
  RiTruckLine as RiTruckLineIcon,
  RiBillLine as RiBillLineIcon,
} from 'react-icons/ri';
import {
  GiReceiveMoney as GiReceiveMoneyIcon,
} from 'react-icons/gi';
import {
  SiSemanticrelease as SiSemanticReleaseIcon,
} from 'react-icons/si';
import {
  HiDocumentReport as HiDocumentReportIcon,
} from 'react-icons/hi';
import { isFunction } from 'lodash';
import { getOptionByValueInList } from '../../utils/general/general-utils';

const MD_ASSIGNMENT_ICON = 'MdAssignment';
const MD_BALLOT_ICON = 'MdBallot';
const MD_FLIGHT_LAND_ICON = 'MdFlightLand';
const MD_SURROUND_SOUND_ICON = 'MdSurroundSound';
const MD_TRAFFIC_ICON = 'MdTraffic';
const MD_LOCAL_SHIPPING_ICON = 'MdLocalShipping';
const MD_RECEIPT_ICON = 'MdReceipt';
const MD_UNARCHIVE_ICON = 'MdUnarchive';
const MD_ASSIGNMENT_TURNED_IN_ICON = 'MdAssignmentTurnedIn';
const MD_ACCOUNT_BALANCE_ICON = 'MdAccountBalance';
const MD_OUTLINE_INTEGRATION_ICON = 'MdOutlineIntegration';
const MD_DIRECTIONS_BOAT_FILLED_ICON = 'MdDirectionsBoatFill';
const GR_MONEY_ICON = 'GrMoney';
const GR_DELIVER_ICON = 'GrDeliver';
const GR_INSPECT_ICON = 'GrInspect';
const FA_WAREHOUSE_ICON = 'FaWarehouse';
const FA_FILE_INVOICE_DOLLAR_ICON = 'FaFileInvoiceDollar';
const RI_FILE_TRANSFER_FILL_ICON = 'RiFileTransferFill';
const RI_TRUCKLINE_ICON = 'RiTruckLine';
const RI_BILL_LINE_ICON = 'RiBillLine';
const GI_RECEIVE_MONEY_ICON = 'GiReceiveMoney';
const SI_SEMANTIC_RELEASE_ICON = 'SiSemanticRelease';
const HI_DOCUMENT_REPORT_ICON = 'HiDocumentReport';

const ICON_LIST = [
  {
    label: 'Assignment',
    value: MD_ASSIGNMENT_ICON,
    renderComponent: (props) => (
      <MdAssignmentIcon
        htmlColor="#312783"
        {...props}
      />
    ),
  },
  {
    label: 'Ballot',
    value: MD_BALLOT_ICON,
    renderComponent: (props) => (
      <MdBallotIcon
        htmlColor="#312783"
        {...props}
      />
    ),
  },
  {
    label: 'Flight Land',
    value: MD_FLIGHT_LAND_ICON,
    renderComponent: (props) => (
      <MdFlightLandIcon
        htmlColor="#312783"
        {...props}
      />
    ),
  },
  {
    label: 'Surround Sound',
    value: MD_SURROUND_SOUND_ICON,
    renderComponent: (props) => (
      <MdSurroundSoundIcon
        htmlColor="#312783"
        {...props}
      />
    ),
  },
  {
    label: 'Traffic',
    value: MD_TRAFFIC_ICON,
    renderComponent: (props) => (
      <MdTrafficIcon
        htmlColor="#312783"
        {...props}
      />
    ),
  },
  {
    label: 'Local Shipping',
    value: MD_LOCAL_SHIPPING_ICON,
    renderComponent: (props) => (
      <MdLocalShippingIcon
        htmlColor="#312783"
        {...props}
      />
    ),
  },
  {
    label: 'Receipt',
    value: MD_RECEIPT_ICON,
    renderComponent: (props) => (
      <MdReceiptIcon
        htmlColor="#312783"
        {...props}
      />
    ),
  },
  {
    label: 'Unarchive',
    value: MD_UNARCHIVE_ICON,
    renderComponent: (props) => (
      <MdUnarchiveIcon
        htmlColor="#312783"
        {...props}
      />
    ),
  },
  {
    label: 'Assignment Turned In',
    value: MD_ASSIGNMENT_TURNED_IN_ICON,
    renderComponent: (props) => (
      <MdAssignmentTurnedInIcon
        htmlColor="#312783"
        {...props}
      />
    ),
  },
  {
    label: 'Account Balance',
    value: MD_ACCOUNT_BALANCE_ICON,
    renderComponent: (props) => (
      <MdAccountBalanceIcon
        htmlColor="#312783"
        {...props}
      />
    ),
  },
  {
    label: 'Outline Integration',
    value: MD_OUTLINE_INTEGRATION_ICON,
    renderComponent: (props) => (
      <MdOutlineIntegrationIcon
        htmlColor="#312783"
        {...props}
      />
    ),
  },
  {
    label: 'Directions Boat Filled',
    value: MD_DIRECTIONS_BOAT_FILLED_ICON,
    renderComponent: (props) => (
      <MdDirectionsBoatFilledIcon
        htmlColor="#312783"
        {...props}
      />
    ),
  },
  {
    label: 'Money',
    value: GR_MONEY_ICON,
    renderComponent: (props) => (
      <GrMoneyIcon
        fill="#312783"
        stroke="#312783"
        size={21}
        {...props}
      />
    ),
  },
  {
    label: 'Deliver',
    value: GR_DELIVER_ICON,
    renderComponent: (props) => (
      <GrDeliverIcon
        fill="#312783"
        stroke="#312783"
        size={21}
        {...props}
      />
    ),
  },
  {
    label: 'Inspect',
    value: GR_INSPECT_ICON,
    renderComponent: (props) => (
      <GrInspectIcon
        fill="#312783"
        stroke="#312783"
        size={21}
        {...props}
      />
    ),
  },
  {
    label: 'Receive Money',
    value: GI_RECEIVE_MONEY_ICON,
    renderComponent: (props) => (
      <GiReceiveMoneyIcon
        color="#312783"
        size={22}
        {...props}
      />
    ),
  },
  {
    label: 'Warehouse',
    value: FA_WAREHOUSE_ICON,
    renderComponent: (props) => (
      <FaWarehouseIcon
        color="#312783"
        stroke="current"
        size={22}
        {...props}
      />
    ),
  },
  {
    label: 'File Invoice Dollar',
    value: FA_FILE_INVOICE_DOLLAR_ICON,
    renderComponent: (props) => (
      <FaFileInvoiceDollarIcon
        color="#312783"
        size={22}
        {...props}
      />
    ),
  },
  {
    label: 'File Transfer Fill',
    value: RI_FILE_TRANSFER_FILL_ICON,
    renderComponent: (props) => (
      <RiFileTransferFillIcon
        color="#312783"
        size={22}
        {...props}
      />
    ),
  },
  {
    label: 'Truck Line',
    value: RI_TRUCKLINE_ICON,
    renderComponent: (props) => (
      <RiTruckLineIcon
        color="#312783"
        size={22}
        {...props}
      />
    ),
  },
  {
    label: 'Bill Line',
    value: RI_BILL_LINE_ICON,
    renderComponent: (props) => (
      <RiBillLineIcon
        color="#312783"
        size={22}
        {...props}
      />
    ),
  },
  {
    label: 'Semantic Release',
    value: SI_SEMANTIC_RELEASE_ICON,
    renderComponent: (props) => (
      <SiSemanticReleaseIcon
        color="#312783"
        size={22}
        {...props}
      />
    ),
  },
  {
    label: 'Document Report',
    value: HI_DOCUMENT_REPORT_ICON,
    renderComponent: (props) => (
      <HiDocumentReportIcon
        color="#312783"
        size={25}
        {...props}
      />
    ),
  },
];

const getIconOptionByValue = (value) => (
  getOptionByValueInList(ICON_LIST, value)
);

const getIconLabelOptionByValue = (value) => {
  const foundOption = getIconOptionByValue(value);
  return foundOption && foundOption?.label;
};

const getRenderComponentFunctionByOption = (option) => {
  const foundOption = getIconOptionByValue(option?.value);
  return foundOption?.renderComponent;
};

const getIconComponentByValue = (value, props = {}, defaultValue = undefined) => {
  const iconOption = getIconOptionByValue(
    value
  );

  const renderComponent = iconOption?.renderComponent;

  if (isFunction(renderComponent)) {
    return renderComponent(props);
  }

  return defaultValue;
};

const filterIconOptionsByValues = (values = []) => (
  ICON_LIST.filter((option) => (
    values.includes(option.value)
  ))
);

export {
  getIconOptionByValue,
  getIconLabelOptionByValue,
  getRenderComponentFunctionByOption,
  getIconComponentByValue,
  filterIconOptionsByValues,
};

export {
  ICON_LIST,
  MD_ASSIGNMENT_ICON,
  MD_BALLOT_ICON,
  MD_FLIGHT_LAND_ICON,
  MD_SURROUND_SOUND_ICON,
  MD_TRAFFIC_ICON,
  MD_LOCAL_SHIPPING_ICON,
  MD_RECEIPT_ICON,
  MD_UNARCHIVE_ICON,
  MD_ASSIGNMENT_TURNED_IN_ICON,
  MD_ACCOUNT_BALANCE_ICON,
  MD_OUTLINE_INTEGRATION_ICON,
  MD_DIRECTIONS_BOAT_FILLED_ICON,
  GR_MONEY_ICON,
  GR_DELIVER_ICON,
  GR_INSPECT_ICON,
  FA_WAREHOUSE_ICON,
  FA_FILE_INVOICE_DOLLAR_ICON,
  RI_FILE_TRANSFER_FILL_ICON,
  RI_TRUCKLINE_ICON,
  RI_BILL_LINE_ICON,
  GI_RECEIVE_MONEY_ICON,
  SI_SEMANTIC_RELEASE_ICON,
  HI_DOCUMENT_REPORT_ICON,
};
