import React, { useEffect } from 'react';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import 'moment/locale/pt-br';
import 'moment/locale/es';
import { useGetUsuarioLocale } from '../../ts/common/hooks/keycloak/useGetUsuarioLocale';

export default function QCXPicker({ children, ...restProps }) {
  const locale = useGetUsuarioLocale();

  useEffect(() => {
    moment.locale(locale);
  }, [locale]);

  return (
    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={locale} {...restProps}>
      {children}
    </MuiPickersUtilsProvider>
  );
}
