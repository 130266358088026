import ListAltIcon from "@mui/icons-material/ListAlt";
import LabelIcon from "@mui/icons-material/Label";
import DnsIcon from "@mui/icons-material/Dns";
import DataUsageIcon from "@mui/icons-material/DataUsage";
import InputIcon from "@mui/icons-material/Input";
import PublicIcon from "@mui/icons-material/Public";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import { SvgIconComponent } from "@mui/icons-material";
import ExtensionIcon from "@mui/icons-material/Extension";
import AssessmentIcon from "@mui/icons-material/Assessment";
import AssistantIcon from "@mui/icons-material/Assistant";
import {
  ASSESSORIA_FOLLOWUP,
  ApplicationPermission,
  MODULOS,
} from "../../../hooks/useHasPermission/permissionsEnum";

export type DrawerRoute = {
  name: string;
  path?: string;
  icon: SvgIconComponent;
  nestedRoutes?: DrawerRoute[];
  permissionNeeded?: ApplicationPermission[];
};

const getDrawerRoutes = (path: string): DrawerRoute[] => {
  const leftDrawerRoutes: DrawerRoute[] = [
    {
      name: "Cadastros",
      icon: ListAltIcon,
      nestedRoutes: [
        {
          name: "Gerais",
          path: "/cadastros/gerais",
          icon: LabelIcon,
        },
        {
          name: "Operacionais",
          path: "/cadastros/operacionais",
          icon: DnsIcon,
        },
        {
          name: "Financeiros",
          path: "/cadastros/financeiros",
          icon: DataUsageIcon,
        },
      ],
      permissionNeeded: [MODULOS.CADASTROS],
    },
    {
      name: "Assessoria",
      path: "/assessoria",
      icon: AssistantIcon,
      permissionNeeded: [ASSESSORIA_FOLLOWUP.VISUALIZAR],
    },
    {
      name: "Importação",
      path: "/importacao",
      icon: InputIcon,
      permissionNeeded: [MODULOS.IMPORTACAO],
    },
    {
      name: "Exportação",
      path: "/exportacao",
      icon: PublicIcon,
      permissionNeeded: [MODULOS.EXPORTACAO],
    },
    {
      name: "Financeiro",
      path: "/financeiro",
      icon: AccountBalanceIcon,
      permissionNeeded: [MODULOS.FINANCEIRO],
    },
    {
      name: "Comercial",
      path: "/comercial",
      icon: MonetizationOnIcon,
    },
    {
      name: "Integração",
      path: "/integracao",
      icon: ExtensionIcon,
    },
    {
      name: "Relatórios",
      path: "/relatorios",
      icon: AssessmentIcon,
    },
  ];
  return leftDrawerRoutes;
};

export { getDrawerRoutes };
