import { useContext, useMemo } from 'react';
import WizardContext from '../../../contexts/components/wizard/WizardContext';

export default function useWizardState() {
  const { step } = useContext(WizardContext);

  const wizardState = useMemo(() => ({
    currentStep: step.current,
    ignoreRules: step.ignoreRules,
    steps: step.list,
    completedSteps: step.completeds,
    conditionalSteps: step.conditionals,
    optionalSteps: step.optionals,
    specialSteps: step.specials,
  }), [step]);

  return wizardState;
}
