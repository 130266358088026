import { getOptionByValueInList } from '../../utils/general/general-utils';

const TIPO_ACORDO_TARIFARIO_ALADI = 'ALADI';
const TIPO_ACORDO_TARIFARIO_OMC = 'OMC';
const TIPO_ACORDO_TARIFARIO_SGPC = 'SGPC';

const TIPO_ACORDO_TARIFARIO_LIST = [
  {
    label: 'ALADI',
    value: TIPO_ACORDO_TARIFARIO_ALADI,
  },
  {
    label: 'OMC',
    value: TIPO_ACORDO_TARIFARIO_OMC,
  },
  {
    label: 'SGPC',
    value: TIPO_ACORDO_TARIFARIO_SGPC,
  },
];

const isTipoAcordoTarifarioAladi = (value) => (
  TIPO_ACORDO_TARIFARIO_ALADI === value
);

const isTipoAcordoTarifarioOmc = (value) => (
  TIPO_ACORDO_TARIFARIO_OMC === value
);

const isTipoAcordoTarifarioSgpc = (value) => (
  TIPO_ACORDO_TARIFARIO_SGPC === value
);

const getTipoAcordoTarifarioLabelByValue = (value) => {
  const foundOption = getOptionByValueInList(TIPO_ACORDO_TARIFARIO_LIST, value);
  return foundOption && foundOption?.label;
};

export {
  TIPO_ACORDO_TARIFARIO_ALADI,
  TIPO_ACORDO_TARIFARIO_OMC,
  TIPO_ACORDO_TARIFARIO_SGPC,
  TIPO_ACORDO_TARIFARIO_LIST,
};

export {
  isTipoAcordoTarifarioAladi,
  isTipoAcordoTarifarioOmc,
  isTipoAcordoTarifarioSgpc,
  getTipoAcordoTarifarioLabelByValue,
};
