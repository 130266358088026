import { Grid } from '@material-ui/core';
import React, { useCallback, useEffect, useMemo } from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { useTranslation } from 'react-i18next';
import { AUTO_LOAD_CONTROL } from '../../features/config-control';
import { selectEmbarcacao } from '../../features/embarcacoes/embarcacoesSlice';
import { paisActions } from '../../features/pais/paisSlice';
import { estadoActions } from '../../features/estado/estadoSlice';
import QCXFinalForm from '../../shared-components/final-form/QCXFinalForm';
import QCXFinalTextField from '../../shared-components/final-text-field/QCXFinalTextField';
import QCXSelectPaisAutocomplete from '../../shared-components/select-pais/QCXSelectPaisAutocomplete';
import { required as requiredValidator } from '../../utils/validators/field/validator';
import QCXFinalSelectLocalPortoAeroportoAutocomplete from '../../shared-components/final-select-local-porto-aeroporto/QCXFinalSelectLocalPortoAeroportoAutocomplete';
import QCXSelectEstadoAutocomplete from '../../shared-components/select-estado/QCXSelectEstadoAutocomplete';

export default function QCXPortoAeroportoForm({
  handleSubmit,
  handleChangeToConsult,
  handleChangeToUpdate,
  handleCancelUpdate,
  isConsult,
  isUpdate,
  ...restProps
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const embarcacao = useSelector(selectEmbarcacao);

  useEffect(() => {
    dispatch(
      paisActions.changeControlTo(AUTO_LOAD_CONTROL)
    );
  }, []);

  const paisOnchange = useCallback((form) => (value) => {
    form.change('estado.id', undefined);
    form.change('pais.id', value);
    dispatch(estadoActions.fetchByFilterAsync([{ name: 'pais', value }]));
  }, []);

  const estadoOnchange = useCallback((form) => (value) => {
    form.change('estado.id', value);
  }, []);

  const onChangeListeners = useMemo(() => (
    [
      {
        name: 'pais.id',
        fn: paisOnchange,
      },
      {
        name: 'estado.id',
        fn: estadoOnchange,
      },
    ]
  ), [paisOnchange, estadoOnchange]);

  return (
    <>
      <QCXFinalForm
        isConsult={isConsult}
        isUpdate={isUpdate}
        initialValues={isConsult || isUpdate ? { ...embarcacao } : {}}
        handleSubmitValues={handleSubmit}
        handleChangeToUpdate={handleChangeToUpdate}
        handleCancelUpdate={handleCancelUpdate}
        onChangeListeners={onChangeListeners}
        {...restProps}
      >
        {({ values }) => (
          <>

            <Grid item xs={12} sm={8} md={4}>
              <QCXFinalTextField
                name="description"
                label={t('com.muralis.qcx.nome')}
                id="text-Nome-field"
                key="text-Nome-field"
                required
                disabled={isConsult}
                validate={requiredValidator}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <QCXFinalTextField
                name="sigla"
                label={t('com.muralis.qcx.sigla')}
                id="text-Sigla-field"
                key="text-Sigla-field"
                disabled={isConsult}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <QCXSelectPaisAutocomplete
                name="pais.id"
                label={t('com.muralis.qcx.endereco.pais')}
                id="autocomplete-Pais-field"
                key="autocomplete-Pais-field"
                required
                fieldProps={{ validate: requiredValidator }}
                disabled={isConsult}
                initialValues={values}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={4}
              md={4}
            >
              <QCXSelectEstadoAutocomplete
                id="autocomplete-estado-field"
                key="autocomplete-estado-field"
                name="estado.id"
                label={t('com.muralis.qcx.endereco.estado')}
                fieldProps={{ validate: requiredValidator }}
                disabled={isConsult}
                required
                initialValues={values}
              />
            </Grid>

            <Grid item xs={12} sm={4} md={4}>
              <QCXFinalSelectLocalPortoAeroportoAutocomplete
                id="text-Local-field"
                key="text-Local-field"
                name="local"
                label={t('com.muralis.qcx.local.label')}
                initialValues={values}
                fieldProps={{ validate: requiredValidator }}
                disabled={isConsult}
                required
              />
            </Grid>

          </>
        )}
      </QCXFinalForm>
    </>
  );
}
