import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  setCamposAdicionais,
  setModeloFaturamento,
  setModeloFollowUp,
  setModeloNumerario,
  setTabelaSda,
  setUnidadesNegocio,
} from '../../features/servico/servicoSlice';
import QCXFinalFormWizard from '../../shared-components/final-form-wizard/QCXFinalFormWizard';
import QCXServicoTecnicalDataForm from './QCXServicoTecnicalDataForm';
import QCXSelectOneModeloFollowUpFinalFormTable from '../modelo-follow-up/QCXSelectOneModeloFollowUpFinalFormTable';
import QCXSelectOneModeloFaturamentoFinalFormTable from '../modelo-faturamento/QCXSelectOneModeloFaturamentoFinalFormTable';
import QCXSelectOneModeloNumerarioFinalFormTable from '../modelo-numerario/QCXSelectOneModeloNumerarioFinalFormTable';
import QCXSelectOneTabelaSdaFinalFormTable from '../tabela-sda/QCXSelectOneTabelaSdaFinalFormTable';
import QCXSelectManyUnidadeNegocioFinalFormTable from '../unidade-negocio/QCXSelectManyUnidadeNegocioFinalFormTable';
import SelectManyCamposAdicionaisTab from '../../pages/module/registrations/general/servico/ServicoRegistrationPage/components/SelectManyCamposAdicionaisTab';

export default function QCXServicoWizardFinalForm({
  handleSubmit,
  handleAlternativeSave,
  handleChangeToPreparingAction,
  model,
  handleChangeModel,
  handleChangeToCreate,
  handleChangeToConsult,
  handleChangeToUpdate,
  handleCancelUpdate,
  handleChangeToBackgroundCreate,
  handleResetBackgroundMode,
  isCreate,
  isConsult,
  isUpdate,
  isBackgroundCreate,
  isPreparingAction,
  authInfo = {},
  requiredRoles = [],
  ...restProps
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const unidadesNegocio = useMemo(() => (
    model?.unidadesDeNegocio || []
  ), [model]);

  const camposAdicionais = useMemo(() => (
    model?.camposAdicionais || []
  ), [model]);

  const modeloNumerario = useMemo(() => (
    model?.modeloNumerario
  ), [model]);

  const modeloFaturamento = useMemo(() => (
    model?.modeloFaturamento
  ), [model]);

  const tabelaSda = useMemo(() => (
    model?.tabelaSda
  ), [model]);

  const modeloFollowUp = useMemo(() => (
    model?.modeloFollowUp
  ), [model]);

  const handleSetSelectedUnidadesNegocio = (data) => {
    dispatch(setUnidadesNegocio(data));
  };

  const handleSetSelectedModeloNumerario = (data) => {
    dispatch(setModeloNumerario(data));
  };

  const handleSetSelectedModeloFaturamento = (data) => {
    dispatch(setModeloFaturamento(data));
  };

  const handleSetSelectedTabelaSda = (data) => {
    dispatch(setTabelaSda(data));
  };

  const handleSetSelectedModeloFollowUp = (data) => {
    dispatch(setModeloFollowUp(data));
  };

  const handleSetSelectedCamposAdicionais = (data) => {
    dispatch(setCamposAdicionais(data));
  };

  const checkIfIsAlternativeSaveByStep = (currentStep) => (
    [1, 2, 3, 4, 5].includes(currentStep)
  );

  const wizardSteps = [
    t('com.muralis.qcx.dadosTecnicos'),
    t('com.muralis.qcx.unidadeNegocio.labelPlural'),
    t('com.muralis.qcx.numerario.label'),
    t('com.muralis.qcx.faturamento.label'),
    t('com.muralis.qcx.tabelaSDA.label'),
    t('com.muralis.qcx.followup.label'),
    'Campos Adicionais'
  ];

  return (
    <QCXFinalFormWizard
      steps={wizardSteps}
      initialValues={model}
      handleChangeValues={handleChangeModel}
      isCreate={isCreate}
      isConsult={isConsult}
      isUpdate={isUpdate}
      isBackgroundCreate={isBackgroundCreate}
      checkIfIsAlternativeSaveByStep={checkIfIsAlternativeSaveByStep}
      handleSubmit={handleSubmit}
      handleAlternativeSave={handleAlternativeSave}
      handleChangeToCreate={handleChangeToCreate}
      handleChangeToBackgroundCreate={handleChangeToBackgroundCreate}
      handleChangeToConsult={handleChangeToConsult}
      handleChangeToUpdate={handleChangeToUpdate}
      handleCancelUpdate={handleCancelUpdate}
      handleResetBackgroundMode={handleResetBackgroundMode}
      {...restProps}
    >
      {(formProps) => (
        <QCXServicoTecnicalDataForm
          key="ficha-dados-tecnicos-servico"
          isConsult={isConsult}
          isUpdate={isUpdate}
          authInfo={authInfo}
          requiredRoles={requiredRoles}
          {...formProps}
        />
      )}
      {({ controlComponentProps, ...restFormProps }) => (
        <QCXSelectManyUnidadeNegocioFinalFormTable
          key="ficha-unidade-negocio-servico"
          list={unidadesNegocio}
          isConsult={isConsult}
          handleChange={handleSetSelectedUnidadesNegocio}
          controlComponentProps={controlComponentProps}
          authInfo={authInfo}
          requiredRoles={requiredRoles}
          {...restFormProps}
        />
      )}
      {({ controlComponentProps, ...restFormProps }) => (
        <QCXSelectOneModeloNumerarioFinalFormTable
          key="ficha-modelo-numerario-servico"
          selected={modeloNumerario}
          isConsult={isConsult}
          handleChange={handleSetSelectedModeloNumerario}
          controlComponentProps={controlComponentProps}
          authInfo={authInfo}
          requiredRoles={requiredRoles}
          {...restFormProps}
        />
      )}
      {({ controlComponentProps, ...restFormProps }) => (
        <QCXSelectOneModeloFaturamentoFinalFormTable
          key="ficha-modelo-faturamento-servico"
          selected={modeloFaturamento}
          isConsult={isConsult}
          handleChange={handleSetSelectedModeloFaturamento}
          controlComponentProps={controlComponentProps}
          authInfo={authInfo}
          requiredRoles={requiredRoles}
          {...restFormProps}
        />
      )}
      {({ controlComponentProps, ...restFormProps }) => (
        <QCXSelectOneTabelaSdaFinalFormTable
          key="ficha-tabela-sda-servico"
          selected={tabelaSda}
          isConsult={isConsult}
          handleChange={handleSetSelectedTabelaSda}
          controlComponentProps={controlComponentProps}
          authInfo={authInfo}
          requiredRoles={requiredRoles}
          {...restFormProps}
        />
      )}
      {({ controlComponentProps, ...restFormProps }) => (
        <QCXSelectOneModeloFollowUpFinalFormTable
          key="ficha-modelo-follow-up-servico"
          selected={modeloFollowUp}
          isConsult={isConsult}
          handleChange={handleSetSelectedModeloFollowUp}
          controlComponentProps={controlComponentProps}
          authInfo={authInfo}
          requiredRoles={requiredRoles}
          {...restFormProps}
        />
      )}
      {({ controlComponentProps, ...restFormProps }) => (
        <SelectManyCamposAdicionaisTab
          key="ficha-campos-adicionais-servico"
          list={camposAdicionais}
          isConsult={isConsult}
          handleChange={handleSetSelectedCamposAdicionais}
          controlComponentProps={controlComponentProps}
          authInfo={authInfo}
          requiredRoles={requiredRoles}
          {...restFormProps}
        />
      )}
    </QCXFinalFormWizard>
  );
}
