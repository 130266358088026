import {
  doGetQCXRequest,
} from '../../utils/api/domain/domain-api-utils';
import { MOTIVO_ADMISSAO_TEMPORARIA_ENDPOINT } from '../../utils/api/domain/domain-endpoint-utils';

export async function fetchAll() {
  return doGetQCXRequest([
    MOTIVO_ADMISSAO_TEMPORARIA_ENDPOINT
  ]);
}

export async function fetchById(id) {
  return doGetQCXRequest([
    MOTIVO_ADMISSAO_TEMPORARIA_ENDPOINT,
    id
  ]);
}
