import { Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { AUTO_LOAD_CONTROL } from '../../features/config-control';
import { paisActions } from '../../features/pais/paisSlice';
import QCXFinalForm from '../../shared-components/final-form/QCXFinalForm';
import QCXFinalTextField from '../../shared-components/final-text-field/QCXFinalTextField';
import QCXSelectPaisAutocomplete from '../../shared-components/select-pais/QCXSelectPaisAutocomplete';
import { required as requiredValidator } from '../../utils/validators/field/validator';

export default function QCXEmbarcacaoForm({
  isConsult,
  isUpdate,
  initialValues,
  handleSubmit,
  handleChangeToUpdate,
  handleCancelUpdate,
  ...restProps
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      paisActions.changeControlTo(AUTO_LOAD_CONTROL)
    );
  }, []);

  return (
    <>
      <QCXFinalForm
        isConsult={isConsult}
        isUpdate={isUpdate}
        initialValues={initialValues}
        handleSubmitValues={handleSubmit}
        handleChangeToUpdate={handleChangeToUpdate}
        handleCancelUpdate={handleCancelUpdate}
        {...restProps}
      >
        {({ values }) => (
          <>
            {(isConsult || isUpdate) && (
              <Grid item xs={12} sm={6} md={2}>
                <QCXFinalTextField
                  id="text-field-code"
                  key="text-field-code"
                  name="id"
                  label={t('com.muralis.qcx.codigo')}
                  disabled
                />
              </Grid>
            )}
            <Grid
              item
              xs={12}
              sm={6}
              md={(isConsult || isUpdate) ? 6 : 8}
            >
              <QCXFinalTextField
                name="description"
                label={t('com.muralis.qcx.nome')}
                maxLength={250}
                disabled={isConsult}
                validate={requiredValidator}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <QCXSelectPaisAutocomplete
                name="pais.id"
                label={t('com.muralis.qcx.endereco.pais')}
                fieldProps={{ validate: requiredValidator }}
                disabled={isConsult}
                initialValues={values}
                required
              />
            </Grid>
          </>
        )}
      </QCXFinalForm>
    </>
  );
}
