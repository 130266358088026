import { ExpandMore, ExpandLess, StarBorder } from '@mui/icons-material';
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useFindPath } from '../../hooks/useFindPath';
import { handleNavigation, isItemActive } from './helpers/leftDrawerHelpers';
import { DrawerRoute } from './routes/leftDrawerRoutes';

const LeftDrawerGroup = ({ route }: { route: DrawerRoute }) => {
  const [open, setOpen] = useState(false);
  const path = useFindPath();
  const isActive = isItemActive(path, route);
  const textColor = isActive ? 'secondary' : 'black';
  const history = useHistory();

  /**
   * It sets the state of the open variable to the opposite of what it currently is.
   * @param {any} e - any - this is the event that is triggered when the button is clicked.
   */
  const handleClick = (e: any) => {
    setOpen(!open);
  };

  return (
    <>
      <ListItemButton onClick={handleClick} selected={isActive}>
        <ListItemIcon style={{ marginRight: '-22px' }}>
          <route.icon color={isActive ? 'secondary' : 'primary'} />
        </ListItemIcon>
        <ListItemText
          disableTypography
          primary={
            <Typography
              position={'relative'}
              top={'3px'}
              left={'-2px'}
              color={textColor}
              fontSize={14}
            >
              {route.name.toUpperCase()}
            </Typography>
          }
        />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {route.nestedRoutes?.map((nestedRoute) => {
            const isActive = isItemActive(path, nestedRoute);
            const textColor = isActive ? 'secondary' : 'black';
            return (
              <ListItemButton
                key={nestedRoute.name}
                sx={{ pl: 4 }}
                selected={isActive}
                onClick={() => handleNavigation(nestedRoute.path, history)}
              >
                <ListItemIcon>
                  <nestedRoute.icon
                    color={isActive ? 'secondary' : 'primary'}
                  />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography color={textColor} fontSize={14}>
                      {nestedRoute.name.toUpperCase()}
                    </Typography>
                  }
                />
              </ListItemButton>
            );
          })}
        </List>
      </Collapse>
    </>
  );
};

export default LeftDrawerGroup;
