import { createFilterOptions } from '@material-ui/lab/useAutocomplete';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormState } from 'react-final-form';
import QCXAutocompleteSelect from '../../autocomplete-select/QCXAutocompleteSelect';
import { fetchAllAsync, selectUsuarios } from '../../../features/usuario/usuarioSlice';

export default function AnalistaResponsavelSelect({
  id,
  key,
  label,
  name,
  ...restProps
}) {
  const dispatch = useDispatch();

  const { values } = useFormState();

  const list = useSelector(selectUsuarios);

  const [defaultValue, setDefaultValue] = useState('');

  useEffect(() => {
    const handleFetch = () => {
      dispatch(fetchAllAsync({ loadAnalistas: true }));
    };

    handleFetch();
  }, []);

  useEffect(() => {
    const value = list.find((item) => (
      `${item?.nome} - ${item?.email}` === _.get(values, name)
    ));

    if (value) {
      setDefaultValue(value);
    } else {
      setDefaultValue(null);
    }
  }, [list, values]);

  const filterOptions = createFilterOptions({
    stringify: (item) => `${item?.nome} - ${item?.email}`,
  });

  return (
    <QCXAutocompleteSelect
      id={id}
      key={key}
      label={label}
      name={name}
      options={list}
      filterOptions={filterOptions}
      defaultValue={defaultValue}
      getOptionValue={(item) => `${item?.nome} - ${item?.email}`}
      getOptionLabel={(item) => (
        item?.nome && item?.email
          ? `${item?.nome} - ${item?.email}`
          : ''
      )}
      {...restProps}
    />
  );
}
