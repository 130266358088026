import {
  doGetQCXRequest,
} from '../../utils/api/domain/domain-api-utils';
import {
  UNIDADE_NEGOCIO_ENDPOINT,
  NOTIFICACAO_ENDPOINT,
} from '../../utils/api/domain/domain-endpoint-utils';

async function fetchByUnidadeDeNegocio(id) {
  return doGetQCXRequest([
    NOTIFICACAO_ENDPOINT,
    UNIDADE_NEGOCIO_ENDPOINT,
    id
  ]);
}

const notificacaoAPI = {
  fetchByUnidadeDeNegocio,
};

export {
  fetchByUnidadeDeNegocio,
  notificacaoAPI,
};
