import { useKeycloak } from '@react-keycloak/web';
import { useMemo } from 'react';
import { KeycloakTokenParsedExtended } from './interfaces';

export function useGetNomeUsuarioResumido() {
  const { keycloak } = useKeycloak();

  const keyCloakToken = useMemo(() => {
    return keycloak?.tokenParsed as KeycloakTokenParsedExtended;
  }, [keycloak]);

  const nomeResumido = useMemo(() => {
    const nome = keyCloakToken?.family_name;
    if (nome.length <= 14) {
      return nome;
    }

    return nome.substring(0, 11);
  }, [keyCloakToken]);

  return nomeResumido ?? 'Usuário';
}
