import { createFilterOptions } from '@material-ui/lab/useAutocomplete';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import QCXAutocompleteSelect from '../autocomplete-select/QCXAutocompleteSelect';

import {
  fetchAllOperatorsAsync,
} from '../../features/follow-up/followUpThunks';

import {
  selectConfigControl,
  selectFollowUps,
} from '../../features/follow-up/followUpSelectors';
import { isAutoLoadControl } from '../../utils/store/store-utils';

export default function QCXSelecProcessoAutocompleteField({
  label,
  name,
  initialValues,
  ...restProps
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [defaultValue, setDefaultValue] = useState('');
  const [filteredFollowUpsActive, setFilteredFollowUpsActive] = useState([]);
  const [internalLoading, setInternalLoading] = useState(true);

  const list = useSelector(selectFollowUps);
  const configControl = useSelector(selectConfigControl);

  useEffect(() => {
    if (!_.isEmpty(list)) {
      (_.debounce(() => (
        setInternalLoading(false)
      ), 2000))();
    }
  }, [list]);

  useEffect(() => {
    if (isAutoLoadControl(configControl)) {
      dispatch(fetchAllOperatorsAsync());
    }
  }, [configControl]);

  useEffect(() => {
    const filterFollowUpsActive = list.filter((item) => item?.active);

    if (filterFollowUpsActive) {
      setFilteredFollowUpsActive(filterFollowUpsActive);
    }
  }, [list, setFilteredFollowUpsActive]);

  useEffect(() => {
    const value = list.find((item) => (
      item?.id === _.get(initialValues, name)
    ));

    if (value) {
      setDefaultValue(value);
    } else {
      setDefaultValue({ id: null, description: '' });
    }
  }, [list, initialValues]);

  const filterOptions = createFilterOptions({
    stringify: (item) => `${item?.code} - ${item?.description}`,
  });

  return (
    <QCXAutocompleteSelect
      label={label}
      id={name}
      name={name}
      options={filteredFollowUpsActive}
      filterOptions={filterOptions}
      defaultValue={defaultValue}
      getOptionLabel={(item) => (
        item?.numero || ''
      )}
      {...restProps}
      placeholder={(
        internalLoading
          ? t('com.muralis.qcx.carregando')
          : t('com.muralis.qcx.selecione')
      )}
      loading={internalLoading}
      loadingText={t('com.muralis.qcx.carregando')}
      disabled={internalLoading}
    />
  );
}
