import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectUnidadeSelecionada } from '../../../features/usuario-logado/usuarioLogadoSlice';

export const useUnidadeNegocioGuard = () => {
  const history = useHistory();
  const selectedUnidade: number = useSelector(selectUnidadeSelecionada);
  useEffect(() => {
    if (!selectedUnidade) {
      history.push('/erro?e=unidadeDeNegocioNaoSelecionada');
    }
  }, []);
};
