import { SingletonUtils } from '../general-utils';

const NAO_CALCULA_SOBRE_MLE_FRETE = 'NAO_CALCULA_SOBRE_MLE_FRETE';
const CALCULA_SOBRE_MLE_FRETE = 'CALCULA_SOBRE_MLE_FRETE';

const isNaoCalculaSobreMleFrete = (value) => (
  NAO_CALCULA_SOBRE_MLE_FRETE === value
);

const isCalculaSobreMleFrete = (value) => (
  CALCULA_SOBRE_MLE_FRETE === value
);

const parseFromSingletonList = (singletonList = []) => (
  SingletonUtils.fromList(
    isCalculaSobreMleFrete,
    NAO_CALCULA_SOBRE_MLE_FRETE,
    singletonList
  )
);

const parseToSingletonList = (value) => (
  SingletonUtils.toList(
    isNaoCalculaSobreMleFrete,
    value
  )
);

const TipoCalculoSeguroUtils = {
  NAO_CALCULA_SOBRE_MLE_FRETE,
  CALCULA_SOBRE_MLE_FRETE,
  isNaoCalculaSobreMleFrete,
  isCalculaSobreMleFrete,
  parseFromSingletonList,
  parseToSingletonList,
};

export default TipoCalculoSeguroUtils;
