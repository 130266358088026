import React, {
  useMemo,
} from 'react';
import {
  MainModeContext,
  SubModeContext,
  MainBackgroundModeContext,
  SubBackgroundModeContext,
} from '../../contexts/store/modes/ModeContexts';

export default function QCXModeContextProvider({
  value,
  children,
}) {
  const mainModeSelector = useMemo(() => ({
    selector: value?.selectMode,
  }), [value]);

  const subModeSelector = useMemo(() => ({
    selector: value?.selectSubMode,
  }), [value]);

  const mainBackgroundModeSelector = useMemo(() => ({
    selector: value?.selectBackgroundMode,
  }), [value]);

  const subBackgroundModeSelector = useMemo(() => ({
    selector: value?.selectSubBackgroundMode,
  }), [value]);

  return (
    <MainModeContext.Provider
      value={mainModeSelector}
    >
      <SubModeContext.Provider
        value={subModeSelector}
      >
        <MainBackgroundModeContext.Provider
          value={mainBackgroundModeSelector}
        >
          <SubBackgroundModeContext.Provider
            value={subBackgroundModeSelector}
          >

            {children}

          </SubBackgroundModeContext.Provider>

        </MainBackgroundModeContext.Provider>

      </SubModeContext.Provider>

    </MainModeContext.Provider>
  );
}
