import { Grid } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { OnChange } from 'react-final-form-listeners';
import QCXFinalForm from '../../shared-components/final-form/QCXFinalForm';
import QCXFinalTextField from '../../shared-components/final-text-field/QCXFinalTextField';
import { required as requiredValidator } from '../../utils/validators/field/validator';
import QCXFinalCheckboxField from '../../shared-components/final-checkbox-field/QCXFinalCheckboxField';
import TipoDocumentoUtils from '../../utils/general/tipo-documento/TipoDocumentoUtils';

export default function QCXTipoDocumentoFinalForm({
  isCreate,
  isConsult,
  isUpdate,
  initialValues,
  handleSubmit,
  handleChangeToUpdate,
  handleCancelUpdate,
  authInfo = {},
  requiredRoles = [],
  ...restProps
}) {
  const { t } = useTranslation();

  return (
    <QCXFinalForm
      isConsult={isConsult}
      isUpdate={isUpdate}
      initialValues={initialValues}
      handleSubmitValues={handleSubmit}
      handleChangeToUpdate={handleChangeToUpdate}
      handleCancelUpdate={handleCancelUpdate}
      authInfo={authInfo}
      requiredRoles={requiredRoles}
      {...restProps}
    >
      {({ form }) => (
        <>
          {!isCreate && (
            <Grid item xs={12} sm={4} md={2}>
              <QCXFinalTextField
                id="final-text-field-id"
                key="text-field-id"
                label={t('com.muralis.qcx.codigo')}
                name="id"
                disabled
              />
            </Grid>
          )}
          <Grid item xs={12} sm={8} md={6}>
            <QCXFinalTextField
              id="text-field-description"
              key="text-field-description"
              label={t('com.muralis.qcx.documento.tipoDocumento')}
              name="description"
              validate={requiredValidator}
              disabled={isConsult}
              required
            />
          </Grid>
          <Grid item xs={12} sm={4} md={isCreate ? 6 : 4}>
            <QCXFinalCheckboxField
              id="text-field-publico"
              key="text-field-publico"
              label={t('com.muralis.qcx.publico')}
              name="publico"
              value={TipoDocumentoUtils.PUBLICO}
              disabled={isConsult}
            />
          </Grid>
          <Grid item md={6}>
            <QCXFinalTextField
              id="text-field-nomepadrao"
              key="text-field-nomepadrao"
              label={t('com.muralis.qcx.documento.nomePadrao')}
              name="nomePadrao"
              validate={requiredValidator}
              disabled={isConsult}
              required
            />
            <OnChange name="nomePadrao">
              {(value) => {
                if (!value) {
                  return;
                }

                const nomeExemplo = `<numero_processo>_${value}.pdf`;
                form.change('ignorableFields.nomeExemplo', nomeExemplo);
              }}
            </OnChange>
          </Grid>
          <Grid item md={6}>
            <QCXFinalTextField
              id="text-field-nomepadrao"
              key="text-field-nomepadrao"
              label="Exemplo Arquivo PDF:"
              name="ignorableFields.nomeExemplo"
              disabled
            />
          </Grid>
        </>
      )}
    </QCXFinalForm>
  );
}
