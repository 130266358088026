import {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { saveAs } from 'file-saver';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { downloaderActions } from '../../../features/downloader/downloaderSlice';
import { downloaderThunks } from '../../../features/downloader/downloaderThunks';
import { downloaderSelectors } from '../../../features/downloader/downloaderSelectors';
import { AUTO_DOWNLOAD_CONTROL, IDLE_CONTROL } from '../../../features/config-control';
import { isIdleControl, isAutoDownloadControl, isSuccessStatus } from '../../store/store-utils';

export default function useDownloader({
  autoDownload = false,
}) {
  const dispatch = useDispatch();

  const status = useSelector(downloaderSelectors.selectStatus);
  const control = useSelector(downloaderSelectors.selectConfigControl);
  const data = useSelector(downloaderSelectors.selectData);
  const metadata = useSelector(downloaderSelectors.selectMetadata);

  const [callbackFn, setCallbackFn] = useState(undefined);

  const idleControl = useMemo(() => (
    isIdleControl(control)
  ), [control]);

  const autoDownloadControl = useMemo(() => (
    isAutoDownloadControl(control)
  ), [control]);

  const isSuccess = useMemo(() => (
    isSuccessStatus(status)
  ), [status]);

  const availableAutoDownload = useMemo(() => (
    autoDownloadControl
    && isSuccess
    && data
  ), [
    data,
    isSuccess,
    autoDownloadControl,
  ]);

  const blob = useMemo(() => (
    data
      ? new Blob([data])
      : undefined
  ), [data]);

  const changeControlIfNeededTo = useCallback((mode, condition) => {
    if (autoDownload && condition) {
      dispatch(
        downloaderActions.changeControlTo(
          mode
        )
      );
    }
  }, [autoDownload]);

  const downloadFileById = useCallback((id) => {
    dispatch(
      downloaderThunks.downloadFileByIdAsync({
        data: {
          id,
        },
      })
    );
  }, []);

  const downloadMultipleFilesBy = useCallback((filesList, callbackOnSuccess) => {
    dispatch(
      downloaderThunks.downloadMultipleFilesByAsync({
        data: filesList,
      })
    );

    setCallbackFn(() => (
      callbackOnSuccess
    ));
  }, [setCallbackFn]);

  const saveCurrentFile = useCallback(() => {
    if (isSuccessStatus(status) && blob) {
      saveAs(blob, metadata.fileName);

      dispatch(downloaderActions.reset());
    }
  }, [
    blob,
    status,
    metadata,
  ]);

  useEffect(() => {
    if (availableAutoDownload) {
      saveCurrentFile();
    }
  }, [
    availableAutoDownload,
    saveCurrentFile,
  ]);

  useEffect(() => {
    if (isSuccess) {
      if (_.isFunction(callbackFn)) {
        callbackFn();
        setCallbackFn(undefined);
      }
    }
  }, [
    isSuccess,
    callbackFn,
    setCallbackFn,
  ]);

  const downloader = useMemo(() => ({
    data,
    status,
    idleControl,
    autoDownloadControl,
    availableAutoDownload,
    saveCurrentFile,
    downloadFileById,
    downloadMultipleFilesBy,
  }), [
    data,
    status,
    idleControl,
    autoDownloadControl,
    availableAutoDownload,
    saveCurrentFile,
    downloadFileById,
    downloadMultipleFilesBy,
  ]);

  useEffect(() => {
    changeControlIfNeededTo(
      AUTO_DOWNLOAD_CONTROL,
      idleControl
    );

    return () => {
      changeControlIfNeededTo(
        IDLE_CONTROL,
        autoDownloadControl
      );

      dispatch(downloaderActions.reset());
    };
  }, [
    idleControl,
    changeControlIfNeededTo,
    autoDownloadControl,
  ]);

  return downloader;
}
