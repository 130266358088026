import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  targets: [],
};

const inspectorSlice = createSlice({
  name: 'inspector',
  initialState,
  reducers: {
    addTarget: (state, action) => {
      state.targets = [...state.targets, action.payload];
    },
    resetTargets: (state) => {
      state.targets = [];
    },
  },
});

const {
  addTarget,
  resetTargets,
} = inspectorSlice.actions;

const selectInspectorTargets = (state) => state.inspector.targets;

export {
  inspectorSlice,
  addTarget,
  resetTargets,
  selectInspectorTargets,
};

export default inspectorSlice.reducer;
