import { Container, Grid, makeStyles } from "@material-ui/core";
import { DynamicFeed } from "@material-ui/icons";
import { useHistory } from "react-router";
import { MediaType } from "../../../../utils/api/api-utils";
import StyledFormTitle from "../../../common/components/typographies/StyledFormTitle";
import PageLayout from "../../../common/layouts/pageLayout";
import { FollowUpFormPayload } from "../newAssessoriaFollowupPage/followupForm/followupForm";
import { useUpdateFollowUpAssessoria } from "./hooks/useUpdateFollowUpAssessoria";
import ViewFollowupForm from "./viewFollowupForm/viewFollowupForm";
import { useHasPermission } from "../../../common/hooks/useHasPermission/useHasPermission";
import { ASSESSORIA_FOLLOWUP } from "../../../common/hooks/useHasPermission/permissionsEnum";

type Props = {};

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    padding: "0 1rem 3rem 1rem",
  },
}));

function ViewAssessoriaFollowupPage({}: Props) {
  const classes = useStyles();

  const history = useHistory();

  function postSubmit(payload: any) {
    history.push(`/assessoria/followup?code=${payload.numero}`);
  }

  const [updateFollowUp] = useUpdateFollowUpAssessoria({
    onSettled: postSubmit,
  });

  function handleSubmit(followUp: FollowUpFormPayload) {
    const formData = new FormData();

    const json = JSON.stringify(followUp);
    const blob = new Blob([json], {
      type: MediaType.APPLICATION_JSON,
    });

    formData.append("data", blob);

    const meta = { metadata: [] };
    const jsonMeta = JSON.stringify(meta);
    const blobMeta = new Blob([jsonMeta], {
      type: MediaType.APPLICATION_JSON,
    });

    formData.append("metadata", blobMeta);

    updateFollowUp({ requestFormData: formData });
  }

  return (
    <PageLayout title={"FollowUp"} icon={<DynamicFeed color={"secondary"} />}>
      <Container className={classes.container}>
        <Grid container style={{ marginTop: "0.2rem" }}>
          <Grid item xs={12} style={{ marginBottom: "1.4rem" }}>
            <StyledFormTitle title="Dados:" />
          </Grid>
          <ViewFollowupForm submitCallback={handleSubmit}></ViewFollowupForm>
        </Grid>
      </Container>
    </PageLayout>
  );
}

export default ViewAssessoriaFollowupPage;
