import ExtensionIcon from '@mui/icons-material/Extension';
import RttIcon from '@mui/icons-material/Rtt';
import CardsGridSubmenu from '../../../common/components/cardsGrid/cardsGridSubmenu';
import BasicLayout from '../../../common/layouts/basicLayout';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import { CardsGridItem } from '../../../common/components/cardsGrid/cardsGrid';

const cover: CardsGridItem = {
  title: 'Croda',
  icon: RttIcon,
  path: '/integracao',
};

const gridItems: CardsGridItem[] = [
  {
    title: 'Escrita',
    icon: DriveFileRenameOutlineIcon,
    path: '/integracao/croda/escrita',
  },
];

const CrodaPage = () => {
  return (
    <BasicLayout
      title={'Croda'}
      icon={<ExtensionIcon color={'secondary'} />}
    >
      <CardsGridSubmenu cover={cover} items={gridItems}></CardsGridSubmenu>
    </BasicLayout>
  );
};

export default CrodaPage;
