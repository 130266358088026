import { Grid, Typography } from '@material-ui/core';
import { Info as InfoIcon } from '@material-ui/icons';
import { useForm, useFormState } from 'react-final-form';
import QCXFormSubtitle from '../../components/form-title/QCXFormSubtitle';
import QCXFinalAlternativeCurrencyField from '../final-currency-field/QCXFinalAlternativeCurrencyField';

export default function QCXDespesasAcessoriasFormGroup({
  isConsult,
  valorValidators,
  handleValorEmReaisOnBlur,
  intl,
}) {
  const form = useForm();
  const { values } = useFormState();
  return (
    <>
      <Grid item xs={12}>
        <QCXFormSubtitle title="Despesas Acessórias" />
      </Grid>
      <Grid
        item
        container
        xs={12}
        sm={12}
        md={4}
        lg={4}
        alignItems="center"
      >
        <Grid item>
          <InfoIcon
            verticalAlign="middle"
            color="secondary"
          />
        </Grid>
        <Grid item style={{ paddingLeft: 10 }}>
          <Typography color="secondary" style={{ textTransform: 'uppercase', fontSize: 12 }}>Serão incluídas na base de cálculo do ICMS</Typography>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={4}
        lg={4}
      >
        <QCXFinalAlternativeCurrencyField
          id="currency-valor-moeda-despesasAcessorias-field"
          key="currency-valor-moeda-despesasAcessorias-field"
          name="despesasAcessorias.valorMoeda"
          label="Valor das Despesas Acessórias na Moeda"
          disabled
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={4}
        lg={4}
      >
        <QCXFinalAlternativeCurrencyField
          id="currency-valor-real-moeda-despesasAcessorias-field"
          key="currency-valor-real-moeda-despesasAcessorias-field"
          name="despesasAcessorias.valorReal"
          label="Valor das Despesas Acessórias em Reais"
          onBlur={handleValorEmReaisOnBlur(form, values, 'mle.moeda', 'despesasAcessorias.valorMoeda', 'despesasAcessorias.valorReal')}
          intl={intl}
          adorned
          disabled={isConsult}
          validate={valorValidators}
        />
      </Grid>
    </>
  );
}
