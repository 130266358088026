import { Grid } from '@material-ui/core';
import React, { useCallback, useMemo } from 'react';
import { isEmpty } from 'lodash';
import { v4 as uuid } from 'uuid';
import { useTranslation } from 'react-i18next';
import { useForm, useFormState } from 'react-final-form';
import i18n from '../../i18n';
import QCXFinalBondManagerOld from '../../shared-components/final-gerenciador-relacionamento/QCXFinalBondManagerOld';
import QCXFormListenerManager from '../../shared-components/form-listener/QCXFormListenerManager';
import {
  vinculoMercadoriaAmparadaPorDUEActions,
  vinculoMercadoriaAmparadaPorDUESelectors,
} from '../../features/vinculo-mercadoria-amparada-por-due/vinculoMercadoriaAmparadaPorDUESlice';
import { fetchById as fetchTipoCEById } from '../../features/tipo-ce/tipoCEAPI';
import QCXFinalTextField from '../../shared-components/final-text-field/QCXFinalTextField';
import { isStrictEquals } from '../../utils/general/general-utils';
import QCXFinalNumericDecimalField from '../../shared-components/final-numeric-decimal-field/QCXFinalNumericDecimalField';
import QCXSelectTipoCEAutocomplete from '../../shared-components/select-tipo-ce/QCXSelectTipoCEAutocomplete';
import QCXSelectNcmSubItemAutocomplete from '../../shared-components/select-ncm-subitem/QCXSelectNcmSubItemAutocomplete';
import { ncmSubitemAPI } from '../../features/ncm-subitem/ncmSubitemAPI';
import QCXFormSubtitle from '../form-title/QCXFormSubtitle';

export default function QCXMercadoriaAmparadaPorFormGroup({
  title = i18n.t('com.muralis.qcx.mercadoria.mercadoriaAmparadaPor'),
  isParentConsult,
  disabled,
  isFilling,
}) {
  const { t } = useTranslation();

  const form = useForm();
  const { values } = useFormState();

  const mercadoriaAmparadaPorListProps = useMemo(
    () => ({
      name: 'due.ncms',
      columns: [
        {
          field: 'tipoCe',
          headerName: t('com.muralis.qcx.tipoCE'),
          headerAlign: 'center',
          align: 'center',
          flex: 90,
          valueGetter: ({ row }) => row?.tipoCe?.description || '-',
        },
        {
          field: 'ncm',
          headerName: t('com.muralis.qcx.NCM.label'),
          headerAlign: 'center',
          align: 'center',
          flex: 250,
          valueGetter: ({ row }) => `${row?.ncm?.code} - ${row?.ncm?.description}` || '-',
        },
        {
          field: 'codigoCe',
          headerName: t('com.muralis.qcx.codigoCE'),
          headerAlign: 'center',
          align: 'center',
          flex: 100,
          valueGetter: ({ row }) => row?.codigoCe || '-',
        },
        {
          field: 'quantidade',
          headerName: t('com.muralis.qcx.quantidade.label'),
          headerAlign: 'center',
          align: 'center',
          flex: 100,
          valueGetter: ({ row }) => row?.quantidade || '-',
        },
      ],
    }),
    []
  );

  const mercadoriaAmparadaPorFormProps = useMemo(
    () => ({
      rootName: 'ignorableFields.ncm',
      fields: [
        {
          name: 'tipoCe.id',
          label: t('com.muralis.qcx.tipoCE'),
        },
        {
          name: 'ncm.id',
          label: t('com.muralis.qcx.NCM.NCMSH'),
        },
        {
          name: 'unidadeDeMedida.description',
          label: t('com.muralis.qcx.unidadeMedida.unidadeMedidaEstatistica'),
          required: false,
        },
        {
          name: 'quantidade',
          label: t('com.muralis.qcx.quantidade.label'),
        },
        {
          name: 'codigoCe',
          label: t('com.muralis.qcx.codigoCE'),
        },
      ],
    }),
    []
  );

  const mercadoriaAmparadaPorReducerConfig = useMemo(
    () => ({
      selectors: {
        selectStatus: vinculoMercadoriaAmparadaPorDUESelectors.selectStatus,
        selectMode: vinculoMercadoriaAmparadaPorDUESelectors.selectMode,
        selectModel: vinculoMercadoriaAmparadaPorDUESelectors.selectModel,
      },
      actions: {
        loading: vinculoMercadoriaAmparadaPorDUEActions.loading,
        resetStatus: vinculoMercadoriaAmparadaPorDUEActions.resetStatus,
        changeToUpdateMode: vinculoMercadoriaAmparadaPorDUEActions.changeToUpdateMode,
        changeToCreateMode: vinculoMercadoriaAmparadaPorDUEActions.changeToCreateMode,
        resetMode: vinculoMercadoriaAmparadaPorDUEActions.resetMode,
        setModel: vinculoMercadoriaAmparadaPorDUEActions.setModel,
        resetModel: vinculoMercadoriaAmparadaPorDUEActions.resetModel,
      },
    }),
    []
  );

  const handleAddMercadoriaAmparadaPor = useCallback(
    async (currentVinculo, currentVinculos, handleSuccessAddVinculo) => {
      const ncmResponse = await ncmSubitemAPI.fetchById(currentVinculo?.ncm?.id);

      const tipoCEResponse = await fetchTipoCEById(currentVinculo?.tipoCe?.id);

      if (ncmResponse?.status === 200 && tipoCEResponse?.status === 200) {
        const foundTipoAtoConcessorio = ncmResponse?.data;
        const foundTipoCE = tipoCEResponse?.data;

        const vinculo = {
          id: uuid(),
          ncm: foundTipoAtoConcessorio,
          tipoCe: foundTipoCE,
          quantidade: currentVinculo?.quantidade,
          codigoCe: currentVinculo?.codigoCe,
        };

        const updatedVinculos = [...currentVinculos, vinculo];

        handleSuccessAddVinculo(updatedVinculos);
      }
    },
    []
  );

  const handleUpdateMercadoriaAmparadaPor = useCallback(
    async (currentVinculo, currentVinculos, handleSuccessUpdateVinculo) => {
      const ncmResponse = await ncmSubitemAPI.fetchById(currentVinculo?.ncm?.id);

      const tipoCEResponse = await fetchTipoCEById(currentVinculo?.tipoCe?.id);

      if (ncmResponse?.status === 200 && tipoCEResponse?.status === 200) {
        const foundTipoAtoConcessorio = ncmResponse?.data;
        const foundTipoCE = tipoCEResponse?.data;

        const updatedVinculo = {
          ...currentVinculo,
          ncm: foundTipoAtoConcessorio,
          tipoCe: foundTipoCE,
        };

        const updatedVinculos =
          currentVinculos?.map((existingVinculo) =>
            isStrictEquals(existingVinculo?.id, updatedVinculo?.id) ? updatedVinculo : existingVinculo
          ) || currentVinculos;

        handleSuccessUpdateVinculo(updatedVinculos);
      }
    },
    []
  );

  const handleMercadoriaAmparadaPorAlreadyExists = useCallback(
    (currentVinculo, currentVinculosList) =>
      currentVinculosList.some(
        (vinculo) =>
          isStrictEquals(vinculo?.tipoCe.id, currentVinculo?.tipoCe.id) &&
          isStrictEquals(vinculo?.ncm.id, currentVinculo?.ncm.id) &&
          isStrictEquals(vinculo?.quantidade, currentVinculo?.quantidade) &&
          isStrictEquals(vinculo?.codigoCe, currentVinculo?.codigoCe)
      ),
    []
  );

  const mercadoriaAmparadaPorDecorator = useCallback((tools) => {
    const finalBondManagerForm = tools?.formUtils?.form;

    finalBondManagerForm.change('ignorableFields.ncm.unidadeDeMedida.description', '');
    finalBondManagerForm.change('ignorableFields.ncm.quantidade', '');
  }, []);

  const onNcmSubitemOnChange = useCallback(
    () => async (value) => {
      if (value) {
        const responseNcmSubItem = await ncmSubitemAPI.fetchById(value);

        if (responseNcmSubItem?.status === 200 && !isEmpty(responseNcmSubItem?.data)) {
          const foundNcm = responseNcmSubItem?.data;

          form.change('ignorableFields.ncm.unidadeDeMedida.description', foundNcm?.unidadeDeMedida?.description || '');

          return;
        }
      }

      form.change('ignorableFields.ncm.unidadeDeMedida.description', '');
    },
    [form]
  );

  const onChangeListeners = useMemo(
    () => [
      {
        name: 'ignorableFields.ncm.ncm.id',
        fn: onNcmSubitemOnChange,
      },
    ],
    [onNcmSubitemOnChange]
  );

  return (
    <>
      <QCXFormListenerManager form={form} values={values} onChangeListeners={onChangeListeners} />
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <QCXFormSubtitle
          title={title}
          boxProps={{
            pt: 1,
          }}
        />
      </Grid>
      <QCXFinalBondManagerOld
        isParentConsult={isParentConsult}
        listProps={mercadoriaAmparadaPorListProps}
        formProps={mercadoriaAmparadaPorFormProps}
        reducerConfig={mercadoriaAmparadaPorReducerConfig}
        handleAdd={handleAddMercadoriaAmparadaPor}
        handleUpdate={handleUpdateMercadoriaAmparadaPor}
        handleAlreadyExists={handleMercadoriaAmparadaPorAlreadyExists}
        {...{
          onClearDecorator: mercadoriaAmparadaPorDecorator,
          onAddDecorator: mercadoriaAmparadaPorDecorator,
        }}
      >
        {({ loading }) => (
          <>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <QCXSelectTipoCEAutocomplete
                id="select-field-ncm-tipo-ce"
                key="select-field-ncm-tipo-ce"
                name="ignorableFields.ncm.tipoCe.id"
                label={t('com.muralis.qcx.tipoCE')}
                disabled={disabled || loading}
                initialValues={values}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <QCXSelectNcmSubItemAutocomplete
                id="text-field-ncm-ncm-id"
                key="text-field-ncm-ncm-id"
                name="ignorableFields.ncm.ncm.id"
                label={t('com.muralis.qcx.NCM.NCMSH')}
                externallyFillable={isFilling}
                disabled={disabled || loading}
                initialValues={values}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <QCXFinalTextField
                id="text-field-ncm-unidade-de-medida-description"
                key="text-field-ncm-unidade-de-medida-description"
                name="ignorableFields.ncm.unidadeDeMedida.description"
                label={t('com.muralis.qcx.unidadeMedida.unidadeMedidaEstatistica')}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <QCXFinalNumericDecimalField
                id="numeric-ncm-quantidade"
                key="numeric-ncm-quantidade"
                name="ignorableFields.ncm.quantidade"
                label={t('com.muralis.qcx.quantidade.label')}
                disabled={disabled || loading}
                scale={5}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <QCXFinalTextField
                id="text-field-ncm-item-item-ac"
                key="text-field-ncm-item-item-ac"
                name="ignorableFields.ncm.codigoCe"
                label={t('com.muralis.qcx.codigoCE')}
                disabled={disabled || loading}
              />
            </Grid>
          </>
        )}
      </QCXFinalBondManagerOld>
    </>
  );
}
