import { MatcherConfigureUtils } from '../../utils/general/general-utils';

const TIPO_ATO_CONCESSORIO_COMUM_CODE = 'TAC01';
const TIPO_ATO_CONCESSORIO_INTERMEDIARIO_CODE = 'TAC02';
const TIPO_ATO_CONCESSORIO_INTERMEDIARIO_GENERICO_CODE = 'TAC03';
const TIPO_ATO_CONCESSORIO_EMBARCAÇÃO_COMUM_CODE = 'TAC04';
const TIPO_ATO_CONCESSORIO_EMBARCAÇÃO_GENERICO_CODE = 'TAC05';
const TIPO_ATO_CONCESSORIO_GENERICO_CODE = 'TAC06';
const TIPO_ATO_CONCESSORIO_FORNECIMENTO_NO_MERCADO_INTERNO_COMUM_CODE = 'TAC07';
const TIPO_ATO_CONCESSORIO_FORNECIMENTO_NO_MERCADO_INTERNO_GENERICO_CODE = 'TAC08';

const isTipoAtoConcessorioComum = (
  MatcherConfigureUtils.getSpecificByCode(
    TIPO_ATO_CONCESSORIO_COMUM_CODE
  )
);

const isTipoAtoConcessorioIntermediario = (
  MatcherConfigureUtils.getSpecificByCode(
    TIPO_ATO_CONCESSORIO_INTERMEDIARIO_CODE
  )
);

const isTipoAtoConcessorioIntermediarioGenerico = (
  MatcherConfigureUtils.getSpecificByCode(
    TIPO_ATO_CONCESSORIO_INTERMEDIARIO_GENERICO_CODE
  )
);

const isTipoAtoConcessorioEmbarcacaoComum = (
  MatcherConfigureUtils.getSpecificByCode(
    TIPO_ATO_CONCESSORIO_EMBARCAÇÃO_COMUM_CODE
  )
);

const isTipoAtoConcessorioEmbarcacaoGenerico = (
  MatcherConfigureUtils.getSpecificByCode(
    TIPO_ATO_CONCESSORIO_EMBARCAÇÃO_GENERICO_CODE
  )
);

const isTipoAtoConcessorioGenerico = (
  MatcherConfigureUtils.getSpecificByCode(
    TIPO_ATO_CONCESSORIO_GENERICO_CODE
  )
);

const isTipoAtoConcessorioFornecimentoNoMercadoriInternoComum = (
  MatcherConfigureUtils.getSpecificByCode(
    TIPO_ATO_CONCESSORIO_FORNECIMENTO_NO_MERCADO_INTERNO_COMUM_CODE
  )
);

const isTipoAtoConcessorioFornecimentoNoMercadoriInternoGenerico = (
  MatcherConfigureUtils.getSpecificByCode(
    TIPO_ATO_CONCESSORIO_FORNECIMENTO_NO_MERCADO_INTERNO_GENERICO_CODE
  )
);

const TipoAtoConcessorioUtils = Object.freeze({
  COMUM: (
    TIPO_ATO_CONCESSORIO_COMUM_CODE
  ),
  INTERMEDIARIO: (
    TIPO_ATO_CONCESSORIO_INTERMEDIARIO_CODE
  ),
  INTERMEDIARIO_GENERICO: (
    TIPO_ATO_CONCESSORIO_INTERMEDIARIO_GENERICO_CODE
  ),
  EMBARCAÇÃO_COMUM: (
    TIPO_ATO_CONCESSORIO_EMBARCAÇÃO_COMUM_CODE
  ),
  EMBARCAÇÃO_GENERICO: (
    TIPO_ATO_CONCESSORIO_EMBARCAÇÃO_GENERICO_CODE
  ),
  GENERICO: (
    TIPO_ATO_CONCESSORIO_GENERICO_CODE
  ),
  FORNECIMENTO_NO_MERCADO_INTERNO_COMUM: (
    TIPO_ATO_CONCESSORIO_FORNECIMENTO_NO_MERCADO_INTERNO_COMUM_CODE
  ),
  FORNECIMENTO_NO_MERCADO_INTERNO_GENERICO: (
    TIPO_ATO_CONCESSORIO_FORNECIMENTO_NO_MERCADO_INTERNO_GENERICO_CODE
  ),
  isComum: (
    isTipoAtoConcessorioComum
  ),
  isIntermediario: (
    isTipoAtoConcessorioIntermediario
  ),
  isIntermediarioGenerico: (
    isTipoAtoConcessorioIntermediarioGenerico
  ),
  isEmbarcacaoComum: (
    isTipoAtoConcessorioEmbarcacaoComum
  ),
  isEmbarcacaoGenerico: (
    isTipoAtoConcessorioEmbarcacaoGenerico
  ),
  isGenerico: (
    isTipoAtoConcessorioGenerico
  ),
  isFornecimentoNoMercadoriInternoComum: (
    isTipoAtoConcessorioFornecimentoNoMercadoriInternoComum
  ),
  isFornecimentoNoMercadoriInternoGenerico: (
    isTipoAtoConcessorioFornecimentoNoMercadoriInternoGenerico
  ),
});

export default TipoAtoConcessorioUtils;
