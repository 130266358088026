import React from 'react';
import { DialogActions } from '@material-ui/core';

export default function QCXDialogActions({
  key,
  id,
  children,
  ...restProps
}) {
  return (
    <DialogActions
      key={key}
      id={id}
      {...restProps}
    >
      {children}
    </DialogActions>
  );
}
