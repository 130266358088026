const TIPO_LOGRADOURO_ALAMEDA = 'ALAMEDA';
const TIPO_LOGRADOURO_AVENIDA = 'AVENIDA';
const TIPO_LOGRADOURO_CHACARA = 'CHACARA';
const TIPO_LOGRADOURO_COLONIA = 'COLONIA';
const TIPO_LOGRADOURO_CONDOMINIO = 'CONDOMINIO';
const TIPO_LOGRADOURO_ESTANCIA = 'ESTANCIA';
const TIPO_LOGRADOURO_ESTRADA = 'ESTRADA';
const TIPO_LOGRADOURO_FAZENDA = 'FAZENDA';
const TIPO_LOGRADOURO_PRACA = 'PRACA';
const TIPO_LOGRADOURO_PROLONGAMENTO = 'PROLONGAMENTO';
const TIPO_LOGRADOURO_RODOVIA = 'RODOVIA';
const TIPO_LOGRADOURO_RUA = 'RUA';
const TIPO_LOGRADOURO_SITIO = 'SITIO';
const TIPO_LOGRADOURO_TRAVESSA = 'TRAVESSA';
const TIPO_LOGRADOURO_VICINAL = 'VICINAL';
const TIPO_LOGRADOURO_EQNP = 'EQNP';

const TIPO_LOGRADOURO_LIST = [
  {
    label: 'com.muralis.qcx.tipoLogradouro.alameda',
    value: TIPO_LOGRADOURO_ALAMEDA,
  },
  {
    label: 'com.muralis.qcx.tipoLogradouro.avenida',
    value: TIPO_LOGRADOURO_AVENIDA,
  },
  {
    label: 'com.muralis.qcx.tipoLogradouro.chacara',
    value: TIPO_LOGRADOURO_CHACARA,
  },
  {
    label: 'com.muralis.qcx.tipoLogradouro.colonia',
    value: TIPO_LOGRADOURO_COLONIA,
  },
  {
    label: 'com.muralis.qcx.tipoLogradouro.condominio',
    value: TIPO_LOGRADOURO_CONDOMINIO,
  },
  {
    label: 'com.muralis.qcx.tipoLogradouro.estancia',
    value: TIPO_LOGRADOURO_ESTANCIA,
  },
  {
    label: 'com.muralis.qcx.tipoLogradouro.estrada',
    value: TIPO_LOGRADOURO_ESTRADA,
  },
  {
    label: 'com.muralis.qcx.tipoLogradouro.fazenda',
    value: TIPO_LOGRADOURO_FAZENDA,
  },
  {
    label: 'com.muralis.qcx.tipoLogradouro.praca',
    value: TIPO_LOGRADOURO_PRACA,
  },
  {
    label: 'com.muralis.qcx.tipoLogradouro.prolongamento',
    value: TIPO_LOGRADOURO_PROLONGAMENTO,
  },
  {
    label: 'com.muralis.qcx.tipoLogradouro.rodovia',
    value: TIPO_LOGRADOURO_RODOVIA,
  },
  {
    label: 'com.muralis.qcx.tipoLogradouro.rua',
    value: TIPO_LOGRADOURO_RUA,
  },
  {
    label: 'com.muralis.qcx.tipoLogradouro.sitio',
    value: TIPO_LOGRADOURO_SITIO,
  },
  {
    label: 'com.muralis.qcx.tipoLogradouro.travessa',
    value: TIPO_LOGRADOURO_TRAVESSA,
  },
  {
    label: 'com.muralis.qcx.tipoLogradouro.vicinal',
    value: TIPO_LOGRADOURO_VICINAL,
  },
  {
    label: 'com.muralis.qcx.tipoLogradouro.EQNP',
    value: TIPO_LOGRADOURO_EQNP,
  },
];

export default TIPO_LOGRADOURO_LIST;
