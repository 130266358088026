import { useCallback } from "react";
import { MediaType } from "../../../../utils/api/api-utils";

export const parseToFormData = (data: any) => {
  const { certificadoDigital, ...restData } = data;

  const jsonData = {
    ...restData,
    ...(certificadoDigital?.id
      ? {
          certificadoDigital,
        }
      : {}),
  };

  const json = JSON.stringify(jsonData);
  const blob = new Blob([json], {
    type: MediaType.APPLICATION_JSON,
  });

  const file =
    certificadoDigital?.length > 0 ? certificadoDigital[0] : undefined;

  const formData = new FormData();

  formData.append("data", blob);

  if (file) {
    formData.append("file", file);
  }

  return formData;
};
