import { getOptionByValueInList } from '../../utils/general/general-utils';
import i18n from '../../i18n';

const COMPRADOR_VENDEDOR_SEM_VINCULACAO = 'SEM_VINCULACAO';
const COMPRADOR_VENDEDOR_COM_VINCULACAO_COM_INFLUENCIA_PRECO = 'COM_VINCULACAO_COM_INFLUENCIA_PRECO';
const COMPRADOR_VENDEDOR_COM_VINCULACAO_SEM_INFLUENCIA_PRECO = 'COM_VINCULACAO_SEM_INFLUENCIA_PRECO';

const VINCULACAO_COMPRADOR_VENDEDOR_LIST = [
  {
    label: i18n.t('com.muralis.qcx.semVinculacao').toUpperCase(),
    value: COMPRADOR_VENDEDOR_SEM_VINCULACAO,
  },
  {
    label: i18n.t('com.muralis.qcx.comVinculacaoInfluenciaPreco').toUpperCase(),
    value: COMPRADOR_VENDEDOR_COM_VINCULACAO_COM_INFLUENCIA_PRECO,
  },
  {
    label: i18n.t('com.muralis.qcx.comVinculacaoSemInfluenciaPreco').toUpperCase(),
    value: COMPRADOR_VENDEDOR_COM_VINCULACAO_SEM_INFLUENCIA_PRECO,
  },
];

const isCompradorVendedorSemVinculacao = (value) => (
  COMPRADOR_VENDEDOR_SEM_VINCULACAO === value
);

const isCompradorVendedorComVinculacaoComInfluenciaPreco = (value) => (
  COMPRADOR_VENDEDOR_COM_VINCULACAO_COM_INFLUENCIA_PRECO === value
);

const isCompradorVendedorComVinculacaoSemInfluenciaPreco = (value) => (
  COMPRADOR_VENDEDOR_COM_VINCULACAO_SEM_INFLUENCIA_PRECO === value
);

const getVinculacaoCompradorVendedorLabelByValue = (value) => {
  const foundOption = getOptionByValueInList(VINCULACAO_COMPRADOR_VENDEDOR_LIST, value);
  return foundOption && foundOption?.label;
};

export {
  COMPRADOR_VENDEDOR_SEM_VINCULACAO,
  COMPRADOR_VENDEDOR_COM_VINCULACAO_COM_INFLUENCIA_PRECO,
  COMPRADOR_VENDEDOR_COM_VINCULACAO_SEM_INFLUENCIA_PRECO,
  VINCULACAO_COMPRADOR_VENDEDOR_LIST,
};

export {
  isCompradorVendedorSemVinculacao,
  isCompradorVendedorComVinculacaoComInfluenciaPreco,
  isCompradorVendedorComVinculacaoSemInfluenciaPreco,
  getVinculacaoCompradorVendedorLabelByValue,
};
