import { Grid, makeStyles } from '@material-ui/core';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormState } from 'react-final-form';
import QCXFormSubtitle from '../../components/form-title/QCXFormSubtitle';
import QCXFinalAlternativeCurrencyField from '../final-currency-field/QCXFinalAlternativeCurrencyField';
import QCXFinalDatePickerField from '../final-date-picker-field/QCXFinalDatePickerField';
import QCXFinalPercentualField from '../final-percentual-field/QCXFinalPercentualField';
import QCXFinalTextField from '../final-text-field/QCXFinalTextField';
import QCXSelectOrgaoEmissorAutocomplete from '../select-orgao-emissor/QCXSelectOrgaoEmissorAutocomplete';
import QCXSelectTipoAtoLegalAutocomplete from '../select-tipo-ato-legal/QCXSelectTipoAtoLegalAutocomplete';
import QCXAliquotaEspecificaFormGroup from './QCXAliquotaEspecificaFormGroup';

const useStyles = makeStyles((theme) => ({
  gridFields: {
    paddingBottom: '16px',
  },
  lastGridFields: {
    [theme.breakpoints.down('xs')]: {
      paddingBottom: '16px',
    },
  },
}));

export default function QCXAntidumpingFormGroup({
  intl,
  modes,
  submitSucceeded,
}) {
  const { t } = useTranslation();

  const classes = useStyles();

  const { values } = useFormState();

  const {
    isConsult,
    isSubConsult,
    isSubNone,
  } = modes;

  const aliquotaEspecificaFields = useMemo(() => ({
    rootName: 'antidumping',
    valorReais: {
      name: 'antidumping.valorReal',
    },
    quantidade: {
      name: 'antidumping.quantidade',
    },
    unidadeDeMedida: {
      name: 'antidumping.unidadeDeMedida.id',
    },
  }), []);

  return (
    <>
      <Grid item container xs={12} sm={6} md={6} lg={6}>
        <Grid
          item
          className={classes.gridFields}
          xs={12}
          sm={12}
          md={12}
          lg={12}
        >
          <QCXSelectTipoAtoLegalAutocomplete
            id="select-field-tipo-ato-legal-antidumping"
            key="select-field-tipo-ato-legal-antidumping"
            name="antidumping.tipoAtoLegal.id"
            label={t('com.muralis.qcx.atoLegal')}
            disabled={isConsult || isSubConsult || isSubNone}
            initialValues={values}
          />
        </Grid>
        <Grid
          item
          className={classes.gridFields}
          xs={12}
          sm={12}
          md={12}
          lg={12}
        >
          <QCXFinalTextField
            id="text-field-numero-do-ato-antidumping"
            key="text-field-numero-do-ato-antidumping"
            name="antidumping.numeroAto"
            label={t('com.muralis.qcx.numeroAto')}
            disabled={isConsult || isSubConsult || isSubNone}
          />
        </Grid>
        <Grid
          item
          className={classes.gridFields}
          xs={12}
          sm={12}
          md={12}
          lg={12}
        >
          <QCXFinalTextField
            id="text-field-ex-antidumping"
            key="text-field-ex-antidumping"
            name="antidumping.ex"
            label={t('com.muralis.qcx.ex')}
            disabled={isConsult || isSubConsult || isSubNone}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
        >
          <QCXFinalAlternativeCurrencyField
            id="currency-valor-base-calculo-real-field-antidumping"
            key="currency-valor-base-calculo-real-field-antidumping"
            name="antidumping.baseCalculoReal"
            label={t('com.muralis.qcx.baseCalculoReais')}
            intl={intl}
            disabled={isConsult || isSubConsult || isSubNone}
            submitSucceeded={submitSucceeded}
            adorned
            scale={7}
          />
        </Grid>
      </Grid>
      <Grid item container xs={12} sm={6} md={6} lg={6} alignContent="flex-start">
        <Grid
          item
          className={classes.gridFields}
          xs={12}
          sm={12}
          md={12}
          lg={12}
        >
          <QCXSelectOrgaoEmissorAutocomplete
            id="select-field-orgao-emissor-antidumping"
            key="select-field-orgao-emissor-antidumping"
            name="antidumping.orgaoEmissorAtoLegal.id"
            label={t('com.muralis.qcx.orgao.orgaoEmissor')}
            disabled={isConsult || isSubConsult || isSubNone}
            initialValues={values}
          />
        </Grid>
        <Grid
          item
          className={classes.gridFields}
          xs={12}
          sm={12}
          md={12}
          lg={12}
        >
          <QCXFinalDatePickerField
            id="date-picker-ano-field-antidumping"
            key="date-picker-ano-field-antidumping"
            name="antidumping.ano"
            label={t('com.muralis.qcx.ano')}
            views={['year']}
            format="YYYY"
            placeholder="YYYY"
            disabled={isConsult || isSubConsult || isSubNone}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
        >
          <QCXFinalPercentualField
            id="aliquota-ad-valorem-field-antidumping"
            key="aliquota-ad-valorem-field-antidumping"
            name="antidumping.aliquota"
            label={t('com.muralis.qcx.aliquotaADValorem')}
            disabled={isConsult || isSubConsult || isSubNone}
            scale={7}
          />
        </Grid>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12}>
        <QCXFormSubtitle
          title={t('com.muralis.qcx.aliquotaEspecifica')}
        />
      </Grid>
      <QCXAliquotaEspecificaFormGroup
        modes={modes}
        submitSucceeded={submitSucceeded}
        fields={aliquotaEspecificaFields}
        intl={intl}
      />
      <Grid
        item
        xs={12}
        sm={6}
        md={6}
        lg={6}
      >
        <QCXFinalAlternativeCurrencyField
          id="valor-devido-reais-tarifario-field-antidumping"
          key="valor-devido-reais-tarifario-field-antidumping"
          name="antidumping.valorDevidoReal"
          label={t('com.muralis.qcx.valorDevidoReais')}
          disabled
          adorned
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        md={6}
        lg={6}
      >
        <QCXFinalAlternativeCurrencyField
          id="valor-recolher-reais-tarifario-field-antidumping"
          key="valor-recolher-tarifario-field-antidumping"
          name="antidumping.valorRecolherReal"
          label={t('com.muralis.qcx.valorRecolherReais')}
          disabled
          adorned
        />
      </Grid>
    </>
  );
}
