import React, {
  useCallback,
  useMemo,
  useState,
} from 'react';
import _, {
  isEmpty,
  isFunction,
  isNumber,
} from 'lodash';
import usePaginator from '../../utils/hooks/paginator/usePaginator';
import PaginatorStateContext from '../../contexts/components/paginator/PaginatorStateContext';
import PaginatorActionContext from '../../contexts/components/paginator/PaginatorActionContext';
import PaginatorControllerContext from '../../contexts/components/paginator/PaginatorControllerContext';
import { isValid } from '../../utils/general/general-utils';

const PaginatorActionTemplate = {
  NEXT: 'next',
  PREVIOUS: 'previous',
  isNext: (action) => (
    action === PaginatorActionTemplate.NEXT
  ),
  isPrevious: (action) => (
    action === PaginatorActionTemplate.PREVIOUS
  ),
};

const DEFAULT_PAGINATOR_OPTIONS = {
  defaultPage: 0,
  actionTemplate: PaginatorActionTemplate,
  metadata: {},
};

function QCXPaginator({
  options = DEFAULT_PAGINATOR_OPTIONS,
  children,
}) {
  const finalOptions = useMemo(() => {
    if (isEmpty(options)) {
      return DEFAULT_PAGINATOR_OPTIONS;
    }

    const mergedOptions = _.merge(
      DEFAULT_PAGINATOR_OPTIONS,
      options
    );

    return mergedOptions;
  }, [options]);

  const [page, setPage] = useState(finalOptions.defaultPage);
  const [usedDefaultPage, setUsedDefaultPage] = useState(false);
  const [action, setAction] = useState(finalOptions.actionTemplate.NEXT);
  const [metadata, setMetadata] = useState(finalOptions.metadata);

  const isNext = useCallback(() => (
    action === finalOptions.actionTemplate.NEXT
  ), [
    action,
    finalOptions,
  ]);

  const isPrevious = useCallback(() => (
    action === finalOptions.actionTemplate.PREVIOUS
  ), [
    action,
    finalOptions,
  ]);

  const next = useCallback((value) => {
    setAction(finalOptions.actionTemplate.NEXT);

    if (isFunction(value) || isNumber(value)) {
      setPage(value);

      return;
    }

    setPage((previousPage) => (
      previousPage + 1
    ));
  }, [
    setPage,
    setAction,
    finalOptions,
  ]);

  const previous = useCallback((value) => {
    setAction(finalOptions.actionTemplate.PREVIOUS);

    if (isFunction(value) || isNumber(value)) {
      setPage(value);

      return;
    }

    setPage((previousPage) => (
      previousPage - 1
    ));
  }, [
    setPage,
    setAction,
    finalOptions,
  ]);

  const markDefaultPageAsUsed = useCallback(() => {
    if (usedDefaultPage) {
      return;
    }

    setUsedDefaultPage(true);
  }, [
    usedDefaultPage,
    setUsedDefaultPage,
  ]);

  const changeMetadata = useCallback((path, value) => {
    if (!isValid(path)) {
      throw new Error('Invalid metadata path provided. The value of the parameter "path" must be valid.');
    }

    const copyOfMetadata = _.cloneDeep(metadata);
    const updatedMetadata = _.set(
      copyOfMetadata,
      path,
      value
    );

    if (_.isEqual(updatedMetadata, metadata)) {
      return;
    }

    setMetadata(updatedMetadata);
  }, [metadata, setMetadata]);

  const getMetadata = useCallback((path) => {
    if (!isValid(path)) {
      throw new Error('Invalid metadata path provided. The value of the parameter "path" must be valid.');
    }

    const value = _.get(metadata, path);

    return value;
  }, [metadata]);

  const paginatorState = useMemo(() => ({
    page,
    defaultPage: finalOptions?.defaultPage,
    usedDefaultPage,
    action,
    metadata,
  }), [
    page,
    finalOptions,
    usedDefaultPage,
    action,
    metadata,
  ]);

  const actionState = useMemo(() => ({
    current: action,
    NEXT: finalOptions?.actionTemplate?.NEXT,
    PREVIOUS: finalOptions?.actionTemplate?.PREVIOUS,
    isNext,
    isPrevious,
  }), [
    action,
    finalOptions,
    isNext,
    isPrevious,
  ]);

  const paginatorController = useMemo(() => ({
    next,
    previous,
    setPage,
    markDefaultPageAsUsed,
    getMetadata,
    changeMetadata,
  }), [
    next,
    previous,
    setPage,
    markDefaultPageAsUsed,
    getMetadata,
    changeMetadata,
  ]);

  return (
    <PaginatorStateContext.Provider
      value={paginatorState}
    >
      <PaginatorActionContext.Provider
        value={actionState}
      >
        <PaginatorControllerContext.Provider
          value={paginatorController}
        >
          {children}
        </PaginatorControllerContext.Provider>

      </PaginatorActionContext.Provider>

    </PaginatorStateContext.Provider>
  );
}

QCXPaginator.Controller = function ({
  children,
  ...restProps
}) {
  const paginator = usePaginator();

  return (
    isFunction(children)
      ? children(paginator, restProps)
      : children
  );
};

QCXPaginator.Container = function ({
  children,
  ...restProps
}) {
  const paginator = usePaginator();

  return (
    isFunction(children)
      ? children(paginator, restProps)
      : children
  );
};

QCXPaginator.Fragment = function ({
  children,
  ...restProps
}) {
  const paginator = usePaginator();

  return (
    isFunction(children)
      ? children(paginator, restProps)
      : children
  );
};

function withPaginator(WrappedComponent) {
  return ({
    paginatorProps,
    ...restProps
  }) => (
    <QCXPaginator
      {...paginatorProps}
    >
      <WrappedComponent
        {...restProps}
      />
    </QCXPaginator>
  );
}

export {
  withPaginator,
};

export default QCXPaginator;
