import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { useAtom } from "jotai";
import { isDrawerOpenAtom } from "../../atoms/atoms";
import SettingsIcon from "@mui/icons-material/Settings";
import { getDrawerRoutes } from "./routes/leftDrawerRoutes";
import LeftDrawerGroup from "./leftDrawerGroup";
import { useFindPath } from "../../hooks/useFindPath";
import { useHistory } from "react-router-dom";
import { handleNavigation, isItemActive } from "./helpers/leftDrawerHelpers";
import { useMemo } from "react";
import { useVerifyPermission } from "../../hooks/useHasPermission/useVerifyPermission";
import { useVerifyAnyPermission } from "../../hooks/useHasPermission/useVerifyAnyPermission";

const LeftDrawer = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useAtom(isDrawerOpenAtom);
  const path = useFindPath();
  const leftDrawerRoutes = useMemo(
    () => getDrawerRoutes(path as string),
    [path]
  );
  const history = useHistory();

  const [userHasAnyPermission] = useVerifyAnyPermission();

  return (
    <Drawer
      sx={{
        width: 240,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: 240,
          boxSizing: "border-box",
          backgroundColor: "#f4f4f4",
        },
      }}
      variant="persistent"
      anchor="left"
      open={isDrawerOpen}
    >
      <List style={{ paddingTop: "65px" }}>
        {leftDrawerRoutes.map((route) => {
          const isActive = isItemActive(path, route);
          const textColor = isActive ? "secondary" : "black";
          if (
            route.permissionNeeded &&
            !userHasAnyPermission(route.permissionNeeded)
          ) {
            return <></>;
          }
          if (route.nestedRoutes) {
            return <LeftDrawerGroup route={route} />;
          } else {
            return (
              <ListItem disablePadding>
                <ListItemButton
                  selected={isActive}
                  onClick={() => handleNavigation(route.path, history)}
                  style={{ paddingTop: "8px", paddingBottom: "8px" }}
                >
                  <ListItemIcon style={{ marginRight: "-22px" }}>
                    <route.icon color={isActive ? "secondary" : "primary"} />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography
                        position={"relative"}
                        top={"3px"}
                        left={"-2px"}
                        color={textColor}
                        fontSize={14}
                        fontWeight={isActive ? "bold" : "inherit"}
                      >
                        {route.name.toUpperCase()}
                      </Typography>
                    }
                  />
                </ListItemButton>
              </ListItem>
            );
          }
        })}
      </List>
      <Box sx={{ flexGrow: 1 }} />
      <Divider />
      <List>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon style={{ marginRight: "-22px" }}>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText
              disableTypography
              primary={
                <Typography color={"gray"} fontSize={14}>
                  {"Configurações".toUpperCase()}
                </Typography>
              }
            />
          </ListItemButton>
        </ListItem>
      </List>
    </Drawer>
  );
};

export default LeftDrawer;
