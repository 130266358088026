import { getOptionByValueInList } from '../../utils/general/general-utils';
import i18n from '../../i18n';

const TIPO_CONTA_FATURA_LI = 'FATURA_LI';
const TIPO_CONTA_FATURA_DI_DUIMP = 'FATURA_DI';

const TIPO_CONTA_FATURA_LIST = [
  {
    label: i18n.t('com.muralis.qcx.LI'),
    value: TIPO_CONTA_FATURA_LI,
  },
  {
    label: i18n.t('com.muralis.qcx.DIDUIMP.label'),
    value: TIPO_CONTA_FATURA_DI_DUIMP,
  },
];

const isTipoContaFaturaLi = (value) => (
  TIPO_CONTA_FATURA_LI === value
);

const isTipoContaFaturaDiDuimp = (value) => (
  TIPO_CONTA_FATURA_DI_DUIMP === value
);

const getTipoContaFaturaLabelByValue = (value) => {
  const foundOption = getOptionByValueInList(
    TIPO_CONTA_FATURA_LIST,
    value
  );
  return foundOption && foundOption?.label;
};

const TipoContaFaturaUtils = {
  TIPO_FATURA_LIST: TIPO_CONTA_FATURA_LIST,
  TIPO_FATURA_LI: (
    TIPO_CONTA_FATURA_LI
  ),
  TIPO_FATURA_DI_DUIMP: (
    TIPO_CONTA_FATURA_DI_DUIMP
  ),
  isTipoFaturaLi: (
    isTipoContaFaturaLi
  ),
  isTipoFaturaDiDuimp: (
    isTipoContaFaturaDiDuimp
  ),
  getLabelFaturaByValue: (
    getTipoContaFaturaLabelByValue
  ),
};

const TIPO_CONTA_LI_DI = 'LI_DI';

const TIPO_CONTA_LI_LIST = [
  {
    label: i18n.t('com.muralis.qcx.DIDUIMP.label'),
    value: TIPO_CONTA_LI_DI,
  },
];

const isTipoContaLiDi = (value) => (
  TIPO_CONTA_LI_DI === value
);

const getTipoContaLiLabelByValue = (value) => {
  const foundOption = getOptionByValueInList(
    TIPO_CONTA_LI_LIST,
    value
  );
  return foundOption && foundOption?.label;
};

const TipoContaLiUtils = {
  TIPO_LI_LIST: TIPO_CONTA_LI_LIST,
  TIPO_LI_DI: (
    TIPO_CONTA_LI_DI
  ),
  isTipoLiDi: (
    isTipoContaLiDi
  ),
  getLabelLiByValue: (
    getTipoContaLiLabelByValue
  ),
};

export {
  TipoContaFaturaUtils,
  TIPO_CONTA_FATURA_LI,
  TIPO_CONTA_FATURA_DI_DUIMP,
  TIPO_CONTA_FATURA_LIST,
  isTipoContaFaturaLi,
  isTipoContaFaturaDiDuimp,
  TipoContaLiUtils,
  getTipoContaFaturaLabelByValue,
  TIPO_CONTA_LI_DI,
  TIPO_CONTA_LI_LIST,
  isTipoContaLiDi,
  getTipoContaLiLabelByValue,
};
