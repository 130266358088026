import { ReponseType } from '../../utils/api/api-utils';
import {
  doGetQCXRequest,
  doPutQCXRequest,
} from '../../utils/api/domain/domain-api-utils';
import {
  ADICAO_ENDPOINT,
  CALCULO_ENDPOINT,
  DECLARACAO_IMPORTACAO_ENDPOINT,
  DI_ENDPOINT,
  FATURA_ENDPOINT,
  REPORT_ENDPOINT,
  ITENS_ENDPOINT,
} from '../../utils/api/domain/domain-endpoint-utils';

async function save(data, queryParams = []) {
  return doPutQCXRequest(
    [
      FATURA_ENDPOINT,
      data?.id,
      DECLARACAO_IMPORTACAO_ENDPOINT
    ],
    data,
    queryParams
  );
}

async function fetchById(id) {
  return doGetQCXRequest([
    FATURA_ENDPOINT,
    id
  ]);
}

async function generateReportById(id) {
  return doGetQCXRequest(
    [
      DECLARACAO_IMPORTACAO_ENDPOINT,
      REPORT_ENDPOINT,
      DI_ENDPOINT,
      id
    ],
    [],
    {
      responseType: ReponseType.ARRAY_BUFFER,
    }
  );
}

async function generateReportAdicoesById(id) {
  return doGetQCXRequest(
    [
      DECLARACAO_IMPORTACAO_ENDPOINT,
      REPORT_ENDPOINT,
      CALCULO_ENDPOINT,
      ADICAO_ENDPOINT,
      id
    ],
    [],
    {
      responseType: ReponseType.ARRAY_BUFFER,
    }
  );
}

async function generateReportItensById(id) {
  return doGetQCXRequest(
    [
      DECLARACAO_IMPORTACAO_ENDPOINT,
      REPORT_ENDPOINT,
      ITENS_ENDPOINT,
      id
    ],
    [],
    {
      responseType: ReponseType.ARRAY_BUFFER,
    }
  );
}

const declaracaoImportacaoAPI = {
  save,
  fetchById,
  generateReportById,
  generateReportAdicoesById,
  generateReportItensById,
};

export {
  declaracaoImportacaoAPI,
  save,
  fetchById,
  generateReportById,
  generateReportAdicoesById,
  generateReportItensById,
};
