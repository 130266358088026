import {
  Badge,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import NotificationsIcon from '@material-ui/icons/Notifications';
import {
  useCallback,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectBadgeContent,
} from '../../features/notificacao/notificacaoSelectors';
import {
  notificacaoActions,
} from '../../features/notificacao/notificacaoSlice';
import QCXBasicMenu from '../basic-menu/QCXBasicMenu';

// const useStyles = makeStyles(() => ({
//   time: {
//     fontSize: '0.75rem',
//     fontFamily: 'Arial',
//     fontWeight: 400,
//     lineHeight: 1.66,
//   },
// }));

export default function QCXNotification({
  // eslint-disable-next-line no-unused-vars
  unidadeDeNegocio,
}) {
  const dispatch = useDispatch();
  // const classes = useStyles();
  const { t } = useTranslation();

  const [openMenu, setOpenMenu] = useState(false);
  // const [anchorEl, setAnchorEl] = useState(null);

  // const notificacoes = useSelector(selectNotificacoes);
  // const novaNotificacao = useSelector(selectNovaNotificacao);
  const badgeContent = useSelector(selectBadgeContent);

  // const unnormalize = useCallback((data) => ({
  //   ...data,
  //   formattedDataCriacao: moment(data?.dataCriacao).format('DD/MM/YYYY HH:mm'),
  // }), []);

  // const updatedNotificacoesList = useMemo(() => (
  //   (notificacoes || [])?.map((notificacao) => (
  //     unnormalize(notificacao)
  //   ))
  // ), [
  //   unnormalize,
  //   notificacoes,
  // ]);

  // const handleOpenMenu = useCallback((e) => {
  //   if (unidadeDeNegocio && !isEmpty(updatedNotificacoesList)) {
  //     setAnchorEl(e.currentTarget);
  //     setOpenMenu(true);
  //   }
  // }, [updatedNotificacoesList, unidadeDeNegocio]);

  // const handleOnMessageReceived = useCallback((notificacao) => {
  //   dispatch(notificacaoActions.setNovaNotificacao(notificacao));
  //   dispatch(notificacaoActions.setBadgeContent(badgeContent + 1));
  // }, [badgeContent]);

  // const handleOnNotificacaoClick = useCallback((notificacao) => {
  //   navigator.clipboard.writeText(notificacao?.followUp?.numero);

  //   dispatch(
  //     setSuccessFeedback({
  //       message: t('com.muralis.qcx.numeroProcessoCopiado'),
  //     })
  //   );
  // }, [badgeContent]);

  // const onConnectFailure = useCallback(() => {
  //   dispatch(failure());
  //   dispatch(setError({
  //     message: t('com.muralis.qcx.erro.erroConexaoPerdidaNotificacoes'),
  //   }));
  // }, []);

  // const handleOnConnect = useCallback(() => {
  //   dispatch(success());
  // }, []);

  const handleCloseMenu = useCallback(() => {
    setOpenMenu(false);
    dispatch(notificacaoActions.resetBadgeContent());
  }, [setOpenMenu]);

  // useEffect(() => {
  //   if (unidadeDeNegocio) {
  //     dispatch(notificacaoThunks.fetchByUnidadeDeNegocioAsync(unidadeDeNegocio));
  //     return;
  //   }
  //   dispatch(notificacaoActions.resetList());
  //   dispatch(notificacaoActions.resetBadgeContent());
  // }, [unidadeDeNegocio]);

  // useEffect(() => {
  //   if (novaNotificacao?.id) {
  //     updatedNotificacoesList?.unshift(novaNotificacao);
  //     dispatch(notificacaoActions.setList(updatedNotificacoesList));
  //     dispatch(notificacaoActions.resetNovaNotificacao());
  //   }
  // }, [novaNotificacao]);

  // const topics = useMemo(() => ([
  //   `/topic/unit/${unidadeDeNegocio}`,
  // ]), [unidadeDeNegocio]);

  return (
    <>
      <Tooltip arrow title={t('com.muralis.qcx.mensagem.alertas')} aria-label="alertas">
        <IconButton
          aria-label={t('com.muralis.qcx.mensagem.alertas')}
          color="inherit"
        // onClick={handleOpenMenu}
        >
          <Badge badgeContent={badgeContent} color="secondary" max={999}>
            <NotificationsIcon />
          </Badge>
        </IconButton>
      </Tooltip>
      <QCXBasicMenu
        id="menu-notificacoes"
        open={openMenu}
        // anchorEl={anchorEl}
        handleClose={handleCloseMenu}
      >
        {/* {updatedNotificacoesList?.map((notificacao) => (
          <MenuItem
            divider
            onClick={() => handleOnNotificacaoClick(notificacao)}
          >
            <CardContent>
              <Typography variant="caption">
                {String(notificacao?.assunto).toUpperCase()}
              </Typography>
              <Typography variant="string" paragraph noWrap>
                {notificacao?.description}
              </Typography>
              <Typography
                sx={{ mb: 1.5 }}
                align="right"
                className={classes.time}
              >
                {notificacao?.formattedDataCriacao}
              </Typography>
            </CardContent>
          </MenuItem>
        ))} */}
      </QCXBasicMenu>
      {/* {unidadeDeNegocio && (
        <QCXWebSocket
          topics={topics}
          onMessageReceived={handleOnMessageReceived}
          onConnect={handleOnConnect}
          onConnectFailure={onConnectFailure}
        />
      )} */}
    </>
  );
}
