import { Grid, makeStyles } from "@material-ui/core";
import { t } from "i18next";
import { ReactNode, useMemo, useState } from "react";
import { Trans } from "react-i18next";
import QCXMoveFocusInside from "../../../../../components/focus-lock/QCXMoveFocusInside";
import QCXFormSubtitle from "../../../../../components/form-title/QCXFormSubtitle";
import QCXInfoAlert from "../../../../../shared-components/alert/QCXInfoAlert";
import QCXAloneInlineBoxWrapper from "../../../../../shared-components/alone-inline-box-wrapper/QCXAloneInlineBoxWrapper";
import QCXFinalTextField from "../../../../../shared-components/final-text-field/QCXFinalTextField";
import { required } from "../../../../../utils/validators/field/validator";
import StyledAutocompleteSelect from "../../../../common/components/input/styledAutocompleteSelect";
import BaseCreateForm from "./baseCreateForm";
import ReferenciaBondManager from "./referenciaBondManager";
import { useListClientes } from "../../../../common/hooks/useListClientes";
import LoadingIndicator from "../../../../common/components/loadingIndicator";
import { ClientData } from "../../../../common/types/clientData";
import {
  parseCnpj,
  parseCpf,
} from "../../../../../utils/hooks/form/field/parsers";
import { useListServicosClienteAssessoria } from "../hooks/useListServicosAssessoria";
import StyledButton from "../../../../common/components/Buttons/StyledButton";
import { useHasPermission } from "../../../../common/hooks/useHasPermission/useHasPermission";
import { ASSESSORIA_FOLLOWUP } from "../../../../common/hooks/useHasPermission/permissionsEnum";

export type FollowUpFormPayload = {
  servico: { id: number };
  importador: { id: number };
  referencias: string[];
  numero: string;
};

type Props = {
  submitCallback: (followUp: FollowUpFormPayload) => void;
};

const useStyles = makeStyles((theme: any) => ({
  root: {
    width: "inherit",
    padding: theme.spacing(2),
  },
  gridFields: {
    paddingBottom: "16px",
  },
}));

const FollowupForm = ({ submitCallback }: Props) => {
  const classes = useStyles();

  const isManual = false;

  const canCreate = useHasPermission(ASSESSORIA_FOLLOWUP.INCLUIR);

  const [referenciasCliente, setReferenciasCliente] = useState<string[]>([]);
  const [clientData, isLoadingClients] = useListClientes();
  const [selectedClientId, setSelectedClientId] = useState<number | null>(null);

  const [selectedServicoId, setSelectedServicoId] =
    useState<number | null>(null);

  const followUpPayload = useMemo<FollowUpFormPayload | null>(() => {
    if (!selectedServicoId || !selectedClientId) {
      return null;
    }

    return {
      numero: "123",
      servico: {
        id: selectedServicoId,
      },
      importador: {
        id: selectedClientId,
      },
      referencias: referenciasCliente,
    };
  }, [selectedClientId, selectedServicoId, referenciasCliente]);

  const [servicosOptions, isLoadingServicos] =
    useListServicosClienteAssessoria(selectedClientId);
  const isLoading = useMemo(
    () => isLoadingClients || isLoadingServicos,
    [isLoadingClients, isLoadingServicos]
  );

  function generateDescription(cliente: ClientData): string {
    const cnpjOrCpf =
      cliente.tipoPessoa === "PESSOA_JURIDICA"
        ? parseCnpj(cliente.pessoa?.cnpj)
        : parseCpf(cliente.pessoa?.cpf);

    return `${cliente.id} - ${cliente.description} - ${cnpjOrCpf}`;
  }

  function handleClientChange(
    value: { id: number; description: string } | null | string
  ) {
    if (typeof value == "string") {
      return;
    }

    if (!value) {
      setSelectedClientId(null);
    }

    setSelectedClientId(value!.id);
  }

  function handleServicoChange(
    value: { id: number; description: string } | null | string
  ) {
    if (typeof value == "string") {
      return;
    }

    if (!value) {
      setSelectedServicoId(null);
    }

    setSelectedServicoId(value!.id);
  }

  function handleChangeReferencias(newReferencias: string[]) {
    setReferenciasCliente(newReferencias);
  }

  return (
    <>
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <BaseCreateForm
          handleSubmit={() => submitCallback(followUpPayload!)}
          validate={undefined}
          fields={undefined}
          initialValues={undefined}
        >
          <Grid item container spacing={2}>
            <Grid
              item
              xs={12}
              style={{
                marginTop: "0.5rem",
                marginBottom: "0.5rem",
              }}
            >
              <QCXInfoAlert>
                <Trans i18nKey="com.muralis.qcx.numeroProcessoAutomaticoUnidadeNegocioAtual">
                  <strong>
                    {
                      {
                        termo: t("com.muralis.qcx.automaticamente"),
                      } as unknown as ReactNode
                    }
                  </strong>
                </Trans>
              </QCXInfoAlert>
            </Grid>

            {isManual && (
              <QCXAloneInlineBoxWrapper
                gridBreakpoints={
                  {
                    sm: 12,
                    md: 6,
                  } as any
                }
              >
                {(fieldProps: any) => (
                  <QCXMoveFocusInside>
                    <QCXFinalTextField
                      id="text-field-numero-processo"
                      key="text-field-numero-processo"
                      name="numero"
                      label={t("com.muralis.qcx.numeroProcesso")}
                      validate={required}
                      disabled={false}
                      helperText={t(
                        "com.muralis.qcx.mensagem.sequencialGeradoAutomaticamente"
                      )}
                      required
                      {...fieldProps}
                    />
                  </QCXMoveFocusInside>
                )}
              </QCXAloneInlineBoxWrapper>
            )}

            <Grid item className={classes.gridFields} xs={12} sm={12} md={6}>
              <StyledAutocompleteSelect
                required
                label={"Cliente"}
                options={clientData.map((cliente) => ({
                  id: cliente.id!,
                  description: generateDescription(cliente),
                }))}
                onChange={(e, value) =>
                  handleClientChange(
                    value as { id: number; description: string } | null | string
                  )
                }
                name={"cliente"}
              />
            </Grid>
            <Grid item className={classes.gridFields} xs={12} sm={12} md={6}>
              <StyledAutocompleteSelect
                required
                label={"Serviço"}
                name={"servico"}
                options={servicosOptions.map((servico) => ({
                  id: servico.id!,
                  description: servico.description ?? "",
                }))}
                onChange={(e, value) =>
                  handleServicoChange(
                    value as { id: number; description: string } | null | string
                  )
                }
              />
            </Grid>

            <Grid item xs={12}>
              <QCXFormSubtitle
                title={t("com.muralis.qcx.referenciasCliente")}
              />
            </Grid>
            <ReferenciaBondManager
              values={referenciasCliente}
              changeValuesCallback={handleChangeReferencias}
            ></ReferenciaBondManager>
            <Grid
              container
              item
              justify="flex-end"
              alignContent="flex-end"
              xs={12}
            >
              <StyledButton
                type="submit"
                color="secondary"
                disabled={followUpPayload === null || !canCreate}
              >
                Abrir
              </StyledButton>
            </Grid>
          </Grid>
        </BaseCreateForm>
      )}
    </>
  );
};

export default FollowupForm;
