import { getOptionByValueInList } from '../../utils/general/general-utils';
import i18n from '../../i18n';

const UTILIZACAO_TRANSPORTE_TOTAL = 'TOTAL';
const UTILIZACAO_TRANSPORTE_PARCIAL = 'PARCIAL';
const UTILIZACAO_TRANSPORTE_MAIS_DE_UM = 'MAIS_DE_UM';

const UTILIZACAO_TRANSPORTE_LIST = [
  {
    label: i18n.t('com.muralis.qcx.total').toUpperCase(),
    value: UTILIZACAO_TRANSPORTE_TOTAL,
  },
  {
    label: i18n.t('com.muralis.qcx.parcial').toUpperCase(),
    value: UTILIZACAO_TRANSPORTE_PARCIAL,
  },
  {
    label: i18n.t('com.muralis.qcx.maisDeUm').toUpperCase(),
    value: UTILIZACAO_TRANSPORTE_MAIS_DE_UM,
  },
];

const isUtilizacaoTransporteTotal = (value) => (
  UTILIZACAO_TRANSPORTE_TOTAL === value
);

const isUtilizacaoTransporteParcial = (value) => (
  UTILIZACAO_TRANSPORTE_PARCIAL === value
);

const isUtilizacaoTransporteMaisDeUm = (value) => (
  UTILIZACAO_TRANSPORTE_MAIS_DE_UM === value
);

const getUtilizacaoTransporteLabelByValue = (value) => {
  const foundOption = getOptionByValueInList(UTILIZACAO_TRANSPORTE_LIST, value);
  return foundOption && foundOption?.label;
};

export {
  UTILIZACAO_TRANSPORTE_TOTAL,
  UTILIZACAO_TRANSPORTE_PARCIAL,
  UTILIZACAO_TRANSPORTE_MAIS_DE_UM,
  UTILIZACAO_TRANSPORTE_LIST,
};

export {
  isUtilizacaoTransporteTotal,
  isUtilizacaoTransporteParcial,
  isUtilizacaoTransporteMaisDeUm,
  getUtilizacaoTransporteLabelByValue,
};
