import {
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { isFunction } from 'lodash';
import {
  AddCircle as AddCircleIcon,
  Block as BlockIcon,
  Clear as ClearIcon,
  Delete as DeleteIcon,
  Search as PageviewIcon,
} from '@material-ui/icons';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import QCXBox from '../../shared-components/box/QCXBox';
import QCXButton from '../../shared-components/button/QCXButton';
import QCXDataGridControl from '../../shared-components/data-grid-control/QCXDataGridControl';
import QCXDataGrid from '../../shared-components/data-grid/QCXDataGrid';

const useStyles = makeStyles((theme) => ({
  controlButton: {
    marginRight: theme.spacing(1),
    '& .MuiButton-startIcon': {
      marginRight: '4px',
    },
  },
}));

export default function QCXRegistrationOneToManyPageTemplate({ ...props }) {
  const { t } = useTranslation();
  const classes = useStyles();

  const {
    title,
    tableProps,
    tableData,
    children,
    isCreate,
    isConsult,
    isUpdate,
    isSubNone,
    isSubCreate,
    isSubConsult,
    isSubUpdate,
    handleCancelCreate,
    handleCancelUpdate,
    handleRemove,
    handleRemoveByForm,
    handleRemoveByTable,
    handleNew,
    handleEdit,
    handleClearForm,
    handleRestartForm,
    handleRowClick,
    localeTableText,
    filterProp = 'code',
    filterPropGetter = (values) => values?.code,
    alternativeMode = false,
    addButtonText = t('com.muralis.qcx.acoes.novo').toUpperCase(),
    handleReinitialize,
  } = props;

  const [isFirstLoadOfForm, setIsFirstLoadOfForm] = useState(true);

  const handleInternalClearForm = () => {
    handleClearForm();
    handleRestartForm();
  };

  const handleInternalNew = () => {
    handleInternalClearForm();
    handleNew();
  };

  const handleInternalCancelCreate = () => {
    handleCancelCreate();
    handleRestartForm();

    if (isFunction(handleRestartForm)) {
      handleRestartForm();
    }
  };

  const handleInternalCancelUpdate = () => {
    handleCancelUpdate();

    if (isFunction(handleReinitialize)) {
      handleReinitialize();
    }
  };

  const handleInternalRemove = () => {
    handleRemove();
    handleRestartForm();
  };

  const handleInternalRemoveByForm = () => {
    handleRemoveByForm();
    handleRestartForm();
  };

  useEffect(() => {
    if (isSubNone) {
      handleRestartForm();
    }
  }, [isSubNone]);

  useEffect(() => {
    if (alternativeMode && isUpdate && isSubConsult) {
      handleEdit();
    }
  }, [isUpdate, isSubConsult]);

  useEffect(() => {
    if (!alternativeMode && isFirstLoadOfForm && isSubNone && (isCreate || isUpdate)) {
      handleInternalNew();
      setIsFirstLoadOfForm(false);
    }
  }, [
    alternativeMode,
    isSubNone,
    isFirstLoadOfForm,
    handleInternalNew,
    setIsFirstLoadOfForm
  ]);

  const tableTitle = tableProps?.title?.toUpperCase() || t('com.muralis.qcx.itensAdicionados').toUpperCase();

  return alternativeMode
    ? (
      <>
        {isSubNone && (
          <Grid container justify="flex-start" style={{ marginTop: '0.2rem' }}>
            <Typography variant="h6" color="primary">
              <QCXBox fontWeight="fontWeightBold" pt={5}>
                {tableTitle}
              </QCXBox>
            </Typography>
            <Grid container style={{ marginTop: '0.7rem' }}>
              <QCXDataGridControl
                columns={[
                  ...tableProps?.columns,
                  {
                    field: 'actions',
                    headerName: t('com.muralis.qcx.acoes.label'),
                    headerAlign: 'center',
                    flex: 200,
                    align: 'center',
                    renderCell: ({ row }) => (
                      <>
                        <IconButton
                          key={`btn-view-${row?.id}`}
                          name={`btn-view-${row?.id}`}
                          onClick={() => handleRemoveByTable({ row })}
                        >
                          <DeleteIcon
                            color="error"
                          />
                        </IconButton>
                        <IconButton
                          key={`btn-view-${row?.id}`}
                          name={`btn-view-${row?.id}`}
                          onClick={() => handleRowClick({ row })}
                        >
                          <PageviewIcon
                            color="primary"
                          />
                        </IconButton>
                      </>
                    ),
                  },
                ]}
                rows={tableData}
                localeText={{
                  noRowsLabel: localeTableText.noRowsLabel || t('com.muralis.qcx.validacao.nenhumItemAdicionado'),
                }}
                filterProp={filterProp}
                filterPropGetter={filterPropGetter}
                controlButtons={[
                  {
                    description: t('com.muralis.qcx.acoes.novo').toUpperCase(),
                    startIcon: <AddCircleIcon />,
                    tooltip: {
                      description: t('com.muralis.qcx.acoes.adicionarNovoItem'),
                    },
                    disabled: isConsult,
                    onClick: handleInternalNew,
                  },
                ]}
              />
            </Grid>
          </Grid>
        )}
        {!isSubNone && (
          <>
            <Grid container justify="flex-start" style={{ marginTop: '0.2rem' }}>
              <Grid item xs={12} sm={12} md={12} xl={12}>
                <Typography variant="h6" color="primary" fontWeight="fontWeightBold">
                  <QCXBox fontWeight="fontWeightBold" pt={1} pl={0}>
                    {String(title).toUpperCase() || t('com.muralis.qcx.formulario').toUpperCase()}
                  </QCXBox>
                </Typography>
              </Grid>
            </Grid>
            <Grid container style={{ marginTop: '1.5rem' }}>
              <Grid container justify="flex-start" spacing={2}>
                {children}
              </Grid>
              <Grid container spacing={2} justify="space-between" style={{ marginTop: '1.5rem' }}>
                <Grid item>
                  {isSubCreate && !isConsult && (
                    <QCXButton
                      className={classes.controlButton}
                      variant="outlined"
                      startIcon={<BlockIcon />}
                      disabled={isConsult}
                      onClick={handleInternalCancelCreate}
                    >
                      {t('com.muralis.qcx.acoes.cancelar')}
                      Cancelar
                    </QCXButton>
                  )}
                  {isSubCreate && !isConsult && (
                    <QCXButton
                      className={classes.controlButton}
                      color="secondary"
                      disabled={isConsult}
                      type="submit"
                    >
                      {t('com.muralis.qcx.acoes.adicionar')}
                    </QCXButton>
                  )}
                  {(isCreate || isConsult) && isSubConsult && (
                    <QCXButton
                      className={classes.controlButton}
                      color="secondary"
                      onClick={handleEdit}
                      disabled={isUpdate}
                    >
                      {t('com.muralis.qcx.acoes.alterar')}
                    </QCXButton>
                  )}
                  {isSubUpdate && (
                    <QCXButton
                      className={classes.controlButton}
                      variant="outlined"
                      startIcon={<BlockIcon />}
                      // disabled={!isSubUpdate || isConsult}
                      onClick={handleInternalCancelUpdate}
                    >
                      {t('com.muralis.qcx.acoes.cancelar')}
                    </QCXButton>
                  )}
                  {isSubUpdate && (
                    <QCXButton
                      className={classes.controlButton}
                      color="secondary"
                      type="submit"
                    >
                      {t('com.muralis.qcx.acoes.salvar')}
                    </QCXButton>
                  )}
                  {isSubConsult && (
                    <QCXButton
                      className={classes.controlButton}
                      variant="outlined"
                      startIcon={(
                        <DeleteIcon
                          color="inherit"
                        />
                      )}
                      style={{
                        fontWeight: 550,
                      }}
                      disabled={!isSubConsult}
                      onClick={handleInternalRemoveByForm}
                    >
                      {t('com.muralis.qcx.acoes.remover')}
                    </QCXButton>
                  )}
                </Grid>
                <Grid item>
                  {isSubConsult && (
                    <QCXButton
                      className={classes.controlButton}
                      variant="outlined"
                      onClick={handleInternalClearForm}
                    >
                      {t('com.muralis.qcx.acoes.voltar')}
                    </QCXButton>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </>
    ) : (
      <>
        <Grid container justify="flex-start" style={{ marginTop: '0.2rem' }}>
          <Typography variant="h6" color="primary">
            <QCXBox fontWeight="fontWeightBold" pt={5} pl={0}>
              {String(title).toUpperCase() || t('com.muralis.qcx.formulario').toUpperCase()}
            </QCXBox>
          </Typography>
        </Grid>
        <Grid container style={{ marginTop: '1.5rem' }}>
          <Grid container justify="flex-start" spacing={2}>
            {children}
          </Grid>
          <Grid container spacing={2} justify="space-between" style={{ marginTop: '1.5rem' }}>
            <Grid item>
              {(isSubNone || isSubConsult) && !isConsult && (
                <QCXButton
                  className={classes.controlButton}
                  color="primary"
                  startIcon={<AddCircleIcon />}
                  disabled={isConsult}
                  onClick={handleInternalNew}
                >
                  {addButtonText}
                </QCXButton>
              )}
              {isSubCreate && !isConsult && (
                <QCXButton
                  className={classes.controlButton}
                  color="secondary"
                  disabled={isConsult}
                  type="submit"
                >
                  {t('com.muralis.qcx.acoes.adicionar')}
                </QCXButton>
              )}
              {isSubCreate && !isConsult && (
                <QCXButton
                  className={classes.controlButton}
                  variant="outlined"
                  startIcon={<BlockIcon />}
                  disabled={isConsult}
                  onClick={handleInternalCancelCreate}
                >
                  {t('com.muralis.qcx.acoes.cancelar')}
                </QCXButton>
              )}
              {isSubConsult && !isConsult && (
                <QCXButton
                  className={classes.controlButton}
                  color="secondary"
                  onClick={handleEdit}
                  disabled={isConsult}
                >
                  {t('com.muralis.qcx.acoes.editar')}
                </QCXButton>
              )}
              {isSubUpdate && (
                <QCXButton
                  className={classes.controlButton}
                  color="secondary"
                  disabled={isConsult}
                  type="submit"
                >
                  {t('com.muralis.qcx.acoes.atualizar')}
                </QCXButton>
              )}
              {isSubUpdate && (
                <QCXButton
                  className={classes.controlButton}
                  variant="outlined"
                  startIcon={<BlockIcon />}
                  disabled={!isSubUpdate || isConsult}
                  onClick={handleInternalCancelUpdate}
                >
                  {t('com.muralis.qcx.acoes.cancelar')}
                </QCXButton>
              )}
              {isSubConsult && (
                <QCXButton
                  className={classes.controlButton}
                  variant="outlined"
                  startIcon={<ClearIcon />}
                  onClick={handleInternalClearForm}
                >
                  {t('com.muralis.qcx.acoes.limparSelecao')}
                </QCXButton>
              )}
              {isSubConsult && !isConsult && (
                <QCXButton
                  className={classes.controlButton}
                  variant="outlined"
                  startIcon={(
                    <DeleteIcon
                      color="inherit"
                    />
                  )}
                  style={{
                    fontWeight: 550,
                  }}
                  disabled={!isSubConsult}
                  onClick={handleInternalRemove}
                >
                  {t('com.muralis.qcx.acoes.remover')}
                </QCXButton>
              )}
            </Grid>
          </Grid>
          <Typography variant="h6" color="primary" style={{ marginTop: '-0.5rem' }}>
            <QCXBox fontWeight="fontWeightBold" pt={5}>
              {tableTitle}
            </QCXBox>
          </Typography>
          <Grid container style={{ marginTop: '0.7rem' }}>
            <QCXDataGrid
              columns={tableProps?.columns}
              rows={tableData}
              onRowClick={handleRowClick}
              localeText={{
                noRowsLabel: localeTableText.noRowsLabel || t('com.muralis.qcx.validacao.nenhumItemEncontrado'),
              }}
            />
          </Grid>
        </Grid>
      </>
    );
}
