import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  setUnidadesNegocio,
} from '../../features/despachante/despachanteSlice';
import QCXFinalFormWizard from '../../shared-components/final-form-wizard/QCXFinalFormWizard';
import QCXDespachanteTecnicalDataForm from './QCXDespachanteTecnicalDataForm';
import QCXSelectManyUnidadeNegocioFinalFormTable from '../unidade-negocio/QCXSelectManyUnidadeNegocioFinalFormTable';

export default function QCXDespachanteWizardFinalForm({
  handleSubmit,
  handleAlternativeSave,
  handleChangeToPreparingAction,
  model,
  handleChangeModel,
  handleChangeToCreate,
  handleChangeToConsult,
  handleChangeToUpdate,
  handleCancelUpdate,
  handleChangeToBackgroundCreate,
  handleResetBackgroundMode,
  isCreate,
  isConsult,
  isUpdate,
  isBackgroundCreate,
  isPreparingAction,
  authInfo = {},
  requiredRoles = [],
  ...restProps
}) {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const unidadesNegocio = useMemo(() => (
    model?.unidadesDeNegocio || []
  ), [model]);

  const handleSetSelectedUnidadesNegocio = (data) => {
    dispatch(setUnidadesNegocio(data));
  };

  const checkIfIsAlternativeSaveByStep = (currentStep) => (
    currentStep === 1
  );

  const wizardSteps = [
    t('com.muralis.qcx.dadosTecnicos'),
    t('com.muralis.qcx.unidadeNegocio.label'),
  ];

  return (
    <QCXFinalFormWizard
      steps={wizardSteps}
      initialValues={model}
      handleChangeValues={handleChangeModel}
      isCreate={isCreate}
      isConsult={isConsult}
      isUpdate={isUpdate}
      isBackgroundCreate={isBackgroundCreate}
      checkIfIsAlternativeSaveByStep={checkIfIsAlternativeSaveByStep}
      handleSubmit={handleSubmit}
      handleAlternativeSave={handleAlternativeSave}
      handleChangeToCreate={handleChangeToCreate}
      handleChangeToBackgroundCreate={handleChangeToBackgroundCreate}
      handleChangeToConsult={handleChangeToConsult}
      handleChangeToUpdate={handleChangeToUpdate}
      handleCancelUpdate={handleCancelUpdate}
      handleResetBackgroundMode={handleResetBackgroundMode}
      {...restProps}
    >
      {(formProps) => (
        <QCXDespachanteTecnicalDataForm
          key="ficha-dados-tecnicos-despachante"
          isCreate={isCreate}
          isConsult={isConsult}
          isUpdate={isUpdate}
          authInfo={authInfo}
          requiredRoles={requiredRoles}
          {...formProps}
        />
      )}
      {({ controlComponentProps, ...restFormProps }) => (
        <QCXSelectManyUnidadeNegocioFinalFormTable
          key="ficha-unidade-negocio-despachante"
          list={unidadesNegocio}
          isConsult={isConsult}
          handleChange={handleSetSelectedUnidadesNegocio}
          controlComponentProps={controlComponentProps}
          authInfo={authInfo}
          requiredRoles={requiredRoles}
          {...restFormProps}
        />
      )}
    </QCXFinalFormWizard>
  );
}
