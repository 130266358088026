import { forwardRef } from "react";
import { IMaskInput } from "react-imask";

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const PercentageTextField = forwardRef<HTMLElement, CustomProps>(
  function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask={"num %"}
        lazy={false}
        blocks={{
          num: {
            mask: Number,
            scale: 2,
            radix: ".",
            mapToRadix: [","],
            normalizeZeros: false,
          },
        }}
        onAccept={(value: any) =>
          onChange({ target: { name: props.name, value: value.slice(0, -2) } })
        }
        overwrite
      />
    );
  }
);

export default PercentageTextField;
