import React, { useMemo, useState } from 'react';
import { Collapse } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { isFunction } from 'lodash';

export default function QCXAlert({
  severity,
  onClose,
  closable,
  unmountOnClose = false,
  ...restProps
}) {
  const [open, setOpen] = useState(true);

  const handleClose = useMemo(() => (
    closable
      ? (...args) => {
        if (isFunction(onClose)) {
          onClose(...args);
        }
        setOpen(false);
      }
      : undefined
  ), [
    closable,
    onClose,
    setOpen,
  ]);

  return (
    <Collapse
      in={open}
      unmountOnExit={unmountOnClose}
    >
      <Alert
        severity={severity}
        onClose={handleClose}
        closable={closable}
        {...restProps}
      />
    </Collapse>
  );
}
