import { createAsyncThunk } from '@reduxjs/toolkit';
import { downloaderAPI } from './downloaderAPI';

const downloadFileByIdAsync = createAsyncThunk(
  'downloader/downloadFileById',
  async ({
    data,
    queryParams = [],
    config = {},
  }) => {
    const response = await downloaderAPI.downloadFileById(
      data.id,
      queryParams,
      config
    );
    return {
      data: response?.data,
      headers: response?.headers,
      status: response?.status,
    };
  }
);

const downloadMultipleFilesByAsync = createAsyncThunk(
  'downloader/downloadMultipleFilesBy',
  async ({
    data,
    queryParams = [],
    config = {},
  }) => {
    const response = await downloaderAPI.downloadMultipleFilesBy(
      data,
      queryParams,
      config
    );
    return {
      data: response?.data,
      headers: response?.headers,
      status: response?.status,
    };
  }
);

const downloaderThunks = {
  downloadFileByIdAsync,
  downloadMultipleFilesByAsync,
};

export {
  downloaderThunks,
  downloadFileByIdAsync,
  downloadMultipleFilesByAsync,
};
