const CTRL_KEY = 'ctrl';
const SHIFT_KEY = 'shift';
const L_KEY = 'l';
const PLUS_KEY = '+';

const combineShortcutKeys = (keys = [], separator = PLUS_KEY) => (
  keys.join(separator)
);

export {
  combineShortcutKeys,
};

export {
  CTRL_KEY,
  SHIFT_KEY,
  L_KEY,
  PLUS_KEY,
};
