import { isEmpty } from 'lodash';
import debounce from 'lodash/debounce';
import {
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  useHistory,
  useParams,
} from 'react-router';

import QCXRegistrationFormPageTemplate from '../../../../../../templates/registration-form-page/QCXRegistrationFormPageTemplate';
import {
  isConsultMode,
  isCreateMode,
  isNoneMode,
  isUpdateMode,
} from '../../../../../../utils/store/store-utils';
import { register, save } from '../api/camposAdicionaisAPI';
import {
  addToList,
  changeToConsultMode, changeToCreateMode, changeToUpdateMode, failure, fetchByIdAsync, loading, resetModel, selectCampoAdicional,
  selectMode, setError, setModel, setResponse, success, updateOnList,
} from '../api/camposAdicionaisSlice';
import QCXCamposAdicionaisselectCamposAdicionaisFinalForm from './components/QCXCamposAdicionaisFinalForm';

export default function CamposAdicionaisRegistrationPage({ authInfo = {} }) {
  const { t } = useTranslation();

  const { id } = useParams();

  const history = useHistory();

  const dispatch = useDispatch();

  const mode = useSelector(selectMode);
  const campoAdicional = useSelector(selectCampoAdicional);

  const isNone = useMemo(() => (
    isNoneMode(mode)
  ), [mode]);

  const isCreate = useMemo(() => (
    isCreateMode(mode)
  ), [mode]);

  const isConsult = useMemo(() => (
    isConsultMode(mode)
  ), [mode]);

  const isUpdate = useMemo(() => (
    isUpdateMode(mode)
  ), [mode]);

  const handleChangeToCreate = () => {
    dispatch(changeToCreateMode());
  };

  const handleChangeToConsult = () => {
    dispatch(changeToConsultMode());
  };

  const handleChangeToUpdate = () => {
    dispatch(changeToUpdateMode());
  };

  useEffect(() => {
    if (!isEmpty(id)) {
      dispatch(fetchByIdAsync(id));
      handleChangeToConsult();
    }
  }, [id]);

  const handleCancelUpdate = () => {
    if (id) {
      dispatch(fetchByIdAsync(id));
    }
    handleChangeToConsult();
  };

  const normalize = useCallback((unnormalizedData) => ({
    ...unnormalizedData,
  }), []);

  const unnormalize = useCallback((normalizedData) => ({
    ...normalizedData,
  }), []);

  const handleDispatchSetModel = useCallback((data) => {
    dispatch(setModel(normalize(data)));
  }, [normalize]);

  const create = async (data) => {
    const executeDebounced = debounce(async () => {
      try {
        const response = await register(data);

        if (response?.status === 201) {
          dispatch(resetModel());

          const created = response?.data;

          handleDispatchSetModel(created);
          dispatch(addToList({ data: created }));

          const handleResultWithDebounce = debounce(() => {
            history.push(t('com.muralis.qcx.url.moduloGeraisServicosCamposAdicionais'));

            dispatch(success());
            dispatch(setResponse({
              status: response.status,
              data: created,
              message: t('com.muralis.qcx.mensagem.etapaCadastrada', { etapa: data?.description }),
            }));
          }, 500);

          handleResultWithDebounce();
        }
      } catch (error) {
        dispatch(failure());
        let errorMessage = t('com.muralis.qcx.erro.erroSalvarDadosEtapa');
        if (error?.response && error?.response?.data) {
          errorMessage = t('com.muralis.qcx.erro.erroSalvarDadosEtapaMensagem', { erro: error?.response.data.message });
        }
        dispatch(setError({
          message: errorMessage,
        }));
      }
    }, 500);

    dispatch(loading());
    executeDebounced();
  };

  const update = async (data) => {
    const executeDebounced = debounce(async () => {
      try {
        const response = await save(data);

        if (response?.status === 200) {
          const handleResultWithDebounce = debounce(() => {
            handleChangeToConsult();
            dispatch(success());

            const saved = response?.data;

            dispatch(setResponse({
              status: response.status,
              data: saved,
              message: t('com.muralis.qcx.mensagem.etapaSalva', { etapa: saved?.description }),
            }));

            dispatch(setModel(saved));
            dispatch(updateOnList({ data: saved }));
            dispatch(fetchByIdAsync(saved?.id));
          }, 500);

          handleResultWithDebounce();
          handleChangeToConsult();
        }
      } catch ({ response }) {
        dispatch(failure());
        dispatch(setError({
          message: t('com.muralis.qcx.erro.erroSalvarDadosEtapaEspecifico', { etapa: data?.description, erro: response?.data?.message }),
        }));
      }
    }, 500);

    dispatch(loading());
    executeDebounced();
  };

  const handleSubmit = async (data) => {
    const normalizedData = normalize(data);

    if (isUpdate) {
      await update(normalizedData);
    } else {
      await create(normalizedData);
    }
  };

  const model = useMemo(() => (
    !isCreate
      ? unnormalize(campoAdicional)
      : {}
  ), [isCreate, campoAdicional, unnormalize]);

  const actionName = useMemo(() => {
    if (isCreate || isNone) return t('com.muralis.qcx.acoes.nova');
    if (isConsult) return t('com.muralis.qcx.acoes.visualizar');
    return t('com.muralis.qcx.acoes.alterar');
  }, [isNone, isCreate, isConsult]);

  const breadcrumbs = useMemo(() => ([
    {
      link: {
        to: '/',
        name: t('com.muralis.qcx.inicio'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.cadastros'),
      },
    },
    {
      link: {
        to: t('com.muralis.qcx.url.moduloCadastrosGerais'),
        name: t('com.muralis.qcx.gerais'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.servico.labelPlural'),
      },
    },
    {
      link: {
        to: t('com.muralis.qcx.url.moduloGeraisServicosCamposAdicionais'),
        name: t('com.muralis.qcx.campoAdicional.labelPlural'),
      },
    },
    {
      text: {
        name: actionName,
      },
    },
  ]), [actionName]);

  const pageTitle = useMemo(() => (
    isNone || isCreate
      ? t('com.muralis.qcx.campoAdicional.novaCampoAdicional')
      : t('com.muralis.qcx.campoAdicional.ocorrenciaExistente', { ID: campoAdicional?.description || campoAdicional?.id || '-' })
  ), [isNone, isCreate, campoAdicional]);

  const breadcrumbsProps = {
    maxItems: 6,
  };

  return (
    <QCXRegistrationFormPageTemplate
      pageTitle={pageTitle}
      breadcrumbs={breadcrumbs}
      breadcrumbsProps={breadcrumbsProps}
      isCreate={isCreate}
      isConsult={isConsult}
      isUpdate={isUpdate}
      handleChangeToCreate={handleChangeToCreate}
      handleChangeToConsult={handleChangeToConsult}
      handleChangeToUpdate={handleChangeToUpdate}
      handleCancelUpdate={handleCancelUpdate}
      authInfo={authInfo}
    >
      {(formProps) => (
        <QCXCamposAdicionaisselectCamposAdicionaisFinalForm
          model={model}
          handleChangeModel={handleDispatchSetModel}
          handleSubmit={handleSubmit}
          authInfo={authInfo}
          requiredRoles={['etapa']}
          {...formProps}
        />
      )}
    </QCXRegistrationFormPageTemplate>
  );
}
