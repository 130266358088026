import React, { useMemo } from 'react';
import BaseSelectWithOptions from '../../../../../../../shared-components/dados-embarque/base-components/BaseSelectWithOptions';

function TipoCanalDesembaracoSelect({ ...props }) {
  const list = useMemo(() => [
    {
      id: 'VERDE', label: 'Verde', value: 'VERDE',
    },
    {
      id: 'LARANJA', label: 'Laranja', value: 'LARANJA',
    },
    {
      id: 'VERMELHO', label: 'Vermelho', value: 'VERMELHO',
    }
  ], []);

  return (
    <BaseSelectWithOptions
      list={list}
      {...props}
    />
  );
}

export default TipoCanalDesembaracoSelect;
