import { fetchByCustomFilter as fetchNcmSubItemByFilter } from '../../../../features/ncm-subitem/ncmSubitemAPI';

// TODO: utilizar thunks ao inves de chamar a request direto
// TODO: tipar o retorno da função
const useFindNcmsForItensXml = (): [(itensXmlDanfe: XmlItemPayload[]) => Promise<unknown[]>] => {
  async function findNcmForItensXml(itensXmlDanfe: XmlItemPayload[]) {
    const cache: Record<string, boolean | undefined> = {};
    const ncmsToLookFor: XmlItemPayload[] = [];

    itensXmlDanfe.forEach((iten) => {
      if (!iten.ncm) {
        return;
      }

      if (!cache[iten.ncm]) {
        cache[iten.ncm] = true;
        ncmsToLookFor.push(iten);
      }
    });

    const findNcmPromises = ncmsToLookFor?.map((item) =>
      fetchNcmSubItemByFilter([
        {
          name: 'query',
          value: item.ncm,
        },
      ])
    );

    const ncmsResponses = await Promise.all(findNcmPromises);

    const ncmsSuccessResponse = ncmsResponses?.filter((response) => response?.status === 200);

    const ncms = ncmsSuccessResponse?.map((responseSuccess) => responseSuccess?.data[0]);

    return ncms;
  }

  return [findNcmForItensXml];
};

type XmlItemPayload = {
  ncm: string;
};

export default useFindNcmsForItensXml;
