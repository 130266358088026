import { createFilterOptions } from '@material-ui/lab/useAutocomplete';
import _ from 'lodash';
import React, {
  useMemo,
  useEffect,
  useState,
} from 'react';
import QCXAutocompleteSelect from '../../../shared-components/autocomplete-select/QCXAutocompleteSelect';

export default function QCXSelectCertificadoAutocomplete({
  label,
  name,
  initialValues,
  ...restProps
}) {
  const [defaultValue, setDefaultValue] = useState('');

  const list = useMemo(() => [{ id: 'CCROM', value: 'CCROM' }], []);

  useEffect(() => {
    const value = list.find((item) => item?.id === _.get(initialValues, name));
    if (value) {
      setDefaultValue(value);
    } else {
      setDefaultValue({ id: null, description: '' });
    }
  }, [list, initialValues]);

  const filterOptions = createFilterOptions({
    stringify: (item) => `${item.value}`,
  });

  return (
    <QCXAutocompleteSelect
      label={label}
      id={name}
      name={name}
      options={list}
      filterOptions={filterOptions}
      defaultValue={defaultValue}
      getOptionLabel={(item) => (
        item.value ? item.value : ''
      )}
      {...restProps}
    />
  );
}
