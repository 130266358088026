import { useTheme } from '@material-ui/core';
import { Grid, Typography } from '@mui/material';

const FormHeaderMedium = (props: {
  children: string;
  marginTop?: string;
  marginBottom?: string;
}) => {
  const theme = useTheme();
  return (
    <Grid item sm={12}>
      <Typography
        textAlign={`left`}
        fontSize={16}
        fontWeight="bold"
        margin={`${props.marginTop ? props.marginTop : '0'} 0 ${
          props.marginBottom ? props.marginBottom : '0'
        } 0`}
        color={theme.palette.primary.main}
      >
        {props.children.toUpperCase()}
      </Typography>
    </Grid>
  );
};

export default FormHeaderMedium;
