import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@material-ui/core';
import {
  Search as PageviewIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import DespachanteContext from '../../../../../contexts/registrations/despachante/DespachanteContext';
import QCXSimpleConsultPageTemplate from '../../../../../templates/simple-consult-page/QCXSimpleConsultPageTemplate';
import {
  activateByIdAsync,
  changeToConsultMode,
  changeToCreateMode,
  fetchAllAsync,
  inactivateByIdAsync,
  resetBackgroundMode,
  resetMode,
  resetModel,
  selectDespachantes,
} from '../../../../../features/despachante/despachanteSlice';
import useOperationController from '../../../../../utils/hooks/store/operation/useOperationController';
import { isAlternativeLoadingStatus } from '../../../../../utils/store/store-utils';
import { resetList as resetUnidadeNegocioList } from '../../../../../features/unidade-negocio/unidadeNegocioSlice';
import { unnormalizeNumeral } from '../../../../../utils/general/general-utils';
import { formatBrazilianNumericDecimal, formatCpfOrCnpj, formatRegistroDespachante } from '../../../../../utils/hooks/form/field/formatters';
import { userHasDeleteRole } from '../../../../../components/perfil/perfil-utils';

export default function DespachanteConsultPage({ authInfo = {} }) {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    status,
  } = useContext(DespachanteContext);

  const despachantes = useSelector(selectDespachantes);

  const isAlternativeLoading = useMemo(() => (
    isAlternativeLoadingStatus(status)
  ), [status]);

  const fetchAll = useCallback(() => {
    dispatch(fetchAllAsync());
  }, []);

  const resetModes = useCallback(() => {
    dispatch(resetMode());
    dispatch(resetBackgroundMode());
  }, []);

  const resetModels = useCallback(() => {
    dispatch(resetModel());
  }, []);

  const resetDependencies = useCallback(() => {
    dispatch(resetUnidadeNegocioList());
  }, []);

  useEffect(() => {
    fetchAll();
    resetModes();
    resetModels();
    resetDependencies();
  }, []);

  const handleActivateInactivate = useOperationController(
    async (event, id) => {
      event.preventDefault();

      const { active } = despachantes.find(
        (despachante) => despachante.id === id
      );

      const isActivation = !active;

      if (isActivation) {
        dispatch(activateByIdAsync(id));
      } else {
        dispatch(inactivateByIdAsync(id));
      }
    }
  );

  const handleClickOnButtonNew = useCallback((event) => {
    event.stopPropagation();
    dispatch(changeToCreateMode());
  }, []);

  const handleClickOnEditButton = useCallback((event, id) => {
    event.stopPropagation();
    dispatch(changeToConsultMode());
    history.push(t('com.muralis.qcx.url.moduloOperacionaisDespachantesDetalhes', { id }));
  }, [history]);

  const columns = [
    {
      field: 'id',
      headerName: t('com.muralis.qcx.codigo'),
      headerAlign: 'center',
      align: 'center',
      flex: 90,
      valueGetter: ({ row }) => (
        row?.id
      ),
    },
    {
      field: 'nome',
      headerName: t('com.muralis.qcx.nome'),
      type: 'string',
      flex: 250,
      valueGetter: ({ row }) => (
        row?.nome
      ),
    },
    {
      field: 'registro',
      headerName: t('com.muralis.qcx.registro'),
      headerAlign: 'center',
      align: 'center',
      flex: 150,
      valueFormatter: ({ row }) => (
        formatRegistroDespachante(row?.registro)
      ),
    },
    {
      field: 'cpfCnpj',
      headerName: t('com.muralis.qcx.CPFCNPJ'),
      headerAlign: 'center',
      align: 'center',
      flex: 145,
      valueFormatter: ({ row }) => (
        formatCpfOrCnpj(row?.cpfCnpj)
      ),
    },
    {
      field: 'limiteRecolhimento',
      headerName: t('com.muralis.qcx.despachante.limiteRecolhimento'),
      headerAlign: 'right',
      align: 'right',
      flex: 235,
      valueFormatter: ({ row }) => (
        `R$ ${unnormalizeNumeral(
          row?.limiteRecolhimento,
          formatBrazilianNumericDecimal(2)
        )}`
      ),
      valueGetter: ({ row }) => (
        unnormalizeNumeral(
          row?.limiteRecolhimento,
          formatBrazilianNumericDecimal(2)
        )
      ),
    },
    {
      field: 'actions',
      headerName: t('com.muralis.qcx.acoes.label'),
      headerAlign: 'center',
      align: 'center',
      flex: 130,
      renderCell: ({ row: { id, active } }) => (
        <>
          {userHasDeleteRole(authInfo, 'despachante') && (
          <IconButton
            key={`stw-active${id}`}
            value={active}
            name={`stw-active-${id}`}
            onClick={(event) => handleActivateInactivate(event, id)}
          >
            <DeleteIcon color="error" />
          </IconButton>
          )}
          <IconButton
            key={`btn-view-${id}`}
            name={`btn-view-${id}`}
            onClick={(event) => handleClickOnEditButton(event, id)}
          >
            <PageviewIcon color="primary" />
          </IconButton>
        </>
      ),
    }
  ];

  const breadcrumbs = [
    {
      link: {
        to: '/',
        name: t('com.muralis.qcx.inicio'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.cadastros'),
      },
    },
    {
      link: {
        to: t('com.muralis.qcx.url.moduloOperacionais'),
        name: t('com.muralis.qcx.operacionais'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.despachante.label'),
      },
    },
  ];

  const templateProps = {
    page: {
      title: t('com.muralis.qcx.despachante.labelPlural'),
      breadcrumbs,
    },
    control: {
      buttonNew: {
        description: t('com.muralis.qcx.despachante.novoDespachante'),
        path: '/cadastros/operacionais/despachantes/novo',
        onClick: handleClickOnButtonNew,
      },
    },
    table: {
      columns,
    },
  };

  return (
    <QCXSimpleConsultPageTemplate
      pageProps={templateProps.page}
      controlProps={templateProps.control}
      tableProps={templateProps.table}
      tableData={despachantes}
      isLoading={isAlternativeLoading}
      filterProp="nome"
      authInfo={authInfo}
      requiredRoles={['despachante']}
    />
  );
}
