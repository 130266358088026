import { MatcherConfigureUtils } from '../../utils/general/general-utils';

const DETALHAMENTO_OPERACAO_SEM_NOTA_FISCAL_OUTRAS_EXPORTACOES_TEMPORARIAS_SEM_NOTA_CODE = 'DOSNF26';
const DETALHAMENTO_OPERACAO_SEM_NOTA_FISCAL_OUTRAS_SAIDAS_DEFINITIVAS_SEM_NOTA_CODE = 'DOSNF27';

const DetalhamentoOperacaoSemNotaFiscalUtils = Object.freeze({
  OUTRAS_EXPORTACOES_TEMPORARIAS_SEM_NOTA: (
    DETALHAMENTO_OPERACAO_SEM_NOTA_FISCAL_OUTRAS_EXPORTACOES_TEMPORARIAS_SEM_NOTA_CODE
  ),
  OUTRAS_SAIDAS_DEFINITIVAS_SEM_NOTA: (
    DETALHAMENTO_OPERACAO_SEM_NOTA_FISCAL_OUTRAS_SAIDAS_DEFINITIVAS_SEM_NOTA_CODE
  ),
  isOutrasExportacoesTemporariasSemNotaFiscal: (
    MatcherConfigureUtils.getSpecificByCode(
      DETALHAMENTO_OPERACAO_SEM_NOTA_FISCAL_OUTRAS_EXPORTACOES_TEMPORARIAS_SEM_NOTA_CODE
    )
  ),
  isOutrasSaidasDefinitivasSemNotaFiscal: (
    MatcherConfigureUtils.getSpecificByCode(
      DETALHAMENTO_OPERACAO_SEM_NOTA_FISCAL_OUTRAS_EXPORTACOES_TEMPORARIAS_SEM_NOTA_CODE
    )
  ),
});

export default DetalhamentoOperacaoSemNotaFiscalUtils;
