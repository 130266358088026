import { IconButton } from '@material-ui/core';
import {
  Delete as DeleteIcon, Search as PageviewIcon,
} from '@material-ui/icons';
import React, {
  useCallback, useContext, useEffect, useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import TransportadorInternacionalContext from '../../../../../contexts/registrations/transportador-internacional/TransportadorInternacionalContext';
import {
  resetList as resetCidadeList,
} from '../../../../../features/cidade/cidadeSlice';
import { IDLE_CONTROL } from '../../../../../features/config-control';
import {
  resetList as resetEstadoList,
} from '../../../../../features/estado/estadoSlice';
import {
  resetList as resetViaTransporteList,
} from '../../../../../features/via-transporte/viaTransporteSlice';
import {
  changeControlTo as changeControlPaisTo,
  resetList as resetPaisList,
} from '../../../../../features/pais/paisSlice';
import {
  activateByIdAsync,
  changeToConsultMode,
  changeToCreateMode,
  fetchAllAsync,
  inactivateByIdAsync,
  resetBackgroundMode,
  resetMode,
  resetModel,
  resetSubMode,
  selectTransportadoresInternacionais,
} from '../../../../../features/transportador-internacional/transportadorInternacionalSlice';
import QCXSimpleConsultPageTemplate from '../../../../../templates/simple-consult-page/QCXSimpleConsultPageTemplate';
import useOperationController from '../../../../../utils/hooks/store/operation/useOperationController';
import { isAlternativeLoadingStatus } from '../../../../../utils/store/store-utils';
import { userHasDeleteRole } from '../../../../../components/perfil/perfil-utils';
import { getLocalidadeOrDefault } from '../../../../../utils/general/general-utils';

export default function TransportadorInternacionalConsultPage({ authInfo = {} }) {
  const { t } = useTranslation();
  const history = useHistory();

  const {
    status,
  } = useContext(TransportadorInternacionalContext);

  const dispatch = useDispatch();

  const transportadoresInternacionais = useSelector(selectTransportadoresInternacionais);

  const isAlternativeLoading = useMemo(() => (
    isAlternativeLoadingStatus(status)
  ), [status]);

  const fetchAll = useCallback(() => {
    dispatch(fetchAllAsync());
  }, []);

  const resetModes = useCallback(() => {
    dispatch(resetMode());
    dispatch(resetBackgroundMode());
    dispatch(resetSubMode());
  }, []);

  const resetModels = useCallback(() => {
    dispatch(resetModel());
  }, []);

  const resetDependencies = useCallback(() => {
    dispatch(changeControlPaisTo(IDLE_CONTROL));
    dispatch(resetPaisList());
    dispatch(resetEstadoList());
    dispatch(resetCidadeList());
    dispatch(resetViaTransporteList());
  }, []);

  useEffect(() => {
    fetchAll();
    resetModes();
    resetModels();
    resetDependencies();
  }, []);

  const handleActivateInactivate = useOperationController(
    async (event, id) => {
      event.preventDefault();

      const { active } = transportadoresInternacionais.find(
        (item) => item.id === id
      );

      const isActivation = !active;

      if (isActivation) {
        dispatch(activateByIdAsync(id));
      } else {
        dispatch(inactivateByIdAsync(id));
      }
    }
  );

  const handleClickOnButtonNew = useCallback((event) => {
    event.stopPropagation();
    dispatch(changeToCreateMode());
  }, []);

  const handleClickOnEditButton = useCallback((event, id) => {
    event.stopPropagation();
    dispatch(changeToConsultMode());
    history.push(t('com.muralis.qcx.url.moduloOperacionaisTransportadorInternacionalDetalhes', { id }));
  }, [history]);

  const columns = [
    {
      field: 'id',
      headerAlign: 'center',
      align: 'center',
      headerName: t('com.muralis.qcx.codigo'),
      type: 'string',
      flex: 90,
      valueGetter: ({ row }) => (
        row?.id
      ),
    },
    {
      field: 'pessoaJuridica.nome',
      headerName: t('com.muralis.qcx.nomeEmpresa'),
      type: 'string',
      flex: 195,
      valueGetter: ({ row }) => (
        row?.pessoaJuridica?.nome
      ),
    },
    {
      field: 'viaTransporte.description',
      headerName: t('com.muralis.qcx.transporte.viaTransporte'),
      type: 'string',
      flex: 100,
      valueGetter: ({ row }) => (
        row?.viaTransporte?.description
      ),
    },
    {
      field: 'localidade',
      headerName: t('com.muralis.qcx.localizacao'),
      type: 'string',
      flex: 120,
      valueGetter: ({ row }) => (
        getLocalidadeOrDefault(row?.endereco, '-')
      ),
    },
    {
      field: 'actions',
      headerName: t('com.muralis.qcx.acoes.label'),
      headerAlign: 'center',
      align: 'center',
      flex: 120,
      renderCell: ({ row: { id, active } }) => (
        <>
          {userHasDeleteRole(authInfo, 'transportador-internacional') && (
          <IconButton
            key={`stw-active${id}`}
            value={active}
            name={`stw-active-${id}`}
            onClick={(event) => handleActivateInactivate(event, id)}
          >
            <DeleteIcon color="error" />
          </IconButton>
          )}
          <IconButton
            key={`btn-view-${id}`}
            name={`btn-view-${id}`}
            onClick={(event) => handleClickOnEditButton(event, id)}
          >
            <PageviewIcon color="primary" />
          </IconButton>
        </>
      ),
    },
  ];

  const breadcrumbs = [
    {
      link: {
        to: '/',
        name: t('com.muralis.qcx.inicio'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.cadastros'),
      },
    },
    {
      link: {
        to: t('com.muralis.qcx.url.moduloOperacionais'),
        name: t('com.muralis.qcx.operacionais'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.transportadorInternacional.label'),
      },
    },
  ];

  const templateProps = {
    page: {
      title: t('com.muralis.qcx.transportadorInternacional.label'),
      breadcrumbs,
    },
    control: {
      buttonNew: {
        description: t('com.muralis.qcx.transportadorInternacional.novoTransportadorInternacional'),
        path: t('com.muralis.qcx.url.moduloOperacionaisTransportadorInternacionalNovo'),
        onClick: handleClickOnButtonNew,
      },
    },
    table: {
      columns,
    },
  };

  return (
    <QCXSimpleConsultPageTemplate
      pageProps={templateProps.page}
      controlProps={templateProps.control}
      tableProps={templateProps.table}
      tableData={transportadoresInternacionais}
      isLoading={isAlternativeLoading}
      buttonNewText={t('com.muralis.qcx.acoes.novo').toUpperCase()}
      authInfo={authInfo}
      requiredRoles={['transportador-internacional']}
    />
  );
}
