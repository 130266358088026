import PivotTableChartIcon from '@mui/icons-material/PivotTableChart';
import ExtensionIcon from '@mui/icons-material/Extension';
import RttIcon from '@mui/icons-material/Rtt';
import CardsGridSubmenu from '../../../common/components/cardsGrid/cardsGridSubmenu';
import BasicLayout from '../../../common/layouts/basicLayout';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import { CardsGridItem } from '../../../common/components/cardsGrid/cardsGrid';

const cover: CardsGridItem = {
  title: 'TOTVS / Easy',
  icon: RttIcon,
  path: '/integracao',
};

const gridItems: CardsGridItem[] = [
  {
    title: 'Leitura',
    icon: ReadMoreIcon,
    path: '/integracao/totvs-easy/leitura',
  },
  {
    title: 'Escrita',
    icon: DriveFileRenameOutlineIcon,
    path: '/integracao/totvs-easy/escrita',
  },
  {
    title: 'Correspondência de Dados',
    icon: ImportExportIcon,
    path: '/integracao/totvs-easy/correspondencia',
  },
];

const TotuvseasyPage = () => {
  return (
    <BasicLayout
      title={'TOTVS / Easy'}
      icon={<ExtensionIcon color={'secondary'} />}
    >
      <CardsGridSubmenu cover={cover} items={gridItems}></CardsGridSubmenu>
    </BasicLayout>
  );
};

export default TotuvseasyPage;
