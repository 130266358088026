import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Hidden, IconButton, makeStyles, Menu, MenuItem, Typography } from '@material-ui/core';
import {
  AddCircle as AddCircleIcon,
  Delete as DeleteIcon,
  MoreHoriz as MoreHorizIcon,
  Search as PageviewIcon,
} from '@material-ui/icons';
import { v4 as uuid } from 'uuid';
import { debounce, isArray, isEmpty, isFunction } from 'lodash';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { useForm } from 'react-final-form';
import QCXListItemIcon from '../../shared-components/list-item-icon/QCXListItemIcon';
import QCXTabsManager from '../../components/tabs/QCXTabsManager';
import QCXBox from '../../shared-components/box/QCXBox';
import QCXButton from '../../shared-components/button/QCXButton';
import QCXDataGridControl from '../../shared-components/data-grid-control/QCXDataGridControl';
import { isSubCreateMode, isBackgroundDeleteMode } from '../../utils/store/store-utils';
import { BACKGROUND_DELETE_MODE, SUB_CONSULT_MODE, SUB_CREATE_MODE, SUB_UPDATE_MODE } from '../../features/mode';
import QCXTabControlButtons from '../../components/tabs/QCXTabControlButtons';
import QCXFormPaginationControl from '../../components/form-pagination-control/QCXFormPaginationControl';
import QCXCustomFormControlButtonsGroup from './QCXCustomFormControlButtonsGroup';
import QCXDualFormPaginationControl from '../../components/form-pagination-control/QCXDualFormPaginationControl';
import useReadOnlyForm from '../../utils/hooks/form/mode/useReadOnlyForm';

const useStyles = makeStyles((theme) => ({
  controlButton: {
    marginRight: theme.spacing(1),
    '& .MuiButton-startIcon': {
      marginRight: '4px',
    },
  },
}));

const DEFAULT_BUTTON_GROUP_OPTIONS = {
  remove: {
    visibleOn: [SUB_CONSULT_MODE],
  },
};

export default function QCXCustomRegistrationOneToManyPageTemplate({ ...props }) {
  const { t } = useTranslation();

  const {
    showButtonsTop = false,
    title,
    tableProps = {},
    tableData = [],
    initialValues,
    isLoading,
    isCreate,
    isConsult,
    isUpdate,
    isSubCreate,
    isSubConsult,
    isSubUpdate,
    isSubNone,
    isBackgroundDelete,
    isSubBackgroundCreate,
    isSubBackgroundConsult,
    isSubBackgroundUpdate,
    isSubBackgroundDelete,
    submitting,
    pristine,
    handleCancelCreate,
    handleCancelUpdate,
    handleChangeToConsult,
    handleRemoveByForm,
    handleRemoveByTable,
    handleNew,
    handleEdit,
    handleChangeToSubConsult,
    handleChangeModel,
    handleClearForm,
    defaultRestartedValues,
    handleChangeToSubNone,
    handleLoadingStatus,
    handleResetStatus,
    localeTableText,
    sortableValueGetter,
    filterProp = 'code',
    filterPropGetter = (values) => values?.code,
    buttonGroupOptions = DEFAULT_BUTTON_GROUP_OPTIONS,
    defaultItem = undefined,
    tabManagerProps = {},
    formPaginationControlProps = {},
    controlButtonsPaginationProps = {
      paginationInfoField: {
        label: t('com.muralis.qcx.item.labelPlural'),
      },
    },
    renderFormControlButtonsComponent = undefined,
    hiddenNewButton = false,
    dualFormControlPagination = false,
    autoFormReset = true,
    autoControlInitialValues = false,
    children,
  } = props;

  const classes = useStyles();

  const form = useForm();
  const { disableUpdate } = useReadOnlyForm();

  const [isAlreadyLoadedInitialValues, setIsAlreadyLoadedInitialValues] = useState(false);
  const [forceSwitchTab, setForceSwitchTab] = useState(undefined);
  const [parentItemNumber, setParentItemNumber] = useState(undefined);
  const [currentItemNumber, setCurrentItemNumber] = useState(defaultItem || 1);
  const [currentItemId, setCurrentItemId] = useState(undefined);
  const [loadInitialItem, setLoadInitialItem] = useState(false);
  const [ignoreDefaultItem, setIgnoreDefaultItem] = useState(false);
  const [lastSubMode, setLastSubMode] = useState(undefined);
  const [lastBackgroundMode, setLastBackgroundMode] = useState(undefined);
  const [autoGeneratedFormContainerKey, setAutoGeneratedFormContainerKey] = useState('default-form-container-key');

  const isLastModeSubCreate = useMemo(() => isSubCreateMode(lastSubMode), [lastSubMode]);

  const isLastModeBackgroundDelete = useMemo(() => isBackgroundDeleteMode(lastBackgroundMode), [lastBackgroundMode]);

  const sortedTableData = useMemo(() => {
    const sortByItem = (a, b) =>
      isFunction(sortableValueGetter) ? sortableValueGetter(a) - sortableValueGetter(b) : a?.item - b?.item;

    const sortedData = [...tableData].sort(sortByItem);

    return sortedData;
  }, [tableData, sortableValueGetter]);

  const hasItems = useMemo(() => isArray(sortedTableData) && !isEmpty(sortedTableData), [sortedTableData]);

  const addedItemsQuantity = useMemo(() => sortedTableData.length, [sortedTableData]);

  const defaultButtonGroupOptions = useMemo(
    () => (buttonGroupOptions && !isEmpty(buttonGroupOptions) ? buttonGroupOptions : DEFAULT_BUTTON_GROUP_OPTIONS),
    [buttonGroupOptions]
  );

  const isRemoveButtonVisibleOnlySubNoneOrSubConsultMode = useMemo(
    () => (isSubNone || isSubConsult) && defaultButtonGroupOptions?.remove?.visibleOn?.includes(SUB_CONSULT_MODE),
    [isSubNone, isSubConsult, defaultButtonGroupOptions]
  );

  const handleForceSwitchTab = useCallback(
    (targetTab) => {
      setForceSwitchTab({
        force: true,
        targetTab,
      });
    },
    [setForceSwitchTab]
  );

  const handleInternalClearForm = useCallback(() => {
    handleClearForm();
    form.restart(defaultRestartedValues);
  }, [form, defaultRestartedValues, handleClearForm]);

  const handleSetLoadInitialItem = useCallback(
    (loadInitialItemFlag) => {
      setLoadInitialItem(loadInitialItemFlag);
    },
    [setLoadInitialItem]
  );

  const handleChangeItemDecorator = useCallback(() => {
    setAutoGeneratedFormContainerKey(uuid());
  }, [setAutoGeneratedFormContainerKey]);

  const handleSetIgnoreDefaultItem = useCallback(
    (ignoreDefault) => {
      setIgnoreDefaultItem(ignoreDefault);
    },
    [setIgnoreDefaultItem]
  );

  const handleSetCurrentItemNumber = useCallback(
    (newCurrentItemNumber) => {
      setCurrentItemNumber(newCurrentItemNumber);
    },
    [setCurrentItemNumber]
  );

  const handleChangeDecorator = useCallback(
    (tab) => {
      if (tab === 0) {
        handleInternalClearForm();
        if (isUpdate) {
          handleChangeToConsult();
        }
        handleChangeToSubNone();
        return;
      }
      if (tab > 0 && hasItems && !isSubCreate) {
        handleLoadingStatus();

        const executeDebouncedChange = debounce(() => {
          handleSetLoadInitialItem(true);

          handleResetStatus();
        }, 400);

        executeDebouncedChange();
      }
    },
    [
      hasItems,
      isSubCreate,
      handleSetLoadInitialItem,
      handleLoadingStatus,
      handleResetStatus,
      handleChangeToConsult,
      handleChangeToSubConsult,
      handleChangeToSubNone,
      handleInternalClearForm,
    ]
  );

  const handleInternalChangeModel = useCallback(
    (model) => {
      handleChangeModel(model);
      setCurrentItemId(model?.id);
    },
    [setCurrentItemId, handleChangeModel]
  );

  const handleInternalNew = useCallback(() => {
    const initForm = () => {
      setAutoGeneratedFormContainerKey(uuid());
      handleInternalClearForm();
      handleForceSwitchTab(1);
    };

    handleNew({
      init: initForm,
    });
  }, [handleNew, handleForceSwitchTab, handleInternalClearForm, setAutoGeneratedFormContainerKey]);

  const handleInternalRowClick = useCallback(
    (data) => {
      const selectedItem = sortedTableData.find((item) => item?.id === data?.row?.id);

      const currentItemIndex = sortedTableData.findIndex((item) => item?.id === data?.row?.id);

      const targetItemNumber = dualFormControlPagination ? selectedItem?.metadata?.item?.numero : currentItemIndex + 1;

      handleSetCurrentItemNumber(targetItemNumber);
      handleForceSwitchTab(1);

      handleLoadingStatus();

      const executeDebouncedChange = debounce(() => {
        const currentItem = sortedTableData[currentItemIndex];

        if (currentItem && !isEmpty(currentItem)) {
          handleInternalChangeModel(currentItem);

          if (isUpdate) {
            handleChangeToConsult();
          }
          handleChangeToSubConsult();
        }

        handleResetStatus();
      }, 500);

      executeDebouncedChange();
    },
    [
      sortedTableData,
      handleSetCurrentItemNumber,
      handleForceSwitchTab,
      handleLoadingStatus,
      handleResetStatus,
      handleInternalChangeModel,
      handleChangeToConsult,
      handleChangeToSubConsult,
      dualFormControlPagination,
    ]
  );

  const handleInternalCancelCreate = useCallback(() => {
    handleCancelCreate({
      callback: () => {
        handleLoadingStatus();

        const executeDebouncedCancelCreate = debounce(() => {
          handleInternalClearForm();

          if (hasItems) {
            const adjustedCurrentItem = isLastModeSubCreate ? addedItemsQuantity : currentItemNumber;
            const adjustedCurrentItemIndex = adjustedCurrentItem - 1;

            const currentItem = sortedTableData[adjustedCurrentItemIndex];

            if (currentItem && !isEmpty(currentItem)) {
              handleInternalChangeModel(currentItem);
            }
          } else {
            handleForceSwitchTab(0);
          }

          handleResetStatus();
        }, 500);

        if (isLastModeSubCreate) {
          handleSetCurrentItemNumber(addedItemsQuantity);
        }

        executeDebouncedCancelCreate();

        if (!hasItems) {
          handleChangeToSubNone();

          return;
        }

        handleChangeToSubConsult();
      },
    });
  }, [
    hasItems,
    addedItemsQuantity,
    currentItemNumber,
    sortedTableData,
    isLastModeSubCreate,
    handleInternalChangeModel,
    handleCancelCreate,
    handleChangeToSubNone,
    handleChangeToSubConsult,
    handleForceSwitchTab,
    handleInternalClearForm,
  ]);

  const handleInternalRemoveByForm = useCallback(
    (event) => {
      if (isFunction(event?.stopPropagation)) {
        event.stopPropagation();

        if (hasItems) {
          const refreshItemCallback = (newTableData) => {
            handleLoadingStatus();

            const executeDebouncedRemoveByForm = debounce(() => {
              const newCurrentItem = Math.min(currentItemNumber, newTableData?.length);
              const newCurrentItemIndex = newCurrentItem - 1;
              const currentItem = newTableData[newCurrentItemIndex];

              if (currentItem && !isEmpty(currentItem)) {
                handleInternalChangeModel(currentItem);

                handleSetCurrentItemNumber(newCurrentItem);
              }
              handleResetStatus();
            }, 500);

            executeDebouncedRemoveByForm();
          };

          handleRemoveByForm(refreshItemCallback);
        }
      }
    },
    [
      hasItems,
      addedItemsQuantity,
      currentItemNumber,
      handleSetCurrentItemNumber,
      handleInternalChangeModel,
      handleRemoveByForm,
      handleForceSwitchTab,
      handleLoadingStatus,
    ]
  );

  const renderTabTopControls = useCallback(
    ({ activeTab }) =>
      activeTab > -1 && (
        <>
          {!hiddenNewButton && !disableUpdate && (
            <Grid item>
              <Hidden smDown>
                <QCXButton
                  id="add-item-button"
                  key="add-item-button"
                  className={classes.controlButtonTop}
                  color="secondary"
                  startIcon={<AddCircleIcon />}
                  onClick={handleInternalNew}
                  disabled={isLoading || isSubUpdate || isSubCreate}
                >
                  {t('com.muralis.qcx.acoes.novo')}
                </QCXButton>
              </Hidden>
              <Hidden mdUp>
                <QCXButton
                  id="add-item-button"
                  key="add-item-button"
                  className={classes.controlButtonTop}
                  color="secondary"
                  size="small"
                  tooltipDescription={t('com.muralis.qcx.acoes.adicionarNovoItem')}
                  tooltip
                  aria-label={t('com.muralis.qcx.acoes.adicionarNovoItem')}
                  onClick={handleInternalNew}
                  disabled={isLoading || isSubUpdate || isSubCreate}
                >
                  <AddCircleIcon />
                </QCXButton>
              </Hidden>
            </Grid>
          )}
        </>
      ),
    [isLoading, isSubUpdate, isSubCreate, handleInternalNew]
  );

  const handleBackToList = useCallback(() => {
    handleInternalClearForm();
    handleForceSwitchTab(0);
    handleChangeToSubNone();
  }, [handleChangeToSubNone, handleForceSwitchTab, handleInternalClearForm]);

  const renderTabBottomControls = useCallback(
    ({ activeTab }) => {
      if (activeTab < 1) {
        return <></>;
      }

      if (dualFormControlPagination) {
        return (
          <QCXDualFormPaginationControl
            initialValues={initialValues}
            list={sortedTableData}
            loadInitialItem={loadInitialItem}
            currentItemNumber={currentItemNumber}
            isLoading={isLoading}
            isUpdate={isUpdate}
            isSubConsult={isSubConsult}
            isSubUpdate={isSubUpdate}
            isSubCreate={isSubCreate}
            isSubBackgroundCreate={isSubBackgroundCreate}
            isSubBackgroundConsult={isSubBackgroundConsult}
            isSubBackgroundUpdate={isSubBackgroundUpdate}
            isSubBackgroundDelete={isSubBackgroundDelete}
            isLastModeBackgroundDelete={isLastModeBackgroundDelete}
            defaultParent={parentItemNumber}
            defaultItem={defaultItem}
            ignoreDefaultItem={ignoreDefaultItem}
            controlButtonsPaginationProps={formPaginationControlProps?.dualControlButtons}
            getSubListByParent={formPaginationControlProps?.getSubListByParent}
            handleSetLoadInitialItem={handleSetLoadInitialItem}
            handleSetCurrentItemNumber={handleSetCurrentItemNumber}
            handleChangeModel={handleInternalChangeModel}
            handleChangeItemDecorator={handleChangeItemDecorator}
            handleLoadingStatus={handleLoadingStatus}
            handleResetStatus={handleResetStatus}
            handleChangeToConsult={handleChangeToConsult}
            handleChangeToSubConsult={handleChangeToSubConsult}
            handleSetIgnoreDefaultItem={handleSetIgnoreDefaultItem}
            handleInternalClearForm={handleInternalClearForm}
            autoFormReset={autoFormReset}
            {...formPaginationControlProps}
          />
        );
      }

      return (
        <Grid container item xs={12}>
          <Grid container item xs={6}>
            {showButtonsTop && (
              <QCXCustomFormControlButtonsGroup
                hideRigthButtons
                isCreate={isCreate}
                isConsult={isConsult}
                isUpdate={isUpdate}
                isSubNone={isSubNone}
                isSubCreate={isSubCreate}
                isSubConsult={isSubConsult}
                isSubUpdate={isSubUpdate}
                submitting={submitting}
                pristine={pristine}
                options={buttonGroupOptions}
                handleEdit={handleEdit}
                handleCancelUpdate={handleCancelUpdate}
                handleCancelCreate={handleInternalCancelCreate}
                handleRemoveByForm={handleInternalRemoveByForm}
                handleBackToList={handleBackToList}
              />
            )}
          </Grid>
          <Grid container item xs={6} justify="flex-end">
            <QCXFormPaginationControl
              initialValues={initialValues}
              list={sortedTableData}
              loadInitialItem={loadInitialItem}
              currentItemNumber={currentItemNumber}
              isLoading={isLoading}
              isUpdate={isUpdate}
              isSubConsult={isSubConsult}
              isSubUpdate={isSubUpdate}
              isSubCreate={isSubCreate}
              isSubBackgroundCreate={isSubBackgroundCreate}
              isSubBackgroundConsult={isSubBackgroundConsult}
              isSubBackgroundUpdate={isSubBackgroundUpdate}
              isSubBackgroundDelete={isSubBackgroundDelete}
              isLastModeBackgroundDelete={isLastModeBackgroundDelete}
              defaultItem={defaultItem}
              ignoreDefaultItem={ignoreDefaultItem}
              controlButtonsPaginationProps={
                formPaginationControlProps?.dualControlButtons?.secondary || controlButtonsPaginationProps
              }
              handleSetLoadInitialItem={handleSetLoadInitialItem}
              handleSetCurrentItemNumber={handleSetCurrentItemNumber}
              handleChangeModel={handleInternalChangeModel}
              handleChangeItemDecorator={handleChangeItemDecorator}
              handleLoadingStatus={handleLoadingStatus}
              handleResetStatus={handleResetStatus}
              handleChangeToConsult={handleChangeToConsult}
              handleChangeToSubConsult={handleChangeToSubConsult}
              handleSetIgnoreDefaultItem={handleSetIgnoreDefaultItem}
              handleInternalClearForm={handleInternalClearForm}
              autoFormReset={autoFormReset}
            />
          </Grid>
        </Grid>
      );
    },
    [
      initialValues,
      sortedTableData,
      dualFormControlPagination,
      loadInitialItem,
      currentItemNumber,
      isLoading,
      isUpdate,
      isSubConsult,
      isSubUpdate,
      isSubCreate,
      isSubBackgroundCreate,
      isSubBackgroundUpdate,
      isSubBackgroundDelete,
      isLastModeBackgroundDelete,
      defaultItem,
      ignoreDefaultItem,
      controlButtonsPaginationProps,
      formPaginationControlProps,
      handleSetLoadInitialItem,
      handleSetCurrentItemNumber,
      handleInternalChangeModel,
      handleChangeItemDecorator,
      handleLoadingStatus,
      handleResetStatus,
      handleChangeToConsult,
      handleChangeToSubConsult,
      handleSetIgnoreDefaultItem,
      handleInternalClearForm,
      formPaginationControlProps,
    ]
  );

  const renderTabControlButtons = useCallback(
    ({ tab }) => (
      <QCXTabControlButtons
        tab={tab}
        renderTopControls={renderTabTopControls}
        renderBottomControls={renderTabBottomControls}
      />
    ),
    [renderTabTopControls, renderTabBottomControls]
  );

  const defaultTabs = useMemo(
    () => [
      {
        label: t('com.muralis.qcx.lista'),
        disabled: isSubCreate || isSubUpdate,
      },
      {
        label: t('com.muralis.qcx.detalhes'),
        disabled: !hasItems || isSubNone,
      },
    ],
    [hasItems, isSubNone, isSubCreate, isSubUpdate]
  );

  const defaultOnlyActiveTabEnabled = useMemo(() => isSubUpdate, [isSubUpdate]);

  const internalDataGridColumns = useMemo(
    () => [
      ...tableProps?.columns,
      {
        field: 'actions',
        headerName: t('com.muralis.qcx.acoes.label'),
        headerAlign: 'center',
        flex: 200,
        align: 'center',
        renderCell: ({ row, ...restParams }) => (
          <>
            <PopupState variant="popover" popupId="demo-popup-menu">
              {(popupState) => (
                <>
                  <IconButton
                    key={`btn-more-options-${row?.id}`}
                    name={`btn-more-options-${row?.id}`}
                    {...bindTrigger(popupState)}
                  >
                    <MoreHorizIcon color="secondary" size={20} />
                  </IconButton>
                  <Menu {...bindMenu(popupState)}>
                    <MenuItem
                      id={`btn-view-${row?.id}`}
                      key={`btn-view-${row?.id}`}
                      onClick={(event) => {
                        popupState.close(event);

                        const data = { row };

                        if (dualFormControlPagination) {
                          setParentItemNumber(row?.metadata?.parent?.numero);
                          setCurrentItemNumber(row?.metadata?.item?.numero);
                        }

                        handleInternalRowClick(data);
                      }}
                    >
                      <QCXListItemIcon>
                        <PageviewIcon fontSize="small" />
                      </QCXListItemIcon>
                      <Typography
                        variant="inherit"
                        style={{
                          fontSize: 12,
                        }}
                      >
                        {t('com.muralis.qcx.acoes.consultar').toUpperCase()}
                      </Typography>
                    </MenuItem>
                    {isFunction(tableProps?.renderOtherActions) &&
                      tableProps?.renderOtherActions({
                        popup: {
                          state: popupState,
                        },
                        params: {
                          row,
                          restParams,
                        },
                      })}
                    {isRemoveButtonVisibleOnlySubNoneOrSubConsultMode && (
                      <MenuItem
                        id={`btn-delete-${row?.id}`}
                        key={`btn-delete-${row?.id}`}
                        onClick={(event) => {
                          popupState.close(event);
                          handleRemoveByTable({ row });
                        }}
                      >
                        <QCXListItemIcon>
                          <DeleteIcon fontSize="small" color="error" />
                        </QCXListItemIcon>
                        <Typography
                          variant="inherit"
                          style={{
                            fontSize: 12,
                          }}
                        >
                          {t('com.muralis.qcx.acoes.excluir').toUpperCase()}
                        </Typography>
                      </MenuItem>
                    )}
                  </Menu>
                </>
              )}
            </PopupState>
          </>
        ),
      },
    ],
    [tableProps, isRemoveButtonVisibleOnlySubNoneOrSubConsultMode, handleInternalRowClick, handleRemoveByTable]
  );

  const internalDataGridLocaleText = useMemo(
    () => ({
      noRowsLabel: localeTableText.noRowsLabel || t('com.muralis.qcx.validacao.nenhumItemEncontrado'),
    }),
    [localeTableText]
  );

  useEffect(() => {
    if (isSubCreate) {
      setIsAlreadyLoadedInitialValues(false);
    }
  }, [isSubCreate, setIsAlreadyLoadedInitialValues]);

  useEffect(() => {
    if (autoControlInitialValues && (isSubCreate || isSubUpdate) && !isAlreadyLoadedInitialValues) {
      form.reset(initialValues);
      setIsAlreadyLoadedInitialValues(true);
    }
  }, [
    isSubCreate,
    isSubUpdate,
    initialValues,
    autoControlInitialValues,
    isAlreadyLoadedInitialValues,
    setIsAlreadyLoadedInitialValues,
  ]);

  useEffect(() => {
    const checkIfHasNotItems = () => {
      if (addedItemsQuantity < 1) {
        handleForceSwitchTab(0);
        handleChangeToSubNone();
      }
    };

    checkIfHasNotItems();
  }, [addedItemsQuantity, handleForceSwitchTab]);

  useEffect(() => {
    let unmount = false;

    const handleSetLastSubMode = () => {
      if (!unmount) {
        if (isSubCreate) {
          setLastSubMode(SUB_CREATE_MODE);
          return;
        }
        if (isSubUpdate) {
          setLastSubMode(SUB_UPDATE_MODE);
          return;
        }
        setLastSubMode(undefined);
      }
    };

    handleSetLastSubMode();

    return () => {
      unmount = true;
    };
  }, [isSubCreate, isSubUpdate, setLastSubMode]);

  useEffect(() => {
    let unmount = false;

    const handleSetLastBackgroundMode = () => {
      if (!unmount) {
        if (isBackgroundDelete) {
          setLastBackgroundMode(BACKGROUND_DELETE_MODE);
          return;
        }
        setLastBackgroundMode(undefined);
      }
    };

    handleSetLastBackgroundMode();

    return () => {
      unmount = true;
    };
  }, [isBackgroundDelete, setLastBackgroundMode]);

  useEffect(() => {
    const readjustCurrentItemNumber = () => {
      if (currentItemId && isSubUpdate) {
        const selectedItem = sortedTableData.find((item) => item?.id === currentItemId);

        const currentItemIndex = sortedTableData.findIndex((item) => item?.id === currentItemId);

        const adjustedItemNumber = dualFormControlPagination
          ? selectedItem?.metadata?.item?.numero
          : currentItemIndex + 1;

        handleSetCurrentItemNumber(adjustedItemNumber);
      }
    };

    readjustCurrentItemNumber();
  }, [sortedTableData, currentItemId, isSubUpdate, dualFormControlPagination, handleSetCurrentItemNumber]);

  return (
    <>
      <QCXTabsManager
        tabs={defaultTabs}
        forceSwitchTab={forceSwitchTab}
        onChangeDecorator={handleChangeDecorator}
        renderControlButtons={renderTabControlButtons}
        onlyActiveTabEnabled={defaultOnlyActiveTabEnabled}
        {...tabManagerProps}
      >
        <QCXDataGridControl
          columns={internalDataGridColumns}
          rows={sortedTableData}
          localeText={internalDataGridLocaleText}
          filterProp={filterProp}
          filterPropGetter={filterPropGetter}
        />
        {isArray(children) && children.length > 1 ? (
          children.map((child) => (
            <>
              <Grid container style={{ marginTop: '1.5rem' }}>
                <Grid key={autoGeneratedFormContainerKey} container justify="flex-start" spacing={2}>
                  {isFunction(child) ? child() : child}
                </Grid>
                {isFunction(renderFormControlButtonsComponent) &&
                  renderFormControlButtonsComponent({
                    isCreate,
                    isConsult,
                    isUpdate,
                    isSubNone,
                    isSubCreate,
                    isSubConsult,
                    isSubUpdate,
                    submitting,
                    pristine,
                    options: buttonGroupOptions,
                    handleEdit,
                    handleCancelUpdate,
                    handleCancelCreate: handleInternalCancelCreate,
                    handleRemoveByForm: handleInternalRemoveByForm,
                  })}
                {!isFunction(renderFormControlButtonsComponent) && (
                  <QCXCustomFormControlButtonsGroup
                    isCreate={isCreate}
                    isConsult={isConsult}
                    isUpdate={isUpdate}
                    isSubNone={isSubNone}
                    isSubCreate={isSubCreate}
                    isSubConsult={isSubConsult}
                    isSubUpdate={isSubUpdate}
                    submitting={submitting}
                    pristine={pristine}
                    options={buttonGroupOptions}
                    handleEdit={handleEdit}
                    handleCancelUpdate={handleCancelUpdate}
                    handleCancelCreate={handleInternalCancelCreate}
                    handleRemoveByForm={handleInternalRemoveByForm}
                    handleBackToList={handleBackToList}
                  />
                )}
              </Grid>
            </>
          ))
        ) : (
          <>
            <Grid container justify="space-between">
              <Grid item container justify="flex-start" xs={4} sm={8} md={8} xl={8}>
                <Typography variant="h6" color="primary" fontWeight="fontWeightBold">
                  <QCXBox fontWeight="fontWeightBold" pt={1} pl={0}>
                    {String(title).toUpperCase() || t('com.muralis.qcx.formulario').toUppercase()}
                  </QCXBox>
                </Typography>
              </Grid>
            </Grid>
            <Grid container style={{ marginTop: '1.5rem' }}>
              <Grid key={autoGeneratedFormContainerKey} container justify="flex-start" spacing={2}>
                {isFunction(children) ? children() : children}
              </Grid>
              {isFunction(renderFormControlButtonsComponent) &&
                renderFormControlButtonsComponent({
                  isCreate,
                  isConsult,
                  isUpdate,
                  isSubNone,
                  isSubCreate,
                  isSubConsult,
                  isSubUpdate,
                  submitting,
                  pristine,
                  options: buttonGroupOptions,
                  handleEdit,
                  handleCancelUpdate,
                  handleCancelCreate: handleInternalCancelCreate,
                  handleRemoveByForm: handleInternalRemoveByForm,
                })}
              {!isFunction(renderFormControlButtonsComponent) && (
                <QCXCustomFormControlButtonsGroup
                  isCreate={isCreate}
                  isConsult={isConsult}
                  isUpdate={isUpdate}
                  isSubNone={isSubNone}
                  isSubCreate={isSubCreate}
                  isSubConsult={isSubConsult}
                  isSubUpdate={isSubUpdate}
                  submitting={submitting}
                  pristine={pristine}
                  options={buttonGroupOptions}
                  handleEdit={handleEdit}
                  handleCancelUpdate={handleCancelUpdate}
                  handleCancelCreate={handleInternalCancelCreate}
                  handleRemoveByForm={handleInternalRemoveByForm}
                  handleBackToList={handleBackToList}
                />
              )}
            </Grid>
          </>
        )}
      </QCXTabsManager>
    </>
  );
}
