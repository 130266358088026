import { Grid, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import QCXFinalAlternativeCurrencyField from '../final-currency-field/QCXFinalAlternativeCurrencyField';

const useStyles = makeStyles(() => ({
  gridFields: {
    paddingBottom: '16px',
  },
}));
export default function QCXSisComexFormGroup() {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <>
      <Grid item container xs={12} sm={6} md={6} lg={6}>
        <Grid
          item
          className={classes.gridFields}
          xs={12}
          sm={12}
          md={12}
          lg={12}
        >
          <QCXFinalAlternativeCurrencyField
            id="currency-valor-taxa-siscomex-field-sisComex"
            key="currency-valor-taxa-siscomex-field-sisComex"
            name="taxaSiscomex.valorReal"
            label={t('com.muralis.qcx.valorTaxaReais')}
            disabled
            adorned
          />
        </Grid>
      </Grid>
    </>
  );
}
