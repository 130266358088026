import AssessoriaFollowupPage from '../../pages/assessoria/assessoriaFollowupPage/assessoriaFollowupPage';
import FollowUpAssessoriaTimelineManagerPage from '../../pages/assessoria/assessoriaFollowupTimelineManagerPage/assessoriaFollowUpTimelineManagePage';
import AssessoriaPage from '../../pages/assessoria/assessoriaPage';
import NewAssessoriaFollowupPage from '../../pages/assessoria/newAssessoriaFollowupPage/newAssessoriaFollowupPage';
import ViewAssessoriaFollowupPage from '../../pages/assessoria/viewAssessoriaFollowupPage/viewAssessoriaFollowupPage';
import ComercialPage from '../../pages/comercial/comercialPage';
import PropostasCadastros from '../../pages/comercial/propostas/propostasCadastrosPage/propostasCadastros';
import PropostasDetails from '../../pages/comercial/propostas/propostasDetails/propostasDetails';
import PropostasFollowUpPage from '../../pages/comercial/propostas/propostasFollowUpPage/propostasFollowUpPage';
import PropostasIndices from '../../pages/comercial/propostas/propostasIndicesPage/propostasIndices';
import PropostasMercadorias from '../../pages/comercial/propostas/propostasMercadoria/propostasMercadorias';
import PropostasNewPage from '../../pages/comercial/propostas/propostasNewPage/propostasNewPage';
import PropostasPage from '../../pages/comercial/propostas/propostasPage';
import PropostasServicos from '../../pages/comercial/propostas/propostasServicosPage/propostasServicos';
import IntegracaoPage from '../../pages/integracao/integracaoPage';
import PlanilhasPage from '../../pages/integracao/planilhas/planilhasPage';
import TotvsEscritaDanfePage from '../../pages/integracao/totvs-easy/escrita/danfe/totvsEscritaDanfePage';
import TotvsEscritaDespesasPage from '../../pages/integracao/totvs-easy/escrita/despesas/totvsEscritaDespesasPage';
import TotvsEscritaNumerariosPage from '../../pages/integracao/totvs-easy/escrita/numerarios/totvsEscritaNumerariosPage';
import TotvsEscritaPage from '../../pages/integracao/totvs-easy/escrita/totvsEscritaPage';
import TotvsLeituraPage from '../../pages/integracao/totvs-easy/leitura/totvsLeituraPage';
import TotvsCorrespondenciaDetailsPage from '../../pages/integracao/totvs-easy/totvsCorrespondenciaDetailsPage';
import TotvsCorrespondenciaPage from '../../pages/integracao/totvs-easy/totvsCorrespondenciaPage';
import TotuvseasyPage from '../../pages/integracao/totvs-easy/totvseasyPage';
import RelatorioAssociatedPage from '../../pages/relatorios/relatorioAssociated/relatorioAssociatedPage';
import RelatorioDraftDanfePage from '../../pages/relatorios/relatorioDraftDanfe/relatorioDraftDanfePage';
import RelatorioHalliburtonPage from '../../pages/relatorios/relatorioHalliburton/relatorioHalliburtonPage';
import RelatorioLaPage from '../../pages/relatorios/relatorioLa/relatorioLaPage';
import RelatoriosPage from '../../pages/relatorios/relatoriosPage';
import CrodaEscritaPage from '../../pages/integracao/croda/escrita/crodaEscritaPage';
import CrodaPage from '../../pages/integracao/croda/CrodaPage';
import CrodaEscritaDanfePage from '../../pages/integracao/croda/escrita/danfe/crodaEscritaDanfePage'
import CrodaEscritaNotaFiscalPage from '../../pages/integracao/croda/escrita/nota-fiscal/crodaEscritaNotaFiscalPage'

type Route = {
  path: string;
  component: React.FC;
};

const tsRoutes: Route[] = [
  {
    path: '/comercial',
    component: ComercialPage,
  },
  {
    path: '/comercial/propostas',
    component: PropostasPage,
  },
  {
    path: '/comercial/propostas/nova',
    component: PropostasNewPage,
  },
  {
    path: '/comercial/propostas/indices',
    component: PropostasIndices,
  },
  {
    path: '/comercial/propostas/mercadorias',
    component: PropostasMercadorias,
  },
  {
    path: '/comercial/propostas/servicos',
    component: PropostasServicos,
  },
  {
    path: '/comercial/propostas/cadastros',
    component: PropostasCadastros,
  },
  {
    path: '/comercial/propostas/:id',
    component: PropostasDetails,
  },
  {
    path: '/comercial/propostas/:id/follow-ups',
    component: PropostasFollowUpPage,
  },
  {
    path: '/assessoria',
    component: AssessoriaPage,
  },
  {
    path: '/assessoria/followup',
    component: AssessoriaFollowupPage,
  },
  {
    path: '/assessoria/followup/novo',
    component: NewAssessoriaFollowupPage,
  },
  {
    path: '/assessoria/followup/:id/detalhes',
    component: ViewAssessoriaFollowupPage,
  },
  {
    path: '/assessoria/followup/:id/timeline',
    component: FollowUpAssessoriaTimelineManagerPage,
  },
  {
    path: '/integracao',
    component: IntegracaoPage,
  },
  {
    path: '/integracao/planilhas',
    component: PlanilhasPage,
  },
  {
    path: '/integracao/totvs-easy',
    component: TotuvseasyPage,
  },
  {
    path: '/integracao/totvs-easy/leitura',
    component: TotvsLeituraPage,
  },
  {
    path: '/integracao/totvs-easy/correspondencia',
    component: TotvsCorrespondenciaPage,
  },
  {
    path: '/integracao/totvs-easy/correspondencia/:id',
    component: TotvsCorrespondenciaDetailsPage,
  },
  {
    path: '/integracao/totvs-easy/escrita',
    component: TotvsEscritaPage,
  },
  {
    path: '/integracao/totvs-easy/escrita/despesas',
    component: TotvsEscritaDespesasPage,
  },
  {
    path: '/integracao/totvs-easy/escrita/danfe',
    component: TotvsEscritaDanfePage,
  },
  {
    path: '/integracao/totvs-easy/escrita/numerarios',
    component: TotvsEscritaNumerariosPage,
  },
  {
    path: '/integracao/croda',
    component: CrodaPage,
  },
  {
    path: '/integracao/croda/escrita',
    component: CrodaEscritaPage,
  },
  {
    path: '/integracao/croda/escrita/danfe',
    component: CrodaEscritaDanfePage,
  },
  {
    path: '/integracao/croda/escrita/nota-fiscal',
    component: CrodaEscritaNotaFiscalPage,
  },
  {
    path: '/relatorios',
    component: RelatoriosPage,
  },
  {
    path: '/relatorios/halliburton',
    component: RelatorioHalliburtonPage,
  },
  {
    path: '/relatorios/danfe',
    component: RelatorioDraftDanfePage,
  },
  {
    path: '/relatorios/la',
    component: RelatorioLaPage,
  },
  {
    path: '/relatorios/associated',
    component: RelatorioAssociatedPage,
  },
];

export { tsRoutes };
