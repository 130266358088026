import React, { useCallback, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { Chip, Collapse, Grid, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/styles';
import { useDropzone } from 'react-dropzone';
import { useForm, useFormState } from 'react-final-form';
import {
  BlockOutlined as BlockIcon,
  CloudUploadOutlined as CloudUploadIcon,
  CloudDoneOutlined as CloudDoneIcon,
  ErrorOutline as ErrorIcon,
  FilePresentOutlined as FilePresentIcon,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import QCXFinalField from '../final-field/QCXFinalField';
import QCXErrorAlert from '../alert/QCXErrorAlert';
import QCXButton from '../button/QCXButton';
import { DropzoneUtils } from './dropzoneUtils';

const useStyles = (theme) => ({
  baseStyle: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 3,
    borderRadius: 2,
    borderColor: '#e3e3e3',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    '-moz-user-select': 'none',
    '-webkit-user-select': 'none',
    '-ms-user-select': 'none',
    userSelect: 'none',
  },
  focusedStyle: {
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
  },
  acceptStyle: {
    borderColor: theme.palette.secondary.main,
    color: theme.palette.secondary.main,
  },
  rejectStyle: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
  },
});

const useClasses = makeStyles(() => ({
  alertAction: {
    display: 'flex',
    alignItems: 'flex-start',
    marginLeft: 'auto',
    marginRight: '-8px',
    paddingLeft: '16px',
  },
}));

/**
 * @deprecated. Use FileDragAndDrop instead
 */
export default function QCXFinalDropzoneField({
  id,
  key,
  name,
  fileGetter,
  fieldProps = {},
  options = {},
  multiple = true,
  onRemoveFile,
  customValidator,
  disabled,
  required,
  disableDuplicatedByNameValidator = false,
  disableDefaultErrors = false,
  ...restProps
}) {
  const { t } = useTranslation();

  const theme = useTheme();
  const styles = useStyles(theme);
  const classes = useClasses();

  const form = useForm();
  const { values } = useFormState();

  const [viewMoreFileRejections, setViewMoreFileRejections] = useState(false);

  const files = useMemo(() => {
    const object = _.get(values, name);

    if (_.isFunction(fileGetter)) {
      return fileGetter(object);
    }

    if (multiple && object?.length > 0) {
      return object;
    }

    if (multiple && (object?.length === 0 || !object?.length)) {
      return [];
    }

    return undefined;
  }, [name, values, multiple]);

  const {
    getRootProps,
    getInputProps,
    acceptedFiles,
    fileRejections,
    isFocused,
    isFileDialogActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    multiple,
    disabled,
    validator: (file) => {
      const previousFiles = _.isArrayLikeObject(files) ? files : [];

      const acceptTypes = options?.accept;

      if (acceptTypes && !_.isEmpty(acceptTypes)) {
        const invalidByFileType = DropzoneUtils.validateByFileType(acceptTypes, file);

        if (invalidByFileType !== null) {
          return invalidByFileType;
        }
      }

      if (!disableDuplicatedByNameValidator) {
        const invalidByFileName = DropzoneUtils.validateByFileName(previousFiles, file);

        if (invalidByFileName !== null) {
          return invalidByFileName;
        }
      }

      if (_.isFunction(customValidator)) {
        const invalidByCustomValidation = customValidator({
          files: previousFiles,
          currentFile: file,
          acceptTypes,
        });

        if (invalidByCustomValidation !== null) {
          return invalidByCustomValidation;
        }
      }

      return null;
    },
    ...options,
  });

  useEffect(() => {
    if (!_.isEmpty(acceptedFiles)) {
      const previousFiles = _.isArrayLikeObject(files) ? files : [];

      const currentFiles = [...previousFiles, ...acceptedFiles];

      form.change(name, currentFiles);
    }
  }, [acceptedFiles]);

  const toggleViewMoreFileRejections = useCallback(() => {
    setViewMoreFileRejections((previousViewMoreFileRejections) => !previousViewMoreFileRejections);
  }, [setViewMoreFileRejections]);

  const handleCloseFileRejectionsErrorAlert = useCallback(() => {
    setViewMoreFileRejections(false);
  }, [setViewMoreFileRejections]);

  const style = useMemo(
    () => ({
      ...styles.baseStyle,
      ...(isFocused ? styles.focusedStyle : {}),
      ...(isDragAccept ? styles.acceptStyle : {}),
      ...(isDragReject ? styles.rejectStyle : {}),
    }),
    [styles, isFocused, isDragAccept, isDragReject]
  );

  const configureRemoveFileHandler = useCallback(
    (file) => (event) => {
      if (_.isFunction(onRemoveFile)) {
        onRemoveFile(event, file, {
          files,
          form,
        });

        return;
      }

      if (_.isFunction(event?.stopPropagation)) {
        event.stopPropagation();
      }

      const filtered = files?.filter((current) => current?.path !== file?.path);

      form.change(name, filtered);
    },
    [name, form, files, onRemoveFile]
  );

  const fileListItems = useMemo(
    () =>
      files.map((file, index) =>
        file ? (
          <Grid key={`chip-file-dropzone-${index + 1}`} item>
            <Chip
              label={file?.path}
              style={{
                '-moz-user-select': 'none',
                '-webkit-user-select': 'none',
                '-ms-user-select': 'none',
                userSelect: 'none',
              }}
              color="secondary"
              size="small"
              onDelete={configureRemoveFileHandler(file)}
              disabled={disabled}
            />
          </Grid>
        ) : undefined
      ),
    [files, disabled, configureRemoveFileHandler]
  );

  const fileRejectionsItems = useMemo(() => {
    const renderRejectionItem = ({ file, errors }) => (
      <li key={file.path}>
        <Chip
          style={{
            backgroundColor: theme.palette.error.light,
            color: theme.palette.error.contrastText,
          }}
          label={file?.path?.length > 100 ? `${String(file?.path).slice(0, 98)}...` : file?.path}
          size="small"
        />
        <ul>
          {errors
            .filter(DropzoneUtils.isNotDefaultFileInvalidType)
            .filter((error) => (disableDefaultErrors ? DropzoneUtils.isNotDefaultError(error) : true))
            .map((error) => (
              <li key={error.code}>{error.message}</li>
            ))}
        </ul>
      </li>
    );

    const firstErrorsItems = fileRejections.slice(0, 3).map(renderRejectionItem);

    const lastErrorsItems = fileRejections.slice(3).map(renderRejectionItem);

    const totalRejections = fileRejections.length;

    return (
      <>
        <b>
          {totalRejections === 1
            ? t('com.muralis.qcx.uploadArquivos.umArquivoRejeitado')
            : t('com.muralis.qcx.uploadArquivos.totalArquivosRejeitados', {
                total: totalRejections,
              })}
          :
        </b>
        <ul>
          {firstErrorsItems}
          {lastErrorsItems.length > 0 && (
            <>
              <QCXButton variant="text" color="inherit" size="small" onClick={toggleViewMoreFileRejections}>
                <b>
                  <u>{viewMoreFileRejections ? t('com.muralis.qcx.verMenos') : t('com.muralis.qcx.verMais')}</u>
                </b>
              </QCXButton>
              <Collapse in={viewMoreFileRejections}>{lastErrorsItems}</Collapse>
            </>
          )}
        </ul>
      </>
    );
  }, [fileRejections, disableDefaultErrors, viewMoreFileRejections, toggleViewMoreFileRejections]);

  const renderAreaText = useCallback(
    ({ primary, secondary }) => (
      <>
        <Typography variant="subtitle1">{primary.text}</Typography>
        <Typography
          variant="subtitle1"
          style={{
            paddingTop: '4px',
            fontStyle: 'italic',
          }}
        >
          {secondary.text}
        </Typography>
      </>
    ),
    []
  );

  const renderBigIcon = useCallback(
    (icon) => (
      <span
        style={{
          fontSize: 64,
          maxHeight: 72,
        }}
      >
        {icon}
      </span>
    ),
    []
  );

  const disabledAreaFeedback = useMemo(
    () =>
      disabled && (
        <>
          {renderBigIcon(<BlockIcon color="inherit" fontSize="inherit" />)}
          {renderAreaText({
            primary: {
              text: t('com.muralis.qcx.uploadArquivos.areaUploadDesabilitada'),
            },
            secondary: {
              text: t('com.muralis.qcx.uploadArquivos.naoSeraPossivalVincularNovosArquivos'),
            },
          })}
        </>
      ),
    [disabled, renderBigIcon, renderAreaText]
  );

  const idleAreaInstructions = useMemo(
    () =>
      !(disabled || isDragAccept || isDragReject || isFileDialogActive) && (
        <>
          {renderBigIcon(<CloudUploadIcon color="inherit" fontSize="inherit" />)}
          {renderAreaText({
            primary: {
              text: t('com.muralis.qcx.uploadArquivos.arrasteSolteOsArquivosAqui'),
            },
            secondary: {
              text: t('com.muralis.qcx.uploadArquivos.ouCliqueParaSelecionarOsArquivos'),
            },
          })}
        </>
      ),
    [disabled, isDragAccept, isDragReject, isFileDialogActive, renderBigIcon, renderAreaText]
  );

  const dragAcceptAreaFeedback = useMemo(
    () =>
      isDragAccept && (
        <>
          {renderBigIcon(<CloudDoneIcon color="inherit" fontSize="inherit" />)}
          {renderAreaText({
            primary: {
              text: t('com.muralis.qcx.uploadArquivos.arquivosValidos'),
            },
            secondary: {
              text: t('com.muralis.qcx.uploadArquivos.apenasSolteOsArquivosParaUpload'),
            },
          })}
        </>
      ),
    [isDragAccept, renderBigIcon, renderAreaText]
  );

  const dragRejectAreaFeedback = useMemo(
    () =>
      isDragReject && (
        <>
          {renderBigIcon(<ErrorIcon color="inherit" fontSize="inherit" />)}
          {renderAreaText({
            primary: {
              text: t('com.muralis.qcx.uploadArquivos.umOuMaisAquivosInvalidos'),
            },
            secondary: {
              text: t('com.muralis.qcx.uploadArquivos.formatoDosArquivosNaoSuportadoOuInvalido'),
            },
          })}
        </>
      ),
    [isDragReject, renderAreaText]
  );

  const fileDialogActiveAreaFeedback = useMemo(
    () =>
      isFileDialogActive && (
        <>
          {renderBigIcon(<FilePresentIcon color="inherit" fontSize="inherit" />)}
          {renderAreaText({
            primary: {
              text: t('com.muralis.qcx.uploadArquivos.selecionandoArquivos'),
            },
            secondary: {
              text: t('com.muralis.qcx.uploadArquivos.aguardandoConfirmacaoSelecaoParaUpload'),
            },
          })}
        </>
      ),
    [isFileDialogActive, renderAreaText]
  );

  return (
    <>
      <QCXFinalField
        id={id}
        key={key}
        name={name}
        disabled={disabled}
        required={required}
        render={({ input: { name: inputName, value, type, onChange, ...restInputProps }, ...restComponentProps }) => (
          <section
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
            }}
          >
            <div {...getRootProps({ style })}>
              <input name={inputName} {...getInputProps()} {...restInputProps} {...restComponentProps} />
              {disabledAreaFeedback}
              {idleAreaInstructions}
              {dragAcceptAreaFeedback}
              {dragRejectAreaFeedback}
              {fileDialogActiveAreaFeedback}
            </div>
            {fileRejections && fileRejections?.length > 0 && (
              <aside style={{ paddingTop: '8px' }}>
                <QCXErrorAlert
                  classes={{
                    action: classes.alertAction,
                  }}
                  onClose={handleCloseFileRejectionsErrorAlert}
                  closable
                  unmountOnClose
                >
                  {fileRejectionsItems}
                </QCXErrorAlert>
              </aside>
            )}
            {fileListItems && fileListItems?.length > 0 && (
              <aside style={{ paddingTop: '8px' }}>
                <Grid item container spacing={1}>
                  {fileListItems}
                </Grid>
              </aside>
            )}
          </section>
        )}
        {...fieldProps}
        {...restProps}
      />
    </>
  );
}
