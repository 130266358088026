import { Grid } from '@material-ui/core';
import { isFunction } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { ncmSubitemActions } from '../../features/ncm-subitem/ncmSubitemSlice';
import { fetchById as fetchNveAtributoById } from '../../features/nve-atributo/nveAtributoAPI';
import { fetchById as fetchNveEspecificacaoById } from '../../features/nve-especificacao/nveEspecificacaoAPI';
import { fetchById as fetchNveById } from '../../features/nve/nveAPI';
import QCXFinalBondManager from '../../shared-components/final-gerenciador-relacionamento/QCXFinalBondManager';
import QCXSelectNveAtributoAutocomplete from '../../shared-components/select-nve-atributo/QCXSelectNveAtributoAutocomplete';
import QCXSelectNveEspecificacaoAutocomplete from '../../shared-components/select-nve-especificacao/QCXSelectNveEspecificacaoAutocomplete';
import QCXSelectNveAutocomplete from '../../shared-components/select-nve/QCXSelectNveAutocomplete';
import { getNveNivel } from '../../shared-components/select-nve/nveUtils';
import { useFilterNveBy } from '../../utils/general/catalogo-produtos/catalogoProdutosUtils';
import { isStrictEquals } from '../../utils/general/general-utils';

export default function QCXFinalAtributoNveBondManager({
  modes,
  classes,
  handleAdd,
  handleUpdate,
  handleAlreadyExists,
  listProps = {
    rootName: 'atributosAdicionais.atributosNve',
  },
}) {
  const { t } = useTranslation();
  const { values } = useFormState();
  const ncmList = useSelector(ncmSubitemActions.selectNcmSubitens);

  const { isConsult, isSubNone, isSubConsult } = modes;

  const ncm = useMemo(() => values?.ncm, [values]);

  const filterNve = useFilterNveBy({
    ncm,
    ncmList,
  });

  useEffect(() => {
    filterNve();
  }, [filterNve]);

  const handleInternalAdd = useCallback(async (currentVinculo, currentVinculos, handleSuccessAddVinculo) => {
    const nveResponse = await fetchNveById(currentVinculo?.nve?.id);

    const nveAtributoResponse = await fetchNveAtributoById(currentVinculo?.atributo?.id);

    const nveEspecificacaoResponse = await fetchNveEspecificacaoById(currentVinculo?.especificacao?.id);

    if (
      nveResponse?.status === 200 &&
      nveAtributoResponse?.status === 200 &&
      nveEspecificacaoResponse?.status === 200
    ) {
      const foundNve = nveResponse?.data;
      const foundNveAtributo = nveAtributoResponse?.data;
      const foundNveEspecificacao = nveEspecificacaoResponse?.data;

      const vinculo = {
        id: uuid(),
        nve: foundNve,
        atributo: foundNveAtributo,
        especificacao: foundNveEspecificacao,
      };

      const updatedVinculos = [...currentVinculos, vinculo];

      handleSuccessAddVinculo(updatedVinculos);
    }
  }, []);

  const handleInternalUpdate = useCallback(async (currentVinculo, currentVinculos, handleSuccessUpdateVinculo) => {
    const nveResponse = await fetchNveById(currentVinculo?.nve?.id);

    const nveAtributoResponse = await fetchNveAtributoById(currentVinculo?.atributo?.id);

    const nveEspecificacaoResponse = await fetchNveEspecificacaoById(currentVinculo?.especificacao?.id);

    if (
      nveResponse?.status === 200 &&
      nveAtributoResponse?.status === 200 &&
      nveEspecificacaoResponse?.status === 200
    ) {
      const foundNve = nveResponse?.data;
      const foundNveAtributo = nveAtributoResponse?.data;
      const foundNveEspecificacao = nveEspecificacaoResponse?.data;

      const updatedVinculo = {
        ...currentVinculo,
        nve: foundNve,
        atributo: foundNveAtributo,
        especificacao: foundNveEspecificacao,
      };

      const updatedVinculos =
        currentVinculos?.map((existingVinculo) =>
          isStrictEquals(existingVinculo?.id, updatedVinculo?.id) ? updatedVinculo : existingVinculo
        ) || currentVinculos;

      handleSuccessUpdateVinculo(updatedVinculos);
    }
  }, []);

  const handleInternalAlreadyExists = useCallback(
    (currentVinculo, currentVinculosList) =>
      currentVinculosList.some(
        (vinculo) =>
          isStrictEquals(vinculo?.especificacao?.id, currentVinculo?.especificacao?.id) &&
          !isStrictEquals(vinculo?.id, currentVinculo?.id)
      ),
    []
  );

  const handlers = useMemo(
    () => ({
      handleAdd: isFunction(handleAdd) ? handleAdd : handleInternalAdd,
      handleUpdate: isFunction(handleUpdate) ? handleUpdate : handleInternalUpdate,
      handleAlreadyExists: isFunction(handleAlreadyExists) ? handleAlreadyExists : handleInternalAlreadyExists,
    }),
    [handleAdd, handleUpdate, handleAlreadyExists, handleInternalAdd, handleInternalUpdate, handleInternalAlreadyExists]
  );

  const atributoNveListProps = useMemo(
    () => ({
      name: listProps?.rootName,
      columns: [
        {
          field: 'nve',
          headerName: t('com.muralis.qcx.nivel'),
          headerAlign: 'left',
          align: 'left',
          flex: 120,
          valueGetter: ({ row }) => getNveNivel(row?.nve) || '',
        },
        {
          field: 'atributo',
          headerName: t('com.muralis.qcx.atributo'),
          headerAlign: 'left',
          align: 'left',
          flex: 140,
          valueGetter: ({ row }) => row?.atributo?.description || '',
        },
        {
          field: 'especificacao',
          headerName: t('com.muralis.qcx.especificacao.label'),
          headerAlign: 'left',
          align: 'left',
          flex: 180,
          valueGetter: ({ row }) => row?.especificacao?.description || '',
        },
      ],
    }),
    [listProps]
  );

  const atributoNveFormProps = useMemo(
    () => ({
      rootName: 'ignorableFields.atributoNve',
      fields: [
        {
          name: 'nve.id',
          label: t('com.muralis.qcx.nivel').toUpperCase(),
        },
        {
          name: 'atributo.id',
          label: t('com.muralis.qcx.atributo').toUpperCase(),
        },
        {
          name: 'especificacao.id',
          label: t('com.muralis.qcx.especificacao.label').toUpperCase(),
        },
      ],
    }),
    []
  );

  return (
    <>
      <QCXFinalBondManager
        isParentConsult={isConsult || isSubConsult}
        listProps={atributoNveListProps}
        formProps={atributoNveFormProps}
        controlButtonsGridProps={{
          add: {
            xs: 12,
            sm: 2,
            md: 2,
          },
          update: {
            xs: 6,
            sm: 6,
            md: 1,
          },
          clear: {
            xs: 6,
            sm: 6,
            md: 1,
          },
        }}
        {...handlers}
      >
        <Grid item className={classes.gridFields} xs={12} sm={12} md={2} lg={2}>
          <QCXSelectNveAutocomplete
            id="text-field-numero-do-lacre-no-container"
            key="text-field-numero-do-lacre-no-container"
            name="ignorableFields.atributoNve.nve.id"
            label={t('com.muralis.qcx.nivel')}
            disabled={isConsult || isSubConsult || isSubNone}
            initialValues={values}
          />
        </Grid>
        <Grid item className={classes.customGridFields} xs={12} sm={12} md={4} lg={4}>
          <QCXSelectNveAtributoAutocomplete
            id="text-field-numero-do-lacre-no-container"
            key="text-field-numero-do-lacre-no-container"
            name="ignorableFields.atributoNve.atributo.id"
            label={t('com.muralis.qcx.atributo')}
            initialValues={values}
            disabled={isConsult || isSubConsult || isSubNone}
          />
        </Grid>
        <Grid item className={classes.customGridFields} xs={12} sm={12} md={4} lg={4}>
          <QCXSelectNveEspecificacaoAutocomplete
            id="text-field-numero-do-lacre-no-container"
            key="text-field-numero-do-lacre-no-container"
            name="ignorableFields.atributoNve.especificacao.id"
            label={t('com.muralis.qcx.especificacao.label')}
            disabled={isConsult || isSubConsult || isSubNone}
            initialValues={values}
          />
        </Grid>
      </QCXFinalBondManager>
    </>
  );
}
