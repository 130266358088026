import { useTranslation } from 'react-i18next';
import QCXFinalMultilineTextField from '../../shared-components/final-text-field/QCXFinalMultilineTextField';

function QCXSolicitacaoPagamentoFinanceiroObservacoesGroup({
  disabled,
}) {
  const { t } = useTranslation();

  return (
    <>
      <QCXFinalMultilineTextField
        id="text-observacao-pagamento-multiline-field"
        key="text-observacao-pagamento-multiline-field"
        name="observacaoPagamento"
        label={t('com.muralis.qcx.observacaoPagamento')}
        rows={5}
        disabled={disabled}
        required
      />
    </>
  );
}

export default QCXSolicitacaoPagamentoFinanceiroObservacoesGroup;
