import { createMuiTheme } from '@material-ui/core';
import { ptBR } from '@material-ui/data-grid';

export const baseTheme = {
  palette: {
    primary: {
      light: '#6450b3',
      main: '#312783',
      dark: '#000055',
      contrastText: '#ffffff',
      drawer: '#f4f4f4',
    },
    secondary: {
      light: '#ffc346',
      main: '#F39200',
      dark: '#ba6400',
      contrastText: '#ffffff',
    },
    danger: {
      main: '#f44336',
    },
    success: {
      main: '#008000',
    },
    background: {
      paper: '#ffffff',
      default: '#fcfcfc',
    },
  },
};

const quickComexTheme = createMuiTheme(
  {
    ...baseTheme,
    typography: {
      fontFamily: 'Arial',
      body1: {
        fontSize: '14px',
      },
    },
    overrides: {
      MuiInputBase: {
        root: {
          fontFamily: 'Arial',
        },
      },
      MuiFormLabel: {
        root: {
          fontSize: '16px',
          '&.Mui-focused': {
            color: baseTheme.palette.secondary.main,
          },
          '&.Mui-disabled, &.MuiInputLabel-shrink': {
            color: 'rgb(49 39 130 / 61%)',
            background: baseTheme.palette.primary.contrastText,
            paddingRight: '5px',
          },
        },
      },
      MuiChip: {
        root: {
          '&.Mui-disabled': {
            opacity: '.7',
          },
        },
        label: {
          fontSize: '14px',
        },
      },
      MuiBreadcrumbs: {
        root: {
          '& li:last-child p': {
            color: baseTheme.palette.primary.main,
            opacity: '1',
          },
        },
      },
      MuiTextField: {
        root: {
          '& label.Mui-focused': {
            color: baseTheme.palette.primary.main,
          },
          '& .MuiInput-underline:after': {
            borderBottomColor: baseTheme.palette.primary.main,
          },
          '& .MuiFilledInput-underline:after': {
            borderBottomColor: baseTheme.palette.primary.main,
          },
          '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
              borderColor: baseTheme.palette.primary.main,
            },
          },
          '& .MuiInput-underline.Mui-error:after': {
            borderBottomColor: '#f44336',
          },
          '& legend span': {
            paddingRight: '15px',
          },
          '& label': {
            color: baseTheme.palette.primary.main,
          },
          '& .MuiOutlinedInput-root fieldset': {
            border: '1px solid',
            borderColor: baseTheme.palette.primary.main,
          },
        },
      },
      MuiInput: {
        underline: {
          borderColor: baseTheme.palette.primary.main,
          '&:hover:not($disabled):before': {
            display: 'none',
          },
          '&:after, &:before': {
            display: 'none',
          },
        },
      },
      MuiInputLabel: {
        root: {
          color: 'rgb(49 39 130 / 61%)',
          transform: 'translate(0, -6px) scale(0.75)',
          '&:after': {
            color: baseTheme.palette.primary.main,
          },
          '&.Mui-focused': {
            color: baseTheme.palette.primary.main,
          },
        },
      },
      MuiButton: {
        outlined: {
          marginRight: '5px',
          border: '1px solid rgba(49 39 130 / 61%)',
          color: 'rgba(49 39 130 / 61%)',
          padding: '5px 15px',
          '&.Mui-disabled': {
            border: '1px solid rgb(0 0 0 / 30.5%)',
            color: 'rgb(0 0 0 / 30.5%)',
          },
        },
        contained: {
          primary: {
            background: baseTheme.palette.secondary.main,
          },
        },
      },
      MuiIconButton: {
        root: {
          '&:hover svg': {
            fill: 'rgb(49 39 130 / 61%)',
          },
        },
      },
      MuiStepIcon: {
        root: {
          '&.MuiStepIcon-active': {
            border: '3px solid',
            borderColor: baseTheme.palette.secondary.main,
            borderRadius: '50%',
          },
        },
      },
      MuiDataGrid: {
        root: {
          '&.MuiDataGrid-booleanCell[data-value="true"]': {
            color: baseTheme.palette.success.main,
          },
        },
      },
      MuiOutlinedInput: {
        root: {
          '&.Mui-disabled.MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgb(49 39 130 / 61%)',
          },
          '&.Mui-disabled': {
            color: 'rgb(49 39 130 / 61%)',
          },
        },
      },
      MuiCard: {
        root: {
          background: 'transparent',
        },
      },
      MuiCardContent: {
        root: {
          padding: '0',
        },
      },
      MuiStepConnector: {
        root: {
          '&.MuiStepConnector-horizontal': {
            minWidth: '60px',
          },
        },
      },
      MuiList: {
        root: {
          display: 'inline-block',
          width: '100%',
          '& .MuiListItemIcon-root': {
            minWidth: '32px',
          },
        },
        padding: {
          paddingTop: '4px',
        },
      },
    },
  },
  ptBR
);

export default quickComexTheme;
