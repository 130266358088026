import React from 'react';
import { InputAdornment } from '@material-ui/core';

export default function QCXInputAdornment({
  position = 'start',
  ...restProps
}) {
  return (
    <InputAdornment
      position={position}
      disableTypography
      {...restProps}
    />
  );
}
