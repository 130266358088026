import { createFilterOptions } from '@material-ui/lab/useAutocomplete';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchAllAsync,
  selectInstituicoesFinanciadoras,
} from '../../features/instituicao-financiadora/instituicaoFinanciadoraSlice';
import QCXAutocompleteSelect from '../autocomplete-select/QCXAutocompleteSelect';

export default function QCXSelectInstituicaoFinanciadoraAutocomplete({
  label,
  name,
  initialValues,
  ...restProps
}) {
  const dispatch = useDispatch();

  const list = useSelector(selectInstituicoesFinanciadoras);

  useEffect(() => {
    dispatch(fetchAllAsync());
  }, []);

  const [defaultValue, setDefaultValue] = useState('');

  useEffect(() => {
    const value = list.find((item) => item?.id === _.get(initialValues, name));
    if (value) {
      setDefaultValue(value);
    } else {
      setDefaultValue({ id: null, description: '' });
    }
  }, [list, initialValues]);

  const filterOptions = createFilterOptions({
    stringify: (item) => `${item?.code} - ${item?.description}`,
  });

  return (
    <QCXAutocompleteSelect
      label={label}
      id={name}
      name={name}
      options={list}
      filterOptions={filterOptions}
      defaultValue={defaultValue}
      getOptionLabel={(item) => (
        item?.code && item?.description
          ? `${item?.code} - ${item?.description}`
          : ''
      )}
      {...restProps}
    />
  );
}
