import { Grid, makeStyles } from '@material-ui/core';
import React, { useCallback, useMemo } from 'react';
import { useFormState } from 'react-final-form';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import QCXFormSubtitle from '../../components/form-title/QCXFormSubtitle';
import { selectRegimesTributacao } from '../../features/regime-tributacao/regimeTributacaoSlice';
import QCXFinalPercentualField from '../final-percentual-field/QCXFinalPercentualField';
import QCXSelectFundamentoLegalPisCofinsAutocomplete from '../select-fundamento-legal-pis-cofins/QCXSelectFundamentoLegalPisCofinsAutocomplete';
import QCXSelectRegimeTributacaoAutocomplete from '../select-regime-tributacao/QCXSelectRegimeTributacaoAutocomplete';
import {
  configureGeneralRegimeTributacaoMatcherWith,
  REGIME_TRIBUTACAO_RECOLHIMENTO_INTEGRAL_CODE,
  REGIME_TRIBUTACAO_REDUCAO_CODE,
} from '../select-regime-tributacao/regimeTributacaoUtils';

const useStyles = makeStyles(() => ({
  gridFields: {
    paddingBottom: '16px',
  },
}));

export default function QCXPisCofinsCommonFormGroup({ modes }) {
  const { t } = useTranslation();

  const classes = useStyles();

  const { values } = useFormState();

  const regimesTributacao = useSelector(selectRegimesTributacao);

  const regimeTributacao = useMemo(() => values?.pisCofins?.regimeTributacao?.id, [values]);

  const isSomeRegimeTributacaoBy = useCallback(
    (codesToCompare) =>
      configureGeneralRegimeTributacaoMatcherWith(regimesTributacao)(regimeTributacao, codesToCompare),
    [regimeTributacao, regimesTributacao]
  );

  const { isConsult, isSubConsult, isSubNone } = modes;

  return (
    <>
      <Grid item container xs={12} sm={6} md={6} lg={6}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <QCXSelectRegimeTributacaoAutocomplete
            id="select-field-regime-tributacao"
            key="select-field-regime-tributacao"
            name="pisCofins.regimeTributacao.id"
            label={t('com.muralis.qcx.regimeTributacao')}
            disabled={isConsult || isSubConsult || isSubNone}
            initialValues={values}
          />
        </Grid>
      </Grid>
      {!isSomeRegimeTributacaoBy([REGIME_TRIBUTACAO_RECOLHIMENTO_INTEGRAL_CODE]) && (
        <Grid item container xs={12} sm={6} md={6} lg={6}>
          <Grid item xs={12} sm={12} md={12} lg={12} className={classes.gridFields}>
            <QCXSelectFundamentoLegalPisCofinsAutocomplete
              id="select-field-fundamento-legal-pis-cofins"
              key="select-field-fundamento-legal-pis-cofins"
              name="pisCofins.fundamentoLegal.id"
              label={t('com.muralis.qcx.legislacao.fundamentoLegal')}
              disabled={isConsult || isSubConsult || isSubNone}
              initialValues={values}
            />
          </Grid>
        </Grid>
      )}
      {isSomeRegimeTributacaoBy([REGIME_TRIBUTACAO_REDUCAO_CODE]) && (
        <>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <QCXFormSubtitle title={t('com.muralis.qcx.PIS')} />
          </Grid>
          <Grid item container xs={12} sm={6} md={6} lg={6}>
            <Grid item xs={12} sm={12} md={12} lg={12} className={classes.gridFields}>
              <QCXFinalPercentualField
                id="percentual-aliquota-reduzida-pis-field"
                key="percentual-aliquota-reduzida-pis-field"
                name="pisCofins.aliquotaReduzidaPisPasep"
                label={t('com.muralis.qcx.alioquoteReduzidaPercentual')}
                disabled={isConsult || isSubConsult || isSubNone}
                scale={7}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <QCXFormSubtitle title={t('com.muralis.qcx.imposto.COFINS')} />
          </Grid>
          <Grid item container xs={12} sm={6} md={6} lg={6}>
            <Grid item xs={12} sm={12} md={12} lg={12} className={classes.gridFields}>
              <QCXFinalPercentualField
                id="percentual-aliquota-reduzida-cofins-field"
                key="percentual-aliquota-reduzida-cofins-field"
                name="pisCofins.aliquotaReduzidaCofins"
                label={t('com.muralis.qcx.alioquoteReduzidaPercentual')}
                scale={7}
                disabled={isConsult || isSubConsult || isSubNone}
              />
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
}
