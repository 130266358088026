import React from 'react';
import { makeStyles } from '@material-ui/core';
import QCXDebug from '../../components/debug/QCXDebug';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
}));

export default function QCXForm({
  onSubmit,
  debugOn = false,
  children,
  ...restProps
}) {
  const classes = useStyles();

  return (
    <form
      className={classes.root}
      onSubmit={onSubmit}
      {...restProps}
    >
      {children}
      <QCXDebug on={debugOn} />
    </form>
  );
}
