import { Grid } from '@material-ui/core';
import React, { useMemo } from 'react';
import { formatNcmSubItemRemoveDash } from '../../utils/hooks/form/field/formatters';
import QCXFinalForm from '../../shared-components/final-form/QCXFinalForm';
import QCXFinalNumericIntegerField from '../../shared-components/final-numeric-integer-field/QCXFinalNumericIntegerField';
import QCXFinalNumericDecimalField from '../../shared-components/final-numeric-decimal-field/QCXFinalNumericDecimalField';
import QCXFinalTextField from '../../shared-components/final-text-field/QCXFinalTextField';
import QCXFinalDatePickerField from '../../shared-components/final-date-picker-field/QCXFinalDatePickerField';
import QCXFormTitle from '../form-title/QCXFormTitle';
import i18n from '../../i18n';

export default function QCXNcmSubitemNivel6Form({
  isConsult,
  initialValues,
  handleChangeToUpdate,
  handleCancelUpdate,
  ...restProps
}) {
  const controlButtons = useMemo(() => ({
    create: {
      hidden: true,
    },
    edit: {
      hidden: true,
    },
    save: {
      hidden: true,
    },
  }), []);

  const aliquotaFieldsData = [{
    role: 'aliquota-II',
    roleAPI: 'aliquotaII',
    labelField: 'aliquotaII',
    labelDatePickerInicioVigencia: 'inicioVigenciaAlioquotaII',
    labelDatePickerFimVigencia: 'fimVigenciaAlioquotaII',
  },
  {
    role: 'aliquota-mercosul',
    roleAPI: 'aliquotaMercosul',
    labelField: 'aliquotaMercosul',
    labelDatePickerInicioVigencia: 'inicioVigenciaAlioquotaMercosul',
    labelDatePickerFimVigencia: 'fimVigenciaAlioquotaMercosul',
  },
  {
    role: 'aliquota-IPI',
    roleAPI: 'aliquotaIpi',
    labelField: 'aliquotaIPI',
    labelDatePickerInicioVigencia: 'inicioVigenciaAlioquotaIPI',
    labelDatePickerFimVigencia: 'fimVigenciaAlioquotaIPI',
  },
  {
    role: 'aliquota-pis-pasep',
    roleAPI: 'aliquotaPisPasep',
    labelField: 'aliquotaPISPASEP',
    labelDatePickerInicioVigencia: 'inicioVigenciaAlioquotaPISPASEP',
    labelDatePickerFimVigencia: 'fimVigenciaAlioquotaPISPASEP',
  },
  {
    role: 'aliquota-cofins',
    roleAPI: 'aliquotaCofins',
    labelField: 'aliquotaCOFINS',
    labelDatePickerInicioVigencia: 'inicioVigenciaAlioquotaCOFINS',
    labelDatePickerFimVigencia: 'fimVigenciaAlioquotaCOFINS',
  }];

  return (
    <QCXFinalForm
      isConsult={isConsult}
      initialValues={initialValues}
      controlButtons={controlButtons}
      handleChangeToUpdate={handleChangeToUpdate}
      handleCancelUpdate={handleCancelUpdate}
      {...restProps}
    >
      <Grid
        container
        spacing={2}
        style={{ marginTop: '1rem' }}
      >
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <QCXFormTitle
            title={i18n.t('com.muralis.qcx.dadosGerais')}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={2} lg={2}>
          <QCXFinalNumericIntegerField
            id="text-field-code"
            key="text-field-code"
            name="code"
            label={i18n.t('com.muralis.qcx.codigo')}
            allowNegative={false}
            disabled={isConsult}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <QCXFinalTextField
            id="text-field-description"
            key="text-field-description"
            name="description"
            format={formatNcmSubItemRemoveDash}
            label={i18n.t('com.muralis.qcx.descricao')}
            maxLength={40}
            disabled={isConsult}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <QCXFinalTextField
            id="text-field-unidade-medida-mercosul"
            key="text-field-unidade-medida-mercosul"
            name="unidadeDeMedida.description"
            label={i18n.t('com.muralis.qcx.unidadeMedida.unidadeMedidaMercosul')}
            disabled={isConsult}
          />
        </Grid>
        {
          aliquotaFieldsData.map((aliquota) => (
            <>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <QCXFinalNumericDecimalField
                  id={`numeric-decimal-${aliquota.role}-field`}
                  key={`numeric-decimal-${aliquota.role}-field`}
                  name={`${aliquota.roleAPI}.valor`}
                  label={i18n.t(`com.muralis.qcx.${aliquota.labelField}`)}
                  disabled={isConsult}
                  scale={7}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <QCXFinalDatePickerField
                  id={`date-picker-data-inicio-vigencia-${aliquota.role}-field`}
                  key={`date-picker-data-inicio-vigencia-${aliquota.role}-field`}
                  name={`${aliquota.roleAPI}.vigencia.inicio`}
                  label={i18n.t(`com.muralis.qcx.${aliquota.labelDatePickerInicioVigencia}`)}
                  disabled={isConsult}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <QCXFinalDatePickerField
                  id={`date-picker-data-fim-vigencia-${aliquota.role}-field`}
                  key={`date-picker-data-fim-vigencia-${aliquota.role}-field`}
                  name={`${aliquota.roleAPI}.vigencia.fim`}
                  label={i18n.t(`com.muralis.qcx.${aliquota.labelDatePickerFimVigencia}`)}
                  disabled={isConsult}
                />
              </Grid>
            </>
          ))
        }
      </Grid>
    </QCXFinalForm>
  );
}
