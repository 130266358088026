import { useMemo } from 'react';
import { isString } from 'lodash';
import { useSelector } from 'react-redux';
import { v4 as uuid, validate } from 'uuid';
import {
  forceUnnormalizeNumeral,
  normalizeData,
  normalizeYear,
  normalizeNumeral,
  unnormalizeNumeral,
  unnormalizeData,
} from '../../utils/general/general-utils';
import { formatBrazilianNumericDecimal } from '../../utils/hooks/form/field/formatters';
import { UNIDADE_DE_MEDIDA_UNIDADE } from '../../features/unidade-medida/unidadeDeMedidaUtils';
import { unidadeDeMedidaActions } from '../../features/unidade-medida/unidadeDeMedidaSlice';

function MercadoriaUtils(deps) {
  this.deps = deps;
}

MercadoriaUtils.prototype.normalize = function (unnormalizedData) {
  return {
    ...unnormalizedData,
    numeroLI: unnormalizedData?.numeroLI,
    dataRegistro: normalizeData(unnormalizedData?.dataRegistro),
    partnumber: unnormalizedData?.partnumber,
    relacao: unnormalizedData?.relacao,
    fabricante: unnormalizedData?.fabricante?.id
      ? unnormalizedData?.fabricante
      : null,
    exportador: unnormalizedData?.exportador?.id
      ? unnormalizedData?.exportador
      : null,
    paisOrigem: unnormalizedData?.paisOrigem?.id
      ? unnormalizedData?.paisOrigem
      : null,
    descricaoResumida: unnormalizedData?.descricaoResumida,
    quantidade: normalizeNumeral(unnormalizedData?.quantidade),
    quantidadeEstatistica: normalizeNumeral(unnormalizedData?.quantidadeEstatistica),
    percentualDesconto: normalizeNumeral(unnormalizedData?.percentualDesconto),
    pesoLiquido: normalizeNumeral(unnormalizedData?.pesoLiquido),
    pesoLiquidoUnitario: normalizeNumeral(unnormalizedData?.pesoLiquidoUnitario),
    valorUnitarioMoeda: normalizeNumeral(unnormalizedData?.valorUnitarioMoeda),
    tipoCalculo: unnormalizedData?.tipoCalculo,
    icms: normalizeNumeral(unnormalizedData?.icms),
    valorTotalMcvMoeda: normalizeNumeral(unnormalizedData?.valorTotalMcvMoeda),
    valorTotalMoeda: normalizeNumeral(unnormalizedData?.valorTotalMoeda),
    valorDescontoMoeda: normalizeNumeral(unnormalizedData?.valorDescontoMoeda),
    valorTotalReal: normalizeNumeral(unnormalizedData?.valorTotalReal),
    destaqueNcm: unnormalizedData?.destaqueNcm,
    produto: unnormalizedData?.produto?.id
      ? {
        id: unnormalizedData?.produto?.id,
      }
      : null,
    unidadeDeMedida: unnormalizedData?.unidadeDeMedida?.id
      ? unnormalizedData?.unidadeDeMedida
      : null,
    ncm: unnormalizedData?.ncm?.id
      ? unnormalizedData?.ncm
      : null,
    naladiSh: unnormalizedData?.naladiSh?.id
      ? unnormalizedData?.naladiSh
      : null,
    naladiNcca: unnormalizedData?.naladiNcca?.id
      ? unnormalizedData?.naladiNcca
      : null,
    atributos: (unnormalizedData?.atributos || [])
      ?.map((atributo) => (
        validate(atributo?.id)
          ? {
            ...atributo,
            nve: atributo?.nve?.id
              ? atributo?.nve
              : null,
            atributo: atributo?.atributo?.id
              ? atributo?.atributo
              : null,
            especificacao: atributo?.especificacao?.id
              ? atributo?.especificacao
              : null,
            id: null,
          } : {
            ...atributo,
            nve: atributo?.nve?.id
              ? atributo?.nve
              : null,
            atributo: atributo?.atributo?.id
              ? atributo?.atributo
              : null,
            especificacao: atributo?.especificacao?.id
              ? atributo?.especificacao
              : null,
          }
      )),
    adicionais: {
      ...unnormalizedData?.adicionais,
      numeroAtoConcessorio: unnormalizedData?.adicionais?.numeroAtoConcessorio,
      itemAtoConcessorio: unnormalizedData?.adicionais?.itemAtoConcessorio,
      numeroSerie: unnormalizedData?.adicionais?.numeroSerie,
      marca: unnormalizedData?.adicionais?.marca,
      modelo: unnormalizedData?.adicionais?.modelo,
      dataValidade: normalizeData(unnormalizedData?.adicionais?.dataValidade),
      anoFabricacao: normalizeYear(unnormalizedData?.adicionais?.anoFabricacao),
      lote: unnormalizedData?.adicionais?.lote,
      especificacao: unnormalizedData?.adicionais?.especificacao,
    },
  };
};

MercadoriaUtils.prototype.unnormalize = function (normalizedData) {
  const foundUnidade = this.deps.unidadeMedidaList.find((
    item
  ) => item.id === normalizedData?.unidadeDeMedida?.id);
  const unidadeMedidaDescription = foundUnidade?.description?.toUpperCase();

  let isUnidade = false;
  if (isString(unidadeMedidaDescription)) {
    isUnidade = unidadeMedidaDescription === UNIDADE_DE_MEDIDA_UNIDADE;
  }

  return {
    ...normalizedData,
    numeroLI: normalizedData?.numeroLI,
    dataRegistro: unnormalizeData(normalizedData?.dataRegistro),
    valorUnitarioMoeda: unnormalizeNumeral(
      normalizedData?.valorUnitarioMoeda,
      formatBrazilianNumericDecimal(7)
    ),
    pesoLiquido: forceUnnormalizeNumeral(
      normalizedData?.pesoLiquido,
      formatBrazilianNumericDecimal(7)
    ),
    pesoLiquidoUnitario: forceUnnormalizeNumeral(
      normalizedData?.pesoLiquidoUnitario,
      formatBrazilianNumericDecimal(7)
    ),
    quantidade: forceUnnormalizeNumeral(
      normalizedData?.quantidade,
      formatBrazilianNumericDecimal(5)
    ),
    percentualDesconto: forceUnnormalizeNumeral(
      normalizedData?.percentualDesconto,
      formatBrazilianNumericDecimal(2)
    ),
    icms: forceUnnormalizeNumeral(
      normalizedData?.icms,
      formatBrazilianNumericDecimal(7)
    ),
    quantidadeEstatistica: forceUnnormalizeNumeral(
      normalizedData?.quantidadeEstatistica,
      formatBrazilianNumericDecimal(isUnidade ? 5 : 7)
    ),
    valorTotalMcvMoeda: unnormalizeNumeral(
      normalizedData?.valorTotalMcvMoeda,
      formatBrazilianNumericDecimal(2)
    ),
    valorTotalMoeda: unnormalizeNumeral(
      normalizedData?.valorTotalMoeda,
      formatBrazilianNumericDecimal(2)
    ),
    valorTotalReal: unnormalizeNumeral(
      normalizedData?.valorTotalReal,
      formatBrazilianNumericDecimal(2)
    ),
    valorDescontoMoeda: unnormalizeNumeral(
      normalizedData?.valorDescontoMoeda,
      formatBrazilianNumericDecimal(2)
    ),
    atributos: (normalizedData?.atributos || [])
      ?.map((atributo) => (
        atributo?.id
          ? {
            ...atributo,
            nve: atributo?.nve?.id
              ? atributo?.nve
              : undefined,
            atributo: atributo?.atributo?.id
              ? atributo?.atributo
              : undefined,
            especificacao: atributo?.especificacao?.id
              ? atributo?.especificacao
              : undefined,
          } : {
            ...atributo,
            nve: atributo?.nve?.id
              ? atributo?.nve
              : undefined,
            atributo: atributo?.atributo?.id
              ? atributo?.atributo
              : undefined,
            especificacao: atributo?.especificacao?.id
              ? atributo?.especificacao
              : undefined,
            id: uuid(),
          }
      )),
    adicionais: {
      ...normalizedData?.adicionais,
      anoFabricacao: `${normalizedData?.adicionais?.anoFabricacao || ''}`,
    },
  };
};

const useMercadoriaUtils = () => {
  const unidadeMedidaList = useSelector(
    unidadeDeMedidaActions.selectUnidadesdeMedida
  );

  const mercadoriaUtils = useMemo(() => (
    new MercadoriaUtils({
      unidadeMedidaList,
    })
  ), [unidadeMedidaList]);

  return mercadoriaUtils;
};

export {
  MercadoriaUtils,
  useMercadoriaUtils,
};
