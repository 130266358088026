import React from "react";
import Button, { ButtonProps } from "@material-ui/core/Button";
import { Tooltip } from "@material-ui/core";

export interface StyledButtonProps extends ButtonProps {
  tooltip?: boolean;
  tooltipDescription?: string;
}

export default function StyledButton({
  tooltip = false,
  tooltipDescription,
  variant,
  color = "default" || "inherit" || "primary" || "secondary",
  fullWidth = false,
  disabled,
  children,
  ...restProps
}: StyledButtonProps) {
  const button = (
    <Button
      variant={variant || "contained"}
      color={color || "default"}
      disabled={disabled}
      fullWidth={fullWidth}
      {...restProps}
    >
      {children}
    </Button>
  );
  return tooltip && !disabled ? (
    <Tooltip
      arrow
      title={tooltipDescription ?? ""}
      aria-label={tooltipDescription}
    >
      <span>{button}</span>
    </Tooltip>
  ) : (
    button
  );
}
