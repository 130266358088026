import { Grid } from '@material-ui/core';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import QCXFinalBondManagerOld from '../../shared-components/final-gerenciador-relacionamento/QCXFinalBondManagerOld';
import QCXFinalTextField from '../../shared-components/final-text-field/QCXFinalTextField';
import QCXFinalAlternativeCurrencyField from '../../shared-components/final-currency-field/QCXFinalAlternativeCurrencyField';
import {
  vinculoNotaFiscalDUEActions,
  vinculoNotaFiscalDUESelectors,
} from '../../features/vinculo-nota-fiscal-due/vinculoNotaFiscalDUESlice';
import QCXFinalDatePickerField from '../../shared-components/final-date-picker-field/QCXFinalDatePickerField';
import { isCurrentOrPastDate } from '../../utils/validators/field/validator';
import { formatDate, isStrictEquals } from '../../utils/general/general-utils';
import QCXFinalNumericDecimalField from '../../shared-components/final-numeric-decimal-field/QCXFinalNumericDecimalField';

export default function QCXNotaFiscalFormGroup({ isParentConsult }) {
  const { t } = useTranslation();

  const notaFiscalListProps = useMemo(
    () => ({
      name: 'ignorableFields.atoConcessorio.notasFiscais',
      columns: [
        {
          field: 'identificacao',
          headerName: t('com.muralis.qcx.NumeroNotaFiscalOuChaveAcessoNFe'),
          headerAlign: 'center',
          align: 'center',
          flex: 135,
          valueGetter: ({ row }) => row?.identificacao || '-',
        },
        {
          field: 'quantidade',
          headerName: t('com.muralis.qcx.quantidade.label'),
          headerAlign: 'center',
          align: 'center',
          flex: 85,
          valueGetter: ({ row }) => row?.quantidade || '-',
        },
        {
          field: 'data',
          headerName: t('com.muralis.qcx.data'),
          headerAlign: 'center',
          align: 'center',
          flex: 50,
          valueGetter: ({ row }) => formatDate(row?.data) || '-',
        },
        {
          field: 'valor',
          headerName: t('com.muralis.qcx.moeda.valorMoedaNegociada'),
          headerAlign: 'right',
          align: 'right',
          flex: 130,
          valueGetter: ({ row }) => row?.valor || '-',
        },
      ],
    }),
    []
  );

  const notaFiscalFormProps = useMemo(
    () => ({
      rootName: 'ignorableFields.notaFiscalAC',
      fields: [
        {
          name: 'identificacao',
          label: t('com.muralis.qcx.numeroNotaFiscalOuChaveAcessoNFe'),
        },
        {
          name: 'quantidade',
          label: t('com.muralis.qcx.quantidade.label'),
        },
        {
          name: 'data',
          label: t('com.muralis.qcx.data'),
        },
        {
          name: 'valor',
          label: t('com.muralis.qcx.moeda.valorMoedaNegociada'),
        },
      ],
    }),
    []
  );

  const notaFiscalReducerConfig = useMemo(
    () => ({
      selectors: {
        selectStatus: vinculoNotaFiscalDUESelectors.selectStatus,
        selectMode: vinculoNotaFiscalDUESelectors.selectMode,
        selectModel: vinculoNotaFiscalDUESelectors.selectModel,
      },
      actions: {
        loading: vinculoNotaFiscalDUEActions.loading,
        resetStatus: vinculoNotaFiscalDUEActions.resetStatus,
        changeToUpdateMode: vinculoNotaFiscalDUEActions.changeToUpdateMode,
        changeToCreateMode: vinculoNotaFiscalDUEActions.changeToCreateMode,
        resetMode: vinculoNotaFiscalDUEActions.resetMode,
        setModel: vinculoNotaFiscalDUEActions.setModel,
        resetModel: vinculoNotaFiscalDUEActions.resetModel,
      },
    }),
    []
  );

  const handleAddNotaFiscal = useCallback(async (currentVinculo, currentVinculos, handleSuccessAddVinculo) => {
    const vinculo = {
      id: uuid(),
      identificacao: currentVinculo?.identificacao,
      quantidade: currentVinculo?.quantidade,
      data: currentVinculo?.data,
      valor: currentVinculo?.valor,
    };

    const updatedVinculos = [...currentVinculos, vinculo];

    handleSuccessAddVinculo(updatedVinculos);
  }, []);

  const handleUpdateNotaFiscal = useCallback(async (currentVinculo, currentVinculos, handleSuccessUpdateVinculo) => {
    const updatedVinculo = {
      ...currentVinculo,
      identificacao: currentVinculo?.identificacao,
      quantidade: currentVinculo?.quantidade,
      data: currentVinculo?.data,
      valor: currentVinculo?.valor,
    };

    const updatedVinculos =
      currentVinculos?.map((existingVinculo) =>
        isStrictEquals(existingVinculo?.id, updatedVinculo?.id) ? updatedVinculo : existingVinculo
      ) || currentVinculos;

    handleSuccessUpdateVinculo(updatedVinculos);
  }, []);

  const handleNotaFiscalAlreadyExists = useCallback(
    (currentVinculo, currentVinculosList) =>
      currentVinculosList.some(
        (vinculo) =>
          isStrictEquals(vinculo?.identificacao, currentVinculo?.identificacao) &&
          isStrictEquals(vinculo?.quantidade, currentVinculo?.quantidade) &&
          isStrictEquals(vinculo?.data, currentVinculo?.data) &&
          isStrictEquals(vinculo?.valor, currentVinculo?.valor)
      ),
    []
  );

  const notaFiscalDecorator = useCallback((tools) => {
    const finalBondManagerForm = tools?.formUtils?.form;

    finalBondManagerForm.change('ignorableFields.notaFiscalAC.quantidade', '');
    finalBondManagerForm.change('ignorableFields.notaFiscalAC.valor', '');
  }, []);

  const dataGridDivProps = useMemo(
    () => ({
      style: {
        paddingTop: '16px',
      },
    }),
    []
  );

  return (
    <>
      <QCXFinalBondManagerOld
        isParentConsult={isParentConsult}
        listProps={notaFiscalListProps}
        formProps={notaFiscalFormProps}
        reducerConfig={notaFiscalReducerConfig}
        handleAdd={handleAddNotaFiscal}
        handleUpdate={handleUpdateNotaFiscal}
        handleAlreadyExists={handleNotaFiscalAlreadyExists}
        dataGridDivProps={dataGridDivProps}
        {...{
          onClearDecorator: notaFiscalDecorator,
          onAddDecorator: notaFiscalDecorator,
        }}
      >
        {({ loading }) => (
          <>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <QCXFinalTextField
                id="text-field-nota-fiscal"
                key="text-field-nota-fiscal"
                name="ignorableFields.notaFiscalAC.identificacao"
                label={t('com.muralis.qcx.numeroNotaFiscalOuChaveAcessoNFe')}
                disabled={loading}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <QCXFinalNumericDecimalField
                id="numeric-quantidade-nota-fiscal"
                key="numeric-quantidade-nota-fiscal"
                name="ignorableFields.notaFiscalAC.quantidade"
                label={t('com.muralis.qcx.quantidade.label')}
                disabled={loading}
                scale={5}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <QCXFinalDatePickerField
                id="date-picker-data-nota-fiscal-field"
                key="date-picker-data-nota-fiscal-field"
                label={t('com.muralis.qcx.data')}
                disabled={loading}
                name="ignorableFields.notaFiscalAC.data"
                validate={isCurrentOrPastDate}
                disableFuture
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <QCXFinalAlternativeCurrencyField
                id="currency-valor-moeda-negociada-nota-fiscal"
                key="currency-valor-moeda-negociada-nota-fiscal"
                name="ignorableFields.notaFiscalAC.valor"
                label={t('com.muralis.qcx.moeda.valorMoedaNegociada')}
                disabled={loading}
              />
            </Grid>
          </>
        )}
      </QCXFinalBondManagerOld>
    </>
  );
}
