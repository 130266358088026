// import HomePage from '../pages/module/home/HomePage';
import GeneralRegistrationsPage from '../pages/module/registrations/general/GeneralRegistrationsPage';
import OperationalRegistrationsPage from '../pages/module/registrations/operational/OperationalRegistrationsPage';
import FinancialRegistrationsPage from '../pages/module/registrations/financial/FinancialRegistrationsPage';
import ImportPage from '../pages/module/import/ImportPage';
import ExportPage from '../pages/module/export/ExportPage';
import FinancialPage from '../pages/module/financial/FinancialPage';

import i18n from '../i18n';
import { ModuloPerfilUtils } from '../components/perfil/perfil-utils';

const SEPARATOR = '/';

// const main = {
//   base: null,
//   access: null,
//   routes: [
//     {
//       path: [],
//       component: HomePage,
//     },
//   ],
// };

const registrations = {
  base: i18n.t('com.muralis.qcx.routes.cadastros'),
  access: ModuloPerfilUtils.CADASTROS,
  routes: [
    {
      path: [i18n.t('com.muralis.qcx.routes.gerais')],
      component: GeneralRegistrationsPage,
    },
    {
      path: [i18n.t('com.muralis.qcx.routes.operacionais')],
      component: OperationalRegistrationsPage,
    },
    {
      path: [i18n.t('com.muralis.qcx.routes.financeiros')],
      component: FinancialRegistrationsPage,
    },
  ],
};

const importacao = {
  base: i18n.t('com.muralis.qcx.routes.importacao'),
  access: ModuloPerfilUtils.IMPORTACAO,
  routes: [
    {
      path: [],
      component: ImportPage,
    },
  ],
};

const exportacao = {
  base: i18n.t('com.muralis.qcx.routes.exportacao'),
  access: ModuloPerfilUtils.EXPORTACAO,
  routes: [
    {
      path: [],
      component: ExportPage,
    },
  ],
};

const financeiro = {
  base: i18n.t('com.muralis.qcx.routes.financeiro'),
  access: ModuloPerfilUtils.FINANCEIRO,
  routes: [
    {
      path: [],
      component: FinancialPage,
    },
  ],
};

export default function useRoutes() {
  const map = ({ base, routes, access }) => routes.map(({ path, component }) => ({
    path: SEPARATOR.concat([base, ...path].join(SEPARATOR)),
    access,
    component,
  }));

  return [
    // ...map(main),
    ...map(registrations),
    ...map(importacao),
    ...map(exportacao),
    ...map(financeiro),
  ];
}
