import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LicencaImportacaoContext from '../../../../contexts/import/licenca-importacao/LicencaImportacaoContext';
import {
  selectError,
  selectResponse,
  selectStatus,
} from '../../../../features/licenca-importacao/licencaImportacaoSelectors';
import {
  clearError,
  clearResponse,
  resetStatus,
} from '../../../../features/licenca-importacao/licencaImportacaoSlice';
import QCXPageTemplate from '../../../../templates/page/QCXPageTemplate';
import { isFailureStatus, isLoadingStatus, isSuccessStatus } from '../../../../utils/store/store-utils';

export default function LicencaImportacaoPage({ children }) {
  const dispatch = useDispatch();

  const status = useSelector(selectStatus);
  const error = useSelector(selectError);
  const response = useSelector(selectResponse);

  const isLoading = useMemo(() => (
    isLoadingStatus(status)
  ), [status]);

  const [successSnackbar, setSuccessSnackbar] = useState({
    open: false,
    message: '',
  });

  const [errorSnackbar, setErrorSnackbar] = useState({
    open: false,
    message: '',
  });

  const handleOpenSuccessSnackbar = useCallback((message) => {
    setSuccessSnackbar({
      open: true,
      message,
    });
  }, []);

  const handleOpenErrorSnackbar = useCallback((message) => {
    setErrorSnackbar({
      open: true,
      message,
    });
  }, []);

  const handleCloseSuccessSnackbar = useCallback(() => {
    setSuccessSnackbar({
      ...successSnackbar,
      open: false,
    });
  }, []);

  const handleCloseErrorSnackbar = useCallback(() => {
    setErrorSnackbar({
      ...errorSnackbar,
      open: false,
    });
  }, []);

  useEffect(() => {
    const handleStatus = () => {
      if (isFailureStatus(status) && error && error.message) {
        handleOpenErrorSnackbar(error.message);
        dispatch(resetStatus());
        dispatch(clearError());

        return;
      }
      if (isSuccessStatus(status) && response && response.message) {
        handleOpenSuccessSnackbar(response.message);
        dispatch(resetStatus());
        dispatch(clearResponse());
      }
    };

    handleStatus();
  }, [response, status, error]);

  const providerData = useMemo(() => ({
    status,
    isLoading,
    successSnackbar,
    errorSnackbar,
    handleCloseSuccessSnackbar,
    handleCloseErrorSnackbar,
  }), [
    status,
    isLoading,
    successSnackbar,
    errorSnackbar,
  ]);

  return (
    <LicencaImportacaoContext.Provider
      value={providerData}
    >
      <QCXPageTemplate
        contextComponent={LicencaImportacaoContext}
      >
        {children}
      </QCXPageTemplate>
    </LicencaImportacaoContext.Provider>
  );
}
