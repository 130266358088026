import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
} from 'react';
import {
  makeStyles,
} from '@material-ui/core';
import { createFilterOptions } from '@material-ui/lab/useAutocomplete';
import _ from 'lodash';
import { useFormState } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  VscQuestion as VscQuestionIcon,
} from 'react-icons/vsc';
import QCXAutocompleteSelect from '../autocomplete-select/QCXAutocompleteSelect';
import {
  getRenderComponentFunctionByOption,
} from '../select-icon/iconUtils';
import { fetchAllAsync, selectEtapas } from '../../features/etapa/etapaSlice';

const useStyles = makeStyles(() => ({
  optionContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  optionItemIcon: {
    paddingRight: '8px',
  },
  optionItemText: {
    paddingBottom: '4px',
  },
  gridPreview: {
    paddingRight: '8px',
  },
  cardPreview: {
    height: '37.63px',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    padding: '1',
    boxShadow: 'none',
  },
  iconSpanPreview: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export default function QCXSelectEtapaPreviewAutocomplete({
  label,
  name,
  filterPredicate,
  disabled,
  ...restProps
}) {
  const classes = useStyles();

  const dispatch = useDispatch();

  const { values } = useFormState();

  const list = useSelector(selectEtapas);

  const [filteredList, setFilteredList] = useState([]);
  const [defaultValue, setDefaultValue] = useState('');

  const hasFilter = useMemo(() => (
    _.isFunction(filterPredicate)
  ), [filterPredicate]);

  useEffect(() => {
    dispatch(fetchAllAsync());
  }, []);

  useEffect(() => {
    if (hasFilter && _.isArray(list)) {
      const filtered = list.filter(filterPredicate);
      setFilteredList(filtered);
    }
  }, [list, filterPredicate]);

  useEffect(() => {
    const value = list.find((item) => item?.id === _.get(values, name));
    if (value) {
      setDefaultValue(value);
    } else {
      setDefaultValue({ id: null, description: '' });
    }
  }, [list, values]);

  const filterOptions = createFilterOptions({
    stringify: (option) => option.description,
  });

  const availableOptions = useMemo(() => (
    hasFilter
      ? filteredList
      : list
  ), [hasFilter, filteredList, list]);

  const getOptionLabel = useCallback((item) => (
    item?.description || ''
  ), []);

  const getOptionValue = useCallback((item) => (
    item?.id || ''
  ), []);

  const handleRenderOptionDecorator = useCallback(({
    option,
    component,
  }) => {
    const renderIcon = getRenderComponentFunctionByOption({
      value: option?.icone,
    });

    const icon = _.isFunction(renderIcon)
      ? renderIcon()
      : (
        <VscQuestionIcon
          color="#312783"
          fontSize={22}
        />
      );

    return (
      <div
        className={classes.optionContainer}
      >
        <span
          className={classes.optionItemIcon}
        >
          {icon}
        </span>
        <span
          className={classes.optionItemText}
        >
          {component}
        </span>
      </div>
    );
  }, []);

  return (
    <QCXAutocompleteSelect
      label={label}
      id={name}
      name={name}
      options={availableOptions}
      filterOptions={filterOptions}
      defaultValue={defaultValue}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      renderOptionDecorator={handleRenderOptionDecorator}
      disabled={disabled}
      {...restProps}
    />
  );
}
