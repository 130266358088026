import { createAsyncThunk } from '@reduxjs/toolkit';
import { isFunction } from 'lodash';
import { MediaType } from '../../utils/api/api-utils';
import { contasReceberAPI } from './contasReceberAPI';

const fetchAllAsync = createAsyncThunk(
  'contasReceber/fetchAll',
  async () => {
    const { data } = await contasReceberAPI.fetchAll();
    return { data };
  }
);

const generateReportAReceberByDateAsync = createAsyncThunk(
  'contasReceber/generateReportAReceberByDate',
  async (payload) => {
    try {
      const response = await contasReceberAPI.generateReportAReceberByDate([
        {
          name: 'datainicial',
          value: payload?.vencimentoDe,
        },
        {
          name: 'datafinal',
          value: payload?.vencimentoAte,
        }
      ]);

      if (response?.status === 204) {
        const onNoContent = payload?.handlers?.onNoContent;
        if (isFunction(onNoContent)) {
          onNoContent();
          return;
        }
      }

      const xmlData = new Blob([response?.data], { type: MediaType.APPLICATION_PDF });

      const url = window.URL.createObjectURL(xmlData);
      window.open(url);
    } catch (e) {
      const onError = payload?.handlers?.onError;

      if (isFunction(onError)) {
        onError();
      }
    }
  }
);

const solicitacaoReceberThunks = {
  fetchAllAsync,
  generateReportAReceberByDateAsync,
};

export {
  generateReportAReceberByDateAsync,
  solicitacaoReceberThunks,
  fetchAllAsync,
};
