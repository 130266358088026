import { createFilterOptions } from '@material-ui/lab/useAutocomplete';
import _, { isFunction } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchAllAsync,
  selectTiposDocumentoFiscal,
  selectConfigControl,
} from '../../features/tipo-documento-fiscal/tipoDocumentoFiscalSlice';
import { isAutoLoadControl } from '../../utils/store/store-utils';
import QCXAutocompleteSelect from '../autocomplete-select/QCXAutocompleteSelect';

export default function QCXSelectTipoDocumentoFiscalAutocomplete({
  label,
  name,
  initialValues,
  configureFilterPredicate,
  ...restProps
}) {
  const dispatch = useDispatch();

  const list = useSelector(selectTiposDocumentoFiscal);
  const configControl = useSelector(selectConfigControl);

  const [defaultValue, setDefaultValue] = useState('');

  const filteredList = useMemo(() => {
    if (isFunction(configureFilterPredicate)) {
      const filterPredicate = configureFilterPredicate();
      const filteredValues = list.filter(filterPredicate);

      return filteredValues;
    }

    return list;
  }, [list, configureFilterPredicate]);

  useEffect(() => {
    if (isAutoLoadControl(configControl)) {
      dispatch(fetchAllAsync());
    }
  }, [configControl]);

  useEffect(() => {
    const value = filteredList.find((item) => item?.id === _.get(initialValues, name));

    if (value) {
      setDefaultValue(value);
      return;
    }

    setDefaultValue({ id: null, description: '' });
  }, [filteredList, initialValues]);

  const filterOptions = createFilterOptions({
    stringify: (item) => `${item?.code} - ${item?.description}`,
  });

  return (
    <QCXAutocompleteSelect
      label={label}
      id={name}
      name={name}
      options={filteredList}
      filterOptions={filterOptions}
      defaultValue={defaultValue}
      getOptionLabel={(item) => item?.description}
      {...restProps}
    />
  );
}
