import { isEmpty, isString } from 'lodash';
import useQuerySearch from './useQuerySearch';

export default function useQueryParam(name = '') {
  const querySearch = useQuerySearch();

  if (isString(name) && !isEmpty(name)) {
    return querySearch.get(name);
  }

  return undefined;
}
