import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { IconButton } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
  Search as PageviewIcon,
  Delete as DeleteIcon,
  Compare as CompareIcon,
} from '@material-ui/icons';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import QCXSimpleConsultPageTemplate from '../../../../../templates/simple-consult-page/QCXSimpleConsultPageTemplate';
import TabelaArmazenagemContext from '../../../../../contexts/registrations/tabela-armazenagem/TabelaArmazenagemContext';
import {
  fetchAllAsync,
  activateByIdAsync,
  inactivateByIdAsync,
  changeToConsultMode,
  selectTabelasArmazenagem,
  resetModel,
  changeToCreateMode,
  resetMode,
  resetRelatedPeriodoModel,
  resetRelatedModeloLancamentoModel,
  resetSubMode,
} from '../../../../../features/tabela-armazenagem/tabelaArmazenagemSlice';
import { resetList as resetListClientes } from '../../../../../features/cliente/clienteSlice';
import { isAlternativeLoadingStatus } from '../../../../../utils/store/store-utils';
import { userHasDeleteRole } from '../../../../../components/perfil/perfil-utils';

export default function TabelaArmazenagemConsultPage({ authInfo = {} }) {
  const { t } = useTranslation();

  const history = useHistory();

  const {
    status,
  } = useContext(TabelaArmazenagemContext);

  const dispatch = useDispatch();

  const tabelasArmazenagem = useSelector(selectTabelasArmazenagem);

  const isAlternativeLoading = useMemo(() => (
    isAlternativeLoadingStatus(status)
  ), [status]);

  const fetchAll = async () => {
    dispatch(fetchAllAsync());
  };

  const resetModes = useCallback(() => {
    dispatch(resetMode());
    dispatch(resetSubMode());
  }, []);

  const resetModels = useCallback(() => {
    dispatch(resetModel());
    dispatch(resetRelatedPeriodoModel());
    dispatch(resetRelatedModeloLancamentoModel());
  }, []);

  const resetDependencies = useCallback(() => {
    dispatch(resetListClientes());
  }, []);

  useEffect(() => {
    fetchAll();
    resetModes();
    resetModels();
    resetDependencies();
  }, []);

  const handleActivateInactivate = async (event, id) => {
    event.preventDefault();

    const { active } = tabelasArmazenagem.find(
      (tabelaArmazenagem) => tabelaArmazenagem.id === id
    );

    const isActivation = !active;

    if (isActivation) {
      dispatch(activateByIdAsync(id));
    } else {
      dispatch(inactivateByIdAsync(id));
    }
  };

  const handleClickOnButtonNew = useCallback((event) => {
    event.stopPropagation();
    dispatch(changeToCreateMode());
  }, []);

  const handleClickOnEditButton = useCallback((event, id) => {
    event.stopPropagation();
    dispatch(changeToConsultMode());
    history.push(t('com.muralis.qcx.url.moduloOperacionaisTabelaArmazenagemDetalhes', { id }));
  }, [history]);
  const breadcrumbs = [
    {
      link: {
        to: '/',
        name: t('com.muralis.qcx.inicio'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.cadastros'),
      },
    },
    {
      link: {
        to: t('com.muralis.qcx.url.moduloOperacionais'),
        name: t('com.muralis.qcx.operacionais'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.armazem.labelPlural'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.tabelaArmazenagem.labelPlural'),
      },
    },
  ];

  const columns = [
    {
      field: 'id',
      headerName: t('com.muralis.qcx.codigo'),
      headerAlign: 'center',
      align: 'center',
      type: 'string',
      flex: 100,
      valueGetter: ({ row }) => (
        row?.id
      ),
    },
    {
      field: 'description',
      headerName: t('com.muralis.qcx.nome'),
      flex: 600,
      valueGetter: ({ row }) => (
        row?.description
      ),
    },

    {
      field: 'actions',
      headerName: t('com.muralis.qcx.acoes.label'),
      headerAlign: 'center',
      flex: 150,
      align: 'center',
      renderCell: ({ row: { id, active } }) => (
        <>
          {userHasDeleteRole(authInfo, 'tabela-armazenagem') && (
          <IconButton
            key={`stw-active${id}`}
            value={active}
            name={`stw-active-${id}`}
            onClick={(event) => handleActivateInactivate(event, id)}
          >
            <DeleteIcon color="error" />
          </IconButton>
          )}
          <IconButton
            key={`btn-view-${id}`}
            name={`btn-view-${id}`}
            onClick={(event) => handleClickOnEditButton(event, id)}
          >
            <PageviewIcon color="primary" />
          </IconButton>
        </>
      ),
    }
  ];

  const templateProps = {
    page: {
      title: t('com.muralis.qcx.tabelaArmazenagem.labelPlural'),
      icon: <CompareIcon />,
      breadcrumbs,
    },
    control: {
      buttonNew: {
        description: ('com.muralis.qcx.tabelaArmazenagem.novaTabelaArmazenagem'),
        path: t('com.muralis.qcx.url.moduloOperacionaisTabelaArmazenagemNova'),
        onClick: handleClickOnButtonNew,
      },
    },
    table: {
      columns,
    },
  };

  return (
    <QCXSimpleConsultPageTemplate
      pageProps={templateProps.page}
      controlProps={templateProps.control}
      tableProps={templateProps.table}
      tableData={tabelasArmazenagem}
      isLoading={isAlternativeLoading}
      filterProp="description"
      buttonNewText={t('com.muralis.qcx.acoes.nova').toUpperCase()}
      authInfo={authInfo}
      requiredRoles={['tabela-armazenagem']}
    />
  );
}
