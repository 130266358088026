import { Box, Breadcrumbs, Divider, Link } from '@mui/material';
import { useHistory, Link as RouterLink } from 'react-router-dom';
import { useFindPath } from '../hooks/useFindPath';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const BreadCrumbs = () => {
  const history = useHistory();
  const path = useFindPath();
  const splitPath = path?.split('/');
  splitPath?.shift();
  let currentPath = '';

  return (
    <Box
      style={{
        paddingTop: '10px',
      }}
    >
      <Breadcrumbs
        separator={
          <NavigateNextIcon style={{ margin: '0 10px' }} fontSize="small" />
        }
        style={{
          paddingLeft: '20px',
          fontSize: '14px',
        }}
      >
        <Link component={RouterLink} to={'/'} underline="hover">
          Início
        </Link>
        ;
        {splitPath?.map((path, index) => {
          currentPath += `/${path}`;
          const navigationPath = currentPath.toString();
          return (
            <Link component={RouterLink} to={navigationPath} underline="hover">
              {path.charAt(0).toUpperCase() + path.slice(1)}
            </Link>
          );
        })}
      </Breadcrumbs>
      <Divider style={{ marginTop: '10px' }}></Divider>
    </Box>
  );
};

export default BreadCrumbs;
