import React, {
  useCallback,
  useMemo,
  useState,
} from 'react';
import {
  useTheme,
  useMediaQuery,
  Grid,
  Typography,
} from '@material-ui/core';
import {
  Close as CloseIcon,
  Email as EmailIcon,
  Print as PrintIcon,
} from '@material-ui/icons';
import {
  FileDownload as FileDownloadIcon,
  NoteAdd as NoteAddIcon,
} from '@mui/icons-material';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import QCXButton from '../../shared-components/button/QCXButton';
import QCXIconButton from '../../shared-components/button/QCXIconButton';
import QCXDataGrid from '../../shared-components/data-grid/QCXDataGrid';
import QCXCompoundDialog from '../../shared-components/dialog/QCXCompoundDialog';
import { CLOSED_STATUS, isOpenStatus } from '../../utils/hooks/form/dialog/formDialogUtils';
import QCXNovoDocumentoFollowUpFormDialog from './QCXNovoDocumentoFollowUpFormDialog';
import useFormDialogSync from '../../utils/hooks/form/dialog/useFormDialogSync';
import QCXEmailDocumentoFollowUpFormDialog from './QCXEmailDocumentoFollowUpFormDialog';
import useDownloader from '../../utils/hooks/downloader/useDownloader';
import { followUpActions } from '../../features/follow-up/followUpSlice';
import { isLoadingStatus } from '../../utils/store/store-utils';
import QCXInfoAlert from '../../shared-components/alert/QCXInfoAlert';

export default function QCXDocumentosFollowUpDialogManager({
  model,
  status,
  handleStatus,
  ...restProps
}) {
  const { t } = useTranslation();

  const theme = useTheme();

  const isXsDownScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const dispatch = useDispatch();

  const [selectedDocuments, setSelectedDocuments] = useState([]);

  const downloader = useDownloader({
    autoDownload: true,
  });

  const downloading = useMemo(() => (
    isLoadingStatus(downloader.status)
  ), [downloader]);

  const documentoArquivoList = useMemo(() => (
    model?.arquivos || []
  ), [model]);

  const open = useMemo(() => (
    isOpenStatus(status)
  ), [status]);

  const handleClose = useCallback(() => {
    handleStatus(CLOSED_STATUS);
  }, [handleStatus]);

  const isMultipleSelection = useMemo(() => (
    selectedDocuments.length > 1
  ), [selectedDocuments]);

  const isSingleSelection = useMemo(() => (
    selectedDocuments.length === 1
  ), [selectedDocuments]);

  const handleSelectionDocumentsChange = useCallback(({ selectionModel }) => {
    setSelectedDocuments(selectionModel);
  }, [setSelectedDocuments]);

  const handleClearDocumentsSelection = useCallback(() => {
    setSelectedDocuments([]);
  }, [setSelectedDocuments]);

  const [
    handleOpenModalNovoDocumento,
    formDialogDocumentoFollowUpStatus,
    handleFormDialogDocumentoFollowUpStatus,
  ] = useFormDialogSync();

  const configureDownloadSingleDocumentHandlerBy = useCallback(({ id }) => {
    const handleDownloadDocumentById = (event) => {
      if (_.isFunction(event?.stopPropagation())) {
        event.stopPropagation();
      }

      downloader.downloadFileById(id);
    };

    return handleDownloadDocumentById;
  }, [downloader]);

  const handleDownloadMultipleDocumentFromSelection = useCallback((event) => {
    if (_.isFunction(event?.stopPropagation())) {
      event.stopPropagation();
    }

    if (!_.isEmpty(selectedDocuments)) {
      const filesList = documentoArquivoList
        .filter((documentoArquivo) => (
          selectedDocuments.includes(documentoArquivo.id)
        ))
        .map(({ documento }) => ({
          id: documento?.id,
        }));

      downloader.downloadMultipleFilesBy(
        filesList,
        handleClearDocumentsSelection
      );
    }
  }, [
    downloader,
    documentoArquivoList,
    selectedDocuments,
    handleClearDocumentsSelection,
  ]);

  const [
    handleOpenModalEnviarDocumentoPorEmail,
    formDialogDocumentoEmailFollowUpStatus,
    handleFormDialogDocumentoEmailFollowUpStatus,
  ] = useFormDialogSync();

  const configureSendEmailWithSingleDocumentHandlerBy = useCallback((arquivo) => {
    const handleDownloadDocumentById = (event) => {
      if (_.isFunction(event?.stopPropagation())) {
        event.stopPropagation();
      }

      dispatch(
        followUpActions.setRelatedEmailModel({
          id: model?.id,
          arquivos: [
            arquivo,
          ],
        })
      );

      handleOpenModalEnviarDocumentoPorEmail();
    };

    return handleDownloadDocumentById;
  }, [
    model,
    handleOpenModalEnviarDocumentoPorEmail,
  ]);

  const handleOpenSendEmailWithMultipleDocumentFromSelection = useCallback((event) => {
    if (_.isFunction(event?.stopPropagation())) {
      event.stopPropagation();
    }

    if (!_.isEmpty(selectedDocuments)) {
      const filesList = documentoArquivoList
        .filter((documentoArquivo) => (
          selectedDocuments.includes(documentoArquivo.id)
        ));

      dispatch(
        followUpActions.setRelatedEmailModel({
          id: model?.id,
          arquivos: filesList,
        })
      );

      handleOpenModalEnviarDocumentoPorEmail();
    }
  }, [
    model,
    documentoArquivoList,
    selectedDocuments,
  ]);

  const columns = useMemo(() => ([
    {
      field: 'tipoDocumento.description',
      headerName: t('com.muralis.qcx.documento.tipoDocumento'),
      flex: 140,
      valueGetter: ({ row }) => (
        row?.tipoDocumento?.description
        || '-'
      ),
    },
    {
      field: 'numero',
      headerName: t('com.muralis.qcx.numero'),
      flex: 100,
      valueGetter: ({ row }) => (
        row?.numero || '-'
      ),
    },
    {
      field: 'actions',
      headerName: t('com.muralis.qcx.acoes.label'),
      headerAlign: 'center',
      align: 'center',
      flex: 110,
      renderCell: ({
        row: {
          id,
          documento,
          ...restPropsRow
        },
      }) => (
        <>
          <QCXIconButton
            key={`icon-button-documento-print-${id}`}
            name={`icon-button-documento-print-${id}`}
            tooltipProps={{
              title: t('com.muralis.qcx.documento.imprimirDocumento'),
            }}
            disableTooltip
            disabled
          >
            <PrintIcon
              color="disabled" // "primary"
              size={20}
            />
          </QCXIconButton>
          <QCXIconButton
            key={`icon-button-documento-file-download-${id}`}
            name={`icon-button-documento-file-download-${id}`}
            tooltipProps={{
              title: t('com.muralis.qcx.documento.baixarDocumento'),
            }}
            onClick={configureDownloadSingleDocumentHandlerBy(documento)}
            disableTooltip={isMultipleSelection}
            disabled={isMultipleSelection || downloading}
          >
            <FileDownloadIcon
              htmlColor={(
                !isMultipleSelection
                  ? theme.palette.primary.main
                  : '#00000042'
              )}
              size={20}
            />
          </QCXIconButton>
          <QCXIconButton
            key={`icon-button-documento-mail-${id}`}
            name={`icon-button-documento-mail-${id}`}
            tooltipProps={{
              title: t('com.muralis.qcx.documento.enviarDocumentoEmail'),
            }}
            onClick={configureSendEmailWithSingleDocumentHandlerBy({
              id,
              documento,
              ...restPropsRow,
            })}
            disableTooltip={isMultipleSelection}
            disabled={isMultipleSelection}
          >
            <EmailIcon
              color={(
                !isMultipleSelection
                  ? 'primary'
                  : 'disabled'
              )}
              size={20}
            />
          </QCXIconButton>
        </>
      ),
    }
  ]), [
    model,
    downloading,
    isMultipleSelection,
    configureDownloadSingleDocumentHandlerBy,
    configureSendEmailWithSingleDocumentHandlerBy,
  ]);

  return (
    <QCXCompoundDialog
      key="dialog-manager-follow-up"
      id="dialog-manager-follow-up"
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      disableBackdropClick
      {...restProps}
    >
      <QCXCompoundDialog.Title
        style={{
          padding: '16px 32px 16px 24px',
        }}
      >
        <Grid
          item
          container
          justify="space-between"
        >
          <Grid
            item
            xs={10}
          >
            <Typography
              variant="subtitle2"
              style={{
                color: theme.palette.primary.light,
              }}
            >
              <strong>
                { t('com.muralis.qcx.documento.documentoAdicionadosProcessoNumero', { numero: model?.numero }).toUpperCase() }
              </strong>
            </Typography>
          </Grid>
          <Grid
            item
            container
            justify="flex-end"
            xs={2}
          >
            <QCXIconButton
              style={{
                margin: '-16px -32px 0 0',
                padding: '10px',
                borderRadius: '4px',
              }}
              onClick={handleClose}
              disableTooltip
              disableRipple
            >
              <CloseIcon />
            </QCXIconButton>
          </Grid>
        </Grid>
      </QCXCompoundDialog.Title>
      <QCXCompoundDialog.Content
        style={{
          padding: '16px 32px 16px 24px',
        }}
      >
        <QCXDataGrid
          columns={columns}
          rows={documentoArquivoList}
          selectionModel={selectedDocuments}
          onSelectionModelChange={handleSelectionDocumentsChange}
          checkboxSelection
        />
      </QCXCompoundDialog.Content>
      <QCXCompoundDialog.Actions
        style={{
          padding: isSingleSelection
            ? '0px 40px 16px 24px'
            : '16px 40px 16px 24px',
        }}
      >
        <Grid
          item
          container
          xs={12}
        >
          {isSingleSelection && (
            <Grid
              item
              container
              style={{
                paddingBottom: '16px',
              }}
              xs={12}
            >
              <Grid
                item
                xs={12}
              >
                <QCXInfoAlert>
                  <Trans i18nKey="com.muralis.qcx.documento.selecioneMaisDocumentosBotoesDownloadEnvioMultiplo">
                    <strong />
                  </Trans>
                </QCXInfoAlert>
              </Grid>
            </Grid>
          )}
          <Grid
            item
            container
            style={{
              paddingBottom: '12px',
            }}
            justify="center"
            spacing={1}
            xs={12}
          >
            <Grid
              item
              xs={12}
              sm={3}
              md={2}
              lg={1}
            >
              <QCXButton
                id="button-follow-up-documentos-print"
                key="button-follow-up-documentos-print"
                color="secondary"
                size="small"
                tooltip={!isXsDownScreen}
                tooltipDescription={t('com.muralis.qcx.acoes.imprimir')}
                startIcon={isXsDownScreen && (
                  <PrintIcon />
                )}
                fullWidth
                disabled
              >
                {!isXsDownScreen && (
                  <PrintIcon />
                )}
                {isXsDownScreen && (
                  t('com.muralis.qcx.acoes.imprimir')
                )}
              </QCXButton>
            </Grid>
            <Grid
              item
              xs={12}
              sm={3}
              md={2}
              lg={1}
            >
              <QCXButton
                id="button-follow-up-documentos-download-selection"
                key="button-follow-up-documentos-download-selection"
                color="secondary"
                size="small"
                onClick={handleDownloadMultipleDocumentFromSelection}
                tooltip={!isXsDownScreen}
                tooltipDescription={t('com.muralis.qcx.documento.baixarDocumentosSelecionados')}
                startIcon={isXsDownScreen && (
                  <FileDownloadIcon />
                )}
                fullWidth
                disabled={!isMultipleSelection || downloading}
              >
                {!isXsDownScreen && (
                  <FileDownloadIcon />
                )}
                {isXsDownScreen && (
                  t('com.muralis.qcx.documento.baixarDocumentosSelecionados')
                )}
              </QCXButton>
            </Grid>
            <Grid
              item
              xs={12}
              sm={3}
              md={2}
              lg={1}
            >
              <QCXButton
                id="button-follow-up-documentos-send-email"
                key="button-follow-up-documentos-send-email"
                color="secondary"
                size="small"
                onClick={handleOpenSendEmailWithMultipleDocumentFromSelection}
                tooltip={!isXsDownScreen}
                tooltipDescription={t('com.muralis.qcx.enviarPorEmail')}
                startIcon={isXsDownScreen && (
                  <EmailIcon />
                )}
                fullWidth
                disabled={!isMultipleSelection || downloading}
              >
                {!isXsDownScreen && (
                  <EmailIcon />
                )}
                {isXsDownScreen && (
                  t('com.muralis.qcx.enviarPorEmail')
                )}
              </QCXButton>
            </Grid>
            <Grid
              item
              xs={12}
              sm={3}
              md={2}
              lg={1}
            >
              <QCXButton
                id="button-follow-up-documentos-upload-new-document"
                key="button-follow-up-documentos-upload-new-document"
                color="secondary"
                size="small"
                onClick={handleOpenModalNovoDocumento}
                tooltip={!isXsDownScreen}
                tooltipDescription={t('com.muralis.qcx.documento.adicionarNovoDocumento')}
                startIcon={isXsDownScreen && (
                  <NoteAddIcon />
                )}
                fullWidth
                disabled={isMultipleSelection}
              >
                {!isXsDownScreen && (
                  <NoteAddIcon />
                )}
                {isXsDownScreen && (
                  t('com.muralis.qcx.documento.adicionarNovoDocumento')
                )}
              </QCXButton>
            </Grid>
          </Grid>
        </Grid>
      </QCXCompoundDialog.Actions>
      <QCXNovoDocumentoFollowUpFormDialog
        parentModel={model}
        status={formDialogDocumentoFollowUpStatus}
        handleStatus={handleFormDialogDocumentoFollowUpStatus}
      />
      <QCXEmailDocumentoFollowUpFormDialog
        parentModel={model}
        status={formDialogDocumentoEmailFollowUpStatus}
        handleStatus={handleFormDialogDocumentoEmailFollowUpStatus}
        handleClearSelection={handleClearDocumentsSelection}
      />
    </QCXCompoundDialog>
  );
}
