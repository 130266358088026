import { Autocomplete } from 'mui-rff';
import QCXFinalTextField from '../final-text-field/QCXFinalTextField';

// https://stackoverflow.com/questions/59832331/why-checkbox-is-not-checked-in-react
// https://codesandbox.io/s/cranky-swirles-y9bcj?file=/src/test.js:845-853

export default function QCXProdutoAutocomplete({
  onBlurCallback,
  options,
  name,
  label,
  textFieldProps = {
    variant: 'outlined',
    size: 'small',
    InputLabelProps: {
      shrink: true,
      style: {
        textTransform: 'uppercase',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    },
  },
  getOptionValue = (item) => item?.partnumber || '',
  getOptionLabel = (item) => item?.description || '',
  initialValues,
  handleChange,
  form,
  values,
  fieldProps,
  required,
  inputValue,
  handleSetInputValue,
  ...restProps
}) {
  return (
    <Autocomplete
      name={name}
      label={label}
      options={options}
      freeSolo
      renderInput={(params) => (
        <QCXFinalTextField
          {...params}
          {...textFieldProps}
          name="partNumberSelector"
          label={label}
          fieldProps={fieldProps}
          required={required}
        />
      )}
      inputValue={inputValue}
      onInputChange={handleSetInputValue}
      onBlur={() => onBlurCallback(inputValue)}
      textFieldProps={textFieldProps}
      getOptionValue={getOptionValue}
      getOptionLabel={getOptionLabel}
      renderOption={(option) => (
        <>
          {getOptionLabel(option)}
        </>
      )}
      fieldProps={fieldProps}
      {...restProps}
    />

  );
}
