import { getOptionByValueInList } from '../../utils/general/general-utils';
import i18n from '../../i18n';

const COBERTURA_CAMBIAL_SEM_COBERTURA = 'SEM_COBERTURA';
const COBERTURA_CAMBIAL_COM_COBERTURA_ATE_180_DIAS = 'COM_COBERTURA_ATE_180_DIAS';
const COBERTURA_CAMBIAL_COM_COBERTURA_180_360_DIAS = 'COM_COBERTURA_180_360_DIAS';
const COBERTURA_CAMBIAL_COM_COBERTURA_ACIMA_360_DIAS = 'COM_COBERTURA_ACIMA_360_DIAS';

const COBERTURA_CAMBIAL_LIST = [
  {
    label: i18n.t('com.muralis.qcx.cobertura.semCobertura').toUpperCase(),
    value: COBERTURA_CAMBIAL_SEM_COBERTURA,
  },
  {
    label: i18n.t('com.muralis.qcx.cobertura.comCobertura180Dias').toUpperCase(),
    value: COBERTURA_CAMBIAL_COM_COBERTURA_ATE_180_DIAS,
  },
  {
    label: i18n.t('com.muralis.qcx.cobertura.comCobertura180360Dias').toUpperCase(),
    value: COBERTURA_CAMBIAL_COM_COBERTURA_180_360_DIAS,
  },
  {
    label: i18n.t('com.muralis.qcx.cobertura.comCoberturaAcima360Dias').toUpperCase(),
    value: COBERTURA_CAMBIAL_COM_COBERTURA_ACIMA_360_DIAS,
  },
];

const isCoberturaCambialSemCobertura = (value) => (
  COBERTURA_CAMBIAL_SEM_COBERTURA === value
);

const isCoberturaCambialComCoberturaAte180Dias = (value) => (
  COBERTURA_CAMBIAL_COM_COBERTURA_ATE_180_DIAS === value
);

const isCoberturaCambialComCoberturaDe180A360Dias = (value) => (
  COBERTURA_CAMBIAL_COM_COBERTURA_180_360_DIAS === value
);

const isCoberturaCambialComCoberturaAcima360Dias = (value) => (
  COBERTURA_CAMBIAL_COM_COBERTURA_ACIMA_360_DIAS === value
);

const isSomeCoberturaCambial = (value, valuesToCompare = []) => (
  valuesToCompare.includes(value)
);

const getCoberturaCambialLabelByValue = (value) => {
  const foundOption = getOptionByValueInList(COBERTURA_CAMBIAL_LIST, value);
  return foundOption && foundOption?.label;
};

export {
  COBERTURA_CAMBIAL_SEM_COBERTURA,
  COBERTURA_CAMBIAL_COM_COBERTURA_ATE_180_DIAS,
  COBERTURA_CAMBIAL_COM_COBERTURA_180_360_DIAS,
  COBERTURA_CAMBIAL_COM_COBERTURA_ACIMA_360_DIAS,
  COBERTURA_CAMBIAL_LIST,
};

export {
  isCoberturaCambialSemCobertura,
  isCoberturaCambialComCoberturaAte180Dias,
  isCoberturaCambialComCoberturaDe180A360Dias,
  isCoberturaCambialComCoberturaAcima360Dias,
  isSomeCoberturaCambial,
  getCoberturaCambialLabelByValue,
};
