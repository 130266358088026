import { isEmpty } from 'lodash';
import debounce from 'lodash/debounce';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { register, save } from '../../../../../features/cotacao-moeda/cotacaoMoedaAPI';
import {
  addToList,
  changeToBackgroundCreateMode,
  changeToConsultMode,
  changeToCreateMode,
  changeToUpdateMode,
  failure,
  fetchByIdAsync,
  loading,
  preparingAction,
  resetBackgroundMode,
  resetModel,
  selectBackgroundMode,
  selectCotacaoMoeda,
  selectMode,
  selectStatus,
  setError,
  setModel,
  setResponse,
  success,
  updateOnList,
} from '../../../../../features/cotacao-moeda/cotacaoMoedaSlice';
import QCXCotacaoMoedaForm from '../../../../../components/cotacao-moeda/QCXCotacaoMoedaForm';
import QCXRegistrationFormPageTemplate from '../../../../../templates/registration-form-page/QCXRegistrationFormPageTemplate';
import {
  isBackgroundCreateMode,
  isConsultMode,
  isCreateMode,
  isNoneMode,
  isPreparingActionStatus,
  isUpdateMode,
} from '../../../../../utils/store/store-utils';
import { normalizeNumeral, unnormalizeNumeral } from '../../../../../utils/general/general-utils';
import { formatBrazilianNumericDecimal } from '../../../../../utils/hooks/form/field/formatters';

export default function CotacaoMoedaRegistrationPage({ authInfo = {} }) {
  const { t } = useTranslation();

  const { id } = useParams();

  const history = useHistory();

  const dispatch = useDispatch();

  const status = useSelector(selectStatus);
  const mode = useSelector(selectMode);
  const backgroundMode = useSelector(selectBackgroundMode);
  const cotacaoMoeda = useSelector(selectCotacaoMoeda);

  const isNone = useMemo(() => (
    isNoneMode(mode)
  ), [mode]);

  const isCreate = useMemo(() => (
    isCreateMode(mode)
  ), [mode]);

  const isBackgroundCreate = useMemo(() => (
    isBackgroundCreateMode(backgroundMode)
  ), [backgroundMode]);

  const isConsult = useMemo(() => (
    isConsultMode(mode)
  ), [mode]);

  const isUpdate = useMemo(() => (
    isUpdateMode(mode)
  ), [mode]);

  const isPreparingAction = useMemo(() => (
    isPreparingActionStatus(status)
  ), [status]);

  const handleChangeToPreparingAction = () => {
    dispatch(preparingAction());
  };

  const handleChangeToCreate = () => {
    dispatch(changeToCreateMode());
  };

  const handleChangeToBackgroundCreate = () => {
    dispatch(changeToBackgroundCreateMode());
  };

  const handleChangeToConsult = () => {
    dispatch(changeToConsultMode());
  };

  const handleChangeToUpdate = () => {
    dispatch(changeToUpdateMode());
  };

  const handleResetBackgroundMode = () => {
    dispatch(resetBackgroundMode());
  };

  useEffect(() => {
    if (!isEmpty(id)) {
      dispatch(fetchByIdAsync(id));
      handleChangeToConsult();
    }
  }, [id]);

  const handleCancelUpdate = () => {
    const currentId = isBackgroundCreate ? cotacaoMoeda?.id : id;

    if (currentId) {
      dispatch(fetchByIdAsync(currentId));
    }
    handleChangeToConsult();
  };

  const normalize = useCallback((unnormalizeData) => {
    const unnormalizedData = {
      ...unnormalizeData,
      cambio: normalizeNumeral(
        unnormalizeData?.cambio
      ),
      percentualMarkup: normalizeNumeral(
        unnormalizeData?.percentualMarkup
      ),
    };

    return unnormalizedData;
  }, []);

  const unnormalize = useCallback((normalizedData) => {
    const unnormalizedData = {
      ...normalizedData,
      cambio: unnormalizeNumeral(
        normalizedData?.cambio,
        formatBrazilianNumericDecimal(5)
      ),
      percentualMarkup: unnormalizeNumeral(
        normalizedData?.percentualMarkup,
        formatBrazilianNumericDecimal(2)
      ),
      taxaAtualizada: unnormalizeNumeral(
        normalizedData?.cambio + (
          (normalizedData?.cambio * normalizedData?.percentualMarkup) / 100
        ),
        formatBrazilianNumericDecimal(5)
      ),
    };

    return unnormalizedData;
  }, []);

  const handleDispatchSetModel = useCallback((data) => {
    dispatch(setModel(normalize(data)));
  }, [normalize]);

  const create = async (data) => {
    const executeDebounced = debounce(async () => {
      try {
        const response = await register(data);
        if (response?.status === 201) {
          dispatch(resetModel());

          const handleResultWithDebounce = debounce(() => {
            history.push(t('com.muralis.qcx.url.modulosFinanceirosCotacoesMoeda'));

            dispatch(success());

            const created = response?.data;

            dispatch(setResponse({
              status: response.status,
              data: created,
              message: t('com.muralis.qcx.mensagem.cotacaoMoedaCadastrada', { id: created?.id }),
            }));

            dispatch(addToList({ data: created }));
          }, 500);

          handleResultWithDebounce();
        }
      } catch ({ response }) {
        dispatch(failure());
        dispatch(setError({
          message: t('com.muralis.qcx.erro.erroCadastrarCotacaoMoeda', { erro: response.data.message }),
        }));
      }
    }, 500);

    dispatch(loading());
    executeDebounced();
  };

  const update = async (data) => {
    const executeDebounced = debounce(async () => {
      try {
        const response = await save(data);

        if (response?.status === 200) {
          const handleResultWithDebounce = debounce(() => {
            handleChangeToConsult();
            dispatch(success());

            const saved = response?.data;

            dispatch(setResponse({
              status: response.status,
              data: saved,
              message: t('com.muralis.qcx.mensagem.cotacaoMoedaAlteracoesSalvas', { id: saved?.id }),
            }));

            dispatch(setModel(saved));
            dispatch(updateOnList({ data: saved }));
          }, 500);

          handleResultWithDebounce();
        }
      } catch ({ response }) {
        dispatch(failure());
        dispatch(setError({
          message: t('com.muralis.qcx.erro.erroSalvarAlteracoesCotacaoMoeda', { id: data?.id, erro: response.data.message }),
        }));
      }
    }, 500);

    dispatch(loading());
    executeDebounced();
  };

  const handleSubmit = async (data) => {
    const normalizedData = normalize(data);

    if (isUpdate && !isBackgroundCreate) {
      await update(normalizedData);
    } else {
      await create(normalizedData);
    }
  };

  const pageTitle = useMemo(() => (
    isNone || isCreate || isBackgroundCreate
      ? t('com.muralis.qcx.cotacaoMoeda.novaCotacaoMoeda')
      : t('com.muralis.qcx.cotacaoMoeda.cotacaoMoedaExistente', { ID: cotacaoMoeda?.description || cotacaoMoeda?.id || '-' })
  ), [isNone, isCreate, cotacaoMoeda]);

  const model = useMemo(() => (
    !isCreate
      ? unnormalize(cotacaoMoeda)
      : {}
  ), [isCreate, cotacaoMoeda, unnormalize]);

  const actionName = useMemo(() => {
    if (isCreate || isBackgroundCreate || isNone) return t('com.muralis.qcx.acoes.nova');
    if (isConsult) return t('com.muralis.qcx.acoes.visualizar');
    return t('com.muralis.qcx.acoes.alterar');
  }, [isNone, isCreate, isConsult]);

  const breadcrumbs = useMemo(() => ([
    {
      link: {
        to: '/',
        name: t('com.muralis.qcx.inicio'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.cadastros'),
      },
    },
    {
      link: {
        to: t('com.muralis.qcx.url.moduloCadastrosFinanceiros'),
        name: t('com.muralis.qcx.financeiro.labelPlural'),
      },
    },
    {
      link: {
        to: t('com.muralis.qcx.url.modulosFinanceirosCotacoesMoeda'),
        name: t('com.muralis.qcx.cotacaoMoeda.labelPlural'),
      },
    },
    {
      text: {
        name: actionName,
      },
    },
  ]), [actionName]);

  return (
    <QCXRegistrationFormPageTemplate
      pageTitle={pageTitle}
      breadcrumbs={breadcrumbs}
      isCreate={isCreate}
      isConsult={isConsult}
      isUpdate={isUpdate}
      isBackgroundCreate={isBackgroundCreate}
      isPreparingAction={isPreparingAction}
      handleChangeToPreparingAction={handleChangeToPreparingAction}
      handleChangeToCreate={handleChangeToCreate}
      handleChangeToBackgroundCreate={handleChangeToBackgroundCreate}
      handleChangeToConsult={handleChangeToConsult}
      handleChangeToUpdate={handleChangeToUpdate}
      handleCancelUpdate={handleCancelUpdate}
      handleResetBackgroundMode={handleResetBackgroundMode}
      authInfo={authInfo}
    >
      {
        (formProps) => (
          <QCXCotacaoMoedaForm
            handleSubmit={handleSubmit}
            handleChangeModel={handleDispatchSetModel}
            initialValues={model}
            authInfo={authInfo}
            requiredRoles={['cotacao']}
            {...formProps}
          />
        )
      }
    </QCXRegistrationFormPageTemplate>
  );
}
