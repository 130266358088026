import { Grid } from '@material-ui/core';
import QCXFinalDateTimePickerField from '../../../../../../shared-components/final-date-time-picker-field/QCXFinalDateTimePickerField';
import QCXSelectEtapaAutocomplete from '../../../../../../shared-components/select-etapa/QCXSelectEtapaAutocomplete';
import QCXFinalMultilineInputWithChips from '../QCXFinalMultilineInputWIthChips';
import MultiSelectTiposDocumentos from '../UploadTabSection/MultiSelectTiposDocumentos';
import QCXSelectClienteAutocomplete from '../../../../../../shared-components/final-select-cliente-field/QCXSelectClienteAutocomplete';

function DownloadDocumentosSection() {
  return (
    <>
      <Grid container xs={12} spacing={2}>
        <Grid item xs={12}>
          <QCXFinalMultilineInputWithChips name="processos" label="Números dos Processos:" />
        </Grid>
        <Grid item xs={12}>
          <QCXSelectClienteAutocomplete
            name="cliente"
            label="Cliente"
            key="select-clientes"
            id="select-clientes"
            required={false}
            initialValues={undefined}
            filter={undefined}
          />
        </Grid>
        <Grid item container xs={12} spacing={2}>
          <Grid item xs={6}>
            <QCXSelectEtapaAutocomplete
              id="select-etapa-field"
              key="select-etapa-field"
              name="etapa"
              label="Etapa"
              disabled={false}
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <QCXFinalDateTimePickerField
              id="date-picker-data-previsao-field"
              key="date-picker-data-previsao-field"
              name="dataInicio"
              label="Inicial"
              disabled={false}
            />
          </Grid>
          <Grid item xs={3}>
            <QCXFinalDateTimePickerField
              id="date-picker-data-previsao-field"
              key="date-picker-data-previsao-field"
              name="dataFim"
              label="Final"
              disabled={false}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <MultiSelectTiposDocumentos label="Tipos de Documentos" name="tiposDocumentos" />
        </Grid>
      </Grid>
    </>
  );
}

export default DownloadDocumentosSection;
