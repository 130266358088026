import { Grid } from '@material-ui/core';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import QCXFinalCheckboxField from '../../shared-components/final-checkbox-field/QCXFinalCheckboxField';
import QCXFinalForm from '../../shared-components/final-form/QCXFinalForm';
import QCXFinalMultilineTextField from '../../shared-components/final-text-field/QCXFinalMultilineTextField';
import QCXFinalTextField from '../../shared-components/final-text-field/QCXFinalTextField';
import { required as requiredValidator } from '../../utils/validators/field/validator';
import QCXFinalSelectTipoProdutoAutocomplete from '../../shared-components/select-tipo-produto/QCXFinalSelectTipoProdutoAutocomplete';

export default function QCXModeloInformacoesComplementaresForm({
  initialValues,
  handleSubmit,
  handleChangeToConsult,
  handleChangeToUpdate,
  handleCancelUpdate,
  isConsult,
  isUpdate,
  ...restProps
}) {
  const { t } = useTranslation();
  const isConsultOrUpdateMode = useMemo(() => (
    (isConsult || isUpdate)
  ), [isConsult, isUpdate]);

  const handleComplementoChange = (form) => (value) => {
    if (!isEmpty(value)) {
      const blockedCharactersPattern = (
        /[\u0300-\u036f\u00b4\u0060\u0026\u0023\u007e\u005e\u0022\u0025\u0027\u0040\u00a1-\u00bf]/g
      );
      const parsed = String(value)
        .normalize('NFD')
        .replace(blockedCharactersPattern, '');

      form.change('complemento', parsed);
    }
  };

  const listenersList = useMemo(() => ([
    {
      name: 'complemento',
      fn: handleComplementoChange,
    },
  ]), [handleComplementoChange]);

  return (
    <>
      <QCXFinalForm
        isConsult={isConsult}
        isUpdate={isUpdate}
        initialValues={initialValues}
        handleSubmitValues={handleSubmit}
        handleChangeToUpdate={handleChangeToUpdate}
        handleCancelUpdate={handleCancelUpdate}
        onChangeListeners={listenersList}
        {...restProps}
      >
        {({ values }) => (
          <>
            {isConsultOrUpdateMode && (
              <Grid item xs={12} sm={4} md={2}>
                <QCXFinalTextField
                  id="text-field-code"
                  key="text-field-code"
                  name="id"
                  label={t('com.muralis.qcx.codigo')}
                  disabled
                />
              </Grid>
            )}
            <Grid item xs={12} sm={8} md={isConsultOrUpdateMode ? 7 : 8}>
              <QCXFinalTextField
                id="text-field-nome"
                key="text-field-nome"
                name="description"
                label={t('com.muralis.qcx.nome')}
                maxLength={50}
                disabled={isConsult}
                validate={requiredValidator}
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={isConsultOrUpdateMode ? 3 : 4}>
              <QCXFinalSelectTipoProdutoAutocomplete
                id="select-field-uso"
                key="select-field-uso"
                name="uso"
                label={t('com.muralis.qcx.uso')}
                fieldProps={{ validate: requiredValidator }}
                initialValues={values}
                disabled={isConsult}
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} xl={3}>
              <QCXFinalCheckboxField
                id="checkbox-field-referencia-cliente"
                key="checkbox-field-referencia-cliente"
                name="referenciaCliente"
                label={t('com.muralis.qcx.referenciaCliente')}
                disabled={isConsult}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} xl={3}>
              <QCXFinalCheckboxField
                id="checkbox-field-transportadora"
                key="checkbox-field-transportadora"
                name="transportadora"
                label={t('com.muralis.qcx.transportadora.label')}
                disabled={isConsult}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} xl={3}>
              <QCXFinalCheckboxField
                id="checkbox-field-quadro-resumo-adicoes"
                key="checkbox-field-quadro-resumo-adicoes"
                name="quadroResumoAdicoes"
                label={t('com.muralis.qcx.quadroResumoAdicoes')}
                disabled={isConsult}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} xl={3}>
              <QCXFinalCheckboxField
                id="checkbox-field-recinto-alfandegado"
                key="checkbox-field-recinto-alfandegado"
                name="recintoAlfandegado"
                label={t('com.muralis.qcx.recintoAlfandegado')}
                disabled={isConsult}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} xl={3}>
              <QCXFinalCheckboxField
                id="checkbox-field-fundamento-legal-ii"
                key="checkbox-field-fundamento-legal-ii"
                name="fundamentoLegalIi"
                label={t('com.muralis.qcx.legislacao.fundamentoLegalII')}
                disabled={isConsult}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} xl={3}>
              <QCXFinalCheckboxField
                id="checkbox-field-fundamento-legal-ipi"
                key="checkbox-field-fundamento-legal-ipi"
                name="fundamentoLegalIpi"
                label={t('com.muralis.qcx.legislacao.fundamentoLegalIPI')}
                disabled={isConsult}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} xl={3}>
              <QCXFinalCheckboxField
                id="checkbox-field-fundamento-legal-icms"
                key="checkbox-field-fundamento-legal-icms"
                name="fundamentoLegalIcms"
                label={t('com.muralis.qcx.legislacao.fundamentoLegalICMS')}
                disabled={isConsult}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} xl={3}>
              <QCXFinalCheckboxField
                id="checkbox-field-despachantes-aduaneiros"
                key="checkbox-field-despachantes-aduaneiros"
                name="despachantesAduaneiros"
                label={t('com.muralis.qcx.despachante.despachantesAduaneiros')}
                disabled={isConsult}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <QCXFinalMultilineTextField
                id="multiline-text-field-complemento"
                key="multiline-text-field-complemento"
                name="complemento"
                label={t('com.muralis.qcx.endereco.complemento')}
                disabled={isConsult}
                rows={5}
                maxLength={3900}
                helperText={t('com.muralis.qcx.validacao.caracteresEspeciaisNaoPermitidos')}
              />
            </Grid>
          </>
        )}
      </QCXFinalForm>
    </>
  );
}
