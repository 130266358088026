import { useCallback, useMemo } from 'react';

export default function useIpiListenersGroup(isConsult) {
  const handleNaoTributavelIpiChange = useCallback((form) => (value) => {
    if (value) {
      form.change('ipi.regimeTributacao', undefined);
      form.change('ipi.baseCalculoReais', undefined);
      form.change('ipi.notaComplementarTipi', undefined);
      form.change('ipi.aliquota', undefined);
      form.change('ipi.reduzida', undefined);
      form.change('ipi.valorUnidadeMedidaReais', undefined);
      form.change('ipi.tipoRecipiente', undefined);
      form.change('ipi.unidadeDeMedida', undefined);
      form.change('ipi.unidadeDeMedida', undefined);
      form.change('ipi.quantidade', undefined);
      form.change('ipi.capacidade', undefined);
    }
  }, [isConsult]);

  const changeListeners = useMemo(() => ([
    {
      name: 'ipi.ipiNaoTributavel',
      fn: handleNaoTributavelIpiChange,
    },
  ]), [
    handleNaoTributavelIpiChange,
  ]);

  return changeListeners;
}
