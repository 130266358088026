import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ContasReceberContext from '../../../../contexts/financial/contas-receber/ContasReceberContext';
import {
  clearError,
  clearResponse,
  resetStatus,
} from '../../../../features/contas-receber/contasReceberSlice';
import {
  selectError,
  selectResponse,
  selectStatus,
} from '../../../../features/contas-receber/contasReceberSelectors';
import { FAILURE_STATUS, LOADING_STATUS, SUCCESS_STATUS } from '../../../../features/status';
import QCXPageTemplate from '../../../../templates/page/QCXPageTemplate';

export default function ContasReceberPage({ children }) {
  const dispatch = useDispatch();
  const status = useSelector(selectStatus);
  const error = useSelector(selectError);
  const response = useSelector(selectResponse);

  const isLoading = useMemo(() => (
    status === LOADING_STATUS
  ), [status]);

  const [successSnackbar, setSuccessSnackbar] = useState({
    open: false,
    message: '',
  });

  const [errorSnackbar, setErrorSnackbar] = useState({
    open: false,
    message: '',
  });

  const handleOpenSuccessSnackbar = useCallback((message) => {
    setSuccessSnackbar({
      open: true,
      message,
    });
  }, []);

  const handleOpenErrorSnackbar = useCallback((message) => {
    setErrorSnackbar({
      open: true,
      message,
    });
  }, []);

  const handleCloseSuccessSnackbar = useCallback(() => {
    setSuccessSnackbar({
      ...successSnackbar,
      open: false,
    });
  }, []);

  const handleCloseErrorSnackbar = useCallback(() => {
    setErrorSnackbar({
      ...errorSnackbar,
      open: false,
    });
  }, []);

  useEffect(() => {
    if (status === FAILURE_STATUS && error && error.message) {
      handleOpenErrorSnackbar(error.message);
      dispatch(resetStatus());
      dispatch(clearError());
    } else if (status === SUCCESS_STATUS && response && response.message) {
      handleOpenSuccessSnackbar(response.message);
      dispatch(resetStatus());
      dispatch(clearResponse());
    }
  }, [response, status, error]);

  const providerData = useMemo(() => ({
    status,
    isLoading,
    successSnackbar,
    errorSnackbar,
    handleCloseSuccessSnackbar,
    handleCloseErrorSnackbar,
  }), [
    status,
    isLoading,
    successSnackbar,
    errorSnackbar,
  ]);

  return (
    <ContasReceberContext.Provider value={providerData}>
      <QCXPageTemplate contextComponent={ContasReceberContext}>
        {children}
      </QCXPageTemplate>
    </ContasReceberContext.Provider>
  );
}
