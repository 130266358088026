import { useTheme } from '@material-ui/core';
import { Box, Grid } from '@mui/material';
import ModalResponseStatus from '../../../planilhas/components/modalResponseStatus';

export type TotvsResponse = {
  faturas: {
    faturaPO: string;
    status: boolean;
    faturaMessage: string;
  }[];
  itens: {
    partNumber: string;
    status: boolean;
    itemMessage: string | { code: string; column: string };
  }[];
  joins: {
    status: boolean;
    faturaId: number;
    mercadoraId: number;
    joinMessage: string;
  }[];
  error: any;
};

const TotvsLeituraPageResult = (props: {
  response: TotvsResponse | undefined;
}) => {
  const theme = useTheme();
  return (
    <Grid item xs={12}>
      <Box
        border={`1px solid ${theme.palette.primary.main}`}
        borderRadius={2}
        padding={2}
        lineHeight={0.5}
      >
        {props.response?.faturas.map((fatura) => (
          <ModalResponseStatus
            status={fatura.status}
            name={fatura.faturaPO}
            message={fatura.faturaMessage}
          />
        ))}
        {props.response?.itens.map((item) => {
          const itemMessage =
            typeof item.itemMessage === 'string'
              ? item.itemMessage
              : 'Erro na coluna: ' + item.itemMessage.column;
          return (
            <ModalResponseStatus
              status={item.status}
              name={item.partNumber}
              message={itemMessage}
            />
          );
        })}

        {props.response?.joins.map((join) => {
          if (!join.status) {
            return (
              <ModalResponseStatus
                status={false}
                name={'Erro de join'}
                message={
                  'Erro ao criar fatura/mercadoria. Por favor, contate o suporte'
                }
              />
            );
          }
        })}
      </Box>
    </Grid>
  );
};

export default TotvsLeituraPageResult;
