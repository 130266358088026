import { Grid } from '@material-ui/core';
import React, { useCallback, useMemo } from 'react';
import { v4 as uuid } from 'uuid';
import { useTranslation } from 'react-i18next';
import QCXFinalBondManagerOld from '../../shared-components/final-gerenciador-relacionamento/QCXFinalBondManagerOld';
import QCXFinalTextField from '../../shared-components/final-text-field/QCXFinalTextField';
import QCXFinalAlternativeCurrencyField from '../../shared-components/final-currency-field/QCXFinalAlternativeCurrencyField';
import {
  vinculoDocumentoImportacaoDUEActions,
  vinculoDocumentoImportacaoDUESelectors,
} from '../../features/vinculo-documento-importacao-due/vinculoDocumentoImportacaoDUESlice';
import { isStrictEquals } from '../../utils/general/general-utils';
import QCXFinalNumericDecimalField from '../../shared-components/final-numeric-decimal-field/QCXFinalNumericDecimalField';

export default function QCXDocumentoImportacaoFormGroup({ isParentConsult }) {
  const { t } = useTranslation();

  const documentoImportacaoListProps = useMemo(
    () => ({
      name: 'ignorableFields.atoConcessorio.documentosImportacao',
      columns: [
        {
          field: 'di',
          headerName: t('com.muralis.qcx.DIDUIMP.DI'),
          headerAlign: 'center',
          align: 'center',
          flex: 100,
          valueGetter: ({ row }) => row?.di || '-',
        },
        {
          field: 'adicao',
          headerName: t('com.muralis.qcx.adicao.label'),
          headerAlign: 'center',
          align: 'center',
          flex: 100,
          valueGetter: ({ row }) => row?.adicao || '-',
        },
        {
          field: 'quantidade',
          headerName: t('com.muralis.qcx.quantidade.label'),
          headerAlign: 'center',
          align: 'center',
          flex: 100,
          valueGetter: ({ row }) => row?.quantidade || '-',
        },
        {
          field: 'valor',
          headerName: t('com.muralis.qcx.valor'),
          headerAlign: 'right',
          align: 'right',
          flex: 100,
          valueGetter: ({ row }) => row?.valor || '-',
        },
      ],
    }),
    []
  );

  const documentoImportacaoFormProps = useMemo(
    () => ({
      rootName: 'ignorableFields.documentoImportacaoAC',
      fields: [
        {
          name: 'di',
          label: t('com.muralis.qcx.DIDUIMP.DI'),
        },
        {
          name: 'adicao',
          label: t('com.muralis.qcx.adicao.label'),
        },
        {
          name: 'quantidade',
          label: t('com.muralis.qcx.quantidade.label'),
        },
        {
          name: 'valor',
          label: t('com.muralis.qcx.valor'),
        },
      ],
    }),
    []
  );

  const documentoImportacaoReducerConfig = useMemo(
    () => ({
      selectors: {
        selectStatus: vinculoDocumentoImportacaoDUESelectors.selectStatus,
        selectMode: vinculoDocumentoImportacaoDUESelectors.selectMode,
        selectModel: vinculoDocumentoImportacaoDUESelectors.selectModel,
      },
      actions: {
        loading: vinculoDocumentoImportacaoDUEActions.loading,
        resetStatus: vinculoDocumentoImportacaoDUEActions.resetStatus,
        changeToUpdateMode: vinculoDocumentoImportacaoDUEActions.changeToUpdateMode,
        changeToCreateMode: vinculoDocumentoImportacaoDUEActions.changeToCreateMode,
        resetMode: vinculoDocumentoImportacaoDUEActions.resetMode,
        setModel: vinculoDocumentoImportacaoDUEActions.setModel,
        resetModel: vinculoDocumentoImportacaoDUEActions.resetModel,
      },
    }),
    []
  );

  const handleAddDocumentoImportacao = useCallback(async (currentVinculo, currentVinculos, handleSuccessAddVinculo) => {
    const vinculo = {
      id: uuid(),
      di: currentVinculo?.di,
      adicao: currentVinculo?.adicao,
      quantidade: currentVinculo?.quantidade,
      valor: currentVinculo?.valor,
    };

    const updatedVinculos = [...currentVinculos, vinculo];

    handleSuccessAddVinculo(updatedVinculos);
  }, []);

  const handleUpdateDocumentoImportacao = useCallback(
    async (currentVinculo, currentVinculos, handleSuccessUpdateVinculo) => {
      const updatedVinculo = {
        ...currentVinculo,
        di: currentVinculo?.di,
        adicao: currentVinculo?.adicao,
        quantidade: currentVinculo?.quantidade,
        valor: currentVinculo?.valor,
      };

      const updatedVinculos =
        currentVinculos?.map((existingVinculo) =>
          isStrictEquals(existingVinculo?.id, updatedVinculo?.id) ? updatedVinculo : existingVinculo
        ) || currentVinculos;

      handleSuccessUpdateVinculo(updatedVinculos);
    },
    []
  );

  const handleDocumentoImportacaoAlreadyExists = useCallback(
    (currentVinculo, currentVinculosList) =>
      currentVinculosList.some(
        (vinculo) =>
          isStrictEquals(vinculo?.di, currentVinculo?.di) &&
          isStrictEquals(vinculo?.adicao, currentVinculo?.adicao) &&
          isStrictEquals(vinculo?.quantidade, currentVinculo?.quantidade) &&
          isStrictEquals(vinculo?.valor, currentVinculo?.valor)
      ),
    []
  );

  const dataGridDivProps = useMemo(
    () => ({
      style: {
        paddingTop: '16px',
      },
    }),
    []
  );

  const documentoImportacaoDecorator = useCallback((tools) => {
    const finalBondManagerForm = tools?.formUtils?.form;

    finalBondManagerForm.change('ignorableFields.documentoImportacaoAC.quantidade', '');
    finalBondManagerForm.change('ignorableFields.documentoImportacaoAC.valor', '');
  }, []);

  return (
    <>
      <QCXFinalBondManagerOld
        isParentConsult={isParentConsult}
        listProps={documentoImportacaoListProps}
        formProps={documentoImportacaoFormProps}
        reducerConfig={documentoImportacaoReducerConfig}
        handleAdd={handleAddDocumentoImportacao}
        handleUpdate={handleUpdateDocumentoImportacao}
        handleAlreadyExists={handleDocumentoImportacaoAlreadyExists}
        dataGridDivProps={dataGridDivProps}
        {...{
          onClearDecorator: documentoImportacaoDecorator,
          onAddDecorator: documentoImportacaoDecorator,
        }}
      >
        {({ loading }) => (
          <>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <QCXFinalTextField
                id="text-field-documento-importacao"
                key="text-field-documento-importacao"
                name="ignorableFields.documentoImportacaoAC.di"
                label={t('com.muralis.qcx.DIDUIMP.DI')}
                disabled={loading}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <QCXFinalTextField
                id="text-field-adicao-documento-importacao"
                key="text-field-adicao-documento-importacao"
                name="ignorableFields.documentoImportacaoAC.adicao"
                label={t('com.muralis.qcx.adicao.label')}
                disabled={loading}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <QCXFinalNumericDecimalField
                id="numeric-quantidade-documento-importacao"
                key="numeric-quantidade-documento-importacao"
                name="ignorableFields.documentoImportacaoAC.quantidade"
                label={t('com.muralis.qcx.quantidade.label')}
                disabled={loading}
                scale={5}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <QCXFinalAlternativeCurrencyField
                id="currency-valor-documento-importacao"
                key="currency-valor-documento-importacao"
                name="ignorableFields.documentoImportacaoAC.valor"
                label={t('com.muralis.qcx.valor')}
                disabled={loading}
              />
            </Grid>
          </>
        )}
      </QCXFinalBondManagerOld>
    </>
  );
}
