import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { focusLockActions, focusLockSelectors } from '../../features/focus-lock/focusLockSlice';
import QCXSnackbarInfo from '../../shared-components/snackbar/QCXSnackbarInfo';

export default function QCXShortcutFeedback({
  ...restProps
}) {
  const dispatch = useDispatch();

  const focusLockFeedback = useSelector(
    focusLockSelectors.selectFeedback
  );

  const [snackbarInfo, setSnackbarInfo] = useState({
    open: false,
    message: '',
  });

  const handleOpenSnackbarInfo = useCallback((message) => {
    setSnackbarInfo({
      open: true,
      message,
    });
  }, []);

  const handleCloseSnackbarInfo = useCallback(() => {
    setSnackbarInfo({
      ...snackbarInfo,
      open: false,
      message: '',
    });
  }, []);

  useEffect(() => {
    const handleFocusLockFeedback = () => {
      if (focusLockFeedback?.show && focusLockFeedback?.message) {
        handleOpenSnackbarInfo(focusLockFeedback?.message);
        dispatch(focusLockActions.resetFeedback());
      }
    };
    handleFocusLockFeedback();
  }, [
    focusLockFeedback,
    handleOpenSnackbarInfo,
    handleCloseSnackbarInfo,
  ]);

  return (
    <QCXSnackbarInfo
      open={snackbarInfo?.open}
      content={snackbarInfo?.message}
      handleClose={handleCloseSnackbarInfo}
      autoHideDuration={snackbarInfo?.autoHideDuration || 5000}
      {...snackbarInfo}
      {...restProps}
    />
  );
}
