import { Grid } from '@material-ui/core';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectStatus,
} from '../../../../../../../features/unidade-negocio/unidadeNegocioSlice';
import QCXSimpleSelectManyPageTemplate from '../../../../../../../templates/simple-select-many-page/QCXSimpleSelectManyPageTemplate';
import { isAlternativeLoadingStatus } from '../../../../../../../utils/store/store-utils';
import { fetchAllAsync, selectCamposAdicionais } from '../../../campos-adicionais/api/camposAdicionaisSlice';

export default function QCXSelectManyCamposAdicionaisTable({ ...props }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const camposAdicionais = useSelector(selectCamposAdicionais);
  const status = useSelector(selectStatus);

  const {
    title = 'Selecione os Campos Adicionais',
    filterProp = 'id',
    filterPropGetter = (data) => (
      data?.pessoa?.nomeResumido
    ),
    columns = [
      {
        field: 'id',
        headerName: t('com.muralis.qcx.codigo'),
        headerAlign: 'center',
        align: 'center',
        flex: 50,
      },
      {
        field: 'description',
        headerName: 'Description',
        flex: 300,
        valueGetter: ({ row }) => (
          row?.description || ''
        ),
      },
      {
        field: 'active', headerName: t('com.muralis.qcx.estado.ativo'), type: 'boolean', flex: 50,
      },
    ],
    selected,
    handleChange,
    isConsult,
    ...restProps
  } = props;

  const isAlternativeLoading = useMemo(() => (
    isAlternativeLoadingStatus(status)
  ), [status]);

  useEffect(() => {
    dispatch(fetchAllAsync());
  }, []);

  const filterProps = [
    'id',
    'description',
  ];

  return (
    <>
      <Grid container direction="row" style={{ marginTop: '0.7rem' }}>
        <QCXSimpleSelectManyPageTemplate
          title={title}
          tableColumns={columns}
          tableData={camposAdicionais}
          filterProp={filterProp}
          filterPropGetter={filterPropGetter}
          isLoading={isAlternativeLoading}
          isConsult={isConsult}
          handleSetSelected={handleChange}
          selected={selected}
          multipleFilter
          filterProps={filterProps}
          {...restProps}
        />
      </Grid>
    </>
  );
}
