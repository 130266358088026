import { createFilterOptions } from '@material-ui/lab/useAutocomplete';
import _, { isArray, isFunction } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormState } from 'react-final-form';
import { fetchAllAsync, selectEtapas } from '../../features/etapa/etapaSlice';
import QCXAutocompleteSelect from '../autocomplete-select/QCXAutocompleteSelect';

export default function QCXSelectEtapaAutocomplete({ label, name, filterPredicate = undefined, ...restProps }) {
  const dispatch = useDispatch();

  const { values } = useFormState();

  const list = useSelector(selectEtapas);

  const [filteredList, setFilteredList] = useState([]);
  const [defaultValue, setDefaultValue] = useState('');

  const hasFilter = useMemo(() => isFunction(filterPredicate), [filterPredicate]);

  useEffect(() => {
    dispatch(fetchAllAsync());
  }, []);

  useEffect(() => {
    if (hasFilter && isArray(list)) {
      const filtered = list.filter(filterPredicate);
      setFilteredList(filtered);
    }
  }, [list, filterPredicate]);

  useEffect(() => {
    const value = list.find((item) => item.id === _.get(values, name)?.id);
    if (value) {
      setDefaultValue(value);
    } else {
      setDefaultValue({ id: null, description: '' });
    }
  }, [list, values]);

  const filterOptions = createFilterOptions({
    stringify: (option) => option.description,
  });

  const availableOptions = useMemo(() => (hasFilter ? filteredList : list), [hasFilter, filteredList, list]);

  return (
    <QCXAutocompleteSelect
      label={label}
      id={name}
      name={name}
      options={availableOptions}
      filterOptions={filterOptions}
      defaultValue={defaultValue}
      getOptionLabel={(item) => item?.description || ''}
      getOptionValue={(item) => item}
      {...restProps}
    />
  );
}
