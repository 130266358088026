import React from 'react';
import QCXAlert from './QCXAlert';

export default function QCXSuccessAlert(props) {
  return (
    <QCXAlert
      {...props}
      severity="success"
    />
  );
}
