import { isFunction } from 'lodash';
import { useContext, useMemo } from 'react';
import WizardContext from '../../../contexts/components/wizard/WizardContext';

export default function useWizard() {
  const {
    next,
    previous,
  } = useContext(WizardContext);

  const wizard = useMemo(() => ({
    next: () => {
      if (isFunction(next)) {
        next();
      }
    },
    previous: () => {
      if (isFunction(previous)) {
        previous();
      }
    },
  }), [
    next,
    previous,
  ]);

  return wizard;
}
