import { isFunction } from 'lodash';
import {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import i18n from '../../../../i18n';

const DEFAULT_OPERATION = {
  active: false,
  authorized: false,
  title: i18n.t('com.muralis.qcx.acoes.confirmarOperacao'),
  message: i18n.t('com.muralis.qcx.mensagem.confirmarOperacaoMensagem'),
};

/**
 * @deprecated esse hook é um código legado, ele deve ser mantido até ser completamente substituido
 * para novas funcionalidades utilize o hook useConfigurePopup.ts
 */
export default function useOperationConfirm(callback, options = {}, deps = []) {
  const [operation, setOperation] = useState({
    ...DEFAULT_OPERATION,
    ...options,
  });
  const [savedArgs, setSavedArgs] = useState([]);

  const confirm = useCallback(() => {
    setOperation((previousOperation) => ({
      ...previousOperation,
      authorized: true,
    }));
  }, []);

  const reset = useCallback(() => {
    setSavedArgs([]);
    setOperation((previousOperation) => ({
      ...previousOperation,
      active: false,
      authorized: false,
    }));
  }, [options]);

  const composedCallback = useCallback((...args) => {
    setSavedArgs(args);
    setOperation((previousOperation) => ({
      ...previousOperation,
      active: true,
    }));
  }, deps);

  const operationObject = useMemo(() => ({
    ...operation,
    confirm,
    reset,
  }), [
    operation,
    confirm,
    reset,
  ]);

  useEffect(() => {
    let unmounted = false;

    if (!unmounted) {
      if (operation?.authorized && isFunction(callback)) {
        callback.call(this, ...savedArgs);
        reset();
      }
    }

    return () => {
      unmounted = true;
    };
  }, [
    operation,
    callback,
    savedArgs,
    reset,
  ]);

  return [
    composedCallback,
    operationObject
  ];
}
