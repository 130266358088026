import DeleteIcon from "@mui/icons-material/Delete";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import SearchIcon from "@mui/icons-material/Search";
import { Button, Grid } from "@mui/material";
import {
  GridActionsCellItem,
  GridCellParams,
  GridColDef,
  GridRowParams,
} from "@mui/x-data-grid";
import { FormEvent, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import DataTable from "../../../common/components/dataTable";
import ErrorBadge from "../../../common/components/forms/errorBadge";
import FormHeader from "../../../common/components/forms/formHeader";
import SelectStyled from "../../../common/components/input/selectStyled";
import TextFieldStyled from "../../../common/components/input/textFieldStyled";
import LoadingIndicator from "../../../common/components/loadingIndicator";
import { useUnidadeNegocioGuard } from "../../../common/hooks/useUnidadeNegocioGuard";
import PageLayout from "../../../common/layouts/pageLayout";
import {
  useClientes,
  useCorrespondenciasImportadores,
  useCreateCorrealacaoImportador,
  useDeleteCorrespondenciaImportador,
  useDeleteCorrespondenciaGeral,
} from "./totvs.hooks";
import { CorrespondenciaImportadorTotvs } from "./totvs.types";

const TotvsCorrespondenciaPage = () => {
  useUnidadeNegocioGuard();
  const history = useHistory();
  const { clientes, isLoading } = useClientes();
  const [error, setError] = useState<string>();
  const [
    correlacoesImportadores,
    isCorrelacoesImportadoresLoading,
    isCorrelacoesImportadoresError,
  ] = useCorrespondenciasImportadores();
  const [createCorrelacaoImportador, isCreating, hasCreateError] =
    useCreateCorrealacaoImportador();
  const [deleteCorrelacaoImportador, isDeleting, hasDeleteError] =
    useDeleteCorrespondenciaImportador();
  const [totvsClientId, setTotvsClientId] = useState<string>();
  const [qcxClientId, setQcxClientId] = useState<string | number | undefined>();

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "importador",
        headerName: "Importador",
        flex: 1,
        headerClassName: "header-theme",
      },
      {
        field: "codigoImportador",
        headerName: "Cód. Importador QuickComex",
        flex: 1,
        headerClassName: "header-theme",
        type: "string",
      },
      {
        field: "codigoImportadorTotvs",
        headerName: "Cód. Importador TOTVS/Easy",
        flex: 1,
        headerClassName: "header-theme",
        type: "string",
      },
      {
        field: "actions",
        type: "actions",
        headerName: "Ações",
        headerClassName: "header-theme",
        getActions: (params: GridRowParams) => [
          <GridActionsCellItem
            icon={<SearchIcon />}
            label="Consultar"
            onClick={() => handleDetailsNavigation(params)}
            showInMenu
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Excluir"
            onClick={() => deleteCorrelacaoImportador({ id: params.row.id })}
            showInMenu
          />,
        ],
      },
    ],
    []
  );

  const rows: any[] = useMemo(() => {
    return (correlacoesImportadores ?? []).map((item) => ({
      id: item.id ?? 0,
      importador: item.nome,
      codigoImportadorTotvs: item.totvsId,
      codigoImportador: item.quickcomexId,
    }));
  }, [correlacoesImportadores]);

  const handleDetailsNavigation = (params: GridCellParams | GridRowParams) => {
    history.push(
      `/integracao/totvs-easy/correspondencia/${params.row.codigoImportadorTotvs}`
    );
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const totvsClientExists = correlacoesImportadores.find(
      (item) => item.totvsId == totvsClientId
    );

    if (totvsClientExists) {
      setError("Código Importador TOTVS/Easy já cadastrado");
      return;
    }

    const novaCorrespondencia: Partial<CorrespondenciaImportadorTotvs> = {
      nome:
        clientes.find((item) => item.id == qcxClientId)?.description ?? "None",
      totvsId: totvsClientId ?? "",
      quickcomexId: qcxClientId?.toString() ?? "",
    };

    createCorrelacaoImportador({ requestPayload: novaCorrespondencia });
    if (!isCreating && !hasCreateError) {
      setTotvsClientId("");
      setQcxClientId(undefined);
      setError(undefined);
    }
  };

  return (
    <PageLayout
      title={"Correspondência de Dados"}
      icon={<HistoryEduIcon color={"secondary"} />}
    >
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <Grid container spacing={2}>
          <FormHeader marginTop="0">
            Correspondências de Importadores
          </FormHeader>
          <Grid item sm={12}>
            <DataTable
              onCellDoubleClick={handleDetailsNavigation}
              columns={columns}
              rows={rows}
            ></DataTable>
          </Grid>
          <Grid item sm={12}>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <FormHeader marginTop="0">Nova Correspondência</FormHeader>
                <Grid item sm={12}>
                  <TextFieldStyled
                    value={totvsClientId}
                    required
                    label="Código Importador TOTVS / Easy"
                    onChange={(e) => setTotvsClientId(e.target.value.trim())}
                  ></TextFieldStyled>
                </Grid>
                <Grid item sm={12}>
                  <SelectStyled
                    options={
                      clientes.map((cliente) => ({
                        id: cliente.id,
                        value: cliente.id + " - " + cliente.description,
                      })) ?? []
                    }
                    label={"Código Importador QuickComex"}
                    onChangeAction={(e, value) => setQcxClientId(value?.id)}
                  ></SelectStyled>
                </Grid>
                <Grid item sm={12} textAlign="right">
                  <Button
                    disabled={!totvsClientId || !qcxClientId}
                    type="submit"
                    variant="contained"
                    color="secondary"
                  >
                    Salvar
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
          {error && <ErrorBadge error={error} />}
        </Grid>
      )}
    </PageLayout>
  );
};

export default TotvsCorrespondenciaPage;
