import { Grid } from '@material-ui/core';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import QCXFinalForm from '../../shared-components/final-form/QCXFinalForm';
import QCXFinalTextField from '../../shared-components/final-text-field/QCXFinalTextField';
import QCXFinalCheckboxField from '../../shared-components/final-checkbox-field/QCXFinalCheckboxField';
import QCXFinalOnblurMaskedField from '../../shared-components/final-onblur-masked-field/QCXFinalOnblurMaskedField';
import { required as requiredValidator, validCFOPCode } from '../../utils/validators/field/validator';
import { numberOnly } from '../../utils/hooks/form/field/parsers';
import { formatCFOPCode } from '../../utils/hooks/form/field/formatters';
import useValidators from '../../utils/hooks/useValidators';

export default function QCXCFOPOperacaoFiscalForm({
  initialValues,
  handleSubmit,
  handleChangeToUpdate,
  handleCancelUpdate,
  isConsult,
  isUpdate,
  ...restProps
}) {
  const { t } = useTranslation();

  const codeValidators = useMemo(() => (
    useValidators([
      requiredValidator,
      validCFOPCode,
    ])
  ), []);

  return (
    <QCXFinalForm
      isConsult={isConsult}
      isUpdate={isUpdate}
      initialValues={initialValues}
      handleSubmitValues={handleSubmit}
      handleChangeToUpdate={handleChangeToUpdate}
      handleCancelUpdate={handleCancelUpdate}
      {...restProps}
    >
      {({ values }) => (
        <>
          <Grid item xs={12} sm={8} md={2}>
            <QCXFinalOnblurMaskedField
              id="text-field-code"
              key="text-field-code"
              name="code"
              label={t('com.muralis.qcx.codigo')}
              maxLength={4}
              parse={numberOnly}
              initialValues={values}
              format={formatCFOPCode}
              validate={codeValidators}
              disabled={isConsult || isUpdate}
              required
            />
          </Grid>
          <Grid item xs={12} sm={8} md={10}>
            <QCXFinalTextField
              name="detalhe"
              label={t('com.muralis.qcx.descricao')}
              maxLength={500}
              disabled={isConsult}
              validate={requiredValidator}
              required
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <QCXFinalCheckboxField
              name="importacao"
              label={t('com.muralis.qcx.importacao.label')}
              disabled={isConsult}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <QCXFinalCheckboxField
              name="exportacao"
              label={t('com.muralis.qcx.exportacao.label')}
              disabled={isConsult}
            />
          </Grid>
        </>
      )}
    </QCXFinalForm>
  );
}
