import { isStrictEquals } from '../../utils/general/general-utils';

const NONE_META_TYPE = 'none';
const FATURA_LI_META_TYPE = 'faturaLi';
const GENERATED_LI_META_TYPE = 'generatedLi';

const isFaturaLiMetaType = (type) => (
  isStrictEquals(FATURA_LI_META_TYPE, type)
);

const isGeneratedLiMetaType = (type) => (
  isStrictEquals(GENERATED_LI_META_TYPE, type)
);

export {
  NONE_META_TYPE,
  FATURA_LI_META_TYPE,
  GENERATED_LI_META_TYPE,
  isFaturaLiMetaType,
  isGeneratedLiMetaType,
};
