import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  useHistory,
  useParams,
} from 'react-router';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash/debounce';
import { isEmpty } from 'lodash';
import { validate } from 'uuid';
import {
  changeToBackgroundCreateMode,
  changeToConsultMode,
  changeToCreateMode,
  changeToUpdateMode,
  failure,
  fetchByIdAsync,
  loading,
  preparingAction,
  resetBackgroundMode,
  resetModel,
  selectBackgroundMode,
  selectModeloFollowUp,
  selectMode,
  selectStatus,
  setError,
  setModel,
  setResponse,
  success,
  addToList,
  updateOnList,
} from '../../../../../features/modelo-follow-up/modeloFollowUpSlice';
import {
  isCreateMode,
  isBackgroundCreateMode,
  isConsultMode,
  isUpdateMode,
  isPreparingActionStatus,
  isNoneMode,
} from '../../../../../utils/store/store-utils';
import { register, save } from '../../../../../features/modelo-follow-up/modeloFollowUpAPI';
import QCXRegistrationFormPageTemplate from '../../../../../templates/registration-form-page/QCXRegistrationFormPageTemplate';
import QCXModeloFollowUpWizardFinalForm from '../../../../../components/modelo-follow-up/QCXModeloFollowUpWizardFinalForm';
import { changeControlTo } from '../../../../../features/etapa/etapaSlice';
import { AUTO_LOAD_CONTROL } from '../../../../../features/config-control';

export default function ModeloFollowUpRegistrationPage({ authInfo = {} }) {
  const { t } = useTranslation();

  const { id } = useParams();

  const history = useHistory();

  const dispatch = useDispatch();

  const [isCreated, setIsCreated] = useState(false);

  const status = useSelector(selectStatus);
  const mode = useSelector(selectMode);
  const backgroundMode = useSelector(selectBackgroundMode);

  const modeloFollowUp = useSelector(selectModeloFollowUp);

  const isNone = useMemo(() => (
    isNoneMode(mode)
  ), [mode]);

  const isCreate = useMemo(() => (
    isCreateMode(mode)
  ), [mode]);

  const isBackgroundCreate = useMemo(() => (
    isBackgroundCreateMode(backgroundMode)
  ), [backgroundMode]);

  const isConsult = useMemo(() => (
    isConsultMode(mode)
  ), [mode]);

  const isUpdate = useMemo(() => (
    isUpdateMode(mode)
  ), [mode]);

  const isPreparingAction = useMemo(() => (
    isPreparingActionStatus(status)
  ), [status]);

  const configureDependencies = useCallback(() => {
    dispatch(changeControlTo(AUTO_LOAD_CONTROL));
  }, []);

  useEffect(() => {
    configureDependencies();
  }, []);

  const handleChangeToPreparingAction = () => {
    dispatch(preparingAction());
  };

  const handleChangeToCreate = () => {
    dispatch(changeToCreateMode());
  };

  const handleChangeToBackgroundCreate = () => {
    dispatch(changeToBackgroundCreateMode());
  };

  const handleChangeToConsult = () => {
    dispatch(changeToConsultMode());
  };

  const handleChangeToUpdate = () => {
    dispatch(changeToUpdateMode());
  };

  const handleResetBackgroundMode = () => {
    dispatch(resetBackgroundMode());
  };

  useEffect(() => {
    if (!isEmpty(id)) {
      dispatch(fetchByIdAsync(id));
      handleChangeToConsult();
    }
  }, [id]);

  const handleCancelUpdate = () => {
    const currentId = isBackgroundCreate ? modeloFollowUp?.id : id;

    if (currentId) {
      dispatch(fetchByIdAsync(currentId));
    }
    handleChangeToConsult();
  };

  const normalize = useCallback((unnormalizedData) => {
    const normalizedData = {
      ...unnormalizedData,
      etapaConclusao: unnormalizedData?.etapaConclusao?.id
        ? unnormalizedData.etapaConclusao
        : null,
      etapas: (unnormalizedData?.etapas || []).map((item) => (
        {
          ...item,
          id: validate(item.id) ? null : item.id,
          etapaAtual: item.etapaAtual?.id ? item.etapaAtual : null,
          etapaAnterior: item.etapaAnterior?.id ? item.etapaAnterior : null,
          proximaEtapa: item.proximaEtapa?.id ? item.proximaEtapa : null,
        })),
    };

    if (!unnormalizedData.hasAnaliseDesempenho) {
      normalizedData.analiseDesempenho = null;
    }

    return normalizedData;
  }, []);

  const unnormalize = useCallback((normalizedData) => {
    const unnormalizedData = {
      ...normalizedData,
    };

    if (normalizedData.analiseDesempenho) {
      unnormalizedData.hasAnaliseDesempenho = true;
    }

    return unnormalizedData;
  }, []);

  const handleDispatchSetModel = useCallback((data) => {
    dispatch(setModel(normalize(data)));
  }, [normalize]);

  const createByStep = async (data, step, next) => {
    const isFirstStep = step === 0;
    const isLastStep = step === 1;

    const executeDebounced = debounce(async () => {
      try {
        if (isFirstStep && !isCreated) {
          const response = await register(data, [{ name: 'STEP', value: step + 1 }]);

          if (response?.status === 201) {
            next();
            dispatch(resetModel());
            dispatch(success());

            const created = response?.data;

            handleDispatchSetModel(created);
            dispatch(addToList({ data: created }));
            dispatch(fetchByIdAsync(created?.id));

            setIsCreated(true);
          }
        } else if (isLastStep) {
          if (isEmpty(data?.etapas)) {
            throw Error(t('com.muralis.qcx.validacao.necessarioEtapaProsseguir'));
          }

          const response = await save(data, [{ name: 'STEP', value: step + 1 }]);

          if (response?.status === 200) {
            dispatch(resetModel());

            const handleResultWithDebounce = debounce(() => {
              history.push(t('com.muralis.qcx.url.moduloGeraisServicosModeloFollowUp'));

              dispatch(success());

              const saved = response?.data;

              dispatch(setModel(saved));

              dispatch(setResponse({
                status: response.status,
                data: saved,
                message: t('com.muralis.qcx.mensagem.modeloFollowUpCadastrado', { modelo: data?.description }),
              }));

              dispatch(updateOnList({ data: saved }));
            }, 500);

            handleResultWithDebounce();
          }
        } else {
          const response = await save(data, [{ name: 'STEP', value: step + 1 }]);

          if (response?.status === 200) {
            if (isCreate) {
              next();
            } else {
              handleChangeToConsult();
            }

            const saved = response?.data;

            dispatch(success());
            dispatch(setModel(saved));
            dispatch(updateOnList({ data: saved }));
            dispatch(fetchByIdAsync(saved?.id));
          }
        }
      } catch (error) {
        const errorMessage = !isEmpty(error?.response)
          ? t('com.muralis.qcx.erro.erroSalvarDadosModeloFollowUpEspecifico', { modelo: data?.description, erro: error?.response?.data?.message })
          : t('com.muralis.qcx.erro.erroSalvarDadosModeloFollowUpMensagem', { erro: error?.message });
        dispatch(failure());
        dispatch(setError({
          message: errorMessage,
        }));
      }
    }, 500);

    dispatch(loading());
    executeDebounced();
  };

  const update = async (data) => {
    const executeDebounced = debounce(async () => {
      try {
        if (isEmpty(data?.etapas)) {
          throw Error(t('com.muralis.qcx.validacao.necessarioEtapaProsseguir'));
        }

        const response = await save(data);

        if (response?.status === 200) {
          const handleResultWithDebounce = debounce(() => {
            handleChangeToConsult();
            dispatch(success());

            const saved = response?.data;

            dispatch(setResponse({
              status: response.status,
              data: saved,
              message: t('com.muralis.qcx.mensagem.modeloFollowUpSalvo', { modelo: saved?.description }),
            }));

            dispatch(setModel(saved));
            dispatch(updateOnList({ data: saved }));
            dispatch(fetchByIdAsync(saved?.id));
          }, 500);

          handleResultWithDebounce();
          handleChangeToConsult();
        }
      } catch (error) {
        const errorMessage = !isEmpty(error?.response)
          ? t('com.muralis.qcx.erro.erroSalvarDadosModeloFollowUpEspecifico', { modelo: data?.description, erro: error?.response?.data?.message })
          : t('com.muralis.qcx.erro.erroSalvarDadosModeloFollowUpMensagem', { erro: error?.message });
        dispatch(failure());
        dispatch(setError({
          message: errorMessage,
        }));
      }
    }, 500);

    dispatch(loading());
    executeDebounced();
  };

  const handleSubmit = async (data, step, next) => {
    const normalizedData = normalize(data);

    if (isUpdate && !isBackgroundCreate) {
      await update(normalizedData, step);
    } else {
      await createByStep(normalizedData, step, next);
    }
  };

  const handleAlternativeSave = async (event, step, next) => {
    if (event) {
      event.stopPropagation();
    }

    const normalizedData = normalize(modeloFollowUp);

    if (isUpdate) {
      await update(normalizedData, step);
    } else {
      await createByStep(normalizedData, step, next);
    }
  };

  const model = useMemo(() => (
    !isCreate || isCreated
      ? unnormalize(modeloFollowUp)
      : {}
  ), [isCreate, modeloFollowUp, unnormalize]);

  const actionName = useMemo(() => {
    if (isCreate || isBackgroundCreate || isNone) return t('com.muralis.qcx.acoes.novo');
    if (isConsult) return t('com.muralis.qcx.acoes.visualizar');
    return t('com.muralis.qcx.acoes.alterar');
  }, [isNone, isCreate, isConsult, isBackgroundCreate]);

  const breadcrumbs = useMemo(() => ([
    {
      link: {
        to: '/',
        name: t('com.muralis.qcx.inicio'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.cadastros'),
      },
    },
    {
      link: {
        to: t('com.muralis.qcx.url.moduloCadastrosGerais'),
        name: t('com.muralis.qcx.gerais'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.servico.labelPlural'),
      },
    },
    {
      link: {
        to: t('com.muralis.qcx.url.moduloGeraisServicosModeloFollowUp'),
        name: t('com.muralis.qcx.modelosFollowUp'),
      },
    },
    {
      text: {
        name: actionName,
      },
    },
  ]), [actionName]);

  const pageTitle = useMemo(() => (
    isNone || isCreate || isBackgroundCreate
      ? t('com.muralis.qcx.followup.novoModeloFollowUp')
      : t('com.muralis.qcx.followup.modeloFollowUpExistente', { ID: modeloFollowUp?.description || modeloFollowUp?.id || '-' })
  ), [isNone, isCreate, isBackgroundCreate, modeloFollowUp]);

  const breadcrumbsProps = {
    maxItems: 6,
  };

  return (
    <QCXRegistrationFormPageTemplate
      pageTitle={pageTitle}
      breadcrumbs={breadcrumbs}
      breadcrumbsProps={breadcrumbsProps}
      isCreate={isCreate}
      isConsult={isConsult}
      isUpdate={isUpdate}
      isBackgroundCreate={isBackgroundCreate}
      isPreparingAction={isPreparingAction}
      handleAlternativeSave={handleAlternativeSave}
      handleChangeToPreparingAction={handleChangeToPreparingAction}
      handleChangeToCreate={handleChangeToCreate}
      handleChangeToBackgroundCreate={handleChangeToBackgroundCreate}
      handleChangeToConsult={handleChangeToConsult}
      handleChangeToUpdate={handleChangeToUpdate}
      handleCancelUpdate={handleCancelUpdate}
      handleResetBackgroundMode={handleResetBackgroundMode}
      showSubtitle={false}
      authInfo={authInfo}
    >
      {(formProps) => (
        <QCXModeloFollowUpWizardFinalForm
          model={model}
          handleChangeModel={handleDispatchSetModel}
          handleSubmit={handleSubmit}
          authInfo={authInfo}
          requiredRoles={['modelo-follow-up']}
          {...formProps}
        />
      )}
    </QCXRegistrationFormPageTemplate>
  );
}
