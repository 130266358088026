import React from 'react';
import { OnChange } from 'react-final-form-listeners';

export default function QCXFormListener({
  name,
  children,
}) {
  return (
    <OnChange name={name}>
      {children}
    </OnChange>
  );
}
