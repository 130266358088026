import { isStrictEquals } from '../general-utils';

const OPERACAO_TRANSFERENCIA = 'TRANSFERENCIA';
const OPERACAO_ESTORNO = 'ESTORNO';

const MOVIMENTACAO_ENTRADA = 'ENTRADA';
const MOVIMENTACAO_SAIDA = 'SAIDA';

const MOVIMENTACAO_STATUS_CONCILIADA = 'CONCILIADA';

const isOperacaoTransferencia = (status) => (
  isStrictEquals(OPERACAO_TRANSFERENCIA, status)
);

const isOperacaoEstorno = (status) => (
  isStrictEquals(OPERACAO_ESTORNO, status)
);

const isMovimentacaoStatusConciliada = (status) => (
  isStrictEquals(MOVIMENTACAO_STATUS_CONCILIADA, status)
);

const OperacaoMovimentacaoUtils = {
  TRANSFERENCIA: (
    OPERACAO_TRANSFERENCIA
  ),
  ESTORNO: (
    OPERACAO_ESTORNO
  ),
  ENTRADA: (
    MOVIMENTACAO_ENTRADA
  ),
  SAIDA: (
    MOVIMENTACAO_SAIDA
  ),
  CONCILIADA: (
    MOVIMENTACAO_STATUS_CONCILIADA
  ),
  isTransferencia: (
    isOperacaoTransferencia
  ),
  isEstorno: (
    isOperacaoEstorno
  ),
  isStatusConciliada: (
    isMovimentacaoStatusConciliada
  ),
};

export {
  OperacaoMovimentacaoUtils,
  OPERACAO_TRANSFERENCIA,
  OPERACAO_ESTORNO,
  isOperacaoTransferencia,
  isOperacaoEstorno,
};
