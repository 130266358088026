import { getOptionByValueInList } from '../../utils/general/general-utils';
import i18n from '../../i18n';

const PROCESSO_VINCULADO_ADMINISTRATIVO = 'ADMINISTRATIVO';
const PROCESSO_VINCULADO_DECLARACAO_PRELIMINAR = 'DECLARACAO_PRELIMINAR';
const PROCESSO_VINCULADO_JUDICIAL = 'JUDICIAL';
const PROCESSO_VINCULADO_JUDICIAL_EXCLUSIVO_DARF = 'JUDICIAL_EXCLUSIVO_DARF';

const PROCESSO_VINCULADO_LIST = [
  {
    label: i18n.t('com.muralis.qcx.administrativo').toUpperCase(),
    value: PROCESSO_VINCULADO_ADMINISTRATIVO,
  },
  {
    label: i18n.t('com.muralis.qcx.declaracaoPreliminar').toUpperCase(),
    value: PROCESSO_VINCULADO_DECLARACAO_PRELIMINAR,
  },
  {
    label: i18n.t('com.muralis.qcx.judicial').toUpperCase(),
    value: PROCESSO_VINCULADO_JUDICIAL,
  },
  {
    label: i18n.t('com.muralis.qcx.judicialExclusivoDARF').toUpperCase(),
    value: PROCESSO_VINCULADO_JUDICIAL_EXCLUSIVO_DARF,
  },
];

const isProcessoVinculadoAdministrativo = (value) => (
  PROCESSO_VINCULADO_ADMINISTRATIVO === value
);

const isProcessoVinculadoDeclaracaoPreliminar = (value) => (
  PROCESSO_VINCULADO_DECLARACAO_PRELIMINAR === value
);

const isProcessoVinculadoJudicial = (value) => (
  PROCESSO_VINCULADO_JUDICIAL === value
);

const isProcessoVinculadoJudicialExclusivoDarf = (value) => (
  PROCESSO_VINCULADO_JUDICIAL_EXCLUSIVO_DARF === value
);

const getProcessoVinculadoLabelByValue = (value) => {
  const foundOption = getOptionByValueInList(PROCESSO_VINCULADO_LIST, value);
  return foundOption && foundOption?.label;
};

export {
  PROCESSO_VINCULADO_ADMINISTRATIVO,
  PROCESSO_VINCULADO_DECLARACAO_PRELIMINAR,
  PROCESSO_VINCULADO_JUDICIAL,
  PROCESSO_VINCULADO_JUDICIAL_EXCLUSIVO_DARF,
  PROCESSO_VINCULADO_LIST,
};

export {
  isProcessoVinculadoAdministrativo,
  isProcessoVinculadoDeclaracaoPreliminar,
  isProcessoVinculadoJudicial,
  isProcessoVinculadoJudicialExclusivoDarf,
  getProcessoVinculadoLabelByValue,
};
