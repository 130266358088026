import React from 'react';
import { List } from '@material-ui/core';

export default function QCXList({ children, ...restProps }) {
  return (
    <List style={{ fontSize: '14px', textTransform: 'uppercase' }} {...restProps}>
      {children}
    </List>
  );
}
