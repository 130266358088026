import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { validate } from 'uuid';
import { Box, Grid, makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import QCXFormStepWizard from '../../shared-components/form-step-wizard/QCXFormStepWizard';
import QCXFinalFormDataReplicator from '../form-data-replicator/QCXFinalFormDataReplicator';
import {
  loading,
  setModel,
  resetStatus,
} from '../../features/declaracao-unica-exportacao/declaracaoUnicaExportacaoSlice';
import { estadoActions } from '../../features/estado/estadoSlice';
import { paisActions } from '../../features/pais/paisSlice';
import { useMercadoriaUtils } from '../fatura/mercadoriaUtils';
import QCXSelectIncotermCondicaoVendaAutocomplete from '../../shared-components/final-select-incoterm-condicao-venda-field/QCXSelectIncotermCondicaoVendaAutocomplete';
import QCXFinalCheckboxField from '../../shared-components/final-checkbox-field/QCXFinalCheckboxField';
import QCXSelectCFOPOperacoesFiscaisAutocomplete from '../../shared-components/final-autocomplete-select-cfop-operacoes-fiscais-field/QCXSelectCFOPOperacoesFiscaisAutocomplete';
import { setErrorFeedback, setSuccessFeedback, setWarningFeedback } from '../../features/feedback/feedbackSlice';
import { selectRelatedModelFollowUp } from '../../features/declaracao-unica-exportacao/declaracaoUnicaExportacaoSelectors';
import useOperationMiddleware from '../../utils/hooks/operation/middleware/useOperationMiddleware';
import QCXConfirmDialog from '../../shared-components/dialog/QCXConfirmDialog';
import {
  normalizeData,
  normalizeNumeral,
  omitVoidPropertiesBy,
  unnormalizeNumeral,
} from '../../utils/general/general-utils';
import { isCurrentOrPastDate, validCpfOrCnpj } from '../../utils/validators/field/validator';
import { formatBrazilianNumericDecimal, formatNotZero } from '../../utils/hooks/form/field/formatters';
import QCXSelectTratamentoPrioritarioAutocomplete from '../../shared-components/select-tratamento-prioritario/QCXSelectTratamentoPrioritarioAutocomplete';
import QCXEnquadramentosOperacaoFormGroup from './QCXEnquadramentosOperacaoFormGroup';
import QCXFinalNumericIntegerField from '../../shared-components/final-numeric-integer-field/QCXFinalNumericIntegerField';
import { configurePaisGetterByCode, PAIS_BRASIL_CODE } from '../../shared-components/select-pais/paisUtils';
import { numberOnly } from '../../utils/hooks/form/field/parsers';
import QCXSelectEmpresaEstrangeiraAutocomplete from '../../shared-components/final-select-empresa-estrangeira-field/QCXSelectEmpresaEstrangeiraAutocomplete';
import QCXDadosNotaFiscalFormGroup from './QCXDadosNotaFiscalFormGroup';
import useValidators from '../../utils/hooks/useValidators';
import TipoDocumentoFiscalUtils from '../../shared-components/select-tipo-documento-fiscal/TipoDocumentoFiscalUtils';
import { selectTiposDocumentoFiscal } from '../../features/tipo-documento-fiscal/tipoDocumentoFiscalSlice';
import QCXFinalAtributoNcmMercadoriaGroup from './QCXFinalAtributoNcmMercadoriaGroup';
import QCXLPCOFormGroup from './QCXLPCOFormGroup';
import QCXJustificativaMercadoriaGroup from './QCXJustificativaMercadoriaGroup';
import QCXInfoAlert from '../../shared-components/alert/QCXInfoAlert';

const useStyles = makeStyles((theme) => ({
  gridField: {
    paddingBottom: '16px',
  },
  leftSiblingGridField: {
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '16px',
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: '8px',
    },
  },
  rightSiblingGridField: {
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '16px',
    },
    [theme.breakpoints.up('md')]: {
      paddingRight: '8px',
    },
  },
}));

export default function QCXDeclaracaoUnicaExportacaoAuxiliarForm({
  isLoading,
  isCreate,
  isConsult,
  isUpdate,
  parentModel,
  authInfo,
  requiredRoles,
  handleCustomSaveBy,
  handleChangeToConsult,
  handleChangeToUpdate,
  handleCancelUpdate,
  controlComponentProps,
  ...restProps
}) {
  const { t } = useTranslation();
  const classes = useStyles();

  const dispatch = useDispatch();

  const mercadoriaUtils = useMercadoriaUtils();

  const paises = useSelector(paisActions.selectPaises);
  const relatedFollowUp = useSelector(selectRelatedModelFollowUp);
  const tiposDocumentosFiscais = useSelector(selectTiposDocumentoFiscal);

  const [estadosCarregados, setEstadosCarregados] = useState(false);

  const [replicated, setReplicated] = useState(false);

  useEffect(() => {
    dispatch(paisActions.fetchAllAsync());

    const resetDependencies = () => {
      dispatch(estadoActions.resetList());
      dispatch(paisActions.resetList());
      setEstadosCarregados(false);
    };

    return resetDependencies;
  }, []);

  const mercadorias = useMemo(() => parentModel?.mercadorias || [], [parentModel]);

  const followUpHasMetadata = useMemo(() => relatedFollowUp?.metadata, [relatedFollowUp]);

  const isTipoDocumentoFiscalSemNotaFiscal = useMemo(
    () =>
      TipoDocumentoFiscalUtils.isSemNotaFiscal(tiposDocumentosFiscais)(
        parentModel?.atributosAdicionais?.tipoDocumentoFiscal?.id
      ),
    [parentModel]
  );

  const internalInitialValues = useMemo(() => {
    const getPaisByCode = configurePaisGetterByCode(paises);
    const defaultPaisBrasil = getPaisByCode(PAIS_BRASIL_CODE);

    return {
      notaFiscal: {
        ...parentModel?.due?.notaFiscal,
        estado: {
          ...parentModel?.due?.notaFiscal?.estado,
          pais: defaultPaisBrasil,
        },
      },
    };
  }, [paises, parentModel]);

  const relationshipPaths = useMemo(
    () => [
      'due.incotermCondicaoVenda',
      'due.fornecedor',
      'due.primeiroEnquadramento',
      'due.segundoEnquadramento',
      'due.terceiroEnquadramento',
      'due.quartoEnquadramento',
      'due.tratamentoPrioritario',
      'operacaoFiscal',
      'due.notaFiscal.estado',
      'due.notaFiscal.modeloNotaFiscal',
    ],
    []
  );

  useEffect(() => {
    const paisId = internalInitialValues?.notaFiscal?.estado?.pais?.id;

    if (paisId && !estadosCarregados) {
      dispatch(estadoActions.fetchByFilterAsync([{ name: 'pais', value: paisId }]));

      setEstadosCarregados(true);
    }
  }, [internalInitialValues, estadosCarregados]);

  const normalize = useCallback((unnormalizedData) => {
    const normalizedData = mercadoriaUtils.normalize(unnormalizedData);

    return {
      ..._.omit(normalizedData, 'endereco'),
      valorTotalMcvReal: normalizeNumeral(unnormalizedData?.valorTotalMcvReal),
      descricaoJustificativa: unnormalizedData?.descricaoJustificativa,
      operacaoFiscal: unnormalizedData?.operacaoFiscal?.id ? unnormalizedData?.operacaoFiscal : undefined,
      due: {
        ...unnormalizedData?.due,
        prazoSolicitado: normalizeNumeral(unnormalizedData?.due?.prazoSolicitado),
        incotermCondicaoVenda: unnormalizedData?.due?.incotermCondicaoVenda?.id
          ? unnormalizedData?.due?.incotermCondicaoVenda
          : undefined,
        fornecedor: unnormalizedData?.due?.fornecedor?.id ? unnormalizedData?.due?.fornecedor : undefined,
        primeiroEnquadramento: unnormalizedData?.due?.primeiroEnquadramento?.id
          ? unnormalizedData?.due?.primeiroEnquadramento
          : undefined,
        segundoEnquadramento: unnormalizedData?.due?.segundoEnquadramento?.id
          ? unnormalizedData?.due?.segundoEnquadramento
          : undefined,
        terceiroEnquadramento: unnormalizedData?.due?.terceiroEnquadramento?.id
          ? unnormalizedData?.due?.terceiroEnquadramento
          : undefined,
        quartoEnquadramento: unnormalizedData?.due?.quartoEnquadramento?.id
          ? unnormalizedData?.due?.quartoEnquadramento
          : undefined,
        tratamentoPrioritario: unnormalizedData?.due?.tratamentoPrioritario?.id
          ? unnormalizedData?.due?.tratamentoPrioritario
          : undefined,
        lpcos: unnormalizedData?.due?.lpcos?.map((lpco) => lpco.lpco),
        ncmExportacao: unnormalizedData?.due?.ncmExportacao?.map((ncmExp) => ({
          ...ncmExp,
          id: validate(ncmExp?.id) ? null : ncmExp?.id,
        })),
        operacoesExportacaoConsignacoes: (unnormalizedData?.due?.operacoesExportacaoConsignacoes || [])?.map(
          (operacaoExportacaoConsignacao) =>
            validate(operacaoExportacaoConsignacao?.id)
              ? {
                  ...operacaoExportacaoConsignacao,
                  tipoDocumentoDue: operacaoExportacaoConsignacao?.tipoDocumentoDue?.id
                    ? operacaoExportacaoConsignacao?.tipoDocumentoDue
                    : null,
                  quantidadeEstatistica: normalizeNumeral(operacaoExportacaoConsignacao?.quantidadeEstatistica),
                  id: null,
                }
              : {
                  ...operacaoExportacaoConsignacao,
                  tipoDocumentoDue: operacaoExportacaoConsignacao?.tipoDocumentoDue?.id
                    ? operacaoExportacaoConsignacao?.tipoDocumentoDue
                    : null,
                  quantidadeEstatistica: normalizeNumeral(operacaoExportacaoConsignacao?.quantidadeEstatistica),
                }
        ),
        operacoesExportacaoTemporarias: (unnormalizedData?.due?.operacoesExportacaoTemporarias || [])?.map(
          (operacaoExportacaoTemporaria) =>
            validate(operacaoExportacaoTemporaria?.id)
              ? {
                  ...operacaoExportacaoTemporaria,
                  tipoDocumentoDue: operacaoExportacaoTemporaria?.tipoDocumentoDue?.id
                    ? operacaoExportacaoTemporaria?.tipoDocumentoDue
                    : null,
                  quantidadeEstatistica: normalizeNumeral(operacaoExportacaoTemporaria?.quantidadeEstatistica),
                  id: null,
                }
              : {
                  ...operacaoExportacaoTemporaria,
                  tipoDocumentoDue: operacaoExportacaoTemporaria?.tipoDocumentoDue?.id
                    ? operacaoExportacaoTemporaria?.tipoDocumentoDue
                    : null,
                  quantidadeEstatistica: normalizeNumeral(operacaoExportacaoTemporaria?.quantidadeEstatistica),
                }
        ),
        notaFiscal: unnormalizedData?.due?.notaFiscal
          ? {
              ...unnormalizedData?.due?.notaFiscal,
              data: normalizeData(unnormalizedData?.due?.notaFiscal?.data),
              cpfCnpjEmitente: numberOnly(unnormalizedData?.due?.notaFiscal?.cpfCnpjEmitente),
              estado: unnormalizedData?.due?.notaFiscal?.estado?.id
                ? unnormalizedData?.due?.notaFiscal?.estado
                : undefined,
              modeloNotaFiscal: unnormalizedData?.due?.notaFiscal?.modeloNotaFiscal?.id
                ? unnormalizedData?.due?.notaFiscal?.modeloNotaFiscal
                : undefined,
            }
          : undefined,
        atosConcessorios: (unnormalizedData?.due?.atosConcessorios || [])?.map((atoConcessorio) =>
          validate(atoConcessorio?.id)
            ? {
                ...atoConcessorio,
                cnpj: numberOnly(atoConcessorio?.cnpj),
                quantidade: normalizeNumeral(atoConcessorio?.quantidade),
                vmleComCobertura: normalizeNumeral(atoConcessorio?.vmleComCobertura),
                vmleSemCobertura: normalizeNumeral(atoConcessorio?.vmleSemCobertura),
                tipoAtoConcessorio: atoConcessorio?.tipoAtoConcessorio?.id ? atoConcessorio?.tipoAtoConcessorio : null,
                notasFiscais: (atoConcessorio?.notasFiscais || [])?.map((notaFiscal) =>
                  validate(notaFiscal?.id)
                    ? {
                        ...notaFiscal,
                        quantidade: normalizeNumeral(notaFiscal?.quantidade),
                        valor: normalizeNumeral(notaFiscal?.valor),
                        data: normalizeData(notaFiscal?.data),
                        id: null,
                      }
                    : {
                        ...notaFiscal,
                        quantidade: normalizeNumeral(notaFiscal?.quantidade),
                        valor: normalizeNumeral(notaFiscal?.valor),
                        data: normalizeData(notaFiscal?.data),
                      }
                ),
                documentosImportacao: (atoConcessorio?.documentosImportacao || [])?.map((documentoImportacao) =>
                  validate(documentoImportacao?.id)
                    ? {
                        ...documentoImportacao,
                        quantidade: normalizeNumeral(documentoImportacao?.quantidade),
                        valor: normalizeNumeral(documentoImportacao?.valor),
                        id: null,
                      }
                    : {
                        ...documentoImportacao,
                        quantidade: normalizeNumeral(documentoImportacao?.quantidade),
                        valor: normalizeNumeral(documentoImportacao?.valor),
                      }
                ),
                id: null,
              }
            : {
                ...atoConcessorio,
                cnpj: numberOnly(atoConcessorio?.cnpj),
                quantidade: normalizeNumeral(atoConcessorio?.quantidade),
                vmleComCobertura: normalizeNumeral(atoConcessorio?.vmleComCobertura),
                vmleSemCobertura: normalizeNumeral(atoConcessorio?.vmleSemCobertura),
                tipoAtoConcessorio: atoConcessorio?.tipoAtoConcessorio?.id ? atoConcessorio?.tipoAtoConcessorio : null,
                notasFiscais: (atoConcessorio?.notasFiscais || [])?.map((notaFiscal) =>
                  validate(notaFiscal?.id)
                    ? {
                        ...notaFiscal,
                        quantidade: normalizeNumeral(notaFiscal?.quantidade),
                        valor: normalizeNumeral(notaFiscal?.valor),
                        data: normalizeData(notaFiscal?.data),
                        id: null,
                      }
                    : {
                        ...notaFiscal,
                        quantidade: normalizeNumeral(notaFiscal?.quantidade),
                        valor: normalizeNumeral(notaFiscal?.valor),
                        data: normalizeData(notaFiscal?.data),
                      }
                ),
                documentosImportacao: (atoConcessorio?.documentosImportacao || [])?.map((documentoImportacao) =>
                  validate(documentoImportacao?.id)
                    ? {
                        ...documentoImportacao,
                        quantidade: normalizeNumeral(documentoImportacao?.quantidade),
                        valor: normalizeNumeral(documentoImportacao?.valor),
                        id: null,
                      }
                    : {
                        ...documentoImportacao,
                        quantidade: normalizeNumeral(documentoImportacao?.quantidade),
                        valor: normalizeNumeral(documentoImportacao?.valor),
                      }
                ),
              }
        ),
      },
    };
  }, []);

  const handleRepassar = useCallback(
    async ({ meta, ...values }) => {
      try {
        const cpfCnpjEmitente = _.get(values, 'due.notaFiscal.cpfCnpjEmitente');

        if (cpfCnpjEmitente) {
          const resultCpfCnpjEmitenteValid = validCpfOrCnpj(cpfCnpjEmitente);

          if (resultCpfCnpjEmitenteValid) {
            dispatch(
              setWarningFeedback({
                message: t('com.muralis.qcx.atencaoMensagem', {
                  mensagem: resultCpfCnpjEmitenteValid,
                }),
              })
            );
            return;
          }
        }

        dispatch(loading());

        const selectedMercadoriaId = [...meta?.selected].map((selectedMercadoria) => selectedMercadoria?.id);

        const selectedMercadorias = mercadorias.filter((mercadoria) => selectedMercadoriaId.includes(mercadoria?.id));

        const clonedValues = _.cloneDeep(values);

        const ignorableValues = _.pick(clonedValues, 'due.notaFiscal.data');

        const notIgnorableValues = _.omit(clonedValues, ['due.notaFiscal.data']);

        const replicableData = _.merge(ignorableValues, omitVoidPropertiesBy(notIgnorableValues));

        const createObjectWithId = (object) => {
          if (!_.has(object, 'id')) {
            return undefined;
          }

          return _.create(
            {},
            {
              id: object?.id,
            }
          );
        };

        const updatedMercadorias = selectedMercadorias.map((mercadoriaBase) => {
          const clonedData = _.cloneDeep(mercadoriaBase);

          const preNormalizedData = relationshipPaths.reduce((previous, currentPath) => {
            const fullCurrentPath = [currentPath, 'id'].join('.');

            if (!_.has(previous, fullCurrentPath)) {
              return previous;
            }

            const updated = _.updateWith(previous, currentPath, createObjectWithId);

            return _.cloneDeep(updated);
          }, clonedData);

          const isNilValueOrObjectId = (value) => {
            if (_.isArray(value)) {
              return false;
            }

            if (_.isPlainObject(value) && _.has(value, 'id')) {
              return _.isNil(_.get(value, 'id'));
            }

            return _.isNil(value);
          };

          const isIgnorableValue = (value) => {
            if (_.isString(value)) {
              return _.isEmpty(value);
            }

            return isNilValueOrObjectId(value);
          };

          const validPreNormalizedData = _.omitBy(preNormalizedData, isIgnorableValue);

          const validPreNormalizedReplicableData = _.omitBy(replicableData, isIgnorableValue);

          const validExistingData = {
            ...validPreNormalizedData,
          };

          const validReplicableData = {
            ...validPreNormalizedReplicableData,
          };

          const updatedData = _.merge(validExistingData, validReplicableData);

          const normalizedData = normalize(updatedData);

          return normalizedData;
        });

        const mergedMercadorias = _.unionBy(updatedMercadorias, parentModel?.mercadorias, 'id');

        const updatedDeclaracaoUnicaExportacao = {
          ...parentModel,
          mercadorias: mergedMercadorias,
        };

        dispatch(setModel(updatedDeclaracaoUnicaExportacao));

        const processo = updatedDeclaracaoUnicaExportacao?.atributosAdicionais?.processo;

        const updatedMercadoriasNumber = selectedMercadorias.reduce((previous, current, index, array) => {
          if (index === 0) {
            return current.item;
          }
          if (array?.length > 1 && index === array?.length - 1) {
            return `${previous} e ${current?.item}`;
          }

          return `${previous}, ${current?.item}`;
        }, []);

        const isManyUpdated = selectedMercadorias?.length > 1;
        const feedbackMessage = isManyUpdated
          ? t('com.muralis.qcx.mensagem.dadosPreenchidosFormularioDUE', {
              mercadoria: t('com.muralis.qcx.mercadoria.mercadoriasNumero', { count: updatedMercadoriasNumber }),
              processo,
            })
          : t('com.muralis.qcx.mensagem.dadosPreenchidosFormularioDUE', {
              mercadoria: t('com.muralis.qcx.mercadoria.mercadoriaNumero', { count: updatedMercadoriasNumber }),
              processo,
            });

        dispatch(
          setSuccessFeedback({
            message: feedbackMessage,
          })
        );

        setReplicated(true);
      } catch (error) {
        dispatch(
          setErrorFeedback({
            message: t('com.muralis.qcx.erro.erroRepassarDadosSelecaoAtual', {
              erro: error?.message ? `: ${error?.message}` : '.',
            }),
          })
        );
      } finally {
        dispatch(resetStatus());
      }
    },
    [mercadorias, relationshipPaths, handleCustomSaveBy]
  );

  const [handleReplicateOperation, operationOfReplicate] = useOperationMiddleware(handleRepassar, [
    mercadorias,
    relationshipPaths,
    handleCustomSaveBy,
  ]);

  const handleReplicateOnSubmitMiddleware = useCallback(
    (data) => {
      const configureOperation = () => ({
        options: {
          title: t('com.muralis.qcx.confirmarOperacaoRepasseDados').toUpperCase(),
          message: t('com.muralis.qcx.mensagem.confirmarOperacaoDadosPreenchidosMercadoriasSelecionadas'),
          endMessage: t('com.muralis.qcx.certezaDesejaRepassar'),
        },
      });

      handleReplicateOperation(configureOperation, data);
    },
    [handleReplicateOperation]
  );

  const handleResetFormData = useCallback((form) => {
    form.restart({});
    form.change('due.notaFiscal.cpfCnpjEmitente', '');
  }, []);

  const tableProps = useMemo(
    () => ({
      title: t('com.muralis.qcx.item.itensRepasse'),
      columns: [
        {
          field: 'item',
          headerName: t('com.muralis.qcx.item.labelSingular'),
          headerAlign: 'center',
          align: 'center',
          flex: 90,
          valueGetter: ({ row }) => row?.item || '-',
        },
        {
          field: 'partnumber',
          headerName: t('com.muralis.qcx.item.partNumber'),
          headerAlign: 'center',
          align: 'center',
          flex: 90,
          valueGetter: ({ row }) => row?.partnumber || '-',
        },
        {
          field: 'ncm',
          headerName: t('com.muralis.qcx.NCM.label'),
          headerAlign: 'center',
          align: 'center',
          flex: 200,
          valueGetter: ({ row }) => `${row?.ncm?.code} - ${row?.ncm?.description}` || '-',
        },
        {
          field: 'quantidade',
          headerName: t('com.muralis.qcx.quantidade.label'),
          headerAlign: 'center',
          align: 'center',
          flex: 90,
          valueFormatter: ({ row: { quantidade } }) =>
            unnormalizeNumeral(quantidade, formatBrazilianNumericDecimal(5)) || '-',
        },
      ],
      data: [...mercadorias].sort((a, b) => a?.item - b?.item),
    }),
    [mercadorias]
  );

  const dataEmissaoValidators = useMemo(() => useValidators([isCurrentOrPastDate]), []);

  const dadosNotaFiscalFieldProps = useMemo(
    () => ({
      notaFiscal: {
        uf: {
          id: 'select-field-uf',
          key: 'select-field-uf',
          name: 'due.notaFiscal.estado.id',
          options: {},
        },
        data: {
          id: 'date-picker-date-emissao',
          key: 'date-picker-date-emissao',
          name: 'due.notaFiscal.data',
          options: {
            validate: !isConsult && dataEmissaoValidators,
          },
        },
        cpfCnpjEmitente: {
          id: 'masked-text-cpf-cnpj-emitente-field',
          key: 'masked-text-cpf-cnpj-emitente-field',
          name: 'due.notaFiscal.cpfCnpjEmitente',
          options: {},
        },
        modeloNotaFiscal: {
          id: 'select-field-modelo-nota-fiscal',
          key: 'select-field-modelo-nota-fiscal',
          name: 'due.notaFiscal.modeloNotaFiscal.id',
          options: {},
        },
        serie: {
          id: 'text-field-serie',
          key: 'text-field-serie',
          name: 'due.notaFiscal.serie',
          options: {},
        },
        numero: {
          id: 'text-field-numero-nota-fiscal',
          key: 'text-field-numero-nota-fiscal',
          name: 'due.notaFiscal.numero',
          options: {},
        },
      },
    }),
    [isConsult, dataEmissaoValidators]
  );

  const fabricanteFilterPredicate = useCallback(
    (empresaEstrangeira) => empresaEstrangeira?.fabricante && empresaEstrangeira?.active,
    []
  );

  const exportadorNaoFabricante = useCallback((values) => values?.due?.exportadorNaoFabricante, []);

  const enquadramentosOperacaoFieldProps = useMemo(
    () => ({
      enquadramento: {
        primeiro: {
          id: 'select-field-primeiro-enquadramento',
          key: 'select-field-primeiro-enquadramento',
          name: 'due.primeiroEnquadramento.id',
        },
        segundo: {
          id: 'select-field-segundo-enquadramento',
          key: 'select-field-segundo-enquadramento',
          name: 'due.segundoEnquadramento.id',
        },
        terceiro: {
          id: 'select-field-terceiro-enquadramento',
          key: 'select-field-terceiro-enquadramento',
          name: 'due.terceiroEnquadramento.id',
        },
        quarto: {
          id: 'select-field-quarto-enquadramento',
          key: 'select-field-quarto-enquadramento',
          name: 'due.quartoEnquadramento.id',
        },
      },
    }),
    []
  );

  const onExportadorNaoFabricanteChange = useCallback(
    (form) => (value) => {
      if (!value) {
        form.change('due.fornecedor.id', undefined);
        form.change('due.notaFiscal.cpfCnpjEmitente', '');
      }
    },
    []
  );

  const onSelectedsChange = useCallback(
    (form) => (selecteds) => {
      if (selecteds.length <= 0) {
        form.change('ncm.id', undefined);
        return;
      }

      const mercadoriaSelecionadas = mercadorias.filter((mercadoria) =>
        selecteds.some((selected) => selected.id === mercadoria.id)
      );

      const currentNCM = mercadoriaSelecionadas[0]?.ncm?.id;

      // iterate over selecteds to compare selected ncm with currenctNCM
      const differentsNcms = mercadoriaSelecionadas.some((selected) => {
        if (selected?.ncm?.id !== currentNCM) {
          return true;
        }

        return false;
      });

      form.change('ignorableFields.differentNcms', differentsNcms);
      if (differentsNcms) {
        form.change('ncm.id', undefined);
        return;
      }

      // change ncm.id in forms
      form.change('ncm.id', currentNCM);
    },
    []
  );

  const onChangeListeners = useMemo(
    () => [
      {
        name: 'due.exportadorNaoFabricante',
        fn: onExportadorNaoFabricanteChange,
      },
      {
        name: 'meta.selected',
        fn: onSelectedsChange,
      },
    ],
    [onExportadorNaoFabricanteChange]
  );

  return (
    <QCXFormStepWizard
      initialValues={internalInitialValues}
      onSubmit={handleReplicateOnSubmitMiddleware}
      onChangeListeners={onChangeListeners}
      controlComponentProps={controlComponentProps}
      disableSubmitOnNext
      disablePristine
      {...restProps}
    >
      {({ values }) => (
        <>
          <>
            <QCXFinalFormDataReplicator
              isConsult={isConsult}
              tableProps={tableProps}
              onReset={handleResetFormData}
              replicated={replicated}
              handleSetReplicated={setReplicated}
              clearSelection={isConsult}
            >
              <Grid
                item
                container
                style={{
                  paddingTop: '24px',
                }}
              >
                <Grid item container spacing={2}>
                  <Grid item className={classes.gridField} xs={12} sm={12} md={6} lg={6}>
                    <QCXSelectTratamentoPrioritarioAutocomplete
                      id="select-field-tratamento-prioritario"
                      key="select-field-tratamento-prioritario"
                      name="due.tratamentoPrioritario.id"
                      label={t('com.muralis.qcx.tratamentoPrioritario')}
                      initialValues={values}
                      disabled={isConsult}
                    />
                  </Grid>
                  <Grid item className={classes.gridField} xs={12} sm={12} md={6} lg={6}>
                    <QCXSelectIncotermCondicaoVendaAutocomplete
                      id="select-field-incoterm"
                      key="select-field-incoterm"
                      name="due.incotermCondicaoVenda.id"
                      label={t('com.muralis.qcx.incoterm')}
                      initialValues={values}
                      disabled={isConsult}
                    />
                  </Grid>
                  <QCXEnquadramentosOperacaoFormGroup
                    disabled={isConsult}
                    fieldProps={enquadramentosOperacaoFieldProps}
                  />
                  {followUpHasMetadata && <Grid item xs={12} sm={12} md={6} lg={6} />}
                  {!followUpHasMetadata && (
                    <Grid item className={classes.rightSiblingGridField} xs={12} sm={12} md={6} lg={6}>
                      <QCXSelectCFOPOperacoesFiscaisAutocomplete
                        id="text-cfop-operacoes-fiscais-field"
                        key="text-cfop-operacoes-fiscais-field"
                        name="operacaoFiscal"
                        label={t('com.muralis.qcx.CFOP.labelExtenso')}
                        initialValues={values}
                        disabled={isConsult}
                      />
                    </Grid>
                  )}
                  <Grid item className={classes.leftSiblingGridField} xs={12} sm={12} md={6} lg={6}>
                    <QCXFinalCheckboxField
                      id="checkbox-exportador-nao-e-fornecedor"
                      key="checkbox-exportador-nao-e-fornecedor"
                      name="due.exportadorNaoFabricante"
                      label={t('com.muralis.qcx.exportador.naoFornecedor')}
                      disabled={isConsult}
                    />
                  </Grid>
                  <Grid item className={classes.gridField} xs={12} sm={12} md={6} lg={6}>
                    <QCXFinalNumericIntegerField
                      id="numeric-integer-prazo-solicitado"
                      key="numeric-integer-prazo-solicitado"
                      name="due.prazoSolicitado"
                      label={t('com.muralis.qcx.prazoSolicitado')}
                      allowNegative={false}
                      disabled={isConsult}
                      maxLength={3}
                      format={formatNotZero}
                    />
                  </Grid>
                  {exportadorNaoFabricante(values) && (
                    <Grid item className={classes.gridField} xs={12} sm={12} md={6} lg={6}>
                      <QCXSelectEmpresaEstrangeiraAutocomplete
                        id="select-field-fornecedor"
                        key="select-field-fornecedor"
                        name="due.fornecedor.id"
                        label={t('com.muralis.qcx.fornecedor.label')}
                        initialValues={values}
                        filterPredicate={fabricanteFilterPredicate}
                        disabled={isConsult}
                      />
                    </Grid>
                  )}
                  {!(followUpHasMetadata || isTipoDocumentoFiscalSemNotaFiscal) && (
                    <QCXDadosNotaFiscalFormGroup disabled={isConsult} fieldProps={dadosNotaFiscalFieldProps} />
                  )}
                </Grid>
                <QCXJustificativaMercadoriaGroup disabled={isConsult} />
                <QCXLPCOFormGroup isParentConsult={isConsult} disabled={isConsult} />
                {values?.ignorableFields?.differentNcms && (
                  <Box mb={1} mt={2}>
                    <QCXInfoAlert>Para incluir atributos, selecione um NCM por vez.</QCXInfoAlert>
                  </Box>
                )}
                <QCXFinalAtributoNcmMercadoriaGroup
                  shouldAllwaysDisplay
                  isParentConsult={isConsult}
                  disabled={isConsult}
                />
              </Grid>
            </QCXFinalFormDataReplicator>
          </>
          <QCXConfirmDialog
            key="confirm-dialog-remove-item"
            id="confirm-dialog-remove-item"
            open={operationOfReplicate?.active}
            title={operationOfReplicate?.title}
            content={operationOfReplicate?.message}
            endContent={operationOfReplicate?.endMessage}
            onConfirm={operationOfReplicate.confirm}
            onClose={operationOfReplicate?.reset}
          />
        </>
      )}
    </QCXFormStepWizard>
  );
}
