import { useMemo } from "react";
import { UseMutateFunction } from "react-query";
import {
  QueriesTypes,
  useDeleteRequest,
  useGetRequest,
  usePostRequest,
  usePutRequest,
} from "../../../../common/hooks/queries";
import { CadastroProposta } from "../common/propostasCadastros.type";

export const useListCadastroPropostaById = (
  id: number | undefined,
  queryParams: string | undefined
): [CadastroProposta[], boolean, boolean] => {
  const {
    data: propostasCadastrosData,
    isError,
    isLoading,
  } = useGetRequest(QueriesTypes.CadastrosProposta, id, queryParams);

  const propostasCadastros: CadastroProposta[] = useMemo(() => {
    if (!id) {
      return [];
    }
    if (propostasCadastrosData && !isError) {
      return propostasCadastrosData;
    } else {
      return [];
    }
  }, [propostasCadastrosData, isError, id]);

  return [propostasCadastros, isLoading, isError];
};

export const useCreateCadastroProposta = (): [
  UseMutateFunction<
    any,
    unknown,
    {
      requestPayload: {
        [key: string]: any;
      };
      queryParams?:
        | {
            clientId?: string | number;
            step?: number;
          }
        | undefined;
    }
  >,
  boolean,
  boolean
] => {
  const { isLoading, isError, mutate } = usePostRequest(
    QueriesTypes.CadastrosProposta
  );

  return [mutate, isLoading, isError];
};

export const useDeleteCadastroProposta = (): [
  UseMutateFunction<
    any,
    unknown,
    {
      id?: string | number;
      nome?: string;
      clienteId?: number;
    }
  >,
  boolean,
  boolean
] => {
  const { isLoading, isError, mutate } = useDeleteRequest(
    QueriesTypes.CadastrosProposta
  );

  return [mutate, isLoading, isError];
};

export const useUpdateCadastroProposta = (): [
  UseMutateFunction<any, unknown, { [key: string]: any }>,
  boolean,
  boolean
] => {
  const { isLoading, isError, mutate } = usePutRequest(
    QueriesTypes.CadastrosProposta
  );

  return [mutate, isLoading, isError];
};
