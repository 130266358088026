import { Grid, makeStyles } from '@material-ui/core';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectRelatedPagosRecebidos } from '../../features/faturamento/faturamentoSelectors';
import QCXDataGrid from '../../shared-components/data-grid/QCXDataGrid';
import { unnormalizeNumeral } from '../../utils/general/general-utils';
import NumerarioImportacaoUtils from '../../utils/general/numerario-importacao/NumerarioImportacaoUtils';
import { formatBrazilianNumericDecimal } from '../../utils/hooks/form/field/formatters';
import QCXFormSubtitle from '../form-title/QCXFormSubtitle';

const useStyles = makeStyles(() => ({
  gridFields: {
    paddingBottom: '16px',
  },
  subtitle: {
    margin: '16px 0',
  },
}));

export default function QCXDespesaReceitaNumerario() {
  const classes = useStyles();

  const { t } = useTranslation();

  const pagosRecebidos = useSelector(selectRelatedPagosRecebidos);

  const pagosRecebidosRows = useMemo(() => (
    pagosRecebidos
  ), [pagosRecebidos]);

  const columns = useMemo(() => ([
    {
      field: 'numerario',
      headerName: t('com.muralis.qcx.numerario.label'),
      flex: 90,
      type: 'string',
      headerAlign: 'center',
      align: 'center',
      valueGetter: ({ row }) => (
        NumerarioImportacaoUtils.formatIdentificacao(
          row?.numerario?.identificacao
        ) || '-'
      ),
    },
    {
      field: 'despesaReceita.description',
      headerName: t('com.muralis.qcx.despesa.despesaReceita'),
      headerAlign: 'center',
      align: 'center',
      flex: 170,
      valueGetter: ({ row }) => (
        row?.solicitacaoPagamento?.despesaReceita?.description
        || row?.titulo?.description
        || '-'
      ),
    },
    {
      field: 'origem',
      headerName: t('com.muralis.qcx.origem'),
      flex: 140,
      type: 'string',
      headerAlign: 'center',
      align: 'center',
      valueGetter: ({ row }) => (
        row?.origem?.replace('_', ' ') || '-'
      ),
    },
    {
      field: 'valor',
      headerName: t('com.muralis.qcx.valor'),
      flex: 80,
      type: 'string',
      headerAlign: 'center',
      align: 'center',
      valueGetter: ({ row: { totalMoeda } }) => (
        unnormalizeNumeral(
          totalMoeda,
          formatBrazilianNumericDecimal(2)
        ) || '-'
      ),
    },
    {
      field: 'valorReais',
      headerName: t('com.muralis.qcx.moeda.valorReais'),
      flex: 90,
      type: 'string',
      headerAlign: 'center',
      align: 'center',
      valueGetter: ({ row: { totalReal } }) => (
        unnormalizeNumeral(
          totalReal,
          formatBrazilianNumericDecimal(2)
        ) || '-'
      ),
    }
  ]), []);

  return (
    <>
      <Grid
        item
        xs={12}
      >
        <QCXFormSubtitle
          className={classes.subtitle}
          title={t('com.muralis.qcx.despesaReceitasModeloNumerario')}
        />
      </Grid>
      <Grid
        item
        xs={12}
      >
        <QCXDataGrid
          columns={columns}
          rows={pagosRecebidosRows}
        />
      </Grid>
    </>
  );
}
