import { Grid } from '@material-ui/core';
import { t } from 'i18next';
import { useCallback, useMemo } from 'react';
import { v4 as uuid } from 'uuid';
import {
  vinculoReferenciaClienteFollowUpActions,
  vinculoReferenciaClienteFollowUpSelectors,
} from '../../../features/vinculo-referencia-cliente-follow-up/vinculoReferenciaClienteFollowUpSlice';
import QCXFinalBondManagerOld from '../../final-gerenciador-relacionamento/QCXFinalBondManagerOld';
import QCXFinalTextField from '../../final-text-field/QCXFinalTextField';
import { isStrictEquals } from '../../../utils/general/general-utils';

function ReferenciaClienteBondManager({ isConsult }) {
  const referenciaClienteReducerConfig = useMemo(
    () => ({
      selectors: {
        selectStatus: vinculoReferenciaClienteFollowUpSelectors.selectStatus,
        selectMode: vinculoReferenciaClienteFollowUpSelectors.selectMode,
        selectModel: vinculoReferenciaClienteFollowUpSelectors.selectModel,
      },
      actions: {
        loading: vinculoReferenciaClienteFollowUpActions.loading,
        resetStatus: vinculoReferenciaClienteFollowUpActions.resetStatus,
        changeToUpdateMode: vinculoReferenciaClienteFollowUpActions.changeToUpdateMode,
        changeToCreateMode: vinculoReferenciaClienteFollowUpActions.changeToCreateMode,
        resetMode: vinculoReferenciaClienteFollowUpActions.resetMode,
        setModel: vinculoReferenciaClienteFollowUpActions.setModel,
        resetModel: vinculoReferenciaClienteFollowUpActions.resetModel,
      },
    }),
    []
  );

  const referenciaClienteFormProps = useMemo(
    () => ({
      rootName: 'ignorableFields.referenciaCliente',
      fields: [
        {
          name: 'referencia',
          label: t('com.muralis.qcx.referenciaCliente'),
        },
      ],
    }),
    []
  );

  const referenciaClienteListProps = useMemo(
    () => ({
      name: 'followUp.referencias',
      columns: [
        {
          field: 'referencia',
          headerName: t('com.muralis.qcx.referencia'),
          headerAlign: 'left',
          align: 'left',
          flex: 140,
          valueGetter: ({ row }) => row?.referencia || '',
        },
      ],
    }),
    []
  );

  const handleAddReferenciaCliente = useCallback(async (currentVinculo, currentVinculos, handleSuccessAddVinculo) => {
    const vinculo = {
      id: uuid(),
      referencia: currentVinculo?.referencia,
    };

    const updatedVinculos = [...currentVinculos, vinculo];

    handleSuccessAddVinculo(updatedVinculos);
  }, []);

  const handleUpdateReferenciaCliente = useCallback(
    async (currentVinculo, currentVinculos, handleSuccessUpdateVinculo) => {
      const updatedVinculo = {
        ...currentVinculo,
        referencia: currentVinculo?.referencia,
      };

      const updatedVinculos =
        currentVinculos?.map((existingVinculo) =>
          isStrictEquals(existingVinculo?.id, updatedVinculo?.id) ? updatedVinculo : existingVinculo
        ) || currentVinculos;

      handleSuccessUpdateVinculo(updatedVinculos);
    },
    []
  );

  const handleReferenciaClienteAlreadyExists = useCallback(
    (currentVinculo, currentVinculosList) =>
      currentVinculosList.some(
        (vinculo) =>
          isStrictEquals(vinculo?.referencia, currentVinculo?.referencia) &&
          !isStrictEquals(vinculo?.id, currentVinculo?.id)
      ),
    []
  );

  return (
    <>
      <QCXFinalBondManagerOld
        isParentConsult={isConsult}
        reducerConfig={referenciaClienteReducerConfig}
        listProps={referenciaClienteListProps}
        formProps={referenciaClienteFormProps}
        readOnlyMode
        controlButtonsGridProps={{
          add: {
            xs: 12,
            sm: 4,
            md: 4,
          },
          update: {
            xs: 6,
            sm: 2,
            md: 2,
          },
          clear: {
            xs: 6,
            sm: 2,
            md: 2,
          },
        }}
        handleAdd={handleAddReferenciaCliente}
        handleUpdate={handleUpdateReferenciaCliente}
        handleAlreadyExists={handleReferenciaClienteAlreadyExists}
      >
        {({ loading }) => (
          <Grid item xs={12}>
            <QCXFinalTextField
              id="text-field-vinculo-referencia-cliente"
              key="text-field-vinculo-referencia-cliente"
              name="ignorableFields.referenciaCliente.referencia"
              label={t('com.muralis.qcx.referenciaCliente')}
              maxLength={20}
              disabled={isConsult || loading}
            />
          </Grid>
        )}
      </QCXFinalBondManagerOld>
    </>
  );
}

export default ReferenciaClienteBondManager;
