/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-vars */
import { makeStyles } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';

const useStyles = makeStyles(() => ({
  root: {
    width: 'calc(100% - 8px)',
    '& .MuiDataGrid-footer, & .MuiTablePagination-toolbar': {
      minHeight: '42px!important',
      '& .MuiIconButton-root': {
        paddingTop: '4px',
        paddingBottom: '4px',
      },
    },
  },
  columnsContainer: {
    height: '40px',
  },
}));
export default function QCXDataGrid({
  divProps,
  ...restProps
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  // const numberOfRows = rows.length;
  // const currentPageSize = numberOfRows <= pageSize
  //   ? numberOfRows
  //   : numberOfRows / Math.ceil(numberOfRows / pageSize);
  // const minHeight = (56 * 2) + (currentPageSize * 56);
  // // const minHeight = `100.${56 * 2}${currentPageSize * 56}%`;
  // console.log(minHeight);
  const ddg = ({
    columns = [],
    rows = [],
    pageSize = 5,
    disableColumnMenu = true,
    disableColumnReorder = true,
    disableSelectionOnClick = true,
    loading = false,
    error = null,
    localeText = {
      noRowsLabel: t('com.muralis.qcx.mensagem.nenhumRegistroEncontrado'),
      noResultsOverlayLabel: t('com.muralis.qcx.mensagem.nenhumRegistroEncontrado'),
      errorOverlayDefaultLabel: t('com.muralis.qcx.erro.unspecified'),
      rootGridLabel: {
      },
      footerRowSelected: (count) => (count !== 1
        ? t('com.muralis.qcx.linha.linhaSelecionadaPlural', { count: count.toLocaleString() })
        : t('com.muralis.qcx.linha.linhaSelecionada', { count: count.toLocaleString() })),
    },
    ...ddgRestProps
  }) => (
    <div
      className={classes.root}
      {...divProps}
    >
      <DataGrid
        className="custom-table"
        columns={columns}
        rows={rows}
        pageSize={pageSize}
        disableColumnMenu={disableColumnMenu}
        disableColumnReorder={disableColumnReorder}
        disableSelectionOnClick={disableSelectionOnClick}
        loading={loading}
        error={error}
        localeText={localeText}
        autoHeight
        rowHeight={38}
        headerHeight={42}
        {...ddgRestProps}
      />
    </div>
  );
  const [Table, setTable] = useState(() => ddg);
  useEffect(() => {
    setTable(() => ddg);
  }, [restProps?.rows?.length]);
  return (
    <>
      <Table {...restProps} />
    </>
  );
}
