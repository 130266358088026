import React from 'react';
import { MoveFocusInside } from 'react-focus-lock';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    width: 'inherit',
  },
}));

export default function QCXMoveFocusInside({
  children,
  ...restProps
}) {
  const classes = useStyles();

  return (
    <>
      <MoveFocusInside
        className={classes.root}
        {...restProps}
      >
        {children}
      </MoveFocusInside>
    </>
  );
}
