import { Grid, makeStyles } from '@material-ui/core';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormState } from 'react-final-form';
import { useSelector } from 'react-redux';
import { selectRegimesTributacao } from '../../features/regime-tributacao/regimeTributacaoSlice';
import QCXFinalPercentualField from '../final-percentual-field/QCXFinalPercentualField';
import QCXSelectDispositivoLegalAutocomplete from '../final-select-dispositivo-legal-field/QCXSelectDispositivoLegalAutocomplete';
import QCXFinalSelectTipoReducaoAutocomplete from '../final-select-tipo-reducao-field/QCXFinalSelectTipoReducaoAutocomplete';
import QCXSelectRegimeTributacaoAutocomplete from '../select-regime-tributacao/QCXSelectRegimeTributacaoAutocomplete';
import {
  configureGeneralRegimeTributacaoMatcherWith,
  REGIME_TRIBUTACAO_NAO_INCIDENCIA_CODE,
  REGIME_TRIBUTACAO_RECOLHIMENTO_INTEGRAL_CODE,
  REGIME_TRIBUTACAO_REDUCAO_CODE,
} from '../select-regime-tributacao/regimeTributacaoUtils';

const useStyles = makeStyles((theme) => ({
  gridFields: {
    paddingBottom: '16px',
  },
  leftSiblingGridField: {
    paddingBottom: '16px',
    [theme.breakpoints.up('md')]: {
      paddingRight: '8px',
    },
  },
  rightSiblingGridField: {
    paddingBottom: '16px',
    [theme.breakpoints.up('md')]: {
      paddingLeft: '8px',
    },
  },
}));

export default function QCXIcmsCommonFormGroup({ modes }) {
  const { t } = useTranslation();

  const classes = useStyles();

  const { values } = useFormState();

  const regimesTributacao = useSelector(selectRegimesTributacao);

  const regimeTributacao = useMemo(() => values?.icms?.regimeTributacao?.id, [values]);

  const isSomeRegimeTributacaoBy = useCallback(
    (codesToCompare) =>
      configureGeneralRegimeTributacaoMatcherWith(regimesTributacao)(regimeTributacao, codesToCompare),
    [regimeTributacao, regimesTributacao]
  );

  const { isConsult, isSubConsult, isSubNone } = modes;

  return (
    <>
      <Grid item container alignContent="flex-start" xs={12} sm={6} md={6} lg={6}>
        <Grid item className={classes.gridFields} xs={12} sm={12} md={12} lg={12}>
          <QCXSelectRegimeTributacaoAutocomplete
            id="select-field-regime-tributacao-icms"
            key="select-field-regime-tributacao-icms"
            name="icms.regimeTributacao.id"
            label={t('com.muralis.qcx.regimeTributacao')}
            initialValues={values}
            disabled={isConsult || isSubConsult || isSubNone}
          />
        </Grid>
        {!isSomeRegimeTributacaoBy([REGIME_TRIBUTACAO_RECOLHIMENTO_INTEGRAL_CODE]) && (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <QCXSelectDispositivoLegalAutocomplete
              id="select-field-dispositivo-legal-icms"
              key="select-field-dispositivo-legal-icms"
              name="icms.dispositivoLegal.id"
              label={t('com.muralis.qcx.dispositivoLegal.label')}
              initialValues={values}
              disabled={isConsult || isSubConsult || isSubNone}
            />
          </Grid>
        )}
      </Grid>
      <Grid item container alignContent="flex-start" xs={12} sm={6} md={6} lg={6}>
        {!isSomeRegimeTributacaoBy([REGIME_TRIBUTACAO_NAO_INCIDENCIA_CODE]) && (
          <Grid item className={classes.gridFields} xs={12} sm={12} md={12} lg={12}>
            <QCXFinalPercentualField
              id="percentual-icms-field-icms"
              key="percentual-icms-field-icms"
              name="icms.aliquotaOriginal"
              label={t('com.muralis.qcx.aliquotaICMSPercentual')}
              scale={7}
              disabled={isConsult || isSubConsult || isSubNone}
            />
          </Grid>
        )}
        <Grid item className={classes.gridFields} xs={12} sm={12} md={12} lg={12}>
          <QCXFinalPercentualField
            id="percentual-fecp-icms-field-icms"
            key="percentual-fecp-icms-field-icms"
            name="icms.aliquotaFecp"
            label={t('com.muralis.qcx.aliquotaFECP')}
            disabled={isConsult || isSubConsult || isSubNone}
            scale={7}
          />
        </Grid>
        <Grid item container xs={12} sm={12} md={12} lg={12}>
          {isSomeRegimeTributacaoBy([REGIME_TRIBUTACAO_REDUCAO_CODE]) && (
            <Grid item className={classes.leftSiblingGridField} xs={12} sm={12} md={6} lg={6}>
              <QCXFinalSelectTipoReducaoAutocomplete
                id="select-field-tipo-de-reducao-icms"
                key="select-field-tipo-de-reducao-icms"
                name="icms.tipoReducao"
                label={t('com.muralis.qcx.tipoReducao.label')}
                initialValues={values}
                disabled={isConsult || isSubConsult || isSubNone}
              />
            </Grid>
          )}
          {isSomeRegimeTributacaoBy([REGIME_TRIBUTACAO_REDUCAO_CODE]) && (
            <Grid item className={classes.rightSiblingGridField} xs={12} sm={12} md={6} lg={6}>
              <QCXFinalPercentualField
                id="percentual-icms-field-icms"
                key="percentual-icms-field-icms"
                name="icms.percentualReducao"
                label={t('com.muralis.qcx.reducaoICMSPercentual')}
                scale={7}
                disabled={isConsult || isSubConsult || isSubNone}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
}
