import { Grid } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import QCXFinalTextField from '../../shared-components/final-text-field/QCXFinalTextField';

export default function QCXDeclaracaoImportacaoConsumoAdimissaoTemporariaFormGroup({
  isConsult,
  classes,
}) {
  const { t } = useTranslation();
  return (
    <>
      <Grid
        item
        className={classes.gridFields}
        xs={12}
        sm={12}
        md={6}
        lg={6}
      >
        <QCXFinalTextField
          id="text-field-fornecedor-tempo-permanencia"
          key="text-field-fornecedor-tempo-permanencia"
          name="atributosAdicionais.tempoPermanencia"
          label={t('com.muralis.qcx.tempoPermanencia')}
          disabled={isConsult}
        />
      </Grid>
    </>
  );
}
