import { every, includes } from 'lodash';
import formatters from './formatters';

export default function useFieldFormat(types) {
  const foundFormatter = formatters
    .find((formatter) => (
      every(formatter.types, ((target) => (
        includes(types, target)
      )))
    ));
  return foundFormatter?.fn;
}
