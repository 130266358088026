const selectConciliacoesBancarias = (state) => state.conciliacaoBancaria.list;
const selectConciliacaoBancaria = (state) => state.conciliacaoBancaria.model;
const selectStatus = (state) => state.conciliacaoBancaria.status;
const selectMode = (state) => state.conciliacaoBancaria.mode.main;
const selectBackgroundMode = (state) => state.conciliacaoBancaria.mode.background;
const selectSubMode = (state) => state.conciliacaoBancaria.mode.sub;
const selectConfigControl = (state) => state.conciliacaoBancaria.config.control;
const selectError = (state) => state.conciliacaoBancaria.error;
const selectResponse = (state) => state.conciliacaoBancaria.response;
const selectRelatedSelectionConciliacaoBancariaList = (state) => (
  state.conciliacaoBancaria.related.list.selectionConciliacaoBancaria
);
const selectRelatedReportModel = (state) => state.conciliacaoBancaria.related.model.report;
const selectRefresh = (state) => state.conciliacaoBancaria.refresh;

const conciliacaoBancariaSelectors = {
  selectConciliacoesBancarias,
  selectConciliacaoBancaria,
  selectConfigControl,
  selectStatus,
  selectRefresh,
  selectMode,
  selectBackgroundMode,
  selectRelatedReportModel,
  selectSubMode,
  selectRelatedSelectionConciliacaoBancariaList,
  selectError,
  selectResponse,
};

export {
  conciliacaoBancariaSelectors,
  selectConciliacoesBancarias,
  selectConciliacaoBancaria,
  selectConfigControl,
  selectStatus,
  selectRefresh,
  selectMode,
  selectBackgroundMode,
  selectRelatedReportModel,
  selectSubMode,
  selectRelatedSelectionConciliacaoBancariaList,
  selectError,
  selectResponse,
};
