import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import PTBR from './locales/pt-br/pt-BR.json';
import ENUS from './locales/en/en.json';
import ES from './locales/es/es.json';

const resources = {
  'pt-BR': PTBR,
  en: ENUS,
  es: ES,
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    interpolation: {
      escapeValue: false,
    },
    fallbackLng: 'pt-BR',
  });

export default i18n;
