import { every, includes } from 'lodash';
import parsers, { configureIntegerParser } from './parsers';

export default function useFieldParse(targets) {
  const foundParser = parsers
    .find((parser) => (
      every(parser.targets, ((target) => (
        includes(targets, target)
      )))
    ));
  return foundParser?.fn;
}

const useIntegerParser = ({ allowNegative = false }) => (
  configureIntegerParser(allowNegative)
);

export {
  useIntegerParser,
};
