import i18n from '../../i18n';

const OPTANTE_SIMPLES_NACIONAL_KEY = 'OPTANTE';
const NAO_OPTANTE_SIMPLES_NACIONAL_KEY = 'NAO_OPTANTE';

const OPTANTE_SIMPLES_NACIONAL_LIST = [
  {
    label: i18n.t('com.muralis.qcx.eOptante'),
    value: OPTANTE_SIMPLES_NACIONAL_KEY,
  },
  {
    label: i18n.t('com.muralis.qcx.naoEOptante'),
    value: NAO_OPTANTE_SIMPLES_NACIONAL_KEY,
  },
];

export default OPTANTE_SIMPLES_NACIONAL_LIST;
