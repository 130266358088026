import { getOptionByValueInList } from '../../utils/general/general-utils';
import i18n from '../../i18n';

const TIPO_ACRESCIMO = 'ACRESCIMO';
const TIPO_DEDUCAO = 'DEDUCAO';

const TIPO_ACRESCIMO_DEDUCAO_LIST = [
  {
    label: i18n.t('com.muralis.qcx.acrescimo').toUpperCase(),
    value: TIPO_ACRESCIMO,
  },
  {
    label: i18n.t('com.muralis.qcx.deducao').toUpperCase(),
    value: TIPO_DEDUCAO,
  },
];

const isTipoAcrescimo = (value) => (
  TIPO_ACRESCIMO === value
);

const isTipoDeducao = (value) => (
  TIPO_DEDUCAO === value
);

const getTipoAcrescimoDeducaoLabelByValue = (value) => {
  const foundOption = getOptionByValueInList(TIPO_ACRESCIMO_DEDUCAO_LIST, value);
  return foundOption && foundOption?.label;
};

export {
  TIPO_ACRESCIMO,
  TIPO_DEDUCAO,
  TIPO_ACRESCIMO_DEDUCAO_LIST,
};

export {
  isTipoAcrescimo,
  isTipoDeducao,
  getTipoAcrescimoDeducaoLabelByValue,
};
