import { Container } from '@material-ui/core';
import debounce from 'lodash/debounce';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { validate } from 'uuid';
import QCXMainLayout from '../../../../../components/main-layout/QCXMainLayout';
import QCXModeloNumerarioWizardFinalForm from '../../../../../components/modelo-numerario/QCXModeloNumerarioWizardFinalForm';
import { register, save } from '../../../../../features/modelo-numerario/modeloNumerarioAPI';
import {
  addToList,
  changeToBackgroundCreateMode,
  changeToConsultMode,
  changeToCreateMode,
  changeToUpdateMode,
  failure,
  fetchByIdAsync,
  loading,
  resetBackgroundMode,
  resetModel,
  resetRelatedModeloLancamentoModel,
  resetSubMode,
  selectBackgroundMode,
  selectMode,
  selectModeloNumerario,
  setError,
  setModel,
  setResponse,
  success,
  updateOnList,
} from '../../../../../features/modelo-numerario/modeloNumerarioSlice';
import { unnormalizeNumeral } from '../../../../../utils/general/general-utils';
import {
  isBackgroundCreateMode,
  isConsultMode,
  isCreateMode,
  isUpdateMode,
} from '../../../../../utils/store/store-utils';

export default function ModeloNumerarioRegistrationPage({ authInfo = {} }) {
  const { t } = useTranslation();

  const { id } = useParams();

  const history = useHistory();

  const dispatch = useDispatch();

  const [isCreated, setIsCreated] = useState(false);

  const mode = useSelector(selectMode);
  const backgroundMode = useSelector(selectBackgroundMode);

  const modeloNumerario = useSelector(selectModeloNumerario);

  const isCreate = useMemo(() => isCreateMode(mode), [mode]);

  const isConsult = useMemo(() => isConsultMode(mode), [mode]);

  const isUpdate = useMemo(() => isUpdateMode(mode), [mode]);

  const isBackgroundCreate = useMemo(() => isBackgroundCreateMode(backgroundMode), [backgroundMode]);

  const handleChangeToCreate = () => {
    dispatch(changeToCreateMode());
  };

  const handleChangeToConsult = () => {
    dispatch(changeToConsultMode());
    dispatch(resetRelatedModeloLancamentoModel());
    dispatch(resetSubMode());
  };

  const handleChangeToUpdate = () => {
    dispatch(changeToUpdateMode());
  };

  const handleCancelUpdate = () => {
    const currentId = isBackgroundCreate ? modeloNumerario?.id : id;

    if (currentId) {
      dispatch(fetchByIdAsync(currentId));
    }
    handleChangeToConsult();
  };

  const handleChangeToBackgroundCreate = () => {
    dispatch(changeToBackgroundCreateMode());
  };

  const handleResetBackgroundMode = () => {
    dispatch(resetBackgroundMode());
  };

  useEffect(() => {
    if (id) {
      dispatch(fetchByIdAsync(id));
      handleChangeToConsult();
    }
  }, [id]);

  const normalize = useCallback((unnormalizedData) => {
    const normalizedData = {
      ...unnormalizedData,
      modelosLancamento: (unnormalizedData?.modelosLancamento || []).map((item) => ({
        ...item,
        id: validate(item.id) ? null : item.id,
      })),
    };
    return normalizedData;
  }, []);

  const unnormalize = useCallback(
    (normalizedData) => {
      const unnormalizedData = {
        ...normalizedData,
      };

      return unnormalizedData;
    },
    [unnormalizeNumeral]
  );

  const handleDispatchSetModel = useCallback(
    (data) => {
      dispatch(setModel(normalize(data)));
    },
    [normalize]
  );

  const createByStep = async (data, step, next) => {
    const isFirstStep = step === 0;
    const isLastStep = step === 1;

    const executeDebounced = debounce(async () => {
      try {
        if (isFirstStep && !isCreated) {
          const response = await register(data);

          if (response?.status === 201) {
            next();
            dispatch(resetModel());
            dispatch(success());

            const created = response?.data;

            handleDispatchSetModel(created);
            dispatch(addToList({ data: created }));

            setIsCreated(true);
          }
        } else if (isLastStep) {
          const response = await save(data);

          if (response?.status === 200) {
            dispatch(resetModel());

            const handleResultWithDebounce = debounce(() => {
              history.push(t('com.muralis.qcx.url.moduloGeraisServicosModeloNumerario'));

              dispatch(success());

              const saved = response?.data;

              dispatch(setModel(saved));

              dispatch(
                setResponse({
                  status: response.status,
                  data: saved,
                  message: t('com.muralis.qcx.mensagem.modeloNumerarioCadastrado', { nome: saved?.description }),
                })
              );

              dispatch(updateOnList({ data: saved }));
            }, 500);

            handleResultWithDebounce();
          }
        } else {
          const response = await save(data);

          if (response?.status === 200) {
            if (isCreate) {
              next();
            } else {
              handleChangeToConsult();
            }
            const saved = response?.data;

            dispatch(success());
            dispatch(setModel(saved));
            dispatch(updateOnList({ data: saved }));
          }
        }
      } catch (error) {
        dispatch(failure());
        dispatch(
          setError({
            message: t('com.muralis.qcx.erro.erroSalvarModeloNumerarioEspecifico', {
              erro: error?.response?.data?.message,
            }),
          })
        );
      }
    }, 500);

    dispatch(loading());
    executeDebounced();
  };

  const update = async (data) => {
    const executeDebounced = debounce(async () => {
      try {
        const response = await save(data);

        if (response?.status === 200) {
          const handleResultWithDebounce = debounce(() => {
            handleChangeToConsult();
            dispatch(success());

            const saved = response?.data;

            dispatch(
              setResponse({
                status: response.status,
                data: saved,
                message: t('com.muralis.qcx.mensagem.modeloNumerarioSalvo', { nome: saved?.description }),
              })
            );

            dispatch(setModel(saved));
            dispatch(updateOnList({ data: saved }));
            dispatch(fetchByIdAsync(saved?.id));
          }, 500);

          handleResultWithDebounce();
        }
      } catch ({ response }) {
        dispatch(failure());
        dispatch(
          setError({
            message: t('com.muralis.qcx.erro.erroSalvarModeloNumerario', {
              nome: data?.description,
              erro: response?.data?.message,
            }),
          })
        );
      }
    }, 500);

    dispatch(loading());
    executeDebounced();
  };

  const handleSubmit = async (data, step, next) => {
    const normalizedData = normalize(data);

    if (isUpdate && !isBackgroundCreate) {
      await update(normalizedData, step);
      handleChangeToConsult();
    } else {
      await createByStep(normalizedData, step, next);
    }
  };

  const handleAlternativeSave = async (event, step, next) => {
    event.stopPropagation();

    const normalizedData = normalize(modeloNumerario);

    if (isUpdate) {
      await update(normalizedData);
    } else {
      createByStep(normalizedData, step, next);
    }
  };

  const model = useMemo(
    () => (isCreated || !isCreate ? unnormalize(modeloNumerario) : {}),
    [isCreate, isCreated, modeloNumerario, unnormalize]
  );

  const actionName = useMemo(() => {
    if (isCreate || isBackgroundCreate) return t('com.muralis.qcx.acoes.novo');
    if (isConsult) return t('com.muralis.qcx.acoes.visualizar');
    return t('com.muralis.qcx.acoes.alterar');
  }, [isCreate, isConsult, isBackgroundCreate]);

  const breadcrumbs = [
    {
      link: {
        to: '/',
        name: t('com.muralis.qcx.inicio'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.cadastros'),
      },
    },
    {
      link: {
        to: t('com.muralis.qcx.url.moduloCadastrosGerais'),
        name: t('com.muralis.qcx.gerais'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.servico.labelPlural'),
      },
    },
    {
      link: {
        to: t('com.muralis.qcx.url.moduloGeraisServicosModeloNumerario'),
        name: t('com.muralis.qcx.modelosNumerario'),
      },
    },
    {
      text: {
        name: actionName,
      },
    },
  ];

  const pageTitle = useMemo(
    () =>
      isCreate || isBackgroundCreate
        ? t('com.muralis.qcx.numerario.novoModeloNumerario')
        : t('com.muralis.qcx.numerario.modeloNumerarioExistente', {
            identificacao: modeloNumerario?.description || modeloNumerario?.code,
          }),
    [isUpdate, isConsult, isBackgroundCreate, modeloNumerario]
  );

  const breadcrumbsProps = {
    maxItems: 6,
  };

  return (
    <QCXMainLayout
      pageTitle={pageTitle}
      breadcrumbs={breadcrumbs}
      breadcrumbsProps={breadcrumbsProps}
      authInfo={authInfo}
    >
      <Container style={{ display: 'flex', padding: '0 1rem 3rem 1rem' }}>
        <QCXModeloNumerarioWizardFinalForm
          isCreate={isCreate}
          isConsult={isConsult}
          isUpdate={isUpdate}
          isBackgroundCreate={isBackgroundCreate}
          model={model}
          handleChangeModel={handleDispatchSetModel}
          handleSubmit={handleSubmit}
          handleChangeToCreate={handleChangeToCreate}
          handleChangeToConsult={handleChangeToConsult}
          handleAlternativeSave={handleAlternativeSave}
          handleChangeToUpdate={handleChangeToUpdate}
          handleCancelUpdate={handleCancelUpdate}
          handleChangeToBackgroundCreate={handleChangeToBackgroundCreate}
          handleResetBackgroundMode={handleResetBackgroundMode}
          authInfo={authInfo}
          requiredRoles={['modelo-numerario']}
        />
      </Container>
    </QCXMainLayout>
  );
}
