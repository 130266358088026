import { Grid, makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useFormState } from 'react-final-form';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { AUTO_LOAD_CONTROL } from '../../features/config-control';
import { maxValue } from '../../utils/validators/field/validator';
import QCXFinalAlternativeCurrencyField from '../final-currency-field/QCXFinalAlternativeCurrencyField';
import QCXFinalNumericDecimalField from '../final-numeric-decimal-field/QCXFinalNumericDecimalField';
import { unidadeDeMedidaActions } from '../../features/unidade-medida/unidadeDeMedidaSlice';
import QCXSelectUnidadeMedidaAutocomplete from '../select-unidade-medida/QCXSelectUnidadeMedidaAutocomplete';

const useStyles = makeStyles(() => ({
  gridFields: {
    paddingBottom: '16px',
  },
}));

export default function QCXAliquotaEspecificaFormGroup({
  intl,
  modes,
  submitSucceeded,
  children,
  fields = {
    rootName: '',
    valorReais: {
      name: '',
    },
    quantidade: {
      name: '',
    },
    unidadeDeMedida: {
      name: '',
    },
  },
}) {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const classes = useStyles();

  const { values } = useFormState();

  useEffect(() => {
    dispatch(unidadeDeMedidaActions.changeControlTo(AUTO_LOAD_CONTROL));
  }, []);

  const {
    isConsult,
    isSubConsult,
    isSubNone,
  } = modes;

  return (
    <>
      <Grid item container xs={12} sm={6} md={6} lg={6}>
        <Grid
          item
          className={classes.gridFields}
          xs={12}
          sm={12}
          md={12}
          lg={12}
        >
          <QCXFinalAlternativeCurrencyField
            id={`currency-valor-reais-${fields?.rootName}`}
            key={`currency-valor-reais-${fields?.rootName}`}
            name={fields?.valorReais?.name}
            label={t('com.muralis.qcx.moeda.valorReais')}
            validate={maxValue(999999999999.99)}
            disabled={isConsult || isSubConsult || isSubNone}
            submitSucceeded={submitSucceeded}
            intl={intl}
            adorned
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
        >
          <QCXFinalNumericDecimalField
            id={`numeric-decimal-quantidade-${fields?.rootName}`}
            key={`numeric-decimal-quantidade-${fields?.rootName}`}
            name={fields?.quantidade?.name}
            label={t('com.muralis.qcx.quantidade.label')}
            disabled={isConsult || isSubConsult || isSubNone}
            scale={7}
          />
        </Grid>
      </Grid>
      <Grid item container xs={12} sm={6} md={6} lg={6}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          className={classes.gridFields}
        >
          <QCXSelectUnidadeMedidaAutocomplete
            id={`autocomplete-select-unidade-medida-${fields?.rootName}`}
            key={`autocomplete-select-unidade-medida-${fields?.rootName}`}
            name={fields?.unidadeDeMedida?.name}
            label={t('com.muralis.qcx.unidadeMedida.label')}
            initialValues={values}
            disabled={isConsult || isSubConsult || isSubNone}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
        >
          {children}
        </Grid>
      </Grid>
    </>
  );
}
