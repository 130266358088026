import { ReponseType, validateQueryParams } from '../../utils/api/api-utils';
import {
  doGetQCXRequest,
  doPostQCXRequest,
  doPutQCXRequest,
} from '../../utils/api/domain/domain-api-utils';
import {
  QCX_FATURAMENTO_ENDPOINT,
  FOLLOW_UP_ENDPOINT,
  IMPORTADOR_ENDPOINT,
  FATURAR_ENDPOINT,
  QCX_CANCELAR_ENDPOINT,
  QCX_RELATORIO_ENDPOINT,
  DEMONSTRACAO_DESPESA_ENDPOINT,
  EXTRATO_ENDPOINT,
  TRANSFERIR_ENDPOINT,
  EMITIR_ENDPOINT,
  NFSE_ENDPOINT,
} from '../../utils/api/domain/domain-endpoint-utils';

async function fetchAll() {
  return doGetQCXRequest([
    QCX_FATURAMENTO_ENDPOINT
  ]);
}

async function fetchById(id) {
  return doGetQCXRequest([
    QCX_FATURAMENTO_ENDPOINT,
    id,
  ]);
}

async function register(data, queryParams = [], config = {}) {
  return doPostQCXRequest(
    [QCX_FATURAMENTO_ENDPOINT],
    data,
    queryParams,
    config
  );
}

async function save(data, queryParams = [], config = {}) {
  return doPutQCXRequest(
    [QCX_FATURAMENTO_ENDPOINT],
    data,
    queryParams,
    config
  );
}

async function filterFollowUpsBy(data, query = []) {
  if (!data?.importador?.id) {
    return Promise.reject();
  }
  return doGetQCXRequest(
    [
      QCX_FATURAMENTO_ENDPOINT,
      FOLLOW_UP_ENDPOINT,
      IMPORTADOR_ENDPOINT,
      data?.importador?.id,
    ],
    query
  );
}

async function faturar(id, data, queryParams = [], config = {}) {
  return doPutQCXRequest(
    [
      QCX_FATURAMENTO_ENDPOINT,
      FATURAR_ENDPOINT,
      id,
    ],
    data,
    queryParams,
    config
  );
}

async function cancelarById(id, config = {}) {
  return doPutQCXRequest(
    [
      QCX_FATURAMENTO_ENDPOINT,
      QCX_CANCELAR_ENDPOINT,
      id
    ],
    {},
    [],
    config
  );
}

async function generateDemonstrativoReportById(id) {
  return doGetQCXRequest(
    [
      QCX_FATURAMENTO_ENDPOINT,
      QCX_RELATORIO_ENDPOINT,
      DEMONSTRACAO_DESPESA_ENDPOINT,
      id
    ],
    [],
    {
      responseType: ReponseType.ARRAY_BUFFER,
    }
  );
}

async function generateReportExtratoContaCorrente(params = []) {
  const validatedParams = validateQueryParams(params);
  if (validatedParams.length) {
    return doGetQCXRequest([
      QCX_FATURAMENTO_ENDPOINT,
      QCX_RELATORIO_ENDPOINT,
      EXTRATO_ENDPOINT
    ],
    validatedParams,
    {
      responseType: 'blob',
    });
  }
  return { data: [] };
}

async function transferirSaldo(data, queryParams = [], config = {}) {
  return doPutQCXRequest(
    [
      QCX_FATURAMENTO_ENDPOINT,
      TRANSFERIR_ENDPOINT
    ],
    data,
    queryParams,
    config
  );
}

async function emitirNFSeById(id) {
  return doGetQCXRequest([
    QCX_FATURAMENTO_ENDPOINT,
    EMITIR_ENDPOINT,
    NFSE_ENDPOINT,
    id,
  ]);
}

const faturamentoAPI = {
  fetchAll,
  fetchById,
  save,
  register,
  filterFollowUpsBy,
  faturar,
  cancelarById,
  generateDemonstrativoReportById,
  generateReportExtratoContaCorrente,
  transferirSaldo,
  emitirNFSeById,
};

export {
  faturamentoAPI,
  fetchAll,
  fetchById,
  filterFollowUpsBy,
  save,
  register,
  faturar,
  cancelarById,
  generateDemonstrativoReportById,
  generateReportExtratoContaCorrente,
  transferirSaldo,
  emitirNFSeById,
};
