import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IDLE_CONTROL } from '../config-control';
import { ALTERNATIVE_LOADING_STATUS, FAILURE_STATUS, IDLE_STATUS, LOADING_STATUS, SUCCESS_STATUS } from '../status';
import { activateById, fetchAll, fetchByFilter, fetchById, inactivateById } from './paisAPI';
import i18n from '../../i18n';

interface PaisState {
  status: string;
  error: {
    message: string;
    name?: string;
    stack?: string;
    code?: string;
  } | null;
  response: {
    status: number | null;
    message: string | null;
    data: any | null;
  };
  config: {
    control: string;
  };
  list: any[];
  model: {
    id: string;
    code: string;
    description: string;
    active: boolean | null;
  };
}

interface Pais {
  active: boolean;
  code: string;
  description: string;
  id: number | null;
  insertionDate: Date | null;
  vigenca: {
    inicio: Date | null;
    fim: Date | null;
  };
}

const initialState: PaisState = {
  status: IDLE_STATUS,
  error: null,
  response: {
    status: null,
    message: null,
    data: null,
  },
  list: [],
  config: { control: IDLE_CONTROL },
  model: {
    id: '',
    code: '',
    description: '',
    active: null,
  },
};

const fetchAllAsync = createAsyncThunk('pais/fetchAll', async () => {
  const { data } = await fetchAll();
  return { data };
});

const fetchByFilterAsync = createAsyncThunk('pais/fetchByFilter', async (params: any[]) => {
  const { data } = await fetchByFilter(params);
  return { data };
});
const fetchByIdAsync = createAsyncThunk('pais/fetchById', async (data) => {
  const response = await fetchById(data);
  return {
    response: {
      status: response.status,
      data: response.data,
    },
  };
});

const activateByIdAsync = createAsyncThunk('pais/activateById', async (id) => {
  const { status, data } = await activateById(id);
  return { response: { status, data } };
});

const inactivateByIdAsync = createAsyncThunk('pais/inactivateById', async (id) => {
  const { status, data } = await inactivateById(id);
  return { response: { status, data } };
});

const paisSlice = createSlice({
  name: 'pais',
  initialState,
  reducers: {
    changeStatusTo: (state, action) => {
      state.status = action.payload.status;
    },
    loading: (state) => {
      state.status = LOADING_STATUS;
    },
    success: (state) => {
      state.status = SUCCESS_STATUS;
    },
    failure: (state) => {
      state.status = FAILURE_STATUS;
    },
    resetStatus: (state) => {
      state.status = IDLE_STATUS;
    },
    addToList: (state, action) => {
      state.list = [...state.list, action.payload.data];
    },
    updateOnList: (state, action) => {
      state.list = state.list.map((current) => {
        if (current.id === action.payload.data?.id) {
          return action.payload.data;
        }
        return current;
      });
    },
    setResponse: (state, action) => {
      state.response = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    clearResponse: (state) => {
      state.response = {
        status: null,
        message: null,
        data: null,
      };
    },
    resetModel: (state) => {
      state.model = initialState.model;
    },
    setConfig: (state, action) => {
      state.config = action.payload;
    },
    changeControlTo: (state, action) => {
      state.config.control = action.payload;
    },
    resetList: (state) => {
      state.list = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllAsync.pending, (state) => {
        state.status = ALTERNATIVE_LOADING_STATUS;
      })
      .addCase(fetchAllAsync.fulfilled, (state, action) => {
        state.status = IDLE_STATUS;
        state.list = action.payload.data;
      })
      .addCase(fetchAllAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.carregarListaPaises', { erro: action.error.message }),
        };
      })
      .addCase(fetchByFilterAsync.pending, (state) => {
        state.status = ALTERNATIVE_LOADING_STATUS;
      })
      .addCase(fetchByFilterAsync.fulfilled, (state, action) => {
        state.status = IDLE_STATUS;
        state.list = action.payload.data;
      })
      .addCase(fetchByFilterAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.carregarListaPaises', { erro: action.error.message }),
        };
      })
      .addCase(fetchByIdAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(fetchByIdAsync.fulfilled, (state, action) => {
        if (action.payload.response.status === 200) {
          state.model = action.payload.response.data;
          state.status = SUCCESS_STATUS;
        }
      })
      .addCase(fetchByIdAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.carregarDadosPais', { erro: action.error.message }),
        };
      })
      .addCase(activateByIdAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(activateByIdAsync.fulfilled, (state, action) => {
        state.status = SUCCESS_STATUS;
        state.response.status = action.payload.response.status;
        state.response.data = action.payload.response.data;

        const { description } = action.payload.response.data;
        state.response.message = i18n.t('com.muralis.qcx.mensagem.paisAtivado', { pais: description });

        if (state.response.status === 200) {
          state.list = state.list.map((current) =>
            current.id === action.payload.response.data.id ? action.payload.response.data : current
          );
        }
      })
      .addCase(activateByIdAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.ativarPais', { erro: action.error.message }),
        };
      })
      .addCase(inactivateByIdAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(inactivateByIdAsync.fulfilled, (state, action) => {
        state.status = SUCCESS_STATUS;
        state.response.status = action.payload.response.status;
        state.response.data = action.payload.response.data;

        const { description } = action.payload.response.data;
        state.response.message = i18n.t('com.muralis.qcx.mensagem.paisInativado', { pais: description });

        if (state.response.status === 200) {
          state.list = state.list.map((current) =>
            current.id === action.payload.response.data.id ? action.payload.response.data : current
          );
        }
      })
      .addCase(inactivateByIdAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.inativarPais', { erro: action.error.message }),
        };
      });
  },
});

const {
  changeStatusTo,
  loading,
  success,
  failure,
  resetStatus,
  addToList,
  updateOnList,
  setResponse,
  clearResponse,
  setError,
  resetModel,
  changeControlTo,
  setConfig,
  resetList,
} = paisSlice.actions;

const selectPaises = (state: any) => state.pais.list as Pais[];
const selectStatus = (state: any) => state.pais.status;
const selectError = (state: any) => state.pais.error;
const selectResponse = (state: any) => state.pais.response;
const selectPais = (state: any) => state.pais.model as Pais;
const selectConfigControl = (state: any) => state.pais.config.control;

const paisSelectors = {
  selectPaises,
  selectStatus,
  selectError,
  selectResponse,
  selectPais,
  selectConfigControl,
};

const paisActions = {
  ...paisSlice.actions,
  fetchAllAsync,
  fetchByFilterAsync,
  fetchByIdAsync,
  activateByIdAsync,
  inactivateByIdAsync,
  selectPaises,
  selectStatus,
  selectError,
  selectResponse,
  selectPais,
  selectConfigControl,
};

export {
  paisSlice,
  changeStatusTo,
  loading,
  success,
  failure,
  resetStatus,
  addToList,
  updateOnList,
  setResponse,
  clearResponse,
  setError,
  resetModel,
  fetchAllAsync,
  fetchByFilterAsync,
  fetchByIdAsync,
  activateByIdAsync,
  inactivateByIdAsync,
  changeControlTo,
  setConfig,
  paisActions,
  resetList,
  paisSelectors,
  selectPaises,
  selectPais,
  selectStatus,
  selectError,
  selectResponse,
  selectConfigControl,
};

export default paisSlice.reducer;
