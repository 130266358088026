import { useTranslation } from "react-i18next";
import QCXClientePersonalDataForm from "../../../../../../components/cliente/QCXClientePersonalDataForm";
import QCXFinalFormWizard from "../../../../../../shared-components/final-form-wizard/QCXFinalFormWizard";
import NewClienteContatosForm from "./newClienteContatosForm";

export default function NewClientWizardForm({
  handleSubmit,
  handleAlternativeSave,
  model,
  handleChangeModel,
  handleChangeToCreate,
  handleChangeToConsult,
  handleChangeToUpdate,
  handleCancelUpdate,
  handleChangeToBackgroundCreate,
  handleResetBackgroundMode,
  isConsult,
  isUpdate,
  submitContatos,
  isBackgroundCreate,
  authInfo = {},
  requiredRoles = [],
  addCallback,
  removeCallback,
  initialValues,
  ...restProps
}: any) {
  const { t } = useTranslation();

  const checkIfIsAlternativeSaveByStep = (currentStep: any) =>
    [2, 3, 4, 5].includes(currentStep);

  const wizardSteps = [
    t("com.muralis.qcx.dadosPessoais"),
    t("com.muralis.qcx.contato.labelPlural"),
  ];

  return (
    <QCXFinalFormWizard
      steps={wizardSteps}
      initialValues={model}
      handleChangeValues={handleChangeModel}
      isConsult={isConsult}
      isUpdate={isUpdate}
      isBackgroundCreate={isBackgroundCreate}
      isSubNone={true}
      isSubCreate={false}
      isSubConsult={false}
      isSubUpdate={false}
      handleSubmit={handleSubmit}
      checkIfIsAlternativeSaveByStep={checkIfIsAlternativeSaveByStep}
      handleAlternativeSave={handleAlternativeSave}
      handleChangeToCreate={handleChangeToCreate}
      handleChangeToConsult={handleChangeToConsult}
      handleChangeToUpdate={handleChangeToUpdate}
      handleCancelUpdate={handleCancelUpdate}
      handleChangeToBackgroundCreate={handleChangeToBackgroundCreate}
      handleResetBackgroundMode={handleResetBackgroundMode}
      {...restProps}
    >
      {(formProps: any) => (
        <QCXClientePersonalDataForm
          key="dados-pessoais-cliente"
          isConsult={isConsult}
          isUpdate={isUpdate}
          authInfo={authInfo}
          requiredRoles={requiredRoles}
          {...formProps}
        />
      )}
      {(formProps: any) => (
        <NewClienteContatosForm
          submitContatos={submitContatos}
          addCallback={addCallback}
          removeCallback={removeCallback}
          initialValues={initialValues}
        />
      )}
    </QCXFinalFormWizard>
  );
}
