import { Grid } from '@material-ui/core';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllAsync, selectTabelasArmazenagem, selectStatus } from '../../features/tabela-armazenagem/tabelaArmazenagemSlice';
import QCXSimpleSelectManyPageTemplate from '../../templates/simple-select-many-page/QCXSimpleSelectManyPageTemplate';
import { isAlternativeLoadingStatus } from '../../utils/store/store-utils';

export default function QCXSelectManyTabelaArmazenagem({ ...props }) {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const tabelasArmazenagem = useSelector(selectTabelasArmazenagem);
  const status = useSelector(selectStatus);

  const isAlternativeLoading = useMemo(() => (
    isAlternativeLoadingStatus(status)
  ), [status]);

  useEffect(() => {
    dispatch(fetchAllAsync());
  }, []);

  const {
    title = t('com.muralis.qcx.tabelaArmazenagem.selecioneTabelasArmazenagem'),
    filterProp = 'description',
    columns = [
      {
        field: 'code',
        headerName: t('com.muralis.qcx.codigo'),
        type: 'string',
        flex: 60,
        valueGetter: ({ row }) => (
          row?.id
        ),
      },
      {
        field: 'description',
        headerName: t('com.muralis.qcx.tabela'),
        type: 'string',
        flex: 300,
        valueGetter: ({ row }) => (
          row?.description
        ),
      },
      {
        field: 'active', headerName: t('com.muralis.qcx.estado.ativo'), type: 'boolean', flex: 50,
      },
    ],
    selected,
    handleChange,
    isConsult,
  } = props;
  return (
    <>
      <Grid container direction="row" style={{ marginTop: '0.7rem' }}>
        <QCXSimpleSelectManyPageTemplate
          title={title}
          tableColumns={columns}
          tableData={tabelasArmazenagem}
          filterProp={filterProp}
          isLoading={isAlternativeLoading}
          isConsult={isConsult}
          handleSetSelected={handleChange}
          selected={selected}
        />
      </Grid>
    </>
  );
}
