import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import QCXSelectManyTabelaArmazenagemTableManager from '../tabela-armazenagem/QCXSelectManyTabelaArmazenagemTableManager';

import {
  selectSubMode,
  setTabelasArmazenagems,
} from '../../features/armazens/armazemSlice';
import QCXFinalFormWizard from '../../shared-components/final-form-wizard/QCXFinalFormWizard';

import QCXArmazemTecnicalDataForm from './QCXArmazemTecnicalDataForm';
import {
  isNoneSubMode, isSubConsultMode, isSubCreateMode, isSubUpdateMode,
} from '../../utils/store/store-utils';

export default function QCXArmazemWizardFinalForm({
  handleSubmit,
  handleAlternativeSave,
  handleChangeToPreparingAction,
  model,
  handleChangeModel,
  handleChangeToCreate,
  handleChangeToConsult,
  handleChangeToUpdate,
  handleCancelUpdate,
  handleChangeToBackgroundCreate,
  handleResetBackgroundMode,
  isCreate,
  isConsult,
  isUpdate,
  isBackgroundCreate,
  isPreparingAction,
  authInfo = {},
  requiredRoles = [],
  ...restProps
}) {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const subMode = useSelector(selectSubMode);

  const isSubNone = useMemo(() => (
    isNoneSubMode(subMode)
  ), [subMode]);

  const isSubCreate = useMemo(() => (
    isSubCreateMode(subMode)
  ), [subMode]);

  const isSubConsult = useMemo(() => (
    isSubConsultMode(subMode)
  ), [subMode]);

  const isSubUpdate = useMemo(() => (
    isSubUpdateMode(subMode)
  ), [subMode]);

  const checkIfIsAlternativeSaveByStep = (currentStep) => (
    currentStep === 1
  );
  const handleSetTabelasArmazenagems = (data) => {
    dispatch(setTabelasArmazenagems(data));
  };

  const wizardSteps = [
    t('com.muralis.qcx.dadosTecnicos'),
    t('com.muralis.qcx.tabelaArmazenagem.labelPlural'),
  ];

  return (
    <QCXFinalFormWizard
      steps={wizardSteps}
      initialValues={model}
      handleChangeValues={handleChangeModel}
      isCreate={isCreate}
      isConsult={isConsult}
      isUpdate={isUpdate}
      isBackgroundCreate={isBackgroundCreate}
      isSubNone={isSubNone}
      isSubCreate={isSubCreate}
      isSubConsult={isSubConsult}
      isSubUpdate={isSubUpdate}
      handleSubmit={handleSubmit}
      checkIfIsAlternativeSaveByStep={checkIfIsAlternativeSaveByStep}
      handleAlternativeSave={handleAlternativeSave}
      handleChangeToCreate={handleChangeToCreate}
      handleChangeToConsult={handleChangeToConsult}
      handleChangeToUpdate={handleChangeToUpdate}
      handleCancelUpdate={handleCancelUpdate}
      handleChangeToBackgroundCreate={handleChangeToBackgroundCreate}
      handleResetBackgroundMode={handleResetBackgroundMode}
      {...restProps}
    >
      {(formProps) => (
        <QCXArmazemTecnicalDataForm
          key="dados-tecnicos-armazem"
          isUpdate={isUpdate}
          isConsult={isConsult}
          authInfo={authInfo}
          requiredRoles={requiredRoles}
          {...formProps}
        />
      )}
      {({ controlComponentProps, ...restFormProps }) => (
        <QCXSelectManyTabelaArmazenagemTableManager
          list={model?.tabelasArmazenagem || []}
          isConsult={isConsult}
          handleChange={handleSetTabelasArmazenagems}
          controlComponentProps={controlComponentProps}
          authInfo={authInfo}
          requiredRoles={requiredRoles}
          {...restFormProps}
        />
      )}
    </QCXFinalFormWizard>
  );
}
