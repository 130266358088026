import { createSlice } from '@reduxjs/toolkit';
import i18n from '../../i18n';
import { AUTO_LOAD_CONTROL } from '../config-control';
import {
  BACKGROUND_CREATE_MODE,
  NONE_BACKGROUND_MODE,
  NONE_MODE,
  NONE_SUB_MODE,
  BATCH_APPROVAL_MODE,
} from '../mode';
import { NO_REFRESH, REFRESH } from '../refresh';
import {
  ALTERNATIVE_LOADING_STATUS,
  FAILURE_STATUS,
  IDLE_STATUS,
  LOADING_STATUS,
  SUCCESS_STATUS,
} from '../status';
import { fetchAllSolicitadasAsync } from './aprovarRecusarPagamentoThunks';

const initialState = {
  status: IDLE_STATUS,
  mode: {
    main: NONE_MODE,
    background: NONE_BACKGROUND_MODE,
    sub: NONE_SUB_MODE,
  },
  refresh: NO_REFRESH,
  error: null,
  response: {
    status: null,
    message: null,
    data: null,
  },
  config: {
    control: AUTO_LOAD_CONTROL,
  },
  list: [],
  model: {
  },
  related: {
    model: {
      aprovarRecusarItem: {},
    },
    list: {
      selectionSolicitacoes: [],
    },
  },
};

const aprovarRecusarPagamento = createSlice({
  name: 'aprovarRecusarPagamento',
  initialState,
  reducers: {
    changeStatusTo: (state, action) => {
      state.status = action.payload.status;
    },
    loading: (state) => {
      state.status = LOADING_STATUS;
    },
    success: (state) => {
      state.status = SUCCESS_STATUS;
    },
    failure: (state) => {
      state.status = FAILURE_STATUS;
    },
    resetStatus: (state) => {
      state.status = IDLE_STATUS;
    },
    refresh: (state) => {
      state.refresh = REFRESH;
    },
    resetRefresh: (state) => {
      state.refresh = NO_REFRESH;
    },
    changeToBatchApprovalMode: (state) => {
      state.mode.main = BATCH_APPROVAL_MODE;
    },
    resetMode: (state) => {
      state.mode.main = NONE_MODE;
    },
    changeToBackgroundCreateMode: (state) => {
      state.mode.background = BACKGROUND_CREATE_MODE;
    },
    resetBackgroundMode: (state) => {
      state.mode.background = NONE_BACKGROUND_MODE;
    },
    resetSubMode: (state) => {
      state.mode.sub = NONE_SUB_MODE;
    },
    changeControlTo: (state, action) => {
      state.config.control = action.payload;
    },
    resetControl: (state) => {
      state.config.control = initialState.config.control;
    },
    setModel: (state, action) => {
      state.model = {
        ...action.payload,
      };
    },
    addToList: (state, action) => {
      state.list = [...state.list, action.payload.data];
    },
    updateOnList: (state, action) => {
      state.list = state.list.map((current) => {
        if (current.id === action.payload.data?.id) {
          return action.payload.data;
        }
        return current;
      });
    },
    setList: (state, action) => {
      state.list = action.payload;
    },
    setResponse: (state, action) => {
      state.response = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    clearResponse: (state) => {
      state.response = {
        status: null,
        message: null,
        data: null,
      };
    },
    clearError: (state) => {
      state.error = initialState.error;
    },
    resetModel: (state) => {
      state.model = initialState.model;
    },
    resetList: (state) => {
      state.list = [];
    },
    setRelatedSelectionSolicitacoesList: (state, action) => {
      state.related.list.selectionSolicitacoes = action.payload;
    },
    resetRelatedSelectionSolicitacoesList: (state) => {
      state.related.list.selectionSolicitacoes = [];
    },
    setRelatedAprovarRecusarItemModel: (state, action) => {
      state.related.model.aprovarRecusarItem = action.payload;
    },
    resetRelatedAprovarRecusarItemModel: (state) => {
      state.related.model.aprovarRecusarItem = initialState.related.model.aprovarRecusarItem;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllSolicitadasAsync.pending, (state) => {
        state.status = ALTERNATIVE_LOADING_STATUS;
      })
      .addCase(fetchAllSolicitadasAsync.fulfilled, (state, action) => {
        state.status = IDLE_STATUS;
        state.list = action.payload.data;
      })
      .addCase(fetchAllSolicitadasAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroCarregarListaSolicitacaoAprovacaoPagamento', {
            erro: action.error.message,
          }),
        };
        state.refresh = NO_REFRESH;
      });
  },
});

const {
  changeStatusTo,
  loading,
  success,
  failure,
  resetStatus,
  refresh,
  resetRefresh,
  resetMode,
  changeToBackgroundCreateMode,
  resetBackgroundMode,
  changeToSubCreateMode,
  changeToSubConsultMode,
  changeToSubUpdateMode,
  changeToBatchApprovalMode,
  resetSubMode,
  changeControlTo,
  resetControl,
  setModel,
  resetModel,
  resetList,
  addToList,
  setList,
  updateOnList,
  setResponse,
  setResponseMessage,
  clearResponse,
  setError,
  clearError,
  setRelatedAprovarRecusarItemModel,
  resetRelatedAprovarRecusarItemModel,
  setRelatedSelectionSolicitacoesList,
  resetRelatedSelectionSolicitacoesList,
} = aprovarRecusarPagamento.actions;

const aprovarRecusarPagamentoActions = {
  ...aprovarRecusarPagamento.actions,
};

export {
  aprovarRecusarPagamentoActions,
  changeStatusTo,
  loading,
  success,
  failure,
  resetStatus,
  refresh,
  resetRefresh,
  resetMode,
  changeToBackgroundCreateMode,
  resetBackgroundMode,
  changeToSubCreateMode,
  changeToSubConsultMode,
  changeToSubUpdateMode,
  changeToBatchApprovalMode,
  resetSubMode,
  changeControlTo,
  resetControl,
  setModel,
  resetModel,
  resetList,
  addToList,
  setList,
  updateOnList,
  setResponse,
  setResponseMessage,
  clearResponse,
  setError,
  clearError,
  setRelatedAprovarRecusarItemModel,
  resetRelatedAprovarRecusarItemModel,
  setRelatedSelectionSolicitacoesList,
  resetRelatedSelectionSolicitacoesList,
};

export default aprovarRecusarPagamento.reducer;
