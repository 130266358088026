import React, { useMemo } from 'react';
import BaseSelectWithOptions from '../../../../../../../shared-components/dados-embarque/base-components/BaseSelectWithOptions';

function EmbalagemMadeiraSelect({ ...props }) {
  const list = useMemo(() => [
    {
      id: 'Não contem', label: 'Não contem', value: 'NAO_CONTEM',
    },
    {
      id: 'Não informado', label: 'Não informado', value: 'NAO_INFORMADO',
    },
    {
      id: 'Suporte Industrializado', label: 'Suporte Industrializado', value: 'SUPORTE_INDUSTRIALIZADO',
    },
    {
      id: 'Suporte Não tratado e Não certificado', label: 'Suporte Não tratado e Não certificado', value: 'SUPORTE_NAO_TRATADO_E_NAO_CERTIFICADO',
    },
    {
      id: 'Suporte Tratado e Certificado', label: 'Suporte Tratado e Certificado', value: 'SUPORTE_TRATADO_E_CERTIFICADO',
    }
  ], []);

  return (
    <BaseSelectWithOptions
      list={list}
      {...props}
    />
  );
}

export default EmbalagemMadeiraSelect;
