import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import QCXNavigatorContent from '../../shared-components/navigator-content/QCXNavigatorContent';
import QCXNavigatorTitle from '../../shared-components/navigator-title/QCXNavigatorTitle';
import QCXDrawer from '../drawer/QCXDrawer';

// Images
import headerBG from '../../assets/images/background_header.jpg';

const drawerWidth = 240;
const appBarHeight = 64;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    minHeight: 'inherit',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    height: appBarHeight,
    backgroundImage: `url(${headerBG})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center 28%',
  },
  appBarClean: {
    zIndex: theme.zIndex.drawer + 1,
    height: appBarHeight,
    background: '#FFFFFF',
    color: theme.palette.primary.main,
  },
  toolbar: theme.mixins.toolbar,
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    minWidth: '100%',
    height: 'auto',
    backgroundColor: 'transparent',
    margin: theme.spacing(0),
    padding: theme.spacing(0),
  },
}));

export default function QCXNavigator({
  pageIcon,
  pageTitle,
  breadcrumbs,
  breadcrumbsProps,
  disableFocusLock,
  children,
  authInfo = {},
}) {
  const classes = useStyles();

  const [openDrawer, setOpenDrawer] = useState(false);

  const handleToggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  return (
    <div className={classes.root}>
      <QCXNavigatorTitle
        className={classes.appBarClean}
        handleToggleDrawer={handleToggleDrawer}
        breadcrumbs={breadcrumbs}
      />
      <QCXDrawer
        additionalClasses={{
          root: classes.drawer,
          paper: classes.drawerPaper,
        }}
        open={openDrawer}
        onClose={handleToggleDrawer}
        disableFocusLock={disableFocusLock}
        authInfo={authInfo}
      />
      <QCXNavigatorContent
        className={classes.content}
        breadcrumbs={breadcrumbs}
        breadcrumbsProps={breadcrumbsProps}
        additionalClasses={{
          appBar: {
            height: appBarHeight,
          },
        }}
        page={{
          icon: pageIcon,
          title: pageTitle,
        }}
      >
        {children}
      </QCXNavigatorContent>
    </div>
  );
}
