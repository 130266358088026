import { Grid, makeStyles } from '@material-ui/core';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import QCXFinalDatePickerField from '../../shared-components/final-date-picker-field/QCXFinalDatePickerField';
import QCXFinalNumericDecimalField from '../../shared-components/final-numeric-decimal-field/QCXFinalNumericDecimalField';
import QCXSelectMoedaAutocomplete from '../../shared-components/final-select-moeda-field/QCXSelectMoedaAutocomplete';
import QCXFinalMultilineTextField from '../../shared-components/final-text-field/QCXFinalMultilineTextField';
import QCXSelectContaBancariaAutocompleteField from '../../shared-components/select-conta-bancaria/QCXSelectContaBancariaAutocompleteField';
import useValidators from '../../utils/hooks/useValidators';
import { maxValue, required } from '../../utils/validators/field/validator';
import QCXFormSubtitle from '../form-title/QCXFormSubtitle';

const useStyles = makeStyles(() => ({
  gridFields: {
    paddingBottom: '16px',
  },
  subtitle: {
    margin: '16px 0',
  },
}));

export default function QCXDadosBancariosForm({
  initialValues,
  isPreparingAction,
  isPerformingAction,
  isEdicaoBloqueada,
}) {
  const { t } = useTranslation();
  const classes = useStyles();

  const taxaCambialValidators = useMemo(() => (
    useValidators([
      required,
      maxValue(9.99999),
    ])
  ), []);

  return (
    <>
      <Grid
        item
        xs={12}
      >
        <QCXFormSubtitle
          className={classes.subtitle}
          title={t('com.muralis.qcx.banco.dadosBancarios')}
        />
      </Grid>
      <Grid
        container
        item
        spacing={2}
        xs={12}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={3}
          lg={3}
        >
          <QCXFinalDatePickerField
            id="date-picker-data-solicitacao-field"
            key="date-picker-data-solicitacao-field"
            name="dataSolicitacao"
            label={t('com.muralis.qcx.numerarioImportacao.dataSolicitacao')}
            disabled
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={3}
          lg={3}
        >
          <QCXFinalDatePickerField
            id="date-picker-data-vencimento-field"
            key="date-picker-data-vencimento-field"
            name="dataVencimento"
            label={t('com.muralis.qcx.dataVencimento')}
            disabled={(
              isPerformingAction
              || isPreparingAction
              || isEdicaoBloqueada
            )}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
        >
          <QCXSelectMoedaAutocomplete
            id="autocomplete-select-moeda-field"
            key="autocomplete-select-moeda-field"
            name="moeda.id"
            label={t('com.muralis.qcx.moedaFaturamento')}
            initialValues={initialValues}
            disabled={(
              isPerformingAction
              || isPreparingAction
              || isEdicaoBloqueada
            )}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
        >
          <QCXFinalNumericDecimalField
            id="numeric-decimal-taxa-cambial-field"
            key="numeric-decimal-taxa-cambial-field"
            name="taxaCambio"
            label={t('com.muralis.qcx.taxaCambio')}
            scale={5}
            validate={taxaCambialValidators}
            disabled={(
              isPerformingAction
              || isPreparingAction
              || isEdicaoBloqueada
            )}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
        >
          <QCXSelectContaBancariaAutocompleteField
            id="autocomplete-select-conta-bancaria-debito-impostos-federais-field"
            key="autocomplete-select-conta-bancaria-debito-impostos-federais-field"
            name="contaBancariaImpostosFederais.id"
            label={t('com.muralis.qcx.conta.contaDebitoImpostosFederais')}
            initialValues={initialValues}
            disabled={(
              isPerformingAction
              || isPreparingAction
              || isEdicaoBloqueada
            )}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
        >
          <QCXSelectContaBancariaAutocompleteField
            id="autocomplete-select-conta-bancaria-debito-impostos-estaduais-capa-field"
            key="autocomplete-select-conta-bancaria-debito-impostos-estaduais-capa-field"
            name="contaBancariaImpostosEstaduais.id"
            label={t('com.muralis.qcx.conta.contaDebitoImpostosEstaduais')}
            initialValues={initialValues}
            disabled={(
              isPerformingAction
              || isPreparingAction
              || isEdicaoBloqueada
            )}
            controlled
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
        >
          <QCXSelectContaBancariaAutocompleteField
            id="autocomplete-select-conta-bancaria-debito-despesas-field"
            key="autocomplete-select-conta-bancaria-debito-despesas-field"
            name="contaBancariaDespesas.id"
            label={t('com.muralis.qcx.conta.contaDebitoDespesas')}
            initialValues={initialValues}
            disabled={(
              isPerformingAction
              || isPreparingAction
              || isEdicaoBloqueada
            )}
            controlled
          />
        </Grid>
        <Grid
          item
          xs={12}
        >
          <QCXFinalMultilineTextField
            id="multiline-text-observacao-field"
            key="multiline-text-observacao-field"
            name="observacao"
            label={t('com.muralis.qcx.observacoesAdicionais')}
            rows={10}
            maxLength={500}
            disabled={(
              isPerformingAction
              || isPreparingAction
              || isEdicaoBloqueada
            )}
          />
        </Grid>
      </Grid>
    </>
  );
}
