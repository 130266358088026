import {
  Grid,
} from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import QCXFinalTextField from '../../shared-components/final-text-field/QCXFinalTextField';
import QCXFormStepWizard from '../../shared-components/form-step-wizard/QCXFormStepWizard';
import QCXFormTitle from '../form-title/QCXFormTitle';
import QCXSelectTipoDeclaracaoAutocomplete from '../../shared-components/select-tipo-declaracao/QCXSelectTipoDeclaracaoAutocomplete';
import QCXFinalSelectTipoProdutoAutocomplete from '../../shared-components/select-tipo-produto/QCXFinalSelectTipoProdutoAutocomplete';
import QCXSelectViaTransporteAutocomplete from '../../shared-components/select-via-transporte/QCXSelectViaTransporteAutocomplete';
import {
  required as requiredValidator,
} from '../../utils/validators/field/validator';
import { TipoProdutoUtils } from '../../shared-components/select-tipo-produto/tipoProdutoUtils';

export default function QCXServicoTecnicalDataForm({
  initialValues,
  handleSubmit,
  isConsult,
  isUpdate,
  controlComponentProps,
  authInfo = {},
  requiredRoles = [],
}) {
  const { t } = useTranslation();

  const handleProdutoChange = (form) => (value) => {
    if (value === 'EXPORTACAO') {
      form.change('tipoDeclaracao', undefined);
    }
  };

  const listeners = [
    { name: 'produto', fn: handleProdutoChange },
  ];

  return (
    <QCXFormStepWizard
      initialValues={initialValues}
      onSubmit={handleSubmit}
      controlComponentProps={controlComponentProps}
      onChangeListeners={listeners}
      authInfo={authInfo}
      requiredRoles={requiredRoles}
    >
      {({ values }) => (
        <>
          <QCXFormTitle
            title={t('com.muralis.qcx.dadosTecnicos')}
          />
          <Grid container spacing={2} style={{ marginTop: '1rem' }}>
            {(isConsult || isUpdate) && (
              <Grid item xs={2}>
                <QCXFinalTextField
                  id="text-field-id-servico"
                  key="text-field-id-servico"
                  name="id"
                  label={t('com.muralis.qcx.codigo')}
                  disabled
                />
              </Grid>
            )}
            <Grid item xs={4}>
              <QCXFinalTextField
                id="text-field-nome-servico"
                key="text-field-nome-servico"
                name="nome"
                label={t('com.muralis.qcx.nome')}
                disabled={isConsult}
                validate={requiredValidator}
                maxLength={40}
                required
              />
            </Grid>
            <Grid item xs={(isConsult || isUpdate) ? 6 : 5}>
              <QCXFinalTextField
                id="text-field-description-servico"
                key="text-field-description-servico"
                name="description"
                label={t('com.muralis.qcx.descricao')}
                maxLength={40}
                disabled={isConsult}
                validate={requiredValidator}
                required
              />
            </Grid>
            <Grid item xs={3}>
              <QCXFinalSelectTipoProdutoAutocomplete
                id="select-field-tipo-produto"
                key="select-field-tipo-produto"
                name="produto"
                label={t('com.muralis.qcx.produto')}
                initialValues={values}
                fieldProps={{ validate: requiredValidator }}
                disabled={isConsult}
                required
              />
            </Grid>
            <Grid item xs={4}>
              <QCXSelectViaTransporteAutocomplete
                id="autocomplete-select-field-via-transporte"
                key="autocomplete-select-field-via-transporte"
                name="viaTransporte.id"
                label={t('com.muralis.qcx.transporte.viaTransporte')}
                initialValues={values}
                fieldProps={{ validate: requiredValidator }}
                disabled={isConsult}
                required
              />
            </Grid>
            <Grid item xs={3}>
              <QCXFinalTextField
                id="text-field-sigla-servico"
                key="text-field-sigla-servico"
                name="sigla"
                label={t('com.muralis.qcx.sigla')}
                maxLength={3}
                disabled={isConsult}
                validate={requiredValidator}
                required
              />
            </Grid>
            <Grid item xs={2}>
              <QCXFinalTextField
                id="text-field-lead-time-total-servico"
                key="text-field-lead-time-total-servico"
                name="leadTimeTotal"
                label={t('com.muralis.qcx.leadTime')}
                type="number"
                disabled={isConsult}
                validate={requiredValidator}
                required
              />
            </Grid>
            {TipoProdutoUtils.isImportacao(values?.produto) && (
              <Grid item xs={3}>
                <QCXSelectTipoDeclaracaoAutocomplete
                  id="select-field-tipo-declaracao-servico"
                  key="select-field-tipo-declaracao-servico"
                  name="tipoDeclaracao.id"
                  label={t('com.muralis.qcx.tipoDeclaracao')}
                  initialValues={values}
                  fieldProps={{ validate: requiredValidator }}
                  disabled={isConsult}
                  required
                />
              </Grid>
            )}
          </Grid>
        </>
      )}
    </QCXFormStepWizard>
  );
}
