import { isStrictEquals } from '../../utils/general/general-utils';

const STATUS_MODAL_SELECTION_PROCESSO_XML_DANFE_IDLE = 'IDLE';
const STATUS_MODAL_SELECTION_PROCESSO_XML_DANFE_SHOW_ALERT = 'SHOW_ALERT';
const STATUS_MODAL_SELECTION_PROCESSO_XML_DANFE_SHOW_DRAG_AND_DROP = 'SHOW_DRAG_AND_DROP';

const isStatusModalIdle = (status) => (
  isStrictEquals(status, STATUS_MODAL_SELECTION_PROCESSO_XML_DANFE_IDLE)
);

const isStatusModalShowAlert = (status) => (
  isStrictEquals(status, STATUS_MODAL_SELECTION_PROCESSO_XML_DANFE_SHOW_ALERT)
);

const isStatusModalShowDragAndDrop = (status) => (
  isStrictEquals(status, STATUS_MODAL_SELECTION_PROCESSO_XML_DANFE_SHOW_DRAG_AND_DROP)
);

const DeclaracaoUnicaExportacaoUtils = Object.freeze({
  MODAL_STATUS_IDLE: (
    STATUS_MODAL_SELECTION_PROCESSO_XML_DANFE_IDLE
  ),
  MODAL_STATUS_SHOW_ALERT: (
    STATUS_MODAL_SELECTION_PROCESSO_XML_DANFE_SHOW_ALERT
  ),
  MODAL_STATUS_SHOW_DRAG_AND_DROP: (
    STATUS_MODAL_SELECTION_PROCESSO_XML_DANFE_SHOW_DRAG_AND_DROP
  ),
  isModalStatusIdle: (
    isStatusModalIdle
  ),
  isModalStatusShowAlert: (
    isStatusModalShowAlert
  ),
  isModalStatusShowDragAndDrop: (
    isStatusModalShowDragAndDrop
  ),
});

export default DeclaracaoUnicaExportacaoUtils;
