import { ReponseType } from '../../utils/api/api-utils';
import {
  doGetQCXRequest,
  doPostQCXRequest,
  doPutQCXActivateRequest,
  doPutQCXInactivateRequest,
  doPutQCXRequest,
} from '../../utils/api/domain/domain-api-utils';
import {
  ETAPA_FOLLOW_UP_ENDPOINT,
  QCX_DOWNLOAD_ENDPOINT,
  QCX_STORAGE_ENDPOINT,
} from '../../utils/api/domain/domain-endpoint-utils';

async function register(data, queryParams = [], config = {}) {
  return doPostQCXRequest([ETAPA_FOLLOW_UP_ENDPOINT], data, queryParams, config);
}

async function save(data, queryParams = [], config = {}) {
  return doPutQCXRequest([ETAPA_FOLLOW_UP_ENDPOINT], data, queryParams, config);
}

async function activateById(id) {
  return doPutQCXActivateRequest([ETAPA_FOLLOW_UP_ENDPOINT], id);
}

async function inactivateById(id) {
  return doPutQCXInactivateRequest([ETAPA_FOLLOW_UP_ENDPOINT], id);
}

async function repasseByProcessos(data, queryParams = [], config = {}) {
  const { unidadeProcesso, processos } = queryParams;

  const queryParamsArray = [{ name: 'unidadeProcesso', value: unidadeProcesso }];

  processos?.forEach((element) => {
    queryParamsArray.push({ name: `processos`, value: element });
  });

  return doPutQCXRequest([ETAPA_FOLLOW_UP_ENDPOINT, 'repasse'], data, queryParamsArray, config);
}

async function dowloadMultipleFiles(queryParams = {}) {
  const { processos, tiposDocumentos, cliente, etapa, dataInicio, dataFim } = queryParams;

  const queryParamsArray = [
    { name: 'cliente', value: cliente },
    { name: 'etapa', value: etapa },
    { name: 'dataInicio', value: dataInicio },
    { name: 'dataFim', value: dataFim },
  ];

  processos?.forEach((element) => {
    queryParamsArray.push({ name: `processos`, value: element });
  });

  tiposDocumentos?.forEach((element) => {
    queryParamsArray.push({ name: `tipoDocumentos`, value: element });
  });

  return doGetQCXRequest([QCX_STORAGE_ENDPOINT, QCX_DOWNLOAD_ENDPOINT, 'followup-files'], queryParamsArray, {
    responseType: ReponseType.ARRAY_BUFFER,
  });
}

const etapaFollowUpAPI = {
  register,
  save,
  activateById,
  inactivateById,
  repasseByProcessos,
  dowloadMultipleFiles,
};

export { etapaFollowUpAPI, register, save, activateById, inactivateById, repasseByProcessos, dowloadMultipleFiles };
