import { createAsyncThunk } from '@reduxjs/toolkit';
import { omit } from 'lodash';
import { TipoProdutoUtils } from '../../shared-components/select-tipo-produto/tipoProdutoUtils';
import { etapaFollowUpAPI } from '../etapa-follow-up/etapaFollowUpAPI';
import { followUpAPI } from '../follow-up/followUpAPI';

const fetchAllAsync = createAsyncThunk(
  'followUpExportacao/fetchAll',
  async () => {
    const { data } = await followUpAPI.fetchByFilter([
      {
        name: 'servicoProduto',
        value: TipoProdutoUtils.EXPORTACAO,
      }
    ]);

    return { data };
  }
);

const fetchByIdAsync = createAsyncThunk(
  'followUpExportacao/fetchById',
  async (data) => {
    const response = await followUpAPI.fetchById(data);
    return {
      response: {
        status: response.status,
        data: response.data,
      },
    };
  }
);

const fetchPreviewAsync = createAsyncThunk(
  'followUp/fetchPreview',
  async (options) => {
    const filters = [
      {
        name: 'servicoProduto',
        value: TipoProdutoUtils.EXPORTACAO,
      }
    ];

    const { noDadoEmbarqueAssociated } = options;

    if (noDadoEmbarqueAssociated) {
      filters.push({ name: 'hasDadosEmbarque', value: false });
    }

    const { data } = await followUpAPI.fetchPreviewByFilter(filters);

    return { data };
  }
);

const activateByIdAsync = createAsyncThunk(
  'followUpExportacao/activateById',
  async (id) => {
    const { status, data } = await followUpAPI.activateById(id);
    return { response: { status, data } };
  }
);

const inactivateByIdAsync = createAsyncThunk(
  'followUpExportacao/inactivateById',
  async (id) => {
    const { status, data } = await followUpAPI.inactivateById(id);
    return { response: { status, data } };
  }
);

const duplicarByIdAsync = createAsyncThunk(
  'followUpExportacao/duplicarById',
  async (data, { rejectWithValue }) => {
    try {
      const response = await followUpAPI.duplicarById(
        data?.id,
        [],
        data?.payload
      );

      return {
        response: {
          status: response?.status,
          data: response?.data,
          ...(
            omit(
              response,
              ['request', 'config']
            )
          ),
        },
      };
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

const reactivateEtapaByIdAsync = createAsyncThunk(
  'followUpExportacao/reactivateEtapaById',
  async (id) => {
    const { status, data } = await etapaFollowUpAPI.activateById(id);
    return { response: { status, data } };
  }
);

const inactivateEtapaByIdAsync = createAsyncThunk(
  'followUpExportacao/inactivateEtapaById',
  async (id) => {
    const { status, data } = await etapaFollowUpAPI.inactivateById(id);
    return { response: { status, data } };
  }
);

const followUpExportacaoThunks = {
  fetchAllAsync,
  fetchByIdAsync,
  fetchPreviewAsync,
  activateByIdAsync,
  inactivateByIdAsync,
  duplicarByIdAsync,
  reactivateEtapaByIdAsync,
  inactivateEtapaByIdAsync,
};

export default followUpExportacaoThunks;
