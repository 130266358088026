import {
  Grid,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core';
import React from 'react';
import i18n from '../../i18n';

const useStyles = makeStyles((theme) => ({
  cardPreview: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    width: '100%',
    padding: '1',
    boxShadow: 'none',
    backgroundColor: theme.palette.grey[100],
  },
  cardPreviewTitleArea: {
    paddingLeft: '8px',
    borderBottom: `1px dashed ${theme.palette.grey[500]}`,
  },
  cardPreviewTitle: {
    color: theme.palette.getContrastText(theme.palette.grey[100]),
    fontWeight: 'bold',
    fontStyle: 'italic',
  },
  cardPreviewContent: {
    paddingTop: '4px',
    paddingBottom: '2px',
    borderLeft: `1px dashed ${theme.palette.grey[500]}`,
  },
  cardPreviewValue: {
    letterSpacing: '3px',
  },
  cardPreviewPart: {
    height: '24px',
    paddingLeft: '8px',
  },
  cardPreviewTitleDisabled: {
    color: theme.palette.grey[500],
    fontWeight: 'bold',
    fontStyle: 'italic',
  },
  cardPreviewValueDisabled: {
    letterSpacing: '3px',
    color: theme.palette.grey[500],
  },
  cardPreviewPartDisabled: {
    height: '24px',
    paddingLeft: '8px',
    color: theme.palette.grey[600],
  },
}));

export default function QCXSimpleMaskPreviewer({
  title = i18n.t('com.muralis.qcx.exemplo'),
  previewOptions,
  disabled,
  ...restProps
}) {
  const classes = useStyles();

  return (
    <Paper
      className={classes.cardPreview}
      style={{
        border: '1px solid #b9b9b9',
      }}
      {...restProps}
    >
      <Grid
        item
        container
      >
        <Grid
          item
          className={classes.cardPreviewTitleArea}
          xs={12}
        >
          <Typography
            variant="overline"
            className={(disabled
              ? classes.cardPreviewTitleDisabled
              : classes.cardPreviewTitle
            )}
          >
            {title}
          </Typography>
        </Grid>
        <Grid
          item
          container
        >
          <Grid
            item
            container
            xs={6}
            direction="column"
            alignItems="center"
            justify="center"
          >
            <Grid
              item
            >
              <Typography
                className={(disabled
                  ? classes.cardPreviewValueDisabled
                  : classes.cardPreviewValue
                )}
                variant="subtitle1"
              >
                {previewOptions?.value}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            container
            className={classes.cardPreviewContent}
            xs={6}
            direction="column"
          >
            {previewOptions?.parts?.map((maskPart) => (
              <Grid
                item
                container
                alignContent="center"
                className={(disabled
                  ? classes.cardPreviewPartDisabled
                  : classes.cardPreviewPart
                )}
              >
                <Typography
                  variant="overline"
                  dangerouslySetInnerHTML={{
                    __html: maskPart,
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}
