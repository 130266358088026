import React, {
  useCallback,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  useTheme,
  Grid,
  Typography,
} from '@material-ui/core';
import {
  Close as CloseIcon,
} from '@material-ui/icons';
import _ from 'lodash';
import QCXIconButton from '../../shared-components/button/QCXIconButton';
import QCXCompoundDialog from '../../shared-components/dialog/QCXCompoundDialog';
import { CLOSED_STATUS, isOpenStatus } from '../../utils/hooks/form/dialog/formDialogUtils';
import QCXFieldsViewerManager from '../fields-viewer-manager/QCXFieldsViewerManager';
import QCXMultilineTextFieldViewer from '../../shared-components/text-field/QCXMultilineTextFieldViewer';

export default function QCXObservacaoGeralEtapasFollowUpDialog({
  parentModel,
  list,
  status,
  handleStatus,
  ...restProps
}) {
  const { t } = useTranslation();
  const theme = useTheme();

  const formattedValues = useMemo(() => {
    const etapasComObservacoes = (list || [])
      .filter((etapaFollowUp) => (
        etapaFollowUp?.observacao
        && _.isString(etapaFollowUp?.observacao)
        && etapaFollowUp?.observacao?.length > 0
      ));

    const observacoesGeraisList = etapasComObservacoes
      .map(({ etapa, observacao }) => (
        t('com.muralis.qcx.etapa.etapaDescricao', { descricao: etapa.description, observacao })
      ));

    const computedObservacaoGeral = observacoesGeraisList.join(
      '\n--\n\n'
    );

    return {
      observacaoGeral: computedObservacaoGeral,
    };
  }, [list]);

  const open = useMemo(() => (
    isOpenStatus(status)
  ), [status]);

  const handleClose = useCallback(() => {
    handleStatus(CLOSED_STATUS);
  }, [handleStatus]);

  return (
    <QCXCompoundDialog
      key="dialog-observacao-geral-etapas-follow-up"
      id="dialog-observacao-geral-etapas-follow-up"
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        style: {
          borderLeft: `6px solid ${theme.palette.primary.main}`,
          width: '100% !important',
        },
      }}
      {...restProps}
    >
      <QCXCompoundDialog.Title
        style={{
          padding: '16px 32px 4px 24px',
        }}
      >
        <Grid
          item
          container
          justify="space-between"
        >
          <Grid
            item
            xs={10}
          >
            <Typography
              variant="subtitle1"
              style={{
                color: theme.palette.primary.light,
              }}
            >
              <strong>
                {t('com.muralis.qcx.etapa.observacoesGeraisEtapas').toUpperCase()}
              </strong>
            </Typography>
            <Typography
              variant="subtitle2"
              style={{
                color: theme.palette.grey[600],
              }}
            >
              {t('com.muralis.qcx.processoNumero', { numero: parentModel?.numero })}
            </Typography>
          </Grid>
          <Grid
            item
            container
            justify="flex-end"
            xs={2}
          >
            <QCXIconButton
              style={{
                margin: '-16px -32px 0 0',
                padding: '16px',
                borderRadius: '4px',
              }}
              onClick={handleClose}
              disableTooltip
              disableRipple
            >
              <CloseIcon />
            </QCXIconButton>
          </Grid>
        </Grid>
      </QCXCompoundDialog.Title>
      <QCXCompoundDialog.Content
        style={{
          padding: '16px 32px 24px 24px',
        }}
      >
        <QCXFieldsViewerManager
          values={formattedValues}
          disableHelperText
          {...restProps}
        >
          {(fieldsViewerProps) => (
            <Grid
              item
              container
            >
              <Grid
                item
                xs={12}
              >
                <QCXMultilineTextFieldViewer
                  key="multiline-text-field-observacao-geral-viewer"
                  id="multiline-text-field-observacao-geral-viewer"
                  name="observacaoGeral"
                  defaultValue=""
                  {...fieldsViewerProps}
                />
              </Grid>
            </Grid>
          )}
        </QCXFieldsViewerManager>
      </QCXCompoundDialog.Content>
    </QCXCompoundDialog>
  );
}
