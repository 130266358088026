import { TextField, TextFieldProps } from "@mui/material";

type TextFieldStyledProps = Omit<TextFieldProps, "variant"> & {
  label?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const TextFieldStyled = ({
  value,
  label,
  onChange,
  ...rest
}: TextFieldStyledProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event);
  };

  return (
    <TextField
      {...rest}
      variant="outlined"
      value={value}
      onChange={handleChange}
      size="small"
      label={label?.toUpperCase()}
      InputLabelProps={{
        shrink: true,
      }}
      fullWidth
    />
  );
};

export default TextFieldStyled;
