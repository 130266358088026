import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, styled } from '@mui/material';

interface ConfirmationModalProps {
  title: string;
  open: boolean;
  message: string;
  singleAction?: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

const ConfirmButton = styled(Button)`
  background: ${({ theme }) => theme.palette.secondary.main};
  color: white;
`;

const CancelButton = styled(Button)`
  background: ${({ theme }) => theme.palette.primary.main};
  color: white;
`;

const CustomDialogTitle = styled(DialogTitle)({
  fontSize: '1.25rem',
  fontWeight: 'bold',
  textAlign: 'center',
  padding: '8px',
});

const CustomDialogContent = styled(DialogContent)({
  padding: '8px',
});

const CustomDialogContentText = styled(DialogContentText)({
  fontSize: '1rem',
});

const CustomDialogActions = styled(DialogActions)({
  padding: '8px',
});

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  title,
  open,
  message,
  singleAction,
  onConfirm,
  onCancel,
}) => {
  return (
    <Dialog open={open} onClose={onCancel}>
      <CustomDialogTitle>{title}</CustomDialogTitle>
      <CustomDialogContent>
        <CustomDialogContentText>{message}</CustomDialogContentText>
      </CustomDialogContent>
      <CustomDialogActions>
        {!singleAction && <CancelButton onClick={onCancel}>Não</CancelButton>}
        <ConfirmButton onClick={onConfirm} autoFocus>
          {singleAction ? 'Ok' : 'Sim'}
        </ConfirmButton>
      </CustomDialogActions>
    </Dialog>
  );
};

export default ConfirmationModal;
