import React, {
  useMemo,
} from 'react';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import QCXCircularProgress from '../../shared-components/progress/QCXCircularProgress';
import { isSubmittingStatus } from '../../utils/hooks/form/dialog/formDialogUtils';

export default function QCXGenericDownloader({
  status,
  loading = false,
  message = 'Preparando o download do(s) arquivo(s)...',
}) {
  const isSubmitting = useMemo(() => (
    isSubmittingStatus(status) || loading
  ), [
    status,
    loading
  ]);

  return (
    <>
      {
        isSubmitting && (
          <Snackbar
            open
            anchorOrigin={{
              horizontal: 'center',
              vertical: 'bottom',
            }}
          >
            <Alert
              severity="success"
              icon={(
                <QCXCircularProgress
                  size={20}
                />
              )}
            >
              {message}
            </Alert>
          </Snackbar>
        )
      }
    </>
  );
}
