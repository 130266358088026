import React, {
  useMemo,
} from 'react';
import {
  Grid,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import QCXFinalForm from '../../shared-components/final-form/QCXFinalForm';
import { selectRelatedSelectionItemList } from '../../features/fatura/faturaSelectors';
import QCXFaturaEstornoDataGridManager from './QCXFaturaEstornoDataGridManager';

export default function QCXFaturaEstornoForm({
  isBackgroundReversal,
  initialValues,
  handleSubmit,
  selectionList,
  ...restProps
}) {
  const { t } = useTranslation();

  const relatedSelectionItemList = useSelector(selectRelatedSelectionItemList);

  const isEmptySelectionItemList = useMemo(() => (
    !relatedSelectionItemList || (
      _.isArrayLikeObject(relatedSelectionItemList)
      && _.isEmpty(relatedSelectionItemList)
    )
  ), [relatedSelectionItemList]);

  const controlButtons = useMemo(() => ({
    create: {
      description: t('com.muralis.qcx.acoes.estornar'),
      disabled: isEmptySelectionItemList,
    },
  }), [isEmptySelectionItemList]);

  return (
    <QCXFinalForm
      initialValues={initialValues}
      handleSubmitValues={handleSubmit}
      controlButtons={controlButtons}
      {...restProps}
    >
      {() => (
        <>
          <Grid
            item
            container
            spacing={2}
            style={{
              marginTop: '0.5rem',
              marginBottom: '1rem',
            }}
          >
            <Grid
              item
              container
              xs={12}
            >
              <QCXFaturaEstornoDataGridManager
                isBackgroundReversal={isBackgroundReversal}
                rows={selectionList}
              />
            </Grid>
          </Grid>
        </>
      )}
    </QCXFinalForm>
  );
}
