import React from 'react';
import { formatAgencia } from '../../utils/hooks/form/field/formatters';
import { parseAgencia } from '../../utils/hooks/form/field/parsers';
import { validateAgencia } from '../../utils/validators/field/validator';
import QCXFinalTextField from '../final-text-field/QCXFinalTextField';

export default function QCXFinalAgenciaTextField({
  id,
  key,
  name,
  label,
  disabled,
  ...restProps
}) {
  return (
    <QCXFinalTextField
      id={id}
      key={key}
      name={name}
      label={label}
      validate={validateAgencia}
      parse={parseAgencia}
      format={formatAgencia}
      formatOnBlur
      maxLength={6}
      disabled={disabled}
      {...restProps}
    />
  );
}
