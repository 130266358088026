import { createSlice } from '@reduxjs/toolkit';
import { AUTO_LOAD_CONTROL, IDLE_CONTROL } from '../config-control';
import i18n from '../../i18n';
import {
  CONSULT_MODE,
  CREATE_MODE,
  UPDATE_MODE,
  LOCKED_MODE,
  NONE_MODE,
  BACKGROUND_CREATE_MODE,
  BACKGROUND_UPDATE_MODE,
  BACKGROUND_DELETE_MODE,
  NONE_BACKGROUND_MODE,
  SUB_CREATE_MODE,
  SUB_CONSULT_MODE,
  SUB_UPDATE_MODE,
  NONE_SUB_MODE,
  BACKGROUND_CONSULT_MODE,
  NONE_SUB_BACKGROUND_MODE,
} from '../mode';
import { NO_REFRESH, REFRESH } from '../refresh';
import {
  ALTERNATIVE_LOADING_STATUS,
  FAILURE_STATUS,
  IDLE_STATUS,
  LOADING_STATUS,
  PREPARING_ACTION_STATUS,
  SUCCESS_STATUS,
} from '../status';
import followUpOrigemFaturamentoThunks from './followUpOrigemFaturamentoThunks';

const initialState = {
  status: IDLE_STATUS,
  mode: {
    main: NONE_MODE,
    background: NONE_BACKGROUND_MODE,
    sub: NONE_SUB_MODE,
    subBackground: NONE_SUB_BACKGROUND_MODE,
  },
  refresh: NO_REFRESH,
  error: null,
  response: {
    status: null,
    message: null,
    data: null,
  },
  config: {
    control: AUTO_LOAD_CONTROL,
  },
  list: [],
};

const followUpOrigemFaturamentoSlice = createSlice({
  name: 'followUpOrigemFaturamento',
  initialState,
  reducers: {
    changeStatusTo: (state, action) => {
      state.status = action.payload.status;
    },
    loading: (state) => {
      state.status = LOADING_STATUS;
    },
    success: (state) => {
      state.status = SUCCESS_STATUS;
    },
    failure: (state) => {
      state.status = FAILURE_STATUS;
    },
    preparingAction: (state) => {
      state.status = PREPARING_ACTION_STATUS;
    },
    resetStatus: (state) => {
      state.status = IDLE_STATUS;
    },
    refresh: (state) => {
      state.refresh = REFRESH;
    },
    resetRefresh: (state) => {
      state.refresh = NO_REFRESH;
    },
    changeModeTo: (state, action) => {
      state.mode.main = action.payload;
    },
    changeToCreateMode: (state) => {
      state.mode.main = CREATE_MODE;
    },
    changeToConsultMode: (state) => {
      state.mode.main = CONSULT_MODE;
    },
    changeToUpdateMode: (state) => {
      state.mode.main = UPDATE_MODE;
    },
    changeToLockMode: (state) => {
      state.mode.main = LOCKED_MODE;
    },
    resetMode: (state) => {
      state.mode.main = NONE_MODE;
    },
    changeToBackgroundCreateMode: (state) => {
      state.mode.background = BACKGROUND_CREATE_MODE;
    },
    changeToBackgroundConsultMode: (state) => {
      state.mode.background = BACKGROUND_CONSULT_MODE;
    },
    changeToBackgroundUpdateMode: (state) => {
      state.mode.background = BACKGROUND_UPDATE_MODE;
    },
    changeToBackgroundDeleteMode: (state) => {
      state.mode.background = BACKGROUND_DELETE_MODE;
    },
    resetBackgroundMode: (state) => {
      state.mode.background = NONE_BACKGROUND_MODE;
    },
    changeToSubCreateMode: (state) => {
      state.mode.sub = SUB_CREATE_MODE;
    },
    changeToSubConsultMode: (state) => {
      state.mode.sub = SUB_CONSULT_MODE;
    },
    changeToSubUpdateMode: (state) => {
      state.mode.sub = SUB_UPDATE_MODE;
    },
    resetSubMode: (state) => {
      state.mode.sub = NONE_SUB_MODE;
    },
    changeControlToAutoLoad: (state) => {
      state.config.control = AUTO_LOAD_CONTROL;
    },
    resetControl: (state) => {
      state.config.control = IDLE_CONTROL;
    },
    setModel: (state, action) => {
      state.model = {
        ...action.payload,
      };
    },
    resetModel: (state) => {
      state.model = initialState.model;
    },
    setList: (state, action) => {
      state.list = action.payload.data;
    },
    resetList: (state) => {
      state.list = [];
    },
    addToList: (state, action) => {
      state.list = [...state.list, action.payload.data];
    },
    updateOnList: (state, action) => {
      state.list = state.list.map((current) => {
        if (current.id === action.payload.data?.id) {
          return action.payload.data;
        }
        return current;
      });
    },
    setResponse: (state, action) => {
      state.response = action.payload;
    },
    clearResponse: (state) => {
      state.response = {
        status: null,
        message: null,
        data: null,
      };
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    clearError: (state) => {
      state.error = initialState.error;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(followUpOrigemFaturamentoThunks.fetchAllAsync.pending, (state) => {
        state.status = ALTERNATIVE_LOADING_STATUS;
      })
      .addCase(followUpOrigemFaturamentoThunks.fetchAllAsync.fulfilled, (state, action) => {
        state.list = action.payload.data;
        state.status = IDLE_STATUS;
        state.refresh = NO_REFRESH;
      })
      .addCase(followUpOrigemFaturamentoThunks.fetchAllAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroCarregarListaFollowUp', {
            mensagem: action.error.message,
          }),
        };
        state.refresh = NO_REFRESH;
      });
  },
});

const { resetRefresh } = followUpOrigemFaturamentoSlice.actions;

const followUpOrigemFaturamentoActions = followUpOrigemFaturamentoSlice.actions;

export {
  resetRefresh,
  followUpOrigemFaturamentoSlice,
  followUpOrigemFaturamentoActions,
};

export default followUpOrigemFaturamentoSlice.reducer;
