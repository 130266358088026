import { utils, writeFile } from 'xlsx';
import {
  DraftDanfeReportItem,
  ReportItemsSums,
} from './relatorioDraftDanfePage.types';

const headers = [
  'ncm',
  'adicao',
  'item',
  'codigo',
  'quantidade',
  'descricao_dos_produtos',
  'fob',
  'peso_bruto_item',
  'frete',
  'seguro',
  'acrescimo',
  'cif',
  'aliquota_ii',
  'aliquota_ipi',
  'aliquota_pis',
  'aliquota_cofins',
  'fator_icms',
  'aliquota_icms',
  'valor_ii_real',
  'valor_ipi_real',
  'valor_pis_real',
  'valor_cofins_real',
  'valor_taxa_siscomex_real',
  'soma_total',
  'base_icms',
  'base_reduzida',
  'valor_icms_real',
  'valor_afrmm_real',
  'numero_danfe',
  'quantidade_volumes',
  'especie_volume',
  'transportadora',
  'di',
];

const formattedHeaders = [
  'NCM',
  'Adição',
  'Item',
  'Código',
  'QTDE',
  'Descrição dos Produtos',
  'FOB R$',
  'Peso Bruto Total do Item',
  'Frete R$',
  'Seguro R$',
  'Acréscimo',
  'CIF R$',
  '% II',
  '% IPI',
  '% PIS',
  '% COFINS',
  'ICMS Base',
  '% ICMS',
  'II',
  'IPI',
  'PIS',
  'COFINS',
  'TAXA SISCOMEX',
  'Soma',
  'Base ICMS R$',
  'BASE Reduzida',
  'Valor do ICMS',
  'AFRMM',
  'Invoice',
  'Quantidade de Volumes',
  'Especie de Volume',
  'Transportadora',
  'DI',
];

/**
 * It takes an array of items and returns an array of arrays of items, where each sub-array contains
 * all the items with the same DANFE number
 * @param {DraftDanfeReportItem[]} items - DraftDanfeReportItem[]
 * @returns An array of arrays of DraftDanfeReportItem
 */
export const groupItemsByDanfe = (items: DraftDanfeReportItem[]) => {
  const groupedItems: { [key: number]: DraftDanfeReportItem[] } = {};
  items.forEach((item) => {
    if (!groupedItems[item.numero_danfe]) {
      groupedItems[item.numero_danfe] = [];
    }
    groupedItems[item.numero_danfe].push(item);
  });
  return Object.values(groupedItems);
};

/**
 * It sums up all the values of a given group of items
 * @param {DraftDanfeReportItem[]} group - DraftDanfeReportItem[]
 * @returns An object with the sums of the values of the items in the group.
 */
const calculateGroupSums = (group: DraftDanfeReportItem[]) => {
  const sums: ReportItemsSums = {
    quantidades: 0,
    fobs: 0,
    pesosBrutos: 0,
    fretes: 0,
    seguros: 0,
    acrescimos: 0,
    cifs: 0,
    valoresII: 0,
    valoresIPI: 0,
    valoresPIS: 0,
    valoresCOFINS: 0,
    valoresTaxaSiscomex: 0,
    valoresSoma: 0,
    valoresBasesICMS: 0,
    valoresICMS: 0,
    valoresAFRMM: 0,
    valoresBaseReduzida: 0,
  };
  group.forEach((item) => {
    sums.quantidades += item.quantidade;
    sums.fobs += item.fob;
    sums.pesosBrutos += item.peso_bruto_item;
    sums.fretes += item.frete;
    sums.seguros += item.seguro;
    sums.acrescimos += item.acrescimo;
    sums.cifs += item.cif;
    sums.valoresII += item.valor_ii_real;
    sums.valoresIPI += item.valor_ipi_real;
    sums.valoresPIS += item.valor_pis_real;
    sums.valoresCOFINS += item.valor_cofins_real;
    sums.valoresTaxaSiscomex += item.valor_taxa_siscomex_real;
    sums.valoresSoma += item.soma_total;
    sums.valoresBasesICMS += item.base_icms;
    sums.valoresICMS += item.valor_icms_real;
    sums.valoresAFRMM += item.valor_afrmm_real;
    sums.valoresBaseReduzida += item.base_reduzida ? item.base_reduzida : 0;
  });
  return sums;
};

/**
 * It takes an array of arrays of DraftDanfeReportItem objects, and generates an Excel spreadsheet with
 * each array as a separate worksheet
 * @param {DraftDanfeReportItem[][]} data - DraftDanfeReportItem[][]
 * @param {string | undefined} processo - string | undefined
 */
export const generateSpreadsheet = (
  data: DraftDanfeReportItem[][],
  processo: string | undefined
) => {
  const workbook = utils.book_new();
  data.forEach((group) => {
    const sums = calculateGroupSums(group);
    const worksheet = utils.json_to_sheet(group, { header: headers });

    // Add the formatted headers
    utils.sheet_add_aoa(worksheet, [formattedHeaders], {
      origin: { c: 0, r: 0 },
    });

    // Add totals row
    utils.sheet_add_aoa(
      worksheet,
      [
        [
          'TOTAL',
          '.',
          '.',
          '.',
          sums.quantidades,
          '.',
          sums.fobs,
          sums.pesosBrutos,
          sums.fretes,
          sums.seguros,
          sums.acrescimos,
          sums.cifs,
          '.',
          '.',
          '.',
          '.',
          '.',
          '.',
          sums.valoresII,
          sums.valoresIPI,
          sums.valoresPIS,
          sums.valoresCOFINS,
          sums.valoresTaxaSiscomex,
          sums.valoresSoma,
          '.',
          sums.valoresBaseReduzida,
          sums.valoresICMS,
          sums.valoresAFRMM,
          '.',
        ],
      ],
      {
        origin: -1,
      }
    );

    // Add quantidade row
    utils.sheet_add_aoa(
      worksheet,
      [['QUANTIDADE', group[0].quantidade_volumes, 'BAU DE METAL']],
      {
        origin: -1,
      }
    );

    // Add container row
    utils.sheet_add_aoa(worksheet, [['CONTAINER', '?']], {
      origin: -1,
    });

    // Add DI row
    utils.sheet_add_aoa(worksheet, [['DI', group[0].di]], {
      origin: -1,
    });

    // Add frete row
    utils.sheet_add_aoa(worksheet, [['FRETE', sums.fretes]], {
      origin: -1,
    });

    // Add peso bruto row
    utils.sheet_add_aoa(worksheet, [['PESO BRUTO', sums.pesosBrutos]], {
      origin: -1,
    });

    // Add peso liquido row
    utils.sheet_add_aoa(
      worksheet,
      [['PESO LIQUIDO', group[0].peso_liquido_total]],
      {
        origin: -1,
      }
    );

    // Add SICOMEX row
    utils.sheet_add_aoa(worksheet, [['SICOMEX', sums.valoresTaxaSiscomex]], {
      origin: -1,
    });

    // Add Transportadora row
    utils.sheet_add_aoa(
      worksheet,
      [['TRANSPORTADORA', group[0].transportadora]],
      {
        origin: -1,
      }
    );

    // Add taxa SEK row
    utils.sheet_add_aoa(worksheet, [['TAXA SEK', group[0].moeda_taxa]], {
      origin: -1,
    });

    // Set A column width
    worksheet['!cols'] = [{ wch: 20 }];

    utils.book_append_sheet(
      workbook,
      worksheet,
      `Danfe ${group[0].numero_danfe}`
    );
  });

  writeFile(workbook, `Relatório Draft Danfe - ${processo}.xlsx`);
};
