import { useMemo } from "react";
import { useGetRequest, QueriesTypes } from "../../../../common/hooks/queries";
import { Servico } from "../../../../common/types/servico";

export const useListServicosClienteAssessoria = (
  clientId: number | null
): [Servico[], boolean, boolean] => {
  const {
    data: servicosData,
    isError,
    isLoading,
  } = useGetRequest(
    QueriesTypes.ServicosClientes,
    clientId ?? undefined,
    clientId ? `produto=ASSESSORIA` : undefined
  );

  const servicos: Servico[] = useMemo(() => {
    if (!clientId) {
      return [];
    }

    if (servicosData && !isError) {
      return servicosData;
    } else {
      return [];
    }
  }, [servicosData, isError]);

  return [servicos, isLoading, isError];
};
