import { isStrictEquals } from '../general-utils';

const TIPO_RELATORIO_CONTAS_RECEBER = 'CONTAS_RECEBER';
const ACTION_CANCELAR = 'CANCELAR';
const STATUS_PENDENTE = 'PENDENTE';
const STATUS_CANCELADO = 'CANCELADO';
const STATUS_RECEBIDO = 'RECEBIDO';
const MOVIMENTACOES_SAIDA = 'SAIDA';
const MOVIMENTACOES_ENTRADA = 'ENTRADA';
const MOVIMENTACOES_TRANSFERENCIA = 'TRANSFERENCIA';
const TITULO_MOVIMENTACAO_CONTA_TRANSFERENCIA = 'TITULO_MOVIMENTACAO_CONTA';

const isReportContasReceber = (status) => (
  isStrictEquals(TIPO_RELATORIO_CONTAS_RECEBER, status)
);

const isStatusRecebidos = (status) => (
  isStrictEquals(STATUS_RECEBIDO, status)
);

const isStatusPendente = (status) => (
  isStrictEquals(STATUS_PENDENTE, status)
);

const isStatusCancelado = (status) => (
  isStrictEquals(STATUS_CANCELADO, status)
);

const valorTipoMovimentacao = (movimentacoes, tipo) => (
  movimentacoes
    .filter((conta) => (conta?.tipo === tipo))
    .map((movimentacao) => movimentacao?.valor)
);

const somaTipoMovimentacao = (tipoMovimentacaoLista) => (tipoMovimentacaoLista
  .reduce((soma, atual) => soma + atual)
);

const calculoValorRecebido = (movimentacoes = []) => {
  const movimentacoesAtivas = movimentacoes.length > 0;
  let valorRecebido = 0;

  if (movimentacoesAtivas) {
    const contasReceberEntradaLista = valorTipoMovimentacao(movimentacoes, MOVIMENTACOES_ENTRADA);
    const contasReceberSaidaLista = valorTipoMovimentacao(movimentacoes, MOVIMENTACOES_SAIDA);

    const contasReceberEntradaListaAtivas = contasReceberEntradaLista.length > 0;
    const contasReceberSaidaListaAtivas = contasReceberSaidaLista.length > 0;

    const contasReceberEntrada = (contasReceberEntradaListaAtivas ? contasReceberEntradaLista : 0);
    const contasReceberSaida = (contasReceberSaidaListaAtivas ? contasReceberSaidaLista : 0);

    const somaReceberEntrada = (contasReceberEntrada !== 0
      ? somaTipoMovimentacao(contasReceberEntrada) : 0);

    const somaReceberSaida = (contasReceberSaida !== 0
      ? somaTipoMovimentacao(contasReceberSaida) : 0);

    if (somaReceberEntrada > somaReceberSaida) {
      valorRecebido = (somaReceberEntrada - somaReceberSaida);
    } else {
      valorRecebido = (somaReceberSaida - somaReceberEntrada);
    }
  }
  return valorRecebido;
};

const calculoSaldoContasReceber = (movimentacoes = [], valorReceber, juros) => {
  const desconto = ((juros ?? 0) / 100);
  const valorDescontado = valorReceber + (valorReceber * desconto);

  const valorRecebido = calculoValorRecebido(movimentacoes);
  const valorSaldo = valorDescontado - valorRecebido;
  return valorSaldo;
};

const contasReceberUtils = {
  CONTAS_RECEBER: TIPO_RELATORIO_CONTAS_RECEBER,
  CANCELAR: ACTION_CANCELAR,
  SAIDA: MOVIMENTACOES_SAIDA,
  ENTRADA: MOVIMENTACOES_ENTRADA,
  PENDENTE: STATUS_PENDENTE,
  TRANSFERENCIA: MOVIMENTACOES_TRANSFERENCIA,
  TITULO_TRANSFERENCIA: TITULO_MOVIMENTACAO_CONTA_TRANSFERENCIA,
  isRecebido: (
    isStatusRecebidos
  ),
  isContasReceber: (
    isReportContasReceber
  ),
  isCancelado: (
    isStatusCancelado
  ),
  isPendente: (
    isStatusPendente
  ),
  calculoValorRecebido,
  calculoSaldoContasReceber,
};

export default contasReceberUtils;
