const selectAprovarRecusarPagamentos = (state) => state.aprovarRecusarPagamento.list;
const selectAprovarRecusarPagamento = (state) => state.aprovarRecusarPagamento.model;
const selectRelatedAprovarRecusarItemModel = (state) => (
  state.aprovarRecusarPagamento.related.model.aprovarRecusarItem
);

const selectRelatedModelFollowUp = (state) => (
  state.aprovarRecusarPagamento.related.model.followUp
);
const selectRelatedSelectionSolicitacoesList = (state) => (
  state.aprovarRecusarPagamento.related.list.selectionSolicitacoes
);
const selectStatus = (state) => state.aprovarRecusarPagamento.status;
const selectRefresh = (state) => state.aprovarRecusarPagamento.refresh;
const selectMode = (state) => state.aprovarRecusarPagamento.mode.main;
const selectBackgroundMode = (state) => state.aprovarRecusarPagamento.mode.background;
const selectSubMode = (state) => state.aprovarRecusarPagamento.mode.sub;
const selectSubBackgroundMode = (state) => state.aprovarRecusarPagamento.mode.subBackground;
const selectType = (state) => state.aprovarRecusarPagamento.type;
const selectHistoryModel = (state) => state.aprovarRecusarPagamento.historyModel;
const selectError = (state) => state.aprovarRecusarPagamento.error;
const selectResponse = (state) => state.aprovarRecusarPagamento.response;

const solicitacaoPagamentoSelectors = {
  selectAprovarRecusarPagamentos,
  selectAprovarRecusarPagamento,
  selectStatus,
  selectRefresh,
  selectMode,
  selectBackgroundMode,
  selectSubMode,
  selectSubBackgroundMode,
  selectType,
  selectHistoryModel,
  selectError,
  selectResponse,
  selectRelatedModelFollowUp,
  selectRelatedSelectionSolicitacoesList,
  selectRelatedAprovarRecusarItemModel,
};

export {
  solicitacaoPagamentoSelectors,
  selectAprovarRecusarPagamentos,
  selectAprovarRecusarPagamento,
  selectStatus,
  selectRefresh,
  selectMode,
  selectBackgroundMode,
  selectSubMode,
  selectSubBackgroundMode,
  selectType,
  selectHistoryModel,
  selectError,
  selectResponse,
  selectRelatedModelFollowUp,
  selectRelatedSelectionSolicitacoesList,
  selectRelatedAprovarRecusarItemModel,
};
