import { createSlice } from '@reduxjs/toolkit';
import { AUTO_LOAD_CONTROL } from '../config-control';
import {
  CONSULT_MODE,
  CREATE_MODE,
  NONE_BACKGROUND_MODE,
  NONE_MODE,
  NONE_SUB_MODE,
  UPDATE_MODE,
} from '../mode';
import { NO_REFRESH, REFRESH } from '../refresh';
import {
  FAILURE_STATUS,
  IDLE_STATUS,
  LOADING_STATUS,
  SUCCESS_STATUS,
  ALTERNATIVE_LOADING_STATUS,
} from '../status';
import {
  notificacaoThunks,
} from './notificacaoThunks';
import i18n from '../../i18n';

const initialState = {
  status: IDLE_STATUS,
  mode: {
    main: NONE_MODE,
    background: NONE_BACKGROUND_MODE,
    sub: NONE_SUB_MODE,
  },
  type: '',
  refresh: NO_REFRESH,
  error: null,
  response: {
    status: null,
    message: null,
    data: null,
  },
  config: {
    control: AUTO_LOAD_CONTROL,
  },
  list: [],
  related: {
    model: {
      novaNotificacao: {},
      badgeContent: 0,
    },
  },
};

const notificacaoSlice = createSlice({
  name: 'notificacao',
  initialState,
  reducers: {
    changeStatusTo: (state, action) => {
      state.status = action.payload.status;
    },
    loading: (state) => {
      state.status = LOADING_STATUS;
    },
    success: (state) => {
      state.status = SUCCESS_STATUS;
    },
    failure: (state) => {
      state.status = FAILURE_STATUS;
    },
    resetStatus: (state) => {
      state.status = IDLE_STATUS;
    },
    refresh: (state) => {
      state.refresh = REFRESH;
    },
    resetRefresh: (state) => {
      state.refresh = NO_REFRESH;
    },
    changeToCreateMode: (state) => {
      state.mode.main = CREATE_MODE;
    },
    changeToConsultMode: (state) => {
      state.mode.main = CONSULT_MODE;
    },
    changeToUpdateMode: (state) => {
      state.mode.main = UPDATE_MODE;
    },
    resetMode: (state) => {
      state.mode.main = NONE_MODE;
    },
    resetBackgroundMode: (state) => {
      state.mode.background = NONE_BACKGROUND_MODE;
    },
    resetSubMode: (state) => {
      state.mode.sub = NONE_SUB_MODE;
    },
    changeControlTo: (state, action) => {
      state.config.control = action.payload;
    },
    resetControl: (state) => {
      state.config.control = initialState.config.control;
    },
    updateOnList: (state, action) => {
      state.list = state.list.map((current) => {
        if (current.id === action.payload.data?.id) {
          return action.payload.data;
        }
        return current;
      });
    },
    setResponse: (state, action) => {
      state.response = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    clearResponse: (state) => {
      state.response = {
        status: null,
        message: null,
        data: null,
      };
    },
    clearError: (state) => {
      state.error = initialState.error;
    },
    resetList: (state) => {
      state.list = [];
    },
    setList: (state, action) => {
      state.list = action.payload;
    },
    resetNovaNotificacao: (state) => {
      state.related.model.novaNotificacao = {};
    },
    setNovaNotificacao: (state, action) => {
      state.related.model.novaNotificacao = action.payload;
    },
    resetBadgeContent: (state) => {
      state.related.model.badgeContent = 0;
    },
    setBadgeContent: (state, action) => {
      state.related.model.badgeContent = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(notificacaoThunks.fetchByUnidadeDeNegocioAsync.pending, (state) => {
        state.status = ALTERNATIVE_LOADING_STATUS;
      })
      .addCase(notificacaoThunks.fetchByUnidadeDeNegocioAsync.fulfilled, (state, action) => {
        state.status = IDLE_STATUS;
        state.list = action.payload.data;
      })
      .addCase(notificacaoThunks.fetchByUnidadeDeNegocioAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroCarregarListaNotificacoes', { erro: action.error.message }),
        };
        state.refresh = NO_REFRESH;
      });
  },
});

const {
  changeStatusTo,
  loading,
  success,
  failure,
  resetStatus,
  resetMode,
  resetBackgroundMode,
  resetSubMode,
  changeControlTo,
  resetControl,
  setModel,
  resetModel,
  resetList,
  setList,
  refresh,
  resetRefresh,
  updateOnList,
  setResponse,
  setResponseMessage,
  clearResponse,
  setError,
  clearError,
  resetNovaNotificacao,
  setNovaNotificacao,
  resetBadgeContent,
  setBadgeContent,
} = notificacaoSlice.actions;

const notificacaoActions = {
  ...notificacaoSlice.actions,
};

export {
  notificacaoSlice,
  changeStatusTo,
  loading,
  success,
  failure,
  resetStatus,
  resetMode,
  resetBackgroundMode,
  resetSubMode,
  changeControlTo,
  resetControl,
  setModel,
  resetModel,
  resetList,
  setList,
  updateOnList,
  setResponse,
  setResponseMessage,
  clearResponse,
  setError,
  clearError,
  resetNovaNotificacao,
  setNovaNotificacao,
  refresh,
  resetRefresh,
  notificacaoActions,
  resetBadgeContent,
  setBadgeContent,
};

export default notificacaoSlice.reducer;
