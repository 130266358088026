import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import QCXFieldsViewerManager from '../fields-viewer-manager/QCXFieldsViewerManager';
import QCXTextFieldViewer from '../../shared-components/text-field/QCXTextFieldViewer';
import FollowUpStatusUtils from '../../utils/general/follow-up/FollowUpStatusUtils';
import { formatCnpj } from '../../utils/hooks/form/field/formatters';
import { formatDate } from '../../utils/general/general-utils';

export default function QCXFollowUpFieldsViewerManager({
  model,
  ...restProps
}) {
  const { t } = useTranslation();

  const formattedValues = useMemo(() => ({
    ...model,
    importador: {
      ...model?.importador,
      pessoa: {
        ...model?.importador?.pessoa,
        cnpj: formatCnpj(
          model?.importador?.pessoa?.cnpj
        ),
      },
    },
    insertionDate: formatDate(
      model?.insertionDate,
      t('com.muralis.qcx.timezone.diaHorario'),
      t('com.muralis.qcx.timezone.diaSemHorario')
    ),
    status: FollowUpStatusUtils.getLabelByValue(model?.status) 
    ? t(FollowUpStatusUtils.getLabelByValue(model?.status)) 
    : '-',

  }), [
    model,
  ]);

  return (
    <QCXFieldsViewerManager
      values={formattedValues}
      disableHelperText
      {...restProps}
    >
      {(fieldsViewerProps) => (
        <Grid
          item
          container
          spacing={2}
        >
          <Grid item xs={12} sm={4} md={3} lg={2}>
            <QCXTextFieldViewer
              key="text-field-processo-viewer"
              id="text-field-processo-viewer"
              label={t('com.muralis.qcx.processo')}
              name="numero"
              {...fieldsViewerProps}
            />
          </Grid>
          <Grid item xs={12} sm={8} md={6} lg={4}>
            <QCXTextFieldViewer
              key="text-field-cliente-viewer"
              id="text-field-cliente-viewer"
              label={t('com.muralis.qcx.cliente.label')}
              name="importador.pessoa.nome"
              {...fieldsViewerProps}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3} lg={2}>
            <QCXTextFieldViewer
              key="text-field-cnpj-viewer"
              id="text-field-cnpj-viewer"
              label={t('com.muralis.qcx.empresa.CNPJ')}
              name="importador.pessoa.cnpj"
              {...fieldsViewerProps}
            />
          </Grid>
          <Grid item xs={12} sm={8} md={6} lg={4}>
            <QCXTextFieldViewer
              key="text-field-servico-viewer"
              id="text-field-servico-viewer"
              label={t('com.muralis.qcx.servico.labelSingular')}
              name="servico.description"
              {...fieldsViewerProps}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3} lg={2}>
            <QCXTextFieldViewer
              key="text-field-insertion-date-viewer"
              id="text-field-insertion-date-viewer"
              label={t('com.muralis.qcx.dataAbertura')}
              name="insertionDate"
              {...fieldsViewerProps}
            />
          </Grid>
          <Grid item xs={12} sm={8} md={3} lg={4}>
            <QCXTextFieldViewer
              key="text-field-status-viewer"
              id="text-field-status-viewer"
              label={t('com.muralis.qcx.status')}
              name="status"
              {...fieldsViewerProps}
            />
          </Grid>
        </Grid>
      )}
    </QCXFieldsViewerManager>
  );
}
