import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IDLE_CONTROL } from '../config-control';
import {
  ALTERNATIVE_LOADING_STATUS,
  FAILURE_STATUS, IDLE_STATUS, LOADING_STATUS, SUCCESS_STATUS,
} from '../status';
import {
  fetchUnidadesDeNegocioAssociadas,
} from './usuarioLogadoAPI';
import { fetchById as fetchUnidadeNegocioById } from '../unidade-negocio/unidadeNegocioAPI';
import i18n from '../../i18n';

const initialState = {
  status: IDLE_STATUS,
  config: {
    control: IDLE_CONTROL,
  },
  error: null,
  response: {
    status: null,
    message: null,
    data: null,
  },
  list: [],
  unidadeSelecionada: '',
  related: {
    unidadeNegocio: {},
  },
};

const fetchUnidadesDeNegocioAsync = createAsyncThunk(
  'usuarioLogado/fetchUnidadesDeNegocio',
  async () => {
    const { data } = await fetchUnidadesDeNegocioAssociadas();
    return { data };
  }
);

const fetchUnidadesNegocioByIdAsync = createAsyncThunk(
  'usuarioLogado/fetchUnidadeDeNegocioById',
  async (id) => {
    const { data } = await fetchUnidadeNegocioById(id);
    return { data };
  }
);

const UnidadeNegocioSlice = createSlice({
  name: 'usuarioLogado',
  initialState,
  reducers: {
    changeStatusTo: (state, action) => {
      state.status = action.payload.status;
    },
    loading: (state) => {
      state.status = LOADING_STATUS;
    },
    success: (state) => {
      state.status = SUCCESS_STATUS;
    },
    failure: (state) => {
      state.status = FAILURE_STATUS;
    },
    resetStatus: (state) => {
      state.status = IDLE_STATUS;
    },
    setResponse: (state, action) => {
      state.response = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setConfig: (state, action) => {
      state.config = action.payload;
    },
    setUnidadeSelecionada: (state, action) => {
      state.unidadeSelecionada = action.payload;
    },
    changeControlTo: (state, action) => {
      state.config.control = action.payload;
    },
    clearResponse: (state) => {
      state.response = {
        status: null,
        message: null,
        data: null,
      };
    },
    resetList: (state) => {
      state.list = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUnidadesDeNegocioAsync.pending, (state) => {
        state.status = ALTERNATIVE_LOADING_STATUS;
      })
      .addCase(fetchUnidadesDeNegocioAsync.fulfilled, (state, action) => {
        state.status = IDLE_STATUS;
        state.list = action.payload.data;
      })
      .addCase(fetchUnidadesDeNegocioAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.carregarUnidadeNegocioAtual', { erro: action.error.message }),
        };
      })
      .addCase(fetchUnidadesNegocioByIdAsync.pending, (state) => {
        state.status = ALTERNATIVE_LOADING_STATUS;
      })
      .addCase(fetchUnidadesNegocioByIdAsync.fulfilled, (state, action) => {
        state.status = IDLE_STATUS;
        state.related.unidadeNegocio = action.payload.data;
      })
      .addCase(fetchUnidadesNegocioByIdAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.carregarUnidadesNegocio', { erro: action.error.message }),
        };
      });
  },
});

const {
  changeStatusTo,
  loading,
  success,
  failure,
  resetStatus,
  setResponse,
  setResponseMessage,
  clearResponse,
  setError,
  resetList,
  setUnidadeSelecionada,
} = UnidadeNegocioSlice.actions;

const selectUnidadesDeNegocioAssociadas = (state) => state.usuarioLogado.list;
const selectUnidadeSelecionada = (state) => state.usuarioLogado.unidadeSelecionada;
const selectRelatedUnidadeNegocioModel = (state) => state.usuarioLogado.related.unidadeNegocio;
const selectStatus = (state) => state.usuarioLogado.status;
const selectError = (state) => state.usuarioLogado.error;
const selectResponse = (state) => state.usuarioLogado.response;
const selectConfigControl = (state) => state.unidadeNegocio.config.control;

export {
  changeStatusTo,
  loading,
  success,
  failure,
  resetStatus,
  setResponse,
  setResponseMessage,
  clearResponse,
  setError,
  setUnidadeSelecionada,
  fetchUnidadesDeNegocioAsync,
  fetchUnidadesNegocioByIdAsync,
  resetList,
  selectConfigControl,
  selectUnidadesDeNegocioAssociadas,
  selectUnidadeSelecionada,
  selectRelatedUnidadeNegocioModel,
  selectStatus,
  selectError,
  selectResponse,
};

export default UnidadeNegocioSlice.reducer;
