import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import {
  CLOSED_STATUS,
  isOpenStatus,
} from '../../utils/hooks/form/dialog/formDialogUtils';
import QCXFinalFormDialog from './QCXFinalFormDialog';
import QCXMoveFocusInside from '../../components/focus-lock/QCXMoveFocusInside';
import QCXFinalMultilineTextField from '../final-text-field/QCXFinalMultilineTextField';

const useStyles = makeStyles(() => ({
  breakWord: {
    wordBreak: 'break-word',
  },
}));

export default function QCXJustifiedActionFormDialog({
  status,
  handleStatus,
  handleSubmit,
  initialValues,
  type,
}) {
  const { t } = useTranslation();

  const classes = useStyles();

  const handleCloseFormDialog = useCallback(() => (
    handleStatus(CLOSED_STATUS)
  ), [handleStatus]);

  const isFormDialogOpen = useMemo(() => (
    isOpenStatus(status)
  ), [status]);

  const numeroProcessoFollowUp = initialValues?.followUp?.numero || '';

  const AVALIACAO_SOLICITACAO_PAGAMENTO_DIALOG_PROPS = {
    PROCESSO_COM_SALDO_INSUFICIENTE: {
      title: t('com.muralis.qcx.aprovarPagamento.aprovarPagamentoSaldoInsuficiente'),
      content: t('com.muralis.qcx.mensagem.justifiqueMotivoAprovacaoSaldoInsuficiente', { numero: numeroProcessoFollowUp }),
      justificativa: true,
      buttonState: false,
    },
    AG_APROVACAO: {
      title: t('com.muralis.qcx.aprovarPagamento.label'),
      content: t('com.muralis.qcx.mensagem.aprovarSolicitacaoPagamento', { numero: numeroProcessoFollowUp }),
      justificativa: false,
      buttonState: true,
    },
    RECUSADO_PELO_APROVADOR: {
      title: t('com.muralis.qcx.recusarPagamento.label'),
      content: t('com.muralis.qcx.mensagem.justifiqueMotivoRecusa', { numero: numeroProcessoFollowUp }),
      justificativa: true,
      buttonState: false,
    },
    CANCELAR: {
      title: t('com.muralis.qcx.cancelamentoContaPagar'),
      content: t('com.muralis.qcx.mensagem.justifiqueMotivoCancelamentoPagamentoConta', { conta: numeroProcessoFollowUp }),
      justificativa: true,
      buttonState: false,
    },
  };

  const dialogActionButtons = {
    button: {
      submit: {
        description: t('com.muralis.qcx.acoes.confirmar'),
        size: 'small',
      },
      cancel: {
        description: t('com.muralis.qcx.acoes.cancelar'),
        size: 'small',
      },
    },
  };

  return (
    <QCXFinalFormDialog
      key="form-dialog-pagamento"
      id="form-dialog-pagamento"
      title={AVALIACAO_SOLICITACAO_PAGAMENTO_DIALOG_PROPS[type].title}
      content={AVALIACAO_SOLICITACAO_PAGAMENTO_DIALOG_PROPS[type].content}
      dialogActionsProps={dialogActionButtons}
      open={isFormDialogOpen}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      onClose={handleCloseFormDialog}
      className={classes.breakWord}
      disablePristine={AVALIACAO_SOLICITACAO_PAGAMENTO_DIALOG_PROPS[type].buttonState}
    >
      {
      AVALIACAO_SOLICITACAO_PAGAMENTO_DIALOG_PROPS[type].justificativa && (
        () => (
          <QCXMoveFocusInside>
            <QCXFinalMultilineTextField
              id="multiline-text-field-justificativa"
              key="multiline-text-field-justificativa"
              name="justificativa"
              label={t('com.muralis.qcx.justificativa')}
              rows={5}
            />
          </QCXMoveFocusInside>
        ))
      }
    </QCXFinalFormDialog>
  );
}
