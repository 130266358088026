import {
  doGetQCXRequest,
} from '../../utils/api/domain/domain-api-utils';
import { MODALIDADE_PAGAMENTO_ENDPOINT } from '../../utils/api/domain/domain-endpoint-utils';

export async function fetchAll() {
  return doGetQCXRequest([
    MODALIDADE_PAGAMENTO_ENDPOINT
  ]);
}

export async function fetchById(id) {
  return doGetQCXRequest([
    MODALIDADE_PAGAMENTO_ENDPOINT,
    id
  ]);
}
