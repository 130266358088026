const selectDeclaracoesImportacao = (state) => state.declaracaoImportacao.list;
const selectDeclaracaoImportacao = (state) => state.declaracaoImportacao.model;
const selectRelatedReportModel = (state) => state.declaracaoImportacao.related.model.report;
const selectRelatedItemModel = (state) => state.declaracaoImportacao.related.model.item;
const selectRelatedAdicaoModel = (state) => state.declaracaoImportacao.related.model.adicao;
const selectRelatedCalculoModel = (state) => (
  state.declaracaoImportacao.related.model.calculo
);
const selectRelatedDuplicataModel = (state) => (
  state.declaracaoImportacao.related.model.duplicata
);
const selectRelatedRegistroModel = (state) => (
  state.declaracaoImportacao.related.model.registro
);
const selectRelatedEmissaoIcmsModel = (state) => (
  state.declaracaoImportacao.related.model.emissaoIcms
);
const selectHistoryModel = (state) => state.declaracaoImportacao.historyModel;
const selectStatus = (state) => state.declaracaoImportacao.status;
const selectRefresh = (state) => state.declaracaoImportacao.refresh;
const selectMode = (state) => state.declaracaoImportacao.mode.main;
const selectBackgroundMode = (state) => state.declaracaoImportacao.mode.background;
const selectSubMode = (state) => state.declaracaoImportacao.mode.sub;
const selectSubBackgroundMode = (state) => state.declaracaoImportacao.mode.subBackground;
const selectError = (state) => state.declaracaoImportacao.error;
const selectResponse = (state) => state.declaracaoImportacao.response;
const selectConditionalSteps = (state) => (
  state.declaracaoImportacao.config.conditionalSteps
);

const declaracaoImportacaoSelectors = {
  selectDeclaracoesImportacao,
  selectDeclaracaoImportacao,
  selectRelatedReportModel,
  selectRelatedItemModel,
  selectRelatedAdicaoModel,
  selectRelatedCalculoModel,
  selectRelatedDuplicataModel,
  selectRelatedRegistroModel,
  selectRelatedEmissaoIcmsModel,
  selectHistoryModel,
  selectStatus,
  selectRefresh,
  selectMode,
  selectBackgroundMode,
  selectSubMode,
  selectSubBackgroundMode,
  selectError,
  selectResponse,
  selectConditionalSteps,
};

export {
  declaracaoImportacaoSelectors,
  selectDeclaracoesImportacao,
  selectDeclaracaoImportacao,
  selectRelatedReportModel,
  selectRelatedItemModel,
  selectRelatedAdicaoModel,
  selectRelatedCalculoModel,
  selectRelatedDuplicataModel,
  selectRelatedRegistroModel,
  selectRelatedEmissaoIcmsModel,
  selectHistoryModel,
  selectStatus,
  selectRefresh,
  selectMode,
  selectBackgroundMode,
  selectSubMode,
  selectSubBackgroundMode,
  selectError,
  selectResponse,
  selectConditionalSteps,
};
