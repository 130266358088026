import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import { GridColDef, GridValueFormatterParams } from "@mui/x-data-grid";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  TextField,
} from "@mui/material";
import { SyntheticEvent, useEffect, useMemo, useState } from "react";
import PageLayout from "../../../../common/layouts/pageLayout";
import DataTable from "../../../../common/components/dataTable";
import {
  ValorHonorario,
  useApplyIndices,
  useListCondicionaisValores,
  useListValores,
} from "./propostasIndices.hooks";
import {
  formatDateISOString,
  formatStringToDate,
} from "../../../../common/utils/parsers/dateParsers";
import { brlFormatterRounded } from "../../../../common/utils/formatters/currencyFormatters";
import DatePickerStyled from "../../../../common/components/input/datePickerStyled";
import MultiSelectStyled from "../../../../common/components/input/multiSelectStyled";
import { arrayIncludesObject } from "../../../../common/utils/arrayIncludesObject";
import PercentageTextField from "../../../../common/components/input/percentageTextField";

const PropostasIndices = () => {
  const [startDate, setStartDate] = useState<Date>(() => new Date());
  const [endDate, setEndDate] = useState<Date>(() => new Date());
  const [indiceValue, setIndiceValue] = useState(0);

  const [isGlobalUpdate, setIsGlobalUpdate] = useState(false);

  const [valores, isLoadingValores] = useListValores({
    startDate,
    endDate,
    isGlobal: isGlobalUpdate,
  });

  const [valoresCondicionais, isLoadingCondicionais] =
    useListCondicionaisValores({
      startDate,
      endDate,
      isGlobal: isGlobalUpdate,
    });

  const [selectedCondicionais, setSelectedCondicionais] = useState<
    ValorHonorario[]
  >([]);

  useEffect(() => {
    const selected = valoresCondicionais.filter((valor) =>
      arrayIncludesObject(
        selectedCondicionais.map((v) => ({ id: v.id, value: v.nomeValor })),
        { id: valor.id, value: valor.nomeValor }
      )
    );

    setSelectedCondicionais([...selected]);
  }, [valoresCondicionais]);

  const rows = useMemo(
    () => (selectedCondicionais.length > 0 ? selectedCondicionais : valores),
    [valores, selectedCondicionais]
  );

  const availableOptions = useMemo(
    () =>
      valoresCondicionais.filter(
        (value) => !arrayIncludesObject(selectedCondicionais, value)
      ),
    [selectedCondicionais, valoresCondicionais]
  );

  const isLoading = useMemo(
    () => isLoadingValores || isLoadingCondicionais,
    [isLoadingValores, isLoadingCondicionais]
  );

  const [applyIndiceLocal] = useApplyIndices();

  const handleAplicar = () => {
    applyIndiceLocal({
      dataDeInicio: startDate.toString(),
      dataDeFim: endDate.toString(),
      valor: indiceValue,
      isGlobal: isGlobalUpdate,
      isConditional: selectedCondicionais.length > 0,
      conditionalsToUpdate: selectedCondicionais,
    });
  };

  const columns: GridColDef[] = [
    {
      field: "cliente",
      headerName: "Cliente",
      flex: 1,
      headerClassName: "header-theme",
      type: "string",
    },
    {
      field: "data",
      headerName: "Data",
      flex: 1,
      headerClassName: "header-theme",
      type: "date",
    },
    {
      field: "servico",
      headerName: "Serviço",
      flex: 1,
      headerClassName: "header-theme",
      type: "string",
    },
    {
      field: "valor",
      headerName: "Valor",
      flex: 1,
      headerClassName: "header-theme",
      valueFormatter: (params: GridValueFormatterParams<any>) => {
        return brlFormatterRounded.format(params.value);
      },
    },
  ];

  function handleSelectConditional(
    event: SyntheticEvent<Element, Event>,
    values:
      | {
          id: string | number | undefined;
          value: string | undefined;
        }[]
      | null
  ): void {
    if (values === null) {
      setSelectedCondicionais([]);
      return;
    }

    const selected = valoresCondicionais.filter((valor) =>
      arrayIncludesObject(values, { id: valor.id, value: valor.nomeValor })
    );
    setSelectedCondicionais([...selected]);
  }

  return (
    <PageLayout
      title={"Atualização de Índices"}
      icon={<HistoryEduIcon color={"secondary"} />}
    >
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <FormControl fullWidth required>
            <DatePickerStyled
              label="Início do Período"
              value={formatDateISOString(startDate.toString(), "YYYY-MM-DD")}
              onChange={(date) =>
                setStartDate(
                  date ? formatStringToDate(date, "YYYY-MM-DD") : new Date()
                )
              }
            ></DatePickerStyled>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl fullWidth required>
            <DatePickerStyled
              label="Fim do Período"
              value={formatDateISOString(endDate.toString(), "YYYY-MM-DD")}
              onChange={(date) =>
                setEndDate(
                  date ? formatStringToDate(date, "YYYY-MM-DD") : new Date()
                )
              }
            ></DatePickerStyled>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl fullWidth>
            <TextField
              required
              label="Índice"
              size="small"
              value={indiceValue.toString()}
              onChange={(e) => setIndiceValue(parseFloat(e.target.value))}
              InputProps={{
                inputComponent: PercentageTextField as any,
              }}
              InputLabelProps={{
                shrink: true,
              }}
            ></TextField>
          </FormControl>
        </Grid>
        <Grid container item xs={2} justifyContent={"center"}>
          <FormControlLabel
            control={
              <Checkbox
                checked={isGlobalUpdate}
                onChange={(e) => setIsGlobalUpdate(!isGlobalUpdate)}
                color="secondary"
              />
            }
            label="Somente Valores Globais"
          />
        </Grid>
        <Grid item xs={10}>
          <FormControl fullWidth>
            <MultiSelectStyled
              options={availableOptions.map((valor) => ({
                id: valor.id,
                value: valor.nomeValor,
              }))}
              label={"Somente Condicionais:"}
              onChangeAction={handleSelectConditional}
            ></MultiSelectStyled>
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <Button
            onClick={() => handleAplicar()}
            fullWidth
            variant="contained"
            color="secondary"
          >
            Aplicar
          </Button>
        </Grid>
        <Grid item xs={12}>
          {!isLoading && (
            <DataTable
              columns={columns}
              rows={rows.map((v, index) => ({
                id: index,
                servico: `${v.nome}: ${v.nomeValor}`,
                cliente: v.cliente ?? "Global",
                data: v.data
                  ? formatDateISOString(v.data.toString(), "DD/MM/YYYY")
                  : "Global",
                valor: v.valor,
              }))}
            ></DataTable>
          )}
        </Grid>
      </Grid>
    </PageLayout>
  );
};

export default PropostasIndices;
