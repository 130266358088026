import React, {
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useHistory } from 'react-router';
import {
  changeModeTo,
  failure,
  loading,
  resetRelatedSelectionFaturaList,
  resetRelatedSelectionItemList,
  resetStatus,
  setError,
  setResponse,
  success,
} from '../../../../features/fatura/faturaSlice';
import {
  selectStatus,
  selectMode,
  selectBackgroundMode,
  selectRelatedEstornoModel,
  selectRelatedSelectionAvailableItemList,
} from '../../../../features/fatura/faturaSelectors';
import {
  isCreateMode,
  isConsultMode,
  isUpdateMode,
  isLoadingStatus,
  isIdleStatus,
  isFailureStatus,
  isBackgroundReversalMode,
} from '../../../../utils/store/store-utils';
import QCXActionsManagerPageTemplate from '../../../../templates/actions-manager-page/QCXActionsManagerPageTemplate';
import useAutoChangeMode from '../../../../utils/hooks/mode/useAutoChangeMode';
import QCXFaturaEstornoForm from '../../../../components/fatura/QCXFaturaEstornoForm';
import { TipoContaFaturaUtils } from '../../../../shared-components/select-tipo-conta/tipoContaUtils';
import { mercadoriaMovimentacaoAPI } from '../../../../features/mercadoria-movimentacao/mercadoriaMovimentacaoAPI';
import { OperacaoMovimentacaoUtils } from '../../../../utils/general/movimentacao/movimentacaoUtils';

export default function FaturaReversalManagerPage({ authInfo = {} }) {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const status = useSelector(selectStatus);
  const mode = useSelector(selectMode);
  const backgroundMode = useSelector(selectBackgroundMode);
  const estornoModel = useSelector(selectRelatedEstornoModel);
  const selectionAvailableItemList = useSelector(selectRelatedSelectionAvailableItemList);

  const pageRole = useMemo(() => (
    'importacao-fatura'
  ), []);

  const isLoading = useMemo(() => (
    isLoadingStatus(status)
  ), [status]);

  const isIdle = useMemo(() => (
    isIdleStatus(status)
  ), [status]);

  const isFailure = useMemo(() => (
    isFailureStatus(status)
  ), [status]);

  const isCreate = useMemo(() => (
    isCreateMode(mode)
  ), [mode]);

  const isConsult = useMemo(() => (
    isConsultMode(mode)
  ), [mode]);

  const isUpdate = useMemo(() => (
    isUpdateMode(mode)
  ), [mode]);

  const isBackgroundReversal = useMemo(() => (
    isBackgroundReversalMode(backgroundMode)
  ), [backgroundMode]);

  const selectedAvailableTransferItemsList = useMemo(() => {
    if (!selectionAvailableItemList || (
      _.isArrayLikeObject(selectionAvailableItemList)
      && _.isEmpty(selectionAvailableItemList)
    )) {
      return [];
    }

    return selectionAvailableItemList;
  }, [selectionAvailableItemList]);

  const normalize = useCallback((values) => {
    const items = values?.itens?.map((item) => ({
      operacao: {
        description: OperacaoMovimentacaoUtils.ESTORNO,
      },
      movimentacao: {
        id: item?.id,
      },
    }));

    return items;
  });

  const handleSubmit = useCallback(async (values) => {
    const normalizedData = normalize(values);

    const origem = (
      TipoContaFaturaUtils.isTipoFaturaLi(values?.conta?.tipo?.description)
        ? t('com.muralis.qcx.LI')
        : t('com.muralis.qcx.DIDUIMP.label')
    );

    dispatch(loading());

    const response = await mercadoriaMovimentacaoAPI.reverse(normalizedData)
      .catch((reason) => {
        const message = reason?.response?.data?.message;

        dispatch(failure());
        dispatch(setError({
          message: t('com.muralis.qcx.erro.erroEstornoItens', { origem, mensagem: message }),
        }));
      });

    if (response?.status === 201) {
      const feedbackMessage = t('com.muralis.qcx.mensagem.estornoItensFatura', { origem });

      dispatch(success());
      dispatch(setResponse({
        status: response.status,
        data: response?.data,
        message: feedbackMessage,
      }));

      history.replace(t('com.muralis.qcx.url.importacaoFaturas'));
    }
  }, [history]);

  useEffect(() => {
    dispatch(resetStatus());

    const resetOnUnmount = () => {
      dispatch(resetRelatedSelectionFaturaList());
      dispatch(resetRelatedSelectionItemList());
    };

    return resetOnUnmount;
  }, []);

  const breadcrumbs = useMemo(() => ([
    {
      link: {
        to: '/',
        name: t('com.muralis.qcx.inicio'),
      },
    },
    {
      link: {
        to: t('com.muralis.qcx.url.moduloImportacao'),
        name: t('com.muralis.qcx.importacao.label'),
      },
    },
    {
      link: {
        to: t('com.muralis.qcx.url.importacaoFaturas'),
        name: t('com.muralis.qcx.fatura.labelPlural'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.estorno.label'),
      },
    },
  ]), []);

  const pageTitle = useMemo(() => (
    t('com.muralis.qcx.estorno.tituloEstornoFatura')
  ), []);

  const pageSubtitle = useMemo(() => {
    const tipoOrigem = TipoContaFaturaUtils.getLabelFaturaByValue(
      estornoModel?.conta?.tipo?.description
    );

    return {
      show: true,
      text: (
        tipoOrigem
          ? t('com.muralis.qcx.estorno.estornarItensOrigem', { origem: tipoOrigem })
          : t('com.muralis.qcx.estorno.estornarItens')
      ),
    };
  }, [estornoModel]);

  useAutoChangeMode((currentMode) => (
    dispatch(changeModeTo(currentMode))
  ), [isUpdate]);

  return (
    <QCXActionsManagerPageTemplate
      key="actions-fatura-reversal-manager-page"
      pageTitle={pageTitle}
      breadcrumbs={breadcrumbs}
      isIdle={isIdle}
      isLoading={isLoading}
      isFailure={isFailure}
      isCreate={isCreate}
      isConsult={isConsult}
      isUpdate={isUpdate}
      subtitle={pageSubtitle}
      authInfo={authInfo}
    >
      {() => (
        <>
          <QCXFaturaEstornoForm
            isBackgroundReversal={isBackgroundReversal}
            initialValues={estornoModel}
            handleSubmit={handleSubmit}
            selectionList={selectedAvailableTransferItemsList}
            authInfo={authInfo}
            requiredRoles={[pageRole]}
          />
        </>
      )}
    </QCXActionsManagerPageTemplate>
  );
}
