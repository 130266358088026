import React, { useMemo } from 'react';
import { v4 as uuid } from 'uuid';
import { BRAZILIAN } from '../../utils/hooks/form/field/mask-types';
import { useNumericMask } from '../../utils/hooks/form/field/useFieldMask';
import QCXFinalFieldWrapper from '../final-field/QCXFinalFieldWrapper';
import QCXInputAdornment from '../input-adornment/QCXInputAdornment';
import QCXTextField from '../text-field/QCXTextField';

export default function QCXFinalAlternativeCurrencyField({
  key = '',
  name,
  label,
  maxLength,
  required,
  validate,
  intl = BRAZILIAN,
  scale = 2,
  adorned = false,
  formatOnBlur = true,
  submitSucceeded,
  ...restProps
}) {
  const [format, parse] = useNumericMask({
    intl,
    scale,
  });

  // FIXME: Solucao provisoria
  const generatedKey = useMemo(() => (submitSucceeded ? uuid() : key), [submitSucceeded, key]);

  const adornmentSymbol = useMemo(() => {
    if (adorned && intl === BRAZILIAN) {
      return 'R$';
    }
    if (adorned && intl !== BRAZILIAN) {
      return '$';
    }
    return undefined;
  }, [adorned, intl]);

  return (
    <QCXFinalFieldWrapper
      key={generatedKey}
      name={name}
      label={label}
      maxLength={maxLength}
      required={required}
      component={QCXTextField}
      validate={validate}
      format={format}
      parse={parse}
      formatOnBlur={formatOnBlur}
      InputProps={{
        startAdornment: adornmentSymbol && <QCXInputAdornment>{adornmentSymbol}</QCXInputAdornment>,
      }}
      {...restProps}
    />
  );
}
