import debounce from 'lodash/debounce';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import QCXGrupoUnidadeNegocioForm from '../../../../../components/grupo-unidade-negocio/QCXGrupoUnidadeNegocioForm';
import { register, save } from '../../../../../features/grupo-unidade-negocio/grupoUnidadesNegocioAPI';
import {
  addToList,
  changeToBackgroundCreateMode,
  changeToConsultMode,
  changeToCreateMode,
  changeToUpdateMode,
  failure,
  fetchByIdAsync,
  loading,
  preparingAction,
  resetBackgroundMode,
  resetModel,
  selectBackgroundMode,
  selectGrupoUnidadeNegocio,
  selectMode,
  selectStatus,
  setError,
  setModel,
  setResponse,
  success,
  updateOnList,
  changeControlTo as changeControlGrupoUnidadeDeNegocioTo,
} from '../../../../../features/grupo-unidade-negocio/grupoUnidadeNegocioSlice';
import QCXRegistrationFormPageTemplate from '../../../../../templates/registration-form-page/QCXRegistrationFormPageTemplate';
import {
  isBackgroundCreateMode,
  isConsultMode,
  isCreateMode,
  isNoneMode,
  isPreparingActionStatus,
  isUpdateMode,
} from '../../../../../utils/store/store-utils';
import { IDLE_CONTROL } from '../../../../../features/config-control';
import { fetchByFilterAsync } from '../../../../../features/unidade-negocio/unidadeNegocioSlice';

export default function GrupoUnidadeNegocioRegistrationPage({ authInfo = {} }) {
  const { t } = useTranslation();

  const { id } = useParams();

  const history = useHistory();

  const dispatch = useDispatch();

  const status = useSelector(selectStatus);
  const mode = useSelector(selectMode);
  const backgroundMode = useSelector(selectBackgroundMode);
  const grupoUnidadeNegocio = useSelector(selectGrupoUnidadeNegocio);

  const isNone = useMemo(() => (
    isNoneMode(mode)
  ), [mode]);

  const isCreate = useMemo(() => (
    isCreateMode(mode)
  ), [mode]);

  const isBackgroundCreate = useMemo(() => (
    isBackgroundCreateMode(backgroundMode)
  ), [backgroundMode]);

  const isConsult = useMemo(() => (
    isConsultMode(mode)
  ), [mode]);

  const isUpdate = useMemo(() => (
    isUpdateMode(mode)
  ), [mode]);

  const isPreparingAction = useMemo(() => (
    isPreparingActionStatus(status)
  ), [status]);

  const handleChangeToPreparingAction = () => {
    dispatch(preparingAction());
  };

  const handleChangeToCreate = () => {
    dispatch(changeToCreateMode());
  };

  const handleChangeToBackgroundCreate = () => {
    dispatch(changeToBackgroundCreateMode());
  };

  const handleChangeToConsult = () => {
    dispatch(changeToConsultMode());
  };

  const handleChangeToUpdate = () => {
    dispatch(changeToUpdateMode());
  };

  const handleResetBackgroundMode = () => {
    dispatch(resetBackgroundMode());
  };

  useEffect(() => {
    if (id) {
      dispatch(fetchByIdAsync(id));
      handleChangeToConsult();
    }
  }, [id]);

  useEffect(() => {
    dispatch(changeControlGrupoUnidadeDeNegocioTo(IDLE_CONTROL));
    dispatch(fetchByFilterAsync([{ name: 'grupo', value: id }]));
  }, []);
  const handleCancelUpdate = () => {
    const currentId = isBackgroundCreate ? grupoUnidadeNegocio?.id : id;

    if (currentId) {
      dispatch(fetchByIdAsync(currentId));
    }
    handleChangeToConsult();
  };

  const normalize = useCallback((data) => {
    const normalizedData = {
      ...data,
      padrao: data?.padrao?.id ? data.padrao : null,
    };

    return normalizedData;
  }, []);

  const handleDispatchSetModel = useCallback((data) => {
    dispatch(setModel(normalize(data)));
  }, [normalize]);

  const create = async (data) => {
    const executeDebounced = debounce(async () => {
      try {
        const response = await register(data);

        if (response?.status === 201) {
          dispatch(resetModel());

          const created = response?.data;

          handleDispatchSetModel(created);
          dispatch(addToList({ data: created }));

          const handleResultWithDebounce = debounce(() => {
            history.push(t('com.muralis.qcx.url.moduloGeraisGrupoUnidadeNegocio'));

            dispatch(success());
            dispatch(setResponse({
              status: response.status,
              data: created,
              message: t('com.muralis.qcx.mensagem.grupoUnidadeNegociosCadastrado', { grupo: created?.description }),
            }));

            dispatch(addToList({ data: created }));
          }, 500);

          handleResultWithDebounce();
        }
      } catch ({ response }) {
        dispatch(failure());
        dispatch(setError({
          message: t('com.muralis.qcx.erro.erroCadastroGrupoUnidadesNegocio',
            { erro: response.data.message }),
        }));
      }
    }, 500);

    dispatch(loading());
    executeDebounced();
  };

  const update = async (data) => {
    const executeDebounced = debounce(async () => {
      try {
        const response = await save(data);

        if (response?.status === 200) {
          const handleResultWithDebounce = debounce(() => {
            handleChangeToConsult();
            dispatch(success());

            const saved = response?.data;

            dispatch(setResponse({
              status: response.status,
              data: saved,
              message: t('com.muralis.qcx.mensagem.grupoUnidadesNegociosAlteracao', { grupo: saved?.description }),
            }));

            dispatch(updateOnList({ data: saved }));
            dispatch(fetchByIdAsync(saved?.id));
          }, 500);

          handleResultWithDebounce();
        }
      } catch ({ response }) {
        dispatch(failure());
        dispatch(setError({
          message: t('com.muralis.qcx.erro.erroAlteracaoGrupoUnidadesNegocios', { erro: response.data.message }),
        }));
      }
    }, 500);

    dispatch(loading());
    executeDebounced();
  };

  const handleSubmit = async (data) => {
    const normalizedData = normalize(data);

    if (isUpdate && !isBackgroundCreate) {
      await update(normalizedData);
    } else {
      await create(normalizedData);
    }
  };

  const model = useMemo(() => (
    !isCreate
      ? grupoUnidadeNegocio
      : {}
  ), [isCreate, grupoUnidadeNegocio]);

  const actionName = useMemo(() => {
    if (isCreate || isBackgroundCreate || isNone) return t('com.muralis.qcx.acoes.novo');
    if (isConsult) return t('com.muralis.qcx.acoes.visualizar');
    return t('com.muralis.qcx.acoes.alterar');
  }, [isNone, isCreate, isConsult, isBackgroundCreate]);

  const breadcrumbs = useMemo(() => ([
    {
      link: {
        to: '/',
        name: t('com.muralis.qcx.inicio'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.cadastros'),
      },
    },
    {
      link: {
        to: t('com.muralis.qcx.url.moduloCadastrosGerais'),
        name: t('com.muralis.qcx.gerais'),
      },
    },
    {
      link: {
        to: t('com.muralis.qcx.url.moduloGeraisGrupoUnidadeNegocio'),
        name: t('com.muralis.qcx.gruposUnidadeNegocio'),
      },
    },
    {
      text: {
        name: actionName,
      },
    },
  ]), [actionName]);

  const pageTitle = useMemo(() => (
    isNone || isCreate || isBackgroundCreate
      ? t('com.muralis.qcx.acoes.novoGrupo')
      : t('com.muralis.qcx.grupoIdentificacao', { identificacao: grupoUnidadeNegocio?.description || grupoUnidadeNegocio?.id || '-' })
  ), [isNone, isCreate, isBackgroundCreate, grupoUnidadeNegocio]);

  return (
    <QCXRegistrationFormPageTemplate
      pageTitle={pageTitle}
      breadcrumbs={breadcrumbs}
      isCreate={isCreate}
      isConsult={isConsult}
      isUpdate={isUpdate}
      isBackgroundCreate={isBackgroundCreate}
      isPreparingAction={isPreparingAction}
      handleChangeToPreparingAction={handleChangeToPreparingAction}
      handleChangeToCreate={handleChangeToCreate}
      handleChangeToBackgroundCreate={handleChangeToBackgroundCreate}
      handleChangeToConsult={handleChangeToConsult}
      handleChangeToUpdate={handleChangeToUpdate}
      handleCancelUpdate={handleCancelUpdate}
      handleResetBackgroundMode={handleResetBackgroundMode}
      authInfo={authInfo}
    >
      {(formProps) => (
        <QCXGrupoUnidadeNegocioForm
          initialValues={model}
          handleChangeModel={handleDispatchSetModel}
          handleSubmit={handleSubmit}
          authInfo={authInfo}
          requiredRoles={['grupo']}
          {...formProps}
        />
      )}
    </QCXRegistrationFormPageTemplate>
  );
}
