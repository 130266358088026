import { getOptionByValueInList } from '../../utils/general/general-utils';
import i18n from '../../i18n';

const ENQUADRAMENTO_LEGAL_NACIONALIZACAO = 'NACIONALIZACAO';
const ENQUADRAMENTO_LEGAL_ADMISSAO_TEMPORARARIA = 'ADMISSAO_TEMPORARARIA';

const ENQUADRAMENTO_LEGAL_LIST = [
  {
    label: i18n.t('com.muralis.qcx.nacionalizacao').toUpperCase(),
    value: ENQUADRAMENTO_LEGAL_NACIONALIZACAO,
  },
  {
    label: i18n.t('com.muralis.qcx.admissaoTemporaria').toUpperCase(),
    value: ENQUADRAMENTO_LEGAL_ADMISSAO_TEMPORARARIA,
  },
];

const isEnquadramentoLegalNacionalizacao = (value) => (
  ENQUADRAMENTO_LEGAL_NACIONALIZACAO === value
);

const isEnquadramentoLegalAdmissaoTemporaria = (value) => (
  ENQUADRAMENTO_LEGAL_ADMISSAO_TEMPORARARIA === value
);

const getEnquadramentoLegalLabelByValue = (value) => {
  const foundOption = getOptionByValueInList(ENQUADRAMENTO_LEGAL_LIST, value);
  return foundOption && foundOption?.label;
};

export {
  ENQUADRAMENTO_LEGAL_ADMISSAO_TEMPORARARIA,
  ENQUADRAMENTO_LEGAL_NACIONALIZACAO,
  ENQUADRAMENTO_LEGAL_LIST,
};

export {
  isEnquadramentoLegalNacionalizacao,
  isEnquadramentoLegalAdmissaoTemporaria,
  getEnquadramentoLegalLabelByValue,
};
