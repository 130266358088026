import { IconButton } from '@material-ui/core';
import {
  Delete as DeleteIcon,
  Search as PageviewIcon,
} from '@material-ui/icons';
import {
  useCallback,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { userHasDeleteRole } from '../../../../../components/perfil/perfil-utils';
import QCXSimpleConsultPageTemplate from '../../../../../templates/simple-consult-page/QCXSimpleConsultPageTemplate';
import useOperationController from '../../../../../utils/hooks/store/operation/useOperationController';
import {
  activateByIdAsync, changeToConsultMode, changeToCreateMode, fetchAllAsync, inactivateByIdAsync, resetMode, resetModel, selectDesvios,
} from './api/desvioSlice';

export default function DesviosConsultPage({ authInfo = {} }) {
  const { t } = useTranslation();

  const history = useHistory();

  const dispatch = useDispatch();

  const desvios = useSelector(selectDesvios);

  const fetchAll = useCallback(() => {
    dispatch(fetchAllAsync());
  }, []);

  const resetModes = useCallback(() => {
    dispatch(resetMode());
  }, []);

  const resetModels = useCallback(() => {
    dispatch(resetModel());
  }, []);

  const resetDependencies = useCallback(() => {
  }, []);

  useEffect(() => {
    fetchAll();
    resetModes();
    resetModels();
    resetDependencies();
  }, []);

  const handleActivateInactivate = useOperationController(
    async (event, id) => {
      event.preventDefault();

      const { active } = desvios.find(
        (etapa) => etapa.id === id
      );

      const isActivation = !active;

      if (isActivation) {
        dispatch(activateByIdAsync(id));
      } else {
        dispatch(inactivateByIdAsync(id));
      }
    }
  );

  const handleClickOnButtonNew = useCallback((event) => {
    event.stopPropagation();
    dispatch(changeToCreateMode());
  }, []);

  const handleClickOnEditButton = useCallback((event, id) => {
    event.stopPropagation();
    dispatch(changeToConsultMode());
    history.push(t('com.muralis.qcx.url.moduloGeraisServicosDesviosDetalhes', { id }));
  }, [history]);

  const columns = [
    {
      field: 'code',
      headerName: 'Código',
      type: 'string',
      flex: 190,
      valueGetter: ({ row }) => (
        row?.code
      ),
    },
    {
      field: 'description',
      headerName: t('com.muralis.qcx.nome'),
      type: 'string',
      flex: 190,
      valueGetter: ({ row }) => (
        row?.description
      ),
    },
    {
      field: 'actions',
      headerName: t('com.muralis.qcx.acoes.label'),
      headerAlign: 'center',
      align: 'center',
      flex: 100,
      renderCell: ({ row: { id, active } }) => (
        <>

          {userHasDeleteRole(authInfo, 'etapa') && (
            <IconButton
              key={`stw-active${id}`}
              value={active}
              name={`stw-active-${id}`}
              onClick={(event) => handleActivateInactivate(event, id)}
            >
              <DeleteIcon color="error" />
            </IconButton>
          )}
          <IconButton
            key={`btn-view-${id}`}
            name={`btn-view-${id}`}
            onClick={(event) => handleClickOnEditButton(event, id)}
          >
            <PageviewIcon color="primary" />
          </IconButton>
        </>
      ),
    },
  ];

  const breadcrumbs = [
    {
      link: {
        to: '/',
        name: t('com.muralis.qcx.inicio'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.cadastros'),
      },
    },
    {
      link: {
        to: t('com.muralis.qcx.url.moduloCadastrosGerais'),
        name: t('com.muralis.qcx.gerais'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.servico.labelPlural'),
      },
    },
    {
      text: {
        name: 'Desvios',
      },
    },
  ];

  const templateProps = {
    page: {
      title: 'Desvios',
      breadcrumbs,
    },
    control: {
      buttonNew: {
        description: 'Novo',
        path: t('com.muralis.qcx.url.moduloGeraisServicosDesviosNovo'),
        onClick: handleClickOnButtonNew,
      },
    },
    table: {
      columns,
    },
  };

  const filterProps = [
    'description',
  ];

  return (
    <QCXSimpleConsultPageTemplate
      pageProps={templateProps.page}
      controlProps={templateProps.control}
      tableProps={templateProps.table}
      tableData={desvios}
      buttonNewText={t('com.muralis.qcx.acoes.nova').toUpperCase()}
      multipleFilter
      filterProps={filterProps}
      authInfo={authInfo}
      requiredRoles={['etapa']}
    />
  );
}
