import { getOptionByValueInList } from '../../utils/general/general-utils';
import i18n from '../../i18n';

const MODALIDADE_DRAWBACK_NAO_TEM = 'NAO_TEM';
const MODALIDADE_DRAWBACK_SUSPENSAO_GENERICO = 'SUSPENSAO_GENERICO';
const MODALIDADE_DRAWBACK_SUSPENSAO_NAO_GENERICO = 'SUSPENSAO_NAO_GENERICO';
const MODALIDADE_DRAWBACK_ISENCAO_AC_WEB = 'ISENCAO_AC_WEB';
const MODALIDADE_DRAWBACK_ISENCAO_AC_PAPEL = 'ISENCAO_AC_PAPEL';

const MODALIDADE_DRAWBACK_LIST = [
  {
    label: i18n.t('com.muralis.qcx.drawback.naoTemDrawback').toUpperCase(),
    value: MODALIDADE_DRAWBACK_NAO_TEM,
  },
  {
    label: i18n.t('com.muralis.qcx.drawback.suspensaoGenerico').toUpperCase(),
    value: MODALIDADE_DRAWBACK_SUSPENSAO_GENERICO,
  },
  {
    label: i18n.t('com.muralis.qcx.drawback.suspensaoNaoGenerico').toUpperCase(),
    value: MODALIDADE_DRAWBACK_SUSPENSAO_NAO_GENERICO,
  },
  {
    label: i18n.t('com.muralis.qcx.drawback.isencaoACWEB').toUpperCase(),
    value: MODALIDADE_DRAWBACK_ISENCAO_AC_WEB,
  },
  {
    label: i18n.t('com.muralis.qcx.drawback.isencaoACPapel').toUpperCase(),
    value: MODALIDADE_DRAWBACK_ISENCAO_AC_PAPEL,
  },
];

const isModalidadeDrawbackSuspensaoGenerico = (value) => (
  MODALIDADE_DRAWBACK_SUSPENSAO_GENERICO === value
);

const isModalidadeDrawbackSuspensaoNaoGenerico = (value) => (
  MODALIDADE_DRAWBACK_SUSPENSAO_NAO_GENERICO === value
);

const isModalidadeDrawbackIsencaoAcWeb = (value) => (
  MODALIDADE_DRAWBACK_ISENCAO_AC_WEB === value
);

const isModalidadeDrawbackIsencaoAcPapel = (value) => (
  MODALIDADE_DRAWBACK_ISENCAO_AC_PAPEL === value
);

const isModalidadeDrawbackNaoTem = (value) => (
  MODALIDADE_DRAWBACK_NAO_TEM === value
);

const isModalidadeDrawbackSuspensaoOptions = (value) => (
  isModalidadeDrawbackSuspensaoGenerico(value)
  || isModalidadeDrawbackSuspensaoNaoGenerico(value)
);

const isModalidadeDrawbackIsencaoAcOptions = (value) => (
  isModalidadeDrawbackIsencaoAcWeb(value)
  || isModalidadeDrawbackIsencaoAcPapel(value)
);

const getModalidadeDrawbackLabelByValue = (value) => {
  const foundOption = getOptionByValueInList(MODALIDADE_DRAWBACK_LIST, value);
  return foundOption && foundOption?.label;
};

export {
  MODALIDADE_DRAWBACK_NAO_TEM,
  MODALIDADE_DRAWBACK_SUSPENSAO_GENERICO,
  MODALIDADE_DRAWBACK_SUSPENSAO_NAO_GENERICO,
  MODALIDADE_DRAWBACK_ISENCAO_AC_WEB,
  MODALIDADE_DRAWBACK_ISENCAO_AC_PAPEL,
  MODALIDADE_DRAWBACK_LIST,
};

export {
  isModalidadeDrawbackSuspensaoGenerico,
  isModalidadeDrawbackSuspensaoNaoGenerico,
  isModalidadeDrawbackIsencaoAcWeb,
  isModalidadeDrawbackIsencaoAcPapel,
  isModalidadeDrawbackNaoTem,
  isModalidadeDrawbackSuspensaoOptions,
  isModalidadeDrawbackIsencaoAcOptions,
  getModalidadeDrawbackLabelByValue,
};
