/* eslint-disable import/prefer-default-export */
import {
  MD_ASSIGNMENT_ICON,
  MD_BALLOT_ICON,
  MD_FLIGHT_LAND_ICON,
  MD_SURROUND_SOUND_ICON,
  MD_TRAFFIC_ICON,
  MD_LOCAL_SHIPPING_ICON,
  MD_RECEIPT_ICON,
  MD_UNARCHIVE_ICON,
  MD_ASSIGNMENT_TURNED_IN_ICON,
  MD_ACCOUNT_BALANCE_ICON,
  MD_OUTLINE_INTEGRATION_ICON,
  MD_DIRECTIONS_BOAT_FILLED_ICON,
  GR_MONEY_ICON,
  GR_DELIVER_ICON,
  GR_INSPECT_ICON,
  FA_WAREHOUSE_ICON,
  FA_FILE_INVOICE_DOLLAR_ICON,
  RI_FILE_TRANSFER_FILL_ICON,
  RI_TRUCKLINE_ICON,
  RI_BILL_LINE_ICON,
  GI_RECEIVE_MONEY_ICON,
  SI_SEMANTIC_RELEASE_ICON,
  HI_DOCUMENT_REPORT_ICON,
  filterIconOptionsByValues,
} from './iconUtils';

const ETAPA_ICON_LIST = filterIconOptionsByValues(
  [
    MD_ASSIGNMENT_ICON,
    MD_BALLOT_ICON,
    MD_FLIGHT_LAND_ICON,
    MD_SURROUND_SOUND_ICON,
    MD_TRAFFIC_ICON,
    MD_LOCAL_SHIPPING_ICON,
    MD_RECEIPT_ICON,
    MD_UNARCHIVE_ICON,
    MD_ASSIGNMENT_TURNED_IN_ICON,
    MD_ACCOUNT_BALANCE_ICON,
    MD_OUTLINE_INTEGRATION_ICON,
    MD_DIRECTIONS_BOAT_FILLED_ICON,
    GR_MONEY_ICON,
    GR_DELIVER_ICON,
    GR_INSPECT_ICON,
    FA_WAREHOUSE_ICON,
    FA_FILE_INVOICE_DOLLAR_ICON,
    RI_FILE_TRANSFER_FILL_ICON,
    RI_TRUCKLINE_ICON,
    RI_BILL_LINE_ICON,
    GI_RECEIVE_MONEY_ICON,
    SI_SEMANTIC_RELEASE_ICON,
    HI_DOCUMENT_REPORT_ICON,
  ]
);

export {
  ETAPA_ICON_LIST,
};
