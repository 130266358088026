import { createSlice } from '@reduxjs/toolkit';
import {
  NONE_SUB_MODE,
  SUB_CONSULT_MODE,
  SUB_CREATE_MODE,
  SUB_UPDATE_MODE,
} from '../mode';
import {
  IDLE_STATUS,
  SUCCESS_STATUS,
  LOADING_STATUS,
  FAILURE_STATUS,
} from '../status';

const initialState = {
  status: IDLE_STATUS,
  mode: {
    sub: NONE_SUB_MODE,
  },
  current: {},
};

const vinculoProcessoLicencaImportacaoSlice = createSlice({
  name: 'vinculoProcessoLicencaImportacao',
  initialState,
  reducers: {
    changeStatusTo: (state, action) => {
      state.status = action.payload.status;
    },
    loading: (state) => {
      state.status = LOADING_STATUS;
    },
    success: (state) => {
      state.status = SUCCESS_STATUS;
    },
    failure: (state) => {
      state.status = FAILURE_STATUS;
    },
    resetStatus: (state) => {
      state.status = IDLE_STATUS;
    },
    changeToSubCreateMode: (state) => {
      state.mode.sub = SUB_CREATE_MODE;
    },
    changeToSubConsultMode: (state) => {
      state.mode.sub = SUB_CONSULT_MODE;
    },
    changeToSubUpdateMode: (state) => {
      state.mode.sub = SUB_UPDATE_MODE;
    },
    resetSubMode: (state) => {
      state.mode.sub = NONE_SUB_MODE;
    },
    setCurrent: (state, action) => {
      state.current = action.payload;
    },
    resetCurrent: (state) => {
      state.current = initialState.current;
    },
  },
});

const {
  changeStatusTo,
  loading,
  success,
  failure,
  resetStatus,
  changeToSubConsultMode,
  changeToSubCreateMode,
  changeToSubUpdateMode,
  resetSubMode,
  setCurrent,
  resetCurrent,
} = vinculoProcessoLicencaImportacaoSlice.actions;

const selectStatus = (state) => state.vinculoProcessoLicencaImportacao.status;
const selectSubMode = (state) => state.vinculoProcessoLicencaImportacao.mode.sub;
const selectCurrent = (state) => state.vinculoProcessoLicencaImportacao.current;

export {
  vinculoProcessoLicencaImportacaoSlice,
  changeStatusTo,
  loading,
  success,
  failure,
  resetStatus,
  changeToSubConsultMode,
  changeToSubCreateMode,
  changeToSubUpdateMode,
  resetSubMode,
  setCurrent,
  resetCurrent,
  selectStatus,
  selectSubMode,
  selectCurrent,
};

export default vinculoProcessoLicencaImportacaoSlice.reducer;
