import { useTheme } from '@material-ui/core';
import { Grid, Typography } from '@mui/material';

const FormHeader = (props: {
  children: string;
  marginTop?: string;
  marginBottom?: string;
  textAlign?: 'left' | 'center' | 'right';
}) => {
  const theme = useTheme();
  return (
    <Grid item sm={12}>
      <Typography
        textAlign={props.textAlign ?? `left`}
        fontSize={20}
        fontWeight="bold"
        color={theme.palette.primary.main}
        margin={`${props.marginTop ? props.marginTop : '20px'} 0 ${
          props.marginBottom ? props.marginBottom : '0px'
        } 0`}
      >
        {props.children.toUpperCase()}
      </Typography>
    </Grid>
  );
};

export default FormHeader;
