import { debounce, isEmpty, isFunction } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { validate } from 'uuid';
import {
  changeToSubConsultMode,
  changeToSubCreateMode,
  changeToSubUpdateMode,
  loading,
  resetRelatedMercadoriaModel,
  resetStatus,
  resetSubMode,
  setRelatedMercadoriaModel,
} from '../../features/fatura/faturaSlice';
import { selectRelatedMercadoriaModel, selectSubMode } from '../../features/fatura/faturaSelectors';
import QCXFinalFormWizard from '../../shared-components/final-form-wizard/QCXFinalFormWizard';
import { isNoneSubMode, isSubConsultMode, isSubCreateMode, isSubUpdateMode } from '../../utils/store/store-utils';
import QCXFaturaFornecedorForm from './QCXFaturaFornecedorForm';
import QCXFaturaImportadorForm from './QCXFaturaImportadorForm';
import QCXFaturaMercadoriaForm from './QCXFaturaMercadoriaForm';
import QCXFaturaTotaisForm from './QCXFaturaTotaisForm';
import { resetBackgroundMode } from '../../features/catalogo-produtos/catalogoProdutosSlice';
import useOperationMiddleware from '../../utils/hooks/operation/middleware/useOperationMiddleware';
import QCXConfirmDialog from '../../shared-components/dialog/QCXConfirmDialog';
import { useMercadoriaUtils } from './mercadoriaUtils';

export default function QCXFaturaWizardFinalForm({
  handleSubmit,
  handleAlternativeSave,
  handleMercadoria,
  handleChangeToPreparingAction,
  model,
  handleChangeModel,
  refreshSelectedModel,
  handleChangeToCreate,
  handleChangeToConsult,
  handleChangeToUpdate,
  handleCancelUpdate,
  handleChangeToBackgroundCreate,
  handleChangeToBackgroundDelete,
  handleResetBackgroundMode,
  isIdle,
  isLoading,
  isFailure,
  isCreate,
  isConsult,
  isUpdate,
  isBackgroundCreate,
  isBackgroundDelete,
  isPreparingAction,
  authInfo = {},
  requiredRoles = [],
  ...restProps
}) {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const subMode = useSelector(selectSubMode);
  const mercadoria = useSelector(selectRelatedMercadoriaModel);

  const mercadoriaUtils = useMercadoriaUtils();

  const checkIfIsAlternativeSaveByStep = (currentStep) => currentStep === 3;

  const isSubNone = useMemo(() => isNoneSubMode(subMode), [subMode]);

  const isSubCreate = useMemo(() => isSubCreateMode(subMode), [subMode]);

  const isSubConsult = useMemo(() => isSubConsultMode(subMode), [subMode]);

  const isSubUpdate = useMemo(() => isSubUpdateMode(subMode), [subMode]);

  const handleLoadingStatus = useCallback(() => dispatch(loading()), []);

  const handleResetStatus = useCallback(() => dispatch(resetStatus()), []);

  const handleChangeToSubCreate = useCallback(() => {
    dispatch(changeToSubCreateMode());
  }, []);

  const handleChangeToSubConsult = useCallback(() => {
    dispatch(changeToSubConsultMode());
  }, []);

  const handleChangeToSubUpdate = useCallback(() => {
    dispatch(changeToSubUpdateMode());
  }, []);

  const handleChangeToSubNone = () => {
    dispatch(resetSubMode());
  };

  const isSavedAllMercadorias = useMemo(() => model?.mercadorias?.every((item) => !validate(item?.id)), [model]);

  const normalizeMercadoria = useCallback(
    (unnormalizedData) => mercadoriaUtils.normalize(unnormalizedData),
    [mercadoriaUtils]
  );

  const unnormalizeMercadoria = useCallback(
    (unnormalizedData) => mercadoriaUtils.unnormalize(unnormalizedData),
    [mercadoriaUtils]
  );

  const handleNewMercadoria = useCallback(
    (additional = {}) => {
      const additionalCallback = () => {
        if (!isEmpty(additional) && isFunction(additional?.init)) {
          additional.init();
        }

        handleChangeToSubCreate();
      };

      if (!isCreate) {
        handleChangeToUpdate({
          callback: additionalCallback,
        });

        return;
      }

      additionalCallback.call();
    },
    [isCreate, handleChangeToUpdate, handleChangeToSubCreate]
  );

  const handleCancelMercadoriaCreate = useCallback(
    (additional = {}) => {
      const additionalCallback = () => {
        if (!isEmpty(additional) && isFunction(additional?.callback)) {
          additional.callback();
        }
      };

      if (!isCreate) {
        handleCancelUpdate({
          callback: additionalCallback,
        });

        return;
      }

      additionalCallback.call();
    },
    [isCreate, handleCancelUpdate]
  );

  const handleCancelMercadoriaUpdate = useCallback(() => {
    if (isUpdate && isSavedAllMercadorias) {
      handleCancelUpdate({
        callback: handleChangeToSubConsult,
      });

      return;
    }

    handleChangeToSubConsult();
  }, [isUpdate, isSavedAllMercadorias, handleCancelUpdate, handleChangeToSubConsult]);

  const handleClearMercadoriaForm = useCallback(() => {
    dispatch(resetRelatedMercadoriaModel());
  }, []);

  const [handleRemoveMercadoriaByIdMiddleware, operationOfRemoveMercadoriaById] = useOperationMiddleware(
    async (data, refreshCallback) => {
      handleMercadoria(data, refreshCallback);
    },
    [handleMercadoria]
  );

  const handleRemoveMercadoriaByForm = useCallback(
    (refreshCallback) => {
      handleChangeToBackgroundDelete();

      const configureOperation = () => ({
        options: {
          title: t('com.muralis.qcx.acoes.confirmarOperacaoRemocao').toUpperCase(),
          message: t('com.muralis.qcx.acoes.operacaoRemocaoItemLista', { item: mercadoria?.item }),
          endMessage: t('com.muralis.qcx.alerta.remocao'),
        },
        cleanUp: () => {
          dispatch(resetBackgroundMode());
        },
      });

      handleRemoveMercadoriaByIdMiddleware(configureOperation, mercadoria, refreshCallback);
    },
    [mercadoria, handleChangeToBackgroundDelete, handleRemoveMercadoriaByIdMiddleware]
  );

  const handleRemoveMercadoriaByTable = useCallback(
    ({ row }) => {
      handleChangeToBackgroundDelete();

      const configureOperation = () => ({
        options: {
          title: t('com.muralis.qcx.acoes.confirmarOperacaoRemocao').toUpperCase(),
          message: t('com.muralis.qcx.acoes.operacaoRemocaoItemLista', { item: row?.item }),
          endMessage: t('com.muralis.qcx.alerta.remocao'),
        },
        cleanUp: () => {
          dispatch(resetBackgroundMode());
        },
      });

      handleRemoveMercadoriaByIdMiddleware(configureOperation, row);
    },
    [handleChangeToBackgroundDelete, handleRemoveMercadoriaByIdMiddleware]
  );

  const handleDispatchSetMercadoriaModel = useCallback((updatedModel) => {
    dispatch(setRelatedMercadoriaModel(updatedModel));
  }, []);

  const handleEditMercadoria = useCallback(() => {
    if (!isCreate) {
      handleChangeToUpdate({
        callback: handleChangeToSubUpdate,
      });

      return;
    }

    handleChangeToSubUpdate();
  }, [isCreate, handleChangeToUpdate, handleChangeToSubUpdate]);

  const handleMercadoriaSubmit = async (data, form) => {
    const normalizedMercadoria = normalizeMercadoria(data);

    if (isSubCreate) {
      const restartForm = () => {
        handleLoadingStatus();

        const executeDebouncedContinueCreating = debounce(() => {
          if (!isEmpty(form) && isFunction(form?.restart)) {
            form.restart({
              partnumberAutocompleteSelector: '',
              partNumberSelector: '',
              partnumber: '',
              valorUnitarioMoeda: '',
              pesoLiquido: '',
              tipoCalculo: 'QUANTIDADE_X_VALOR',
              icms: '',
              quantidade: '',
              valorTotalMoeda: '',
              quantidadeEstatistica: '',
            });
          }

          handleCancelMercadoriaCreate();
          handleClearMercadoriaForm();
          handleNewMercadoria();

          handleResetStatus();
        }, 500);

        executeDebouncedContinueCreating();
      };

      await handleMercadoria(normalizedMercadoria, restartForm);
      return;
    }

    handleMercadoria(normalizedMercadoria);
  };

  const mercadoriaModel = useMemo(
    () => (!isSubCreate ? unnormalizeMercadoria(mercadoria) : {}),
    [isSubCreate, mercadoria, unnormalizeMercadoria]
  );

  const wizardSteps = useMemo(
    () => [
      t('com.muralis.qcx.importador.label'),
      t('com.muralis.qcx.totais'),
      t('com.muralis.qcx.fornecedor.label'),
      t('com.muralis.qcx.mercadoria.label'),
    ],
    []
  );

  const optionalSteps = useMemo(() => [t('com.muralis.qcx.fornecedor.label')], []);

  const ignoreRulesForSpecialSteps = useMemo(() => [t('com.muralis.qcx.mercadoria.label')], []);

  return (
    <>
      <QCXFinalFormWizard
        steps={wizardSteps}
        optionalSteps={optionalSteps}
        ignoreRulesForSpecialSteps={ignoreRulesForSpecialSteps}
        initialValues={model}
        handleChangeValues={handleChangeModel}
        isCreate={isCreate}
        isConsult={isConsult}
        isUpdate={isUpdate}
        isBackgroundCreate={isBackgroundCreate}
        isSubNone={isSubNone}
        isSubCreate={isSubCreate}
        isSubConsult={isSubConsult}
        isSubUpdate={isSubUpdate}
        handleSubmit={handleSubmit}
        checkIfIsAlternativeSaveByStep={checkIfIsAlternativeSaveByStep}
        handleAlternativeSave={handleAlternativeSave}
        handleChangeToCreate={handleChangeToCreate}
        handleChangeToBackgroundCreate={handleChangeToBackgroundCreate}
        handleChangeToConsult={handleChangeToConsult}
        handleChangeToUpdate={handleChangeToUpdate}
        handleCancelUpdate={handleCancelUpdate}
        handleResetBackgroundMode={handleResetBackgroundMode}
        handleChangeToSubNone={handleChangeToSubNone}
        {...restProps}
      >
        {(formProps) => (
          <QCXFaturaImportadorForm
            key="ficha-importador-fatura"
            isConsult={isConsult}
            authInfo={authInfo}
            requiredRoles={requiredRoles}
            {...formProps}
          />
        )}
        {(formProps) => (
          <QCXFaturaTotaisForm
            key="ficha-totais-fatura"
            isConsult={isConsult}
            authInfo={authInfo}
            requiredRoles={requiredRoles}
            {...formProps}
          />
        )}
        {(formProps) => (
          <QCXFaturaFornecedorForm
            key="ficha-fornecedor-fatura"
            isConsult={isConsult}
            authInfo={authInfo}
            requiredRoles={requiredRoles}
            {...formProps}
          />
        )}
        {({ initialValues, controlComponentProps }) => (
          <QCXFaturaMercadoriaForm
            key="ficha-mercadoria-fatura"
            isIdle={isIdle}
            isLoading={isLoading}
            isFailure={isFailure}
            isPreparingAction={isPreparingAction}
            isCreate={isCreate}
            isConsult={isConsult}
            isUpdate={isUpdate}
            isSubNone={isSubNone}
            isSubCreate={isSubCreate}
            isSubConsult={isSubConsult}
            isSubUpdate={isSubUpdate}
            isBackgroundDelete={isBackgroundDelete}
            faturaValues={initialValues}
            initialValues={mercadoriaModel}
            fatura={model}
            handleSubmit={handleMercadoriaSubmit}
            handleChangeModel={handleDispatchSetMercadoriaModel}
            handleNew={handleNewMercadoria}
            handleEdit={handleEditMercadoria}
            handleChangeToSubConsult={handleChangeToSubConsult}
            handleChangeToConsult={handleChangeToConsult}
            handleCancelCreate={handleCancelMercadoriaCreate}
            handleCancelUpdate={handleCancelMercadoriaUpdate}
            handleRemoveByForm={handleRemoveMercadoriaByForm}
            handleRemoveByTable={handleRemoveMercadoriaByTable}
            handleChangeToSubNone={handleChangeToSubNone}
            handleClearForm={handleClearMercadoriaForm}
            handleChangeToUpdate={handleChangeToUpdate}
            handleLoadingStatus={handleLoadingStatus}
            handleResetStatus={handleResetStatus}
            controlComponentProps={controlComponentProps}
            authInfo={authInfo}
            requiredRoles={requiredRoles}
          />
        )}
      </QCXFinalFormWizard>
      <QCXConfirmDialog
        key="confirm-dialog-remove-mercadoria-fatura"
        id="confirm-dialog-remove-mercadoria-fatura"
        open={operationOfRemoveMercadoriaById?.active}
        title={operationOfRemoveMercadoriaById?.title}
        content={operationOfRemoveMercadoriaById?.message}
        endContent={operationOfRemoveMercadoriaById?.endMessage}
        onConfirm={operationOfRemoveMercadoriaById.confirm}
        onClose={operationOfRemoveMercadoriaById?.reset}
      />
    </>
  );
}
