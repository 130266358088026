import { Grid } from '@material-ui/core';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormState } from 'react-final-form';
import { get } from 'lodash';
import QCXSelectEnquadramentoOperacaoAutocomplete from '../../shared-components/select-enquadramento-operacao/QCXSelectEnquadramentoOperacaoAutocomplete';

export default function QCXEnquadramentosOperacaoFormGroup({
  fieldProps = {
    enquadramento: {
      primeiro: {
        id: 'select-field-primeiro-enquadramento',
        key: 'select-field-primeiro-enquadramento',
        name: 'primeiroEnquadramento',
      },
      segundo: {
        id: 'select-field-segundo-enquadramento',
        key: 'select-field-segundo-enquadramento',
        name: 'segundoEnquadramento',
      },
      terceiro: {
        id: 'select-field-terceiro-enquadramento',
        key: 'select-field-terceiro-enquadramento',
        name: 'terceiroEnquadramento',
      },
      quarto: {
        id: 'select-field-quarto-enquadramento',
        key: 'select-field-quarto-enquadramento',
        name: 'quartoEnquadramento',
      },
    },
  },
  disabled,
}) {
  const { t } = useTranslation();
  const { values } = useFormState();

  const enquadramentoPreenchidoBy = useCallback((path) => {
    const value = get(
      values,
      path
    );

    return !!value;
  }, [values]);

  return (
    <>
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        lg={6}
      >
        <QCXSelectEnquadramentoOperacaoAutocomplete
          {...fieldProps.enquadramento.primeiro}
          label={t('com.muralis.qcx.enquadramento.primeiro')}
          initialValues={values}
          disabled={disabled}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        lg={6}
      >
        <QCXSelectEnquadramentoOperacaoAutocomplete
          {...fieldProps.enquadramento.segundo}
          label={t('com.muralis.qcx.enquadramento.segundo')}
          initialValues={values}
          disabled={disabled || !enquadramentoPreenchidoBy(fieldProps.enquadramento.primeiro.name)}
          controlled
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        lg={6}
      >
        <QCXSelectEnquadramentoOperacaoAutocomplete
          {...fieldProps.enquadramento.terceiro}
          label={t('com.muralis.qcx.enquadramento.terceiro')}
          initialValues={values}
          disabled={disabled || !enquadramentoPreenchidoBy(fieldProps.enquadramento.segundo.name)}
          controlled
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        lg={6}
      >
        <QCXSelectEnquadramentoOperacaoAutocomplete
          {...fieldProps.enquadramento.quarto}
          label={t('com.muralis.qcx.enquadramento.quarto')}
          initialValues={values}
          disabled={disabled || !enquadramentoPreenchidoBy(fieldProps.enquadramento.terceiro.name)}
          controlled
        />
      </Grid>
    </>
  );
}
