import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Delete as DeleteIcon,
  Search as PageviewIcon,
  Receipt as ReceiptIcon,
} from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import ModeloDanfeContext from '../../../../../contexts/registrations/modelo-danfe/ModeloDanfeContext';
import { isAlternativeLoadingStatus } from '../../../../../utils/store/store-utils';
import { selectModelosDanfe } from '../../../../../features/modelo-danfe/modeloDanfeSelectors';
import {
  changeToCreateMode,
  resetBackgroundMode,
  resetMode,
  resetModel,
} from '../../../../../features/modelo-danfe/modeloDanfeSlice';
import { resetList as resetClientesList } from '../../../../../features/cliente/clienteSlice';
import QCXSimpleConsultPageTemplate from '../../../../../templates/simple-consult-page/QCXSimpleConsultPageTemplate';
import { activateByIdAsync, fetchAllAsync, inactivateByIdAsync } from '../../../../../features/modelo-danfe/modeloDanfeThunks';
import { userHasRole } from '../../../../../components/perfil/perfil-utils';

export default function ModeloDanfeConsultPage({ authInfo = {} }) {
  const { t } = useTranslation();
  const history = useHistory();

  const {
    status,
  } = useContext(ModeloDanfeContext);

  const dispatch = useDispatch();

  // Get variables through selectors

  const isAlternativeLoading = useMemo(() => (
    isAlternativeLoadingStatus(status)
  ), [status]);

  const modelosDanfe = useSelector(selectModelosDanfe);

  const fetchAll = useCallback(() => {
    dispatch(fetchAllAsync());
  }, []);

  const resetModes = useCallback(() => {
    dispatch(resetMode());
    dispatch(resetBackgroundMode());
  }, []);

  const resetModels = useCallback(() => {
    dispatch(resetModel());
  }, []);

  const resetDependencies = useCallback(() => {
    dispatch(resetClientesList());
  }, []);

  useEffect(() => {
    fetchAll();
    resetModes();
    resetModels();
    resetDependencies();
  }, []);

  useEffect(() => {
    dispatch(fetchAllAsync());
  }, []);

  const handleButtonNewClick = useCallback((event) => {
    event.stopPropagation();
    dispatch(changeToCreateMode());
  }, []);

  const handleActivateInactivate = async (event, id) => {
    event.preventDefault?.();

    const { active } = modelosDanfe.find((it) => it.id === id);

    const isActivation = !active;

    if (isActivation) {
      dispatch(activateByIdAsync(id));
    } else {
      dispatch(inactivateByIdAsync(id));
    }
  };

  const handleClickOnEditButton = useCallback((event, id) => {
    history.push(t('com.muralis.qcx.url.moduloOperacionaisModeloDANFEDetalhes', { id }));
  }, [history]);

  const breadcrumbs = [
    {
      link: {
        to: '/',
        name: t('com.muralis.qcx.inicio'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.cadastros'),
      },
    },
    {
      link: {
        to: t('com.muralis.qcx.url.moduloOperacionais'),
        name: t('com.muralis.qcx.operacionais'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.modeloDANFE.label'),
        default: true,
      },
    },
  ];

  const columns = [
    {
      field: 'nome',
      headerName: t('com.muralis.qcx.modelo'),
      headerAlign: 'left',
      align: 'left',
      flex: 90,
      valueGetter: ({ row }) => `${row?.nome ?? '-'}`,
    },
    {
      field: 'formatoImpressao',
      headerName: t('com.muralis.qcx.layoutImpressao'),
      headerAlign: 'left',
      align: 'left',
      flex: 90,
      valueGetter: ({ row }) => {
        /** @type {string} */
        const layout = row?.formatoImpressao;
        if (layout.toUpperCase() === 'LAYOUT_RETRATO') {
          return t('com.muralis.qcx.retrato');
        } if (layout.toUpperCase() === 'LAYOUT_PAISAGEM') {
          return t('com.muralis.qcx.paisagem');
        }
        return '';
      },
    },
    {
      field: 'actions',
      headerName: t('com.muralis.qcx.acoes.label'),
      headerAlign: 'center',
      align: 'center',
      flex: 90,
      renderCell: ({ row: { id, active } }) => (
        <>
          {userHasRole(authInfo, 'modelo-danfe-excluir') && (
            <IconButton
              key={`stw-active${id}`}
              value={active}
              name={`stw-active-${id}`}
              onClick={(event) => handleActivateInactivate(event, id)}
            >
              <DeleteIcon color="error" />
            </IconButton>
          )}
          <IconButton
            key={`btn-view-${id}`}
            name={`btn-view-${id}`}
            onClick={(event) => handleClickOnEditButton(event, id)}
          >
            <PageviewIcon color="primary" />
          </IconButton>
        </>
      ),
    }
  ];

  const templateProps = {
    page: {
      title: t('com.muralis.qcx.modeloDANFE.label'),
      icon: <ReceiptIcon />,
      breadcrumbs,
    },
    control: {
      buttonNew: {
        description: t('com.muralis.qcx.modeloDANFE.novoModeloDANFE'),
        path: t('com.muralis.qcx.url.moduloOperacionaisModeloDANFENovo'),
        onClick: handleButtonNewClick,
      },
    },
    table: {
      columns,
    },
  };

  return (
    <QCXSimpleConsultPageTemplate
      pageProps={templateProps.page}
      controlProps={templateProps.control}
      tableProps={templateProps.table}
      tableData={modelosDanfe}
      isLoading={isAlternativeLoading}
      authInfo={authInfo}
      requiredRoles={['modelo-danfe']}
    />
  );
}
