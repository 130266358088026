const selectTratamentosTributarios = (state) => state.tratamentoTributario.list;
const selectStatus = (state) => state.tratamentoTributario.status;
const selectError = (state) => state.tratamentoTributario.error;
const selectResponse = (state) => state.tratamentoTributario.response;

const tratamentoTributarioSelectors = {
  selectTratamentosTributarios,
  selectStatus,
  selectError,
  selectResponse,
};

export {
  tratamentoTributarioSelectors,
  selectTratamentosTributarios,
  selectStatus,
  selectError,
  selectResponse,
};
