import { isStrictEquals } from '../../../general/general-utils';

const CLOSED_STATUS = 'closeStatus';
const OPEN_STATUS = 'openStatus';
const AWAITING_STATUS = 'awaitingStatus';
const SUBMITTING_STATUS = 'submittingStatus';
const DONE_STATUS = 'doneStatus';
const FAIL_STATUS = 'failStatus';

const isClosedStatus = (status) => (
  isStrictEquals(CLOSED_STATUS, status)
);

const isOpenStatus = (status) => (
  isStrictEquals(OPEN_STATUS, status)
);

const isAwaitingStatus = (status) => (
  isStrictEquals(AWAITING_STATUS, status)
);

const isSubmittingStatus = (status) => (
  isStrictEquals(SUBMITTING_STATUS, status)
);

const isDoneStatus = (status) => (
  isStrictEquals(DONE_STATUS, status)
);

const isFailStatus = (status) => (
  isStrictEquals(FAIL_STATUS, status)
);

export {
  CLOSED_STATUS,
  OPEN_STATUS,
  AWAITING_STATUS,
  SUBMITTING_STATUS,
  DONE_STATUS,
  FAIL_STATUS,
  isClosedStatus,
  isOpenStatus,
  isAwaitingStatus,
  isSubmittingStatus,
  isDoneStatus,
  isFailStatus,
};
