import { Grid, makeStyles } from "@material-ui/core";
import { t } from "i18next";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { Trans } from "react-i18next";
import QCXMoveFocusInside from "../../../../../components/focus-lock/QCXMoveFocusInside";
import QCXFormSubtitle from "../../../../../components/form-title/QCXFormSubtitle";
import QCXInfoAlert from "../../../../../shared-components/alert/QCXInfoAlert";
import QCXAloneInlineBoxWrapper from "../../../../../shared-components/alone-inline-box-wrapper/QCXAloneInlineBoxWrapper";
import QCXFinalTextField from "../../../../../shared-components/final-text-field/QCXFinalTextField";
import { required } from "../../../../../utils/validators/field/validator";
import StyledAutocompleteSelect from "../../../../common/components/input/styledAutocompleteSelect";
import { useListClientes } from "../../../../common/hooks/useListClientes";
import LoadingIndicator from "../../../../common/components/loadingIndicator";
import { ClientData } from "../../../../common/types/clientData";
import {
  parseCnpj,
  parseCpf,
} from "../../../../../utils/hooks/form/field/parsers";
import StyledButton from "../../../../common/components/Buttons/StyledButton";
import { useHasPermission } from "../../../../common/hooks/useHasPermission/useHasPermission";
import { ASSESSORIA_FOLLOWUP } from "../../../../common/hooks/useHasPermission/permissionsEnum";
import TextFieldStyled from "../../../../common/components/input/textFieldStyled";
import BaseCreateForm from "../../newAssessoriaFollowupPage/followupForm/baseCreateForm";
import ReferenciaBondManager from "../../newAssessoriaFollowupPage/followupForm/referenciaBondManager";
import { useListServicosClienteAssessoria } from "../../newAssessoriaFollowupPage/hooks/useListServicosAssessoria";
import { useParams } from "react-router";
import { useGetAssessorioFollowUp } from "../hooks/useGetAssessoriaFollowUp";
import { useQueryClient } from "react-query";
import { QueriesTypes } from "../../../../common/hooks/queries";

export type FollowUpFormPayload = {
  servico: { id: number };
  importador: { id: number };
  referencias: string[];
  numero: string;
};

type Props = {
  submitCallback: (followUp: FollowUpFormPayload) => void;
};

const useStyles = makeStyles((theme: any) => ({
  root: {
    width: "inherit",
    padding: theme.spacing(2),
  },
  gridFields: {
    paddingBottom: "16px",
  },
}));

const ViewFollowupForm = ({ submitCallback }: Props) => {
  const classes = useStyles();
  const params: { id: string } = useParams();

  const client = useQueryClient();

  const [followUpData, isLoadingFollowUp] = useGetAssessorioFollowUp(params.id);

  const [referencias, setReferencias] = useState<any>([]);
  const [cliente, setCliente] = useState({ id: 0, description: "" });
  const [servico, setServico] = useState({ id: 0, description: "" });

  const followUpPayload = useMemo(
    () => ({ ...followUpData, referencias }),
    [followUpData, referencias]
  );

  function generateDescription(cliente: {
    id: number;
    pessoa: { tipo: string; cpf: string; cnpj: string; nome: string };
  }): string {
    const cnpjOrCpf =
      cliente.pessoa?.tipo === "PessoaJuridica"
        ? parseCnpj(cliente.pessoa?.cnpj)
        : parseCpf(cliente.pessoa?.cpf);

    return `${cliente.id} - ${cliente.pessoa.nome} - ${cnpjOrCpf}`;
  }

  useEffect(() => {
    if (
      followUpData.referencias != undefined &&
      followUpData.servico != undefined &&
      followUpData.importador != undefined
    ) {
      setReferencias(followUpData.referencias);
      setCliente({
        id: followUpData.importador.id,
        description: generateDescription(followUpData.importador as any),
      });
      setServico({
        id: followUpData.servico.id,
        description: followUpData.servico.description,
      });
    }

    return () => {};
  }, [followUpData]);

  const isLoading = useMemo(() => isLoadingFollowUp, [isLoadingFollowUp]);

  function handleChangeReferencias(newValues: string[]) {
    setReferencias([...newValues]);
  }

  const [isUpdating, setIsUpdating] = useState(false);

  const handleSubmit = useMemo(
    () =>
      isUpdating
        ? () => submitCallback(followUpPayload)
        : () => setIsUpdating(true),
    [isUpdating, submitCallback, followUpPayload]
  );

  const handleCancel = () => {
    setIsUpdating(false);
    client.invalidateQueries(QueriesTypes.FollowUps);
  };

  const canEdit = useHasPermission(ASSESSORIA_FOLLOWUP.ALTERAR);

  return (
    <>
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <BaseCreateForm
          handleSubmit={() => handleSubmit()}
          validate={undefined}
          fields={undefined}
          initialValues={undefined}
        >
          <Grid item container spacing={2}>
            <Grid
              item
              xs={6}
              style={{
                marginTop: "0.5rem",
                marginBottom: "0.5rem",
              }}
            >
              <TextFieldStyled
                value={followUpData.code}
                disabled
                label={"Número do Processo"}
                onChange={() => {}}
              ></TextFieldStyled>
            </Grid>

            <Grid item xs={6}></Grid>

            <Grid item className={classes.gridFields} xs={12} sm={12} md={6}>
              <StyledAutocompleteSelect
                disabled
                required
                value={cliente}
                label={"Cliente"}
                options={[cliente]}
                onChange={(e, value) => {}}
                name={"cliente"}
              />
            </Grid>
            <Grid item className={classes.gridFields} xs={12} sm={12} md={6}>
              <StyledAutocompleteSelect
                disabled
                required
                value={servico}
                label={"Serviço"}
                name={"servico"}
                options={[servico]}
                onChange={(e, value) => {}}
              />
            </Grid>

            <Grid item xs={12}>
              <QCXFormSubtitle
                title={t("com.muralis.qcx.referenciasCliente")}
              />
            </Grid>

            <ReferenciaBondManager
              disabled={!isUpdating}
              values={referencias}
              changeValuesCallback={handleChangeReferencias}
            ></ReferenciaBondManager>

            <Grid
              container
              item
              justify="flex-end"
              alignContent="flex-end"
              xs={12}
            >
              {isUpdating && (
                <StyledButton variant="outlined" onClick={() => handleCancel()}>
                  {"Cancelar"}
                </StyledButton>
              )}

              <StyledButton type="submit" color="secondary" disabled={!canEdit}>
                {isUpdating ? "Salvar" : "Editar"}
              </StyledButton>
            </Grid>
          </Grid>
        </BaseCreateForm>
      )}
    </>
  );
};

export default ViewFollowupForm;
