import { Grid } from '@material-ui/core';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { validate } from 'uuid';
import QCXFinalCheckboxField from '../../shared-components/final-checkbox-field/QCXFinalCheckboxField';
import QCXFinalNumericIntegerField from '../../shared-components/final-numeric-integer-field/QCXFinalNumericIntegerField';
import QCXFinalTextField from '../../shared-components/final-text-field/QCXFinalTextField';
import QCXFormStepWizard from '../../shared-components/form-step-wizard/QCXFormStepWizard';
import QCXSelectEtapaAutocomplete from '../../shared-components/select-etapa/QCXSelectEtapaAutocomplete';
import QCXSelectTipoPrazoAutocomplete, {
  getTipoPrazoLabelByValue,
} from '../../shared-components/select-tipo-prazo/QCXSelectTipoPrazoAutocomplete';
import QCXRegistrationOneToManyPageTemplate from '../../templates/registration-one-to-many-page/QCXRegistrationOneToManyPageTemplate';
import { required as requiredValidator } from '../../utils/validators/field/validator';

export default function QCXEtapaModeloFollowUpFinalFormManager({
  isConsult,
  isUpdate,
  isSubNone,
  isSubCreate,
  isSubConsult,
  isSubUpdate,
  model,
  list,
  handleSubmit,
  handleNew,
  handleEdit,
  handleCancelCreate,
  handleCancelUpdate,
  handleRemove,
  handleRowClick,
  handleClearForm,
  controlComponentProps,
  authInfo = {},
  requiredRoles = [],
}) {
  const { t } = useTranslation();

  const isTipoDePrazoDias = useCallback((values) => values?.tipoPrazoConclusao === 'DIA', []);

  const tableProps = useMemo(
    () => ({
      title: t('com.muralis.qcx.etapa.etapasAdicionadas'),
      columns: [
        {
          field: 'ordem',
          headerAlign: 'center',
          align: 'center',
          headerName: 'Ordem:',
          type: 'string',
          flex: 90,
          valueGetter: ({ row }) => row?.ordem,
        },
        {
          field: 'description',
          headerName: 'Etapa Atual',
          flex: 220,
          valueGetter: ({ row }) => row?.etapaAtual?.description || '-',
        },
        {
          field: 'tipoPrazoConclusao',
          headerName: t('com.muralis.qcx.tipoPrazoConclusao'),
          headerAlign: 'center',
          align: 'center',
          flex: 220,
          valueFormatter: ({ row }) =>
            `${row?.prazoConclusao} ${getTipoPrazoLabelByValue(row?.tipoPrazoConclusao)}` || '-',
          valueGetter: ({ row }) => getTipoPrazoLabelByValue(row?.tipoPrazoConclusao) || '-',
        },
        {
          field: 'preenchimentoObrigatorio',
          headerName: 'Obrigatório',
          flex: 110,
          type: 'boolean',
        },
        {
          field: 'etapaBase',
          headerName: 'Etapa Base',
          flex: 220,
          valueGetter: ({ row }) => row?.etapaBase?.description || '-',
        },
        {
          field: 'diasUteisFilter',
          headerName: t('com.muralis.qcx.diasUteis'),
          flex: 110,
          hide: true,
          type: 'boolean',
          valueGetter: ({ row }) =>
            row?.diasUteis ? t('com.muralis.qcx.eDiasUteis') : t('com.muralis.qcx.naoDiasUteis'),
        },
      ],
    }),
    []
  );
  const localeTableText = useMemo(
    () => ({
      noRowsLabel: t('com.muralis.qcx.validacao.nenhumaEtapaAdicionada'),
    }),
    []
  );

  return (
    <QCXFormStepWizard
      initialValues={model}
      onSubmit={handleSubmit}
      controlComponentProps={controlComponentProps}
      authInfo={authInfo}
      requiredRoles={requiredRoles}
    >
      {({ form, submitSucceeded, values }) => (
        <QCXRegistrationOneToManyPageTemplate
          title={t('com.muralis.qcx.etapa.label')}
          tableProps={tableProps}
          tableData={list}
          addButtonText={t('com.muralis.qcx.acoes.nova').toUpperCase()}
          isConsult={isConsult}
          isUpdate={isUpdate}
          isSubNone={isSubNone}
          isSubCreate={isSubCreate}
          isSubConsult={isSubConsult}
          isSubUpdate={isSubUpdate}
          submitSucceeded={submitSucceeded}
          handleNew={handleNew}
          handleEdit={handleEdit}
          handleCancelCreate={handleCancelCreate}
          handleCancelUpdate={handleCancelUpdate}
          handleRemove={handleRemove}
          handleClearForm={handleClearForm}
          handleRestartForm={() =>
            form.restart({
              etapaAtual: {
                id: '',
              },
              tipoPrazoConclusao: '',
              etapaBase: {
                id: '',
              },
              prazoConclusao: '',
            })
          }
          handleRowClick={handleRowClick}
          localeTableText={localeTableText}
        >
          {(isSubConsult || isSubUpdate) && !validate(values?.id) && (
            <Grid item xs={12} sm={4} md={3}>
              <QCXFinalTextField
                id="text-field-code"
                key="text-field-code"
                name="id"
                label={t('com.muralis.qcx.codigo')}
                disabled
              />
            </Grid>
          )}
          <Grid item xs={1}>
            <QCXFinalNumericIntegerField
              id="autocomplete-select-field-etapa-atual"
              key="autocomplete-select-field-etapa-atual"
              name="ordem"
              label="Ordem:"
              initialValues={values}
              disabled={isConsult || isSubConsult || isSubNone}
              form={form}
              values={values}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={isSubConsult || isSubUpdate ? 5 : 4}>
            <QCXSelectEtapaAutocomplete
              id="autocomplete-select-field-etapa-atual"
              key="autocomplete-select-field-etapa-atual"
              name="etapaAtual"
              label={t('com.muralis.qcx.etapa.etapaAtual')}
              initialValues={values}
              filterPredicate={(item) =>
                ![values?.proximaEtapa?.id, values?.etapaAnterior?.id].includes(item.id) && item?.active
              }
              fieldProps={{
                validate: requiredValidator,
              }}
              disabled={isConsult || isSubConsult || isSubNone}
              required
              form={form}
              values={values}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <QCXSelectTipoPrazoAutocomplete
              id="autocomplete-select-field-tipo-prazo-conclusao"
              key="autocomplete-select-field-tipo-prazo-conclusao"
              name="tipoPrazoConclusao"
              label={t('com.muralis.qcx.tipoPrazo')}
              initialValues={values}
              fieldProps={{
                validate: requiredValidator,
              }}
              disabled={isConsult || isSubConsult || isSubNone}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <QCXFinalNumericIntegerField
              id="text-field-prazo-de-conclusao"
              key="text-field-prazo-de-conclusao"
              name="prazoConclusao"
              label={t('com.muralis.qcx.prazoConclusao')}
              maxLength={3}
              validate={requiredValidator}
              disabled={isConsult || isSubConsult || isSubNone}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <QCXSelectEtapaAutocomplete
              id="autocomplete-select-field-etapa-atual"
              key="autocomplete-select-field-etapa-atual"
              name="etapaBase"
              label="Etapa Base"
              initialValues={values}
              filterPredicate={(item) =>
                ![values?.proximaEtapa?.id, values?.etapaAnterior?.id].includes(item.id) && item?.active
              }
              disabled={isConsult || isSubConsult || isSubNone}
              form={form}
              values={values}
            />
          </Grid>
          <Grid item xs={3}>
            <QCXFinalCheckboxField
              id="select-field-dias-uteis"
              key="select-field-dias-uteis"
              name="preenchimentoObrigatorio"
              label="Preenchimento Obrigatório"
              disabled={isConsult || isSubConsult || isSubNone}
            />
          </Grid>
          <Grid item xs={3}>
            <QCXFinalCheckboxField
              id="select-field-dias-uteis"
              key="select-field-dias-uteis"
              name="bloqueiaAlteracaoData"
              label="Bloqueia Alteração de Data"
              disabled={isConsult || isSubConsult || isSubNone}
            />
          </Grid>
          <Grid item xs={3}>
            <QCXFinalCheckboxField
              id="select-field-dias-uteis"
              key="select-field-dias-uteis"
              name="obrigatorioOcorrencia"
              label="Obrigatório Ocorrência"
              disabled={isConsult || isSubConsult || isSubNone}
            />
          </Grid>
          {isTipoDePrazoDias(values) && (
            <Grid item xs={12}>
              <QCXFinalCheckboxField
                id="select-field-dias-uteis"
                key="select-field-dias-uteis"
                name="diasUteis"
                label={t('com.muralis.qcx.diasUteis')}
                disabled={isConsult || isSubConsult || isSubNone}
              />
            </Grid>
          )}
        </QCXRegistrationOneToManyPageTemplate>
      )}
    </QCXFormStepWizard>
  );
}
