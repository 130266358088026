import { isStrictEquals } from '../general-utils';

const TIPO_DESPESA_RECEITA_DESPESA = 'DESPESA';
const TIPO_DESPESA_RECEITA_RECEITA = 'RECEITA';
const TIPO_DESPESA_RECEITA_IMPOSTOS_FEDERAIS = 'IMPOSTOS_FEDERAIS';
const TIPO_DESPESA_RECEITA_IMPOSTOS_ESTADUAIS = 'IMPOSTOS_ESTADUAIS';

const isDespesaReceitaDespesa = (key) => (
  isStrictEquals(key, TIPO_DESPESA_RECEITA_DESPESA)
);

const isDespesaReceitaReceita = (code) => (
  isStrictEquals(code, TIPO_DESPESA_RECEITA_RECEITA)
);

const isDespesaReceitaImpostoFederal = (code) => (
  isStrictEquals(code, TIPO_DESPESA_RECEITA_IMPOSTOS_FEDERAIS)
);

const isDespesaReceitaImpostoEstadual = (code) => (
  isStrictEquals(code, TIPO_DESPESA_RECEITA_IMPOSTOS_ESTADUAIS)
);

const DespesaReceitaUtils = Object.freeze({
  DESPESA: (
    TIPO_DESPESA_RECEITA_DESPESA
  ),
  RECEITA: (
    TIPO_DESPESA_RECEITA_RECEITA
  ),
  IMPOSTOS_FEDERAIS: (
    TIPO_DESPESA_RECEITA_IMPOSTOS_FEDERAIS
  ),
  IMPOSTOS_ESTADUAIS: (
    TIPO_DESPESA_RECEITA_IMPOSTOS_ESTADUAIS
  ),
  isDespesa: (
    isDespesaReceitaDespesa
  ),
  isReceita: (
    isDespesaReceitaReceita
  ),
  isImpostoFederal: (
    isDespesaReceitaImpostoFederal
  ),
  isImpostoEstadual: (
    isDespesaReceitaImpostoEstadual
  ),
});

export default DespesaReceitaUtils;
