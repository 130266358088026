import React, {
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import {
  useParams,
} from 'react-router';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  isEmpty,
  isFunction,
} from 'lodash';
import {
  changeModeTo,
  changeToBackgroundCreateMode,
  changeToBackgroundDeleteMode,
  changeToConsultMode,
  changeToCreateMode,
  changeToUpdateMode,
  preparingAction,
  resetBackgroundMode,
} from '../../../../features/follow-up/followUpSlice';
import {
  selectBackgroundMode,
  selectFollowUp,
  selectStatus,
  selectMode,
} from '../../../../features/follow-up/followUpSelectors';
import { fetchByIdAsync } from '../../../../features/follow-up/followUpThunks';
import {
  isCreateMode,
  isBackgroundCreateMode,
  isConsultMode,
  isUpdateMode,
  isBackgroundDeleteMode,
  isLoadingStatus,
  isIdleStatus,
  isFailureStatus,
  isLockedMode,
} from '../../../../utils/store/store-utils';
import QCXActionsManagerPageTemplate from '../../../../templates/actions-manager-page/QCXActionsManagerPageTemplate';
import useAutoChangeMode from '../../../../utils/hooks/mode/useAutoChangeMode';
import QCXFollowUpFieldsViewerManager from '../../../../components/follow-up/QCXFollowUpFieldsViewerManager';
import QCXFollowUpTimelineManager from '../../../../components/follow-up/QCXFollowUpTimelineManager';

export default function FollowUpTimelineManagerPage({ authInfo = {} }) {
  const { t } = useTranslation();
  const params = useParams();

  const dispatch = useDispatch();

  const status = useSelector(selectStatus);
  const mode = useSelector(selectMode);
  const backgroundMode = useSelector(selectBackgroundMode);
  const followUp = useSelector(selectFollowUp);

  const isLoading = useMemo(() => (
    isLoadingStatus(status)
  ), [status]);

  const isIdle = useMemo(() => (
    isIdleStatus(status)
  ), [status]);

  const isFailure = useMemo(() => (
    isFailureStatus(status)
  ), [status]);

  const isCreate = useMemo(() => (
    isCreateMode(mode)
  ), [mode]);

  const isConsult = useMemo(() => (
    isConsultMode(mode)
  ), [mode]);

  const isUpdate = useMemo(() => (
    isUpdateMode(mode)
  ), [mode]);

  const isLocked = useMemo(() => (
    isLockedMode(mode)
  ), [mode]);

  const isBackgroundCreate = useMemo(() => (
    isBackgroundCreateMode(backgroundMode)
  ), [backgroundMode]);

  const isBackgroundDelete = useMemo(() => (
    isBackgroundDeleteMode(backgroundMode)
  ), [backgroundMode]);

  const handleChangeToPreparingAction = useCallback(() => {
    dispatch(preparingAction());
  }, []);

  const handleChangeToCreate = useCallback(() => {
    dispatch(changeToCreateMode());
  }, []);

  const handleChangeToBackgroundCreate = useCallback(() => {
    dispatch(changeToBackgroundCreateMode());
  }, []);

  const handleChangeToBackgroundDelete = useCallback(() => {
    dispatch(changeToBackgroundDeleteMode());
  }, []);

  const handleChangeToConsult = useCallback(() => {
    dispatch(changeToConsultMode());
  }, []);

  const handleChangeToUpdate = useCallback((additional = {}) => {
    dispatch(changeToUpdateMode());

    if (!isEmpty(additional) && isFunction(additional?.callback)) {
      additional.callback();
    }
  }, []);

  const handleResetBackgroundMode = useCallback(() => {
    dispatch(resetBackgroundMode());
  }, []);

  const fetchById = useCallback((followUpId) => {
    dispatch(fetchByIdAsync(followUpId));
  }, []);

  const model = useMemo(() => (
    followUp
  ), [followUp]);

  const breadcrumbs = useMemo(() => ([
    {
      link: {
        to: '/',
        name: t('com.muralis.qcx.inicio'),
      },
    },
    {
      link: {
        to: t('com.muralis.qcx.url.moduloImportacao'),
        name: t('com.muralis.qcx.importacao.label'),
      },
    },
    {
      link: {
        to: t('com.muralis.qcx.url.importacaoFollowUp'),
        name: t('com.muralis.qcx.followup.label'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.timeline.label'),
      },
    },
  ]), []);

  const pageTitle = useMemo(() => (
    t('com.muralis.qcx.timeline.timelineFollowUp', { numero: model?.numero || '-' })
  ), [model]);

  useAutoChangeMode((currentMode) => (
    dispatch(changeModeTo(currentMode))
  ), [isUpdate]);

  useEffect(() => {
    const handleFetchById = () => {
      if (!isEmpty(params?.id)) {
        fetchById(params?.id);

        handleChangeToConsult();
      }
    };

    handleFetchById();
  }, [
    params,
    handleChangeToConsult,
  ]);

  return (
    <QCXActionsManagerPageTemplate
      pageTitle={pageTitle}
      breadcrumbs={breadcrumbs}
      isIdle={isIdle}
      isLoading={isLoading}
      isFailure={isFailure}
      isCreate={isCreate}
      isConsult={isConsult}
      isUpdate={isUpdate}
      isLocked={isLocked}
      isBackgroundCreate={isBackgroundCreate}
      isBackgroundDelete={isBackgroundDelete}
      subtitle={{
        show: true,
        text: t('com.muralis.qcx.dadosProcesso'),
      }}
      handleChangeToPreparingAction={handleChangeToPreparingAction}
      handleChangeToCreate={handleChangeToCreate}
      handleChangeToBackgroundCreate={handleChangeToBackgroundCreate}
      handleChangeToBackgroundDelete={handleChangeToBackgroundDelete}
      handleChangeToConsult={handleChangeToConsult}
      handleChangeToUpdate={handleChangeToUpdate}
      handleResetBackgroundMode={handleResetBackgroundMode}
      authInfo={authInfo}
    >
      {() => (
        <>
          <QCXFollowUpFieldsViewerManager
            model={model}
          />
          <QCXFollowUpTimelineManager
            model={model}
          />
        </>
      )}
    </QCXActionsManagerPageTemplate>
  );
}
