import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import QCXFormStepWizard from '../../shared-components/form-step-wizard/QCXFormStepWizard';
import QCXFormTitle from '../form-title/QCXFormTitle';
import QCXSelectVinculacaoCompradorVendedorAutocomplete from '../../shared-components/select-vinculacao-comprador-vendedor/QCXSelectVinculacaoCompradorVendedorAutocomplete';
import { useFornecedorListenersGroup } from '../relacao-fornecedor/relacao-fornecedor-utils';
import QCXRelacaoFornecedorFieldGroup from '../relacao-fornecedor/QCXRelacaoFornecedorFieldGroup';
import QCXFaturaFornecedorFlagsFormGroup from '../fatura/QCXFaturaFornecedorFlagsFormGroup';
import QCXDeclaracaoImportacaoConsumoAdimissaoTemporariaFormGroup from './QCXDeclaracaoImportacaoConsumoAdimissaoTemporariaFormGroup';
import {
  configureGeneralTipoDeclaracaoMatcherWith,
  TIPO_DECLARACAO_CONSUMO_E_ADMISSAO_TEMPORARIA,
} from '../../shared-components/select-tipo-declaracao/tipoDeclaracaoUtils';
import { selectTiposDeclaracao } from '../../features/tipo-declaracao/tipoDeclaracaoSlice';

const useStyles = makeStyles((theme) => ({
  gridFields: {
    paddingBottom: '16px',
  },
  sibling: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: '8px',
      marginTop: '8px',
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: '8px',
    },
  },
}));

export default function QCXDeclaracaoImportacaoFornecedorForm({
  initialValues,
  handleSubmit,
  isConsult,
  isLocked,
  controlComponentProps,
  ...restProps
}) {
  const { t } = useTranslation();

  const classes = useStyles();

  const tiposDeclaracao = useSelector(selectTiposDeclaracao);

  const tipoDeclaracao = useMemo(() => (
    initialValues?.atributosAdicionais?.tipoDeclaracao?.id
  ), [initialValues]);

  const isSomeTipoDeclaracaoBy = useCallback((codesToCompare) => (
    configureGeneralTipoDeclaracaoMatcherWith(
      tiposDeclaracao
    )(
      tipoDeclaracao,
      codesToCompare
    )
  ), [tiposDeclaracao, tipoDeclaracao]);

  const isTipoDeclaracaoConsumoBy = useMemo(() => (
    isSomeTipoDeclaracaoBy([
      TIPO_DECLARACAO_CONSUMO_E_ADMISSAO_TEMPORARIA,
    ])
  ), [isSomeTipoDeclaracaoBy]);

  const onChangeListeners = useFornecedorListenersGroup();

  const renderSiblings = useCallback((values) => (
    <Grid
      item
      className={classes.sibling}
      xs={12}
      sm={12}
      md={12}
      lg={12}
    >
      <QCXSelectVinculacaoCompradorVendedorAutocomplete
        id="autocomplete-select-vinculacao-comprador-vendedor-fornecedor-field"
        key="autocomplete-select-vinculacao-comprador-vendedor-fornecedor-field"
        name="atributosAdicionais.vinculacaoCompradorVendedor"
        label={t('com.muralis.qcx.vinculacaoCompradorVendedor')}
        initialValues={values}
        disabled={isConsult}
      />
    </Grid>
  ), [isConsult, classes]);

  return (
    <QCXFormStepWizard
      initialValues={initialValues}
      onSubmit={handleSubmit}
      controlComponentProps={controlComponentProps}
      onChangeListeners={onChangeListeners}
      {...restProps}
    >
      {({ values }) => (
        <>
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={12}
            >
              <QCXFormTitle
                title={t('com.muralis.qcx.fornecedor.fichaFornecedor')}
              />
            </Grid>
            <QCXRelacaoFornecedorFieldGroup
              isConsult={isConsult}
              relacao={values?.relacao}
              fabricante={values?.fabricante}
              exportador={values?.exportador}
              required={false}
              initialValues={values}
              renderSiblings={renderSiblings}
            />
            {isTipoDeclaracaoConsumoBy && (
              <QCXDeclaracaoImportacaoConsumoAdimissaoTemporariaFormGroup
                isConsult={isConsult}
                classes={classes}
              />
            )}
            <QCXFaturaFornecedorFlagsFormGroup
              isConsult={isConsult}
            />
          </Grid>
        </>
      )}
    </QCXFormStepWizard>
  );
}
