import React from 'react';
import QCXFormStepWizard from '../../shared-components/form-step-wizard/QCXFormStepWizard';
import QCXSelectManyTabelaArmazenagem from './QCXSelectManyTabelaArmazenagem';

export default function QCXSelectManyTabelaArmazenagemTableManager({
  list,
  isConsult,
  handleSubmit,
  handleChange,
  controlComponentProps,
  ...restProps
}) {
  return (
    <QCXFormStepWizard
      onSubmit={handleSubmit}
      controlComponentProps={controlComponentProps}
      {...restProps}
    >
      {() => (
        <QCXSelectManyTabelaArmazenagem
          selected={list}
          isConsult={isConsult}
          handleChange={handleChange}
        />
      )}
    </QCXFormStepWizard>
  );
}
