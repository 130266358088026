import React from 'react';

const MainModeContext = React.createContext();
const SubModeContext = React.createContext();
const MainBackgroundModeContext = React.createContext();
const SubBackgroundModeContext = React.createContext();

export {
  MainModeContext,
  SubModeContext,
  MainBackgroundModeContext,
  SubBackgroundModeContext,
};
