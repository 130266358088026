import {
  doGetQCXRequest,
  doPostQCXRequest,
} from '../../utils/api/domain/domain-api-utils';
import {
  QCX_TRANSFERENCIA_ENDPOINT,
  QCX_ESTORNO_ENDPOINT,
  QCX_MERCADORIA_MOVIMENTACAO_ENDPOINT,
  QCX_SALDO_ENDPOINT,
  FOLLOW_UP_ENDPOINT,
  IMPORTADOR_ENDPOINT,
  ADICAO_ENDPOINT,
} from '../../utils/api/domain/domain-endpoint-utils';

async function fetchBalancesByOwners(data, queryParams = []) {
  return doPostQCXRequest(
    [
      QCX_MERCADORIA_MOVIMENTACAO_ENDPOINT,
      QCX_SALDO_ENDPOINT,
    ],
    data,
    queryParams
  );
}

async function fetchAvailableFollowUpsByImporterAndType(params) {
  return doGetQCXRequest(
    [
      QCX_MERCADORIA_MOVIMENTACAO_ENDPOINT,
      FOLLOW_UP_ENDPOINT,
      IMPORTADOR_ENDPOINT,
      params?.importador?.id,
    ],
    [
      {
        name: 'tipo',
        value: params?.tipo?.description,
      }
    ]
  );
}

async function transfer(data, queryParams = []) {
  return doPostQCXRequest(
    [
      QCX_MERCADORIA_MOVIMENTACAO_ENDPOINT,
      QCX_TRANSFERENCIA_ENDPOINT,
    ],
    data,
    queryParams
  );
}

async function transferAdicaoToLi(data, queryParams = []) {
  return doPostQCXRequest(
    [
      QCX_MERCADORIA_MOVIMENTACAO_ENDPOINT,
      QCX_TRANSFERENCIA_ENDPOINT,
      ADICAO_ENDPOINT,
    ],
    data,
    queryParams
  );
}

async function reverse(data, queryParams = []) {
  return doPostQCXRequest(
    [
      QCX_MERCADORIA_MOVIMENTACAO_ENDPOINT,
      QCX_ESTORNO_ENDPOINT,
    ],
    data,
    queryParams
  );
}

const mercadoriaMovimentacaoAPI = {
  fetchBalancesByOwners,
  fetchAvailableFollowUpsByImporterAndType,
  transfer,
  transferAdicaoToLi,
  reverse,
};

export {
  mercadoriaMovimentacaoAPI,
  fetchBalancesByOwners,
  fetchAvailableFollowUpsByImporterAndType,
  transfer,
  transferAdicaoToLi,
  reverse,
};
