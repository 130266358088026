import { createFilterOptions } from '@material-ui/lab/useAutocomplete';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchAllAsync,
  fetchByFilterAsync,
  selectPortosAeroportos,
} from '../../features/porto-aeroporto/PortoAeroportoSlice';
import QCXAutocompleteSelect from '../autocomplete-select/QCXAutocompleteSelect';

export default function QCXSelectPortoAeroportoAutocomplete({
  label,
  name,
  initialValues,
  filter,
  ...restProps
}) {
  const dispatch = useDispatch();

  const [defaultValue, setDefaultValue] = useState('');

  const list = useSelector(selectPortosAeroportos);

  useEffect(() => {
    const fetch = () => {
      if (filter?.local) {
        dispatch(
          fetchByFilterAsync([
            {
              name: 'local',
              value: filter?.local,
            },
          ])
        );

        return;
      }

      dispatch(fetchAllAsync());
    };

    fetch();
  }, []);

  useEffect(() => {
    const value = list.find((item) => item?.id === _.get(initialValues, name));

    if (value) {
      setDefaultValue(value);
    } else {
      setDefaultValue({ id: null, description: '' });
    }
  }, [list, initialValues]);

  const filterOptions = createFilterOptions({
    stringify: (item) => (
      item?.sigla
        ? `${item?.sigla} - ${item?.description}`
        : item?.description || ''
    ),
  });

  return (
    <QCXAutocompleteSelect
      label={label}
      id={name}
      name={name}
      options={list}
      filterOptions={filterOptions}
      defaultValue={defaultValue}
      getOptionLabel={(item) => (
        item?.sigla && item?.description
          ? `${item?.sigla} - ${item?.description}`
          : item?.description || ''
      )}
      {...restProps}
    />
  );
}
