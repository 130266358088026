import { createFilterOptions } from '@material-ui/lab/useAutocomplete';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import followUpDestinoThunks from '../../features/follow-up-destino-faturamento/followUpDestinoFaturamentoThunks';
import followUpDestinoSelectors from '../../features/follow-up-destino-faturamento/followUpDestinoFaturamentoSelectors';
import QCXAutocompleteSelect from '../autocomplete-select/QCXAutocompleteSelect';

export default function QCXSelectFollowUpDestinoAutocompleteField({
  label,
  name,
  initialValues,
  controlled = false,
  internalLoadingAtStart = true,
  disabled = false,
  ...restProps
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const list = useSelector(followUpDestinoSelectors.selectFollowUps);

  const [defaultValue, setDefaultValue] = useState('');
  const [filteredFollowUpsActive, setFilteredFollowUpsActive] = useState([]);
  const [internalLoading, setInternalLoading] = useState(internalLoadingAtStart);

  useEffect(() => {
    if (!_.isEmpty(list)) {
      (_.debounce(() => (
        setInternalLoading(false)
      ), 2000))();
    }
  }, [list]);

  useEffect(() => {
    if (!controlled) {
      dispatch(followUpDestinoThunks.fetchAllAsync());
    }
  }, [controlled]);

  useEffect(() => {
    const filterFollowUpsActive = list?.filter((item) => item?.active);

    if (filterFollowUpsActive) {
      setFilteredFollowUpsActive(filterFollowUpsActive);
    }
  }, [list, setFilteredFollowUpsActive]);

  useEffect(() => {
    const value = list?.find((item) => (
      item?.id === _.get(initialValues, name)
    ));

    if (value) {
      setDefaultValue(value);
    } else {
      setDefaultValue({ id: null, description: '' });
    }
  }, [list, initialValues]);

  const filterOptions = createFilterOptions({
    stringify: (option) => (
      option?.numero
    ),
  });

  return (
    <QCXAutocompleteSelect
      label={label}
      id={name}
      name={name}
      options={filteredFollowUpsActive}
      filterOptions={filterOptions}
      defaultValue={defaultValue}
      getOptionLabel={(item) => (
        item?.numero || ''
      )}
      {...restProps}
      placeholder={(
        internalLoading
          ? t('com.muralis.qcx.carregando')
          : t('com.muralis.qcx.selecione')
      )}
      loading={internalLoading}
      loadingText={t('com.muralis.qcx.carregando')}
      disabled={internalLoading || disabled}
    />
  );
}
