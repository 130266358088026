import i18n from '../../i18n';

const QUANTIDADE_X_VALOR = 'QUANTIDADE_X_VALOR';
const QUANTIDADE_X_VALOR_POR_10 = 'QUANTIDADE_X_VALOR_POR_10';
const QUANTIDADE_X_VALOR_POR_100 = 'QUANTIDADE_X_VALOR_POR_100';
const QUANTIDADE_X_VALOR_POR_1000 = 'QUANTIDADE_X_VALOR_POR_1000';

const FORMA_CALCULO_LIST = [
  {
    label: i18n.t('com.muralis.qcx.quantidade.quantidadeValor'),
    value: QUANTIDADE_X_VALOR,
  },
  {
    label: i18n.t('com.muralis.qcx.quantidade.quantidadeValor10'),
    value: QUANTIDADE_X_VALOR_POR_10,
  },
  {
    label: i18n.t('com.muralis.qcx.quantidade.quantidadeValor100'),
    value: QUANTIDADE_X_VALOR_POR_100,
  },
  {
    label: i18n.t('com.muralis.qcx.quantidade.quantidadeValor1000'),
    value: QUANTIDADE_X_VALOR_POR_1000,
  },
];

const getTipoCalculoValue = (tipoCalculo) => {
  switch (tipoCalculo) {
    case QUANTIDADE_X_VALOR: {
      return 1;
    }
    case QUANTIDADE_X_VALOR_POR_10: {
      return 10;
    }
    case QUANTIDADE_X_VALOR_POR_100: {
      return 100;
    }
    case QUANTIDADE_X_VALOR_POR_1000: {
      return 1000;
    }
    default: {
      return null;
    }
  }
};

export {
  FORMA_CALCULO_LIST,
  QUANTIDADE_X_VALOR,
  QUANTIDADE_X_VALOR_POR_10,
  QUANTIDADE_X_VALOR_POR_100,
  QUANTIDADE_X_VALOR_POR_1000,
  getTipoCalculoValue,
};
