import BasicLayout from '../../common/layouts/basicLayout';
import PivotTableChartIcon from '@mui/icons-material/PivotTableChart';
import CardsGrid, { CardsGridItem } from '../../common/components/cardsGrid/cardsGrid';
import ExtensionIcon from '@mui/icons-material/Extension';
import RttIcon from '@mui/icons-material/Rtt';
import AssessmentIcon from '@mui/icons-material/Assessment';

const gridItems: CardsGridItem[] = [
  {
    title: 'Planilhas',
    icon: PivotTableChartIcon,
    path: '/integracao/planilhas',
    requiredRoles: ['planilhas-visualizar'],
  },
  {
    title: 'TOTVS / Easy',
    icon: RttIcon,
    path: '/integracao/totvs-easy',
    subItems: true,
    requiredRoles: ['totvs-visualizar'],
  },
  {
    title: 'Croda',
    icon: RttIcon,
    path: '/integracao/croda',
    subItems: true,
    requiredRoles: ['croda-visualizar'],
  },
];

const IntegracaoPage = () => {
  return (
    <BasicLayout title={'Integração'} icon={<ExtensionIcon color={'secondary'} />}>
      <CardsGrid items={gridItems}></CardsGrid>
    </BasicLayout>
  );
};

export default IntegracaoPage;
