import {
  COBERTURA_CAMBIAL_SEM_COBERTURA,
} from '../select-cobertura-cambial/coberturaCambialUtils';
import {
  getMotivoImportacaoSemCoberturaCambialIdBy,
  MOTIVO_ADMISSAO_REGIME_ADUANEIRO_ESPECIAL_ATIPICO_CODE,
  MOTIVO_NACIONALIZACAO_MERCADORIA_ADMITIDA_ANTERIORMENTE_REGIME_ADUANEIRO_ESPECIAL_ATIPICO_CODE,
} from '../select-motivo-importacao-sem-cobertura-cambial/motivoImportacaoSemCoberturaCambialUtils';
import {
  REGIME_TRIBUTACAO_IMUNIDADE_CODE,
  REGIME_TRIBUTACAO_ISENCAO_CODE,
  REGIME_TRIBUTACAO_SUSPENSAO_CODE,
  getRegimeTributacaoIdBy,
} from '../select-regime-tributacao/regimeTributacaoUtils';

// IMPORTAÇÃO
const TIPO_DECLARACAO_DUIMP = '50';
const TIPO_DECLARACAO_CONSUMO_E_ADMISSAO_TEMPORARIA = '12';
const TIPO_DECLARACAO_CONSUMO = '01';
const TIPO_DECLARACAO_ADMISSAO_EM_ENTREPOSTO_ADUANEIRO = '02';
const TIPO_DECLARACAO_ADMISSAO_EM_EIZOF_ENTREPOSTO_INTERNACIONAL_DA_ZFM = '03';
const TIPO_DECLARACAO_ADMISSAO_EM_ENTREPOSTO_INDUSTRIAL = '04';
const TIPO_DECLARACAO_ADMISSAO_TEMPORARIA = '05';
const TIPO_DECLARACAO_ADMISSAO_NA_ZFM_ZONA_FRANCA_DE_MANAUS = '06';
const TIPO_DECLARACAO_ADMISSAO_EM_LOJA_FRANCA = '07';
const TIPO_DECLARACAO_ADMISSAO_EM_ALC_AREA_DE_LIVRE_COMERCIO = '08';
const TIPO_DECLARACAO_ADMISSAO_EM_DAD_DEPOSITO_ADUANEIRO_DE_DISTRIBUICAO = '09';
const TIPO_DECLARACAO_ADMISSAO_EM_DE_DAF = '10';
const TIPO_DECLARACAO_NACIONALIZACAO_DE_ADMISSAO_TEMPORARIA = '13';
const TIPO_DECLARACAO_NACIONALIZACAO_DE_ENTREPOSTO_ADUANEIRO = '14';
const TIPO_DECLARACAO_SAIDA_DE_EIZOF_ENTREPOSTO_INTERNACIONAL_DA_ZFM = '15';
const TIPO_DECLARACAO_SAIDA_DE_ENTREPOSTO_INDUSTRIAL = '16';
const TIPO_DECLARACAO_NACIONALIZACAO_DE_DEA_DEPOSITO_ESPECIAL_ALFANDEGADO = '17';
const TIPO_DECLARACAO_NACIONALIZACAO_DE_REGIME_ADUANEIRO_GERAL = '18';
const TIPO_DECLARACAO_INTERNACAO_DA_ZFM_PI_PRODUTO_INDUSTRIALIZADO = '19';
const TIPO_DECLARACAO_INTERNACAO_DA_ZFM_PE_PRODUTO_ESTRANGEIRO = '20';
const TIPO_DECLARACAO_INTERNACAO_DE_ALC_AREA_DE_LIVRE_COMERCIO = '21';

// EXPORTAÇÃO
const TIPO_DECLARACAO_ADMISSAO_EM_ZPE_ZONA_DE_PROCESSAMENTO_DE_EXPORTACAO = '11';
const TIPO_DECLARACAO_INTERNACAO_DA_ZPE_ZONA_DE_PROCESSAMENTO_DE_EXPORTACAO = '22';
const TIPO_DECLARACAO_TRANSFERENCIA_DA_ZFM_PRODUTO_INDUSTRIAL_PARA_ALC = '23';
const TIPO_DECLARACAO_TRANSFERENCIA_DA_ZFM_PRODUTO_ESTRANGEIRO_PARA_ALC = '24';
const TIPO_DECLARACAO_TRANSFERENCIA_DA_ZFM_PRODUTO_INDUSTRIAL_PARA_ZPE = '25';
const TIPO_DECLARACAO_TRANSFERENCIA_DA_ZFM_PRODUTO_ESTRANGEIRO_PARA_ZPE = '26';
const TIPO_DECLARACAO_TRANSFERENCIA_DA_ALC_PRODUTO_ESTRANGEIRO_PARA_ZFM = '27';
const TIPO_DECLARACAO_NACIONALIZACAO_DE_DE_DEPOSITO_ESPECIAL = '28';

const TIPOS_DECLARACAO_IMPORTACAO = [
  TIPO_DECLARACAO_DUIMP,
  TIPO_DECLARACAO_CONSUMO_E_ADMISSAO_TEMPORARIA,
  TIPO_DECLARACAO_CONSUMO,
  TIPO_DECLARACAO_ADMISSAO_EM_ENTREPOSTO_ADUANEIRO,
  TIPO_DECLARACAO_ADMISSAO_EM_EIZOF_ENTREPOSTO_INTERNACIONAL_DA_ZFM,
  TIPO_DECLARACAO_ADMISSAO_EM_ENTREPOSTO_INDUSTRIAL,
  TIPO_DECLARACAO_ADMISSAO_TEMPORARIA,
  TIPO_DECLARACAO_ADMISSAO_NA_ZFM_ZONA_FRANCA_DE_MANAUS,
  TIPO_DECLARACAO_ADMISSAO_EM_LOJA_FRANCA,
  TIPO_DECLARACAO_ADMISSAO_EM_ALC_AREA_DE_LIVRE_COMERCIO,
  TIPO_DECLARACAO_ADMISSAO_EM_DAD_DEPOSITO_ADUANEIRO_DE_DISTRIBUICAO,
  TIPO_DECLARACAO_ADMISSAO_EM_DE_DAF,
  TIPO_DECLARACAO_NACIONALIZACAO_DE_ADMISSAO_TEMPORARIA,
  TIPO_DECLARACAO_NACIONALIZACAO_DE_ENTREPOSTO_ADUANEIRO,
  TIPO_DECLARACAO_SAIDA_DE_EIZOF_ENTREPOSTO_INTERNACIONAL_DA_ZFM,
  TIPO_DECLARACAO_SAIDA_DE_ENTREPOSTO_INDUSTRIAL,
  TIPO_DECLARACAO_NACIONALIZACAO_DE_DEA_DEPOSITO_ESPECIAL_ALFANDEGADO,
  TIPO_DECLARACAO_NACIONALIZACAO_DE_REGIME_ADUANEIRO_GERAL,
  TIPO_DECLARACAO_INTERNACAO_DA_ZFM_PI_PRODUTO_INDUSTRIALIZADO,
  TIPO_DECLARACAO_INTERNACAO_DA_ZFM_PE_PRODUTO_ESTRANGEIRO,
  TIPO_DECLARACAO_INTERNACAO_DE_ALC_AREA_DE_LIVRE_COMERCIO,
];

const TIPOS_DECLARACAO_EXPORTACAO = [
  TIPO_DECLARACAO_ADMISSAO_EM_ZPE_ZONA_DE_PROCESSAMENTO_DE_EXPORTACAO,
  TIPO_DECLARACAO_INTERNACAO_DA_ZPE_ZONA_DE_PROCESSAMENTO_DE_EXPORTACAO,
  TIPO_DECLARACAO_TRANSFERENCIA_DA_ZFM_PRODUTO_INDUSTRIAL_PARA_ALC,
  TIPO_DECLARACAO_TRANSFERENCIA_DA_ZFM_PRODUTO_ESTRANGEIRO_PARA_ALC,
  TIPO_DECLARACAO_TRANSFERENCIA_DA_ZFM_PRODUTO_INDUSTRIAL_PARA_ZPE,
  TIPO_DECLARACAO_TRANSFERENCIA_DA_ZFM_PRODUTO_ESTRANGEIRO_PARA_ZPE,
  TIPO_DECLARACAO_TRANSFERENCIA_DA_ALC_PRODUTO_ESTRANGEIRO_PARA_ZFM,
  TIPO_DECLARACAO_NACIONALIZACAO_DE_DE_DEPOSITO_ESPECIAL,
];

const configureGeneralTipoDeclaracaoMatcherWith = (tiposDeclaracao) => {
  const isSomeTipoDeclaracaoConfiguredById = (id, codesToCompare = []) => {
    const foundTipoDeclaracao = tiposDeclaracao.find((tipoDeclaracao) => (
      tipoDeclaracao?.id === id
    ));

    return codesToCompare.includes(foundTipoDeclaracao?.code);
  };

  return isSomeTipoDeclaracaoConfiguredById;
};

const configureSpecificTipoDeclaracaoMatcherWith = (defaultCode) => {
  const configureSpecificMatcherWith = (list) => {
    const isTipoDeclaracaoConfiguredById = (id) => {
      const foundTipoDeclaracao = list.find((tipoDeclaracao) => (
        tipoDeclaracao?.id === id
      ));

      return (
        defaultCode === foundTipoDeclaracao?.code
      );
    };

    return isTipoDeclaracaoConfiguredById;
  };

  return configureSpecificMatcherWith;
};

const configureSpecificTipoDeclaracaoInternacaoZFMPIMatcher = (
  configureSpecificTipoDeclaracaoMatcherWith(
    TIPO_DECLARACAO_INTERNACAO_DA_ZFM_PI_PRODUTO_INDUSTRIALIZADO
  )
);

const iiIsencaoIpiSuspensaoPattern = (regimesTributacao) => ({
  codes: [
    TIPO_DECLARACAO_ADMISSAO_TEMPORARIA,
    TIPO_DECLARACAO_ADMISSAO_NA_ZFM_ZONA_FRANCA_DE_MANAUS,
  ],
  ii: {
    regimeTributacao: {
      id: getRegimeTributacaoIdBy(
        REGIME_TRIBUTACAO_ISENCAO_CODE
      )(regimesTributacao),
    },
  },
  ipi: {
    regimeTributacao: {
      id: getRegimeTributacaoIdBy(
        REGIME_TRIBUTACAO_SUSPENSAO_CODE
      )(regimesTributacao),
    },
  },
});

const iiPisCofinsIsencaoPattern = (regimesTributacao) => ({
  codes: [
    TIPO_DECLARACAO_ADMISSAO_EM_ENTREPOSTO_ADUANEIRO
  ],
  ii: {
    regimeTributacao: {
      id: getRegimeTributacaoIdBy(
        REGIME_TRIBUTACAO_ISENCAO_CODE
      )(regimesTributacao),
    },
  },
  pisCofins: {
    regimeTributacao: {
      id: getRegimeTributacaoIdBy(
        REGIME_TRIBUTACAO_ISENCAO_CODE
      )(regimesTributacao),
    },
  },
});

const iiIpiSuspensaoPattern = (regimesTributacao) => ({
  codes: [
    TIPO_DECLARACAO_ADMISSAO_EM_EIZOF_ENTREPOSTO_INTERNACIONAL_DA_ZFM,
    TIPO_DECLARACAO_ADMISSAO_EM_ENTREPOSTO_INDUSTRIAL,
    TIPO_DECLARACAO_ADMISSAO_EM_ALC_AREA_DE_LIVRE_COMERCIO,
  ],
  ii: {
    regimeTributacao: {
      id: getRegimeTributacaoIdBy(
        REGIME_TRIBUTACAO_SUSPENSAO_CODE
      )(regimesTributacao),
    },
  },
  ipi: {
    regimeTributacao: {
      id: getRegimeTributacaoIdBy(
        REGIME_TRIBUTACAO_SUSPENSAO_CODE
      )(regimesTributacao),
    },
  },
});

const iiPisCofinsSuspensaoPattern = (regimesTributacao) => ({
  ii: {
    regimeTributacao: {
      id: getRegimeTributacaoIdBy(
        REGIME_TRIBUTACAO_SUSPENSAO_CODE
      )(regimesTributacao),
    },
  },
  pisCofins: {
    regimeTributacao: {
      id: getRegimeTributacaoIdBy(
        REGIME_TRIBUTACAO_SUSPENSAO_CODE
      )(regimesTributacao),
    },
  },
});

const semCoberturaCambialNacionalizacaoPattern = (motivosImportacaoSemCoberturaCambial) => ({
  codes: [
    TIPO_DECLARACAO_SAIDA_DE_ENTREPOSTO_INDUSTRIAL,
    TIPO_DECLARACAO_INTERNACAO_DA_ZFM_PI_PRODUTO_INDUSTRIALIZADO,
    TIPO_DECLARACAO_INTERNACAO_DA_ZFM_PE_PRODUTO_ESTRANGEIRO,
    TIPO_DECLARACAO_INTERNACAO_DE_ALC_AREA_DE_LIVRE_COMERCIO,
  ],
  coberturaCambial: COBERTURA_CAMBIAL_SEM_COBERTURA,
  motivoImportacaoSemCoberturaCambial: {
    id: getMotivoImportacaoSemCoberturaCambialIdBy(
      MOTIVO_NACIONALIZACAO_MERCADORIA_ADMITIDA_ANTERIORMENTE_REGIME_ADUANEIRO_ESPECIAL_ATIPICO_CODE
    )(motivosImportacaoSemCoberturaCambial),
  },
});

const semCoberturaCambialAdmissaoPattern = (motivosImportacaoSemCoberturaCambial) => ({
  codes: [
    TIPO_DECLARACAO_ADMISSAO_EM_LOJA_FRANCA,
    TIPO_DECLARACAO_ADMISSAO_EM_DAD_DEPOSITO_ADUANEIRO_DE_DISTRIBUICAO,
    TIPO_DECLARACAO_ADMISSAO_EM_DE_DAF,
  ],
  coberturaCambial: COBERTURA_CAMBIAL_SEM_COBERTURA,
  motivoImportacaoSemCoberturaCambial: {
    id: getMotivoImportacaoSemCoberturaCambialIdBy(
      MOTIVO_ADMISSAO_REGIME_ADUANEIRO_ESPECIAL_ATIPICO_CODE
    )(motivosImportacaoSemCoberturaCambial),
  },
});

const iiIpiPisCofinsPatterns = [
  iiIsencaoIpiSuspensaoPattern,
  iiPisCofinsIsencaoPattern,
  iiIpiSuspensaoPattern,
];

const semCoberturaCambialPatterns = [
  semCoberturaCambialNacionalizacaoPattern,
  semCoberturaCambialAdmissaoPattern,
];

const getSuggestiveValues = ({
  tipoDeclaracao,
  regimesTributacao,
  motivosImportacaoSemCoberturaCambial,
}) => {
  let suggestedValues;

  iiIpiPisCofinsPatterns.forEach((f) => {
    const { codes, ...rest } = f(regimesTributacao);

    const hasTipoDeclaracao = codes.includes(tipoDeclaracao?.code);

    if (
      hasTipoDeclaracao
      && (tipoDeclaracao?.code === TIPO_DECLARACAO_ADMISSAO_EM_ENTREPOSTO_INDUSTRIAL)
    ) {
      suggestedValues = {
        ...rest,
        pisCofins: {
          regimeTributacao: {
            id: getRegimeTributacaoIdBy(
              REGIME_TRIBUTACAO_SUSPENSAO_CODE
            )(regimesTributacao),
          },
        },
      };
    }

    if (
      hasTipoDeclaracao
      && (tipoDeclaracao?.code === TIPO_DECLARACAO_ADMISSAO_TEMPORARIA)
    ) {
      suggestedValues = {
        ...rest,
        pisCofins: {
          regimeTributacao: {
            id: getRegimeTributacaoIdBy(
              REGIME_TRIBUTACAO_ISENCAO_CODE
            )(regimesTributacao),
          },
        },
      };
    }

    if (hasTipoDeclaracao && !suggestedValues) {
      suggestedValues = rest;
    }
  });

  if (suggestedValues) {
    return suggestedValues;
  }

  semCoberturaCambialPatterns.forEach((f) => {
    const { codes, ...rest } = f(motivosImportacaoSemCoberturaCambial);

    const hasTipoDeclaracao = codes.includes(tipoDeclaracao?.code);

    if (
      hasTipoDeclaracao
      && (
        [
          TIPO_DECLARACAO_INTERNACAO_DA_ZFM_PI_PRODUTO_INDUSTRIALIZADO,
          TIPO_DECLARACAO_INTERNACAO_DA_ZFM_PE_PRODUTO_ESTRANGEIRO,
        ].includes(tipoDeclaracao?.code)
      )
    ) {
      suggestedValues = {
        ...rest,
        ii: {
          regimeTributacao: {
            id: getRegimeTributacaoIdBy(
              REGIME_TRIBUTACAO_IMUNIDADE_CODE
            )(regimesTributacao),
          },
        },
      };
    }

    if (
      hasTipoDeclaracao
      && (
        [
          TIPO_DECLARACAO_ADMISSAO_EM_LOJA_FRANCA,
          TIPO_DECLARACAO_ADMISSAO_EM_DAD_DEPOSITO_ADUANEIRO_DE_DISTRIBUICAO,
          TIPO_DECLARACAO_ADMISSAO_EM_DE_DAF,
        ].includes(tipoDeclaracao?.code)
      )
    ) {
      suggestedValues = {
        ...rest,
        ii: {
          regimeTributacao: {
            id: getRegimeTributacaoIdBy(
              REGIME_TRIBUTACAO_IMUNIDADE_CODE
            )(regimesTributacao),
          },
        },
        ...iiPisCofinsSuspensaoPattern(regimesTributacao),
      };
    }

    if (hasTipoDeclaracao && !suggestedValues) {
      suggestedValues = rest;
    }
  });

  return suggestedValues || {};
};

export {
  TIPOS_DECLARACAO_IMPORTACAO,
  TIPOS_DECLARACAO_EXPORTACAO,
  TIPO_DECLARACAO_DUIMP,
  TIPO_DECLARACAO_CONSUMO_E_ADMISSAO_TEMPORARIA,
  TIPO_DECLARACAO_CONSUMO,
  TIPO_DECLARACAO_ADMISSAO_EM_ENTREPOSTO_ADUANEIRO,
  TIPO_DECLARACAO_ADMISSAO_EM_EIZOF_ENTREPOSTO_INTERNACIONAL_DA_ZFM,
  TIPO_DECLARACAO_ADMISSAO_EM_ENTREPOSTO_INDUSTRIAL,
  TIPO_DECLARACAO_ADMISSAO_TEMPORARIA,
  TIPO_DECLARACAO_ADMISSAO_NA_ZFM_ZONA_FRANCA_DE_MANAUS,
  TIPO_DECLARACAO_ADMISSAO_EM_LOJA_FRANCA,
  TIPO_DECLARACAO_ADMISSAO_EM_ALC_AREA_DE_LIVRE_COMERCIO,
  TIPO_DECLARACAO_ADMISSAO_EM_DAD_DEPOSITO_ADUANEIRO_DE_DISTRIBUICAO,
  TIPO_DECLARACAO_ADMISSAO_EM_DE_DAF,
  TIPO_DECLARACAO_ADMISSAO_EM_ZPE_ZONA_DE_PROCESSAMENTO_DE_EXPORTACAO,
  TIPO_DECLARACAO_NACIONALIZACAO_DE_ADMISSAO_TEMPORARIA,
  TIPO_DECLARACAO_NACIONALIZACAO_DE_ENTREPOSTO_ADUANEIRO,
  TIPO_DECLARACAO_SAIDA_DE_EIZOF_ENTREPOSTO_INTERNACIONAL_DA_ZFM,
  TIPO_DECLARACAO_SAIDA_DE_ENTREPOSTO_INDUSTRIAL,
  TIPO_DECLARACAO_NACIONALIZACAO_DE_DEA_DEPOSITO_ESPECIAL_ALFANDEGADO,
  TIPO_DECLARACAO_NACIONALIZACAO_DE_REGIME_ADUANEIRO_GERAL,
  TIPO_DECLARACAO_INTERNACAO_DA_ZFM_PI_PRODUTO_INDUSTRIALIZADO,
  TIPO_DECLARACAO_INTERNACAO_DA_ZFM_PE_PRODUTO_ESTRANGEIRO,
  TIPO_DECLARACAO_INTERNACAO_DE_ALC_AREA_DE_LIVRE_COMERCIO,
  TIPO_DECLARACAO_INTERNACAO_DA_ZPE_ZONA_DE_PROCESSAMENTO_DE_EXPORTACAO,
  TIPO_DECLARACAO_TRANSFERENCIA_DA_ZFM_PRODUTO_INDUSTRIAL_PARA_ALC,
  TIPO_DECLARACAO_TRANSFERENCIA_DA_ZFM_PRODUTO_ESTRANGEIRO_PARA_ALC,
  TIPO_DECLARACAO_TRANSFERENCIA_DA_ZFM_PRODUTO_INDUSTRIAL_PARA_ZPE,
  TIPO_DECLARACAO_TRANSFERENCIA_DA_ZFM_PRODUTO_ESTRANGEIRO_PARA_ZPE,
  TIPO_DECLARACAO_TRANSFERENCIA_DA_ALC_PRODUTO_ESTRANGEIRO_PARA_ZFM,
  TIPO_DECLARACAO_NACIONALIZACAO_DE_DE_DEPOSITO_ESPECIAL,
};

export {
  configureGeneralTipoDeclaracaoMatcherWith,
  configureSpecificTipoDeclaracaoInternacaoZFMPIMatcher,
  getSuggestiveValues,
};
