import { MenuItem, Typography, makeStyles } from '@material-ui/core';
import { FileUpload as FileUploadIcon } from '@mui/icons-material';
import { debounce, isArray, isEmpty, isFunction } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import uuid from 'react-uuid';
import { selectDeclaracaoImportacao } from '../../../../../../../features/declaracao-importacao/declaracaoImportacaoSelectors';
import {
  vinculoDespesaReceitaActions,
  vinculoDespesaReceitaSelectors,
} from '../../../../../../../features/vinculo-despesa-receita/vinculoDespesaReceitaSlice';
import QCXFormStepWizard from '../../../../../../../shared-components/form-step-wizard/QCXFormStepWizard';
import QCXListItemIcon from '../../../../../../../shared-components/list-item-icon/QCXListItemIcon';
import QCXCustomRegistrationTabControlTemplate from '../../../../../../../templates/custom-registration-one-to-many-page/QCXCustomRegistrationTabControlTemplate';
import { isValid, unnormalizeNumeral } from '../../../../../../../utils/general/general-utils';
import { formatBrazilianNumericDecimal } from '../../../../../../../utils/hooks/form/field/formatters';
import useCatalogoProdutosAtributosListeners from '../../../../../../../utils/general/catalogo-produtos/catalogoProdutosAtributosListeners';
import useConfigurePopUp, { ConfigurePopUpProps } from '../../../../../../common/hooks/popUps/useConfigurePopUp';
import { selectRelatedAdicaoModel, selectRelatedItemModel } from '../../../api/custeiosSelectors';
import {
  changeToSubConsultMode,
  failure,
  loading,
  resetBackgroundMode,
  resetRelatedAdicaoModel,
  setError,
  setRelatedAdicaoModel,
  setRelatedItemModel,
  setResponse,
  success,
} from '../../../api/custeiosSlice';
import { fetchByIdAsync } from '../../../api/custeiosThunks';
import CusteiosMercadoriaFormGroup from '../formGroups/CusteiosMercadoriaFormGroup';
import useMercadoriaListenersGroup from '../../../../../../../utils/general/fatura/faturaMercadoriaListeners';
import { unidadeDeMedidaActions } from '../../../../../../../features/unidade-medida/unidadeDeMedidaSlice';
import {
  selectCatalogosProdutos,
  selectRelatedEspecificacaoModel,
} from '../../../../../../../features/catalogo-produtos/catalogoProdutosSlice';
import useExternallyFilling from '../../../../../../../utils/hooks/form/field/useExternallyFilling';
import { getTipoCalculoValue } from '../../../../../../../shared-components/select-forma-calculo/formaCalculoUtils';
import { nveActions } from '../../../../../../../features/nve/nveSlice';
import { nveAtributoActions } from '../../../../../../../features/nve-atributo/nveAtributoSlice';
import { nveEspecificacaoActions } from '../../../../../../../features/nve-especificacao/nveEspecificacaoSlice';
import { ncmSubitemActions } from '../../../../../../../features/ncm-subitem/ncmSubitemSlice';
import { useHistory } from 'react-router';
import { faturaAPI } from '../../../../../../../features/fatura/faturaAPI';

const useStyles = makeStyles((theme) => ({
  gridFields: {
    paddingBottom: '16px',
  },
  moedaInternacionalFieldLeft: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: '5px',
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingBottom: '16px',
    },
  },
  moedaInternacionalFieldRight: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: '11px',
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '8px',
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
    },
  },
  rateioCheckbox: {
    paddingBottom: '30px',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '16px',
    },
  },
  sibling: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: '8px',
      marginTop: '8px',
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: '8px',
    },
  },
  generateLiIcon: {
    color: theme.palette.primary.main,
  },
}));

export default function ItensStepForm({
  isIdle,
  isLoading,
  isPreparingAction,
  isCreate,
  isConsult,
  isUpdate,
  isSubNone,
  isSubCreate,
  isSubConsult,
  isSubUpdate,
  isBackgroundDelete,
  isSubBackgroundCreate,
  isSubBackgroundUpdate,
  isSubBackgroundDelete,
  parentModel,
  defaultItem,
  handleCustomSaveBy,
  handleChangeToConsult,
  handleChangeToUpdate,
  handleChangeToSubNone,
  handleChangeToSubConsult,
  handleChangeToSubUpdate,
  handleChangeToBackgroundDelete,
  handleCancelUpdate,
  handleMetadataUpdate,
  handleLoadingStatus,
  handleResetStatus,
  controlComponentProps,
  isSubBackgroundConsult,
  tabManagerProps = {},
  formPaginationControlProps = {},
  buttonGroupOptions = {},
  ...restProps
}: any) {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [fatura, setFatura] = useState<any>({});

  const di = useSelector(selectDeclaracaoImportacao);

  useEffect(() => {
    async function fetchFatura() {
      const reponse = await faturaAPI.fetchById(di.id);
      setFatura(reponse.data);
    }
    fetchFatura();
  }, [di.id]);

  const adicao = useSelector(selectRelatedAdicaoModel);
  const adicoes = useMemo(
    () =>
      !isEmpty(parentModel?.fatura?.atributosAdicionais?.adicoes)
        ? parentModel?.fatura?.atributosAdicionais?.adicoes
        : fatura?.atributosAdicionais?.adicoes,
    [parentModel, fatura]
  );

  const hasItems = useMemo(() => isArray(adicoes) && !isEmpty(adicoes), [adicoes]);
  const mercadorias = useMemo(() => parentModel?.fatura?.mercadorias, [parentModel]);
  const itemModel = useSelector(selectRelatedItemModel);
  const unidadeMedidaList = useSelector(unidadeDeMedidaActions.selectUnidadesdeMedida);
  const catalogoProdutoList = useSelector(selectCatalogosProdutos);
  const ncmList = useSelector(ncmSubitemActions.selectNcmSubitens);
  const nveEspecificacao = useSelector(selectRelatedEspecificacaoModel);
  const [isFirstFormLoadValues, setIsFirstFormLoadValues] = useState(true);
  const [isFilling, subscribeToFilling] = useExternallyFilling();
  const isSobrescreveDadosPartnumber = useMemo(() => parentModel?.sobrescreveDadosPartnumber, [parentModel]);

  const unnormalize = useCallback(
    (normalizedData: any) => ({
      ...normalizedData,
      valorFornecedor: isValid(normalizedData?.valorFornecedor)
        ? unnormalizeNumeral(normalizedData?.valorFornecedor, formatBrazilianNumericDecimal(2))
        : undefined,
      pesoLiquido: isValid(normalizedData?.pesoLiquido)
        ? unnormalizeNumeral(normalizedData?.pesoLiquido, formatBrazilianNumericDecimal(7))
        : undefined,
      pesoBruto: isValid(normalizedData?.pesoBruto)
        ? unnormalizeNumeral(normalizedData?.pesoBruto, formatBrazilianNumericDecimal(7))
        : undefined,
      quantidadeEstatistica: isValid(normalizedData?.quantidadeEstatistica)
        ? unnormalizeNumeral(normalizedData?.quantidadeEstatistica, formatBrazilianNumericDecimal(7))
        : undefined,
      valorMleReal: isValid(normalizedData?.valorMleReal)
        ? unnormalizeNumeral(normalizedData?.valorMleReal, formatBrazilianNumericDecimal(2))
        : undefined,
      valorDespesaMoeda: isValid(normalizedData?.valorDespesaMoeda)
        ? unnormalizeNumeral(normalizedData?.valorDespesaMoeda, formatBrazilianNumericDecimal(2))
        : undefined,
      valorDespesaReal: isValid(normalizedData?.valorDespesaReal)
        ? unnormalizeNumeral(normalizedData?.valorDespesaReal, formatBrazilianNumericDecimal(2))
        : undefined,
      valorCifReal: isValid(normalizedData?.valorCifReal)
        ? unnormalizeNumeral(normalizedData?.valorCifReal, formatBrazilianNumericDecimal(2))
        : undefined,
      valorMcvMoeda: isValid(normalizedData?.valorMcvMoeda)
        ? unnormalizeNumeral(normalizedData?.valorMcvMoeda, formatBrazilianNumericDecimal(2))
        : undefined,
      valorMcvReal: isValid(normalizedData?.valorMcvReal)
        ? unnormalizeNumeral(normalizedData?.valorMcvReal, formatBrazilianNumericDecimal(2))
        : undefined,
      valorFreteTerritorioNacionalMoeda: isValid(normalizedData?.valorFreteTerritorioNacionalMoeda)
        ? unnormalizeNumeral(normalizedData?.valorFreteTerritorioNacionalMoeda, formatBrazilianNumericDecimal(2))
        : undefined,
      valorFreteTerritorioNacionalReal: isValid(normalizedData?.valorFreteTerritorioNacionalReal)
        ? unnormalizeNumeral(normalizedData?.valorFreteTerritorioNacionalReal, formatBrazilianNumericDecimal(2))
        : undefined,
      valorSeguroMoeda: isValid(normalizedData?.valorSeguroMoeda)
        ? unnormalizeNumeral(normalizedData?.valorSeguroMoeda, formatBrazilianNumericDecimal(2))
        : undefined,
      valorSeguroReal: isValid(normalizedData?.valorSeguroReal)
        ? unnormalizeNumeral(normalizedData?.valorSeguroReal, formatBrazilianNumericDecimal(2))
        : undefined,
      valorFreteMoeda: isValid(normalizedData?.valorFreteMoeda)
        ? unnormalizeNumeral(normalizedData?.valorFreteMoeda, formatBrazilianNumericDecimal(2))
        : undefined,
      valorFreteReal: isValid(normalizedData?.valorFreteReal)
        ? unnormalizeNumeral(normalizedData?.valorFreteReal, formatBrazilianNumericDecimal(2))
        : undefined,
      exportador: normalizedData?.exportador?.id ? normalizedData?.exportador : undefined,
      fabricante: normalizedData?.fabricante?.id ? normalizedData?.fabricante : undefined,
      paisOrigem: normalizedData?.paisOrigem?.id ? normalizedData?.paisOrigem : undefined,
      instituicaoFinanciadora: normalizedData?.instituicaoFinanciadora?.id
        ? normalizedData?.instituicaoFinanciadora
        : undefined,
      motivoImportacaoSemCoberturaCambial: normalizedData?.motivoImportacaoSemCoberturaCambial?.id
        ? normalizedData?.motivoImportacaoSemCoberturaCambial
        : undefined,
      moeda: normalizedData?.moeda?.id ? normalizedData?.moeda : undefined,
      moedaDespesa: normalizedData?.moedaDespesa?.id ? normalizedData?.moedaDespesa : undefined,
      ncm: normalizedData?.ncm?.id ? normalizedData?.ncm : undefined,
      naladiNcca: normalizedData?.naladiNcca?.id ? normalizedData?.naladiNcca : undefined,
      naladiSh: normalizedData?.naladiSh?.id ? normalizedData?.naladiSh : undefined,
      operacaoFiscal: normalizedData?.operacaoFiscal?.id ? normalizedData?.operacaoFiscal : undefined,
      atributos: (normalizedData?.atributos || [])?.map((atributo: any) =>
        atributo?.id
          ? {
              ...atributo,
              nve: atributo?.nve?.id ? atributo?.nve : undefined,
              atributo: atributo?.atributo?.id ? atributo?.atributo : undefined,
              especificacao: atributo?.especificacao?.id ? atributo?.especificacao : undefined,
            }
          : {
              ...atributo,
              nve: atributo?.nve?.id ? atributo?.nve : undefined,
              atributo: atributo?.atributo?.id ? atributo?.atributo : undefined,
              especificacao: atributo?.especificacao?.id ? atributo?.especificacao : undefined,
              id: uuid(),
            }
      ),
      declaracaoExportacoes: (normalizedData.declaracaoExportacoes || [])?.map((de: any) =>
        de?.id
          ? {
              ...de,
              qtdUnidadeEstatistica: isValid(de?.qtdUnidadeEstatistica)
                ? unnormalizeNumeral(de?.qtdUnidadeEstatistica, formatBrazilianNumericDecimal(7))
                : undefined,
            }
          : {
              ...de,
              id: uuid(),
              qtdUnidadeEstatistica: isValid(de?.qtdUnidadeEstatistica)
                ? unnormalizeNumeral(de?.qtdUnidadeEstatistica, formatBrazilianNumericDecimal(7))
                : undefined,
            }
      ),
      acrescimosDeducoes: (normalizedData?.acrescimosDeducoes || [])?.map((acrescimoDeducao: any) =>
        acrescimoDeducao?.id
          ? {
              ...acrescimoDeducao,
              moeda: acrescimoDeducao?.moeda?.id ? acrescimoDeducao?.moeda : undefined,
              acrescimoValoracaoAduaneira: acrescimoDeducao?.acrescimoValoracaoAduaneira?.id
                ? acrescimoDeducao?.acrescimoValoracaoAduaneira
                : undefined,
              valorMoeda: isValid(acrescimoDeducao?.valorMoeda)
                ? unnormalizeNumeral(acrescimoDeducao?.valorMoeda, formatBrazilianNumericDecimal(2))
                : undefined,
              valorReal: isValid(acrescimoDeducao?.valorReal)
                ? unnormalizeNumeral(acrescimoDeducao?.valorReal, formatBrazilianNumericDecimal(2))
                : undefined,
            }
          : {
              ...acrescimoDeducao,
              moeda: acrescimoDeducao?.moeda?.id ? acrescimoDeducao?.moeda : undefined,
              acrescimoValoracaoAduaneira: acrescimoDeducao?.acrescimoValoracaoAduaneira?.id
                ? acrescimoDeducao?.acrescimoValoracaoAduaneira
                : undefined,
              valorMoeda: isValid(acrescimoDeducao?.valorMoeda)
                ? unnormalizeNumeral(acrescimoDeducao?.valorMoeda, formatBrazilianNumericDecimal(2))
                : undefined,
              valorReal: isValid(acrescimoDeducao?.valorReal)
                ? unnormalizeNumeral(acrescimoDeducao?.valorReal, formatBrazilianNumericDecimal(2))
                : undefined,
              id: uuid(),
            }
      ),
      incotermCondicaoVenda: normalizedData?.incotermCondicaoVenda?.id
        ? normalizedData?.incotermCondicaoVenda
        : undefined,
      metodoValoracaoAduaneira: normalizedData?.metodoValoracaoAduaneira?.id
        ? normalizedData?.metodoValoracaoAduaneira
        : undefined,
      portoAeroporto: normalizedData?.portoAeroporto?.id ? normalizedData?.portoAeroporto : undefined,
      antidumping: {
        ...normalizedData?.antidumping,
        orgaoEmissorAtoLegal: normalizedData?.antidumping?.orgaoEmissorAtoLegal?.id
          ? normalizedData?.antidumping?.orgaoEmissorAtoLegal
          : undefined,
        unidadeDeMedida: normalizedData?.antidumping?.unidadeDeMedida?.id
          ? normalizedData?.antidumping?.unidadeDeMedida
          : undefined,
        tipoAtoLegal: normalizedData?.antidumping?.tipoAtoLegal?.id
          ? normalizedData?.antidumping?.tipoAtoLegal
          : undefined,
        ano: `${normalizedData?.antidumping?.ano || ''}`,
        aliquota: isValid(normalizedData?.antidumping?.aliquota)
          ? unnormalizeNumeral(normalizedData?.antidumping?.aliquota, formatBrazilianNumericDecimal(7))
          : undefined,
        valorReal: isValid(normalizedData?.antidumping?.valorReal)
          ? unnormalizeNumeral(normalizedData?.antidumping?.valorReal, formatBrazilianNumericDecimal(2))
          : undefined,
        quantidade: isValid(normalizedData?.antidumping?.quantidade)
          ? unnormalizeNumeral(normalizedData?.antidumping?.quantidade, formatBrazilianNumericDecimal(7))
          : undefined,
        baseCalculoReal: isValid(normalizedData?.antidumping?.baseCalculoReal)
          ? unnormalizeNumeral(normalizedData?.antidumping?.baseCalculoReal, formatBrazilianNumericDecimal(2))
          : undefined,
        valorDevidoReal: isValid(normalizedData?.antidumping?.valorDevidoReal)
          ? unnormalizeNumeral(normalizedData?.antidumping?.valorDevidoReal, formatBrazilianNumericDecimal(2))
          : undefined,
        valorRecolherReal: isValid(normalizedData?.antidumping?.valorRecolherReal)
          ? unnormalizeNumeral(normalizedData?.antidumping?.valorRecolherReal, formatBrazilianNumericDecimal(2))
          : undefined,
      },
      icms: {
        ...normalizedData?.icms,
        regimeTributacao: normalizedData?.icms?.regimeTributacao?.id
          ? normalizedData?.icms?.regimeTributacao
          : undefined,
        dispositivoLegal: normalizedData?.icms?.dispositivoLegal?.id
          ? normalizedData?.icms?.dispositivoLegal
          : undefined,
        baseCalculoReal: isValid(normalizedData?.icms?.baseCalculoReal)
          ? unnormalizeNumeral(normalizedData?.icms?.baseCalculoReal, formatBrazilianNumericDecimal(2))
          : undefined,
        aliquota: isValid(normalizedData?.icms?.aliquota)
          ? unnormalizeNumeral(normalizedData?.icms?.aliquota, formatBrazilianNumericDecimal(7))
          : undefined,
        aliquotaOriginal: isValid(normalizedData?.icms?.aliquotaOriginal)
          ? unnormalizeNumeral(normalizedData?.icms?.aliquotaOriginal, formatBrazilianNumericDecimal(7))
          : undefined,
        aliquotaFecp: isValid(normalizedData?.icms?.aliquotaFecp)
          ? unnormalizeNumeral(normalizedData?.icms?.aliquotaFecp, formatBrazilianNumericDecimal(7))
          : undefined,
        percentualReducao: isValid(normalizedData?.icms?.percentualReducao)
          ? unnormalizeNumeral(normalizedData?.icms?.percentualReducao, formatBrazilianNumericDecimal(7))
          : undefined,
        valorRecolherReal: isValid(normalizedData?.icms?.valorRecolherReal)
          ? unnormalizeNumeral(normalizedData?.icms?.valorRecolherReal, formatBrazilianNumericDecimal(2))
          : undefined,
        valorDevidoReal: isValid(normalizedData?.icms?.valorDevidoReal)
          ? unnormalizeNumeral(normalizedData?.icms?.valorDevidoReal, formatBrazilianNumericDecimal(2))
          : undefined,
        recolherPermanencia: isValid(normalizedData?.icms?.valorDevidoReal)
          ? unnormalizeNumeral(normalizedData?.icms?.recolherPermanencia, formatBrazilianNumericDecimal(2))
          : undefined,
      },
      pisCofins: {
        ...normalizedData?.pisCofins,
        unidadeDeMedidaPisPasep: normalizedData?.pisCofins?.unidadeDeMedidaPisPasep?.id
          ? normalizedData?.pisCofins?.unidadeDeMedidaPisPasep
          : undefined,
        unidadeDeMedidaCofins: normalizedData?.pisCofins?.unidadeDeMedidaCofins?.id
          ? normalizedData?.pisCofins?.unidadeDeMedidaCofins
          : undefined,
        regimeTributacao: normalizedData?.pisCofins?.regimeTributacao?.id
          ? normalizedData?.pisCofins?.regimeTributacao
          : undefined,
        fundamentoLegalRegimeTributacaoPisCofins: normalizedData?.pisCofins?.fundamentoLegalRegimeTributacaoPisCofins
          ?.id
          ? normalizedData?.pisCofins?.fundamentoLegalRegimeTributacaoPisCofins
          : undefined,
        baseCalculoReal: isValid(normalizedData?.pisCofins?.baseCalculoReal)
          ? unnormalizeNumeral(normalizedData?.pisCofins?.baseCalculoReal, formatBrazilianNumericDecimal(2))
          : undefined,
        percentualReducao: isValid(normalizedData?.pisCofins?.percentualReducao)
          ? unnormalizeNumeral(normalizedData?.pisCofins?.percentualReducao, formatBrazilianNumericDecimal(7))
          : undefined,
        percentualReducaoBaseCalculo: isValid(normalizedData?.pisCofins?.percentualReducaoBaseCalculo)
          ? unnormalizeNumeral(
              normalizedData?.pisCofins?.percentualReducaoBaseCalculo,
              formatBrazilianNumericDecimal(7)
            )
          : undefined,
        aliquotaPisPasep: isValid(normalizedData?.pisCofins?.aliquotaPisPasep)
          ? unnormalizeNumeral(normalizedData?.pisCofins?.aliquotaPisPasep, formatBrazilianNumericDecimal(7))
          : undefined,
        aliquotaReduzidaPisPasep: isValid(normalizedData?.pisCofins?.aliquotaReduzidaPisPasep)
          ? unnormalizeNumeral(normalizedData?.pisCofins?.aliquotaReduzidaPisPasep, formatBrazilianNumericDecimal(7))
          : undefined,
        valorRecolherPisPasepReal: isValid(normalizedData?.pisCofins?.valorRecolherPisPasepReal)
          ? unnormalizeNumeral(normalizedData?.pisCofins?.valorRecolherPisPasepReal, formatBrazilianNumericDecimal(2))
          : undefined,
        quantidadePisPasep: isValid(normalizedData?.pisCofins?.quantidadePisPasep)
          ? unnormalizeNumeral(normalizedData?.pisCofins?.quantidadePisPasep, formatBrazilianNumericDecimal(2))
          : undefined,
        aliquotaCofins: isValid(normalizedData?.pisCofins?.aliquotaCofins)
          ? unnormalizeNumeral(normalizedData?.pisCofins?.aliquotaCofins, formatBrazilianNumericDecimal(7))
          : undefined,
        aliquotaReduzidaCofins: isValid(normalizedData?.pisCofins?.aliquotaReduzidaCofins)
          ? unnormalizeNumeral(normalizedData?.pisCofins?.aliquotaReduzidaCofins, formatBrazilianNumericDecimal(7))
          : undefined,
        valorRecolherCofinsReal: isValid(normalizedData?.pisCofins?.valorRecolherCofinsReal)
          ? unnormalizeNumeral(normalizedData?.pisCofins?.valorRecolherCofinsReal, formatBrazilianNumericDecimal(2))
          : undefined,
        quantidadeCofins: isValid(normalizedData?.pisCofins?.quantidadeCofins)
          ? unnormalizeNumeral(normalizedData?.pisCofins?.quantidadeCofins, formatBrazilianNumericDecimal(7))
          : undefined,
        valorDevidoPisPasepReal: isValid(normalizedData?.pisCofins?.valorDevidoPisPasepReal)
          ? unnormalizeNumeral(normalizedData?.pisCofins?.valorDevidoPisPasepReal, formatBrazilianNumericDecimal(2))
          : undefined,
        valorDevidoCofinsReal: isValid(normalizedData?.pisCofins?.valorDevidoCofinsReal)
          ? unnormalizeNumeral(normalizedData?.pisCofins?.valorDevidoCofinsReal, formatBrazilianNumericDecimal(2))
          : undefined,
        recolherPermanenciaCofins: isValid(normalizedData?.pisCofins?.recolherPermanenciaCofins)
          ? unnormalizeNumeral(normalizedData?.pisCofins?.recolherPermanenciaCofins, formatBrazilianNumericDecimal(2))
          : undefined,
        recolherPermanenciaPisPasep: isValid(normalizedData?.pisCofins?.recolherPermanenciaPisPasep)
          ? unnormalizeNumeral(normalizedData?.pisCofins?.recolherPermanenciaPisPasep, formatBrazilianNumericDecimal(2))
          : undefined,
      },
      ipi: {
        ...normalizedData?.ipi,
        tipoAtoLegalFundamentoLegal: normalizedData?.ipi?.tipoAtoLegalFundamentoLegal?.id
          ? normalizedData?.ipi?.tipoAtoLegalFundamentoLegal
          : undefined,
        orgaoEmissorFundamentoLegal: normalizedData?.ipi?.orgaoEmissorFundamentoLegal?.id
          ? normalizedData?.ipi?.orgaoEmissorFundamentoLegal
          : undefined,
        numeroAtoFundamentoLegal: normalizedData?.ipi?.numeroAtoFundamentoLegal,
        anoFundamentoLegal: `${normalizedData?.ipi?.anoFundamentoLegal || ''}`,
        numeroExFundamentoLegal: `${normalizedData?.ipi?.numeroExFundamentoLegal || ''}`,

        orgaoEmissorAtoLegal: normalizedData?.ipi?.orgaoEmissorAtoLegal?.id
          ? normalizedData?.ipi?.orgaoEmissorAtoLegal
          : undefined,
        regimeTributacao: normalizedData?.ipi?.regimeTributacao?.id ? normalizedData?.ipi?.regimeTributacao : undefined,
        tipoRecipiente: normalizedData?.ipi?.tipoRecipiente?.id ? normalizedData?.ipi?.tipoRecipiente : undefined,
        unidadeDeMedida: normalizedData?.ipi?.unidadeDeMedida?.id ? normalizedData?.ipi?.unidadeDeMedida : undefined,
        baseCalculoReal: isValid(normalizedData?.ipi?.baseCalculoReal)
          ? unnormalizeNumeral(normalizedData?.ipi?.baseCalculoReal, formatBrazilianNumericDecimal(2))
          : undefined,
        aliquota: isValid(normalizedData?.ipi?.aliquota)
          ? unnormalizeNumeral(normalizedData?.ipi?.aliquota, formatBrazilianNumericDecimal(7))
          : undefined,
        aliquotaReduzida: isValid(normalizedData?.ipi?.aliquotaReduzida)
          ? unnormalizeNumeral(normalizedData?.ipi?.aliquotaReduzida, formatBrazilianNumericDecimal(7))
          : undefined,
        valorUnidadeMedidaReal: isValid(normalizedData?.ipi?.valorUnidadeMedidaReal)
          ? unnormalizeNumeral(normalizedData?.ipi?.valorUnidadeMedidaReal, formatBrazilianNumericDecimal(2))
          : undefined,
        quantidade: isValid(normalizedData?.ipi?.quantidade)
          ? unnormalizeNumeral(normalizedData?.ipi?.quantidade, formatBrazilianNumericDecimal(7))
          : undefined,
        capacidade: isValid(normalizedData?.ipi?.capacidade)
          ? unnormalizeNumeral(normalizedData?.ipi?.capacidade, formatBrazilianNumericDecimal(7))
          : undefined,
        valorDevidoReal: isValid(normalizedData?.ipi?.valorDevidoReal)
          ? unnormalizeNumeral(normalizedData?.ipi?.valorDevidoReal, formatBrazilianNumericDecimal(2))
          : undefined,
        valorRecolherReal: isValid(normalizedData?.ipi?.valorRecolherReal)
          ? unnormalizeNumeral(normalizedData?.ipi?.valorRecolherReal, formatBrazilianNumericDecimal(2))
          : undefined,
        recolherPermanencia: isValid(normalizedData?.ipi?.recolherPermanencia)
          ? unnormalizeNumeral(normalizedData?.ipi?.recolherPermanencia, formatBrazilianNumericDecimal(2))
          : undefined,
        tipoAtoLegalExTarifario: normalizedData?.ipi?.tipoAtoLegalExTarifario?.id
          ? normalizedData?.ipi?.tipoAtoLegalExTarifario
          : undefined,
        anoExTarifario: `${normalizedData?.ipi?.anoExTarifario || ''}`,
      },
      ii: {
        ...normalizedData?.ii,
        fundamentoLegalRegimeTributacaoIi: normalizedData?.ii?.fundamentoLegalRegimeTributacaoIi?.id
          ? normalizedData?.ii?.fundamentoLegalRegimeTributacaoIi
          : undefined,
        tipoAtoLegalExTarifario: normalizedData?.ii?.tipoAtoLegalExTarifario?.id
          ? normalizedData?.ii?.tipoAtoLegalExTarifario
          : undefined,
        tipoAtoLegalAcordoTarifario: normalizedData?.ii?.tipoAtoLegalAcordoTarifario?.id
          ? normalizedData?.ii?.tipoAtoLegalAcordoTarifario
          : undefined,
        regimeTributacao: normalizedData?.ii?.regimeTributacao?.id ? normalizedData?.ii?.regimeTributacao : undefined,
        ano: `${normalizedData?.ii?.ano || ''}`,
        anoExTarifario: `${normalizedData?.ii?.anoExTarifario || ''}`,
        anoAcordoTarifario: `${normalizedData?.ii?.anoAcordoTarifario || ''}`,
        orgaoEmissorAtoLegalExTarifario: normalizedData?.ii?.orgaoEmissorAtoLegalExTarifario?.id
          ? normalizedData?.ii?.orgaoEmissorAtoLegalExTarifario
          : undefined,
        orgaoEmissorAtoLegalAcordoTarifario: normalizedData?.ii?.orgaoEmissorAtoLegalAcordoTarifario?.id
          ? normalizedData?.ii?.orgaoEmissorAtoLegalAcordoTarifario
          : undefined,
        baseCalculoReal: isValid(normalizedData?.ii?.baseCalculoReal)
          ? unnormalizeNumeral(normalizedData?.ii?.baseCalculoReal, formatBrazilianNumericDecimal(2))
          : undefined,
        aliquota: isValid(normalizedData?.ii?.aliquota)
          ? unnormalizeNumeral(normalizedData?.ii?.aliquota, formatBrazilianNumericDecimal(7))
          : undefined,
        percentualReducao: isValid(normalizedData?.ii?.percentualReducao)
          ? unnormalizeNumeral(normalizedData?.ii?.percentualReducao, formatBrazilianNumericDecimal(7))
          : undefined,
        aliquotaReduzida: isValid(normalizedData?.ii?.aliquotaReduzida)
          ? unnormalizeNumeral(normalizedData?.ii?.aliquotaReduzida, formatBrazilianNumericDecimal(7))
          : undefined,
        coeficienteReducao: isValid(normalizedData?.ii?.coeficienteReducao)
          ? unnormalizeNumeral(normalizedData?.ii?.coeficienteReducao, formatBrazilianNumericDecimal(7))
          : undefined,
        valorDolar: isValid(normalizedData?.ii?.valorDolar)
          ? unnormalizeNumeral(normalizedData?.ii?.valorDolar, formatBrazilianNumericDecimal(2))
          : undefined,
        valorReal: isValid(normalizedData?.ii?.valorReal)
          ? unnormalizeNumeral(normalizedData?.ii?.valorReal, formatBrazilianNumericDecimal(2))
          : undefined,
        valorDevidoReal: isValid(normalizedData?.ii?.valorDevidoReal)
          ? unnormalizeNumeral(normalizedData?.ii?.valorDevidoReal, formatBrazilianNumericDecimal(2))
          : undefined,
        valorRecolherReal: isValid(normalizedData?.ii?.valorRecolherReal)
          ? unnormalizeNumeral(normalizedData?.ii?.valorRecolherReal, formatBrazilianNumericDecimal(2))
          : undefined,
        recolherPermanencia: isValid(normalizedData?.ii?.recolherPermanencia)
          ? unnormalizeNumeral(normalizedData?.ii?.recolherPermanencia, formatBrazilianNumericDecimal(2))
          : undefined,
        acordoAladi: normalizedData?.ii?.acordoAladi ? normalizedData?.ii?.acordoAladi.id : undefined,
      },
      taxaSiscomex: {
        ...normalizedData?.taxaSiscomex,
        valorReal: isValid(normalizedData?.taxaSiscomex?.valorReal)
          ? unnormalizeNumeral(normalizedData?.taxaSiscomex?.valorReal, formatBrazilianNumericDecimal(2))
          : undefined,
      },
      afrmm: {
        ...normalizedData?.afrmm,
        valorDevidoReal: isValid(normalizedData?.afrmm?.valorDevidoReal)
          ? unnormalizeNumeral(normalizedData?.afrmm?.valorDevidoReal, formatBrazilianNumericDecimal(2))
          : undefined,
        valorRecolherReal: isValid(normalizedData?.afrmm?.valorRecolherReal)
          ? unnormalizeNumeral(normalizedData?.afrmm?.valorRecolherReal, formatBrazilianNumericDecimal(2))
          : undefined,
      },
    }),
    []
  );

  const handleEdit = useCallback(() => {
    if (!isCreate) {
      handleChangeToUpdate({
        callback: handleChangeToSubUpdate,
      });

      return;
    }

    handleChangeToSubUpdate();
  }, [isCreate, handleChangeToUpdate, handleChangeToSubUpdate]);

  const currentTitleSubPage = useMemo(() => t('com.muralis.qcx.item.dadosItem'), []);

  const handleChangeModel = useCallback((updatedModel: any) => {
    dispatch(setRelatedItemModel(updatedModel));
  }, []);

  const handleInternalCancelUpdate = useCallback(() => {
    if (isUpdate) {
      handleCancelUpdate({
        callback: handleChangeToSubConsult,
      });

      return;
    }

    handleChangeToSubConsult();
  }, [isUpdate, handleCancelUpdate, handleChangeToSubConsult]);

  const tableProps = useMemo(
    () => ({
      title: t('com.muralis.qcx.adicao.adicoesAdicionadas'),
      columns: [
        {
          field: 'nroAdicao',
          headerName: t('com.muralis.qcx.adicao.numeroAdicacao'),
          headerAlign: 'center',
          align: 'center',
          flex: 170,
          valueGetter: ({ row }: { row: any }) => row?.item || '-',
        },
        {
          field: 'quantidadeItens',
          headerName: t('com.muralis.qcx.quantidadeItens'),
          headerAlign: 'center',
          align: 'center',
          flex: 170,
          valueGetter: ({ row }: { row: any }) => row?.mercadorias?.length || '-',
        },
        {
          field: 'exportador',
          headerName: t('com.muralis.qcx.exportador.label'),
          headerAlign: 'center',
          align: 'center',
          flex: 160,
          valueGetter: ({ row }: { row: any }) => row?.exportador?.pessoaJuridica?.nomeResumido || '-',
        },
        {
          field: 'fornecedor',
          headerName: t('com.muralis.qcx.fornecedor.label'),
          headerAlign: 'center',
          align: 'center',
          flex: 160,
          valueGetter: ({ row }: { row: any }) => row?.fabricante?.pessoaJuridica?.nomeResumido || '-',
        },
        {
          field: 'valorMleMoeda',
          headerName: t('com.muralis.qcx.moeda.VMLEmoeda'),
          headerAlign: 'center',
          align: 'center',
          flex: 140,
          valueFormatter: ({ row }: { row: any }) =>
            unnormalizeNumeral(row?.valorMleMoeda, formatBrazilianNumericDecimal(7)) || '-',
        },
        {
          field: 'valorCifMoeda',
          headerName: t('com.muralis.qcx.moeda.CIFmoeda'),
          headerAlign: 'center',
          align: 'center',
          flex: 140,
          valueFormatter: ({ row }: { row: any }) =>
            unnormalizeNumeral(row?.valorCifBaseCalculoMoeda, formatBrazilianNumericDecimal(7)) || '-',
        },
        {
          field: 'valorMcvMoeda',
          headerName: t('com.muralis.qcx.moeda.MCVmoeda'),
          headerAlign: 'center',
          align: 'center',
          flex: 140,
          valueFormatter: ({ row }: { row: any }) =>
            unnormalizeNumeral(row?.valorMcvMoeda, formatBrazilianNumericDecimal(7)) || '-',
        },
        {
          field: 'pesoBruto',
          headerName: t('com.muralis.qcx.pesoBruto'),
          headerAlign: 'center',
          align: 'center',
          flex: 130,
          valueFormatter: ({ row }: { row: any }) =>
            unnormalizeNumeral(row?.pesoBruto, formatBrazilianNumericDecimal(7)) || '-',
        },
        {
          field: 'pesoLiquido',
          headerName: t('com.muralis.qcx.pesoLiquido'),
          headerAlign: 'center',
          align: 'center',
          flex: 130,
          valueFormatter: ({ row }: { row: any }) =>
            unnormalizeNumeral(row?.pesoLiquido, formatBrazilianNumericDecimal(7)) || '-',
        },
      ],
    }),
    []
  );

  const popUpConfig: ConfigurePopUpProps = {
    primaryConfirmCallback: async (data: any, refreshCallback?: (args: any) => void) => {
      const composeWith = (parent: any) => {
        dispatch(resetRelatedAdicaoModel());

        return {
          ...parent,
          atributosAdicionais: {
            ...parent?.atributosAdicionais,
            adicoes: [
              ...(parent?.atributosAdicionais?.adicoes?.filter((current: any) => current?.id !== data?.id) || []),
            ],
          },
        };
      };

      const executeDebounced = debounce(async () => {
        try {
          const response = await handleCustomSaveBy({
            composeWith,
            step: 9, // TODO: Verificar dinamicamento de acordo com o Tipo de Declaração atual
          });

          if (response?.status === 200) {
            const handleResultWithDebounce = debounce(() => {
              dispatch(success());

              const saved = response?.data;

              const processo = saved?.atributosAdicionais?.processo;
              const adicaoNumber = data?.item;

              const feedbackMessage = t('com.muralis.qcx.mensagem.adicaoRemovidaListaDeclaracaoImportacao', {
                adicaoNumber,
                processo,
              });

              dispatch(
                setResponse({
                  status: response.status,
                  data: saved,
                  message: feedbackMessage,
                })
              );

              dispatch(changeToSubConsultMode());
              dispatch(fetchByIdAsync(saved?.id));

              if (isFunction(refreshCallback)) {
                refreshCallback(saved?.atributosAdicionais?.adicoes);
              }
            }, 500);

            handleResultWithDebounce();
          }
          //@ts-ignore
        } catch ({ request, response }) {
          dispatch(failure());
          dispatch(
            setError({
              message: t('com.muralis.qcx.erro.erroRemoverAdicaoListaDeclaracaoImportacao', {
                //@ts-ignore
                item: data?.item,
                //@ts-ignore
                processo: request?.data?.atributosAdicionais?.processo,
                //@ts-ignore
                mensagem: response?.data?.message,
              }),
            })
          );
        }
      }, 500);

      dispatch(loading());
      executeDebounced();
    },
  };

  const [handleRemoveByIdMiddleware, operationOfRemoveById] = useConfigurePopUp(popUpConfig);

  const handleRemoveByForm = useCallback(
    (refreshCallback: any) => {
      handleChangeToBackgroundDelete();

      const configureOperation = () => ({
        options: {
          title: t('com.muralis.qcx.acoes.confirmarOperacaoRemocao').toUpperCase(),
          message: t('com.muralis.qcx.mensagem.confirmarOperacaoAdicao', {
            item: adicao?.item,
          }),
          endMessage: t('com.muralis.qcx.acoes.certezaRemocao'),
        },
        cleanUp: () => {
          dispatch(resetBackgroundMode());
        },
      });

      handleRemoveByIdMiddleware({onOpenCallback: configureOperation, args: adicao,});
    },
    [adicao, handleChangeToBackgroundDelete, handleRemoveByIdMiddleware]
  );

  const handleRemoveByTable = useCallback(
    ({ row }: { row: any }) => {
      handleChangeToBackgroundDelete();

      const configureOperation = () => ({
        options: {
          title: t('com.muralis.qcx.acoes.confirmarOperacaoRemocao').toUpperCase(),
          message: t('com.muralis.qcx.mensagem.confirmarOperacaoAdicao', {
            item: row?.item,
          }),
          endMessage: t('com.muralis.qcx.acoes.certezaRemocao'),
        },
        cleanUp: () => {
          dispatch(resetBackgroundMode());
        },
      });

      handleRemoveByIdMiddleware({onOpenCallback: configureOperation, args: row});
    },
    [handleChangeToBackgroundDelete, handleRemoveByIdMiddleware]
  );

  const handleClearForm = useCallback(() => {
    dispatch(resetRelatedAdicaoModel());
  }, []);

  const defaultRestartedValues = useMemo(
    () => ({
      // TODO: Definir os valores default para o restart do form
    }),
    []
  );

  const localeTableText = useMemo(
    () => ({
      noRowsLabel: t('com.muralis.qcx.validacao.nenhumaAdicaoEncontrada'),
    }),
    []
  );

  const handleAlternativeSaveAdicao = useCallback(() => {
    if (isEmpty(controlComponentProps) || !isFunction(controlComponentProps?.handleAlternativeSave)) {
      throw Error('Error: "handleAlternativeSave" is not a function.');
    }

    const { handleAlternativeSave } = controlComponentProps;

    // TODO: Verificar dinamicamento de acordo com o Tipo de Declaração atual
    const adicoesStepIndex = 8;

    handleAlternativeSave(null, adicoesStepIndex);
  }, [controlComponentProps]);
  const internalTabManagerProps = useMemo(
    () => ({
      ...tabManagerProps,
      scrollButtons: 'auto',
      variant: 'scrollable',
      tabs: [
        {
          label: t('com.muralis.qcx.lista'),
          disabled: isSubUpdate,
        },
        {
          label: t('com.muralis.qcx.mercadoria.label'),
          disabled: !hasItems || isSubNone,
        },
      ],
      onlyActiveTabEnabled: isSubUpdate,
    }),
    [hasItems, isSubNone, isSubUpdate]
  );

  const internalFormPaginationControlProps = useMemo(
    () => ({
      ...formPaginationControlProps,
      dualControlButtons: {
        primary: {
          paginationInfoField: {
            label: t('com.muralis.qcx.adicao.label'),
            hiddenValue: isSubCreate || isSubUpdate,
          },
          buttonGroup: {
            first: {
              description: t('com.muralis.qcx.adicao.primeiraAdicao'),
            },
            next: {
              description: t('com.muralis.qcx.adicao.proximaAdicao'),
            },
            previous: {
              description: t('com.muralis.qcx.adicao.adicaoAnterior'),
            },
            last: {
              description: t('com.muralis.qcx.adicao.ultimaAdicao'),
            },
          },
        },
        secondary: {
          paginationInfoField: {
            label: t('com.muralis.qcx.item.labelPlural'),
            hiddenValue: isSubCreate || isSubUpdate,
          },
        },
      },
      getSubListByParent: (parent: any) => parent?.mercadorias || [],
    }),
    [isSubCreate, isSubUpdate, formPaginationControlProps]
  );

  const initialValues = useMemo(
    () =>
      !isSubCreate
        ? unnormalize(itemModel)
        : {
            quantidade: '',
            valorTotalMoeda: '',
            icms: '',
            destaqueNcm: '',
            pesoLiquido: '',
            pesoLiquidoUnitario: '',
            quantidadeEstatistica: '',
            percentualDesconto: '',
            valorUnitarioMoeda: '',
            meta: {
              initialized: false,
            },
          },
    [isSubCreate, itemModel, unnormalize]
  );

  const suggestedInitialValues = useMemo(
    () => ({
      partnumberAutocompleteSelector: initialValues?.partnumber,
      partNumberSelector: initialValues?.partnumber,
      relacao: parentModel?.relacao,
      exportador: parentModel?.exportador?.id
        ? {
            id: parentModel?.exportador?.id,
          }
        : undefined,
      fabricante: parentModel?.fabricante?.id
        ? {
            id: parentModel?.fabricante?.id,
          }
        : undefined,
      paisOrigem: parentModel?.paisOrigem?.id
        ? {
            id: parentModel?.paisOrigem?.id,
          }
        : undefined,
    }),
    [, initialValues, parentModel]
  );

  const computedInitialValues = useMemo(
    () => ({
      ...initialValues,
      ...suggestedInitialValues,
    }),
    [initialValues, suggestedInitialValues]
  );

  const tableData = useMemo(() => {
    if (isEmpty(adicoes)) {
      return mercadorias;
    }

    if (isSubConsult) {
      const modifiedAdicoes = adicoes.map((adicao: any) => ({
        ...adicao,
        mercadorias: adicao?.mercadorias?.map((mercadoria: any) => ({
          ...mercadoria,
          metadata: {
            parent: {
              id: adicao?.id,
              numero: adicao?.item,
            },
            item: {
              numero: mercadoria?.itemAdicao,
            },
          },
        })),
      }));

      return modifiedAdicoes;
    }

    const groupByParent = (parent: any) => (subPrevious: any, subCurrent: any) =>
      [
        ...subPrevious,
        {
          ...subCurrent,
          metadata: {
            parent: {
              id: parent?.id,
              numero: parent?.item,
            },
            item: {
              numero: subCurrent?.itemAdicao,
            },
          },
        },
      ];

    const groupByMercadorias = (previous: any, current: any) => [
      ...previous,
      ...current?.mercadorias?.reduce(groupByParent(current), []),
    ];

    const modifiedMercadoriasFromAdicoes = adicoes.reduce(groupByMercadorias, []);

    return modifiedMercadoriasFromAdicoes;
  }, [adicoes, mercadorias, isSubConsult]);

  const isDualFormPagination = useMemo(() => !isEmpty(adicoes), [adicoes]);

  const sortableValueGetter = useCallback(
    (data: any) => (isDualFormPagination ? data?.metadata?.parent?.numero : data?.item),
    [isDualFormPagination]
  );

  const mercadoriaFormProps = useMemo(
    () => ({
      faturaValues: parentModel?.fatura,
      listCatalogo: catalogoProdutoList,
      unidadeMedidaList,
      isFirstFormLoadValues,
      setIsFirstFormLoadValues,
      rootName: 'adicionais',
    }),
    [parentModel, catalogoProdutoList, unidadeMedidaList, isFirstFormLoadValues, setIsFirstFormLoadValues]
  );

  const modes = useMemo(
    () => ({
      isCreate,
      isConsult,
      isSubConsult,
      isSubNone,
      isSubCreate,
      isSubUpdate,
    }),
    [isCreate, isConsult, isSubConsult, isSubNone, isSubCreate, isSubUpdate]
  );

  const [onBlurObjectHandlers, mercadoriaChangeListeners] = useMercadoriaListenersGroup({
    modes,
    formProps: mercadoriaFormProps,
    subscribeToFilling,
    isSobrescreveDadosPartnumber,
    getTipoCalculoValue,
  }) as [
    {
      handleValorTotalMoedaOnBlur: (form: any, values: any) => () => void;
      handleValorUnitarioMoedaProdutoOnBlur: (form: any, values: any) => void;
      handleQuantidadeOnBlur: (form: any, values: any) => () => void;
      handlePesoLiquidoOnBlur: (form: any, values: any) => () => void;
      handlePesoLiquidoUnitarioOnBlur: (form: any, values: any) => () => void;
    },
    {
      name: string;
      fn: (form: any, values: any) => (value: any) => void;
    }[]
  ];

  const catalogoProdutoActions = useMemo(
    () => ({
      nveActions,
      nveAtributoActions,
      nveEspecificacaoActions,
    }),
    [nveActions, nveAtributoActions, nveEspecificacaoActions]
  );

  const catalogoProdutoFormProps = useMemo(
    () => ({
      ncmList,
      ncm: itemModel?.ncm,
      especificacao: nveEspecificacao,
    }),
    [ncmList, itemModel, nveEspecificacao]
  );

  const catalogoProdutosAtributosChangeListeners = useCatalogoProdutosAtributosListeners({
    rootName: 'ignorableFields.atributoNve',
    modes,
    actions: catalogoProdutoActions,
    formProps: catalogoProdutoFormProps,
  });

  const changeListeners = useMemo(
    () => [...mercadoriaChangeListeners, ...catalogoProdutosAtributosChangeListeners],
    [mercadoriaChangeListeners, catalogoProdutosAtributosChangeListeners]
  );

  const handleSubmit = useCallback(() => {
    history.push(t('com.muralis.qcx.url.importacaoCusteios'));
  }, [history]);

  return (
    <>
      <QCXFormStepWizard
        onSubmit={handleSubmit}
        controlComponentProps={controlComponentProps}
        // onChangeListeners={changeListeners}
        alternativeMode
        {...restProps}
      >
        {({ submitting, submitSucceeded }: { submitting: boolean; submitSucceeded: boolean }) => (
          <QCXCustomRegistrationTabControlTemplate
            autoControlInitialValues
            autoFormReset
            buttonGroupOptions={buttonGroupOptions}
            defaultItem={defaultItem}
            defaultRestartedValues={defaultRestartedValues}
            dualFormControlPagination={isDualFormPagination}
            formPaginationControlProps={internalFormPaginationControlProps}
            handleAlternativeSave={undefined}
            handleCancelCreate={undefined}
            handleCancelUpdate={handleInternalCancelUpdate}
            handleChangeModel={handleChangeModel}
            handleChangeToConsult={handleChangeToConsult}
            handleChangeToSubConsult={handleChangeToSubConsult}
            handleChangeToSubNone={handleChangeToSubNone}
            handleChangeToUpdate={handleChangeToUpdate}
            handleClearForm={handleClearForm}
            handleEdit={handleEdit}
            handleLoadingStatus={handleLoadingStatus}
            handleMetadataUpdate={handleMetadataUpdate}
            handleNew={undefined}
            handleRemoveByForm={handleRemoveByForm}
            handleRemoveByTable={handleRemoveByTable}
            handleResetStatus={handleResetStatus}
            initialValues={computedInitialValues}
            isBackgroundDelete={isBackgroundDelete}
            isConsult={isConsult}
            isCreate={isCreate}
            isIdle={isIdle}
            isLoading={isLoading}
            isPreparingAction={isPreparingAction}
            isSubBackgroundConsult={isSubBackgroundConsult}
            isSubBackgroundCreate={isSubBackgroundCreate}
            isSubBackgroundDelete={isSubBackgroundDelete}
            isSubBackgroundUpdate={isSubBackgroundUpdate}
            isSubConsult={isSubConsult}
            isSubCreate={isSubCreate}
            isSubNone={isSubNone}
            isSubUpdate={isSubUpdate}
            isUpdate={isUpdate}
            localeTableText={localeTableText}
            sortableValueGetter={sortableValueGetter}
            submitSucceeded={submitSucceeded}
            submitting={submitting}
            tableData={tableData}
            tableProps={tableProps}
            tabManagerProps={internalTabManagerProps}
            title={currentTitleSubPage}
          >
            {() => (
              <CusteiosMercadoriaFormGroup
              // tipo={TIPO_DI_DUIMP}
              // modes={{
              //   isConsult,
              //   isSubConsult,
              //   isSubNone,
              // }}
              // classes={classes}
              // isFilling={false}
              // onBlurObjectHandlers={undefined}
              // fatura={undefined}
              />
            )}
          </QCXCustomRegistrationTabControlTemplate>
        )}
      </QCXFormStepWizard>
    </>
  );
}
