import { useMemo } from 'react';
import { FaturaDIDuimp } from '../types/faturaDIDuimp';
import { useGetRequest, QueriesTypes } from './queries';
import { AcordoAladi } from '../types/acordoAladi';

export const useAcordoAladi = () => {
  const {
    data: acordosData,
    isError,
    isLoading,
  } = useGetRequest(QueriesTypes.AcordoAladi);
  const acordosAladi: AcordoAladi[] = useMemo(() => {
    if (acordosData && !isError) {
      try {
        return acordosData?.filter((item: any) => item.active);
      } catch (e) {
        console.log(e);
        return [];
      }
    } else {
      return [];
    }
  }, [acordosData]);
  return { acordosAladi, isLoading, isError };
};
