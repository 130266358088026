import { createFilterOptions } from '@material-ui/lab/useAutocomplete';
import _ from 'lodash';
import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
} from 'react';
import QCXAutocompleteSelect from '../autocomplete-select/QCXAutocompleteSelect';
import { MODALIDADE_DRAWBACK_LIST } from './modalidadeDrawbackUtils';

export default function QCXSelectModalidadeDrawbackAutocomplete({
  label,
  name,
  initialValues,
  ...restProps
}) {
  const [defaultValue, setDefaultValue] = useState('');

  useEffect(() => {
    const value = MODALIDADE_DRAWBACK_LIST.find(
      (item) => (
        item?.value === _.get(initialValues, name)
      )
    );

    if (value) {
      setDefaultValue(value);
    } else {
      setDefaultValue('');
    }
  }, [initialValues]);

  const filterOptions = useMemo(() => (
    createFilterOptions({
      stringify: (option) => (
        option.label + option.value
      ),
    })
  ), []);

  const getOptionLabel = useCallback((item) => (
    item?.label || ''
  ), []);

  const getOptionValue = useCallback((item) => (
    item?.value || ''
  ), []);

  return (
    <QCXAutocompleteSelect
      label={label}
      id={name}
      name={name}
      options={MODALIDADE_DRAWBACK_LIST}
      filterOptions={filterOptions}
      defaultValue={defaultValue}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      {...restProps}
    />
  );
}
