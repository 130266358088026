import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchAll, fetchById, fetchByFilter } from './fundamentacaoEspecificaAPI';

const fetchAllAsync = createAsyncThunk(
  'fundamentacaoEspecifica/fetchAll',
  async () => {
    const { data } = await fetchAll();
    return { data };
  }
);

const fetchByIdAsync = createAsyncThunk(
  'fundamentacaoEspecifica/fetchById',
  async (data) => {
    const response = await fetchById(data);
    return {
      response: {
        status: response.status,
        data: response.data,
      },
    };
  }
);

const fetchByFilterAsync = createAsyncThunk(
  'fundamentacaoEspecifica/fetchByFilter',
  async (params) => {
    const { data } = await fetchByFilter(params);
    return { data };
  }
);

const fundamentacaoEspecificaThunks = {
  fetchAllAsync,
  fetchByIdAsync,
  fetchByFilterAsync,
};

export {
  fundamentacaoEspecificaThunks,
  fetchAllAsync,
  fetchByIdAsync,
  fetchByFilterAsync,
};
