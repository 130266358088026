import React from 'react';
import { Box } from '@material-ui/core';

export default function QCXBox({
  fontWeight,
  pt,
  children,
  ...restProps
}) {
  return (
    <Box
      fontWeight={fontWeight}
      pt={pt}
      {...restProps}
    >
      {children}
    </Box>
  );
}
