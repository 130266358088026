import { getOptionByValueInList } from '../../utils/general/general-utils';
import i18n from '../../i18n';

const MODO_TRANSFERENCIA_POR_QUANTIDADE = 'TRANSFERIR_POR_QUANTIDADE';
const MODO_TRANSFERENCIA_POR_PESO_LIQUIDO = 'TRANSFERIR_POR_PESO_LIQUIDO';

const MODO_TRANSFERENCIA_FATURA_LIST = [
  {
    label: i18n.t('com.muralis.qcx.quantidade'),
    value: MODO_TRANSFERENCIA_POR_QUANTIDADE,
  },
  {
    label: i18n.t('com.muralis.qcx.pesoLiquido'),
    value: MODO_TRANSFERENCIA_POR_PESO_LIQUIDO,
  },
];

const isModoTransferenciaPorQuantidade = (value) => (
  MODO_TRANSFERENCIA_POR_QUANTIDADE === value
);

const isModoTransferenciaPorPesoLiquido = (value) => (
  MODO_TRANSFERENCIA_POR_PESO_LIQUIDO === value
);

const getTipoModoTransferenciaFaturaLabelByValue = (value) => {
  const foundOption = getOptionByValueInList(
    MODO_TRANSFERENCIA_FATURA_LIST,
    value
  );
  return foundOption && foundOption?.label;
};

const ModoTransferenciaFaturaUtils = {
  LIST: MODO_TRANSFERENCIA_FATURA_LIST,
  POR_QUANTIDADE: (
    MODO_TRANSFERENCIA_POR_QUANTIDADE
  ),
  POR_PESO_LIQUIDO: (
    MODO_TRANSFERENCIA_POR_PESO_LIQUIDO
  ),
  isPorQuantidade: (
    isModoTransferenciaPorQuantidade
  ),
  isPorPesoLiquido: (
    isModoTransferenciaPorPesoLiquido
  ),
  getLabelByValue: (
    getTipoModoTransferenciaFaturaLabelByValue
  ),
};

export {
  MODO_TRANSFERENCIA_POR_QUANTIDADE,
  MODO_TRANSFERENCIA_POR_PESO_LIQUIDO,
  MODO_TRANSFERENCIA_FATURA_LIST,
  isModoTransferenciaPorQuantidade,
  isModoTransferenciaPorPesoLiquido,
  getTipoModoTransferenciaFaturaLabelByValue,
};

export default ModoTransferenciaFaturaUtils;
