import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import i18n from '../../i18n';
import { isNaoCalculadaStatus } from '../../utils/general/operations/operationUtils';
import {
  CONSULT_MODE,
  CREATE_MODE,
  UPDATE_MODE,
  TRANSFER_MODE,
  REVERSAL_MODE,
  NONE_MODE,
  SUB_CREATE_MODE,
  SUB_CONSULT_MODE,
  SUB_UPDATE_MODE,
  NONE_SUB_MODE,
  BACKGROUND_CREATE_MODE,
  BACKGROUND_DELETE_MODE,
  BACKGROUND_TRANSFER_MODE,
  BACKGROUND_REVERSAL_MODE,
  NONE_BACKGROUND_MODE,
} from '../mode';
import {
  ALTERNATIVE_LOADING_STATUS,
  FAILURE_STATUS,
  IDLE_STATUS,
  LOADING_STATUS,
  PREPARING_ACTION_STATUS,
  SUCCESS_STATUS,
} from '../status';
import {
  activateByIdAsync,
  calculateByIdAsync,
  fetchAllAsync,
  fetchByIdAsync,
  inactivateByIdAsync,
} from './faturaThunks';

const initialState = {
  status: IDLE_STATUS,
  mode: {
    main: NONE_MODE,
    background: NONE_BACKGROUND_MODE,
    sub: NONE_SUB_MODE,
  },
  error: null,
  response: {
    status: null,
    message: null,
    data: null,
  },
  list: [],
  model: {
    code: null,
    description: null,
    active: null,
    numero: null,
    followUp: null,
    pesoBruto: null,
    pesoLiquido: null,
    rateiaPesoLiquidoItem: null,
    dadosComumFornecedor: null,
    importador: {
      id: null,
    },
    paisProcedencia: {
      id: null,
    },
    urfDespacho: {
      id: null,
    },
    urfEntrada: {
      id: null,
    },
    incotermCondicaoVenda: {
      id: null,
    },
    mle: {
      valorMoeda: null,
      valorReal: null,
      moeda: {
        id: null,
      },
    },
    despesa: {
      valorMoeda: null,
      valorReal: null,
      moeda: {
        id: null,
      },
    },
    frete: {
      prepaid: null,
      embutirItem: null,
      valorMoedaCollect: null,
      valorRealCollect: null,
      valorMoedaPrepaid: null,
      valorRealPrepaid: null,
      moeda: {
        id: null,
      },
    },
    seguro: {
      embutirItem: null,
      calculaSobreMleFrete: null,
      valorPercentual: null,
      valorMoeda: null,
      valorReal: null,
      moeda: {
        id: null,
      },
    },
    relacao: null,
    exportador: {
      id: null,
    },
    fabricante: {
      id: null,
    },
    mercadorias: [],
  },
  related: {
    model: {
      mercadoria: {},
      transferencia: {},
      transferenciaItem: {},
      estorno: {},
    },
    list: {
      selectionFatura: [],
      selectionAvailableItem: [],
      selectionItem: [],
      selectionChangedItem: [],
    },
  },
};

const faturaSlice = createSlice({
  name: 'fatura',
  initialState,
  reducers: {
    changeStatusTo: (state, action) => {
      state.status = action.payload.status;
    },
    loading: (state) => {
      state.status = LOADING_STATUS;
    },
    success: (state) => {
      state.status = SUCCESS_STATUS;
    },
    failure: (state) => {
      state.status = FAILURE_STATUS;
    },
    preparingAction: (state) => {
      state.status = PREPARING_ACTION_STATUS;
    },
    resetStatus: (state) => {
      state.status = IDLE_STATUS;
    },
    changeModeTo: (state, action) => {
      state.mode.main = action.payload;
    },
    changeToCreateMode: (state) => {
      state.mode.main = CREATE_MODE;
    },
    changeToConsultMode: (state) => {
      state.mode.main = CONSULT_MODE;
    },
    changeToUpdateMode: (state) => {
      state.mode.main = UPDATE_MODE;
    },
    changeToTransferMode: (state) => {
      state.mode.main = TRANSFER_MODE;
    },
    changeToReversalMode: (state) => {
      state.mode.main = REVERSAL_MODE;
    },
    resetMode: (state) => {
      state.mode.main = NONE_MODE;
    },
    changeToBackgroundCreateMode: (state) => {
      state.mode.background = BACKGROUND_CREATE_MODE;
    },
    changeToBackgroundDeleteMode: (state) => {
      state.mode.background = BACKGROUND_DELETE_MODE;
    },
    changeToBackgroundTransferMode: (state) => {
      state.mode.background = BACKGROUND_TRANSFER_MODE;
    },
    changeToBackgroundReversalMode: (state) => {
      state.mode.background = BACKGROUND_REVERSAL_MODE;
    },
    resetBackgroundMode: (state) => {
      state.mode.background = NONE_BACKGROUND_MODE;
    },
    changeToSubCreateMode: (state) => {
      state.mode.sub = SUB_CREATE_MODE;
    },
    changeToSubConsultMode: (state) => {
      state.mode.sub = SUB_CONSULT_MODE;
    },
    changeToSubUpdateMode: (state) => {
      state.mode.sub = SUB_UPDATE_MODE;
    },
    resetSubMode: (state) => {
      state.mode.sub = NONE_SUB_MODE;
    },
    setModel: (state, action) => {
      state.model = {
        ...action.payload,
      };
    },
    setNumero: (state, action) => {
      state.model.numero = action.payload.numero;
    },
    setFollowUp: (state, action) => {
      state.model.followUp = action.payload.followUp;
    },
    setImportador: (state, action) => {
      state.model.importador = {
        ...action.payload.importador,
      };
    },
    updateSelectedMercadoria: (state) => {
      const currentProdutoPartnumber = state.related.model?.mercadoria?.produto?.partnumber;
      if (!_.isEmpty(currentProdutoPartnumber)) {
        const { mercadorias } = state.model;
        const mercadoria = mercadorias.find((item) => item?.produto?.partnumber === currentProdutoPartnumber);

        state.related.model.mercadoria = mercadoria;
      }
    },
    resetModel: (state) => {
      state.model = initialState.model;
    },
    addToList: (state, action) => {
      state.list = [...state.list, action.payload.data];
    },
    updateOnList: (state, action) => {
      state.list = state.list.map((current) => {
        if (current.id === action.payload.data?.id) {
          return action.payload.data;
        }
        return current;
      });
    },
    setRelatedMercadoriaModel: (state, action) => {
      state.related.model.mercadoria = {
        ...action.payload,
      };
    },
    resetRelatedMercadoriaModel: (state) => {
      state.related.model.mercadoria = {};
    },
    setRelatedTransferenciaModel: (state, action) => {
      state.related.model.transferencia = action.payload;
    },
    resetRelatedTransferenciaModel: (state) => {
      state.related.model.transferencia = initialState.related.model.transferencia;
    },
    setRelatedTransferenciaItemModel: (state, action) => {
      state.related.model.transferenciaItem = action.payload;
    },
    resetRelatedTransferenciaItemModel: (state) => {
      state.related.model.transferenciaItem = initialState.related.model.transferenciaItem;
    },
    setRelatedEstornoModel: (state, action) => {
      state.related.model.estorno = action.payload;
    },
    resetRelatedEstornoModel: (state) => {
      state.related.model.estorno = {};
    },
    setRelatedSelectionFaturaList: (state, action) => {
      state.related.list.selectionFatura = action.payload;
    },
    resetRelatedSelectionFaturaList: (state) => {
      state.related.list.selectionFatura = [];
    },
    setRelatedSelectionAvailableItemList: (state, action) => {
      state.related.list.selectionAvailableItem = action.payload;
    },
    resetRelatedSelectionAvailableItemList: (state) => {
      state.related.list.selectionAvailableItem = [];
    },
    setRelatedSelectionItemList: (state, action) => {
      state.related.list.selectionItem = action.payload;
    },
    resetRelatedSelectionItemList: (state) => {
      state.related.list.selectionItem = [];
    },
    addItemToChangedSelectionList: (state, action) => {
      const item = action.payload;

      if (state.related.list.selectionChangedItem?.indexOf(item) === -1) {
        state.related.list.selectionChangedItem = [...state.related.list.selectionChangedItem, item];
      }
    },
    removeItemFromChangedSelectionList: (state, action) => {
      state.related.list.selectionChangedItem = state.related.list.selectionChangedItem?.filter(
        (item) => item !== action.payload
      );
    },
    resetRelatedSelectionChangedItemList: (state) => {
      state.related.list.selectionChangedItem = [];
    },
    setResponse: (state, action) => {
      state.response = action.payload;
    },
    clearResponse: (state) => {
      state.response = {
        status: null,
        message: null,
        data: null,
      };
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    clearError: (state) => {
      state.error = initialState.error;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllAsync.pending, (state) => {
        state.status = ALTERNATIVE_LOADING_STATUS;
      })
      .addCase(fetchAllAsync.fulfilled, (state, action) => {
        state.status = IDLE_STATUS;
        state.list = action.payload.data;
      })
      .addCase(fetchAllAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroCarregarListaFaturas', { erro: action.error.message }),
        };
      })
      .addCase(fetchByIdAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(fetchByIdAsync.fulfilled, (state, action) => {
        if (action.payload.response.status === 200) {
          state.model = {
            ...action.payload.response.data,
          };
          state.status = SUCCESS_STATUS;
        }
      })
      .addCase(fetchByIdAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroCarregarFatura', { erro: action.error.message }),
        };
      })
      .addCase(activateByIdAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(activateByIdAsync.fulfilled, (state, action) => {
        state.status = SUCCESS_STATUS;
        state.response.status = action.payload.response.status;
        state.response.data = action.payload.response.data;

        const { numero } = action.payload.response.data;
        state.response.message = i18n.t('com.muralis.qcx.mensagem.faturaAtivada', { numero });

        if (state.response.status === 200) {
          state.list = state.list.map((current) =>
            current.id === action.payload.response.data.id ? action.payload.response.data : current
          );
        }
      })
      .addCase(activateByIdAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroAtivarFatura', { erro: action.error.message }),
        };
      })
      .addCase(inactivateByIdAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(inactivateByIdAsync.fulfilled, (state, action) => {
        state.status = SUCCESS_STATUS;
        state.response.status = action.payload.response.status;
        state.response.data = action.payload.response.data;

        const { numero } = action.payload.response.data;
        state.response.message = i18n.t('com.muralis.qcx.mensagem.faturaInativada', { numero });

        if (state.response.status === 200) {
          state.list = state.list.map((current) =>
            current.id === action.payload.response.data.id ? action.payload.response.data : current
          );
        }
      })
      .addCase(inactivateByIdAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroCalcularFatura', { erro: action.payload.message }),
        };
      })
      .addCase(calculateByIdAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(calculateByIdAsync.fulfilled, (state, action) => {
        state.status = SUCCESS_STATUS;
        state.response.status = action.payload.response.status;
        state.response.data = action.payload.response.data;

        const requestMetaArg = action.meta.arg;
        const isNaoCalculada = isNaoCalculadaStatus(requestMetaArg?.meta?.calculada);

        const { id, numero } = action.payload.response.data;
        state.response.message = i18n.t('com.muralis.qcx.mensagem.faturaCalculo', {
          id,
          numero,
          estado: isNaoCalculada
            ? i18n.t('com.muralis.qcx.estado.calculada')
            : i18n.t('com.muralis.qcx.estado.recalculada'),
        });

        if (state.response.status === 200) {
          state.list = state.list.map((current) =>
            current.id === action.payload.response.data.id ? action.payload.response.data : current
          );
        }
      })
      .addCase(calculateByIdAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroCalcularFatura', { erro: action.payload.message }),
        };
      });
  },
});

const {
  changeStatusTo,
  loading,
  success,
  failure,
  preparingAction,
  resetStatus,
  changeToCreateMode,
  changeToConsultMode,
  changeToUpdateMode,
  changeToTransferMode,
  changeToReversalMode,
  resetMode,
  changeModeTo,
  changeToBackgroundCreateMode,
  changeToBackgroundDeleteMode,
  changeToBackgroundTransferMode,
  changeToBackgroundReversalMode,
  resetBackgroundMode,
  changeToSubConsultMode,
  changeToSubUpdateMode,
  changeToSubCreateMode,
  resetSubMode,
  setModel,
  setNumero,
  setFollowUp,
  setImportador,
  resetModel,
  addToList,
  updateOnList,
  setRelatedMercadoriaModel,
  resetRelatedMercadoriaModel,
  setRelatedTransferenciaModel,
  resetRelatedTransferenciaModel,
  setRelatedTransferenciaItemModel,
  resetRelatedTransferenciaItemModel,
  setRelatedEstornoModel,
  resetRelatedEstornoModel,
  setRelatedSelectionFaturaList,
  resetRelatedSelectionFaturaList,
  setRelatedSelectionAvailableItemList,
  resetRelatedSelectionAvailableItemList,
  setRelatedSelectionItemList,
  resetRelatedSelectionItemList,
  addItemToChangedSelectionList,
  removeItemFromChangedSelectionList,
  resetRelatedSelectionChangedItemList,
  setResponse,
  setResponseMessage,
  clearResponse,
  setError,
  clearError,
  updateSelectedMercadoria,
} = faturaSlice.actions;

const faturaActions = faturaSlice.actions;

export {
  faturaSlice,
  faturaActions,
  changeStatusTo,
  loading,
  success,
  failure,
  preparingAction,
  resetStatus,
  changeModeTo,
  changeToCreateMode,
  changeToConsultMode,
  changeToUpdateMode,
  changeToTransferMode,
  changeToReversalMode,
  resetMode,
  changeToBackgroundCreateMode,
  changeToBackgroundDeleteMode,
  changeToBackgroundTransferMode,
  changeToBackgroundReversalMode,
  resetBackgroundMode,
  changeToSubConsultMode,
  changeToSubUpdateMode,
  changeToSubCreateMode,
  resetSubMode,
  setModel,
  setNumero,
  setFollowUp,
  setImportador,
  resetModel,
  addToList,
  updateOnList,
  setRelatedMercadoriaModel,
  updateSelectedMercadoria,
  resetRelatedMercadoriaModel,
  setRelatedTransferenciaModel,
  resetRelatedTransferenciaModel,
  setRelatedTransferenciaItemModel,
  resetRelatedTransferenciaItemModel,
  setRelatedEstornoModel,
  resetRelatedEstornoModel,
  setRelatedSelectionFaturaList,
  resetRelatedSelectionFaturaList,
  setRelatedSelectionAvailableItemList,
  resetRelatedSelectionAvailableItemList,
  setRelatedSelectionItemList,
  resetRelatedSelectionItemList,
  addItemToChangedSelectionList,
  removeItemFromChangedSelectionList,
  resetRelatedSelectionChangedItemList,
  setResponse,
  setResponseMessage,
  clearResponse,
  setError,
  clearError,
};

export default faturaSlice.reducer;
