import { useCallback } from 'react';
import EtapaFollowUpUtils from '../../../../utils/general/follow-up/EtapaFollowUpUtils';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core';

// define value type
type Value = {
  active: boolean;
  status?: string;
};

export default function useActiveInactiveStatus() {
  const theme = useTheme();
  const { t } = useTranslation();

  const isActive = useCallback((value: Value) => value?.active, []);
  const isCompleted = useCallback((value: Value) => EtapaFollowUpUtils.isStatusConcluida(value?.status), []);

  const inactivateReactivateButtonTooltipProps = useCallback((value: Value) => {
    if (isActive(value) && !isCompleted(value)) {
      return {
        title: t('com.muralis.qcx.etapa.desativarEtapa'),
      };
    }

    if (!isActive(value) && !isCompleted(value)) {
      return {
        title: t('com.muralis.qcx.etapa.reativarEtapa'),
      };
    }

    return {
      title: t('com.muralis.qcx.etapa.naoPossivelDesativar'),
    };
  }, []);

  const inactivateReactivateButtonColor = useCallback(
    (value: Value) => {
      if (isCompleted(value) && !isActive(value)) {
        return theme.palette.secondary.main;
      }

      if (!isActive(value)) {
        return theme.palette.secondary.main;
      }

      return theme.palette.primary.main;
    },
    [theme]
  );

  return { inactivateReactivateButtonTooltipProps, inactivateReactivateButtonColor, isCompleted, isActive };
}
