import React, {
  useCallback,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Skeleton } from '@mui/material';
import QCXEtapaFollowUpCard from './QCXEtapaFollowUpCard';
import QCXTimelinePaginator from '../../shared-components/paginator/QCXTimelinePaginator';
import useQueryParam from '../../utils/hooks/router/useQueryParam';
import { parseToNumber } from '../../utils/general/parse-utils';
import { isValid } from '../../utils/general/general-utils';

const paginatorOptions = {
  defaultPage: 0,
  actionTemplate: {
    NEXT: 'left',
    PREVIOUS: 'right',
    isNext: (action) => (
      action === paginatorOptions.actionTemplate.NEXT
    ),
    isPrevious: (action) => (
      action === paginatorOptions.actionTemplate.PREVIOUS
    ),
  },
  metadata: {},
};

export default function QCXTimelineFollowUp({
  list,
  loading,
  handler,
  ...restProps
}) {
  const { t } = useTranslation();
  const etapaIdQueryParam = useQueryParam('etapa');

  const etapaIdParameter = useMemo(() => {
    const etapaId = parseToNumber(etapaIdQueryParam);

    return isValid(etapaId)
      ? etapaId
      : undefined;
  }, [etapaIdQueryParam]);

  const controlButtonsPaginatorProps = {
    next: {
      tooltipProps: {
        title: t('com.muralis.qcx.etapa.proximasEtapas'),
      },
    },
    back: {
      tooltipProps: {
        title: t('com.muralis.qcx.etapa.etapasAnteriores'),
      },
    },
  };

  const paginatorProps = useMemo(() => ({
    options: {
      ...paginatorOptions,
      ...(
        etapaIdParameter
          ? {
            metadata: {
              etapa: {
                id: etapaIdParameter,
              },
            },
          }
          : {}
      ),
    },
  }), [
    etapaIdParameter,
    paginatorOptions,
  ]);

  const handleEditEtapaDate = useCallback((values) => {
    handler.alterarDataConclusaoEtapa(values);
  }, [handler]);

  const handleEditEtapaObservations = useCallback((values) => {
    handler.alterarObservacaoEtapa(values);
  }, [handler]);

  const handleInactivateEtapa = useCallback((values) => {
    handler.inactivateEtapa(values);
  }, [handler]);

  const handleReactivateEtapa = useCallback((values) => {
    handler.reactivateEtapa(values);
  }, [handler]);

  return (
    <QCXTimelinePaginator
      list={list}
      paginatorProps={paginatorProps}
      controlButtonsProps={controlButtonsPaginatorProps}
      loading={loading}
      {...restProps}
    >
      {({ value }) => (
        loading
          ? (
            <Skeleton
              variant="rectangular"
              animation="wave"
              width={160}
              height={210}
            />
          ) : (
            <QCXEtapaFollowUpCard
              model={value}
              loading={loading}
              onEditDate={handleEditEtapaDate}
              onEditObservations={handleEditEtapaObservations}
              onInactivate={handleInactivateEtapa}
              onReactivate={handleReactivateEtapa}
            />
          )
      )}
    </QCXTimelinePaginator>
  );
}
