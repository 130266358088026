import { isStrictEquals } from '../general-utils';

const REPORT_DRAFT_DI_DUIMP = 'REPORT_DRAFT_DI_DUIMP';
const REPORT_CALCULO_POR_ADICAO = 'REPORT_CALCULO_POR_ADICAO';
const REPORT_ITENS_ORDEM_DIGITACAO = 'REPORT_ITENS_ORDEM_DIGITACAO';
const RELATORIO_DRAFT_LI = 'RELATORIO_DRAFT_LI';

const isReportDraftDiDuimp = (status) => (
  isStrictEquals(REPORT_DRAFT_DI_DUIMP, status)
);

const isReportCalculoPorAdicao = (status) => (
  isStrictEquals(REPORT_CALCULO_POR_ADICAO, status)
);

const isReportItensPorOrdemDeDigitacao = (status) => (
  isStrictEquals(REPORT_ITENS_ORDEM_DIGITACAO, status)
);

const isRelatorioDraftLi = (status) => (
  isStrictEquals(RELATORIO_DRAFT_LI, status)
);

const ReportUtils = {
  DRAFT_DI_DUIMP: (
    REPORT_DRAFT_DI_DUIMP
  ),
  CALCULO_POR_ADICAO: (
    REPORT_CALCULO_POR_ADICAO
  ),
  ITENS_ORDEM_DIGITACAO: (
    REPORT_ITENS_ORDEM_DIGITACAO
  ),
  DRAFT_LI: (
    RELATORIO_DRAFT_LI
  ),
  isDraftLi: (
    isRelatorioDraftLi
  ),
  isDraftDiDuimp: (
    isReportDraftDiDuimp
  ),
  isCalculoPorAdicao: (
    isReportCalculoPorAdicao
  ),
  isItensPorOrdemDeDigitacao: (
    isReportItensPorOrdemDeDigitacao
  ),
};

export {
  ReportUtils,
  RELATORIO_DRAFT_LI,
  REPORT_DRAFT_DI_DUIMP,
  REPORT_CALCULO_POR_ADICAO,
  REPORT_ITENS_ORDEM_DIGITACAO,
  isReportDraftDiDuimp,
  isReportCalculoPorAdicao,
  isReportItensPorOrdemDeDigitacao,
  isRelatorioDraftLi,
};
