import { Grid } from '@material-ui/core';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { fundamentacaoEspecificaActions } from '../../features/fundamentacao-especifica/fundamentacaoEspecificaSlice';
import { fundamentacaoEspecificaThunks } from '../../features/fundamentacao-especifica/fundamentacaoEspecificaThunks';
import QCXFinalForm from '../../shared-components/final-form/QCXFinalForm';
import QCXFinalMultilineTextField from '../../shared-components/final-text-field/QCXFinalMultilineTextField';
import QCXFinalTextField from '../../shared-components/final-text-field/QCXFinalTextField';
import QCXSelectFundamentacaoEspecificaAutocomplete from '../../shared-components/select-fundamentacao-especifica/QCXSelectFundamentacaoEspecificaAutocomplete';
import QCXSelectTratamentoTributarioAutocomplete from '../../shared-components/select-tratamento-tributario/QCXSelectTratamentoTributarioAutocomplete';
import { required as requiredValidator } from '../../utils/validators/field/validator';

export default function QCXDispositivoLegalForm({
  isConsult,
  isUpdate,
  handleSubmit,
  initialValues,
  handleCancelUpdate,
  handleChangeToUpdate,
  handleChangeToConsult,
  ...restProps
}) {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const handleTratamentoTributarioChange = useCallback((form) => (value) => {
    if (!value) {
      form.change('fundamentacaoEspecifica.id', undefined);
      form.resetFieldState('fundamentacaoEspecifica.id');

      dispatch(
        fundamentacaoEspecificaActions.resetList()
      );

      return;
    }

    dispatch(
      fundamentacaoEspecificaThunks.fetchByFilterAsync([{
        name: 'tratamentoTributario',
        value,
      }])
    );
  }, []);

  const listeners = useMemo(() => ([
    {
      name: 'fundamentacaoEspecifica.tratamentoTributario.id',
      fn: handleTratamentoTributarioChange,
    },
  ]), [handleTratamentoTributarioChange]);

  return (
    <>
      <QCXFinalForm
        isConsult={isConsult}
        isUpdate={isUpdate}
        initialValues={initialValues}
        onChangeListeners={listeners}
        handleSubmitValues={handleSubmit}
        handleChangeToUpdate={handleChangeToUpdate}
        handleCancelUpdate={handleCancelUpdate}
        {...restProps}
      >
        {() => (
          <>
            {(isConsult || isUpdate) && (
              <Grid
                item
                xs={12}
                sm={2}
                md={2}
              >
                <QCXFinalTextField
                  id="text-field-code"
                  key="text-field-code"
                  name="id"
                  label={t('com.muralis.qcx.codigo')}
                  disabled
                />
              </Grid>
            )}
            <Grid
              item
              xs={12}
              {...(isConsult || isUpdate
                ? {
                  sm: 10,
                  md: 10,
                } : {
                  sm: 12,
                  md: 12,
                }
              )}
            >
              <QCXFinalTextField
                id="text-field-descricao"
                key="text-field-descricao"
                name="description"
                label={t('com.muralis.qcx.descricao')}
                maxLength={100}
                validate={requiredValidator}
                disabled={isConsult}
                required
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={3}
            >
              <QCXSelectTratamentoTributarioAutocomplete
                id="autocomplete-select-tratamento-tributario-field"
                key="autocomplete-select-tratamento-tributario-field"
                name="fundamentacaoEspecifica.tratamentoTributario.id"
                label={t('com.muralis.qcx.tratamentoTributario')}
                fieldProps={{ validate: requiredValidator }}
                disabled={isConsult}
                required
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={9}
            >
              <QCXSelectFundamentacaoEspecificaAutocomplete
                id="autocomplete-select-fundamentacao-especifica-field"
                key="autocomplete-select-fundamentacao-especifica-field"
                name="fundamentacaoEspecifica.id"
                label={t('com.muralis.qcx.fundamentacaoEspecifica')}
                fieldProps={{ validate: requiredValidator }}
                disabled={isConsult}
                required
              />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <QCXFinalMultilineTextField
                id="text-observacoes-multiline-field"
                key="text-observacoes-multiline-field"
                name="observacoes"
                label={t('com.muralis.qcx.observacoes')}
                rows={4}
                maxLength={300}
                disabled={isConsult}
                remaningCharactersInfo
                required
              />
            </Grid>
          </>
        )}
      </QCXFinalForm>
    </>
  );
}
