import { IconButton, Tooltip } from '@material-ui/core';
import {
  Delete as DeleteIcon, DirectionsBoat,
  Search as PageviewIcon, CheckCircle,
} from '@material-ui/icons';
import _ from 'lodash';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { userHasDeleteRole } from '../../../../../components/perfil/perfil-utils';
import UsuarioContext from '../../../../../contexts/registrations/usuario/UsuarioContext';
import {
  activateByIdAsync, changeToConsultMode,
  changeToCreateMode,
  fetchAllAsync, inactivateByIdAsync, resetBackgroundMode,
  resetMode,
  resetModel, selectUsuarios,
} from '../../../../../features/usuario/usuarioSlice';
import QCXSimpleConsultPageTemplate from '../../../../../templates/simple-consult-page/QCXSimpleConsultPageTemplate';
import { formatCpf } from '../../../../../utils/hooks/form/field/formatters';
import { isAlternativeLoadingStatus } from '../../../../../utils/store/store-utils';

export default function UsuarioConsultPage({ authInfo }) {
  const { t } = useTranslation();

  const history = useHistory();
  const dispatch = useDispatch();

  const {
    status,
  } = useContext(UsuarioContext);

  const usuarios = useSelector(selectUsuarios);

  const isAlternativeLoading = useMemo(() => (
    isAlternativeLoadingStatus(status)
  ), [status]);

  const fetchAll = useCallback(() => {
    dispatch(fetchAllAsync());
  }, []);

  const resetModes = useCallback(() => {
    dispatch(resetMode());
    dispatch(resetBackgroundMode());
  }, []);

  const resetModels = useCallback(() => {
    dispatch(resetModel());
  }, []);

  const resetDependencies = useCallback(() => {
  }, []);

  useEffect(() => {
    fetchAll();
  }, []);

  useEffect(() => {
    fetchAll();
    resetModes();
    resetModels();
    resetDependencies();
  }, []);

  const breadcrumbs = [
    {
      link: {
        to: '/',
        name: t('com.muralis.qcx.inicio'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.cadastros'),
      },
    },
    {
      link: {
        to: t('com.muralis.qcx.url.moduloCadastrosGerais'),
        name: t('com.muralis.qcx.gerais'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.usuarios'),
      },
    },
  ];
  const handleActivateInactivate = async (event, id) => {
    event.preventDefault();

    const { active } = usuarios.find(
      (item) => item.id === id
    );

    const isActivation = !active;

    if (isActivation) {
      dispatch(activateByIdAsync(id));
    } else {
      dispatch(inactivateByIdAsync(id));
    }
  };

  const handleClickOnButtonNew = useCallback((event) => {
    event.stopPropagation();
    dispatch(changeToCreateMode());
  }, []);

  const handleClickOnEditButton = useCallback((event, id) => {
    event.stopPropagation();
    dispatch(changeToConsultMode());
    history.push(t('com.muralis.qcx.url.moduloGeraisUsuarioDetalhes', { id }));
  }, [history]);

  const columns = [
    {
      field: 'firstName',
      headerName: t('com.muralis.qcx.nome'),
      type: 'string',
      flex: 180,
      valueGetter: ({ row }) => (
        row?.nome
      ),
    },
    {
      field: 'cpf',
      headerName: t('com.muralis.qcx.CPF'),
      type: 'string',
      flex: 120,
      valueGetter: ({ row }) => {
        const cpf = _.get(row, 'attributes.cpf[0]');
        return cpf ? formatCpf(cpf) : '';
      },
    },
    {
      field: 'email',
      headerName: t('com.muralis.qcx.email'),
      type: 'string',
      flex: 170,
      valueGetter: ({ row }) => (
        row?.email
      ),
    },
    {
      field: 'active',
      headerName: t('com.muralis.qcx.estado.ativo'),
      type: 'boolean',
      flex: 135,
    },
    {
      field: 'activeFilter',
      headerName: t('com.muralis.qcx.estado.ativo'),
      flex: 135,
      hide: true,
      valueGetter: ({ row }) => (
        row?.active ? t('com.muralis.qcx.estado.eAtivo').toLowerCase() : t('com.muralis.qcx.estado.naoAtivo').toLowerCase()
      ),
    },

    {
      field: 'id',
      headerName: t('com.muralis.qcx.acoes.label'),
      headerAlign: 'center',
      align: 'center',
      flex: 150,
      renderCell: ({ row: { id, active } }) => (
        <>
          {userHasDeleteRole(authInfo, 'usuario') && (
            <Tooltip arrow title={active ? t('com.muralis.qcx.usuario.inativarUsuario') : t('com.muralis.qcx.usuario.ativarUsuario')} aria-label="sair">
              <IconButton
                key={`stw-active${id}`}
                value={active}
                name={`stw-active-${id}`}
                onClick={(event) => handleActivateInactivate(event, id)}
              >
                {active && <DeleteIcon color="error" />}
                {!active && <CheckCircle style={{ color: 'green' }} />}
              </IconButton>
            </Tooltip>
          )}
          <IconButton
            key={`btn-view-${id}`}
            name={`btn-view-${id}`}
            onClick={(event) => handleClickOnEditButton(event, id)}
          >
            <PageviewIcon color="primary" />
          </IconButton>
        </>
      ),
    }
  ];

  const templateProps = {
    page: {
      title: t('com.muralis.qcx.usuario.labelPlural'),
      icon: <DirectionsBoat />,
      breadcrumbs,
    },
    control: {
      buttonNew: {
        description: t('com.muralis.qcx.usuario.novoUsuario'),
        path: t('com.muralis.qcx.url.moduloGeraisUsuarioNovo'),
        onClick: handleClickOnButtonNew,
      },
    },
    table: {
      columns,
    },
  };

  const filterPropGetter = useCallback(
    (usuario) => usuario?.nome,
    []
  );

  return (
    <QCXSimpleConsultPageTemplate
      pageProps={templateProps.page}
      controlProps={templateProps.control}
      tableProps={templateProps.table}
      tableData={usuarios}
      isLoading={isAlternativeLoading}
      filterPropGetter={filterPropGetter}
      authInfo={authInfo}
      requiredRoles={['usuario']}
      showInactiveRows
    />
  );
}
