/* eslint-disable import/extensions */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { omit } from 'lodash';
import { DEFAULT_TIPO_DUE_PARAMETER } from '../../utils/general/fatura/faturaUtils';
import { faturaAPI } from '../fatura/faturaAPI';
import { finishLoading, startLoading } from '../application-state/applicationStateSlice';

const fetchAllFromFaturaAsync = createAsyncThunk(
  'declaracaoUnicaExportacao/fetchAllFromFatura',
  async (_, { dispatch }) => {
    dispatch(startLoading());

    const { data, status } = await faturaAPI.fetchByFilter([DEFAULT_TIPO_DUE_PARAMETER]);

    if (status === 200) {
      dispatch(finishLoading());
    }

    return { data };
  }
);

const fetchByIdFromFaturaAsync = createAsyncThunk('declaracaoUnicaExportacao/fetchByIdFromFatura', async (data) => {
  const response = await faturaAPI.fetchById(data);
  return {
    response: {
      status: response.status,
      data: response.data,
    },
  };
});

const activateByIdFromFaturaAsync = createAsyncThunk('declaracaoUnicaExportacao/activateByIdFromFatura', async (id) => {
  const { status, data } = await faturaAPI.activateById(id);
  return { response: { status, data } };
});

const inactivateByIdFromFaturaAsync = createAsyncThunk(
  'declaracaoUnicaExportacao/inactivateByIdFromFatura',
  async (id) => {
    const { status, data } = await faturaAPI.inactivateById(id);
    return { response: { status, data } };
  }
);

const calculaDUEAposZerarCamposAsync = createAsyncThunk(
  'declaracaoUnicaExportacao/calculaDueZerandoCampos',
  async (data, { rejectWithValue }) => {
    try {
      const response = await faturaAPI.calculateById(data?.id, [], { shouldZeroFields: true });

      return {
        response: {
          status: response?.status,
          data: response?.data,
          ...omit(response, ['request', 'config']),
        },
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const calculateByIdFromFaturaAsync = createAsyncThunk(
  'declaracaoUnicaExportacao/calculateByIdFromFatura',
  async (data, { rejectWithValue }) => {
    try {
      const response = await faturaAPI.calculateById(data?.id);
      return {
        response: {
          status: response?.status,
          data: response?.data,
          ...omit(response, ['request', 'config']),
        },
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const conferirByIdFromFaturaAsync = createAsyncThunk(
  'declaracaoUnicaExportacao/conferirByIdFromFatura',
  async (data, { rejectWithValue }) => {
    try {
      const response = await faturaAPI.conferirBy(data?.id);

      return {
        response: {
          status: response?.status,
          data: response?.data,
          ...omit(response, ['request', 'config']),
        },
      };
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

const liberarByIdFromFaturaAsync = createAsyncThunk(
  'declaracaoUnicaExportacao/liberarByIdFromFatura',
  async (data, { rejectWithValue }) => {
    try {
      const response = await faturaAPI.liberarBy(data?.id);
      return {
        response: {
          status: response?.status,
          data: response?.data,
          ...omit(response, ['request', 'config']),
        },
      };
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

const reabrirByIdAsync = createAsyncThunk(
  'declaracaoUnicaExportacao/reabrirById',
  async (data, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());

      const response = await faturaAPI.reabrirById(data?.id);

      if (response?.status === 200) {
        dispatch(finishLoading());
        dispatch(fetchAllFromFaturaAsync());
      }

      return {
        response: {
          status: response?.status,
          data: response?.data,
          ...omit(response, ['request', 'config']),
        },
      };
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

const retificarByIdFromFaturaAsync = createAsyncThunk(
  'declaracaoUnicaExportacao/retificarByIdFromFatura',
  async (data, { rejectWithValue }) => {
    try {
      const response = await faturaAPI.retificarById(data?.id, [], { motivo: data?.motivo });
      return {
        response: {
          status: response?.status,
          data: response?.data,
          ...omit(response, ['request', 'config']),
        },
      };
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

const duplicarByIdFromFaturaAsync = createAsyncThunk(
  'declaracaoUnicaExportacao/duplicarById',
  async (data, { rejectWithValue }) => {
    try {
      const response = await faturaAPI.duplicarBy(data?.id, [], data?.payload);

      return {
        response: {
          status: response?.status,
          data: response?.data,
          ...omit(response, ['request', 'config']),
        },
      };
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

const declaracaoUnicaExportacaoThunks = {
  fetchAllFromFaturaAsync,
  fetchByIdFromFaturaAsync,
  activateByIdFromFaturaAsync,
  inactivateByIdFromFaturaAsync,
  calculateByIdFromFaturaAsync,
  conferirByIdFromFaturaAsync,
  liberarByIdFromFaturaAsync,
  duplicarByIdFromFaturaAsync,
  retificarByIdFromFaturaAsync,
  calculaDUEAposZerarCamposAsync,
  reabrirByIdAsync,
};

export {
  activateByIdFromFaturaAsync,
  calculaDUEAposZerarCamposAsync,
  calculateByIdFromFaturaAsync,
  conferirByIdFromFaturaAsync,
  declaracaoUnicaExportacaoThunks,
  duplicarByIdFromFaturaAsync,
  fetchAllFromFaturaAsync,
  fetchByIdFromFaturaAsync,
  inactivateByIdFromFaturaAsync,
  liberarByIdFromFaturaAsync,
  reabrirByIdAsync,
  retificarByIdFromFaturaAsync,
};
