import { isEmpty, isFunction } from 'lodash';
import debounce from 'lodash/debounce';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { validate, v4 as uuid } from 'uuid';
import { clienteActions } from '../../../../../features/cliente/clienteSlice';
import { IDLE_CONTROL } from '../../../../../features/config-control';
import { servicoActions } from '../../../../../features/servico/servicoSlice';
import { transportadoraActions } from '../../../../../features/transportadora/transportadoraSlice';
import QCXConfirmDialog from '../../../../../shared-components/dialog/QCXConfirmDialog';
import QCXRegistrationFormPageTemplate from '../../../../../templates/registration-form-page/QCXRegistrationFormPageTemplate';
import useOperationConfirm from '../../../../../utils/hooks/operation/confirm/useOperationConfirm';
import {
  isBackgroundCreateMode,
  isBackgroundDeleteMode,
  isBackgroundUpdateMode,
  isConsultMode,
  isCreateMode,
  isFailureStatus,
  isIdleStatus,
  isLoadingStatus,
  isLockedMode,
  isNoneMode,
  isPreparingActionStatus,
  isUpdateMode,
} from '../../../../../utils/store/store-utils';
import * as dadosEmbarqueAPI from '../api/dadosEmbarqueAPI';
import { selectBackgroundMode, selectDadosEmbarque, selectMode, selectStatus } from '../api/dadosEmbarqueSelectors';
import {
  changeToBackgroundCreateMode,
  changeToBackgroundDeleteMode,
  changeToConsultMode,
  changeToCreateMode,
  changeToUpdateMode,
  failure,
  loading,
  preparingAction,
  resetBackgroundMode,
  resetModel,
  setError,
  setModel,
  setResponse,
  success,
  updateOnList,
} from '../api/dadosEmbarqueSlice';
import { fetchByIdAsync } from '../api/dadosEmbarqueThunks';
import QCXDadosEmbarqueWizardFinalForm from './components/QCXDadosEmbarqueWizardFinalForm';

export default function ExportacaoDadosEmbarqueRegistrationPage({ authInfo = {} }) {
  const { t } = useTranslation();
  const params = useParams();
  const { id } = params;

  const dispatch = useDispatch();

  const fetchById = useCallback(() => {
    dispatch(fetchByIdAsync(id));
  }, []);

  useEffect(() => {
    if (id !== undefined) {
      dispatch(changeToConsultMode());
      fetchById();
    } else {
      dispatch(changeToCreateMode());
    }
  }, [id]);

  const dadosEmbarque = useSelector(selectDadosEmbarque);

  const unnormalize = useCallback((unnormalizedDadosEmbarque) => {
    const camposAdicionaisFromServico = unnormalizedDadosEmbarque?.followUp?.servico?.camposAdicionais;

    const unnormalizedCamposAdicionais = unnormalizedDadosEmbarque?.dadosEmbarqueCamposAdicionais
      ? unnormalizedDadosEmbarque?.dadosEmbarqueCamposAdicionais?.map((ca) => ({
          ...ca,
          camposAdicionais: { ...camposAdicionaisFromServico?.find((e) => e.id === ca.camposAdicionais.id) },
        }))
      : camposAdicionaisFromServico?.map((ca) => ({ camposAdicionais: { ...ca }, valor: '' }));

    const unnormalizedFollowup = {
      ...unnormalizedDadosEmbarque?.followUp,
      numeroConhecimentoHouse: unnormalizedDadosEmbarque?.followUp?.numeroConhecimentoHouse ?? '-',
      numeroConhecimentoMaster: unnormalizedDadosEmbarque?.followUp?.numeroConhecimentoMaster ?? '-',
      referencias: unnormalizedDadosEmbarque?.followUp?.referencias?.map((r) => ({ id: uuid(), referencia: r })) ?? [],
    };

    return {
      ...unnormalizedDadosEmbarque,
      camposAdicionais: unnormalizedCamposAdicionais,
      followUp: unnormalizedFollowup,
      ignorableFields: {
        flags: {
          desova: unnormalizedDadosEmbarque?.tipoDesova !== undefined ?? false,
          necessidadeLi: unnormalizedDadosEmbarque?.orgaoAnuente?.length > 0 ?? false,
        },
      },
    };
  }, []);

  const model = useMemo(() => unnormalize(dadosEmbarque), [dadosEmbarque]);

  const history = useHistory();

  const status = useSelector(selectStatus);
  const mode = useSelector(selectMode);
  const backgroundMode = useSelector(selectBackgroundMode);

  const isPreparingAction = useMemo(() => isPreparingActionStatus(status), [status]);

  const isLoading = useMemo(() => isLoadingStatus(status), [status]);

  const isIdle = useMemo(() => isIdleStatus(status), [status]);

  const isFailure = useMemo(() => isFailureStatus(status), [status]);

  const isNone = useMemo(() => isNoneMode(mode), [mode]);

  const isCreate = useMemo(() => isCreateMode(mode), [mode]);

  const isConsult = useMemo(() => isConsultMode(mode), [mode]);

  const isUpdate = useMemo(() => isUpdateMode(mode), [mode]);

  const isLocked = useMemo(() => isLockedMode(mode), [mode]);

  const isBackgroundCreate = useMemo(() => isBackgroundCreateMode(backgroundMode), [backgroundMode]);

  const isBackgroundUpdate = useMemo(() => isBackgroundUpdateMode(backgroundMode), [backgroundMode]);

  const isBackgroundDelete = useMemo(() => isBackgroundDeleteMode(backgroundMode), [backgroundMode]);

  const handleChangeToPreparingAction = useCallback(() => {
    dispatch(preparingAction());
  }, []);

  const handleChangeToCreate = useCallback(() => {
    dispatch(changeToCreateMode());
  }, []);

  const handleChangeToBackgroundCreate = useCallback(() => {
    dispatch(changeToBackgroundCreateMode());
  }, []);

  const handleChangeToBackgroundDelete = useCallback(() => {
    dispatch(changeToBackgroundDeleteMode());
  }, []);

  const handleChangeToConsult = useCallback(() => {
    dispatch(changeToConsultMode());
  }, []);

  const handleChangeToUpdate = useCallback((additional = {}) => {
    dispatch(changeToUpdateMode());

    if (!isEmpty(additional) && isFunction(additional?.callback)) {
      additional.callback();
    }
  }, []);

  const handleResetBackgroundMode = useCallback(() => {
    dispatch(resetBackgroundMode());
  }, []);

  const handleBasicCancelUpdate = useCallback(
    (additional = {}) => {
      const currentId = isBackgroundCreate ? dadosEmbarque?.id : params?.id;

      if (currentId) {
        fetchById(currentId);
      }

      handleChangeToConsult();

      if (!isEmpty(additional) && isFunction(additional?.callback)) {
        additional.callback();
      }
    },
    [params, dadosEmbarque, isBackgroundCreate, fetchById, handleChangeToConsult]
  );

  const operationOfCancelUpdateOptions = useMemo(
    () => ({
      title: t('com.muralis.qcx.aviso').toUpperCase(),
      message: t('com.muralis.qcx.mensagem.alteracoesRealizadasSeraoPerdidas'),
      endMessage: t('com.muralis.qcx.mensagem.certezaDesejaCancelar'),
    }),
    []
  );

  const [handleCancelUpdate, operationOfCancelUpdate] = useOperationConfirm(
    handleBasicCancelUpdate,
    operationOfCancelUpdateOptions,
    [handleBasicCancelUpdate, operationOfCancelUpdateOptions]
  );

  const normalize = useCallback(
    (unnormalizedData) => {
      const { ignorableFields, containers, camposAdicionais, followUp, ...data } = unnormalizedData;

      const normalizedContainers = containers?.map((c) => (validate(c?.id) ? { ...c, id: null } : c));
      const normalizedCamposAdicionais = camposAdicionais?.map((ca) => ({
        ...ca,
        camposAdicionais: { id: ca?.camposAdicionais?.id },
      }));

      return {
        ...dadosEmbarque,
        ...data,
        containers: normalizedContainers,
        followUp: { id: followUp.id },
        dadosEmbarqueCamposAdicionais: normalizedCamposAdicionais,
      };
    },
    [dadosEmbarque]
  );

  const handleDispatchSetModel = useCallback(
    (data) => {
      const normalizedData = unnormalize(data);

      dispatch(setModel(normalizedData));
    },
    [normalize]
  );

  const create = useCallback(
    async (data, next) => {
      const executeDebounced = debounce(async () => {
        try {
          const response = await dadosEmbarqueAPI.register(data);

          if (response?.status === 201) {
            const created = response?.data;

            next();
            dispatch(success());
            dispatch(
              setResponse({
                status: response.status,
                data: created,
                message: t('com.muralis.qcx.mensagem.followUpRegistrado', { numero: created?.numero }),
              })
            );
            dispatch(setModel(created));
          }
        } catch (error) {
          dispatch(setModel(data));
          dispatch(changeToBackgroundCreateMode());

          const responseErrorMessage =
            error?.response && error?.response?.data && error?.response?.data?.message
              ? error?.response?.data?.message
              : undefined;

          if (!responseErrorMessage) {
            dispatch(failure());
            dispatch(
              setError({
                message: t('com.muralis.qcx.erro.erroRegistrarFollowUp'),
              })
            );
          }
        }
      }, 500);

      dispatch(loading());
      executeDebounced();
    },
    [history]
  );

  const update = useCallback(
    async (data, next, step) => {
      const executeDebounced = debounce(async () => {
        try {
          const response = await dadosEmbarqueAPI.save(data);

          if (response?.status === 200) {
            const handleResultWithDebounce = debounce(() => {
              if (!isCreate) {
                handleChangeToConsult();
              }
              dispatch(success());

              const saved = response?.data;

              dispatch(
                setResponse({
                  status: response.status,
                  data: saved,
                  message: t('com.muralis.qcx.mensagem.followUpSalvo', { numero: data?.numero }),
                })
              );

              dispatch(setModel(saved));
              dispatch(updateOnList({ data: saved }));

              if (step === 2) {
                dispatch(resetModel());
                history.push('/Exportacao/dados-embarque');
              } else {
                next();
              }
            }, 500);

            handleResultWithDebounce();
          }
        } catch (error) {
          const responseErrorMessage =
            error?.response && error?.response?.data && error?.response?.data?.message
              ? error?.response?.data?.message
              : undefined;

          if (!responseErrorMessage) {
            dispatch(failure());
            dispatch(
              setError({
                message: t('com.muralis.qcx.erro.erroSalvarFollowUp'),
              })
            );

            return;
          }

          const fullErrorMessage = t('com.muralis.qcx.erro.erroSalvarFollowUpEspecifico', {
            mensagem: responseErrorMessage,
          });

          dispatch(failure());
          dispatch(
            setError({
              message: fullErrorMessage,
            })
          );
        }
      }, 500);

      dispatch(loading());
      executeDebounced();
    },
    [handleChangeToConsult]
  );

  const handleSubmit = useCallback(
    async (data, step, next) => {
      const normalizedData = normalize(data);

      if ((isUpdate && !isBackgroundCreate) || isBackgroundUpdate || step !== 0) {
        await update(normalizedData, next, step);

        return;
      }

      if (isCreate || isBackgroundCreate) {
        await create(normalizedData, next);
      }
    },
    [isCreate, isUpdate, isBackgroundCreate, isBackgroundUpdate, normalize, update, create]
  );

  const actionName = useMemo(() => {
    if (isCreate || isBackgroundCreate || isNone) return t('com.muralis.qcx.acoes.novo');
    if (isConsult) return t('com.muralis.qcx.acoes.visualizar');
    return t('com.muralis.qcx.acoes.alterar');
  }, [isNone, isCreate, isConsult, isBackgroundCreate]);

  const breadcrumbs = useMemo(
    () => [
      {
        link: {
          to: '/',
          name: t('com.muralis.qcx.inicio'),
        },
      },
      {
        link: {
          to: t('com.muralis.qcx.url.moduloExportacao'),
          name: t('com.muralis.qcx.exportacao.label'),
        },
      },
      {
        link: {
          to: t('com.muralis.qcx.url.exportacaoDadosEmbarque'),
          name: t('com.muralis.qcx.dadosEmbarque.label'),
        },
      },
      {
        text: {
          name: t('com.muralis.qcx.registro'),
        },
      },
      {
        text: {
          name: actionName,
        },
      },
    ],
    [isCreate, actionName]
  );

  const pageTitle = useMemo(
    () =>
      isNone || isCreate || isBackgroundCreate
        ? t('com.muralis.qcx.novoRegistro')
        : t('com.muralis.qcx.dadosEmbarque.label'),
    [isNone, isCreate, isBackgroundCreate, model]
  );

  // Não tenho certeza do que isso estava fazendo
  // useAutoChangeMode((currentMode) => (
  //   dispatch(changeModeTo(currentMode))
  // ), [isUpdate]);

  useEffect(() => {
    const configureDependencies = () => {
      dispatch(clienteActions.changeControlTo(IDLE_CONTROL));
      dispatch(servicoActions.changeControlTo(IDLE_CONTROL));
      dispatch(transportadoraActions.changeControlTo(IDLE_CONTROL));
    };

    configureDependencies();

    return () => {
      dispatch(clienteActions.resetControl());
      dispatch(servicoActions.resetControl());
      dispatch(transportadoraActions.resetControl());
    };
  }, []);

  useEffect(() => {
    const handleFetchById = () => {
      if (!isEmpty(params?.id)) {
        fetchById(params?.id);

        handleChangeToConsult();
      }
    };

    handleFetchById();
  }, [params, handleChangeToConsult]);

  const refreshSelectedModel = useCallback(() => {
    if (!isEmpty(dadosEmbarque) && dadosEmbarque?.id) {
      fetchById(dadosEmbarque?.id);
    }
  }, [dadosEmbarque, isUpdate, fetchById, handleChangeToConsult]);

  return (
    <QCXRegistrationFormPageTemplate
      pageTitle={pageTitle}
      breadcrumbs={breadcrumbs}
      isIdle={isIdle}
      isLoading={isLoading}
      isFailure={isFailure}
      isCreate={isCreate}
      isConsult={isConsult}
      isUpdate={isUpdate}
      isLocked={isLocked}
      isPreparingAction={isPreparingAction}
      isBackgroundCreate={isBackgroundCreate}
      isBackgroundDelete={isBackgroundDelete}
      handleChangeToPreparingAction={handleChangeToPreparingAction}
      handleChangeToCreate={handleChangeToCreate}
      handleChangeToBackgroundCreate={handleChangeToBackgroundCreate}
      handleChangeToBackgroundDelete={handleChangeToBackgroundDelete}
      handleChangeToConsult={handleChangeToConsult}
      handleChangeToUpdate={handleChangeToUpdate}
      handleCancelUpdate={handleCancelUpdate}
      handleResetBackgroundMode={handleResetBackgroundMode}
      authInfo={authInfo}
    >
      {(formProps) => (
        <>
          <QCXDadosEmbarqueWizardFinalForm
            model={model}
            handleChangeModel={handleDispatchSetModel}
            handleSubmit={handleSubmit}
            refreshSelectedModel={refreshSelectedModel}
            authInfo={authInfo}
            requiredRoles={[]}
            {...formProps}
          >
            <QCXConfirmDialog
              open={operationOfCancelUpdate?.active}
              title={operationOfCancelUpdate?.title}
              content={operationOfCancelUpdate?.message}
              endContent={operationOfCancelUpdate?.endMessage}
              onConfirm={operationOfCancelUpdate.confirm}
              onClose={operationOfCancelUpdate?.reset}
              buttonGroupOptions={{
                confirm: {
                  description: t('com.muralis.qcx.expressao.sim'),
                },
                cancel: {
                  description: t('com.muralis.qcx.expressao.nao'),
                },
              }}
            />
          </QCXDadosEmbarqueWizardFinalForm>
          <QCXConfirmDialog
            open={operationOfCancelUpdate?.active}
            title={operationOfCancelUpdate?.title}
            content={operationOfCancelUpdate?.message}
            endContent={operationOfCancelUpdate?.endMessage}
            onConfirm={operationOfCancelUpdate.confirm}
            onClose={operationOfCancelUpdate?.reset}
            buttonGroupOptions={{
              confirm: {
                description: t('com.muralis.qcx.expressao.sim'),
              },
              cancel: {
                description: t('com.muralis.qcx.expressao.nao'),
              },
            }}
          />
        </>
      )}
    </QCXRegistrationFormPageTemplate>
  );
}
