import { MatcherConfigureUtils } from '../../utils/general/general-utils';

const TIPO_DOCUMENTO_FISCAL_NOTA_FISCAL_FORMULARIO_CODE = 'TDF01';
const TIPO_DOCUMENTO_FISCAL_NOTA_FISCAL_ELETRONICA_CODE = 'TDF02';
const TIPO_DOCUMENTO_FISCAL_SEM_NOTA_FISCAL_CODE = 'TDF03';

const isTipoDocumentoFiscalNotaFiscalFormulario = (
  MatcherConfigureUtils.getSpecificByCode(
    TIPO_DOCUMENTO_FISCAL_NOTA_FISCAL_FORMULARIO_CODE
  )
);

const isTipoDocumentoFiscalNotaFiscalEletronica = (
  MatcherConfigureUtils.getSpecificByCode(
    TIPO_DOCUMENTO_FISCAL_NOTA_FISCAL_ELETRONICA_CODE
  )
);

const isTipoDocumentoFiscalSemNotaFiscal = (
  MatcherConfigureUtils.getSpecificByCode(
    TIPO_DOCUMENTO_FISCAL_SEM_NOTA_FISCAL_CODE
  )
);

const SituacaoEspecialDespachoUtils = Object.freeze({
  NOTA_FISCAL_FORMULARIO: (
    TIPO_DOCUMENTO_FISCAL_NOTA_FISCAL_FORMULARIO_CODE
  ),
  NOTA_FISCAL_ELETRONICA: (
    TIPO_DOCUMENTO_FISCAL_NOTA_FISCAL_ELETRONICA_CODE
  ),
  SEM_NOTA_FISCAL: (
    TIPO_DOCUMENTO_FISCAL_SEM_NOTA_FISCAL_CODE
  ),
  isNotaFiscalFormulario: (
    isTipoDocumentoFiscalNotaFiscalFormulario
  ),
  isNotaFiscalEletronica: (
    isTipoDocumentoFiscalNotaFiscalEletronica
  ),
  isSemNotaFiscal: (
    isTipoDocumentoFiscalSemNotaFiscal
  ),
});

export default SituacaoEspecialDespachoUtils;
