import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  ALTERNATIVE_LOADING_STATUS,
  FAILURE_STATUS,
  IDLE_STATUS,
  LOADING_STATUS,
  SUCCESS_STATUS,
} from '../status';
import {
  fetchAll,
} from './tipoDeclaracaoAPI';
import i18n from '../../i18n';

const initialState = {
  status: IDLE_STATUS,
  error: null,
  response: {
    status: null,
    message: null,
    data: null,
  },
  list: [],
  model: {},
};

const fetchAllAsync = createAsyncThunk(
  'tipoDeclaracao/fetchAll',
  async () => {
    const { data } = await fetchAll();
    return { data };
  }
);

const tipoDeclaracaoSlice = createSlice({
  name: 'tipoDeclaracao',
  initialState,
  reducers: {
    changeStatusTo: (state, action) => {
      state.status = action.payload.status;
    },
    loading: (state) => {
      state.status = LOADING_STATUS;
    },
    success: (state) => {
      state.status = SUCCESS_STATUS;
    },
    failure: (state) => {
      state.status = FAILURE_STATUS;
    },
    resetStatus: (state) => {
      state.status = IDLE_STATUS;
    },
    resetList: (state) => {
      state.list = [];
    },
    setResponse: (state, action) => {
      state.response = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    clearResponse: (state) => {
      state.response = {
        status: null,
        message: null,
        data: null,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllAsync.pending, (state) => {
        state.status = ALTERNATIVE_LOADING_STATUS;
      })
      .addCase(fetchAllAsync.fulfilled, (state, action) => {
        state.status = IDLE_STATUS;
        state.list = action.payload.data;
      })
      .addCase(fetchAllAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.carregarListaTiposDeclaracao', { erro: action.error.message }),
        };
      });
  },
});

const {
  changeStatusTo,
  loading,
  success,
  failure,
  resetStatus,
  resetList,
  setResponse,
  setResponseMessage,
  clearResponse,
  setError,
} = tipoDeclaracaoSlice.actions;

const selectTiposDeclaracao = (state) => state.tipoDeclaracao.list;
const selectStatus = (state) => state.tipoDeclaracao.status;
const selectError = (state) => state.tipoDeclaracao.error;
const selectResponse = (state) => state.tipoDeclaracao.response;

export {
  tipoDeclaracaoSlice,
  changeStatusTo,
  loading,
  success,
  failure,
  resetStatus,
  resetList,
  setResponse,
  setResponseMessage,
  clearResponse,
  setError,
  fetchAllAsync,
  selectTiposDeclaracao,
  selectStatus,
  selectError,
  selectResponse,
};

export default tipoDeclaracaoSlice.reducer;
