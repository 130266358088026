import _ from 'lodash';
import { useEffect, useState, useCallback, useMemo } from 'react';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import QCXFinalFormDialog from '../../shared-components/dialog/QCXFinalFormDialog';
import QCXFormListenerManager from '../../shared-components/form-listener/QCXFormListenerManager';
import QCXFinalRadioField from '../../shared-components/final-radio-field/QCXFinalRadioField';
import QCXRelatoriosContasPagarForm from './QCXRelatoriosContasPagarForm';
import QCXRelatoriosContasReceberForm from './QCXRelatoriosContasReceberForm';

import { CLOSED_STATUS, isOpenStatus } from '../../utils/hooks/form/dialog/formDialogUtils';
import ContasPagarUtils from '../../utils/general/contas-pagar/ContasPagarUtils';
import ContasReceberUtils from '../../utils/general/contas-receber/ContasReceberUtils';

export default function QCXRelatoriosContasDialogForm({
  handleOnSubmit,
  handleFormStatus,
  formDialogStatus,
  initialValues,
  typeReport = '',
}) {
  const [tipoRelatorio, setTipoRelatorio] = useState('');

  const { t } = useTranslation();

  const onClickTipoRelatorio = (event) => {
    const valueInput = event.target.value;
    setTipoRelatorio(valueInput);
  };

  useEffect(() => {
    setTipoRelatorio(initialValues?.tipo);
  }, [initialValues]);

  const isFormDialogOpen = useMemo(() => isOpenStatus(formDialogStatus), [formDialogStatus]);

  const handleCloseFormDialogNew = useCallback(() => {
    handleFormStatus(CLOSED_STATUS);
    _.debounce(() => {}, 200)();
  }, [handleFormStatus, setTipoRelatorio]);

  return (
    <QCXFinalFormDialog
      key="form-dialog-relatorio-conta-pagar-receber"
      id="form-dialog-relatorio-conta-pagar-receber"
      title={t('com.muralis.qcx.relatorios.label')}
      dialogActionsProps={{
        button: {
          submit: {
            description: t('com.muralis.qcx.acoes.imprimir'),
            size: 'small',
            color: 'secondary',
          },
          cancel: {
            description: t('com.muralis.qcx.acoes.cancelar'),
            size: 'small',
          },
        },
      }}
      open={isFormDialogOpen}
      initialValues={initialValues}
      onSubmit={handleOnSubmit}
      onClose={handleCloseFormDialogNew}
    >
      {({ values, form }) => (
        <>
          <QCXFormListenerManager form={form} values={values} onChangeListeners={[]} />
          <Grid container>
            {ContasPagarUtils.isContasPagar(typeReport) && (
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <QCXFinalRadioField
                  id="checkbox-tipo-relatorio-contas-pagas"
                  key="checkbox-tipo-relatorio-contas-pagas"
                  name="tipo"
                  label={t('com.muralis.qcx.financeiro.contasPagar')}
                  value={ContasPagarUtils.CONTAS_PAGAR}
                  onClick={(e) => onClickTipoRelatorio(e)}
                  required
                />
                {tipoRelatorio === ContasPagarUtils.CONTAS_PAGAR && (
                  <QCXRelatoriosContasPagarForm initialValues={values} />
                )}
              </Grid>
            )}
            {ContasReceberUtils.isContasReceber(typeReport) && (
              <>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <QCXFinalRadioField
                    id="checkbox-tipo-relatorio-contas-receber"
                    key="checkbox-tipo-relatorio-contas-receber"
                    name="tipo"
                    label={t('com.muralis.qcx.financeiro.contasReceber')}
                    value={ContasReceberUtils.CONTAS_RECEBER}
                    onClick={(e) => onClickTipoRelatorio(e)}
                    required
                  />
                  {tipoRelatorio === ContasReceberUtils.CONTAS_RECEBER && (
                    <QCXRelatoriosContasReceberForm initialValues={values} />
                  )}
                </Grid>
              </>
            )}
          </Grid>
        </>
      )}
    </QCXFinalFormDialog>
  );
}
