import { isEmpty, isFunction, merge, omit } from 'lodash';
import debounce from 'lodash/debounce';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import {
  addToList,
  changeToBackgroundCreateMode,
  changeToBackgroundDeleteMode,
  changeToConsultMode,
  changeToCreateMode,
  changeToSubConsultMode,
  changeToSubCreateMode,
  changeToUpdateMode,
  failure,
  loading,
  preparingAction,
  resetBackgroundMode,
  resetModel,
  resetRelatedItensXmlDanfeMetadata,
  resetRelatedMercadoriaModel,
  resetRelatedModelFollowUp,
  resetRelatedSelectionAvailableItemList,
  resetRelatedSelectionChangedItemList,
  resetRelatedSelectionItemList,
  resetRelatedTransferenciaItem,
  resetStatus,
  resetSubBackgroundMode,
  setError,
  setModel,
  setRelatedMercadoriaModel,
  setRelatedModelFollowUp,
  setResponse,
  success,
  updateOnList,
  updateSelectedMercadoria,
} from '../../../../features/declaracao-unica-exportacao/declaracaoUnicaExportacaoSlice';

import QCXDeclaracaoUnicaExportacaoWizardFinalForm from '../../../../components/declaracao-unica-exportacao/QCXDeclaracaoUnicaExportacaoWizardFinalForm';
import { useMercadoriaUtils } from '../../../../components/fatura/mercadoriaUtils';
import { changeControlTo as changeControlCatalogoProduto } from '../../../../features/catalogo-produtos/catalogoProdutosSlice';
import { selectCFOPOperacoesFiscais } from '../../../../features/cfop-operacoes-fiscais/cfopOperacoesFiscaisSlice';
import { AUTO_LOAD_CONTROL, IDLE_CONTROL } from '../../../../features/config-control';
import {
  selectBackgroundMode,
  selectDeclaracaoUnicaExportacao,
  selectMode,
  selectRelatedItensXmlDanfeMetadata,
  selectRelatedModelFollowUp,
  selectStatus,
  selectSubBackgroundMode,
  selectSubMode,
} from '../../../../features/declaracao-unica-exportacao/declaracaoUnicaExportacaoSelectors';
import { fetchByIdFromFaturaAsync } from '../../../../features/declaracao-unica-exportacao/declaracaoUnicaExportacaoThunks';
import { estadoActions } from '../../../../features/estado/estadoSlice';
import { faturaAPI } from '../../../../features/fatura/faturaAPI';
import { resetModel as resetModelFatura } from '../../../../features/fatura/faturaSlice';
import { changeControlTo as changeControlNaladiNccaTo } from '../../../../features/naladi-ncca/naladiNccaSlice';
import { changeControlTo as changeControlNaladiShTo } from '../../../../features/naladi-sh/naladiShSlice';
import { changeControlTo as changeControlNcmTo } from '../../../../features/ncm-subitem/ncmSubitemSlice';
import { changeControlTo as changeControlPaisTo } from '../../../../features/pais/paisSlice';
import {
  changeControlTo as changeControlUnidadeMedidaTo,
  selectUnidadesdeMedida,
} from '../../../../features/unidade-medida/unidadeDeMedidaSlice';
import QCXConfirmDialog from '../../../../shared-components/dialog/QCXConfirmDialog';
import QCXRegistrationFormPageTemplate from '../../../../templates/registration-form-page/QCXRegistrationFormPageTemplate';
import { TIPO_DUE } from '../../../../utils/general/fatura/faturaUtils';
import {
  forceUnnormalizeNumeral,
  isValid,
  normalizeNumeral,
  unnormalizeNumeral,
} from '../../../../utils/general/general-utils';
import {
  formatBrazilianNumericDecimal,
  formatCpfOrCnpj,
  formatLatitudeLongitude,
} from '../../../../utils/hooks/form/field/formatters';
import useOperationConfirm from '../../../../utils/hooks/operation/confirm/useOperationConfirm';
import {
  isBackgroundCreateMode,
  isBackgroundDeleteMode,
  isConsultMode,
  isCreateMode,
  isFailureStatus,
  isIdleStatus,
  isLoadingStatus,
  isLockedMode,
  isNoneMode,
  isNoneSubMode,
  isPreparingActionStatus,
  isSubBackgroundConsultMode,
  isSubBackgroundCreateMode,
  isSubBackgroundDeleteMode,
  isSubBackgroundUpdateMode,
  isSubConsultMode,
  isSubCreateMode,
  isSubUpdateMode,
  isUpdateMode,
} from '../../../../utils/store/store-utils';
// import { isLiberadaStatus } from '../../../../utils/general/operations/operationUtils';
import { clienteActions } from '../../../../features/cliente/clienteSlice';
import { followUpAPI } from '../../../../features/follow-up/followUpAPI';
import { numberOnly } from '../../../../utils/hooks/form/field/parsers';
// eslint-disable-next-line import/extensions
import useFindNcmsForItensXml from './useFindNcmsForItensXml';

export default function DeclaracaoUnicaExportacaoRegistrationPage({ authInfo = {} }) {
  const { t } = useTranslation();

  const params = useParams();

  const history = useHistory();
  const dispatch = useDispatch();
  const mercadoriaUtils = useMercadoriaUtils();

  const [isCreated, setIsCreated] = useState(false);

  const status = useSelector(selectStatus);
  const mode = useSelector(selectMode);
  const backgroundMode = useSelector(selectBackgroundMode);
  const subMode = useSelector(selectSubMode);
  const subBackgroundMode = useSelector(selectSubBackgroundMode);
  const declaracaoUnicaExportacao = useSelector(selectDeclaracaoUnicaExportacao);
  const unidadesDeMedida = useSelector(selectUnidadesdeMedida);
  const operacoesFiscais = useSelector(selectCFOPOperacoesFiscais);
  const itensXmlDanfeMetadata = useSelector(selectRelatedItensXmlDanfeMetadata);
  const relatedFollowUp = useSelector(selectRelatedModelFollowUp);

  const isPreparingAction = useMemo(() => isPreparingActionStatus(status), [status]);

  const isLoading = useMemo(() => isLoadingStatus(status), [status]);

  const isIdle = useMemo(() => isIdleStatus(status), [status]);

  const isFailure = useMemo(() => isFailureStatus(status), [status]);

  const isNone = useMemo(() => isNoneMode(mode), [mode]);

  const isCreate = useMemo(() => isCreateMode(mode), [mode]);

  const isConsult = useMemo(() => isConsultMode(mode), [mode]);

  const isUpdate = useMemo(() => isUpdateMode(mode), [mode]);

  const isLocked = useMemo(() => isLockedMode(mode), [mode]);

  const isBackgroundCreate = useMemo(() => isBackgroundCreateMode(backgroundMode), [backgroundMode]);

  const isBackgroundDelete = useMemo(() => isBackgroundDeleteMode(backgroundMode), [backgroundMode]);

  const isSubNone = useMemo(() => isNoneSubMode(subMode), [subMode]);

  const isSubCreate = useMemo(() => isSubCreateMode(subMode), [subMode]);

  const isSubConsult = useMemo(() => isSubConsultMode(subMode), [subMode]);

  const isSubBackgroundCreate = useMemo(() => isSubBackgroundCreateMode(subBackgroundMode), [subBackgroundMode]);

  const isSubBackgroundConsult = useMemo(() => isSubBackgroundConsultMode(subBackgroundMode), [subBackgroundMode]);

  const isSubBackgroundUpdate = useMemo(() => isSubBackgroundUpdateMode(subBackgroundMode), [subBackgroundMode]);

  const isSubBackgroundDelete = useMemo(() => isSubBackgroundDeleteMode(subBackgroundMode), [subBackgroundMode]);

  const isSubUpdate = useMemo(() => isSubUpdateMode(subMode), [subMode]);

  const handleChangeToPreparingAction = useCallback(() => {
    dispatch(preparingAction());
  }, []);

  const handleChangeToCreate = useCallback(() => {
    dispatch(changeToCreateMode());
  }, []);

  const handleChangeToBackgroundCreate = useCallback(() => {
    dispatch(changeToBackgroundCreateMode());
  }, []);

  const handleChangeToBackgroundDelete = useCallback(() => {
    dispatch(changeToBackgroundDeleteMode());
  }, []);

  const handleChangeToConsult = useCallback(() => {
    dispatch(changeToConsultMode());
  }, []);

  const handleChangeToUpdate = useCallback((additional = {}) => {
    dispatch(changeToUpdateMode());

    if (!isEmpty(additional) && isFunction(additional?.callback)) {
      additional.callback();
    }
  }, []);

  const handleResetBackgroundMode = useCallback(() => {
    dispatch(resetBackgroundMode());
  }, []);

  const fetchById = useCallback((id) => {
    dispatch(fetchByIdFromFaturaAsync(id));
  }, []);

  useEffect(() => {
    dispatch(resetStatus());

    dispatch(clienteActions.changeControlTo(IDLE_CONTROL));
    dispatch(changeControlCatalogoProduto(AUTO_LOAD_CONTROL));
    dispatch(changeControlPaisTo(AUTO_LOAD_CONTROL));
    dispatch(changeControlNcmTo(AUTO_LOAD_CONTROL));
    dispatch(changeControlNaladiShTo(AUTO_LOAD_CONTROL));
    dispatch(changeControlNaladiNccaTo(AUTO_LOAD_CONTROL));
    dispatch(changeControlUnidadeMedidaTo(AUTO_LOAD_CONTROL));

    return () => {
      dispatch(estadoActions.resetControl());
      dispatch(clienteActions.resetControl());
      dispatch(resetModel());
      dispatch(resetModelFatura());
      dispatch(resetRelatedSelectionAvailableItemList());
      dispatch(resetRelatedSelectionChangedItemList());
      dispatch(resetRelatedSelectionItemList());
      dispatch(resetRelatedTransferenciaItem());
      dispatch(resetRelatedItensXmlDanfeMetadata());
      dispatch(resetRelatedModelFollowUp());
    };
  }, []);

  useEffect(() => {
    const handleFetchById = () => {
      if (!isEmpty(params?.id)) {
        fetchById(params?.id);

        handleChangeToConsult();
      }
    };

    handleFetchById();
  }, [params, handleChangeToConsult]);

  useEffect(() => {
    const handleFindRelatedFollowUp = async () => {
      const idDue = declaracaoUnicaExportacao?.followUp?.id;

      if (!idDue || !isEmpty(relatedFollowUp)) {
        return;
      }

      const response = await followUpAPI.fetchById(idDue);

      if (response?.status === 200) {
        const followUpResponseData = response?.data;

        dispatch(setRelatedModelFollowUp(followUpResponseData));
      }
    };

    handleFindRelatedFollowUp();
  }, [relatedFollowUp, declaracaoUnicaExportacao]);

  const handleBasicCancelUpdate = useCallback(
    (additional = {}) => {
      const currentId = isBackgroundCreate ? declaracaoUnicaExportacao?.id : params?.id;

      if (currentId) {
        fetchById(currentId);
      }

      handleChangeToConsult();

      if (!isEmpty(additional) && isFunction(additional?.callback)) {
        additional.callback();
      }
    },
    [params, declaracaoUnicaExportacao, isBackgroundCreate, fetchById, handleChangeToConsult]
  );

  const operationOfCancelUpdateOptions = useMemo(
    () => ({
      title: t('com.muralis.qcx.aviso').toUpperCase(),
      message: t('com.muralis.qcx.cancelarAlteracoesPerdidas'),
      endMessage: t('com.muralis.qcx.mensagem.certezaDesejaCancelar'),
    }),
    []
  );

  const [handleControlledCancelUpdate, operationOfCancelUpdate] = useOperationConfirm(
    handleBasicCancelUpdate,
    operationOfCancelUpdateOptions,
    [handleBasicCancelUpdate, operationOfCancelUpdateOptions]
  );

  const normalize = useCallback((unnormalizedData) => {
    const normalizedMercadorias = unnormalizedData?.mercadorias || [];

    const {
      atributosAdicionais,
      importador,
      paisProcedencia,
      urfDespacho,
      urfEntrada,
      pesoLiquido,
      rateiaPesoLiquidoItem,
      incotermCondicaoVenda,
      mle,
      despesa,
      desconto,
      frete,
      seguro,
      exportador,
      fabricante,
      paisOrigem,
      followUp,
      ...restUnnormalizedData
    } = unnormalizedData;

    const normalizedData = {
      ...restUnnormalizedData,
      followUp: {
        ...followUp,
        danfes: followUp?.danfes?.map((danfe) => ({
          ...danfe,
          taxaConversao: normalizeNumeral(danfe.taxaConversao),
        })),
      },
      importador: importador?.id ? importador : null,
      urfDespacho: urfDespacho?.id ? urfDespacho : null,
      pesoLiquido: normalizeNumeral(pesoLiquido),
      rateiaPesoLiquidoItem,
      urfEntrada: urfEntrada?.id ? urfEntrada : null,
      atributosAdicionais: {
        ...atributosAdicionais,
        tipo: TIPO_DUE,
        processo: atributosAdicionais?.processo,
        informacoesComplementares: atributosAdicionais?.informacoesComplementares,
        importador: atributosAdicionais?.importador?.id ? atributosAdicionais?.importador : null,
        paisDestino: atributosAdicionais?.paisDestino?.id ? atributosAdicionais?.paisDestino : null,
        situacaoEspecialDespacho: atributosAdicionais?.situacaoEspecialDespacho?.id
          ? atributosAdicionais?.situacaoEspecialDespacho
          : null,
        tipoDocumentoFiscal: atributosAdicionais?.tipoDocumentoFiscal?.id
          ? atributosAdicionais?.tipoDocumentoFiscal
          : null,
        detalhamentoOperacaoSemNotaFiscal: atributosAdicionais?.detalhamentoOperacaoSemNotaFiscal?.id
          ? atributosAdicionais?.detalhamentoOperacaoSemNotaFiscal
          : null,
        formaExportacao: atributosAdicionais?.formaExportacao?.id ? atributosAdicionais?.formaExportacao : null,
        viaEspecialTransporte: atributosAdicionais?.viaEspecialTransporte?.id
          ? atributosAdicionais?.viaEspecialTransporte
          : null,
        recintoAlfandegadoDespacho: atributosAdicionais?.recintoAlfandegadoDespacho?.id
          ? atributosAdicionais?.recintoAlfandegadoDespacho
          : null,
        recintoAlfandegadoEmbarque: atributosAdicionais?.recintoAlfandegadoEmbarque?.id
          ? atributosAdicionais?.recintoAlfandegadoEmbarque
          : null,
        latitudeEmbarque: numberOnly(atributosAdicionais?.latitudeEmbarque),
        longitudeEmbarque: numberOnly(atributosAdicionais?.longitudeEmbarque),
        latitudeDespacho: numberOnly(atributosAdicionais?.latitudeDespacho),
        longitudeDespacho: numberOnly(atributosAdicionais?.longitudeDespacho),
        cnpjCpfResponsavelLocalDespacho: numberOnly(atributosAdicionais?.cnpjCpfResponsavelLocalDespacho),
        cnpjCpfResponsavelLocalEmbarque: numberOnly(atributosAdicionais?.cnpjCpfResponsavelLocalEmbarque),
      },
      mle: {
        ...mle,
        moeda: mle?.moeda?.id ? mle?.moeda : null,
        valorMCVCapaMoeda: normalizeNumeral(mle?.valorMCVCapaMoeda),
        valorMCVCapaReal: normalizeNumeral(mle?.valorMCVCapaReal),
        valorMoeda: normalizeNumeral(mle?.valorMoeda),
        valorReal: normalizeNumeral(mle?.valorReal),
      },
      desconto: {
        ...desconto,
        moeda: desconto?.moeda?.id ? desconto?.moeda : null,
        valorMoeda: normalizeNumeral(desconto?.valorMoeda),
        valorReal: normalizeNumeral(desconto?.valorReal),
      },
      despesa: {
        ...despesa,
        moeda: despesa?.moeda?.id ? despesa?.moeda : null,
        valorMoeda: normalizeNumeral(despesa?.valorMoeda),
        valorReal: normalizeNumeral(despesa?.valorReal),
      },
      frete: {
        ...frete,
        moeda: frete?.moeda?.id ? frete?.moeda : null,
        valorMoedaCollect: normalizeNumeral(frete?.valorMoedaCollect),
        valorRealCollect: normalizeNumeral(frete?.valorRealCollect),
        valorMoedaPrepaid: normalizeNumeral(frete?.valorMoedaPrepaid),
        valorRealPrepaid: normalizeNumeral(frete?.valorRealPrepaid),
        valorFreteNacionalMoeda: normalizeNumeral(frete?.valorFreteNacionalMoeda),
        valorFreteNacionalReal: normalizeNumeral(frete?.valorFreteNacionalReal),
      },
      seguro: {
        ...seguro,
        moeda: seguro?.moeda?.id ? seguro?.moeda : null,
        valorMoeda: normalizeNumeral(seguro?.valorMoeda),
        valorReal: normalizeNumeral(seguro?.valorReal),
        valorPercentual: normalizeNumeral(seguro?.valorPercentual),
      },
      mercadorias: normalizedMercadorias,
    };

    return normalizedData;
  }, []);

  const unnormalize = useCallback(
    (normalizedData) => {
      const { pesoLiquido, atributosAdicionais, mle, despesa, desconto, frete, seguro, mercadorias } = normalizedData;

      return {
        ...normalizedData,
        followUp: {
          ...relatedFollowUp,
          danfes:
            relatedFollowUp?.danfes?.map((danfe) => ({
              ...danfe,
              taxaConversao: unnormalizeNumeral(danfe.taxaConversao, formatBrazilianNumericDecimal(7)),
            })) ?? [],
        },
        pesoLiquido: isValid(pesoLiquido)
          ? forceUnnormalizeNumeral(pesoLiquido, formatBrazilianNumericDecimal(7))
          : undefined,
        atributosAdicionais: {
          ...atributosAdicionais,
          cnpjCpfResponsavelLocalDespacho: formatCpfOrCnpj(atributosAdicionais?.cnpjCpfResponsavelLocalDespacho),
          cnpjCpfResponsavelLocalEmbarque: formatCpfOrCnpj(atributosAdicionais?.cnpjCpfResponsavelLocalEmbarque),
          latitudeEmbarque: formatLatitudeLongitude(atributosAdicionais?.latitudeEmbarque),
          longitudeEmbarque: formatLatitudeLongitude(atributosAdicionais?.longitudeEmbarque),
          latitudeDespacho: formatLatitudeLongitude(atributosAdicionais?.latitudeDespacho),
          longitudeDespacho: formatLatitudeLongitude(atributosAdicionais?.longitudeDespacho),
        },
        mle: {
          ...mle,
          valorMoeda: isValid(mle?.valorMoeda)
            ? unnormalizeNumeral(mle?.valorMoeda, formatBrazilianNumericDecimal(2))
            : undefined,
          valorMCVCapaMoeda: isValid(mle?.valorMCVCapaMoeda)
            ? unnormalizeNumeral(mle?.valorMCVCapaMoeda, formatBrazilianNumericDecimal(2))
            : undefined,
          valorMCVCapaReal: isValid(mle?.valorMCVCapaReal)
            ? unnormalizeNumeral(mle?.valorMCVCapaReal, formatBrazilianNumericDecimal(2))
            : undefined,
          valorReal: isValid(mle?.valorReal)
            ? unnormalizeNumeral(mle?.valorReal, formatBrazilianNumericDecimal(2))
            : undefined,
        },
        desconto: {
          ...desconto,
          moeda: desconto?.moeda?.id ? desconto?.moeda : undefined,
          valorMoeda: isValid(desconto?.valorMoeda)
            ? unnormalizeNumeral(desconto?.valorMoeda, formatBrazilianNumericDecimal(2))
            : undefined,
          valorReal: isValid(desconto?.valorReal)
            ? unnormalizeNumeral(desconto?.valorReal, formatBrazilianNumericDecimal(2))
            : undefined,
        },
        despesa: {
          ...despesa,
          valorMoeda: isValid(despesa?.valorMoeda)
            ? unnormalizeNumeral(despesa?.valorMoeda, formatBrazilianNumericDecimal(2))
            : undefined,
          valorReal: isValid(despesa?.valorReal)
            ? unnormalizeNumeral(despesa?.valorReal, formatBrazilianNumericDecimal(2))
            : undefined,
        },
        frete: {
          ...frete,
          moeda: frete?.moeda?.id ? frete?.moeda : undefined,
          valorMoedaPrepaid: isValid(frete?.valorMoedaPrepaid)
            ? unnormalizeNumeral(frete?.valorMoedaPrepaid, formatBrazilianNumericDecimal(2))
            : undefined,
          valorRealPrepaid: isValid(frete?.valorRealPrepaid)
            ? unnormalizeNumeral(frete?.valorRealPrepaid, formatBrazilianNumericDecimal(2))
            : undefined,
          valorFreteNacionalMoeda: isValid(frete?.valorFreteNacionalMoeda)
            ? unnormalizeNumeral(frete?.valorFreteNacionalMoeda, formatBrazilianNumericDecimal(2))
            : undefined,
          valorFreteNacionalReal: isValid(frete?.valorFreteNacionalReal)
            ? unnormalizeNumeral(frete?.valorFreteNacionalReal, formatBrazilianNumericDecimal(2))
            : undefined,
          valorMoedaCollect: isValid(frete?.valorMoedaCollect)
            ? unnormalizeNumeral(frete?.valorMoedaCollect, formatBrazilianNumericDecimal(2))
            : undefined,
          valorRealCollect: isValid(frete?.valorRealCollect)
            ? unnormalizeNumeral(frete?.valorRealCollect, formatBrazilianNumericDecimal(2))
            : undefined,
        },
        seguro: {
          ...seguro,
          moeda: seguro?.moeda?.id ? seguro?.moeda : undefined,
          valorMoeda: isValid(seguro?.valorMoeda)
            ? unnormalizeNumeral(seguro?.valorMoeda, formatBrazilianNumericDecimal(2))
            : undefined,
          valorReal: isValid(seguro?.valorReal)
            ? unnormalizeNumeral(seguro?.valorReal, formatBrazilianNumericDecimal(2))
            : undefined,
          valorPercentual: isValid(seguro?.valorPercentual)
            ? unnormalizeNumeral(seguro?.valorPercentual, formatBrazilianNumericDecimal(4))
            : undefined,
        },
        mercadorias,
      };
    },
    [relatedFollowUp]
  );

  const [findNcmForItensXml] = useFindNcmsForItensXml();

  const parseItensXmlDanfeToMercadoriaDue = useCallback(
    async (data) => {
      const itensXmlDanfe = (data?.itensXmlDanfe || [])?.map((item) => ({
        ...omit(item, ['modo', 'itemOriginal', 'quantidadeInicial', 'valorTotalMoedaInicial']),
        id: null,
      }));

      if (isEmpty(itensXmlDanfe)) {
        return data;
      }

      const ncms = await findNcmForItensXml(itensXmlDanfe);

      const parsedItensXmlDanfeNcm = itensXmlDanfe?.map((item) => ({
        ...omit(item, ['unidadeDeMedida', 'operacaoFiscal']),
        ncm: ncms?.find((ncm) => ncm?.code === item?.ncm) || undefined,
      }));

      const parsedItensXmlDanfeUnidadeDeMedida = itensXmlDanfe?.map((item) => ({
        ...omit(item, ['ncm', 'operacaoFiscal']),
        unidadeDeMedida:
          unidadesDeMedida?.find(
            (unidadeDeMedida) =>
              unidadeDeMedida?.sigla === item?.unidadeDeMedida || unidadeDeMedida?.description === item?.unidadeDeMedida
          ) || undefined,
      }));

      const parsedItensXmlDanfeOperacoesFiscais = itensXmlDanfe?.map((item) => ({
        ...omit(item, ['ncm', 'unidadeDeMedida']),
        operacaoFiscal:
          operacoesFiscais?.find((operacaoFiscal) => numberOnly(operacaoFiscal?.code) === item?.operacaoFiscal) ||
          undefined,
      }));

      const mergeMercadoriasNcmUnidadeDeMedida = merge(parsedItensXmlDanfeNcm, parsedItensXmlDanfeUnidadeDeMedida);

      const mergeMercadorias = merge(parsedItensXmlDanfeOperacoesFiscais, mergeMercadoriasNcmUnidadeDeMedida);

      const mercadorias = mergeMercadorias?.map((mercadoria) => ({
        ...mercadoriaUtils.normalize(mercadoria),
        tipoCalculo: 'QUANTIDADE_X_VALOR',
      }));

      return {
        ...omit(data, ['itensXmlDanfe']),
        mercadorias,
      };
    },
    [unidadesDeMedida, operacoesFiscais]
  );

  const handleDispatchSetModel = useCallback(
    (data) => {
      const normalizedData = normalize(data);

      dispatch(setModel(normalizedData));
    },
    [normalize]
  );

  const hasNoConditionalStep = useMemo(
    () => !isEmpty(itensXmlDanfeMetadata) && (isCreate || isCreated),
    [isCreate, isCreated, itensXmlDanfeMetadata]
  );

  const lastStep = useMemo(() => (hasNoConditionalStep ? 4 : 3), [hasNoConditionalStep]);

  const createByStep = async (data, step, next) => {
    const isFirstStep = step === 0;
    const isLastStep = step === lastStep;

    const executeDebounced = debounce(async () => {
      try {
        if (isFirstStep && !isCreated) {
          const parsedData = await parseItensXmlDanfeToMercadoriaDue(data);

          const response = await faturaAPI.register(parsedData, [{ name: 'STEP', value: step + 1 }]);

          if (response?.status === 201) {
            next();
            dispatch(resetModel());
            dispatch(success());

            const created = response?.data;

            handleDispatchSetModel(created);
            dispatch(addToList({ data: created }));
            dispatch(fetchByIdFromFaturaAsync(created?.id));

            setIsCreated(true);
          }
        } else if (isLastStep) {
          const response = await faturaAPI.save(data, [{ name: 'STEP', value: step + 1 }]);

          if (response?.status === 200) {
            dispatch(resetModel());

            const handleResultWithDebounce = debounce(() => {
              history.push(`${t('com.muralis.qcx.url.exportacaoDue')}?q=${data?.atributosAdicionais?.processo}`);

              dispatch(success());

              const saved = response?.data;

              dispatch(setModel(saved));

              dispatch(
                setResponse({
                  status: response.status,
                  data: saved,
                  message: t('com.muralis.qcx.mensagem.DUERegistrada', {
                    processo: data?.atributosAdicionais?.processo,
                  }),
                })
              );

              dispatch(updateOnList({ data: saved }));
            }, 500);

            handleResultWithDebounce();
          }
        } else {
          const response = await faturaAPI.save(data, [{ name: 'STEP', value: step + 1 }]);

          if (response?.status === 200) {
            if (isCreate) {
              next();
            } else {
              handleChangeToConsult();
            }

            const saved = response?.data;

            const followUp = await followUpAPI.fetchById(saved?.id);
            const followUpResponseData = followUp?.data;
            dispatch(setRelatedModelFollowUp(followUpResponseData));

            dispatch(success());
            dispatch(setModel(saved));
            dispatch(updateOnList({ data: saved }));
            dispatch(fetchByIdFromFaturaAsync(saved?.id));
          }
        }
      } catch (error) {
        dispatch(failure());
        let errorMessage = t('com.muralis.qcx.erro.erroSalvarDadosDUE');
        if (error?.response && error?.response?.data) {
          errorMessage = t('com.muralis.qcx.erro.erroSalvarDadosDUEEspecifico', {
            erro: error?.response?.data?.message,
          });
        }
        dispatch(
          setError({
            message: errorMessage,
          })
        );
      }
    }, 500);

    dispatch(loading());
    executeDebounced();
  };

  const update = async (data, step) => {
    const executeDebounced = debounce(async () => {
      try {
        const response = await faturaAPI.save(data, [{ name: 'STEP', value: step + 1 }]);

        if (response?.status === 200) {
          const handleResultWithDebounce = debounce(async () => {
            handleChangeToConsult();
            dispatch(success());

            const saved = response?.data;

            dispatch(
              setResponse({
                status: response.status,
                data: saved,
                message: t('com.muralis.qcx.mensagem.DUESalva', { processo: data?.atributosAdicionais?.processo }),
              })
            );

            const followUp = await followUpAPI.fetchById(saved?.id);
            const followUpResponseData = followUp?.data;
            dispatch(setRelatedModelFollowUp(followUpResponseData));
            dispatch(setModel(saved));
            dispatch(updateSelectedMercadoria());
            dispatch(updateOnList({ data: saved }));
            dispatch(fetchByIdFromFaturaAsync(saved?.id));
          }, 500);

          handleResultWithDebounce();
        }
      } catch ({ response }) {
        dispatch(failure());
        dispatch(
          setError({
            message: t('com.muralis.qcx.erro.erroSalvarDadosDUEMensagem', {
              processo: data?.atributosAdicionais?.processo,
              mensagem: response?.data?.message,
            }),
          })
        );
      }
    }, 500);

    dispatch(loading());
    executeDebounced();
  };

  const handleSubmit = async (data, step, next) => {
    const normalizedData = normalize(data);

    if (isUpdate && !isBackgroundCreate) {
      await update(normalizedData, step);
      handleChangeToConsult();
      return;
    }

    await createByStep(normalizedData, step, next);
  };

  const handleCustomSaveBy = async (options) => {
    if (!isFunction(options?.composeWith)) {
      throw new Error('Required Function Error: "composeWith" is not a function.');
    }

    const unnormalizedData = options?.composeWith(declaracaoUnicaExportacao);

    const normalizedData = normalize(unnormalizedData);

    const response = await faturaAPI.save(normalizedData, [
      {
        name: 'STEP',
        value: options?.step,
      },
    ]);

    return response;
  };

  const handleAlternativeSave = async (event, step, next) => {
    if (event && !isEmpty(event)) {
      event.stopPropagation();
    }

    const normalizedData = normalize(declaracaoUnicaExportacao);

    if (isUpdate) {
      await update(normalizedData, step);
      return;
    }

    await createByStep(normalizedData, step, next);
  };

  const handleMercadoria = async (data, callback) => {
    const callCallbackIfValid = (...args) => {
      if (isFunction(callback)) {
        callback(...args);
      }
    };

    const isCreateOperation = isSubCreate;

    if (isCreateOperation) {
      const unnormalizedData = {
        ...declaracaoUnicaExportacao,
        mercadorias: [...(declaracaoUnicaExportacao?.mercadorias || []), data],
      };
      const normalizedData = normalize(unnormalizedData);

      const executeDebounced = debounce(async () => {
        try {
          const response = await faturaAPI.save(normalizedData, [{ name: 'STEP', value: 4 }]);

          if (response?.status === 200) {
            const handleResultWithDebounce = debounce(() => {
              dispatch(success());

              const saved = response?.data;

              const lastItemAdded = saved?.mercadorias?.length;

              dispatch(
                setResponse({
                  status: response.status,
                  data: saved,
                  message: t('com.muralis.qcx.mensagem.itemAdicionadoListaMercadoriasDUE', {
                    item: lastItemAdded,
                    processo: declaracaoUnicaExportacao?.atributosAdicionais?.processo,
                  }),
                })
              );

              dispatch(resetRelatedMercadoriaModel());
              dispatch(changeToSubCreateMode());
              dispatch(fetchByIdFromFaturaAsync(saved?.id));
            }, 500);

            handleResultWithDebounce();

            callCallbackIfValid();
          }
        } catch ({ response }) {
          const itemNumber = normalizedData?.mercadorias?.length;

          dispatch(failure());
          dispatch(
            setError({
              message: t('com.muralis.qcx.erro.erroAdicionarItemMercadoriaDUE', {
                item: itemNumber,
                processo: declaracaoUnicaExportacao?.atributosAdicionais?.processo,
                mensagem: response?.data?.message,
              }),
            })
          );
        }
      }, 500);

      dispatch(loading());
      executeDebounced();

      return;
    }

    const isUpdateOperation = isSubUpdate;

    if (isUpdateOperation) {
      dispatch(setRelatedMercadoriaModel(data));

      const unnormalizedData = {
        ...declaracaoUnicaExportacao,
        mercadorias: [
          ...(declaracaoUnicaExportacao?.mercadorias.map((current) => (current?.id === data?.id ? data : current)) ||
            []),
        ],
      };
      const normalizedData = normalize(unnormalizedData);

      const executeDebounced = debounce(async () => {
        try {
          const response = await faturaAPI.save(normalizedData, [{ name: 'STEP', value: 4 }]);

          if (response?.status === 200) {
            const handleResultWithDebounce = debounce(() => {
              dispatch(success());

              const saved = response?.data;

              dispatch(
                setResponse({
                  status: response.status,
                  data: saved,
                  message: t('com.muralis.qcx.mensagem.itemSalvoListaMercadoriasDUE', {
                    item: data?.item,
                    processo: declaracaoUnicaExportacao?.atributosAdicionais?.processo,
                  }),
                })
              );

              if (isUpdate) {
                handleChangeToConsult();
              }

              dispatch(changeToSubConsultMode());
              dispatch(fetchByIdFromFaturaAsync(saved?.id));
            }, 500);

            handleResultWithDebounce();
          }
        } catch ({ response }) {
          dispatch(failure());
          dispatch(
            setError({
              message: t('com.muralis.qcx.erro.erroSalvarItemMercadoriaDUE', {
                item: data?.item,
                processo: declaracaoUnicaExportacao?.atributosAdicionais?.processo,
                mensagem: response?.data?.message,
              }),
            })
          );
        }
      }, 500);

      dispatch(loading());
      executeDebounced();

      return;
    }

    const isRemoveOperation =
      (isBackgroundDelete || isSubBackgroundDelete) && (isSubNone || isSubConsult || isSubUpdate);

    if (isRemoveOperation) {
      const unnormalizedData = {
        ...declaracaoUnicaExportacao,
        mercadorias: [...(declaracaoUnicaExportacao?.mercadorias.filter((current) => current?.id !== data?.id) || [])],
      };
      const normalizedData = normalize(unnormalizedData);

      const executeDebounced = debounce(async () => {
        try {
          const response = await faturaAPI.save(normalizedData, [{ name: 'STEP', value: 4 }]);

          if (response?.status === 200) {
            const handleResultWithDebounce = debounce(() => {
              dispatch(success());

              const saved = response?.data;

              dispatch(
                setResponse({
                  status: response.status,
                  data: saved,
                  message: t('com.muralis.qcx.mensagem.itemRemovidoListaMercadoriaDUE', {
                    item: data?.item,
                    processo: declaracaoUnicaExportacao?.atributosAdicionais?.processo,
                  }),
                })
              );

              dispatch(fetchByIdFromFaturaAsync(saved?.id));
              dispatch(resetSubBackgroundMode());

              callCallbackIfValid(saved?.mercadorias);
            }, 500);

            handleResultWithDebounce();
          }
        } catch ({ response }) {
          dispatch(failure());
          dispatch(
            setError({
              message: t('com.muralis.qcx.erro.erroRemoverItemListaMercadoriaDUE', {
                item: data?.item,
                processo: declaracaoUnicaExportacao?.atributosAdicionais?.processo,
                mensagem: response?.data?.message,
              }),
            })
          );
        } finally {
          handleResetBackgroundMode();
        }
      }, 500);

      dispatch(loading());
      executeDebounced();
    }
  };

  const refreshSelectedModel = useCallback(() => {
    if (!isEmpty(declaracaoUnicaExportacao) && declaracaoUnicaExportacao?.id) {
      fetchById(declaracaoUnicaExportacao?.id);
    }
  }, [declaracaoUnicaExportacao, isUpdate, fetchById, handleChangeToConsult]);

  const model = useMemo(
    () =>
      isCreated || !isCreate
        ? unnormalize({
            ...declaracaoUnicaExportacao,
          })
        : {
            ...declaracaoUnicaExportacao,
            followUp: {
              id: declaracaoUnicaExportacao?.followUp?.id,
            },
            importador: {
              id: declaracaoUnicaExportacao?.followUp?.importador?.id,
            },
          },
    [isCreate, isCreated, declaracaoUnicaExportacao, unnormalize]
  );

  // TODO: ADICIONAR / CONFIRMAR REGRA APÓS VALIDAÇÃO DAS INFORMAÇÕES DA DU-E
  // const isLiberada = useMemo(() => (
  //   isLiberadaStatus(model?.atributosAdicionais?.liberada)
  // ), [model]);

  const actionName = useMemo(() => {
    if (isCreate || isBackgroundCreate || isNone) return t('com.muralis.qcx.acoes.nova');
    if (isConsult) return t('com.muralis.qcx.acoes.visualizar');
    return t('com.muralis.qcx.acoes.alterar');
  }, [isNone, isCreate, isConsult, isBackgroundCreate, t]);

  const breadcrumbs = useMemo(
    () => [
      {
        link: {
          to: '/',
          name: t('com.muralis.qcx.inicio'),
        },
      },
      {
        link: {
          to: t('com.muralis.qcx.url.moduloExportacao'),
          name: t('com.muralis.qcx.exportacao.label'),
        },
      },
      {
        link: {
          to: t('com.muralis.qcx.url.exportacaoDue'),
          name: t('com.muralis.qcx.dueExportacao.sigla'),
        },
      },
      {
        text: {
          name: actionName,
        },
      },
    ],
    [actionName, t]
  );

  const pageTitle = useMemo(
    () =>
      isNone || isCreate || isBackgroundCreate
        ? t('com.muralis.qcx.dueExportacao.novaDUE')
        : t('com.muralis.qcx.dueExportacao.DUESelecionadaIdentificacao', {
            identificacao: model?.atributosAdicionais?.processo || '-',
          }),
    [isNone, isCreate, isBackgroundCreate, model]
  );

  return (
    <QCXRegistrationFormPageTemplate
      pageTitle={pageTitle}
      breadcrumbs={breadcrumbs}
      isIdle={isIdle}
      isLoading={isLoading}
      isFailure={isFailure}
      isCreate={isCreate}
      isConsult={isConsult}
      isUpdate={isUpdate}
      isLocked={isLocked}
      isPreparingAction={isPreparingAction}
      isBackgroundCreate={isBackgroundCreate}
      isBackgroundDelete={isBackgroundDelete}
      isSubBackgroundCreate={isSubBackgroundCreate}
      isSubBackgroundConsult={isSubBackgroundConsult}
      isSubBackgroundUpdate={isSubBackgroundUpdate}
      isSubBackgroundDelete={isSubBackgroundDelete}
      handleAlternativeSave={handleAlternativeSave}
      handleChangeToPreparingAction={handleChangeToPreparingAction}
      handleChangeToCreate={handleChangeToCreate}
      handleChangeToBackgroundCreate={handleChangeToBackgroundCreate}
      handleChangeToBackgroundDelete={handleChangeToBackgroundDelete}
      handleChangeToConsult={handleChangeToConsult}
      handleChangeToUpdate={handleChangeToUpdate}
      handleCancelUpdate={handleControlledCancelUpdate}
      handleResetBackgroundMode={handleResetBackgroundMode}
      showSubtitle={false}
      authInfo={authInfo}
    >
      {(formProps) => (
        <QCXDeclaracaoUnicaExportacaoWizardFinalForm
          model={model}
          handleChangeModel={handleDispatchSetModel}
          handleSubmit={handleSubmit}
          handleCustomSaveBy={handleCustomSaveBy}
          refreshSelectedModel={refreshSelectedModel}
          handleMercadoria={handleMercadoria}
          authInfo={authInfo}
          requiredRoles={['exportacao-due']}
          isCreated={isCreated}
          {...formProps}
        >
          <QCXConfirmDialog
            open={operationOfCancelUpdate?.active}
            title={operationOfCancelUpdate?.title}
            content={operationOfCancelUpdate?.message}
            endContent={operationOfCancelUpdate?.endMessage}
            onConfirm={operationOfCancelUpdate.confirm}
            onClose={operationOfCancelUpdate?.reset}
            buttonGroupOptions={{
              confirm: {
                description: t('com.muralis.qcx.expressao.sim'),
              },
              cancel: {
                description: t('com.muralis.qcx.expressao.nao'),
              },
            }}
          />
        </QCXDeclaracaoUnicaExportacaoWizardFinalForm>
      )}
    </QCXRegistrationFormPageTemplate>
  );
}
