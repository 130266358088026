const selectFollowUps = (state) => state.followUpDestinoFaturamento.list;
const selectFollowUp = (state) => state.followUpDestinoFaturamento.model;

const selectStatus = (state) => state.followUpDestinoFaturamento.status;
const selectRefresh = (state) => state.followUpDestinoFaturamento.refresh;
const selectMode = (state) => state.followUpDestinoFaturamento.mode.main;
const selectBackgroundMode = (state) => state.followUpDestinoFaturamento.mode.background;
const selectSubMode = (state) => state.followUpDestinoFaturamento.mode.sub;
const selectConfigControl = (state) => state.followUpDestinoFaturamento.config.control;
const selectError = (state) => state.followUpDestinoFaturamento.error;
const selectResponse = (state) => state.followUpDestinoFaturamento.response;

const followUpDestinoFaturamentoSelectors = {
  selectFollowUps,
  selectFollowUp,
  selectStatus,
  selectRefresh,
  selectMode,
  selectBackgroundMode,
  selectSubMode,
  selectConfigControl,
  selectError,
  selectResponse,
};

export default followUpDestinoFaturamentoSelectors;
