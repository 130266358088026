import { KeyboardDatePicker } from '@material-ui/pickers';
import QCXPicker from '../picker/QCXPicker';

export default function QCXDatePicker({
  format = 'DD/MM/YYYY',
  placeholder = 'DD/MM/YYYY',
  disablePast = false,
  disableFuture = false,
  ...restProps
}) {
  return (
    <QCXPicker>
      <KeyboardDatePicker
        InputLabelProps={{
          shrink: true,
          style: {
            textTransform: 'uppercase',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            // width: '100%',
          },
        }}
        emptyLabel=""
        format={format}
        placeholder={placeholder}
        inputVariant="outlined"
        size="small"
        fullWidth
        disablePast={disablePast}
        disableFuture={disableFuture}
        {...restProps}
      />
    </QCXPicker>
  );
}
