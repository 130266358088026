import QCXSelectBoolean from '../select-boolean/QCXSelectBoolean';

export default function QCXSelectNcmDominioBooleanAutocomplete({
  id,
  key,
  name,
  label,
  initialValues,
  filterPredicate,
  ...restProps
}) {
  return (
    <QCXSelectBoolean
      id={id}
      key={key}
      name={name}
      label={label}
      initialValues={initialValues}
      {...restProps}
    />
  );
}
