import _ from 'lodash';
import { formatBrazilianNumericDecimal } from '../../hooks/form/field/formatters';
import { padLeadingZeros, unnormalizeNumeral } from '../general-utils';
import { OperacaoMovimentacaoUtils } from '../movimentacao/movimentacaoUtils';
import TituloUtils from '../titulo/TituloUtils';

const NUMERARIO_IMPORTACAO_ROLE = 'importacao-solicitacao-numerario';

const NUMERARIO_CAPA_STATUS_PAGO = 'PAGO';

const formatIdentificacao = (identificacao) => {
  if (!(identificacao?.sequencial || identificacao?.ano)) {
    return undefined;
  }

  const { sequencial, ano } = identificacao;

  const sequencialWithLeadingZeros = padLeadingZeros(sequencial, 4);
  const formattedIdentificacao = `${sequencialWithLeadingZeros}/${ano}`;

  return formattedIdentificacao;
};

const getQuantidadeFrom = (values) => _.get(values, 'quantidade') || 0;

const getTotalMoedaFrom = (values) => {
  const totalMoeda = _.get(values, 'totalMoeda');
  const formatted = unnormalizeNumeral(totalMoeda, formatBrazilianNumericDecimal(2));

  return formatted;
};

const getTotalRealFrom = (values) => {
  const totalReal = _.get(values, 'totalReal');
  const formatted = unnormalizeNumeral(totalReal, formatBrazilianNumericDecimal(2));

  return formatted;
};

const getMovimentacoesConciliadasFrom = (capas) => {
  const capasPagas = capas?.filter((capa) => capa?.status === NUMERARIO_CAPA_STATUS_PAGO);

  const movimentacoes = capasPagas
    ?.flatMap((capa) => capa?.titulos)
    ?.filter((titulo) => TituloUtils.isStatusRecebido(titulo?.status))
    ?.flatMap((titulo) => titulo?.movimentacoes);
  // ?.filter((movimentacao) => (
  //   OperacaoMovimentacaoUtils.isStatusConciliada(movimentacao?.conciliada)
  // ));

  return movimentacoes;
};

const NumerarioImportacaoUtils = Object.freeze({
  ROLE: NUMERARIO_IMPORTACAO_ROLE,
  formatIdentificacao,
  getQuantidadeFrom,
  getTotalMoedaFrom,
  getTotalRealFrom,
  getMovimentacoesConciliadasFrom,
});

export default NumerarioImportacaoUtils;
