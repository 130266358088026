import React from 'react';
import { MediaType } from '../../utils/api/api-utils';
import QCXFinalCustomInputFile from './QCXFinalCustomInputFile';
import i18n from '../../i18n';

export default function QCXFinalPdfInputFile({
  id,
  key,
  label,
  name,
  tooltipTitle = i18n.t('com.muralis.qcx.arquivo.uploadArquivoPDF'),
  disableRemoveControl = false,
  inputRef,
  ...restProps
}) {
  return (
    <QCXFinalCustomInputFile
      id={id}
      key={key}
      label={label}
      name={name}
      accept={MediaType.APPLICATION_PDF}
      tooltipTitle={tooltipTitle}
      disableRemoveControl={disableRemoveControl}
      inputRef={inputRef}
      {...restProps}
    />
  );
}
