import React, {
  useCallback,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  useTheme,
  Grid,
  Typography,
} from '@material-ui/core';
import {
  Close as CloseIcon,
} from '@material-ui/icons';
import QCXIconButton from '../../shared-components/button/QCXIconButton';
import QCXCompoundDialog from '../../shared-components/dialog/QCXCompoundDialog';
import { CLOSED_STATUS, isOpenStatus } from '../../utils/hooks/form/dialog/formDialogUtils';
import QCXDocumentoImportacaoFormGroup from './QCXDocumentoImportacaoFormGroup';

export default function QCXDocumentoImportacaoDialog({
  status,
  handleStatus,
  isParentConsult,
  ...restProps
}) {
  const { t } = useTranslation();
  const theme = useTheme();

  const open = useMemo(() => (
    isOpenStatus(status)
  ), [status]);

  const handleClose = useCallback(() => {
    handleStatus(CLOSED_STATUS);
  }, [handleStatus]);

  return (
    <QCXCompoundDialog
      key="dialog-documento-informacao"
      id="dialog-documento-informacao"
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      disableBackdropClick
      {...restProps}
    >
      <QCXCompoundDialog.Title
        style={{
          padding: '16px 32px 16px 24px',
        }}
      >
        <Grid
          item
          container
          justify="space-between"
        >
          <Grid
            item
            xs={10}
          >
            <Typography
              variant="subtitle2"
              style={{
                color: theme.palette.primary.light,
              }}
            >
              <strong>
                {t('com.muralis.qcx.documento.documentoImportacao').toUpperCase()}
              </strong>
            </Typography>
          </Grid>
          <Grid
            item
            container
            justify="flex-end"
            xs={2}
          >
            <QCXIconButton
              style={{
                margin: '-16px -32px 0 0',
                padding: '10px',
                borderRadius: '4px',
              }}
              onClick={handleClose}
              disableTooltip
              disableRipple
            >
              <CloseIcon />
            </QCXIconButton>
          </Grid>
        </Grid>
      </QCXCompoundDialog.Title>
      <QCXCompoundDialog.Content
        style={{
          padding: '16px 32px 16px 24px',
        }}
      >
        <QCXDocumentoImportacaoFormGroup
          isParentConsult={isParentConsult}
        />
      </QCXCompoundDialog.Content>
    </QCXCompoundDialog>
  );
}
