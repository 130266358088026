import { getOptionByValueInList } from '../general-utils';

const CANAL_VERDE = 'VERDE';
const CANAL_AMARELO = 'AMARELO';
const CANAL_VERMELHO = 'VERMELHO';
const CANAL_CINZA = 'CINZA';

const LIST = [
  {
    label: 'Verde',
    value: CANAL_VERDE,
  },
  {
    label: 'Amarelo',
    value: CANAL_AMARELO,
  },
  {
    label: 'Vermelho',
    value: CANAL_VERMELHO,
  },
  {
    label: 'Cinza',
    value: CANAL_CINZA,
  }
];

const isCanalVerde = (value) => (
  CANAL_VERDE === value
);

const isCanalAmarelo = (value) => (
  CANAL_AMARELO === value
);

const isCanalVermelho = (value) => (
  CANAL_VERMELHO === value
);

const isCanalCinza = (value) => (
  CANAL_CINZA === value
);

const getLabelByValue = (value) => {
  const foundOption = getOptionByValueInList(LIST, value);
  return foundOption && foundOption?.label;
};

const getHexadecimalColorByValue = (value) => {
  if (isCanalVerde(value)) {
    return '#13ad28';
  }

  if (isCanalAmarelo(value)) {
    return '#e8e800';
  }

  if (isCanalVermelho(value)) {
    return '#e80400';
  }

  if (isCanalCinza(value)) {
    return '#969696';
  }

  return undefined;
};

const CanalLiberacaoUtils = {
  LIST,
  CANAL_VERDE,
  CANAL_AMARELO,
  CANAL_VERMELHO,
  CANAL_CINZA,
  isCanalVerde,
  isCanalAmarelo,
  isCanalVermelho,
  isCanalCinza,
  getLabelByValue,
  getHexadecimalColorByValue,
};

export default CanalLiberacaoUtils;
