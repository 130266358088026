import {
  Grid,
} from '@material-ui/core';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { validate } from 'uuid';
import QCXRegistrationOneToManyPageTemplate from '../../templates/registration-one-to-many-page/QCXRegistrationOneToManyPageTemplate';
import QCXFormStepWizard from '../../shared-components/form-step-wizard/QCXFormStepWizard';
import QCXFinalTextField from '../../shared-components/final-text-field/QCXFinalTextField';
import QCXFinalNumericIntegerField from '../../shared-components/final-numeric-integer-field/QCXFinalNumericIntegerField';
import { maxValue, required as requiredValidator } from '../../utils/validators/field/validator';
import QCXFinalPercentualField from '../../shared-components/final-percentual-field/QCXFinalPercentualField';
import { formatBrazilianNumericDecimal, formatNotZero } from '../../utils/hooks/form/field/formatters';
import { unnormalizeNumeral } from '../../utils/general/general-utils';
import useValidators from '../../utils/hooks/useValidators';

export default function QCXTabelaPeriodoFinalFormManager({
  isConsult,
  isUpdate,
  isSubNone,
  isSubCreate,
  isSubConsult,
  isSubUpdate,
  initialValues,
  list,
  handleSubmit,
  handleNew,
  handleEdit,
  handleCancelCreate,
  handleCancelUpdate,
  handleRemove,
  handleRowClick,
  handleClearForm,
  controlComponentProps,
  authInfo = {},
  requiredRoles = [],
}) {
  const { t } = useTranslation();

  const tableProps = useMemo(() => ({
    title: t('com.muralis.qcx.tabelasPeriodosAdicionadas'),
    columns: [
      {
        field: 'periodo',
        headerName: t('com.muralis.qcx.periodo'),
        flex: 150,
        valueFormatter: ({ row: { periodo } }) => `${periodo}°`,
      },
      {
        field: 'intervalo',
        headerName: t('com.muralis.qcx.quantidade.quantidadeDias'),
        flex: 600,
        valueGetter: ({ row: { quantidadeDias } }) => (
          quantidadeDias || ''
        ),
      },
      {
        field: 'porcentagem',
        headerName: t('com.muralis.qcx.porcentagem'),
        headerAlign: 'right',
        align: 'right',
        flex: 150,
        valueFormatter: ({ row }) => (
          unnormalizeNumeral(
            row?.porcentagem,
            formatBrazilianNumericDecimal(2)
          )
        ),
      },
    ],
  }), []);

  const configureRestartFormHandler = useCallback((form) => {
    const handleRestartForm = () => {
      form.restart({
        porcentagem: '',
      });
    };

    return handleRestartForm;
  }, []);

  const localeTableText = useMemo(() => ({
    noRowsLabel: t('com.muralis.qcx.validacao.nenhumaTabelaPeriodoAdicionada'),
  }), []);

  const porcentagemValidators = useMemo(() => (
    useValidators([
      requiredValidator,
      maxValue(100),
    ])
  ), []);

  return (
    <QCXFormStepWizard
      initialValues={initialValues}
      onSubmit={handleSubmit}
      controlComponentProps={controlComponentProps}
      authInfo={authInfo}
      requiredRoles={requiredRoles}
    >
      {({ form, submitSucceeded, values }) => (
        <QCXRegistrationOneToManyPageTemplate
          title={t('com.muralis.qcx.tabelaPeriodo')}
          tableProps={tableProps}
          tableData={list}
          isConsult={isConsult}
          isUpdate={isUpdate}
          isSubNone={isSubNone}
          isSubCreate={isSubCreate}
          isSubConsult={isSubConsult}
          isSubUpdate={isSubUpdate}
          submitSucceeded={submitSucceeded}
          handleNew={handleNew}
          handleEdit={handleEdit}
          handleCancelCreate={handleCancelCreate}
          handleCancelUpdate={handleCancelUpdate}
          handleRemove={handleRemove}
          handleClearForm={handleClearForm}
          handleRestartForm={configureRestartFormHandler(form, values)}
          handleRowClick={handleRowClick}
          localeTableText={localeTableText}
        >
          {(isSubConsult || isSubUpdate) && !validate(values?.id) && (
            <Grid item xs={3}>
              <QCXFinalTextField
                id="text-field-code"
                key="text-field-code"
                name="id"
                label={t('com.muralis.qcx.codigo')}
                disabled
              />
            </Grid>
          )}

          <Grid item xs={isSubConsult || isSubUpdate ? 3 : 4}>
            <QCXFinalNumericIntegerField
              id="text-field-intervalo-fim"
              key="text-field-intervalo-fim"
              name="periodo"
              label={t('com.muralis.qcx.periodo')}
              allowNegative={false}
              validate={requiredValidator}
              disabled={isConsult || isSubConsult || isSubNone}
              format={formatNotZero}
              required
            />
          </Grid>
          <Grid item xs={isSubConsult || isSubUpdate ? 3 : 4}>
            <QCXFinalNumericIntegerField
              id="text-field-intervalo-fim"
              key="text-field-intervalo-fim"
              name="quantidadeDias"
              label={t('com.muralis.qcx.quantidade.quantidadeDias')}
              allowNegative={false}
              validate={requiredValidator}
              disabled={isConsult || isSubConsult || isSubNone}
              format={formatNotZero}
              required
            />
          </Grid>
          <Grid item xs={isSubConsult || isSubUpdate ? 3 : 4}>
            <QCXFinalPercentualField
              id="text-field-percentual"
              key="text-field-percentual"
              name="porcentagem"
              label={t('com.muralis.qcx.porcentagem')}
              disabled={isConsult || isSubConsult || isSubNone}
              validate={porcentagemValidators}
              submitSucceeded={submitSucceeded}
              required
            />
          </Grid>
        </QCXRegistrationOneToManyPageTemplate>
      )}
    </QCXFormStepWizard>
  );
}
