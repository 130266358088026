import React, { ReactNode, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Autocomplete, AutocompleteProps, TextFieldProps } from "mui-rff";
import { isFunction } from "lodash";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import { AutocompleteRenderOptionState } from "@material-ui/lab";

// https://stackoverflow.com/questions/59832331/why-checkbox-is-not-checked-in-react
// https://codesandbox.io/s/cranky-swirles-y9bcj?file=/src/test.js:845-853
// https://stackoverflow.com/questions/59790956/material-ui-autocomplete-clear-value

export interface BaseStyledAutocompleteSelectValue {
  id: number;
  description: string;
}

export interface StyledAutocompleteSelectProps<T>
  extends AutocompleteProps<
    T,
    boolean | undefined,
    boolean | undefined,
    boolean | undefined
  > {
  textFieldProps?: TextFieldProps;
  renderOptionDecorator?: ({}: {
    option: T;
    state: AutocompleteRenderOptionState;
    component: ReactNode;
  }) => ReactNode;
}

/*
  @Deprecated use selectStyled instead
*/
export default function StyledAutocompleteSelect<
  T extends BaseStyledAutocompleteSelectValue
>({
  getOptionLabel,
  renderOptionDecorator,
  textFieldProps,
  name,
  ...rest
}: StyledAutocompleteSelectProps<T>) {
  const styledTextFieldProps: Partial<TextFieldProps> = {
    ...textFieldProps,
    variant: "outlined",
    size: "small",
    InputLabelProps: {
      shrink: true,
      style: {
        textTransform: "uppercase",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      },
    },
  };

  const defaultGetOption = (item: T) => item.description || "";
  const getOptionLabelFinal = useMemo(
    () => getOptionLabel ?? defaultGetOption,
    []
  );

  const customRenderOption = (
    option: T,
    state: AutocompleteRenderOptionState
  ) => {
    const { inputValue } = state;
    const optionLabel = String(getOptionLabelFinal(option));

    const matches = match(optionLabel, inputValue);
    const parts = parse(optionLabel, matches);

    const highlightStyle = {
      fontWeight: 700,
    };

    const optionComponent = (
      <div>
        {parts.map((part, partIndex) => {
          const partKey = `label-part-${partIndex}`;
          const isHighlightedWord = part.highlight;

          if (isHighlightedWord) {
            return (
              <span key={partKey} style={highlightStyle}>
                {part.text}
              </span>
            );
          }

          const charactersOfPart = part.text.split("");

          return charactersOfPart.map(
            (characterOfPart, characterOfPartIndex) => {
              const characterOfPartKey = `character-from-part-${characterOfPartIndex}`;

              const inputValueInLowerCase = String(inputValue).toLowerCase();
              const characterOfPartInLowerCase =
                String(characterOfPart).toLowerCase();

              const isCharacterOfPartMatches = inputValueInLowerCase.includes(
                characterOfPartInLowerCase
              );

              const characterStyle = isCharacterOfPartMatches
                ? highlightStyle
                : {};

              return (
                <span key={characterOfPartKey} style={characterStyle}>
                  {characterOfPart}
                </span>
              );
            }
          );
        })}
      </div>
    );

    if (renderOptionDecorator) {
      return renderOptionDecorator({
        option,
        state,
        component: optionComponent,
      });
    }

    return optionComponent as ReactNode;
  };

  return (
    <Autocomplete
      getOptionLabel={getOptionLabelFinal}
      renderOption={customRenderOption}
      name={name}
      textFieldProps={styledTextFieldProps}
      {...rest}
    />
  );
}
