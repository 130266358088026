/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { saveAs } from 'file-saver';
import { MediaType } from '../../utils/api/api-utils';
import { doPostQCXRequest } from '../../utils/api/domain/domain-api-utils';
import { FOLLOW_UP_ENDPOINT, QCX_STORAGE_ENDPOINT } from '../../utils/api/domain/domain-endpoint-utils';
import { setErrorFeedback, setSuccessFeedback } from '../feedback/feedbackSlice';
import { followUpAPI } from '../follow-up/followUpAPI';
import { etapaFollowUpAPI } from './etapaFollowUpAPI';

const fetchAllByFollowUpIdAsync = createAsyncThunk('etapaFollowUp/fetchAllByFollowUpId', async (id) => {
  const { data } = await followUpAPI.fetchAllEtapasById(id);
  return { data };
});

const uploadMultipleFilesAsync = createAsyncThunk(
  'etapaFollowUp/uploadMultipleFiles',
  async (payload: any, thunkApi) => {
    const response = await doPostQCXRequest([FOLLOW_UP_ENDPOINT, QCX_STORAGE_ENDPOINT], payload, {
      headers: {
        'Content-Type': MediaType.MULTIPART_FORM_DATA,
      },
    } as any);

    if (response.status === 201) {
      const message = `Repasse realizado com sucesso! ${response?.data?.message ?? ''}`;
      thunkApi.dispatch(setSuccessFeedback({ message }));
    } else {
      thunkApi.dispatch(setErrorFeedback({ message: 'Erro ao realizar repasse!' }));
    }

    return {
      response: {
        status: response?.status,
        data: response?.data,
      },
    };
  }
);

const dowloadMultipleFilesAsync = createAsyncThunk(
  'etapaFollowUp/dowloadMultipleFiles',
  async (payload: any, thunkApi) => {
    const response = await etapaFollowUpAPI.dowloadMultipleFiles(payload);

    if (response.status === 200) {
      const message = `Download realizado com sucesso! ${response?.data?.message ?? ''}`;

      const blob = new Blob([response.data], { type: 'application/zip' });
      saveAs(blob, 'arquivos.zip');

      thunkApi.dispatch(setSuccessFeedback({ message }));
    } else {
      thunkApi.dispatch(setErrorFeedback({ message: `Erro ao realizar o download: ${response?.data?.message}` }));
    }

    return { response };
  }
);

const repasseByProcessosAsync = createAsyncThunk(
  'etapaFollowUp/repasseByProcessos',
  async ({ payload, params }: any, thunkApi) => {
    const response = await etapaFollowUpAPI.repasseByProcessos(payload, params);

    if (response.status === 200) {
      const message = `Repasse realizado com sucesso! ${response?.data?.message ?? ''}`;
      thunkApi.dispatch(setSuccessFeedback({ message }));
    } else {
      thunkApi.dispatch(setErrorFeedback({ message: 'Erro ao realizar repasse!' }));
    }

    return {
      response: {
        status: response?.status,
        data: response?.data,
      },
    };
  }
);

const etapaFollowUpThunks = {
  fetchAllByFollowUpIdAsync,
  repasseByProcessosAsync,
  uploadMultipleFilesAsync,
  dowloadMultipleFilesAsync,
};

export {
  dowloadMultipleFilesAsync,
  etapaFollowUpThunks,
  fetchAllByFollowUpIdAsync,
  repasseByProcessosAsync,
  uploadMultipleFilesAsync,
};
