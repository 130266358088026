import { Autocomplete, ListItem, TextField } from '@mui/material';
import { SyntheticEvent, useMemo, useState } from 'react';

export type SimpleMultiSelectOption = {
  id: string | number;
  value: string;
}

const MultiSelectStyled = ({
  options,
  label,
  onChangeAction,
  controlledValues,
  disabled,
}: {
  options: readonly SimpleMultiSelectOption[];
  label: string;
  onChangeAction?: (
    event: SyntheticEvent<Element, Event>,
    value:SimpleMultiSelectOption[] | null
  ) => void;
  controlledValues?: SimpleMultiSelectOption[];
  disabled?: boolean;
}) => {
  const [internalValues, setValue] = useState<
    SimpleMultiSelectOption[]
  >([]);

  const values = useMemo(() => controlledValues ?? internalValues, [controlledValues, internalValues]);

  return (
    <Autocomplete
      disabled={disabled}
      disablePortal
      size="small"
      options={options}
      multiple
      selectOnFocus
      handleHomeEndKeys
      value={values}
      onChange={(event, newValues) => {
        if (values == null) {
          setValue([]);
          onChangeAction && onChangeAction(event, newValues);
          return;
        }

        setValue([...newValues]);
        onChangeAction && onChangeAction(event, newValues);
      }}
      onBlur={(e) => {
        if (values == null) {
          setValue([]);
        }
      }}
      getOptionLabel={(option: any) => option.value.toUpperCase()}
      renderOption={(props, option) => <ListItem {...props}>{option.value?.toUpperCase()}</ListItem>}
      renderInput={(params) => <TextField {...params} defaultValue={'Selecione'} label={label.toUpperCase()} />}
    />
  );
};

export default MultiSelectStyled;
