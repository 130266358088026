import { isStrictEquals } from '../general-utils';

const ORIGEM_NUMERARIO = 'NUMERARIO';
const ORIGEM_FATURAMENTO = 'FATURAMENTO';
const ORIGEM_OPERACIONAL = 'OPERACIONAL';
const ORIGEM_ADMINISTRATIVA = 'ADMINISTRATIVA';
const ORIGEM_TRANSFERENCIA = 'TRANSFERENCIA_SALDO';

const STATUS_RECEBIDO = 'RECEBIDO';

const isTituloOrigemNumerario = (status) => (
  isStrictEquals(ORIGEM_NUMERARIO, status)
);

const isTituloOrigemFaturamento = (status) => (
  isStrictEquals(ORIGEM_FATURAMENTO, status)
);

const isTituloOrigemOperacional = (status) => (
  isStrictEquals(ORIGEM_OPERACIONAL, status)
);

const isTituloOrigemAdministrativa = (status) => (
  isStrictEquals(ORIGEM_ADMINISTRATIVA, status)
);

const isTituloOrigemTransferencia = (status) => (
  isStrictEquals(ORIGEM_TRANSFERENCIA, status)
);

const isTituloStatusRecebido = (status) => (
  isStrictEquals(STATUS_RECEBIDO, status)
);

const TituloUtils = {
  ORIGEM_NUMERARIO,
  ORIGEM_FATURAMENTO,
  ORIGEM_OPERACIONAL,
  ORIGEM_ADMINISTRATIVA,
  ORIGEM_TRANSFERENCIA,
  STATUS_RECEBIDO,
  isOrigemNumerario: (
    isTituloOrigemNumerario
  ),
  isOrigemFaturamento: (
    isTituloOrigemFaturamento
  ),
  isOrigemOperacional: (
    isTituloOrigemOperacional
  ),
  isOrigemAdministrativa: (
    isTituloOrigemAdministrativa
  ),
  isOrigemTransferencia: (
    isTituloOrigemTransferencia
  ),
  isStatusRecebido: (
    isTituloStatusRecebido
  ),
};

export default TituloUtils;
