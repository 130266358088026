import { createFilterOptions } from '@material-ui/lab/useAutocomplete';
import _ from 'lodash';
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import i18n from '../../../../i18n';
import QCXAutocompleteSelect from '../../../../shared-components/autocomplete-select/QCXAutocompleteSelect';

const TIPO_RATEIO_FRETE = 'RATEIO_FRETE';
const TIPO_RATEIO_VALOR = 'RATEIO_VALOR';

const labels = {
  RATEIO_FRETE: i18n.t('com.muralis.qcx.rateioFrete').toUpperCase(),
  RATEIO_VALOR: i18n.t('com.muralis.qcx.rateioValor').toUpperCase(),
};

export const getLabelTipoRateio = (value) => labels[value];

const TIPO_RATEIO_LIST = [
  {
    label: i18n.t('com.muralis.qcx.rateioFrete').toUpperCase(),
    value: TIPO_RATEIO_FRETE,
  },
  {
    label: i18n.t('com.muralis.qcx.rateioValor').toUpperCase(),
    value: TIPO_RATEIO_VALOR,
  },
];

export default function QCXSelectTipoRateioAutocomplete({ label, name, initialValues, ...restProps }) {
  const [defaultValue, setDefaultValue] = useState('');

  useEffect(() => {
    const value = TIPO_RATEIO_LIST.find((item) => item?.value === _.get(initialValues, name));
    if (value) {
      setDefaultValue(value);
    } else {
      setDefaultValue('');
    }
  }, [initialValues]);

  const filterOptions = useMemo(
    () =>
      createFilterOptions({
        stringify: (option) => option.label + option.value,
      }),
    []
  );

  const getOptionLabel = useCallback((item) => item?.label || '', []);

  const getOptionValue = useCallback((item) => item?.value || '', []);

  return (
    <QCXAutocompleteSelect
      label={label}
      id={name}
      name={name}
      options={TIPO_RATEIO_LIST}
      filterOptions={filterOptions}
      defaultValue={defaultValue}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      {...restProps}
    />
  );
}
