import React, {
  useCallback,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  makeStyles,
  useTheme,
  Typography,
} from '@material-ui/core';
import {
  DeleteForever as DeleteForeverIcon,
  Edit as EditIcon,
  InfoOutlined as InfoOutlinedIcon,
  Info as InfoIcon,
  RestoreFromTrash,
} from '@material-ui/icons';
import { VscQuestion as VscQuestionIcon } from 'react-icons/vsc';
import { isFunction, isUndefined } from 'lodash';
import { getIconComponentByValue } from '../../shared-components/select-icon/iconUtils';
import QCXIconButton from '../../shared-components/button/QCXIconButton';
import { formatDate } from '../../utils/general/general-utils';
import QCXFlatCard from '../../shared-components/card/QCXFlatCard';
import EtapaFollowUpUtils from '../../utils/general/follow-up/EtapaFollowUpUtils';

const useStyles = makeStyles((theme) => ({
  cardDateTypography: {
    fontWeight: 'bold',
  },
  cardTitleTypography: {
    textAlign: 'center',
    textTransform: 'uppercase',
    fontSize: 13,
  },
  cardIconButtonAction: {
    padding: '6px !important',
  },
  cardIconButtonActionDisabled: {
    padding: '6px !important',
    cursor: 'not-allowed !important',
    pointerEvents: 'all !important',
  },
  cardDateTypographyDisabled: {
    fontWeight: 'bold',
    color: theme.palette.grey[500],
  },
  cardTitleTypographyDisabled: {
    textAlign: 'center',
    textTransform: 'uppercase',
    fontSize: 13,
    color: theme.palette.grey[500],
  },
}));

export default function QCXEtapaFollowUpCard({
  model,
  onEditDate,
  onEditObservations,
  onInactivate,
  onReactivate,
  ...restProps
}) {
  const { t } = useTranslation();
  const classes = useStyles();

  const theme = useTheme();

  const isActive = useMemo(() => (
    model?.active
  ), [model]);

  const isCompleted = useMemo(() => (
    EtapaFollowUpUtils.isStatusConcluida(
      model?.status
    )
  ), [model]);

  const hasObservacoes = useMemo(() => (
    model?.observacao && model?.observacao?.length > 0
  ), [model]);

  const color = useMemo(() => {
    if (isActive && isCompleted) {
      return theme.palette.secondary.main;
    }

    if (isActive && !isCompleted) {
      return theme.palette.primary.main;
    }

    if (!isActive && isCompleted) {
      return '#fdd88a';
    }

    return '#a7a2bf';
  }, [
    theme,
    isActive,
    isCompleted,
  ]);

  const inactivateReactivateButtonColor = useMemo(() => {
    if (isCompleted) {
      return '#fdd88a';
    }

    return theme.palette.primary.main;
  }, [
    theme,
    isCompleted,
  ]);

  const computedStyle = useMemo(() => ({
    border: `2px solid ${color}`,
  }), [color]);

  const iconComponent = useMemo(() => {
    const iconProps = {
      style: {
        color,
      },
      htmlColor: color,
      color,
      fontSize: 'inherit',
      size: 'inherit',
    };

    const component = getIconComponentByValue(
      model?.etapa?.icone,
      iconProps
    );

    return (
      isUndefined(component)
        ? (
          <VscQuestionIcon
            {...iconProps}
          />
        ) : component
    );
  }, [
    model,
    color,
  ]);

  const overflowedDescription = useMemo(() => (
    model?.description?.length > 43
  ), [model]);

  const handleEditDate = useCallback((event) => {
    if (isFunction(event?.stopPropagation)) {
      event.stopPropagation();
    }

    if (!isFunction(onEditDate)) {
      throw new Error(
        'Required function not provided. Implements handler for "onEditDate" event.'
      );
    }

    onEditDate(model);
  }, [
    model,
    onEditDate,
  ]);

  const handleEditObservations = useCallback((event) => {
    if (isFunction(event?.stopPropagation)) {
      event.stopPropagation();
    }

    if (!isFunction(onEditObservations)) {
      throw new Error(
        'Required function not provided. Implements handler for "onEditObservations" event.'
      );
    }

    onEditObservations(model);
  }, [
    model,
    onEditObservations,
  ]);

  const handleInactivateOrReactivate = useCallback((event) => {
    if (isFunction(event?.stopPropagation)) {
      event.stopPropagation();
    }

    if (isActive) {
      if (!isFunction(onInactivate)) {
        throw new Error(
          'Required function not provided. Implements handler for "onInactivate" event.'
        );
      }

      onInactivate(model);
    } else {
      if (!isFunction(onReactivate)) {
        throw new Error(
          'Required function not provided. Implements handler for "onReactivate" event.'
        );
      }

      onReactivate(model);
    }
  }, [
    model,
    onInactivate,
    onReactivate,
  ]);

  const inactivateReactivateButtonTooltipProps = useMemo(() => {
    if (isActive && !isCompleted) {
      return {
        title: t('com.muralis.qcx.etapa.desativarEtapa'),
      };
    }

    if (!isActive && !isCompleted) {
      return {
        title: t('com.muralis.qcx.etapa.reativarEtapa'),
      };
    }

    return {
      title: t('com.muralis.qcx.etapa.naoPossivelDesativar'),
    };
  }, [
    isActive,
    isCompleted,
  ]);

  return (
    <QCXFlatCard
      style={computedStyle}
      {...restProps}
    >
      <QCXFlatCard.TopBox
        fontWeight
      >
        <Typography
          className={(
            isActive
              ? classes.cardDateTypography
              : classes.cardDateTypographyDisabled
          )}
        >
          {formatDate(
            model?.dataConclusao
          ) || (
            <>&nbsp;</>
          )}
        </Typography>
      </QCXFlatCard.TopBox>
      <QCXFlatCard.MiddleBox>
        <QCXFlatCard.IconBox>
          {iconComponent}
        </QCXFlatCard.IconBox>
        <QCXFlatCard.ContentBox
          enableScrollbar={overflowedDescription}
        >
          <Typography
            className={(
              isActive
                ? classes.cardTitleTypography
                : classes.cardTitleTypographyDisabled
            )}
          >
            {model?.description || '-'}
          </Typography>
        </QCXFlatCard.ContentBox>
      </QCXFlatCard.MiddleBox>
      <QCXFlatCard.ActionsBox>
        <QCXIconButton
          className={(
            isActive
              ? classes.cardIconButtonAction
              : classes.cardIconButtonActionDisabled
          )}
          tooltipProps={{
            title: t('com.muralis.qcx.alterarData'),
          }}
          onClick={handleEditDate}
          disabled={!isActive}
        >
          <EditIcon
            htmlColor={color}
            fontSize="default"
          />
        </QCXIconButton>
        <QCXIconButton
          className={(
            isActive
              ? classes.cardIconButtonAction
              : classes.cardIconButtonActionDisabled
          )}
          tooltipProps={{
            title: t('com.muralis.qcx.observacoes'),
          }}
          onClick={handleEditObservations}
          disabled={!isActive}
        >
          {hasObservacoes
            ? (
              <InfoIcon
                htmlColor={color}
                fontSize="default"
              />
            ) : (
              <InfoOutlinedIcon
                htmlColor={color}
                fontSize="default"
              />
            )}
        </QCXIconButton>
        <QCXIconButton
          className={classes.cardIconButtonAction}
          tooltipProps={inactivateReactivateButtonTooltipProps}
          onClick={handleInactivateOrReactivate}
          disabled={isCompleted}
        >
          {isActive
            ? (
              <DeleteForeverIcon
                htmlColor={inactivateReactivateButtonColor}
                fontSize="default"
              />
            ) : (
              <RestoreFromTrash
                htmlColor={inactivateReactivateButtonColor}
                fontSize="default"
              />
            )}
        </QCXIconButton>
      </QCXFlatCard.ActionsBox>
    </QCXFlatCard>
  );
}
