import { FormControlLabel } from '@material-ui/core';
import React from 'react';

export default function QCXFormControlLabel({ control, ...restProps }) {
  return (
    <FormControlLabel
      control={control}
      {...restProps}
    />
  );
}
