/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ListAltIcon from '@material-ui/icons/ListAltSharp';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InputIcon from '@material-ui/icons/InputRounded';
import PublicIcon from '@material-ui/icons/PublicRounded';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import LabelIcon from '@material-ui/icons/Label';
import DataUsageIcon from '@material-ui/icons/DataUsageRounded';
import DnsIcon from '@material-ui/icons/DnsRounded';
import TableChartIcon from '@material-ui/icons/TableChartRounded';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import ExtensionIcon from '@material-ui/icons/Extension';
import AssessmentIcon from '@material-ui/icons/Assessment';
import AssistantIcon from '@material-ui/icons/Assistant';

import { Grid, makeStyles } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import QCXCollapse from '../../shared-components/collapse/QCXCollapse';
import QCXListItemText from '../../shared-components/list-item-text/QCXListItemText';
import QCXListItemIcon from '../../shared-components/list-item-icon/QCXListItemIcon';
import QCXListItem from '../../shared-components/list-item/QCXListItem';
import QCXList from '../../shared-components/list/QCXList';
import QCXListMenu from '../../shared-components/list-menu/QCXListMenu';
import QCXFocusLock from '../focus-lock/QCXFocusLock';
import {
  ACESSOS_ACESSOS_TABELA_ADUANEIRA_BASE_ROLE_NAME,
  ACESSOS_CADASTROS_FINANCEIROS_BASE_ROLE_NAME,
  ACESSOS_CADASTROS_GERAIS_BASE_ROLE_NAME,
  ACESSOS_CADASTROS_OPERACIONAIS_BASE_ROLE_NAME,
  ACESSOS_IMPORTACAO_BASE_ROLE_NAME,
  ACESSOS_EXPORTACAO_BASE_ROLE_NAME,
  ACESSOS_FINANCEIRO_BASE_ROLE_NAME,
  ACESSOS_ASSESSORIA_BASE_ROLE_NAME,
  ACESSOS_COMERCIAL_BASE_ROLE_NAME,
  ACESSOS_INTEGRACAO_BASE_ROLE_NAME,
  ACESSOS_RELATORIOS_BASE_ROLE_NAME,
} from '../perfil/perfil-utils';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  grow: {
    alignSelf: 'bottom',
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  activeNavLink: {
    fontWeight: 'bold',
  },
}));

export default function QCXLateralMenu({ disableFocusLock, authInfo = {} }) {
  const { t } = useTranslation();

  const userHasAnyRole = (mainRoles = []) =>
    authInfo.roles?.some((role) => mainRoles.find((item) => role.startsWith(item)));
  const userHasAnyAccessModules = (mainAccess = []) =>
    authInfo.accessModules?.some((access) => mainAccess.find((item) => access === item));
  const classes = useStyles();

  // const [enterSubmenuTransition, setEnterSubmenuTransition] = useState(false);
  const [openRegistrationsSubmenu, setOpenRegistrationsSubmenu] = useState(false);

  const [activeGeneralRegistrationsSubmenu, setActiveGeneralRegistrationsSubmenu] = useState(false);
  const [activeOperationalRegistrationsSubmenu, setActiveOperationalRegistrationsSubmenu] = useState(false);
  const [activeImportacaoSubmenu, setActiveImportacaoSubmenu] = useState(false);
  const [activeExportacaoSubmenu, setActiveExportacaoSubmenu] = useState(false);
  const [activeFinancialRegistrationsSubmenu, setActiveFinancialRegistrationsSubmenu] = useState(false);
  const [activeFinancialSubmenu, setActiveFinancialSubmenu] = useState(false);
  const [activeComercialSubmenu, setActiveComercialSubmenu] = useState(false);
  const [activeIntegracaoSubmenu, setActiveIntegracaoSubmenu] = useState(false);
  const [activeRelatoriosSubmenu, setActiveRelatoriosSubmenu] = useState(false);
  const [activeAssessoriaSubMenu, setActiveAssessoriaSubMenu] = useState(false);

  const toggleRegistrationsSubmenu = useCallback(() => {
    setOpenRegistrationsSubmenu(!openRegistrationsSubmenu);
  }, [openRegistrationsSubmenu, setOpenRegistrationsSubmenu]);

  const isActiveGeneralRegistrationsSubmenu = useCallback(
    (match) => {
      const active = !!match;
      setActiveGeneralRegistrationsSubmenu(active);
      return active;
    },
    [setActiveGeneralRegistrationsSubmenu]
  );

  const isActiveOperationalRegistrationsSubmenu = useCallback(
    (match) => {
      const active = !!match;
      setActiveOperationalRegistrationsSubmenu(active);
      return active;
    },
    [setActiveOperationalRegistrationsSubmenu]
  );

  const isActiveImportacaoSubmenu = useCallback(
    (match) => {
      const active = !!match;
      setActiveImportacaoSubmenu(active);
      return active;
    },
    [setActiveImportacaoSubmenu]
  );

  const isActiveExportacaoSubmenu = useCallback(
    (match) => {
      const active = !!match;
      setActiveExportacaoSubmenu(active);
      return active;
    },
    [setActiveExportacaoSubmenu]
  );

  const isActiveFinancialRegistrationsSubmenu = useCallback(
    (match) => {
      const active = !!match;
      setActiveFinancialRegistrationsSubmenu(active);
      return active;
    },
    [setActiveFinancialRegistrationsSubmenu]
  );

  const isActiveFinancialSubmenu = useCallback(
    (match) => {
      const active = !!match;
      setActiveFinancialSubmenu(active);
      return active;
    },
    [setActiveFinancialSubmenu]
  );

  const isActiveComercialSubmenu = useCallback(
    (match) => {
      const active = !!match;
      setActiveComercialSubmenu(active);
      return active;
    },
    [setActiveComercialSubmenu]
  );

  const isActiveIntegracaoSubmenu = useCallback(
    (match) => {
      const active = !!match;
      setActiveIntegracaoSubmenu(active);
      return active;
    },
    [setActiveIntegracaoSubmenu]
  );

  // isActiveRegistrationsSubmenu
  const isActiveRelatoriosSubmenu = useCallback(
    (match) => {
      const active = !!match;
      setActiveRelatoriosSubmenu(active);
      return active;
    },
    [setActiveRelatoriosSubmenu]
  );

  const isActiveAssessoriaSubmenu = useCallback(
    (match) => {
      const active = !!match;
      setActiveAssessoriaSubMenu(active);
      return active;
    },
    [setActiveAssessoriaSubMenu]
  );

  const expandComponent = useMemo(() => {
    if (
      !(
        activeGeneralRegistrationsSubmenu ||
        activeFinancialRegistrationsSubmenu ||
        activeOperationalRegistrationsSubmenu
      )
    ) {
      return openRegistrationsSubmenu ? <ExpandLessIcon /> : <ExpandMoreIcon />;
    }
    return undefined;
  }, [
    openRegistrationsSubmenu,
    activeGeneralRegistrationsSubmenu,
    activeFinancialRegistrationsSubmenu,
    activeOperationalRegistrationsSubmenu,
  ]);

  return (
    <Grid container style={{ minWidth: '100%', minHeight: '100%' }} alignContent="space-between">
      <QCXFocusLock disabled={disableFocusLock}>
        <Grid item style={{ minWidth: '100%' }}>
          <QCXListMenu>
            <QCXListItem
              button
              key="cadastros"
              hidden={
                !userHasAnyRole([
                  ...ACESSOS_ACESSOS_TABELA_ADUANEIRA_BASE_ROLE_NAME,
                  ...ACESSOS_CADASTROS_FINANCEIROS_BASE_ROLE_NAME,
                  ...ACESSOS_CADASTROS_GERAIS_BASE_ROLE_NAME,
                  ...ACESSOS_CADASTROS_OPERACIONAIS_BASE_ROLE_NAME,
                  ...ACESSOS_FINANCEIRO_BASE_ROLE_NAME,
                ]) || !userHasAnyAccessModules(['CADASTROS'])
              }
              onClick={toggleRegistrationsSubmenu}
            >
              <QCXListItemIcon>
                <ListAltIcon color="primary" />
              </QCXListItemIcon>
              <QCXListItemText
                primary={t('com.muralis.qcx.cadastros')}
                isActive={
                  activeGeneralRegistrationsSubmenu ||
                  activeFinancialRegistrationsSubmenu ||
                  activeOperationalRegistrationsSubmenu
                }
              />
              {expandComponent}
            </QCXListItem>
            <QCXCollapse
              in={
                openRegistrationsSubmenu ||
                activeGeneralRegistrationsSubmenu ||
                activeFinancialRegistrationsSubmenu ||
                activeOperationalRegistrationsSubmenu
              }
              timeout="auto"
              enter={openRegistrationsSubmenu}
            >
              <QCXList component="div" disablePadding>
                <QCXListItem
                  button
                  component={NavLink}
                  className={classes.nested}
                  to={t('com.muralis.qcx.url.moduloCadastrosGerais')}
                  isActive={isActiveGeneralRegistrationsSubmenu}
                  hidden={!userHasAnyRole(ACESSOS_CADASTROS_GERAIS_BASE_ROLE_NAME)}
                  selected={activeGeneralRegistrationsSubmenu}
                >
                  <QCXListItemIcon>
                    <LabelIcon color={activeGeneralRegistrationsSubmenu ? 'secondary' : 'primary'} />
                  </QCXListItemIcon>
                  <QCXListItemText primary={t('com.muralis.qcx.gerais')} isActive={activeGeneralRegistrationsSubmenu} />
                </QCXListItem>
                <QCXListItem
                  button
                  component={NavLink}
                  className={classes.nested}
                  to={t('com.muralis.qcx.url.moduloCadastrosOperacionais')}
                  hidden={!userHasAnyRole(ACESSOS_CADASTROS_OPERACIONAIS_BASE_ROLE_NAME)}
                  isActive={isActiveOperationalRegistrationsSubmenu}
                  selected={activeOperationalRegistrationsSubmenu}
                >
                  <QCXListItemIcon>
                    <DnsIcon color={activeOperationalRegistrationsSubmenu ? 'secondary' : 'primary'} />
                  </QCXListItemIcon>
                  <QCXListItemText
                    primary={t('com.muralis.qcx.operacionais')}
                    isActive={activeOperationalRegistrationsSubmenu}
                  />
                </QCXListItem>
                <QCXListItem
                  button
                  component={NavLink}
                  className={classes.nested}
                  to={t('com.muralis.qcx.url.moduloCadastrosFinanceiros')}
                  hidden={!userHasAnyRole(ACESSOS_CADASTROS_FINANCEIROS_BASE_ROLE_NAME)}
                  isActive={isActiveFinancialRegistrationsSubmenu}
                  selected={activeFinancialRegistrationsSubmenu}
                >
                  <QCXListItemIcon>
                    <DataUsageIcon color={activeFinancialRegistrationsSubmenu ? 'secondary' : 'primary'} />
                  </QCXListItemIcon>
                  <QCXListItemText
                    primary={t('com.muralis.qcx.financeiro.labelPlural')}
                    isActive={activeFinancialRegistrationsSubmenu}
                  />
                </QCXListItem>
                <QCXListItem
                  button
                  disabled
                  className={classes.nested}
                  hidden
                  // hidden={!userHasAnyRole(ACESSOS_ACESSOS_TABELA_ADUANEIRA_BASE_ROLE_NAME)}
                >
                  <QCXListItemIcon>
                    <TableChartIcon color="primary" />
                  </QCXListItemIcon>
                  <QCXListItemText primary={t('com.muralis.qcx.tabelasAduaneiras')} />
                </QCXListItem>
              </QCXList>
            </QCXCollapse>
            <QCXListItem
              button
              key="assessoria"
              component={NavLink}
              to="/assessoria"
              hidden={!userHasAnyRole(ACESSOS_ASSESSORIA_BASE_ROLE_NAME)}
              isActive={isActiveAssessoriaSubmenu}
              selected={activeAssessoriaSubMenu}
            >
              <QCXListItemIcon>
                <AssistantIcon color={activeAssessoriaSubMenu ? 'secondary' : 'primary'} />
              </QCXListItemIcon>
              <QCXListItemText primary="Assessoria" isActive={activeAssessoriaSubMenu} />
            </QCXListItem>
            <QCXListItem
              button
              key="modulo-importacao"
              component={NavLink}
              to={t('com.muralis.qcx.url.moduloImportacao')}
              hidden={!userHasAnyRole(ACESSOS_IMPORTACAO_BASE_ROLE_NAME) || !userHasAnyAccessModules(['IMPORTACAO'])}
              isActive={isActiveImportacaoSubmenu}
              selected={activeImportacaoSubmenu}
            >
              <QCXListItemIcon>
                <InputIcon color={activeImportacaoSubmenu ? 'secondary' : 'primary'} />
              </QCXListItemIcon>
              <QCXListItemText primary={t('com.muralis.qcx.importacao.label')} isActive={activeImportacaoSubmenu} />
            </QCXListItem>
            <QCXListItem
              button
              key="modulo-exportacao"
              component={NavLink}
              to={t('com.muralis.qcx.url.moduloExportacao')}
              hidden={!userHasAnyRole(ACESSOS_EXPORTACAO_BASE_ROLE_NAME) || !userHasAnyAccessModules(['EXPORTACAO'])}
              isActive={isActiveExportacaoSubmenu}
              selected={activeExportacaoSubmenu}
            >
              <QCXListItemIcon>
                <PublicIcon color={activeExportacaoSubmenu ? 'secondary' : 'primary'} />
              </QCXListItemIcon>
              <QCXListItemText primary={t('com.muralis.qcx.exportacao.label')} isActive={activeExportacaoSubmenu} />
            </QCXListItem>
            <QCXListItem
              button
              key="financeiro"
              component={NavLink}
              to={t('com.muralis.qcx.url.moduloFinanceiro')}
              hidden={!userHasAnyRole(ACESSOS_FINANCEIRO_BASE_ROLE_NAME) || !userHasAnyAccessModules(['FINANCEIRO'])}
              isActive={isActiveFinancialSubmenu}
              selected={activeFinancialSubmenu}
            >
              <QCXListItemIcon>
                <AccountBalanceIcon color={activeFinancialSubmenu ? 'secondary' : 'primary'} />
              </QCXListItemIcon>
              <QCXListItemText primary={t('com.muralis.qcx.financeiro.label')} isActive={activeFinancialSubmenu} />
            </QCXListItem>
            <QCXListItem
              button
              key="comercial"
              component={NavLink}
              to={t('com.muralis.qcx.url.moduloComercial')}
              hidden={!userHasAnyRole(ACESSOS_COMERCIAL_BASE_ROLE_NAME) || !userHasAnyAccessModules(['COMERCIAL'])}
              isActive={isActiveComercialSubmenu}
              selected={activeComercialSubmenu}
            >
              <QCXListItemIcon>
                <MonetizationOnIcon color={activeComercialSubmenu ? 'secondary' : 'primary'} />
              </QCXListItemIcon>
              <QCXListItemText primary={t('com.muralis.qcx.comercial.label')} isActive={activeComercialSubmenu} />
            </QCXListItem>
            <QCXListItem
              button
              key="integracao"
              component={NavLink}
              to="/integracao"
              hidden={!userHasAnyRole(ACESSOS_INTEGRACAO_BASE_ROLE_NAME) || !userHasAnyAccessModules(['INTEGRACAO'])}
              isActive={isActiveIntegracaoSubmenu}
              selected={activeIntegracaoSubmenu}
            >
              <QCXListItemIcon>
                <ExtensionIcon color={activeIntegracaoSubmenu ? 'secondary' : 'primary'} />
              </QCXListItemIcon>
              <QCXListItemText primary="Integração" isActive={activeIntegracaoSubmenu} />
            </QCXListItem>
            <QCXListItem
              button
              key="relatorios"
              component={NavLink}
              to="/relatorios"
              hidden={!userHasAnyRole(ACESSOS_RELATORIOS_BASE_ROLE_NAME) || !userHasAnyAccessModules(['RELATORIOS'])}
              isActive={isActiveRelatoriosSubmenu}
              selected={activeRelatoriosSubmenu}
            >
              <QCXListItemIcon>
                <AssessmentIcon color={activeRelatoriosSubmenu ? 'secondary' : 'primary'} />
              </QCXListItemIcon>
              <QCXListItemText primary="Relatórios" isActive={activeRelatoriosSubmenu} />
            </QCXListItem>
          </QCXListMenu>
        </Grid>
      </QCXFocusLock>
    </Grid>
  );
}
