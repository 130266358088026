import {

  Grid,
} from '@material-ui/core';
import { isEmpty } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { validate } from 'uuid';
import { fetchById as fetchDespesaById } from '../../features/despesa-receita/despesaReceitaAPI';
import { failure, setError } from '../../features/despesa-receita/despesaReceitaSlice';
import QCXFinalAlternativeCurrencyField from '../../shared-components/final-currency-field/QCXFinalAlternativeCurrencyField';
import QCXSelectMoedaAutocomplete from '../../shared-components/final-select-moeda-field/QCXSelectMoedaAutocomplete';
import QCXFormStepWizard from '../../shared-components/form-step-wizard/QCXFormStepWizard';
import QCXSelectDespesaAutocomplete from '../../shared-components/select-despesa-receita/QCXSelectDespesaAutocomplete';
import QCXSelectTipoCalculoAutocomplete from '../../shared-components/select-tipo-calculo/QCXSelectTipoCalculoAutocomplete';
import QCXRegistrationOneToManyPageTemplate from '../../templates/registration-one-to-many-page/QCXRegistrationOneToManyPageTemplate';
import { forceUnnormalizeNumeral, isValid } from '../../utils/general/general-utils';
import { formatBrazilianNumericDecimal } from '../../utils/hooks/form/field/formatters';
import { required as requiredValidator, maxValue } from '../../utils/validators/field/validator';
import useValidators from '../../utils/hooks/useValidators';

export default function QCXDespesaFinalFormManager({
  isConsult,
  isUpdate,
  isSubNone,
  isSubCreate,
  isSubConsult,
  isSubUpdate,
  model,
  list,
  handleSubmit,
  handleNew,
  handleEdit,
  handleCancelCreate,
  handleCancelUpdate,
  handleRemove,
  handleRowClick,
  handleClearForm,
  controlComponentProps,
  ...restProps
}) {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const handleDespesaReceitaChange = useCallback((form) => async (value) => {
    if (!(isSubCreate || isSubUpdate)) {
      return;
    }

    if (value) {
      try {
        const response = await fetchDespesaById(value);

        if (response?.status === 200) {
          const foundDespesaReceita = response?.data;

          if (foundDespesaReceita && !isEmpty(foundDespesaReceita)) {
            form.change('description', foundDespesaReceita?.description);
            form.change('tipo', foundDespesaReceita?.tipo);
            form.change('tipoCalculo', undefined);
            form.change('tipoCalculo.id', foundDespesaReceita?.tipoCalculo.id);
            form.change('moeda', undefined);
            form.change('moeda.id', foundDespesaReceita?.moeda?.id);
          }
        }
      } catch (error) {
        const defaultMessage = t('com.muralis.qcx.mensagem.carregarDadosDespesaSelecionada');
        let fullMessage = defaultMessage;

        if (error?.response) {
          fullMessage = `${defaultMessage}: ${error?.response?.data?.message}`;
        }

        if (error?.message) {
          fullMessage = `${defaultMessage}: ${error?.message}`;
        }

        dispatch(failure());
        dispatch(setError({
          message: fullMessage,
        }));
      }

      return;
    }

    form.change('description', '');
    form.change('tipo', '');
    form.change('tipoCalculo', undefined);
    form.change('moeda', undefined);
  }, [
    isSubCreate,
    isSubUpdate,
  ]);

  const listenerList = useMemo(() => ([
    {
      name: 'despesaReceita.id',
      fn: handleDespesaReceitaChange,
    },
  ]), [
    handleDespesaReceitaChange,
  ]);

  const valorValidators = useMemo(() => (
    useValidators([
      requiredValidator,
      maxValue(999999999999.99),
    ])
  ), []);

  const configureRestartFormHandler = useCallback((form) => {
    const handleRestartForm = () => {
      if (form) {
        form.restart({});
        form.initialize({
          valor: '',
        });
      }
    };

    return handleRestartForm;
  }, []);

  const configureReinitializeHandler = useCallback((form) => {
    const handleReinitialize = () => {
      if (!isEmpty(form)) {
        form.restart(model);
      }
    };

    return handleReinitialize;
  }, [model]);

  const tableProps = useMemo(() => ({
    title: t('com.muralis.qcx.despesa.despesasAdicionadas'),
    columns: [
      {
        field: 'id',
        headerName: t('com.muralis.qcx.codigo'),
        headerAlign: 'center',
        align: 'center',
        flex: 150,
        valueFormatter: ({ row }) => (
          validate(row?.id)
            ? '-'
            : row?.id
        ),
      },
      {
        field: 'description',
        headerName: t('com.muralis.qcx.descricao'),
        flex: 600,
        valueGetter: ({ row }) => (
          row?.description
        ),
      },
      {
        field: 'valor',
        headerName: t('com.muralis.qcx.valor'),
        headerAlign: 'right',
        align: 'right',
        flex: 160,
        valueFormatter: ({ row: { valor } }) => (
          forceUnnormalizeNumeral(
            valor,
            formatBrazilianNumericDecimal(2)
          )
        ),
      },
    ],
  }), []);

  const localeTableText = useMemo(() => ({
    noRowsLabel: t('com.muralis.qcx.validacao.nenhumaDespesaAdicionada'),
  }), []);

  return (
    <QCXFormStepWizard
      initialValues={model}
      onSubmit={handleSubmit}
      controlComponentProps={controlComponentProps}
      onChangeListeners={listenerList}
      {...restProps}
    >
      {({ form, values, submitSucceeded }) => (
        <QCXRegistrationOneToManyPageTemplate
          title={t('com.muralis.qcx.despesa.labelPlural')}
          addButtonText={t('com.muralis.qcx.nova').toUpperCase()}
          tableProps={tableProps}
          tableData={list}
          isConsult={isConsult}
          isUpdate={isUpdate}
          isSubNone={isSubNone}
          isSubCreate={isSubCreate}
          isSubConsult={isSubConsult}
          isSubUpdate={isSubUpdate}
          submitSucceeded={submitSucceeded}
          handleNew={handleNew}
          handleEdit={handleEdit}
          handleCancelCreate={handleCancelCreate}
          handleCancelUpdate={handleCancelUpdate}
          handleRemove={handleRemove}
          handleClearForm={handleClearForm}
          handleRestartForm={configureRestartFormHandler(form)}
          handleRowClick={handleRowClick}
          localeTableText={localeTableText}
          filterPropGetter={(row) => row?.description}
          handleReinitialize={configureReinitializeHandler(form)}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
          >
            <QCXSelectDespesaAutocomplete
              id="autocomplete-select-field-despesa-receita"
              key="autocomplete-select-field-despesa-receita"
              name="despesaReceita.id"
              label={t('com.muralis.qcx.despesa.label')}
              initialValues={values}
              fieldProps={{
                validate: requiredValidator,
              }}
              disabled={isConsult || isSubConsult || isSubNone}
              required
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
          >
            <QCXSelectTipoCalculoAutocomplete
              id="select-field-tipo-calculo"
              key="select-field-tipo-calculo"
              name="tipoCalculo.id"
              label={t('com.muralis.qcx.tipoCalculo')}
              initialValues={values}
              disabled
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={8}
            md={4}
          >
            <QCXSelectMoedaAutocomplete
              id="select-field-moeda-negociada"
              key="select-field-moeda-negociada"
              name="moeda.id"
              label={t('com.muralis.qcx.moeda.moedaNegociada')}
              fieldProps={{ validate: requiredValidator }}
              initialValues={values}
              disabled={(
                isConsult
                || isSubConsult
                || isSubNone
                || !isValid(values?.despesaReceita?.id)
              )}
              required
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            md={4}
          >
            <QCXFinalAlternativeCurrencyField
              id="currency-field-modelo-lancamento-valor"
              key="currency-field-modelo-lancamento-valor"
              name="valor"
              label={t('com.muralis.qcx.valor')}
              validate={valorValidators}
              maxLength={21}
              submitSucceeded={submitSucceeded}
              disabled={(
                isConsult
                || isSubConsult
                || isSubNone
                || !isValid(values?.despesaReceita?.id)
              )}
              required
            />
          </Grid>
        </QCXRegistrationOneToManyPageTemplate>
      )}
    </QCXFormStepWizard>
  );
}
