import { useTheme } from '@material-ui/core';
import { Box, Grid, Typography } from '@mui/material';
import { ILeituraError } from '../totvsLeituraPage.types';

const TotvsLeituraPageError = (props: {
  errors: ILeituraError[];
  warnings: ILeituraError[];
}) => {
  const theme = useTheme();
  return (
    <Grid item xs={12}>
      <Box
        border={`1px solid ${theme.palette.primary.main}`}
        borderRadius={2}
        padding={2}
        lineHeight={0.5}
      >
        {props.errors.map((error) => (
          <Typography color="primary">{'Erro: ' + error.mensagem}</Typography>
        ))}
        {/* {props.warnings.map((warning) => (
          <Typography color="secondary">
            {'Aviso: ' + warning.mensagem}
          </Typography>
        ))} */}
      </Box>
    </Grid>
  );
};

export default TotvsLeituraPageError;
