import {
  doPostQCXRequest,
} from '../../utils/api/domain/domain-api-utils';
import {
  QCX_ESTORNO_ENDPOINT,
  QCX_SALDO_ENDPOINT,
  QCX_DANFE_ADICAO_MOVIMENTACAO_ENDPOINT,
  QCX_TRANSFERENCIA_ENDPOINT,
} from '../../utils/api/domain/domain-endpoint-utils';

async function fetchBalancesByOwners(data, queryParams = []) {
  return doPostQCXRequest(
    [
      QCX_DANFE_ADICAO_MOVIMENTACAO_ENDPOINT,
      QCX_SALDO_ENDPOINT,
    ],
    data,
    queryParams
  );
}

async function reverse(data, queryParams = []) {
  return doPostQCXRequest(
    [
      QCX_DANFE_ADICAO_MOVIMENTACAO_ENDPOINT,
      QCX_ESTORNO_ENDPOINT,
    ],
    data,
    queryParams
  );
}

async function transfer(data, queryParams = []) {
  return doPostQCXRequest(
    [
      QCX_DANFE_ADICAO_MOVIMENTACAO_ENDPOINT,
      QCX_TRANSFERENCIA_ENDPOINT,
    ],
    data,
    queryParams
  );
}

const danfeAdicaoMovimentacaoAPI = {
  fetchBalancesByOwners,
  reverse,
  transfer,
};

export {
  danfeAdicaoMovimentacaoAPI,
  fetchBalancesByOwners,
  reverse,
  transfer,
};
