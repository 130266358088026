import { useCallback, useMemo } from 'react';

export default function useIpiCommonFormGroupListeners() {
  const handleIpiNaoTributavelChange = useCallback((form) => (value) => {
    if (value) {
      form.change('ipi.regimeTributacao', undefined);
      form.change('ipi.notaComplementarTipi', undefined);
      form.change('ipi.aliquota', undefined);
      form.change('ipi.reduzida', undefined);
    }
  }, []);

  const onChangeListeners = useMemo(() => ([
    {
      name: 'ipi.ipiNaoTributavel',
      fn: handleIpiNaoTributavelChange,
    },
  ]), [
    handleIpiNaoTributavelChange,
  ]);

  return onChangeListeners;
}
