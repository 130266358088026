import { Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { Trans } from 'react-i18next';

const useStyles = makeStyles(() => ({
  footer: {
    borderTop: '1px dashed #bdbdbd',
    alignItems: 'center',
    background: '#fdfdfd',
    marginLeft: '-24px',
    width: 'calc(100% + 24px)',
    height: '57px',
    alignSelf: 'flex-end',
    marginBottom: '0',
  },
}));

export default function QCXFooter(props) {
  const classes = useStyles();
  const year = new Date().getFullYear();

  return (
    <Grid
      container
      item
      direction="row"
      justify="center"
      alignItems="flex-end"
      className={classes.footer}
      {...props}
    >
      <Typography variant="caption">
        <Trans i18nKey="com.muralis.qcx.footer">
          {{
            ano: year,
          }}
          <a href="https://www.quickcomex.com.br" target="_blank" rel="noreferrer">{{ nomeEmpresa: 'Quick Comex' }}</a>
        </Trans>
      </Typography>
    </Grid>
  );
}
