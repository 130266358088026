import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';
import { getTipoModeloLabelByValue } from '../../shared-components/select-tipo-modelo/QCXFinalSelectTipoModeloAutocomplete';
import { isSubCreateMode } from '../../utils/store/store-utils';
import {
  BACKGROUND_CREATE_MODE,
  CONSULT_MODE,
  CREATE_MODE,
  NONE_BACKGROUND_MODE,
  NONE_MODE,
  NONE_SUB_MODE,
  SUB_CONSULT_MODE,
  SUB_CREATE_MODE,
  SUB_UPDATE_MODE,
  UPDATE_MODE,
} from '../mode';
import {
  ALTERNATIVE_LOADING_STATUS,
  FAILURE_STATUS,
  IDLE_STATUS,
  LOADING_STATUS,
  PREPARING_ACTION_STATUS,
  SUCCESS_STATUS,
} from '../status';
import {
  fetchAll,
  fetchById,
  activateById,
  inactivateById,
} from './modeloFaturamentoAPI';
import i18n from '../../i18n';

const initialState = {
  status: IDLE_STATUS,
  mode: {
    main: NONE_MODE,
    background: NONE_BACKGROUND_MODE,
    sub: NONE_SUB_MODE,
  },
  config: {
    conditionalSteps: [
      i18n.t('com.muralis.qcx.agenda'),
    ],
  },
  error: null,
  response: {
    status: null,
    message: null,
    data: null,
  },
  list: [],
  model: {
    id: null,
    insertionDate: null,
    code: null,
    description: null,
    active: null,
    automatico: null,
    agenda: {
      id: null,
      insertionDate: null,
      porEtapa: null,
      porDespesaReceita: null,
      processoAProcesso: null,
      frequencia: null,
      despesaReceita: {
        id: null,
      },
      etapa: {
        id: null,
      },
    },
    etapa: {
      id: null,
    },
    planoDeConta: {
      id: null,
    },
    modelosLancamento: [],
    viaTransporte: {
      id: null,
    },
  },
  related: {
    model: {
      modeloLancamento: {},
    },
  },
  locked: {
    model: {
      modeloLancamento: {},
    },
  },
};

const fetchAllAsync = createAsyncThunk(
  'modeloFaturamento/fetchAll',
  async () => {
    const { data } = await fetchAll();
    return { data };
  }
);

const fetchByIdAsync = createAsyncThunk(
  'modeloFaturamento/fetchById',
  async (data) => {
    const response = await fetchById(data);
    return {
      response: {
        status: response.status,
        data: response.data,
      },
    };
  }
);

const activateByIdAsync = createAsyncThunk(
  'modeloFaturamento/activateById',
  async (id) => {
    const { status, data } = await activateById(id);
    return { response: { status, data } };
  }
);

const inactivateByIdAsync = createAsyncThunk(
  'modeloFaturamento/inactivateById',
  async (id) => {
    const { status, data } = await inactivateById(id);
    return { response: { status, data } };
  }
);

const modeloFaturamentoSlice = createSlice({
  name: 'modeloFaturamento',
  initialState,
  reducers: {
    changeStatusTo: (state, action) => {
      state.status = action.payload.status;
    },
    loading: (state) => {
      state.status = LOADING_STATUS;
    },
    success: (state) => {
      state.status = SUCCESS_STATUS;
    },
    failure: (state) => {
      state.status = FAILURE_STATUS;
    },
    preparingAction: (state) => {
      state.status = PREPARING_ACTION_STATUS;
    },
    resetStatus: (state) => {
      state.status = IDLE_STATUS;
    },
    changeToCreateMode: (state) => {
      state.mode.main = CREATE_MODE;
    },
    changeToConsultMode: (state) => {
      state.mode.main = CONSULT_MODE;
    },
    changeToUpdateMode: (state) => {
      state.mode.main = UPDATE_MODE;
    },
    resetMode: (state) => {
      state.mode.main = NONE_MODE;
    },
    changeToBackgroundCreateMode: (state) => {
      state.mode.background = BACKGROUND_CREATE_MODE;
    },
    resetBackgroundMode: (state) => {
      state.mode.background = NONE_BACKGROUND_MODE;
    },
    changeToSubCreateMode: (state) => {
      state.mode.sub = SUB_CREATE_MODE;
    },
    changeToSubConsultMode: (state) => {
      state.mode.sub = SUB_CONSULT_MODE;
    },
    changeToSubUpdateMode: (state) => {
      state.mode.sub = SUB_UPDATE_MODE;
    },
    resetSubMode: (state) => {
      state.mode.sub = NONE_SUB_MODE;
    },
    setModel: (state, action) => {
      const updatedModelosLancamentoList = action.payload.modelosLancamento.map(
        (current) => ((current?.id === null)
          ? {
            ...current,
            id: uuid(),
          }
          : current
        )
      );

      state.model = {
        ...action.payload,
        modelosLancamento: updatedModelosLancamentoList,
      };
    },
    resetModel: (state) => {
      state.model = initialState.model;
    },
    setRelatedModeloLancamentoModel: (state, action) => {
      state.related.model.modeloLancamento = {
        ...action.payload,
      };
      state.mode.sub = SUB_CONSULT_MODE;
    },
    lockSelectedModeloLancamento: (state) => {
      state.locked.model.modeloLancamento = state.related.model.modeloLancamento;
    },
    resetLockedModeloLancamentoModel: (state) => {
      state.locked.model.modeloLancamento = {};
    },
    addToList: (state, action) => {
      state.list = [...state.list, action.payload.data];
    },
    updateOnList: (state, action) => {
      state.list = state.list.map((current) => {
        if (current.id === action.payload.data?.id) {
          return action.payload.data;
        }
        return current;
      });
    },
    resetList: (state) => {
      state.list = [];
    },
    addModeloLancamento: (state, action) => {
      state.status = LOADING_STATUS;

      const newModeloLancamento = action.payload.data;

      const alreadyExists = state.model.modelosLancamento
        .some((existingModeloLancamento) => {
          const relatedDespesaReceitaIdFromExisting = existingModeloLancamento?.despesaReceita?.id;
          const relatedDespesaReceitaIdFromNew = newModeloLancamento?.despesaReceita?.id;

          return relatedDespesaReceitaIdFromExisting === relatedDespesaReceitaIdFromNew;
        });

      if (alreadyExists) {
        const tipoModeloLabelOfNewModeloLancamento = getTipoModeloLabelByValue(
          newModeloLancamento?.tipo
        );

        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.mensagem.tipoModeloAdicionadoModeloFaturamento', { despesaReceita: tipoModeloLabelOfNewModeloLancamento, despesaReceitaId: newModeloLancamento?.despesaReceita?.id }),
        };
        return;
      }

      state.model.modelosLancamento = [
        ...state.model.modelosLancamento,
        {
          ...newModeloLancamento,
          id: uuid(),
        }
      ];
      state.related.model.modeloLancamento = {};
      state.mode.sub = NONE_SUB_MODE;
      state.status = IDLE_STATUS;
    },
    updateModeloLancamento: (state, action) => {
      const updated = action.payload.data;

      const alreadyExists = state.model.modelosLancamento
        .filter((existingModeloLancamento) => {
          const relatedDespesaReceitaIdFromExisting = existingModeloLancamento?.despesaReceita?.id;
          const relatedDespesaReceitaIdFromLocked = (
            state.locked.model.modeloLancamento?.despesaReceita?.id
          );

          return relatedDespesaReceitaIdFromExisting !== relatedDespesaReceitaIdFromLocked;
        })
        .some((existingModeloLancamento) => {
          const relatedDespesaReceitaIdFromExisting = existingModeloLancamento?.despesaReceita?.id;
          const relatedDespesaReceitaIdFromUpdated = updated?.despesaReceita?.id;

          return relatedDespesaReceitaIdFromExisting === relatedDespesaReceitaIdFromUpdated;
        });

      if (alreadyExists) {
        const tipoModeloLabelOfUpdatedModeloLancamento = getTipoModeloLabelByValue(
          updated?.tipo
        );

        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.mensagem.tipoModeloExisteModeloFaturamento', { despesaReceita: tipoModeloLabelOfUpdatedModeloLancamento, despesaReceitaId: updated?.despesaReceita?.id }),
        };
        return;
      }

      state.related.model.modeloLancamento = updated;
      state.model.modelosLancamento = state.model.modelosLancamento.map(
        (current) => ((current?.id === updated?.id)
          ? updated : current
        )
      );
      state.mode.sub = SUB_CONSULT_MODE;
      state.locked.model.modeloLancamento = {};
    },
    removeModeloLancamentoById: (state, action) => {
      const { id } = action.payload.data;
      state.model.modelosLancamento = state.model.modelosLancamento.filter((current) => (
        current?.id !== id
      ));
      state.related.model.modeloLancamento = initialState.related.model.modeloLancamento;
      state.mode.sub = NONE_SUB_MODE;
    },
    resetRelatedModeloLancamentoModel: (state) => {
      state.related.model.modeloLancamento = {};

      if (!isSubCreateMode(state.mode.sub)) {
        state.mode.sub = NONE_SUB_MODE;
      }
    },
    setResponse: (state, action) => {
      state.response = action.payload;
    },
    clearResponse: (state) => {
      state.response = {
        status: null,
        message: null,
        data: null,
      };
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    clearError: (state) => {
      state.error = initialState.error;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllAsync.pending, (state) => {
        state.status = ALTERNATIVE_LOADING_STATUS;
      })
      .addCase(fetchAllAsync.fulfilled, (state, action) => {
        state.status = IDLE_STATUS;
        state.list = action.payload.data;
      })
      .addCase(fetchAllAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroCarregarListaModelosFaturamento', { erro: action.error.message }),
        };
      })
      .addCase(fetchByIdAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(fetchByIdAsync.fulfilled, (state, action) => {
        if (action.payload.response.status === 200) {
          state.model = action.payload.response.data;
          state.status = SUCCESS_STATUS;
        }
      })
      .addCase(fetchByIdAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroCarregarModeloFaturamento', { erro: action.error.message }),
        };
      })
      .addCase(activateByIdAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(activateByIdAsync.fulfilled, (state, action) => {
        state.status = SUCCESS_STATUS;
        state.response.status = action.payload.response.status;
        state.response.data = action.payload.response.data;

        const { description } = action.payload.response.data;
        state.response.message = i18n.t('com.muralis.qcx.mensagem.modeloFaturamentoAtivado', { modelo: description });

        if (state.response.status === 200) {
          state.list = state.list
            .map((current) => (
              current.id === action.payload.response.data.id
                ? action.payload.response.data
                : current
            ));
        }
      })
      .addCase(activateByIdAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroAtivarModeloFaturamento', { erro: action.error.message }),
        };
      })
      .addCase(inactivateByIdAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(inactivateByIdAsync.fulfilled, (state, action) => {
        state.status = SUCCESS_STATUS;
        state.response.status = action.payload.response.status;
        state.response.data = action.payload.response.data;

        const { description } = action.payload.response.data;
        state.response.message = i18n.t('com.muralis.qcx.mensagem.modeloFaturamentoInativado', { modelo: description });

        if (state.response.status === 200) {
          state.list = state.list
            .map((current) => (
              current.id === action.payload.response.data.id
                ? action.payload.response.data
                : current
            ));
        }
      })
      .addCase(inactivateByIdAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroInativarModeloFaturamento', { erro: action.error.message }),
        };
      });
  },
});

const {
  changeStatusTo,
  loading,
  success,
  failure,
  preparingAction,
  resetStatus,
  changeToCreateMode,
  changeToConsultMode,
  changeToUpdateMode,
  resetMode,
  changeToBackgroundCreateMode,
  resetBackgroundMode,
  changeToSubCreateMode,
  changeToSubConsultMode,
  changeToSubUpdateMode,
  resetSubMode,
  setModel,
  resetModel,
  addToList,
  updateOnList,
  resetList,
  setRelatedModeloLancamentoModel,
  addModeloLancamento,
  updateModeloLancamento,
  removeModeloLancamentoById,
  resetRelatedModeloLancamentoModel,
  lockSelectedModeloLancamento,
  resetLockedModeloLancamentoModel,
  setResponse,
  setResponseMessage,
  clearResponse,
  setError,
  clearError,
} = modeloFaturamentoSlice.actions;

const selectModelosFaturamento = (state) => state.modeloFaturamento.list;
const selectStatus = (state) => state.modeloFaturamento.status;
const selectMode = (state) => state.modeloFaturamento.mode.main;
const selectBackgroundMode = (state) => state.modeloFaturamento.mode.background;
const selectSubMode = (state) => state.modeloFaturamento.mode.sub;
const selectError = (state) => state.modeloFaturamento.error;
const selectResponse = (state) => state.modeloFaturamento.response;
const selectModeloFaturamento = (state) => state.modeloFaturamento.model;
const selectRelatedModeloLancamentoModel = (state) => (
  state.modeloFaturamento.related.model.modeloLancamento
);
const selectLockedModeloLancamentoModel = (state) => (
  state.modeloFaturamento.locked.model.modeloLancamento
);
const selectConditionalSteps = (state) => (
  state.modeloFaturamento.config.conditionalSteps
);

export {
  modeloFaturamentoSlice,
  changeStatusTo,
  loading,
  success,
  failure,
  preparingAction,
  resetStatus,
  changeToCreateMode,
  changeToConsultMode,
  changeToUpdateMode,
  resetMode,
  changeToBackgroundCreateMode,
  resetBackgroundMode,
  changeToSubCreateMode,
  changeToSubConsultMode,
  changeToSubUpdateMode,
  resetSubMode,
  setModel,
  resetModel,
  addToList,
  updateOnList,
  resetList,
  setRelatedModeloLancamentoModel,
  addModeloLancamento,
  updateModeloLancamento,
  removeModeloLancamentoById,
  resetRelatedModeloLancamentoModel,
  lockSelectedModeloLancamento,
  resetLockedModeloLancamentoModel,
  setResponse,
  setResponseMessage,
  clearResponse,
  setError,
  clearError,
  fetchAllAsync,
  fetchByIdAsync,
  activateByIdAsync,
  inactivateByIdAsync,
  selectModelosFaturamento,
  selectModeloFaturamento,
  selectRelatedModeloLancamentoModel,
  selectLockedModeloLancamentoModel,
  selectStatus,
  selectMode,
  selectBackgroundMode,
  selectSubMode,
  selectError,
  selectResponse,
  selectConditionalSteps,
};

export default modeloFaturamentoSlice.reducer;
