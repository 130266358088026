import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { TIPO_LI } from '../../utils/general/fatura/faturaUtils';
import { CALCULADA_STATUS, isNaoCalculadaStatus } from '../../utils/general/operations/operationUtils';
import {
  CONSULT_MODE,
  CREATE_MODE,
  UPDATE_MODE,
  TRANSFER_MODE,
  REVERSAL_MODE,
  LOCKED_MODE,
  NONE_MODE,
  BACKGROUND_CREATE_MODE,
  BACKGROUND_UPDATE_MODE,
  BACKGROUND_DELETE_MODE,
  BACKGROUND_TRANSFER_MODE,
  BACKGROUND_REVERSAL_MODE,
  NONE_BACKGROUND_MODE,
  SUB_CREATE_MODE,
  SUB_CONSULT_MODE,
  SUB_UPDATE_MODE,
  NONE_SUB_MODE,
  BACKGROUND_CONSULT_MODE,
  NONE_SUB_BACKGROUND_MODE,
  SUB_BACKGROUND_CREATE_MODE,
  SUB_BACKGROUND_CONSULT_MODE,
  SUB_BACKGROUND_UPDATE_MODE,
  SUB_BACKGROUND_DELETE_MODE,
} from '../mode';
import { NO_REFRESH, REFRESH } from '../refresh';
import {
  ALTERNATIVE_LOADING_STATUS,
  FAILURE_STATUS,
  IDLE_STATUS,
  LOADING_STATUS,
  PREPARING_ACTION_STATUS,
  SUCCESS_STATUS,
} from '../status';
import {
  fetchByIdAsync,
  fetchAllFromFaturaAsync,
  fetchByIdFromFaturaAsync,
  calculateByIdFromFaturaAsync,
  activateByIdFromFaturaAsync,
  inactivateByIdFromFaturaAsync,
  conferirByIdFromFaturaAsync,
  duplicarByIdFromFaturaAsync,
  gerarSubstitutivaAsync,
  updatePartialLi,
} from './licencaImportacaoThunks';
import { FATURA_LI_META_TYPE, GENERATED_LI_META_TYPE, NONE_META_TYPE } from './licencaImportacaoUtils';
import i18n from '../../i18n';

const initialState = {
  status: IDLE_STATUS,
  mode: {
    main: NONE_MODE,
    background: NONE_BACKGROUND_MODE,
    sub: NONE_SUB_MODE,
    subBackground: NONE_SUB_BACKGROUND_MODE,
    lastSubBackground: undefined,
  },
  type: NONE_META_TYPE,
  refresh: NO_REFRESH,
  error: null,
  response: {
    status: null,
    message: null,
    data: null,
  },
  list: [],
  model: {
    code: undefined,
    description: undefined,
    possuiPendencia: undefined,
    followUp: undefined,
    atributosAdicionais: {
      tipo: TIPO_LI,
      processo: undefined,
      conferida: undefined,
      transmitida: undefined,
      numero: undefined,
      data: undefined,
    },
    pesoBruto: undefined,
    pesoLiquido: undefined,
    rateiaPesoLiquidoItem: undefined,
    dadosComumFornecedor: undefined,
    importador: undefined,
    paisProcedencia: undefined,
    urfDespacho: undefined,
    urfEntrada: undefined,
    incotermCondicaoVenda: undefined,
    mle: {
      valorMoeda: undefined,
      valorReal: undefined,
      moeda: undefined,
    },
    desconto: {
      valorMoeda: undefined,
      valorReal: undefined,
      moeda: undefined,
    },
    despesa: {
      valorMoeda: undefined,
      valorReal: undefined,
      moeda: undefined,
    },
    frete: {
      prepaid: undefined,
      embutirItem: undefined,
      valorMoedaCollect: undefined,
      valorRealCollect: undefined,
      valorMoedaPrepaid: undefined,
      valorRealPrepaid: undefined,
      moeda: undefined,
    },
    seguro: {
      embutirItem: undefined,
      calculaSobreMleFrete: undefined,
      valorPercentual: undefined,
      valorMoeda: undefined,
      valorReal: undefined,
      moeda: undefined,
    },
    relacao: undefined,
    exportador: undefined,
    fabricante: undefined,
    mercadorias: [],
  },
  related: {
    report: {},
    model: {
      mercadoria: {},
      duplicata: {
        id: undefined,
        atributosAdicionais: {
          tipo: undefined,
          processo: undefined,
        },
      },
      registro: {},
      transferencia: {},
      transferenciaItem: {},
      estorno: {},
    },
    list: {
      selectionLicencaImportacao: [],
      selectionAvailableItem: [],
      selectionItem: [],
      selectionChangedItem: [],
    },
  },
  historyModel: {
    last: {},
  },
};

const licencaImportacaoSlice = createSlice({
  name: 'licencaImportacao',
  initialState,
  reducers: {
    changeStatusTo: (state, action) => {
      state.status = action.payload.status;
    },
    loading: (state) => {
      state.status = LOADING_STATUS;
    },
    success: (state) => {
      state.status = SUCCESS_STATUS;
    },
    failure: (state) => {
      state.status = FAILURE_STATUS;
    },
    preparingAction: (state) => {
      state.status = PREPARING_ACTION_STATUS;
    },
    resetStatus: (state) => {
      state.status = IDLE_STATUS;
    },
    refresh: (state) => {
      state.refresh = REFRESH;
    },
    resetRefresh: (state) => {
      state.refresh = NO_REFRESH;
    },
    changeModeTo: (state, action) => {
      state.mode.main = action.payload;
    },
    changeToCreateMode: (state) => {
      state.mode.main = CREATE_MODE;
    },
    changeToConsultMode: (state) => {
      state.mode.main = CONSULT_MODE;
    },
    changeToUpdateMode: (state) => {
      state.mode.main = UPDATE_MODE;
    },
    changeToTransferMode: (state) => {
      state.mode.main = TRANSFER_MODE;
    },
    changeToReversalMode: (state) => {
      state.mode.main = REVERSAL_MODE;
    },
    changeToLockMode: (state) => {
      state.mode.main = LOCKED_MODE;
    },
    resetMode: (state) => {
      state.mode.main = NONE_MODE;
    },
    changeToBackgroundCreateMode: (state) => {
      state.mode.background = BACKGROUND_CREATE_MODE;
    },
    changeToBackgroundConsultMode: (state) => {
      state.mode.background = BACKGROUND_CONSULT_MODE;
    },
    changeToBackgroundUpdateMode: (state) => {
      state.mode.background = BACKGROUND_UPDATE_MODE;
    },
    changeToBackgroundDeleteMode: (state) => {
      state.mode.background = BACKGROUND_DELETE_MODE;
    },
    changeToBackgroundTransferMode: (state) => {
      state.mode.background = BACKGROUND_TRANSFER_MODE;
    },
    changeToBackgroundReversalMode: (state) => {
      state.mode.background = BACKGROUND_REVERSAL_MODE;
    },
    resetBackgroundMode: (state) => {
      state.mode.background = NONE_BACKGROUND_MODE;
    },
    changeToSubCreateMode: (state) => {
      state.mode.sub = SUB_CREATE_MODE;
    },
    changeToSubConsultMode: (state) => {
      state.mode.sub = SUB_CONSULT_MODE;
    },
    changeToSubUpdateMode: (state) => {
      state.mode.sub = SUB_UPDATE_MODE;
    },
    resetSubMode: (state) => {
      state.mode.sub = NONE_SUB_MODE;
    },
    changeToSubBackgroundCreateMode: (state) => {
      state.mode.lastSubBackground = state.mode.subBackground;
      state.mode.subBackground = SUB_BACKGROUND_CREATE_MODE;
    },
    changeToSubBackgroundConsultMode: (state) => {
      state.mode.lastSubBackground = state.mode.subBackground;
      state.mode.subBackground = SUB_BACKGROUND_CONSULT_MODE;
    },
    changeToSubBackgroundUpdateMode: (state) => {
      state.mode.lastSubBackground = state.mode.subBackground;
      state.mode.subBackground = SUB_BACKGROUND_UPDATE_MODE;
    },
    changeToSubBackgroundDeleteMode: (state) => {
      state.mode.lastSubBackground = state.mode.subBackground;
      state.mode.subBackground = SUB_BACKGROUND_DELETE_MODE;
    },
    resetSubBackgroundMode: (state) => {
      state.mode.lastSubBackground = state.mode.subBackground;
      state.mode.subBackground = NONE_SUB_BACKGROUND_MODE;
    },
    changeToLastSubBackgroundMode: (state) => {
      if (state.mode.lastSubBackground) {
        state.mode.subBackground = state.mode.lastSubBackground;
      }
    },
    changeTypeTo: (state, action) => {
      state.type = action.payload;
    },
    resetType: (state) => {
      state.type = NONE_META_TYPE;
    },
    changeLastHistoryModel: (state, action) => {
      state.historyModel.last = action.payload;
    },
    resetHistoryModel: (state) => {
      state.historyModel = {};
    },
    resetLastHistoryModel: (state) => {
      state.historyModel.last = {};
    },
    setModel: (state, action) => {
      state.model = {
        ...action.payload,
      };
    },
    setProcesso: (state, action) => {
      state.model.followUp = action.payload.followUp;
      state.model.atributosAdicionais.processo = action.payload.processo;
    },
    updateSelectedMercadoria: (state) => {
      const currentProdutoPartnumber = state.related.model?.mercadoria?.produto?.partnumber;
      if (!_.isEmpty(currentProdutoPartnumber)) {
        const { mercadorias } = state.model;
        const mercadoria = mercadorias.find((item) => item?.produto?.partnumber === currentProdutoPartnumber);

        state.related.model.mercadoria = mercadoria;
      }
    },
    resetModel: (state) => {
      state.model = initialState.model;
    },
    addToList: (state, action) => {
      state.list = [...state.list, action.payload.data];
    },
    updateOnList: (state, action) => {
      state.list = state.list.map((current) => {
        if (current.id === action.payload.data?.id) {
          return action.payload.data;
        }
        return current;
      });
    },
    setRelatedReportModel: (state, action) => {
      state.related.model.report = {
        ...action.payload,
      };
    },
    resetRelatedReportModel: (state) => {
      state.related.model.report = initialState.related.model.report;
    },
    setRelatedMercadoriaModel: (state, action) => {
      state.related.model.mercadoria = {
        ...action.payload,
      };
    },
    resetRelatedMercadoriaModel: (state) => {
      state.related.model.mercadoria = {};
    },
    setRelatedDuplicataModel: (state, action) => {
      state.related.model.duplicata = {
        ...action.payload,
      };
    },
    resetRelatedDuplicataModel: (state) => {
      state.related.model.duplicata = {};
    },
    setRelatedRegistroModel: (state, action) => {
      state.related.model.registro = {
        ...action.payload,
      };
    },
    resetRelatedRegistroModel: (state) => {
      state.related.model.registro = {};
    },
    setRelatedTransferenciaModel: (state, action) => {
      state.related.model.transferencia = action.payload;
    },
    resetRelatedTransferenciaModel: (state) => {
      state.related.model.transferencia = initialState.related.model.transferencia;
    },
    setRelatedTransferenciaItemModel: (state, action) => {
      state.related.model.transferenciaItem = action.payload;
    },
    resetRelatedTransferenciaItemModel: (state) => {
      state.related.model.transferenciaItem = initialState.related.model.transferenciaItem;
    },
    setRelatedEstornoModel: (state, action) => {
      state.related.model.estorno = action.payload;
    },
    resetRelatedEstornoModel: (state) => {
      state.related.model.estorno = {};
    },
    setRelatedSelectionLicencaImportacaoList: (state, action) => {
      state.related.list.selectionLicencaImportacao = action.payload;
    },
    resetRelatedSelectionLicencaImportacaoList: (state) => {
      state.related.list.selectionLicencaImportacao = [];
    },
    setRelatedSelectionAvailableItemList: (state, action) => {
      state.related.list.selectionAvailableItem = action.payload;
    },
    resetRelatedSelectionAvailableItemList: (state) => {
      state.related.list.selectionAvailableItem = [];
    },
    setRelatedSelectionItemList: (state, action) => {
      state.related.list.selectionItem = action.payload;
    },
    resetRelatedSelectionItemList: (state) => {
      state.related.list.selectionItem = [];
    },
    addItemToChangedSelectionList: (state, action) => {
      const item = action.payload;

      if (state.related.list.selectionChangedItem?.indexOf(item) === -1) {
        state.related.list.selectionChangedItem = [...state.related.list.selectionChangedItem, item];
      }
    },
    removeItemFromChangedSelectionList: (state, action) => {
      state.related.list.selectionChangedItem = state.related.list.selectionChangedItem?.filter(
        (item) => item !== action.payload
      );
    },
    resetRelatedSelectionChangedItemList: (state) => {
      state.related.list.selectionChangedItem = [];
    },
    setResponse: (state, action) => {
      state.response = action.payload;
    },
    clearResponse: (state) => {
      state.response = {
        status: null,
        message: null,
        data: null,
      };
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    clearError: (state) => {
      state.error = initialState.error;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchByIdAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(fetchByIdAsync.fulfilled, (state, action) => {
        if (action.payload.response.status === 200) {
          const {
            id,
            code,
            data,
            numero,
            description,
            importador,
            urfDespacho,
            urfEntrada,
            paisProcedencia,
            pesoLiquido,
            incotermCondicaoVenda,
            rateiaPesoLiquidoItem,
            mle,
            desconto,
            despesa,
            frete,
            seguro,
            relacao,
            exportador,
            fabricante,
            paisOrigem,
            dadosComumFornecedor,
            sobrescreveDadosPartnumber,
            mercadorias,
            active,
            insertionDate,
            ...restFaturaLi
          } = action.payload.response.data;

          const mappedLicencaImportacao = {
            id,
            code,
            data,
            numero,
            description,
            importador,
            urfDespacho,
            urfEntrada,
            paisProcedencia,
            pesoLiquido,
            incotermCondicaoVenda,
            rateiaPesoLiquidoItem,
            mle,
            desconto,
            despesa,
            frete,
            seguro,
            relacao,
            exportador,
            fabricante,
            paisOrigem,
            dadosComumFornecedor,
            sobrescreveDadosPartnumber,
            mercadorias,
            calculada: CALCULADA_STATUS,
            atributosAdicionais: {
              ...restFaturaLi,
            },
            active: true,
            insertionDate,
          };

          state.model = {
            ...mappedLicencaImportacao,
          };

          state.status = SUCCESS_STATUS;
        }
      })
      .addCase(fetchByIdAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.carregarLicencaImportacao', { erro: action.error.message }),
        };
      })
      .addCase(fetchByIdFromFaturaAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(fetchByIdFromFaturaAsync.fulfilled, (state, action) => {
        if (action.payload.response.status === 200) {
          state.model = {
            ...action.payload.response.data,
          };
          state.status = SUCCESS_STATUS;
        }
      })
      .addCase(fetchByIdFromFaturaAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.carregarLicencaImportacao', { erro: action.error.message }),
        };
      })
      .addCase(fetchAllFromFaturaAsync.pending, (state) => {
        state.status = ALTERNATIVE_LOADING_STATUS;
      })
      .addCase(fetchAllFromFaturaAsync.fulfilled, (state, action) => {
        const mappedList = action.payload.data?.reduce((previous, current) => {
          const faturaLis = current?.lis?.map(
            ({
              id,
              code,
              numero,
              description,
              importador,
              urfDespacho,
              urfEntrada,
              paisProcedencia,
              pesoLiquido,
              incotermCondicaoVenda,
              rateiaPesoLiquidoItem,
              mle,
              desconto,
              despesa,
              frete,
              seguro,
              relacao,
              exportador,
              fabricante,
              paisOrigem,
              dadosComumFornecedor,
              sobrescreveDadosPartnumber,
              mercadorias,
              active,
              insertionDate,
              ...restFaturaLi
            }) => ({
              id,
              code,
              numero,
              description,
              importador,
              urfDespacho,
              urfEntrada,
              paisProcedencia,
              pesoLiquido,
              incotermCondicaoVenda,
              rateiaPesoLiquidoItem,
              mle,
              desconto,
              despesa,
              frete,
              seguro,
              relacao,
              exportador,
              fabricante,
              paisOrigem,
              dadosComumFornecedor,
              sobrescreveDadosPartnumber,
              mercadorias,
              calculada: current?.calculada,
              atributosAdicionais: {
                ...restFaturaLi,
              },
              active,
              insertionDate,
              numeroLiOriginal: current?.numeroLiOriginal,
              dataLiOriginal: current?.dataLiOriginal,
              metaFields: {
                type: GENERATED_LI_META_TYPE,
                fatura: {
                  id: current?.id,
                },
              },
            })
          );

          return [
            ...previous,
            ...(isNaoCalculadaStatus(current?.calculada)
              ? [
                  {
                    ...current,
                    metaFields: {
                      type: FATURA_LI_META_TYPE,
                    },
                  },
                ]
              : []),
            ...faturaLis,
          ];
        }, []);

        state.list = mappedList;
        state.status = IDLE_STATUS;
        state.refresh = NO_REFRESH;
      })
      .addCase(fetchAllFromFaturaAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.carregarListaLicencaImportacao', { erro: action.error.message }),
        };
        state.refresh = NO_REFRESH;
      })
      .addCase(activateByIdFromFaturaAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(activateByIdFromFaturaAsync.fulfilled, (state, action) => {
        state.status = SUCCESS_STATUS;
        state.response.status = action.payload.response.status;
        state.response.data = action.payload.response.data;

        const { atributosAdicionais } = action.payload.response.data;
        state.response.message = i18n.t('com.muralis.qcx.mensagem.licencaImportacaoAtivada', {
          li: atributosAdicionais?.processo,
        });

        if (state.response.status === 200) {
          state.list = state.list.map((current) =>
            current.id === action.payload.response.data.id ? action.payload.response.data : current
          );
        }
      })
      .addCase(activateByIdFromFaturaAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.ativarLicencaImportacao', { erro: action.error.message }),
        };
      })
      .addCase(inactivateByIdFromFaturaAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(inactivateByIdFromFaturaAsync.fulfilled, (state, action) => {
        state.status = SUCCESS_STATUS;
        state.response.status = action.payload.response.status;
        state.response.data = action.payload.response.data;

        const { atributosAdicionais } = action.payload.response.data;
        state.response.message = i18n.t('com.muralis.qcx.mensagem.licencaImportacaoExcluida', {
          li: atributosAdicionais?.processo,
        });

        if (state.response.status === 200) {
          state.list = state.list.map((current) =>
            current.id === action.payload.response.data.id ? action.payload.response.data : current
          );
        }
      })
      .addCase(inactivateByIdFromFaturaAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.excluirLicencaImportacao', { erro: action.error.message }),
        };
      })
      .addCase(calculateByIdFromFaturaAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(calculateByIdFromFaturaAsync.fulfilled, (state, action) => {
        state.status = SUCCESS_STATUS;
        state.response.status = action.payload.response.status;
        state.response.data = action.payload.response.data;

        const requestMetaArg = action.meta.arg;
        const isNaoCalculada = isNaoCalculadaStatus(requestMetaArg?.meta?.calculada);

        const { atributosAdicionais } = action.payload.response.data;

        state.response.message = isNaoCalculada
          ? i18n.t('com.muralis.qcx.mensagem.licencaImportacaoCalculada', { li: atributosAdicionais?.processo })
          : i18n.t('com.muralis.qcx.mensagem.licencaImportacaoRecalculadaOutrasLIreferenteOriginal', {
              li: requestMetaArg?.meta?.processo,
              liOrigem: atributosAdicionais?.processo,
            });

        if (isNaoCalculada) {
          state.historyModel.last = {
            processo: atributosAdicionais?.processo,
          };
        }

        if (state.response.status === 200) {
          state.refresh = REFRESH;
        }
      })
      .addCase(calculateByIdFromFaturaAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.calcularLicencaImportacao', { erro: action.payload?.message }),
        };
      })
      .addCase(conferirByIdFromFaturaAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(conferirByIdFromFaturaAsync.fulfilled, (state, action) => {
        state.status = SUCCESS_STATUS;
        state.response.status = action.payload.response.status;
        state.response.data = action.payload.response.data;

        const licencaImportacaoIdFromRequest = action.meta.arg?.id;

        const { processo } = action.payload.response.data?.lis?.find(
          (licencaImportacao) => licencaImportacao?.id === licencaImportacaoIdFromRequest
        );

        state.response.message = i18n.t('com.muralis.qcx.mensagem.licencaImportacaoConferida', { li: processo });

        if (state.response.status === 200) {
          state.refresh = REFRESH;
        }
      })
      .addCase(conferirByIdFromFaturaAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.conferirLicencaImportacao', { erro: action.payload?.message }),
        };
      })
      .addCase(duplicarByIdFromFaturaAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(duplicarByIdFromFaturaAsync.fulfilled, (state, action) => {
        state.status = SUCCESS_STATUS;
        state.response.status = action.payload.response.status;
        state.response.data = action.payload.response.data;

        const requestPayload = action.meta.arg;

        const licencaImportacaoDuplicada = action.payload.response.data;

        const { processo } = licencaImportacaoDuplicada?.atributosAdicionais;

        state.response.message = i18n.t('com.muralis.qcx.mensagem.licencaImportacaoDuplicadaProcessoOrigem', {
          li: processo,
          liOrigem: requestPayload?.meta?.from?.processo,
        });

        state.list = [...state.list, licencaImportacaoDuplicada];

        state.historyModel.last = {
          processo,
        };

        if (state.response.status === 201) {
          state.refresh = REFRESH;
        }
      })
      .addCase(duplicarByIdFromFaturaAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.duplicarLicencaImportacao', { erro: action.payload?.message }),
        };
      })
      .addCase(gerarSubstitutivaAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(gerarSubstitutivaAsync.fulfilled, (state, action) => {
        state.status = SUCCESS_STATUS;
        state.response.status = action.payload.response.status;
        state.response.data = action.payload.response.data;

        const { processo } = action.payload.response.data;

        state.response.message = i18n.t('com.muralis.qcx.mensagem.licencaImportacaoSubstitutivaGerada', {
          li: processo,
        });

        if (state.response.status === 201) {
          state.refresh = REFRESH;
        }
      })
      .addCase(gerarSubstitutivaAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.duplicarLicencaImportacao', { erro: action.payload?.message }),
        };
      })
      .addCase(updatePartialLi.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(updatePartialLi.fulfilled, (state, action) => {
        state.status = SUCCESS_STATUS;
        state.response.status = action.payload.response.status;
        state.response.data = action.payload.response.data;

        const { processo } = action.payload.response.data;

        state.response.message = `Licença de Importação ${processo} Atualizada`;

        if (state.response.status === 200) {
          state.refresh = REFRESH;
        }
      })
      .addCase(updatePartialLi.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.atualizarLicencaImportacao', { erro: action.payload?.message }),
        };
      });
  },
});

const {
  changeStatusTo,
  loading,
  success,
  failure,
  preparingAction,
  resetStatus,
  refresh,
  resetRefresh,
  changeModeTo,
  changeToCreateMode,
  changeToConsultMode,
  changeToUpdateMode,
  changeToTransferMode,
  changeToReversalMode,
  changeToLockedMode,
  resetMode,
  changeToBackgroundCreateMode,
  changeToBackgroundConsultMode,
  changeToBackgroundUpdateMode,
  changeToBackgroundDeleteMode,
  resetBackgroundMode,
  changeToSubConsultMode,
  changeToSubUpdateMode,
  changeToSubCreateMode,
  resetSubMode,
  changeToSubBackgroundCreateMode,
  changeToSubBackgroundConsultMode,
  changeToSubBackgroundUpdateMode,
  changeToSubBackgroundDeleteMode,
  changeToBackgroundTransferMode,
  changeToBackgroundReversalMode,
  changeToLastSubBackgroundMode,
  resetSubBackgroundMode,
  changeTypeTo,
  resetType,
  changeLastHistoryModel,
  resetHistoryModel,
  resetLastHistoryModel,
  setModel,
  setProcesso,
  resetModel,
  addToList,
  updateOnList,
  setRelatedMercadoriaModel,
  updateSelectedMercadoria,
  resetRelatedMercadoriaModel,
  setRelatedDuplicataModel,
  resetRelatedDuplicataModel,
  setRelatedRegistroModel,
  resetRelatedRegistroModel,
  setRelatedTransferenciaModel,
  resetRelatedTransferenciaModel,
  setRelatedTransferenciaItemModel,
  resetRelatedTransferenciaItemModel,
  setRelatedEstornoModel,
  resetRelatedEstornoModel,
  setRelatedSelectionLicencaImportacaoList,
  resetRelatedSelectionLicencaImportacaoList,
  setRelatedSelectionAvailableItemList,
  resetRelatedSelectionAvailableItemList,
  setRelatedSelectionItemList,
  resetRelatedSelectionItemList,
  addItemToChangedSelectionList,
  removeItemFromChangedSelectionList,
  resetRelatedSelectionChangedItemList,
  setRelatedReportModel,
  resetRelatedReportModel,
  setResponse,
  setResponseMessage,
  clearResponse,
  setError,
  clearError,
} = licencaImportacaoSlice.actions;

const licencaImportacaoActions = licencaImportacaoSlice.actions;

export {
  licencaImportacaoSlice,
  licencaImportacaoActions,
  changeStatusTo,
  loading,
  success,
  failure,
  preparingAction,
  resetStatus,
  refresh,
  resetRefresh,
  changeModeTo,
  changeToCreateMode,
  changeToConsultMode,
  changeToUpdateMode,
  changeToTransferMode,
  changeToReversalMode,
  changeToLockedMode,
  resetMode,
  changeToBackgroundCreateMode,
  changeToBackgroundConsultMode,
  changeToBackgroundUpdateMode,
  changeToBackgroundDeleteMode,
  changeToBackgroundTransferMode,
  changeToBackgroundReversalMode,
  resetBackgroundMode,
  changeToSubConsultMode,
  changeToSubUpdateMode,
  changeToSubCreateMode,
  resetSubMode,
  changeToSubBackgroundCreateMode,
  changeToSubBackgroundConsultMode,
  changeToSubBackgroundUpdateMode,
  changeToSubBackgroundDeleteMode,
  changeToLastSubBackgroundMode,
  resetSubBackgroundMode,
  changeTypeTo,
  resetType,
  changeLastHistoryModel,
  resetHistoryModel,
  resetLastHistoryModel,
  setModel,
  setProcesso,
  resetModel,
  addToList,
  updateOnList,
  setRelatedMercadoriaModel,
  updateSelectedMercadoria,
  resetRelatedMercadoriaModel,
  setRelatedDuplicataModel,
  resetRelatedDuplicataModel,
  setRelatedRegistroModel,
  resetRelatedRegistroModel,
  setRelatedTransferenciaModel,
  resetRelatedTransferenciaModel,
  setRelatedTransferenciaItemModel,
  resetRelatedTransferenciaItemModel,
  setRelatedEstornoModel,
  resetRelatedEstornoModel,
  setRelatedSelectionLicencaImportacaoList,
  resetRelatedSelectionLicencaImportacaoList,
  setRelatedSelectionAvailableItemList,
  resetRelatedSelectionAvailableItemList,
  setRelatedSelectionItemList,
  resetRelatedSelectionItemList,
  addItemToChangedSelectionList,
  setRelatedReportModel,
  resetRelatedReportModel,
  removeItemFromChangedSelectionList,
  resetRelatedSelectionChangedItemList,
  setResponse,
  setResponseMessage,
  clearResponse,
  setError,
  clearError,
};

export default licencaImportacaoSlice.reducer;
