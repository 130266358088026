import React, { useCallback, useMemo } from 'react';
import _ from 'lodash';
import { useForm, useFormState } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { Info as InfoIcon } from '@material-ui/icons';
import {
  CompareArrows as CompareArrowsIcon,
} from '@mui/icons-material';
import { Grid, Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
  selectRelatedSelectionAvailableItemList,
  selectRelatedSelectionChangedItemList,
  selectRelatedSelectionItemList,
  selectRelatedTransferenciaItemModel,
} from '../../features/danfe/danfeSelectors';
import {
  setRelatedSelectionItemList,
  setRelatedTransferenciaItem as setRelatedTransferenciaItemModel,
  resetRelatedTransferenciaItem as resetRelatedTransferenciaItemModel,
  removeItemFromChangedSelectionList,
  addItemToChangedSelectionList,
  setRelatedSelectionAvailableItemList,
  updateModelAdicoes,
} from '../../features/danfe/danfeSlice';
import QCXDataGridControl from '../../shared-components/data-grid-control/QCXDataGridControl';
import QCXFinalFormDialog from '../../shared-components/dialog/QCXFinalFormDialog';
import useFormDialogSync from '../../utils/hooks/form/dialog/useFormDialogSync';
import { CLOSED_STATUS, isOpenStatus, SUBMITTING_STATUS } from '../../utils/hooks/form/dialog/formDialogUtils';
import QCXButton from '../../shared-components/button/QCXButton';
import { normalizeNumeral, unnormalizeNumeral } from '../../utils/general/general-utils';
import { formatBrazilianNumericDecimal } from '../../utils/hooks/form/field/formatters';
import QCXSelectModoTransferenciaFaturaAutocomplete from '../../shared-components/select-modo-transferencia-fatura/QCXSelectTipoFaturaAutocomplete';
import { maxValue, required } from '../../utils/validators/field/validator';
import QCXFinalNumericDecimalField from '../../shared-components/final-numeric-decimal-field/QCXFinalNumericDecimalField';
import ModoTransferenciaFaturaUtils from '../../shared-components/select-modo-transferencia-fatura/modoTransferenciaFaturaUtils';
import useValidators from '../../utils/hooks/useValidators';
import QCXBox from '../../shared-components/box/QCXBox';
import { FaturaUtils } from '../../utils/general/fatura/faturaUtils';

export default function QCXDanfeTransferenciaDataGridManager({
  isBackgroundTransfer,
  rows = [],
  ...restProps
}) {
  const dispatch = useDispatch();

  const form = useForm();
  const { values } = useFormState();
  const { t } = useTranslation();

  const relatedTransferenciaItemModel = useSelector(selectRelatedTransferenciaItemModel);
  const relatedSelectionAvailableItemList = useSelector(selectRelatedSelectionAvailableItemList);
  const relatedSelectionItemList = useSelector(selectRelatedSelectionItemList);
  const relatedSelectionChangedItemList = useSelector(selectRelatedSelectionChangedItemList);

  const isEmptySelectionItemList = useMemo(() => (
    !relatedSelectionItemList || (
      _.isArrayLikeObject(relatedSelectionItemList)
      && _.isEmpty(relatedSelectionItemList)
    )
  ), [relatedSelectionItemList]);

  const handleSelectionModelChange = useCallback(({ selectionModel }) => {
    dispatch(setRelatedSelectionItemList(selectionModel));
    const mappedItems = rows
      .filter((item) => (
        selectionModel?.includes(item?.id)
      ))
      .map((item) => ({
        ...item,
        modo: ModoTransferenciaFaturaUtils.POR_QUANTIDADE,
        ...item?.transferencia,
        itemOriginal: {
          id: item?.id,
          quantidade: item?.quantidadeInicial,
          pesoLiquido: item?.pesoLiquido,
          partNumber: item?.partNumber,
        },
      }));

    form.change('adicoes', mappedItems);

    dispatch(updateModelAdicoes(mappedItems));

    const isSelectedItemBy = (id) => (
      selectionModel?.indexOf(id) !== -1
    );

    const isModifiedItemBy = (id) => (
      relatedSelectionChangedItemList?.indexOf(id) !== -1
    );

    const hasAnyModifiedAndNotSelectedItem = rows?.some((selectedItem) => (
      isModifiedItemBy(selectedItem?.id) && !isSelectedItemBy(selectedItem?.id)
    ));

    if (hasAnyModifiedAndNotSelectedItem) {
      const updatedSelectionAvailableItemList = relatedSelectionAvailableItemList
        ?.map((item) => {
          if (isModifiedItemBy(item?.id) && !isSelectedItemBy(item?.id)) {
            dispatch(
              removeItemFromChangedSelectionList(
                item?.id
              )
            );

            return {
              ...item,
              transferencia: undefined,
            };
          }

          return item;
        });

      dispatch(
        setRelatedSelectionAvailableItemList(
          updatedSelectionAvailableItemList
        )
      );
    }
  }, [
    form,
    rows,
    relatedSelectionChangedItemList,
    relatedSelectionAvailableItemList,
  ]);

  const handleModoTransferenciaChange = useCallback((formControl, formValues) => (value) => {
    const pesoLiquidoValue = formValues?.itemOriginal?.pesoLiquido;
    const quantidadeValue = formValues?.itemOriginal?.quantidade;

    if (ModoTransferenciaFaturaUtils.isPorQuantidade(value)) {
      const formattedPesoLiquido = unnormalizeNumeral(
        pesoLiquidoValue,
        formatBrazilianNumericDecimal(7)
      );

      formControl.change('pesoLiquido', formattedPesoLiquido);
      return;
    }

    if (ModoTransferenciaFaturaUtils.isPorPesoLiquido(value)) {
      const formattedQuantidade = unnormalizeNumeral(
        quantidadeValue,
        formatBrazilianNumericDecimal(5)
      );

      formControl.change('quantidade', formattedQuantidade);
      return;
    }

    formControl.change('quantidade', quantidadeValue);
    formControl.change('pesoLiquido', pesoLiquidoValue);
  }, []);

  const handleQuantidadeChange = useCallback((formControl, formValues) => (value) => {
    const quantidadeOriginal = formValues?.itemOriginal?.quantidade;
    const pesoLiquidoOriginal = formValues?.itemOriginal?.pesoLiquido;

    if (value) {
      const quantidadeAtual = normalizeNumeral(value);

      const pesoLiquidoEquivalente = FaturaUtils.calculatePesoLiquidoEquivalenteBy(
        quantidadeAtual,
        quantidadeOriginal,
        pesoLiquidoOriginal
      );

      const formattedPesoLiquidoEquivalente = unnormalizeNumeral(
        pesoLiquidoEquivalente,
        formatBrazilianNumericDecimal(7)
      );

      formControl.change('pesoLiquidoEquivalente', formattedPesoLiquidoEquivalente);

      return;
    }

    const formattedPesoLiquido = unnormalizeNumeral(
      0,
      formatBrazilianNumericDecimal(7)
    );

    formControl.change('pesoLiquidoEquivalente', formattedPesoLiquido);
  }, []);

  const changeListeners = useMemo(() => (
    [
      {
        name: 'modo',
        fn: handleModoTransferenciaChange,
      },
      {
        name: 'quantidade',
        fn: handleQuantidadeChange,
      }
    ]
  ), [
    handleModoTransferenciaChange,
    handleQuantidadeChange,
  ]);

  const [
    handleAlterarModoTransferenciaClick,
    formDialogAlterarModoTransferenciaStatus,
    handleFormDialogAlterarModoTransferenciaStatus,
  ] = useFormDialogSync(
    async (event, data) => {
      if (_.isFunction(event?.stopPropagation)) {
        event.stopPropagation();

        const quantidadeTotal = data?.quantidade;
        const quantidadeParcial = normalizeNumeral(
          data?.transferencia?.quantidade
          || data?.quantidade
        );
        const pesoLiquidoTotal = data?.pesoLiquido;

        const pesoLiquidoEquivalente = FaturaUtils.calculatePesoLiquidoEquivalenteBy(
          quantidadeParcial,
          quantidadeTotal,
          pesoLiquidoTotal
        );

        dispatch(
          setRelatedTransferenciaItemModel({
            modo: (
              data?.transferencia?.modo
              || ModoTransferenciaFaturaUtils.POR_QUANTIDADE
            ),
            quantidade: unnormalizeNumeral(
              (
                data?.transferencia?.quantidade
                || data?.quantidade
              ),
              formatBrazilianNumericDecimal(5)
            ),
            pesoLiquidoEquivalente: unnormalizeNumeral(
              pesoLiquidoEquivalente,
              formatBrazilianNumericDecimal(7)
            ),
            pesoLiquido: unnormalizeNumeral(
              (
                data?.transferencia?.pesoLiquido
                || data?.pesoLiquido
              ),
              formatBrazilianNumericDecimal(7)
            ),
            itemOriginal: {
              id: data?.id,
              numero: data?.numeroSeqAdicao,
              quantidade: data?.quantidade,
              pesoLiquido: data?.pesoLiquido,
              partNumber: data?.partNumber,
            },
          })
        );
      }
    },
    []
  );

  const handleAlterarModoTransferenciaByDataSubmit = useCallback((data) => {
    handleFormDialogAlterarModoTransferenciaStatus(SUBMITTING_STATUS);
    const updatedItemsFormState = values?.adicoes?.map((currentItem) => (
      currentItem?.itemOriginal?.id === data?.itemOriginal?.id
        ? data
        : currentItem
    ));

    form.change('adicoes', updatedItemsFormState);

    dispatch(updateModelAdicoes(updatedItemsFormState));

    const isPristineItem = (
      normalizeNumeral(data?.quantidade) === data?.itemOriginal?.quantidade
      && normalizeNumeral(data?.pesoLiquido) === data?.itemOriginal?.pesoLiquido
    );

    if (!isPristineItem) {
      dispatch(
        addItemToChangedSelectionList(
          data?.itemOriginal?.id
        )
      );
    }

    const isPreviousChanged = (
      relatedSelectionChangedItemList?.indexOf(
        data?.itemOriginal?.id
      ) !== -1
    );

    const isUndoPreviousChangedItem = (
      isPristineItem && isPreviousChanged
    );

    if (isUndoPreviousChangedItem) {
      dispatch(
        removeItemFromChangedSelectionList(
          data?.itemOriginal?.id
        )
      );
    }

    const updatedSelectionAvailableItemList = relatedSelectionAvailableItemList
      ?.map((item) => (
        item?.id === data?.itemOriginal?.id
          ? {
            ...item,
            transferencia: {
              modo: data?.modo,
              quantidade: data?.quantidade,
              pesoLiquido: data?.pesoLiquido,
            },
          } : item
      ));

    dispatch(
      setRelatedSelectionAvailableItemList(
        updatedSelectionAvailableItemList
      )
    );

    (_.debounce(() => {
      dispatch(resetRelatedTransferenciaItemModel());
    }, 200))();
  }, [
    rows,
    form,
    values,
    relatedSelectionChangedItemList,
    relatedSelectionAvailableItemList,
    handleFormDialogAlterarModoTransferenciaStatus,
  ]);

  const handleCloseFormDialogAlterarModoTransferencia = useCallback(() => {
    handleFormDialogAlterarModoTransferenciaStatus(CLOSED_STATUS);

    (_.debounce(() => {
      dispatch(resetRelatedTransferenciaItemModel());
    }, 200))();
  }, [handleFormDialogAlterarModoTransferenciaStatus]);

  const isFormDialogAlterarModoTransferenciaOpen = useMemo(() => (
    isOpenStatus(formDialogAlterarModoTransferenciaStatus)
  ));

  const formDialogAlterarModoTransferenciaContent = useMemo(() => (
    t(
      'com.muralis.qcx.mensagem.alterarQuantidadeTransferenciaItem',
      {
        identificador: relatedTransferenciaItemModel?.itemOriginal?.partNumber || '###',
      }
    )
  ), [relatedTransferenciaItemModel, t]);

  const columns = useMemo(() => ([
    {
      field: 'numeroAdicao',
      headerName: t('com.muralis.qcx.adicao.label'),
      headerAlign: 'center',
      align: 'center',
      flex: 120,
      valueGetter: ({ row }) => row?.numeroAdicao || '-',
    },
    {
      field: 'numeroSeqAdicao',
      headerName: t('com.muralis.qcx.item.labelSingular'),
      headerAlign: 'center',
      align: 'center',
      flex: 90,
      valueGetter: ({ row }) => row?.numeroSeqAdicao || '-',
    },
    {
      field: 'partNumber',
      headerName: t('com.muralis.qcx.item.partNumber'),
      headerAlign: 'center',
      align: 'center',
      flex: 130,
      valueGetter: ({ row }) => row?.partNumber || '-',
    },
    {
      field: 'exportador',
      headerName: t('com.muralis.qcx.exportador.label'),
      headerAlign: 'center',
      align: 'center',
      flex: 150,
      valueGetter: ({ row }) => row?.exportador || '-',
    },
    {
      field: 'fabricante',
      headerName: t('com.muralis.qcx.fabricante.label'),
      headerAlign: 'center',
      align: 'center',
      flex: 150,
      valueGetter: ({ row }) => row?.fabricante || '-',
    },
    {
      field: 'ncm',
      headerName: t('com.muralis.qcx.NCM.NCMSH'),
      headerAlign: 'center',
      align: 'center',
      flex: 120,
      valueGetter: ({ row }) => row?.ncm || '-',
    },
    {
      field: 'quantidade',
      headerName: t('com.muralis.qcx.quantidade.label'),
      headerAlign: 'center',
      align: 'center',
      flex: 120,
      renderCell: ({ row }) => {
        const quantidadeTotalInicial = row?.quantidadeInicial;
        const quantidadeTotalDisponivel = row?.quantidade;
        const quantidadeParcial = normalizeNumeral(row?.transferencia?.quantidade);
        const pesoLiquidoTotal = row?.pesoLiquido;

        const formattedQuantidadeTotalDisponivel = unnormalizeNumeral(
          quantidadeTotalDisponivel,
          formatBrazilianNumericDecimal(5)
        );

        if (quantidadeTotalInicial === quantidadeTotalDisponivel && !quantidadeParcial) {
          return formattedQuantidadeTotalDisponivel;
        }

        const pesoLiquidoEquivalente = FaturaUtils.calculatePesoLiquidoEquivalenteBy(
          quantidadeParcial,
          quantidadeTotalDisponivel,
          pesoLiquidoTotal
        );

        const formattedQuantidadeTotalInicial = unnormalizeNumeral(
          quantidadeTotalInicial,
          formatBrazilianNumericDecimal(5)
        );

        const formattedQuantidadeParcial = unnormalizeNumeral(
          quantidadeParcial,
          formatBrazilianNumericDecimal(5)
        );

        const formattedPesoLiquidoEquivalente = unnormalizeNumeral(
          pesoLiquidoEquivalente,
          formatBrazilianNumericDecimal(7)
        );

        const isChangedQuantidadeItem = (
          ModoTransferenciaFaturaUtils.isPorQuantidade(row?.transferencia?.modo)
          && quantidadeTotalDisponivel !== quantidadeParcial
        );

        const formattedQuantidade = isChangedQuantidadeItem
          ? formattedQuantidadeParcial
          : formattedQuantidadeTotalDisponivel;

        const helperText = isChangedQuantidadeItem
          ? t('com.muralis.qcx.pesoLiquidoRelativo', { pesoLiquido: formattedPesoLiquidoEquivalente }).toUpperCase()
          : t('com.muralis.qcx.quantidadeInicial', { quantidadeInicial: formattedQuantidadeTotalInicial }).toUpperCase();

        return (
          <>
            <QCXBox
              pr={1}
            >
              {formattedQuantidade}
            </QCXBox>
            <Tooltip
              title={helperText}
              arrow
            >
              <InfoIcon
                color="disabled"
                fontSize="small"
              />
            </Tooltip>
          </>
        );
      },
    },
    {
      field: 'actions',
      headerName: t('com.muralis.qcx.acoes.label'),
      headerAlign: 'center',
      align: 'center',
      flex: 100,
      renderCell: ({ row }) => (
        <>
          <QCXButton
            key={`btn-change-values-${row?.id}`}
            name={`btn-change-values-${row?.id}`}
            variant="text"
            color="secondary"
            size="small"
            tooltipDescription={t('com.muralis.qcx.acoes.alterarQuantidade')}
            tooltip
            onClick={(event) => {
              handleAlterarModoTransferenciaClick(
                event,
                row
              );
            }}
            disabled={(
              isEmptySelectionItemList
              || !relatedSelectionItemList?.includes(row?.id)
            )}
          >
            <CompareArrowsIcon />
          </QCXButton>
        </>
      ),
    },
  ]), [
    t,
    isEmptySelectionItemList,
    relatedSelectionItemList,
  ]);

  return (
    <>
      <QCXDataGridControl
        checkboxSelection
        columns={columns}
        rows={rows}
        selectionModel={relatedSelectionItemList}
        onSelectionModelChange={handleSelectionModelChange}
        {...restProps}
      />
      <QCXFinalFormDialog
        key="form-dialog-alterar-modo-transferencia"
        id="form-dialog-alterar-modo-transferencia"
        title={t('com.muralis.qcx.acoes.alterarQuantidade')}
        content={formDialogAlterarModoTransferenciaContent}
        dialogActionsProps={{
          button: {
            submit: {
              description: t('com.muralis.qcx.acoes.confirmar'),
              size: 'small',
            },
            cancel: {
              description: t('com.muralis.qcx.acoes.cancelar'),
              size: 'small',
            },
          },
        }}
        open={isFormDialogAlterarModoTransferenciaOpen}
        initialValues={relatedTransferenciaItemModel}
        onSubmit={handleAlterarModoTransferenciaByDataSubmit}
        onClose={handleCloseFormDialogAlterarModoTransferencia}
        formProps={{
          onChangeListeners: changeListeners,
        }}
      >
        {({ values: initialValuesModoTransferencia }) => (
          <Grid
            item
            container
            xs={12}
            spacing={2}
          >
            {false && (
              <Grid
                item
                xs={12}
              >
                <QCXSelectModoTransferenciaFaturaAutocomplete
                  id="select-field-modo-transferencia-danfe"
                  key="select-field-modo-transferencia-danfe"
                  name="modo"
                  label={t('com.muralis.qcx.transferencia.transferirPor')}
                  initialValues={initialValuesModoTransferencia}
                  fieldProps={{
                    validate: required,
                  }}
                  required
                />
              </Grid>
            )}
            {ModoTransferenciaFaturaUtils.isPorQuantidade(
              initialValuesModoTransferencia?.modo
            ) && (
              <>
                <Grid
                  item
                  xs={12}
                >
                  <QCXFinalNumericDecimalField
                    id="numeric-decimal-quantidade-field"
                    key="numeric-decimal-quantidade-field"
                    name="quantidade"
                    label={t('com.muralis.qcx.quantidade.label')}
                    scale={5}
                    validate={useValidators([
                      required,
                      maxValue(
                        Number(
                          initialValuesModoTransferencia?.itemOriginal?.quantidade
                        ).toFixed(5)
                      )
                    ])}
                    required={required && (
                      Number.isNaN(Number(initialValuesModoTransferencia?.quantidadeParcial))
                      || Number(initialValuesModoTransferencia?.quantidadeParcial) <= 0
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <QCXFinalNumericDecimalField
                    id="text-number-peso-liquido-equivalente-field"
                    key="text-number-peso-liquido-equivalente-field"
                    name="pesoLiquidoEquivalente"
                    label={t('com.muralis.qcx.pesoLiquido')}
                    scale={7}
                    disabled
                    helperText={t('com.muralis.qcx.mensagem.equivalenteQuantidadeAtual')}
                  />
                </Grid>
              </>
            )}
            {ModoTransferenciaFaturaUtils.isPorPesoLiquido(
              initialValuesModoTransferencia?.modo
            ) && (
              <Grid
                item
                xs={12}
              >
                <QCXFinalNumericDecimalField
                  id="text-number-peso-liquido-field"
                  key="text-number-peso-liquido-field"
                  name="pesoLiquido"
                  label={t('com.muralis.qcx.pesoLiquido')}
                  scale={7}
                  validate={useValidators([
                    required,
                    maxValue(
                      Number(
                        initialValuesModoTransferencia?.itemOriginal?.pesoLiquido
                      ).toFixed(7)
                    )
                  ])}
                  required
                />
              </Grid>
            )}
          </Grid>
        )}
      </QCXFinalFormDialog>
    </>
  );
}
