const selectNotificacoes = (state) => state.notificacao.list;
const selectNovaNotificacao = (state) => state.notificacao.related.model.novaNotificacao;
const selectBadgeContent = (state) => state.notificacao.related.model.badgeContent;

const notificacaoSelectors = {
  selectNotificacoes,
  selectNovaNotificacao,
  selectBadgeContent,
};

export {
  selectNotificacoes,
  selectNovaNotificacao,
  selectBadgeContent,
  notificacaoSelectors,
};
