import { createAsyncThunk } from '@reduxjs/toolkit';
import { isFunction } from 'lodash';
import { MediaType } from '../../utils/api/api-utils';
import { faturamentoAPI } from './faturamentoAPI';

const fetchAllAsync = createAsyncThunk(
  'faturamento/fetchAll',
  async () => {
    const { data } = await faturamentoAPI.fetchAll();
    return { data };
  }
);

const fetchByIdAsync = createAsyncThunk(
  'faturamento/fetchById',
  async (data) => {
    const response = await faturamentoAPI.fetchById(data);
    return {
      response: {
        status: response.status,
        data: response.data,
      },
    };
  }
);

const generateReportExtratoContaCorrenteAsync = createAsyncThunk(
  'faturamento/generateReportExtratoContaCorrente',
  async (payload) => {
    const { handlers, data } = payload;

    try {
      const response = await faturamentoAPI.generateReportExtratoContaCorrente(data);

      if (response?.status === 204) {
        const onNoContent = handlers?.onNoContent;
        if (isFunction(onNoContent)) {
          onNoContent();
          return;
        }
      }

      const xmlData = new Blob([response?.data], { type: MediaType.APPLICATION_PDF });

      const url = window.URL.createObjectURL(xmlData);
      window.open(url);
    } catch (e) {
      const onError = handlers?.onError;

      if (isFunction(onError)) {
        onError();
      }
    }
  }
);

const faturamentoThunks = {
  fetchAllAsync,
  fetchByIdAsync,
  generateReportExtratoContaCorrenteAsync,
};

export {
  faturamentoThunks,
  fetchAllAsync,
  fetchByIdAsync,
  generateReportExtratoContaCorrenteAsync,
};
