import {
  Grid,
  makeStyles,
} from '@material-ui/core';
import React, { useMemo } from 'react';
import {
  FirstPage as FirstPageIcon,
  LastPage as LastPageIcon,
  NavigateBefore as NavigateBeforeIcon,
  NavigateNext as NavigateNextIcon,
} from '@material-ui/icons';
import _ from 'lodash';
import QCXButton from '../../shared-components/button/QCXButton';
import QCXTextField from '../../shared-components/text-field/QCXTextField';

const useStyles = makeStyles(() => ({
  gridControl: {
    paddingLeft: '4px',
    paddingTop: '8px',
  },
  middleControlButton: {
    minWidth: '40px',
    padding: '3px 8px',
  },
  sideControlButton: {
    minWidth: '56px',
    padding: '3px 8px',
  },
  textFieldInfo: {
    maxWidth: '140px',
  },
}));

export default function QCXControlButtonsPagination({
  metadata,
  isSubCreate,
  isSubUpdate,
  handleFirstItem,
  handlePreviousItem,
  handleNextItem,
  handleLastItem,
  disabled,
}) {
  const classes = useStyles();

  const paginationInfoFieldRestProps = useMemo(() => (
    _.omit(
      metadata?.paginationInfoField,
      ['label', 'value', 'hiddenValue']
    )
  ), [metadata]);

  const paginationInfoValue = useMemo(() => (
    !metadata?.paginationInfoField?.hiddenValue
      ? metadata?.paginationInfoField?.value
      : '' // TODO: Verificar se o campo fica em branco ou se exibe '### / ###'
  ), [metadata]);

  return (
    <>
      <Grid
        item
        className={classes.gridControl}
      >
        <QCXButton
          id="first-item-button"
          key="first-item-button"
          className={classes.sideControlButton}
          color="primary"
          size="small"
          tooltipDescription={metadata?.buttonGroup?.first?.description || 'Primeiro Item'}
          tooltip
          onClick={handleFirstItem}
          disabled={(
            disabled
            || isSubUpdate
            || isSubCreate
            || metadata?.quantity === 1
            || metadata?.current < 2
          )}
        >
          <FirstPageIcon />
        </QCXButton>
      </Grid>
      <Grid
        item
        className={classes.gridControl}
      >
        <QCXButton
          id="previous-item-button"
          key="previous-item-button"
          className={classes.middleControlButton}
          color="primary"
          size="small"
          tooltip
          tooltipDescription={metadata?.buttonGroup?.previous?.description || 'Item Anterior'}
          onClick={handlePreviousItem}
          disabled={(
            disabled
            || isSubUpdate
            || isSubCreate
            || metadata?.quantity === 1
            || metadata?.current < 2
          )}
        >
          <NavigateBeforeIcon />
        </QCXButton>
      </Grid>
      <Grid
        item
        className={classes.gridControl}
      >
        <QCXTextField
          id="text-field-pagination-info"
          key="text-field-pagination-info"
          className={classes.textFieldInfo}
          label={metadata?.paginationInfoField?.label || 'Itens'}
          size="small"
          value={paginationInfoValue}
          fullWidth={false}
          inputProps={{
            readOnly: true,
            style: {
              textAlign: 'center',
            },
          }}
          disabled={(
            disabled
            || isSubUpdate
            || isSubCreate
          )}
          {...paginationInfoFieldRestProps}
        />
      </Grid>
      <Grid
        item
        className={classes.gridControl}
      >
        <QCXButton
          id="next-item-button"
          key="next-item-button"
          className={classes.middleControlButton}
          color="primary"
          size="small"
          tooltip
          tooltipDescription={metadata?.buttonGroup?.next?.description || 'Próximo Item'}
          onClick={handleNextItem}
          disabled={(
            disabled
            || isSubUpdate
            || isSubCreate
            || metadata?.quantity === 1
            || metadata?.current === metadata?.quantity
          )}
        >
          <NavigateNextIcon />
        </QCXButton>
      </Grid>
      <Grid
        item
        className={classes.gridControl}
      >
        <QCXButton
          id="last-item-button"
          key="last-item-button"
          className={classes.sideControlButton}
          color="primary"
          size="small"
          tooltip
          tooltipDescription={metadata?.buttonGroup?.last?.description || 'Último Item'}
          onClick={handleLastItem}
          disabled={(
            disabled
            || isSubUpdate
            || isSubCreate
            || metadata?.quantity === 1
            || metadata?.current === metadata?.quantity
          )}
        >
          <LastPageIcon />
        </QCXButton>
      </Grid>
    </>
  );
}
