import { Box, Grid } from '@material-ui/core';
import QCXFormSubtitle from '../form-title/QCXFormSubtitle';
import QCXInfoAlert from '../../shared-components/alert/QCXInfoAlert';
import QCXFinalTextField from '../../shared-components/final-text-field/QCXFinalTextField';

export default function QCXJustificativaMercadoriaGroup({ disabled, title = 'Justificativa' }) {
  return (
    <>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <QCXFormSubtitle
          title={title}
          boxProps={{
            pt: 1,
            pb: 1,
          }}
        />
        <Box mb={2}>
          <QCXInfoAlert>
            Justificativa para os valores informados (VMLE, peso líquido ou quantidade na unidade de medida estatística)
            que estão fora da margem de tolerância estatística
          </QCXInfoAlert>
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <QCXFinalTextField
          id="justificativa-text-field-descricao"
          key="justificativa-text-field-descricao"
          name="descricaoJustificativa"
          label="Justificativa"
          disabled={disabled}
          maxLength={50}
        />
      </Grid>
    </>
  );
}
