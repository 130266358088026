import { createAsyncThunk } from '@reduxjs/toolkit';
import { followUpAPI } from '../follow-up/followUpAPI';

const fetchAllAsync = createAsyncThunk(
  'followUpOrigemFaturamento/fetchAll',
  async (id) => {
    const { data } = await followUpAPI.fetchAllByFaturamentoId(id);

    return { data };
  }
);

const followUpOrigemFaturamentoThunks = {
  fetchAllAsync,
};

export default followUpOrigemFaturamentoThunks;
