import {
  Drawer,
  Grid,
  Hidden,
  makeStyles,
} from '@material-ui/core';
import QCXLateralMenu from '../lateral-menu/QCXLateralMenu';

const useStyles = makeStyles((theme) => ({
  menu: {
    height: `calc(100% - ${theme.spacing(8)} - 1px)`,
    minWidth: '100%',
    marginTop: theme.spacing(8),
    background: theme.palette.primary.drawer,
  },
}));

export default function QCXDrawer({
  additionalClasses,
  open,
  onClose,
  isPermanentDrawer,
  disableFocusLock,
  children,
  authInfo = {},
  ...restProps
}) {
  const classes = useStyles();

  return (
    <>
      <Hidden mdDown>
        <Drawer
          className={additionalClasses.root}
          variant="permanent"
          classes={{
            paper: additionalClasses.paper,
          }}
          anchor="left"
          {...restProps}
        >
          <Grid
            container
            style={{ height: '100%' }}
          >
            <Grid
              className={classes.menu}
            >
              <QCXLateralMenu
                classes={{
                  logo: classes.logo,
                }}
                authInfo={authInfo}
                disableFocusLock={disableFocusLock}
              />
            </Grid>
          </Grid>
        </Drawer>
      </Hidden>
      <Hidden lgUp>
        <Drawer
          ModalProps={{
            disableAutoFocus: true,
            disableEnforceFocus: true,
          }}
          open={open}
          variant="temporary"
          className={additionalClasses.root}
          classes={{
            paper: additionalClasses.paper,
          }}
          anchor="left"
          onClose={onClose}
          {...restProps}
        >
          <Grid
            container
            style={{ height: '100%' }}
          >
            <Grid>
              <QCXLateralMenu
                disableFocusLock={disableFocusLock}
                authInfo={authInfo}
              />
            </Grid>
          </Grid>
        </Drawer>
      </Hidden>
    </>
  );
}
