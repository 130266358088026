import { createFilterOptions } from '@material-ui/lab/useAutocomplete';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllAsync, selectFornecedoresFavorecidos } from '../../features/fornecedor-favorecido/FornecedorSlice';
import QCXAutocompleteSelect from '../autocomplete-select/QCXAutocompleteSelect';

export default function QCXSelectFavorecidoAutocomplete({
  label,
  name,
  initialValues,
  filterPredicate,
  ...restProps
}) {
  const dispatch = useDispatch();
  const list = useSelector(selectFornecedoresFavorecidos);
  const [defaultValue, setDefaultValue] = useState(null);
  const [favorecidos, setFavorecidos] = useState([]);

  useEffect(() => {
    dispatch(fetchAllAsync());
  }, []);

  useEffect(() => {
    const filteredFavorecidos = list
      .filter((item) => item?.active && item?.favorecido);

    setFavorecidos(filteredFavorecidos);
  }, [list]);

  useEffect(() => {
    const value = list.find((item) => item.id === _.get(initialValues, name));
    if (value) {
      setDefaultValue(value);
    } else {
      setDefaultValue(null);
    }
  }, [list, initialValues, name]);

  const filterOptions = createFilterOptions({
    stringify: (item) => `${item?.id} - ${
      item?.pessoa?.nomeResumido
      || item?.pessoa?.nome
      || ''
    }`,
  });

  return (
    <QCXAutocompleteSelect
      label={label}
      id={name}
      name={name}
      options={favorecidos}
      filterOptions={filterOptions}
      defaultValue={defaultValue}
      getOptionLabel={(item) => (item?.id ? `${item?.id} - ${
        item?.pessoa?.nomeResumido
        || item?.pessoa?.nome
        || ''
      }` : '')}
      {...restProps}
    />
  );
}
