import React, {
  useCallback, useContext, useEffect, useMemo,
} from 'react';
import { IconButton } from '@material-ui/core';
import {
  Search as PageviewIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { VscQuestion as VscQuestionIcon } from 'react-icons/vsc';
import { isUndefined } from 'lodash';
import useOperationController from '../../../../../utils/hooks/store/operation/useOperationController';
import QCXSimpleConsultPageTemplate from '../../../../../templates/simple-consult-page/QCXSimpleConsultPageTemplate';
import {
  activateByIdAsync,
  changeToConsultMode,
  changeToCreateMode,
  fetchAllAsync,
  inactivateByIdAsync,
  resetMode,
  resetModel,
  selectEtapas,
} from '../../../../../features/etapa/etapaSlice';
import { isAlternativeLoadingStatus } from '../../../../../utils/store/store-utils';
import EtapaContext from '../../../../../contexts/registrations/etapa/EtapaContext';
import { getResponsavelRealizacaoEtapaLabelByValue } from '../../../../../shared-components/select-responsavel-realizacao-etapa/QCXSelectResponsavelRealizacaoEtapaAutocomplete';
import { userHasDeleteRole } from '../../../../../components/perfil/perfil-utils';
import { getIconComponentByValue } from '../../../../../shared-components/select-icon/iconUtils';
import EtapaUtils from '../../../../../utils/general/etapa/EtapaUtils';

export default function EtapaConsultPage({ authInfo = {} }) {
  const { t } = useTranslation();

  const history = useHistory();

  const {
    status,
  } = useContext(EtapaContext);

  const dispatch = useDispatch();

  const etapas = useSelector(selectEtapas);

  const isAlternativeLoading = useMemo(() => (
    isAlternativeLoadingStatus(status)
  ), [status]);

  const fetchAll = useCallback(() => {
    dispatch(fetchAllAsync());
  }, []);

  const resetModes = useCallback(() => {
    dispatch(resetMode());
  }, []);

  const resetModels = useCallback(() => {
    dispatch(resetModel());
  }, []);

  const resetDependencies = useCallback(() => {
  }, []);

  useEffect(() => {
    fetchAll();
    resetModes();
    resetModels();
    resetDependencies();
  }, []);

  const handleActivateInactivate = useOperationController(
    async (event, id) => {
      event.preventDefault();

      const { active } = etapas.find(
        (etapa) => etapa.id === id
      );

      const isActivation = !active;

      if (isActivation) {
        dispatch(activateByIdAsync(id));
      } else {
        dispatch(inactivateByIdAsync(id));
      }
    }
  );

  const handleClickOnButtonNew = useCallback((event) => {
    event.stopPropagation();
    dispatch(changeToCreateMode());
  }, []);

  const handleClickOnEditButton = useCallback((event, id) => {
    event.stopPropagation();
    dispatch(changeToConsultMode());
    history.push(t('com.muralis.qcx.url.moduloGeraisServicosEtapasDetalhes', { id }));
  }, [history]);

  const columns = [
    {
      field: 'id',
      headerName: t('com.muralis.qcx.codigo'),
      headerAlign: 'center',
      align: 'center',
      type: 'string',
      valueGetter: ({ row }) => (
        row?.id
      ),
      flex: 80,
    },
    {
      field: 'icone',
      headerName: t('com.muralis.qcx.icone'),
      headerAlign: 'center',
      align: 'center',
      flex: 70,
      renderCell: ({ row }) => {
        const component = getIconComponentByValue(
          row?.icone
        );

        const iconComponent = (
          isUndefined(component)
            ? (
              <VscQuestionIcon
                size={22}
                color="#b9b9b9"
              />
            ) : component
        );

        return (
          <span
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {iconComponent}
          </span>
        );
      },
    },
    {
      field: 'sigla',
      headerName: t('com.muralis.qcx.sigla'),
      headerAlign: 'center',
      align: 'center',
      type: 'string',
      flex: 85,
      valueGetter: ({ row }) => (
        row?.sigla || '-'
      ),
    },
    {
      field: 'description',
      headerName: t('com.muralis.qcx.nome'),
      type: 'string',
      flex: 190,
      valueGetter: ({ row }) => (
        row?.description
      ),
    },
    {
      field: 'responsavel',
      headerName: t('com.muralis.qcx.responsavel'),
      headerAlign: 'center',
      align: 'center',
      type: 'string',
      flex: 100,
      valueFormatter: ({ row }) => (
        getResponsavelRealizacaoEtapaLabelByValue(
          row?.responsavel
        ) || '-'
      ),
      valueGetter: ({ row }) => (
        getResponsavelRealizacaoEtapaLabelByValue(
          row?.responsavel
        ) || '-'
      ),
    },
    {
      field: 'publica',
      headerName: t('com.muralis.qcx.publica'),
      type: 'boolean',
      flex: 80,
      valueGetter: ({ row }) => (
        EtapaUtils.isPublica(row?.publica)
      ),
    },
    {
      field: 'actions',
      headerName: t('com.muralis.qcx.acoes.label'),
      headerAlign: 'center',
      align: 'center',
      flex: 100,
      renderCell: ({ row: { id, active } }) => (
        <>

          {userHasDeleteRole(authInfo, 'etapa') && (
          <IconButton
            key={`stw-active${id}`}
            value={active}
            name={`stw-active-${id}`}
            onClick={(event) => handleActivateInactivate(event, id)}
          >
            <DeleteIcon color="error" />
          </IconButton>
          )}
          <IconButton
            key={`btn-view-${id}`}
            name={`btn-view-${id}`}
            onClick={(event) => handleClickOnEditButton(event, id)}
          >
            <PageviewIcon color="primary" />
          </IconButton>
        </>
      ),
    },
  ];

  const breadcrumbs = [
    {
      link: {
        to: '/',
        name: t('com.muralis.qcx.inicio'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.cadastros'),
      },
    },
    {
      link: {
        to: t('com.muralis.qcx.url.moduloCadastrosGerais'),
        name: t('com.muralis.qcx.gerais'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.servico.labelPlural'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.etapa.labelPlural'),
      },
    },
  ];

  const templateProps = {
    page: {
      title: t('com.muralis.qcx.etapa.labelPlural'),
      breadcrumbs,
    },
    control: {
      buttonNew: {
        description: t('com.muralis.qcx.etapa.novaEtapa'),
        path: t('com.muralis.qcx.url.moduloGeraisServicosEtapasNovo'),
        onClick: handleClickOnButtonNew,
      },
    },
    table: {
      columns,
    },
  };

  const filterProps = [
    'id',
    'sigla',
    'description',
  ];

  return (
    <QCXSimpleConsultPageTemplate
      pageProps={templateProps.page}
      controlProps={templateProps.control}
      tableProps={templateProps.table}
      tableData={etapas}
      isLoading={isAlternativeLoading}
      buttonNewText={t('com.muralis.qcx.acoes.nova').toUpperCase()}
      multipleFilter
      filterProps={filterProps}
      authInfo={authInfo}
      requiredRoles={['etapa']}
    />
  );
}
