import { UseMutateFunction } from "react-query";
import {
  usePostRequest,
  QueriesTypes,
  useDeleteRequest,
  usePutRequest,
} from "../../../../common/hooks/queries";

export const useAddFollowUp = (
  onSettledCallback: () => void
): [
  UseMutateFunction<
    any,
    unknown,
    {
      requestPayload: {
        [key: string]: any;
      };
      queryParams?:
        | {
            clientId?: string | number;
            step?: number;
          }
        | undefined;
    }
  >,
  boolean,
  boolean
] => {
  const { isLoading, isError, mutate } = usePostRequest(
    QueriesTypes.PropostaFollowUp,
    { onSettledCallback }
  );

  return [mutate, isLoading, isError];
};

export const useDeleteFollowUp = (
  onSettledCallback: () => void
): [
  UseMutateFunction<
    any,
    unknown,
    {
      id?: string | number;
      nome?: string;
      clienteId?: number;
      propostaId?: number | string;
    }
  >,
  boolean,
  boolean
] => {
  const { isLoading, isError, mutate } = useDeleteRequest(
    QueriesTypes.PropostaFollowUp,
    { onSettledCallback }
  );

  return [mutate, isLoading, isError];
};

export const useEditFollowUp = (
  onSettledCallback: () => void
): [
  UseMutateFunction<any, unknown, { [key: string]: any }>,
  boolean,
  boolean
] => {
  const { isLoading, isError, mutate } = usePutRequest(
    QueriesTypes.PropostaFollowUp,
    { onSettledCallback }
  );

  return [mutate, isLoading, isError];
};
