import { isEmpty, isFunction } from 'lodash';
import debounce from 'lodash/debounce';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { validate } from 'uuid';
import QCXLicencaImportacaoWizardFinalForm from '../../../../components/licenca-importacao/QCXLicencaImportacaoWizardFinalForm';
import { changeControlTo as changeControlCatalogoProduto } from '../../../../features/catalogo-produtos/catalogoProdutosSlice';
import { clienteActions } from '../../../../features/cliente/clienteSlice';
import { AUTO_LOAD_CONTROL, IDLE_CONTROL } from '../../../../features/config-control';
import { faturaAPI } from '../../../../features/fatura/faturaAPI';
import { selectFatura as selectFaturaOriginal } from '../../../../features/fatura/faturaSelectors';
import { resetModel as resetModelFatura } from '../../../../features/fatura/faturaSlice';
import { fetchByIdAsync as fetchFaturaOriginalById } from '../../../../features/fatura/faturaThunks';
import {
  selectBackgroundMode,
  selectHistoryModel,
  selectLicencaImportacao,
  selectMode,
  selectRefresh,
  selectStatus,
  selectSubBackgroundMode,
  selectSubMode,
  selectType,
} from '../../../../features/licenca-importacao/licencaImportacaoSelectors';
import {
  addToList,
  changeToSubUpdateMode,
  changeToBackgroundCreateMode,
  changeToBackgroundDeleteMode,
  changeToConsultMode,
  changeToCreateMode,
  changeToSubConsultMode,
  changeToSubCreateMode,
  changeToUpdateMode,
  changeTypeTo,
  failure,
  loading,
  preparingAction,
  resetBackgroundMode,
  resetModel,
  resetRefresh,
  resetRelatedMercadoriaModel,
  resetStatus,
  resetSubBackgroundMode,
  resetType,
  setError,
  setModel,
  setRelatedMercadoriaModel,
  setResponse,
  success,
  updateOnList,
  updateSelectedMercadoria,
} from '../../../../features/licenca-importacao/licencaImportacaoSlice';
import {
  fetchByIdAsync,
  fetchByIdFromFaturaAsync,
  updatePartialLi,
} from '../../../../features/licenca-importacao/licencaImportacaoThunks';
import {
  FATURA_LI_META_TYPE,
  GENERATED_LI_META_TYPE,
  isFaturaLiMetaType,
  isGeneratedLiMetaType,
} from '../../../../features/licenca-importacao/licencaImportacaoUtils';
import { changeControlTo as changeControlNaladiNccaTo } from '../../../../features/naladi-ncca/naladiNccaSlice';
import { changeControlTo as changeControlNaladiShTo } from '../../../../features/naladi-sh/naladiShSlice';
import { changeControlTo as changeControlNcmTo } from '../../../../features/ncm-subitem/ncmSubitemSlice';
import { changeControlTo as changeControlPaisTo } from '../../../../features/pais/paisSlice';
import { changeControlTo as changeControlUnidadeMedidaTo } from '../../../../features/unidade-medida/unidadeDeMedidaSlice';
import QCXConfirmDialog from '../../../../shared-components/dialog/QCXConfirmDialog';
import QCXRegistrationFormPageTemplate from '../../../../templates/registration-form-page/QCXRegistrationFormPageTemplate';
// eslint-disable-next-line import/no-unresolved, import/extensions
import useConfigurePopUp from '../../../../ts/common/hooks/popUps/useConfigurePopUp';
import { buildUrlWithQueryParams } from '../../../../utils/api/api-utils';
import { TIPO_LI } from '../../../../utils/general/fatura/faturaUtils';
import {
  forceUnnormalizeNumeral,
  isValid,
  normalizeNumeral,
  unnormalizeNumeral,
} from '../../../../utils/general/general-utils';
import {
  isCalculadaStatus,
  isIntegracaoSiscomexErroStatus,
  isIntegradaSiscomex,
} from '../../../../utils/general/operations/operationUtils';
import { formatBrazilianNumericDecimal } from '../../../../utils/hooks/form/field/formatters';
import useOperationConfirm from '../../../../utils/hooks/operation/confirm/useOperationConfirm';
import {
  isBackgroundCreateMode,
  isBackgroundDeleteMode,
  isBackgroundUpdateMode,
  isConsultMode,
  isCreateMode,
  isFailureStatus,
  isIdleStatus,
  isLoadingStatus,
  isLockedMode,
  isNoneMode,
  isNoneSubMode,
  isPreparingActionStatus,
  isRefresh,
  isSubBackgroundConsultMode,
  isSubBackgroundCreateMode,
  isSubBackgroundDeleteMode,
  isSubBackgroundUpdateMode,
  isSubConsultMode,
  isSubCreateMode,
  isSubUpdateMode,
  isUpdateMode,
} from '../../../../utils/store/store-utils';
import useNormalizeLI from './normalizeLI';

export default function LicencaImportacaoRegistrationPage({ authInfo = {} }) {
  const { t } = useTranslation();

  const params = useParams();

  const history = useHistory();

  const dispatch = useDispatch();

  const [isCreated, setIsCreated] = useState(false);

  const [metadata, setMetadata] = useState({
    step: undefined,
    tab: undefined,
    item: undefined,
  });

  const status = useSelector(selectStatus);
  const mode = useSelector(selectMode);
  const backgroundMode = useSelector(selectBackgroundMode);
  const subMode = useSelector(selectSubMode);
  const subBackgroundMode = useSelector(selectSubBackgroundMode);
  const type = useSelector(selectType);
  const historyModel = useSelector(selectHistoryModel);
  const licencaImportacao = useSelector(selectLicencaImportacao);
  const faturaOriginal = useSelector(selectFaturaOriginal);

  const isPreparingAction = useMemo(() => isPreparingActionStatus(status), [status]);

  const isLoading = useMemo(() => isLoadingStatus(status), [status]);

  const isIdle = useMemo(() => isIdleStatus(status), [status]);

  const isFailure = useMemo(() => isFailureStatus(status), [status]);

  const isNone = useMemo(() => isNoneMode(mode), [mode]);

  const isCreate = useMemo(() => isCreateMode(mode), [mode]);

  const isConsult = useMemo(() => isConsultMode(mode), [mode]);

  const isUpdate = useMemo(() => isUpdateMode(mode), [mode]);

  const isLocked = useMemo(() => isLockedMode(mode), [mode]);

  const isBackgroundCreate = useMemo(() => isBackgroundCreateMode(backgroundMode), [backgroundMode]);

  const isBackgroundUpdate = useMemo(() => isBackgroundUpdateMode(backgroundMode), [backgroundMode]);

  const isBackgroundDelete = useMemo(() => isBackgroundDeleteMode(backgroundMode), [backgroundMode]);

  const isSubNone = useMemo(() => isNoneSubMode(subMode), [subMode]);

  const isSubCreate = useMemo(() => isSubCreateMode(subMode), [subMode]);

  const isSubConsult = useMemo(() => isSubConsultMode(subMode), [subMode]);

  const isSubBackgroundCreate = useMemo(() => isSubBackgroundCreateMode(subBackgroundMode), [subBackgroundMode]);

  const isSubBackgroundConsult = useMemo(() => isSubBackgroundConsultMode(subBackgroundMode), [subBackgroundMode]);

  const isSubBackgroundUpdate = useMemo(() => isSubBackgroundUpdateMode(subBackgroundMode), [subBackgroundMode]);

  const isSubBackgroundDelete = useMemo(() => isSubBackgroundDeleteMode(subBackgroundMode), [subBackgroundMode]);

  const isSubUpdate = useMemo(() => isSubUpdateMode(subMode), [subMode]);

  const isFaturaLi = useMemo(() => isFaturaLiMetaType(type), [type]);

  const isGeneratedLi = useMemo(() => isGeneratedLiMetaType(type), [type]);

  const isCurrentLicencaImportacaoCalculada = useMemo(
    () => isCalculadaStatus(licencaImportacao?.calculada),
    [licencaImportacao]
  );

  const handleChangeToPreparingAction = useCallback(() => {
    dispatch(preparingAction());
  }, []);

  const handleChangeToCreate = useCallback(() => {
    dispatch(changeToCreateMode());
  }, []);

  const handleChangeToBackgroundCreate = useCallback(() => {
    dispatch(changeToBackgroundCreateMode());
  }, []);

  const handleChangeToBackgroundDelete = useCallback(() => {
    dispatch(changeToBackgroundDeleteMode());
  }, []);

  const handleChangeToConsult = useCallback(() => {
    dispatch(changeToConsultMode());
  }, []);

  const handleBasicChangeToUpdate = useCallback(() => {
    // O sub mode é quem controla o estado dos mercadorias dentro da LI
    if (metadata.step === 5) {
      dispatch(changeToSubUpdateMode());
    }

    dispatch(changeToUpdateMode());
  }, [metadata]);

  const handleUnmountedChangeToUpdate = useCallback(() => {
    dispatch(changeToUpdateMode());

    dispatch(changeTypeTo(FATURA_LI_META_TYPE));

    const metadataParams = [
      {
        name: 'step',
        value: metadata?.step,
      },
      {
        name: 'tab',
        value: metadata?.tab,
      },
      {
        name: 'item',
        value: metadata?.item,
      },
    ];

    history.replace(
      buildUrlWithQueryParams(
        t('com.muralis.qcx.url.importacaoLicencaImportacaoDetalhes', { id: params.origemId }),
        metadataParams
      )
    );
  }, []);

  useEffect(() => {
    dispatch(resetStatus());

    dispatch(clienteActions.changeControlTo(IDLE_CONTROL));
    dispatch(changeControlCatalogoProduto(AUTO_LOAD_CONTROL));
    dispatch(changeControlPaisTo(AUTO_LOAD_CONTROL));
    dispatch(changeControlNcmTo(AUTO_LOAD_CONTROL));
    dispatch(changeControlNaladiShTo(AUTO_LOAD_CONTROL));
    dispatch(changeControlNaladiNccaTo(AUTO_LOAD_CONTROL));
    dispatch(changeControlUnidadeMedidaTo(AUTO_LOAD_CONTROL));

    return () => {
      dispatch(clienteActions.resetControl());
      dispatch(resetModel());
      dispatch(resetModelFatura());
    };
  }, []);

  const operationOfChangeToUpdateOptions = useMemo(
    () => ({
      title: t('com.muralis.qcx.aviso').toUpperCase(),
      message: t('com.muralis.qcx.mensagem.modoAlteracaoLI'),
      endMessage: t('com.muralis.qcx.mensagem.comoDesejaContinuar'),
    }),
    []
  );

  const handleChangeToUpdateWithoutUnmount = useCallback(() => {
    dispatch(changeTypeTo(GENERATED_LI_META_TYPE));
    dispatch(changeToUpdateMode());
  }, []);

  const [handleControlledChangeToUpdate, operationOfChangeToUpdate] = useConfigurePopUp({
    primaryConfirmCallback: handleUnmountedChangeToUpdate,
    secondaryConfirmCallback: handleChangeToUpdateWithoutUnmount,
    initialOptions: operationOfChangeToUpdateOptions,
  });

  const handleChangeToUpdate = useCallback(() => {
    if (isGeneratedLi) {
      handleControlledChangeToUpdate();
      return;
    }

    handleBasicChangeToUpdate();
  }, [handleBasicChangeToUpdate, isGeneratedLi, handleControlledChangeToUpdate]);

  const handleMetadataUpdate = useCallback((name, updatedMetadata) => {
    setMetadata((previousState) => ({
      ...previousState,
      [name]: updatedMetadata,
    }));
  }, []);

  const handleResetBackgroundMode = useCallback(() => {
    dispatch(resetBackgroundMode());
  }, []);

  const fetchById = useCallback(
    (data) => {
      const handleFetchById = () => {
        if (isGeneratedLi) {
          dispatch(fetchByIdAsync(data?.id));
          dispatch(fetchFaturaOriginalById(data?.origemId));
          return;
        }

        dispatch(fetchByIdFromFaturaAsync(data?.id));
        dispatch(resetType());
      };

      handleFetchById();
    },
    [isFaturaLi, isGeneratedLi]
  );

  useEffect(() => {
    const handleFetchById = () => {
      if (!isEmpty(params?.id) && !isSubBackgroundDelete) {
        fetchById(params);
      }
    };

    handleFetchById();
  }, [params]);

  const handleBackToPreviousGenerated = useCallback(() => {
    if (!isEmpty(historyModel?.last) && isBackgroundUpdate) {
      dispatch(changeTypeTo(GENERATED_LI_META_TYPE));

      const metadataParams = [
        {
          name: 'step',
          value: metadata?.step,
        },
        {
          name: 'tab',
          value: metadata?.tab,
        },
        {
          name: 'item',
          value: metadata?.item,
        },
      ];

      history.replace(
        buildUrlWithQueryParams(
          t('com.muralis.qcx.url.importacaoLicencaImportacaoDetalhesOrigem', {
            id: historyModel?.last?.id,
            faturaId: historyModel?.last?.fatura?.id,
          }),
          metadataParams
        )
      );
      dispatch(resetBackgroundMode());
    }
  }, [metadata, history, historyModel, isBackgroundUpdate]);

  const handleBasicCancelUpdate = useCallback(() => {
    handleChangeToConsult();
  }, [
    params,
    licencaImportacao,
    isBackgroundCreate,
    isCurrentLicencaImportacaoCalculada,
    fetchById,
    handleChangeToConsult,
    handleBackToPreviousGenerated,
  ]);

  const operationOfCancelUpdateOptions = useMemo(
    () => ({
      title: t('com.muralis.qcx.aviso').toUpperCase(),
      message: t('com.muralis.qcx.mensagem.cancelarAlteracoesPerdidosLIDesmontada'),
      endMessage: t('com.muralis.qcx.mensagem.certezaDesejaCancelar'),
    }),
    []
  );

  const [handleControlledCancelUpdate, operationOfCancelUpdate] = useOperationConfirm(
    handleBasicCancelUpdate,
    operationOfCancelUpdateOptions,
    [handleBasicCancelUpdate, operationOfCancelUpdateOptions]
  );

  const handleCancelUpdate = useMemo(() => {
    if (isCurrentLicencaImportacaoCalculada) {
      return handleControlledCancelUpdate;
    }

    return handleBasicCancelUpdate;
  }, [isCurrentLicencaImportacaoCalculada, handleBasicCancelUpdate, handleControlledCancelUpdate]);

  const normalize = useCallback((unnormalizedData) => {
    const normalizedVinculos = (unnormalizedData?.atributosAdicionais?.vinculos || [])?.map((vinculo) =>
      validate(vinculo?.id)
        ? {
            ...vinculo,
            id: null,
          }
        : vinculo
    );

    const normalizedMercadorias = unnormalizedData?.mercadorias || [];

    const {
      atributosAdicionais,
      importador,
      paisProcedencia,
      urfDespacho,
      urfEntrada,
      pesoLiquido,
      rateiaPesoLiquidoItem,
      incotermCondicaoVenda,
      mle,
      despesa,
      desconto,
      frete,
      seguro,
      exportador,
      fabricante,
      paisOrigem,
      ...restUnnormalizedData
    } = unnormalizedData;

    const normalizedData = {
      ...restUnnormalizedData,
      importador: importador?.id ? importador : null,
      urfDespacho: urfDespacho?.id ? urfDespacho : null,
      pesoLiquido: normalizeNumeral(pesoLiquido),
      rateiaPesoLiquidoItem,
      paisProcedencia: paisProcedencia?.id ? paisProcedencia : null,
      urfEntrada: urfEntrada?.id ? urfEntrada : null,
      incotermCondicaoVenda: incotermCondicaoVenda?.id ? incotermCondicaoVenda : null,
      atributosAdicionais: {
        ...atributosAdicionais,
        tipo: TIPO_LI,
        processo: atributosAdicionais?.processo,
        informacoesComplementares: atributosAdicionais?.informacoesComplementares,
        destaqueNcm: atributosAdicionais?.destaqueNcm,
        materialUsado: atributosAdicionais?.materialUsado,
        modalidadeDrawback: atributosAdicionais?.modalidadeDrawback,
        regimeTributacao: atributosAdicionais?.regimeTributacao?.id ? atributosAdicionais?.regimeTributacao : null,
        fundamentoLegalRegimeTributacaoIi: atributosAdicionais?.fundamentoLegalRegimeTributacaoIi?.id
          ? atributosAdicionais?.fundamentoLegalRegimeTributacaoIi
          : null,
        followUp: unnormalizedData?.followUp,
        tipoAcordoTarifario: atributosAdicionais?.tipoAcordoTarifario,
        acordoAladi: atributosAdicionais?.acordoAladi?.id ? atributosAdicionais?.acordoAladi : null,
        enquadramentoLegal: atributosAdicionais?.enquadramentoLegal,
        tipoOperacao: atributosAdicionais?.tipoOperacao,
        coberturaCambial: atributosAdicionais?.coberturaCambial,
        motivoImportacaoSemCoberturaCambial: atributosAdicionais?.motivoImportacaoSemCoberturaCambial,
        modalidadePagamento: atributosAdicionais?.modalidadePagamento?.id
          ? atributosAdicionais?.modalidadePagamento
          : null,
        quantidadeDiasLimitePagamento: atributosAdicionais?.quantidadeDiasLimitePagamento,
        instituicaoFinanciadora: atributosAdicionais?.instituicaoFinanciadora?.id
          ? atributosAdicionais?.instituicaoFinanciadora
          : null,
        numeroAtoConcessorio: atributosAdicionais?.numeroAtoConcessorio,
        vinculos: normalizedVinculos,
      },
      mle: {
        ...mle,
        moeda: mle?.moeda?.id ? mle?.moeda : null,
        valorMoeda: normalizeNumeral(mle?.valorMoeda),
        valorReal: normalizeNumeral(mle?.valorReal),
      },
      desconto: {
        ...desconto,
        moeda: desconto?.moeda?.id ? desconto?.moeda : null,
        valorMoeda: normalizeNumeral(desconto?.valorMoeda),
        valorReal: normalizeNumeral(desconto?.valorReal),
      },
      despesa: {
        ...despesa,
        moeda: despesa?.moeda?.id ? despesa?.moeda : null,
        valorMoeda: normalizeNumeral(despesa?.valorMoeda),
        valorReal: normalizeNumeral(despesa?.valorReal),
      },
      frete: {
        ...frete,
        moeda: frete?.moeda?.id ? frete?.moeda : null,
        valorMoedaCollect: normalizeNumeral(frete?.valorMoedaCollect),
        valorRealCollect: normalizeNumeral(frete?.valorRealCollect),
        valorMoedaPrepaid: normalizeNumeral(frete?.valorMoedaPrepaid),
        valorRealPrepaid: normalizeNumeral(frete?.valorRealPrepaid),
        valorFreteNacionalMoeda: normalizeNumeral(frete?.valorFreteNacionalMoeda),
        valorFreteNacionalReal: normalizeNumeral(frete?.valorFreteNacionalReal),
      },
      seguro: {
        ...seguro,
        moeda: seguro?.moeda?.id ? seguro?.moeda : null,
        valorMoeda: normalizeNumeral(seguro?.valorMoeda),
        valorReal: normalizeNumeral(seguro?.valorReal),
        valorPercentual: normalizeNumeral(seguro?.valorPercentual),
      },
      exportador: exportador?.id ? exportador : null,
      fabricante: fabricante?.id ? fabricante : null,
      paisOrigem: paisOrigem?.id ? paisOrigem : null,
      mercadorias: normalizedMercadorias,
    };

    return normalizedData;
  }, []);

  const unnormalize = useCallback((normalizedData) => {
    const { pesoLiquido, atributosAdicionais, mle, despesa, desconto, frete, seguro, mercadorias } = normalizedData;

    return {
      ...normalizedData,
      pesoLiquido: isValid(pesoLiquido)
        ? forceUnnormalizeNumeral(pesoLiquido, formatBrazilianNumericDecimal(7))
        : undefined,
      atributosAdicionais,
      mle: {
        ...mle,
        valorMoeda: isValid(mle?.valorMoeda)
          ? unnormalizeNumeral(mle?.valorMoeda, formatBrazilianNumericDecimal(2))
          : undefined,
        valorReal: isValid(mle?.valorReal)
          ? unnormalizeNumeral(mle?.valorReal, formatBrazilianNumericDecimal(2))
          : undefined,
      },
      desconto: {
        ...desconto,
        moeda: desconto?.moeda?.id ? desconto?.moeda : undefined,
        valorMoeda: isValid(desconto?.valorMoeda)
          ? unnormalizeNumeral(desconto?.valorMoeda, formatBrazilianNumericDecimal(2))
          : undefined,
        valorReal: isValid(desconto?.valorReal)
          ? unnormalizeNumeral(desconto?.valorReal, formatBrazilianNumericDecimal(2))
          : undefined,
      },
      despesa: {
        ...despesa,
        valorMoeda: isValid(despesa?.valorMoeda)
          ? unnormalizeNumeral(despesa?.valorMoeda, formatBrazilianNumericDecimal(2))
          : undefined,
        valorReal: isValid(despesa?.valorReal)
          ? unnormalizeNumeral(despesa?.valorReal, formatBrazilianNumericDecimal(2))
          : undefined,
      },
      frete: {
        ...frete,
        moeda: frete?.moeda?.id ? frete?.moeda : undefined,
        valorMoedaPrepaid: isValid(frete?.valorMoedaPrepaid)
          ? unnormalizeNumeral(frete?.valorMoedaPrepaid, formatBrazilianNumericDecimal(2))
          : undefined,
        valorRealPrepaid: isValid(frete?.valorRealPrepaid)
          ? unnormalizeNumeral(frete?.valorRealPrepaid, formatBrazilianNumericDecimal(2))
          : undefined,
        valorFreteNacionalMoeda: isValid(frete?.valorFreteNacionalMoeda)
          ? unnormalizeNumeral(frete?.valorFreteNacionalMoeda, formatBrazilianNumericDecimal(2))
          : undefined,
        valorFreteNacionalReal: isValid(frete?.valorFreteNacionalReal)
          ? unnormalizeNumeral(frete?.valorFreteNacionalReal, formatBrazilianNumericDecimal(2))
          : undefined,
        valorMoedaCollect: isValid(frete?.valorMoedaCollect)
          ? unnormalizeNumeral(frete?.valorMoedaCollect, formatBrazilianNumericDecimal(2))
          : undefined,
        valorRealCollect: isValid(frete?.valorRealCollect)
          ? unnormalizeNumeral(frete?.valorRealCollect, formatBrazilianNumericDecimal(2))
          : undefined,
      },
      seguro: {
        ...seguro,
        moeda: seguro?.moeda?.id ? seguro?.moeda : undefined,
        valorMoeda: isValid(seguro?.valorMoeda)
          ? unnormalizeNumeral(seguro?.valorMoeda, formatBrazilianNumericDecimal(2))
          : undefined,
        valorReal: isValid(seguro?.valorReal)
          ? unnormalizeNumeral(seguro?.valorReal, formatBrazilianNumericDecimal(2))
          : undefined,
        valorPercentual: isValid(seguro?.valorPercentual)
          ? unnormalizeNumeral(seguro?.valorPercentual, formatBrazilianNumericDecimal(4))
          : undefined,
      },
      mercadorias,
      valorTotalMcvMoeda: isValid(atributosAdicionais?.valorTotalMcvMoeda)
        ? unnormalizeNumeral(atributosAdicionais?.valorTotalMcvMoeda, formatBrazilianNumericDecimal(2))
        : undefined,
      valorTotalMcvReal: isValid(atributosAdicionais?.valorTotalMcvReal)
        ? unnormalizeNumeral(atributosAdicionais?.valorTotalMcvReal, formatBrazilianNumericDecimal(2))
        : undefined,
    };
  }, []);

  const handleDispatchSetModel = useCallback(
    (data) => {
      const normalizedData = normalize(data);

      dispatch(setModel(normalizedData));
    },
    [normalize]
  );

  const createByStep = async (data, step, next) => {
    const isFirstStep = step === 0;
    const isLastStep = step === 4;

    const executeDebounced = debounce(async () => {
      try {
        if (isFirstStep && !isCreated) {
          const response = await faturaAPI.register(data, [{ name: 'STEP', value: step + 1 }]);

          if (response?.status === 201) {
            next();
            dispatch(resetModel());
            dispatch(success());

            const created = response?.data;

            handleDispatchSetModel(created);
            dispatch(addToList({ data: created }));
            dispatch(fetchByIdFromFaturaAsync(created?.id));

            setIsCreated(true);
          }
        } else if (isLastStep) {
          const response = await faturaAPI.save(data, [{ name: 'STEP', value: step + 1 }]);

          if (response?.status === 200) {
            dispatch(resetModel());

            const handleResultWithDebounce = debounce(() => {
              history.push(`/importacao/licencas-de-importacao?q=${data?.atributosAdicionais?.processo}`);

              dispatch(success());

              const saved = response?.data;

              dispatch(setModel(saved));

              dispatch(
                setResponse({
                  status: response.status,
                  data: saved,
                  message: t('com.muralis.qcx.mensagem.licencaImportacaoRegistrada', {
                    processo: data?.atributosAdicionais?.processo,
                  }),
                })
              );

              dispatch(updateOnList({ data: saved }));
            }, 500);

            handleResultWithDebounce();
          }
        } else {
          const response = await faturaAPI.save(data, [{ name: 'STEP', value: step + 1 }]);

          if (response?.status === 200) {
            if (isCreate) {
              next();
            } else {
              handleChangeToConsult();
            }

            const saved = response?.data;

            dispatch(success());
            dispatch(setModel(saved));
            dispatch(updateOnList({ data: saved }));
            dispatch(fetchByIdFromFaturaAsync(saved?.id));
          }
        }
      } catch (error) {
        dispatch(failure());
        let errorMessage = t('com.muralis.qcx.erro.erroSalvarDadosLI');
        if (error?.response && error?.response?.data) {
          errorMessage = t('com.muralis.qcx.erro.erroSalvarDadosLIEspecifico', {
            erro: error?.response?.data?.message,
          });
        }
        dispatch(
          setError({
            message: errorMessage,
          })
        );
      }
    }, 500);

    dispatch(loading());
    executeDebounced();
  };

  const update = async (data, step) => {
    const executeDebounced = debounce(async () => {
      try {
        const response = await faturaAPI.save(data, [{ name: 'STEP', value: step + 1 }]);

        if (response?.status === 200) {
          const handleResultWithDebounce = debounce(() => {
            handleChangeToConsult();
            dispatch(success());

            const saved = response?.data;

            dispatch(
              setResponse({
                status: response.status,
                data: saved,
                message: t('com.muralis.qcx.mensagem.licencaImportacaoSalva', {
                  processo: data?.atributosAdicionais?.processo,
                }),
              })
            );

            dispatch(setModel(saved));
            dispatch(updateSelectedMercadoria());
            dispatch(updateOnList({ data: saved }));
            dispatch(fetchByIdFromFaturaAsync(saved?.id));
          }, 500);

          handleResultWithDebounce();
        }
      } catch ({ response }) {
        dispatch(failure());
        dispatch(
          setError({
            message: t('com.muralis.qcx.erro.erroSalvarDadosLIMensagem', {
              processo: data?.atributosAdicionais?.processo,
              mensagem: response?.data?.message,
            }),
          })
        );
      }
    }, 500);

    dispatch(loading());
    executeDebounced();
  };

  const normalizePartialLI = useNormalizeLI();

  const refresh = useSelector(selectRefresh);

  useEffect(() => {
    const checkIfIsRefresh = () => {
      if (isRefresh(refresh)) {
        fetchById(params);
        dispatch(resetRefresh());
      }
    };

    checkIfIsRefresh();
  }, [refresh]);

  const handleSubmit = async (data, step, next) => {
    if (isGeneratedLi) {
      const normalizedData = normalizePartialLI(data);
      dispatch(updatePartialLi(normalizedData));
      handleChangeToConsult();
      return;
    }

    const normalizedData = normalize(data);
    if (isUpdate && !isBackgroundCreate) {
      await update(normalizedData, step);
      handleChangeToConsult();
    } else {
      await createByStep(normalizedData, step, next);
    }
  };

  const handleAlternativeSave = async (event, step, next) => {
    if (event && !isEmpty(event)) {
      event.stopPropagation();
    }

    const normalizedData = normalize(licencaImportacao);

    if (isUpdate) {
      await update(normalizedData, step);
    } else {
      await createByStep(normalizedData, step, next);
    }
  };

  const handleMercadoria = async (data, callback) => {
    const callCallbackIfValid = (...args) => {
      if (isFunction(callback)) {
        callback(...args);
      }
    };

    const isCreateOperation = isSubCreate;

    if (isCreateOperation) {
      const unnormalizedData = {
        ...licencaImportacao,
        mercadorias: [...(licencaImportacao?.mercadorias || []), data],
      };
      const normalizedData = normalize(unnormalizedData);

      const executeDebounced = debounce(async () => {
        try {
          const response = await faturaAPI.save(normalizedData, [{ name: 'STEP', value: 4 }]);

          if (response?.status === 200) {
            const handleResultWithDebounce = debounce(() => {
              dispatch(success());

              const saved = response?.data;

              const lastItemAdded = saved?.mercadorias?.length;

              dispatch(
                setResponse({
                  status: response.status,
                  data: saved,
                  message: t('com.muralis.qcx.mensagem.itemAdicionadoListaMercadoriasLI', {
                    item: lastItemAdded,
                    processo: licencaImportacao?.atributosAdicionais?.processo,
                  }),
                })
              );

              dispatch(resetRelatedMercadoriaModel());
              dispatch(changeToSubCreateMode());
              dispatch(fetchByIdFromFaturaAsync(saved?.id));
            }, 500);

            handleResultWithDebounce();

            callCallbackIfValid();
          }
        } catch ({ response }) {
          const itemNumber = normalizedData?.mercadorias?.length;

          dispatch(failure());
          dispatch(
            setError({
              message: t('com.muralis.qcx.erro.erroAdicionarItemMercadoriaLI', {
                item: itemNumber,
                processo: licencaImportacao?.atributosAdicionais?.processo,
                mensagem: response?.data?.message,
              }),
            })
          );
        }
      }, 500);

      dispatch(loading());
      executeDebounced();

      return;
    }

    const isUpdateOperation = isSubUpdate;

    if (isUpdateOperation) {
      dispatch(setRelatedMercadoriaModel(data));

      const unnormalizedData = {
        ...licencaImportacao,
        mercadorias: [
          ...(licencaImportacao?.mercadorias.map((current) => (current?.id === data?.id ? data : current)) || []),
        ],
      };
      const normalizedData = normalize(unnormalizedData);

      const executeDebounced = debounce(async () => {
        try {
          const response = await faturaAPI.save(normalizedData, [{ name: 'STEP', value: 4 }]);

          if (response?.status === 200) {
            const handleResultWithDebounce = debounce(() => {
              dispatch(success());

              const saved = response?.data;

              dispatch(
                setResponse({
                  status: response.status,
                  data: saved,
                  message: t('com.muralis.qcx.mensagem.itemSalvoListaMercadoriasLI', {
                    item: data?.item,
                    processo: licencaImportacao?.atributosAdicionais?.processo,
                  }),
                })
              );

              if (isUpdate) {
                handleChangeToConsult();
              }

              dispatch(changeToSubConsultMode());
              dispatch(fetchByIdFromFaturaAsync(saved?.id));
            }, 500);

            handleResultWithDebounce();
          }
        } catch ({ response }) {
          dispatch(failure());
          dispatch(
            setError({
              message: t('com.muralis.qcx.erro.erroSalvarItemMercadoriaLI', {
                item: data?.item,
                processo: licencaImportacao?.atributosAdicionais?.processo,
                mensagem: response?.data?.message,
              }),
            })
          );
        }
      }, 500);

      dispatch(loading());
      executeDebounced();

      return;
    }

    const isRemoveOperation =
      (isBackgroundDelete || isSubBackgroundDelete) && (isSubNone || isSubConsult || isSubUpdate);

    if (isRemoveOperation) {
      const unnormalizedData = {
        ...licencaImportacao,
        mercadorias: [...(licencaImportacao?.mercadorias.filter((current) => current?.id !== data?.id) || [])],
      };
      const normalizedData = normalize(unnormalizedData);

      const executeDebounced = debounce(async () => {
        try {
          const response = await faturaAPI.save(normalizedData, [{ name: 'STEP', value: 4 }]);

          if (response?.status === 200) {
            const handleResultWithDebounce = debounce(() => {
              dispatch(success());

              const saved = response?.data;

              dispatch(
                setResponse({
                  status: response.status,
                  data: saved,
                  message: t('com.muralis.qcx.mensagem.itemRemovidoListaMercadoriaLI', {
                    item: data?.item,
                    processo: licencaImportacao?.atributosAdicionais?.processo,
                  }),
                })
              );

              dispatch(fetchByIdFromFaturaAsync(saved?.id));
              dispatch(resetSubBackgroundMode());

              callCallbackIfValid(saved?.mercadorias);
            }, 500);

            handleResultWithDebounce();
          }
        } catch ({ response }) {
          dispatch(failure());
          dispatch(
            setError({
              message: t('com.muralis.qcx.erro.erroRemoverItemListaMercadoriaLI', {
                item: data?.item,
                processo: licencaImportacao?.atributosAdicionais?.processo,
                mensagem: response?.data?.message,
              }),
            })
          );
        } finally {
          handleResetBackgroundMode();
        }
      }, 500);

      dispatch(loading());
      executeDebounced();
    }
  };

  const model = useMemo(
    () =>
      isCreated || !isCreate
        ? unnormalize({
            ...licencaImportacao,
          })
        : {
            ...licencaImportacao,
            followUp: {
              id: licencaImportacao?.followUp?.id,
            },
            importador: {
              id: licencaImportacao?.followUp?.importador?.id,
            },
          },
    [isCreate, isCreated, licencaImportacao, unnormalize]
  );

  const hasLisIntegradasOuRegistradas = useMemo(() => {
    const hasLiIntegradaOuRegistrada = faturaOriginal?.lis?.some(
      (li) =>
        isIntegradaSiscomex(li?.registro, li?.integracaoRegistroSiscomex) ||
        isIntegracaoSiscomexErroStatus(li?.integracaoRegistroSiscomex)
    );

    return hasLiIntegradaOuRegistrada;
  }, [model, faturaOriginal, isIntegradaSiscomex]);

  const isBloqueada = useMemo(() => {
    const statusIntegracaoSiscomex = !!model?.atributosAdicionais?.integracaoRegistroSiscomex;
    const registroLi = !!model?.atributosAdicionais?.registro;
    const isRegistradaOuSalvaSiscomex = !!isIntegradaSiscomex(registroLi, statusIntegracaoSiscomex);

    const isLiBloqueada =
      hasLisIntegradasOuRegistradas ||
      isIntegracaoSiscomexErroStatus(statusIntegracaoSiscomex) ||
      isRegistradaOuSalvaSiscomex;

    return isLiBloqueada;
  }, [model, hasLisIntegradasOuRegistradas]);

  const actionName = useMemo(() => {
    if (isCreate || isBackgroundCreate || isNone) return t('com.muralis.qcx.acoes.novo');
    if (isConsult) return t('com.muralis.qcx.acoes.visualizar');
    return t('com.muralis.qcx.acoes.alterar');
  }, [isNone, isCreate, isConsult, isBackgroundCreate]);

  const breadcrumbs = useMemo(
    () => [
      {
        link: {
          to: '/',
          name: t('com.muralis.qcx.inicio'),
        },
      },
      {
        link: {
          to: t('com.muralis.qcx.url.moduloImportacao'),
          name: t('com.muralis.qcx.importacao.label'),
        },
      },
      {
        link: {
          to: t('com.muralis.qcx.url.importacaoLicencaImportacao'),
          name: t('com.muralis.qcx.licencasImportacaoLI'),
        },
      },
      {
        text: {
          name: `${isCreate ? t('com.muralis.qcx.registroManual') : t('com.muralis.qcx.registro')}`,
        },
      },
      {
        text: {
          name: actionName,
        },
      },
    ],
    [isCreate, actionName]
  );
  const pageTitle = useMemo(
    () =>
      isNone || isCreate || isBackgroundCreate
        ? t('com.muralis.qcx.licencaImportacao.novoRegistroManualLIProcesso', {
            processo: model?.atributosAdicionais?.processo || '-',
          })
        : t('com.muralis.qcx.licencaImportacao.licencaImportacaoProcesso', {
            processo: model?.atributosAdicionais?.processo || '-',
          }),
    [isNone, isCreate, isBackgroundCreate, model]
  );

  return (
    <QCXRegistrationFormPageTemplate
      pageTitle={pageTitle}
      breadcrumbs={breadcrumbs}
      isIdle={isIdle}
      isLoading={isLoading}
      isFailure={isFailure}
      isCreate={isCreate}
      isConsult={isConsult}
      isUpdate={isUpdate}
      isLocked={isLocked}
      isBackgroundCreate={isBackgroundCreate}
      isBackgroundDelete={isBackgroundDelete}
      isPreparingAction={isPreparingAction}
      isSubBackgroundCreate={isSubBackgroundCreate}
      isSubBackgroundConsult={isSubBackgroundConsult}
      isSubBackgroundUpdate={isSubBackgroundUpdate}
      isSubBackgroundDelete={isSubBackgroundDelete}
      handleAlternativeSave={handleAlternativeSave}
      handleChangeToPreparingAction={handleChangeToPreparingAction}
      handleChangeToCreate={handleChangeToCreate}
      handleChangeToBackgroundCreate={handleChangeToBackgroundCreate}
      handleChangeToBackgroundDelete={handleChangeToBackgroundDelete}
      handleChangeToConsult={handleChangeToConsult}
      handleChangeToUpdate={handleChangeToUpdate}
      handleCancelUpdate={handleCancelUpdate}
      handleResetBackgroundMode={handleResetBackgroundMode}
      handleMetadataUpdate={handleMetadataUpdate}
      showSubtitle={false}
      authInfo={authInfo}
      disableUpdate={isBloqueada}
    >
      {(formProps) => (
        <QCXLicencaImportacaoWizardFinalForm
          model={model}
          handleChangeModel={handleDispatchSetModel}
          handleSubmit={handleSubmit}
          handleMercadoria={handleMercadoria}
          authInfo={authInfo}
          requiredRoles={['importacao-li-lpco']}
          {...formProps}
        >
          <QCXConfirmDialog
            open={operationOfChangeToUpdate?.isPopUpOpen}
            title={operationOfChangeToUpdate?.title}
            content={operationOfChangeToUpdate?.message}
            endContent={operationOfChangeToUpdate?.endMessage}
            onConfirm={operationOfChangeToUpdate.confirmPrimaryAction}
            onSecondaryConfirm={operationOfChangeToUpdate.confirmSecondaryAction}
            onClose={operationOfChangeToUpdate?.reset}
            buttonGroupOptions={{
              confirm: {
                description: t('com.muralis.qcx.acoes.continuar'),
              },
              secondaryConfirm: {
                description: 'Manter',
              },
            }}
          />
          <QCXConfirmDialog
            open={operationOfCancelUpdate?.active}
            title={operationOfCancelUpdate?.title}
            content={operationOfCancelUpdate?.message}
            endContent={operationOfCancelUpdate?.endMessage}
            onConfirm={operationOfCancelUpdate.confirm}
            onClose={operationOfCancelUpdate?.reset}
            buttonGroupOptions={{
              confirm: {
                description: t('com.muralis.qcx.expressao.sim'),
              },
              cancel: {
                description: t('com.muralis.qcx.expressao.nao'),
              },
            }}
          />
        </QCXLicencaImportacaoWizardFinalForm>
      )}
    </QCXRegistrationFormPageTemplate>
  );
}
