import { isFunction } from 'lodash';
import React from 'react';
import QCXFinalField, { checkErrorOnChange } from './QCXFinalField';

export default function QCXFinalFieldWrapper({
  name,
  label,
  type,
  required,
  component,
  validate,
  checkError = checkErrorOnChange,
  format,
  parse,
  formatOnBlur = false,
  children,
  componentProps = {},
  variant = 'outlined',
  onBlur = undefined,
  ...restProps
}) {
  const Component = component;

  const handleFormatingOnBlur = (...args) => {
    if (isFunction(onBlur)) {
      onBlur();
    }
    if (isFunction(format)) {
      return format(...args);
    }
    return null;
  };

  return (
    <QCXFinalField
      name={name}
      type={type}
      required={required}
      render={({
        input: {
          name: inputName,
          value,
          type: inputType,
          onChange,
          ...restInputProps
        },
        meta,
        ...restComponentProps
      }) => {
        const { error, submitError } = meta;
        const isError = checkError({ meta });

        const helperTextProp = {
          helperText: isError ? error || submitError : restProps?.helperText,
        };

        return (
          <Component
            variant={variant}
            label={label}
            name={inputName}
            type={inputType}
            value={value}
            error={isError}
            onChange={onChange}
            required={required}
            inputProps={{
              required,
              ...restInputProps,
            }}
            meta={meta}
            {...helperTextProp}
            {...restComponentProps}
            {...componentProps}
          />
        );
      }}
      validate={validate}
      format={formatOnBlur ? handleFormatingOnBlur : format}
      parse={parse}
      formatOnBlur={formatOnBlur}
      {...(!formatOnBlur && isFunction(onBlur)
        ? { onBlur }
        : {}
      )}
      {...restProps}
    />
  );
}
