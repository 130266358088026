import {
  BRAZILIAN,
  MASK_NUMERIC_DECIMAL,
} from './mask-types';
import useFieldFormat from './useFieldFormat';
import useFieldParse from './useFieldParse';

export default function useFieldMask(maskType) {
  const format = useFieldFormat(maskType);
  const parse = useFieldParse(maskType);

  return [format, parse];
}

function useNumericMask({
  intl = BRAZILIAN,
  scale = 2,
}) {
  const maskType = [MASK_NUMERIC_DECIMAL, intl];

  const format = useFieldFormat(maskType);
  const parse = useFieldParse(maskType);

  return [format(scale), parse];
}

export {
  useNumericMask,
};
