import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { cidadeActions } from '../../features/cidade/cidadeSlice';
import { AUTO_LOAD_CONTROL } from '../../features/config-control';
import { estadoActions } from '../../features/estado/estadoSlice';
import { paisActions } from '../../features/pais/paisSlice';
import QCXBox from '../../shared-components/box/QCXBox';
import QCXFinalOnblurMaskedField from '../../shared-components/final-onblur-masked-field/QCXFinalOnblurMaskedField';
import QCXFinalSelectTipoPessoaAlternativaAutocomplete from '../../shared-components/select-tipo-pessoa/QCXFinalSelectTipoPessoaAlternativaAutocomplete';
import QCXFinalTextField from '../../shared-components/final-text-field/QCXFinalTextField';
import QCXFormStepWizard from '../../shared-components/form-step-wizard/QCXFormStepWizard';
import QCXSelectCidadeAutocomplete from '../../shared-components/select-cidade/QCXSelectCidadeAutocomplete';
import QCXSelectEstadoAutocomplete from '../../shared-components/select-estado/QCXSelectEstadoAutocomplete';
import QCXSelectPaisAutocomplete from '../../shared-components/select-pais/QCXSelectPaisAutocomplete';
import { formatCnpj, formatCpf } from '../../utils/hooks/form/field/formatters';
import { numberOnly } from '../../utils/hooks/form/field/parsers';
import { required as requiredValidator, validCnpj, validCpf } from '../../utils/validators/field/validator';
import QCXSelectTipoLogradouroAutocomplete from '../../shared-components/select-tipo-logradouro/QCXSelectTipoLogradouroAutocomplete';

export default function QCXClientePersonalDataForm({
  initialValues,
  handleSubmit,
  isConsult,
  isUpdate,
  controlComponentProps,
  authInfo = {},
  requiredRoles = [],
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  dispatch(paisActions.changeControlTo(AUTO_LOAD_CONTROL));

  const isPessoaJuridica = (tipoPessoa) =>
    tipoPessoa === 'PESSOA_JURIDICA' ||
    tipoPessoa === 'ORGAOS_DIPLOMATICOS_E_REPRESENTANTES_DE_ORGANISMOS_INTERNACIONAIS' ||
    tipoPessoa === null;

  const isPessoaJuridicaBy = (values) => isPessoaJuridica(values?.tipoPessoa);

  const isBrasil = (values) => values?.endereco?.cidade?.estado?.pais?.id === 1;

  const isPessoaFisica = (tipoPessoa) =>
    tipoPessoa === 'PESSOA_FISICA' || tipoPessoa === 'FISICAS_DOMICILIADAS_NO_EXTERIOR';

  const isPessoaFisicaBy = (values) => isPessoaFisica(values?.tipoPessoa);

  const paisOnchange = (form) => (value) => {
    form.change('endereco.cidade.id', undefined);
    form.change('endereco.cidade.estado.id', undefined);
    form.change('endereco.cidade.estado.pais.id', value);
    form.change('pessoa.cnae.id', '');
    dispatch(estadoActions.fetchByFilterAsync([{ name: 'pais', value }]));
  };

  const estadoOnchange = (form) => (value) => {
    form.change('endereco.cidade.id', undefined);
    form.change('endereco.cidade.estado.id', value);
    dispatch(cidadeActions.fetchByFilterAsync([{ name: 'estado', value }]));
  };

  const cidadeOnchange = (form) => (value) => {
    form.change('endereco.cidade.id', value);
  };

  const tipoPessoaOnchange = (form) => (value) => {
    if (isPessoaJuridica(value)) {
      form.change('pessoa.cpf', undefined);
    } else {
      form.change('pessoa.cnpj', undefined);
      form.change('pessoa.nomeResumido', undefined);
    }
  };

  const listenerList = [
    { name: 'endereco.cidade.estado.pais.id', fn: paisOnchange },
    { name: 'endereco.cidade.estado.id', fn: estadoOnchange },
    { name: 'endereco.cidade.id', fn: cidadeOnchange },
    { name: 'tipoPessoa', fn: tipoPessoaOnchange },
  ];

  return (
    <QCXFormStepWizard
      initialValues={initialValues}
      onSubmit={handleSubmit}
      controlComponentProps={controlComponentProps}
      onChangeListeners={listenerList}
      authInfo={authInfo}
      requiredRoles={requiredRoles}
    >
      {({ values }) => (
        <>
          <Typography variant="h6" color="primary">
            <QCXBox fontWeight="fontWeightBold" pt={5}>
              {t('com.muralis.qcx.dadosPessoais').toUpperCase()}
            </QCXBox>
          </Typography>
          <Grid container spacing={2} style={{ marginTop: '1rem' }}>
            {(isConsult || isUpdate) && (
              <Grid item xs={12} sm={2} md={2}>
                <QCXFinalTextField
                  id="text-field-code"
                  key="text-field-code"
                  name="id"
                  label={t('com.muralis.qcx.codigo')}
                  disabled
                />
              </Grid>
            )}
            <Grid item xs={12} sm={isPessoaFisicaBy(values) && (isConsult || isUpdate) ? 4 : 6} md={3}>
              <QCXFinalSelectTipoPessoaAlternativaAutocomplete
                id="text-field-tipo-pessoa"
                key="text-field-tipo-pessoa"
                name="tipoPessoa"
                label={t('com.muralis.qcx.tipoPessoa.label')}
                initialValues={values}
                fieldProps={{ validate: requiredValidator }}
                disabled={isConsult}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={!(isConsult || isUpdate) ? 5 : 4}>
              <QCXFinalTextField
                id="text-field-pessoa-nome"
                key="text-field-pessoa-nome"
                name="pessoa.nome"
                label={t('com.muralis.qcx.nome')}
                maxLength={50}
                validate={requiredValidator}
                disabled={isConsult}
                required
              />
            </Grid>
            {isPessoaJuridicaBy(values) && (
              <Grid item xs={12} sm={isConsult || isUpdate ? 4 : 6} md={!(isConsult || isUpdate) ? 4 : 3}>
                <QCXFinalTextField
                  id="text-field-pessoa-nome-resumido"
                  key="text-field-pessoa-nome-resumido"
                  name="pessoa.nomeResumido"
                  label={t('com.muralis.qcx.empresa.nomeFantasia')}
                  maxLength={30}
                  validate={requiredValidator}
                  disabled={isConsult}
                  required
                />
              </Grid>
            )}
            {isPessoaFisicaBy(values) && (
              <Grid item xs={12} sm={3} md={isConsult || isUpdate ? 3 : 4}>
                <QCXFinalOnblurMaskedField
                  id="text-field-pessoa-cpf"
                  key="text-field-pessoa-cpf"
                  name="pessoa.cpf"
                  label={t('com.muralis.qcx.CPF')}
                  format={formatCpf}
                  parse={numberOnly}
                  validate={isPessoaFisicaBy(values) ? validCpf : undefined}
                  maxLength={11}
                  disabled={isConsult}
                  initialValues={initialValues}
                />
              </Grid>
            )}
            {isPessoaJuridicaBy(values) &&
              (isBrasil(values) ? (
                <Grid item xs={12} sm={3} md={3}>
                  <QCXFinalOnblurMaskedField
                    id="text-field-pessoa-cnpj"
                    key="text-field-pessoa-cnpj"
                    name="pessoa.cnpj"
                    label={t('com.muralis.qcx.empresa.CNPJ')}
                    format={formatCnpj}
                    parse={numberOnly}
                    validate={isPessoaJuridicaBy(values) ? validCnpj : undefined}
                    required
                    maxLength={14}
                    disabled={isConsult}
                    initialValues={initialValues}
                  />
                </Grid>
              ) : (
                <Grid item xs={12} sm={3} md={3}>
                  <QCXFinalTextField
                    id="text-field-pessoa-cnpj"
                    key="text-field-pessoa-cnpj"
                    name="pessoa.cnpj"
                    label={t('com.muralis.qcx.empresa.CNPJ')}
                    required
                    disabled={isConsult}
                    initialValues={initialValues}
                  />
                </Grid>
              ))}
            <Grid item xs={12} sm={3} md={isPessoaFisicaBy(values) ? 2 : 3}>
              <QCXFinalTextField
                id="text-field-endereco-cep"
                key="text-field-endereco-cep"
                name="endereco.cep"
                label={t('com.muralis.qcx.codigoPostal')}
                parse={numberOnly}
                validate={requiredValidator}
                disabled={isConsult}
                maxLength={8}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={isPessoaFisicaBy(values) ? 2 : 3}>
              <QCXSelectTipoLogradouroAutocomplete
                name="tipoLogradouro"
                label={t('com.muralis.qcx.tipoLogradouro.label')}
                initialValues={values}
                fieldProps={{ validate: requiredValidator }}
                disabled={isConsult}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={isPessoaFisicaBy(values) ? 2 : 3}>
              <QCXFinalTextField
                name="grupo"
                id="text-field-grupo"
                key="text-field-grupo"
                label={t('com.muralis.qcx.grupo')}
                maxLength={20}
                disabled={isConsult}
              />
            </Grid>
            <Grid item xs={12} sm={isPessoaFisicaBy(values) ? 5 : 6} md={isPessoaFisicaBy(values) ? 5 : 6}>
              <QCXFinalTextField
                id="text-field-endereco-logradouro"
                key="text-field-endereco-logradouro"
                name="endereco.logradouro"
                label={t('com.muralis.qcx.endereco.logradouro')}
                maxLength={150}
                validate={requiredValidator}
                disabled={isConsult}
                required
              />
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
              <QCXFinalTextField
                id="text-field-endereco-numero"
                key="text-field-endereco-numero"
                name="endereco.numero"
                label={t('com.muralis.qcx.endereco.numero')}
                maxLength={10}
                validate={requiredValidator}
                disabled={isConsult}
                required
              />
            </Grid>
            <Grid item xs={12} sm={isPessoaFisicaBy(values) ? 5 : 4} md={3}>
              <QCXFinalTextField
                id="text-field-endereco-complemento"
                key="text-field-endereco-complemento"
                name="endereco.complemento"
                label={t('com.muralis.qcx.endereco.complemento')}
                maxLength={50}
                disabled={isConsult}
              />
            </Grid>
            <Grid item xs={12} sm={isPessoaFisicaBy(values) ? 4 : 3} md={3}>
              <QCXFinalTextField
                id="text-field-endereco-bairro"
                key="text-field-endereco-bairro"
                name="endereco.bairro"
                label={t('com.muralis.qcx.endereco.bairro')}
                maxLength={70}
                validate={requiredValidator}
                disabled={isConsult}
                required
              />
            </Grid>
            <Grid item xs={12} sm={isPessoaFisicaBy(values) ? 4 : 3} md={3}>
              <QCXSelectPaisAutocomplete
                name="endereco.cidade.estado.pais.id"
                label={t('com.muralis.qcx.endereco.pais')}
                fieldProps={{ validate: requiredValidator }}
                disabled={isConsult}
                required
                initialValues={values}
              />
            </Grid>
            <Grid item xs={12} sm={isPessoaFisicaBy(values) ? 4 : 3} md={3}>
              <QCXSelectEstadoAutocomplete
                name="endereco.cidade.estado.id"
                label={t('com.muralis.qcx.endereco.estado')}
                fieldProps={{ validate: requiredValidator }}
                disabled={isConsult}
                required
                initialValues={values}
              />
            </Grid>
            <Grid item xs={12} sm={isPessoaFisicaBy(values) ? 4 : 3} md={3}>
              <QCXSelectCidadeAutocomplete
                name="endereco.cidade.id"
                label={t('com.muralis.qcx.endereco.cidade')}
                fieldProps={{ validate: requiredValidator }}
                initialValues={values}
                disabled={isConsult}
                required
              />
            </Grid>
          </Grid>
        </>
      )}
    </QCXFormStepWizard>
  );
}
