import { IconButton } from '@material-ui/core';
import {
  Delete as DeleteIcon, Search as PageviewIcon,
} from '@material-ui/icons';
import React, {
  useCallback, useContext, useEffect, useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import FornecedorContext from '../../../../../contexts/registrations/fornecedor/FornecedorContext';
import { formatCnpj, formatCpf } from '../../../../../utils/hooks/form/field/formatters';
import {
  activateByIdAsync,
  fetchAllAsync,
  inactivateByIdAsync,
  resetMode,
  resetModel,
  selectFornecedoresFavorecidos,
} from '../../../../../features/fornecedor-favorecido/FornecedorSlice';
import QCXSimpleConsultPageTemplate from '../../../../../templates/simple-consult-page/QCXSimpleConsultPageTemplate';
import useOperationController from '../../../../../utils/hooks/store/operation/useOperationController';
import { isAlternativeLoadingStatus } from '../../../../../utils/store/store-utils';
import { userHasDeleteRole } from '../../../../../components/perfil/perfil-utils';

export default function FornecedorConsultPage({ authInfo = {} }) {
  const { t } = useTranslation();

  const history = useHistory();

  const {
    status,
  } = useContext(FornecedorContext);

  const dispatch = useDispatch();

  const fornecedoresFavorecidos = useSelector(selectFornecedoresFavorecidos);

  const isAlternativeLoading = useMemo(() => (
    isAlternativeLoadingStatus(status)
  ), [status]);

  const fetchAll = useCallback(() => {
    dispatch(fetchAllAsync());
  }, []);

  const resetModes = useCallback(() => {
    dispatch(resetMode());
  }, []);

  const resetModels = useCallback(() => {
    dispatch(resetModel());
  }, []);

  useEffect(() => {
    fetchAll();
    resetModes();
    resetModels();
  }, []);

  const handleActivateInactivate = useOperationController(
    async (event, id) => {
      event.preventDefault();

      const { active } = fornecedoresFavorecidos.find(
        (fornecedorFornecido) => (
          fornecedorFornecido?.id === id
        )
      );

      const isActivation = !active;

      if (isActivation) {
        dispatch(activateByIdAsync(id));
      } else {
        dispatch(inactivateByIdAsync(id));
      }
    }
  );

  const handleClickOnEditButton = useCallback((event, id) => {
    event.stopPropagation();
    history.push(t('com.muralis.qcx.url.modulosFinanceirosFornecedorDetalhes', { id }));
  }, [history]);

  const columns = [
    {
      field: 'id',
      headerName: t('com.muralis.qcx.codigo'),
      headerAlign: 'center',
      align: 'center',
      type: 'string',
      flex: 50,
      valueGetter: ({ row }) => (
        row?.id
      ),
    },
    {
      field: 'razaoSocial',
      headerName: t('com.muralis.qcx.empresa.razaoSocial'),
      type: 'string',
      flex: 250,
      valueGetter: ({ row }) => (
        row?.pessoa?.nome
      ),
    },
    {
      field: 'CPFCNPJ',
      headerName: t('com.muralis.qcx.CPFCNPJ'),
      type: 'number',
      headerAlign: 'center',
      align: 'center',
      flex: 100,
      valueGetter: ({ row }) => (
        formatCnpj(row?.pessoa?.cnpj) || formatCpf(row?.pessoa?.cpf)
      ),
    },
    {
      field: 'favorecido',
      headerName: t('com.muralis.qcx.favorecido'),
      type: 'boolean',
      flex: 100,
      valueGetter: ({ row }) => (
        row?.favorecido
      ),
    },
    {
      field: 'actions',
      headerName: t('com.muralis.qcx.acoes.label'),
      headerAlign: 'center',
      align: 'center',
      flex: 80,
      renderCell: ({ row: { id, active } }) => (
        <>
          {userHasDeleteRole(authInfo, 'fornecedor-favorecido') && (
          <IconButton
            key={`stw-active${id}`}
            value={active}
            name={`stw-active-${id}`}
            onClick={(event) => handleActivateInactivate(event, id)}
          >
            <DeleteIcon color="error" />
          </IconButton>
          )}
          <IconButton
            key={`btn-view-${id}`}
            name={`btn-view-${id}`}
            onClick={(event) => handleClickOnEditButton(event, id)}
          >
            <PageviewIcon color="primary" />
          </IconButton>
        </>
      ),
    },
  ];

  const breadcrumbs = [
    {
      link: {
        to: '/',
        name: t('com.muralis.qcx.inicio'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.cadastros'),
      },
    },
    {
      link: {
        to: t('com.muralis.qcx.url.moduloCadastrosFinanceiros'),
        name: t('com.muralis.qcx.financeiro.label'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.fornecedor.label'),
      },
    },
  ];

  const templateProps = {
    page: {
      title: t('com.muralis.qcx.fornecedor.label'),
      breadcrumbs,
    },
    control: {
      buttonNew: {
        description: t('com.muralis.qcx.fornecedor.novoFornecedor'),
        path: t('com.muralis.qcx.url.modulosFinanceirosFornecedorNovo'),
      },
    },
    table: {
      columns,
    },
  };

  return (
    <QCXSimpleConsultPageTemplate
      pageProps={templateProps.page}
      controlProps={templateProps.control}
      tableProps={templateProps.table}
      tableData={fornecedoresFavorecidos}
      isLoading={isAlternativeLoading}
      buttonNewText={t('com.muralis.qcx.acoes.novo').toUpperCase()}
      multipleFilter
      authInfo={authInfo}
      requiredRoles={['fornecedor-favorecido']}
    />
  );
}
