import React, { useMemo } from 'react';
import { v4 as uuid } from 'uuid';
import { BRAZILIAN } from '../../utils/hooks/form/field/mask-types';
import { useNumericMask } from '../../utils/hooks/form/field/useFieldMask';
import QCXFinalFieldWrapper from '../final-field/QCXFinalFieldWrapper';
import QCXTextField from '../text-field/QCXTextField';
import QCXInputAdornment from '../input-adornment/QCXInputAdornment';

export default function QCXFinalPercentualField({
  key = 'text-field-percentual',
  name,
  label,
  type,
  intl = BRAZILIAN,
  scale = 2,
  formatOnBlur = true,
  required,
  helperText,
  validate,
  children,
  submitSucceeded,
  ...restProps
}) {
  const [format, parse] = useNumericMask({
    intl,
    scale,
  });

  // FIXME: Solucao provisoria
  const generatedKey = useMemo(() => (
    submitSucceeded ? uuid() : key
  ), [submitSucceeded, key]);

  return (
    <QCXFinalFieldWrapper
      key={generatedKey}
      name={name}
      type={type}
      label={label}
      required={required}
      component={QCXTextField}
      validate={validate}
      format={format}
      parse={parse}
      formatOnBlur={formatOnBlur}
      InputProps={{
        endAdornment: (
          <QCXInputAdornment>
            %
          </QCXInputAdornment>
        ),
      }}
      {...restProps}
    />
  );
}
