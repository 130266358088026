import { Grid } from '@material-ui/core';
import { useCallback, useMemo } from 'react';
import {
  vinculoReferenciaClienteFollowUpActions,
  vinculoReferenciaClienteFollowUpSelectors,
} from '../../../../../../../features/vinculo-referencia-cliente-follow-up/vinculoReferenciaClienteFollowUpSlice';
import QCXFinalBondManagerOld from '../../../../../../../shared-components/final-gerenciador-relacionamento/QCXFinalBondManagerOld';
import QCXSelectOrgaoAnuenteAutocomplete from '../../../../../../../shared-components/select-orgao-anuente/QCXSelectOrgaoAnuenteAutocomplete';
import { isStrictEquals } from '../../../../../../../utils/general/general-utils';

function OrgaoAnuenteBondManager({ isConsult }) {
  const referenciaClienteReducerConfig = useMemo(
    () => ({
      selectors: {
        selectStatus: vinculoReferenciaClienteFollowUpSelectors.selectStatus,
        selectMode: vinculoReferenciaClienteFollowUpSelectors.selectMode,
        selectModel: vinculoReferenciaClienteFollowUpSelectors.selectModel,
      },
      actions: {
        loading: vinculoReferenciaClienteFollowUpActions.loading,
        resetStatus: vinculoReferenciaClienteFollowUpActions.resetStatus,
        changeToUpdateMode: vinculoReferenciaClienteFollowUpActions.changeToUpdateMode,
        changeToCreateMode: vinculoReferenciaClienteFollowUpActions.changeToCreateMode,
        resetMode: vinculoReferenciaClienteFollowUpActions.resetMode,
        setModel: vinculoReferenciaClienteFollowUpActions.setModel,
        resetModel: vinculoReferenciaClienteFollowUpActions.resetModel,
      },
    }),
    []
  );

  const referenciaClienteFormProps = useMemo(
    () => ({
      rootName: 'ignorableFields.form',
      fields: [
        {
          name: 'orgaoAnuente',
          label: 'Orgão Anuente',
        },
      ],
    }),
    []
  );

  const referenciaClienteListProps = useMemo(
    () => ({
      name: 'orgaoAnuente',
      columns: [
        {
          field: 'description',
          headerName: 'Descrição',
          headerAlign: 'left',
          align: 'left',
          flex: 140,
          valueGetter: ({ row }) => row?.description || '',
        },
      ],
    }),
    []
  );

  const handleAddReferenciaCliente = useCallback(async (currentVinculo, currentVinculos, handleSuccessAddVinculo) => {
    const updatedVinculos = [...currentVinculos, currentVinculo.orgaoAnuente];

    handleSuccessAddVinculo(updatedVinculos);
  }, []);

  const handleUpdateReferenciaCliente = useCallback(
    async (currentVinculo, currentVinculos, handleSuccessUpdateVinculo) => {
      const updatedVinculo = {
        ...currentVinculo,
        referencia: currentVinculo?.referencia,
      };

      const updatedVinculos =
        currentVinculos?.map((existingVinculo) =>
          isStrictEquals(existingVinculo?.id, updatedVinculo?.id) ? updatedVinculo : existingVinculo
        ) || currentVinculos;

      handleSuccessUpdateVinculo(updatedVinculos);
    },
    []
  );

  const handleReferenciaClienteAlreadyExists = useCallback(
    (currentVinculo, currentVinculosList) =>
      currentVinculosList.some((vinculo) => isStrictEquals(vinculo?.id, currentVinculo?.id)),
    []
  );

  return (
    <>
      <QCXFinalBondManagerOld
        isParentConsult={isConsult}
        reducerConfig={referenciaClienteReducerConfig}
        listProps={referenciaClienteListProps}
        formProps={referenciaClienteFormProps}
        controlButtonsGridProps={{
          add: {
            xs: 12,
            sm: 4,
            md: 4,
          },
          update: {
            xs: 6,
            sm: 2,
            md: 2,
          },
          clear: {
            xs: 6,
            sm: 2,
            md: 2,
          },
        }}
        handleAdd={handleAddReferenciaCliente}
        handleUpdate={handleUpdateReferenciaCliente}
        handleAlreadyExists={handleReferenciaClienteAlreadyExists}
      >
        {() => (
          <Grid item xs={12} sm={8} md={8}>
            <QCXSelectOrgaoAnuenteAutocomplete
              id="autocomplete-field-orgao-anuente"
              key="autocomplete-field-orgao-anuente"
              name="ignorableFields.form.orgaoAnuente"
              label="Orgão Anuente:"
              getOptionValue={(option) => option}
              disabled={isConsult}
            />
          </Grid>
        )}
      </QCXFinalBondManagerOld>
    </>
  );
}

export default OrgaoAnuenteBondManager;
