import { saveAs } from "file-saver";
import { CorrespondenciasListItem, ICorrelation } from "../../totvs.types";
import {
  fillAndAlignString,
  formatDateToAAAAMMDD,
  formatDateToDDMMAA,
  formatDateToDDMMAAAA,
  formatDateToMMAAAA,
  saveTxtFile,
} from "../totvsEscritaPage.helpers";
import { DanfeCapa, DanfeItem } from "./totvsEscritaDanfePage.types";

export const generateDanfeTXT = (
  danfeCapa: DanfeCapa[],
  danfeItens: DanfeItem[]
) => {
  const danfeCapaFirst = danfeCapa[0];
  const danfeCapaTXT = danfeCapaFirst ? flattenDanfeCapa(danfeCapaFirst) : "";
  const danfeItensTXT = danfeItens
    .map((danfeItem) => flattenDanfeItem(danfeItem))
    .join("");
  const danfeTXT = [danfeCapaTXT, danfeItensTXT].join("");
  saveTxtFile(danfeTXT, "totvs-danfe");
  return danfeTXT;
};

const flattenDanfeCapa = (danfeCapa: DanfeCapa) => {
  let danfeCapaTXT = "";
  danfeCapaTXT += fillAndAlignString(danfeCapa.tipo, 1, "left");
  danfeCapaTXT += fillAndAlignString(danfeCapa.codigo_empresa, 2, "left");
  danfeCapaTXT += fillAndAlignString(danfeCapa.codigo_filial, 2, "left");
  danfeCapaTXT += fillAndAlignString(
    danfeCapa.numero_nota.toString(),
    9,
    "right",
    false,
    0,
    "0"
  );
  danfeCapaTXT += fillAndAlignString(danfeCapa.filler1, 6, "left");
  danfeCapaTXT += fillAndAlignString(
    Number(danfeCapa.aliquota_icms).toFixed(2),
    7,
    "left",
    true
  );
  danfeCapaTXT += fillAndAlignString(danfeCapa.cfop, 6, "right");
  danfeCapaTXT += fillAndAlignString(null, 5, "left");
  danfeCapaTXT += fillAndAlignString(danfeCapa.cgc_emitente, 14, "right");
  danfeCapaTXT += fillAndAlignString(danfeCapa.codigo_ipi, 3, "left");
  danfeCapaTXT += fillAndAlignString(danfeCapa.referencia_cliente, 17, "left");
  danfeCapaTXT += fillAndAlignString(
    danfeCapa.quantidade_itens,
    2,
    "right",
    false,
    0,
    "0"
  );
  danfeCapaTXT += fillAndAlignString(
    formatDateToMMAAAA(danfeCapa.data_referencia),
    6,
    "left"
  );
  danfeCapaTXT += fillAndAlignString(
    formatDateToAAAAMMDD(danfeCapa.data_emissao),
    8,
    "left"
  );
  danfeCapaTXT += fillAndAlignString(
    formatDateToAAAAMMDD(danfeCapa.data_entrada),
    8,
    "left"
  );
  danfeCapaTXT += fillAndAlignString(danfeCapa.filler2, 6, "left");
  danfeCapaTXT += fillAndAlignString(
    formatDateToAAAAMMDD(danfeCapa.data_registro_di),
    8,
    "left"
  );
  danfeCapaTXT += fillAndAlignString(danfeCapa.filler3, 2, "left");
  danfeCapaTXT += fillAndAlignString("E", 1, "left");
  danfeCapaTXT += fillAndAlignString("NFE", 3, "left");
  danfeCapaTXT += fillAndAlignString(null, 1, "left");
  danfeCapaTXT += fillAndAlignString(null, 1, "left");
  danfeCapaTXT += fillAndAlignString(danfeCapa.flag_entrada, 1, "left");
  danfeCapaTXT += fillAndAlignString(
    danfeCapa.numero_registro_di.replace(/[-\/]/g, ""),
    10,
    "left"
  );
  danfeCapaTXT += fillAndAlignString(danfeCapa.serie.toString(), 3, "left");
  danfeCapaTXT += fillAndAlignString(danfeCapa.codigo_cst, 2, "left");
  danfeCapaTXT += fillAndAlignString(
    danfeCapa.situacao_tributaria_e,
    5,
    "left"
  );
  danfeCapaTXT += fillAndAlignString(
    danfeCapa.situacao_tributaria_f,
    5,
    "left"
  );
  danfeCapaTXT += fillAndAlignString(danfeCapa.uf_emitente, 2, "left");
  danfeCapaTXT += fillAndAlignString(
    danfeCapa.via_transporte?.slice(1, 2),
    1,
    "left"
  );
  danfeCapaTXT += fillAndAlignString(
    Number(danfeCapa.valor_frete).toFixed(2),
    15,
    "left",
    true
  );
  danfeCapaTXT += fillAndAlignString(
    Number(danfeCapa.valor_icms).toFixed(2),
    15,
    "left",
    true
  );
  danfeCapaTXT += fillAndAlignString(
    Number(danfeCapa.valor_base_icms).toFixed(2),
    15,
    "left",
    true
  );
  danfeCapaTXT += fillAndAlignString(danfeCapa.codigo_importador, 4, "left");
  danfeCapaTXT += fillAndAlignString(
    danfeCapa.codigo_transportador,
    20,
    "left"
  );
  danfeCapaTXT += fillAndAlignString(
    Number(danfeCapa.valor_seguro).toFixed(2),
    15,
    "left",
    true
  );
  danfeCapaTXT += fillAndAlignString(
    Number(danfeCapa.valor_ipi).toFixed(2),
    15,
    "left",
    true
  );
  danfeCapaTXT += fillAndAlignString(
    Number(danfeCapa.valor_total_mercadoria).toFixed(2),
    15,
    "left",
    true
  );
  danfeCapaTXT += fillAndAlignString(
    danfeCapa.valor_total_nota,
    15,
    "left",
    true
  );
  danfeCapaTXT += fillAndAlignString(
    danfeCapa.valor_base_ipi,
    15,
    "left",
    true
  );
  danfeCapaTXT += fillAndAlignString(danfeCapa.filler5, 2, "left");
  danfeCapaTXT += fillAndAlignString(danfeCapa.codigo_exportador, 4, "left");
  danfeCapaTXT += fillAndAlignString(danfeCapa.nome_exportador, 60, "left");
  danfeCapaTXT += fillAndAlignString(danfeCapa.numero_po, 17, "left");
  danfeCapaTXT += fillAndAlignString(
    danfeCapa.perc_reducao_base_icms?.toString() ?? null,
    6,
    "left",
    true
  );

  return danfeCapaTXT;
};

const flattenDanfeItem = (danfeItem: DanfeItem) => {
  let danfeItemTXT = "\n";
  danfeItemTXT += fillAndAlignString(danfeItem.tipo, 1, "left");
  danfeItemTXT += fillAndAlignString(danfeItem.codigo_empresa, 2, "left");
  danfeItemTXT += fillAndAlignString(danfeItem.codigo_filial, 2, "left");
  danfeItemTXT += fillAndAlignString(
    danfeItem.numero_nota?.toString() ?? null,
    9,
    "right",
    false,
    0,
    "0"
  );
  danfeItemTXT += fillAndAlignString(
    danfeItem.lancamento?.toString() ?? null,
    6,
    "right",
    false,
    0,
    "0"
  );
  danfeItemTXT += fillAndAlignString(danfeItem.classificacao, 10, "left");
  danfeItemTXT += fillAndAlignString(danfeItem.codigo_produdo, 20, "left");
  danfeItemTXT += fillAndAlignString(danfeItem.codigo_trib_icms, 1, "left");
  danfeItemTXT += fillAndAlignString(danfeItem.codigo_trib_ipi, 1, "left");
  danfeItemTXT += fillAndAlignString(
    danfeItem.numero_item?.toString() ?? null,
    2,
    "left"
  );
  danfeItemTXT += fillAndAlignString(danfeItem.quantidade, 11, "left", true, 4);
  danfeItemTXT += fillAndAlignString(danfeItem.unidade, 3, "left");
  danfeItemTXT += fillAndAlignString(danfeItem.valor_ipi, 15, "left", true);
  danfeItemTXT += fillAndAlignString(danfeItem.cif_ii, 15, "left", true);
  danfeItemTXT += fillAndAlignString(danfeItem.flag_entrada, 1, "left");
  danfeItemTXT += fillAndAlignString(null, 11, "right");
  danfeItemTXT += fillAndAlignString(danfeItem.descricao_produto, 150, "left");
  danfeItemTXT += fillAndAlignString(
    danfeItem.valor_fob_reais,
    15,
    "left",
    true
  );
  danfeItemTXT += fillAndAlignString(
    danfeItem.valor_frete_reais,
    15,
    "left",
    true
  );
  danfeItemTXT += fillAndAlignString(
    danfeItem.valor_seguro_reais,
    15,
    "left",
    true
  );
  danfeItemTXT += fillAndAlignString(
    danfeItem.valor_ii_reais,
    15,
    "left",
    true
  );
  danfeItemTXT += fillAndAlignString(danfeItem.aliquota_ipi, 5, "left", true);
  danfeItemTXT += fillAndAlignString(
    danfeItem.valor_despesas,
    15,
    "left",
    true
  );
  danfeItemTXT += fillAndAlignString(
    danfeItem.numero_processo_easy ?? null,
    17,
    "left"
  );
  danfeItemTXT += fillAndAlignString(danfeItem.numero_ex ?? null, 3, "left");
  danfeItemTXT += fillAndAlignString(
    danfeItem.numero_invoice ?? null,
    15,
    "left"
  );
  danfeItemTXT += fillAndAlignString(
    danfeItem.valor_icms_recolher,
    15,
    "left",
    true
  );
  danfeItemTXT += fillAndAlignString(
    danfeItem.peso_liquido,
    11,
    "left",
    true,
    4
  );
  danfeItemTXT += fillAndAlignString(
    danfeItem.item_pedido_compra ?? null,
    4,
    "left",
    false,
    0,
    "0"
  );
  danfeItemTXT += fillAndAlignString(danfeItem.aliquota_ii, 5, "left", true);
  danfeItemTXT += fillAndAlignString(
    danfeItem.numero_pedido ?? null,
    17,
    "left"
  );
  danfeItemTXT += fillAndAlignString(danfeItem.totvs_li ?? null, 10, "left");
  danfeItemTXT += fillAndAlignString(
    danfeItem.serie_danfe?.toString() ?? null,
    3,
    "left"
  );
  danfeItemTXT += fillAndAlignString(
    danfeItem.base_icms_por_item,
    15,
    "left",
    true
  );
  danfeItemTXT += fillAndAlignString(
    danfeItem.valor_fob_moeda,
    15,
    "left",
    true,
    7
  );
  danfeItemTXT += fillAndAlignString(danfeItem.aliquota_pis, 6, "left", true);
  danfeItemTXT += fillAndAlignString(
    danfeItem.valor_quantidade_pis,
    9,
    "left",
    true
  );
  danfeItemTXT += fillAndAlignString(
    danfeItem.base_calculo_pis,
    15,
    "left",
    true
  );
  danfeItemTXT += fillAndAlignString(
    danfeItem.valor_pis_real,
    15,
    "left",
    true
  );
  danfeItemTXT += fillAndAlignString(
    danfeItem.aliquota_cofins,
    6,
    "left",
    true
  );
  danfeItemTXT += fillAndAlignString(
    danfeItem.valor_quantidade_cofins,
    9,
    "left",
    true
  );
  danfeItemTXT += fillAndAlignString(
    danfeItem.base_calculo_cofins,
    15,
    "left",
    true
  );
  danfeItemTXT += fillAndAlignString(
    danfeItem.valor_cofins_real,
    15,
    "left",
    true
  );
  danfeItemTXT += fillAndAlignString(
    danfeItem.numero_adicao?.toString() ?? null,
    3,
    "left"
  );
  danfeItemTXT += fillAndAlignString(
    danfeItem.item?.toString() ?? null,
    3,
    "left"
  );
  danfeItemTXT += fillAndAlignString(
    danfeItem.valor_desconto_real,
    15,
    "left",
    true
  );
  danfeItemTXT += fillAndAlignString(danfeItem.valor_iof, 15, "left", true);
  danfeItemTXT += fillAndAlignString(
    danfeItem.despesas_aduaneiras,
    15,
    "left",
    true
  );
  danfeItemTXT += fillAndAlignString(
    danfeItem.aliquota_especifica_ipi ?? null,
    15,
    "left",
    true,
    4
  );
  danfeItemTXT += fillAndAlignString(
    danfeItem.quantidade_total_ipi ?? null,
    11,
    "left",
    true,
    4
  );
  danfeItemTXT += fillAndAlignString(
    danfeItem.quantidade_total_pis ?? null,
    11,
    "left",
    true,
    4
  );
  danfeItemTXT += fillAndAlignString(
    danfeItem.quantidade_total_cofins ?? null,
    11,
    "left",
    true,
    4
  );
  danfeItemTXT += fillAndAlignString(danfeItem.lote ?? null, 10, "left");
  danfeItemTXT += fillAndAlignString(
    formatDateToDDMMAAAA(danfeItem.data_validade ?? null),
    8,
    "left"
  );
  danfeItemTXT += fillAndAlignString(danfeItem.aliquota_icms, 5, "left", true);
  danfeItemTXT += fillAndAlignString(
    danfeItem.aliquota_cofins,
    6,
    "left",
    true
  );

  return danfeItemTXT;
};

export const verifyDanfeCorrelations = (
  danfeCapa: DanfeCapa[],
  correlation: CorrespondenciasListItem | undefined
) => {
  let errors: string[] = [];
  danfeCapa.forEach((despesaCapa) => {
    const cfops = correlation?.correspondenciasGerais.filter(
      (item) => item.tipo == "CFOP"
    );
    if (cfops) {
      const cfop = cfops.find(
        (d) => d.nome.split("-")[0].trim() == despesaCapa.cfop
      );
      if (cfop) {
        despesaCapa.cfop = cfop.totvsId;
      } else {
        errors.push(
          `Correspondência de CFOP ${despesaCapa.cfop} não encontrada.`
        );
      }
    } else {
      errors.push(
        `Correspondência de CFOP ${despesaCapa.cfop} não encontrada.`
      );
    }
    despesaCapa.codigo_importador = correlation?.totvsId || "";
    if (despesaCapa.codigo_transportador.trim()) {
      const transportadoras = correlation?.correspondenciasGerais.filter(
        (item) => item.tipo == "Transportadora"
      );
      if (transportadoras) {
        const transportadora = transportadoras.find(
          (d) => d.quickcomexId == despesaCapa.codigo_transportador
        );
        if (transportadora) {
          despesaCapa.cfop = transportadora.totvsId;
        } else {
          errors.push(
            `Correspondência de Transportadora ${despesaCapa.codigo_transportador} não encontrada.`
          );
        }
      } else {
        errors.push(
          `Correspondência de Transportadora ${despesaCapa.codigo_transportador} não encontrada.`
        );
      }
    }
  });
  return errors;
};
