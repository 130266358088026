import { createFilterOptions } from '@material-ui/lab/useAutocomplete';
import _ from 'lodash';
import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
} from 'react';
import { getOptionByValueInList } from '../../utils/general/general-utils';
import QCXAutocompleteSelect from '../autocomplete-select/QCXAutocompleteSelect';
import i18n from '../../i18n';

const list = [
  {
    label: i18n.t('com.muralis.qcx.outros').toUpperCase(),
    value: 'OUTROS',
  },
  {
    label: i18n.t('com.muralis.qcx.exTarifario').toUpperCase(),
    value: 'EX_TARIFARIO',
  },
  {
    label: i18n.t('com.muralis.qcx.linhaProducao').toUpperCase(),
    value: 'LINHA_PRODUCAO',
  },
  {
    label: i18n.t('com.muralis.qcx.maquinasReconstrucao').toUpperCase(),
    value: 'MAQUINAS_RECONSTRUCAO',
  },
  {
    label: i18n.t('com.muralis.qcx.moldesFerramentas').toUpperCase(),
    value: 'MOLDES_FERRAMENTAS',
  },
  {
    label: i18n.t('com.muralis.qcx.veiculosMais30Anos').toUpperCase(),
    value: 'VEICULOS_MAIS_30_ANOS',
  },
  {
    label: i18n.t('com.muralis.qcx.doacao').toUpperCase(),
    value: 'DOACAO',
  },
  {
    label: i18n.t('com.muralis.qcx.conteiner').toUpperCase(),
    value: 'CONTEINER',
  },
  {
    label: i18n.t('com.muralis.qcx.retornoMercadoria').toUpperCase(),
    value: 'RETORNO_MERCADORIA',
  },
  {
    label: i18n.t('com.muralis.qcx.partesPecasRecondicionadas').toUpperCase(),
    value: 'PARTES_PECAS_RECONDICIONADAS',
  },
  {
    label: i18n.t('com.muralis.qcx.maquinasEquipamentos').toUpperCase(),
    value: 'MAQUINAS_EQUIPAMENTOS',
  },
];

export default function QCXSelectTipoOperacaoAutocomplete({
  label,
  name,
  initialValues,
  ...restProps
}) {
  const [defaultValue, setDefaultValue] = useState('');

  useEffect(() => {
    const value = list.find((item) => item?.value === _.get(initialValues, name));
    if (value) {
      setDefaultValue(value);
    } else {
      setDefaultValue('');
    }
  }, [list, initialValues]);

  const filterOptions = useMemo(() => (
    createFilterOptions({
      stringify: (option) => (
        option.label + option.value
      ),
    })
  ), []);

  const getOptionLabel = useCallback((item) => (
    item?.label || ''
  ), []);

  const getOptionValue = useCallback((item) => (
    item?.value || ''
  ), []);

  return (
    <QCXAutocompleteSelect
      label={label}
      id={name}
      name={name}
      options={list}
      filterOptions={filterOptions}
      defaultValue={defaultValue}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      {...restProps}
    />
  );
}

function getTipoOperacaoLabelByValue(value) {
  const foundOption = getOptionByValueInList(list, value);
  return foundOption && foundOption?.label;
}

export {
  getTipoOperacaoLabelByValue,
};
