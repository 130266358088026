import React from 'react';
import QCXFinalField from '../final-field/QCXFinalField';
import QCXCheckboxLabel from '../checkbox-label/QCXCheckboxLabel';

export default function QCXFinalCheckboxField({ name, label, required, ...restProps }) {
  const render = ({
    input: { name: inputName, value: inputValue, checked, onChange, ...restInputProps },
    ...restCheckboxFieldProps
  }) => (
    <QCXCheckboxLabel
      label={label}
      name={inputName}
      type="checkbox"
      value={inputValue}
      checked={checked}
      onChange={onChange}
      inputProps={{
        required,
        ...restInputProps,
      }}
      {...restCheckboxFieldProps}
    />
  );

  return <QCXFinalField name={name} type="checkbox" required={required} render={render} {...restProps} />;
}
