import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Chip, Grid, IconButton } from '@material-ui/core';
import {
  Search as PageviewIcon,
  Delete as DeleteIcon,
  Print as PrintIcon,
  Code as CodeIcon,
  DoneAll as DoneAllIcon,
  FileCopy as FileCopyIcon,
  Receipt as ReceiptIcon,
  AssignmentTurnedIn as AssignmentTurnedInIcon,
  MoreHoriz as MoreHorizIcon,
  ConfirmationNumber as ConfirmationNumberIcon,
  Event as EventIcon,
  AcUnit as AcUnitIcon,
} from '@material-ui/icons';
import { FaCalculator as FaCalculatorIcon } from 'react-icons/fa';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import _, { isEmpty, isFunction } from 'lodash';
import { bindTrigger, bindMenu } from 'material-ui-popup-state';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import { Circle as CircleIcon } from '@mui/icons-material';
import ReplayIcon from '@mui/icons-material/Replay';
import saveAs from 'file-saver';
import { isAlternativeLoadingStatus } from '../../../../utils/store/store-utils';
import { IDLE_CONTROL } from '../../../../features/config-control';
import {
  changeLastHistoryModel,
  changeToConsultMode,
  changeToCreateMode,
  declaracaoImportacaoActions,
  failure,
  loading,
  resetBackgroundMode,
  resetHistoryModel,
  resetMode,
  resetModel,
  resetSubMode,
  updateOnList,
  setError,
  refresh as refreshList,
  setProcesso,
  setRelatedCalculoModel,
  setRelatedDuplicataModel,
  setResponse,
  success,
  setRelatedRegistroModel,
  resetRelatedRegistroModel,
  setRelatedReportModel,
  resetRelatedReportModel,
  setRelatedEmissaoIcmsModel,
  resetRelatedEmissaoIcmsModel,
} from '../../../../features/declaracao-importacao/declaracaoImportacaoSlice';
import {
  activateByIdFromFaturaAsync,
  duplicarByIdFromFaturaAsync,
  fetchAllFromFaturaAsync,
  inactivateByIdFromFaturaAsync,
  conferirByIdFromFaturaAsync,
  liberarByIdFromFaturaAsync,
  retransmitirByIdFromFaturaAsync,
  reabrirByIdAsync,
} from '../../../../features/declaracao-importacao/declaracaoImportacaoThunks';
import {
  selectDeclaracoesImportacao,
  selectHistoryModel,
  selectRelatedCalculoModel,
  selectRelatedDuplicataModel,
  selectRelatedEmissaoIcmsModel,
  selectRelatedRegistroModel,
  selectRelatedReportModel,
} from '../../../../features/declaracao-importacao/declaracaoImportacaoSelectors';
import { TipoProdutoUtils } from '../../../../shared-components/select-tipo-produto/tipoProdutoUtils';
import { changeControlTo as changeControlPaisTo } from '../../../../features/pais/paisSlice';
import { changeControlTo as changeControlNaladiShTo } from '../../../../features/naladi-sh/naladiShSlice';
import { changeControlTo as changeControlUnidadeMedidaTo } from '../../../../features/unidade-medida/unidadeDeMedidaSlice';
import { changeControlTo as changeControlNcmTo } from '../../../../features/ncm-subitem/ncmSubitemSlice';
import QCXSimpleConsultPageTemplate from '../../../../templates/simple-consult-page/QCXSimpleConsultPageTemplate';
import DeclaracaoImportacaoContext from '../../../../contexts/import/declaracao-importacao/DeclaracaoImportacaoContext';
import { formatDate, getCurrentDate, normalizeData } from '../../../../utils/general/general-utils';
import QCXListItemIcon from '../../../../shared-components/list-item-icon/QCXListItemIcon';
import QCXFinalFormDialog from '../../../../shared-components/dialog/QCXFinalFormDialog';
import {
  CLOSED_STATUS,
  OPEN_STATUS,
  SUBMITTING_STATUS,
  DONE_STATUS,
  FAIL_STATUS,
  isOpenStatus,
  AWAITING_STATUS,
  isAwaitingStatus,
} from '../../../../utils/hooks/form/dialog/formDialogUtils';
import QCXFinalTextField from '../../../../shared-components/final-text-field/QCXFinalTextField';
import { required } from '../../../../utils/validators/field/validator';
import QCXConfirmDialog from '../../../../shared-components/dialog/QCXConfirmDialog';
import useOperationMiddleware from '../../../../utils/hooks/operation/middleware/useOperationMiddleware';
import { userHasCreateRole, userHasDeleteRole, userHasUpdateRole } from '../../../../components/perfil/perfil-utils';
import QCXMoveFocusInside from '../../../../components/focus-lock/QCXMoveFocusInside';
import QCXPopupState from '../../../../components/popup-state/QCXPopupState';
import {
  isCalculadaStatus,
  isConferidaStatus,
  isLiberadaStatus,
  isNaoCalculadaStatus,
  isNaoConferidaStatus,
} from '../../../../utils/general/operations/operationUtils';
import useFormDialogSync from '../../../../utils/hooks/form/dialog/useFormDialogSync';
import QCXFinalDatePickerField from '../../../../shared-components/final-date-picker-field/QCXFinalDatePickerField';
import { faturaAPI } from '../../../../features/fatura/faturaAPI';
import { TIPO_DI_DUIMP } from '../../../../utils/general/fatura/faturaUtils';
import { setQuery } from '../../../../features/controller/controllerSlice';
import { DECLARACAO_IMPORTACAO_ENDPOINT } from '../../../../utils/api/domain/domain-endpoint-utils';
import { followUpAPI } from '../../../../features/follow-up/followUpAPI';
import QCXSelectFollowUpImportacaoAutocompleteField from '../../../../shared-components/select-follow-up/QCXSelectFollowUpImportacaoAutocompleteField';
import { declaracaoImportacaoAPI } from '../../../../features/declaracao-importacao/declaracaoImportacaoAPI';
import QCXFinalRadioField from '../../../../shared-components/final-radio-field/QCXFinalRadioField';
import useGenericDownloader from '../../../../utils/hooks/downloader/useGenericDownloader';
import QCXGenericDownloader from '../../../../components/downloader/QCXGenericDownloader';
import { setSuccessFeedback, setErrorFeedback } from '../../../../features/feedback/feedbackSlice';
import { ReportUtils } from '../../../../utils/general/report/reportUtils';
import { followUpActions } from '../../../../features/follow-up/followUpSlice';
import useFormDialogAsync from '../../../../utils/hooks/form/dialog/useFormDialogAsync';
import CanalLiberacaoUtils from '../../../../utils/general/declaracao-Importacao/CanalLiberacaoUtils';
import { clienteActions } from '../../../../features/cliente/clienteSlice';
import { useGenerateDIXMLReport } from '../../../../ts/common/hooks/useGenerateDIXMLReport.ts';
import { useHasPermission } from '../../../../ts/common/hooks/useHasPermission/useHasPermission.ts';
import { GERARXML } from '../../../../ts/common/hooks/useHasPermission/permissionsEnum.ts';
import useConfigurePopUp from '../../../../ts/common/hooks/popUps/useConfigurePopUp';

export default function DeclaracaoImportacaoConsultPage({ authInfo = {} }) {
  const { t } = useTranslation();
  const history = useHistory();
  const [idDi, setIdDi] = useState(null);
  const [tipoDi, setTipoDi] = useState(null);
  const [hasDownloaded, setHasDownloaded] = useState(false);
  const { data: reportData } = useGenerateDIXMLReport(idDi, tipoDi);

  const memoizedReportData = useMemo(() => reportData, [reportData]);

  // Permissions
  const canGenerateDIDiag = useHasPermission(GERARXML.DIAGNOSTICODI);
  const canGerenateDIReg = useHasPermission(GERARXML.REGISTRODI);

  useEffect(() => {
    if (memoizedReportData && !hasDownloaded) {
      saveAs(memoizedReportData, `${idDi ?? 'DI'} - ${tipoDi}.xml`);
      setHasDownloaded(true);
    }
  }, [memoizedReportData]);

  const genericDownloader = useGenericDownloader({
    autoDownload: true,
  });

  const { status } = useContext(DeclaracaoImportacaoContext);

  const dispatch = useDispatch();

  const declaracoesImportacao = useSelector(selectDeclaracoesImportacao);
  const reportRelatedModel = useSelector(selectRelatedReportModel);
  const calculoRelatedModel = useSelector(selectRelatedCalculoModel);
  const registroRelatedModel = useSelector(selectRelatedRegistroModel);
  const duplicataRelatedModel = useSelector(selectRelatedDuplicataModel);
  const emissaoIcmsRelatedModel = useSelector(selectRelatedEmissaoIcmsModel);
  const historyModel = useSelector(selectHistoryModel);

  const isAlternativeLoading = useMemo(() => isAlternativeLoadingStatus(status), [status]);

  const defaultDateValue = useMemo(() => getCurrentDate(), []);

  const fetchAll = useCallback(() => {
    dispatch(fetchAllFromFaturaAsync());
  }, []);

  const resetModes = useCallback(() => {
    dispatch(resetMode());
    dispatch(resetBackgroundMode());
    dispatch(resetSubMode());
  }, []);

  const resetModels = useCallback(() => {
    dispatch(resetModel());
    dispatch(resetHistoryModel());
  }, []);

  const resetDependencies = useCallback(() => {
    dispatch(changeControlPaisTo(IDLE_CONTROL));
    dispatch(changeControlNcmTo(IDLE_CONTROL));
    dispatch(changeControlNaladiShTo(IDLE_CONTROL));
    dispatch(changeControlUnidadeMedidaTo(IDLE_CONTROL));
  }, []);

  const configureDependencies = useCallback(() => {
    dispatch(clienteActions.changeControlTo(IDLE_CONTROL));
  }, []);

  useEffect(() => {
    fetchAll();
    resetModes();
    resetModels();
    resetDependencies();
  }, []);

  useEffect(() => {
    const checkIfExistsQuery = () => {
      if (!isEmpty(historyModel) && historyModel?.last?.processo) {
        dispatch(setQuery(historyModel?.last?.processo));
      }
    };

    checkIfExistsQuery();
  }, [historyModel]);

  const handleConsultarClick = useCallback(
    (event, data) => {
      if (isFunction(event?.stopPropagation)) {
        event.stopPropagation();

        configureDependencies();

        dispatch(changeToConsultMode());

        history.push(t('com.muralis.qcx.url.importacaoDeclaracoesImportacaoDetalhes', { id: data?.id }));
      }
    },
    [configureDependencies, history]
  );

  const [handleImprimirClick, formDialogImpressaoStatus, handleFormDialogImpressaoStatus] = useFormDialogSync(
    async (event, data) => {
      if (isFunction(event?.stopPropagation)) {
        event.stopPropagation();

        dispatch(
          setRelatedReportModel({
            ...data,
            tipo: ReportUtils.DRAFT_DI_DUIMP,
          })
        );
      }
    },
    []
  );

  const handleGerarXMLDiagnosticoById = useCallback((event, values) => {
    setTipoDi(values.declaracaoImportacao.tipo);
    setIdDi(values.declaracaoImportacao.id);
    setHasDownloaded(false);
  }, []);

  const handleImprimirByIdSubmit = useCallback(
    (values) => {
      handleFormDialogImpressaoStatus(SUBMITTING_STATUS);

      const getTipoRelatorioLabel = (tipo) => {
        if (ReportUtils.isCalculoPorAdicao(tipo)) {
          return t('com.muralis.qcx.calculoAdicaoDIDUIMP');
        }
        if (ReportUtils.isDraftDiDuimp(tipo)) {
          return t('com.muralis.qcx.DIDUIMP.draftDeDIDUIMP');
        }
        if (ReportUtils.isItensPorOrdemDeDigitacao(tipo)) {
          return t('com.muralis.qcx.itensPorOrdemDigitacao');
        }
        return '';
      };

      const tipoRelatorioLabel = getTipoRelatorioLabel(values?.tipo);

      const fetcher = async () => {
        if (ReportUtils.isDraftDiDuimp(values?.tipo)) {
          return declaracaoImportacaoAPI.generateReportById(values?.declaracaoImportacao?.id);
        }

        if (ReportUtils.isCalculoPorAdicao(values?.tipo)) {
          return declaracaoImportacaoAPI.generateReportAdicoesById(values?.declaracaoImportacao?.id);
        }

        if (ReportUtils.isItensPorOrdemDeDigitacao(values?.tipo)) {
          return declaracaoImportacaoAPI.generateReportItensById(values?.declaracaoImportacao?.id);
        }

        return undefined;
      };

      const handlers = {
        onSuccess: () => {
          dispatch(resetRelatedReportModel());
          handleFormDialogImpressaoStatus(DONE_STATUS);
          const successMessage = t('com.muralis.qcx.mensagem.relatorioTipoGerado', {
            tipoRelatorio: tipoRelatorioLabel,
            processo: values?.declaracaoImportacao?.processo,
          });

          dispatch(
            setSuccessFeedback({
              message: successMessage,
            })
          );
        },
        onNoContent: () => {
          handleFormDialogImpressaoStatus(OPEN_STATUS);

          const infoMessage = t('com.muralis.qcx.mensagem.naoFoiPossivelGerarRelatorio', {
            tipoRelatorio: tipoRelatorioLabel,
            processo: values?.declaracaoImportacao?.processo,
          });

          dispatch(
            setErrorFeedback({
              message: infoMessage,
            })
          );
        },
        onError: (reason) => {
          handleFormDialogImpressaoStatus(FAIL_STATUS);
          const errorMessage = t('com.muralis.qcx.erro.erroGerarRelatorio', {
            tipoRelatorio: tipoRelatorioLabel,
            processo: values?.declaracaoImportacao?.processo,
            mensagem: `${reason?.response && reason?.response?.data ? `: ${reason?.response?.data?.message}.` : '.'}`,
          });

          dispatch(
            setErrorFeedback({
              message: errorMessage,
            })
          );
        },
      };

      genericDownloader.downloadFileById(fetcher, handlers);
    },
    [handleFormDialogImpressaoStatus]
  );

  const handleCloseFormDialogImpressao = useCallback(
    () => handleFormDialogImpressaoStatus(CLOSED_STATUS),
    [handleFormDialogImpressaoStatus]
  );

  const isFormDialogImpressaoOpen = useMemo(() => isOpenStatus(formDialogImpressaoStatus), [formDialogImpressaoStatus]);

  const formDialogImpressaoContent = useMemo(
    () =>
      t('com.muralis.qcx.selecioneAbaixoTipoRelatorioGerarProcesso', {
        processo: reportRelatedModel?.declaracaoImportacao?.processo || '#######',
      }),
    [isFormDialogImpressaoOpen, reportRelatedModel]
  );

  const formDialogImpressaoSubmittingFeedback = useMemo(
    () =>
      t('com.muralis.qcx.gerandoRelatorioDIDUIMP', {
        processo: reportRelatedModel?.declaracaoImportacao?.processo || '#######',
      }),
    [reportRelatedModel]
  );

  const [handleCalcularClick, formDialogCalculoStatus, handleFormDialogCalculoStatus] = useFormDialogSync(
    async (event, data) => {
      if (isFunction(event?.stopPropagation)) {
        event.stopPropagation();

        dispatch(
          setRelatedCalculoModel({
            id: data?.id,
            calculada: data?.calculada,
            atributosAdicionais: {
              tipo: data?.atributosAdicionais?.tipo,
              processo: data?.atributosAdicionais?.processo,
            },
            active: data?.active,
          })
        );
      }
    },
    []
  );

  const handleCalcularByDataSubmit = useCallback(
    async (values) => {
      try {
        dispatch(loading());

        handleFormDialogCalculoStatus(SUBMITTING_STATUS);

        const payload = {
          atributosAdicionais: {
            tipo: values?.atributosAdicionais?.tipo,
            dataCalculo: normalizeData(values?.atributosAdicionais?.dataCalculo),
          },
        };

        const response = await faturaAPI.calculateById(values?.id, [DECLARACAO_IMPORTACAO_ENDPOINT], payload);

        if (response.status === 200) {
          const isNaoCalculada = isNaoCalculadaStatus(values?.calculada);
          const processo = values?.atributosAdicionais?.processo;
          const responseMessage = t('com.muralis.qcx.mensagem.DIEstado', {
            processo,
            estado: isNaoCalculada ? t('com.muralis.qcx.estado.calculada') : t('com.muralis.qcx.estado.recalculada'),
          });

          const simplifiedResponse = {
            status: response?.status,
            message: responseMessage,
            data: response?.data,
          };

          dispatch(success());
          dispatch(
            updateOnList({
              data: response?.data,
            })
          );
          dispatch(setResponse(simplifiedResponse));

          if (isNaoCalculada) {
            dispatch(
              changeLastHistoryModel({
                processo: values?.atributosAdicionais?.processo,
              })
            );
          }

          dispatch(declaracaoImportacaoActions.refresh());

          handleFormDialogCalculoStatus(DONE_STATUS);
        }
      } catch (error) {
        handleFormDialogCalculoStatus(FAIL_STATUS);

        const isUnknownError =
          isEmpty(error?.response) || isEmpty(error?.response?.data) || isEmpty(error?.response?.data?.message);

        const isNaoCalculada = isNaoCalculadaStatus(values?.calculada);
        const errorMessage = isUnknownError
          ? t('com.muralis.qcx.erro.erroCalcularRecalcular', {
              estado: isNaoCalculada ? t('com.muralis.qcx.acoes.calcular') : t('com.muralis.qcx.acoes.recalcular'),
            })
          : t('com.muralis.qcx.erro.erroCalcularRecalcularEspecifico', {
              estado: isNaoCalculada ? t('com.muralis.qcx.acoes.calcular') : t('com.muralis.qcx.acoes.recalcular'),
              mensagem: error?.response?.data?.message,
            });

        dispatch(failure());
        dispatch(
          setError({
            message: errorMessage,
          })
        );
        dispatch(setResponse(error.response));
      }
    },
    [declaracoesImportacao, handleFormDialogCalculoStatus]
  );

  const filterAvailableFollowUp = useCallback(async () => {
    const availableFollowUpsResponse = await followUpAPI
      .fetchByFilter([
        {
          name: 'servicoProduto',
          value: TipoProdutoUtils.IMPORTACAO,
        },
        {
          name: 'filtrarVinculados',
          value: true,
        },
      ])
      .catch((reason) => {
        const errorMessage = t('com.muralis.qcx.erro.erroConsultarFollowUp', {
          dado: reason?.response && reason?.response?.data ? `: ${reason?.response?.data?.message}.` : '.',
        });
        dispatch(failure());
        dispatch(
          setErrorFeedback({
            message: errorMessage,
          })
        );
      });

    if (availableFollowUpsResponse?.status === 200) {
      const availableFollowUps = availableFollowUpsResponse?.data;

      if (_.isArrayLikeObject(availableFollowUps) && _.isEmpty(availableFollowUps)) {
        dispatch(failure());

        dispatch(
          setErrorFeedback({
            message: t('com.muralis.qcx.validacao.naoEncontradoFollowUp'),
          })
        );

        return;
      }

      dispatch(followUpActions.resetControl());
      dispatch(
        followUpActions.setList({
          data: availableFollowUps,
        })
      );
    }
  }, []);

  const handleCloseFormDialogCalculo = useCallback(
    () => handleFormDialogCalculoStatus(CLOSED_STATUS),
    [handleFormDialogCalculoStatus]
  );

  const isFormDialogCalculoOpen = useMemo(() => isOpenStatus(formDialogCalculoStatus));

  const formDialogCalculoContent = useMemo(
    () =>
      t('com.muralis.qcx.mensagem.inserirDataCalcularRecalcularDIDUIMP', {
        acao: isNaoCalculadaStatus(calculoRelatedModel?.calculada)
          ? t('com.muralis.qcx.acoes.calcular').toLowerCase()
          : t('com.muralis.qcx.acoes.recalcular').toLowerCase(),
        processo: calculoRelatedModel?.atributosAdicionais?.processo,
      }),
    [calculoRelatedModel]
  );

  const [handleConferirByIdClickMiddleware, operationOfConferirById] = useOperationMiddleware(async (data) => {
    dispatch(
      conferirByIdFromFaturaAsync({
        id: data?.id,
      })
    );
  }, []);

  const handleConfigureConferirByIdClick = useCallback(
    (row, popupState) => {
      const { id, atributosAdicionais, metaFields } = row;

      const handleConferirByIdClick = (event) => {
        if (isFunction(event?.stopPropagation)) {
          popupState.close(event);

          event.stopPropagation();

          const configureOperation = () => ({
            options: {
              title: t('com.muralis.qcx.acoes.confirmarOperacaoConferencia').toUpperCase(),
              message: t('com.muralis.qcx.confirmarOperacaoDIDUIMPConferida', {
                processo: atributosAdicionais?.processo,
              }),
              endMessage: t('com.muralis.qcx.certezaConferila'),
            },
          });

          const dataOfOperationConferir = {
            id,
            metaFields,
          };

          handleConferirByIdClickMiddleware(configureOperation, dataOfOperationConferir);
        }
      };

      return handleConferirByIdClick;
    },
    [handleConferirByIdClickMiddleware]
  );

  const [handleDuplicarClick, formDialogDuplicacaoStatus, handleFormDialogDuplicacaoStatus] = useFormDialogSync(
    async (event, data) => {
      if (isFunction(event?.stopPropagation)) {
        event.stopPropagation();
        await filterAvailableFollowUp();
        dispatch(
          setRelatedDuplicataModel({
            id: data?.id,
            atributosAdicionais: {
              tipo: TIPO_DI_DUIMP,
            },
            meta: {
              from: {
                processo: data?.processo,
              },
            },
          })
        );
      }
    },
    [filterAvailableFollowUp]
  );

  const [handleLiberarByIdClickMiddleware, operationOfLiberarById] = useOperationMiddleware(async (data) => {
    dispatch(
      liberarByIdFromFaturaAsync({
        id: data?.id,
      })
    );
  }, []);

  const [handleRetransmitirByIdClickMiddleware, operationOfRetransmitirById] = useOperationMiddleware(async (data) => {
    dispatch(
      retransmitirByIdFromFaturaAsync({
        id: data?.id,
      })
    );
  }, []);

  const handleConfigureLiberarByIdClick = useCallback(
    (row, popupState) => {
      const { id, atributosAdicionais, metaFields } = row;

      const handleLiberarByIdClick = (event) => {
        if (isFunction(event?.stopPropagation)) {
          popupState.close(event);

          event.stopPropagation();

          const configureOperation = () => ({
            options: {
              title: t('com.muralis.qcx.confirmarLiberacao').toUpperCase(),
              message: t('com.muralis.qcx.certezaLiberarRegistroDI', { processo: atributosAdicionais?.processo }),
            },
          });

          const dataOfOperationLiberar = {
            id,
            metaFields,
          };

          handleLiberarByIdClickMiddleware(configureOperation, dataOfOperationLiberar);
        }
      };

      return handleLiberarByIdClick;
    },
    [handleLiberarByIdClickMiddleware]
  );

  const handleConfigureRetransmitirByIdClick = useCallback(
    (row, popupState) => {
      const { id } = row;

      const handleRetransmitirByIdClick = (event) => {
        if (isFunction(event?.stopPropagation)) {
          popupState.close(event);

          event.stopPropagation();

          const configureOperation = () => ({
            options: {
              title: 'Confirmar operação de Retransmissão'.toUpperCase(),
              message: 'Deseja realmente retransmitir o registro DI?',
            },
          });

          const dataOfOperationRetransmitir = {
            id,
          };

          handleRetransmitirByIdClickMiddleware(configureOperation, dataOfOperationRetransmitir);
        }
      };

      return handleRetransmitirByIdClick;
    },
    [handleLiberarByIdClickMiddleware]
  );

  const handleDuplicarByProcessoSubmit = useCallback(
    (values) => {
      dispatch(
        duplicarByIdFromFaturaAsync({
          id: values?.id,
          meta: {
            from: {
              processo: values?.meta?.from?.processo,
            },
          },
          payload: {
            followUp: {
              id: values?.followUp?.id,
            },
            atributosAdicionais: {
              tipo: values?.atributosAdicionais?.tipo,
            },
          },
        })
      );

      handleFormDialogDuplicacaoStatus(DONE_STATUS);
    },
    [handleFormDialogDuplicacaoStatus]
  );

  const handleCloseFormDialogDuplicacao = useCallback(
    () => handleFormDialogDuplicacaoStatus(CLOSED_STATUS),
    [handleFormDialogDuplicacaoStatus]
  );

  const isFormDialogDuplicacaoOpen = useMemo(() => isOpenStatus(formDialogDuplicacaoStatus));

  const formDialogDuplicacaoContent = useMemo(
    () =>
      t('com.muralis.qcx.mensagem.preenchaNumeroNovoProcessoDuplicarDIDUIMP', {
        processo: duplicataRelatedModel?.meta?.from?.processo,
      }),
    [duplicataRelatedModel]
  );

  const [handleActivateOrInactivateByIdClickMiddleware, operationOfActivateOrInactivateById] = useOperationMiddleware(
    async (data) => {
      const isInactivation = data?.active;

      if (isInactivation) {
        dispatch(inactivateByIdFromFaturaAsync(data?.id));

        return;
      }

      dispatch(activateByIdFromFaturaAsync(data?.id));
    },
    [declaracoesImportacao]
  );

  const handleConfigureActivateOrInactivateByIdClick = useCallback(
    (row, popupState) => {
      const { id, atributosAdicionais, active } = row;

      const handleActivateOrInactivateByIdClick = (event) => {
        if (isFunction(event?.stopPropagation)) {
          popupState.close(event);

          event.stopPropagation();

          const configureOperation = () => ({
            options: {
              title: t('com.muralis.qcx.acoes.confirmarOperacaoExclusao').toUpperCase(),
              message: t('com.muralis.qcx.certezaExcluirRecuperarDI', {
                acao: active
                  ? t('com.muralis.qcx.acoes.excluir').toLowerCase()
                  : t('com.muralis.qcx.acoes.recuperar').toLowerCase(),
                processo: atributosAdicionais?.processo,
              }),
            },
          });

          const dataOfOperationActivateOrInactivate = {
            id,
            active,
          };

          handleActivateOrInactivateByIdClickMiddleware(configureOperation, dataOfOperationActivateOrInactivate);
        }
      };

      return handleActivateOrInactivateByIdClick;
    },
    [handleActivateOrInactivateByIdClickMiddleware]
  );

  const [
    handleDadosRegistroSiscomexClick,
    formDialogDadosRegistroSiscomexStatus,
    handleFormDialogDadosRegistroSiscomexStatus,
  ] = useFormDialogSync(async (event, data) => {
    if (isFunction(event?.stopPropagation)) {
      event.stopPropagation();

      dispatch(
        setRelatedRegistroModel({
          id: data?.id,
          processo: data?.processo,
          registro: data?.registro,
          dataRegistroSiscomex: data?.dataRegistroSiscomex,
          confirmacaoPendente: !data?.registro || !data?.dataRegistroSiscomex,
        })
      );
    }
  }, []);

  const [handleInformarDadosRegistroSiscomexClickMiddleware, operationOfInformarDadosRegistroSiscomex] =
    useOperationMiddleware(
      async (values) => {
        try {
          dispatch(loading());
          handleFormDialogDadosRegistroSiscomexStatus(SUBMITTING_STATUS);

          const updated = {
            id: values?.id,
            atributosAdicionais: {
              tipo: TIPO_DI_DUIMP,
              registro: values?.registro,
              dataRegistroSiscomex: normalizeData(values?.dataRegistroSiscomex),
            },
          };

          const response = await declaracaoImportacaoAPI.save(updated);

          if (response?.status === 200) {
            dispatch(
              setSuccessFeedback({
                message: t('com.muralis.qcx.mensagem.numeroRegistroDIDUIMPSalvo', { processo: values?.processo }),
              })
            );

            handleFormDialogDadosRegistroSiscomexStatus(DONE_STATUS);
            dispatch(resetRelatedRegistroModel());
            dispatch(refreshList());

            dispatch(success());
            dispatch(
              setResponse({
                status: response.status,
                data: updated,
                message: t('com.muralis.qcx.mensagem.numeroRegistroDIDUIMPSalvo', { processo: values?.processo }),
              })
            );
          }
        } catch (error) {
          handleFormDialogDadosRegistroSiscomexStatus(FAIL_STATUS);

          let errorMessage = t('com.muralis.qcx.erro.erroSalvarDIDUIMP');
          if (error?.response && error?.response?.data) {
            errorMessage = t('com.muralis.qcx.erro.erroSalvarerroSalvarDIDUIMPEspecificoDIDUIMP', {
              erro: error?.response?.data?.message,
            });
          }

          dispatch(
            setErrorFeedback({
              message: errorMessage,
            })
          );
        }
      },
      [handleFormDialogDadosRegistroSiscomexStatus]
    );

  const handleConfigureInformarDadosRegistroSiscomexByIdClick = useCallback(
    (values) => {
      const formattedDataRegistroSiscomex = formatDate(values?.dataRegistroSiscomex);

      const configureOperation = () => ({
        options: {
          title: t('com.muralis.qcx.autorizarOperacaoDadosRegistro').toUpperCase(),
          message: t('com.muralis.qcx.confirmarNumeroRegistroDataRegistroNaoPoderaoAlterar', {
            registro: values?.registro,
            data: formattedDataRegistroSiscomex,
          }),
          endMessage: t('com.muralis.qcx.certezaConfirmarDados'),
        },
      });

      handleInformarDadosRegistroSiscomexClickMiddleware(configureOperation, values);
    },
    [handleInformarDadosRegistroSiscomexClickMiddleware]
  );

  const handleCloseDadosRegistroSiscomexOperation = useCallback(() => {
    const callback = () => {
      if (isAwaitingStatus(formDialogDadosRegistroSiscomexStatus)) {
        handleFormDialogDadosRegistroSiscomexStatus(OPEN_STATUS);
      }
    };

    operationOfInformarDadosRegistroSiscomex?.reset(callback);
  }, [
    operationOfInformarDadosRegistroSiscomex,
    formDialogDadosRegistroSiscomexStatus,
    handleFormDialogDadosRegistroSiscomexStatus,
  ]);

  const handleDadosRegistroSiscomexSubmit = useCallback(
    async (values) => {
      handleFormDialogDadosRegistroSiscomexStatus(AWAITING_STATUS);

      dispatch(
        setRelatedRegistroModel({
          ...values,
          confirmacaoPendente: true,
        })
      );
      handleConfigureInformarDadosRegistroSiscomexByIdClick(values);
    },
    [handleFormDialogDadosRegistroSiscomexStatus, handleConfigureInformarDadosRegistroSiscomexByIdClick]
  );

  const handleCloseFormDialogDadosRegistroSiscomex = useCallback(() => {
    handleFormDialogDadosRegistroSiscomexStatus(CLOSED_STATUS);
    _.debounce(() => {
      dispatch(resetRelatedRegistroModel());
    }, 300);
  }, [handleFormDialogDadosRegistroSiscomexStatus]);

  const isFormDialogDadosRegistroSiscomexOpen = useMemo(() => isOpenStatus(formDialogDadosRegistroSiscomexStatus));

  // eslint-disable-next-line no-unused-vars
  const formDialogAlteracaoNumeroRegistroSiscomexContent = useMemo(
    () => t('com.muralis.qcx.numeroRegistroDIDUIMPSiscomex'),
    []
  );
  const formDialogDadosRegistroSiscomexContent = useMemo(
    () =>
      registroRelatedModel?.confirmacaoPendente ||
      !registroRelatedModel?.registro ||
      !registroRelatedModel?.dataRegistroSiscomex
        ? t('com.muralis.qcx.mensagem.dadosReferenteDIDUIMPSiscomex')
        : t('com.muralis.qcx.mensagem.dadosRegistroReferenteDIDUIMPSiscomexInformados'),
    [registroRelatedModel]
  );

  const [handleEmissaoIcmsClick, formDialogEmissaoIcmsStatus, handleFormDialogEmissaoIcmsStatus] = useFormDialogSync(
    async (event, data) => {
      if (isFunction(event?.stopPropagation)) {
        event.stopPropagation();

        dispatch(
          setRelatedEmissaoIcmsModel({
            id: data?.id,
            processo: data?.processo,
            registro: data?.registro,
            dataVencimentoIcms: data?.dataVencimentoIcms,
            numeroConvenioProtocoloEspecificacaoMercadoria: data?.numeroConvenioProtocoloEspecificacaoMercadoria,
            confirmacaoPendente: !data?.dataVencimentoIcms,
          })
        );
      }
    },
    []
  );

  const [handleEmissaoIcmsByIdClickMiddleware, operationOfEmissaoIcms] = useOperationMiddleware(
    async (data) => {
      try {
        dispatch(loading());
        handleFormDialogEmissaoIcmsStatus(SUBMITTING_STATUS);

        const updated = {
          id: data?.id,
          atributosAdicionais: {
            tipo: TIPO_DI_DUIMP,
            dataVencimentoIcms: normalizeData(data?.dataVencimentoIcms),
            numeroConvenioProtocoloEspecificacaoMercadoria: data?.numeroConvenioProtocoloEspecificacaoMercadoria,
          },
        };

        const response = await declaracaoImportacaoAPI.save(updated);

        if (response?.status === 200) {
          handleFormDialogEmissaoIcmsStatus(DONE_STATUS);
          dispatch(resetRelatedEmissaoIcmsModel());
          dispatch(refreshList());

          const savedMessage = data?.numeroConvenioProtocoloEspecificacaoMercadoria
            ? t('com.muralis.qcx.mensagem.dataVencmentoEmissaoICMSDIDUIMPSalvos', { registro: data?.registro })
            : t('com.muralis.qcx.mensagem.dataVencimentoEmissaoICMSDIDUIMPSalva', { registro: data?.registro });

          dispatch(success());
          dispatch(
            setSuccessFeedback({
              message: savedMessage,
            })
          );
        }
      } catch (error) {
        handleFormDialogEmissaoIcmsStatus(FAIL_STATUS);
        let errorMessage = t('com.muralis.qcx.naoPossivelSalvarDadosEmissaoICMSDIDUIMP', { registro: data?.registro });
        if (error?.response && error?.response?.data) {
          errorMessage = t('com.muralis.qcx.naoPossivelSalvarDadosEmissaoICMSDIDUIMPMensagem', {
            registro: data?.registro,
            erro: error?.response?.data?.message,
          });
        }

        dispatch(
          setErrorFeedback({
            message: errorMessage,
          })
        );
      }
    },
    [formDialogEmissaoIcmsStatus, handleFormDialogEmissaoIcmsStatus]
  );

  const handleConfigureEmissaoIcmsByIdClick = useCallback(
    (values) => {
      const formattedDate = formatDate(values?.dataVencimentoIcms);

      const hasNumeroConvenioProtocoloEspecificacaoMercadoria =
        !!values?.numeroConvenioProtocoloEspecificacaoMercadoria;

      const configureOperation = () => ({
        options: {
          title: t('com.muralis.qcx.DIDUIMP.autorizarOperacaoDIDUIMP', { numero: values?.registro }),
          message: hasNumeroConvenioProtocoloEspecificacaoMercadoria
            ? t('com.muralis.qcx.mensagem.confirmarDataVencimentoEspecificacaoMercadoria', {
                data: formattedDate,
                mercadoria: values?.numeroConvenioProtocoloEspecificacaoMercadoria,
              })
            : t('com.muralis.qcx.mensagem.confirmarDataVencimento', { data: formattedDate }),
          endMessage: hasNumeroConvenioProtocoloEspecificacaoMercadoria
            ? t('com.muralis.qcx.DIDUIMP.certezaUtilizalosEmissaoICMS')
            : t('com.muralis.qcx.DIDUIMP.certezaUtilizalaEmissaoICMS'),
        },
      });

      handleEmissaoIcmsByIdClickMiddleware(configureOperation, values);
    },
    [formDialogEmissaoIcmsStatus, handleFormDialogEmissaoIcmsStatus, handleEmissaoIcmsByIdClickMiddleware]
  );

  const handleCloseEmissaoIcmsOperation = useCallback(() => {
    const callback = () => {
      if (isAwaitingStatus(formDialogEmissaoIcmsStatus)) {
        handleFormDialogEmissaoIcmsStatus(OPEN_STATUS);
      }
    };

    operationOfEmissaoIcms?.reset(callback);
  }, [operationOfEmissaoIcms, formDialogEmissaoIcmsStatus, handleFormDialogEmissaoIcmsStatus]);

  const handleEmissaoIcmsSubmit = useCallback(
    async (values) => {
      handleFormDialogEmissaoIcmsStatus(AWAITING_STATUS);

      dispatch(
        setRelatedEmissaoIcmsModel({
          ...values,
          confirmacaoPendente: true,
        })
      );
      handleConfigureEmissaoIcmsByIdClick(values);
    },
    [handleFormDialogEmissaoIcmsStatus, handleConfigureEmissaoIcmsByIdClick]
  );

  const handleCloseFormDialogEmissaoIcms = useCallback(() => {
    handleFormDialogEmissaoIcmsStatus(CLOSED_STATUS);
    _.debounce(() => {
      dispatch(resetRelatedEmissaoIcmsModel());
    }, 300);
  }, [handleFormDialogEmissaoIcmsStatus]);

  const isFormDialogEmissaoIcmsOpen = useMemo(() => isOpenStatus(formDialogEmissaoIcmsStatus));

  const formDialogEmissaoIcmsContent = useMemo(
    () =>
      emissaoIcmsRelatedModel?.confirmacaoPendente || !emissaoIcmsRelatedModel?.dataVencimentoIcms
        ? t('com.muralis.qcx.mensagem.dataRelacionadaVencimentoPagamentoICMS')
        : t('com.muralis.qcx.mensagem.dataVencimentoPagamentoICMSSEFAZ'),
    [emissaoIcmsRelatedModel]
  );

  const [handleButtonNewClick, formDialogNewStatus, handleFormDialogNewStatus] = useFormDialogAsync(
    async (event) => {
      event.stopPropagation();

      configureDependencies();

      dispatch(changeToCreateMode());

      history.push(t('com.muralis.qcx.url.importacaoDeclaracoesNovo'));
    },
    [configureDependencies, history]
  );

  const handleButtonNewClickMiddleware = useCallback(
    async (event) => {
      await filterAvailableFollowUp();
      handleButtonNewClick(event);
    },
    [filterAvailableFollowUp, handleButtonNewClick]
  );

  const handleProcessoNewSubmit = useCallback(
    async (values) => {
      try {
        dispatch(loading());

        handleFormDialogNewStatus(SUBMITTING_STATUS);

        const response = await followUpAPI.fetchById(values?.followUp?.id);

        if (response?.status === 200) {
          dispatch(
            setProcesso({
              processo: response?.data?.numero,
              followUp: response?.data,
            })
          );

          handleFormDialogNewStatus(DONE_STATUS);
        }
      } catch (error) {
        handleFormDialogNewStatus(FAIL_STATUS);

        let errorMessage = t('com.muralis.qcx.erro.erroCarregarProcesso');
        if (error?.response && error?.response?.data) {
          errorMessage = t('com.muralis.qcx.erro.erroCarregarProcessoEspecifico', {
            erro: error?.response?.data?.message,
          });
        }

        dispatch(failure());
        dispatch(
          setError({
            message: errorMessage,
          })
        );
      }
    },
    [handleFormDialogNewStatus]
  );

  const handleCloseFormDialogNew = useCallback(
    () => handleFormDialogNewStatus(CLOSED_STATUS),
    [handleFormDialogNewStatus]
  );

  const [handleOpenPopUpReabrirDi, reabriDiPopUpConfig] = useConfigurePopUp({
    primaryConfirmCallback: async ({ id }) => {
      dispatch(reabrirByIdAsync({ id }));
    },
    initialOptions: {
      title: 'Deseja reabrir a DI?',
    },
  });

  const handleReabrirClick = useCallback(({ id }) => {
    handleOpenPopUpReabrirDi({ args: { id } });
  });

  const isFormDialogNewOpen = useMemo(() => isOpenStatus(formDialogNewStatus), [formDialogNewStatus]);

  const columns = [
    {
      field: 'id',
      headerName: t('com.muralis.qcx.processo'),
      headerAlign: 'center',
      align: 'center',
      flex: 100,
      valueGetter: ({ row }) => row?.atributosAdicionais?.processo || '-',
    },
    {
      field: 'cliente',
      headerName: 'Cliente',
      headerAlign: 'center',
      align: 'center',
      flex: 100,
      valueGetter: ({ row }) => row?.importador?.pessoa?.nomeResumido || '-',
    },
    {
      field: 'numeroConhecimentoHouse',
      headerName: 'Conhecimento House',
      headerAlign: 'center',
      align: 'center',
      flex: 100,
      valueGetter: ({ row }) => row?.numeroConhecimentoHouse || '-',
    },
    {
      field: 'atributosAdicionais.registro',
      headerName: t('com.muralis.qcx.NDIDUIMP'),
      flex: 100,
      valueGetter: ({ row }) => row?.atributosAdicionais?.registro || '-',
    },
    {
      field: 'atributosAdicionais.dataRegistroSiscomex',
      headerName: t('com.muralis.qcx.registro'),
      flex: 100,
      valueGetter: ({ row }) => formatDate(row?.atributosAdicionais?.dataRegistroSiscomex) || '-',
    },
    {
      field: 'atributosAdicionais.retificacao.numero',
      headerName: t('com.muralis.qcx.NRetificado'),
      flex: 110,
      valueGetter: ({ row }) => formatDate(row?.atributosAdicionais?.retificacao?.numero) || '-',
    },
    {
      field: 'atributosAdicionais.canalLiberacao',
      headerName: t('com.muralis.qcx.canal'),
      headerAlign: 'center',
      align: 'center',
      flex: 100,
      renderCell: ({ row }) => {
        const canal = row?.atributosAdicionais?.canalLiberacao;
        const htmlColor = CanalLiberacaoUtils.getHexadecimalColorByValue(canal);
        const label = CanalLiberacaoUtils.getLabelByValue(canal);

        return canal ? (
          <Chip
            {...(htmlColor
              ? {
                  icon: (
                    <CircleIcon
                      style={{
                        color: htmlColor,
                      }}
                      fontSize="small"
                    />
                  ),
                }
              : {})}
            label={label}
            size="small"
          />
        ) : (
          '-'
        );
      },
    },
    {
      field: 'actions',
      headerName: t('com.muralis.qcx.acoes.label'),
      headerAlign: 'center',
      align: 'center',
      flex: 140,
      renderCell: ({ row: { id, atributosAdicionais, calculada, active, metaFields } }) => (
        <QCXPopupState popupId="popup-menu-li">
          {(popupState) => (
            <>
              <IconButton key={`btn-more-options-${id}`} name={`btn-more-options-${id}`} {...bindTrigger(popupState)}>
                <MoreHorizIcon color="secondary" size={20} />
              </IconButton>
              <Menu {...bindMenu(popupState)}>
                <MenuItem
                  onClick={(event) => {
                    popupState.close(event);

                    handleConsultarClick(event, {
                      id,
                    });
                  }}
                >
                  <QCXListItemIcon>
                    <PageviewIcon fontSize="small" />
                  </QCXListItemIcon>
                  <Typography
                    variant="inherit"
                    style={{
                      fontSize: 12,
                    }}
                  >
                    {t('com.muralis.qcx.acoes.consultar').toUpperCase()}
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={(event) => {
                    popupState.close(event);
                    handleImprimirClick(event, {
                      declaracaoImportacao: {
                        id,
                        processo: atributosAdicionais?.processo,
                      },
                    });
                  }}
                >
                  <QCXListItemIcon>
                    <PrintIcon color="primary" fontSize="small" />
                  </QCXListItemIcon>
                  <Typography
                    variant="inherit"
                    style={{
                      fontSize: 12,
                    }}
                  >
                    {t('com.muralis.qcx.acoes.imprimir').toUpperCase()}
                  </Typography>
                </MenuItem>
                <MenuItem
                  disabled={
                    !canGenerateDIDiag ||
                    !(
                      isCalculadaStatus(calculada) &&
                      isConferidaStatus(atributosAdicionais?.conferida) &&
                      isLiberadaStatus(atributosAdicionais?.liberada)
                    )
                  }
                  onClick={(event) => {
                    handleGerarXMLDiagnosticoById(event, {
                      declaracaoImportacao: {
                        id,
                        processo: atributosAdicionais?.processo,
                        tipo: 'DIAGNOSTICO',
                      },
                    });
                  }}
                >
                  <QCXListItemIcon>
                    <CodeIcon color="primary" fontSize="small" />
                  </QCXListItemIcon>
                  <Typography
                    variant="inherit"
                    style={{
                      fontSize: 12,
                    }}
                  >
                    {'Gerar XML de Diagnóstico'.toUpperCase()}
                  </Typography>
                </MenuItem>
                <MenuItem
                  disabled={
                    !canGerenateDIReg ||
                    !(
                      isCalculadaStatus(calculada) &&
                      isConferidaStatus(atributosAdicionais?.conferida) &&
                      isLiberadaStatus(atributosAdicionais?.liberada)
                    )
                  }
                  onClick={(event) => {
                    handleGerarXMLDiagnosticoById(event, {
                      declaracaoImportacao: {
                        id,
                        processo: atributosAdicionais?.processo,
                        tipo: 'REGISTRO',
                      },
                    });
                  }}
                >
                  <QCXListItemIcon>
                    <CodeIcon color="primary" fontSize="small" />
                  </QCXListItemIcon>
                  <Typography
                    variant="inherit"
                    style={{
                      fontSize: 12,
                    }}
                  >
                    {'Gerar XML de Registro'.toUpperCase()}
                  </Typography>
                </MenuItem>
                {userHasUpdateRole(authInfo, 'importacao-di-duimp') && (
                  <>
                    <MenuItem
                      onClick={(event) => {
                        popupState.close(event);
                        handleCalcularClick(event, {
                          id,
                          calculada,
                          atributosAdicionais,
                          active,
                        });
                      }}
                    >
                      <QCXListItemIcon>
                        <FaCalculatorIcon color="green" size={20} />
                      </QCXListItemIcon>
                      <Typography
                        variant="inherit"
                        style={{
                          fontSize: 12,
                        }}
                      >
                        {isNaoCalculadaStatus(calculada)
                          ? t('com.muralis.qcx.acoes.calcular').toUpperCase()
                          : t('com.muralis.qcx.acoes.recalcular').toUpperCase()}
                      </Typography>
                    </MenuItem>
                    <MenuItem
                      onClick={handleConfigureConferirByIdClick(
                        {
                          id,
                          atributosAdicionais,
                          metaFields,
                        },
                        popupState
                      )}
                      disabled={isNaoCalculadaStatus(calculada) || isConferidaStatus(atributosAdicionais?.conferida)}
                    >
                      <QCXListItemIcon>
                        <DoneAllIcon
                          fontSize="small"
                          htmlColor={
                            isNaoCalculadaStatus(calculada) || isConferidaStatus(atributosAdicionais?.conferida)
                              ? 'rgba(0, 0, 0, 0.38)'
                              : 'green'
                          }
                        />
                      </QCXListItemIcon>
                      <Typography
                        variant="inherit"
                        style={{
                          fontSize: 12,
                        }}
                      >
                        {t('com.muralis.qcx.acoes.conferir').toUpperCase()}
                      </Typography>
                    </MenuItem>
                    <MenuItem
                      onClick={handleConfigureLiberarByIdClick(
                        {
                          id,
                          atributosAdicionais,
                          metaFields,
                        },
                        popupState
                      )}
                      disabled={
                        isNaoCalculadaStatus(calculada) ||
                        isNaoConferidaStatus(atributosAdicionais?.conferida) ||
                        isLiberadaStatus(atributosAdicionais?.liberada)
                      }
                    >
                      <QCXListItemIcon>
                        <AssignmentTurnedInIcon
                          fontSize="small"
                          htmlColor={
                            isNaoCalculadaStatus(calculada) ||
                            isNaoConferidaStatus(atributosAdicionais?.conferida) ||
                            isLiberadaStatus(atributosAdicionais?.liberada)
                              ? 'rgba(0, 0, 0, 0.38)'
                              : 'orange'
                          }
                        />
                      </QCXListItemIcon>
                      <Typography
                        variant="inherit"
                        style={{
                          fontSize: 12,
                        }}
                      >
                        {t('com.muralis.qcx.acoes.liberarRegistro').toUpperCase()}
                      </Typography>
                    </MenuItem>
                    <MenuItem
                      onClick={handleConfigureRetransmitirByIdClick(
                        {
                          id,
                        },
                        popupState
                      )}
                      disabled={
                        atributosAdicionais?.dataRegistroSiscomex ||
                        atributosAdicionais?.registro ||
                        !isLiberadaStatus(atributosAdicionais?.liberada)
                      }
                    >
                      <QCXListItemIcon>
                        <ReplayIcon
                          fontSize="small"
                          htmlColor={
                            atributosAdicionais?.dataRegistroSiscomex ||
                            atributosAdicionais?.registro ||
                            !isLiberadaStatus(atributosAdicionais?.liberada)
                              ? 'rgba(0, 0, 0, 0.38)'
                              : 'green'
                          }
                        />
                      </QCXListItemIcon>
                      <Typography
                        variant="inherit"
                        style={{
                          fontSize: 12,
                        }}
                      >
                        RETRANSMITIR
                      </Typography>
                    </MenuItem>
                  </>
                )}
                {isCalculadaStatus(calculada) &&
                  isConferidaStatus(atributosAdicionais?.conferida) &&
                  isLiberadaStatus(atributosAdicionais?.liberada) && (
                    <MenuItem
                      onClick={(event) => {
                        popupState.close(event);
                        handleDadosRegistroSiscomexClick(event, {
                          id,
                          processo: atributosAdicionais?.processo,
                          registro: atributosAdicionais?.registro,
                          dataRegistroSiscomex: atributosAdicionais?.dataRegistroSiscomex,
                        });
                      }}
                    >
                      <QCXListItemIcon>
                        <ConfirmationNumberIcon fontSize="small" color="secondary" />
                      </QCXListItemIcon>
                      <Typography
                        variant="inherit"
                        style={{
                          fontSize: 12,
                        }}
                      >
                        {t('com.muralis.qcx.acoes.alterarRegistro').toUpperCase()}
                      </Typography>
                    </MenuItem>
                  )}
                <MenuItem
                  onClick={(event) => {
                    popupState.close(event);
                    handleReabrirClick({ id });
                  }}
                  disabled={!atributosAdicionais?.registro}
                >
                  <QCXListItemIcon>
                    <AcUnitIcon
                      fontSize="small"
                      htmlColor={
                        isNaoCalculadaStatus(calculada) ||
                        isNaoConferidaStatus(atributosAdicionais?.conferida) ||
                        !isLiberadaStatus(atributosAdicionais?.liberada)
                          ? 'rgba(0, 0, 0, 0.38)'
                          : 'orange'
                      }
                    />
                  </QCXListItemIcon>
                  <Typography
                    variant="inherit"
                    style={{
                      fontSize: 12,
                    }}
                  >
                    {t('com.muralis.qcx.acoes.reabrir').toUpperCase()}
                  </Typography>
                </MenuItem>
                {userHasCreateRole(authInfo, 'importacao-di-duimp') && (
                  <>
                    <MenuItem
                      onClick={(event) => {
                        popupState.close(event);
                        handleDuplicarClick(event, {
                          id,
                          processo: atributosAdicionais?.processo,
                        });
                      }}
                    >
                      <QCXListItemIcon>
                        <FileCopyIcon fontSize="small" color="primary" />
                      </QCXListItemIcon>
                      <Typography
                        variant="inherit"
                        style={{
                          fontSize: 12,
                        }}
                      >
                        {t('com.muralis.qcx.acoes.duplicar').toUpperCase()}
                      </Typography>
                    </MenuItem>
                  </>
                )}
                <MenuItem
                  disabled={
                    !(
                      isCalculadaStatus(calculada) &&
                      isConferidaStatus(atributosAdicionais?.conferida) &&
                      isLiberadaStatus(atributosAdicionais?.liberada) &&
                      !!atributosAdicionais?.registro
                    )
                  }
                  onClick={(event) => {
                    popupState.close(event);
                    handleEmissaoIcmsClick(event, {
                      id,
                      processo: atributosAdicionais?.processo,
                      registro: atributosAdicionais?.registro,
                      dataVencimentoIcms: atributosAdicionais?.dataVencimentoIcms,
                      numeroConvenioProtocoloEspecificacaoMercadoria:
                        atributosAdicionais?.numeroConvenioProtocoloEspecificacaoMercadoria,
                    });
                  }}
                >
                  <QCXListItemIcon>
                    <EventIcon
                      fontSize="small"
                      color={
                        !(
                          isCalculadaStatus(calculada) &&
                          isConferidaStatus(atributosAdicionais?.conferida) &&
                          isLiberadaStatus(atributosAdicionais?.liberada) &&
                          !!atributosAdicionais?.registro
                        )
                          ? 'disabled'
                          : 'primary'
                      }
                    />
                  </QCXListItemIcon>
                  <Typography
                    variant="inherit"
                    style={{
                      fontSize: 12,
                    }}
                  >
                    {t('com.muralis.qcx.imposto.emissaoICMS').toUpperCase()}
                  </Typography>
                </MenuItem>
                {userHasDeleteRole(authInfo, 'importacao-di-duimp') && (
                  <>
                    <MenuItem
                      onClick={handleConfigureActivateOrInactivateByIdClick(
                        {
                          id,
                          atributosAdicionais,
                          active,
                        },
                        popupState
                      )}
                      disabled={!active}
                    >
                      <QCXListItemIcon>
                        <DeleteIcon fontSize="small" color={active ? 'error' : 'disabled'} />
                      </QCXListItemIcon>
                      <Typography
                        variant="inherit"
                        style={{
                          fontSize: 12,
                        }}
                      >
                        {t('com.muralis.qcx.acoes.excluir').toUpperCase()}
                      </Typography>
                    </MenuItem>
                  </>
                )}
              </Menu>
            </>
          )}
        </QCXPopupState>
      ),
    },
  ];

  const breadcrumbs = useMemo(
    () => [
      {
        link: {
          to: '/',
          name: t('com.muralis.qcx.inicio'),
        },
      },
      {
        link: {
          to: t('com.muralis.qcx.url.moduloImportacao'),
          name: t('com.muralis.qcx.importacao.label'),
        },
      },
      {
        text: {
          name: t('com.muralis.qcx.DIDUIMP.declaracoesImportacaoDIDUIMP'),
        },
        default: true,
      },
    ],
    []
  );

  const templateProps = useMemo(
    () => ({
      page: {
        title: t('com.muralis.qcx.DIDUIMP.declaracoesImportacaoDIDUIMP'),
        icon: <ReceiptIcon />,
        breadcrumbs,
      },
      control: {
        buttonNew: {
          description: t('com.muralis.qcx.DIDUIMP.novoRegistroManualDIDUIMP'),
          onClick: handleButtonNewClickMiddleware,
        },
      },
      table: {
        columns,
      },
    }),
    [columns, breadcrumbs, handleButtonNewClickMiddleware]
  );

  return (
    <QCXSimpleConsultPageTemplate
      pageProps={templateProps.page}
      controlProps={templateProps.control}
      tableProps={templateProps.table}
      tableData={declaracoesImportacao}
      isLoading={isAlternativeLoading}
      authInfo={authInfo}
      requiredRoles={['importacao-di-duimp']}
    >
      <QCXFinalFormDialog
        key="form-dialog-processo-registro-manual-di-duimp"
        id="form-dialog-processo-registro-manual-di-duimp"
        title={t('com.muralis.qcx.DIDUIMP.novoRegistroManualDIDUIMP')}
        content={t('com.muralis.qcx.preencherCampoContinuar')}
        dialogActionsProps={{
          button: {
            submit: {
              description: t('com.muralis.qcx.acoes.continuar'),
              size: 'small',
            },
            cancel: {
              description: t('com.muralis.qcx.acoes.cancelar'),
              size: 'small',
            },
          },
        }}
        open={isFormDialogNewOpen}
        initialValues={{}}
        onSubmit={handleProcessoNewSubmit}
        onClose={handleCloseFormDialogNew}
      >
        {() => (
          <QCXMoveFocusInside>
            <QCXSelectFollowUpImportacaoAutocompleteField
              key="select-autocomplete-processo-field"
              id="select-autocomplete-processo-field"
              name="followUp.id"
              label={t('com.muralis.qcx.processo')}
              validate={required}
              controlled
              required
            />
          </QCXMoveFocusInside>
        )}
      </QCXFinalFormDialog>
      <QCXFinalFormDialog
        key="form-dialog-impressao-di-duimp"
        id="form-dialog-impressao-di-duimp"
        title={t('com.muralis.qcx.DIDUIMP.relatorioDIDUIMP')}
        content={formDialogImpressaoContent}
        dialogActionsProps={{
          button: {
            submit: {
              description: t('com.muralis.qcx.acoes.gerar'),
              size: 'small',
            },
            cancel: {
              description: t('com.muralis.qcx.acoes.cancelar'),
              size: 'small',
            },
          },
        }}
        open={isFormDialogImpressaoOpen}
        initialValues={reportRelatedModel}
        onSubmit={handleImprimirByIdSubmit}
        onClose={handleCloseFormDialogImpressao}
        disablePristine
      >
        {() => (
          <Grid container>
            <Grid item xs={12}>
              <QCXFinalRadioField
                id="checkbox-tipo-relatorio"
                key="checkbox-tipo-relatorio"
                name="tipo"
                label={t('com.muralis.qcx.DIDUIMP.DraftDIDUIMP')}
                value={ReportUtils.DRAFT_DI_DUIMP}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <QCXFinalRadioField
                id="checkbox-tipo-relatorio"
                key="checkbox-tipo-relatorio"
                name="tipo"
                label={t('com.muralis.qcx.relatorioCalculoAdicao')}
                value={ReportUtils.CALCULO_POR_ADICAO}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <QCXFinalRadioField
                id="checkbox-tipo-relatorio"
                key="checkbox-tipo-relatorio"
                name="tipo"
                label={t('com.muralis.qcx.relatorioItensOrdemDigitacao')}
                value={ReportUtils.ITENS_ORDEM_DIGITACAO}
                required
              />
            </Grid>
          </Grid>
        )}
      </QCXFinalFormDialog>
      <QCXFinalFormDialog
        key="form-dialog-calculo-di-duimp"
        id="form-dialog-calculo-di-duimp"
        title={t('com.muralis.qcx.DIDUIMP.calculoDeclaracaoDIDUIMP')}
        content={formDialogCalculoContent}
        dialogActionsProps={{
          button: {
            submit: {
              description: isNaoCalculadaStatus(calculoRelatedModel?.calculada)
                ? t('com.muralis.qcx.acoes.calcular')
                : t('com.muralis.qcx.acoes.recalcular'),
              size: 'small',
            },
            cancel: {
              description: t('com.muralis.qcx.acoes.cancelar'),
              size: 'small',
            },
          },
        }}
        open={isFormDialogCalculoOpen}
        initialValues={calculoRelatedModel}
        onSubmit={handleCalcularByDataSubmit}
        onClose={handleCloseFormDialogCalculo}
      >
        {() => (
          <QCXMoveFocusInside>
            <QCXFinalDatePickerField
              id="date-picker-data-calculo-field"
              key="date-picker-data-calculo-field"
              name="atributosAdicionais.dataCalculo"
              label={t('com.muralis.qcx.dataCalculo')}
              defaultValue={defaultDateValue}
              validate={required}
              required
            />
          </QCXMoveFocusInside>
        )}
      </QCXFinalFormDialog>
      <QCXFinalFormDialog
        key="form-dialog-duplicacao-di-duimp"
        id="form-dialog-duplicacao-di-duimp"
        title={t('com.muralis.qcx.DIDUIMP.duplicacaoDIDUIMP')}
        content={formDialogDuplicacaoContent}
        dialogActionsProps={{
          button: {
            submit: {
              description: t('com.muralis.qcx.acoes.duplicar'),
              size: 'small',
            },
            cancel: {
              description: t('com.muralis.qcx.acoes.cancelar'),
              size: 'small',
            },
          },
        }}
        open={isFormDialogDuplicacaoOpen}
        initialValues={duplicataRelatedModel}
        onSubmit={handleDuplicarByProcessoSubmit}
        onClose={handleCloseFormDialogDuplicacao}
      >
        {() => (
          <QCXMoveFocusInside>
            <QCXSelectFollowUpImportacaoAutocompleteField
              key="select-autocomplete-processo-field"
              id="select-autocomplete-processo-field"
              name="followUp.id"
              label={t('com.muralis.qcx.processo')}
              validate={required}
              controlled
              required
            />
          </QCXMoveFocusInside>
        )}
      </QCXFinalFormDialog>
      <QCXFinalFormDialog
        key="form-dialog-dados-registro-siscomex-di-duimp"
        id="form-dialog-dados-registro-siscomex-di-duimp"
        title={t('com.muralis.qcx.dadosRegistroSiscomex')}
        content={formDialogDadosRegistroSiscomexContent}
        dialogActionsProps={{
          button: {
            submit: {
              description: t('com.muralis.qcx.acoes.confirmar'),
              size: 'small',
              disabled: !registroRelatedModel?.confirmacaoPendente,
            },
            cancel: {
              description:
                !registroRelatedModel?.registro ||
                !registroRelatedModel?.dataRegistroSiscomex ||
                registroRelatedModel?.confirmacaoPendente
                  ? t('com.muralis.qcx.acoes.cancelar')
                  : t('com.muralis.qcx.acoes.fechar'),
              size: 'small',
            },
          },
        }}
        open={isFormDialogDadosRegistroSiscomexOpen}
        initialValues={registroRelatedModel}
        onSubmit={handleDadosRegistroSiscomexSubmit}
        onClose={handleCloseFormDialogDadosRegistroSiscomex}
      >
        {({ initialValues }) => (
          <Grid item container spacing={2} xs={12}>
            <Grid item xs={12}>
              <QCXMoveFocusInside>
                <QCXFinalTextField
                  key="numero-registro-siscomex-field"
                  id="numero-registro-siscomex-field"
                  name="registro"
                  label={t('com.muralis.qcx.numeroRegistro')}
                  disabled={!!initialValues?.registro && !initialValues?.confirmacaoPendente}
                  validate={required}
                  required
                />
              </QCXMoveFocusInside>
            </Grid>
            <Grid item xs={12}>
              <QCXFinalDatePickerField
                key="data-registro-siscomex-picker-field"
                id="data-registro-siscomex-picker-field"
                name="dataRegistroSiscomex"
                label={t('com.muralis.qcx.dataRegistro')}
                defaultValue={defaultDateValue}
                disabled={!!initialValues?.dataRegistroSiscomex && !initialValues?.confirmacaoPendente}
                validate={required}
                required
              />
            </Grid>
          </Grid>
        )}
      </QCXFinalFormDialog>
      <QCXConfirmDialog
        key="confirm-dialog-reabrir-due"
        id="confirm-dialog-reabrir-due"
        open={reabriDiPopUpConfig?.isPopUpOpen}
        title={reabriDiPopUpConfig?.title}
        content={reabriDiPopUpConfig?.message}
        endContent={reabriDiPopUpConfig?.endMessage}
        onConfirm={reabriDiPopUpConfig.confirmPrimaryAction}
        onClose={reabriDiPopUpConfig?.reset}
      />
      <QCXFinalFormDialog
        key="form-dialog-emissao-icms-di-duimp"
        id="form-dialog-emissao-icms-di-duimp"
        title={
          emissaoIcmsRelatedModel?.registro
            ? t('com.muralis.qcx.imposto.emissaoICMSDIDUIMPNumero', { numero: emissaoIcmsRelatedModel?.registro })
            : t('com.muralis.qcx.imposto.emissaoICMSDIDUIMP')
        }
        content={formDialogEmissaoIcmsContent}
        dialogActionsProps={{
          button: {
            submit: {
              description: t('com.muralis.qcx.acoes.confirmar'),
              size: 'small',
              disabled: !emissaoIcmsRelatedModel?.confirmacaoPendente,
            },
            cancel: {
              description:
                !emissaoIcmsRelatedModel?.dataVencimentoIcms || emissaoIcmsRelatedModel?.confirmacaoPendente
                  ? t('com.muralis.qcx.acoes.cancelar')
                  : t('com.muralis.qcx.acoes.fechar'),
              size: 'small',
            },
          },
        }}
        open={isFormDialogEmissaoIcmsOpen}
        initialValues={emissaoIcmsRelatedModel}
        onSubmit={handleEmissaoIcmsSubmit}
        onClose={handleCloseFormDialogEmissaoIcms}
        disablePristine
      >
        {({ initialValues }) => (
          <Grid item container spacing={2} xs={12}>
            <Grid item xs={12}>
              <QCXMoveFocusInside>
                <QCXFinalDatePickerField
                  key="data-vencimento-icms-picker-field"
                  id="data-vencimento-icmss-picker-field"
                  name="dataVencimentoIcms"
                  label={t('com.muralis.qcx.dataVencimento')}
                  defaultValue={defaultDateValue}
                  disablePast
                  disabled={!!initialValues?.dataVencimentoIcms && !initialValues?.confirmacaoPendente}
                  validate={required}
                  required
                />
              </QCXMoveFocusInside>
            </Grid>
            <Grid item xs={12}>
              <QCXFinalTextField
                key="numero-convenio-protocolo-especificacao-mercadoria-text-field"
                id="numero-convenio-protocolo-especificacao-mercadoria-text-field"
                name="numeroConvenioProtocoloEspecificacaoMercadoria"
                label={t('com.muralis.qcx.mensagem.numeroConvenioProtocoloEspecificacaoMercadoria')}
                maxLength={255}
                helperText={t('com.muralis.qcx.referenteGeracaoGNRE')}
                disabled={!initialValues?.confirmacaoPendente}
              />
            </Grid>
          </Grid>
        )}
      </QCXFinalFormDialog>
      <QCXConfirmDialog
        key="confirm-dialog-conferencia-di-duimp"
        id="confirm-dialog-conferencia-di-duimp"
        open={operationOfConferirById?.active}
        title={operationOfConferirById?.title}
        content={operationOfConferirById?.message}
        endContent={operationOfConferirById?.endMessage}
        onConfirm={operationOfConferirById.confirm}
        onClose={operationOfConferirById?.reset}
      />
      <QCXConfirmDialog
        key="confirm-dialog-liberacao-di-duimp"
        id="confirm-dialog-liberacao-di-duimp"
        open={operationOfLiberarById?.active}
        title={operationOfLiberarById?.title}
        content={operationOfLiberarById?.message}
        endContent={operationOfLiberarById?.endMessage}
        onConfirm={operationOfLiberarById.confirm}
        onClose={operationOfLiberarById?.reset}
      />
      <QCXConfirmDialog
        key="confirm-dialog-retransmissao-di-duimp"
        id="confirm-dialog-retransmissao-di-duimp"
        open={operationOfRetransmitirById?.active}
        title={operationOfRetransmitirById?.title}
        content={operationOfRetransmitirById?.message}
        endContent={operationOfRetransmitirById?.endMessage}
        onConfirm={operationOfRetransmitirById.confirm}
        onClose={operationOfRetransmitirById?.reset}
      />
      <QCXConfirmDialog
        key="confirm-dialog-dados-registro-siscomex-di-duimp"
        id="confirm-dialog-dados-registro-siscomex-di-duimp"
        open={operationOfInformarDadosRegistroSiscomex?.active}
        title={operationOfInformarDadosRegistroSiscomex?.title}
        buttonGroupOptions={{
          confirm: {
            description: t('com.muralis.qcx.expressao.sim'),
          },
          cancel: {
            description: t('com.muralis.qcx.expressao.nao'),
          },
        }}
        content={operationOfInformarDadosRegistroSiscomex?.message}
        endContent={operationOfInformarDadosRegistroSiscomex?.endMessage}
        onConfirm={operationOfInformarDadosRegistroSiscomex.confirm}
        onClose={handleCloseDadosRegistroSiscomexOperation}
      />
      <QCXConfirmDialog
        key="confirm-dialog-data-vencimento-emissao-icms-di-duimp"
        id="confirm-dialog-data-vencimento-emissao-icms-di-duimp"
        open={operationOfEmissaoIcms?.active}
        title={operationOfEmissaoIcms?.title}
        buttonGroupOptions={{
          confirm: {
            description: t('com.muralis.qcx.expressao.sim'),
          },
          cancel: {
            description: t('com.muralis.qcx.expressao.nao'),
          },
        }}
        content={operationOfEmissaoIcms?.message}
        endContent={operationOfEmissaoIcms?.endMessage}
        onConfirm={operationOfEmissaoIcms.confirm}
        onClose={handleCloseEmissaoIcmsOperation}
      />
      <QCXConfirmDialog
        key="confirm-dialog-activate-inactivate-di-duimp"
        id="confirm-dialog-activate-inactivate-di-duimp"
        open={operationOfActivateOrInactivateById?.active}
        title={operationOfActivateOrInactivateById?.title}
        content={operationOfActivateOrInactivateById?.message}
        endContent={operationOfActivateOrInactivateById?.endMessage}
        onConfirm={operationOfActivateOrInactivateById.confirm}
        onClose={operationOfActivateOrInactivateById?.reset}
      />
      <QCXGenericDownloader status={formDialogImpressaoStatus} message={formDialogImpressaoSubmittingFeedback} />
    </QCXSimpleConsultPageTemplate>
  );
}
