import React, { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import QCXFinalAlternativeCurrencyField from '../../shared-components/final-currency-field/QCXFinalAlternativeCurrencyField';

export default function QCXFinalAlternativeCurrencyFieldRender({
  disabled,
  name,
  ...restProps
}) {
  const [key, setKey] = useState(name);

  useEffect(() => {
    if (disabled) {
      setKey(`${name.replaceAll('.', '-')}-${uuid()}`);
    }
  }, [
    name,
    disabled
  ]);

  return (
    <>
      <QCXFinalAlternativeCurrencyField
        {...restProps}
        key={key}
        name={name}
        disabled={disabled}
      />
    </>
  );
}
