import React from 'react';
import { Container, Grid, makeStyles } from '@material-ui/core';
import QCXMainLayout from '../../components/main-layout/QCXMainLayout';
import QCXFormSubtitle from '../../components/form-title/QCXFormSubtitle';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    padding: '0 1rem 3rem 1rem',
  },
}));

export default function QCXActionsManagerPageTemplate({
  key = 'main-layout-actions-manager',
  pageTitle = '',
  pageIcon = undefined,
  breadcrumbs = [],
  breadcrumbsProps = {},
  isUpdate,
  isConsult,
  subtitle = {
    show: false,
    text: undefined,
  },
  children,
  authInfo = {},
  ...restProps
}) {
  const classes = useStyles();

  return (
    <QCXMainLayout
      key={key}
      pageIcon={pageIcon}
      pageTitle={pageTitle}
      breadcrumbs={breadcrumbs}
      breadcrumbsProps={breadcrumbsProps}
      authInfo={authInfo}
      disableFocusLock
    >
      <Container className={classes.container}>
        <Grid container style={{ marginTop: '0.2rem' }}>
          {subtitle?.show && (
            <Grid item xs={12} style={{ marginBottom: '1.4rem' }}>
              <QCXFormSubtitle title={subtitle?.text} />
            </Grid>
          )}
          {typeof children === 'function'
            ? children({
                isConsult,
                isUpdate,
                ...restProps,
              })
            : children}
        </Grid>
      </Container>
    </QCXMainLayout>
  );
}
