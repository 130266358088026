/* eslint-disable no-unused-vars */
import { Grid } from '@material-ui/core';
import { Radios } from 'mui-rff';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { fetchAllAsync } from '../../features/grupo-unidade-negocio/grupoUnidadeNegocioSlice';
import QCXFinalCheckboxField from '../../shared-components/final-checkbox-field/QCXFinalCheckboxField';
import QCXFinalTextField from '../../shared-components/final-text-field/QCXFinalTextField';
import QCXFormStepWizard from '../../shared-components/form-step-wizard/QCXFormStepWizard';
import QCXSelectGrupoUnidadeNegocioAutocomplete from '../../shared-components/select-grupo-unidade-negocio/QCXSelectGrupoUnidadeNegocioAutocomplete';
import PerfilUtils from '../../utils/general/perfil/PerfilUtils';
import { required as requiredValidator } from '../../utils/validators/field/validator';
import QCXFormTitle from '../form-title/QCXFormTitle';
import {
  ACESSOS_CADASTROS_FINANCEIROS_NAME_MAP,
  ACESSOS_CADASTROS_GERAIS_NAME_MAP,
  ACESSOS_CADASTROS_OPERACIONAIS_NAME_MAP,
  ACESSOS_IMPORTACAO_NAME_MAP,
  ACESSOS_EXPORTACAO_NAME_MAP,
  ACESSOS_FINANCEIRO_NAME_MAP,
  ADMINISTRADOR_ROLE,
  // MODULOS_DE_ACESSO,
  userHasRole,
} from './perfil-utils';

export default function QCXModulosAcessoPerfilForm({
  initialValues,
  handleSubmit,
  isCreate,
  isConsult,
  controlComponentProps,
  authInfo = {},
  ...restProps
}) {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [userHasGrupoRole, setUserHasGrupoRole] = useState(false);
  useEffect(() => {
    const grupoRole = userHasRole(authInfo, 'grupo-incluir');
    setUserHasGrupoRole(grupoRole);
    if (grupoRole) {
      dispatch(fetchAllAsync());
    }
  }, []);

  const handleModuloAcessoChange = (form, values) => (value) => {
    const selectedModules = value || [];
    const allPermissions = values?.permissoesAcesso || [];

    const moduleFilters = {
      CADASTROS: (permission) =>
        ACESSOS_CADASTROS_OPERACIONAIS_NAME_MAP.includes(permission) ||
        ACESSOS_CADASTROS_GERAIS_NAME_MAP.includes(permission) ||
        ACESSOS_CADASTROS_FINANCEIROS_NAME_MAP.includes(permission),
      IMPORTACAO: (permission) => ACESSOS_IMPORTACAO_NAME_MAP.includes(permission),
      EXPORTACAO: (permission) => ACESSOS_EXPORTACAO_NAME_MAP.includes(permission),
      FINANCEIRO: (permission) => ACESSOS_FINANCEIRO_NAME_MAP.includes(permission),
    };

    const updatedPermissions = allPermissions.filter((permission) => {
      if (selectedModules.length === 0) {
        return true;
      }
      const filterCondition = moduleFilters[selectedModules];
      return filterCondition ? filterCondition(permission) : true;
    });

    form.change('permissoesAcesso', updatedPermissions);
  };

  const handlePerfilAdministradorChange = (form) => (value) => {
    if (value?.includes(ADMINISTRADOR_ROLE)) {
      form.change('atributos.grupo[0].id', '');
    }
  };
  const listeners = [
    { name: 'atributos.modulosAcesso[]', fn: handleModuloAcessoChange },
    { name: 'permissoesAcesso[]', fn: handlePerfilAdministradorChange },
  ];
  const userIsAdmin = userHasRole(authInfo, ADMINISTRADOR_ROLE);
  return (
    <QCXFormStepWizard
      initialValues={initialValues}
      onSubmit={handleSubmit}
      controlComponentProps={controlComponentProps}
      onChangeListeners={listeners}
      disablePristine
      authInfo={authInfo}
      {...restProps}
    >
      {({ values }) => (
        <>
          <Grid container spacing={2} style={{ marginTop: '0.2rem' }} alignItems="center">
            <Grid item xs={12} sm={userHasGrupoRole ? 12 : 6} md={userHasGrupoRole ? 12 : 6}>
              <QCXFinalTextField
                name="nome"
                label={t('com.muralis.qcx.nomePerfil')}
                disabled={isConsult}
                validate={requiredValidator}
              />
            </Grid>

            {userIsAdmin && (
              <>
                <Grid item xs={12} sm={8} md={8}>
                  <QCXSelectGrupoUnidadeNegocioAutocomplete
                    name="atributos.grupo[0].id"
                    label={t('com.muralis.qcx.grupoAssociado')}
                    initialValues={initialValues}
                    {...(isCreate
                      ? {
                          fieldProps: {
                            validate: values?.permissoesAcesso?.includes(ADMINISTRADOR_ROLE) ? null : requiredValidator,
                          },
                        }
                      : {})}
                    disabled={!isCreate || values?.permissoesAcesso?.includes(ADMINISTRADOR_ROLE)}
                    required={isCreate}
                  />
                </Grid>
              </>
            )}
          </Grid>

          <QCXFormTitle title={t('com.muralis.qcx.definaModulosAcessoPerfil')} />
          <Grid container spacing={2} style={{ marginTop: '0.2rem' }}>
            <Grid item container xs={12} sm={12} md={8} lg={7}>
              <Grid item xs={12} sm={3} md={3}>
                <QCXFinalCheckboxField
                  name="atributos.modulosAcesso[]"
                  label={t('com.muralis.qcx.cadastros')}
                  disabled={isConsult}
                  value="CADASTROS"
                />
              </Grid>
              <Grid item xs={12} sm={3} md={3}>
                <QCXFinalCheckboxField
                  name="atributos.modulosAcesso[]"
                  label={t('com.muralis.qcx.importacao.label')}
                  disabled={isConsult}
                  value="IMPORTACAO"
                />
              </Grid>
              <Grid item xs={12} sm={3} md={3}>
                <QCXFinalCheckboxField
                  name="atributos.modulosAcesso[]"
                  label={t('com.muralis.qcx.exportacao.label')}
                  disabled={isConsult}
                  value="EXPORTACAO"
                />
              </Grid>
              <Grid item xs={12} sm={3} md={3}>
                <QCXFinalCheckboxField
                  name="atributos.modulosAcesso[]"
                  label={t('com.muralis.qcx.financeiro.label')}
                  disabled={isConsult}
                  value="FINANCEIRO"
                />
              </Grid>
            </Grid>
            <Grid item container xs={12} sm={12} md={8} lg={7}>
              <Grid item xs={12} sm={3} md={3}>
                <QCXFinalCheckboxField
                  name="atributos.modulosAcesso[]"
                  label="Comercial"
                  disabled={isConsult}
                  value="COMERCIAL"
                />
              </Grid>
              <Grid item xs={12} sm={3} md={3}>
                <QCXFinalCheckboxField
                  name="atributos.modulosAcesso[]"
                  label="Integração"
                  disabled={isConsult}
                  value="INTEGRACAO"
                />
              </Grid>
              <Grid item xs={12} sm={3} md={3}>
                <QCXFinalCheckboxField
                  name="atributos.modulosAcesso[]"
                  label="Relatórios"
                  disabled={isConsult}
                  value="RELATORIOS"
                />
              </Grid>
            </Grid>
          </Grid>

          <QCXFormTitle title={t('com.muralis.qcx.definaModuloMaisUtilizado')} />
          <Grid container spacing={2} style={{ marginTop: '0.2rem' }}>
            <Grid item container xs={12} sm={6} md={12}>
              <Radios
                name="atributos.preferido[0]"
                required
                radioGroupProps={{ row: true }}
                formControlProps={{ disabled: isConsult }}
                data={[
                  { value: 'CADASTROS', label: t('com.muralis.qcx.cadastros').toUpperCase() },
                  { value: 'IMPORTACAO', label: t('com.muralis.qcx.importacao.label').toUpperCase() },
                  { value: 'EXPORTACAO', label: t('com.muralis.qcx.exportacao.label').toUpperCase() },
                  { value: 'FINANCEIRO', label: t('com.muralis.qcx.financeiro.label').toUpperCase() },
                  { value: 'COMERCIAL', label: 'COMERCIAL' },
                  { value: 'INTEGRACAO', label: 'INTEGRAÇÃO' },
                  { value: 'RELATORIOS', label: 'RELATÓRIOS' },
                ]}
              />
            </Grid>
          </Grid>

          <QCXFormTitle title={t('com.muralis.qcx.configuracaoEtapasTiposDocumento')} />
          <Grid container spacing={2} style={{ marginTop: '0.2rem' }}>
            <Grid item container xs={12}>
              <Grid item xs={12} sm={12} md={4}>
                <QCXFinalCheckboxField
                  name="atributos.visualizaEtapaPrivada"
                  label={t('com.muralis.qcx.visualizarEtapasPrivadas')}
                  value={PerfilUtils.ETAPA_PRIVADA}
                  disabled={isConsult}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <QCXFinalCheckboxField
                  name="atributos.visualizaTipoDocumentoPrivado"
                  label={t('com.muralis.qcx.visualizarTiposDocumentosPrivados')}
                  value={PerfilUtils.TIPO_DOCUMENTO_PRIVADO}
                  disabled={isConsult}
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </QCXFormStepWizard>
  );
}
