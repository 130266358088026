import React from 'react';
import QCXTextField from './QCXTextField';

export default function QCXMultilineTextField({
  variant = 'filled',
  visible,
  fullWidth = true,
  inputProps = {},
  value,
  rowsMax,
  maxLength,
  placeholder,
  debug = false,
  retreat = true,
  remaningCharactersInfo = false,
  ...restProps
}) {
  return (
    <>
      <QCXTextField
        multiline
        variant={variant}
        visible={visible}
        fullWidth={fullWidth}
        inputProps={inputProps}
        rowsMax={rowsMax}
        maxLength={maxLength}
        value={value}
        placeholder={placeholder}
        debug={debug}
        InputLabelProps={{
          shrink: retreat,
          style: {
            textTransform: 'uppercase',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          },
        }}
        remaningCharactersInfo={remaningCharactersInfo || maxLength > 0}
        {...restProps}
      />
    </>
  );
}
