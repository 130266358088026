/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-vars */
import { makeStyles } from "@material-ui/core";
import { DataGrid, DataGridProps } from "@material-ui/data-grid";
import React, { ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  root: {
    width: "calc(100% - 8px)",
    "& .MuiDataGrid-footer, & .MuiTablePagination-toolbar": {
      minHeight: "42px!important",
      "& .MuiIconButton-root": {
        paddingTop: "4px",
        paddingBottom: "4px",
      },
    },
  },
  columnsContainer: {
    height: "40px",
  },
}));

export default function StyledDataGrid({
  divProps,
  ...restProps
}: DataGridProps & { divProps?: any }) {
  const { t } = useTranslation();
  const classes = useStyles();

  const ddg = ({
    pageSize = 5,
    localeText = {
      noRowsLabel: t("com.muralis.qcx.mensagem.nenhumRegistroEncontrado"),
      noResultsOverlayLabel: t(
        "com.muralis.qcx.mensagem.nenhumRegistroEncontrado"
      ),
      errorOverlayDefaultLabel: t("com.muralis.qcx.erro.unspecified"),
      footerRowSelected: (count: number) =>
        count !== 1
          ? (t(
              "com.muralis.qcx.linha.linhaSelecionadaPlural",
              count.toLocaleString()
            ) as ReactNode)
          : (t(
              "com.muralis.qcx.linha.linhaSelecionada",
              count.toLocaleString()
            ) as ReactNode),
    },
    ...ddgRestProps
  }: DataGridProps) => (
    <div className={classes.root} {...divProps}>
      <DataGrid
        className="custom-table"
        pageSize={pageSize}
        disableColumnMenu
        disableColumnReorder
        disableColumnResize
        disableSelectionOnClick
        localeText={localeText}
        autoHeight
        rowHeight={38}
        headerHeight={42}
        {...ddgRestProps}
      />
    </div>
  );

  const [Table, setTable] = useState(() => ddg);

  useEffect(() => {
    setTable(() => ddg);
  }, [restProps?.rows?.length]);

  return (
    <>
      <Table {...restProps} />
    </>
  );
}
