import React from 'react';
import usePaginator from '../../utils/hooks/paginator/usePaginator';
import QCXPaginator from './QCXPaginator';

export default function QCXTimelinePaginatorContainer({
  list = [],
  children,
}) {
  const paginator = usePaginator();

  return (
    <QCXPaginator.Container>

      {list.map((value, index, array) => (
        children(
          {
            value,
            index,
            array,
          },
          paginator
        )
      ))}
    </QCXPaginator.Container>
  );
}
