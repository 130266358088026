import { Grid } from '@material-ui/core';
import { useCallback, useMemo } from 'react';
import uuid from 'react-uuid';
import { useForm } from 'react-final-form';
import QCXFinalBondManagerOld from '../../shared-components/final-gerenciador-relacionamento/QCXFinalBondManagerOld';
import QCXFinalTextField from '../../shared-components/final-text-field/QCXFinalTextField';
import QCXFinalNumericDecimalField from '../../shared-components/final-numeric-decimal-field/QCXFinalNumericDecimalField';
import QCXFormSubtitle from '../form-title/QCXFormSubtitle';
import {
  vinculoOperacaoExportacaoTemporariaVinculadaDUEActions,
  vinculoOperacaoExportacaoTemporariaVinculadaDUESelectors,
} from '../../features/vinculo-operacao-exportacao-temporaria-vinculada-due/vinculoOperacaoExportacaoTemporariaVinculadaDUESlice';

export default function QCXNotaComplementarFormGroup({ disabled, isParentConsult }) {
  const form = useForm();
  const listProps = useMemo(
    () => ({
      name: 'due.notasComplementares',
      columns: [
        {
          field: 'chaveAcessoNfe',
          headerName: 'Chave de Acesso da NF-e',
          headerAlign: 'center',
          align: 'center',
          flex: 300,
          valueGetter: ({ row }) => row?.chaveAcessoNfe,
        },
        {
          field: 'quantidadeAssociada',
          headerName: 'Quantidade Associada',
          headerAlign: 'center',
          align: 'center',
          flex: 100,
          valueGetter: ({ row }) => row?.quantidadeAssociada,
        },
      ],
    }),
    []
  );
  const formProps = useMemo(
    () => ({
      rootName: 'ignorableFields.notaComplementar.form',
      fields: [
        {
          name: 'chaveAcessoNfe',
          label: 'Chave de Acesso da NF-e',
          required: true,
        },
        {
          name: 'quantidadeAssociada',
          label: 'Quantidade Associada',
          required: true,
        },
      ],
    }),
    []
  );

  const reducerConfig = useMemo(
    () => ({
      selectors: {
        selectStatus: vinculoOperacaoExportacaoTemporariaVinculadaDUESelectors.selectStatus,
        selectMode: vinculoOperacaoExportacaoTemporariaVinculadaDUESelectors.selectMode,
        selectModel: vinculoOperacaoExportacaoTemporariaVinculadaDUESelectors.selectModel,
      },
      actions: {
        loading: vinculoOperacaoExportacaoTemporariaVinculadaDUEActions.loading,
        resetStatus: vinculoOperacaoExportacaoTemporariaVinculadaDUEActions.resetStatus,
        changeToUpdateMode: vinculoOperacaoExportacaoTemporariaVinculadaDUEActions.changeToUpdateMode,
        changeToCreateMode: vinculoOperacaoExportacaoTemporariaVinculadaDUEActions.changeToCreateMode,
        resetMode: vinculoOperacaoExportacaoTemporariaVinculadaDUEActions.resetMode,
        setModel: vinculoOperacaoExportacaoTemporariaVinculadaDUEActions.setModel,
        resetModel: vinculoOperacaoExportacaoTemporariaVinculadaDUEActions.resetModel,
      },
    }),
    []
  );

  const handleAdd = useCallback((currentVinculo, currentVinculosList, handleSuccessAddVinculo) => {
    const vinculo = {
      id: uuid(),
      ...currentVinculo,
    };

    const updatedVinculos = [...currentVinculosList, vinculo];
    handleSuccessAddVinculo(updatedVinculos);
    form.resetFieldState('ignorableFields.notaReferenciada.form.quantidadeAssociada');
    form.change('ignorableFields.notaReferenciada.form.quantidadeAssociada', '');
  }, []);
  return (
    <>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <QCXFormSubtitle
          title="Notas Complementares"
          boxProps={{
            pt: 1,
          }}
        />
      </Grid>
      <QCXFinalBondManagerOld
        isParentConsult={isParentConsult}
        listProps={listProps}
        formProps={formProps}
        reducerConfig={reducerConfig}
        handleAdd={handleAdd}
        controlButtonsGridProps={{
          add: {
            xs: 12,
            sm: 2,
            md: 2,
          },
        }}
      >
        {({ loading }) => (
          <>
            <Grid item xs={12} sm={12} md={8} lg={8}>
              <QCXFinalTextField
                name="ignorableFields.notaComplementar.form.chaveAcessoNfe"
                label="Chave de Acesso da NF-e"
                disabled={disabled || loading}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <QCXFinalNumericDecimalField
                name="ignorableFields.notaComplementar.form.quantidadeAssociada"
                label="Quantidade Associada"
                disabled={disabled || loading}
                scale={5}
              />
            </Grid>
          </>
        )}
      </QCXFinalBondManagerOld>
    </>
  );
}
