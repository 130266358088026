import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { updateClientesAssociados } from '../../features/modelo-danfe/modeloDanfeSlice';
import QCXFinalFormWizard from '../../shared-components/final-form-wizard/QCXFinalFormWizard';
import QCXSelectManyClienteFormTableManager from '../cliente/QCXSelectManyClienteFormTableManager';
import QCXModeloDanfeBasicasForm from './QCXModeloDanfeBasicasForm';

export default function QCXModeloDanfeWizardFinalForm({
  model,
  handleChangeModel,
  isCreate,
  isConsult,
  isUpdate,
  isBackgroundCreate,
  isPreparingAction,
  handleSubmit,
  handleAlternativeSave,
  handleChangeToPreparingAction,
  handleChangeToBackgroundCreate,
  handleResetBackgroundMode,
  handleChangeToCreate,
  handleChangeToConsult,
  handleChangeToUpdate,
  handleCancelUpdate,
  authInfo = {},
  requiredRoles = [],
  ...restProps
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const wizardSteps = [t('com.muralis.qcx.basicas'),
    t('com.muralis.qcx.cliente.labelPlural')];

  const clientes = useMemo(() => (
    model?.clientes || []
  ), [model]);

  const configureClientesFilterPredicateWith = useCallback(() => {
    const clientesFilterPredicate = (cliente) => (
      !cliente?.modeloDanfe || cliente?.modeloDanfe?.id === model?.id
    );

    return clientesFilterPredicate;
  }, [model]);

  const handleSetSelectedClientes = useCallback((data) => {
    const updatedClientes = data.map((cliente) => {
      if (!cliente?.modeloDanfe) {
        return {
          ...cliente,
          modeloDanfe: {
            id: model?.id,
          },
        };
      }
      return cliente;
    });

    dispatch(updateClientesAssociados(updatedClientes));
  }, [model]);

  const checkIfIsAlternativeSaveByStep = useCallback((currentStep) => (
    currentStep === 1
  ), []);

  return (
    <>
      <QCXFinalFormWizard
        steps={wizardSteps}
        initialValues={model}
        handleChangeValues={handleChangeModel}
        isCreate={isCreate}
        isConsult={isConsult}
        isUpdate={isUpdate}
        isBackgroundCreate={isBackgroundCreate}
        checkIfIsAlternativeSaveByStep={checkIfIsAlternativeSaveByStep}
        handleSubmit={handleSubmit}
        handleAlternativeSave={handleAlternativeSave}
        handleChangeToBackgroundCreate={handleChangeToBackgroundCreate}
        handleChangeToCreate={handleChangeToCreate}
        handleChangeToConsult={handleChangeToConsult}
        handleChangeToUpdate={handleChangeToUpdate}
        handleCancelUpdate={handleCancelUpdate}
        handleResetBackgroundMode={handleResetBackgroundMode}
        {...restProps}
      >
        {(formProps) => (
          <QCXModeloDanfeBasicasForm
            key="ficha-modelo-danfe-basicas"
            isCreate={isCreate}
            isUpdate={isUpdate}
            isConsult={isConsult}
            authInfo={authInfo}
            requiredRoles={requiredRoles}
            {...formProps}
          />
        )}
        {({ controlComponentProps, ...restFormProps }) => (
          <QCXSelectManyClienteFormTableManager
            list={clientes}
            isConsult={isConsult}
            handleChange={handleSetSelectedClientes}
            controlComponentProps={controlComponentProps}
            authInfo={authInfo}
            requiredRoles={requiredRoles}
            configureFilterPredicate={
              configureClientesFilterPredicateWith
            }
            {...restFormProps}
          />
        )}
      </QCXFinalFormWizard>
    </>
  );
}
