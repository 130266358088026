import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IDLE_CONTROL } from '../config-control';
import {
  ALTERNATIVE_LOADING_STATUS,
  FAILURE_STATUS,
  IDLE_STATUS,
  LOADING_STATUS,
  SUCCESS_STATUS,
} from '../status';
import {
  fetchByFilterDominio,
} from './ncmExportacaoAPI';
import i18n from '../../i18n';

const initialState = {
  status: IDLE_STATUS,
  error: null,
  response: {
    status: null,
    message: null,
    data: null,
  },
  list: [],
  config: { control: IDLE_CONTROL },
  model: {
    id: '',
    code: '',
    description: '',
    active: null,

  },
};

const fetchByFilterDominioAsync = createAsyncThunk(
  'ncmDominioExportacao/fetchByFilterDominio',
  async (params) => {
    const { data } = await fetchByFilterDominio(params);
    return { data };
  }
);

const ncmDominioExportacaoSlice = createSlice({
  name: 'ncmDominioExportacao',
  initialState,
  reducers: {
    changeStatusTo: (state, action) => {
      state.status = action.payload.status;
    },
    loading: (state) => {
      state.status = LOADING_STATUS;
    },
    success: (state) => {
      state.status = SUCCESS_STATUS;
    },
    failure: (state) => {
      state.status = FAILURE_STATUS;
    },
    resetStatus: (state) => {
      state.status = IDLE_STATUS;
    },
    addToList: (state, action) => {
      state.list = [...state.list, action.payload.data];
    },
    updateOnList: (state, action) => {
      state.list = state.list.map((current) => {
        if (current.id === action.payload.data?.id) {
          return action.payload.data;
        }
        return current;
      });
    },
    setResponse: (state, action) => {
      state.response = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    clearResponse: (state) => {
      state.response = {
        status: null,
        message: null,
        data: null,
      };
    },
    resetModel: (state) => {
      state.model = initialState.model;
    },
    setConfig: (state, action) => {
      state.config = action.payload;
    },
    changeControlTo: (state, action) => {
      state.config.control = action.payload;
    },
    resetList: (state) => {
      state.list = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchByFilterDominioAsync.pending, (state) => {
        state.status = ALTERNATIVE_LOADING_STATUS;
      })
      .addCase(fetchByFilterDominioAsync.fulfilled, (state, action) => {
        state.status = IDLE_STATUS;
        state.list = action.payload.data;
      })
      .addCase(fetchByFilterDominioAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.carregarListaDominioAtributoNcm', { erro: action.error.message }),
        };
      });
  },
});

const {
  changeStatusTo,
  loading,
  success,
  failure,
  resetStatus,
  addToList,
  updateOnList,
  setResponse,
  setResponseMessage,
  clearResponse,
  setError,
  resetModel,
  changeControlTo,
  setConfig,
  resetList,
} = ncmDominioExportacaoSlice.actions;

const selectDominiosNcm = (state) => state.ncmDominioExportacao.list;
const selectStatus = (state) => state.ncmDominioExportacao.status;
const selectError = (state) => state.ncmDominioExportacao.error;
const selectResponse = (state) => state.ncmDominioExportacao.response;
const selectDominioNcm = (state) => state.ncmDominioExportacao.model;
const selectConfigControl = (state) => state.ncmDominioExportacao.config.control;

const ncmDominioExportacaoActions = {
  ...ncmDominioExportacaoSlice.actions,
  fetchByFilterDominioAsync,
  selectDominiosNcm,
  selectStatus,
  selectError,
  selectResponse,
  selectDominioNcm,
  selectConfigControl,
};

export {
  ncmDominioExportacaoSlice,
  changeStatusTo,
  loading,
  success,
  failure,
  resetStatus,
  addToList,
  updateOnList,
  setResponse,
  setResponseMessage,
  clearResponse,
  setError,
  resetModel,
  fetchByFilterDominioAsync,
  changeControlTo,
  setConfig,
  ncmDominioExportacaoActions,
  resetList,
  selectDominiosNcm,
  selectDominioNcm,
  selectStatus,
  selectError,
  selectResponse,
  selectConfigControl,
};

export default ncmDominioExportacaoSlice.reducer;
