import React from 'react';
import PopupState from 'material-ui-popup-state';

export default function QCXPopupState({
  variant = 'popover',
  popupId,
  disableAutoFocus = true,
  children,
  ...restProps
}) {
  return (
    <PopupState
      variant={variant}
      popupId={popupId}
      disableAutoFocus={disableAutoFocus}
      {...restProps}
    >
      {children}
    </PopupState>
  );
}
