import i18n from '../../../i18n';
import { getOptionByValueInList, isStrictEquals } from '../general-utils';

const CONCILIACAO_BANCARIA = 'CONCILIACAO_BANCARIA';
const CONCILIACAO_BANCARIA_STATUS_CONCILIADA = 'CONCILIADA';
const CONCILIACAO_BANCARIA_STATUS_NAO_CONCILIADA = 'NAO_CONCILIADA';

const isConciliacaoBancaria = (key) => (
  isStrictEquals(key, CONCILIACAO_BANCARIA)
);

const isConciliacaoBancariaConciliada = (code) => (
  isStrictEquals(code, CONCILIACAO_BANCARIA_STATUS_CONCILIADA)
);

const isConciliacaoBancariaNaoConciliada = (code) => (
  isStrictEquals(code, CONCILIACAO_BANCARIA_STATUS_NAO_CONCILIADA)
);

const LIST = [
  {
    label: i18n.t('com.muralis.qcx.conciliada'),
    value: CONCILIACAO_BANCARIA_STATUS_CONCILIADA,
  },
  {
    label: i18n.t('com.muralis.qcx.naoConciliada'),
    value: CONCILIACAO_BANCARIA_STATUS_NAO_CONCILIADA,
  },
];

const getLabelByValue = (value) => {
  const foundOption = getOptionByValueInList(LIST, value);
  return foundOption && foundOption?.label;
};

const ConciliacaoBancariaUtils = Object.freeze({
  CONCILIACAO_BANCARIA,
  CONCILIADA: (
    CONCILIACAO_BANCARIA_STATUS_CONCILIADA
  ),
  NAO_CONCILIADA: (
    CONCILIACAO_BANCARIA_STATUS_NAO_CONCILIADA
  ),
  isConciliada: (
    isConciliacaoBancariaConciliada
  ),
  isNaoConciliada: (
    isConciliacaoBancariaNaoConciliada
  ),
  isConciliacaoBancaria,
  getLabelByValue,
});

export default ConciliacaoBancariaUtils;
