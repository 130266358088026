import i18n from '../../../i18n';

const REGIME_TRIBUTARIO_NENHUM = 'NENHUM';
const REGIME_TRIBUTARIO_SIMPLES_NACIONAL = 'SIMPLES_NACIONAL';
const REGIME_TRIBUTARIO_SIMPLES_NACIONAL_EXCESSO = 'SIMPLES_NACIONAL_EXCESSO';
const REGIME_TRIBUTARIO_NORMAL_LUCRO_PRESUMIDO = 'NORMAL_LUCRO_PRESUMIDO';
const REGIME_TRIBUTARIO_NORMAL_LUCRO_REAL = 'NORMAL_LUCRO_REAL';

const REGIME_TRIBUTARIO_ESPECIAL_SEM_REGIME_TRIBUTARIO_ESPECIAL = 'SEM_REGIME_TRIBUTARIO_ESPECIAL';
const REGIME_TRIBUTARIO_ESPECIAL_MICRO_EMPRESA_MUNICIPAL = 'MICRO_EMPRESA_MUNICIPAL';
const REGIME_TRIBUTARIO_ESPECIAL_ESTIMATIVA = 'ESTIMATIVA';
const REGIME_TRIBUTARIO_ESPECIAL_SOCIEDADE_DE_PROFISSIONAIS = 'SOCIEDADE_DE_PROFISSIONAIS';
const REGIME_TRIBUTARIO_ESPECIAL_COOPERATIVA = 'COOPERATIVA';
const REGIME_TRIBUTARIO_ESPECIAL_MICROEMPRESARIO_INDIVIDUAL_MEI = 'MICROEMPRESARIO_INDIVIDUAL_MEI';
const REGIME_TRIBUTARIO_ESPECIAL_MICROEMPRESA_PEQUENO_PORTE_ME_EPP = 'EMPRESA_MICROEMPRESA_PEQUENO_PORTE_ME_EPP';

const REGIME_TRIBUTARIO_LIST = [
  {
    label: i18n.t('com.muralis.qcx.regimeTributario.nenhum'),
    value: REGIME_TRIBUTARIO_NENHUM,
  },
  {
    label: i18n.t('com.muralis.qcx.regimeTributario.simplesNacional'),
    value: REGIME_TRIBUTARIO_SIMPLES_NACIONAL,
  },
  {
    label: i18n.t('com.muralis.qcx.regimeTributario.simplesNacionalExcesso'),
    value: REGIME_TRIBUTARIO_SIMPLES_NACIONAL_EXCESSO,
  },
  {
    label: i18n.t('com.muralis.qcx.regimeTributario.normalLucroPresumido'),
    value: REGIME_TRIBUTARIO_NORMAL_LUCRO_PRESUMIDO,
  },
  {
    label: i18n.t('com.muralis.qcx.regimeTributario.normalLucroReal'),
    value: REGIME_TRIBUTARIO_NORMAL_LUCRO_REAL,
  },
];

const REGIME_TRIBUTARIO_ESPECIAL_LIST = [
  {
    label: i18n.t('com.muralis.qcx.regimeTributarioEspecial.semRegimeTributarioEspecial'),
    value: REGIME_TRIBUTARIO_ESPECIAL_SEM_REGIME_TRIBUTARIO_ESPECIAL,
  },
  {
    label: i18n.t('com.muralis.qcx.regimeTributarioEspecial.microEmpresaMunicipal'),
    value: REGIME_TRIBUTARIO_ESPECIAL_MICRO_EMPRESA_MUNICIPAL,
  },
  {
    label: i18n.t('com.muralis.qcx.regimeTributarioEspecial.estimativa'),
    value: REGIME_TRIBUTARIO_ESPECIAL_ESTIMATIVA,
  },
  {
    label: i18n.t('com.muralis.qcx.regimeTributarioEspecial.sociedadeProfissionais'),
    value: REGIME_TRIBUTARIO_ESPECIAL_SOCIEDADE_DE_PROFISSIONAIS,
  },
  {
    label: i18n.t('com.muralis.qcx.regimeTributarioEspecial.cooperativa'),
    value: REGIME_TRIBUTARIO_ESPECIAL_COOPERATIVA,
  },
  {
    label: i18n.t('com.muralis.qcx.regimeTributarioEspecial.microeempresarioMEI'),
    value: REGIME_TRIBUTARIO_ESPECIAL_MICROEMPRESARIO_INDIVIDUAL_MEI,
  },
  {
    label: i18n.t('com.muralis.qcx.regimeTributarioEspecial.microempresaPequenoPorteMEEPP'),
    value: REGIME_TRIBUTARIO_ESPECIAL_MICROEMPRESA_PEQUENO_PORTE_ME_EPP,
  },
];

const getTipoRegimeTributarioEmpresaValue = (tipoRegimeTributarioEmpresa) => {
  switch (tipoRegimeTributarioEmpresa) {
    case REGIME_TRIBUTARIO_ESPECIAL_SEM_REGIME_TRIBUTARIO_ESPECIAL: {
      return 0;
    }
    case REGIME_TRIBUTARIO_ESPECIAL_MICRO_EMPRESA_MUNICIPAL: {
      return 1;
    }
    case REGIME_TRIBUTARIO_ESPECIAL_ESTIMATIVA: {
      return 2;
    }
    case REGIME_TRIBUTARIO_ESPECIAL_SOCIEDADE_DE_PROFISSIONAIS: {
      return 3;
    }
    case REGIME_TRIBUTARIO_ESPECIAL_COOPERATIVA: {
      return 4;
    }
    case REGIME_TRIBUTARIO_ESPECIAL_MICROEMPRESARIO_INDIVIDUAL_MEI: {
      return 5;
    }
    case REGIME_TRIBUTARIO_ESPECIAL_MICROEMPRESA_PEQUENO_PORTE_ME_EPP: {
      return 6;
    }
    default: {
      return 0;
    }
  }
};

const getTipoRegimeTributarioValue = (tipoRegimeTributario) => {
  switch (tipoRegimeTributario) {
    case REGIME_TRIBUTARIO_NENHUM: {
      return 0;
    }
    case REGIME_TRIBUTARIO_SIMPLES_NACIONAL: {
      return 1;
    }
    case REGIME_TRIBUTARIO_SIMPLES_NACIONAL_EXCESSO: {
      return 2;
    }
    case REGIME_TRIBUTARIO_NORMAL_LUCRO_PRESUMIDO: {
      return 3;
    }
    case REGIME_TRIBUTARIO_NORMAL_LUCRO_REAL: {
      return 4;
    }
    default: {
      return 0;
    }
  }
};
export {
  REGIME_TRIBUTARIO_LIST,
  REGIME_TRIBUTARIO_ESPECIAL_LIST,
  getTipoRegimeTributarioValue,
  getTipoRegimeTributarioEmpresaValue,
};
