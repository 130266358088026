import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Debug } from 'mui-rff';

const useStyles = makeStyles(() => ({
  pre: {
    position: 'relative',
    border: '1px solid #ccc',
    background: 'rgba(0, 0, 0, 0.1)',
    boxShadow: 'inset 1px 1px 3px rgba(0, 0, 0, 0.2)',
    padding: '20px',
  },
}));

export default function QCXDebug({
  on = false,
}) {
  const classes = useStyles();

  return on && (
    <pre className={classes.pre}>
      <Debug />
    </pre>
  );
}
