const selectDadosEmbarques = (state) => state.dadosEmbarqueImportacao.list;
const selectDadosEmbarque = (state) => state.dadosEmbarqueImportacao.model;
const selectRelatedReportModel = (state) => state.dadosEmbarqueImportacao.related.model.report;
const selectRelatedItemModel = (state) => state.dadosEmbarqueImportacao.related.model.item;
const selectRelatedAdicaoModel = (state) => state.dadosEmbarqueImportacao.related.model.adicao;
const selectRelatedCalculoModel = (state) => (
  state.dadosEmbarqueImportacao.related.model.calculo
);
const selectRelatedDuplicataModel = (state) => (
  state.dadosEmbarqueImportacao.related.model.duplicata
);
const selectRelatedRegistroModel = (state) => (
  state.dadosEmbarqueImportacao.related.model.registro
);
const selectRelatedEmissaoIcmsModel = (state) => (
  state.dadosEmbarqueImportacao.related.model.emissaoIcms
);
const selectHistoryModel = (state) => state.dadosEmbarqueImportacao.historyModel;
const selectStatus = (state) => state.dadosEmbarqueImportacao.status;
const selectRefresh = (state) => state.dadosEmbarqueImportacao.refresh;
const selectMode = (state) => state.dadosEmbarqueImportacao.mode.main;
const selectBackgroundMode = (state) => state.dadosEmbarqueImportacao.mode.background;
const selectSubMode = (state) => state.dadosEmbarqueImportacao.mode.sub;
const selectSubBackgroundMode = (state) => state.dadosEmbarqueImportacao.mode.subBackground;
const selectError = (state) => state.dadosEmbarqueImportacao.error;
const selectResponse = (state) => state.dadosEmbarqueImportacao.response;
const selectConditionalSteps = (state) => (
  state.dadosEmbarqueImportacao.config.conditionalSteps
);

const DadosEmbarqueSelectors = {
  selectDadosEmbarques,
  selectDadosEmbarque,
  selectRelatedReportModel,
  selectRelatedItemModel,
  selectRelatedAdicaoModel,
  selectRelatedCalculoModel,
  selectRelatedDuplicataModel,
  selectRelatedRegistroModel,
  selectRelatedEmissaoIcmsModel,
  selectHistoryModel,
  selectStatus,
  selectRefresh,
  selectMode,
  selectBackgroundMode,
  selectSubMode,
  selectSubBackgroundMode,
  selectError,
  selectResponse,
  selectConditionalSteps,
};

export {
  DadosEmbarqueSelectors,
  selectDadosEmbarques,
  selectDadosEmbarque,
  selectRelatedReportModel,
  selectRelatedItemModel,
  selectRelatedAdicaoModel,
  selectRelatedCalculoModel,
  selectRelatedDuplicataModel,
  selectRelatedRegistroModel,
  selectRelatedEmissaoIcmsModel,
  selectHistoryModel,
  selectStatus,
  selectRefresh,
  selectMode,
  selectBackgroundMode,
  selectSubMode,
  selectSubBackgroundMode,
  selectError,
  selectResponse,
  selectConditionalSteps,
};
