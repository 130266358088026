import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash';
import QCXModeloFaturamentoTecnicalDataForm from './QCXModeloFaturamentoTecnicalDataForm';
import QCXModeloFaturamentoAgendaForm from './QCXModeloFaturamentoAgendaForm';
import QCXModeloLancamentoFinalFormManager from '../modelo-lancamento/QCXModeloLancamentoFinalFormManager';
import QCXFinalFormWizard from '../../shared-components/final-form-wizard/QCXFinalFormWizard';
import {
  addModeloLancamento,
  changeToSubConsultMode,
  changeToSubCreateMode,
  changeToSubUpdateMode,
  lockSelectedModeloLancamento,
  removeModeloLancamentoById,
  resetLockedModeloLancamentoModel,
  resetRelatedModeloLancamentoModel,
  resetSubMode,
  selectConditionalSteps,
  selectRelatedModeloLancamentoModel,
  selectSubMode,
  setRelatedModeloLancamentoModel,
  updateModeloLancamento,
} from '../../features/modelo-faturamento/modeloFaturamentoSlice';
import {
  isNoneSubMode,
  isSubConsultMode,
  isSubCreateMode,
  isSubUpdateMode,
} from '../../utils/store/store-utils';
import { forceUnnormalizeNumeral, isValid, normalizeNumeral } from '../../utils/general/general-utils';
import { formatBrazilianNumericDecimal } from '../../utils/hooks/form/field/formatters';

export default function QCXModeloFaturamentoWizardFinalForm({
  handleSubmit,
  handleAlternativeSave,
  handleChangeToPreparingAction,
  model,
  handleChangeModel,
  handleChangeToCreate,
  handleChangeToConsult,
  handleChangeToUpdate,
  handleCancelUpdate,
  handleChangeToBackgroundCreate,
  handleResetBackgroundMode,
  isCreate,
  isConsult,
  isUpdate,
  isBackgroundCreate,
  isPreparingAction,
  authInfo = {},
  requiredRoles = [],
  ...restProps
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const modeloLancamento = useSelector(selectRelatedModeloLancamentoModel);
  const subMode = useSelector(selectSubMode);
  const conditionalSteps = useSelector(selectConditionalSteps);

  const [isFaturamentoAutomatico, setIsFaturamentoAutomatico] = useState(false);

  const isSubNone = useMemo(() => (
    isNoneSubMode(subMode)
  ), [subMode]);

  const isSubCreate = useMemo(() => (
    isSubCreateMode(subMode)
  ), [subMode]);

  const isSubConsult = useMemo(() => (
    isSubConsultMode(subMode)
  ), [subMode]);

  const isSubUpdate = useMemo(() => (
    isSubUpdateMode(subMode)
  ), [subMode]);

  const checkIfIsAlternativeSaveByStep = (currentStep) => (
    currentStep === 2
  );

  useEffect(() => {
    setIsFaturamentoAutomatico(model?.automatico);
  }, [model]);

  const normalizeModeloLancamento = (data) => (
    cloneDeep({
      ...data,
      despesaReceita: data?.despesaReceita,
      moeda: data?.moeda,
      tipoCalculo: data?.tipoCalculo,
      valor: normalizeNumeral(data?.valor),
      tipo: data?.tipo,
    })
  );

  const unnormalizeModeloLancamento = (data) => ({
    ...data,
    despesaReceita: data?.despesaReceita,
    moeda: data?.moeda,
    tipoCalculo: data?.tipoCalculo,
    valor: isValid(data?.valor)
      ? forceUnnormalizeNumeral(
        data?.valor,
        formatBrazilianNumericDecimal(2)
      )
      : undefined,
    tipo: data?.tipo,
  });

  const handleNewModeloLancamento = () => {
    dispatch(changeToSubCreateMode());
  };

  const handleCancelModeloLancamentoCreate = () => {
    dispatch(resetSubMode());
  };

  const handleCancelModeloLancamentoUpdate = () => {
    dispatch(changeToSubConsultMode());
    dispatch(resetLockedModeloLancamentoModel());
  };

  const handleEditModeloLancamento = () => {
    dispatch(changeToSubUpdateMode());
    dispatch(lockSelectedModeloLancamento());
  };

  const handleClearModeloLancamentoForm = () => {
    dispatch(resetLockedModeloLancamentoModel());
    dispatch(resetRelatedModeloLancamentoModel());
    dispatch(resetSubMode());
  };

  const handleRemoveModeloLancamento = () => {
    dispatch(removeModeloLancamentoById({ data: modeloLancamento }));
    handleClearModeloLancamentoForm();
  };

  const handleModelosLancamentoRowClick = ({ row }) => {
    if (!isSubUpdate && !isSubCreate && (modeloLancamento?.id !== row?.id)) {
      dispatch(setRelatedModeloLancamentoModel(row));
      dispatch(changeToSubConsultMode());
      dispatch(resetLockedModeloLancamentoModel());
    }
  };

  const handleModeloLancamentoSubmit = (data) => {
    const normalizedData = normalizeModeloLancamento(data);

    if (isSubCreate) {
      dispatch(addModeloLancamento({ data: normalizedData }));
    } else if (isSubUpdate) {
      dispatch(updateModeloLancamento({ data: normalizedData }));
    }
  };

  const modeloLancamentoModel = useMemo(() => (
    unnormalizeModeloLancamento(modeloLancamento)
  ), [unnormalizeModeloLancamento, modeloLancamento]);

  const modeloLancamentoList = useMemo(() => (
    model?.modelosLancamento
  ), [model]);

  const wizardSteps = useMemo(() => ([
    t('com.muralis.qcx.dadosTecnicos'),
    t('com.muralis.qcx.agenda'),
    t('com.muralis.qcx.despesa.despesasReceitas'),
  ]), []);

  const handleFaturamentoAutomaticoChange = useCallback((value) => (
    setIsFaturamentoAutomatico(value)
  ), [setIsFaturamentoAutomatico]);

  const wizardConditionalSteps = useMemo(() => (
    isFaturamentoAutomatico
      ? []
      : conditionalSteps
  ), [isFaturamentoAutomatico, conditionalSteps]);

  return (
    <QCXFinalFormWizard
      steps={wizardSteps}
      conditionalSteps={wizardConditionalSteps}
      initialValues={model}
      handleChangeValues={handleChangeModel}
      isCreate={isCreate}
      isConsult={isConsult}
      isUpdate={isUpdate}
      isBackgroundCreate={isBackgroundCreate}
      isSubNone={isSubNone}
      isSubCreate={isSubCreate}
      isSubConsult={isSubConsult}
      isSubUpdate={isSubUpdate}
      handleSubmit={handleSubmit}
      checkIfIsAlternativeSaveByStep={checkIfIsAlternativeSaveByStep}
      handleAlternativeSave={handleAlternativeSave}
      handleChangeToCreate={handleChangeToCreate}
      handleChangeToConsult={handleChangeToConsult}
      handleChangeToUpdate={handleChangeToUpdate}
      handleCancelUpdate={handleCancelUpdate}
      handleChangeToBackgroundCreate={handleChangeToBackgroundCreate}
      handleResetBackgroundMode={handleResetBackgroundMode}
      {...restProps}
    >
      {(formProps) => (
        <QCXModeloFaturamentoTecnicalDataForm
          key="dados-tecnico-modelo-faturamento"
          isCreate={isCreate}
          isConsult={isConsult}
          isUpdate={isUpdate}
          handleAutomaticoChange={handleFaturamentoAutomaticoChange}
          authInfo={authInfo}
          requiredRoles={requiredRoles}
          {...formProps}
        />
      )}
      {(formProps) => (
        <QCXModeloFaturamentoAgendaForm
          key="agenda-modelo-faturamento"
          isCreate={isCreate}
          isConsult={isConsult}
          isUpdate={isUpdate}
          authInfo={authInfo}
          requiredRoles={requiredRoles}
          {...formProps}
        />
      )}
      {({ controlComponentProps }) => (
        <QCXModeloLancamentoFinalFormManager
          key="modelo-lancamento-modelo-faturamento"
          isCreate={isCreate}
          isConsult={isConsult}
          isUpdate={isUpdate}
          isSubNone={isSubNone}
          isSubCreate={isSubCreate}
          isSubConsult={isSubConsult}
          isSubUpdate={isSubUpdate}
          model={modeloLancamentoModel}
          list={modeloLancamentoList}
          handleSubmit={handleModeloLancamentoSubmit}
          handleNew={handleNewModeloLancamento}
          handleCancelCreate={handleCancelModeloLancamentoCreate}
          handleCancelUpdate={handleCancelModeloLancamentoUpdate}
          handleRemove={handleRemoveModeloLancamento}
          handleRowClick={handleModelosLancamentoRowClick}
          handleEdit={handleEditModeloLancamento}
          handleClearForm={handleClearModeloLancamentoForm}
          controlComponentProps={controlComponentProps}
          authInfo={authInfo}
          requiredRoles={requiredRoles}
        />
      )}
    </QCXFinalFormWizard>
  );
}
