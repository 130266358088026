import { isArray, isEmpty } from 'lodash';
import { isStrictEquals } from '../general-utils';

const ICMS_DIFERIDO = 'DIFERIDO';
const ICMS_NAO_DIFERIDO = 'NAO_DIFERIDO';

const DANFE_FILHOTE = 'FILHOTE';
const DANFE_MAE = 'ENTRADA';
const DANFE_COMPLEMENTAR = 'COMPLEMENTAR';

const DANFE_EMITIDA = 'EMITIDA';
const DANFE_NAO_EMITIDA = 'NAO_EMITIDA';
const DANFE_ASSINADA = 'ASSINADA';
const DANFE_TRANSMITIDA = 'TRANSMITIDA';

const TIPO_CONTA_DANFE_DANFE_FILHOTE = 'DANFE_DANFE_FILHOTE';

const isIcmsDiferido = (type) => {
  const value = isArray(type) ? type[0] : type;
  return isStrictEquals(ICMS_DIFERIDO, value);
};

const isDanfeMae = (value) => (
  isStrictEquals(DANFE_MAE, value)
);

const isDanfeFilhote = (value) => (
  isStrictEquals(DANFE_FILHOTE, value)
);

const isDanfeEmitida = (value) => (
  isStrictEquals(DANFE_EMITIDA, value)
);

const isDanfeNaoEmitida = (value) => (
  isStrictEquals(DANFE_NAO_EMITIDA, value)
);

const normalizeIcmsDiferido = (type) => {
  if (isIcmsDiferido(type)) {
    return ICMS_DIFERIDO;
  }

  return ICMS_NAO_DIFERIDO;
};

const unnormalizeIcmsDiferido = (type) => {
  if (isIcmsDiferido(type)) {
    return [ICMS_DIFERIDO];
  }

  return [];
};

const criaSolicitacaoSaldoItens = (itens) => (
  (itens || [])
    ?.map((item) => ({
      tipo: {
        description: TIPO_CONTA_DANFE_DANFE_FILHOTE,
      },
      proprietario: {
        id: item?.id,
      },
    }))
);

const isFilhote = (danfe) => (
  isDanfeFilhote(danfe?.tipoDanfe)
);

const hasFilhotes = (danfe) => (
  !isEmpty(danfe?.filhotes)
);

export {
  ICMS_DIFERIDO,
  ICMS_NAO_DIFERIDO,
  DANFE_MAE,
  DANFE_FILHOTE,
  DANFE_COMPLEMENTAR,
  DANFE_EMITIDA,
  DANFE_NAO_EMITIDA,
  DANFE_ASSINADA,
  DANFE_TRANSMITIDA,
  TIPO_CONTA_DANFE_DANFE_FILHOTE,
  isIcmsDiferido,
  normalizeIcmsDiferido,
  unnormalizeIcmsDiferido,
  isDanfeMae,
  isDanfeFilhote,
  isDanfeEmitida,
  isDanfeNaoEmitida,
  criaSolicitacaoSaldoItens,
  isFilhote,
  hasFilhotes,
};
