import { isStrictEquals } from '../../utils/general/general-utils';

const DOCUMENTO_INSTRUCAO_CONHECIMENTO_CARGA = '28';

const isSomeDocumentoInstrucaoByCode = (code, codesToCompare = []) => (
  codesToCompare.includes(code)
);

const isDocumentoInstrucaoConhecimentoCargaByCode = (code) => (
  isStrictEquals(code, DOCUMENTO_INSTRUCAO_CONHECIMENTO_CARGA)
);

export {
  DOCUMENTO_INSTRUCAO_CONHECIMENTO_CARGA,
};

export {
  isSomeDocumentoInstrucaoByCode,
  isDocumentoInstrucaoConhecimentoCargaByCode,
};
