import { createSlice } from '@reduxjs/toolkit';
import {
  BACKGROUND_CONSULT_MODE,
  BACKGROUND_CREATE_MODE,
  BACKGROUND_DELETE_MODE,
  BACKGROUND_UPDATE_MODE,
  CONSULT_MODE,
  CREATE_MODE,
  LOCKED_MODE,
  NONE_BACKGROUND_MODE,
  NONE_MODE,
  NONE_SUB_BACKGROUND_MODE,
  NONE_SUB_MODE,
  SUB_BACKGROUND_CONSULT_MODE,
  SUB_BACKGROUND_CREATE_MODE,
  SUB_BACKGROUND_DELETE_MODE,
  SUB_BACKGROUND_UPDATE_MODE,
  SUB_CONSULT_MODE,
  SUB_CREATE_MODE,
  SUB_UPDATE_MODE,
  UPDATE_MODE,
} from '../../../../../features/mode';
import { NO_REFRESH, REFRESH } from '../../../../../features/refresh';
import {
  ALTERNATIVE_LOADING_STATUS,
  FAILURE_STATUS,
  IDLE_STATUS,
  LOADING_STATUS,
  PREPARING_ACTION_STATUS,
  SUCCESS_STATUS,
} from '../../../../../features/status';
import i18n from '../../../../../i18n';
import { activateByIdAsync, fetchAllAsync, fetchByIdAsync, inactivateByIdAsync } from './custeiosThunks';

const initialState = {
  status: IDLE_STATUS,
  mode: {
    main: NONE_MODE,
    background: NONE_BACKGROUND_MODE,
    sub: NONE_SUB_MODE,
    subBackground: NONE_SUB_BACKGROUND_MODE,
  },
  refresh: NO_REFRESH,
  error: null as {
    message: string;
    name?: string | undefined;
    stack?: string | undefined;
    code?: string | undefined | null;
  } | null,
  response: {
    status: null,
    message: null,
    data: null,
  },
  related: {
    model: {
      report: {},
      item: {},
      adicao: {},
      calculo: {},
      duplicata: {},
      registro: {},
      emissaoIcms: {},
    },
  },
  list: [] as any[],
  model: {},
};

const custeiosImportacaoSlice = createSlice({
  name: 'custeiosImportacao',
  initialState,
  reducers: {
    changeStatusTo: (state, action) => {
      state.status = action.payload.status;
    },
    loading: (state) => {
      state.status = LOADING_STATUS;
    },
    success: (state) => {
      state.status = SUCCESS_STATUS;
    },
    failure: (state) => {
      state.status = FAILURE_STATUS;
    },
    preparingAction: (state) => {
      state.status = PREPARING_ACTION_STATUS;
    },
    resetStatus: (state) => {
      state.status = IDLE_STATUS;
    },
    refresh: (state) => {
      state.refresh = REFRESH;
    },
    resetRefresh: (state) => {
      state.refresh = NO_REFRESH;
    },
    changeToCreateMode: (state) => {
      state.mode.main = CREATE_MODE;
    },
    changeToConsultMode: (state) => {
      state.mode.main = CONSULT_MODE;
    },
    changeToUpdateMode: (state) => {
      state.mode.main = UPDATE_MODE;
    },
    changeToLockMode: (state) => {
      state.mode.main = LOCKED_MODE;
    },
    resetMode: (state) => {
      state.mode.main = NONE_MODE;
    },
    changeToBackgroundCreateMode: (state) => {
      state.mode.background = BACKGROUND_CREATE_MODE;
    },
    changeToBackgroundConsultMode: (state) => {
      state.mode.background = BACKGROUND_CONSULT_MODE;
    },
    changeToBackgroundUpdateMode: (state) => {
      state.mode.background = BACKGROUND_UPDATE_MODE;
    },
    changeToBackgroundDeleteMode: (state) => {
      state.mode.background = BACKGROUND_DELETE_MODE;
    },
    resetBackgroundMode: (state) => {
      state.mode.background = NONE_BACKGROUND_MODE;
    },
    changeToSubCreateMode: (state) => {
      state.mode.sub = SUB_CREATE_MODE;
    },
    changeToSubConsultMode: (state) => {
      state.mode.sub = SUB_CONSULT_MODE;
    },
    changeToSubUpdateMode: (state) => {
      state.mode.sub = SUB_UPDATE_MODE;
    },
    resetSubMode: (state) => {
      state.mode.sub = NONE_SUB_MODE;
    },
    changeToSubBackgroundCreateMode: (state) => {
      state.mode.subBackground = SUB_BACKGROUND_CREATE_MODE;
    },
    changeToSubBackgroundConsultMode: (state) => {
      state.mode.subBackground = SUB_BACKGROUND_CONSULT_MODE;
    },
    changeToSubBackgroundUpdateMode: (state) => {
      state.mode.subBackground = SUB_BACKGROUND_UPDATE_MODE;
    },
    changeToSubBackgroundDeleteMode: (state) => {
      state.mode.subBackground = SUB_BACKGROUND_DELETE_MODE;
    },
    resetSubBackgroundMode: (state) => {
      state.mode.subBackground = NONE_SUB_BACKGROUND_MODE;
    },
    setModel: (state, action) => {
      state.model = {
        ...action.payload,
      };
    },
    resetModel: (state) => {
      state.model = initialState.model;
    },
    setRelatedItemModel: (state, action) => {
      state.related.model.item = {
        ...action.payload,
      };
    },
    resetRelatedItemModel: (state) => {
      state.related.model.item = initialState.related.model.item;
    },
    addToList: (state, action) => {
      state.list = [...state.list, action.payload.data];
    },
    updateOnList: (state, action) => {
      state.list = state.list.map((current) => {
        if (current.id === action.payload.data?.id) {
          return action.payload.data;
        }
        return current;
      });
    },
    setResponse: (state, action) => {
      state.response = action.payload;
    },
    clearResponse: (state) => {
      state.response = {
        status: null,
        message: null,
        data: null,
      };
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    clearError: (state) => {
      state.error = initialState.error;
    },
    setRelatedAdicaoModel: (state, action) => {
      state.related.model.adicao = {
        ...action.payload,
      };
    },
    resetRelatedAdicaoModel: (state) => {
      state.related.model.adicao = initialState.related.model.adicao;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllAsync.pending, (state) => {
        state.status = ALTERNATIVE_LOADING_STATUS;
      })
      .addCase(fetchAllAsync.fulfilled, (state, action) => {
        state.list = action.payload.data;
        state.status = IDLE_STATUS;
        state.refresh = NO_REFRESH;
      })
      .addCase(fetchAllAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroCarregarListaFollowUp', {
            mensagem: action.error.message,
          }),
        };
        state.refresh = NO_REFRESH;
      })
      .addCase(fetchByIdAsync.pending, (state) => {
        state.status = ALTERNATIVE_LOADING_STATUS;
      })
      .addCase(fetchByIdAsync.fulfilled, (state, action) => {
        state.model = action.payload.response.data;
        state.status = IDLE_STATUS;
        state.refresh = NO_REFRESH;
      })
      .addCase(fetchByIdAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroCarregarListaFollowUp', {
            mensagem: action.error.message,
          }),
        };
        state.refresh = NO_REFRESH;
      })
      .addCase(activateByIdAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(activateByIdAsync.fulfilled, (state, action) => {
        state.status = SUCCESS_STATUS;
        state.response.status = action.payload.response.status;
        state.response.data = action.payload.response.data;

        const { atributosAdicionais } = action.payload.response.data;
        state.response.message = i18n.t('com.muralis.qcx.mensagem.DIDUIMPAtivada', {
          atributo: atributosAdicionais?.processo,
        });

        if (state.response.status === 200) {
          state.list = state.list.map((current) =>
            current.id === action.payload.response.data.id ? action.payload.response.data : current
          );
        }
      })
      .addCase(activateByIdAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroTentarAtivarDIDUIMP', {
            mensagem: action.error.message,
          }),
        };
      })
      .addCase(inactivateByIdAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(inactivateByIdAsync.fulfilled, (state, action) => {
        state.status = SUCCESS_STATUS;
        state.response.status = action.payload.response.status;
        state.response.data = action.payload.response.data;

        const { atributosAdicionais } = action.payload.response.data;
        state.response.message = i18n.t('com.muralis.qcx.mensagem.DIDUIMPExcluida', {
          atributo: atributosAdicionais?.processo,
        });

        if (state.response.status === 200) {
          state.list = state.list.map((current) =>
            current.id === action.payload.response.data.id ? action.payload.response.data : current
          );
        }
      })
      .addCase(inactivateByIdAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroExcluirDIDUIMP', {
            mensagem: action.error.message,
          }),
        };
      });
  },
});

const {
  changeStatusTo,
  loading,
  success,
  failure,
  preparingAction,
  resetStatus,
  refresh,
  resetRefresh,
  changeToCreateMode,
  changeToConsultMode,
  changeToUpdateMode,
  changeToLockMode,
  resetMode,
  changeToBackgroundCreateMode,
  changeToBackgroundConsultMode,
  changeToBackgroundUpdateMode,
  changeToBackgroundDeleteMode,
  resetBackgroundMode,
  changeToSubConsultMode,
  changeToSubUpdateMode,
  changeToSubCreateMode,
  resetSubMode,
  changeToSubBackgroundCreateMode,
  changeToSubBackgroundConsultMode,
  changeToSubBackgroundUpdateMode,
  changeToSubBackgroundDeleteMode,
  resetSubBackgroundMode,
  setModel,
  resetModel,
  addToList,
  updateOnList,
  setResponse,
  clearResponse,
  setError,
  clearError,
  setRelatedAdicaoModel,
  resetRelatedAdicaoModel,
  setRelatedItemModel,
} = custeiosImportacaoSlice.actions;

const custeiosImportacaoActions = custeiosImportacaoSlice.actions;

export {
  addToList,
  changeStatusTo,
  changeToBackgroundConsultMode,
  changeToBackgroundCreateMode,
  changeToBackgroundDeleteMode,
  changeToBackgroundUpdateMode,
  changeToConsultMode,
  changeToCreateMode,
  changeToLockMode,
  changeToSubBackgroundConsultMode,
  changeToSubBackgroundCreateMode,
  changeToSubBackgroundDeleteMode,
  changeToSubBackgroundUpdateMode,
  changeToSubConsultMode,
  changeToSubCreateMode,
  changeToSubUpdateMode,
  changeToUpdateMode,
  clearError,
  clearResponse,
  custeiosImportacaoActions,
  custeiosImportacaoSlice,
  failure,
  loading,
  preparingAction,
  refresh,
  resetBackgroundMode,
  resetMode,
  resetModel,
  resetRefresh,
  resetStatus,
  resetSubBackgroundMode,
  resetSubMode,
  setError,
  setModel,
  setResponse,
  success,
  updateOnList,
  setRelatedAdicaoModel,
  resetRelatedAdicaoModel,
  setRelatedItemModel,
};

export default custeiosImportacaoSlice.reducer;
