import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setFollowups } from '../../features/perfil/perfilSlice';
import QCXFinalFormWizard from '../../shared-components/final-form-wizard/QCXFinalFormWizard';
import QCXModulosAcessoPerfilForm from './QCXModulosAcessoPerfilForm';
import QCXModulosAcessoPermissaoForm from './QCXModulosAcessoPermissaoForm';

export default function QCXPerfilWizardFinalForm({
  handleSubmit,
  handleAlternativeSave,
  handleChangeToPreparingAction,
  model,
  handleChangeModel,
  handleChangeToCreate,
  handleChangeToConsult,
  handleChangeToUpdate,
  handleCancelUpdate,
  handleChangeToBackgroundCreate,
  handleResetBackgroundMode,
  isCreate,
  isConsult,
  isUpdate,
  isBackgroundCreate,
  isPreparingAction,
  authInfo = {},
  requiredRoles = [],
  ...restProps
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleSetSelectedUnidadesNegocio = (form) => (data) => {
    form.change('atributos.unidadesDeNegocio[]', data);
  };
  const handleSetClientes = (form) => (data) => {
    form.change('atributos.clientes[]', data);
  };
  const handleSetFollowups = (data) => {
    dispatch(setFollowups(data));
  };

  const checkIfIsAlternativeSaveByStep = () => (
    false
  );

  const wizardSteps = [
    t('com.muralis.qcx.modulos'),
    t('com.muralis.qcx.acessos'),
  ];

  return (
    <QCXFinalFormWizard
      steps={wizardSteps}
      initialValues={model}
      handleChangeValues={handleChangeModel}
      isCreate={isCreate}
      isConsult={isConsult}
      isUpdate={isUpdate}
      isBackgroundCreate={isBackgroundCreate}
      checkIfIsAlternativeSaveByStep={checkIfIsAlternativeSaveByStep}
      handleSubmit={handleSubmit}
      handleAlternativeSave={handleAlternativeSave}
      handleChangeToCreate={handleChangeToCreate}
      handleChangeToBackgroundCreate={handleChangeToBackgroundCreate}
      handleChangeToConsult={handleChangeToConsult}
      handleChangeToUpdate={handleChangeToUpdate}
      handleCancelUpdate={handleCancelUpdate}
      handleResetBackgroundMode={handleResetBackgroundMode}
      {...restProps}
    >
      {({ initialValues, ...formProps }) => (
        <QCXModulosAcessoPerfilForm
          key="ficha-modulos-perfil"
          isCreate={isCreate}
          isConsult={isConsult}
          isUpdate={isUpdate}
          authInfo={authInfo}
          requiredRoles={requiredRoles}
          initialValues={initialValues}
          {...formProps}
        />
      )}
      {({ controlComponentProps, ...restFormProps }) => (
        <QCXModulosAcessoPermissaoForm
          key="ficha-acessos-perfil"
          isConsult={isConsult}
          isCreate={isCreate}
          model={model}
          handleChangeUnidadeNegocio={handleSetSelectedUnidadesNegocio}
          handleChangeClientes={handleSetClientes}
          handleChangeFollowups={handleSetFollowups}
          controlComponentProps={controlComponentProps}
          isUpdate={isUpdate}
          authInfo={authInfo}
          requiredRoles={requiredRoles}
          {...restFormProps}
        />
      )}
    </QCXFinalFormWizard>
  );
}
