import { getOptionByValueInList } from '../../utils/general/general-utils';
import i18n from '../../i18n';

const CARACTERIZACAO_OPERACAO_PROPRIA = 'PROPRIA';
const CARACTERIZACAO_OPERACAO_CONTA_ORDEM = 'CONTA_ORDEM';

const CARACTERIZACAO_OPERACAO_LIST = [
  {
    label: i18n.t('com.muralis.qcx.operacao.propria').toUpperCase(),
    value: CARACTERIZACAO_OPERACAO_PROPRIA,
  },
  {
    label: i18n.t('com.muralis.qcx.operacao.contaOrdem').toUpperCase(),
    value: CARACTERIZACAO_OPERACAO_CONTA_ORDEM,
  },
];

const isCaracterizacaoOperacaoPropria = (value) => (
  CARACTERIZACAO_OPERACAO_PROPRIA === value
);

const isCaracterizacaoOperacaoContaOrdem = (value) => (
  CARACTERIZACAO_OPERACAO_CONTA_ORDEM === value
);

const getCaracterizacaoOperacaoLabelByValue = (value) => {
  const foundOption = getOptionByValueInList(CARACTERIZACAO_OPERACAO_LIST, value);
  return foundOption && foundOption?.label;
};

export {
  CARACTERIZACAO_OPERACAO_PROPRIA,
  CARACTERIZACAO_OPERACAO_CONTA_ORDEM,
  CARACTERIZACAO_OPERACAO_LIST,
};

export {
  isCaracterizacaoOperacaoPropria,
  isCaracterizacaoOperacaoContaOrdem,
  getCaracterizacaoOperacaoLabelByValue,
};
