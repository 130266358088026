import React, { useMemo, useCallback } from 'react';
import {
  Grid,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useValidators from '../../utils/hooks/useValidators';
import {
  formatTelefone,
} from '../../utils/hooks/form/field/formatters';
import {
  numberOnly,
  parseTelefone,
} from '../../utils/hooks/form/field/parsers';
import {
  required as requiredValidator,
  validTelefoneLength,
} from '../../utils/validators/field/validator';
import { MediaType } from '../../utils/api/api-utils';
import QCXFormSubtitle from '../form-title/QCXFormSubtitle';
import QCXFinalCertificateInputFile from '../../shared-components/input-file/QCXFinalCertificateInputFile';
import QCXFinalTextField from '../../shared-components/final-text-field/QCXFinalTextField';
import QCXFinalOnblurMaskedField from '../../shared-components/final-onblur-masked-field/QCXFinalOnblurMaskedField';
import QCXSelectRegimeTributarioAutocomplete from '../../shared-components/select-regime-tributario/QCXSelectRegimeTributarioAutocomplete';
import QCXSelectRegimeTributarioEspecialAutocomplete from '../../shared-components/select-regime-tributario-especial/QCXSelectRegimeTributarioEspecial';
import QCXFinalMultilineTextField from '../../shared-components/final-text-field/QCXFinalMultilineTextField';
import QCXFinalCustomInputFile from '../../shared-components/input-file/QCXFinalCustomInputFile';
import QCXSelectOptanteSimplesNacionalAutocomplete from '../../shared-components/select-optante-simples-nacional/QCXSelectOptanteSimplesNacional';

export default function QCXUnidadeNegocioNfseForm({
  isConsult,
  initialValues,
}) {
  const { t } = useTranslation();

  const telefoneValidators = useMemo(() => (
    useValidators([
      requiredValidator,
      validTelefoneLength,
    ])
  ), []);

  const fileGetter = useCallback((values) => {
    if (!values?.id && values?.length > 0) {
      return values[0];
    }

    return values;
  }, []);

  const fileNameGetter = useCallback((file) => {
    if (file?.id) {
      return file?.nome;
    }

    return file?.name;
  }, []);

  return (
    <>
      <Grid
        item
        style={{
          paddingTop: '2rem',
          paddingBottom: '1rem',
        }}
        xs={12}
      >
        <QCXFormSubtitle
          title={t('com.muralis.qcx.NFSE.label')}
        />
      </Grid>
      <Grid
        container
        item
        xs={12}
        spacing={2}
      >
        <Grid
          item
          xs={12}
          md={6}
        >
          <QCXFinalCertificateInputFile
            id="upload-certificado-a1"
            key="upload-path-certificado-a1"
            inputId="input-certificado-a1"
            label={t('com.muralis.qcx.NFSE.certificadoNFSe')}
            name="certificadoA1"
            accept={MediaType.APPLICATION_X_PKCS12}
            fileGetter={fileGetter}
            fileNameGetter={fileNameGetter}
            disableRemoveControl
            disabled={isConsult}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
        >
          <QCXFinalTextField
            id="textfield-certificado-a1-senha"
            key="textfield-certificado-a1-senha"
            name="senhaCertificadoA1"
            type="password"
            label={t('com.muralis.qcx.NFSE.senhaCertificadoA1Nsfe')}
            maxLength={255}
            disabled={isConsult}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
        >
          <QCXFinalCustomInputFile
            id="upload-logo-nfse"
            key="upload-logo-nfse"
            inputId="input-logo-nfse"
            label={t('com.muralis.qcx.empresa.logoNFSe')}
            name="logo"
            accept={MediaType.IMAGE_JPEG}
            fileGetter={fileGetter}
            fileNameGetter={fileNameGetter}
            disableRemoveControl
            disabled={isConsult}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
        >
          <QCXFinalTextField
            id="text-field-email-recebimento-nfse"
            key="text-field-email-recebimento-nfse"
            label={t('com.muralis.qcx.NFSE.emailRecebimentoNFSe')}
            type="email"
            name="contatoNfe.email"
            maxLength={50}
            disabled={isConsult}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
        >
          <QCXFinalOnblurMaskedField
            id="text-field-contato-telefone"
            key="text-field-contato-telefone"
            label={t('com.muralis.qcx.NFSE.telefoneEmissao')}
            name="contatoNfe.telefone"
            format={formatTelefone}
            parse={parseTelefone}
            validate={telefoneValidators}
            disabled={isConsult}
            required
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
        >
          <QCXSelectRegimeTributarioAutocomplete
            id="select-regime-tributario-autocomplete"
            name="regimeTributario"
            label={t('com.muralis.qcx.regimeTributario.label')}
            initialValues={initialValues}
            disabled={isConsult}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
        >
          <QCXSelectRegimeTributarioEspecialAutocomplete
            id="select-regime-tributario-especial-autocomplete"
            name="regimeTributarioEspecial"
            label={t('com.muralis.qcx.regimeTributarioEspecial.label')}
            initialValues={initialValues}
            disabled={isConsult}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
        >
          <QCXFinalTextField
            id="textfield-codigo-item-lista-servico"
            key="textfield-codigo-item-lista-servico"
            name="codigoServico"
            type="text"
            label={t('com.muralis.qcx.codigoServico')}
            maxLength={255}
            required
            disabled={isConsult}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
        >
          <QCXFinalTextField
            id="text-field-aliquota-ir-retido"
            key="text-field-aliquota-ir-retido"
            label={t('com.muralis.qcx.aliquotaIRretido')}
            name="aliquotaIrrf"
            parse={numberOnly}
            disabled={isConsult}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
        >
          <QCXSelectOptanteSimplesNacionalAutocomplete
            key="checkbox-tipo-field"
            name="simplesNacional"
            label={t('com.muralis.qcx.empresa.optanteSimplesNacional')}
            disabled={isConsult}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          style={{
            paddingTop: '1rem',
          }}
        >
          <QCXFinalMultilineTextField
            id="text-discriminacao-multiline-field"
            key="text-discriminacao-multiline-field"
            name="discriminacaoServico"
            label={t('com.muralis.qcx.detalhamentoServicoPrestado')}
            rows={4}
            maxLength={1000}
            remaningCharactersInfo
            disabled={isConsult}
          />
        </Grid>
        <Grid
          item
          style={{
            paddingTop: '1rem',
            paddingBottom: '1rem',
          }}
          xs={12}
        >
          <QCXFormSubtitle
            title={t('com.muralis.qcx.descricaoServicoRPS')}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          style={{
            paddingTop: '1rem',
          }}
        >
          <QCXFinalMultilineTextField
            id="text-descricao-multiline-field"
            key="text-descricao-multiline-field"
            name="descricaoServicoRps"
            label={t('com.muralis.qcx.descricaoRPSServicosPrestados')}
            helperText={t('com.muralis.qcx.validacao.utilizePipeParaQuebraLinha')}
            rows={4}
            remaningCharactersInfo
            disabled={isConsult}
          />
        </Grid>
      </Grid>
    </>
  );
}
