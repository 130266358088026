import React, { useMemo } from 'react';
import BaseSelectWithOptions from '../base-components/BaseSelectWithOptions';

function TipoCargaSelect({ ...props }) {
  const list = useMemo(() => [
    {
      id: 'Perigosa', label: 'Perigosa', value: 'PERIGOSA',
    }, {
      id: 'Normal', label: 'Normal', value: 'NORMAL',
    }
  ], []);

  return (
    <BaseSelectWithOptions
      list={list}
      {...props}
    />
  );
}

export default TipoCargaSelect;
