import { useTheme } from "@material-ui/core";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { useParams } from "react-router";
import { QueriesTypes } from "../../../../../common/hooks/queries";
import { PropostaFollowUp } from "../../../../../common/types/propostaFollowUp";
import { useAddFollowUp, useEditFollowUp } from "../propostasFollowUp.hook";
import SelectStyled from "../../../../../common/components/input/selectStyled";
import { formatDateISOString } from "../../../../../common/utils/parsers/dateParsers";
import DatePickerStyled from "../../../../../common/components/input/datePickerStyled";

const PropostasFollowUpForm = ({
  clearEditingCallback,
  editingPayload,
  isNewCliente,
  newClientCallback,
}: {
  newClientCallback: (value: boolean) => void;
  clearEditingCallback: () => void;
  editingPayload?: PropostaFollowUp;
  isNewCliente: boolean;
}) => {
  const getDayDate = () =>
    formatDateISOString(new Date().toString(), "YYYY-MM-DD");

  const [inputKey, setInputKey] = useState("input-key");
  const theme = useTheme();
  const params: { id: string } = useParams();

  const [data, setData] = useState<string>(getDayDate);
  const [status, setStatus] = useState<string | null>(null);
  const [alerta, setAlerta] = useState<string | null>(null);
  const [comentario, setComentario] = useState<string | null>(null);

  const clearForm = () => {
    setData(getDayDate());
    setStatus(null);
    setAlerta(null);
    setComentario(null);
    setInputKey("input-key" + Math.random());
  };

  useEffect(() => {
    if (editingPayload) {
      const { data, dataAlerta, status, comentario } = editingPayload;
      setData(data);
      setAlerta(dataAlerta);
      setStatus(status);
      setComentario(comentario);
    } else {
      clearForm();
    }

    return () => {};
  }, [editingPayload]);

  const queryClient = useQueryClient();

  const resetFormInputs = () => {
    clearForm();
    queryClient.invalidateQueries(QueriesTypes.Propostas);
  };

  const resetAfterEditing = () => {
    queryClient.invalidateQueries(QueriesTypes.Propostas);
    clearEditingCallback();
  };

  const [addFollowUp, isCreateLoading] = useAddFollowUp(resetFormInputs);
  const [editFollowUp, isEditLoading] = useEditFollowUp(resetAfterEditing);
  const isLoading = useMemo(
    () => isCreateLoading || isEditLoading,
    [isCreateLoading, isEditLoading]
  );

  useEffect(() => {
    if (status === "Aprovado" && isNewCliente) {
      newClientCallback(true);
    } else {
      newClientCallback(false);
    }
  }, [status]);

  const handleSubmit = () => {
    if (editingPayload) {
      editFollowUp({
        id: editingPayload.id,
        data: new Date(data),
        status,
        dataAlerta: alerta ? new Date(alerta) : null,
        comentario,
        propostaId: params.id,
      });
    } else {
      addFollowUp({
        requestPayload: {
          data: new Date(data),
          status,
          dataAlerta: alerta ? new Date(alerta) : null,
          comentario,
          propostaId: params.id,
        },
      });
    }
  };

  const statusLabels = [
    {
      id: "Solicitado",
      value: "Solicitado",
    },
    {
      id: "Enviado",
      value: "Enviado",
    },
    {
      id: "Aprovado",
      value: "Aprovado",
    },
    {
      id: "Em análise",
      value: "Em análise",
    },
    {
      id: "Stand by",
      value: "Stand by",
    },
    {
      id: "Reprovado",
      value: "Reprovado",
    },
  ];

  return (
    <form>
      <Typography
        textAlign={`left`}
        fontSize={20}
        fontWeight="bold"
        color={theme.palette.primary.main}
        margin={"0 0 10px 0"}
      >
        {"Adicionar Follow-up".toUpperCase()}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <FormControl fullWidth required>
            <DatePickerStyled
              onChange={(date) => setData(date ?? getDayDate())}
              value={data}
              label={"Data*"}
            ></DatePickerStyled>
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <FormControl fullWidth required>
            <SelectStyled
              key={"status-" + inputKey}
              controlledValue={status ? { id: status, value: status } : null}
              options={statusLabels}
              onChangeAction={(e, option) => setStatus(option?.value ?? null)}
              label={"Status"}
              size="small"
            ></SelectStyled>
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <FormControl fullWidth required>
            <DatePickerStyled
              onChange={(date) => setAlerta(date)}
              value={alerta}
              label="Alerta"
            ></DatePickerStyled>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            InputLabelProps={{ shrink: true }}
            key={"comentario-" + inputKey}
            id="outlined-basic"
            label="Comentário"
            variant="outlined"
            value={comentario}
            onChange={(e) => setComentario(e.target.value)}
            fullWidth
            size="small"
          />
        </Grid>
      </Grid>
      <Grid item xs={12} textAlign={"right"}>
        {editingPayload !== undefined && (
          <Button
            variant="contained"
            color="secondary"
            sx={{ marginRight: "10px" }}
            onClick={() => clearEditingCallback()}
          >
            Cancelar
          </Button>
        )}
        <Button
          disabled={
            isLoading ||
            !status ||
            !comentario ||
            (isNewCliente && status === "Aprovado")
          }
          color="secondary"
          onClick={() => handleSubmit()}
          variant="contained"
          style={{ margin: "10px 0" }}
        >
          {editingPayload ? "Salvar" : "Adicionar"}
        </Button>
      </Grid>
    </form>
  );
};

export default PropostasFollowUpForm;
