import { validateQueryParams } from '../../../../../../utils/api/api-utils';
import {
  doGetQCXRequest, doPostQCXRequest, doPutQCXActivateRequest, doPutQCXInactivateRequest, doPutQCXRequest,
} from '../../../../../../utils/api/domain/domain-api-utils';
import { FILTER_ENDPOINT, CAMPOS_ADICIONAIS_ENDPOINT } from '../../../../../../utils/api/domain/domain-endpoint-utils';

export async function fetchAll() {
  return doGetQCXRequest([
    CAMPOS_ADICIONAIS_ENDPOINT
  ]);
}

export async function fetchByFilter(params = []) {
  const validatedParams = validateQueryParams(params);
  if (validatedParams.length) {
    return doGetQCXRequest([
      CAMPOS_ADICIONAIS_ENDPOINT, FILTER_ENDPOINT
    ], validatedParams);
  }
  return { data: [] };
}

export async function fetchById(id) {
  return doGetQCXRequest([
    CAMPOS_ADICIONAIS_ENDPOINT,
    id
  ]);
}

export async function register(data) {
  return doPostQCXRequest(
    [CAMPOS_ADICIONAIS_ENDPOINT],
    data
  );
}

export async function save(data) {
  return doPutQCXRequest(
    [CAMPOS_ADICIONAIS_ENDPOINT],
    data
  );
}

export async function activateById(id) {
  return doPutQCXActivateRequest(
    [CAMPOS_ADICIONAIS_ENDPOINT],
    id
  );
}

export async function inactivateById(id) {
  return doPutQCXInactivateRequest(
    [CAMPOS_ADICIONAIS_ENDPOINT],
    id
  );
}
