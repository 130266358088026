/* eslint-disable @typescript-eslint/no-explicit-any */
import { Li } from 'quickcomex-api-types';
import { useCallback } from 'react';

const useNormalizeLI = () => {
  // Essa funcao está normalizando apenas os campos necessários para o update
  // no futuro adicione outros campos q forem necessarios
  const normalizeLiPartial: (unnormalizedData: any) => Partial<Li> = useCallback((unnormalizedData: any) => {
    const normalizedPartialLi: Partial<Li> = {
      id: unnormalizedData.id,
      informacoesComplementares: unnormalizedData.atributosAdicionais.informacoesComplementares,
    };

    return normalizedPartialLi;
  }, []);

  return normalizeLiPartial;
};

export default useNormalizeLI;
