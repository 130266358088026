import { isStrictEquals, MatcherConfigureUtils } from '../../utils/general/general-utils';

const VIA_TRANSPORTE_IMPORTACAO_FILTER = 'IMPORTACAO';

const VIA_TRANSPORTE_MARITIMA_CODE = '01';
const VIA_TRANSPORTE_FLUVIAL_CODE = '02';
const VIA_TRANSPORTE_LACUSTRE_CODE = '03';
const VIA_TRANSPORTE_AEREA_CODE = '04';
const VIA_TRANSPORTE_POSTAL_CODE = '05';
const VIA_TRANSPORTE_FERROVIARIA_CODE = '06';
const VIA_TRANSPORTE_RODOVIARIO_CODE = '07';
const VIA_TRANSPORTE_CONDUTO_REDE_TRANSMISSAO_CODE = '08';
const VIA_TRANSPORTE_MEIOS_PROPRIOS_CODE = '09';
const VIA_TRANSPORTE_ENTRADA_SAIDA_FICTA_CODE = '10';
const VIA_TRANSPORTE_COURIER_CODE = '11';
const VIA_TRANSPORTE_EM_MAOS_CODE = '12';
const VIA_TRANSPORTE_POR_REBOQUE_CODE = '13';
const VIA_TRANSPORTE_OUTROS_MEIOS_CODE = '11';

const configureSpecificViaTransporteMaritimaMatcher = (
  MatcherConfigureUtils.getSpecificByCode(VIA_TRANSPORTE_MARITIMA_CODE)
);

const configureSpecificViaTransporteFluvialMatcher = (
  MatcherConfigureUtils.getSpecificByCode(VIA_TRANSPORTE_FLUVIAL_CODE)
);

const configureSpecificViaTransporteLacustreMatcher = (
  MatcherConfigureUtils.getSpecificByCode(VIA_TRANSPORTE_LACUSTRE_CODE)
);

const configureSpecificViaTransporteAereaMatcher = (
  MatcherConfigureUtils.getSpecificByCode(VIA_TRANSPORTE_AEREA_CODE)
);

const configureSpecificViaTransportePostalMatcher = (
  MatcherConfigureUtils.getSpecificByCode(VIA_TRANSPORTE_POSTAL_CODE)
);

const configureSpecificViaTransporteFerroviariaMatcher = (
  MatcherConfigureUtils.getSpecificByCode(VIA_TRANSPORTE_FERROVIARIA_CODE)
);

const configureSpecificViaTransporteRodoviarioMatcher = (
  MatcherConfigureUtils.getSpecificByCode(VIA_TRANSPORTE_RODOVIARIO_CODE)
);

const configureSpecificViaTransporteCondutoRedeTransmissaoMatcher = (
  MatcherConfigureUtils.getSpecificByCode(VIA_TRANSPORTE_CONDUTO_REDE_TRANSMISSAO_CODE)
);

const configureSpecificViaTransporteMeiosPropriosMatcher = (
  MatcherConfigureUtils.getSpecificByCode(VIA_TRANSPORTE_MEIOS_PROPRIOS_CODE)
);

const configureSpecificViaTransporteEntradaSaidaFictaMatcher = (
  MatcherConfigureUtils.getSpecificByCode(VIA_TRANSPORTE_ENTRADA_SAIDA_FICTA_CODE)
);

const configureSpecificViaTransporteCourierMatcher = (
  MatcherConfigureUtils.getSpecificByCode(VIA_TRANSPORTE_COURIER_CODE)
);

const configureSpecificViaTransporteEmMaosMatcher = (
  MatcherConfigureUtils.getSpecificByCode(VIA_TRANSPORTE_EM_MAOS_CODE)
);

const configureSpecificViaTransportePorReboqueMatcher = (
  MatcherConfigureUtils.getSpecificByCode(VIA_TRANSPORTE_POR_REBOQUE_CODE)
);

const configureSpecificViaTransporteOutrosMeiosMatcher = (
  MatcherConfigureUtils.getSpecificByCode(VIA_TRANSPORTE_OUTROS_MEIOS_CODE)
);

const isSomeViaTransporteByCode = (code, codesToCompare = []) => (
  codesToCompare.includes(code)
);

const isViaTransporteAereaByCode = (code) => (
  isStrictEquals(code, VIA_TRANSPORTE_AEREA_CODE)
);

export {
  VIA_TRANSPORTE_IMPORTACAO_FILTER,
  VIA_TRANSPORTE_MARITIMA_CODE,
  VIA_TRANSPORTE_FLUVIAL_CODE,
  VIA_TRANSPORTE_LACUSTRE_CODE,
  VIA_TRANSPORTE_AEREA_CODE,
  VIA_TRANSPORTE_POSTAL_CODE,
  VIA_TRANSPORTE_FERROVIARIA_CODE,
  VIA_TRANSPORTE_RODOVIARIO_CODE,
  VIA_TRANSPORTE_CONDUTO_REDE_TRANSMISSAO_CODE,
  VIA_TRANSPORTE_MEIOS_PROPRIOS_CODE,
  VIA_TRANSPORTE_ENTRADA_SAIDA_FICTA_CODE,
  VIA_TRANSPORTE_COURIER_CODE,
  VIA_TRANSPORTE_EM_MAOS_CODE,
  VIA_TRANSPORTE_POR_REBOQUE_CODE,
  VIA_TRANSPORTE_OUTROS_MEIOS_CODE,
};

export {
  configureSpecificViaTransporteMaritimaMatcher,
  configureSpecificViaTransporteFluvialMatcher,
  configureSpecificViaTransporteLacustreMatcher,
  configureSpecificViaTransporteAereaMatcher,
  configureSpecificViaTransportePostalMatcher,
  configureSpecificViaTransporteFerroviariaMatcher,
  configureSpecificViaTransporteRodoviarioMatcher,
  configureSpecificViaTransporteCondutoRedeTransmissaoMatcher,
  configureSpecificViaTransporteMeiosPropriosMatcher,
  configureSpecificViaTransporteEntradaSaidaFictaMatcher,
  configureSpecificViaTransporteCourierMatcher,
  configureSpecificViaTransporteEmMaosMatcher,
  configureSpecificViaTransportePorReboqueMatcher,
  configureSpecificViaTransporteOutrosMeiosMatcher,
  isSomeViaTransporteByCode,
  isViaTransporteAereaByCode,
};
