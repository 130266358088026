import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import QCXNcmSubitemNivel6Form from '../../../../../components/ncm-subitem/QCXNcmSubitemNivel6Form';
import {
  changeToConsultMode,
  fetchByIdAsync,
  selectStatus,
  selectNcmSubitem,
  selectMode,
} from '../../../../../features/ncm-subitem/ncmSubitemSlice';
import QCXRegistrationFormPageTemplate from '../../../../../templates/registration-form-page/QCXRegistrationFormPageTemplate';
import {
  isPreparingActionStatus,
  isConsultMode,
} from '../../../../../utils/store/store-utils';
import i18n from '../../../../../i18n';

export default function NcmSubItemRegistrationPage({ authInfo = {} }) {
  const { id } = useParams();

  const dispatch = useDispatch();

  const status = useSelector(selectStatus);

  const ncmSubitem = useSelector(selectNcmSubitem);

  const mode = useSelector(selectMode);

  const isConsult = useMemo(() => (
    isConsultMode(mode)
  ), [mode]);

  const isPreparingAction = useMemo(() => (
    isPreparingActionStatus(status)
  ), [status]);

  const handleChangeToConsult = () => {
    dispatch(changeToConsultMode());
  };

  const handleCancelUpdate = () => {
    const currentId = ncmSubitem?.id;

    if (currentId) {
      dispatch(fetchByIdAsync(currentId));
    }
  };

  useEffect(() => {
    if (id) {
      dispatch(fetchByIdAsync(id));
      handleChangeToConsult();
    }
  }, [id]);

  const unnormalize = useCallback((data) => data, []);

  const model = useMemo(() => (
    unnormalize(ncmSubitem)
  ), [ncmSubitem, unnormalize]);

  const breadcrumbs = useMemo(() => ([
    {
      link: {
        to: '/',
        name: i18n.t('com.muralis.qcx.inicio'),
      },
    },
    {
      text: {
        name: i18n.t('com.muralis.qcx.cadastros'),
      },
    },
    {
      link: {
        to: i18n.t('com.muralis.qcx.url.moduloOperacionais'),
        name: i18n.t('com.muralis.qcx.operacionais'),
      },
    },
    {
      text: {
        name: i18n.t('com.muralis.qcx.catalogoProdutosMercadoria'),
      },
    },
    {
      link: {
        to: i18n.t('com.muralis.qcx.url.modulosOperacionaisNcmNivelSeis'),
        name: i18n.t('com.muralis.qcx.NCM.NCMnivel6'),
      },
    },
    {
      text: {
        name: i18n.t('com.muralis.qcx.acoes.visualizar'),
      },
    },
  ]), []);

  const breadcrumbsProps = {
    maxItems: 6,
  };

  const pageTitle = i18n.t('com.muralis.qcx.NCM.NCMnivel6Existente', { codigo: ncmSubitem?.code || '-' });

  return (
    <QCXRegistrationFormPageTemplate
      pageTitle={pageTitle}
      showSubtitle={false}
      breadcrumbs={breadcrumbs}
      breadcrumbsProps={breadcrumbsProps}
      isConsult={isConsult}
      isPreparingAction={isPreparingAction}
      handleChangeToConsult={handleChangeToConsult}
      handleCancelUpdate={handleCancelUpdate}
      authInfo={authInfo}
    >
      {
        (formProps) => (
          <QCXNcmSubitemNivel6Form
            initialValues={model}
            authInfo={authInfo}
            requiredRoles={['tabela-armazenagem']}
            isConsult={isConsult}
            {...formProps}
          />
        )
      }
    </QCXRegistrationFormPageTemplate>
  );
}
