import { createSlice } from '@reduxjs/toolkit';
import { LOCKED_FOCUS, UNLOCKED_FOCUS } from '../focus-lock';
import i18n from '../../i18n';

const initialState = {
  mode: UNLOCKED_FOCUS,
  feedback: {
    show: false,
    message: '',
  },
};

const focusLockSlice = createSlice({
  name: 'focusLock',
  initialState,
  reducers: {
    lock: (state) => {
      state.mode = LOCKED_FOCUS;
    },
    unlock: (state) => {
      state.mode = UNLOCKED_FOCUS;
    },
    showLockFeedback: (state) => {
      state.feedback.show = true;
      state.feedback.message = i18n.t('com.muralis.qcx.mensagem.modoFocoTravadoAtivado');
    },
    showUnlockFeedback: (state) => {
      state.feedback.show = true;
      state.feedback.message = i18n.t('com.muralis.qcx.mensagem.modoFocoTravadoDesativado');
    },
    resetFeedback: (state) => {
      state.feedback = initialState.feedback;
    },
  },
});

const {
  lock,
  unlock,
  showLockFeedback,
  showUnlockFeedback,
  resetFeedback,
} = focusLockSlice.actions;

const focusLockActions = focusLockSlice.actions;

const selectMode = (state) => state.focusLock.mode;
const selectFeedback = (state) => state.focusLock.feedback;

const focusLockSelectors = {
  selectMode,
  selectFeedback,
};

export {
  focusLockSlice,
  focusLockActions,
  lock,
  unlock,
  showLockFeedback,
  showUnlockFeedback,
  resetFeedback,
  focusLockSelectors,
  selectMode,
  selectFeedback,
};

export default focusLockSlice.reducer;
