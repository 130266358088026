import { createFilterOptions } from '@material-ui/lab/useAutocomplete';
import _ from 'lodash';
import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
} from 'react';
import { getOptionByValueInList } from '../../utils/general/general-utils';
import QCXAutocompleteSelect from '../autocomplete-select/QCXAutocompleteSelect';
import i18n from '../../i18n';

const list = [
  {
    label: i18n.t('com.muralis.qcx.produtoAmparadoIncisoArtigo').toUpperCase(),
    value: 'PRODUTO_AMPARADO_INCISO_ART_4_DEC_5171_04',
  },
  {
    label: i18n.t('com.muralis.qcx.naoSeAplica').toUpperCase(),
    value: 'NAO_SE_APLICA',
  },
];

export default function QCXFinalSelectDetalhamentoProdutoAutocomplete({
  label,
  name,
  initialValues,
  ...restProps
}) {
  const [defaultValue, setDefaultValue] = useState('');

  useEffect(() => {
    const defaultInitialValue = _.get(initialValues, name);

    const option = list.find((item) => item?.value === defaultInitialValue);

    if (option) {
      setDefaultValue(option);
    } else {
      setDefaultValue('');
    }
  }, [list, initialValues]);

  const filterOptions = useMemo(() => (
    createFilterOptions({
      stringify: (option) => (
        option.label + option.value
      ),
    })
  ), []);

  const getOptionLabel = useCallback((item) => (
    item?.label || ''
  ), []);

  const getOptionValue = useCallback((item) => (
    item?.value || ''
  ), []);

  return (
    <>
      <QCXAutocompleteSelect
        label={label}
        id={name}
        name={name}
        options={list}
        filterOptions={filterOptions}
        defaultValue={defaultValue}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        {...restProps}
      />
    </>
  );
}

function getDetalhamentoProdutoLabelByValue(value) {
  const foundOption = getOptionByValueInList(list, value);
  return foundOption && foundOption?.label;
}

export {
  getDetalhamentoProdutoLabelByValue,
};
