import React, { useMemo } from 'react';
import {
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import QCXDialog from './QCXDialog';
import QCXDialogTitle from './QCXDialogTitle';
import QCXDialogContent from './QCXDialogContent';
import QCXDialogActions from './QCXDialogActions';

const useStyles = makeStyles(() => ({
  base: {
    width: '100% !important',
  },
}));

function QCXCompoundDialog({
  key,
  id,
  classes = {},
  open,
  onConfirm,
  onClose,
  fullWidth,
  maxWidth,
  disableBackdropClick = false,
  disableRestoreFocus = false,
  disableFullScreenOnXs = false,
  children,
  ...restProps
}) {
  const overridedClasses = useStyles();

  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const computedClasses = useMemo(() => ({
    paperFullWidth: overridedClasses.base,
    ...classes,
  }), [
    classes,
    overridedClasses,
  ]);

  const enableFullScreen = useMemo(() => (
    fullScreen && !disableFullScreenOnXs
  ), [
    fullScreen,
    disableFullScreenOnXs,
  ]);

  return (
    <QCXDialog
      key={key}
      id={id}
      classes={computedClasses}
      open={open}
      onConfirm={onConfirm}
      onClose={onClose}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      fullScreen={enableFullScreen}
      disableBackdropClick={disableBackdropClick}
      disableRestoreFocus={disableRestoreFocus}
      {...restProps}
    >
      {children}
    </QCXDialog>
  );
}

QCXCompoundDialog.Title = function ({
  children,
  ...restProps
}) {
  return (
    <QCXDialogTitle
      {...restProps}
    >
      {children}
    </QCXDialogTitle>
  );
};

QCXCompoundDialog.Content = function ({
  classes,
  children,
  ...restProps
}) {
  const overridedClasses = useStyles();

  const computedClasses = useMemo(() => ({
    root: overridedClasses.base,
    ...classes,
  }), [
    classes,
    overridedClasses,
  ]);

  return (
    <QCXDialogContent
      classes={computedClasses}
      {...restProps}
    >
      {children}
    </QCXDialogContent>
  );
};

QCXCompoundDialog.Actions = function ({
  children,
  ...restProps
}) {
  return (
    <QCXDialogActions
      {...restProps}
    >
      {children}
    </QCXDialogActions>
  );
};

export default QCXCompoundDialog;
