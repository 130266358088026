import React from 'react';
import { DialogContentText } from '@material-ui/core';

export default function QCXDialogContentText({
  key,
  id,
  fontWeightBold = false,
  fontStyleItalic = false,
  children,
  ...restProps
}) {
  return (
    <DialogContentText
      key={key}
      id={id}
      {...(
        fontWeightBold || fontStyleItalic
          ? {
            style: {
              ...(fontWeightBold
                ? { fontWeight: 'bold' }
                : {}
              ),
              ...(fontStyleItalic
                ? { fontStyle: 'italic' }
                : {}
              ),
            },
          } : {}
      )}
      {...restProps}
    >
      {children}
    </DialogContentText>
  );
}
