import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash/debounce';
import _, { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  changeToBackgroundCreateMode,
  changeToCreateMode,
  changeToConsultMode,
  changeToUpdateMode,
  preparingAction,
  loading,
  setModel,
  resetModel,
  addToList,
  setResponse,
  failure,
  setError,
  updateOnList,
  resetBackgroundMode,
  success,
} from '../../../../features/solicitacao-pagamento/solicitacaoPagamentoSlice';
import { fetchByIdAsync as fetchById } from '../../../../features/solicitacao-pagamento/solicitacaoPagamentoThunks';
import {
  selectSolicitacaoPagamento,
  selectStatus,
  selectMode,
  selectSubMode,
  selectBackgroundMode,
} from '../../../../features/solicitacao-pagamento/solicitacaoPagamentoSelectors';
import {
  isCreateMode,
  isNoneMode,
  isBackgroundCreateMode,
  isSubUpdateMode,
  isExternalMode,
  isConsultMode,
  isUpdateMode,
  isPreparingActionStatus,
} from '../../../../utils/store/store-utils';
import QCXRegistrationFormPageTemplate from '../../../../templates/registration-form-page/QCXRegistrationFormPageTemplate';
import QCXSolicitacaoPagamentoFinanceiroForm from '../../../../components/solicitacao-de-pagamento/QCXSolicitacaoPagamentoFinanceiroForm';
import useAutoChangeMode from '../../../../utils/hooks/mode/useAutoChangeMode';
import { changeModeTo } from '../../../../features/conta-bancaria/contaBancariaSlice';
import { fetchById as fetchViaTransporteById } from '../../../../features/via-transporte/viaTransporteAPI';
import { solicitacaoPagamentoAPI } from '../../../../features/solicitacao-pagamento/solicitacaoPagamentoAPI';
import { normalizeData, normalizeNumeral, unnormalizeNumeral } from '../../../../utils/general/general-utils';
import { MediaType } from '../../../../utils/api/api-utils';
import { formatBrazilianNumericDecimal } from '../../../../utils/hooks/form/field/formatters';
import SolicitacaoPagamentoUtils from '../../../../utils/general/solicitacao-pagamento/SolicitacaoPagamentoUtils';
import { convertLocalDateTimeStringToUTC } from '../../../../utils/general/dateTime-utils';

export default function SolicitacaoPagamentoRegistrationPage({ authInfo = {} }) {
  const { t } = useTranslation();
  const { id } = useParams();

  const history = useHistory();

  const dispatch = useDispatch();

  const [viaTransporteData, setViaTransporteData] = useState('');

  const status = useSelector(selectStatus);

  const mode = useSelector(selectMode);

  const subMode = useSelector(selectSubMode);

  const backgroundMode = useSelector(selectBackgroundMode);

  const solicitacaoPagamento = useSelector(selectSolicitacaoPagamento);

  const followUp = useMemo(() => solicitacaoPagamento?.followUp, [solicitacaoPagamento]);

  const idViaTransporte = useMemo(() => followUp?.servico?.viaTransporte?.id, [followUp]);

  const requestViaTransporte = useCallback(async () => {
    if (idViaTransporte) {
      const response = await fetchViaTransporteById(idViaTransporte);

      const { description: nomeViaTransporte } = response?.data;

      setViaTransporteData(nomeViaTransporte);
    }
  }, [idViaTransporte, setViaTransporteData]);

  useEffect(() => {
    requestViaTransporte();
  }, [requestViaTransporte]);

  const tableRows = useMemo(
    () =>
      followUp?.id
        ? [
            {
              id: followUp?.id,
              processo: followUp?.numero,
              cliente: followUp?.importador?.pessoa?.nome,
              servico: followUp?.servico?.nome,
              viaTransporte: viaTransporteData,
            },
          ]
        : [],
    [followUp, viaTransporteData]
  );

  const isNone = useMemo(() => isNoneMode(mode), [mode]);

  const isCreate = useMemo(() => isCreateMode(mode), [mode]);

  const isConsult = useMemo(() => isConsultMode(mode), [mode]);

  const isUpdate = useMemo(() => isUpdateMode(mode), [mode]);

  const isPreparingAction = useMemo(() => isPreparingActionStatus(status), [status]);

  const isExternal = useMemo(() => isExternalMode(mode), [mode]);

  const isSubUpdate = useMemo(() => isSubUpdateMode(subMode), [subMode]);

  const isBackgroundCreate = useMemo(() => isBackgroundCreateMode(backgroundMode), [backgroundMode]);

  const handleChangeToCreate = useCallback(() => {
    dispatch(changeToCreateMode());
  }, []);

  const handleChangeToBackgroundCreate = useCallback(() => {
    dispatch(changeToBackgroundCreateMode());
  }, []);

  const handleChangeToPreparingAction = () => {
    dispatch(preparingAction());
  };

  const handleChangeToConsult = () => {
    dispatch(changeToConsultMode());
  };

  const handleChangeToUpdate = () => {
    dispatch(changeToUpdateMode());
  };

  const handleCancelUpdate = useCallback(() => {
    const currentId = isBackgroundCreate ? solicitacaoPagamento?.id : id;

    if (currentId) {
      dispatch(fetchById(currentId));
    }
    handleChangeToConsult();
  }, [solicitacaoPagamento, id]);

  const handleResetBackgroundMode = useCallback(() => {
    dispatch(resetBackgroundMode());
  }, []);

  useEffect(() => {
    if (!isEmpty(id)) {
      dispatch(fetchById(id));
      handleChangeToConsult();
    }
  }, [id]);

  const normalize = useCallback((unnormalizedData) => {
    const data = _.omit(unnormalizedData, [
      'ignorableFields',
      'draftFields',
      'saldo',
      'valorRecebido',
      'insertionDate',
    ]);

    return {
      ...data,
      taxaCambio: normalizeNumeral(unnormalizedData?.taxaCambio),
      valorOriginalMoeda: normalizeNumeral(unnormalizedData?.valorOriginalMoeda),
      valorPagarMoeda: normalizeNumeral(unnormalizedData?.valorPagarMoeda),
      valorRecebidoMoeda: normalizeNumeral(unnormalizedData?.valorRecebidoMoeda),
      percentualDesconto: normalizeNumeral(unnormalizedData?.percentualDesconto),
      valorDescontoMoeda: normalizeNumeral(unnormalizedData?.valorDescontoMoeda),
      percentualMulta: normalizeNumeral(unnormalizedData?.percentualMulta),
      valorMultaMoeda: normalizeNumeral(unnormalizedData?.valorMultaMoeda),
      percentualJuros: normalizeNumeral(unnormalizedData?.percentualJuros),
      valorJurosMoeda: normalizeNumeral(unnormalizedData?.valorJurosMoeda),
      dataVencimento: convertLocalDateTimeStringToUTC(unnormalizedData?.dataVencimento),
      dataPagamento: convertLocalDateTimeStringToUTC(unnormalizedData?.dataPagamento),
      dataSolicitacao: moment(unnormalizedData?.dataSolicitacao).format('YYYY-MM-DDTHH:mm:ss'),
      dataRecebimento: convertLocalDateTimeStringToUTC(unnormalizedData?.dataRecebimento),
      followUp: unnormalizedData?.followUp?.id ? unnormalizedData?.followUp : null,
      moeda: unnormalizedData?.moeda?.id ? unnormalizedData?.moeda : null,
      fornecedor: unnormalizedData?.fornecedor?.id ? unnormalizedData?.fornecedor : null,
      favorecido: unnormalizedData?.favorecido?.id ? unnormalizedData?.favorecido : null,
      numerario: unnormalizedData?.numerario?.id ? unnormalizedData?.numerario : null,
      lancamento: unnormalizedData?.lancamento?.id ? unnormalizedData?.lancamento : null,
      despesaReceita: unnormalizedData?.despesaReceita?.id ? unnormalizedData?.despesaReceita : null,
      contaBancariaPagamento: unnormalizedData?.contaBancariaPagamento?.id
        ? unnormalizedData?.contaBancariaPagamento
        : null,
    };
  }, []);

  const unnormalize = useCallback(
    (normalizedData) => ({
      ...normalizedData,
      taxaCambio: unnormalizeNumeral(normalizedData?.taxaCambio, formatBrazilianNumericDecimal(5)),
      valorOriginalMoeda: unnormalizeNumeral(normalizedData?.valorOriginalMoeda, formatBrazilianNumericDecimal(2)),
      valorPagarMoeda: unnormalizeNumeral(normalizedData?.valorPagarMoeda, formatBrazilianNumericDecimal(2)),
      valorRecebidoMoeda: unnormalizeNumeral(normalizedData?.valorRecebidoMoeda, formatBrazilianNumericDecimal(2)),
      percentualDesconto: unnormalizeNumeral(normalizedData?.percentualDesconto, formatBrazilianNumericDecimal(5)),
      valorDescontoMoeda: unnormalizeNumeral(normalizedData?.valorDescontoMoeda, formatBrazilianNumericDecimal(2)),
      percentualMulta: unnormalizeNumeral(normalizedData?.percentualMulta, formatBrazilianNumericDecimal(5)),
      valorMultaMoeda: unnormalizeNumeral(normalizedData?.valorMultaMoeda, formatBrazilianNumericDecimal(2)),
      percentualJuros: unnormalizeNumeral(normalizedData?.percentualJuros, formatBrazilianNumericDecimal(5)),
      valorJurosMoeda: unnormalizeNumeral(normalizedData?.valorJurosMoeda, formatBrazilianNumericDecimal(2)),
    }),
    []
  );

  const handleDispatchSetModel = useCallback(
    (data) => {
      dispatch(setModel(normalize(data)));
    },
    [normalize]
  );

  const parseToFormData = useCallback((data) => {
    const { documentoPagamento, ...restData } = data;

    const jsonData = {
      ...restData,
      ...(documentoPagamento?.id
        ? {
            documentoPagamento,
          }
        : {}),
    };

    const json = JSON.stringify(jsonData);
    const blob = new Blob([json], {
      type: MediaType.APPLICATION_JSON,
    });

    const file = documentoPagamento?.length > 0 ? documentoPagamento[0] : undefined;

    const formData = new FormData();

    formData.append('data', blob);

    if (file) {
      formData.append('file', file);
    }

    return formData;
  }, []);

  const create = async (data) => {
    const executeDebounced = debounce(async () => {
      try {
        const formData = parseToFormData(data);

        const response = await solicitacaoPagamentoAPI.register(formData);

        if (response?.status === 201) {
          dispatch(resetModel());

          const created = response?.data;

          handleDispatchSetModel(created);
          dispatch(addToList({ data: created }));

          const handleResultWithDebounce = debounce(() => {
            history.push(t('com.muralis.qcx.url.financeiroSolicitacaoPagamento'));

            dispatch(success());
            dispatch(
              setResponse({
                status: response.status,
                data: created,
                message: t('com.muralis.qcx.mensagem.solicitacaoPagamentoCriada', {
                  identificador: data?.followUp?.numero || data?.id,
                }),
              })
            );
          }, 500);

          handleResultWithDebounce();
        }
      } catch (error) {
        dispatch(failure());
        let errorMessage = t('com.muralis.qcx.erro.erroSalvarSolicitacaoPagamento');
        if (error?.response && error?.response?.data) {
          errorMessage = t('com.muralis.qcx.erro.erroSalvarSolicitacaoPagamentoMensagem', {
            erro: error?.response.data.message,
          });
        }
        dispatch(
          setError({
            message: errorMessage,
          })
        );
      }
    }, 500);

    dispatch(loading());
    executeDebounced();
  };

  const update = async (data) => {
    const executeDebounced = debounce(async () => {
      try {
        const formData = parseToFormData(data);

        const response = await solicitacaoPagamentoAPI.save(formData);

        if (response?.status === 200) {
          const handleResultWithDebounce = debounce(() => {
            handleChangeToConsult();
            dispatch(success());

            const saved = response?.data;

            dispatch(
              setResponse({
                status: response.status,
                data: saved,
                message: t('com.muralis.qcx.mensagem.solicitacaoPagamentoSalva', {
                  identificador: saved?.followUp?.numero || saved?.id,
                }),
              })
            );

            dispatch(setModel(saved));
            dispatch(updateOnList({ data: saved }));
            dispatch(fetchById(saved?.id));
          }, 500);

          handleResultWithDebounce();
          handleChangeToConsult();
        }
      } catch ({ response }) {
        dispatch(failure());
        dispatch(
          setError({
            message: t('com.muralis.qcx.erro.erroSalvarSolicitacaoPagamentoEspecifico', {
              identificador: data?.followUp?.numero || data?.id,
              erro: response?.data?.message,
            }),
          })
        );
      }
    }, 500);

    dispatch(loading());
    executeDebounced();
  };

  const handleSubmit = async (data) => {
    const normalizedData = normalize(data);
    if (isUpdate && !isBackgroundCreate) {
      await update(normalizedData);
    } else {
      await create(normalizedData);
    }
  };

  const model = useMemo(
    () =>
      isCreate && !isBackgroundCreate
        ? {
            ...solicitacaoPagamento,
            followUp: {
              id: solicitacaoPagamento?.followUp?.id,
            },
          }
        : unnormalize(solicitacaoPagamento),
    [isCreate, isBackgroundCreate, solicitacaoPagamento, unnormalize]
  );

  const actionName = useMemo(() => {
    if (isCreate || isNone) return t('com.muralis.qcx.acoes.nova');
    if (isUpdate) return t('com.muralis.qcx.acoes.alterar');
    return t('com.muralis.qcx.acoes.visualizar');
  }, [isNone, isCreate, isUpdate]);

  const breadcrumbs = useMemo(
    () => [
      {
        link: {
          to: '/',
          name: t('com.muralis.qcx.inicio'),
        },
      },
      {
        link: {
          to: t('com.muralis.qcx.url.moduloFinanceiro'),
          name: t('com.muralis.qcx.financeiro.label'),
        },
      },
      {
        link: {
          to: t('com.muralis.qcx.url.financeiroSolicitacaoPagamento'),
          name: t('com.muralis.qcx.solicitacaoPagamento.label'),
        },
      },
      {
        text: {
          name: actionName,
        },
      },
    ],
    [actionName]
  );

  const pageTitle = useMemo(
    () =>
      isNone || isCreate || isBackgroundCreate
        ? t('com.muralis.qcx.solicitacaoPagamento.novaSolicitacaoPagamento')
        : t('com.muralis.qcx.solicitacaoPagamento.solicitacaoPagamentoExistente', {
            identificador: solicitacaoPagamento?.followUp?.numero || solicitacaoPagamento?.id,
          }),
    [model, isNone, isCreate, isSubUpdate, isBackgroundCreate]
  );

  useAutoChangeMode((currentMode) => dispatch(changeModeTo(currentMode)), [isExternal]);

  const disableUpdate = useMemo(
    () =>
      !isCreate &&
      !(
        SolicitacaoPagamentoUtils.isSolicitacaoPendente(solicitacaoPagamento?.status) ||
        SolicitacaoPagamentoUtils.isAgAprovacao(solicitacaoPagamento?.status)
      ),
    [solicitacaoPagamento, isCreate]
  );

  return (
    <QCXRegistrationFormPageTemplate
      pageTitle={pageTitle}
      showSubtitle={false}
      breadcrumbs={breadcrumbs}
      isCreate={isCreate}
      isConsult={isConsult}
      isUpdate={isUpdate}
      isBackgroundCreate={isBackgroundCreate}
      isPreparingAction={isPreparingAction}
      handleChangeToPreparingAction={handleChangeToPreparingAction}
      handleChangeToCreate={handleChangeToCreate}
      handleChangeToBackgroundCreate={handleChangeToBackgroundCreate}
      handleChangeToConsult={handleChangeToConsult}
      handleChangeToUpdate={handleChangeToUpdate}
      handleCancelUpdate={handleCancelUpdate}
      handleResetBackgroundMode={handleResetBackgroundMode}
      authInfo={authInfo}
    >
      {(formProps) => (
        <QCXSolicitacaoPagamentoFinanceiroForm
          initialValues={model}
          isCreate={isCreate}
          handleSubmit={handleSubmit}
          handleChangeModel={handleDispatchSetModel}
          authInfo={authInfo}
          requiredRoles={['exportacao-solicitacao-pagamento']}
          tableRows={tableRows}
          disableUpdate={disableUpdate}
          {...formProps}
        />
      )}
    </QCXRegistrationFormPageTemplate>
  );
}
