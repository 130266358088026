import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ALTERNATIVE_LOADING_STATUS, FAILURE_STATUS, IDLE_STATUS, LOADING_STATUS, SUCCESS_STATUS } from '../status';
import { fetchAll, fetchById, activateById, inactivateById } from './unidadeLocalRfbAPI';
import i18n from '../../i18n';

interface UnidadeLocalRfbState {
  status: string;
  error: {
    message: string;
    name?: string;
    stack?: string;
    code?: string;
  } | null;
  response: {
    status: number | null;
    message: string | null;
    data: any | null;
  };
  list: any[];
  model: {
    id: string;
    code: string;
    description: string;
    active: boolean | null;
    vigencia: {
      inicio: string | null;
      fim: string | null;
    };
    insertionDate: Date | null;
    codigoRegiaoFiscal: string;
    siglaRegiaoFiscal: string;
    recintoAlfandegadoPrincipal: string;
    categoria: string;
    indicadorDPV: string;
    viaDPV: string;
    codigoComercioExterior: string;
    codigoPorto2: string;
    codigoMunicipio: string;
    indicadorZFM: string;
    ministerioAgricultura: string;
    ministerioSaude: string;
    urfAnterior: string;
  };
}

interface UnidadeLocalRfb {
  active: boolean;
  categoria: string;
  code: string;
  codigoRegiaoFiscal: string;
  description: string;
  id: number | null;
  insertionDate: string;
  recintosAduaneiros: any[];
  siglaRegiaoFiscal: string;
  vigencia: {
    inicio: string;
    fim: string;
  };
}

const initialState: UnidadeLocalRfbState = {
  status: IDLE_STATUS,
  error: null,
  response: {
    status: null,
    message: null,
    data: null,
  },
  list: [],
  model: {
    id: '',
    code: '',
    description: '',
    active: null,
    vigencia: {
      inicio: '',
      fim: '',
    },
    insertionDate: null,
    codigoRegiaoFiscal: '',
    siglaRegiaoFiscal: '',
    recintoAlfandegadoPrincipal: '',
    categoria: '',
    indicadorDPV: '',
    viaDPV: '',
    codigoComercioExterior: '',
    codigoPorto2: '',
    codigoMunicipio: '',
    indicadorZFM: '',
    ministerioAgricultura: '',
    ministerioSaude: '',
    urfAnterior: '',
  },
};

const fetchAllAsync = createAsyncThunk('unidadeLocalRfb/fetchAll', async () => {
  const { data } = await fetchAll();
  return { data };
});

const fetchByIdAsync = createAsyncThunk('unidadeLocalRfb/fetchById', async (data) => {
  const response = await fetchById(data);
  return {
    response: {
      status: response.status,
      data: response.data,
    },
  };
});

const activateByIdAsync = createAsyncThunk('unidadeLocalRfb/activateById', async (id) => {
  const { status, data } = await activateById(id);
  return { response: { status, data } };
});

const inactivateByIdAsync = createAsyncThunk('unidadeLocalRfb/inactivateById', async (id) => {
  const { status, data } = await inactivateById(id);
  return { response: { status, data } };
});

const unidadeLocalRfbSlice = createSlice({
  name: 'unidadeLocalRfb',
  initialState,
  reducers: {
    changeStatusTo: (state, action) => {
      state.status = action.payload.status;
    },
    loading: (state) => {
      state.status = LOADING_STATUS;
    },
    success: (state) => {
      state.status = SUCCESS_STATUS;
    },
    failure: (state) => {
      state.status = FAILURE_STATUS;
    },
    resetStatus: (state) => {
      state.status = IDLE_STATUS;
    },
    addToList: (state, action) => {
      state.list = [...state.list, action.payload.data];
    },
    updateOnList: (state, action) => {
      state.list = state.list.map((current) => {
        if (current.id === action.payload.data?.id) {
          return action.payload.data;
        }
        return current;
      });
    },
    setResponse: (state, action) => {
      state.response = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    clearResponse: (state) => {
      state.response = {
        status: null,
        message: null,
        data: null,
      };
    },
    resetModel: (state) => {
      state.model = initialState.model;
    },
    resetList: (state) => {
      state.list = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllAsync.pending, (state) => {
        state.status = ALTERNATIVE_LOADING_STATUS;
      })
      .addCase(fetchAllAsync.fulfilled, (state, action) => {
        state.status = IDLE_STATUS;
        state.list = action.payload.data;
      })
      .addCase(fetchAllAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.carregarListaUnidadesRFB', { erro: action.error.message }),
        };
      })
      .addCase(fetchByIdAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(fetchByIdAsync.fulfilled, (state, action) => {
        if (action.payload.response.status === 200) {
          state.model = action.payload.response.data;
          state.status = SUCCESS_STATUS;
        }
      })
      .addCase(fetchByIdAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.carregarDadosUnidadeURF', { erro: action.error.message }),
        };
      })
      .addCase(activateByIdAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(activateByIdAsync.fulfilled, (state, action) => {
        state.status = SUCCESS_STATUS;
        state.response.status = action.payload.response.status;
        state.response.data = action.payload.response.data;

        const { description } = action.payload.response.data;
        state.response.message = i18n.t('com.muralis.qcx.mensagem.unidadeRFBativada', { unidade: description });

        if (state.response.status === 200) {
          state.list = state.list.map((current) =>
            current.id === action.payload.response.data.id ? action.payload.response.data : current
          );
        }
      })
      .addCase(activateByIdAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.ativarUnidadeRFB', { erro: action.error.message }),
        };
      })
      .addCase(inactivateByIdAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(inactivateByIdAsync.fulfilled, (state, action) => {
        state.status = SUCCESS_STATUS;
        state.response.status = action.payload.response.status;
        state.response.data = action.payload.response.data;

        const { description } = action.payload.response.data;
        state.response.message = i18n.t('com.muralis.qcx.mensagem.unidadeRFBinativada', { unidade: description });

        if (state.response.status === 200) {
          state.list = state.list.map((current) =>
            current.id === action.payload.response.data.id ? action.payload.response.data : current
          );
        }
      })
      .addCase(inactivateByIdAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.inativarUnidadeRFB', { erro: action.error.message }),
        };
      });
  },
});

const {
  changeStatusTo,
  loading,
  success,
  failure,
  resetStatus,
  addToList,
  updateOnList,
  setResponse,
  clearResponse,
  setError,
  resetModel,
  resetList,
} = unidadeLocalRfbSlice.actions;

const selectUnidadesLocaisRfb = (state: any) => state.unidadeLocalRfb.list as UnidadeLocalRfb[];
const selectStatus = (state: any) => state.unidadeLocalRfb.status;
const selectError = (state: any) => state.unidadeLocalRfb.error;
const selectResponse = (state: any) => state.unidadeLocalRfb.response;
const selectUnidadeLocalRfb = (state: any) => state.unidadeLocalRfb.model as UnidadeLocalRfb;

export {
  unidadeLocalRfbSlice,
  changeStatusTo,
  loading,
  success,
  failure,
  resetStatus,
  addToList,
  updateOnList,
  setResponse,
  clearResponse,
  setError,
  resetModel,
  fetchAllAsync,
  fetchByIdAsync,
  activateByIdAsync,
  inactivateByIdAsync,
  resetList,
  selectUnidadesLocaisRfb,
  selectUnidadeLocalRfb,
  selectStatus,
  selectError,
  selectResponse,
};

export default unidadeLocalRfbSlice.reducer;
