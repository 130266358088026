import OilBarrelIcon from '@mui/icons-material/OilBarrel';
import { Button, Grid } from '@mui/material';
import { useKeycloak } from '@react-keycloak/web';
import saveAs from 'file-saver';
import { SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllAsync as fetchAllClientesAsync, selectClientes } from '../../../../features/cliente/clienteSlice';
import { selectUnidadeSelecionada } from '../../../../features/usuario-logado/usuarioLogadoSlice';
import ErrorBadge from '../../../common/components/forms/errorBadge';
import FormHeaderMedium from '../../../common/components/forms/formHeaderMedium';
import DatePickerStyled from '../../../common/components/input/datePickerStyled';
import SelectStyled from '../../../common/components/input/selectStyled';
import LoadingIndicator from '../../../common/components/loadingIndicator';
import { QueriesTypes, useGetRequest } from '../../../common/hooks/queries';
import { useUnidadeNegocioGuard } from '../../../common/hooks/useUnidadeNegocioGuard';
import PageLayout from '../../../common/layouts/pageLayout';
import { ClientData } from '../../../common/types/clientData';
import { Danfe } from '../../../common/types/danfe';
import { FaturaDIDuimp } from '../../../common/types/faturaDIDuimp';
import { FollowUp } from '../../../common/types/followUp';
import { formatDateISOString, formatStringToDate } from '../../../common/utils/parsers/dateParsers';
import { KEYCLOAK_TOKEN_TIMEOUT } from '../../../../App';

interface ReportLine {
  followUp: FollowUp;
  faturaDIDuimp: FaturaDIDuimp | undefined;
}

const RelatorioHalliburtonPage = () => {
  useUnidadeNegocioGuard();
  const dispatch = useDispatch();
  const keycloak = useKeycloak();
  const [startDate, setStartDate] = useState<Date | undefined>(new Date());
  const [endDate, setEndDate] = useState<Date | undefined>(new Date());
  const [error, setError] = useState<string | undefined>();
  const [cliente, setCliente] = useState<number | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const clientes: ClientData[] = useSelector(selectClientes);
  const selectedUnidade: number = useSelector(selectUnidadeSelecionada);

  const handleReportGeneration = () => {
    const initialDate = startDate?.toISOString().split('T')[0].toString();
    const finalDate = endDate?.toISOString().split('T')[0].toString();
    keycloak.keycloak.updateToken(KEYCLOAK_TOKEN_TIMEOUT);
    fetch(
      `${process.env.REACT_APP_RELATORIOS_API_BASE_URL}/${process.env.REACT_APP_RELATORIOS_API_HALLIBURTON_ENDPOINT}?importador=${cliente}&dataInicio=${initialDate}&dataFim=${finalDate}`,
      {
        headers: {
          Authorization: `Bearer ${keycloak.keycloak.token}`,
          quickcomexTenant: selectedUnidade.toString(),
        },
      }
    )
      .then(async (response) => {
        if (!response.ok || response.status === 204) {
          const data = await response.json();
          setError(data.message);
          return Promise.reject(data.message);
        } else {
          return response.blob();
        }
      })
      .then((blob) => {
        saveAs(blob, 'halliburton.xlsm');
        clearData();
      })
      .catch((error) => {
        console.error(error);
        clearData();
      });
  };

  const handleSubmit = () => {
    if (startDate && endDate && startDate > endDate) {
      setError('A data final deve ser maior que a data inicial');
    } else {
      setError(undefined);
      setIsLoading(true);
      handleReportGeneration();
    }
  };

  const clearData = () => {
    setStartDate(new Date());
    setEndDate(new Date());
    setCliente(undefined);
    setIsLoading(false);
  };

  const handleClienteChange = (
    event: SyntheticEvent<Element, Event>,
    value: {
      id: string | number | undefined;
      value: string | undefined;
    } | null
  ) => {
    const selectedClient = clientes.find((clientes) => clientes.id === value?.id);
    setCliente(selectedClient?.id);
  };

  useEffect(() => {
    // Ignore typescript due to older version of redux-toolkit
    // TODO: Update redux-toolkit to typescript
    /* @ts-ignore */
    dispatch(fetchAllClientesAsync());
  }, []);

  return (
    <PageLayout title={'Relatório Halliburton'} icon={<OilBarrelIcon color={'secondary'} />}>
      {isLoading ? (
        <LoadingIndicator message="Gerando relatório" />
      ) : (
        <Grid container spacing={2}>
          <FormHeaderMedium>Selecione o importador</FormHeaderMedium>
          <Grid item sm={12}>
            <SelectStyled
              options={clientes.map((cliente, index) => ({
                id: cliente.id,
                value: cliente.description + ' - ' + cliente.pessoa?.cnpj,
              }))}
              onChangeAction={handleClienteChange}
              label={'Cliente'}
            ></SelectStyled>
          </Grid>
          <FormHeaderMedium>Selecione o período</FormHeaderMedium>
          <Grid item sm={6}>
            <DatePickerStyled
              value={startDate ? formatDateISOString(startDate.toDateString(), 'YYYY-MM-DD') : null}
              label="Início do período"
              onChange={(value: string | null) =>
                setStartDate(value ? formatStringToDate(value, 'YYYY-MM-DD') : undefined)
              }
            ></DatePickerStyled>
          </Grid>
          <Grid item sm={6}>
            <DatePickerStyled
              value={endDate ? formatDateISOString(endDate.toDateString(), 'YYYY-MM-DD') : null}
              label="Fim do período"
              onChange={(value: string | null) =>
                setEndDate(value ? formatStringToDate(value, 'YYYY-MM-DD') : undefined)
              }
            ></DatePickerStyled>
          </Grid>
          <ErrorBadge error={error} />
          <Grid item sm={12} textAlign={'right'}>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleSubmit}
              disabled={!cliente || !startDate || !endDate || isLoading}
            >
              Gerar
            </Button>
          </Grid>
        </Grid>
      )}
    </PageLayout>
  );
};

export default RelatorioHalliburtonPage;
