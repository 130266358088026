import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
} from 'react';
import {
  Grid,
  makeStyles,
  Paper,
} from '@material-ui/core';
import { createFilterOptions } from '@material-ui/lab/useAutocomplete';
import _, { isUndefined } from 'lodash';
import { useFormState } from 'react-final-form';
import {
  VscQuestion as VscQuestionIcon,
} from 'react-icons/vsc';
import QCXAutocompleteSelect from '../autocomplete-select/QCXAutocompleteSelect';
import { ETAPA_ICON_LIST } from './etapaIconUtils';
import {
  getIconComponentByValue,
  getRenderComponentFunctionByOption,
} from './iconUtils';

const useStyles = makeStyles(() => ({
  optionContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  optionItemIcon: {
    paddingRight: '8px',
  },
  optionItemText: {
    paddingBottom: '4px',
  },
  gridPreview: {
    paddingRight: '8px',
  },
  cardPreview: {
    height: '37.63px',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    padding: '1',
    boxShadow: 'none',
  },
  iconSpanPreview: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export default function QCXSelectEtapaIconAutocomplete({
  label,
  name,
  disabled,
  ...restProps
}) {
  const classes = useStyles();

  const { values } = useFormState();

  const [defaultValue, setDefaultValue] = useState('');

  useEffect(() => {
    const value = ETAPA_ICON_LIST.find(
      (item) => (
        item?.value === _.get(values, name)
      )
    );

    if (value) {
      setDefaultValue(value);
    } else {
      setDefaultValue('');
    }
  }, [values]);

  const currentValue = useMemo(() => (
    _.get(values, name)
  ), [
    name,
    values,
  ]);

  const filterOptions = useMemo(() => (
    createFilterOptions({
      stringify: (option) => (
        option.label + option.value
      ),
    })
  ), []);

  const getOptionLabel = useCallback((item) => (
    item?.label || ''
  ), []);

  const getOptionValue = useCallback((item) => (
    item?.value || ''
  ), []);

  const handleRenderOptionDecorator = useCallback(({
    option,
    component,
  }) => {
    const renderIcon = getRenderComponentFunctionByOption(option);

    return (
      <div
        className={classes.optionContainer}
      >
        <span
          className={classes.optionItemIcon}
        >
          {renderIcon()}
        </span>
        <span
          className={classes.optionItemText}
        >
          {component}
        </span>
      </div>
    );
  }, []);

  const renderCurrentIcon = useCallback((props) => {
    const iconComponent = getIconComponentByValue(
      currentValue,
      props
    );

    if (isUndefined(iconComponent)) {
      return (
        <VscQuestionIcon
          {...props}
          color="#b9b9b9"
        />
      );
    }

    return iconComponent;
  }, [currentValue]);

  return (
    <Grid
      item
      container
      xs={12}
    >
      <Grid
        item
        className={classes.gridPreview}
        xs={4}
        sm={2}
        md={3}
      >
        <Paper
          className={classes.cardPreview}
          style={{
            ...(disabled || !currentValue
              ? {
                border: '1px solid #b9b9b9',
              } : {
                border: '1px solid #312783',
              }),
          }}
        >
          <span
            className={classes.iconSpanPreview}
          >
            {renderCurrentIcon({
              ...(disabled || !currentValue
                ? {
                  color: '#3127829c',
                  htmlColor: '#3127829c',
                } : {}),
              size: 30,
              fontSize: '30',
            })}
          </span>
        </Paper>
      </Grid>
      <Grid
        item
        xs={8}
        sm={10}
        md={9}
      >
        <QCXAutocompleteSelect
          label={label}
          id={name}
          name={name}
          options={ETAPA_ICON_LIST}
          filterOptions={filterOptions}
          defaultValue={defaultValue}
          getOptionLabel={getOptionLabel}
          getOptionValue={getOptionValue}
          renderOptionDecorator={handleRenderOptionDecorator}
          disabled={disabled}
          {...restProps}
        />
      </Grid>
    </Grid>
  );
}
