import { isArray } from 'lodash';
import { isStrictEquals } from '../general-utils';
import i18n from '../../../i18n';

const OPTANTE_SIMPLES_NACIONAL = 'OPTANTE';
const NAO_OPTANTE_SIMPLES_NACIONAL = 'NAO_OPTANTE';

const CONTRIBUINTE_ICMS = 'CONTRIBUINTE_ICMS';
const CONTRIBUINTE_ISENTO = 'CONTRIBUINTE_ISENTO';
const NAO_CONTRIBUINTE = 'NAO_CONTRIBUINTE';

const RETER = 'RETER';
const NAO_RETER = 'NAO_RETER';

const INDICADOR_INSCRICAO_ESTADUAL_LIST = [
  {
    label: i18n.t('com.muralis.qcx.indicadorInscricaoEstadual.contribuinteICMS'),
    value: CONTRIBUINTE_ICMS,
  },
  {
    label: i18n.t('com.muralis.qcx.indicadorInscricaoEstadual.contribuinteIsento'),
    value: CONTRIBUINTE_ISENTO,
  },
  {
    label: i18n.t('com.muralis.qcx.indicadorInscricaoEstadual.naoContribuinte'),
    value: NAO_CONTRIBUINTE,
  },
];

const isOptanteReter = (type) => {
  const value = isArray(type) ? type[0] : type;
  return isStrictEquals(RETER, value);
};

const normalizeReter = (type) => {
  if (isOptanteReter(type)) {
    return RETER;
  }

  return NAO_RETER;
};

const unnormalizeReter = (type) => {
  if (isOptanteReter(type)) {
    return [RETER];
  }

  return [];
};

const isOptanteSimplesNacional = (type) => {
  const value = isArray(type) ? type[0] : type;
  return isStrictEquals(OPTANTE_SIMPLES_NACIONAL, value);
};

const normalizeOptanteSimplesNacional = (type) => {
  if (isOptanteSimplesNacional(type)) {
    return OPTANTE_SIMPLES_NACIONAL;
  }

  return NAO_OPTANTE_SIMPLES_NACIONAL;
};

const unnormalizeOptanteSimplesNacional = (type) => {
  if (isOptanteSimplesNacional(type)) {
    return [OPTANTE_SIMPLES_NACIONAL];
  }

  return [];
};

export {
  RETER,
  NAO_RETER,
  isOptanteReter,
  normalizeReter,
  unnormalizeReter,
  INDICADOR_INSCRICAO_ESTADUAL_LIST,
  OPTANTE_SIMPLES_NACIONAL,
  NAO_OPTANTE_SIMPLES_NACIONAL,
  isOptanteSimplesNacional,
  normalizeOptanteSimplesNacional,
  unnormalizeOptanteSimplesNacional,
};
