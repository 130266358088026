import { Grid, Typography } from '@material-ui/core';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import QCXFinalTextField from '../../shared-components/final-text-field/QCXFinalTextField';
import QCXSelectViaTransporteAutocomplete from '../../shared-components/select-via-transporte/QCXSelectViaTransporteAutocomplete';
import {
  required as requiredValidator,
  maxValue,
  validValorMaximo,
  validValorMinimo,
} from '../../utils/validators/field/validator';
import QCXFinalSelectTipoCalculoPercentualAutocomplete from '../../shared-components/select-tipo-calculo-percentual/QCXFinalSelectTipoCalculoPercentualAutocomplete';
import QCXFinalNumericDecimalField from '../../shared-components/final-numeric-decimal-field/QCXFinalNumericDecimalField';
import QCXFinalPercentualField from '../../shared-components/final-percentual-field/QCXFinalPercentualField';
import useValidators from '../../utils/hooks/useValidators';
import QCXFormStepWizard from '../../shared-components/form-step-wizard/QCXFormStepWizard';
import QCXBox from '../../shared-components/box/QCXBox';

export default function QCXTabelaSdaForm({
  initialValues,
  handleSubmit,
  isCreate,
  isConsult,
  controlComponentProps,
  authInfo = {},
  requiredRoles = [],
}) {
  const { t } = useTranslation();
  const contribuicaoAssistencialValidators = useMemo(() => (
    useValidators([
      requiredValidator,
      maxValue(9.99),
    ])
  ), []);

  return (
    <>
      <QCXFormStepWizard
        initialValues={initialValues}
        onSubmit={handleSubmit}
        controlComponentProps={controlComponentProps}
        authInfo={authInfo}
        requiredRoles={requiredRoles}
      >
        {({ values }) => (
          <>
            <Typography variant="h6" color="primary">
              <QCXBox fontWeight="fontWeightBold" pt={3}>
                {t('com.muralis.qcx.dadosTecnicos').toUpperCase()}
              </QCXBox>
            </Typography>
            <Grid container spacing={2} style={{ marginTop: '1rem' }}>
              {!isCreate && (
                <Grid item xs={12} sm={6} md={3}>
                  <QCXFinalTextField
                    name="id"
                    label={t('com.muralis.qcx.codigo')}
                    disabled
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={isCreate ? 12 : 6} md={isCreate ? 4 : 5}>
                <QCXFinalTextField
                  name="description"
                  label={t('com.muralis.qcx.descricao')}
                  disabled={isConsult}
                  validate={requiredValidator}
                  maxLength={40}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <QCXSelectViaTransporteAutocomplete
                  name="viaTransporte.id"
                  label={t('com.muralis.qcx.transporte.viaTransporte')}
                  fieldProps={{ validate: requiredValidator }}
                  disabled={isConsult}
                  initialValues={values}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6} md={isCreate ? 4 : 3}>
                <QCXFinalSelectTipoCalculoPercentualAutocomplete
                  id="select-tipo-calculo-percentual-field"
                  key="select-tipo-calculo-percentual-field"
                  name="calculo"
                  label={t('com.muralis.qcx.calculo')}
                  initialValues={values}
                  fieldProps={{ validate: requiredValidator }}
                  disabled={isConsult}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <QCXFinalPercentualField
                  id="text-field-valor-percentual"
                  key="text-field-valor-percentual"
                  name="percentual"
                  label={t('com.muralis.qcx.valorPercentual')}
                  disabled={isConsult}
                  validate={requiredValidator}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <QCXFinalNumericDecimalField
                  id="currency-field-valor-minimo"
                  key="currency-field-valor-minimo"
                  name="valor.minimo"
                  label={t('com.muralis.qcx.valorMinimo')}
                  disabled={isConsult}
                  scale={2}
                  validate={validValorMinimo}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <QCXFinalNumericDecimalField
                  id="currency-field-valor-maximo"
                  key="currency-field-valor-maximo"
                  name="valor.maximo"
                  label={t('com.muralis.qcx.valorMaximo')}
                  disabled={isConsult}
                  scale={2}
                  validate={validValorMaximo}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <QCXFinalPercentualField
                  id="text-field-percentual-contribuicao-assistencial"
                  key="text-field-percentual-contribuicao-assistencial"
                  name="percentualContribuicaoAssistencial"
                  label={t('com.muralis.qcx.contribuicaoAssistencial')}
                  validate={contribuicaoAssistencialValidators}
                  disabled={isConsult}
                  required
                />
              </Grid>
            </Grid>
          </>
        )}
      </QCXFormStepWizard>
    </>
  );
}
