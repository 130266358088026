import { Grid } from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import QCXSelectCFOPOperacoesFiscaisAutocomplete from '../../shared-components/final-autocomplete-select-cfop-operacoes-fiscais-field/QCXSelectCFOPOperacoesFiscaisAutocomplete';
import QCXFinalDatePickerField from '../../shared-components/final-date-picker-field/QCXFinalDatePickerField';
import QCXFinalTextField from '../../shared-components/final-text-field/QCXFinalTextField';
import { isCurrentOrFutureDate } from '../../utils/validators/field/validator';

export default function QCXFaturaAdicionaisFormGroup({ modes }) {
  const { t } = useTranslation();

  const { isConsult, isSubConsult, isSubNone } = modes;

  const currentDate = moment().toDate();

  return (
    <>
      <Grid item xs={12} sm={6} md={6} lg={6}>
        <QCXFinalTextField
          id="text-field-numero-ato-concessorio"
          key="text-field-numero-ato-concessorio"
          name="adicionais.numeroAtoConcessorio"
          label={t('com.muralis.qcx.numeroAtoConcessorio')}
          disabled={isConsult || isSubConsult || isSubNone}
          maxLength={11}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={6}>
        <QCXFinalTextField
          id="text-field-item-ato-concessorio"
          key="text-field-item-ato-concessorio"
          name="adicionais.itemAtoConcessorio"
          label={t('com.muralis.qcx.fatura.itemAtoConcessorio')}
          disabled={isConsult || isSubConsult || isSubNone}
          maxLength={3}
        />
      </Grid>
      <Grid item container xs={12} sm={6} md={6} lg={6}>
        <Grid
          item
          style={{
            paddingBottom: '16px',
          }}
          xs={12}
          sm={12}
          md={12}
          lg={12}
        >
          <QCXFinalTextField
            id="text-field-marca"
            key="text-field-marca"
            name="adicionais.marca"
            label={t('com.muralis.qcx.marca')}
            disabled={isConsult || isSubConsult || isSubNone}
            maxLength={20}
          />
        </Grid>
        <Grid
          item
          style={{
            paddingBottom: '16px',
          }}
          xs={12}
          sm={12}
          md={12}
          lg={12}
        >
          <QCXFinalTextField
            id="text-field-modelo"
            key="text-field-modelo"
            name="adicionais.modelo"
            label={t('com.muralis.qcx.modelo')}
            disabled={isConsult || isSubConsult || isSubNone}
            maxLength={20}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <QCXFinalTextField
            id="text-field-lote"
            key="text-field-lote"
            name="adicionais.lote"
            label={t('com.muralis.qcx.lote')}
            disabled={isConsult || isSubConsult || isSubNone}
            maxLength={5}
          />
        </Grid>
      </Grid>
      <Grid item container xs={12} sm={6} md={6} lg={6}>
        <Grid
          item
          style={{
            paddingBottom: '16px',
          }}
          xs={12}
          sm={12}
          md={12}
          lg={12}
        >
          <QCXFinalTextField
            id="text-field-numero-serie"
            key="text-field-numero-serie"
            name="adicionais.numeroSerie"
            label={t('com.muralis.qcx.numeroSerie')}
            disabled={isConsult || isSubConsult || isSubNone}
            maxLength={20}
          />
        </Grid>
        <Grid
          item
          style={{
            paddingBottom: '16px',
          }}
          xs={12}
          sm={12}
          md={12}
          lg={12}
        >
          <QCXFinalDatePickerField
            id="date-picker-ano-fabricacao-field"
            key="date-picker-ano-fabricacao-field"
            name="adicionais.anoFabricacao"
            label={t('com.muralis.qcx.anoFabricacao')}
            views={['year']}
            format="YYYY"
            placeholder="YYYY"
            disabled={isConsult || isSubConsult || isSubNone}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <QCXFinalDatePickerField
            id="date-picker-data-validade-field"
            key="date-picker-data-validade-field"
            name="adicionais.dataValidade"
            label={t('com.muralis.qcx.dataValidade')}
            minDate={currentDate}
            validate={(!isConsult || !isSubConsult || !isSubNone) && isCurrentOrFutureDate}
            disabled={isConsult || isSubConsult || isSubNone}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={6}>
        <QCXSelectCFOPOperacoesFiscaisAutocomplete
          id="text-cfop-operacoes-fiscais-field"
          key="text-cfop-operacoes-fiscais-field"
          name="operacaoFiscal"
          label={t('com.muralis.qcx.CFOP.labelExtenso')}
          disabled={isConsult || isSubConsult || isSubNone}
        />
      </Grid>
    </>
  );
}
