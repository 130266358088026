import DeleteIcon from "@mui/icons-material/Delete";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import { Button, FormControl, Grid, Typography } from "@mui/material";
import {
  GridActionsCellItem,
  GridColDef,
  GridValueFormatterParams,
  GridValueSetterParams,
} from "@mui/x-data-grid";
import { SyntheticEvent, useEffect, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { fetchAllAsync as fetchTipoDocumento } from "../../../../../features/tipo-documento/tipoDocumentoSlice";
import { fetchAllAsync as fetchPortoAeroporto } from "../../../../../features/porto-aeroporto/PortoAeroportoSlice";

import DataTable from "../../../../common/components/dataTable";
import FormHeader from "../../../../common/components/forms/formHeader";
import FormHeaderMedium from "../../../../common/components/forms/formHeaderMedium";
import SelectStyled from "../../../../common/components/input/selectStyled";
import SwitchStyled from "../../../../common/components/input/switchStyled";
import LoadingIndicator from "../../../../common/components/loadingIndicator";
import { QueriesTypes } from "../../../../common/hooks/queries";
import PageLayout from "../../../../common/layouts/pageLayout";
import { brlFormatter } from "../../../../common/utils/formatters/currencyFormatters";
import FileSubmitForm from "../common/fileSubmitForm";
import {
  useListAllCadastroProposta,
  useListAllServicoProposta,
  useListPropostaMercadoria,
} from "../common/hooks";
import { MercadoriaProposta } from "../common/mercadoriaProposta.type";
import { PropostaFile } from "../common/propostaFile";
import { ServicoCover, ServicoProposta } from "../common/propostaServicos.type";
import { CadastroCover } from "../common/propostasCadastros.type";
import { ModalProposta } from "../propostasNewPage/components/firstPage";
import { useListServicoPropostaById } from "../propostasServicosPage/propostasServicos.hooks";
import {
  useAddAnexoToProposta,
  useAddCadastroToProposta,
  useAddServicoValorToProposta,
  useDeleteAnexoInProposta,
  useDeleteCadastroValorInProposta,
  useDeleteServicoValorInProposta,
  useGetPropostaById,
  useUpdateCadastroValorInProposta,
  useUpdateServicoValorInProposta,
} from "./propostasDetails.hook";
import { PortoAeroporto } from "../../../../common/types/portoAeroporto";
import MultiSelectStyled from "../../../../common/components/input/multiSelectStyled";
import { selectPortosAeroportos } from "../../../../../features/porto-aeroporto/PortoAeroportoSlice";
import { GridRowData, GridRowParams } from "@material-ui/data-grid";
import TextFieldStyled from "../../../../common/components/input/textFieldStyled";
import { formatCpfOrCnpj } from "../../../../../utils/hooks/form/field/formatters";

const PropostasDetails = () => {
  const queryClient = useQueryClient();
  const history = useHistory();

  const dispatch = useDispatch();
  const fetchAll = async () => {
    dispatch(fetchTipoDocumento());
    dispatch(fetchPortoAeroporto());
  };
  useEffect(() => {
    fetchAll();
  }, []);

  const params: { id: string } = useParams();
  const [proposta, isLoading, isError] = useGetPropostaById(params.id);

  const [cadastros] = useListAllCadastroProposta(proposta.clienteId);
  const [servicos] = useListAllServicoProposta(proposta.clienteId);
  const [mercadorias] = useListPropostaMercadoria();

  const [isEditing, setIsEditing] = useState(false);

  const [selectedMercadoria, setselectedMercadoria] =
    useState<MercadoriaProposta>();
  const handleMercadoriaChange = (
    event: SyntheticEvent<Element, Event>,
    value: { id: string | number | undefined; value: string | undefined } | null
  ) => {
    const selectedMercadoria = mercadorias?.find(
      (mercadoria) => mercadoria.id === value?.id
    );
    setselectedMercadoria(selectedMercadoria);
  };

  const modais: ModalProposta[] = [
    {
      id: 1,
      name: "Aéreo",
    },
    {
      id: 2,
      name: "Ferroviário",
    },
    {
      id: 3,
      name: "Marítimo",
    },
    {
      id: 4,
      name: "Rodoviário",
    },
  ];
  const filteredModais = useMemo(() => {
    return modais.filter((modal) =>
      servicos
        .filter((s) => s.mercadoria === selectedMercadoria?.nome)
        .map((s) => s.modal)
        .includes(modal.name)
    );
  }, [selectedMercadoria]);
  const [selectedModal, setselectedModal] = useState<ModalProposta>();
  const handleModalChange = (
    event: SyntheticEvent<Element, Event>,
    value: { id: string | number | undefined; value: string | undefined } | null
  ) => {
    const selectedModal = modais.find((modal) => modal.id === value?.id);
    setselectedModal(selectedModal);
  };

  const filteredServicos = useMemo(() => {
    return servicos.filter((servico) => {
      return (
        servico.mercadoria === selectedMercadoria?.nome &&
        servico.modal === selectedModal?.name
      );
    });
  }, [selectedModal, selectedMercadoria]);
  const [selectedServico, setSelectedServico] = useState<ServicoCover>();
  const handleServicoChange = (
    event: SyntheticEvent<Element, Event>,
    value: { id: string | number | undefined; value: string | undefined } | null
  ) => {
    const selectedServico = servicos.find(
      (servico) => servico.id === value?.id
    );
    setSelectedServico(selectedServico);
  };
  const [servicosValores] = useListServicoPropostaById(
    selectedServico?.id,
    `clienteId=${proposta.clienteId}`
  );
  const filteredServicosValores = useMemo(
    () =>
      servicosValores.filter(
        (valor) =>
          !proposta.servicos.find(
            (servico) =>
              valor.id === servico.id && valor.nomeValor === servico.nomeValor
          )
      ),
    [servicosValores, proposta]
  );
  const [selectedServicoValor, setSelectedServicoValor] =
    useState<ServicoProposta>();
  const handleChangeServicoValor = (
    event: SyntheticEvent<Element, Event>,
    value: { id: string | number | undefined; value: string | undefined } | null
  ) => {
    const selectedSV = servicosValores.find(
      (servico) => servico.nomeValor === value?.value
    );

    setSelectedServicoValor(selectedSV);
  };

  const propostaHasCadastro = (candidate: CadastroCover) => {
    return proposta.cadastros
      .map((cadastro) => cadastro.nome)
      .includes(candidate.nome);
  };

  const [addServico] = useAddServicoValorToProposta(() => {
    queryClient.invalidateQueries(QueriesTypes.Propostas);
  });
  const handleServicoValorAddition = () => {
    if (!selectedServicoValor) {
      return;
    }

    addServico({
      requestPayload: {
        ...selectedServicoValor,
        propostaId: proposta.id,
        locaisEmbarque: selectedLocais,
      },
    });
  };

  const invalidateProposta = () => {
    queryClient.invalidateQueries(QueriesTypes.Propostas);
  };

  const [deleteServicoValor] =
    useDeleteServicoValorInProposta(invalidateProposta);
  const [deleteCadastro] = useDeleteCadastroValorInProposta(invalidateProposta);
  const [addCadastro] = useAddCadastroToProposta(invalidateProposta);

  const handleToggleCadastro = (cadastroCover: CadastroCover) => {
    if (propostaHasCadastro(cadastroCover)) {
      deleteCadastro({
        cadastroInPropostaQuery: `?propostaId=${proposta.id}&cadastroId=${cadastroCover.id}`,
      });
    } else {
      addCadastro({
        requestPayload: {
          propostaId: proposta.id,
          id: cadastroCover.id,
          nome: cadastroCover.nome,
        },
      });
    }
  };
  const handleValorDeletion = ({ cadastroId, servicoId, nome }: any) => {
    if (cadastroId === undefined && servicoId === undefined) {
      return;
    }

    if (cadastroId) {
      const cadastroInPropostaQuery = `?propostaId=${proposta.id}&cadastroId=${cadastroId}&nomeValor=${nome}`;
      deleteCadastro({ cadastroInPropostaQuery });
    }

    if (servicoId) {
      const servicoInPropostaQuery = `?propostaId=${proposta.id}&servicoId=${servicoId}&nomeValor=${nome}`;
      deleteServicoValor({ servicoInPropostaQuery });
    }
  };

  const valoresProposta = useMemo(() => {
    let valores = [];

    valores =
      proposta.servicos?.map((servico) => ({
        id: servico.id + servico.nomeValor + "s",
        isServico: true,
        servicoId: servico.id,
        mercadoria: servico.mercadoria,
        modal: servico.modal,
        serviço: servico.nome,
        nome: servico.nomeValor,
        valor: servico.valor,
        locaisEmbarque: servico.locaisEmbarque,
      })) ?? [];

    const cadastrosFromProposta =
      proposta.cadastros?.map((cadastro) => ({
        id: cadastro.id + cadastro.nomeValor + "c",
        isServico: false,
        cadastroId: cadastro.id,
        mercadoria: "N/A",
        modal: "N/A",
        serviço: cadastro.nome,
        nome: cadastro.nomeValor,
        valor: cadastro.valor,
        locaisEmbarque: [],
      })) ?? [];

    valores = valores.concat(cadastrosFromProposta as any);

    return valores;
  }, [proposta]);

  const [updateCadastroValor] =
    useUpdateCadastroValorInProposta(invalidateProposta);
  const [updateServicoValor] =
    useUpdateServicoValorInProposta(invalidateProposta);

  const modelosLancamentosColumns: GridColDef[] = [
    {
      field: "mercadoria",
      headerName: "Mercadoria",
      flex: 1,
      headerClassName: "header-theme",
    },
    {
      field: "modal",
      headerName: "Modal",
      flex: 1,
      headerClassName: "header-theme",
    },
    {
      field: "serviço",
      headerName: "Serviço",
      flex: 1,
      headerClassName: "header-theme",
    },
    {
      field: "nome",
      headerName: "Nome",
      flex: 2,
      headerClassName: "header-theme",
    },
    {
      field: "valor",
      headerName: "Valor",
      flex: 1,
      headerClassName: "header-theme",
      align: "right",
      headerAlign: "right",
      valueFormatter: (params: GridValueFormatterParams<any>) => {
        return brlFormatter.format(params.value);
      },
    },
    {
      field: "locaisEmbarque",
      headerName: "Locais de Embarque",
      flex: 2,
      headerClassName: "header-theme",
      valueFormatter: (params: GridValueFormatterParams<PortoAeroporto[]>) => {
        return params.value.map((local) => local.description).join(" - ");
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Ações",
      headerClassName: "header-theme",
      // @ts-ignore
      getActions: (params: any) => [
        <GridActionsCellItem
          icon={<DeleteIcon color="error" />}
          label="Deletar"
          onClick={() => handleValorDeletion(params.row)}
        />,
      ],
    },
  ];

  const columns: GridColDef[] = [
    {
      field: "data",
      headerName: "Data",
      flex: 1,
      headerClassName: "header-theme",
      type: "string",
      valueGetter: ({ value }) => value && new Date(value).toLocaleDateString(),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      headerClassName: "header-theme",
    },
    {
      field: "alerta",
      headerName: "Alerta",
      flex: 1,
      headerClassName: "header-theme",
      valueGetter: ({ value }) => value && new Date(value).toLocaleDateString(),
    },
    {
      field: "comentario",
      headerName: "Comentário",
      flex: 2,
      headerClassName: "header-theme",
    },
  ];

  const portosAeroportos: PortoAeroporto[] = useSelector(
    selectPortosAeroportos
  );

  const [selectedLocais, setSelectedLocais] = useState<PortoAeroporto[]>([]);

  const handleLocaisChange = (
    event: SyntheticEvent<Element, Event>,
    value:
      | {
          id: string | number | undefined;
          value: string | undefined;
        }[]
      | null
  ) => {
    let newSelectedLocais: PortoAeroporto[] = [];
    value?.forEach((value) => {
      const selectedLocal = locais.find((local) => local.id === value.id);
      selectedLocal && newSelectedLocais.push(selectedLocal);
    });
    setSelectedLocais(newSelectedLocais);
  };

  const locais = useMemo(() => {
    return portosAeroportos.filter((portoAeroporto) => {
      return (
        portoAeroporto.local === "LOCAL_DE_ORIGEM" ||
        portoAeroporto.local === "LOCAL_DE_DESTINO" ||
        portoAeroporto.local === "LOCAL_DE_ENTREGA"
      );
    });
  }, [portosAeroportos]);

  const totalValue = useMemo(() => {
    if (isError || isLoading) {
      return 0;
    }

    let sum = proposta.servicos.reduce((sum, s) => sum + +s.valor, 0);
    sum = proposta.cadastros.reduce((sum, c) => sum + +c.valor, sum);

    return brlFormatter.format(sum);
  }, [proposta]);

  const handleGoToFollowups = () => {
    history.push(`/comercial/propostas/${params.id}/follow-ups`);
  };

  const [addFile] = useAddAnexoToProposta(invalidateProposta);
  const [deleteFile] = useDeleteAnexoInProposta(invalidateProposta);

  const handleDeleteFile = (anexoId: string) => {
    console.log({ anexoId });
    deleteFile({ id: anexoId });
  };

  const handleAddFile = (anexo: PropostaFile) => {
    addFile({
      requestPayload: {
        ...anexo,
        propostaId: proposta.id,
      },
    });
  };

  const columnsAnexos: GridColDef[] = useMemo(() => {
    return [
      {
        field: "data",
        headerName: "Data",
        flex: 1,
        headerClassName: "header-theme",
      },
      {
        field: "tipo",
        headerName: "Tipo de Documento",
        flex: 2,
        headerClassName: "header-theme",
      },
      {
        field: "descricao",
        headerName: "Descrição",
        flex: 3,
        headerClassName: "header-theme",
      },
      {
        field: "validade",
        headerName: "Validade",
        flex: 1,
        headerClassName: "header-theme",
      },
      {
        field: "actions",
        type: "actions",
        headerName: "Ações",
        headerClassName: "header-theme",
        // @ts-ignore
        getActions: (params: any) => [
          <GridActionsCellItem
            icon={<DeleteIcon color="error" />}
            label="Excluir"
            onClick={() => handleDeleteFile(params.id)}
          />,
        ],
      },
    ];
  }, []);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [isEditingServico, setIsEditingServico] = useState(false);
  const [editingLocaisEmbarque, setEditingLocaisEmbarque] = useState<
    PortoAeroporto[]
  >([]);
  const [editingServicoValor, setEditingServicoValor] = useState("0");
  const [editingRow, setEditingRow] = useState<GridRowData>();

  const handleEditingLocaisChange = (
    event: SyntheticEvent<Element, Event>,
    value:
      | {
          id: string | number | undefined;
          value: string | undefined;
        }[]
      | null
  ) => {
    let newSelectedLocais: PortoAeroporto[] = [];
    value?.forEach((value) => {
      const selectedLocal = locais.find((local) => local.id === value.id);
      selectedLocal && newSelectedLocais.push(selectedLocal);
    });
    setEditingLocaisEmbarque(newSelectedLocais);
  };

  function handleSelectRow(params: GridRowParams) {
    setIsEditingServico(true);
    setEditingLocaisEmbarque(params.row.locaisEmbarque);
    setEditingServicoValor(params.row.valor);
    setEditingRow(params.row);
  }

  function handleUpdateValor() {
    if (editingRow?.isServico) {
      updateValor({
        groupId: editingRow?.servicoId,
        tipo: "servico",
        nome: editingRow?.nome,
        novoValor: Number(editingServicoValor),
        locaisEmbarque: editingLocaisEmbarque,
      });
    } else {
      updateValor({
        groupId: editingRow?.cadastroId,
        tipo: "cadastro",
        nome: editingRow?.nome,
        novoValor: Number(editingServicoValor),
        locaisEmbarque: [],
      });
    }

    setIsEditingServico(false);
  }

  async function updateValor({
    groupId,
    tipo,
    nome,
    novoValor,
    locaisEmbarque,
  }: {
    groupId: number;
    tipo: "servico" | "cadastro";
    nome: string;
    novoValor: number;
    locaisEmbarque: PortoAeroporto[];
  }): Promise<void> {
    switch (tipo) {
      case "servico":
        updateServicoValor({
          id: groupId,
          nomeValor: nome,
          valor: novoValor,
          propostaId: params.id,
          locaisEmbarque,
        });
        break;
      case "cadastro":
        updateCadastroValor({
          id: groupId,
          nomeValor: nome,
          valor: novoValor,
          propostaId: params.id,
        });
        break;
      default:
        break;
    }
  }

  return (
    <PageLayout
      title={"Proposta " + params.id}
      icon={<HistoryEduIcon color={"secondary"} />}
    >
      {isLoading || isError ? (
        <LoadingIndicator />
      ) : (
        <Grid container spacing={2} padding={2}>
          <FormHeader marginTop="0px">Detalhes da proposta</FormHeader>
          <Grid item sm={12}>
            <Typography
              textTransform={"uppercase"}
              color={"gray"}
              fontSize={14}
            >
              {"ID da Proposta: " + proposta?.id}
            </Typography>
            <Typography>
              {"Data: " + proposta?.createdAt?.toLocaleDateString() ?? ""}
            </Typography>
          </Grid>
          <Grid item sm={12}>
            <FormHeaderMedium>Cliente</FormHeaderMedium>
            <Typography>{"Nome: " + proposta.nomeCliente}</Typography>
            <Typography>
              {"CPF/CNPJ: " +
                (proposta.cpforcnpj
                  ? formatCpfOrCnpj(proposta.cpforcnpj)
                  : "CPF/CNPJ não cadastrado")}
            </Typography>
            <Typography>
              {`Código: ${
                proposta.clienteId == null
                  ? "Cliente não cadastrado"
                  : proposta.clienteId
              }`}
            </Typography>
          </Grid>
          {isEditing && cadastros.length > 0 && (
            <>
              <FormHeaderMedium marginTop="0px" marginBottom="0px">
                Inserir Cadastros
              </FormHeaderMedium>
              {cadastros.map((cadastro) => (
                <Grid item sm={12} marginTop="0px">
                  <SwitchStyled
                    checked={propostaHasCadastro(cadastro)}
                    key={cadastro.id}
                    label={cadastro.nome}
                    onChange={(e) => handleToggleCadastro(cadastro)}
                  ></SwitchStyled>
                </Grid>
              ))}
            </>
          )}
          {isEditing && !isEditingServico && (
            <>
              <FormHeaderMedium marginTop="0px" marginBottom="10px">
                Inserir valores de serviços
              </FormHeaderMedium>
              <Grid item sm={4}>
                <FormControl fullWidth required>
                  <SelectStyled
                    options={
                      mercadorias?.map((mercadoria) => ({
                        id: mercadoria.id,
                        value: mercadoria.nome,
                      })) ?? []
                    }
                    onChangeAction={handleMercadoriaChange}
                    label={"Mercadoria"}
                  ></SelectStyled>
                </FormControl>
              </Grid>
              <Grid item sm={4}>
                <FormControl fullWidth required>
                  <SelectStyled
                    options={filteredModais.map((modal) => ({
                      id: modal.id,
                      value: modal.name,
                    }))}
                    onChangeAction={handleModalChange}
                    label={"Modal"}
                  ></SelectStyled>
                </FormControl>
              </Grid>
              <Grid item sm={4}>
                <FormControl fullWidth required>
                  <SelectStyled
                    options={filteredServicos.map((servico) => ({
                      id: servico.id,
                      value: servico.nome,
                    }))}
                    onChangeAction={handleServicoChange}
                    label={"Serviço"}
                  ></SelectStyled>
                </FormControl>
              </Grid>
              <Grid item sm={4}>
                <FormControl fullWidth required>
                  <SelectStyled
                    options={filteredServicosValores.map((servico) => ({
                      id: servico.id,
                      value: servico.nomeValor,
                    }))}
                    onChangeAction={handleChangeServicoValor}
                    label={"Valor do Serviço"}
                  ></SelectStyled>
                </FormControl>
              </Grid>
              <Grid item sm={5}>
                <FormControl fullWidth>
                  <MultiSelectStyled
                    options={locais.map((local) => ({
                      id: local.id,
                      value: local.description + " - " + local.sigla,
                    }))}
                    onChangeAction={handleLocaisChange}
                    label={"Locais de Embarque / Desembarque"}
                  ></MultiSelectStyled>
                </FormControl>
              </Grid>
              <Grid item sm={3}>
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  onClick={handleServicoValorAddition}
                  disabled={!selectedServicoValor}
                >
                  Inserir
                </Button>
              </Grid>
            </>
          )}
          {isEditing && isEditingServico && (
            <>
              <FormHeaderMedium marginTop="0px" marginBottom="10px">
                Atualizar valores de serviços
              </FormHeaderMedium>
              <Grid item sm={4}>
                <TextFieldStyled
                  label={"Valor do Serviço"}
                  value={editingServicoValor}
                  type="number"
                  onChange={(e) => setEditingServicoValor(e.target.value)}
                />
              </Grid>
              <Grid item sm={5}>
                <FormControl fullWidth>
                  <MultiSelectStyled
                    disabled={!editingRow?.isServico}
                    options={locais.map((local) => ({
                      id: local.id,
                      value: local.description + " - " + local.sigla,
                    }))}
                    controlledValues={editingLocaisEmbarque.map((local) => ({
                      id: local.id,
                      value: local.description + " - " + local.sigla,
                    }))}
                    onChangeAction={handleEditingLocaisChange}
                    label={"Locais de Embarque / Desembarque"}
                  ></MultiSelectStyled>
                </FormControl>
              </Grid>
              <Grid item sm={3}>
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  onClick={() => handleUpdateValor()}
                >
                  Salvar
                </Button>
              </Grid>
            </>
          )}
          <Grid item sm={12}>
            <FormHeaderMedium>Honorários</FormHeaderMedium>
            {proposta.servicos && (
              <DataTable
                onRowClick={handleSelectRow}
                columns={modelosLancamentosColumns}
                rows={valoresProposta}
              ></DataTable>
            )}
          </Grid>
          <FormHeader marginTop="0px">
            {"Valor total: " + totalValue}
          </FormHeader>
          <Grid item sm={12}>
            <FormHeaderMedium marginBottom="10px">Follow-ups</FormHeaderMedium>
            {proposta?.followUps && (
              <DataTable
                onCellClick={(params) => handleGoToFollowups()}
                columns={columns}
                rows={proposta.followUps.map((followup, index) => ({
                  id: index,
                  data: followup.data,
                  status: followup.status,
                  alerta: followup.dataAlerta,
                  comentario: followup.comentario,
                }))}
              ></DataTable>
            )}
          </Grid>
          {isEditing && (
            <Grid item sm={12}>
              <FormHeaderMedium marginBottom="10px">
                Adicionar Anexos
              </FormHeaderMedium>
              <FileSubmitForm
                propostaId={proposta.id.toString()}
                uploadedCallback={handleAddFile}
                isSubmitting={isSubmitting}
                setIsSubmitting={setIsSubmitting}
              />
            </Grid>
          )}
          <Grid item sm={12}>
            <FormHeaderMedium marginBottom="10px">Anexos</FormHeaderMedium>
            <DataTable
              columns={columnsAnexos}
              rows={
                proposta.anexos?.map((arquivo) => ({
                  id: arquivo.id,
                  data: arquivo.data,
                  tipo: arquivo.tipo,
                  descricao: arquivo.nome,
                  validade: arquivo.validade,
                })) ?? []
              }
            ></DataTable>
          </Grid>

          <Grid item sm={12} textAlign={"right"}>
            <Button
              variant="contained"
              color="secondary"
              sx={{ marginRight: "10px" }}
              onClick={() => history.push("/comercial/propostas")}
            >
              Voltar
            </Button>

            <Button
              variant="contained"
              color="primary"
              onClick={
                isEditing ? () => setIsEditing(false) : () => setIsEditing(true)
              }
            >
              {isEditing ? "Visualizar" : "Editar"}
            </Button>
          </Grid>
        </Grid>
      )}
    </PageLayout>
  );
};

export default PropostasDetails;
