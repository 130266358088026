const REGIME_TRIBUTACAO_RECOLHIMENTO_INTEGRAL_CODE = '1';
const REGIME_TRIBUTACAO_IMUNIDADE_CODE = '2';
const REGIME_TRIBUTACAO_ISENCAO_CODE = '3';
const REGIME_TRIBUTACAO_REDUCAO_CODE = '4';
const REGIME_TRIBUTACAO_SUSPENSAO_CODE = '5';
const REGIME_TRIBUTACAO_NAO_INCIDENCIA_CODE = '6';
const REGIME_TRIBUTACAO_TRIBUTACAO_SIMPLIFICADA_CODE = '7';
const REGIME_TRIBUTACAO_TRIBUTACAO_SIMPLIFICADA_DE_BAGAGEM_CODE = '8';
const REGIME_TRIBUTACAO_PAGAMENTO_REALIZADO_CODE = '9';
const REGIME_TRIBUTACAO_DIFERIDO_CODE = '10';
const REGIME_TRIBUTACAO_EXONERADO_CODE = '11';

const configureRegimeTributacaoRecolhimentoIntegralMatcher = (regimesTributacao) => {
  const isRegimeTributacaoRecolhimentoIntegral = (regimeTributacaoId) => {
    const foundRegimeTributacao = regimesTributacao
      .find((regimeTributacao) => (
        regimeTributacao?.id === regimeTributacaoId
      ));

    return (
      foundRegimeTributacao?.code === REGIME_TRIBUTACAO_RECOLHIMENTO_INTEGRAL_CODE
    );
  };

  return isRegimeTributacaoRecolhimentoIntegral;
};

const configureGeneralRegimeTributacaoMatcherWith = (regimesTributacao) => {
  const isSomeRegimeTributacaoConfiguredById = (id, codesToCompare = []) => {
    const foundRegimeTributacao = regimesTributacao.find((regimeTributacao) => (
      regimeTributacao?.id === id
    ));

    return codesToCompare.includes(foundRegimeTributacao?.code);
  };

  return isSomeRegimeTributacaoConfiguredById;
};

const configureSpecificRegimeTributacaoMatcherWith = (defaultCode) => {
  const configureSpecificMatcherWith = (list) => {
    const isRegimeTributacaoConfiguredById = (id) => {
      const foundRegimeTributacao = list.find((regimeTributacao) => (
        regimeTributacao?.id === id
      ));

      return (
        defaultCode === foundRegimeTributacao?.code
      );
    };

    return isRegimeTributacaoConfiguredById;
  };

  return configureSpecificMatcherWith;
};

const getRegimeTributacaoIdBy = (code) => {
  const configureMatcherWith = (list) => {
    const foundRegimeTributacao = list.find((regimeTributacao) => (
      regimeTributacao?.code === code
    ));

    return foundRegimeTributacao?.id;
  };

  return configureMatcherWith;
};

const configureSpecificRegimeTributacaoReducaoMatcher = (
  configureSpecificRegimeTributacaoMatcherWith(REGIME_TRIBUTACAO_REDUCAO_CODE)
);

const configureSpecificRegimeTributacaoSuspensaoMatcher = (
  configureSpecificRegimeTributacaoMatcherWith(REGIME_TRIBUTACAO_SUSPENSAO_CODE)
);

const configureSpecificRegimeTributacaoImunidadeMatcher = (
  configureSpecificRegimeTributacaoMatcherWith(REGIME_TRIBUTACAO_IMUNIDADE_CODE)
);

export {
  REGIME_TRIBUTACAO_RECOLHIMENTO_INTEGRAL_CODE,
  REGIME_TRIBUTACAO_IMUNIDADE_CODE,
  REGIME_TRIBUTACAO_ISENCAO_CODE,
  REGIME_TRIBUTACAO_REDUCAO_CODE,
  REGIME_TRIBUTACAO_SUSPENSAO_CODE,
  REGIME_TRIBUTACAO_NAO_INCIDENCIA_CODE,
  REGIME_TRIBUTACAO_TRIBUTACAO_SIMPLIFICADA_CODE,
  REGIME_TRIBUTACAO_TRIBUTACAO_SIMPLIFICADA_DE_BAGAGEM_CODE,
  REGIME_TRIBUTACAO_PAGAMENTO_REALIZADO_CODE,
  REGIME_TRIBUTACAO_DIFERIDO_CODE,
  REGIME_TRIBUTACAO_EXONERADO_CODE,
};

export {
  configureRegimeTributacaoRecolhimentoIntegralMatcher,
  configureGeneralRegimeTributacaoMatcherWith,
  configureSpecificRegimeTributacaoReducaoMatcher,
  configureSpecificRegimeTributacaoSuspensaoMatcher,
  configureSpecificRegimeTributacaoImunidadeMatcher,
  getRegimeTributacaoIdBy,
};
