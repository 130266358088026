import { Grid } from '@material-ui/core';
import { useForm, useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import QCXFormSubtitle from '../../../../../../../components/form-title/QCXFormSubtitle';
import QCXFinalMultilineTextField from '../../../../../../../shared-components/final-text-field/QCXFinalMultilineTextField';
import QCXFinalNumericDecimalFieldRender from '../../../../../../../components/fatura/QCXFinalNumericDecimalFieldRender';
import QCXFinalAlternativeCurrencyFieldRender from '../../../../../../../components/fatura/QCXFinalAlternativeCurrencyFieldRender';
import QCXFinalBondManagerOld from '../../../../../../../shared-components/final-gerenciador-relacionamento/QCXFinalBondManagerOld';
import { unnormalizeNumeral } from '../../../../../../../utils/general/general-utils';
import { formatBrazilianNumericDecimal } from '../../../../../../../utils/hooks/form/field/formatters';
import { useEffect, useMemo } from 'react';
import {
  vinculoDespesaReceitaActions,
  vinculoDespesaReceitaSelectors,
} from '../../../../../../../features/vinculo-despesa-receita/vinculoDespesaReceitaSlice';
import { useSelector } from 'react-redux';
import { selectCusteio } from '../../../api/custeiosSelectors';
import { Custeio, Mercadoria } from '../../../custeio.types';

const CusteiosMercadoriaFormGroup = () => {
  const { t } = useTranslation();
  const form = useForm();
  const { values }: { values: Mercadoria } = useFormState();
  const custeio: Custeio = useSelector(selectCusteio);

  const despesasMercadoria = useMemo(() => {
    const mercadoriaId = values.id;
    const despesas = custeio.despesas.map((despesa) => {
      let filtered = despesa.despesasMercadoria.filter(
        (despesaMercadoria) => despesaMercadoria.mercadoria.id === mercadoriaId
      );
      filtered = filtered.map((despesaMercadoria) => ({
        ...despesaMercadoria,
        description: despesa.despesaReceita.description,
      }));
      return filtered;
    });
    const flattened = despesas.flat();
    return flattened;
  }, [custeio, values.id]);

  useEffect(() => {
    const valorTotalDespesasReal = despesasMercadoria.reduce(
      (acc, despesaMercadoria) => acc + despesaMercadoria.valorReal,
      0
    );
    const valorTotalDespesasMoeda = despesasMercadoria.reduce(
      (acc, despesaMercadoria) => acc + despesaMercadoria.valorMoeda,
      0
    );
    const valorTotalDespesasDolar = despesasMercadoria.reduce(
      (acc, despesaMercadoria) => acc + despesaMercadoria.valorDolar,
      0
    );
    form.change('despesasMercadoria', despesasMercadoria);
    form.change('valorTotalDespesasReal', unnormalizeNumeral(valorTotalDespesasReal, formatBrazilianNumericDecimal(2)));
    form.change(
      'valorTotalDespesasMoeda',
      unnormalizeNumeral(valorTotalDespesasMoeda, formatBrazilianNumericDecimal(2))
    );
    form.change(
      'valorTotalDespesasDolar',
      unnormalizeNumeral(valorTotalDespesasDolar, formatBrazilianNumericDecimal(2))
    );
  }, [despesasMercadoria, values]);

  const despesaReceitaListProps = useMemo(
    () => ({
      name: 'despesasMercadoria',
      columns: [
        {
          field: 'description',
          headerName: t('com.muralis.qcx.despesa.despesaReceita'),
          headerAlign: 'left',
          align: 'left',
          flex: 240,
          // valueGetter: ({ row }: { row: any }) => row?.despesaReceita.description || '',
        },
        {
          field: 'tipoRateio',
          headerName: t('com.muralis.qcx.tipoRateio'),
          headerAlign: 'left',
          align: 'left',
          flex: 240,
          // valueGetter: ({ row }: { row: any }) => row?.tipoRateio || '',
        },
        {
          field: 'valorReal',
          headerName: t('com.muralis.qcx.moeda.valorReais'),
          headerAlign: 'right',
          align: 'right',
          flex: 180,
          valueGetter: ({ row }: { row: any }) => row?.valorReal,
          valueFormatter: ({ value }: any) => {
            const unnormalizedValue = unnormalizeNumeral(value, formatBrazilianNumericDecimal(2));

            if (unnormalizedValue) {
              return `R$ ${unnormalizedValue}`;
            }

            if (value) {
              return `R$ ${value}`;
            }

            return '-';
          },
        },
        {
          field: 'valorMoeda',
          headerName: t('com.muralis.qcx.moeda.valorMoeda'),
          headerAlign: 'right',
          align: 'right',
          flex: 180,
          valueGetter: ({ row }: { row: any }) => row?.valorMoeda,
          valueFormatter: ({ value }: any) => {
            const unnormalizedValue = unnormalizeNumeral(value, formatBrazilianNumericDecimal(2));

            if (unnormalizedValue) {
              return `$ ${unnormalizedValue}`;
            }

            if (value) {
              return `$ ${value}`;
            }

            return '-';
          },
        },
        {
          field: 'valorDolar',
          headerName: t('com.muralis.qcx.moeda.valorDolar'),
          headerAlign: 'right',
          align: 'right',
          flex: 180,
          valueGetter: ({ row }: { row: any }) => row?.valorDolar,
          valueFormatter: ({ value }: any) => {
            const unnormalizedValue = unnormalizeNumeral(value, formatBrazilianNumericDecimal(2));

            if (unnormalizedValue) {
              return `U$ ${unnormalizedValue}`;
            }

            if (value) {
              return `U$ ${value}`;
            }

            return '-';
          },
        },
      ],
    }),
    []
  );

  const despesaReceitaFormProps = useMemo(
    () => ({
      rootName: 'ignorableFields.despesasMercadoria',
      fields: [
        {
          name: 'id',
          label: t('com.muralis.qcx.despesa.despesaReceita'),
        },
        {
          name: 'tipoRateio',
          label: t('com.muralis.qcx.tipoRateio'),
        },
        {
          name: 'valorReal',
          label: t('com.muralis.qcx.moeda.valorReais'),
        },
      ],
    }),
    []
  );

  const despesaReceitaReducerConfig = useMemo(
    () => ({
      selectors: {
        selectStatus: vinculoDespesaReceitaSelectors.selectStatus,
        selectMode: vinculoDespesaReceitaSelectors.selectMode,
        selectModel: vinculoDespesaReceitaSelectors.selectModel,
      },
      actions: {
        loading: vinculoDespesaReceitaActions.loading,
        resetStatus: vinculoDespesaReceitaActions.resetStatus,
        changeToUpdateMode: vinculoDespesaReceitaActions.changeToUpdateMode,
        changeToCreateMode: vinculoDespesaReceitaActions.changeToCreateMode,
        resetMode: vinculoDespesaReceitaActions.resetMode,
        setModel: vinculoDespesaReceitaActions.setModel,
        resetModel: vinculoDespesaReceitaActions.resetModel,
      },
    }),
    []
  );

  return (
    <Grid container xs={12} spacing={2}>
      <Grid item xs={12}>
        <QCXFormSubtitle title={'Item'} />
      </Grid>
      {/* Dados do item */}
      <Grid item container xs={12} spacing={2}>
        {/* Especificação da Mercadoria */}
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <QCXFinalMultilineTextField
            id="text-especificacao-multiline-field"
            key="text-especificacao-multiline-field"
            label={t('com.muralis.qcx.especificacao.especificacaoMercadoria')}
            disabled
            rows={5}
            maxLength={3900}
            type={undefined}
            required={false}
            validate={undefined}
            children={undefined}
            remaningCharactersInfo={undefined}
            name={'adicionais.especificacaoCompleta'}
          />
        </Grid>
        {/* Valor Unitário */}
        <Grid item xs={12} sm={4} md={4} lg={4}>
          <QCXFinalNumericDecimalFieldRender
            id="numeric-decimal-valor-unitario-field"
            label={t('com.muralis.qcx.valorUnitario')}
            disabled
            scale={7}
            required={false}
            validate={undefined}
            name={'valorUnitarioReal'}
          />
        </Grid>
        {/* Quantidade */}
        <Grid item xs={12} sm={4} md={4} lg={4}>
          <QCXFinalNumericDecimalFieldRender
            id="numeric-decimal-quantidade-field"
            label={t('com.muralis.qcx.quantidade.label')}
            disabled
            validate={undefined}
            scale={5}
            required={undefined}
            name={'quantidade'}
          />
        </Grid>
        {/* Valor Total do Item */}
        <Grid item xs={12} sm={4} md={4} lg={4}>
          <QCXFinalAlternativeCurrencyFieldRender
            id="currency-valor-total-item-field"
            label={t('com.muralis.qcx.mercadoria.valorTotalItem')}
            disabled
            name={'valorTotalReal'}
          />
        </Grid>
        {/* Valor Total CIF da mercadoria em R$ */}
        <Grid item xs={12} sm={4} md={4} lg={4}>
          <QCXFinalAlternativeCurrencyFieldRender
            id="currency-valor-cif-reais-item-field"
            label={'CIF em Reais'}
            disabled
            name={'valorCifReal'}
          />
        </Grid>
        {/* Valor Total CIF da mercadoria na moeda */}
        <Grid item xs={12} sm={4} md={4} lg={4}>
          <QCXFinalAlternativeCurrencyFieldRender
            id="currency-valor-cif-moeda-item-field"
            label={'CIF na Moeda'}
            disabled
            name={'valorCifMoeda'}
          />
        </Grid>
        {/* Valor Total CIF da mercadoria em dólar */}
        <Grid item xs={12} sm={4} md={4} lg={4}>
          <QCXFinalAlternativeCurrencyFieldRender
            id="currency-valor-cif-dolar-item-field"
            label={'CIF em Dólar'}
            disabled
            name={'valorCifDolar'}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <QCXFormSubtitle title={'Despesas Rateadas'} />
      </Grid>
      {/* Valor Total das despesas rateadas em reais */}
      <Grid item container xs={12} spacing={2}>
        <Grid item xs={12} sm={4} md={4} lg={4}>
          <QCXFinalAlternativeCurrencyFieldRender
            id="currency-valor-despesas-real-item-field"
            name="valorTotalDespesasReal"
            label={'Total das Despesas em Reais'}
            disabled
          />
        </Grid>
        {/* Valor Total das despesas rateadas na moeda */}
        <Grid item xs={12} sm={4} md={4} lg={4}>
          <QCXFinalAlternativeCurrencyFieldRender
            id="currency-valor-despesas-moeda-item-field"
            name="valorTotalDespesasMoeda"
            label={'Total das Despesas na Moeda'}
            disabled
          />
        </Grid>
        {/* Valor Total das despesas rateadas em Dólar */}
        <Grid item xs={12} sm={4} md={4} lg={4}>
          <QCXFinalAlternativeCurrencyFieldRender
            id="currency-valor-despesas-dolar-item-field"
            name="valorTotalDespesasDolar"
            label={'Total das Despesas em Dólar'}
            disabled
          />
        </Grid>
      </Grid>
      <Grid item container xs={12} spacing={2}>
        <QCXFinalBondManagerOld
          listProps={despesaReceitaListProps}
          formProps={despesaReceitaFormProps}
          reducerConfig={despesaReceitaReducerConfig}
          handleAdd={() => {}}
          handleUpdate={() => {}}
          handleAlreadyExists={() => {}}
          disableButtons={true}
          readOnlyMode={true}
          isParentConsult={undefined}
          handleRemove={undefined}
          onClearDecorator={undefined}
          onConsultDecorator={undefined}
          onAddDecorator={undefined}
          controlButtonsGridProps={undefined}
          children={undefined}
        />
      </Grid>
    </Grid>
  );
};

export default CusteiosMercadoriaFormGroup;
