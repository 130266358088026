import { AppBar, Toolbar, Typography } from '@mui/material';

const PageTitle = ({ title, icon }: { title: string; icon: JSX.Element }) => {
  return (
    <AppBar
      position="static"
      style={{ height: '53px', justifyContent: 'center' }}
      // sx={{
      //   width: '100%',
      //   height: 50,
      //   backgroundColor: 'primary.main',
      // }}
      // alignItems="center"
    >
      <Toolbar style={{ marginLeft: '-2px' }}>
        {icon}
        <Typography
          color={'white'}
          style={{ fontWeight: 'bolder', marginLeft: 7, marginTop: 2 }}
        >
          {title.toUpperCase()}
        </Typography>
      </Toolbar>
    </AppBar>
  );
};

export default PageTitle;
