import { LoadingButton } from "@mui/lab";
import { FormControl, Grid, Stack, TextField, Typography } from "@mui/material";
import {
  ChangeEvent,
  Dispatch,
  FormEvent,
  Fragment,
  SetStateAction,
  useState,
} from "react";
import SelectStyled from "../../../../common/components/input/selectStyled";
import { uploadFile } from "../../../../common/services/propostasApi";

import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useSelector } from "react-redux";
import { selectTiposDocumentos } from "../../../../../features/tipo-documento/tipoDocumentoSlice";
import { DocumentInterface } from "../../../../common/types/document";
import { PropostaFile } from "./propostaFile";
import { selectUnidadeSelecionada } from "../../../../../features/usuario-logado/usuarioLogadoSlice";
import { useKeycloak } from "@react-keycloak/web";

const FileSubmitForm = ({
  propostaId,
  isSubmitting,
  setIsSubmitting,
  uploadedCallback,
}: {
  propostaId: string;
  isSubmitting: boolean;
  setIsSubmitting: Dispatch<SetStateAction<boolean>>;
  uploadedCallback: (file: PropostaFile) => void;
}) => {
  const { keycloak } = useKeycloak();
  const { token } = keycloak;
  const selectedUnidade: number = useSelector(selectUnidadeSelecionada);
  const tiposDocumentos: DocumentInterface[] = useSelector(
    selectTiposDocumentos
  );
  const [inputKey, setInputKey] = useState("random-key");
  const [selectedFile, setSelectedFile] = useState<File | null>();
  const [file, setFile] = useState<PropostaFile>();

  const handleFileSelection = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      if (e.target.files[0]) {
        if (e.target.files[0].size > 9437184) {
          alert("O limite do tamanho do arquivo é de 9MB");
        } else {
          setSelectedFile(e.target.files[0]);
          setFile({ ...file, nome: e.target.files[0].name });
        }
      }
    } else {
      setSelectedFile(null);
    }
  };

  const handleFileUpload = async (e: FormEvent<HTMLFormElement>) => {
    if (!selectedFile) {
      return;
    }

    setIsSubmitting(true);
    e.preventDefault();

    const { url } = await uploadFile(
      selectedFile,
      selectedUnidade,
      propostaId,
      selectedFile.name,
      token!
    );

    const newFile = {
      ...file,
      data: new Date().toLocaleDateString("pt-BR"),
      path: url,
    };
    uploadedCallback(newFile);
    setSelectedFile(null);
    setFile(undefined);
    setInputKey(new Date().getTime().toString());
    setIsSubmitting(false);
  };

  const handleDocumentTypeChange = (id: number) => {
    setFile({
      ...file,
      tipo: tiposDocumentos.find((tipo) => tipo.id === id),
    });
  };

  const handleDocumentDateChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFile({
      ...file,
      validade: new Date(e.target.value).toLocaleDateString("pt-BR"),
    });
  };

  return (
    <Fragment>
      <form onSubmit={handleFileUpload}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div
              style={{
                border: "1px dashed #ccc",
                borderRadius: "5px",
                minHeight: "100px",
                textAlign: "center",
              }}
            >
              <input
                type="file"
                accept="application/pdf"
                id="input-file-upload"
                key={inputKey}
                onChange={handleFileSelection}
                style={{ display: "none" }}
              />
              <label id="label-file-upload" htmlFor="input-file-upload">
                <Stack
                  style={{
                    alignItems: "center",
                    cursor: "pointer",
                    margin: "10px 0 0 0",
                    color: "gray",
                  }}
                >
                  <CloudUploadIcon sx={{ width: 40, height: 40 }} />
                  <Typography>Clique para selecionar o arquivo</Typography>
                </Stack>
              </label>
            </div>
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <TextField
                label="Arquivo"
                type={"text"}
                disabled
                size="small"
                value={selectedFile?.name ?? ""}
                InputLabelProps={{
                  shrink: true,
                }}
              ></TextField>
            </FormControl>
          </Grid>
          <Grid item xs={5}>
            <FormControl fullWidth required>
              <SelectStyled
                options={tiposDocumentos.map((documento, index) => ({
                  id: documento.id,
                  value: documento.description + " - " + documento.code,
                }))}
                key={`select-${inputKey}`}
                onChangeAction={(e, value) => {
                  handleDocumentTypeChange((value?.id as number) ?? 0);
                }}
                label={"Tipo de Documento"}
              ></SelectStyled>
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl fullWidth required>
              <TextField
                size="small"
                id="date"
                label="Data de Validade"
                type="date"
                key={`date-${inputKey}`}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={handleDocumentDateChange}
              />
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <LoadingButton
              disabled={
                selectedFile == null ||
                file?.tipo === undefined ||
                file.validade === undefined
              }
              loading={isSubmitting}
              type="submit"
              variant="contained"
              fullWidth
              color="secondary"
            >
              Inserir
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </Fragment>
  );
};

export default FileSubmitForm;
