import { IconButton } from '@material-ui/core';
import { Domain, Search as PageviewIcon, Delete as DeleteIcon } from '@material-ui/icons';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import ArmazemContext from '../../../../../contexts/registrations/armazem/ArmazemContext';
import {
  activateByIdAsync, fetchAllAsync, inactivateByIdAsync,
  resetBackgroundMode,
  resetMode, resetModel as resetArmazem, resetSubMode, selectArmazens,
} from '../../../../../features/armazens/armazemSlice';
import {
  changeControlTo as changeControlPaisTo,
  resetList as resetPaisList,
} from '../../../../../features/pais/paisSlice';
import {
  resetList as resetEstadoList,
} from '../../../../../features/estado/estadoSlice';
import {
  resetList as resetCidadeList,
} from '../../../../../features/cidade/cidadeSlice';
import {
  ALTERNATIVE_LOADING_STATUS,
} from '../../../../../features/status';
import { resetList as resetUnidadeLocalList } from '../../../../../features/unidade-local-rfb/unidadeLocalRfbSlice';
import { resetList as resetSetorLotacaoList } from '../../../../../features/setor-lotacao/setorLotacaoSlice';
import { resetList as resetRecintoAduaneiroList } from '../../../../../features/recinto-aduaneiro/recintoAduaneiroSlice';
import { resetList as resetTabelaArmazenagemList } from '../../../../../features/tabela-armazenagem/tabelaArmazenagemSlice';
import QCXSimpleConsultPageTemplate from '../../../../../templates/simple-consult-page/QCXSimpleConsultPageTemplate';
import { IDLE_CONTROL } from '../../../../../features/config-control';
import { userHasDeleteRole } from '../../../../../components/perfil/perfil-utils';

export default function ArmazemConsultPage({ authInfo = {} }) {
  const { t } = useTranslation();
  const history = useHistory();

  const {
    status,
  } = useContext(ArmazemContext);

  const dispatch = useDispatch();

  const armazens = useSelector(selectArmazens);

  const isAlternativeLoading = useMemo(() => status === ALTERNATIVE_LOADING_STATUS, [status]);

  const fetchAll = async () => {
    dispatch(fetchAllAsync());
  };

  const resetModes = useCallback(() => {
    dispatch(resetMode());
    dispatch(resetBackgroundMode());
    dispatch(resetSubMode());
  }, []);

  const resetModels = useCallback(() => {
    dispatch(resetArmazem());
  }, []);

  const resetDependencies = useCallback(() => {
    dispatch(changeControlPaisTo(IDLE_CONTROL));
    dispatch(resetUnidadeLocalList());
    dispatch(resetSetorLotacaoList());
    dispatch(resetRecintoAduaneiroList());
    dispatch(resetTabelaArmazenagemList());
    dispatch(resetPaisList());
    dispatch(resetEstadoList());
    dispatch(resetCidadeList());
  }, []);

  useEffect(() => {
    fetchAll();
    resetModes();
    resetModels();
    resetDependencies();
  }, []);

  const handleActivateInactivate = async (event, id) => {
    event.preventDefault();

    const { active } = armazens.find(
      (item) => item.id === id
    );

    const isActivation = !active;

    if (isActivation) {
      dispatch(activateByIdAsync(id));
    } else {
      dispatch(inactivateByIdAsync(id));
    }
  };

  const handleClickOnEditButton = useCallback(
    (_event, id) => (
      history.push(
        t('com.muralis.qcx.url.moduloOperacionaisArmazensDetalhes', { id })
      )
    ), [history]
  );
  const breadcrumbs = [
    {
      link: {
        to: '/',
        name: t('com.muralis.qcx.inicio'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.cadastros'),
      },
    },
    {
      link: {
        to: t('com.muralis.qcx.url.moduloOperacionais'),
        name: t('com.muralis.qcx.operacionais'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.armazem.labelPlural'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.principal'),
      },
    },
  ];

  const columns = [
    {
      field: 'id',
      headerName: t('com.muralis.qcx.codigo'),
      headerAlign: 'center',
      align: 'center',
      flex: 150,
      valueGetter: ({ row }) => (
        row?.id
      ),
    },
    {
      field: 'nomeResumido',
      headerName: t('com.muralis.qcx.nome'),
      type: 'string',
      flex: 445,
      valueGetter: ({ row }) => (
        row?.pessoa?.nomeResumido
      ),
    },

    {
      field: 'actions',
      headerName: t('com.muralis.qcx.acoes.label'),
      headerAlign: 'center',
      align: 'center',
      flex: 150,
      renderCell: ({ row: { id, active } }) => (
        <>
          {userHasDeleteRole(authInfo, 'armazem') && (
          <IconButton
            key={`stw-active${id}`}
            value={active}
            name={`stw-active-${id}`}
            onClick={(event) => handleActivateInactivate(event, id)}
          >
            <DeleteIcon color="error" />
          </IconButton>
          )}
          <IconButton
            key={`btn-view-${id}`}
            name={`btn-view-${id}`}
            onClick={(event) => handleClickOnEditButton(event, id)}
          >
            <PageviewIcon color="primary" />
          </IconButton>
        </>
      ),
    }
  ];

  const templateProps = {
    page: {
      title: t('com.muralis.qcx.armazem.labelPlural'),
      icon: <Domain />,
      breadcrumbs,
    },
    control: {
      buttonNew: {
        description: t('com.muralis.qcx.armazem.novoArmazem'),
        path: t('com.muralis.qcx.url.moduloOperacionaisArmazensNovo'),
      },
    },
    table: {
      columns,
    },
  };

  const filterPropGetter = useCallback(
    (item) => item.description,
    []
  );

  return (
    <QCXSimpleConsultPageTemplate
      pageProps={templateProps.page}
      controlProps={templateProps.control}
      tableProps={templateProps.table}
      tableData={armazens}
      isLoading={isAlternativeLoading}
      filterPropGetter={filterPropGetter}
      authInfo={authInfo}
      requiredRoles={['armazem']}
    />
  );
}
