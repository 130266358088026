import { useMemo } from 'react';
import useQueryParam from '../router/useQueryParam';

const PARAM_NAME = 'mode';

const createParamBy = (mode) => (
  `${PARAM_NAME}=${mode}`
);

const ModeParamConstants = {
  READ_ONLY: 'readOnly',
};

const ModeParamCreator = {
  getReadOnly: () => (
    createParamBy(
      ModeParamConstants.READ_ONLY
    )
  ),
};

export default function useModeParam() {
  const modeParam = useQueryParam(PARAM_NAME);

  const wrap = useMemo(() => ({
    readOnly: modeParam === ModeParamConstants.READ_ONLY,
  }), [modeParam]);

  return wrap;
}

export {
  ModeParamConstants,
  ModeParamCreator,
};
