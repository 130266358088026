import { getOptionByValueInList } from '../../utils/general/general-utils';
import i18n from '../../i18n';

const TIPO_DOCUMENTO_CHEGADA_CARGA_TERMO_ENTRADA = 'TERMO_ENTRADA';
const TIPO_DOCUMENTO_CHEGADA_CARGA_MANIFESTO_CARGA = 'MANIFESTO_CARGA';
const TIPO_DOCUMENTO_CHEGADA_CARGA_DTA = 'DTA';
const TIPO_DOCUMENTO_CHEGADA_CARGA_MIC_DTA = 'MIC_DTA';

const TIPO_DOCUMENTO_CHEGADA_CARGA_LIST = [
  {
    label: i18n.t('com.muralis.qcx.termoEntrada').toUpperCase(),
    value: TIPO_DOCUMENTO_CHEGADA_CARGA_TERMO_ENTRADA,
  },
  {
    label: i18n.t('com.muralis.qcx.chegadaCarga.manifestoCarga').toUpperCase(),
    value: TIPO_DOCUMENTO_CHEGADA_CARGA_MANIFESTO_CARGA,
  },
  {
    label: i18n.t('com.muralis.qcx.chegadaCarga.DTA').toUpperCase(),
    value: TIPO_DOCUMENTO_CHEGADA_CARGA_DTA,
  },
  {
    label: i18n.t('com.muralis.qcx.chegadaCarga.MICDAC').toUpperCase(),
    value: TIPO_DOCUMENTO_CHEGADA_CARGA_MIC_DTA,
  },
];

const isTipoDocumentoChegadaCargaTermoEntrada = (value) => (
  TIPO_DOCUMENTO_CHEGADA_CARGA_TERMO_ENTRADA === value
);

const isTipoDocumentoChegadaCargaManifestoCarga = (value) => (
  TIPO_DOCUMENTO_CHEGADA_CARGA_MANIFESTO_CARGA === value
);

const isTipoDocumentoChegadaCargaDta = (value) => (
  TIPO_DOCUMENTO_CHEGADA_CARGA_DTA === value
);

const isTipoDocumentoChegadaCargaMicDta = (value) => (
  TIPO_DOCUMENTO_CHEGADA_CARGA_MIC_DTA === value
);

const getTipoDocumentoChegadaCargaLabelByValue = (value) => {
  const foundOption = getOptionByValueInList(TIPO_DOCUMENTO_CHEGADA_CARGA_LIST, value);
  return foundOption && foundOption?.label;
};

export {
  TIPO_DOCUMENTO_CHEGADA_CARGA_TERMO_ENTRADA,
  TIPO_DOCUMENTO_CHEGADA_CARGA_MANIFESTO_CARGA,
  TIPO_DOCUMENTO_CHEGADA_CARGA_DTA,
  TIPO_DOCUMENTO_CHEGADA_CARGA_MIC_DTA,
  TIPO_DOCUMENTO_CHEGADA_CARGA_LIST,
};

export {
  isTipoDocumentoChegadaCargaTermoEntrada,
  isTipoDocumentoChegadaCargaManifestoCarga,
  isTipoDocumentoChegadaCargaDta,
  isTipoDocumentoChegadaCargaMicDta,
  getTipoDocumentoChegadaCargaLabelByValue,
};
