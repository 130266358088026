import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createFilterOptions } from '@material-ui/lab/useAutocomplete';
import _ from 'lodash';
import {
  fetchAllAsync,
  selectModelosInformacoesComplementares,
} from '../../features/modelo-informacoes-complementares/modeloInformacoesComplementaresSlice';
import QCXAutocompleteSelect from '../autocomplete-select/QCXAutocompleteSelect';

export default function QCXAutocompleteModeloInformacaoComplementar({
  label,
  name,
  initialValues,
  ...restProps
}) {
  const dispatch = useDispatch();

  const list = useSelector(selectModelosInformacoesComplementares);

  useEffect(() => {
    dispatch(fetchAllAsync());
  }, []);

  const [defaultValue, setDefaultValue] = useState('');

  useEffect(() => {
    const value = list.find((item) => item?.id === _.get(initialValues, name));
    if (value) {
      setDefaultValue(value);
    } else {
      setDefaultValue({ id: null, description: '' });
    }
  }, [list, initialValues]);

  const filterOptions = createFilterOptions({
    stringify: (item) => item?.description,
  });

  return (
    <QCXAutocompleteSelect
      label={label}
      id={name}
      name={name}
      options={list}
      filterOptions={filterOptions}
      defaultValue={defaultValue}
      getOptionLabel={(item) => item?.description || ''}
      getOptionDisabled={(item) => !item.active}
      externallyFillable
      {...restProps}
    />
  );
}
