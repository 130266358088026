import { createFilterOptions } from '@material-ui/lab/useAutocomplete';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import followUpExportacaoThunks from '../../features/follow-up-exportacao/followUpExportacaoThunks';
import followUpExportacaoSelectors from '../../features/follow-up-exportacao/followUpExportacaoSelectors';
import QCXAutocompleteSelect from '../autocomplete-select/QCXAutocompleteSelect';

export default function QCXSelectFollowUpExportacaoAutocompleteField({
  label,
  name,
  initialValues,
  controlled = false,
  ...restProps
}) {
  const dispatch = useDispatch();

  const list = useSelector(followUpExportacaoSelectors.selectFollowUps);

  const [defaultValue, setDefaultValue] = useState('');

  useEffect(() => {
    if (!controlled) {
      dispatch(followUpExportacaoThunks.fetchAllAsync());
    }
  }, [controlled]);

  useEffect(() => {
    const value = list.find((item) => (
      item?.id === _.get(initialValues, name)
    ));

    if (value) {
      setDefaultValue(value);
    } else {
      setDefaultValue({ id: null, description: '' });
    }
  }, [list, initialValues]);

  const filterOptions = createFilterOptions({
    stringify: (option) => (
      option?.numero
    ),
  });

  return (
    <QCXAutocompleteSelect
      label={label}
      id={name}
      name={name}
      options={list}
      filterOptions={filterOptions}
      defaultValue={defaultValue}
      getOptionLabel={(item) => (
        item?.numero || ''
      )}
      {...restProps}
    />
  );
}
