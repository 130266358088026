import i18n from '../../i18n';

const TED = 'TED';
const DOC = 'DOC';
const DINHEIRO = 'DINHEIRO';
const CHEQUE = 'CHEQUE';
const BOLETO = 'BOLETO';
const PIX = 'PIX';
const DEBITO_EM_CONTA = 'DEBITO_EM_CONTA';

const FORMA_PAGAMENTO_LIST = [
  {
    label: i18n.t('com.muralis.qcx.formaPagamento.TED').toUpperCase(),
    value: TED,
  },
  {
    label: i18n.t('com.muralis.qcx.formaPagamento.DOC').toUpperCase(),
    value: DOC,
  },
  {
    label: i18n.t('com.muralis.qcx.formaPagamento.dinheiro').toUpperCase(),
    value: DINHEIRO,
  },
  {
    label: i18n.t('com.muralis.qcx.formaPagamento.cheque').toUpperCase(),
    value: CHEQUE,
  },
  {
    label: i18n.t('com.muralis.qcx.formaPagamento.boleto').toUpperCase(),
    value: BOLETO,
  },
  {
    label: i18n.t('com.muralis.qcx.formaPagamento.PIX').toUpperCase(),
    value: PIX,
  },
  {
    label: i18n.t('com.muralis.qcx.formaPagamento.debitoConta').toUpperCase(),
    value: DEBITO_EM_CONTA,
  },
];

const isFormaPagamentoTED = (value) => (
  TED === value
);

const isFormaPagamentoDOC = (value) => (
  DOC === value
);
const isFormaPagamentoDinheiro = (value) => (
  DINHEIRO === value
);

const isFormaPagamentoCheque = (value) => (
  CHEQUE === value
);
const isFormaPagamentoBoleto = (value) => (
  BOLETO === value
);

const isFormaPagamentoPIX = (value) => (
  PIX === value
);
const isFormaPagamentoDebidoEmConta = (value) => (
  DEBITO_EM_CONTA === value
);

const FormaPagamentoUtils = Object.freeze({
  TED,
  DOC,
  DINHEIRO,
  CHEQUE,
  BOLETO,
  PIX,
  DEBITO_EM_CONTA,
  isTED: (
    isFormaPagamentoTED
  ),
  isDOC: (
    isFormaPagamentoDOC
  ),
  isDinheiro: (
    isFormaPagamentoDinheiro
  ),
  isCheque: (
    isFormaPagamentoCheque
  ),
  isBoleto: (
    isFormaPagamentoBoleto
  ),
  isPIX: (
    isFormaPagamentoPIX
  ),
  isDebidoEmConta: (
    isFormaPagamentoDebidoEmConta
  ),
  LIST: (
    FORMA_PAGAMENTO_LIST
  ),
});

export default FormaPagamentoUtils;
