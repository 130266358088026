import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import { AUTO_LOAD_CONTROL } from '../config-control';
import {
  CONSULT_MODE,
  CREATE_MODE,
  UPDATE_MODE,
  NONE_MODE,
  BACKGROUND_CREATE_MODE,
  NONE_BACKGROUND_MODE,
  SUB_CREATE_MODE,
  SUB_CONSULT_MODE,
  SUB_UPDATE_MODE,
  NONE_SUB_MODE,
} from '../mode';
import {
  ALTERNATIVE_LOADING_STATUS,
  FAILURE_STATUS,
  IDLE_STATUS,
  LOADING_STATUS,
  PREPARING_ACTION_STATUS,
  SUCCESS_STATUS,
} from '../status';
import {
  activateById,
  fetchAll, fetchById, inactivateById,
} from './servicoAPI';
import i18n from '../../i18n';

const initialState = {
  status: IDLE_STATUS,
  mode: {
    main: NONE_MODE,
    background: NONE_BACKGROUND_MODE,
    sub: NONE_SUB_MODE,
  },
  error: null,
  response: {
    status: null,
    message: null,
    data: null,
  },
  config: {
    control: AUTO_LOAD_CONTROL,
  },
  list: [],
  model: {
    description: null,
    nome: null,
    sigla: null,
    leadTimeTotal: null,
    produto: null,
    tipoDeclaracao: null,
    viaTransporte: null,
    unidadesDeNegocio: [],
    camposAdicionais: [],
    modeloNumerario: null,
    modeloFaturamento: null,
    tabelaSda: null,
  },
  related: {},
};

const fetchAllAsync = createAsyncThunk(
  'servico/fetchAll',
  async () => {
    const { data } = await fetchAll();
    return { data };
  }
);

const fetchByIdAsync = createAsyncThunk(
  'servico/fetchById',
  async (data) => {
    const response = await fetchById(data);
    return {
      response: {
        status: response.status,
        data: response.data,
      },
    };
  }
);

const activateByIdAsync = createAsyncThunk(
  'servico/activateById',
  async (id) => {
    const { status, data } = await activateById(id);
    return { response: { status, data } };
  }
);

const inactivateByIdAsync = createAsyncThunk(
  'servico/inactivateById',
  async (id) => {
    const { status, data } = await inactivateById(id);
    return { response: { status, data } };
  }
);

const servicoSlice = createSlice({
  name: 'servico',
  initialState,
  reducers: {
    changeStatusTo: (state, action) => {
      state.status = action.payload.status;
    },
    loading: (state) => {
      state.status = LOADING_STATUS;
    },
    success: (state) => {
      state.status = SUCCESS_STATUS;
    },
    failure: (state) => {
      state.status = FAILURE_STATUS;
    },
    preparingAction: (state) => {
      state.status = PREPARING_ACTION_STATUS;
    },
    resetStatus: (state) => {
      state.status = IDLE_STATUS;
    },
    changeToCreateMode: (state) => {
      state.mode.main = CREATE_MODE;
    },
    changeToConsultMode: (state) => {
      state.mode.main = CONSULT_MODE;
    },
    changeToUpdateMode: (state) => {
      state.mode.main = UPDATE_MODE;
    },
    resetMode: (state) => {
      state.mode.main = NONE_MODE;
    },
    changeToBackgroundCreateMode: (state) => {
      state.mode.background = BACKGROUND_CREATE_MODE;
    },
    resetBackgroundMode: (state) => {
      state.mode.background = NONE_BACKGROUND_MODE;
    },
    changeToSubCreateMode: (state) => {
      state.mode.sub = SUB_CREATE_MODE;
    },
    changeToSubConsultMode: (state) => {
      state.mode.sub = SUB_CONSULT_MODE;
    },
    changeToSubUpdateMode: (state) => {
      state.mode.sub = SUB_UPDATE_MODE;
    },
    resetSubMode: (state) => {
      state.mode.sub = NONE_SUB_MODE;
    },
    setModel: (state, action) => {
      state.model = {
        ...action.payload,
      };
    },
    resetModel: (state) => {
      state.model = initialState.model;
    },
    setList: (state, action) => {
      state.list = action.payload;
    },
    addToList: (state, action) => {
      state.list = [...state.list, action.payload.data];
    },
    updateOnList: (state, action) => {
      state.list = state.list.map((current) => {
        if (current.id === action.payload.data?.id) {
          return action.payload.data;
        }
        return current;
      });
    },
    resetList: (state) => {
      state.list = [];
    },
    setUnidadesNegocio: (state, action) => {
      state.model.unidadesDeNegocio = [...action.payload];
    },
    setModeloNumerario: (state, action) => {
      state.model.modeloNumerario = !isEmpty(action.payload)
        ? { ...action.payload }
        : null;
    },
    setModeloFaturamento: (state, action) => {
      state.model.modeloFaturamento = !isEmpty(action.payload)
        ? { ...action.payload }
        : null;
    },
    setTabelaSda: (state, action) => {
      state.model.tabelaSda = !isEmpty(action.payload)
        ? { ...action.payload }
        : null;
    },
    setModeloFollowUp: (state, action) => {
      state.model.modeloFollowUp = !isEmpty(action.payload)
        ? { ...action.payload }
        : null;
    },
    setCamposAdicionais: (state, action) => {
      state.model.camposAdicionais = [...action.payload];
    },
    setConfig: (state, action) => {
      state.config = action.payload;
    },
    changeControlTo: (state, action) => {
      state.config.control = action.payload;
    },
    resetControl: (state) => {
      state.config.control = initialState.config.control;
    },
    setResponse: (state, action) => {
      state.response = action.payload;
    },
    clearResponse: (state) => {
      state.response = {
        status: null,
        message: null,
        data: null,
      };
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    clearError: (state) => {
      state.error = initialState.error;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllAsync.pending, (state) => {
        state.status = ALTERNATIVE_LOADING_STATUS;
      })
      .addCase(fetchAllAsync.fulfilled, (state, action) => {
        state.status = IDLE_STATUS;
        state.list = action.payload.data;
      })
      .addCase(fetchAllAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroCarregarListaServicos', { erro: action.error.message }),
        };
      })
      .addCase(fetchByIdAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(fetchByIdAsync.fulfilled, (state, action) => {
        if (action.payload.response.status === 200) {
          state.model = {
            ...action.payload.response.data,
          };
          state.status = SUCCESS_STATUS;
        }
      })
      .addCase(fetchByIdAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroCarregarServico', { erro: action.error.message }),
        };
      })
      .addCase(activateByIdAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(activateByIdAsync.fulfilled, (state, action) => {
        state.status = SUCCESS_STATUS;
        state.response.status = action.payload.response.status;
        state.response.data = action.payload.response.data;

        const { nome, description } = action.payload.response.data;
        state.response.message = i18n.t('com.muralis.qcx.mensagem.servicoAtivado', { nome: nome || description });

        if (state.response.status === 200) {
          state.list = state.list
            .map((current) => (
              current.id === action.payload.response.data.id
                ? action.payload.response.data
                : current
            ));
        }
      })
      .addCase(activateByIdAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroAtivarServico', { erro: action.error.message }),
        };
      })
      .addCase(inactivateByIdAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(inactivateByIdAsync.fulfilled, (state, action) => {
        state.status = SUCCESS_STATUS;
        state.response.status = action.payload.response.status;
        state.response.data = action.payload.response.data;

        const { nome, description } = action.payload.response.data;
        state.response.message = i18n.t('com.muralis.qcx.mensagem.servicoInativado', { nome: nome || description });

        if (state.response.status === 200) {
          state.list = state.list
            .map((current) => (
              current.id === action.payload.response.data.id
                ? action.payload.response.data
                : current
            ));
        }
      })
      .addCase(inactivateByIdAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroInativarServico', { erro: action.error.message }),
        };
      });
  },
});

const {
  changeStatusTo,
  loading,
  success,
  failure,
  preparingAction,
  resetStatus,
  changeToCreateMode,
  changeToConsultMode,
  changeToUpdateMode,
  resetMode,
  changeToBackgroundCreateMode,
  resetBackgroundMode,
  changeToSubConsultMode,
  changeToSubUpdateMode,
  changeToSubCreateMode,
  resetSubMode,
  setModel,
  resetModel,
  setList,
  addToList,
  updateOnList,
  resetList,
  setUnidadesNegocio,
  setModeloNumerario,
  setModeloFaturamento,
  setTabelaSda,
  setModeloFollowUp,
  setConfig,
  changeControlTo,
  resetControl,
  setResponse,
  clearResponse,
  setCamposAdicionais,
  setError,
  clearError,
} = servicoSlice.actions;

const servicoActions = servicoSlice.actions;

const selectServicos = (state) => state.servico.list;
const selectServico = (state) => state.servico.model;
const selectStatus = (state) => state.servico.status;
const selectMode = (state) => state.servico.mode.main;
const selectBackgroundMode = (state) => state.servico.mode.background;
const selectSubMode = (state) => state.servico.mode.sub;
const selectConfigControl = (state) => state.servico.config.control;
const selectError = (state) => state.servico.error;
const selectResponse = (state) => state.servico.response;

const servicoSelectors = {
  selectServicos,
  selectServico,
  selectStatus,
  selectMode,
  selectBackgroundMode,
  selectSubMode,
  selectConfigControl,
  selectError,
  selectResponse,
};

export {
  servicoSlice,
  servicoActions,
  servicoSelectors,
  changeStatusTo,
  loading,
  success,
  failure,
  preparingAction,
  resetStatus,
  changeToCreateMode,
  changeToConsultMode,
  changeToUpdateMode,
  resetMode,
  changeToBackgroundCreateMode,
  resetBackgroundMode,
  changeToSubConsultMode,
  changeToSubUpdateMode,
  changeToSubCreateMode,
  resetSubMode,
  setModel,
  resetModel,
  setList,
  addToList,
  updateOnList,
  resetList,
  setUnidadesNegocio,
  setModeloNumerario,
  setModeloFaturamento,
  setTabelaSda,
  setCamposAdicionais,
  setModeloFollowUp,
  setConfig,
  changeControlTo,
  resetControl,
  setResponse,
  clearResponse,
  setError,
  clearError,
  fetchAllAsync,
  fetchByIdAsync,
  activateByIdAsync,
  inactivateByIdAsync,
  selectServicos,
  selectServico,
  selectStatus,
  selectMode,
  selectBackgroundMode,
  selectSubMode,
  selectConfigControl,
  selectError,
  selectResponse,
};

export default servicoSlice.reducer;
