import React, {
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useHistory } from 'react-router';
import {
  changeModeTo,
  failure,
  loading,
  resetRelatedSelectionAvailableItemList,
  resetRelatedSelectionChangedItemList,
  resetRelatedSelectionFaturaList,
  resetRelatedSelectionItemList,
  resetRelatedTransferenciaItemModel,
  resetRelatedTransferenciaModel,
  resetStatus,
  setError,
  setResponse,
  success,
} from '../../../../features/fatura/faturaSlice';
import {
  selectStatus,
  selectMode,
  selectRelatedSelectionAvailableItemList,
  selectBackgroundMode,
  selectRelatedTransferenciaModel,
} from '../../../../features/fatura/faturaSelectors';
import {
  isCreateMode,
  isConsultMode,
  isUpdateMode,
  isLoadingStatus,
  isIdleStatus,
  isFailureStatus,
  isBackgroundTransferMode,
} from '../../../../utils/store/store-utils';
import QCXActionsManagerPageTemplate from '../../../../templates/actions-manager-page/QCXActionsManagerPageTemplate';
import useAutoChangeMode from '../../../../utils/hooks/mode/useAutoChangeMode';
import QCXFaturaTransferenciaForm from '../../../../components/fatura/QCXFaturaTransferenciaForm';
import { OperacaoMovimentacaoUtils } from '../../../../utils/general/movimentacao/movimentacaoUtils';
import { normalizeNumeral } from '../../../../utils/general/general-utils';
import { forceParseToNumber } from '../../../../utils/general/parse-utils';
import { mercadoriaMovimentacaoAPI } from '../../../../features/mercadoria-movimentacao/mercadoriaMovimentacaoAPI';
import { followUpAPI } from '../../../../features/follow-up/followUpAPI';
import { TipoContaFaturaUtils } from '../../../../shared-components/select-tipo-conta/tipoContaUtils';
import { followUpActions } from '../../../../features/follow-up/followUpSlice';

export default function FaturaTransferManagerPage({ authInfo = {} }) {
  const { t } = useTranslation();

  const history = useHistory();

  const dispatch = useDispatch();

  const status = useSelector(selectStatus);
  const mode = useSelector(selectMode);
  const backgroundMode = useSelector(selectBackgroundMode);
  const transferenciaModel = useSelector(selectRelatedTransferenciaModel);
  const selectionAvailableItemList = useSelector(selectRelatedSelectionAvailableItemList);

  const pageRole = useMemo(() => (
    'importacao-fatura'
  ), []);

  const isLoading = useMemo(() => (
    isLoadingStatus(status)
  ), [status]);

  const isIdle = useMemo(() => (
    isIdleStatus(status)
  ), [status]);

  const isFailure = useMemo(() => (
    isFailureStatus(status)
  ), [status]);

  const isCreate = useMemo(() => (
    isCreateMode(mode)
  ), [mode]);

  const isConsult = useMemo(() => (
    isConsultMode(mode)
  ), [mode]);

  const isUpdate = useMemo(() => (
    isUpdateMode(mode)
  ), [mode]);

  const isBackgroundTransfer = useMemo(() => (
    isBackgroundTransferMode(backgroundMode)
  ), [backgroundMode]);

  const selectedAvailableTransferItemsList = useMemo(() => {
    if (!selectionAvailableItemList || (
      _.isArrayLikeObject(selectionAvailableItemList)
      && _.isEmpty(selectionAvailableItemList)
    )) {
      return [];
    }

    return selectionAvailableItemList;
  }, [selectionAvailableItemList]);

  const normalize = useCallback((values) => {
    const tipoContaDescription = values?.conta?.tipo?.description;
    const referencia = values?.referencia;
    const operacaoDescription = OperacaoMovimentacaoUtils.TRANSFERENCIA;

    const items = values?.itens?.map((item) => ({
      conta: {
        tipo: {
          description: tipoContaDescription,
        },
        proprietario: {
          id: item?.itemOriginal?.id,
        },
      },
      referencia: {
        id: referencia?.id,
      },
      operacao: {
        description: operacaoDescription,
      },
      valor: forceParseToNumber(
        normalizeNumeral(
          item?.quantidade
        )
      ),
    }));

    return items;
  }, []);

  const handleSubmit = useCallback(async (values) => {
    const normalizedData = normalize(values);

    const destino = (
      TipoContaFaturaUtils.isTipoFaturaLi(values?.conta?.tipo?.description)
        ? t('com.muralis.qcx.LI')
        : t('com.muralis.qcx.DIDUIMP.label')
    );

    dispatch(loading());

    const response = await mercadoriaMovimentacaoAPI.transfer(normalizedData)
      .catch((reason) => {
        const message = reason?.response?.data?.message;

        dispatch(failure());
        dispatch(setError({
          message: t('com.muralis.qcx.erro.erroTransferenciaItensFatura', { destino, erro: message }),
        }));
      });

    if (response?.status === 201) {
      const responseFollowUp = await followUpAPI.fetchById(
        values?.referencia?.id
      ).catch((reason) => {
        const message = reason?.response?.data?.message;

        dispatch(failure());
        dispatch(setError({
          message: t('com.muralis.qcx.erro.erroCarregarProcessoReferencia', { mensagem: message }),
        }));
      });

      const foundFollowUp = responseFollowUp?.data;
      const feedbackMessage = t('com.muralis.qcx.mensagem.transferenciaItensFatura', { destino, numero: foundFollowUp?.numero });

      dispatch(success());
      dispatch(setResponse({
        status: response.status,
        data: response?.data,
        message: feedbackMessage,
      }));

      history.replace(t('com.muralis.qcx.url.importacaoFaturas'));
    }
  }, [
    history,
    normalize,
  ]);

  useEffect(() => {
    dispatch(resetStatus());

    const resetOnUnmount = () => {
      dispatch(followUpActions.changeControlToAutoLoad());
      dispatch(resetRelatedTransferenciaModel());
      dispatch(resetRelatedTransferenciaItemModel());
      dispatch(resetRelatedSelectionFaturaList());
      dispatch(resetRelatedSelectionAvailableItemList());
      dispatch(resetRelatedSelectionItemList());
      dispatch(resetRelatedSelectionChangedItemList());
    };

    return resetOnUnmount;
  }, []);

  const breadcrumbs = useMemo(() => ([
    {
      link: {
        to: '/',
        name: t('com.muralis.qcx.inicio'),
      },
    },
    {
      link: {
        to: t('com.muralis.qcx.url.moduloImportacao'),
        name: t('com.muralis.qcx.importacao.label'),
      },
    },
    {
      link: {
        to: t('com.muralis.qcx.url.importacaoFaturas'),
        name: t('com.muralis.qcx.fatura.labelPlural'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.transferencia.label'),
      },
    },
  ]), []);

  const pageTitle = useMemo(() => (
    t('com.muralis.qcx.transferencia.transferenciaFatura')
  ), []);

  const pageSubtitle = useMemo(() => {
    const tipoDestino = TipoContaFaturaUtils.getLabelFaturaByValue(
      transferenciaModel?.conta?.tipo?.description
    );

    return {
      show: true,
      text: (
        tipoDestino
          ? t('com.muralis.qcx.transferencia.transferirPara', { tipoDestino })
          : t('com.muralis.qcx.transferencia.transferirItens')
      ),
    };
  }, [transferenciaModel]);

  useAutoChangeMode((currentMode) => (
    dispatch(changeModeTo(currentMode))
  ), [isUpdate]);

  return (
    <QCXActionsManagerPageTemplate
      key="actions-fatura-transfer-manager-page"
      pageTitle={pageTitle}
      breadcrumbs={breadcrumbs}
      isIdle={isIdle}
      isLoading={isLoading}
      isFailure={isFailure}
      isCreate={isCreate}
      isConsult={isConsult}
      isUpdate={isUpdate}
      subtitle={pageSubtitle}
      authInfo={authInfo}
    >
      {() => (
        <>
          <QCXFaturaTransferenciaForm
            isBackgroundTransfer={isBackgroundTransfer}
            initialValues={transferenciaModel}
            handleSubmit={handleSubmit}
            selectionList={selectedAvailableTransferItemsList}
            authInfo={authInfo}
            requiredRoles={[pageRole]}
          />
        </>
      )}
    </QCXActionsManagerPageTemplate>
  );
}
