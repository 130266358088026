import { useMemo } from "react";
import { useGetRequest, QueriesTypes } from "../../../../common/hooks/queries";
import { FollowUp } from "../../../../common/types/followUp";

export const useGetAssessorioFollowUp = (
  id: string
): [FollowUp, boolean, boolean] => {
  const {
    data: followUpData,
    isError,
    isLoading,
  } = useGetRequest(QueriesTypes.FollowUps, id);

  const followUp: FollowUp = useMemo(() => {
    if (followUpData && !isError) {
      return { ...followUpData };
    } else {
      return {} as FollowUp;
    }
  }, [followUpData, isError, id]);

  return [followUp, isLoading, isError];
};
