import { isFunction } from 'lodash';
import {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import i18n from '../../../../i18n';

/**
 * @deprecated esse hook é um código legado, ele deve ser mantido até ser completamente substituido
 * para novas funcionalidades utilize o hook useConfigurePopup.ts
 */
export default function useOperationMiddleware(next, deps = []) {
  const DEFAULT_OPERATION = {
    active: false,
    authorized: false,
    title: i18n.t('com.muralis.qcx.acoes.confirmarOperacao'),
    message: i18n.t('com.muralis.qcx.mensagem.confirmarOperacaoMensagem'),
  };

  const [operation, setOperation] = useState({
    ...DEFAULT_OPERATION,
  });
  const [savedArgs, setSavedArgs] = useState([]);
  const [cleanUpFn, setCleanUpFn] = useState(undefined);

  const confirm = useCallback(() => {
    setOperation((previousOperation) => ({
      ...previousOperation,
      authorized: true,
    }));
  }, [setOperation]);

  const reset = useCallback((callback) => {
    setSavedArgs([]);

    setOperation((previousOperation) => ({
      ...previousOperation,
      active: false,
      authorized: false,
    }));

    if (isFunction(callback)) {
      callback();
    }

    if (isFunction(cleanUpFn)) {
      cleanUpFn();

      setCleanUpFn(undefined);
    }
  }, [
    setOperation,
    setSavedArgs,
    cleanUpFn,
  ]);

  const composedCallback = useCallback((configureOperation, ...args) => {
    setSavedArgs(args);

    const {
      options,
      cleanUp,
    } = configureOperation();

    setOperation((previousOperation) => ({
      ...previousOperation,
      ...options,
      active: true,
    }));

    if (isFunction(cleanUp)) {
      setCleanUpFn(() => (
        cleanUp
      ));
    }
  }, [
    setOperation,
    setSavedArgs,
    setCleanUpFn,
    ...deps,
  ]);

  const operationObject = useMemo(() => ({
    ...operation,
    confirm,
    reset,
  }), [
    operation,
    confirm,
    reset,
  ]);

  useEffect(() => {
    let unmounted = false;

    if (!unmounted) {
      if (operation?.authorized && isFunction(next)) {
        next.call(this, ...savedArgs);
        reset();
      }
    }

    return () => {
      unmounted = true;
    };
  }, [
    operation,
    next,
    savedArgs,
    reset,
  ]);

  return [
    composedCallback,
    operationObject
  ];
}
