import { validateQueryParams } from '../../../../../utils/api/api-utils';
import {
  doGetQCXRequest,
  doPostQCXRequest,
  doPutQCXActivateRequest,
  doPutQCXCalculateRequest,
  doPutQCXInactivateRequest,
  doPutQCXRequest,
} from '../../../../../utils/api/domain/domain-api-utils';
import { CUSTEIOS_ENDPOINT, FILTER_ENDPOINT } from '../../../../../utils/api/domain/domain-endpoint-utils';

export async function fetchAll() {
  return doGetQCXRequest([CUSTEIOS_ENDPOINT]);
}
export async function fetchByFilter(
  params = [] as {
    name: string;
    value: any;
  }[]
) {
  const validatedParams = validateQueryParams(params);
  if (validatedParams.length) {
    return doGetQCXRequest([CUSTEIOS_ENDPOINT, FILTER_ENDPOINT], validatedParams);
  }
  return { data: [] };
}

export async function calculateById(id: number) {
  return doPutQCXCalculateRequest([CUSTEIOS_ENDPOINT], id);
}

export async function fetchById(id: number | string) {
  return doGetQCXRequest([CUSTEIOS_ENDPOINT, id]);
}

export async function register(data: any) {
  return doPostQCXRequest([CUSTEIOS_ENDPOINT], data);
}

export async function save(data: any) {
  return doPutQCXRequest([CUSTEIOS_ENDPOINT], data);
}

export async function activateById(id: number | string) {
  return doPutQCXActivateRequest([CUSTEIOS_ENDPOINT], id);
}

export async function inactivateById(id: number | string) {
  return doPutQCXInactivateRequest([CUSTEIOS_ENDPOINT], id);
}
