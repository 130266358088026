import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React from 'react';
import QCXButton from '../button/QCXButton';

export default function QCXSnackbar({
  open,
  content,
  onClose,
  severity,
  action,
  ...restProps
}) {
  return (
    <Snackbar
      open={open}
      onClose={onClose}
      {...restProps}
    >
      <Alert
        onClose={onClose}
        severity={severity}
        action={action?.enable
          ? (
            <QCXButton
              variant="text"
              color="inherit"
              size="small"
              onClick={action?.onClick}
            >
              {action?.buttonName}
            </QCXButton>
          ) : null}
      >
        {content}
      </Alert>
      {/* {
        action?.enable
        && (
          <Alert
            action={(
              <QCXButton
                color="inherit"
                size="small"
                onClick={action?.onClick}
              >
                {action?.buttonName}
              </QCXButton>
            )}
          />
        )
      } */}
    </Snackbar>
  );
}
