import _ from 'lodash';
import {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import QCXFinalFormDialog from '../../shared-components/dialog/QCXFinalFormDialog';
import QCXFormListenerManager from '../../shared-components/form-listener/QCXFormListenerManager';
import QCXFinalDatePickerField from '../../shared-components/final-date-picker-field/QCXFinalDatePickerField';
import QCXSelectClienteAutocomplete from '../../shared-components/final-select-cliente-field/QCXSelectClienteAutocomplete';

import {
  CLOSED_STATUS,
  isOpenStatus,
} from '../../utils/hooks/form/dialog/formDialogUtils';
import QCXSelectFollowUpDestinoAutocompleteField from '../../shared-components/select-follow-up/QCXSelectFollowUpDestinoAutocompleteField';
import { followUpDestinoFaturamentoActions } from '../../features/follow-up-destino-faturamento/followUpDestinoFaturamentoSlice';
import { followUpAPI } from '../../features/follow-up/followUpAPI';

export default function QCXRelatorioFaturamentoDialogForm({
  handleOnSubmit,
  handleFormStatus,
  formDialogStatus,
  initialValues,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isDisabled, setIsDisabled] = useState(false);

  const isFormDialogOpen = useMemo(() => (
    isOpenStatus(formDialogStatus)
  ), [formDialogStatus]);

  const handleCloseFormDialogNew = useCallback(() => {
    handleFormStatus(CLOSED_STATUS);
    (_.debounce(() => {
      dispatch(followUpDestinoFaturamentoActions.resetList());
    }, 200))();
  }, [
    handleFormStatus,
  ]);

  useEffect(() => {
    dispatch(followUpDestinoFaturamentoActions.resetList());
    return () => {
      dispatch(followUpDestinoFaturamentoActions.resetList());
    };
  }, []);

  const handleOnImportadorChange = useCallback(() => async (value) => {
    if (value) {
      setIsDisabled(true);
      const response = await followUpAPI.fetchAllByImportadorFaturamentoId(value);
      if (response?.status === 200) {
        dispatch(followUpDestinoFaturamentoActions.setList({
          data: response?.data,
        }));
        setIsDisabled(false);
        return;
      }
    }
    dispatch(followUpDestinoFaturamentoActions.resetList());
  }, []);

  const onChangeListeners = useMemo(() => (
    [
      {
        name: 'cliente.id',
        fn: handleOnImportadorChange,
      },
    ]
  ), [
    handleOnImportadorChange,
  ]);

  return (
    <QCXFinalFormDialog
      key="form-dialog-relatorio-faturamento"
      id="form-dialog-relatorio-faturamento"
      title={t('com.muralis.qcx.relatorioContaCorrenteCliente')}
      dialogActionsProps={{
        button: {
          submit: {
            description: t('com.muralis.qcx.acoes.imprimir'),
            size: 'small',
            color: 'secondary',
          },
          cancel: {
            description: t('com.muralis.qcx.acoes.cancelar'),
            size: 'small',
          },
        },
      }}
      open={isFormDialogOpen}
      initialValues={initialValues}
      onSubmit={handleOnSubmit}
      onClose={handleCloseFormDialogNew}
    >
      {({ values, form }) => (
        <>
          <QCXFormListenerManager
            form={form}
            values={values}
            onChangeListeners={onChangeListeners}
          />
          <Grid
            container
          >
            <Grid
              container
              spacing={3}
              style={{
                margin: '10px 0',
              }}
              sm={12}
              xs={12}
              md={12}
              lg={12}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
              >
                <QCXFinalDatePickerField
                  id="date-picker-data-vencimento-de-field"
                  key="date-picker-data-vencimento-de-field"
                  name="vencimentoDe"
                  label={t('com.muralis.qcx.vencimentoDe')}
                  required
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
              >
                <QCXFinalDatePickerField
                  id="date-picker-data-vencimento-ate-field"
                  key="date-picker-data-vencimento-ate-field"
                  name="vencimentoAte"
                  label={t('com.muralis.qcx.vencimentoAte')}
                  required
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
              >
                <QCXSelectClienteAutocomplete
                  id="autocomplete-select-cliente-field"
                  key="autocomplete-select-cliente-field"
                  name="cliente.id"
                  label={t('com.muralis.qcx.cliente.label')}
                  initialValues={initialValues}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
              >
                <QCXSelectFollowUpDestinoAutocompleteField
                  key="select-autocomplete-processo-field"
                  id="select-autocomplete-processo-field"
                  name="followUp.id"
                  label={t('com.muralis.qcx.numeroProcesso')}
                  controlled
                  internalLoadingAtStart={false}
                  disabled={isDisabled}
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </QCXFinalFormDialog>
  );
}
