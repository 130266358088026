import { getOptionByValueInList } from '../../utils/general/general-utils';
import i18n from '../../i18n';

const RELACAO_FORNECEDOR_FABRICANTE_EXPORTADOR = 'FABRICANTE_EXPORTADOR';
const RELACAO_FORNECEDOR_FABRICANTE_NAO_EXPORTADOR = 'FABRICANTE_NAO_EXPORTADOR';
const RELACAO_FORNECEDOR_FABRICANTE_DESCONHECIDO = 'FABRICANTE_DESCONHECIDO';

const RELACAO_FORNECEDOR_LIST = [
  {
    label: i18n.t('com.muralis.qcx.relacaoFornecedor.fabricanteExportador'),
    value: RELACAO_FORNECEDOR_FABRICANTE_EXPORTADOR,
  },
  {
    label: i18n.t('com.muralis.qcx.relacaoFornecedor.fabricanteNaoExportador'),
    value: RELACAO_FORNECEDOR_FABRICANTE_NAO_EXPORTADOR,
  },
  {
    label: i18n.t('com.muralis.qcx.relacaoFornecedor.fabricanteDesconhecido'),
    value: RELACAO_FORNECEDOR_FABRICANTE_DESCONHECIDO,
  },
];

const isRelacaoFornecedorFabricanteExportador = (value) => (
  RELACAO_FORNECEDOR_FABRICANTE_EXPORTADOR === value
);

const isRelacaoFornecedorFabricanteNaoExportador = (value) => (
  RELACAO_FORNECEDOR_FABRICANTE_NAO_EXPORTADOR === value
);

const isRelacaoFornecedorFabricanteDesconhecido = (value) => (
  RELACAO_FORNECEDOR_FABRICANTE_DESCONHECIDO === value
);

const getRelacaoFornecedorLabelByValue = (value) => {
  const foundOption = getOptionByValueInList(RELACAO_FORNECEDOR_LIST, value);
  return foundOption && foundOption?.label;
};

export {
  RELACAO_FORNECEDOR_FABRICANTE_EXPORTADOR,
  RELACAO_FORNECEDOR_FABRICANTE_NAO_EXPORTADOR,
  RELACAO_FORNECEDOR_FABRICANTE_DESCONHECIDO,
  RELACAO_FORNECEDOR_LIST,
};

export {
  isRelacaoFornecedorFabricanteExportador,
  isRelacaoFornecedorFabricanteNaoExportador,
  isRelacaoFornecedorFabricanteDesconhecido,
  getRelacaoFornecedorLabelByValue,
};
