import React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@material-ui/core';
import _ from 'lodash';
import QCXCircularProgress from '../../shared-components/progress/QCXCircularProgress';

const CardSolicitacaoTypeUtils = Object.freeze({
  ICON: 'ICON',
  SYMBOL: 'SYMBOL',
  isIcon: (type) => (
    type === CardSolicitacaoTypeUtils.ICON
  ),
  isSymbol: (type) => (
    type === CardSolicitacaoTypeUtils.SYMBOL
  ),
});

export default function QCXCardSolicitacao({
  color,
  content,
  type,
  renderIcon,
  renderSymbol,
  loading = false,
  children,
  ...restProps
}) {
  const useStyles = makeStyles(() => ({
    symbol: {
      color,
      position: 'relative',
      padding: 0,
      margin: 0,
      fontWeight: 'bold',
    },
    borda: {
      background: [color],
      width: '1px',
      position: 'absolute',
      right: 0,
      top: '50%',
      height: '70%',
      transform: 'translateY(-50%)',
    },
  }));

  const classes = useStyles();

  return (
    <Card
      {...restProps}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        border: `1px solid ${color}`,
        borderLeft: `6px solid ${color}`,
        boxShadow: 'none',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          width: '100%',
        }}
      >
        <CardContent
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '35%',
            position: 'relative',
          }}
        >
          {CardSolicitacaoTypeUtils.isIcon(type) && _.isFunction(renderIcon) && (
            renderIcon({
              className: classes.symbol,
              sx: {
                display: 'flex',
                fontSize: '64px',
              },
            })
          )}

          {CardSolicitacaoTypeUtils.isSymbol(type) && _.isFunction(renderSymbol) && (
            <span
              style={{
                display: 'flex',
                alignItems: 'center',
                width: '64px',
                height: '64px',
              }}
            >
              {renderSymbol({
                className: classes.symbol,
                sx: {
                  display: 'flex',
                  fontSize: '32px',
                },
              })}
            </span>
          )}

          {children}

          <div className={classes.borda} />

        </CardContent>

        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '65%',
            padding: '20px',
            position: 'relative',
          }}
        >
          {loading && (
            <QCXCircularProgress
              style={{ color }}
            />
          )}
          {!loading && (
            <>
              <Typography variant="subtitle1" color="inhert" component="div" noWrap>
                {content.title}
              </Typography>

              <Typography variant="title" color="inhert" component="h2" noWrap>
                {content.value}
              </Typography>
            </>
          )}
        </CardContent>

      </Box>
    </Card>
  );
}

export {
  CardSolicitacaoTypeUtils,
};
