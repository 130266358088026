import { IconButton, Tooltip } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import {
  Block as BlockIcon,
  MoreHoriz as MoreHorizIcon,
  Search as PageviewIcon,
  Receipt as ReceiptIcon,
} from '@material-ui/icons';
import { isEmpty, isFunction } from 'lodash';
import { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import QCXMoveFocusInside from '../../../../../components/focus-lock/QCXMoveFocusInside';
import { userHasDeleteRole } from '../../../../../components/perfil/perfil-utils';
import QCXPopupState from '../../../../../components/popup-state/QCXPopupState';
import { setQuery } from '../../../../../features/controller/controllerSlice';
import { followUpAPI } from '../../../../../features/follow-up/followUpAPI';

import { FAILURE_STATUS } from '../../../../../features/status';
import QCXConfirmDialog from '../../../../../shared-components/dialog/QCXConfirmDialog';
import QCXFinalFormDialog from '../../../../../shared-components/dialog/QCXFinalFormDialog';
import QCXListItemIcon from '../../../../../shared-components/list-item-icon/QCXListItemIcon';
import QCXSimpleConsultPageTemplate from '../../../../../templates/simple-consult-page/QCXSimpleConsultPageTemplate';
import { formatDate } from '../../../../../utils/general/general-utils';
import {
  CLOSED_STATUS,
  DONE_STATUS,
  SUBMITTING_STATUS,
  isOpenStatus,
} from '../../../../../utils/hooks/form/dialog/formDialogUtils';
import useFormDialogAsync from '../../../../../utils/hooks/form/dialog/useFormDialogAsync';
import { formatCnpj } from '../../../../../utils/hooks/form/field/formatters';
import useOperationMiddleware from '../../../../../utils/hooks/operation/middleware/useOperationMiddleware';
import { isLoadingStatus, isRefresh } from '../../../../../utils/store/store-utils';
import { required } from '../../../../../utils/validators/field/validator';
import { selectDadosEmbarques, selectHistoryModel, selectRefresh, selectStatus } from '../api/dadosEmbarqueSelectors';
import {
  changeToConsultMode,
  changeToCreateMode,
  failure,
  loading,
  resetBackgroundMode,
  resetMode,
  resetModel,
  resetRefresh,
  resetSubMode,
  setError,
  setModel,
} from '../api/dadosEmbarqueSlice';
import { activateByIdAsync, fetchAllAsync, inactivateByIdAsync } from '../api/dadosEmbarqueThunks';
import QCXSelectFollowUpExportacaoAutocompleteField from './components/QCXSelectFollowUpExportacaoAutocompleteField';

export default function ExportacaoDadosEmbarqueConsultPage({ authInfo = {} }) {
  const { t } = useTranslation();
  const history = useHistory();

  const dispatch = useDispatch();

  const refresh = useSelector(selectRefresh);
  const dadosEmbarques = useSelector(selectDadosEmbarques);
  const historyModel = useSelector(selectHistoryModel);
  const status = useSelector(selectStatus);

  const isLoading = useMemo(() => isLoadingStatus(status), [status]);

  const fetchAll = useCallback(() => {
    dispatch(fetchAllAsync());
  }, []);

  const resetModes = useCallback(() => {
    dispatch(resetMode());
    dispatch(resetBackgroundMode());
    dispatch(resetSubMode());
  }, []);

  const resetModels = useCallback(() => {
    dispatch(resetModel());
  }, []);

  useEffect(() => {
    fetchAll();

    resetModes();
    resetModels();
  }, []);

  useEffect(() => {
    const checkIfIsRefresh = () => {
      if (isRefresh(refresh)) {
        dispatch(fetchAllAsync());
        dispatch(resetRefresh());
      }
    };

    checkIfIsRefresh();
  }, [refresh]);

  useEffect(() => {
    const checkIfExistsQuery = () => {
      if (!isEmpty(historyModel) && historyModel?.last?.numero) {
        dispatch(setQuery(historyModel?.last?.numero));
      }
    };

    checkIfExistsQuery();
  }, [historyModel]);

  const handleConsultarClick = useCallback(
    (event, data) => {
      if (isFunction(event?.stopPropagation)) {
        event.stopPropagation();

        dispatch(changeToConsultMode());

        history.push(t('com.muralis.qcx.url.exportacaoDadosEmbarqueDetalhes', { id: data?.id }));
      }
    },
    [history]
  );

  const [handleActivateOrInactivateByIdClickMiddleware, operationOfActivateOrInactivateById] = useOperationMiddleware(
    async (data) => {
      const isInactivation = data?.active;

      if (isInactivation) {
        dispatch(inactivateByIdAsync(data?.id));

        return;
      }

      dispatch(activateByIdAsync(data?.id));
    },
    [dadosEmbarques]
  );

  const handleConfigureActivateOrInactivateByIdClick = useCallback(
    (row, popupState) => {
      const { id, atributosAdicionais, active } = row;

      const handleActivateOrInactivateByIdClick = (event) => {
        if (isFunction(event?.stopPropagation)) {
          popupState.close(event);

          event.stopPropagation();

          const configureOperation = () => ({
            options: {
              title: t('com.muralis.qcx.acoes.confirmarOperacaoExclusao').toUpperCase(),
              message: t('com.muralis.qcx.acoes.confirmacaoAcaoDI', {
                acao: active ? t('com.muralis.qcx.acoes.excluir') : t('com.muralis.qcx.acoes.recuperar'),
                numero: atributosAdicionais?.numero,
              }),
            },
          });

          const dataOfOperationActivateOrInactivate = {
            id,
            active,
          };

          handleActivateOrInactivateByIdClickMiddleware(configureOperation, dataOfOperationActivateOrInactivate);
        }
      };

      return handleActivateOrInactivateByIdClick;
    },
    [handleActivateOrInactivateByIdClickMiddleware]
  );

  const columns = useMemo(
    () => [
      {
        field: 'id',
        headerName: t('com.muralis.qcx.processo'),
        headerAlign: 'center',
        align: 'center',
        flex: 140,
        valueGetter: ({ row }) => row?.followUp?.numero || '-',
      },
      {
        field: 'importador',
        headerName: t('com.muralis.qcx.cliente.label'),
        flex: 180,
        valueGetter: ({ row }) => row?.followUp?.importador?.pessoa?.nome || '-',
      },
      {
        field: 'importador.cnpj',
        headerName: t('com.muralis.qcx.empresa.CNPJ'),
        flex: 140,
        valueGetter: ({ row }) => formatCnpj(row?.followUp?.importador?.pessoa?.cnpj) || '-',
      },
      {
        field: 'insertionDate',
        headerName: t('com.muralis.qcx.abertura'),
        headerAlign: 'center',
        align: 'center',
        type: 'date',
        flex: 100,
        valueGetter: ({ row }) => formatDate(row?.insertionDate, 'DD/MM/YYYY HH:mm:ss', 'DD/MM/YYYY') || '-',
      },
      {
        field: 'actions',
        headerName: t('com.muralis.qcx.acoes.label'),
        headerAlign: 'center',
        align: 'center',
        flex: 120,
        renderCell: ({ row: { id, atributosAdicionais, active } }) => (
          <>
            <QCXPopupState popupId="popup-menu-follow-up">
              {(popupState) => (
                <>
                  <Tooltip title={t('com.muralis.qcx.maisOpcoes')} arrow>
                    <IconButton
                      key={`btn-more-options-${id}`}
                      name={`btn-more-options-${id}`}
                      {...bindTrigger(popupState)}
                    >
                      <MoreHorizIcon color="secondary" size={20} />
                    </IconButton>
                  </Tooltip>
                  <Menu {...bindMenu(popupState)}>
                    <MenuItem
                      onClick={(event) => {
                        popupState.close(event);

                        handleConsultarClick(event, {
                          id,
                        });
                      }}
                    >
                      <QCXListItemIcon>
                        <PageviewIcon fontSize="small" />
                      </QCXListItemIcon>
                      <Typography
                        variant="inherit"
                        style={{
                          fontSize: 12,
                        }}
                      >
                        {t('com.muralis.qcx.acoes.consultar').toUpperCase()}
                      </Typography>
                    </MenuItem>
                    {userHasDeleteRole(authInfo, 'Exportacao-follow-up') && (
                      <>
                        <MenuItem
                          onClick={handleConfigureActivateOrInactivateByIdClick(
                            {
                              id,
                              atributosAdicionais,
                              active,
                            },
                            popupState
                          )}
                          disabled
                        >
                          <QCXListItemIcon>
                            <BlockIcon fontSize="small" color="disabled" />
                          </QCXListItemIcon>
                          <Typography
                            variant="inherit"
                            style={{
                              fontSize: 12,
                            }}
                          >
                            {t('com.muralis.qcx.acoes.cancelar').toUpperCase()}
                          </Typography>
                        </MenuItem>
                      </>
                    )}
                  </Menu>
                </>
              )}
            </QCXPopupState>
          </>
        ),
      },
    ],
    [handleConsultarClick, handleConfigureActivateOrInactivateByIdClick]
  );

  const breadcrumbs = [
    {
      link: {
        to: '/',
        name: t('com.muralis.qcx.inicio'),
      },
    },
    {
      link: {
        to: t('com.muralis.qcx.url.moduloExportacao'),
        name: t('com.muralis.qcx.exportacao.label'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.dadosEmbarque.label'),
      },
      default: true,
    },
  ];

  const [handleButtonNewClick, formDialogNewStatus, handleFormDialogNewStatus] = useFormDialogAsync(
    async (event) => {
      event.stopPropagation();

      dispatch(changeToCreateMode());

      history.push(t('com.muralis.qcx.url.exportacaoDadosEmbarqueNovo'));
    },
    [history]
  );

  const handleCloseFormDialogNew = useCallback(
    () => handleFormDialogNewStatus(CLOSED_STATUS),
    [handleFormDialogNewStatus]
  );

  const isFormDialogNewOpen = useMemo(() => isOpenStatus(formDialogNewStatus), [formDialogNewStatus]);

  const handleProcessoNewSubmit = useCallback(
    async (values) => {
      try {
        dispatch(loading());

        handleFormDialogNewStatus(SUBMITTING_STATUS);

        const response = await followUpAPI.fetchById(values?.followUp?.id);

        if (response?.status === 200) {
          dispatch(
            setModel({
              followUp: response?.data,
            })
          );

          handleFormDialogNewStatus(DONE_STATUS);
        }
      } catch (error) {
        handleFormDialogNewStatus(FAILURE_STATUS);

        let errorMessage = t('com.muralis.qcx.erro.erroCarregarProcesso');
        if (error?.response && error?.response?.data) {
          errorMessage = t('com.muralis.qcx.erro.erroCarregarProcessoEspecifico', {
            erro: error?.response?.data?.message,
          });
        }

        dispatch(failure());
        dispatch(
          setError({
            message: errorMessage,
          })
        );
      }
    },
    [handleFormDialogNewStatus]
  );

  const templateProps = {
    page: {
      title: t('com.muralis.qcx.dadosEmbarque.label'),
      icon: <ReceiptIcon />,
      breadcrumbs,
    },
    control: {
      buttonNew: {
        description: t('com.muralis.qcx.followup.novoRegistroFollowUp'),
        onClick: handleButtonNewClick,
      },
    },
    table: {
      columns,
    },
  };

  return (
    <QCXSimpleConsultPageTemplate
      pageProps={templateProps.page}
      controlProps={templateProps.control}
      tableProps={templateProps.table}
      tableData={dadosEmbarques}
      isLoading={isLoading}
      authInfo={authInfo}
      requiredRoles={['exportacao-follow-up']}
    >
      <QCXFinalFormDialog
        key="form-dialog-processo-registro-manual-di-duimp"
        id="form-dialog-processo-registro-manual-di-duimp"
        title="Novo Registro de Dados de Embarque"
        content={t('com.muralis.qcx.preencherCampoContinuar')}
        dialogActionsProps={{
          button: {
            submit: {
              description: t('com.muralis.qcx.acoes.continuar'),
              size: 'small',
            },
            cancel: {
              description: t('com.muralis.qcx.acoes.cancelar'),
              size: 'small',
            },
          },
        }}
        open={isFormDialogNewOpen}
        initialValues={{}}
        onSubmit={handleProcessoNewSubmit}
        onClose={handleCloseFormDialogNew}
      >
        {() => (
          <QCXMoveFocusInside>
            <QCXSelectFollowUpExportacaoAutocompleteField
              key="select-autocomplete-processo-field"
              id="select-autocomplete-processo-field"
              name="followUp.id"
              label={t('com.muralis.qcx.processo')}
              validate={required}
              required
            />
          </QCXMoveFocusInside>
        )}
      </QCXFinalFormDialog>
      <QCXConfirmDialog
        key="confirm-dialog-activate-inactivate-follow-up"
        id="confirm-dialog-activate-inactivate-follow-up"
        open={operationOfActivateOrInactivateById?.active}
        title={operationOfActivateOrInactivateById?.title}
        content={operationOfActivateOrInactivateById?.message}
        endContent={operationOfActivateOrInactivateById?.endMessage}
        onConfirm={operationOfActivateOrInactivateById.confirm}
        onClose={operationOfActivateOrInactivateById?.reset}
      />
    </QCXSimpleConsultPageTemplate>
  );
}
