import { Grid, makeStyles } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { OnChange } from 'react-final-form-listeners';
import { Trans, useTranslation } from 'react-i18next';
import QCXInfoAlert from '../../shared-components/alert/QCXInfoAlert';
import QCXDynamicFieldGroup from '../../shared-components/dynamic-field-group/QCXDynamicFieldGroup';
import QCXFinalAlternativeCurrencyField from '../../shared-components/final-currency-field/QCXFinalAlternativeCurrencyField';
import QCXSelectCodigoReceitaAutocomplete from '../../shared-components/final-select-codigo-receita-field/QCXSelectCodigoReceitaAutocomplete';
import QCXFinalTextField from '../../shared-components/final-text-field/QCXFinalTextField';
import QCXFormStepWizard from '../../shared-components/form-step-wizard/QCXFormStepWizard';
import QCXSelectBancoAutocompleteField from '../../shared-components/select-banco/QCXSelectBancoAutocompleteField';
import QCXSelectContaBancariaAutocompleteField from '../../shared-components/select-conta-bancaria/QCXSelectContaBancariaAutocompleteField';
import QCXFinalAgenciaTextField from '../../shared-components/text-field-agencia/QCXFinalAgenciaTextField';
import QCXFinalContaCorrenteTextField from '../../shared-components/text-field-conta-corrente/QCXFinalContaCorrenteTextField';
import { PAGAMENTODI } from '../../ts/common/hooks/useHasPermission/permissionsEnum';
import { useHasPermission } from '../../ts/common/hooks/useHasPermission/useHasPermission';
import { isNaoCalculadaStatus } from '../../utils/general/operations/operationUtils';
import QCXFormSubtitle from '../form-title/QCXFormSubtitle';
import QCXFormTitle from '../form-title/QCXFormTitle';

const useStyles = makeStyles(() => ({
  gridFields: {
    paddingBottom: '16px',
  },
  fakeFields: {
    height: '40px !important',
    paddingBottom: '16px',
  },
}));

export default function QCXDeclaracaoImportacaoPagamentosForm({
  initialValues,
  handleSubmit,
  isConsult,
  isLocked,
  controlComponentProps,
  ...restProps
}) {
  const { t } = useTranslation();
  const classes = useStyles();

  const hasPermissionToEditBanco = useHasPermission(PAGAMENTODI.ALTERAR);

  return (
    <QCXFormStepWizard
      initialValues={initialValues}
      onSubmit={handleSubmit}
      controlComponentProps={controlComponentProps}
      {...restProps}
    >
      {({ values, form }) => (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <QCXFormTitle title={t('com.muralis.qcx.pagamento.fichaPagamentos')} />
            </Grid>
            {isEmpty(values?.atributosAdicionais?.pagamentos) && isNaoCalculadaStatus(values?.calculada) && (
              <Grid item xs={12}>
                <QCXInfoAlert>
                  <Trans i18nKey="com.muralis.qcx.mensagem.dadosRelacionadosGeradosDICalculada">
                    <em>
                      <strong>
                        {{
                          tributo: t('com.muralis.qcx.impostoTaxa').toLowerCase(),
                        }}
                      </strong>
                      <strong>
                        {{
                          dadosrelacionados: t('com.muralis.qcx.outrosDadosRelacionados').toLowerCase(),
                        }}
                      </strong>
                      <strong>
                        {{
                          DI: t('com.muralis.qcx.declaracaoImportacao'),
                        }}
                      </strong>
                      <strong>
                        {{
                          estado: t('com.muralis.qcx.estado.calculada').toLowerCase(),
                        }}
                      </strong>
                    </em>
                  </Trans>
                </QCXInfoAlert>
              </Grid>
            )}
            <QCXDynamicFieldGroup
              rootName="atributosAdicionais.pagamentos"
              list={values?.atributosAdicionais?.pagamentos}
            >
              {({ identificacao, valorReal, codigoReceita, novoCodigoReceita }) => (
                <>
                  <Grid item xs={12} sm={12} md={3} lg={3}>
                    <QCXFinalTextField
                      id="text-identificacao-field"
                      key="text-identificacao-field"
                      name={identificacao?.name}
                      label={t('com.muralis.qcx.impostosOuTaxas')}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={3} lg={3}>
                    <QCXFinalAlternativeCurrencyField
                      id="currency-valor-field"
                      key="currency-valor-field"
                      name={valorReal?.name}
                      label={t('com.muralis.qcx.valor')}
                      scale={2}
                      adorned
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={3} lg={3}>
                    <QCXSelectCodigoReceitaAutocomplete
                      id="text-codigo-receita-field"
                      key="text-codigo-receita-field"
                      name={`${codigoReceita?.name}.id`}
                      label={t('com.muralis.qcx.codigoReceita')}
                      disabled
                      initialValues={values}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={3} lg={3}>
                    <QCXSelectCodigoReceitaAutocomplete
                      id="text-novo-codigo-receita-field"
                      key="text-novo-codigo-receita-field"
                      name={`${novoCodigoReceita?.name}.id`}
                      label={t('com.muralis.qcx.codigoReceitaAlterado')}
                      disabled={isConsult}
                      initialValues={values}
                    />
                  </Grid>
                </>
              )}
            </QCXDynamicFieldGroup>
            <Grid item xs={12}>
              <QCXFormSubtitle title={t('com.muralis.qcx.banco.dadosBancarios')} />
            </Grid>
            <Grid item container spacing={2} xs={12}>
              <Grid item className={classes.gridFields} xs={12} sm={12} md={8} lg={8}>
                <QCXSelectContaBancariaAutocompleteField
                  key="autocomplete-select-conta-bancaria-pagamentos-field"
                  id="autocomplete-select-conta-bancaria-pagamentos-field"
                  name="atributosAdicionais.dadosBancarios.contaBancaria"
                  label="Conta Bancária"
                  initialValues={values}
                  disabled={isConsult || !hasPermissionToEditBanco}
                />
                <OnChange name="atributosAdicionais.dadosBancarios.contaBancaria">
                  {(value) => {
                    if (!value) {
                      return;
                    }

                    form.change('atributosAdicionais.dadosBancarios.banco', value?.banco);
                    form.change('atributosAdicionais.dadosBancarios.agencia', value?.agencia);
                    form.change('atributosAdicionais.dadosBancarios.conta', value?.contaCorrente);
                  }}
                </OnChange>
              </Grid>
            </Grid>
            <Grid item container spacing={2} xs={12}>
              <Grid item className={classes.gridFields} xs={12} sm={12} md={4} lg={4}>
                <QCXSelectBancoAutocompleteField
                  id="autocomplete-select-banco-dados-bancarios-pagamentos-field"
                  key="autocomplete-select-banco-pagamentos-field"
                  name="atributosAdicionais.dadosBancarios.banco.id"
                  label={t('com.muralis.qcx.banco.codigoBanco')}
                  initialValues={values}
                  disabled={isConsult || !hasPermissionToEditBanco}
                />
              </Grid>
              <Grid item className={classes.gridFields} xs={12} sm={12} md={4} lg={4}>
                <QCXFinalAgenciaTextField
                  id="text-field-agencia-dados-bancarios-pagamentos"
                  key="text-field-agencia-dados-bancarios-pagamentos"
                  name="atributosAdicionais.dadosBancarios.agencia"
                  label={t('com.muralis.qcx.banco.numeroAgencia')}
                  disabled={isConsult || !hasPermissionToEditBanco}
                />
              </Grid>
              <Grid item className={classes.gridFields} xs={12} sm={12} md={4} lg={4}>
                <QCXFinalContaCorrenteTextField
                  id="text-field-conta-corrente-dados-bancarios-pagamentos"
                  key="text-field-conta-corrente-dados-bancarios-pagamentos"
                  name="atributosAdicionais.dadosBancarios.conta"
                  label={t('com.muralis.qcx.banco.contaCorrente')}
                  disabled={isConsult || !hasPermissionToEditBanco}
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </QCXFormStepWizard>
  );
}
