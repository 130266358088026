import { isArray, isFunction } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { CONSULT_MODE, CREATE_MODE } from '../../../features/mode';

export default function useAutoChangeMode(handleChangeModeTo, suspendExecution = [false]) {
  const history = useHistory();

  const [lastMode, setLastMode] = useState();

  const getCurrentModeByPathname = useCallback((pathname) => {
    const chunkOfConsultPagePattern = /[a-z]+\/[0-9]+\/detalhes/g;

    const resultsOfPossibleConsultMode = pathname.match(chunkOfConsultPagePattern);

    if (resultsOfPossibleConsultMode !== null && isArray(resultsOfPossibleConsultMode)) {
      return CONSULT_MODE;
    }

    return CREATE_MODE;
  }, []);

  useEffect(() => {
    let unmount = false;

    if (!unmount && !suspendExecution.includes(true)) {
      const { pathname } = history.location;

      const currentMode = getCurrentModeByPathname(pathname);

      if (isFunction(handleChangeModeTo) && lastMode !== currentMode) {
        handleChangeModeTo.call(this, currentMode);
        setLastMode(currentMode);
      }
    }

    const cleanUp = () => {
      unmount = true;
    };

    return cleanUp;
  }, [
    history,
    suspendExecution,
    getCurrentModeByPathname,
    handleChangeModeTo,
    lastMode,
    setLastMode,
  ]);
}
