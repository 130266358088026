import { getOptionByValueInList } from '../../utils/general/general-utils';
import i18n from '../../i18n';

const LOCAL_PORTO = 'PORTO';
const LOCAL_AEROPORTO = 'AEROPORTO';
const LOCAL_ORIGEM = 'LOCAL_DE_ORIGEM';
const LOCAL_DESTINO = 'LOCAL_DE_DESTINO';
const LOCAL_ENTREGA = 'LOCAL_DE_ENTREGA';

const LOCAL_PORTO_AEROPORTO_LIST = [
  {
    label: i18n.t('com.muralis.qcx.portoAeroporto.porto'),
    value: LOCAL_PORTO,
  },
  {
    label: i18n.t('com.muralis.qcx.portoAeroporto.aeroporto'),
    value: LOCAL_AEROPORTO,
  },
  {
    label: i18n.t('com.muralis.qcx.local.localOrigem'),
    value: LOCAL_ORIGEM,
  },
  {
    label: i18n.t('com.muralis.qcx.local.localDestino'),
    value: LOCAL_DESTINO,
  },
  {
    label: i18n.t('com.muralis.qcx.local.localEntrega'),
    value: LOCAL_ENTREGA,
  },
];

const isLocalPorto = (value) => (
  LOCAL_PORTO === value
);

const isLocalAeroporto = (value) => (
  LOCAL_AEROPORTO === value
);

const isLocalOrigem = (value) => (
  LOCAL_ORIGEM === value
);

const isLocalDestino = (value) => (
  LOCAL_DESTINO === value
);

const isLocalEntrega = (value) => (
  LOCAL_ENTREGA === value
);

const getLocalPortoAeroportoLabelByValue = (value) => {
  const foundOption = getOptionByValueInList(LOCAL_PORTO_AEROPORTO_LIST, value);
  return foundOption && foundOption?.label;
};

const LocalPortoAeroportoUtils = {
  PORTO: (
    LOCAL_PORTO
  ),
  AEROPORTO: (
    LOCAL_AEROPORTO
  ),
  ORIGEM: (
    LOCAL_ORIGEM
  ),
  DESTINO: (
    LOCAL_DESTINO
  ),
  ENTREGA: (
    LOCAL_ENTREGA
  ),
  LIST: (
    LOCAL_PORTO_AEROPORTO_LIST
  ),
  isPorto: (
    isLocalPorto
  ),
  isAeroporto: (
    isLocalAeroporto
  ),
  isOrigem: (
    isLocalOrigem
  ),
  isDestino: (
    isLocalDestino
  ),
  isEntrega: (
    isLocalEntrega
  ),
  getLabelByValue: (
    getLocalPortoAeroportoLabelByValue
  ),
};

export {
  LocalPortoAeroportoUtils,
  LOCAL_PORTO,
  LOCAL_AEROPORTO,
  LOCAL_ORIGEM,
  LOCAL_DESTINO,
  LOCAL_ENTREGA,
  LOCAL_PORTO_AEROPORTO_LIST,
};

export {
  isLocalPorto,
  isLocalAeroporto,
  isLocalOrigem,
  isLocalDestino,
  isLocalEntrega,
  getLocalPortoAeroportoLabelByValue,
};
