import { createSlice } from '@reduxjs/toolkit';
import {
  NONE_MODE,
  CREATE_MODE,
  CONSULT_MODE,
  UPDATE_MODE,
} from '../mode';
import {
  IDLE_STATUS,
  SUCCESS_STATUS,
  LOADING_STATUS,
  FAILURE_STATUS,
} from '../status';

const initialState = {
  status: IDLE_STATUS,
  mode: {
    main: NONE_MODE,
  },
  model: {},
};

const documentoInstrucaoDespachoDeclaracaoImportacaoSlice = createSlice({
  name: 'documentoInstrucaoDespachoDeclaracaoImportacao',
  initialState,
  reducers: {
    changeStatusTo: (state, action) => {
      state.status = action.payload.status;
    },
    loading: (state) => {
      state.status = LOADING_STATUS;
    },
    success: (state) => {
      state.status = SUCCESS_STATUS;
    },
    failure: (state) => {
      state.status = FAILURE_STATUS;
    },
    resetStatus: (state) => {
      state.status = IDLE_STATUS;
    },
    changeToCreateMode: (state) => {
      state.mode.main = CREATE_MODE;
    },
    changeToConsultMode: (state) => {
      state.mode.main = CONSULT_MODE;
    },
    changeToUpdateMode: (state) => {
      state.mode.main = UPDATE_MODE;
    },
    resetMode: (state) => {
      state.mode.main = NONE_MODE;
    },
    setModel: (state, action) => {
      state.model = action.payload;
    },
    resetModel: (state) => {
      state.model = initialState.model;
    },
  },
});

const {
  changeStatusTo,
  loading,
  success,
  failure,
  resetStatus,
  changeToConsultMode,
  changeToCreateMode,
  changeToUpdateMode,
  resetMode,
  setModel,
  resetModel,
} = documentoInstrucaoDespachoDeclaracaoImportacaoSlice.actions;

const documentoInstrucaoDespachoDeclaracaoImportacaoActions = {
  ...documentoInstrucaoDespachoDeclaracaoImportacaoSlice.actions,
};

const selectStatus = (state) => state.documentoInstrucaoDespachoDeclaracaoImportacao.status;
const selectMode = (state) => state.documentoInstrucaoDespachoDeclaracaoImportacao.mode.main;
const selectModel = (state) => state.documentoInstrucaoDespachoDeclaracaoImportacao.model;

const documentoInstrucaoDespachoDeclaracaoImportacaoSelectors = {
  selectStatus,
  selectMode,
  selectModel,
};

export {
  documentoInstrucaoDespachoDeclaracaoImportacaoSlice,
  changeStatusTo,
  loading,
  success,
  failure,
  resetStatus,
  changeToConsultMode,
  changeToCreateMode,
  changeToUpdateMode,
  resetMode,
  setModel,
  resetModel,
  documentoInstrucaoDespachoDeclaracaoImportacaoActions,
  selectStatus,
  selectMode,
  selectModel,
  documentoInstrucaoDespachoDeclaracaoImportacaoSelectors,
};

export default documentoInstrucaoDespachoDeclaracaoImportacaoSlice.reducer;
