const selectModelosDanfe = (state) => state.modeloDanfe.list;
const selectModeloDanfe = (state) => state.modeloDanfe.model;

const selectStatus = (state) => state.modeloDanfe.status;
const selectMode = (state) => state.modeloDanfe.mode.main;
const selectBackgroundMode = (state) => state.modeloDanfe.mode.background;
const selectError = (state) => state.modeloDanfe.error;
const selectResponse = (state) => state.modeloDanfe.response;

const modeloDanfeSelectors = {
  selectModelosDanfe,
  selectModeloDanfe,
  selectStatus,
  selectMode,
  selectBackgroundMode,
  selectError,
  selectResponse,
};

export {
  modeloDanfeSelectors,
  selectModelosDanfe,
  selectModeloDanfe,
  selectStatus,
  selectMode,
  selectBackgroundMode,
  selectError,
  selectResponse,
};
