import { Box, Grid, makeStyles } from '@material-ui/core';
import {
  Style as StyleIcon,
  AccountBalance as AccountBalanceIcon,
  Category as CategoryIcon,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import FaturamentoUtils from '../../utils/general/faturamento/FaturamentoUtils';
import QCXFormSubtitle from '../form-title/QCXFormSubtitle';
import QCXCardSolicitacao, { CardSolicitacaoTypeUtils } from '../numerario-importacao/QCXCardSolicitacao';

const useStyles = makeStyles(() => ({
  gridFields: {
    paddingBottom: '16px',
  },
  subtitle: {
    margin: '16px 0',
  },
}));

export default function QCXResumoSolicitacaoCards({
  initialValues,
  isLoading,
  isPreparingAction,
  isPerformingAction,
}) {
  const { t } = useTranslation();

  const classes = useStyles();

  return (
    <>
      <Grid
        item
        xs={12}
      >
        <QCXFormSubtitle
          className={classes.subtitle}
          title={t('com.muralis.qcx.numerarioImportacao.resumoSolicitacao')}
        />
      </Grid>
      <Grid item container spacing={5}>
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
        >
          <QCXCardSolicitacao
            type={CardSolicitacaoTypeUtils.ICON}
            renderIcon={(props) => (
              <CategoryIcon
                {...props}
              />
            )}
            content={{
              title: t('com.muralis.qcx.numerarioImportacao.totalItens'),
              value: FaturamentoUtils.getQuantidadeFrom(
                initialValues?.draftFields?.current
              ),
            }}
            color="#F39200"
            loading={isPreparingAction || isPerformingAction || isLoading}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={4}
        >
          <QCXCardSolicitacao
            type={CardSolicitacaoTypeUtils.ICON}
            renderIcon={(props) => (
              <AccountBalanceIcon
                {...props}
              />
            )}
            content={{
              title: t('com.muralis.qcx.faturamento.totalFaturado'),
              value: FaturamentoUtils.getTotalMoedaFrom(
                initialValues?.draftFields?.current
              ),
            }}
            color="#F39200"
            loading={isPreparingAction || isPerformingAction || isLoading}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={4}
        >
          <QCXCardSolicitacao
            type={CardSolicitacaoTypeUtils.SYMBOL}
            renderSymbol={(props) => (
              <Box
                {...props}
              >
                BRL
              </Box>
            )}
            content={{
              title: t('com.muralis.qcx.numerarioImportacao.totalReais'),
              value: FaturamentoUtils.getTotalRealFrom(
                initialValues?.draftFields?.current
              ),
            }}
            color="#F39200"
            disableIcon
            loading={isPreparingAction || isPerformingAction || isLoading}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={4}
        >
          <QCXCardSolicitacao
            type={CardSolicitacaoTypeUtils.ICON}
            renderIcon={(props) => (
              <StyleIcon
                {...props}
              />
            )}
            content={{
              title: t('com.muralis.qcx.numerarioImportacao.totalProcessos'),
              value: FaturamentoUtils.getQuantidadeFrom(initialValues),
            }}
            color="#312783"
            loading={isLoading || isPerformingAction}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={4}
        >
          <QCXCardSolicitacao
            type={CardSolicitacaoTypeUtils.ICON}
            renderIcon={(props) => (
              <AccountBalanceIcon
                {...props}
              />
            )}
            content={{
              title: t('com.muralis.qcx.numerarioImportacao.totalValorProcessos'),
              value: FaturamentoUtils.getTotalMoedaFrom(initialValues),
            }}
            color="#312783"
            loading={isLoading || isPerformingAction}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={4}
        >
          <QCXCardSolicitacao
            type={CardSolicitacaoTypeUtils.SYMBOL}
            renderSymbol={(props) => (
              <Box
                {...props}
              >
                BRL
              </Box>
            )}
            content={{
              title: t('com.muralis.qcx.numerarioImportacao.totalReais'),
              value: FaturamentoUtils.getTotalRealFrom(initialValues),
            }}
            color="#312783"
            disableIcon
            loading={isLoading || isPerformingAction}
          />
        </Grid>
      </Grid>
    </>
  );
}
