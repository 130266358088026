import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AUTO_LOAD_CONTROL } from '../config-control';
import {
  ALTERNATIVE_LOADING_STATUS,
  FAILURE_STATUS,
  IDLE_STATUS,
  LOADING_STATUS,
  SUCCESS_STATUS,
} from '../status';
import {
  fetchByFilter,
} from '../tipo-documento-due/tipoDocumentoDueAPI';
import i18n from '../../i18n';

const initialState = {
  status: IDLE_STATUS,
  error: null,
  response: {
    status: null,
    message: null,
    data: null,
  },
  config: { control: AUTO_LOAD_CONTROL },
  list: [],
};

const fetchByFilterAsync = createAsyncThunk(
  'tipoDocumentoDueTemporaria/fetchByFilter',
  async (params) => {
    const { data } = await fetchByFilter(params);
    return { data };
  }
);

const tipoDocumentoDueTemporariaSlice = createSlice({
  name: 'tipoDocumentoDueTemporaria',
  initialState,
  reducers: {
    changeStatusTo: (state, action) => {
      state.status = action.payload.status;
    },
    loading: (state) => {
      state.status = LOADING_STATUS;
    },
    success: (state) => {
      state.status = SUCCESS_STATUS;
    },
    failure: (state) => {
      state.status = FAILURE_STATUS;
    },
    resetStatus: (state) => {
      state.status = IDLE_STATUS;
    },
    setResponse: (state, action) => {
      state.response = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    clearResponse: (state) => {
      state.response = {
        status: null,
        message: null,
        data: null,
      };
    },
    resetList: (state) => {
      state.list = [];
    },
    setConfig: (state, action) => {
      state.config = action.payload;
    },
    changeControlTo: (state, action) => {
      state.config.control = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchByFilterAsync.pending, (state) => {
        state.status = ALTERNATIVE_LOADING_STATUS;
      })
      .addCase(fetchByFilterAsync.fulfilled, (state, action) => {
        state.status = IDLE_STATUS;
        state.list = action.payload.data;
      })
      .addCase(fetchByFilterAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroCarregarListaTipoDocumentoDueTemporaria', { erro: action.error.message }),
        };
      });
  },
});

const {
  changeStatusTo,
  loading,
  success,
  failure,
  preparingAction,
  resetStatus,
  changeControlTo,
  changeToConsultMode,
  changeToCreateMode,
  changeToUpdateMode,
  resetMode,
  changeToBackgroundCreateMode,
  resetBackgroundMode,
  setConfig,
  addToList,
  updateOnList,
  setResponse,
  setResponseMessage,
  clearResponse,
  setError,
  setModel,
  resetModel,
  resetList,
} = tipoDocumentoDueTemporariaSlice.actions;

const selectTiposDocumentoDueTemporaria = (
  (state) => state.tipoDocumentoDueTemporaria.list
);
const selectStatus = (state) => state.tipoDocumentoDueTemporaria.status;
const selectError = (state) => state.tipoDocumentoDueTemporaria.error;
const selectResponse = (state) => state.tipoDocumentoDueTemporaria.response;
const selectConfigControl = (state) => state.tipoDocumentoDueTemporaria.config.control;

const tipoDocumentoDueActions = {
  ...tipoDocumentoDueTemporariaSlice.actions,
  selectConfigControl,
  selectStatus,
  selectError,
  selectResponse,
  selectTiposDocumentoDueTemporaria,
  fetchByFilterAsync,
};
export {
  tipoDocumentoDueTemporariaSlice,
  changeStatusTo,
  loading,
  success,
  failure,
  preparingAction,
  resetStatus,
  changeControlTo,
  changeToConsultMode,
  changeToCreateMode,
  changeToUpdateMode,
  resetMode,
  changeToBackgroundCreateMode,
  resetBackgroundMode,
  setConfig,
  addToList,
  updateOnList,
  setResponse,
  setResponseMessage,
  clearResponse,
  setError,
  setModel,
  resetModel,
  resetList,
  tipoDocumentoDueActions,
  fetchByFilterAsync,
  selectTiposDocumentoDueTemporaria,
  selectStatus,
  selectError,
  selectResponse,
  selectConfigControl,
};

export default tipoDocumentoDueTemporariaSlice.reducer;
