const CREATE_MODE = 'create';
const CONSULT_MODE = 'consult';
const UPDATE_MODE = 'update';
const LOCKED_MODE = 'lock';
const TRANSFER_MODE = 'transfer';
const REVERSAL_MODE = 'reversal';
const EXTERNAL_MODE = 'external';
const BATCH_APPROVAL_MODE = 'approvalBatch';
const NONE_MODE = 'none';

const BACKGROUND_CREATE_MODE = 'backgroundCreate';
const BACKGROUND_CONSULT_MODE = 'backgroundConsult';
const BACKGROUND_UPDATE_MODE = 'backgroundUpdate';
const BACKGROUND_DELETE_MODE = 'backgroundDelete';
const BACKGROUND_TRANSFER_MODE = 'backgroundTransfer';
const BACKGROUND_REVERSAL_MODE = 'backgroundReversal';
const NONE_BACKGROUND_MODE = 'noneBackground';

const SUB_CREATE_MODE = 'subCreate';
const SUB_CONSULT_MODE = 'subConsult';
const SUB_UPDATE_MODE = 'subUpdate';
const NONE_SUB_MODE = 'noneSub';

const SUB_BACKGROUND_CREATE_MODE = 'subBackgroundCreate';
const SUB_BACKGROUND_CONSULT_MODE = 'subBackgroundConsult';
const SUB_BACKGROUND_UPDATE_MODE = 'subBackgroundUpdate';
const SUB_BACKGROUND_DELETE_MODE = 'subBackgroundDelete';
const NONE_SUB_BACKGROUND_MODE = 'noneSubBackground';

export {
  CREATE_MODE,
  CONSULT_MODE,
  UPDATE_MODE,
  LOCKED_MODE,
  TRANSFER_MODE,
  REVERSAL_MODE,
  EXTERNAL_MODE,
  BATCH_APPROVAL_MODE,
  NONE_MODE,
  BACKGROUND_CREATE_MODE,
  BACKGROUND_CONSULT_MODE,
  BACKGROUND_UPDATE_MODE,
  BACKGROUND_DELETE_MODE,
  BACKGROUND_TRANSFER_MODE,
  BACKGROUND_REVERSAL_MODE,
  NONE_BACKGROUND_MODE,
  SUB_CREATE_MODE,
  SUB_CONSULT_MODE,
  SUB_UPDATE_MODE,
  NONE_SUB_MODE,
  SUB_BACKGROUND_CREATE_MODE,
  SUB_BACKGROUND_CONSULT_MODE,
  SUB_BACKGROUND_UPDATE_MODE,
  SUB_BACKGROUND_DELETE_MODE,
  NONE_SUB_BACKGROUND_MODE,
};
