import { useMemo } from "react";
import { ClientData } from "../types/clientData";
import { useGetRequest, QueriesTypes } from "./queries";

export const useListClientes = (): [ClientData[], boolean, boolean] => {
  const {
    data: clientesData,
    isError,
    isLoading,
  } = useGetRequest(QueriesTypes.Clientes);

  let clientes: ClientData[] = useMemo(() => {
    if (clientesData && !isError) {
      return clientesData;
    } else {
      return [];
    }
  }, [clientesData, isError]);

  const clientesOrdenados = useMemo(() => {
    if (clientes) {
      return clientes.sort((a, b) => {
        const descriptionA = a.description ?? '';
        const descriptionB = b.description ?? '';
        return descriptionA.localeCompare(descriptionB);
      });
    };
    return [];
  }, [clientes]);

  return [clientesOrdenados, isLoading, isError];
};
