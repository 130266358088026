import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import QCXFinalCheckboxField from '../../../../../../shared-components/final-checkbox-field/QCXFinalCheckboxField';
import QCXSelectUnidadeNegocioAutocomplete from '../../../../../../shared-components/select-unidade-negocio/QCXSelectUnidadeNegocioAutocomplete';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingBottom: theme.spacing(2), // Adjust the value as per your requirement
  },
}));

const ProcessosInfoForm = () => {
  const classes = useStyles();

  return (
    <Grid container spacing={4} alignItems="center" justify="space-between" className={classes.container}>
      <Grid item xs={8}>
        <QCXSelectUnidadeNegocioAutocomplete label="Unidade dos processos:" name="unidadeProcesso" autoLoad />
      </Grid>
      <Grid item xs={4}>
        <QCXFinalCheckboxField name="limparDadosAposRepasse" label="Limpar Dados Após o repasse" required={false} />
      </Grid>
    </Grid>
  );
};

export default ProcessosInfoForm;
