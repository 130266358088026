import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  IDLE_STATUS,
  SUCCESS_STATUS,
  LOADING_STATUS,
  ALTERNATIVE_LOADING_STATUS,
  FAILURE_STATUS,
} from '../status';
import {
  fetchAll,
  fetchById,
} from './fundamentoLegalPisCofinsAPI';
import i18n from '../../i18n';

const initialState = {
  status: IDLE_STATUS,
  error: null,
  response: {
    status: null,
    message: null,
    data: null,
  },
  list: [],
};

const fetchAllAsync = createAsyncThunk(
  'fundamentoLegalPisCofins/fetchAll',
  async () => {
    const { data } = await fetchAll();
    return { data };
  }
);

const fetchByIdAsync = createAsyncThunk(
  'fundamentoLegalPisCofins/fetchById',
  async (data) => {
    const response = await fetchById(data);
    return {
      response: {
        status: response.status,
        data: response.data,
      },
    };
  }
);

const fundamentoLegalPisCofinsSlice = createSlice({
  name: 'fundamentoLegalPisCofins',
  initialState,
  reducers: {
    changeStatusTo: (state, action) => {
      state.status = action.payload.status;
    },
    loading: (state) => {
      state.status = LOADING_STATUS;
    },
    success: (state) => {
      state.status = SUCCESS_STATUS;
    },
    failure: (state) => {
      state.status = FAILURE_STATUS;
    },
    resetStatus: (state) => {
      state.status = IDLE_STATUS;
    },
    setResponse: (state, action) => {
      state.response = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    clearResponse: (state) => {
      state.response = {
        status: null,
        message: null,
        data: null,
      };
    },
    resetList: (state) => {
      state.list = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllAsync.pending, (state) => {
        state.status = ALTERNATIVE_LOADING_STATUS;
      })
      .addCase(fetchAllAsync.fulfilled, (state, action) => {
        state.status = IDLE_STATUS;
        state.list = action.payload.data;
      })
      .addCase(fetchAllAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.carregarListaOrgaosEmissores', { erro: action.error.message }),
        };
      })
      .addCase(fetchByIdAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(fetchByIdAsync.fulfilled, (state, action) => {
        if (action.payload.response.status === 200) {
          state.model = action.payload.response.data;
          state.status = SUCCESS_STATUS;
        }
      })
      .addCase(fetchByIdAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.carregarDadosOrgaoEmissor', { erro: action.error.message }),
        };
      });
  },
});

const {
  changeStatusTo,
  loading,
  success,
  failure,
  resetStatus,
  setResponse,
  setResponseMessage,
  clearResponse,
  setError,
  resetList,
} = fundamentoLegalPisCofinsSlice.actions;

const selectFundamentosLegaisPisCofins = (state) => state.fundamentoLegalPisCofins.list;
const selectStatus = (state) => state.fundamentoLegalPisCofins.status;
const selectError = (state) => state.fundamentoLegalPisCofins.error;
const selectResponse = (state) => state.fundamentoLegalPisCofins.response;

export {
  fundamentoLegalPisCofinsSlice,
  changeStatusTo,
  loading,
  success,
  failure,
  resetStatus,
  setResponse,
  setResponseMessage,
  clearResponse,
  setError,
  fetchAllAsync,
  fetchByIdAsync,
  resetList,
  selectFundamentosLegaisPisCofins,
  selectStatus,
  selectError,
  selectResponse,
};

export default fundamentoLegalPisCofinsSlice.reducer;
