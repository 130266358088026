import AssessmentIcon from '@mui/icons-material/Assessment';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import CardsGrid, { CardsGridItem } from '../../common/components/cardsGrid/cardsGrid';
import BasicLayout from '../../common/layouts/basicLayout';

const gridItems: CardsGridItem[] = [
  {
    title: 'Halliburton',
    icon: InsertDriveFileIcon,
    path: '/relatorios/halliburton',
    requiredGroups: ['8246575', '154792'],
    requiredRoles: ['relatorio-halliburton-visualizar'],
  },
  {
    title: 'Draft DANFE Excel',
    icon: InsertDriveFileIcon,
    path: '/relatorios/danfe',
    requiredRoles: ['relatorio-draft-danfe-excel-visualizar'],
  },
  {
    title: 'Planilha LA',
    icon: InsertDriveFileIcon,
    path: '/relatorios/la',
    requiredRoles: ['relatorio-planilha-la-visualizar'],
  },
  {
    title: 'Associated',
    icon: InsertDriveFileIcon,
    path: '/relatorios/associated',
    requiredGroups: ['8246575', '154792'],
    requiredRoles: ['relatorio-associated-visualizar'],
  },
];

const RelatoriosPage = () => {
  return (
    <BasicLayout title={'Relatórios'} icon={<AssessmentIcon color={'secondary'} />}>
      <CardsGrid items={gridItems}></CardsGrid>
    </BasicLayout>
  );
};

export default RelatoriosPage;
