const METODO_1_ART_1_DO_ACORDO_DECRETO_92930_86_CODE = '01';
const METODO_2_ART_2_DO_ACORDO_DECRETO_92930_86_CODE = '02';
const METODO_3_ART_3_DO_ACORDO_DECRETO_92930_86_CODE = '03';
const METODO_4_ART_4_DO_ACORDO_DECRETO_92930_86_CODE = '04';
const METODO_5_ART_5_DO_ACORDO_DECRETO_92930_86_CODE = '05';
const METODO_6_ART_6_DO_ACORDO_DECRETO_92930_86_CODE = '06';

const configureMetodoValoracaoMatcherWith = (metodosValoracao) => {
  const isSomeMetodoValoracaoConfiguredById = (id, codesToCompare = []) => {
    const foundMetodoValoracao = metodosValoracao.find((metodoValoracao) => (
      metodoValoracao?.id === id
    ));

    return codesToCompare.includes(foundMetodoValoracao?.code);
  };

  return isSomeMetodoValoracaoConfiguredById;
};

const configureSpecificMetodoValoracaoMatcherWith = (defaultCode) => {
  const configureSpecificMatcherWith = (list) => {
    const isMetodoValoracaoConfiguredById = (id) => {
      const foundMetodoValoracao = list.find((metodoValoracao) => (
        metodoValoracao?.id === id
      ));

      return (
        defaultCode === foundMetodoValoracao?.code
      );
    };

    return isMetodoValoracaoConfiguredById;
  };

  return configureSpecificMatcherWith;
};

const configureSpecificMetodoValoracaoArt1SuspensaoMatcher = (
  configureSpecificMetodoValoracaoMatcherWith(METODO_1_ART_1_DO_ACORDO_DECRETO_92930_86_CODE)
);

export {
  METODO_1_ART_1_DO_ACORDO_DECRETO_92930_86_CODE,
  METODO_2_ART_2_DO_ACORDO_DECRETO_92930_86_CODE,
  METODO_3_ART_3_DO_ACORDO_DECRETO_92930_86_CODE,
  METODO_4_ART_4_DO_ACORDO_DECRETO_92930_86_CODE,
  METODO_5_ART_5_DO_ACORDO_DECRETO_92930_86_CODE,
  METODO_6_ART_6_DO_ACORDO_DECRETO_92930_86_CODE,
};

export {
  configureMetodoValoracaoMatcherWith,
  configureSpecificMetodoValoracaoArt1SuspensaoMatcher,
};
