import { Grid } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormState } from 'react-final-form';
import QCXFinalCheckboxField from '../../shared-components/final-checkbox-field/QCXFinalCheckboxField';

export default function QCXFaturaFornecedorFlagsFormGroup({
  isConsult,
}) {
  const { t } = useTranslation();

  const { values } = useFormState();

  return (
    <>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <QCXFinalCheckboxField
          id="checkbox-dados-comum-fornecedor-field"
          key="checkbox-dados-comum-fornecedor-field"
          name="dadosComumFornecedor"
          label={t('com.muralis.qcx.fatura.dadosComumTodosItens')}
          disabled={isConsult}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <QCXFinalCheckboxField
          id="checkbox-sobrescreve-dados-partnumber-field"
          key="checkbox-sobrescreve-dados-partnumber-field"
          name="sobrescreveDadosPartnumber"
          label={t('com.muralis.qcx.fornecedor.sobrescreverInformacoesFornecedor')}
          disabled={isConsult || !values?.dadosComumFornecedor}
        />
      </Grid>
    </>
  );
}
