import { createFilterOptions } from '@material-ui/lab/useAutocomplete';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormState } from 'react-final-form';
import {
  fetchAllAsync,
  selectUnidadesNegocio,
  selectConfigControl,
} from '../../features/unidade-negocio/unidadeNegocioSlice';
import { isAutoLoadControl } from '../../utils/store/store-utils';
import QCXAutocompleteSelect from '../autocomplete-select/QCXAutocompleteSelect';

export default function QCXSelectUnidadeNegocioAutocomplete({
  label,
  name,
  initialValues = null,
  autoLoad = false,
  customFilter = () => true,
  ...restProps
}) {
  const dispatch = useDispatch();
  const list = useSelector(selectUnidadesNegocio);
  const configControl = useSelector(selectConfigControl);

  const { values } = useFormState();

  const localInitialValues = useMemo(() => initialValues ?? values, [initialValues, values]);

  useEffect(() => {
    if (isAutoLoadControl(configControl) || autoLoad) {
      dispatch(fetchAllAsync());
    }
  }, [configControl]);

  const [defaultValue, setDefaultValue] = useState('');

  useEffect(() => {
    const value = list.find((item) => item?.id === _.get(localInitialValues, name));
    if (value) {
      setDefaultValue(value);
    } else {
      setDefaultValue({ id: null, description: '' });
    }
  }, [list, localInitialValues]);

  const filterOptions = createFilterOptions({
    stringify: (item) => `${item?.pessoa?.nomeResumido}`,
  });
  return (
    <QCXAutocompleteSelect
      label={label}
      id={name}
      name={name}
      options={list.filter(customFilter)}
      filterOptions={filterOptions}
      defaultValue={defaultValue}
      getOptionLabel={(item) => (item?.pessoa?.nomeResumido ? `${item?.pessoa?.nomeResumido}` : '')}
      {...restProps}
    />
  );
}
