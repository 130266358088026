// Use to format and not round
const brlFormatter = new Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL",
  minimumFractionDigits: 2, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
});

// Use to format and round
const brlFormatterRounded = new Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL",
  maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

export { brlFormatter, brlFormatterRounded };
