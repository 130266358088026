import { createFilterOptions } from '@material-ui/lab/useAutocomplete';

import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import QCXAutocompleteSelect from '../autocomplete-select/QCXAutocompleteSelect';

import i18n from '../../i18n';

export default function QCXSelectNcmAtributoAutocomplete({
  id,
  key,
  name,
  label = i18n.t('com.muralis.qcx.nomeApresentacao'),
  list,
  filterPredicate,
  ...restProps
}) {
  const [defaultValue, setDefaultValue] = useState('');

  useEffect(() => {
    const value = list.find((item) => item?.id === _.get(list, name));
    if (value) {
      setDefaultValue(value);
    } else {
      setDefaultValue({ id: null, description: '' });
    }
  }, [list]);

  const filterOptions = createFilterOptions({
    stringify: (item) => `${item.codigo}`,
  });

  return (
    <QCXAutocompleteSelect
      id={id}
      name={name}
      key={key}
      label={label}
      options={list}
      filterOptions={filterOptions}
      defaultValue={defaultValue}
      getOptionLabel={(item) => `${item?.codigo} - ${item?.nomeApresentacao}`}
      {...restProps}
    />
  );
}
