import React from 'react';
import QCXCheckbox from '../checkbox/QCXCheckbox';
import QCXFormControlLabel from '../form-control-label/QCXFormControlLabel';

export default function QCXCheckboxLabel({
  label,
  disabled,
  ...restProps
}) {
  return (
    <QCXFormControlLabel
      style={{
        textTransform: 'uppercase',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      }}
      control={<QCXCheckbox disabled={disabled} {...restProps} />}
      label={label}
      disabled={disabled}
    />
  );
}
