import { createSlice } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import i18n from '../../i18n';
import { DEFAULT_OPERATION } from '../operation';

const initialState = {
  authorization: {
    operation: {
      active: false,
      authorized: false,
      type: DEFAULT_OPERATION,
      title: i18n.t('com.muralis.qcx.acoes.confirmarOperacao'),
      message: i18n.t('com.muralis.qcx.mensagem.confirmarOperacaoMensagem'),
    },
  },
  query: undefined,
};

const controllerSlice = createSlice({
  name: 'controller',
  initialState,
  reducers: {
    activateOperation: (state) => {
      state.authorization.operation.active = true;
    },
    setOperationTitle: (state, action) => {
      state.authorization.operation.title = action.payload;
    },
    setOperationMessage: (state, action) => {
      state.authorization.operation.message = action.payload;
    },
    setOperationType: (state, action) => {
      state.authorization.operation.type = action.payload;
    },
    confirmOperation: (state) => {
      state.authorization.operation.authorized = true;
      state.authorization.operation.active = false;
    },
    setOperation: (state, action) => {
      state.authorization.operation.authorized = action.payload?.authorized || false;
      state.authorization.operation.active = action.payload?.active || false;
      state.authorization.operation.title = (
        !action.payload?.title || isEmpty(action.payload?.title)
          ? initialState.authorization.operation.title
          : action.payload?.title
      );
      state.authorization.operation.message = (
        !action.payload?.message || isEmpty(action.payload?.message)
          ? initialState.authorization.operation.message
          : action.payload?.message
      );
    },
    resetOperation: (state) => {
      state.authorization.operation = initialState.authorization.operation;
    },
    setQuery: (state, action) => {
      state.query = action.payload;
    },
    resetQuery: (state) => {
      state.query = undefined;
    },
  },
});

const {
  activateOperation,
  setOperationType,
  setOperationTitle,
  setOperationMessage,
  confirmOperation,
  setOperation,
  resetOperation,
  setQuery,
  resetQuery,
} = controllerSlice.actions;

const selectOperation = (state) => state.controller.authorization.operation;
const selectOperationActive = (state) => state.controller.authorization.operation.active;
const selectOperationAuthorized = (state) => state.controller.authorization.operation.authorized;
const selectOperationType = (state) => state.controller.authorization.operation.type;
const selectOperationTitle = (state) => state.controller.authorization.operation.title;
const selectOperationMessage = (state) => state.controller.authorization.operation.message;
const selectQuery = (state) => state.controller.query;

export {
  controllerSlice,
  activateOperation,
  setOperationType,
  setOperationTitle,
  setOperationMessage,
  confirmOperation,
  setOperation,
  resetOperation,
  setQuery,
  resetQuery,
  selectOperation,
  selectOperationActive,
  selectOperationAuthorized,
  selectOperationType,
  selectOperationTitle,
  selectOperationMessage,
  selectQuery,
};

export default controllerSlice.reducer;
