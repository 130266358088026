import React, { useMemo } from 'react';
import { v4 as uuid } from 'uuid';
import QCXFinalFieldWrapper from '../final-field/QCXFinalFieldWrapper';
import QCXTextField from '../text-field/QCXTextField';

export default function QCXFinalOnblurMaskedField({
  key = '',
  name,
  label,
  type,
  maxLength,
  required,
  validate,
  formatOnBlur = true,
  children,
  format,
  parse,
  submitSucceeded,
  ...restProps
}) {
  // FIXME: Solucao provisoria
  const generatedKey = useMemo(() => (
    submitSucceeded ? uuid() : key
  ), [submitSucceeded, key]);

  return (
    <QCXFinalFieldWrapper
      key={generatedKey}
      name={name}
      type={type}
      label={label}
      maxLength={maxLength}
      required={required}
      component={QCXTextField}
      validate={validate}
      format={format}
      parse={parse}
      formatOnBlur={formatOnBlur}
      {...restProps}
    />
  );
}
