import { isStrictEquals } from '../general-utils';

const TIPO_RELATORIO_CONTAS_PAGAR = 'CONTAS_PAGAR';
const STATUS_PENDENTE = 'PENDENTE';
const STATUS_CANCELADO = 'CANCELADO';
const STATUS_PAGO = 'PAGO';
const MOVIMENTACOES_SAIDA = 'SAIDA';
const MOVIMENTACOES_ENTRADA = 'ENTRADA';
const MOVIMENTACOES_TRANSFERENCIA = 'TRANSFERENCIA';
const TITULO_MOVIMENTACAO_CONTA_TRANSFERENCIA = 'TITULO_MOVIMENTACAO_CONTA';
const ACAO_CANCELAR = 'CANCELAR';

const isReportContasPagar = (status) => (
  isStrictEquals(TIPO_RELATORIO_CONTAS_PAGAR, status)
);

const isStatusPago = (status) => (
  isStrictEquals(STATUS_PAGO, status)
);

const isStatusPendente = (status) => (
  isStrictEquals(STATUS_PENDENTE, status)
);

const isStatusCancelado = (status) => (
  isStrictEquals(STATUS_CANCELADO, status)
);

const valorTipoMovimentacao = (movimentacoes, tipo) => (movimentacoes
  .filter((conta) => conta?.tipo === tipo)
  .map((movimentacao) => movimentacao?.valor));

const somaTipoMovimentacao = (tipoMovimentacaoLista) => (tipoMovimentacaoLista
  .reduce((soma, atual) => soma + atual)
);

const calculoValorPagar = (movimentacoes = []) => {
  const movimentacoesAtivas = movimentacoes.length > 0;
  let valorRecebido = 0;

  if (movimentacoesAtivas) {
    const contasPagarEntradaLista = valorTipoMovimentacao(movimentacoes, MOVIMENTACOES_ENTRADA);
    const contasPagarSaidaLista = valorTipoMovimentacao(movimentacoes, MOVIMENTACOES_SAIDA);

    const contasPagarEntradaListaAtivas = contasPagarEntradaLista.length > 0;
    const contasPagarSaidaListaAtivas = contasPagarSaidaLista.length > 0;

    const contasPagarEntrada = (contasPagarEntradaListaAtivas ? contasPagarEntradaLista : 0);
    const contasPagarSaida = (contasPagarSaidaListaAtivas ? contasPagarSaidaLista : 0);

    const somaPagarEntrada = (contasPagarEntrada !== 0
      ? somaTipoMovimentacao(contasPagarEntrada) : 0);

    const somaPagarSaida = (contasPagarSaida !== 0
      ? somaTipoMovimentacao(contasPagarSaida) : 0);

    if (somaPagarEntrada > somaPagarSaida) {
      valorRecebido = (somaPagarEntrada - somaPagarSaida);
    } else {
      valorRecebido = (somaPagarSaida - somaPagarEntrada);
    }
  }
  return valorRecebido;
};

const calculoSaldoContasPagar = (movimentacoes = [], valorPagar) => {
  const valorRecebido = calculoValorPagar(movimentacoes);
  const valorSaldo = valorRecebido - valorPagar;
  return valorSaldo;
};

const contasPagarUtils = {
  CONTAS_PAGAR: TIPO_RELATORIO_CONTAS_PAGAR,
  CANCELAR: ACAO_CANCELAR,
  PAGO: STATUS_PAGO,
  PENDENTE: STATUS_PENDENTE,
  CANCELADO: STATUS_CANCELADO,
  SAIDA: MOVIMENTACOES_SAIDA,
  ENTRADA: MOVIMENTACOES_ENTRADA,
  TRANSFERENCIA: MOVIMENTACOES_TRANSFERENCIA,
  TITULO_TRANSFERENCIA: TITULO_MOVIMENTACAO_CONTA_TRANSFERENCIA,
  isContasPagar: (
    isReportContasPagar
  ),
  isPago: (
    isStatusPago
  ),
  isPendente: (
    isStatusPendente
  ),
  isCancelado: (
    isStatusCancelado
  ),
  saldoContasPagar: (
    calculoSaldoContasPagar
  ),
};

export default contasPagarUtils;
