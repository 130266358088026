import { Grid } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import QCXFinalForm from '../../shared-components/final-form/QCXFinalForm';
import QCXFinalTextField from '../../shared-components/final-text-field/QCXFinalTextField';
import { required as requiredValidator } from '../../utils/validators/field/validator';

export default function QCXGrupoUnidadeNegocioForm({
  isConsult,
  isUpdate,
  initialValues,
  handleSubmit,
  handleChangeToUpdate,
  handleCancelUpdate,
  handleChangeUnidadeNegocio,
  ...restProps
}) {
  const { t } = useTranslation();

  return (
    <>
      <QCXFinalForm
        isConsult={isConsult}
        isUpdate={isUpdate}
        initialValues={initialValues}
        handleSubmitValues={handleSubmit}
        handleChangeToUpdate={handleChangeToUpdate}
        handleCancelUpdate={handleCancelUpdate}
        {...restProps}
      >

        {() => (
          <>
            {(isConsult || isUpdate) && (
              <Grid item xs={12} sm={6} md={2}>
                <QCXFinalTextField
                  id="text-field-code"
                  key="text-field-code"
                  name="id"
                  label={t('com.muralis.qcx.codigo')}
                  disabled
                />
              </Grid>
            )}
            <Grid
              item
              xs={12}
              sm={6}
              md={(isConsult || isUpdate) ? 6 : 8}
            >
              <QCXFinalTextField
                name="description"
                label={t('com.muralis.qcx.nome')}
                maxLength={250}
                disabled={isConsult}
                validate={requiredValidator}
                required
              />
            </Grid>
          </>
        )}
      </QCXFinalForm>
    </>
  );
}
