import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import { Tooltip } from '@material-ui/core';
import { isFunction } from 'lodash';

export default function QCXIconButton({
  disableTooltip = false,
  tooltipProps = {},
  color = 'default',
  disabled,
  children,
  ...restProps
}) {
  const iconButton = (
    <IconButton color={color} disabled={disabled} {...restProps}>
      {isFunction(children)
        ? children({
            color,
            disabled,
          })
        : children}
    </IconButton>
  );
  return !disableTooltip ? (
    <Tooltip arrow {...tooltipProps}>
      <span>{iconButton}</span>
    </Tooltip>
  ) : (
    iconButton
  );
}
