/* eslint-disable max-len */
import { Grid } from '@material-ui/core';
import React, { useCallback, useMemo } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { isNaN } from 'lodash';
import { useSelector } from 'react-redux';
import QCXFinalAlternativeCurrencyField from '../../shared-components/final-currency-field/QCXFinalAlternativeCurrencyField';
import QCXFinalNumericDecimalField from '../../shared-components/final-numeric-decimal-field/QCXFinalNumericDecimalField';
import QCXFinalPercentualField from '../../shared-components/final-percentual-field/QCXFinalPercentualField';
import QCXSelectFormaCalculoAutocomplete from '../../shared-components/select-forma-calculo/QCXSelectFormaCalculoAutocomplete';
import QCXFinalMultilineTextField from '../../shared-components/final-text-field/QCXFinalMultilineTextField';
import QCXFinalTextField from '../../shared-components/final-text-field/QCXFinalTextField';
import QCXSelectProdutoAutocomplete from '../../shared-components/select-catalogo-produto/QCXSelectProdutoAutocomplete';
import QCXSelectUnidadeMedidaAutocomplete from '../../shared-components/select-unidade-medida/QCXSelectUnidadeMedidaAutocomplete';
// import QCXMoveFocusInside from '../focus-lock/QCXMoveFocusInside';
import { required as requiredValidator } from '../../utils/validators/field/validator';
import QCXNcmNaladiFormGroup from '../ncm-naladi-form-group/QCXNcmNaladiFormGroup';
import QCXSelectIncotermCondicaoVendaAutocomplete from '../../shared-components/final-select-incoterm-condicao-venda-field/QCXSelectIncotermCondicaoVendaAutocomplete';
import QCXSelectCFOPOperacoesFiscaisAutocomplete from '../../shared-components/final-autocomplete-select-cfop-operacoes-fiscais-field/QCXSelectCFOPOperacoesFiscaisAutocomplete';
import QCXSelectTratamentoPrioritarioAutocomplete from '../../shared-components/select-tratamento-prioritario/QCXSelectTratamentoPrioritarioAutocomplete';
import QCXFinalCheckboxField from '../../shared-components/final-checkbox-field/QCXFinalCheckboxField';
import QCXEnquadramentosOperacaoFormGroup from '../declaracao-unica-exportacao/QCXEnquadramentosOperacaoFormGroup';
import { formatNotZero } from '../../utils/hooks/form/field/formatters';
import QCXFinalNumericIntegerField from '../../shared-components/final-numeric-integer-field/QCXFinalNumericIntegerField';
import QCXSelectEmpresaEstrangeiraAutocomplete from '../../shared-components/final-select-empresa-estrangeira-field/QCXSelectEmpresaEstrangeiraAutocomplete';
import QCXFormListenerManager from '../../shared-components/form-listener/QCXFormListenerManager';
import QCXFinalNumericDecimalFieldRender from './QCXFinalNumericDecimalFieldRender';
import QCXFinalAlternativeCurrencyFieldRender from './QCXFinalAlternativeCurrencyFieldRender';
import { selectRelatedModelFollowUp } from '../../features/declaracao-unica-exportacao/declaracaoUnicaExportacaoSelectors';

export default function QCXFaturaMercadoriaExportacaoStandardFormGroup({
  tipo,
  modes,
  classes,
  isFilling,
  quantidadeEstatisticaProps,
  onBlurObjectHandlers,
  required = true,
}) {
  const { t } = useTranslation();
  const form = useForm();
  const { values } = useFormState();

  const relatedFollowUp = useSelector(selectRelatedModelFollowUp);

  const { isConsult, isSubConsult, isSubNone } = modes;

  const { isQuantidade, isQuilograma } = quantidadeEstatisticaProps;

  const { handleQuantidadeOnBlur, handleValorTotalMoedaOnBlur, handleValorUnitarioMoedaProdutoOnBlur } =
    onBlurObjectHandlers;

  const fabricanteFilterPredicate = useCallback(
    (empresaEstrangeira) => empresaEstrangeira?.fabricante && empresaEstrangeira?.active,
    []
  );

  const enquadramentosOperacaoFieldProps = useMemo(
    () => ({
      enquadramento: {
        primeiro: {
          id: 'select-field-primeiro-enquadramento',
          key: 'select-field-primeiro-enquadramento',
          name: 'due.primeiroEnquadramento.id',
        },
        segundo: {
          id: 'select-field-segundo-enquadramento',
          key: 'select-field-segundo-enquadramento',
          name: 'due.segundoEnquadramento.id',
        },
        terceiro: {
          id: 'select-field-terceiro-enquadramento',
          key: 'select-field-terceiro-enquadramento',
          name: 'due.terceiroEnquadramento.id',
        },
        quarto: {
          id: 'select-field-quarto-enquadramento',
          key: 'select-field-quarto-enquadramento',
          name: 'due.quartoEnquadramento.id',
        },
      },
    }),
    []
  );

  const exportadorNaoFabricante = useMemo(() => values?.due?.exportadorNaoFabricante, [values]);

  const followUpHasMetadata = useMemo(() => relatedFollowUp?.metadata, [relatedFollowUp]);

  const onExportadorNaoFabricanteChange = useCallback(
    () => (value) => {
      if (!value) {
        form.change('due.fornecedor.id', undefined);
      }
    },
    [form]
  );

  const onChangeListeners = useMemo(
    () => [
      {
        name: 'due.exportadorNaoFabricante',
        fn: onExportadorNaoFabricanteChange,
      },
    ],
    [onExportadorNaoFabricanteChange]
  );

  return (
    <>
      <QCXFormListenerManager form={form} values={values} onChangeListeners={onChangeListeners} />
      <Grid item xs={12} sm={12} md={6} lg={6}>
        {/* <QCXMoveFocusInside> */}
        <QCXSelectProdutoAutocomplete
          id="autocomplete-field-partnumber"
          key="autocomplete-field-partnumber"
          name="partnumberAutocompleteSelector"
          label={t('com.muralis.qcx.item.partNumber')}
          tipo={tipo}
          initialValues={values}
          form={form}
          disabled={isConsult || isSubConsult || isSubNone}
          partnumber={values?.partnumber}
        />
        {/* </QCXMoveFocusInside> */}
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6} style={{ paddingTop: '14px' }}>
        <QCXFinalTextField
          id="text-field-descricao"
          key="text-field-descricao"
          name="descricaoResumida"
          label={t('com.muralis.qcx.mercadoria.descricaoResumida')}
          disabled={isConsult || isSubConsult || isSubNone}
          maxLength={50}
        />
      </Grid>
      <Grid item container xs={12} sm={12} md={6} lg={6} alignContent="flex-start">
        <Grid
          item
          style={{
            paddingTop: '8px',
            paddingBottom: '16px',
          }}
          xs={12}
          sm={12}
          md={12}
          lg={12}
        >
          <QCXFinalNumericDecimalFieldRender
            id="numeric-decimal-quantidade-field"
            name="quantidade"
            label={t('com.muralis.qcx.quantidade.label')}
            onBlur={handleQuantidadeOnBlur(form, values)}
            disabled={isConsult || isSubConsult || isSubNone}
            validate={required && requiredValidator}
            scale={5}
            required={required && (isNaN(Number(values?.quantidade)) || Number(values?.quantidade) <= 0)}
          />
        </Grid>
        <Grid
          item
          style={{
            paddingBottom: '16px',
          }}
          xs={12}
          sm={12}
          md={12}
          lg={12}
        >
          <QCXSelectUnidadeMedidaAutocomplete
            id="select-unidade-medida-field"
            key="select-unidade-medida-field"
            name="unidadeDeMedida.id"
            label={t('com.muralis.qcx.unidadeMedida.label')}
            initialValues={values}
            disabled={isConsult || isSubConsult || isSubNone}
            fieldProps={required && { validate: requiredValidator }}
            externallyFillable={isFilling}
            required={required}
          />
        </Grid>
        <Grid
          item
          style={{
            paddingBottom: '16px',
          }}
          xs={12}
          sm={12}
          md={12}
          lg={12}
        >
          <QCXFinalNumericDecimalFieldRender
            id="numeric-decimal-valor-unitario-field"
            name="valorUnitarioMoeda"
            label={t('com.muralis.qcx.valorUnitario')}
            disabled={isConsult || isSubConsult || isSubNone}
            scale={7}
            onBlur={() => handleValorUnitarioMoedaProdutoOnBlur(form, values)}
            validate={required && requiredValidator}
            required={
              required && (isNaN(Number(values?.valorUnitarioMoeda)) || Number(values?.valorUnitarioMoeda) <= 0)
            }
          />
        </Grid>
        <Grid
          item
          style={{
            paddingBottom: '16px',
          }}
          xs={12}
          sm={12}
          md={12}
          lg={12}
        >
          <QCXSelectFormaCalculoAutocomplete
            id="autocomplete-select-forma-calculo-field"
            key="autocomplete-select-forma-calculo-field"
            name="tipoCalculo"
            onBlur={() => handleValorUnitarioMoedaProdutoOnBlur(form, values)}
            label={t('com.muralis.qcx.formaCalculo')}
            initialValues={values}
            disabled={isConsult || isSubConsult || isSubNone}
          />
        </Grid>
        <Grid
          item
          style={{
            paddingBottom: '16px',
          }}
          xs={12}
          sm={12}
          md={12}
          lg={12}
        >
          <QCXFinalPercentualField
            id="percentual-desconto-field"
            key="percentual-desconto-field"
            name="percentualDesconto"
            label={t('com.muralis.qcx.porcentagemDesconto')}
            disabled={isConsult || isSubConsult || isSubNone}
          />
        </Grid>
        <Grid
          item
          container
          style={{
            paddingBottom: '16px',
          }}
          xs={12}
          sm={12}
          md={6}
          lg={6}
        >
          <QCXFinalAlternativeCurrencyFieldRender
            id="currency-valor-total-item-field"
            name="valorTotalMoeda"
            onBlur={handleValorTotalMoedaOnBlur(form, values)}
            label={t('com.muralis.qcx.mercadoria.valorTotalItem')}
            disabled={isConsult || isSubConsult || isSubNone}
          />
        </Grid>

        <Grid
          item
          container
          style={{
            paddingBottom: '16px',
            paddingLeft: '16px',
          }}
          xs={12}
          sm={12}
          md={6}
          lg={6}
        >
          <QCXFinalAlternativeCurrencyFieldRender
            id="currency-valor-total-reais-item-field"
            name="valorTotalReal"
            onBlur={handleValorTotalMoedaOnBlur(form, values)}
            label="Valor Total MCV Nota Fiscal"
            disabled
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <QCXSelectIncotermCondicaoVendaAutocomplete
            id="select-field-incoterm"
            key="select-field-incoterm"
            name="due.incotermCondicaoVenda.id"
            label={t('com.muralis.qcx.incoterm')}
            fieldProps={{ validate: requiredValidator }}
            initialValues={values}
            disabled={isConsult || isSubConsult || isSubNone}
            required
          />
        </Grid>
      </Grid>
      <Grid item container xs={12} sm={12} md={6} lg={6} alignContent="flex-start">
        <QCXNcmNaladiFormGroup
          modes={modes}
          isFilling={isFilling}
          renderFields={{
            ncm: true,
            naladiSh: false,
            naladiNcca: false,
          }}
        />
        <Grid
          item
          style={{
            paddingBottom: '16px',
          }}
          xs={12}
          sm={12}
          md={12}
          lg={12}
        >
          <QCXFinalNumericDecimalFieldRender
            id="numeric-decimal-peso-liquido-field"
            name="pesoLiquido"
            label={t('com.muralis.qcx.pesoLiquido')}
            disabled={isConsult || isSubConsult || isSubNone}
            scale={7}
          />
        </Grid>
        <Grid
          item
          style={{
            paddingBottom: '16px',
          }}
          xs={12}
          sm={12}
          md={12}
          lg={12}
        >
          <QCXFinalTextField
            id="text-unidade-na-medida-field"
            key="text-unidade-na-medida-field"
            name="ncm.unidadeDeMedida.description"
            label={t('com.muralis.qcx.unidadeMedida.unidadeMedidaEstatistica')}
            disabled
          />
        </Grid>
        <Grid
          item
          style={{
            paddingBottom: '16px',
          }}
          xs={12}
          sm={12}
          md={12}
          lg={12}
        >
          <QCXFinalNumericDecimalField
            id="numeric-decimal-quantidade-estatistica-field"
            key="numeric-decimal-quantidade-estatistica-field"
            name="quantidadeEstatistica"
            label={t('com.muralis.qcx.quantidade.quantidadeEstatistica')}
            disabled={isQuilograma || isQuantidade || isConsult || isSubConsult || isSubNone}
            scale={isQuantidade ? 5 : 7}
          />
        </Grid>
        {!followUpHasMetadata && (
          <Grid
            item
            style={{
              paddingBottom: '16px',
            }}
            xs={12}
            sm={12}
            md={12}
            lg={12}
          >
            <QCXSelectCFOPOperacoesFiscaisAutocomplete
              id="text-cfop-operacoes-fiscais-field"
              key="text-cfop-operacoes-fiscais-field"
              name="operacaoFiscal"
              label={t('com.muralis.qcx.CFOP.labelExtenso')}
              initialValues={values}
              disabled={isConsult || isSubConsult || isSubNone}
            />
          </Grid>
        )}
        <Grid
          item
          style={{
            paddingBottom: '16px',
          }}
          xs={12}
          sm={12}
          md={12}
          lg={12}
        >
          <QCXSelectTratamentoPrioritarioAutocomplete
            id="select-field-tratamento-prioritario"
            key="select-field-tratamento-prioritario"
            name="due.tratamentoPrioritario.id"
            label={t('com.muralis.qcx.tratamentoPrioritario')}
            initialValues={values}
            disabled={isConsult || isSubConsult || isSubNone}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <QCXFinalAlternativeCurrencyField
            id="currency-valor-total-mcv-item-field"
            key="currency-valor-total-mcv-item-field"
            name="valorTotalMcvMoeda"
            label={t('com.muralis.qcx.mercadoria.valorTotalMCVItem')}
            disabled={isConsult || isSubConsult || isSubNone}
          />
        </Grid>
        <Grid item className={classes.valorTotalReaisFieldLeft} xs={12} sm={12} md={6} lg={6}>
          <QCXFinalAlternativeCurrencyField
            id="currency-valor-total-item-reais"
            key="currency-valor-total-item-reais"
            name="valorTotalMcvReal"
            label={t('com.muralis.qcx.mercadoria.valorTotalItemMoeda')}
            disabled={isConsult || isSubConsult || isSubNone}
          />
        </Grid>
        <Grid
          item
          style={{
            paddingBottom: '30px',
          }}
          xs={12}
          sm={12}
          md={12}
          lg={12}
        >
          <QCXFinalCheckboxField
            id="checkbox-exportador-nao-e-fornecedor"
            key="checkbox-exportador-nao-e-fornecedor"
            name="due.exportadorNaoFabricante"
            label={t('com.muralis.qcx.exportador.naoFornecedor')}
            disabled={isConsult || isSubConsult || isSubNone}
          />
        </Grid>
        {exportadorNaoFabricante && (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <QCXSelectEmpresaEstrangeiraAutocomplete
              id="select-field-fornecedor"
              key="select-field-fornecedor"
              name="due.fornecedor.id"
              label={t('com.muralis.qcx.fornecedor.label')}
              initialValues={values}
              filterPredicate={fabricanteFilterPredicate}
              fieldProps={{
                validate: requiredValidator,
              }}
              disabled={isConsult || isSubConsult || isSubNone}
              required
            />
          </Grid>
        )}
      </Grid>
      {values?.descricaoNfe && (
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <QCXFinalTextField
            id="text-field-descricao-nfe"
            key="text-field-descricao-nfe"
            name="descricaoNfe"
            label={t('com.muralis.qcx.mercadoria.descricaoNfe')}
            disabled
          />
        </Grid>
      )}
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <QCXFinalMultilineTextField
          id="text-especificacao-multiline-field"
          key="text-especificacao-multiline-field"
          name="adicionais.especificacao"
          label={t('com.muralis.qcx.especificacao.especificacaoMercadoria')}
          disabled={isConsult || isSubConsult || isSubNone}
          rows={5}
          maxLength={3900}
          helperText={t('com.muralis.qcx.validacao.caracteresEspeciaisNaoPermitidos')}
          validate={required && requiredValidator}
          required={required}
        />
      </Grid>
      <QCXEnquadramentosOperacaoFormGroup
        fieldProps={enquadramentosOperacaoFieldProps}
        disabled={isConsult || isSubConsult || isSubNone}
      />
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <QCXFinalNumericIntegerField
          id="numeric-integer-prazo-solicitado"
          key="numeric-integer-prazo-solicitado"
          name="due.prazoSolicitado"
          label={t('com.muralis.qcx.prazoSolicitado')}
          allowNegative={false}
          maxLength={3}
          format={formatNotZero}
          disabled={isConsult || isSubConsult || isSubNone}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <QCXFinalTextField
          id="text-field-numero-dossie"
          key="text-field-numero-dossie"
          name="due.numeroDossie"
          label={t('com.muralis.qcx.mercadoria.numeroDossie')}
          disabled={isConsult || isSubConsult || isSubNone}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <QCXFinalPercentualField
          id="percentual-comissao-agente-field"
          key="percentual-comissao-agente-field"
          name="due.percentualComissaoAgente"
          label={t('com.muralis.qcx.comissaoAgente')}
          disabled={isConsult || isSubConsult || isSubNone}
          scale={7}
        />
      </Grid>
    </>
  );
}
