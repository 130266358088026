import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import QCXFinalDatePickerField from '../../shared-components/final-date-picker-field/QCXFinalDatePickerField';
import QCXSelectFornecedorAutocomplete from '../../shared-components/select-fornecedor-favorecido-autocomplete/QCXSelectFornecedorAutocomplete';
import QCXSelectSolicitanteAutocomplete from '../../shared-components/select-solicitante-autocomplete/QCXSelectSolicitanteAutocomplete';
import QCXSelectFollowUpAutocompleteField from '../../shared-components/select-follow-up/QCXSelectFollowUpAutocompleteField';

export default function QCXRelatoriosContasPagarForm({ initialValues }) {
  const { t } = useTranslation();

  return (
    <Grid
      container
      spacing={3}
      style={{
        margin: '10px 0',
      }}
      sm={12}
      xs={12}
      md={12}
      lg={12}
    >
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        lg={6}
      >
        <QCXFinalDatePickerField
          id="date-picker-data-vencimento-de-field"
          key="date-picker-data-vencimento-de-field"
          name="data.vencimentoDe"
          label={t('com.muralis.qcx.vencimentoDe')}
          required
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        lg={6}
      >
        <QCXFinalDatePickerField
          id="date-picker-data-vencimento-ate-field"
          key="date-picker-data-vencimento-ate-field"
          name="data.vencimentoAte"
          label={t('com.muralis.qcx.vencimentoAte')}
          required
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
      >
        <QCXSelectFollowUpAutocompleteField
          key="select-autocomplete-processo-field"
          id="select-autocomplete-processo-field"
          name="followUp.id"
          label={t('com.muralis.qcx.numeroProcesso')}
          controlled
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
      >
        <QCXSelectSolicitanteAutocomplete
          id="select-field-solicitante"
          key="select-field-solicitante"
          name="relatorios.solicitante.id"
          label={t('com.muralis.qcx.solicitante')}
          initialValues={initialValues}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
      >
        <QCXSelectFornecedorAutocomplete
          id="select-field-fornecedor"
          key="select-field-fornecedor"
          name="relatorios.fornecedor.id"
          label={t('com.muralis.qcx.fornecedor.label')}
          initialValues={initialValues}
        />
      </Grid>
    </Grid>
  );
}
