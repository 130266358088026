import React from 'react';
import { ListItemText, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.typography.body1,
    fontWeight: theme.typography.fontWeightBold,
  },
}));

export default function QCXListItemText({ isActive = false, ...restProps }) {
  const classes = useStyles();

  return isActive ? (
    <ListItemText
      primaryTypographyProps={{ classes }}
      {...restProps}
    />
  ) : (
    <ListItemText
      {...restProps}
    />
  );
}
