import { createFilterOptions } from '@material-ui/lab/useAutocomplete';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import followUpOrigemThunks from '../../features/follow-up-origem-faturamento/followUpOrigemFaturamentoThunks';
import followUpOrigemSelectors from '../../features/follow-up-origem-faturamento/followUpOrigemFaturamentoSelectors';
import QCXAutocompleteSelect from '../autocomplete-select/QCXAutocompleteSelect';

export default function QCXSelectFollowUpOrigemAutocompleteField({
  label,
  name,
  initialValues,
  controlled = false,
  ...restProps
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const list = useSelector(followUpOrigemSelectors.selectFollowUps);

  const [defaultValue, setDefaultValue] = useState('');
  const [internalLoading, setInternalLoading] = useState(true);

  useEffect(() => {
    if (!_.isEmpty(list)) {
      (_.debounce(() => (
        setInternalLoading(false)
      ), 2000))();
    }
  }, [list]);

  useEffect(() => {
    if (!controlled) {
      dispatch(followUpOrigemThunks.fetchAllAsync());
    }
  }, [controlled]);

  useEffect(() => {
    const value = list.find((item) => (
      item?.id === _.get(initialValues, name)
    ));

    if (value) {
      setDefaultValue(value);
    } else {
      setDefaultValue({ id: null, description: '' });
    }
  }, [list, initialValues]);

  const filterOptions = createFilterOptions({
    stringify: (option) => (
      option?.followUp?.numero
    ),
  });

  return (
    <QCXAutocompleteSelect
      label={label}
      id={name}
      name={name}
      options={list}
      filterOptions={filterOptions}
      defaultValue={defaultValue}
      getOptionLabel={(item) => (
        item?.followUp?.numero || ''
      )}
      {...restProps}
      placeholder={(
        internalLoading
          ? t('com.muralis.qcx.carregando')
          : t('com.muralis.qcx.selecione')
      )}
      loading={internalLoading}
      loadingText={t('com.muralis.qcx.carregando')}
      disabled={internalLoading}
    />
  );
}
