import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh',
  },
  container: {
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    overflow: 'hidden',
    width: '100%',
  },
}));

export default function QCXLayout({ children, ...restProps }) {
  const classes = useStyles();

  return (
    <div
      className={classes.root}
      {...restProps}
    >
      {children}
    </div>
  );
}
