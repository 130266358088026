import { IconButton } from '@material-ui/core';
import { Search as PageviewIcon } from '@material-ui/icons';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import MoedaContext from '../../../../../contexts/registrations/moeda/MoedaContext';
import {
  fetchAllAsync,
  resetBackgroundMode,
  resetMode,
  resetModel,
  selectMoedas,
} from '../../../../../features/moeda/moedaSlice';
import {
  ALTERNATIVE_LOADING_STATUS,
} from '../../../../../features/status';
import QCXSimpleConsultPageTemplate from '../../../../../templates/simple-consult-page/QCXSimpleConsultPageTemplate';

export default function MoedaConsultPage({ authInfo }) {
  const { t } = useTranslation();

  const history = useHistory();

  const {
    status,
  } = useContext(MoedaContext);

  const dispatch = useDispatch();

  const unidadesDeMedida = useSelector(selectMoedas);

  const isAlternativeLoading = useMemo(() => status === ALTERNATIVE_LOADING_STATUS, [status]);

  const fetchAll = async () => {
    dispatch(fetchAllAsync());
  };

  const resetModes = useCallback(() => {
    dispatch(resetMode());
    dispatch(resetBackgroundMode());
  }, []);

  const resetModels = useCallback(() => {
    dispatch(resetModel());
  }, []);

  useEffect(() => {
    fetchAll();
    resetModes();
    resetModels();
  }, []);

  const handleClickOnEditButton = useCallback(
    (_event, id) => (
      history.push(
        t('com.muralis.qcx.url.modulosFinanceirosMoedaDetalhes', { id })
      )
    ), [history]
  );

  const breadcrumbs = [
    {
      link: {
        to: '/',
        name: t('com.muralis.qcx.inicio'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.cadastros'),
      },
    },
    {
      link: {
        to: t('com.muralis.qcx.url.moduloCadastrosFinanceiros'),
        name: t('com.muralis.qcx.financeiro.labelPlural'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.moeda.labelPlural'),
      },
    },
  ];

  const columns = [
    {
      field: 'code',
      headerName: t('com.muralis.qcx.codigo'),
      headerAlign: 'center',
      align: 'center',
      type: 'string',
      flex: 90,
      valueGetter: ({ row }) => (
        row?.code
      ),
    },
    {
      field: 'description',
      headerName: t('com.muralis.qcx.descricao'),
      type: 'string',
      flex: 175,
      valueGetter: ({ row }) => (
        row?.description
      ),
    },
    {
      field: 'sigla',
      headerName: t('com.muralis.qcx.sigla'),
      headerAlign: 'center',
      align: 'center',
      type: 'string',
      flex: 90,
      valueGetter: ({ row }) => (
        row?.sigla || '-'
      ),
    },
    {
      field: 'actions',
      headerName: t('com.muralis.qcx.acoes.label'),
      headerAlign: 'center',
      align: 'center',
      flex: 100,
      renderCell: ({ row: { id } }) => (
        //   <IconButton
        //    key={`stw-active${id}`}
        //    value={active}
        //    name={`stw-active-${id}`}
        //    onClick={(event) => handleActivateInactivate(event, id)}
        //    >
        //      <DeleteIcon color="error" />
        //   </IconButton>
        <IconButton
          key={`btn-view-${id}`}
          name={`btn-view-${id}`}
          onClick={(event) => handleClickOnEditButton(event, id)}
        >
          <PageviewIcon color="primary" />
        </IconButton>
      ),
    }
  ];

  const templateProps = {
    page: {
      title: t('com.muralis.qcx.moeda.labelPlural'),
      breadcrumbs,
    },
    control: {
      buttonNew: {
        description: t('com.muralis.qcx.moeda.novaMoeda'),
        path: t('com.muralis.qcx.url.modulosFinanceirosMoedaNova'),
      },
    },
    table: {
      columns,
    },
  };

  const filterPropGetter = useCallback(
    (item) => item.description,
    []
  );

  return (
    <QCXSimpleConsultPageTemplate
      pageProps={templateProps.page}
      controlProps={templateProps.control}
      tableProps={templateProps.table}
      tableData={unidadesDeMedida}
      isLoading={isAlternativeLoading}
      filterPropGetter={filterPropGetter}
      buttonNewText={t('com.muralis.qcx.acoes.nova').toUpperCase()}
      authInfo={authInfo}
      requiredRoles={['moeda']}

    />
  );
}
