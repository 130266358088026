import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';

/**
 * The function converts a UTC date string to the user's local date and time.
 * @param {string} utcDate - The `utcDate` parameter is a string representing a date and time in UTC
 * format.
 * @returns the local date and time converted from the provided UTC date string. If there is an error
 * during the conversion process, it will return the original UTC date string.
 */
export const convertUTCStringToLocalDateTime = (utcDate: string) => {
  if (typeof utcDate !== 'string' || !utcDate) return utcDate;

  // Ensure the date string is treated as UTC
  let utcDateTime = utcDate;
  if (!utcDateTime.endsWith('Z') && !utcDateTime.endsWith('+00:00')) {
    utcDateTime += 'Z';
  }

  // Detect user's timezone. Falls back to UTC if unable to detect
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone || 'UTC';

  try {
    // Convert UTC+0 timestamp to user's local timezone
    const localDate = utcToZonedTime(utcDateTime, timezone);
    return localDate;
  } catch (error) {
    return utcDate;
  }
};

/**
 * The function converts a local date and time string to a UTC format string.
 * @param {string} localDate - The `localDate` parameter is a string representing a local date and
 * time.
 * @returns the converted local date and time in UTC format as an ISO string.
 */
export const convertLocalDateTimeStringToUTC = (localDate: string) => {
  if (typeof localDate !== 'string' || !localDate) return localDate;
  // Detect user's timezone. Falls back to UTC if unable to detect
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone || 'UTC';

  try {
    // Convert local date and time to UTC format
    const utcDate = zonedTimeToUtc(localDate, timezone, { timeZone: 'UTC' });
    const utcIsoString = utcDate.toISOString();
    return utcIsoString;
  } catch (error) {
    return localDate;
  }
};

/**
 * The `convertDateExcel` function converts an Excel timestamp to a string in the format "YYYY-MM-DD".
 * @param {number} excelTimestamp - The `excelTimestamp` parameter is a number representing the date in
 * Excel timestamp format. In Excel, dates are stored as the number of days since December 31, 1899,
 * with fractional parts representing the time of day. For example, the value 1 represents January 1,
 * 190
 * @returns a string in the format "YYYY-MM-DD" representing the date converted from the Excel
 * timestamp. If the parsed timestamp is NaN, the function returns null.
 */
export const convertExcelTimestampToDateStr = (excelTimestamp?: number): string | null => {
  if (!excelTimestamp) return null;

  const secondsInDay = 24 * 60 * 60;
  const excelEpoch = new Date(1899, 11, 31);
  const excelEpochAsUnixTimestamp = excelEpoch.getTime();
  const missingLeapYearDay = secondsInDay * 1000;
  const delta = excelEpochAsUnixTimestamp - missingLeapYearDay;
  const excelTimestampAsUnixTimestamp = excelTimestamp * secondsInDay * 1000;
  const parsedTimestamp = excelTimestampAsUnixTimestamp + delta;

  // Check if parsed value is NaN
  if (Number.isNaN(parsedTimestamp)) return null;

  // Convert the Unix timestamp to a Date object
  const parsedDate = new Date(parsedTimestamp);

  // Convert the Date object to a string in the YYYY-MM-DD format
  const yyyy = parsedDate.getFullYear();
  const mm = String(parsedDate.getMonth() + 1).padStart(2, '0');
  const dd = String(parsedDate.getDate()).padStart(2, '0');

  return `${yyyy}-${mm}-${dd}`;
};
