import { useTheme } from '@material-ui/core';
import { CircularProgress, Grid, Typography } from '@mui/material';

const LoadingIndicator = ({ message }: { message?: string }) => {
  const theme = useTheme();
  return (
    <Grid
      container
      xs={12}
      justifyContent={'center'}
      textAlign={'center'}
      spacing={2}
    >
      <Grid item xs={12}>
        <CircularProgress color="primary" />
      </Grid>
      {message && (
        <Grid item xs={12}>
          <Typography
            color={theme.palette.primary.main}
            textTransform={'uppercase'}
          >
            {message}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default LoadingIndicator;
