import React from 'react';
import QCXFormControlLabel from '../form-control-label/QCXFormControlLabel';
import QCXRadio from '../radio/QCXRadio';

export default function QCXRadioLabel({
  label,
  disabled,
  ...restProps
}) {
  return (
    <QCXFormControlLabel
      style={{
        textTransform: 'uppercase',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      }}
      control={<QCXRadio disabled={disabled} {...restProps} />}
      label={label}
      disabled={disabled}
    />
  );
}
