const IDLE_STATUS = 'idle';
const LOADING_STATUS = 'loading';
const ALTERNATIVE_LOADING_STATUS = 'alternativaLoading';
const PREPARING_ACTION_STATUS = 'preparingAction';
const PERFORMING_ACTION_STATUS = 'performingAction';
const SUCCESS_STATUS = 'success';
const FAILURE_STATUS = 'failure';

export {
  IDLE_STATUS,
  ALTERNATIVE_LOADING_STATUS,
  PREPARING_ACTION_STATUS,
  PERFORMING_ACTION_STATUS,
  LOADING_STATUS,
  SUCCESS_STATUS,
  FAILURE_STATUS,
};
