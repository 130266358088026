import { createAsyncThunk } from '@reduxjs/toolkit';
import { followUpAPI } from '../follow-up/followUpAPI';

const fetchAllAsync = createAsyncThunk(
  'followUpDestinoFaturamento/fetchAll',
  async (id) => {
    const { data } = await followUpAPI.fetchAllByImportadorFaturamentoId(id);

    return { data };
  }
);

const followUpDestinoFaturamentoThunks = {
  fetchAllAsync,
};

export default followUpDestinoFaturamentoThunks;
