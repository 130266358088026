import { useKeycloak } from "@react-keycloak/web";
import { useCallback } from "react";
import { CustomResources, TokenWithAttributes } from "./useHasPermission";

export const useVerifyPermission = (): [(permission: string) => boolean] => {
  const { keycloak } = useKeycloak();

  const verifyPermission = useCallback(
    (permission: string) => {
      if (!keycloak.tokenParsed) {
        return false;
      }

      const parsedToken = keycloak.tokenParsed as TokenWithAttributes;

      if (!parsedToken.attributes) {
        return false;
      }

      if (parsedToken.attributes.modulosAcesso.includes(permission)) {
        return true;
      }

      if (!parsedToken.resource_access) {
        return false;
      }

      const resources = parsedToken.resource_access as CustomResources;

      if (resources.api.roles.includes(permission)) {
        return true;
      }

      return false;
    },
    [keycloak]
  );

  return [verifyPermission];
};
