import {
  doGetQCXRequest,
} from '../../utils/api/domain/domain-api-utils';
import { ORGAO_ANUENTE_ENDPOINT } from '../../utils/api/domain/domain-endpoint-utils';

export async function fetchAll() {
  return doGetQCXRequest([
    ORGAO_ANUENTE_ENDPOINT
  ]);
}

export async function fetchById(id) {
  return doGetQCXRequest([
    ORGAO_ANUENTE_ENDPOINT,
    id
  ]);
}
