import { Grid } from '@material-ui/core';
import React from 'react';
import { useForm, useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import QCXFinalCheckboxField from '../final-checkbox-field/QCXFinalCheckboxField';
import QCXFinalAlternativeCurrencyField from '../final-currency-field/QCXFinalAlternativeCurrencyField';
import QCXFinalPercentualField from '../final-percentual-field/QCXFinalPercentualField';
import QCXSelectMoedaAutocomplete from '../final-select-moeda-field/QCXSelectMoedaAutocomplete';

export default function QCXMoedaSeguroFormGroup({
  isConsult,
  handleValorNaMoedaOnBlur,
  handleValorNaMoedaSeguro,
  valorValidators,
  intl,
}) {
  const { t } = useTranslation();
  const form = useForm();
  const { values } = useFormState();
  const tipo = values?.atributosAdicionais?.tipo;

  return (
    <>
      <Grid item xs={12} sm={12} md={4} lg={4}>
        <QCXFinalCheckboxField
          id="checkbox-seguro-embutir-item-field"
          key="checkbox-seguro-embutir-item-field"
          name="seguro.embutirItem"
          label={t('com.muralis.qcx.seguro.embutirSeguroItens')}
          disabled={isConsult}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4}>
        <QCXFinalCheckboxField
          id="checkbox-seguro-calcula-sobre-mle-frete-field"
          key="checkbox-seguro-calcula-sobre-mle-frete-field"
          name="seguro.calculaSobreMleFrete"
          label={t('com.muralis.qcx.seguro.calcularSeguroMLEFrete')}
          disabled={isConsult}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4}>
        <QCXFinalPercentualField
          id="valor-percentual-field"
          key="valor-percentual-field"
          name="seguro.valorPercentual"
          onBlur={handleValorNaMoedaSeguro(form, values)}
          label={t('com.muralis.qcx.seguro.percentualSeguroInternacional')}
          scale={7}
          disabled={isConsult}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4}>
        {tipo !== 'DUE' && (
        <QCXSelectMoedaAutocomplete
          id="select-field-moeda-seguro"
          key="select-field-moeda-seguro"
          name="seguro.moeda.id"
          label={t('com.muralis.qcx.seguro.moedaSeguroInternacional')}
          disabled={isConsult}
          initialValues={values}
        />
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4}>
        <QCXFinalAlternativeCurrencyField
          id="currency-valor-moeda-seguro-field"
          key="currency-valor-moeda-seguro-field"
          name="seguro.valorMoeda"
          label={t('com.muralis.qcx.seguro.valorSeguroInternacionalMoeda')}
          onBlur={handleValorNaMoedaOnBlur(form, values, 'seguro.moeda', 'seguro.valorMoeda', 'seguro.valorReal')}
          disabled={isConsult || !values?.seguro?.moeda?.id}
          required={!!values?.seguro?.moeda?.id}
          validate={valorValidators}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4}>
        <QCXFinalAlternativeCurrencyField
          id="currency-valor-real-moeda-seguro-field"
          key="currency-valor-real-moeda-seguro-field"
          name="seguro.valorReal"
          label={t('com.muralis.qcx.seguro.valorSeguroInternacionalReais')}
          intl={intl}
          adorned
          disabled
          helperText={!(isConsult) && t('com.muralis.qcx.mensagem.arredondamentoValor')}
        />
      </Grid>
    </>
  );
}
