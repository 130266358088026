import { debounce, isEmpty, isFunction } from 'lodash';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { validate } from 'uuid';
import {
  changeToLastSubBackgroundMode,
  changeToSubBackgroundConsultMode,
  changeToSubBackgroundDeleteMode,
  changeToSubConsultMode,
  changeToSubCreateMode,
  changeToSubUpdateMode,
  loading,
  resetRelatedMercadoriaModel,
  resetStatus,
  resetSubBackgroundMode,
  resetSubMode,
  setRelatedMercadoriaModel,
} from '../../features/licenca-importacao/licencaImportacaoSlice';
import {
  selectRelatedMercadoriaModel,
  selectSubMode,
  selectType,
} from '../../features/licenca-importacao/licencaImportacaoSelectors';
import QCXFinalFormWizard from '../../shared-components/final-form-wizard/QCXFinalFormWizard';
import { isNoneSubMode, isSubConsultMode, isSubCreateMode, isSubUpdateMode } from '../../utils/store/store-utils';
import QCXLicencaImportacaoImportadorForm from './QCXLicencaImportacaoImportadorForm';
import QCXLicencaImportacaoBasicasForm from './QCXLicencaImportacaoBasicasForm';
import QCXFaturaTotaisForm from '../fatura/QCXFaturaTotaisForm';
import QCXFaturaFornecedorForm from '../fatura/QCXFaturaFornecedorForm';
import QCXFaturaMercadoriaForm from '../fatura/QCXFaturaMercadoriaForm';
import { TIPO_LI } from '../../utils/general/fatura/faturaUtils';
import useQueryParam from '../../utils/hooks/router/useQueryParam';
import { parseToNumber } from '../../utils/general/parse-utils';
import { isGeneratedLiMetaType } from '../../features/licenca-importacao/licencaImportacaoUtils';
import { SUB_CONSULT_MODE, SUB_UPDATE_MODE } from '../../features/mode';
import { isCalculadaStatus } from '../../utils/general/operations/operationUtils';
import useOperationMiddleware from '../../utils/hooks/operation/middleware/useOperationMiddleware';
import QCXConfirmDialog from '../../shared-components/dialog/QCXConfirmDialog';
import { useMercadoriaUtils } from '../fatura/mercadoriaUtils';
import { isValid } from '../../utils/general/general-utils';

export default function QCXLicencaImportacaoWizardFinalForm({
  isIdle,
  isLoading,
  isFailure,
  isCreate,
  isConsult,
  isUpdate,
  isLocked,
  isBackgroundCreate,
  isBackgroundDelete,
  isSubBackgroundCreate,
  isSubBackgroundConsult,
  isSubBackgroundUpdate,
  isSubBackgroundDelete,
  isPreparingAction,
  handleSubmit,
  handleAlternativeSave,
  handleMercadoria,
  handleChangeToPreparingAction,
  model,
  handleChangeModel,
  refreshSelectedModel,
  handleChangeToCreate,
  handleChangeToConsult,
  handleChangeToUpdate,
  handleCancelUpdate,
  handleChangeToBackgroundCreate,
  handleChangeToBackgroundDelete,
  handleResetBackgroundMode,
  handleMetadataUpdate,
  children,
  authInfo = {},
  requiredRoles = [],
  ...restProps
}) {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const stepQueryParam = useQueryParam('step');
  const itemQueryParam = useQueryParam('item');
  const tabQueryParam = useQueryParam('tab');

  const subMode = useSelector(selectSubMode);
  const type = useSelector(selectType);
  const mercadoria = useSelector(selectRelatedMercadoriaModel);

  const mercadoriaUtils = useMercadoriaUtils();

  const stepNumberQueryParam = useMemo(
    () => (isValid(stepQueryParam) ? parseToNumber(stepQueryParam) - 1 : undefined),
    [stepQueryParam]
  );

  const itemNumberQueryParam = useMemo(() => parseToNumber(itemQueryParam) || undefined, [itemQueryParam]);

  const tabNumberQueryParam = useMemo(() => parseToNumber(tabQueryParam) || undefined, [tabQueryParam]);

  const checkIfIsAlternativeSaveByStep = (currentStep) => currentStep === 4;

  const isSubNone = useMemo(() => isNoneSubMode(subMode), [subMode]);

  const isSubCreate = useMemo(() => isSubCreateMode(subMode), [subMode]);

  const isSubConsult = useMemo(() => isSubConsultMode(subMode), [subMode]);

  const isSubUpdate = useMemo(() => isSubUpdateMode(subMode), [subMode]);

  const isGeneratedLi = useMemo(() => isGeneratedLiMetaType(type), [type]);

  const handleLoadingStatus = useCallback(() => dispatch(loading()), []);

  const handleResetStatus = useCallback(() => dispatch(resetStatus()), []);

  const handleChangeToSubCreate = useCallback(() => {
    dispatch(changeToSubCreateMode());
  }, []);

  const handleChangeToSubConsult = useCallback(() => {
    dispatch(changeToSubConsultMode());
  }, []);

  const handleChangeToSubUpdate = useCallback(() => {
    dispatch(changeToSubUpdateMode());
  }, []);

  const handleChangeToSubNone = useCallback(() => {
    if (isSubBackgroundCreate) {
      handleChangeToSubCreate();
      dispatch(resetSubBackgroundMode());

      return;
    }

    if (isSubBackgroundConsult) {
      handleChangeToSubConsult();
      dispatch(resetSubBackgroundMode());

      return;
    }
    if (isSubBackgroundUpdate) {
      handleChangeToSubUpdate();
      dispatch(changeToSubBackgroundConsultMode());

      return;
    }

    dispatch(resetSubMode());
  }, [
    isSubBackgroundCreate,
    isSubBackgroundConsult,
    isSubBackgroundUpdate,
    handleChangeToSubCreate,
    handleChangeToSubConsult,
    handleChangeToSubUpdate,
  ]);

  const isSavedAllMercadorias = useMemo(() => model?.mercadorias?.every((item) => !validate(item?.id)), [model]);

  const handleNewMercadoria = useCallback(
    (additional = {}) => {
      const additionalCallback = () => {
        if (!isEmpty(additional) && isFunction(additional?.init)) {
          additional.init();
        }

        handleChangeToSubCreate();
      };

      if (isFunction(handleMetadataUpdate)) {
        handleMetadataUpdate('tab', 1);

        handleMetadataUpdate('item', 1);
      }

      if (!isCreate) {
        handleChangeToUpdate();
      }

      additionalCallback.call();
    },
    [isCreate, handleMetadataUpdate, handleChangeToUpdate, handleChangeToSubCreate]
  );

  const handleCancelMercadoriaCreate = useCallback(
    (additional = {}) => {
      const additionalCallback = () => {
        if (!isEmpty(additional) && isFunction(additional?.callback)) {
          additional.callback();
        }

        if (isSubBackgroundCreate || isSubBackgroundConsult) {
          dispatch(resetSubBackgroundMode());
        }
      };

      if (!isCreate) {
        handleCancelUpdate();

        additionalCallback.call();
        return;
      }

      additionalCallback.call();

      if (isSubBackgroundConsult) {
        dispatch(resetSubBackgroundMode());
      }
    },
    [isCreate, isSubBackgroundCreate, isSubBackgroundConsult, handleCancelUpdate]
  );

  const handleCancelMercadoriaUpdate = useCallback(() => {
    const handleSubConsultAndSubBackground = () => {
      handleChangeToSubConsult();

      if (isSubBackgroundConsult || isSubBackgroundUpdate) {
        dispatch(resetSubBackgroundMode());
      }
    };

    if (isUpdate && isSavedAllMercadorias) {
      handleCancelUpdate({
        callback: handleSubConsultAndSubBackground,
      });

      return;
    }

    handleSubConsultAndSubBackground();
  }, [
    isUpdate,
    isSubBackgroundConsult,
    isSubBackgroundUpdate,
    isSavedAllMercadorias,
    handleCancelUpdate,
    handleChangeToSubConsult,
  ]);

  const handleClearMercadoriaForm = useCallback(() => {
    dispatch(resetRelatedMercadoriaModel());
  }, []);

  const [handleRemoveMercadoriaByIdMiddleware, operationOfRemoveMercadoriaById] = useOperationMiddleware(
    async (data, refreshCallback) => {
      handleMercadoria(data, refreshCallback);
    },
    [handleMercadoria]
  );

  const handleRemoveMercadoriaByForm = useCallback(
    (refreshCallback) => {
      dispatch(changeToSubBackgroundDeleteMode());

      const configureOperation = () => ({
        options: {
          title: t('com.muralis.qcx.acoes.confirmarOperacaoRemocao').toUpperCase(),
          message: t('com.muralis.qcx.acoes.operacaoRemocaoItemLista', { item: mercadoria?.item }),
          renderMiddleMessage: () => (
            <Trans i18nKey="com.muralis.qcx.alerta.atencaoItemTransferenciaEstorno">
              <b>ATENÇÃO: </b>
              <i>Caso o item seja proveniente de uma transferência o mesmo será estornado automaticamente.</i>
            </Trans>
          ),
          endMessage: t('com.muralis.qcx.alerta.remocao'),
        },
        cleanUp: () => {
          dispatch(changeToLastSubBackgroundMode());
        },
      });

      handleRemoveMercadoriaByIdMiddleware(configureOperation, mercadoria, refreshCallback);
    },
    [mercadoria, handleRemoveMercadoriaByIdMiddleware]
  );

  const handleRemoveMercadoriaByTable = useCallback(
    ({ row }) => {
      handleChangeToBackgroundDelete();

      const configureOperation = () => ({
        options: {
          title: t('com.muralis.qcx.acoes.confirmarOperacaoRemocao').toUpperCase(),
          message: t('com.muralis.qcx.acoes.operacaoRemocaoItemLista', { item: row?.item }),
          renderMiddleMessage: () => (
            <Trans i18nKey="com.muralis.qcx.alerta.atencaoItemTransferenciaEstorno">
              <b>ATENÇÃO: </b>
              <i>Caso o item seja proveniente de uma transferência o mesmo será estornado automaticamente.</i>
            </Trans>
          ),
          endMessage: t('com.muralis.qcx.alerta.remocao'),
        },
        cleanUp: () => {
          dispatch(changeToLastSubBackgroundMode());
        },
      });

      handleRemoveMercadoriaByIdMiddleware(configureOperation, row);
    },
    [handleChangeToBackgroundDelete, handleRemoveMercadoriaByIdMiddleware]
  );

  const handleDispatchSetMercadoriaModel = useCallback((updatedModel) => {
    dispatch(setRelatedMercadoriaModel(updatedModel));
  }, []);

  const handleEditMercadoria = useCallback(() => {
    if (!isCreate) {
      handleChangeToUpdate({
        callback: handleChangeToSubUpdate,
      });

      return;
    }

    handleChangeToSubUpdate();
  }, [isCreate, handleChangeToUpdate, handleChangeToSubUpdate]);

  const tabManagerStepMercadoriaProps = useMemo(
    () => ({
      defaultActiveTab: tabNumberQueryParam,
    }),
    [tabNumberQueryParam]
  );

  const formPaginationControlProps = useMemo(
    () => ({
      autoLoadItem:
        isCalculadaStatus(model?.calculada) && (isSubNone || isSubConsult || isSubUpdate || isSubBackgroundCreate),
    }),
    [model, isSubNone, isSubConsult, isSubBackgroundCreate]
  );

  useEffect(() => {
    const handleQueryParamsMetadataUpdate = () => {
      if (isFunction(handleMetadataUpdate)) {
        if (isValid(tabNumberQueryParam)) {
          handleMetadataUpdate('tab', tabNumberQueryParam);
        }

        if (isValid(itemNumberQueryParam)) {
          handleMetadataUpdate('item', itemNumberQueryParam);
          return;
        }

        if (isValid(mercadoria?.item)) {
          handleMetadataUpdate('item', mercadoria?.item);
        }
      }
    };

    handleQueryParamsMetadataUpdate();
  }, [mercadoria, tabNumberQueryParam, itemNumberQueryParam, handleMetadataUpdate]);

  const normalize = useCallback((unnormalizedData) => mercadoriaUtils.normalize(unnormalizedData), [mercadoriaUtils]);

  const unnormalize = useCallback((normalizedData) => mercadoriaUtils.unnormalize(normalizedData), [mercadoriaUtils]);

  const handleMercadoriaSubmit = useCallback(
    async (data, form) => {
      const normalizedMercadoria = normalize(data);

      if (isSubCreate) {
        const restartForm = () => {
          handleLoadingStatus();

          const executeDebouncedContinueCreating = debounce(() => {
            if (!isEmpty(form) && isFunction(form.restart)) {
              form.restart({
                partnumberAutocompleteSelector: '',
                partNumberSelector: '',
                partnumber: '',
                valorUnitarioMoeda: '',
                pesoLiquido: '',
                pesoLiquidoUnitario: '',
                tipoCalculo: 'QUANTIDADE_X_VALOR',
                icms: '',
                quantidade: '',
                valorTotalMoeda: '',
                quantidadeEstatistica: '',
                percentualDesconto: '',
              });
            }

            handleClearMercadoriaForm();
            handleNewMercadoria();

            handleResetStatus();
          }, 500);

          executeDebouncedContinueCreating();
        };

        await handleMercadoria(normalizedMercadoria, restartForm);
        return;
      }

      handleMercadoria(normalizedMercadoria);
    },
    [
      isSubCreate,
      normalize,
      handleLoadingStatus,
      handleClearMercadoriaForm,
      handleNewMercadoria,
      handleResetStatus,
      handleMercadoria,
    ]
  );

  const mercadoriaModel = useMemo(
    () => (!isSubCreate ? unnormalize(mercadoria) : {}),
    [isSubCreate, mercadoria, unnormalize]
  );

  const wizardSteps = useMemo(
    () => [
      t('com.muralis.qcx.importador.label'),
      t('com.muralis.qcx.basicas'),
      t('com.muralis.qcx.totais'),
      t('com.muralis.qcx.fornecedor.label'),
      t('com.muralis.qcx.mercadoria.label'),
    ],
    []
  );

  const optionalSteps = useMemo(() => [t('com.muralis.qcx.fornecedor.label')], []);

  const ignoreRulesForSpecialSteps = useMemo(() => [t('com.muralis.qcx.mercadoria.label')], []);

  return (
    <>
      <QCXFinalFormWizard
        steps={wizardSteps}
        optionalSteps={optionalSteps}
        defaultStep={stepNumberQueryParam}
        ignoreRulesForSpecialSteps={ignoreRulesForSpecialSteps}
        initialValues={model}
        handleChangeValues={handleChangeModel}
        isCreate={isCreate}
        isConsult={isConsult}
        isUpdate={isUpdate}
        isBackgroundCreate={isBackgroundCreate}
        isSubNone={isSubNone}
        isSubCreate={isSubCreate}
        isSubConsult={isSubConsult}
        isSubUpdate={isSubUpdate}
        handleSubmit={handleSubmit}
        checkIfIsAlternativeSaveByStep={checkIfIsAlternativeSaveByStep}
        handleAlternativeSave={handleAlternativeSave}
        handleChangeToCreate={handleChangeToCreate}
        handleChangeToBackgroundCreate={handleChangeToBackgroundCreate}
        handleChangeToConsult={handleChangeToConsult}
        handleChangeToUpdate={handleChangeToUpdate}
        handleCancelUpdate={handleCancelUpdate}
        handleResetBackgroundMode={handleResetBackgroundMode}
        handleChangeToSubNone={handleChangeToSubNone}
        handleMetadataUpdate={handleMetadataUpdate}
        {...restProps}
      >
        {(formProps) => (
          <QCXLicencaImportacaoImportadorForm
            key="ficha-importador-licenca-importacao"
            isConsult={isConsult}
            isLocked={isLocked}
            authInfo={authInfo}
            requiredRoles={requiredRoles}
            {...formProps}
          />
        )}
        {(formProps) => (
          <QCXLicencaImportacaoBasicasForm
            key="ficha-dados-basicos-licenca-importacao"
            isConsult={isConsult}
            isLocked={isLocked}
            authInfo={authInfo}
            requiredRoles={requiredRoles}
            {...formProps}
          />
        )}
        {(formProps) => (
          <QCXFaturaTotaisForm
            key="ficha-totais-licenca-importacao"
            isConsult={isConsult}
            authInfo={authInfo}
            requiredRoles={requiredRoles}
            {...formProps}
          />
        )}
        {(formProps) => (
          <QCXFaturaFornecedorForm
            key="ficha-fornecedor-licenca-importacao"
            isConsult={isConsult}
            authInfo={authInfo}
            requiredRoles={requiredRoles}
            {...formProps}
          />
        )}
        {({ initialValues, controlComponentProps }) => (
          <QCXFaturaMercadoriaForm
            key="ficha-mercadoria-licenca-importacao"
            isIdle={isIdle}
            isLoading={isLoading}
            isFailure={isFailure}
            isPreparingAction={isPreparingAction}
            isCreate={isCreate}
            isConsult={isConsult}
            isUpdate={isUpdate}
            isSubNone={isSubNone}
            isSubCreate={isSubCreate}
            isSubConsult={isSubConsult}
            isSubUpdate={isSubUpdate}
            isBackgroundDelete={isBackgroundDelete}
            isSubBackgroundCreate={isSubBackgroundCreate}
            isSubBackgroundUpdate={isSubBackgroundUpdate}
            isSubBackgroundDelete={isSubBackgroundDelete}
            tipo={TIPO_LI}
            faturaValues={initialValues}
            initialValues={mercadoriaModel}
            fatura={model}
            defaultItem={itemNumberQueryParam}
            handleSubmit={handleMercadoriaSubmit}
            handleChangeModel={handleDispatchSetMercadoriaModel}
            handleNew={handleNewMercadoria}
            handleEdit={handleEditMercadoria}
            handleChangeToSubConsult={handleChangeToSubConsult}
            handleChangeToConsult={handleChangeToConsult}
            handleCancelCreate={handleCancelMercadoriaCreate}
            handleCancelUpdate={handleCancelMercadoriaUpdate}
            handleRemoveByForm={handleRemoveMercadoriaByForm}
            handleRemoveByTable={handleRemoveMercadoriaByTable}
            handleChangeToSubNone={handleChangeToSubNone}
            handleClearForm={handleClearMercadoriaForm}
            handleChangeToUpdate={handleChangeToUpdate}
            handleMetadataUpdate={handleMetadataUpdate}
            handleLoadingStatus={handleLoadingStatus}
            handleResetStatus={handleResetStatus}
            tabManagerProps={tabManagerStepMercadoriaProps}
            formPaginationControlProps={formPaginationControlProps}
            buttonGroupOptions={{
              remove: {
                visibleOn: [isGeneratedLi || isSubBackgroundUpdate ? SUB_UPDATE_MODE : SUB_CONSULT_MODE],
              },
            }}
            controlComponentProps={controlComponentProps}
            authInfo={authInfo}
            requiredRoles={requiredRoles}
          />
        )}
      </QCXFinalFormWizard>
      {children}
      <QCXConfirmDialog
        key="confirm-dialog-remove-mercadoria-li"
        id="confirm-dialog-remove-mercadoria-li"
        open={operationOfRemoveMercadoriaById?.active}
        title={operationOfRemoveMercadoriaById?.title}
        content={operationOfRemoveMercadoriaById?.message}
        renderMiddleMessage={operationOfRemoveMercadoriaById?.renderMiddleMessage}
        endContent={operationOfRemoveMercadoriaById?.endMessage}
        onConfirm={operationOfRemoveMercadoriaById.confirm}
        onClose={operationOfRemoveMercadoriaById?.reset}
      />
    </>
  );
}
