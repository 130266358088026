import { IconButton } from '@material-ui/core';
import {
  Delete as DeleteIcon,
  Search as PageviewIcon,
} from '@material-ui/icons';
import React, {
  useMemo,
  useEffect,
  useContext,
  useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { userHasDeleteRole } from '../../../../../components/perfil/perfil-utils';
import DispositivoLegalContext from '../../../../../contexts/registrations/dispositivo-legal/DispositivoLegalContext';
import {
  resetMode,
  resetModel,
  resetSubMode,
  fetchAllAsync,
  activateByIdAsync,
  changeToCreateMode,
  changeToConsultMode,
  inactivateByIdAsync,
  resetBackgroundMode,
  selectDispositivosLegais,
} from '../../../../../features/dispositivo-legal/dispositivoLegalSlice';
import QCXSimpleConsultPageTemplate from '../../../../../templates/simple-consult-page/QCXSimpleConsultPageTemplate';
import useOperationController from '../../../../../utils/hooks/store/operation/useOperationController';
import { isAlternativeLoadingStatus } from '../../../../../utils/store/store-utils';

export default function DispositivoLegalConsultPage({ authInfo = {} }) {
  const { t } = useTranslation();
  const history = useHistory();

  const {
    status,
  } = useContext(DispositivoLegalContext);

  const dispatch = useDispatch();

  const dispositivosLegais = useSelector(selectDispositivosLegais);

  const isAlternativeLoading = useMemo(() => (
    isAlternativeLoadingStatus(status)
  ), [status]);

  const fetchAll = useCallback(() => {
    dispatch(fetchAllAsync());
  }, []);

  const resetModes = useCallback(() => {
    dispatch(resetMode());
    dispatch(resetBackgroundMode());
    dispatch(resetSubMode());
  }, []);

  const resetModels = useCallback(() => {
    dispatch(resetModel());
  }, []);

  const resetDependencies = useCallback(() => {
  }, []);

  useEffect(() => {
    fetchAll();
    resetModes();
    resetModels();
    resetDependencies();
  }, []);

  const handleActivateInactivate = useOperationController(
    async (event, id) => {
      event.preventDefault();

      const { active } = dispositivosLegais.find(
        (item) => item.id === id
      );

      const isActivation = !active;

      if (isActivation) {
        dispatch(activateByIdAsync(id));
      } else {
        dispatch(inactivateByIdAsync(id));
      }
    }
  );

  const handleClickOnButtonNew = useCallback((event) => {
    event.stopPropagation();
    dispatch(changeToCreateMode());
  }, []);

  const handleClickOnEditButton = useCallback((event, id) => {
    event.stopPropagation();
    dispatch(changeToConsultMode());
    history.push(t('com.muralis.qcx.url.moduloOperacionaisDispositivosLegaisDetalhes', { id }));
  }, [history]);

  const columns = useMemo(() => ([
    {
      field: 'id',
      headerAlign: 'center',
      align: 'center',
      headerName: t('com.muralis.qcx.codigo'),
      type: 'string',
      flex: 70,
      valueGetter: ({ row }) => (
        row?.id
      ),
    },
    {
      field: 'description',
      headerName: t('com.muralis.qcx.descricao'),
      type: 'string',
      flex: 160,
      valueGetter: ({ row }) => (
        row?.description
      ),
    },
    {
      field: 'tratamentoTributario',
      headerName: t('com.muralis.qcx.tratamentoTributario'),
      type: 'string',
      flex: 110,
      valueGetter: ({ row }) => (
        row?.fundamentacaoEspecifica?.tratamentoTributario?.description
      ),
    },
    {
      field: 'fundamentacaoEspecifica',
      headerName: t('com.muralis.qcx.fundamentacaoEspecifica'),
      type: 'string',
      flex: 180,
      valueGetter: ({ row }) => (
        row?.fundamentacaoEspecifica?.description
      ),
    },
    {
      field: 'actions',
      headerName: t('com.muralis.qcx.acoes.label'),
      headerAlign: 'center',
      align: 'center',
      flex: 90,
      renderCell: ({ row: { id, active } }) => (
        <>
          {userHasDeleteRole(authInfo, 'dispositivo-legal') && (
            <IconButton
              key={`stw-active${id}`}
              value={active}
              name={`stw-active-${id}`}
              onClick={(event) => handleActivateInactivate(event, id)}
            >
              <DeleteIcon color="error" />
            </IconButton>
          )}
          <IconButton
            key={`btn-view-${id}`}
            name={`btn-view-${id}`}
            onClick={(event) => handleClickOnEditButton(event, id)}
          >
            <PageviewIcon color="primary" />
          </IconButton>
        </>
      ),
    },
  ]), [
    authInfo,
    handleActivateInactivate,
    handleClickOnEditButton,
  ]);

  const breadcrumbs = useMemo(() => ([
    {
      link: {
        to: '/',
        name: t('com.muralis.qcx.inicio'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.cadastros'),
      },
    },
    {
      link: {
        to: t('com.muralis.qcx.url.moduloOperacionais'),
        name: t('com.muralis.qcx.operacionais'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.dispositivoLegal.label'),
      },
    },
  ]), []);

  const templateProps = useMemo(() => ({
    page: {
      title: t('com.muralis.qcx.dispositivoLegal.label'),
      breadcrumbs,
    },
    control: {
      buttonNew: {
        description: t('com.muralis.qcx.dispositivoLegal.novoDispositivoLegal'),
        path: t('com.muralis.qcx.url.moduloOperacionaisDispositivosLegaisNovo'),
        onClick: handleClickOnButtonNew,
      },
    },
    table: {
      columns,
    },
  }), [
    breadcrumbs,
    handleClickOnButtonNew
  ]);

  return (
    <QCXSimpleConsultPageTemplate
      pageProps={templateProps.page}
      controlProps={templateProps.control}
      tableProps={templateProps.table}
      tableData={dispositivosLegais}
      isLoading={isAlternativeLoading}
      buttonNewText={t('com.muralis.qcx.acoes.novo').toUpperCase()}
      authInfo={authInfo}
      requiredRoles={['dispositivo-legal']}
    />
  );
}
