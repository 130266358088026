/* eslint-disable */
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Code as CodeIcon,
  Receipt as ReceiptIcon,
  Delete as DeleteIcon,
  Search as PageviewIcon,
  MoreHoriz as MoreHorizIcon,
  Publish as PublishIcon,
  Print as PrintIcon,
  GetApp as DownloadIcon,
  Group as GroupIcon,
  RotateLeft as RotateLeftIcon,
} from '@material-ui/icons';
import { isEmpty, isFunction } from 'lodash';
import { IconButton, Menu, MenuItem, Typography } from '@material-ui/core';
import { bindMenu, bindTrigger } from 'material-ui-popup-state';
import DanfeContext from '../../../../contexts/import/danfe/DanfeContext';
import { isAlternativeLoadingStatus, isRefresh } from '../../../../utils/store/store-utils';
import {
  resetModel,
  changeToCreateMode,
  changeToConsultMode,
  resetMode,
  resetBackgroundMode,
  loading,
  failure,
  refresh as refreshList,
  resetRefresh,
  resetRelatedSelectionAvailableItemList,
  resetRelatedSelectionChangedItemList,
  resetRelatedSelectionItemList,
  resetRelatedTransferenciaItem,
  resetRelatedDanfeMae,
  success,
} from '../../../../features/danfe/danfeSlice';
import QCXSimpleConsultPageTemplate from '../../../../templates/simple-consult-page/QCXSimpleConsultPageTemplate';
import { selectDanfes, selectRefresh } from '../../../../features/danfe/danfeSelectors';
import { userHasDeleteRole } from '../../../../components/perfil/perfil-utils';
import {
  activateByIdAsync,
  inactivateByIdAsync,
  sendDanfeAsync,
  baixarXmlAsync,
  printDanfeAsync,
  fetchByFilterAsync,
} from '../../../../features/danfe/danfeThunks';
import QCXPopupState from '../../../../components/popup-state/QCXPopupState';
import QCXListItem from '../../../../shared-components/list-item-icon/QCXListItemIcon';
import {
  criaSolicitacaoSaldoItens,
  DANFE_ASSINADA,
  DANFE_COMPLEMENTAR,
  DANFE_EMITIDA,
  DANFE_FILHOTE,
  DANFE_MAE,
  DANFE_NAO_EMITIDA,
  DANFE_TRANSMITIDA,
  isDanfeEmitida,
  isDanfeFilhote,
  isDanfeMae,
  isDanfeNaoEmitida,
} from '../../../../utils/general/danfe/danfeUtils';
import { OPERACAO_ESTORNO } from '../../../../utils/general/movimentacao/movimentacaoUtils';
import QCXConfirmDialog from '../../../../shared-components/dialog/QCXConfirmDialog';
import useOperationMiddleware from '../../../../utils/hooks/operation/middleware/useOperationMiddleware';
import { setErrorFeedback, setSuccessFeedback, setWarningFeedback } from '../../../../features/feedback/feedbackSlice';
import { danfeAdicaoMovimentacaoAPI } from '../../../../features/danfe-adicao-movimentacao/danfeAdicaoMovimentacaoAPI';
import { useGenerateDANFEXMLReport } from '../../../../ts/common/hooks/useGenerateDANFEXML';

export default function DanfeConsultPage({ authInfo = {} }) {
  const { t } = useTranslation();
  const history = useHistory();

  const { status } = useContext(DanfeContext);

  const dispatch = useDispatch();

  // Get variables through selectors
  const danfes = useSelector(selectDanfes);
  const refresh = useSelector(selectRefresh);

  const isAlternativeLoading = useMemo(() => isAlternativeLoadingStatus(status), [status]);

  const fetchAll = useCallback(() => {
    dispatch(fetchByFilterAsync([{ name: 'active', value: true }]));
  }, []);

  const resetModes = useCallback(() => {
    dispatch(resetMode());
    dispatch(resetBackgroundMode());
  }, []);

  const resetModels = useCallback(() => {
    dispatch(resetModel());
    dispatch(resetRelatedSelectionAvailableItemList());
    dispatch(resetRelatedSelectionChangedItemList());
    dispatch(resetRelatedSelectionItemList());
    dispatch(resetRelatedTransferenciaItem());
    dispatch(resetRelatedDanfeMae());
  }, []);

  const resetDependencies = useCallback(() => {}, []);

  useEffect(() => {
    fetchAll();
    resetModes();
    resetModels();
    resetDependencies();
  }, [fetchAll, resetModes, resetModels, resetDependencies]);

  useEffect(() => {
    if (isRefresh(refresh)) {
      fetchAll();
      dispatch(resetRefresh());
    }
  }, [fetchAll, refresh]);

  const handleButtonNewClick = useCallback((event) => {
    event.stopPropagation?.();
    dispatch(resetModel());
    dispatch(changeToCreateMode());
  }, []);

  const handleClickOnEditButton = useCallback(
    (event, id) => {
      if (isFunction(event?.stopPropagation)) {
        event.stopPropagation();
      }

      dispatch(changeToConsultMode());
      history.push(t('com.muralis.qcx.url.importacaoDANFEDetalhes', { id }));
    },
    [history]
  );

  const handleClickOnFilhoteButton = useCallback(
    async (event, danfe) => {
      if (isFunction(event?.stopPropagation)) {
        event.stopPropagation();
      }

      dispatch(loading());

      const { id, adicoes, numeroDanfe } = danfe;

      const danfeAdicaoMovimentacaoContaList = criaSolicitacaoSaldoItens(adicoes);

      const responseContasItens = await danfeAdicaoMovimentacaoAPI.fetchBalancesByOwners(
        danfeAdicaoMovimentacaoContaList
      );

      if (isEmpty(responseContasItens?.data)) {
        dispatch(success());
        dispatch(
          setWarningFeedback({
            message: t('com.muralis.qcx.mensagem.todosItensDANFEForamTransferidos', {
              numeroDanfe,
            }),
          })
        );

        return;
      }

      dispatch(resetModel());
      dispatch(changeToCreateMode());

      history.push(t('com.muralis.qcx.url.importacaoDANFENovo'), {
        data: {
          danfeMae: {
            id,
          },
        },
      });
    },
    [t, history]
  );

  const diDescription = (di) => {
    let baseDescription = di?.atributosAdicionais?.processo;
    if (di?.atributosAdicionais?.registro) {
      baseDescription = `${baseDescription} - ${di.atributosAdicionais.registro}`;
    }
    return baseDescription;
  };

  const statusNfeDescription = useCallback(
    (statusNfe) => {
      if (!statusNfe) {
        return '';
      }

      switch (statusNfe) {
        case DANFE_NAO_EMITIDA:
          return t('com.muralis.qcx.estado.naoEmitida');
        case DANFE_ASSINADA:
          return t('com.muralis.qcx.estado.assinada');
        case DANFE_TRANSMITIDA:
          return t('com.muralis.qcx.estado.transmitida');
        case DANFE_EMITIDA:
          return t('com.muralis.qcx.estado.emitida');
        default:
          return '';
      }
    },
    [t]
  );

  const tipoDanfeDescription = useCallback(
    (tipoDanfe) => {
      if (!tipoDanfe) {
        return '';
      }

      switch (tipoDanfe) {
        case DANFE_MAE:
          return t('com.muralis.qcx.entrada');
        case DANFE_FILHOTE:
          return t('com.muralis.qcx.filhote');
        case DANFE_COMPLEMENTAR:
          return t('com.muralis.qcx.acoes.complementar');
        default:
          return '';
      }
    },
    [t]
  );

  const handleActivateInactivate = async (event, id) => {
    event?.preventDefault?.();
    event?.stopPropagation?.();

    const { active } = danfes.find((item) => item.id === id);

    const isActivation = !active;

    if (isActivation) {
      dispatch(activateByIdAsync(id));
    } else {
      dispatch(inactivateByIdAsync(id));
    }
  };

  const handleSend = async (event, id) => {
    event?.preventDefault?.();
    event?.stopPropagation?.();

    dispatch(sendDanfeAsync(id));
  };

  const [hasDownloaded, setHasDownloaded] = useState(false);
  const [idDanfe, setIdDanfe] = useState(null);
  const [selectedDi, setSelectedDi] = useState(null);
  const { data: reportData } = useGenerateDANFEXMLReport(idDanfe);

  const handleDownloadXml = useCallback((event, id, di) => {
    event?.preventDefault?.();
    event?.stopPropagation?.();

    setIdDanfe(id);
    setHasDownloaded(false);
    setSelectedDi(di);
  }, []);

  const memoizedReportData = useMemo(() => reportData, [reportData]);

  useEffect(() => {
    if (memoizedReportData && !hasDownloaded) {
      saveAs(memoizedReportData, `${selectedDi?.atributosAdicionais?.processo ?? 'Danfe'}.xml`);
      setHasDownloaded(true);
    }
  }, [memoizedReportData]);

  const handlePrint = async (event, id) => {
    event?.preventDefault?.();
    event?.stopPropagation?.();

    dispatch(printDanfeAsync(id));
  };

  const [handleEstornarDanfeFilhoteMiddleware, operationOfEstornarDanfeFilhote] = useOperationMiddleware(
    async (data) => {
      dispatch(loading());

      const payload = [
        {
          operacao: {
            description: OPERACAO_ESTORNO,
          },
          filhote: {
            id: data?.id,
          },
        },
      ];

      const response = await danfeAdicaoMovimentacaoAPI.reverse(payload).catch((reason) => {
        const responseMessage = reason?.response?.data?.message;

        const mensagem = responseMessage ? `: ${responseMessage}.` : '.';

        const errorMessage = t('com.muralis.qcx.erro.erroEstornoDANFEFilhote', {
          numeroDanfe: data?.numeroDanfe,
          mensagem,
        });

        dispatch(failure());
        dispatch(
          setErrorFeedback({
            message: errorMessage,
          })
        );
      });

      if (response?.status === 201) {
        dispatch(
          setSuccessFeedback({
            message: t('com.muralis.qcx.mensagem.estornoDANFEFilhote', {
              numeroDanfe: data?.numeroDanfe,
            }),
          })
        );

        dispatch(refreshList());
      }
    },
    [t]
  );

  const handleConfigureEstornarDanfeFilhoteClick = useCallback(
    (row, popupState) => {
      const { id, numeroDanfe } = row;

      const handleEstornarDanfeFilhoteClick = (event) => {
        if (isFunction(event?.stopPropagation)) {
          popupState.close(event);

          event.stopPropagation();

          const configureOperation = () => ({
            options: {
              title: t('com.muralis.qcx.confirmarOperacaoEstorno').toUpperCase(),
              message: t('com.muralis.qcx.mensagem.confirmarOperacaoEstornoDANFEFilhote', {
                numeroDanfe,
              }),
              endMessage: t('com.muralis.qcx.acoes.confirmacaoAcao', {
                acao: t('com.muralis.qcx.acoes.estornar').toLowerCase(),
              }),
            },
          });

          const dataOfOperationEstornarDanfeFilhote = {
            id,
            numeroDanfe,
          };

          handleEstornarDanfeFilhoteMiddleware(configureOperation, dataOfOperationEstornarDanfeFilhote);
        }
      };

      return handleEstornarDanfeFilhoteClick;
    },
    [t, handleEstornarDanfeFilhoteMiddleware]
  );

  const breadcrumbs = useMemo(
    () => [
      {
        link: {
          to: '/',
          name: t('com.muralis.qcx.inicio'),
        },
      },
      {
        link: {
          to: t('com.muralis.qcx.url.moduloImportacao'),
          name: t('com.muralis.qcx.importacao.label'),
        },
      },
      {
        text: {
          name: t('com.muralis.qcx.DANFE.label'),
        },
        default: true,
      },
    ],
    [t]
  );

  const columns = useMemo(
    () => [
      {
        field: 'code',
        headerName: t('com.muralis.qcx.codigo'),
        headerAlign: 'center',
        align: 'left',
        flex: 5,
        valueGetter: ({ row }) => row?.code,
      },
      {
        field: 'di',
        headerName: t('com.muralis.qcx.DIDUIMP.label'),
        headerAlign: 'center',
        align: 'left',
        flex: 5,
        valueGetter: ({ row }) => diDescription(row?.di),
      },
      {
        field: 'importador',
        headerName: t('com.muralis.qcx.importador.label'),
        headerAlign: 'center',
        align: 'left',
        flex: 6,
        valueGetter: ({ row }) => row?.importador?.pessoa?.nome || '-',
      },
      {
        field: 'numero',
        headerName: t('com.muralis.qcx.DANFE.numeroDANFE'),
        headerAlign: 'center',
        align: 'center',
        flex: 3,
        valueGetter: ({ row }) => row?.numeroDanfe,
      },
      {
        field: 'modelo',
        headerName: t('com.muralis.qcx.DANFE.modeloDANFE'),
        headerAlign: 'center',
        align: 'left',
        flex: 4,
        valueGetter: ({ row }) => row?.importador?.modeloDanfe?.nome || '-',
      },
      {
        field: 'status',
        headerName: t('com.muralis.qcx.DANFE.NFStatus'),
        headerAlign: 'center',
        align: 'center',
        flex: 3,
        valueGetter: ({ row }) => statusNfeDescription(row?.statusNfe),
      },
      {
        field: 'tipo',
        headerName: t('com.muralis.qcx.DANFE.tipoDANFE'),
        headerAlign: 'center',
        align: 'center',
        flex: 3,
        valueGetter: ({ row }) => tipoDanfeDescription(row?.tipoDanfe),
      },
      {
        field: 'actions',
        headerName: t('com.muralis.qcx.acoes.label'),
        headerAlign: 'center',
        align: 'left',
        flex: 3,
        renderCell: ({ row: { id, active, statusNfe, tipoDanfe, numeroDanfe, adicoes, di } }) => (
          <>
            <QCXPopupState popupId="popup-menu-danfe-consult">
              {(popupState) => (
                <>
                  <IconButton
                    key={`btn-more-options-${id}`}
                    name={`btn-more-options-${id}`}
                    {...bindTrigger(popupState)}
                  >
                    <MoreHorizIcon color="secondary" size={20} />
                  </IconButton>
                  <Menu {...bindMenu(popupState)}>
                    {/** Item 1: Visualizar */}
                    <MenuItem
                      onClick={(event) => {
                        popupState.close(event);

                        handleClickOnEditButton(event, id);
                      }}
                    >
                      <QCXListItem>
                        <PageviewIcon fontSize="small" color="primary" />
                      </QCXListItem>
                      <Typography
                        variant="inherit"
                        style={{
                          fontSize: 12,
                        }}
                      >
                        {t('com.muralis.qcx.acoes.consultar').toUpperCase()}
                      </Typography>
                    </MenuItem>

                    {/** Item 2: Transmitir (assina automaticamente) */}
                    <MenuItem
                      onClick={(event) => {
                        popupState.close(event);

                        handleSend(event, id);
                      }}
                      disabled={isDanfeEmitida(statusNfe)}
                    >
                      <QCXListItem>
                        <PublishIcon fontSize="small" color="primary" />
                      </QCXListItem>
                      <Typography
                        variant="inherit"
                        style={{
                          fontSize: 12,
                        }}
                      >
                        {t('com.muralis.qcx.acoes.transmitir').toUpperCase()}
                      </Typography>
                    </MenuItem>

                    {/** Item 3: Baixar xml */}
                    <MenuItem
                      onClick={(event) => {
                        popupState.close(event);

                        handleDownloadXml(event, id, di);
                      }}
                    >
                      <QCXListItem>
                        <CodeIcon fontSize="small" color="primary" />
                      </QCXListItem>
                      <Typography
                        variant="inherit"
                        style={{
                          fontSize: 12,
                        }}
                      >
                        {t('com.muralis.qcx.acoes.baixarXML').toUpperCase()}
                      </Typography>
                    </MenuItem>

                    {/** Item 4: Imprimir */}
                    <MenuItem
                      onClick={(event) => {
                        popupState.close(event);

                        handlePrint(event, id);
                      }}
                      disabled={isDanfeNaoEmitida(statusNfe)}
                    >
                      <QCXListItem>
                        <PrintIcon fontSize="small" color="primary" />
                      </QCXListItem>
                      <Typography
                        variant="inherit"
                        style={{
                          fontSize: 12,
                        }}
                      >
                        {t('com.muralis.qcx.acoes.imprimir').toUpperCase()}
                      </Typography>
                    </MenuItem>

                    {/** Item 5: Espelhar (= visualizar pdf, mesmo sem enviar) */}
                    <MenuItem
                      onClick={(event) => {
                        popupState.close(event);

                        handlePrint(event, id);
                      }}
                      disabled={isDanfeEmitida(statusNfe)}
                    >
                      <QCXListItem>
                        <DownloadIcon fontSize="small" color="primary" />
                      </QCXListItem>
                      <Typography
                        variant="inherit"
                        style={{
                          fontSize: 12,
                        }}
                      >
                        {t('com.muralis.qcx.DANFE.espelhoDANFE').toUpperCase()}
                      </Typography>
                    </MenuItem>

                    {/** Item 6: Criar Filhote */}
                    {isDanfeMae(tipoDanfe) && (
                      <MenuItem
                        onClick={(event) => {
                          popupState.close(event);

                          handleClickOnFilhoteButton(event, {
                            id,
                            adicoes,
                            numeroDanfe,
                          });
                        }}
                      >
                        <QCXListItem>
                          <GroupIcon fontSize="small" color="primary" />
                        </QCXListItem>
                        <Typography
                          variant="inherit"
                          style={{
                            fontSize: 12,
                          }}
                        >
                          {t('com.muralis.qcx.filhote').toUpperCase()}
                        </Typography>
                      </MenuItem>
                    )}

                    {/** Item 7: Estornar Filhote */}
                    {isDanfeFilhote(tipoDanfe) && (
                      <MenuItem
                        onClick={handleConfigureEstornarDanfeFilhoteClick(
                          {
                            id,
                            numeroDanfe,
                          },
                          popupState
                        )}
                        disabled={isDanfeEmitida(statusNfe)}
                      >
                        <QCXListItem>
                          <RotateLeftIcon fontSize="small" color="primary" />
                        </QCXListItem>
                        <Typography
                          variant="inherit"
                          style={{
                            fontSize: 12,
                          }}
                        >
                          {t('com.muralis.qcx.acoes.estornar').toUpperCase()}
                        </Typography>
                      </MenuItem>
                    )}
                    {/** Item 9: Desativar */}
                    <MenuItem
                      onClick={(event) => {
                        popupState.close(event);
                        handleActivateInactivate(event, id);
                      }}
                      disabled={isDanfeEmitida(statusNfe) || !userHasDeleteRole(authInfo, 'danfe') || !active}
                    >
                      <QCXListItem>
                        <DeleteIcon fontSize="small" color="error" />
                      </QCXListItem>
                      <Typography
                        variant="inherit"
                        style={{
                          fontSize: 12,
                        }}
                      >
                        {t('com.muralis.qcx.acoes.remover').toUpperCase()}
                      </Typography>
                    </MenuItem>
                  </Menu>
                </>
              )}
            </QCXPopupState>
          </>
        ),
      },
    ],
    [
      t,
      diDescription,
      handleSend,
      handlePrint,
      handleDownloadXml,
      statusNfeDescription,
      tipoDanfeDescription,
      handleClickOnEditButton,
      handleConfigureEstornarDanfeFilhoteClick,
    ]
  );

  const templateProps = useMemo(
    () => ({
      page: {
        title: t('com.muralis.qcx.DANFE.label'),
        icon: <ReceiptIcon />,
        breadcrumbs,
      },
      control: {
        buttonNew: {
          description: t('com.muralis.qcx.DANFE.novoRegistroDANFE', { tipoDanfe: '' }),
          path: t('com.muralis.qcx.url.importacaoDANFENovo'),
          onClick: handleButtonNewClick,
        },
      },
      table: {
        columns,
      },
    }),
    [columns, breadcrumbs, handleButtonNewClick, t]
  );

  return (
    <QCXSimpleConsultPageTemplate
      pageProps={templateProps.page}
      controlProps={templateProps.control}
      tableProps={templateProps.table}
      tableData={danfes}
      isLoading={isAlternativeLoading}
      authInfo={authInfo}
      requiredRoles={['danfe']}
    >
      <QCXConfirmDialog
        key="confirm-dialog-estornar-danfe-filhote"
        id="confirm-dialog-estornar-danfe-filhote"
        open={operationOfEstornarDanfeFilhote?.active}
        title={operationOfEstornarDanfeFilhote?.title}
        content={operationOfEstornarDanfeFilhote?.message}
        endContent={operationOfEstornarDanfeFilhote?.endMessage}
        onConfirm={operationOfEstornarDanfeFilhote.confirm}
        onClose={operationOfEstornarDanfeFilhote?.reset}
      />
    </QCXSimpleConsultPageTemplate>
  );
}
