import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import QCXFormStepWizard from '../../shared-components/form-step-wizard/QCXFormStepWizard';
import QCXTabsManager from '../tabs/QCXTabsManager';
import ComercialTabForm from './ComercialTabForm.tsx';
import IntegracaoTabForm from './IntegracaoTabForm.tsx';
import QCXAssessoriaTabForm from './QCXAssessoriaTabForm';
import QCXCadastrosFinanceirosTabForm from './QCXCadastrosFinanceirosTabForm';
import QCXCadastrosGeraisTabForm from './QCXCadastrosGeraisTabForm';
import QCXCadastrosOperacionaisTabForm from './QCXCadastrosOperacionaisTabForm';
import QCXExportacaoTabForm from './QCXExportacaoTabForm';
import QCXFinanceirosTabForm from './QCXFinanceirosTabForm';
import QCXImportacaoTabForm from './QCXImportacaoTabForm';
import QCXTabelasAduaneirasTabForm from './QCXTabelasAduaneirasTabForm';
import RelatoriosTabForm from './RelatoriosTabForm.tsx';
import { ModuloPerfilUtils } from './perfil-utils';

export default function QCXModulosAcessoPermissaoForm({
  initialValues,
  handleSubmit,
  isConsult,
  isUpdate,
  model,
  handleChangeUnidadeNegocio,
  controlComponentProps,
  handleChangeClientes,
  authInfo = {},
  ...restProps
}) {
  const { t } = useTranslation();

  const configureTabs = useCallback(
    (values) => [
      {
        label: t('com.muralis.qcx.cadastrosGerais'),
        disabled: !values?.atributos?.modulosAcesso?.includes(ModuloPerfilUtils.CADASTROS),
      },
      {
        label: t('com.muralis.qcx.cadastrosOperacionais'),
        disabled: !values?.atributos?.modulosAcesso?.includes(ModuloPerfilUtils.CADASTROS),
      },
      {
        label: t('com.muralis.qcx.cadastrosFinanceiros'),
        disabled: !values?.atributos?.modulosAcesso?.includes(ModuloPerfilUtils.CADASTROS),
      },
      {
        label: t('com.muralis.qcx.tabelasAduaneiras'),
        disabled: false,
      },
      {
        label: 'Assessoria',
        disabled: false,
      },
      {
        label: t('com.muralis.qcx.importacao.label'),
        disabled: !values?.atributos?.modulosAcesso?.includes(ModuloPerfilUtils.IMPORTACAO),
      },
      {
        label: t('com.muralis.qcx.exportacao.label'),
        disabled: !values?.atributos?.modulosAcesso?.includes(ModuloPerfilUtils.EXPORTACAO),
      },
      {
        label: t('com.muralis.qcx.financeiro.label'),
        disabled: !values?.atributos?.modulosAcesso?.includes(ModuloPerfilUtils.FINANCEIRO),
      },
      {
        label: t('com.muralis.qcx.comercial.label'),
        disabled: !values?.atributos?.modulosAcesso?.includes(ModuloPerfilUtils.COMERCIAL),
      },
      {
        label: t('com.muralis.qcx.integracao.label'),
        disabled: !values?.atributos?.modulosAcesso?.includes(ModuloPerfilUtils.INTEGRACAO),
      },
      {
        label: t('com.muralis.qcx.relatorios.label'),
        disabled: !values?.atributos?.modulosAcesso?.includes(ModuloPerfilUtils.RELATORIOS),
      },
    ],
    [t]
  );

  const getFirstTabIndex = useCallback((allTabs = []) => {
    const index = allTabs.findIndex((item) => !item.disabled);
    if (index === -1) {
      return 0;
    }
    return index;
  }, []);

  const handleUnidadesDeNegocioChange = useCallback(
    (form) => () => {
      if (!isConsult) {
        form.change('atributos.padrao[0].id', '');
      }
    },
    [isConsult]
  );

  const listeners = useMemo(
    () => [
      {
        name: 'atributos.unidadesDeNegocio[]',
        fn: handleUnidadesDeNegocioChange,
      },
    ],
    [handleUnidadesDeNegocioChange]
  );

  return (
    <QCXFormStepWizard
      initialValues={initialValues}
      onSubmit={handleSubmit}
      controlComponentProps={controlComponentProps}
      disablePristine
      authInfo={authInfo}
      onChangeListeners={listeners}
      {...restProps}
    >
      {({ values, form }) => {
        const tabs = configureTabs(values);
        const defaultActiveTabIndex = getFirstTabIndex(tabs);

        return (
          <>
            <QCXTabsManager
              tabs={tabs}
              defaultActiveTab={defaultActiveTabIndex}
              tabGrid={{
                xs: 12,
                sm: 12,
                md: 12,
              }}
              scrollButtons="auto"
              variant="scrollable"
            >
              {values?.atributos?.modulosAcesso?.includes(t('com.muralis.qcx.cadastros').toUpperCase()) ? (
                <QCXCadastrosGeraisTabForm
                  form={form}
                  values={values}
                  isConsult={isConsult}
                  model={model}
                  isUpdate={isUpdate}
                  handleChangeUnidadeNegocio={handleChangeUnidadeNegocio(form)}
                  authInfo={authInfo}
                />
              ) : (
                <div />
              )}
              <QCXCadastrosOperacionaisTabForm
                form={form}
                values={values}
                isConsult={isConsult}
                model={model}
                handleChangeClientes={handleChangeClientes(form)}
                authInfo={authInfo}
              />
              <QCXCadastrosFinanceirosTabForm form={form} values={values} isConsult={isConsult} authInfo={authInfo} />
              <QCXTabelasAduaneirasTabForm form={form} values={values} isConsult={isConsult} authInfo={authInfo} />
              <QCXAssessoriaTabForm form={form} values={values} isConsult={isConsult} authInfo={authInfo} />
              <QCXImportacaoTabForm form={form} values={values} isConsult={isConsult} authInfo={authInfo} />
              <QCXExportacaoTabForm form={form} values={values} isConsult={isConsult} authInfo={authInfo} />
              <QCXFinanceirosTabForm form={form} values={values} isConsult={isConsult} authInfo={authInfo} />
              <ComercialTabForm form={form} values={values} isConsult={isConsult} authInfo={authInfo} />
              <IntegracaoTabForm form={form} values={values} isConsult={isConsult} authInfo={authInfo} />
              <RelatoriosTabForm form={form} values={values} isConsult={isConsult} authInfo={authInfo} />
            </QCXTabsManager>
          </>
        );
      }}
    </QCXFormStepWizard>
  );
}
