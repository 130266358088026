import { Button, Grid } from '@mui/material';
import { useState, useMemo } from 'react';
import FormHeaderMedium from '../../../common/components/forms/formHeaderMedium';
import SelectStyled from '../../../common/components/input/selectStyled';
import LoadingIndicator from '../../../common/components/loadingIndicator';
import { useGetRequest, QueriesTypes } from '../../../common/hooks/queries';
import { useUnidadeNegocioGuard } from '../../../common/hooks/useUnidadeNegocioGuard';
import PageLayout from '../../../common/layouts/pageLayout';
import { Danfe } from '../../../common/types/danfe';
import { generateSpreadsheet } from './relatorioLaPage.helpers';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

const RelatorioLaPage = () => {
  useUnidadeNegocioGuard();
  const [processo, setProcesso] = useState<string | undefined>(undefined);

  const {
    data: relatorioData,
    isLoading: isRelatorioLoading,
    isError: isRelatorioError,
  } = useGetRequest(QueriesTypes.RelatorioLa, undefined, `processo=${processo}`);

  const {
    data: danfeData,
    isLoading: isDanfeDataLoading,
    isError: isDanfeDataError,
  } = useGetRequest(QueriesTypes.Danfe);

  const processos = useMemo(() => {
    if (!danfeData || typeof danfeData != 'object') {
      return [];
    }
    const filteredDanfeData = danfeData.filter(
      (danfe: Danfe) => danfe.tipoDanfe == 'ENTRADA'
    );
    const processos: string[] = filteredDanfeData.map(
      (danfe: Danfe) => danfe.di.atributosAdicionais.processo
    );

    // Remove duplicates
    return processos.filter((processo, index) => {
      return processos.indexOf(processo) === index;
    });
  }, [danfeData]);

  const handleSubmit = () => {
    generateSpreadsheet(relatorioData, processo);
  };

  return (
    <PageLayout
      title={'Relatório LA'}
      icon={<InsertDriveFileIcon color={'secondary'} />}
    >
      {isDanfeDataLoading ? (
        <LoadingIndicator message="Carregando Processos" />
      ) : (
        <Grid container spacing={2}>
          <FormHeaderMedium>
            Selecione o processo para o relatório
          </FormHeaderMedium>
          <Grid item sm={6} md={5} lg={3}>
            <SelectStyled
              options={processos.map((processo, index) => ({
                id: index,
                value: processo,
              }))}
              label={'Processo'}
              onChangeAction={(event, newValue) => {
                setProcesso(newValue?.value);
              }}
            />
          </Grid>
          <Grid item sm={2} textAlign={'right'}>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleSubmit}
              fullWidth
              disabled={isRelatorioError || !relatorioData}
            >
              Gerar Relatório
            </Button>
          </Grid>
        </Grid>
      )}
    </PageLayout>
  );
};

export default RelatorioLaPage;
