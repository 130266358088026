import React from 'react';
import QCXFinalField from '../final-field/QCXFinalField';
import QCXRadioLabel from '../radio-label/QCXRadioLabel';

export default function QCXFinalRadioField({
  name,
  label,
  value,
  required,
  children,
  ...restProps
}) {
  const render = ({
    input: {
      name: inputName,
      value: inputValue,
      checked,
      onChange,
      ...restInputProps
    },
    ...restCheckboxFieldProps
  }) => (
    <QCXRadioLabel
      label={label}
      name={inputName}
      type="radio"
      value={inputValue}
      checked={checked}
      onChange={onChange}
      inputProps={{
        required,
        ...restInputProps,
      }}
      {...restCheckboxFieldProps}
    />
  );

  return (
    <QCXFinalField
      name={name}
      type="radio"
      value={value}
      required={required}
      render={render}
      {...restProps}
    />
  );
}
