import { doPutRequest, validateQueryParams } from '../../utils/api/api-utils';
import {
  doGetQCXRequest,
  doPostQCXRequest,
  doPutQCXActivateRequest,
  doPutQCXInactivateRequest,
  doPutQCXRequest,
  doPutQCXCalculateRequest,
  doPutQCXConferirRequest,
  doPutQCXDuplicarRequest,
  doPutQCXLiberarRequest,
  doPutQCXRetransmitirRequest,
  doPutQCXRetificarRequest,
} from '../../utils/api/domain/domain-api-utils';
import {
  FATURA_ENDPOINT,
  FILTER_ENDPOINT,
  FIND_ENDPOINT,
  REABRIR_ENDPOINT,
} from '../../utils/api/domain/domain-endpoint-utils';

async function fetchAll() {
  return doGetQCXRequest([FATURA_ENDPOINT]);
}

async function fetchByFilter(params = []) {
  const validatedQueryParams = validateQueryParams(params);

  return doGetQCXRequest([FATURA_ENDPOINT, FILTER_ENDPOINT], validatedQueryParams);
}

async function fetchById(id) {
  return doGetQCXRequest([FATURA_ENDPOINT, id]);
}

async function findByParams(params = []) {
  const validatedQueryParams = validateQueryParams(params);

  return doGetQCXRequest([FATURA_ENDPOINT, FIND_ENDPOINT], validatedQueryParams);
}

async function register(data, queryParams = []) {
  return doPostQCXRequest([FATURA_ENDPOINT], data, queryParams);
}

async function save(data, queryParams = []) {
  return doPutQCXRequest([FATURA_ENDPOINT], data, queryParams);
}

async function activateById(id) {
  return doPutQCXActivateRequest([FATURA_ENDPOINT], id);
}

async function inactivateById(id) {
  return doPutQCXInactivateRequest([FATURA_ENDPOINT], id);
}

async function calculateById(id, subPath = [], data = null) {
  const { shouldZeroFields, ...restData } = data ?? { shouldZeroFields: false };

  return doPutQCXCalculateRequest([FATURA_ENDPOINT], id, subPath, restData, [
    { name: 'shouldZeroFields', value: shouldZeroFields },
  ]);
}

async function conferirBy(id, subPath = []) {
  return doPutQCXConferirRequest([FATURA_ENDPOINT], id, subPath);
}

async function duplicarBy(id, subPath = [], data = null) {
  return doPutQCXDuplicarRequest([FATURA_ENDPOINT], id, subPath, data);
}

async function liberarBy(id, subPath = []) {
  return doPutQCXLiberarRequest([FATURA_ENDPOINT], id, subPath);
}

async function retificarById(id, subPath = [], data = null) {
  return doPutQCXRetificarRequest([FATURA_ENDPOINT], id, subPath, data);
}

async function reabrirById(id) {
  return doPutQCXRequest([FATURA_ENDPOINT, REABRIR_ENDPOINT, id], {});
}

async function retransmitirBy(id, subPath = []) {
  return doPutQCXRetransmitirRequest([FATURA_ENDPOINT], id, subPath);
}

async function gerarSubstitutiva({ id, faturaId }) {
  return doPutQCXRequest([FATURA_ENDPOINT, 'substitutiva', faturaId, 'li', id], {});
}

const faturaAPI = {
  fetchAll,
  fetchByFilter,
  fetchById,
  findByParams,
  register,
  save,
  activateById,
  inactivateById,
  calculateById,
  conferirBy,
  duplicarBy,
  liberarBy,
  retransmitirBy,
  retificarById,
  reabrirById,
  gerarSubstitutiva,
};

export {
  faturaAPI,
  reabrirById,
  fetchAll,
  fetchByFilter,
  fetchById,
  findByParams,
  register,
  save,
  activateById,
  inactivateById,
  calculateById,
  conferirBy,
  duplicarBy,
  liberarBy,
  retificarById,
  gerarSubstitutiva,
};
