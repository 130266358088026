import React from 'react';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
export interface ProgressBarStep {
  step: number;
  description: string;
}

interface ProgressBarModalProps {
  title: string;
  open: boolean;
  totalSteps: number;
  currentStep: ProgressBarStep;
  onClose: () => void;
}

const ModalContainer = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: ${({ theme }) => theme.palette.background.paper};
  border: 2px solid ${({ theme }) => theme.palette.primary.main};
  border-radius: 10px;
  box-shadow: ${({ theme }) => theme.shadows[24]};
  padding: ${({ theme }) => theme.spacing(4)};
  slot: 'Backdrop';
`;

const AnimatedDotsTypography = styled(Typography)`
  @keyframes dots {
    0%,
    20% {
      content: '.';
    }
    40% {
      content: '..';
    }
    60% {
      content: '...';
    }
    80%,
    100% {
      content: '';
    }
  }

  &:after {
    display: inline-block;
    content: '';
    animation: dots 2s infinite;
  }
`;

const ProgressBarModal: React.FC<ProgressBarModalProps> = ({ title, open, totalSteps, currentStep, onClose }) => {
  const progress = (currentStep.step / totalSteps) * 100;

  return (
    <Modal open={open} onClose={onClose} closeAfterTransition>
      <Fade in={open}>
        <ModalContainer>
          <Typography variant="h6" mb={2}>
            {title}
          </Typography>
          <LinearProgress variant="determinate" value={progress} />
          <AnimatedDotsTypography align="center" mt={2}>
            {currentStep.description + ' '}
          </AnimatedDotsTypography>
        </ModalContainer>
      </Fade>
    </Modal>
  );
};

export default ProgressBarModal;
