import React, {
  useMemo,
} from 'react';
import FocusLock from 'react-focus-lock';
import { makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { selectMode } from '../../features/focus-lock/focusLockSlice';
import { isUnlockedFocusMode } from '../../utils/store/store-utils';
import useFocusLockShortcut from '../../utils/hooks/shortcut-keys/focus-lock/useFocusLockShortcut';

const useStyles = makeStyles(() => ({
  root: {
    width: 'inherit',
  },
}));

export default function QCXFocusLock({
  children,
  ...restProps
}) {
  const classes = useStyles();

  const mode = useSelector(selectMode);

  const isUnlockedFocus = useMemo(() => (
    isUnlockedFocusMode(mode)
  ), [mode]);

  useFocusLockShortcut();

  return (
    <FocusLock
      className={classes.root}
      disabled={isUnlockedFocus}
      // returnFocus={{
      //   preventScroll: true,
      // }}
      {...restProps}
    >
      {children}
    </FocusLock>
  );
}
