import React, { useCallback, useEffect, useMemo } from 'react';
import { Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import QCXFormTitle from '../form-title/QCXFormTitle';
import QCXFormStepWizard from '../../shared-components/form-step-wizard/QCXFormStepWizard';
import {
  setRelatedSelectionAvailableItemList,
  loading,
  success,
  failure,
  setError,
  setResponse,
} from '../../features/danfe/danfeSlice';
import {
  selectRelatedSelectionAvailableItemList,
  selectAdicoes,
} from '../../features/danfe/danfeSelectors';
import { danfeAdicaoMovimentacaoAPI } from '../../features/danfe-adicao-movimentacao/danfeAdicaoMovimentacaoAPI';
import {
  criaSolicitacaoSaldoItens,
  TIPO_CONTA_DANFE_DANFE_FILHOTE,
} from '../../utils/general/danfe/danfeUtils';
import { OperacaoMovimentacaoUtils } from '../../utils/general/movimentacao/movimentacaoUtils';
import QCXDanfeTransferenciaDataGridManager from './QCXDanfeTransferenciaDataGridManager';
import { forceParseToNumber } from '../../utils/general/parse-utils';
import { normalizeNumeral } from '../../utils/general/general-utils';

export default function QCXDanfeTransferenciaForm({
  isConsult,
  isUpdate,
  isCreate,
  initialValues,
  handleChangeToUpdate,
  handleCancelUpdate,
  controlComponentProps,
  danfeNormalizer,
  ...restProps
}) {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const history = useHistory();

  const availableItemsList = useSelector(selectRelatedSelectionAvailableItemList);
  const adicoes = useSelector(selectAdicoes);

  const itensDanfeMae = useMemo(() => (
    initialValues?.danfeMae?.adicoes
  ), [initialValues]);

  const disableFinishButton = useMemo(() => ({
    finishButton: {
      disable: isEmpty(adicoes),
    },
  }), [adicoes]);

  const normalize = useCallback((unnormalizedData) => {
    const referencia = {
      ...unnormalizedData,
      adicoes: [],
    };

    const solicitacoesTransferencia = unnormalizedData?.adicoes
      ?.map((adicao) => ({
        conta: {
          tipo: {
            description: TIPO_CONTA_DANFE_DANFE_FILHOTE,
          },
          proprietario: {
            id: adicao?.itemOriginal?.id,
          },
        },
        referencia,
        operacao: {
          description: OperacaoMovimentacaoUtils.TRANSFERENCIA,
        },
        valor: forceParseToNumber(
          normalizeNumeral(
            adicao?.quantidade
          )
        ),
      }));

    return solicitacoesTransferencia;
  }, []);

  const unnormalize = useCallback((normalizedData) => {
    const {
      proprietario,
      saldoAtual,
      saldoInicial,
    } = normalizedData;

    const unnormalizedData = {
      exportador: proprietario?.adicaoDi?.exportador?.pessoaJuridica?.nome,
      fabricante: proprietario?.adicaoDi?.fabricante?.pessoaJuridica?.nome,
      id: proprietario?.id,
      numeroAdicao: proprietario?.numeroAdicao,
      numeroSeqAdicao: proprietario?.numeroSeqAdicao,
      ncm: proprietario?.adicaoDi?.ncm?.code,
      partNumber: proprietario?.mercadoria?.partnumber,
      pesoLiquido: proprietario?.mercadoria?.pesoLiquido,
      quantidade: saldoAtual,
      quantidadeInicial: saldoInicial,
      valor: proprietario?.valorTotalReal,
    };

    return unnormalizedData;
  }, []);

  const handleFindAvailableItens = useCallback(async (itens) => {
    if (isEmpty(adicoes)) {
      dispatch(loading());

      const responseContasItens = await danfeAdicaoMovimentacaoAPI.fetchBalancesByOwners(itens);

      if (responseContasItens?.status === 200) {
        const responseData = responseContasItens?.data;

        const itensTransferiveis = responseData?.map((data) => unnormalize(data));

        dispatch(setRelatedSelectionAvailableItemList(itensTransferiveis));
      }

      dispatch(success());
    }
  }, []);

  useEffect(() => {
    const danfeAdicaoMovimentacaoContaList = criaSolicitacaoSaldoItens(itensDanfeMae);

    handleFindAvailableItens(danfeAdicaoMovimentacaoContaList);
  }, [itensDanfeMae, handleFindAvailableItens]);

  const handleSubmit = useCallback(async (values) => {
    const normalizedDanfeFilhote = danfeNormalizer({
      ...values,
      adicoes: [],
    });

    const normalizedData = normalize({
      ...normalizedDanfeFilhote,
      adicoes: values?.adicoes,
    });

    dispatch(loading());

    const response = await danfeAdicaoMovimentacaoAPI.transfer(normalizedData)
      .catch((reason) => {
        const message = t(
          'com.muralis.qcx.erro.erroTransferenciaItensDANFE',
          {
            tipoDanfe: t('com.muralis.qcx.filhote').toLowerCase(),
            mensagem: reason?.response?.data?.message,
          }
        );

        dispatch(failure());
        dispatch(setError({
          message,
        }));
      });

    if (response?.status === 201) {
      const feedbackMessage = t(
        'com.muralis.qcx.mensagem.registroDANFE',
        {
          tipoDanfe: t('com.muralis.qcx.filhote').toLowerCase(),
        }
      );

      dispatch(success());
      dispatch(setResponse({
        status: response.status,
        data: response?.data,
        message: feedbackMessage,
      }));

      history.push(t('com.muralis.qcx.url.importacaoDANFE'));
    }
  }, [
    t,
    history,
    normalize,
    danfeNormalizer,
  ]);

  return (
    <>
      <QCXFormStepWizard
        isConsult={isConsult}
        isUpdate={isUpdate}
        disablePristine
        initialValues={initialValues}
        handleChangeToUpdate={handleChangeToUpdate}
        handleCancelUpdate={handleCancelUpdate}
        controlComponentProps={controlComponentProps}
        handleSubmitValues={handleSubmit}
        onSubmit={handleSubmit}
        extraConditions={disableFinishButton}
        {...restProps}
      >
        {() => (
          <>
            <Grid
              item
              container
              spacing={2}
            >
              <Grid
                item
                container
                xs={12}
              >
                <QCXFormTitle title={t('com.muralis.qcx.tabelaAdicoes')} />
              </Grid>
              <Grid
                item
                container
                xs={12}
              >
                <QCXDanfeTransferenciaDataGridManager
                  rows={availableItemsList || []}
                />
              </Grid>
            </Grid>
          </>
        )}
      </QCXFormStepWizard>
    </>
  );
}
