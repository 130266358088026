import { useKeycloak } from '@react-keycloak/web';
import { useMemo } from 'react';
import { KeycloadIdTokenParsedExtended } from './interfaces';

export function useGetUsuarioLocale() {
  const { keycloak } = useKeycloak();

  const keyCloakIdToken = useMemo(() => {
    return keycloak?.idTokenParsed as KeycloadIdTokenParsedExtended;
  }, [keycloak]);

  const locale = useMemo(() => keyCloakIdToken?.locale, [keyCloakIdToken]);

  return locale ?? 'pt-BR';
}
