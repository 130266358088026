import { createAsyncThunk } from '@reduxjs/toolkit';
import { numerarioImportacaoAPI } from './numerarioImportacaoAPI';
import { setErrorFeedback, setSuccessFeedback } from '../feedback/feedbackSlice';

const fetchAllAsync = createAsyncThunk(
  'numerarioImportacao/fetchAll',
  async () => {
    const { data } = await numerarioImportacaoAPI.fetchAll();
    return { data };
  }
);

const fetchByIdAsync = createAsyncThunk(
  'numerarioImportacao/fetchById',
  async (data) => {
    const response = await numerarioImportacaoAPI.fetchById(data);
    return {
      response: {
        status: response.status,
        data: response.data,
      },
    };
  }
);

const activateByIdAsync = createAsyncThunk(
  'numerarioImportacao/activateById',
  async (id) => {
    const { status, data } = await numerarioImportacaoAPI.activateById(id);
    return { response: { status, data } };
  }
);

const inactivateByIdAsync = createAsyncThunk(
  'numerarioImportacao/inactivateById',
  async (id) => {
    const { status, data } = await numerarioImportacaoAPI.inactivateById(id);
    return { response: { status, data } };
  }
);

const notifyByIdAsync = createAsyncThunk('numerarioImportacao/notifyById', async (id, thunkApi) => {
  const {status, data} = await numerarioImportacaoAPI.notifyNumerario(id)

  if (status === 200){
    thunkApi.dispatch(
      setSuccessFeedback({
        message: 'Notificação enviada com sucesso!',
      })
    );
  }else{
    thunkApi.dispatch(
      setErrorFeedback({
        message: 'Erro ao enviar notificação.',
      })
    );
  }

  return {response: {status, data}};
})

const numerarioImportacaoThunks = {
  fetchAllAsync,
  fetchByIdAsync,
  activateByIdAsync,
  inactivateByIdAsync,
  notifyByIdAsync,
};

export {
  numerarioImportacaoThunks,
  fetchAllAsync,
  fetchByIdAsync,
  activateByIdAsync,
  inactivateByIdAsync,
  notifyByIdAsync,
};
