import { TextField, TextFieldProps } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useIMask } from "react-imask";

export interface CPFTextFieldProps {
  onChange: (values: { maskedValue: string; unmaskedValue: string }) => void;
  textFieldProps?: TextFieldProps;
}

function CPFTextField({ onChange, textFieldProps }: CPFTextFieldProps) {
  const { ...rest } = textFieldProps ?? {};

  const { ref, value, unmaskedValue } = useIMask({
    mask: "000.000.000-00",
    radix: ".",
    normalizeZeros: true,
    placeholderChar: "_",
    lazy: false,
  });

  useEffect(() => {
    onChange({ maskedValue: value, unmaskedValue });
  }, [value, unmaskedValue]);

  return (
    <TextField
      {...rest}
      fullWidth
      required
      label="CPF"
      size="small"
      inputRef={ref}
      InputLabelProps={{
        shrink: true,
      }}
    ></TextField>
  );
}

export default CPFTextField;
