import _ from 'lodash';
import i18n from '../../../i18n';
import { isStrictEquals, sortCollectionBy, normalizeNumeral } from '../general-utils';
import {
  RELACAO_FABRICANTE_DESCONHECIDO,
} from '../../../components/relacao-fornecedor/relacao-fornecedor-utils';

const TIPO_FATURA = 'FATURA';
const TIPO_LI = 'LI';
const TIPO_DI_DUIMP = 'DI_DUIMP';
const TIPO_DUIMP = 'DUIMP';
const TIPO_DUE = 'DUE';

const isTipoFatura = (type) => (
  isStrictEquals(TIPO_FATURA, type)
);

const isTipoLi = (type) => (
  isStrictEquals(TIPO_LI, type)
);

const getNameValuePairByTipo = (tipo) => ({
  name: 'tipo',
  value: tipo,
});

const DEFAULT_TIPO_LI_PARAMETER = getNameValuePairByTipo(TIPO_LI);
const DEFAULT_TIPO_DI_DUIMP_PARAMETER = getNameValuePairByTipo(TIPO_DI_DUIMP);
const DEFAULT_TIPO_DUE_PARAMETER = getNameValuePairByTipo(TIPO_DUE);

const calculatePesoLiquidoEquivalenteBy = (
  quantidadeAtual,
  quantidadeOriginal,
  pesoLiquidoOriginal
) => {
  const fracaoPesoLiquido = pesoLiquidoOriginal / quantidadeOriginal;
  const pesoLiquidoEquivalente = quantidadeAtual * fracaoPesoLiquido;

  return pesoLiquidoEquivalente;
};

const sortItensByNumero = (list) => (
  sortCollectionBy(
    list,
    'item'
  )
);

const numericoValido = (valor) => {
  const valorNumerico = normalizeNumeral(valor);

  return Boolean(valorNumerico);
};

const getPendenciasFaturaOcr = (fatura) => {
  const pendenciasFatura = [];
  const pendenciasMercadorias = [];

  if (!fatura || fatura?.origem !== 'OCR') return null;

  // numero
  if (!fatura.numero) {
    pendenciasFatura.push(i18n.t('com.muralis.qcx.fatura.numeroFatura'));
  }
  // peso bruto
  if (!fatura.pesoBruto) {
    pendenciasFatura.push(i18n.t('com.muralis.qcx.pesoBruto'));
  }
  // peso liquido
  if (!fatura.pesoLiquido) {
    pendenciasFatura.push(i18n.t('com.muralis.qcx.pesoLiquido'));
  }
  // moeda do MLE
  if (!fatura.mle || !fatura.mle.moeda) {
    pendenciasFatura.push(i18n.t('com.muralis.qcx.fatura.moedaMLE'));
  }
  // exportador
  if (!fatura.exportador) {
    pendenciasFatura.push(i18n.t('com.muralis.qcx.exportador.label'));
  }
  // fabricante
  if (
    !fatura.fabricante
    && fatura.relacao !== RELACAO_FABRICANTE_DESCONHECIDO
  ) {
    pendenciasFatura.push(i18n.t('com.muralis.qcx.fabricante.label'));
  }

  let metadata = {};
  try {
    metadata = JSON.parse(fatura.metadata);
    // eslint-disable-next-line no-empty
  } catch (error) {}
  const pendencias = {
    ...metadata,
    fatura: pendenciasFatura,
    mercadorias: pendenciasMercadorias,
  };
  const itensInfo = _.get(metadata, 'itensInfo', []);
  fatura.mercadorias.forEach((mercadoria) => {
    const infoMercadoria = itensInfo.find(
      (info) => (info.itemPartnumber && info.itemPartnumber === mercadoria.partnumber)
        || (info.itemNcm && info.itemNcm === mercadoria.ncm)
        || (info.itemDescricao && info.itemDescricao === mercadoria.descricaoResumida)
        || (info.id && info.id === mercadoria.id)
    );
    const pendenciasMercadoria = [];

    // partnumber
    if (!mercadoria.partnumber) {
      pendenciasMercadoria.push('Partnumber');
    }
    // fornecedor
    if (
      !mercadoria.fabricante
      && mercadoria.relacao !== RELACAO_FABRICANTE_DESCONHECIDO
    ) {
      pendenciasMercadoria.push(i18n.t('com.muralis.qcx.fabricante.label'));
    }
    // pais de origem
    if (!mercadoria.fabricante?.endereco && !mercadoria.paisOrigem) {
      pendenciasMercadoria.push(i18n.t('com.muralis.qcx.pais.paisOrigem'));
    }
    // exportador
    if (!mercadoria.exportador) {
      pendenciasMercadoria.push('Exportador');
    }
    // pais de origem
    if (!mercadoria.exportador?.endereco) {
      pendenciasMercadoria.push('País de Aquisição');
    }
    // quantidade
    if (!numericoValido(mercadoria.quantidade)) {
      pendenciasMercadoria.push(i18n.t('com.muralis.qcx.quantidade.label'));
    }
    // ncm
    if (!mercadoria.ncm?.id) {
      pendenciasMercadoria.push(i18n.t('com.muralis.qcx.NCM.label'));
    }
    // unidade de medida
    if (!mercadoria.unidadeDeMedida) {
      pendenciasMercadoria.push(i18n.t('com.muralis.qcx.unidadeMedida.label'));
    }
    // valor unitario
    if (!numericoValido(mercadoria.valorUnitarioMoeda)) {
      pendenciasMercadoria.push(i18n.t('com.muralis.qcx.valorUnitario'));
    }
    // especificacao
    if (!mercadoria.adicionais?.especificacao) {
      pendenciasMercadoria.push(i18n.t('com.muralis.qcx.especificacao.label'));
    }
    if (pendenciasMercadoria.length > 0) {
      pendenciasMercadorias.push(
        {
          idMercadoria: mercadoria.id,
          item: mercadoria.item,
          sequencial: infoMercadoria?.sequencial || 'N/A',
          pagina: infoMercadoria?.pagina || 'N/A',
          pendencias: pendenciasMercadoria,
          infoMercadoria,
        }
      );
    }
  });
  pendencias.temPendencias = Boolean(pendencias.fatura.length || pendencias.mercadorias.length);

  return pendencias;
};

const updateFaturaOcrMetadata = (fatura) => {
  const pendencias = getPendenciasFaturaOcr(fatura);
  if (pendencias === null) return;

  const { fatura: pendenciasFatura, mercadorias, ...outras } = pendencias;

  const metadata = {
    ...outras,
    itensInfo: [],
  };

  if (pendencias.temPendencias) {
    mercadorias.forEach((pendencia) => {
      metadata.itensInfo.push({
        id: pendencia.idMercadoria,
        ...pendencia.infoMercadoria,
      });
    });
  }

  fatura.metadata = JSON.stringify(metadata);
};

const FaturaUtils = {
  TIPO_FATURA,
  TIPO_LI,
  TIPO_DI_DUIMP,
  TIPO_DUIMP,
  TIPO_DUE,
  DEFAULT_TIPO_LI_PARAMETER,
  DEFAULT_TIPO_DI_DUIMP_PARAMETER,
  DEFAULT_TIPO_DUE_PARAMETER,
  isTipoFatura,
  isTipoLi,
  getNameValuePairByTipo,
  calculatePesoLiquidoEquivalenteBy,
  sortItensByNumero,
  getPendenciasFaturaOcr,
  updateFaturaOcrMetadata,
  numericoValido,
};

export {
  FaturaUtils,
  TIPO_FATURA,
  TIPO_LI,
  TIPO_DI_DUIMP,
  DEFAULT_TIPO_LI_PARAMETER,
  DEFAULT_TIPO_DI_DUIMP_PARAMETER,
  DEFAULT_TIPO_DUE_PARAMETER,
  TIPO_DUIMP,
  TIPO_DUE,
  isTipoFatura,
  isTipoLi,
  getNameValuePairByTipo,
  calculatePesoLiquidoEquivalenteBy,
  sortItensByNumero,
  getPendenciasFaturaOcr,
  updateFaturaOcrMetadata,
  numericoValido,
};
