import { IconButton } from '@material-ui/core';
import {
  Delete as DeleteIcon,
  Search as PageviewIcon,
  ViewList as ViewListIcon,
} from '@material-ui/icons';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import ClienteContext from '../../../../../contexts/registrations/cliente/ClienteContext';
import {
  activateByIdAsync,
  changeToCreateMode,
  fetchAllAsync,
  inactivateByIdAsync,
  resetBackgroundMode,
  resetMode,
  resetModel,
  resetRelatedContatoModel,
  resetSubMode,
  selectClientes,
} from '../../../../../features/cliente/clienteSlice';
import {
  ALTERNATIVE_LOADING_STATUS,
} from '../../../../../features/status';
import {
  changeControlTo as changeControlPaisTo,
  resetList as resetPaisList,
} from '../../../../../features/pais/paisSlice';
import {
  resetList as resetEstadoList,
} from '../../../../../features/estado/estadoSlice';
import {
  resetList as resetCidadeList,
} from '../../../../../features/cidade/cidadeSlice';
import {
  changeControlTo as changeControlNaladiShTo,
} from '../../../../../features/naladi-sh/naladiShSlice';
import {
  changeControlTo as changeControlNaladiNccaTo,
} from '../../../../../features/naladi-ncca/naladiNccaSlice';
import {
  changeControlTo as changeControlUnidadeMedidaTo,
} from '../../../../../features/unidade-medida/unidadeDeMedidaSlice';
import {
  changeControlTo as changeControlNcmTo,
} from '../../../../../features/ncm-subitem/ncmSubitemSlice';
import { IDLE_CONTROL } from '../../../../../features/config-control';
import QCXSimpleConsultPageTemplate from '../../../../../templates/simple-consult-page/QCXSimpleConsultPageTemplate';
import { formatCnpj, formatCpf } from '../../../../../utils/hooks/form/field/formatters';
import { userHasDeleteRole } from '../../../../../components/perfil/perfil-utils';
import { getLocalidadeOrDefault } from '../../../../../utils/general/general-utils';
import QCXConfirmDialog from '../../../../../shared-components/dialog/QCXConfirmDialog';
// eslint-disable-next-line import/no-unresolved, import/extensions
import useConfigurePopUp from '../../../../../ts/common/hooks/popUps/useConfigurePopUp';

export default function ClienteConsultPage({ authInfo = {} }) {
  const { t } = useTranslation();
  const history = useHistory();

  const {
    status,
  } = useContext(ClienteContext);

  const dispatch = useDispatch();

  const clientes = useSelector(selectClientes);

  const isAlternativeLoading = useMemo(() => status === ALTERNATIVE_LOADING_STATUS, [status]);

  const fetchAll = async () => {
    dispatch(fetchAllAsync());
  };

  const resetModes = useCallback(() => {
    dispatch(resetMode());
    dispatch(resetBackgroundMode());
    dispatch(resetSubMode());
  }, []);

  const resetModels = useCallback(() => {
    dispatch(resetModel());
    dispatch(resetRelatedContatoModel());
  }, []);

  const resetDependencies = useCallback(() => {
    dispatch(changeControlPaisTo(IDLE_CONTROL));
    dispatch(changeControlNcmTo(IDLE_CONTROL));
    dispatch(changeControlNaladiShTo(IDLE_CONTROL));
    dispatch(changeControlNaladiNccaTo(IDLE_CONTROL));
    dispatch(changeControlUnidadeMedidaTo(IDLE_CONTROL));
    dispatch(resetPaisList());
    dispatch(resetEstadoList());
    dispatch(resetCidadeList());
  }, []);

  useEffect(() => {
    fetchAll();
    resetModes();
    resetModels();
    resetDependencies();
  }, []);

  const breadcrumbs = [
    {
      link: {
        to: '/',
        name: t('com.muralis.qcx.inicio'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.cadastros'),
      },
    },
    {
      link: {
        to: t('com.muralis.qcx.url.moduloOperacionais'),
        name: t('com.muralis.qcx.operacionais'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.inicio'),
      },
    },
  ];

  const handleActivateInactivate = async ({ id }) => {
    const { active } = clientes.find(
      (item) => item.id === id
    );

    const isActivation = !active;

    if (isActivation) {
      dispatch(activateByIdAsync(id));
    } else {
      dispatch(inactivateByIdAsync(id));
    }
  };

  const handleClickOnButtonNew = useCallback((event) => {
    event.stopPropagation();
    dispatch(changeToCreateMode());
  }, []);

  const handleClickOnEditButton = useCallback(
    (_event, id) => (
      history.push(
        t('com.muralis.qcx.url.moduloOperacionaisClientesDetalhes', { id })
      )
    ), [history]
  );

  const [handleOpenPopUp, popUpConfig] = useConfigurePopUp({ primaryConfirmCallback: handleActivateInactivate });

  const columns = [
    {
      field: 'id',
      headerName: t('com.muralis.qcx.codigo'),
      headerAlign: 'center',
      align: 'center',
      flex: 100,
      valueGetter: ({ row }) => (
        row?.id
      ),
    },
    {
      field: 'description',
      headerName: t('com.muralis.qcx.nome'),
      flex: 250,
      valueGetter: ({ row }) => (
        row?.description
      ),
    },
    {
      field: 'pessoa.nomeResumido',
      headerName: t('com.muralis.qcx.empresa.nomeFantasia'),
      type: 'string',
      flex: 190,
      valueGetter: ({ row }) => (
        row?.pessoa?.nomeResumido || '-'
      ),
    },
    {
      field: 'pessoa.cnpj',
      headerName: t('com.muralis.qcx.empresa.CNPJ'),
      type: 'string',
      flex: 190,
      valueGetter: ({ row }) => (
        formatCnpj(row?.pessoa?.cnpj)
      ),
    },
    {
      field: 'pessoa.cpf',
      headerName: t('com.muralis.qcx.CPF'),
      type: 'string',
      flex: 190,
      hide: true,
      valueGetter: ({ row }) => (
        formatCpf(row?.pessoa?.cpf)
      ),
    },
    {
      field: 'localidade',
      headerName: t('com.muralis.qcx.localidade'),
      type: 'string',
      flex: 165,
      valueGetter: ({ row }) => (
        getLocalidadeOrDefault(row?.endereco, '-')
      ),
    },
    {
      field: 'insertionDate',
      headerName: t('com.muralis.qcx.dataCriacao'),
      type: 'dateTime',
      flex: 150,
      valueGetter: ({ row }) => (
        row?.insertionDate
      ),
    },
    {
      field: 'actions',
      headerName: t('com.muralis.qcx.acoes.label'),
      headerAlign: 'center',
      align: 'center',
      flex: 150,
      renderCell: ({ row: { id, active } }) => (
        <>
          {userHasDeleteRole(authInfo, 'cliente') && (
            <IconButton
              key={`stw-active${id}`}
              value={active}
              name={`stw-active-${id}`}
              onClick={() => handleOpenPopUp({ args: { id } })}
            >
              <DeleteIcon color="error" />
            </IconButton>
          )}
          <IconButton
            key={`btn-view-${id}`}
            name={`btn-view-${id}`}
            onClick={(event) => handleClickOnEditButton(event, id)}
          >
            <PageviewIcon color="primary" />
          </IconButton>
        </>
      ),
    }
  ];

  const templateProps = {
    page: {
      title: t('com.muralis.qcx.cliente.labelPlural'),
      icon: <ViewListIcon />,
      breadcrumbs,
    },
    control: {
      buttonNew: {
        description: t('com.muralis.qcx.cliente.novoCliente'),
        path: t('com.muralis.qcx.url.moduloOperacionaisClientesNovo'),
        onClick: handleClickOnButtonNew,
      },
    },
    table: {
      columns,
    },
  };

  const filterPropGetter = useCallback(
    (item) => item.description,
    []
  );

  return (
    <QCXSimpleConsultPageTemplate
      pageProps={templateProps.page}
      controlProps={templateProps.control}
      tableProps={templateProps.table}
      tableData={clientes}
      isLoading={isAlternativeLoading}
      filterPropGetter={filterPropGetter}
      authInfo={authInfo}
      requiredRoles={['cliente']}
    >
      <QCXConfirmDialog
        key="confirm-dialog-activate-inactivate-di-duimp"
        id="confirm-dialog-activate-inactivate-di-duimp"
        open={popUpConfig?.isPopUpOpen}
        title={popUpConfig?.title}
        content={popUpConfig?.message}
        endContent={popUpConfig?.endMessage}
        onConfirm={popUpConfig.confirmPrimaryAction}
        onClose={popUpConfig?.reset}
      />
    </QCXSimpleConsultPageTemplate>
  );
}
