import { validateQueryParams } from '../../utils/api/api-utils';
import {
  doGetQCXRequest,
  doPostQCXRequest,
  doPutQCXActivateRequest,
  doPutQCXInactivateRequest,
  doPutQCXRequest,
} from '../../utils/api/domain/domain-api-utils';
import {
  FILTER_ENDPOINT,
  PORTO_AEROPORTO_ENDPOINT,
} from '../../utils/api/domain/domain-endpoint-utils';

export async function fetchAll() {
  return doGetQCXRequest([
    PORTO_AEROPORTO_ENDPOINT
  ]);
}

export async function fetchById(id) {
  return doGetQCXRequest([
    PORTO_AEROPORTO_ENDPOINT,
    id,
  ]);
}

export async function fetchByFilter(params = []) {
  const validatedParams = validateQueryParams(params);
  if (validatedParams.length) {
    return doGetQCXRequest(
      [
        PORTO_AEROPORTO_ENDPOINT,
        FILTER_ENDPOINT
      ],
      validatedParams
    );
  }
  return { data: [] };
}

export async function register(data, queryParams = []) {
  return doPostQCXRequest(
    [PORTO_AEROPORTO_ENDPOINT],
    data,
    queryParams
  );
}

export async function save(data, queryParams = []) {
  return doPutQCXRequest(
    [PORTO_AEROPORTO_ENDPOINT],
    data,
    queryParams
  );
}

export async function activateById(id) {
  return doPutQCXActivateRequest(
    [PORTO_AEROPORTO_ENDPOINT],
    id
  );
}

export async function inactivateById(id) {
  return doPutQCXInactivateRequest(
    [PORTO_AEROPORTO_ENDPOINT],
    id
  );
}
