import { cloneDeep } from 'lodash';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  addModeloLancamento,
  changeToSubConsultMode,
  changeToSubCreateMode,
  changeToSubUpdateMode, resetSubMode, selectSubMode,
  removeModeloLancamentoById,
  resetRelatedModeloLancamentoModel,
  selectRelatedModeloLancamentoModel,
  setRelatedModeloLancamentoModel,
  updateModeloLancamento,
  resetLockedModeloLancamentoModel,
  lockSelectedModeloLancamento,
} from '../../features/modelo-numerario/modeloNumerarioSlice';
import QCXFinalFormWizard from '../../shared-components/final-form-wizard/QCXFinalFormWizard';
import { forceUnnormalizeNumeral, isValid, normalizeNumeral } from '../../utils/general/general-utils';
import { formatBrazilianNumericDecimal } from '../../utils/hooks/form/field/formatters';
import {
  isNoneSubMode, isSubConsultMode, isSubCreateMode, isSubUpdateMode,
} from '../../utils/store/store-utils';
import QCXModeloLancamentoFinalFormManager from '../modelo-lancamento/QCXModeloLancamentoFinalFormManager';
import QCXModeloNumerarioTecnicalDataForm from './QCXModeloNumerarioTecnicalDataForm';

export default function QCXModeloNumerarioWizardFinalForm({
  handleSubmit,
  handleAlternativeSave,
  model,
  handleChangeModel,
  handleChangeToCreate,
  handleChangeToConsult,
  handleChangeToUpdate,
  handleCancelUpdate,
  handleChangeToBackgroundCreate,
  handleResetBackgroundMode,
  isCreate,
  isConsult,
  isUpdate,
  isBackgroundCreate,
  authInfo = {},
  requiredRoles = [],
  ...restProps
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const subMode = useSelector(selectSubMode);

  const modeloLancamento = useSelector(selectRelatedModeloLancamentoModel);

  const isSubNone = useMemo(() => (
    isNoneSubMode(subMode)
  ), [subMode]);

  const isSubCreate = useMemo(() => (
    isSubCreateMode(subMode)
  ), [subMode]);

  const isSubConsult = useMemo(() => (
    isSubConsultMode(subMode)
  ), [subMode]);

  const isSubUpdate = useMemo(() => (
    isSubUpdateMode(subMode)
  ), [subMode]);

  const normalizeModeloLancamento = (data) => (
    cloneDeep({
      ...data,
      despesaReceita: data?.despesaReceita,
      moeda: data?.moeda,
      tipoCalculo: data?.tipoCalculo,
      valor: normalizeNumeral(data?.valor),
      tipo: data?.tipo,
    })
  );

  const unnormalizeModeloLancamento = (data) => ({
    ...data,
    despesaReceita: data?.despesaReceita,
    moeda: data?.moeda,
    tipoCalculo: data?.tipoCalculo,
    valor: isValid(data?.valor)
      ? forceUnnormalizeNumeral(
        data?.valor,
        formatBrazilianNumericDecimal(2)
      )
      : undefined,
    tipo: data?.tipo,
  });

  const handleNewModeloLancamento = () => {
    dispatch(changeToSubCreateMode());
  };

  const handleCancelModeloLancamentoCreate = () => {
    dispatch(resetSubMode());
  };

  const handleCancelModeloLancamentoUpdate = () => {
    dispatch(changeToSubConsultMode());
    dispatch(resetLockedModeloLancamentoModel());
  };

  const handleClearModeloLancamentoForm = () => {
    dispatch(resetLockedModeloLancamentoModel());
    dispatch(resetRelatedModeloLancamentoModel());
    dispatch(resetSubMode());
  };

  const handleRemoveModeloLancamento = () => {
    dispatch(removeModeloLancamentoById({ data: modeloLancamento }));
    handleClearModeloLancamentoForm();
  };

  const handleModelosLancamentoRowClick = ({ row }) => {
    if (!isSubUpdate && !isSubCreate && (modeloLancamento?.id !== row?.id)) {
      dispatch(setRelatedModeloLancamentoModel(row));
      dispatch(changeToSubConsultMode());
      dispatch(resetLockedModeloLancamentoModel());
    }
  };

  const handleEditModeloLancamento = () => {
    dispatch(changeToSubUpdateMode());
    dispatch(lockSelectedModeloLancamento());
  };

  const handleModeloLancamentoSubmit = (data) => {
    const normalizedData = normalizeModeloLancamento(data);

    if (isSubCreate) {
      dispatch(addModeloLancamento({ data: normalizedData }));
    } else if (isSubUpdate) {
      dispatch(updateModeloLancamento({ data: normalizedData }));
    }
  };

  const modeloLancamentoModel = useMemo(() => (
    unnormalizeModeloLancamento(modeloLancamento)
  ), [unnormalizeModeloLancamento, modeloLancamento]);

  const modeloLancamentoList = useMemo(() => (
    model?.modelosLancamento
  ), [model]);

  const checkIfIsAlternativeSaveByStep = (currentStep) => (
    currentStep === 1
  );

  const wizardSteps = [
    t('com.muralis.qcx.dadosTecnicos'),
    t('com.muralis.qcx.despesa.despesasReceitas'),
  ];

  return (
    <QCXFinalFormWizard
      steps={wizardSteps}
      initialValues={model}
      handleChangeValues={handleChangeModel}
      isCreate={isCreate}
      isConsult={isConsult}
      isUpdate={isUpdate}
      isBackgroundCreate={isBackgroundCreate}
      isSubNone={isSubNone}
      isSubCreate={isSubCreate}
      isSubConsult={isSubConsult}
      isSubUpdate={isSubUpdate}
      handleSubmit={handleSubmit}
      checkIfIsAlternativeSaveByStep={checkIfIsAlternativeSaveByStep}
      handleAlternativeSave={handleAlternativeSave}
      handleChangeToCreate={handleChangeToCreate}
      handleChangeToConsult={handleChangeToConsult}
      handleChangeToUpdate={handleChangeToUpdate}
      handleCancelUpdate={handleCancelUpdate}
      handleChangeToBackgroundCreate={handleChangeToBackgroundCreate}
      handleResetBackgroundMode={handleResetBackgroundMode}
      {...restProps}
    >
      {(formProps) => (
        <QCXModeloNumerarioTecnicalDataForm
          key="dados-tecnicos-modelo-numerario"
          isConsult={isConsult}
          isUpdate={isUpdate}
          authInfo={authInfo}
          requiredRoles={requiredRoles}
          {...formProps}
        />
      )}
      {({ controlComponentProps }) => (
        <QCXModeloLancamentoFinalFormManager
          key="modelo-lancamento-modelo-numerario"
          isConsult={isConsult}
          isUpdate={isUpdate}
          isSubNone={isSubNone}
          isSubCreate={isSubCreate}
          isSubConsult={isSubConsult}
          isSubUpdate={isSubUpdate}
          model={modeloLancamentoModel}
          list={modeloLancamentoList}
          handleSubmit={handleModeloLancamentoSubmit}
          handleNew={handleNewModeloLancamento}
          handleCancelCreate={handleCancelModeloLancamentoCreate}
          handleCancelUpdate={handleCancelModeloLancamentoUpdate}
          handleRemove={handleRemoveModeloLancamento}
          handleRowClick={handleModelosLancamentoRowClick}
          handleEdit={handleEditModeloLancamento}
          handleClearForm={handleClearModeloLancamentoForm}
          controlComponentProps={controlComponentProps}
          authInfo={authInfo}
          requiredRoles={requiredRoles}
        />
      )}

    </QCXFinalFormWizard>
  );
}
