import { isStrictEquals } from '../../utils/general/general-utils';

const NONE_META_TYPE = 'none';
const FATURA_DUE_META_TYPE = 'faturaDue';
const GENERATED_DUE_META_TYPE = 'generatedDue';

const isFaturaDueMetaType = (type) => (
  isStrictEquals(FATURA_DUE_META_TYPE, type)
);

const isGeneratedDueMetaType = (type) => (
  isStrictEquals(GENERATED_DUE_META_TYPE, type)
);

export {
  NONE_META_TYPE,
  FATURA_DUE_META_TYPE,
  GENERATED_DUE_META_TYPE,
  isFaturaDueMetaType,
  isGeneratedDueMetaType,
};
