import React from 'react';
import { ListItem, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    display: 'flex',
  },
}));

export default function QCXListItem({ children, hidden = false, ...restProps }) {
  const classes = useStyles();
  if (hidden) {
    return <></>;
  }
  return (
    <ListItem {...restProps}>
      <span className={classes.root}>
        {children}
      </span>
    </ListItem>
  );
}
