import { TipoDocumento } from 'quickcomex-api-types';
import { useEffect } from 'react';
import { Field, useForm } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { useDispatch, useSelector } from 'react-redux';
import { setErrorFeedback } from '../../../../../../features/feedback/feedbackSlice';
import { fetchAllAsync, selectTiposDocumentos } from '../../../../../../features/tipo-documento/tipoDocumentoSlice';
import FileDragAndDrop from '../../../../../../ts/common/components/fileDragAndDrop';
import { MediaType } from '../../../../../../utils/api/api-utils';

export type ProcessedFile = {
  file: File;
  tipoDocumento: TipoDocumento;
  processo: string;
};

function UploadDocumentosSection() {
  const dispatch = useDispatch();
  const tipoDocumentos = useSelector(selectTiposDocumentos);

  const form = useForm();

  useEffect(() => {
    dispatch(fetchAllAsync());
  }, []);

  return (
    <>
      <Field name="arquivos">{({ input }) => <FileDragAndDrop input={input} accept={MediaType.ALL} multiple />}</Field>
      <OnChange name="arquivos">
        {(listaArquivos) => {
          if (!listaArquivos || !listaArquivos.length) {
            return;
          }

          const listaArquivosArray: File[] = Array.from(listaArquivos);

          const arquivosProcessados: ProcessedFile[] = [];
          listaArquivosArray.forEach((arquivo: File) => {
            const match = arquivo.name.match(/^([^_]+)_([^.]+)\.[^.]+$/);
            let processo = '';
            let nomePadrao = '';
            let fullName = '';

            if (match) {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              [fullName, processo, nomePadrao] = match;
            } else {
              dispatch(
                setErrorFeedback({
                  message: `Arquivo ${arquivo.name} possui um nome no formato que não pode ser reconhecido.`,
                })
              );
              return; // subir mensagem de erro pro usuário
            }

            const tipoDocumento = tipoDocumentos.find(
              (td: { nomePadrao: string }) => td.nomePadrao.toUpperCase() === nomePadrao.toUpperCase()
            );
            if (tipoDocumento) {
              arquivosProcessados.push({
                file: arquivo,
                tipoDocumento,
                processo,
              });
            }
          });

          form.change('ignorableFields.arquivosProcessados', arquivosProcessados);
        }}
      </OnChange>
    </>
  );
}

export default UploadDocumentosSection;
