import _ from 'lodash';
import i18n from '../../i18n';

const DefaultErrorCode = {
  FILE_INVALID_TYPE: 'file-invalid-type',
  FILE_TOO_LARGE: 'file-too-large',
  FILE_TOO_SMALL: 'file-too-small',
  TOO_MANY_FILES: 'too-many-files',
};

const DEFAULT_ERROR_CODES = Object.values(DefaultErrorCode);

const validateByFileName = (files, newFile) => {
  const exists = files.some((existingFile) => (existingFile.name === newFile.name));

  if (exists) {
    return {
      code: 'file-exists-with-same-name',
      message: i18n.t('com.muralis.qcx.uploadArquivos.existeArquivoComMesmoNome'),
    };
  }

  return null;
};

const validateByFileType = (acceptTypes, file) => {
  const acceptMimeTypes = Object.keys(acceptTypes);
  const invalidType = !acceptMimeTypes.includes(file?.type);

  if (file?.name) {
    const acceptExtensions = Object.values(acceptTypes);
    const flatAcceptExtensions = acceptExtensions.reduce((previous, current) => (
      [...previous, ...current]
    ), []);

    const uniqueAcceptExtensions = _.uniqWith(flatAcceptExtensions, _.isEqual);
    const invalidExtension = uniqueAcceptExtensions.some((extension) => (
      !file?.name?.toLowerCase().endsWith(extension)
    ));

    if (invalidType || invalidExtension) {
      return {
        code: 'file-type-extension-not-match',
        message: i18n.t('com.muralis.qcx.uploadArquivos.tipoExtensaoArquivoNaoSuportado'),
      };
    }
  }

  if (invalidType) {
    return {
      code: 'file-type-not-match',
      message: i18n.t('com.muralis.qcx.uploadArquivos.tipoArquivoNaoSuportado'),
    };
  }

  return null;
};

const DropzoneUtils = Object.freeze({
  getDefaultErrorCodeObject: () => (
    DefaultErrorCode
  ),
  isNotDefaultFileInvalidType: (error) => (
    error?.code !== DefaultErrorCode.FILE_INVALID_TYPE
  ),
  isNotDefaultFileTooLarge: (error) => (
    error?.code !== DefaultErrorCode.FILE_TOO_LARGE
  ),
  isNotDefaultFileTooSmall: (error) => (
    error?.code !== DefaultErrorCode.FILE_TOO_SMALL
  ),
  isNotDefaultTooManyFiles: (error) => (
    error?.code !== DefaultErrorCode.FILE_INVALID_TYPE
  ),
  isNotDefaultError: (error) => (
    !DEFAULT_ERROR_CODES.includes(error?.code)
  ),
  validateByFileName,
  validateByFileType,
});

export {
  DropzoneUtils,
  DefaultErrorCode,
};
