import React from 'react';
import { Collapse } from '@material-ui/core';

export default function QCXList({ children, ...restProps }) {
  return (
    <Collapse {...restProps}>
      {children}
    </Collapse>
  );
}
