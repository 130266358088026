import { Box, CssBaseline, styled, ThemeProvider } from '@mui/material';
import theme from '../themes/theme';
import TopBar from '../components/topBar';
import LeftDrawer from '../components/leftDrawer/leftDrawer';
import { useAtom } from 'jotai';
import { isDrawerOpenAtom } from '../atoms/atoms';
import BreadCrumbs from '../components/breadCrumbs';
import PageTitle from '../components/pageTitle';

type BasicLayoutProps = {
  title: string;
  children: JSX.Element | JSX.Element[];
  icon: JSX.Element;
};

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  // padding: theme.spacing(1),
  paddingTop: theme.spacing(8),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${239}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

// The theme provider has temporarily been inserted here to
// allow using Material UI V5. This theme provider overrides
// the theme provider in the App.js file.
// When Material UI V5 is fully supported, this theme provider
// can be removed and the new theme applied to App.ts.

const BasicLayout = ({ children, title, icon }: BasicLayoutProps) => {
  const [isDrawerOpen, setIsDrawerOpen] = useAtom(isDrawerOpenAtom);
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <TopBar></TopBar>
        <LeftDrawer></LeftDrawer>
        <Main open={isDrawerOpen}>
          <PageTitle icon={icon} title={title}></PageTitle>
          <BreadCrumbs></BreadCrumbs>
          {children}
        </Main>
      </Box>
    </ThemeProvider>
  );
};

export default BasicLayout;
