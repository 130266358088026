import { Grid } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import QCXFinalForm from '../../shared-components/final-form/QCXFinalForm';
import QCXFinalTextField from '../../shared-components/final-text-field/QCXFinalTextField';
import { required as requiredValidator } from '../../utils/validators/field/validator';

export default function QCXBancoForm({
  handleSubmit,
  handleChangeToConsult,
  handleChangeToUpdate,
  handleCancelUpdate,
  isConsult,
  isUpdate,
  initialValues,
  ...restProps
}) {
  const { t } = useTranslation();

  return (
    <>
      <QCXFinalForm
        isConsult={isConsult}
        isUpdate={isUpdate}
        initialValues={initialValues}
        handleSubmitValues={handleSubmit}
        handleChangeToUpdate={handleChangeToUpdate}
        handleCancelUpdate={handleCancelUpdate}
        {...restProps}
      >
        {() => (
          <>
            <Grid item xs={12} sm={8} md={4}>
              <QCXFinalTextField
                id="text-field-codigo"
                key="text-field-codigo"
                name="code"
                label={t('com.muralis.qcx.codigo')}
                disabled={isConsult}
                required
                validate={requiredValidator}
                maxLength={3}
              />
            </Grid>

            <Grid item xs={12} sm={8} md={8}>
              <QCXFinalTextField
                id="text-field-descricao"
                key="text-field-descricao"
                name="description"
                label={t('com.muralis.qcx.descricao')}
                disabled={isConsult}
                required
                validate={requiredValidator}
              />
            </Grid>
          </>
        )}
      </QCXFinalForm>
    </>
  );
}
