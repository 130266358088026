import { useCallback, useEffect, useState } from 'react';
import { isFunction } from 'lodash';
import {
  CLOSED_STATUS,
  OPEN_STATUS,
  isDoneStatus,
  isFailStatus,
} from './formDialogUtils';

export default function useFormDialogSync(startUpFn, deps = []) {
  const [status, setStatus] = useState(CLOSED_STATUS);

  useEffect(() => {
    let unmounted = false;

    if (!unmounted) {
      if (isDoneStatus(status)) {
        setStatus(CLOSED_STATUS);
      }

      if (isFailStatus(status)) {
        setStatus(OPEN_STATUS);
      }
    }

    return () => {
      unmounted = true;
    };
  }, [status]);

  const composedCallback = useCallback((...args) => {
    if (isFunction(startUpFn)) {
      startUpFn.call(this, ...args);
    }

    setStatus(OPEN_STATUS);
  }, deps);

  return [
    composedCallback,
    status,
    setStatus,
  ];
}
