import { Grid } from '@material-ui/core';
import React from 'react';

export default function QCXTabControlButtons({
  tab,
  renderTopControls,
  renderBottomControls,
}) {
  return (
    <>
      <Grid
        item
        container
        justify="flex-end"
        alignItems="center"
        xs={12}
        sm={2}
        md={2}
      >
        {renderTopControls({
          activeTab: tab?.active,
        })}
      </Grid>
      <Grid
        item
        container
        justify="flex-end"
        alignItems="center"
        xs={12}
        sm={12}
        md={12}
      >
        {renderBottomControls({
          activeTab: tab?.active,
        })}
      </Grid>
    </>
  );
}
