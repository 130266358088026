const NAO_INICIADA = 'NAO_INICIADA';
const EM_ANDAMENTO = 'EM_ANDAMENTO';
const CONCLUIDA = 'CONCLUIDA';
const ATRASADA = 'ATRASADA';

const isStatusNaoIniciada = (value) => (
  NAO_INICIADA === value
);
const isStatusEmAndamento = (value) => (
  EM_ANDAMENTO === value
);
const isStatusConcluida = (value) => (
  CONCLUIDA === value
);
const isStatusAtrasada = (value) => (
  ATRASADA === value
);

const EtapaFollowUpUtils = {
  NAO_INICIADA,
  EM_ANDAMENTO,
  CONCLUIDA,
  ATRASADA,
  isStatusNaoIniciada,
  isStatusEmAndamento,
  isStatusConcluida,
  isStatusAtrasada,
};

export default EtapaFollowUpUtils;
