import { Grid } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, useFormState } from 'react-final-form';
import QCXFinalAlternativeCurrencyField from '../final-currency-field/QCXFinalAlternativeCurrencyField';
import QCXSelectMoedaAutocomplete from '../final-select-moeda-field/QCXSelectMoedaAutocomplete';

export default function QCXMoedaMleFormGroup({
  isConsult,
  handleValorNaMoedaOnBlur,
  handleMoedaMLEDUEOnBlur,
  valorValidators,
  submitSucceeded,
  intl,
  required = true,
}) {
  const { t } = useTranslation();

  const form = useForm();
  const { values } = useFormState();
  const tipo = values?.atributosAdicionais?.tipo;
  return (
    <>
      <Grid item xs={12} sm={12} md={4} lg={4}>
        <QCXSelectMoedaAutocomplete
          id="select-field-moeda-vmle"
          key="select-field-moeda-vmle"
          name="mle.moeda.id"
          label={t('com.muralis.qcx.fatura.moedaMLE')}
          onBlur={tipo === 'DUE' && handleMoedaMLEDUEOnBlur(form, values)}
          disabled={isConsult}
          initialValues={values}
          required={required}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4}>
        <QCXFinalAlternativeCurrencyField
          id="currency-valor-moeda-vmle-field"
          key="currency-valor-moeda-vmle-field"
          name="mle.valorMoeda"
          label={t('com.muralis.qcx.moeda.valorMoedaMLE')}
          validate={valorValidators}
          onBlur={handleValorNaMoedaOnBlur(form, values, 'mle.moeda', 'mle.valorMoeda', 'mle.valorReal')}
          disabled={isConsult || !values?.mle?.moeda?.id}
          submitSucceeded={submitSucceeded}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4}>
        <QCXFinalAlternativeCurrencyField
          id="currency-valor-real-moeda-vmle-field"
          key="currency-valor-real-moeda-vmle-field"
          name="mle.valorReal"
          label={t('com.muralis.qcx.moeda.valorMoedaMLEReais')}
          intl={intl}
          adorned
          disabled
          submitSucceeded={submitSucceeded}
          helperText={!(isConsult) && t('com.muralis.qcx.mensagem.arredondamentoValor')}
        />
      </Grid>
    </>
  );
}
