import React, { useMemo } from 'react';
import BaseSelectWithOptions from '../base-components/BaseSelectWithOptions';

export function convertTipoContainerValueToLabel(value) {
  switch (value) {
    case 'VINTE_PES':
      return '20`';
    case 'QUARENTA_PES':
      return '40`';
    case 'HC_40_PES':
      return 'HC 40`';
    case 'FR_20_PES':
      return 'FR 20`';
    case 'FR_40_PES':
      return 'FR 40`';
    case 'TANQUE':
      return 'Tanque';
    case 'OT_20_PES':
      return 'OT 20`';
    case 'OT_40_PES':
      return 'OT 40`';
    case 'PF_20_PES':
      return 'PF 20`';
    case 'PF_40_PES':
      return 'PF 40`';
    case 'RF_20_PES':
      return 'RF 20`';
    case 'RF_40_PES':
      return 'RF 40`';
    case undefined:
      return '';
    default:
      throw new Error('Valor não mapeado!');
  }
}

function TipoContainerSelect({ ...props }) {
  const list = useMemo(() => [
    {
      id: 'VINTE_PES',
      label: '20`',
      value: 'VINTE_PES',
    },
    {
      id: 'QUARENTA_PES',
      label: '40`',
      value: 'QUARENTA_PES',
    },
    {
      id: 'HC_40_PES',
      label: 'HC 40`',
      value: 'HC_40_PES',
    },
    {
      id: 'FR_20_PES',
      label: 'FR 20`',
      value: 'FR_20_PES',
    },
    {
      id: 'FR_40_PES',
      label: 'FR 40`',
      value: 'FR_40_PES',
    },
    {
      id: 'TANQUE',
      label: 'Tanque',
      value: 'TANQUE',
    },
    {
      id: 'OT_20_PES',
      label: 'OT 20`',
      value: 'OT_20_PES',
    },
    {
      id: 'OT_40_PES',
      label: 'OT 40`',
      value: 'OT_40_PES',
    },
    {
      id: 'PF_20_PES',
      label: 'PF 20`',
      value: 'PF_20_PES',
    },
    {
      id: 'PF_40_PES',
      label: 'PF 40`',
      value: 'PF_40_PES',
    },
    {
      id: 'RF_20_PES',
      label: 'RF 20`',
      value: 'RF_20_PES',
    },
    {
      id: 'RF_40_PES',
      label: 'RF 40`',
      value: 'RF_40_PES',
    }
  ], []);

  return (
    <BaseSelectWithOptions
      list={list}
      getOptionValue={(option) => option.value}
      {...props}
    />
  );
}

export default TipoContainerSelect;
