import { IconButton } from '@material-ui/core';
import {
  Delete as DeleteIcon,
  Search as PageviewIcon,
  ViewList as ViewListIcon,
} from '@material-ui/icons';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { userHasDeleteRole } from '../../../../../components/perfil/perfil-utils';
import ModeloNumerarioContext from '../../../../../contexts/registrations/modelo-numerario/ModeloNumerarioContext';
import {
  activateByIdAsync,
  changeToCreateMode,
  fetchAllAsync,
  inactivateByIdAsync,
  resetBackgroundMode,
  resetMode,
  resetModel,
  resetRelatedModeloLancamentoModel,
  resetSubMode,
  selectModelosNumerario,
} from '../../../../../features/modelo-numerario/modeloNumerarioSlice';
import QCXSimpleConsultPageTemplate from '../../../../../templates/simple-consult-page/QCXSimpleConsultPageTemplate';
import useOperationController from '../../../../../utils/hooks/store/operation/useOperationController';
import { isAlternativeLoadingStatus } from '../../../../../utils/store/store-utils';

export default function ModeloNumerarioConsultPage({ authInfo = {} }) {
  const { t } = useTranslation();

  const history = useHistory();

  const {
    status,
  } = useContext(ModeloNumerarioContext);

  const dispatch = useDispatch();

  const modelosNumerarios = useSelector(selectModelosNumerario);

  const isAlternativeLoading = useMemo(() => (
    isAlternativeLoadingStatus(status)
  ), [status]);

  const fetchAll = async () => {
    dispatch(fetchAllAsync());
  };

  const resetModes = useCallback(() => {
    dispatch(resetMode());
    dispatch(resetBackgroundMode());
    dispatch(resetSubMode());
  }, []);

  const resetModels = useCallback(() => {
    dispatch(resetModel());
    dispatch(resetRelatedModeloLancamentoModel());
  }, []);

  useEffect(() => {
    fetchAll();
    resetModes();
    resetModels();
  }, []);

  const handleActivateInactivate = useOperationController(
    async (event, id) => {
      event.preventDefault();

      const { active } = modelosNumerarios.find(
        (item) => item.id === id
      );

      const isActivation = !active;

      if (isActivation) {
        dispatch(activateByIdAsync(id));
      } else {
        dispatch(inactivateByIdAsync(id));
      }
    }
  );

  const handleClickOnButtonNew = useCallback((event) => {
    event.stopPropagation();
    dispatch(changeToCreateMode());
  }, []);

  const handleClickOnEditButton = useCallback(
    (_event, id) => (
      history.push(
        t('com.muralis.qcx.url.moduloGeraisServicosModeloNumerarioDetalhes', { id })
      )
    ), [history]
  );
  const breadcrumbs = [
    {
      link: {
        to: '/',
        name: t('com.muralis.qcx.inicio'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.cadastros'),
      },
    },
    {
      link: {
        to: t('com.muralis.qcx.url.moduloCadastrosGerais'),
        name: t('com.muralis.qcx.gerais'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.servico.labelPlural'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.numerario.modeloNumerario'),
      },
    },
  ];

  const columns = [
    {
      field: 'id',
      headerName: t('com.muralis.qcx.codigo'),
      headerAlign: 'center',
      align: 'center',
      type: 'string',
      flex: 100,
      valueGetter: ({ row }) => (
        row?.id
      ),
    },
    {
      field: 'description',
      headerName: t('com.muralis.qcx.descricao'),
      flex: 250,
      valueGetter: ({ row }) => (
        row?.description
      ),
    },
    {
      field: 'viaTransporte',
      headerName: t('com.muralis.qcx.transporte.viaTransporte'),
      flex: 150,
      valueGetter: ({ row }) => (
        row?.viaTransporte?.description
      ),
    },
    {
      field: 'etapa',
      headerName: t('com.muralis.qcx.etapa.label'),
      flex: 150,
      valueGetter: ({ row }) => (
        row?.etapa?.description
      ),
    },
    {
      field: 'actions',
      headerName: t('com.muralis.qcx.acoes.label'),
      headerAlign: 'center',
      align: 'center',
      flex: 150,
      renderCell: ({ row: { id, active } }) => (
        <>
          {userHasDeleteRole(authInfo, 'modelo-numerario') && (
          <IconButton
            key={`stw-active${id}`}
            value={active}
            name={`stw-active-${id}`}
            onClick={(event) => handleActivateInactivate(event, id)}
          >
            <DeleteIcon color="error" />
          </IconButton>
          )}
          <IconButton
            key={`btn-view-${id}`}
            name={`btn-view-${id}`}
            onClick={(event) => handleClickOnEditButton(event, id)}
          >
            <PageviewIcon color="primary" />
          </IconButton>
        </>
      ),
    }
  ];

  const templateProps = {
    page: {
      title: t('com.muralis.qcx.modelosNumerario'),
      icon: <ViewListIcon />,
      breadcrumbs,
    },
    control: {
      buttonNew: {
        description: t('com.muralis.qcx.numerario.novoModeloNumerario'),
        path: t('com.muralis.qcx.url.moduloGeraisServicosModeloNumerarioNovo'),
        onClick: handleClickOnButtonNew,
      },
    },
    table: {
      columns,
    },
  };

  const filterPropGetter = useCallback(
    (item) => item.description,
    []
  );

  const filterProps = [
    'id',
    'description',
  ];

  return (
    <QCXSimpleConsultPageTemplate
      pageProps={templateProps.page}
      controlProps={templateProps.control}
      tableProps={templateProps.table}
      tableData={modelosNumerarios}
      isLoading={isAlternativeLoading}
      filterPropGetter={filterPropGetter}
      multipleFilter
      filterProps={filterProps}
      authInfo={authInfo}
      requiredRoles={['modelo-numerario']}
    />
  );
}
