import React from 'react';
import { Stack, StackProps, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { styled } from '@mui/material/styles';

interface ModalResponseStatusProps extends StackProps {
  status: boolean;
  name: string;
  message: string;
}

const StyledStack = styled(Stack)<{ status: boolean }>(({ theme, status }) => ({
  backgroundColor: status ? theme.palette.success.light : theme.palette.error.light,
  color: status ? theme.palette.success.main : theme.palette.error.main,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1),
  alignItems: 'center',
}));

const ModalResponseStatus: React.FC<ModalResponseStatusProps> = (props) => {
  const { status, name, message, ...stackProps } = props;

  return (
    <StyledStack direction="row" spacing={2} status={status} {...stackProps}>
      {status ? <CheckIcon color="error" /> : <CloseIcon color="error" />}
      <Typography fontWeight="bold" color={'white'}>
        {name}
      </Typography>
      <Typography color={'white'}>{message}</Typography>
    </StyledStack>
  );
};

export default ModalResponseStatus;
