import { Grid, Typography } from '@material-ui/core';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { cidadeActions } from '../../features/cidade/cidadeSlice';
import { AUTO_LOAD_CONTROL } from '../../features/config-control';
import { estadoActions } from '../../features/estado/estadoSlice';
import { paisActions } from '../../features/pais/paisSlice';
import { fetchByFilterAsync as filterSetorLotacao } from '../../features/setor-lotacao/setorLotacaoSlice';
import { setList as SetRecintoAduaneiroList } from '../../features/recinto-aduaneiro/recintoAduaneiroSlice';
import QCXBox from '../../shared-components/box/QCXBox';
import QCXFinalCheckboxField from '../../shared-components/final-checkbox-field/QCXFinalCheckboxField';
import QCXFinalTextField from '../../shared-components/final-text-field/QCXFinalTextField';
import QCXFormStepWizard from '../../shared-components/form-step-wizard/QCXFormStepWizard';
import QCXSelectCidadeAutocomplete from '../../shared-components/select-cidade/QCXSelectCidadeAutocomplete';
import QCXSelectEstadoAutocomplete from '../../shared-components/select-estado/QCXSelectEstadoAutocomplete';
import QCXSelectPaisAutocomplete from '../../shared-components/select-pais/QCXSelectPaisAutocomplete';
import QCXSelectRecintoAduaneiroAutocomplete from '../../shared-components/select-recinto-aduaneiro/QCXSelectRecintoAduaneiroAutocomplete';
import QCXSelectSetorLotacaoAutocomplete from '../../shared-components/select-setor-lotacao/QCXSelectSetorLotacaoAutocomplete';
import QCXSelectUnidadeLocalRFBAutocomplete from '../../shared-components/select-unidade-local-rfb/QCXSelectUnidadeLocalRFBAutocomplete';
import {
  required as requiredValidator,
  validPostalCode,
  validCep,
} from '../../utils/validators/field/validator';
import { selectUnidadesLocaisRfb } from '../../features/unidade-local-rfb/unidadeLocalRfbSlice';
import QCXFinalOnblurMaskedField from '../../shared-components/final-onblur-masked-field/QCXFinalOnblurMaskedField';
import { formatCep } from '../../utils/hooks/form/field/formatters';
import { parseToOnlyNumbersWithMaxDigits } from '../../utils/hooks/form/field/parsers';

export default function QCXArmazemTecnicalDataForm({
  initialValues,
  handleSubmit,
  isConsult,
  isUpdate,
  controlComponentProps,
  authInfo = {},
  requiredRoles = [],
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const unidadesLocaisRfb = useSelector(selectUnidadesLocaisRfb);
  dispatch(paisActions.changeControlTo(AUTO_LOAD_CONTROL));

  const unidadeLocalRfbOnchange = useCallback((form, values) => (value) => {
    if (value) {
      const unidadeLocalRfbSelected = unidadesLocaisRfb?.find(
        (item) => item?.id === values?.unidadeLocalRfb?.id
      );
      form.reset({
        ...values,
        setorLotacao: { id: isConsult ? initialValues?.setorLotacao?.id : '' },
        recintoAduaneiro: { id: isConsult ? initialValues?.recintoAduaneiro?.id : '' },
        unidadeLocalRfb: { id: value },
      });
      dispatch(filterSetorLotacao([{ name: 'unidadeLocalRfb', value }]));
      const recintosAduaneiros = (
        isConsult
          ? initialValues?.unidadeLocalRfb?.recintosAduaneiro
          : unidadeLocalRfbSelected?.recintosAduaneiro
      );
      dispatch(SetRecintoAduaneiroList({ data: recintosAduaneiros }));
    }
  }, [unidadesLocaisRfb, initialValues, isConsult]);

  const isBrasilSelected = (value) => (
    value === 1
  );

  const isBrasilSelectedBy = (values) => (
    isBrasilSelected(values?.endereco.cidade.estado.pais.id)
  );

  const paisOnchange = (form, values) => (value) => {
    const isCurrentBrazilSelected = isBrasilSelected(value);
    const postalCode = values?.endereco?.cep;
    const isEmptyPostaCode = !postalCode;

    form.change('endereco.cidade.id', undefined);
    form.change('endereco.cidade.estado.id', undefined);
    form.change('endereco.cidade.estado.pais.id', value);
    dispatch(estadoActions.fetchByFilterAsync([{ name: 'pais', value }]));
    form.resetFieldState('endereco.cep');

    if (isEmptyPostaCode) {
      return;
    }

    const postalCodeString = String(postalCode);
    if (!isCurrentBrazilSelected) {
      const sanitizedPostalCode = postalCodeString.replace('-', '');
      form.change('endereco.cep', sanitizedPostalCode);
      return;
    }

    if (postalCodeString.length <= 9) {
      form.change('endereco.cep', formatCep(postalCodeString));
      return;
    }

    const ajustedPostalCode = postalCodeString.slice(0, 8);
    form.change('endereco.cep', formatCep(ajustedPostalCode));
  };

  const estadoOnchange = (form) => (value) => {
    form.change('endereco.cidade.id', undefined);
    form.change('endereco.cidade.estado.id', value);
    dispatch(cidadeActions.fetchByFilterAsync([{ name: 'estado', value }]));
  };

  const cidadeOnchange = (form) => (value) => {
    form.change('endereco.cidade.id', value);
  };
  const listenerList = [
    { name: 'unidadeLocalRfb.id', fn: unidadeLocalRfbOnchange },
    { name: 'endereco.cidade.estado.pais.id', fn: paisOnchange },
    { name: 'endereco.cidade.estado.id', fn: estadoOnchange },
    { name: 'endereco.cidade.id', fn: cidadeOnchange }
  ];

  return (
    <QCXFormStepWizard
      initialValues={initialValues}
      onSubmit={handleSubmit}
      controlComponentProps={controlComponentProps}
      onChangeListeners={listenerList}
      authInfo={authInfo}
      requiredRoles={requiredRoles}
    >
      {({ values }) => (
        <>
          <Typography variant="h6" color="primary">
            <QCXBox fontWeight="fontWeightBold" pt={3}>
              {t('com.muralis.qcx.dadosTecnicos').toUpperCase()}
            </QCXBox>
          </Typography>
          <Grid container spacing={2} style={{ marginTop: '1rem' }}>
            {(isConsult || isUpdate) && (
              <Grid item xs={12} sm={6} md={2}>
                <QCXFinalTextField
                  id="text-field-code"
                  key="text-field-code"
                  name="id"
                  label={t('com.muralis.qcx.codigo')}
                  disabled
                />
              </Grid>
            )}
            <Grid item xs={12} sm={8} md={5}>
              <QCXFinalTextField
                name="pessoa.nome"
                label={t('com.muralis.qcx.empresa.razaoSocial')}
                disabled={isConsult}
                maxLength={50}
                validate={requiredValidator}
                required
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              md={isConsult || isUpdate ? 5 : 4}
            >
              <QCXFinalTextField
                name="pessoa.nomeResumido"
                label={t('com.muralis.qcx.nomeResumido')}
                maxLength={30}
                validate={requiredValidator}
                disabled={isConsult}
                required
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={3}
              md={isConsult || isUpdate ? 4 : 3}
            >
              <QCXSelectPaisAutocomplete
                name="endereco.cidade.estado.pais.id"
                label={t('com.muralis.qcx.endereco.pais')}
                fieldProps={{ validate: requiredValidator }}
                disabled={isConsult}
                required
                initialValues={values}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <QCXSelectEstadoAutocomplete
                name="endereco.cidade.estado.id"
                label={t('com.muralis.qcx.endereco.estado')}
                fieldProps={{ validate: requiredValidator }}
                disabled={isConsult}
                required
                initialValues={values}
              />
            </Grid>
            <Grid item xs={12} sm={5} md={4}>
              <QCXSelectCidadeAutocomplete
                name="endereco.cidade.id"
                label={t('com.muralis.qcx.endereco.cidade')}
                fieldProps={{ validate: requiredValidator }}
                initialValues={values}
                disabled={isConsult}
                required
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              md={isConsult || isUpdate ? 2 : 4}
            >
              <QCXFinalOnblurMaskedField
                id="text-field-endereco-cep"
                key="text-field-endereco-cep"
                name="endereco.cep"
                label={isBrasilSelectedBy(values) ? t('com.muralis.qcx.endereco.CEP') : t('com.muralis.qcx.endereco.codigoPostal')}
                {...(isBrasilSelectedBy(values)
                  ? {
                    format: formatCep,
                    parse: parseToOnlyNumbersWithMaxDigits(8),
                    validate: validCep(8, 9),
                    maxLength: 9,
                  } : {
                    format: (value) => value,
                    parse: parseToOnlyNumbersWithMaxDigits(10),
                    validate: validPostalCode(5, 10),
                    maxLength: 10,
                  }
                  )}
                disabled={isConsult}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={5}
              md={isConsult || isUpdate ? 4 : 5}
            >
              <QCXFinalTextField
                name="endereco.logradouro"
                label={t('com.muralis.qcx.endereco.logradouro')}
                validate={requiredValidator}
                disabled={isConsult}
                required
              />
            </Grid>
            <Grid item xs={12} sm={3} md={2}>
              <QCXFinalTextField
                name="endereco.numero"
                label={t('com.muralis.qcx.endereco.numero')}
                validate={requiredValidator}
                disabled={isConsult}
                required
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={5}
              md={isConsult || isUpdate ? 4 : 5}
            >
              <QCXFinalTextField
                name="endereco.complemento"
                label={t('com.muralis.qcx.endereco.complemento')}
                disabled={isConsult}
              />
            </Grid>
            <Grid item xs={12} sm={7} md={4}>
              <QCXFinalTextField
                name="endereco.bairro"
                label={t('com.muralis.qcx.endereco.bairro')}
                validate={requiredValidator}
                disabled={isConsult}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <QCXFinalTextField
                id="text-field-pessoa-inscricao-estadual"
                key="text-field-pessoa-inscricao-estadual"
                name="pessoa.inscricaoEstadual"
                label={t('com.muralis.qcx.empresa.inscricaoEstadual')}
                disabled={isConsult}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <QCXFinalTextField
                id="text-field-pessoa-inscricao-municipal"
                key="text-field-pessoa-inscricao-municipal"
                name="pessoa.inscricaoMunicipal"
                label={t('com.muralis.qcx.empresa.inscricaoMunicipal')}
                disabled={isConsult}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <QCXSelectUnidadeLocalRFBAutocomplete
                name="unidadeLocalRfb.id"
                label={t('com.muralis.qcx.unidadeLocalURF')}
                fieldProps={{ validate: requiredValidator }}
                initialValues={values}
                disabled={isConsult}
                required
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <QCXSelectRecintoAduaneiroAutocomplete
                name="recintoAduaneiro.id"
                label={t('com.muralis.qcx.recintoAduaneiro')}
                fieldProps={{ validate: requiredValidator }}
                initialValues={values}
                disabled={isConsult}
                required
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <QCXSelectSetorLotacaoAutocomplete
                name="setorLotacao.id"
                label={t('com.muralis.qcx.setorLotacao')}
                fieldProps={{ validate: requiredValidator }}
                initialValues={values}
                disabled={isConsult}
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} />
            <Grid item xs={12} sm={4} md={3}>
              <QCXFinalCheckboxField
                name="importador"
                label={t('com.muralis.qcx.importador.label')}
                disabled={isConsult}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <QCXFinalCheckboxField
                name="exportador"
                label={t('com.muralis.qcx.exportador.label')}
                disabled={isConsult}
              />
            </Grid>
          </Grid>
        </>
      )}
    </QCXFormStepWizard>
  );
}
