import React, { useEffect } from 'react';

export default function QCXFaturaForceTabReload({
  dataId = 'tabs-manager-tab-1',
  defaultTimeout = 1000,
}) {
  useEffect(() => {
    setTimeout(() => {
      document.querySelector(`button[data-id="${dataId}"]`).click();
    }, defaultTimeout);
  }, []);

  return (<></>);
}
