import { Grid } from '@material-ui/core';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormState } from 'react-final-form';
import { omit, get, isEmpty } from 'lodash';
import QCXFinalTextField from '../../shared-components/final-text-field/QCXFinalTextField';
import QCXSelectEstadoAutocomplete from '../../shared-components/select-estado/QCXSelectEstadoAutocomplete';
import QCXFinalDatePickerField from '../../shared-components/final-date-picker-field/QCXFinalDatePickerField';
import QCXFinalOnblurMaskedField from '../../shared-components/final-onblur-masked-field/QCXFinalOnblurMaskedField';
import QCXSelectModeloNotaFiscalAutocomplete from '../../shared-components/select-modelo-nota-fiscal/QCXSelectModeloNotaFiscalAutocomplete';
import QCXFormSubtitle from '../form-title/QCXFormSubtitle';
import { formatCpfOrCnpj } from '../../utils/hooks/form/field/formatters';
import { parseCpfOrCnpj } from '../../utils/hooks/form/field/parsers';

export default function QCXDadosNotaFiscalFormGroup({
  fieldProps = {
    notaFiscal: {
      uf: {
        id: 'select-field-uf',
        key: 'select-field-uf',
        name: 'due.notaFiscal.estado.id',
        options: {},
      },
      data: {
        id: 'date-picker-date-emissao',
        key: 'date-picker-date-emissao',
        name: 'due.notaFiscal.data',
        options: {},
      },
      cpfCnpjEmitente: {
        id: 'masked-text-cpf-cnpj-emitente-field',
        key: 'masked-text-cpf-cnpj-emitente-field',
        name: 'due.notaFiscal.cpfCnpjEmitente',
        options: {},
      },
      modeloNotaFiscal: {
        id: 'select-field-modelo-nota-fiscal',
        key: 'select-field-modelo-nota-fiscal',
        name: 'due.notaFiscal.modeloNotaFiscal.id',
        options: {},
      },
      serie: {
        id: 'text-field-serie',
        key: 'text-field-serie',
        name: 'due.notaFiscal.serie',
        options: {},
      },
      numero: {
        id: 'text-field-numero-nota-fiscal',
        key: 'text-field-numero-nota-fiscal',
        name: 'due.notaFiscal.numero',
        options: {},
      },
    },
  },
  disabled,
}) {
  const { t } = useTranslation();
  const { values } = useFormState();

  const omitProps = useCallback((props, names) => (
    omit(
      props,
      names
    )
  ), []);

  const buildFieldProps = useCallback((notaFiscalPropPath) => {
    const notaFiscalProp = get(
      fieldProps,
      notaFiscalPropPath
    );

    const notaFiscalPropOptionless = omitProps(
      notaFiscalProp,
      ['options']
    );

    if (isEmpty(notaFiscalProp?.options)) {
      return notaFiscalPropOptionless;
    }

    return {
      ...notaFiscalPropOptionless,
      ...notaFiscalProp?.options,
    };
  }, [fieldProps, omitProps]);

  return (
    <>
      <Grid item xs={12}>
        <QCXFormSubtitle
          title={t('com.muralis.qcx.dadosNotaFiscal')}
          boxProps={{
            pt: 1,
            pb: 1,
          }}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        lg={6}
      >
        <QCXSelectEstadoAutocomplete
          {...buildFieldProps('notaFiscal.uf')}
          label={t('com.muralis.qcx.endereco.uf')}
          disabled={disabled}
          initialValues={values}
          getOptionLabel={(item) => (
            item?.code
              ? item?.code
              : ''
          )}
          filter={{
            options: {
              stringify: (item) => item?.code,
            },
          }}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        lg={6}
      >
        <QCXFinalDatePickerField
          {...buildFieldProps('notaFiscal.data')}
          label={t('com.muralis.qcx.dataEmissao')}
          disabled={disabled}
          disableFuture
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        lg={6}
      >
        <QCXFinalOnblurMaskedField
          {...buildFieldProps('notaFiscal.cpfCnpjEmitente')}
          label={t('com.muralis.qcx.CPFCNPJEmitente')}
          format={formatCpfOrCnpj}
          parse={parseCpfOrCnpj}
          disabled={disabled}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        lg={6}
      >
        <QCXSelectModeloNotaFiscalAutocomplete
          {...buildFieldProps('notaFiscal.modeloNotaFiscal')}
          label={t('com.muralis.qcx.modeloNotaFiscal')}
          initialValues={values}
          disabled={disabled}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        lg={6}
      >
        <QCXFinalTextField
          {...buildFieldProps('notaFiscal.serie')}
          label={t('com.muralis.qcx.DANFE.serie')}
          disabled={disabled}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        lg={6}
      >
        <QCXFinalTextField
          {...buildFieldProps('notaFiscal.numero')}
          label={t('com.muralis.qcx.numeroNotaFiscal')}
          disabled={disabled}
        />
      </Grid>
    </>
  );
}
