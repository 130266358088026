import React from 'react';
import QCXSnackbar from './QCXSnackbar';

export default function QCXSuccessSnackbar({
  open, content, handleClose, autoHideDuration = 4000, ...restProps
}) {
  return (
    <QCXSnackbar
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
      content={content}
      severity="success"
      {...restProps}
    />
  );
}
