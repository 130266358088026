import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import PageLayout from "../../../common/layouts/pageLayout";
import { GridCellParams, GridColDef } from "@mui/x-data-grid";
import DataTable, {
  DataTableExtraAction,
} from "../../../common/components/dataTable";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import TimelineIcon from "@mui/icons-material/Timeline";
import { GridActionsCellItem } from "@mui/x-data-grid";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useMemo } from "react";
import { useHistory } from "react-router-dom";
import LoadingIndicator from "../../../common/components/loadingIndicator";
import InventoryIcon from "@mui/icons-material/Inventory";
import BuildIcon from "@mui/icons-material/Build";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";
import CollectionsBookmark from "@mui/icons-material/CollectionsBookmark";
import { useUnidadeNegocioGuard } from "../../../common/hooks/useUnidadeNegocioGuard";
import { useDeleteProposta, usePropostas } from "./propostas.hooks";
import { formatDateISOString } from "../../../common/utils/parsers/dateParsers";

const PropostasPage = () => {
  useUnidadeNegocioGuard();
  const history = useHistory();
  const [propostas, isLoading, isError] = usePropostas();
  const [deleteProposta] = useDeleteProposta();

  const handlePropostaDeletion = (id: number) => {
    deleteProposta({ id });
  };

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "cliente",
        headerName: "Cliente",
        flex: 1,
        headerClassName: "header-theme",
      },
      {
        field: "data",
        headerName: "Data da Proposta",
        flex: 1,
        headerClassName: "header-theme",
        type: "string",
      },
      {
        field: "followup",
        headerName: "Último Follow-Up",
        flex: 1,
        headerClassName: "header-theme",
        type: "string",
      },
      {
        field: "actions",
        type: "actions",
        headerName: "Ações",
        headerClassName: "header-theme",
        // @ts-ignore
        getActions: (params: any) => [
          <GridActionsCellItem
            icon={<SearchIcon />}
            label="Consultar"
            onClick={() => handleDetailsNavigation(params.row.id)}
            showInMenu
          />,
          <GridActionsCellItem
            icon={<MoreHorizIcon />}
            label="Follow-Ups"
            onClick={() =>
              history.push(`/comercial/propostas/${params.row.id}/follow-ups`)
            }
            showInMenu
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Deletar"
            onClick={() => handlePropostaDeletion(params.row.id)}
            showInMenu
          />,
        ],
      },
    ],
    []
  );

  const extraActions: DataTableExtraAction[] = useMemo(
    () => [
      {
        label: "Solicitar Proposta",
        color: "secondary",
        onClick: () => history.push("/comercial/propostas/nova"),
        icon: AddCircleIcon,
      },
      {
        label: "Índices",
        color: "primary",
        onClick: () => history.push(`/comercial/propostas/indices`),
        icon: TimelineIcon,
      },
      {
        label: "Mercadorias",
        color: "primary",
        onClick: () => history.push(`/comercial/propostas/mercadorias`),
        icon: InventoryIcon,
      },
      {
        label: "Cadastros",
        color: "primary",
        onClick: () => history.push(`/comercial/propostas/cadastros`),
        icon: CollectionsBookmark,
      },
      {
        label: "Serviços",
        color: "primary",
        onClick: () => history.push(`/comercial/propostas/servicos`),
        icon: BuildIcon,
      },
    ],
    []
  );

  const handleDetailsNavigation = (id: number) => {
    history.push(`/comercial/propostas/${id}`);
  };

  return (
    <PageLayout
      title={"Propostas"}
      icon={<HistoryEduIcon color={"secondary"} />}
    >
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <DataTable
          onCellDoubleClick={(params) => handleDetailsNavigation(params.row.id)}
          columns={columns}
          rows={
            propostas
              ? propostas.map((proposta, index) => ({
                  id: proposta.id,
                  cliente: proposta.nomeCliente,
                  data: formatDateISOString(proposta.createdAt, "DD/MM/YYYY"),
                  followup: proposta.ultimoFollowUp,
                }))
              : []
          }
          extraActions={extraActions}
        ></DataTable>
      )}
    </PageLayout>
  );
};

export default PropostasPage;
