import React, { useCallback, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectFeedback,
  resetFeedback,
} from '../../features/feedback/feedbackSlice';
import QCXProgressBackdrop from '../../shared-components/backdrop/QCXProgressBackdrop';
import QCXModeContextProvider from '../../shared-components/context-provider/QCXModeContextProvider';
import QCXErrorSnackbar from '../../shared-components/snackbar/QCXErrorSnackbar';
import QCXSnackbar from '../../shared-components/snackbar/QCXSnackbar';
import QCXSuccessSnackbar from '../../shared-components/snackbar/QCXSuccessSnackbar';

export default function QCXPageTemplate({
  contextComponent,
  modeSelectors = {},
  children,
}) {
  const dispatch = useDispatch();

  const {
    isLoading,
    successSnackbar,
    errorSnackbar,
    handleCloseSuccessSnackbar,
    handleCloseErrorSnackbar,
  } = useContext(contextComponent);

  const feedback = useSelector(selectFeedback);

  const handleCloseSnackbar = useCallback(() => {
    if (feedback?.show) {
      dispatch(resetFeedback());
    }
  }, [feedback]);

  return (
    <QCXModeContextProvider
      value={modeSelectors}
    >
      {children}
      {
        successSnackbar?.open && (
          <QCXSuccessSnackbar
            open={successSnackbar?.open}
            content={successSnackbar?.message}
            handleClose={handleCloseSuccessSnackbar}
            autoHideDuration={successSnackbar?.autoHideDuration || 5000}
            {...successSnackbar}
          />
        )
      }
      {
        errorSnackbar?.open && (
          <QCXErrorSnackbar
            open={errorSnackbar?.open}
            content={errorSnackbar?.message}
            handleClose={handleCloseErrorSnackbar}
            autoHideDuration={errorSnackbar?.autoHideDuration || 5000}
            {...errorSnackbar}
          />
        )
      }
      {
        feedback?.show && (
          <QCXSnackbar
            open={feedback?.show}
            severity={feedback?.severity}
            content={feedback?.message}
            onClose={handleCloseSnackbar}
            {...feedback}
          />
        )
      }
      <QCXProgressBackdrop
        open={isLoading}
      />
    </QCXModeContextProvider>
  );
}
