import { useReducer } from 'react';

enum BondManagerState {
  CREATE = 'CREATE',
  CONSULT = 'CONSULT',
  UPDATE = 'UPDATE',
}

enum BondManagerActions {
  CHANGE_TO_CONSULT = 'CHANGE_TO_CONSULT',
  CHANGE_TO_CREATE = 'CHANGE_TO_CREATE',
  CHANGE_TO_UPDATE = 'CHANGE_TO_UPDATE',
  START_LOADING = 'START_LOADING',
  STOP_LOADING = 'STOP_LOADING',
}

type StateManager = {
  currentState: BondManagerState;
  isLoading: boolean;
};

function bondManagerReducer(stateManager: StateManager, action: BondManagerActions) {
  switch (action) {
    case BondManagerActions.CHANGE_TO_CONSULT:
      return { ...stateManager, currentState: BondManagerState.CONSULT };
    case BondManagerActions.CHANGE_TO_CREATE:
      return { ...stateManager, currentState: BondManagerState.CREATE };
    case BondManagerActions.CHANGE_TO_UPDATE:
      return { ...stateManager, currentState: BondManagerState.UPDATE };
    case BondManagerActions.START_LOADING:
      return { ...stateManager, isLoading: true };
    case BondManagerActions.STOP_LOADING:
      return { ...stateManager, isLoading: false };
    default:
      return stateManager;
  }
}

const useBondManagerState = () => {
  const [bondManagerState, dispatchBondManagerState] = useReducer(bondManagerReducer, {
    currentState: BondManagerState.CREATE,
    isLoading: false,
  });

  const changeToConsult = () => dispatchBondManagerState(BondManagerActions.CHANGE_TO_CONSULT);
  const changeToCreate = () => dispatchBondManagerState(BondManagerActions.CHANGE_TO_CREATE);
  const changeToUpdate = () => dispatchBondManagerState(BondManagerActions.CHANGE_TO_UPDATE);
  const changeToLoading = () => dispatchBondManagerState(BondManagerActions.START_LOADING);
  const changeToFinnishLoading = () => dispatchBondManagerState(BondManagerActions.STOP_LOADING);

  const { isLoading, currentState } = bondManagerState;
  const isConsult = currentState === BondManagerState.CONSULT;
  const isCreate = currentState === BondManagerState.CREATE;
  const isUpdate = currentState === BondManagerState.UPDATE;

  return {
    changeToConsult,
    changeToCreate,
    changeToUpdate,
    changeToLoading,
    changeToFinnishLoading,
    isLoading,
    isConsult,
    isCreate,
    isUpdate,
  };
};

export default useBondManagerState;
