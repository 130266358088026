import { Grid } from '@material-ui/core';
import { useCallback, useMemo } from 'react';
import uuid from 'react-uuid';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-final-form';
import { useSelector } from 'react-redux';
import QCXFinalBondManagerOld from '../../shared-components/final-gerenciador-relacionamento/QCXFinalBondManagerOld';
import QCXFinalTextField from '../../shared-components/final-text-field/QCXFinalTextField';
import QCXFinalNumericDecimalField from '../../shared-components/final-numeric-decimal-field/QCXFinalNumericDecimalField';
import QCXFormSubtitle from '../form-title/QCXFormSubtitle';
import QCXSelectUnidadeLocalRFBAutocomplete from '../../shared-components/select-unidade-local-rfb/QCXSelectUnidadeLocalRFBAutocomplete';
import QCXFinalOnblurMaskedField from '../../shared-components/final-onblur-masked-field/QCXFinalOnblurMaskedField';
import { formatCnpj } from '../../utils/hooks/form/field/formatters';
import { parseCnpj } from '../../utils/hooks/form/field/parsers';
// import { validCnpj } from '../../utils/validators/field/validator';
import { selectUnidadesLocaisRfb } from '../../features/unidade-local-rfb/unidadeLocalRfbSlice';
import QCXAutocompleteSelect from '../../shared-components/autocomplete-select/QCXAutocompleteSelect';
import {
  vinculoOperacaoExportacaoTemporariaVinculadaDUEActions,
  vinculoOperacaoExportacaoTemporariaVinculadaDUESelectors,
} from '../../features/vinculo-operacao-exportacao-temporaria-vinculada-due/vinculoOperacaoExportacaoTemporariaVinculadaDUESlice';

export default function QCXNotaReferenciadaFormularioFormGroup({ disabled, isParentConsult }) {
  const { t } = useTranslation();
  const form = useForm();
  const urfList = useSelector(selectUnidadesLocaisRfb);
  const listProps = useMemo(
    () => ({
      name: 'due.notasReferenciadasFormulario',
      columns: [
        {
          field: 'uf',
          headerName: 'UF',
          headerAlign: 'center',
          align: 'center',
          flex: 100,
          valueGetter: ({ row }) => row?.uf.description,
        },
        {
          field: 'anoMes',
          headerName: 'Ano/Mês',
          headerAlign: 'center',
          align: 'center',
          flex: 100,
          valueGetter: ({ row }) => row?.anoMes,
        },
        {
          field: 'cnpjBeneficiario',
          headerName: 'CNPJ',
          headerAlign: 'center',
          align: 'center',
          flex: 100,
          valueGetter: ({ row }) => row?.cnpjBeneficiario,
        },
        {
          field: 'modelo',
          headerName: 'Modelo',
          headerAlign: 'center',
          align: 'center',
          flex: 100,
          valueGetter: ({ row }) => row?.modelo,
        },
        {
          field: 'serie',
          headerName: 'Série',
          headerAlign: 'center',
          align: 'center',
          flex: 100,
          valueGetter: ({ row }) => row?.serie,
        },
        {
          field: 'numeroDocumento',
          headerName: 'Número Documento',
          headerAlign: 'center',
          align: 'center',
          flex: 100,
          valueGetter: ({ row }) => row?.numeroDocumento,
        },
        {
          field: 'numeroItem',
          headerName: 'Número Item',
          headerAlign: 'center',
          align: 'center',
          flex: 100,
          valueGetter: ({ row }) => row?.numeroItem,
        },
        {
          field: 'quantidade',
          headerName: 'Quantidade',
          headerAlign: 'center',
          align: 'center',
          flex: 100,
          valueGetter: ({ row }) => row?.quantidade,
        },
      ],
    }),
    []
  );
  const formProps = useMemo(
    () => ({
      rootName: 'ignorableFields.notaReferenciadaFormulario.form',
      fields: [
        {
          name: 'uf',
          label: 'UF',
          required: true,
        },
        {
          name: 'anoMes',
          label: 'Ano/Mês',
          required: true,
        },
        {
          name: 'cnpjBeneficiario',
          label: 'CNPJ',
          required: true,
        },
        {
          name: 'modelo',
          label: 'Modelo',
          required: true,
        },
        {
          name: 'serie',
          label: 'Série',
          required: true,
        },
        {
          name: 'numeroDocumento',
          label: 'Número Documento',
          required: true,
        },
        {
          name: 'numeroItem',
          label: 'Número Item',
          required: true,
        },
        {
          name: 'quantidade',
          label: 'Quantidade',
          required: true,
        },
      ],
    }),
    []
  );

  const list = useMemo(
    () => [
      { id: '01', value: '01' },
      { id: '04', value: '04' },
    ],
    []
  );

  const reducerConfig = useMemo(
    () => ({
      selectors: {
        selectStatus: vinculoOperacaoExportacaoTemporariaVinculadaDUESelectors.selectStatus,
        selectMode: vinculoOperacaoExportacaoTemporariaVinculadaDUESelectors.selectMode,
        selectModel: vinculoOperacaoExportacaoTemporariaVinculadaDUESelectors.selectModel,
      },
      actions: {
        loading: vinculoOperacaoExportacaoTemporariaVinculadaDUEActions.loading,
        resetStatus: vinculoOperacaoExportacaoTemporariaVinculadaDUEActions.resetStatus,
        changeToUpdateMode: vinculoOperacaoExportacaoTemporariaVinculadaDUEActions.changeToUpdateMode,
        changeToCreateMode: vinculoOperacaoExportacaoTemporariaVinculadaDUEActions.changeToCreateMode,
        resetMode: vinculoOperacaoExportacaoTemporariaVinculadaDUEActions.resetMode,
        setModel: vinculoOperacaoExportacaoTemporariaVinculadaDUEActions.setModel,
        resetModel: vinculoOperacaoExportacaoTemporariaVinculadaDUEActions.resetModel,
      },
    }),
    []
  );

  const handleAdd = useCallback((currentVinculo, currentVinculosList, handleSuccessAddVinculo) => {
    const urfValue = urfList.find((item) => item?.id === currentVinculo?.uf);
    const vinculo = {
      id: uuid(),
      ...currentVinculo,
      uf: urfValue,
    };

    const updatedVinculos = [...currentVinculosList, vinculo];
    handleSuccessAddVinculo(updatedVinculos);
    // form.resetFieldState('ignorableFields.notaReferenciada.form.quantidadeAssociada');
    // form.change('ignorableFields.notaReferenciada.form.quantidadeAssociada', '');

    // Reset all fields States and change values to empty
    form.resetFieldState('ignorableFields.notaReferenciadaFormulario.form.uf');
    form.change('ignorableFields.notaReferenciadaFormulario.form.uf', '');
    form.resetFieldState('ignorableFields.notaReferenciadaFormulario.form.anoMes');
    form.change('ignorableFields.notaReferenciadaFormulario.form.anoMes', '');
    form.resetFieldState('ignorableFields.notaReferenciadaFormulario.form.cnpjBeneficiario');
    form.change('ignorableFields.notaReferenciadaFormulario.form.cnpjBeneficiario', '');
    form.resetFieldState('ignorableFields.notaReferenciadaFormulario.form.modelo');
    form.change('ignorableFields.notaReferenciadaFormulario.form.modelo', '');
    form.resetFieldState('ignorableFields.notaReferenciadaFormulario.form.serie');
    form.change('ignorableFields.notaReferenciadaFormulario.form.serie', '');
    form.resetFieldState('ignorableFields.notaReferenciadaFormulario.form.numeroDocumento');
    form.change('ignorableFields.notaReferenciadaFormulario.form.numeroDocumento', '');
    form.resetFieldState('ignorableFields.notaReferenciadaFormulario.form.numeroItem');
    form.change('ignorableFields.notaReferenciadaFormulario.form.numeroItem', '');
    form.resetFieldState('ignorableFields.notaReferenciadaFormulario.form.quantidade');
    form.change('ignorableFields.notaReferenciadaFormulario.form.quantidade', '');
  }, []);

  return (
    <>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <QCXFormSubtitle
          title="Notas Referenciadas Formulário"
          boxProps={{
            pt: 1,
          }}
        />
      </Grid>
      <QCXFinalBondManagerOld
        isParentConsult={isParentConsult}
        listProps={listProps}
        formProps={formProps}
        reducerConfig={reducerConfig}
        handleAdd={handleAdd}
        controlButtonsGridProps={{
          add: {
            xs: 12,
            sm: 2,
            md: 2,
          },
        }}
      >
        {({ loading }) => (
          <>
            <Grid item xs={12} sm={4} md={4}>
              <QCXSelectUnidadeLocalRFBAutocomplete
                name="ignorableFields.notaReferenciadaFormulario.form.uf"
                label={t('com.muralis.qcx.unidadeLocalURF')}
                disabled={disabled || loading}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <QCXFinalTextField
                name="ignorableFields.notaReferenciadaFormulario.form.anoMes"
                label="Ano/Mês"
                disabled={disabled || loading}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <QCXFinalOnblurMaskedField
                name="ignorableFields.notaReferenciadaFormulario.form.cnpjBeneficiario"
                label={t('com.muralis.qcx.atoConcessorio.CNPJBeneficiarioAC')}
                defaultValue=""
                maxLength={18}
                format={formatCnpj}
                parse={parseCnpj}
                // validate={validCnpj}
                disabled={disabled || loading}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <QCXAutocompleteSelect
                label="Modelo"
                id="select-field-modelo"
                name="ignorableFields.notaReferenciadaFormulario.form.modelo"
                options={list}
                getOptionLabel={(option) => option.value}
                defaultValue={list[0]}
                disabled={disabled || loading}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <QCXFinalTextField
                name="ignorableFields.notaReferenciadaFormulario.form.serie"
                label="Série"
                disabled={disabled || loading}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <QCXFinalTextField
                name="ignorableFields.notaReferenciadaFormulario.form.numeroDocumento"
                label="Número do documento"
                disabled={disabled || loading}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <QCXFinalTextField
                name="ignorableFields.notaReferenciadaFormulario.form.numeroItem"
                label="Número Item"
                disabled={disabled || loading}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <QCXFinalNumericDecimalField
                name="ignorableFields.notaReferenciadaFormulario.form.quantidade"
                label="Quantidade"
                disabled={disabled || loading}
                scale={5}
              />
            </Grid>
          </>
        )}
      </QCXFinalBondManagerOld>
    </>
  );
}
