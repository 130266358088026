import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ExportadorFabricante } from 'quickcomex-api-types';
import i18n from '../../i18n';
import {
  BACKGROUND_CREATE_MODE,
  CONSULT_MODE,
  CREATE_MODE,
  NONE_BACKGROUND_MODE,
  NONE_MODE,
  NONE_SUB_MODE,
  UPDATE_MODE,
} from '../mode';
import {
  ALTERNATIVE_LOADING_STATUS,
  FAILURE_STATUS,
  IDLE_STATUS,
  LOADING_STATUS,
  PREPARING_ACTION_STATUS,
  SUCCESS_STATUS,
} from '../status';
import { activateById, fetchAll, fetchById, inactivateById } from './empresaEstrangeiraAPI';

interface EmpresaEstrangeiraInitialState {
  status: string;
  mode: {
    main: string;
    background: string;
    sub: string;
  };
  error: any;
  response: {
    status?: number | null;
    message?: string | null;
    data: any;
  };
  list: any[];
  model: any;
}

const initialState: EmpresaEstrangeiraInitialState = {
  status: IDLE_STATUS,
  mode: {
    main: NONE_MODE,
    background: NONE_BACKGROUND_MODE,
    sub: NONE_SUB_MODE,
  },
  error: null,
  response: {
    status: null,
    message: null,
    data: null,
  },
  list: [],
  model: {},
};

const fetchAllAsync = createAsyncThunk('empresaEstrangeira/fetchAll', async () => {
  const { data } = await fetchAll();
  return { data };
});

const fetchByIdAsync = createAsyncThunk('empresaEstrangeira/fetchById', async (data) => {
  const response = await fetchById(data);
  return {
    response: {
      status: response.status,
      data: response.data,
    },
  };
});

const activateByIdAsync = createAsyncThunk('empresaEstrangeira/activateById', async (id) => {
  const { status, data } = await activateById(id);
  return { response: { status, data } };
});

const inactivateByIdAsync = createAsyncThunk('empresaEstrangeira/inactivateById', async (id) => {
  const { status, data } = await inactivateById(id);
  return { response: { status, data } };
});

const empresaEstrangeiraSlice = createSlice({
  name: 'empresaEstrangeira',
  initialState,
  reducers: {
    changeStatusTo: (state, action) => {
      state.status = action.payload.status;
    },
    loading: (state) => {
      state.status = LOADING_STATUS;
    },
    success: (state) => {
      state.status = SUCCESS_STATUS;
    },
    failure: (state) => {
      state.status = FAILURE_STATUS;
    },
    preparingAction: (state) => {
      state.status = PREPARING_ACTION_STATUS;
    },
    resetStatus: (state) => {
      state.status = IDLE_STATUS;
    },
    changeToCreateMode: (state) => {
      state.mode.main = CREATE_MODE;
    },
    changeToConsultMode: (state) => {
      state.mode.main = CONSULT_MODE;
    },
    changeToUpdateMode: (state) => {
      state.mode.main = UPDATE_MODE;
    },
    resetMode: (state) => {
      state.mode.main = NONE_MODE;
    },
    changeToBackgroundCreateMode: (state) => {
      state.mode.background = BACKGROUND_CREATE_MODE;
    },
    resetBackgroundMode: (state) => {
      state.mode.background = NONE_BACKGROUND_MODE;
    },
    setModel: (state, action) => {
      state.model = {
        ...action.payload,
      };
    },
    resetModel: (state) => {
      state.model = initialState.model;
    },
    addToList: (state, action) => {
      state.list = [...state.list, action.payload.data];
    },
    resetList: (state) => {
      state.list = [];
    },
    updateOnList: (state, action) => {
      state.list = state.list.map((current) => {
        if (current.id === action.payload.data?.id) {
          return action.payload.data;
        }
        return current;
      });
    },
    setResponse: (state, action) => {
      state.response = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    clearResponse: (state) => {
      state.response = {
        status: null,
        message: null,
        data: null,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllAsync.pending, (state) => {
        state.status = ALTERNATIVE_LOADING_STATUS;
      })
      .addCase(fetchAllAsync.fulfilled, (state, action) => {
        state.status = IDLE_STATUS;
        state.list = action.payload.data;
      })
      .addCase(fetchAllAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroCarregarListaEmpresaEstrangeiraMensagem', {
            erro: action.error.message,
          }),
        };
      })
      .addCase(fetchByIdAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(fetchByIdAsync.fulfilled, (state, action) => {
        if (action.payload.response.status === 200) {
          state.model = action.payload.response.data;
          state.status = SUCCESS_STATUS;
        }
      })
      .addCase(fetchByIdAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroCarregarEmpresaEstrangeiraMensagem', {
            erro: action.error.message,
          }),
        };
      })
      .addCase(activateByIdAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(activateByIdAsync.fulfilled, (state, action) => {
        state.status = SUCCESS_STATUS;
        state.response.status = action.payload.response.status;
        state.response.data = action.payload.response.data;

        const { pessoaJuridica } = action.payload.response.data;
        state.response.message = i18n.t('com.muralis.qcx.mensagem.empresaEstrangeiraAtivada', {
          empresa: pessoaJuridica.nome,
        });

        if (state.response.status === 200) {
          state.list = state.list.map((current) =>
            current.id === action.payload.response.data.id ? action.payload.response.data : current
          );
        }
      })
      .addCase(activateByIdAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroAtivarEmpresaEstrangeiraMensagem', { erro: action.error.message }),
        };
      })
      .addCase(inactivateByIdAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(inactivateByIdAsync.fulfilled, (state, action) => {
        state.status = SUCCESS_STATUS;
        state.response.status = action.payload.response.status;
        state.response.data = action.payload.response.data;

        const { pessoaJuridica } = action.payload.response.data;
        state.response.message = i18n.t('com.muralis.qcx.mensagem.empresaEstrangeiraInativada', {
          empresa: pessoaJuridica.nome,
        });

        if (state.response.status === 200) {
          state.list = state.list.map((current) =>
            current.id === action.payload.response.data.id ? action.payload.response.data : current
          );
        }
      })
      .addCase(inactivateByIdAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroInativarEmpresaEstrangeiraMensagem', {
            erro: action.error.message,
          }),
        };
      });
  },
});

const {
  changeStatusTo,
  loading,
  success,
  failure,
  resetStatus,
  changeToBackgroundCreateMode,
  changeToConsultMode,
  changeToCreateMode,
  changeToUpdateMode,
  preparingAction,
  resetBackgroundMode,
  resetList,
  resetMode,
  setModel,
  addToList,
  updateOnList,
  setResponse,
  clearResponse,
  setError,
  resetModel,
} = empresaEstrangeiraSlice.actions;

const selectEmpresasEstrangeiras = (state: any) => state.empresaEstrangeira.list as ExportadorFabricante[];
const selectStatus = (state: any) => state.empresaEstrangeira.status;
const selectError = (state: any) => state.empresaEstrangeira.error;
const selectResponse = (state: any) => state.empresaEstrangeira.response;
const selectEmpresaEstrangeira = (state: any) => state.empresaEstrangeira.model as ExportadorFabricante;
const selectMode = (state: any) => state.empresaEstrangeira.mode.main;
const selectBackgroundMode = (state: any) => state.empresaEstrangeira.mode.background;

export {
  activateByIdAsync,
  addToList,
  changeStatusTo,
  changeToBackgroundCreateMode,
  changeToConsultMode,
  changeToCreateMode,
  changeToUpdateMode,
  clearResponse,
  empresaEstrangeiraSlice,
  failure,
  fetchAllAsync,
  fetchByIdAsync,
  inactivateByIdAsync,
  loading,
  preparingAction,
  resetBackgroundMode,
  resetList,
  resetMode,
  resetModel,
  resetStatus,
  selectBackgroundMode,
  selectEmpresaEstrangeira,
  selectEmpresasEstrangeiras,
  selectError,
  selectMode,
  selectResponse,
  selectStatus,
  setError,
  setModel,
  setResponse,
  success,
  updateOnList,
};

export default empresaEstrangeiraSlice.reducer;
