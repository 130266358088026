import { Button, Input, Modal, makeStyles } from "@material-ui/core";
import { ChangeEvent, useState } from "react";
import FileDragAndDrop from "../fileDragAndDrop";
import FormHeaderMedium from "../forms/formHeaderMedium";
import { SpreadsheetConfig } from "../../hooks/spreadsheets/useSpreadsheet";
import MessageBadge from "../forms/messageBadge";

interface SpreadsheetImportModalProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmit: (file: File | null) => void;
  instructions: string;
}

const SpreadsheetImportModal = ({
  isOpen,
  setIsOpen,
  onSubmit,
  instructions,
}: SpreadsheetImportModalProps) => {
  const useStyles = makeStyles((theme) => ({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      borderRadius: 8,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(4),
      outline: "none",
    },
    input: {
      display: "none",
    },
    button: {
      marginTop: theme.spacing(2),
    },
  }));

  const classes = useStyles();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>, file: File) => {
    setSelectedFile(file);
  };

  return (
    <Modal
      className={classes.modal}
      open={isOpen}
      onClose={setIsOpen}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <div className={classes.paper}>
        <FormHeaderMedium marginBottom="10px">
          Selecione um arquivo excel
        </FormHeaderMedium>
        <FileDragAndDrop
          accept={".xlsx"}
          onChangeAction={handleFileChange}
        ></FileDragAndDrop>
        {instructions && (
          <div style={{ marginTop: "15px" }}>
            {" "}
            <MessageBadge message={instructions}></MessageBadge>
          </div>
        )}
        <label htmlFor="contained-button-file">
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "16px",
            }}
          >
            <Button
              variant="contained"
              component="span"
              color="primary"
              onClick={() => setIsOpen(false)}
              className={classes.button}
              style={{ marginRight: "8px" }}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              component="span"
              color="secondary"
              onClick={() => onSubmit(selectedFile)}
              className={classes.button}
            >
              Importar
            </Button>
          </div>
        </label>
      </div>
    </Modal>
  );
};

export default SpreadsheetImportModal;
