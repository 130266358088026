import {
  Grid,
  makeStyles,
} from '@material-ui/core';
import React, { useMemo } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectMoedas } from '../../features/moeda/moedaSlice';
import QCXFormStepWizard from '../../shared-components/form-step-wizard/QCXFormStepWizard';
import useFaturaTotaisListeners from '../../utils/general/fatura/faturaTotaisListeners';
import { BRAZILIAN } from '../../utils/hooks/form/field/mask-types';
import useValidators from '../../utils/hooks/useValidators';
import { maxValue } from '../../utils/validators/field/validator';
import QCXFormTitle from '../form-title/QCXFormTitle';
import QCXFaturaTotaisFormGroup from './FaturaTotaisFormGroup/QCXFaturaTotaisFormGroup';
import QCXWarningAlert from '../../shared-components/alert/QCXWarningAlert';

const useStyles = makeStyles((theme) => ({
  moedaInternacionalFieldLeft: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: '5px',
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingBottom: '16px',
    },
  },
  moedaInternacionalFieldRight: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: '11px',
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '8px',
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
    },
  },
}));

export default function QCXFaturaTotaisForm({
  initialValues,
  handleSubmit,
  isConsult,
  controlComponentProps,
  ...restProps
}) {
  /* eslint react/no-unknown-property: 0 */
  const { t } = useTranslation();

  const classes = useStyles();

  const moedas = useSelector(selectMoedas);

  const [
    onBlurObjectHandlers,
    changeListeners,
  ] = useFaturaTotaisListeners(isConsult, moedas);

  const valorValidators = useMemo(() => (
    useValidators([
      maxValue(999999999999.99),
    ])
  ), []);

  const validatePendencias = (fatura) => {
    const pendencias = [];

    if (fatura.origem !== 'OCR') return <></>;

    // moeda do MLE
    if (!fatura.mle || !fatura.mle.moeda) {
      pendencias.push(t('com.muralis.qcx.fatura.moedaMLE'));
    }

    if (pendencias.length > 0) {
      return (
        <Grid xs={12} style={{ marginTop: '2rem' }}>
          <QCXWarningAlert closable>
            <Trans i18nKey="com.muralis.qcx.mensagem.pendenciaOCRFatura">
              Verifique as seguintes pendências detectadas pelo OCR:
              <ul i18nIsDynamicList>
                {pendencias.map((pendencia) => (
                  <li>{pendencia}</li>
                ))}
              </ul>
            </Trans>
          </QCXWarningAlert>
        </Grid>
      );
    }

    return <></>;
  };

  return (
    <QCXFormStepWizard
      initialValues={initialValues}
      onSubmit={handleSubmit}
      controlComponentProps={controlComponentProps}
      onChangeListeners={changeListeners}
      {...restProps}
    >
      {({ values, submitSucceeded }) => (
        <>
          {validatePendencias(values)}
          <QCXFormTitle title={t('com.muralis.qcx.fatura.fichaTotais')} />
          <Grid container spacing={2} style={{ marginTop: '1rem' }}>
            <QCXFaturaTotaisFormGroup
              intl={BRAZILIAN}
              classes={classes}
              isConsult={isConsult}
              valorValidators={valorValidators}
              submitSucceeded={submitSucceeded}
              onBlurObjectHandlers={onBlurObjectHandlers}
            />
          </Grid>
        </>
      )}
    </QCXFormStepWizard>
  );
}
