import { useCallback } from "react";
import { QueriesTypes, usePutRequestFormData } from "../queries";
import { UseMutateFunction } from "react-query";
import { parseToFormData } from "../../utils/parsers/formDataParsers";

type UpdatePayload = {
  requestPayload: {
    [key: string]: any;
  };
  queryParams?: {
    step?: number;
  };
};

export const useAddClientContacts = (): [
  ({}: UpdatePayload) => void,
  boolean,
  boolean
] => {
  const { mutate, isLoading, isError } = usePutRequestFormData(
    QueriesTypes.Clientes
  );

  const updateFunction = useCallback(
    ({ requestPayload, queryParams }: UpdatePayload) => {
      const formData = parseToFormData(requestPayload);

      mutate({ requestFormData: formData, queryParams });
    },
    [mutate]
  );

  return [updateFunction, isLoading, isError];
};
