import CardsGrid, {
  CardsGridItem,
} from "../../common/components/cardsGrid/cardsGrid";
import BasicLayout from "../../common/layouts/basicLayout";
import { DynamicFeed } from "@mui/icons-material";
import AssistantIcon from "@mui/icons-material/Assistant";

const gridItems: CardsGridItem[] = [
  {
    title: "Follow-up",
    icon: DynamicFeed,
    path: "/assessoria/followup",
  },
];

const AssessoriaPage = () => {
  return (
    <BasicLayout
      title={"Assessoria"}
      icon={<AssistantIcon color={"secondary"} />}
    >
      <CardsGrid items={gridItems}></CardsGrid>
    </BasicLayout>
  );
};

export default AssessoriaPage;
