import React, {
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import {
  useHistory,
  useParams,
} from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';
import { isEmpty } from 'lodash';
import {
  changeToConsultMode,
  changeToCreateMode,
  changeToUpdateMode,
  failure,
  fetchByIdAsync,
  loading,
  resetModel,
  selectFornecedorFavorecido,
  selectMode,
  setError,
  setModel,
  setResponse,
  success,
  addToList,
  updateOnList,
  changeModeTo,
} from '../../../../../features/fornecedor-favorecido/FornecedorSlice';
import {
  isCreateMode,
  isConsultMode,
  isUpdateMode,
  isNoneMode,
} from '../../../../../utils/store/store-utils';
import { register, save } from '../../../../../features/fornecedor-favorecido/FornecedorAPI';
import QCXRegistrationFormPageTemplate from '../../../../../templates/registration-form-page/QCXRegistrationFormPageTemplate';
import useAutoChangeMode from '../../../../../utils/hooks/mode/useAutoChangeMode';
import { getTipoModeloLabelByValue } from '../../../../../shared-components/select-tipo-modelo/QCXFinalSelectTipoModeloAutocomplete';
import QCXFornecedorForm from '../../../../../components/fornecedor/QCXFornecedorForm';
import { normalizeDigits } from '../../../../../utils/general/general-utils';
import { numberOnly } from '../../../../../utils/hooks/form/field/parsers';
import {
  formatCep,
  formatCnpjOrCnpjValidate,
} from '../../../../../utils/hooks/form/field/formatters';

export default function FornecedorRegistrationPage({ authInfo = {} }) {
  const { t } = useTranslation();

  const { id } = useParams();

  const history = useHistory();

  const dispatch = useDispatch();

  const mode = useSelector(selectMode);

  const fornecedor = useSelector(selectFornecedorFavorecido);

  const isNone = useMemo(() => (
    isNoneMode(mode)
  ), [mode]);

  const isCreate = useMemo(() => (
    isCreateMode(mode)
  ), [mode]);

  const isConsult = useMemo(() => (
    isConsultMode(mode)
  ), [mode]);

  const isUpdate = useMemo(() => (
    isUpdateMode(mode)
  ), [mode]);

  const fetchById = useCallback((fornecedorId) => (
    dispatch(fetchByIdAsync(fornecedorId))
  ), []);

  const handleChangeToCreate = () => {
    dispatch(changeToCreateMode());
  };

  const handleChangeToConsult = () => {
    dispatch(changeToConsultMode());
  };

  const handleChangeToUpdate = () => {
    dispatch(changeToUpdateMode());
  };

  useAutoChangeMode((currentMode) => (
    dispatch(changeModeTo(currentMode))
  ), [isUpdate]);

  useEffect(() => {
    if (!isEmpty(id)) {
      dispatch(fetchByIdAsync(id));
      handleChangeToConsult();
    }
  }, [id]);

  const handleCancelUpdate = () => {
    if (id) {
      fetchById(id);
    }
    handleChangeToConsult();
  };

  const normalize = useCallback((unnormalizedData) => {
    const normalizedPessoaData = {
      ...unnormalizedData?.pessoa,
      tipo: numberOnly(
        unnormalizedData?.cpfCnpj
      ).length > 11 ? 'PessoaJuridica' : 'PessoaFisica',
      cnpj: numberOnly(
        unnormalizedData?.cpfCnpj
      ).length > 11 ? numberOnly(
          unnormalizedData?.cpfCnpj
        ) : '',
      cpf: numberOnly(
        unnormalizedData?.cpfCnpj
      ).length < 12 ? numberOnly(
          unnormalizedData?.cpfCnpj
        ) : '',
    };

    const normalizedData = {
      ...unnormalizedData,
      pessoa: normalizedPessoaData,
      contaBancaria: unnormalizedData?.contaBancaria?.id
        ? unnormalizedData?.contaBancaria
        : undefined,
      contato: {
        email: unnormalizedData?.contato?.email,
        telefone: unnormalizedData?.contato?.telefone,
        celular: unnormalizedData?.contato?.celular,
      },
      endereco: {
        ...unnormalizedData?.endereco,
        cep: unnormalizedData?.endereco?.cep
          ? normalizeDigits(unnormalizedData?.endereco?.cep)
          : null,
      },
      contaContabil: unnormalizedData?.contaContabil?.id
        ? unnormalizedData?.contaContabil
        : undefined,
      planoDeConta: unnormalizedData?.contaContabil?.id
        ? unnormalizedData?.contaContabil : undefined,
    };

    return normalizedData;
  }, [
    normalizeDigits,
    numberOnly,
  ]);

  const unnormalize = useCallback((normalizedData) => {
    const unnormalizedData = {
      ...normalizedData,
      pessoa: {
        ...normalizedData?.pessoa,
      },
      cpfCnpj: formatCnpjOrCnpjValidate(normalizedData?.pessoa?.cnpj, normalizedData?.pessoa?.cpf),
      contaBancaria: normalizedData?.contaBancaria?.id
        ? normalizedData?.contaBancaria
        : undefined,
      contaContabil: normalizedData?.planoDeConta?.id
        ? normalizedData?.planoDeConta
        : undefined,
      contato: {
        email: normalizedData?.contato?.email,
        telefone: normalizedData?.contato?.telefone,
        celular: normalizedData?.contato?.celular,
      },
      endereco: {
        ...normalizedData?.endereco,
        cep: normalizedData?.endereco?.cep ? formatCep(normalizedData?.endereco?.cep) : '',
      },
    };

    return unnormalizedData;
  }, [
    formatCep,
    formatCnpjOrCnpjValidate
  ]);

  const handleDispatchSetModel = useCallback((data) => {
    const normalizedData = normalize(data);

    dispatch(
      setModel(normalizedData)
    );
  }, [normalize]);

  const create = async (data) => {
    const executeDebounced = debounce(async () => {
      try {
        const response = await register(data);

        if (response?.status === 201) {
          dispatch(resetModel());

          const created = response?.data;

          handleDispatchSetModel(created);
          dispatch(addToList({ data: created }));

          const handleResultWithDebounce = debounce(() => {
            history.push(t('com.muralis.qcx.url.modulosFinanceirosFornecedor'));

            dispatch(success());
            dispatch(setResponse({
              status: response.status,
              data: created,
              message: t('com.muralis.qcx.mensagem.fornecedorCadastrado', { descricao: data?.pessoa?.nome }),
            }));
          }, 500);

          handleResultWithDebounce();
        }
      } catch (error) {
        const defaultErrorMessage = t('com.muralis.qcx.erro.erroCadastrarFornecedor', { descricao: data?.pessoa?.nome });
        const errorMessage = error?.response?.data?.message
          ? `${defaultErrorMessage}: ${error?.response?.data?.message}.`
          : `${defaultErrorMessage}.`;

        dispatch(failure());
        dispatch(setError({
          message: errorMessage,
        }));
      }
    }, 500);

    dispatch(loading());
    executeDebounced();
  };

  const update = async (data) => {
    const executeDebounced = debounce(async () => {
      try {
        const response = await save(data);

        if (response?.status === 200) {
          const handleResultWithDebounce = debounce(() => {
            handleChangeToConsult();
            dispatch(success());

            const saved = response?.data;

            dispatch(setResponse({
              status: response.status,
              data: saved,
              message: t('com.muralis.qcx.mensagem.FornecedorFavorecidoSalvo', { nome: saved?.pessoa?.nome }),
            }));

            dispatch(setModel(saved));
            dispatch(updateOnList({ data: saved }));
            dispatch(fetchByIdAsync(saved?.id));
          }, 500);

          handleResultWithDebounce();
          handleChangeToConsult();
        }
      } catch (error) {
        const defaultErrorMessage = t('com.muralis.qcx.erro.erroSalvarFornecedorFavorecido', { nome: error?.pessoa?.nome });
        const errorMessage = error?.response?.data?.message
          ? `${defaultErrorMessage}: ${error?.response?.data?.message}.`
          : `${defaultErrorMessage}.`;

        dispatch(failure());
        dispatch(setError({
          message: errorMessage,
        }));
      }
    }, 500);

    dispatch(loading());
    executeDebounced();
  };

  const handleSubmit = async (data) => {
    const normalizedData = normalize(data);

    if (isUpdate) {
      await update(normalizedData);
    } else {
      await create(normalizedData);
    }
  };

  const model = useMemo(() => (
    !isCreate
      ? unnormalize(fornecedor)
      : {}
  ), [
    isCreate,
    fornecedor,
    unnormalize,
  ]);

  const actionName = useMemo(() => {
    if (isCreate || isNone) {
      return t('com.muralis.qcx.acoes.novo');
    }
    if (isConsult) {
      return t('com.muralis.qcx.acoes.visualizar');
    }
    return t('com.muralis.qcx.acoes.alterar');
  }, [isNone, isCreate, isConsult]);

  const breadcrumbs = useMemo(() => ([
    {
      link: {
        to: '/',
        name: t('com.muralis.qcx.inicio'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.cadastros'),
      },
    },
    {
      link: {
        to: t('com.muralis.qcx.url.moduloCadastrosFinanceiros'),
        name: t('com.muralis.qcx.financeiro.label'),
      },
    },
    {
      link: {
        to: t('com.muralis.qcx.url.modulosFinanceirosFornecedor'),
        name: t('com.muralis.qcx.fornecedor.label'),
      },
    },
    {
      text: {
        name: actionName,
      },
    },
  ]), [actionName]);

  const pageTitle = useMemo(() => (
    isNone || isCreate
      ? t('com.muralis.qcx.fornecedor.novoFornecedor')
      : t('com.muralis.qcx.fornecedor.fornecedorExistente', { id: fornecedor?.pessoa?.nomeResumido || fornecedor?.id || '-' })
  ), [
    isNone,
    isCreate,
    fornecedor,
    getTipoModeloLabelByValue,
  ]);

  return (
    <QCXRegistrationFormPageTemplate
      pageTitle={pageTitle}
      breadcrumbs={breadcrumbs}
      isCreate={isCreate}
      isConsult={isConsult}
      isUpdate={isUpdate}
      handleChangeToCreate={handleChangeToCreate}
      handleChangeToConsult={handleChangeToConsult}
      handleChangeToUpdate={handleChangeToUpdate}
      handleCancelUpdate={handleCancelUpdate}
      authInfo={authInfo}
    >
      {(formProps) => (
        <QCXFornecedorForm
          isCreate={isCreate}
          initialValues={model}
          handleSubmit={handleSubmit}
          handleChangeModel={handleDispatchSetModel}
          authInfo={authInfo}
          requiredRoles={['fornecedor-favorecido']}
          {...formProps}
        />
      )}
    </QCXRegistrationFormPageTemplate>
  );
}
