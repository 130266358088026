import { Container } from '@material-ui/core';
import { ViewList as ViewListIcon } from '@material-ui/icons';
import debounce from 'lodash/debounce';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { validate } from 'uuid';
import QCXCatalogoProdutosWizardFinalForm from '../../../../../components/catalogo-produtos/QCXCatalogoProdutosWizardFinalForm';
import QCXMainLayout from '../../../../../components/main-layout/QCXMainLayout';
import { register, save } from '../../../../../features/catalogo-produtos/catalogoProdutosAPI';
import {
  addToList,
  changeToConsultMode,
  changeToUpdateMode,
  failure,
  fetchByIdAsync,
  loading,
  resetModel,
  selectCatalogoProdutos,
  selectMode,
  setError,
  setModel,
  resetRelatedEspecificacaoModel,
  resetSubMode,
  setResponse,
  success,
  updateOnList,
  selectBackgroundMode,
  changeToBackgroundCreateMode,
  resetBackgroundMode,
  changeToCreateMode,
} from '../../../../../features/catalogo-produtos/catalogoProdutosSlice';
import {
  isTipoDuimp,
  normalizeTipoCatalogoProdutos,
  unnormalizeTipoCatalogoProdutos,
} from '../../../../../utils/general/catalogo-produtos/catalogoProdutosUtils';
import {
  forceUnnormalizeNumeral,
  isValid,
  normalizeData,
  normalizeNumeral,
  unnormalizeNumeral,
} from '../../../../../utils/general/general-utils';
import { formatBrazilianNumericDecimal } from '../../../../../utils/hooks/form/field/formatters';
import {
  isBackgroundCreateMode,
  isConsultMode,
  isCreateMode,
  isUpdateMode,
} from '../../../../../utils/store/store-utils';

export default function CatalogoProdutosRegistrationPage({ authInfo = {} }) {
  const { t } = useTranslation();

  const { id } = useParams();

  const history = useHistory();

  const dispatch = useDispatch();

  const [isCreated, setIsCreated] = useState(false);

  const mode = useSelector(selectMode);
  const backgroundMode = useSelector(selectBackgroundMode);

  const catalogoProdutos = useSelector(selectCatalogoProdutos);

  const isCreate = useMemo(() => isCreateMode(mode), [mode]);

  const isConsult = useMemo(() => isConsultMode(mode), [mode]);

  const isUpdate = useMemo(() => isUpdateMode(mode), [mode]);

  const isBackgroundCreate = useMemo(() => isBackgroundCreateMode(backgroundMode), [backgroundMode]);

  const handleChangeToCreate = () => {
    dispatch(changeToCreateMode());
  };

  const handleChangeToConsult = () => {
    dispatch(changeToConsultMode());
    dispatch(resetRelatedEspecificacaoModel());
    dispatch(resetSubMode());
  };

  const handleChangeToUpdate = () => {
    dispatch(changeToUpdateMode());
  };

  const handleCancelUpdate = () => {
    const currentId = isBackgroundCreate ? catalogoProdutos?.id : id;

    if (currentId) {
      dispatch(fetchByIdAsync(currentId));
    }
    handleChangeToConsult();
  };

  const handleChangeToBackgroundCreate = () => {
    dispatch(changeToBackgroundCreateMode());
  };

  const handleResetBackgroundMode = () => {
    dispatch(resetBackgroundMode());
  };

  useEffect(() => {
    if (id) {
      dispatch(fetchByIdAsync(id));
      handleChangeToConsult();
    }
  }, [id]);

  const normalize = (unnormalizedData) => {
    const normalizedTipo = normalizeTipoCatalogoProdutos(unnormalizedData?.tipo);

    let normalizedEspecificacoes = [];
    if (!isTipoDuimp(normalizedTipo)) {
      if (unnormalizedData?.ncm?.id === catalogoProdutos?.ncm?.id || mode === 'none') {
        normalizedEspecificacoes = (unnormalizedData?.especificacoes || []).map((item) => ({
          ...item,
          id: validate(item.id) ? null : item.id,
        }));
      }
    }

    const normalizedDetalhamentoProduto = isTipoDuimp(normalizedTipo) ? unnormalizedData?.detalhamentoProduto : null;

    const normalizedData = {
      ...unnormalizedData,
      exportador: unnormalizedData?.exportador?.id ? unnormalizedData?.exportador : null,
      fabricante: unnormalizedData?.fabricante?.id ? unnormalizedData?.fabricante : null,
      paisOrigem: unnormalizedData?.paisOrigem?.id ? unnormalizedData?.paisOrigem : null,
      anoFabricacao: normalizeData(unnormalizedData?.anoFabricacao, 'YYYY'),
      validade: normalizeData(unnormalizedData?.validade),
      especificacoes: normalizedEspecificacoes,
      unidadeDeMedida: unnormalizedData?.unidadeDeMedida?.id ? unnormalizedData.unidadeDeMedida : null,
      ncm: unnormalizedData?.ncm?.id ? unnormalizedData.ncm : null,
      naladiNcca: unnormalizedData?.naladiNcca?.id ? unnormalizedData.naladiNcca : null,
      naladiSh: unnormalizedData?.naladiSh?.id ? unnormalizedData.naladiSh : null,
      icms: normalizeNumeral(unnormalizedData?.icms),
      pesoLiquido: normalizeNumeral(unnormalizedData?.pesoLiquido),
      valorUnitarioMoeda: normalizeNumeral(unnormalizedData?.valorUnitarioMoeda),
      clientes: unnormalizedData?.clientes || [],
      tipo: normalizedTipo,
      detalhamentoProduto: normalizedDetalhamentoProduto,
    };
    return normalizedData;
  };

  const unnormalize = useCallback(
    (normalizedData) => {
      const { icms, pesoLiquido, anoFabricacao, valorUnitarioMoeda, ncm, naladiNcca, naladiSh } = normalizedData;

      return {
        ...normalizedData,
        icms: isValid(icms) ? forceUnnormalizeNumeral(icms, formatBrazilianNumericDecimal(2)) : undefined,
        pesoLiquido: isValid(pesoLiquido)
          ? forceUnnormalizeNumeral(pesoLiquido, formatBrazilianNumericDecimal(7))
          : undefined,
        valorUnitarioMoeda: isValid(valorUnitarioMoeda)
          ? unnormalizeNumeral(valorUnitarioMoeda, formatBrazilianNumericDecimal(5))
          : undefined,
        anoFabricacao: anoFabricacao ? `${anoFabricacao}` : null,
        ncm: {
          ...ncm,
          id: isValid(ncm?.id) ? ncm?.id : undefined,
          unidadeDeMedida: {
            description: ncm?.unidadeDeMedida?.description ? ncm?.unidadeDeMedida?.description : '',
          },
        },
        naladiNcca: {
          id: isValid(naladiNcca?.id) ? naladiNcca?.id : undefined,
        },
        naladiSh: naladiSh ?? { id: undefined },
        tipo: unnormalizeTipoCatalogoProdutos(normalizedData?.tipo),
      };
    },
    [unnormalizeNumeral]
  );

  const handleDispatchSetModel = useCallback(
    (data) => {
      dispatch(setModel(normalize(data)));
    },
    [normalize]
  );

  const createByStep = async (data, step, next) => {
    const isFirstStep = step === 0;
    const isLastStep = step === 2;

    const executeDebounced = debounce(async () => {
      try {
        if (isFirstStep && !isCreated) {
          const response = await register(data);

          if (response?.status === 201) {
            next();
            dispatch(resetModel());
            dispatch(success());

            const created = response?.data;

            handleDispatchSetModel(created);
            dispatch(addToList({ data: created }));

            setIsCreated(true);
          }
        } else if (isLastStep) {
          const response = await save(data);

          if (response?.status === 200) {
            dispatch(resetModel());

            const handleResultWithDebounce = debounce(() => {
              history.push(t('com.muralis.qcx.url.moduloOperacionaisCatalogosProdutos'));

              dispatch(success());

              const saved = response?.data;

              dispatch(
                setResponse({
                  status: response.status,
                  data: saved,
                  message: t('com.muralis.qcx.mensagem.catalogoProdutosCadastrado', {
                    catalogo: saved?.descricaoResumida,
                  }),
                })
              );

              dispatch(updateOnList({ data: saved }));
            }, 500);

            handleResultWithDebounce();
          }
        } else {
          const response = await save(data);

          if (response?.status === 200) {
            if (isCreate) {
              next();
            } else {
              handleChangeToConsult();
            }
            const saved = response?.data;

            dispatch(success());
            dispatch(setModel(saved));
            dispatch(updateOnList({ data: saved }));
          }
        }
      } catch (error) {
        dispatch(failure());
        dispatch(
          setError({
            message: t('com.muralis.qcx.erro.erroSalvarCatalogoProdutosMensagem', {
              erro: error?.response?.data?.message,
            }),
          })
        );
      }
    }, 500);

    dispatch(loading());
    executeDebounced();
  };

  const update = async (data) => {
    const executeDebounced = debounce(async () => {
      try {
        const response = await save(data);

        if (response?.status === 200) {
          const handleResultWithDebounce = debounce(() => {
            handleChangeToConsult();
            dispatch(success());

            const saved = response?.data;

            dispatch(
              setResponse({
                status: response.status,
                data: saved,
                message: t('com.muralis.qcx.mensagem.catalogoProdutosSalvo', { catalogo: saved?.descricaoResumida }),
              })
            );

            dispatch(setModel(saved));
            dispatch(updateOnList({ data: saved }));
            dispatch(fetchByIdAsync(saved?.id));
          }, 500);

          handleResultWithDebounce();
        }
      } catch ({ response }) {
        dispatch(failure());
        dispatch(
          setError({
            message: t('com.muralis.qcx.erro.erroSalvarCatalogoProdutosEspecifico', {
              catalogo: data?.descricaoResumida,
              erro: response?.data?.message,
            }),
          })
        );
      }
    }, 500);

    dispatch(loading());
    executeDebounced();
  };

  const handleSubmit = async (data, step, next) => {
    const normalizedData = normalize(data);

    if (isUpdate && !isBackgroundCreate) {
      await update(normalizedData);
      handleChangeToConsult();
    } else {
      await createByStep(normalizedData, step, next);
    }
  };

  const handleAlternativeSave = async (event, step, next) => {
    event.stopPropagation();

    const normalizedData = normalize(catalogoProdutos);

    if (isUpdate) {
      await update(normalizedData);
    } else {
      createByStep(normalizedData, step, next);
    }
  };

  const model = useMemo(
    () => (isCreated || !isCreate ? unnormalize(catalogoProdutos) : { tipoCalculo: 'QUANTIDADE_X_VALOR' }),
    [isCreate, isCreated, catalogoProdutos, unnormalize]
  );

  const actionName = useMemo(() => {
    if (isCreate || isBackgroundCreate) return t('com.muralis.qcx.acoes.novo');
    if (isConsult) return t('com.muralis.qcx.acoes.visualizar');
    return t('com.muralis.qcx.acoes.alterar');
  }, [isCreate, isConsult, isBackgroundCreate]);

  const breadcrumbs = [
    {
      link: {
        to: '/',
        name: t('com.muralis.qcx.inicio'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.cadastros'),
      },
    },
    {
      link: {
        to: t('com.muralis.qcx.url.moduloOperacionais'),
        name: t('com.muralis.qcx.operacionais'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.catalogoProdutosMercadoria'),
      },
    },
    {
      link: {
        to: t('com.muralis.qcx.url.moduloOperacionaisCatalogosProdutos'),
        name: t('com.muralis.qcx.principal'),
      },
    },
    {
      text: {
        name: actionName,
      },
    },
  ];

  const pageTitle = useMemo(
    () =>
      isCreate || isBackgroundCreate
        ? t('com.muralis.qcx.catalogoProdutos.novoCatalogoProduto')
        : t('com.muralis.qcx.catalogoProdutos.catalogoProdutoExistente', {
            ID: catalogoProdutos?.partnumber || catalogoProdutos?.descricaoResumida || catalogoProdutos?.code,
          }),
    [isUpdate, isConsult, isBackgroundCreate, catalogoProdutos]
  );

  const breadcrumbsProps = {
    maxItems: 6,
  };

  return (
    <QCXMainLayout
      pageTitle={pageTitle}
      pageIcon={<ViewListIcon />}
      breadcrumbs={breadcrumbs}
      breadcrumbsProps={breadcrumbsProps}
      authInfo={authInfo}
    >
      <Container style={{ display: 'flex', padding: '0rem 3rem 3rem 3rem' }}>
        <QCXCatalogoProdutosWizardFinalForm
          isCreate={isCreate}
          isConsult={isConsult}
          isUpdate={isUpdate}
          isBackgroundCreate={isBackgroundCreate}
          model={model}
          handleChangeModel={handleDispatchSetModel}
          handleSubmit={handleSubmit}
          handleChangeToCreate={handleChangeToCreate}
          handleChangeToConsult={handleChangeToConsult}
          handleAlternativeSave={handleAlternativeSave}
          handleChangeToUpdate={handleChangeToUpdate}
          handleCancelUpdate={handleCancelUpdate}
          handleChangeToBackgroundCreate={handleChangeToBackgroundCreate}
          handleResetBackgroundMode={handleResetBackgroundMode}
          authInfo={authInfo}
          requiredRoles={['catalogo-produtos']}
        />
      </Container>
    </QCXMainLayout>
  );
}
