import { useMemo } from "react";
import { useGetRequest, QueriesTypes } from "../../../../common/hooks/queries";
import { FollowUp } from "../../../../common/types/followUp";

export const useListFollowUpsAssessoria = (): [FollowUp[], boolean] => {
  const {
    data: followUpsData,
    isError,
    isLoading,
  } = useGetRequest(
    QueriesTypes.FollowUpsFilter,
    undefined,
    `servicoProduto=ASSESSORIA`
  );

  const followUps: FollowUp[] = useMemo(() => {
    if (followUpsData && !isError) {
      try {
        return followUpsData?.filter((item: FollowUp) => item.active);
      } catch (e) {
        console.log(e);
        return [];
      }
    } else {
      return [];
    }
  }, [followUpsData]);
  return [followUps, isLoading];
};
