import { Collapse, Grid, IconButton, useMediaQuery, useTheme } from '@material-ui/core';
import {
  CheckCircle,
  DeleteForever as DeleteForeverIcon,
  DescriptionOutlined as DescriptionOutlinedIcon,
  ExpandLess,
  ExpandMore,
  Info as InfoIcon,
  InfoOutlined,
  ListAltOutlined as ListAltOutlinedIcon,
  RestoreFromTrash,
} from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { addDays } from 'date-fns';
import _, { isFunction } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useFormState } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { OnBlur } from 'react-final-form-listeners';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import QCXDocumentosFollowUpDialogManager from '../../../../../../components/follow-up/QCXDocumentosFollowUpDialogManager';
import QCXNovaEtapaFollowUpFormDialog from '../../../../../../components/follow-up/QCXNovaEtapaFollowUpFormDialog';
import QCXObservacaoEtapaFollowUpFormDialog from '../../../../../../components/follow-up/QCXObservacaoEtapaFollowUpFormDialog';
import QCXObservacaoGeralEtapasFollowUpDialog from '../../../../../../components/follow-up/QCXObservacaoGeralEtapasFollowUpDialog';
import QCXFormSubtitle from '../../../../../../components/form-title/QCXFormSubtitle';
import { etapaFollowUpActions } from '../../../../../../features/etapa-follow-up/etapaFollowUpSlice';
import QCXButton from '../../../../../../shared-components/button/QCXButton';
import QCXFinalDateTimePickerField from '../../../../../../shared-components/final-date-time-picker-field/QCXFinalDateTimePickerField';
import QCXTextFieldViewer from '../../../../../../shared-components/text-field/QCXTextFieldViewer';
import useFormDialogSync from '../../../../../../utils/hooks/form/dialog/useFormDialogSync';
import EtapaOcorrenciasBondManager from './EtapaOcorrenciasBondManager';

import { selectMode } from '../../../../../../features/follow-up/followUpSelectors';
import QCXIconButton from '../../../../../../shared-components/button/QCXIconButton';
import EtapaFollowUpUtils from '../../../../../../utils/general/follow-up/EtapaFollowUpUtils';
import { isConsultMode } from '../../../../../../utils/store/store-utils';

function FollowUpListPhases() {
  const { t } = useTranslation();
  const { values, initialValues } = useFormState();

  const theme = useTheme();
  const dispatch = useDispatch();

  const loading = useMemo(() => false, []);

  const mode = useSelector(selectMode);

  const isConsult = useMemo(() => isConsultMode(mode), [mode]);

  const handleExpand = useCallback((fields, index, currentValue) => {
    fields.update(index, { ...currentValue, open: !currentValue.open });
  }, []);

  const isXsDownScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const [handleOpenModalDocumentos, formDialogDocumentosStatus, handleFormDialogDocumentosStatus] = useFormDialogSync();

  const [handleOpenModalNovaEtapa, formDialogEtapaFollowUpStatus, handleFormDialogEtapaFollowUpStatus] =
    useFormDialogSync(() => {
      dispatch(
        etapaFollowUpActions.setModel({
          followUp: {
            id: values?.id,
          },
        })
      );
    }, [values]);

  const [
    handleOpenObservacoesGerais,
    dialogObservacaoGeraLEtapasFollowUpStatus,
    handleConsultObservacaoGeraLEtapasFollowUpStatus,
  ] = useFormDialogSync();

  const [
    handleAlterarObservacaoEtapa,
    formDialogAlterarObservacaoEtapaStatus,
    handleFormDialogAlterarObservacaoEtapaStatus,
  ] = useFormDialogSync(
    (payload) => {
      dispatch(
        etapaFollowUpActions.setModel({
          ...payload,
          metadata: {
            followUp: {
              id: values?.id,
            },
          },
        })
      );
    },
    [values]
  );

  const hasObservation = useCallback((value) => !!value.observacao, []);

  const isCompleted = useCallback((value) => EtapaFollowUpUtils.isStatusConcluida(value?.status), []);

  const isActive = useCallback((value) => value?.active, []);

  const handleInactivateOrReactivate = useCallback((event, value, fields, index) => {
    if (isFunction(event?.stopPropagation)) {
      event.stopPropagation();
    }

    if (isActive(value)) {
      fields.update(index, { ...value, active: false });
    } else {
      fields.update(index, { ...value, active: true });
    }
  }, []);

  const inactivateReactivateButtonTooltipProps = useCallback((value) => {
    if (isActive(value) && !isCompleted(value)) {
      return {
        title: t('com.muralis.qcx.etapa.desativarEtapa'),
      };
    }

    if (!isActive(value) && !isCompleted(value)) {
      return {
        title: t('com.muralis.qcx.etapa.reativarEtapa'),
      };
    }

    return {
      title: t('com.muralis.qcx.etapa.naoPossivelDesativar'),
    };
  }, []);

  const inactivateReactivateButtonColor = useCallback(
    (value) => {
      if (isCompleted(value) || !isActive(value)) {
        return '#fdd88a';
      }

      return theme.palette.primary.main;
    },
    [isConsult, theme]
  );

  const hasAnyObservations = useMemo(
    () => (values.etapas || []).some(({ observacao }) => observacao && _.isString(observacao) && observacao.length > 0),
    [values.etapas]
  );

  const getColor = useCallback((etapa) => {
    if (!etapa.dataConclusao) {
      return 'green';
    }

    if (etapa.dataPrevisao > etapa.dataConclusao) {
      return 'green';
    }

    if (etapa.dataPrevisao < etapa.dataConclusao) {
      return 'red';
    }

    return 'green';
  }, []);

  const handleDataPrevisaoOnChange = useCallback(
    (etapas, etapaEditada, fields) => {
      etapas.forEach((etapa, index) => {
        if (
          (etapa?.etapaBase?.id === etapaEditada?.id ||
            (etapa?.etapaBase === undefined && etapa?.sequencia > etapaEditada?.sequencia)) &&
          !etapa?.dataConclusao
        ) {
          let timeDiff = 0;

          if (etapa?.modeloFollowUpEtapa?.tipoPrazoConclusao === 'DIA') {
            timeDiff = etapa?.modeloFollowUpEtapa?.prazoConclusao;
          } else if (etapa?.modeloFollowUpEtapa?.tipoPrazoConclusao === 'HORA') {
            timeDiff = etapa?.modeloFollowUpEtapa?.prazoConclusao / 24;
          }

          let nativeDate;

          if (moment.isMoment(etapaEditada?.dataPrevisao)) {
            nativeDate = etapaEditada?.dataPrevisao?.toDate();
          } else if (etapaEditada.dataPrevisao instanceof Date) {
            nativeDate = etapaEditada?.dataPrevisao;
          } else {
            return;
          }

          const dataAtualizada = addDays(nativeDate, timeDiff);
          fields.update(index, { ...etapa, dataPrevisao: dataAtualizada });
          handleDataPrevisaoOnChange(etapas, { ...etapa, dataPrevisao: dataAtualizada }, fields);
        }
      });
    },
    [initialValues]
  );

  const handleDataConclusaoOnChange = useCallback(
    (etapas, etapaEditada, fields) => {
      etapas.forEach((etapa, index) => {
        if (
          (etapa?.etapaBase?.id === etapaEditada?.id ||
            (etapa?.etapaBase === undefined && etapa?.sequencia > etapaEditada?.sequencia)) &&
          !etapa?.dataConclusao
        ) {
          let timeDiff = 0;

          if (etapa?.modeloFollowUpEtapa?.tipoPrazoConclusao === 'DIA') {
            timeDiff = etapa?.modeloFollowUpEtapa?.prazoConclusao;
          } else if (etapa?.modeloFollowUpEtapa?.tipoPrazoConclusao === 'HORA') {
            timeDiff = etapa?.modeloFollowUpEtapa?.prazoConclusao / 24;
          }

          let nativeDate;

          if (moment.isMoment(etapaEditada?.dataConclusao)) {
            nativeDate = etapaEditada?.dataConclusao?.toDate();
          } else if (etapaEditada.dataConclusao instanceof Date) {
            nativeDate = etapaEditada?.dataConclusao;
          } else {
            return;
          }

          const dataAtualizada = addDays(nativeDate, timeDiff);
          fields.update(index, { ...etapa, dataPrevisao: dataAtualizada });
          handleDataPrevisaoOnChange(etapas, { ...etapa, dataPrevisao: dataAtualizada }, fields);
        }
      });
    },
    [initialValues]
  );

  return (
    <>
      <Grid style={{ paddingTop: '2rem' }} container spacing={2} xs={12}>
        <Grid item xs={12}>
          <QCXFormSubtitle title="Etapas:" />
        </Grid>
        <FieldArray name="etapas">
          {({ fields }) => (
            <>
              {fields.map((name, index) => (
                <>
                  <Grid item container spacing={1} xs={12}>
                    <Grid item container xs={12} sm={5} alignItems="center">
                      <Grid item xs={1}>
                        <CheckCircle htmlColor={getColor(values.etapas[index])} />
                      </Grid>
                      <Grid item xs={1}>
                        <QCXIconButton
                          tooltipProps={inactivateReactivateButtonTooltipProps(values.etapas[index])}
                          onClick={(event) => handleInactivateOrReactivate(event, values.etapas[index], fields, index)}
                          disabled={isCompleted(values.etapas[index]) || isConsult}
                        >
                          {isActive(values.etapas[index]) ? (
                            <DeleteForeverIcon
                              htmlColor={inactivateReactivateButtonColor(values.etapas[index])}
                              fontSize="default"
                            />
                          ) : (
                            <RestoreFromTrash
                              htmlColor={inactivateReactivateButtonColor(values.etapas[index])}
                              fontSize="default"
                            />
                          )}
                        </QCXIconButton>
                      </Grid>
                      <Grid item xs={10}>
                        <QCXTextFieldViewer
                          key="text-field-etapa-follow-up"
                          id="text-field-etapa-follow-up"
                          label={index === 0 ? 'Etapa' : undefined}
                          state={values}
                          name={[`${name}.etapa.sigla`, `${name}.description`]}
                          disableHelperText
                          disabled={isConsult || !isActive(values.etapas[index])}
                        />
                      </Grid>
                    </Grid>
                    <Grid item sx={12} sm={2} container alignItems="center">
                      <QCXFinalDateTimePickerField
                        id="date-picker-data-previsao-field"
                        key="date-picker-data-previsao-field"
                        name={`${name}.dataPrevisao`}
                        label={index === 0 ? t('com.muralis.qcx.listaFollowUp.previsao') : undefined}
                        disabled={
                          isConsult ||
                          !isActive(values.etapas[index]) ||
                          values.etapas[index].dataConclusao ||
                          values.etapas[index].bloqueiaAlteracaoData
                        }
                      />
                      <OnBlur name={`${name}.dataPrevisao`}>
                        {() => {
                          handleDataPrevisaoOnChange(values.etapas, values.etapas[index], fields);
                        }}
                      </OnBlur>
                    </Grid>
                    <Grid item sx={12} sm={2} container alignItems="center">
                      <QCXFinalDateTimePickerField
                        id="date-picker-data-realizado-field"
                        key="date-picker-data-realizado-field"
                        name={`${name}.dataConclusao`}
                        label={index === 0 ? t('com.muralis.qcx.listaFollowUp.realizado') : undefined}
                        disabled={
                          isConsult || !isActive(values.etapas[index]) || values.etapas[index].bloqueiaAlteracaoData
                        }
                      />
                      <OnBlur name={`${name}.dataConclusao`}>
                        {() => {
                          handleDataConclusaoOnChange(values.etapas, values.etapas[index], fields);
                        }}
                      </OnBlur>
                    </Grid>
                    <Grid item container sm={3} alignItems="center">
                      <Grid item xs={2}>
                        <IconButton
                          disabled={isConsult || !isActive(values.etapas[index])}
                          onClick={() => handleAlterarObservacaoEtapa(values?.etapas[index])}
                        >
                          {hasObservation(values.etapas[index]) ? (
                            <InfoIcon color="primary" />
                          ) : (
                            <InfoOutlined color="primary" />
                          )}
                        </IconButton>
                      </Grid>
                      <Grid xs={9}>
                        <QCXTextFieldViewer
                          key="text-field-processo-viewer"
                          id="text-field-processo-viewer"
                          label={index === 0 ? 'Usuário' : undefined}
                          state={values}
                          name={`${name}.usuario`}
                          disableHelperText
                          disabled={isConsult || !isActive(values.etapas[index])}
                        />
                      </Grid>
                      <Grid xs={1}>
                        <IconButton onClick={() => handleExpand(fields, index, values?.etapas[index])}>
                          {values?.etapas[index]?.open ? <ExpandLess /> : <ExpandMore />}
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid xs={12}>
                    <Collapse in={values?.etapas[index]?.open} timeout="auto" unmountOnExit>
                      <Grid style={{ padding: '1rem 3rem 3rem 3rem' }} xs={12}>
                        <EtapaOcorrenciasBondManager
                          isConsult={isConsult || !isActive(values.etapas[index])}
                          etapaId={values?.etapas[index]?.id}
                          name={`${name}.ocorrencias`}
                        />
                      </Grid>
                    </Collapse>
                  </Grid>
                </>
              ))}
            </>
          )}
        </FieldArray>
        <Grid item container spacing={4}>
          <Grid item container alignContent="center" justify="center" spacing={2} xs={12}>
            <Grid item lg={1} md={2} sm={3} xs={12}>
              {loading ? (
                <Skeleton variant="rectangular" animation="wave" width="100%" height={40} />
              ) : (
                <QCXButton
                  id="button-follow-up-documentos"
                  key="button-follow-up-documentos"
                  color="secondary"
                  size="small"
                  onClick={handleOpenModalDocumentos}
                  tooltip={!isXsDownScreen}
                  tooltipDescription={t('com.muralis.qcx.documento.labelPlural')}
                  startIcon={isXsDownScreen && <DescriptionOutlinedIcon />}
                  fullWidth
                >
                  {!isXsDownScreen && <DescriptionOutlinedIcon />}
                  {isXsDownScreen && t('com.muralis.qcx.documento.labelPlural')}
                </QCXButton>
              )}
            </Grid>
            <Grid item lg={1} md={2} sm={3} xs={12}>
              {loading ? (
                <Skeleton variant="rectangular" animation="wave" width="100%" height={40} />
              ) : (
                <QCXButton
                  id="button-follow-up-nova-etapa"
                  key="button-follow-up-nova-etapa"
                  color="secondary"
                  size="small"
                  onClick={handleOpenModalNovaEtapa}
                  tooltip={!isXsDownScreen}
                  tooltipDescription={t('com.muralis.qcx.etapa.novaEtapa')}
                  startIcon={isXsDownScreen && <ListAltOutlinedIcon />}
                  fullWidth
                >
                  {!isXsDownScreen && <ListAltOutlinedIcon />}
                  {isXsDownScreen && t('com.muralis.qcx.etapa.novaEtapa')}
                </QCXButton>
              )}
            </Grid>
            <Grid item lg={1} md={2} sm={3} xs={12}>
              {loading ? (
                <Skeleton variant="rectangular" animation="wave" width="100%" height={40} />
              ) : (
                <QCXButton
                  id="button-follow-up-observacoes-gerais"
                  key="button-follow-up-observacoes-gerais"
                  color="primary"
                  size="small"
                  onClick={handleOpenObservacoesGerais}
                  tooltip={!isXsDownScreen}
                  tooltipDescription={t('com.muralis.qcx.observacoesGerais')}
                  startIcon={isXsDownScreen && (hasAnyObservations ? <InfoIcon /> : <InfoOutlined />)}
                  fullWidth
                  disabled={!hasAnyObservations}
                >
                  {!isXsDownScreen && (hasAnyObservations ? <InfoIcon /> : <InfoOutlined />)}
                  {isXsDownScreen && t('com.muralis.qcx.observacoesGerais')}
                </QCXButton>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <QCXDocumentosFollowUpDialogManager
        model={values}
        status={formDialogDocumentosStatus}
        handleStatus={handleFormDialogDocumentosStatus}
      />
      <QCXNovaEtapaFollowUpFormDialog
        parentModel={values}
        status={formDialogEtapaFollowUpStatus}
        handleStatus={handleFormDialogEtapaFollowUpStatus}
      />
      <QCXObservacaoEtapaFollowUpFormDialog
        status={formDialogAlterarObservacaoEtapaStatus}
        handleStatus={handleFormDialogAlterarObservacaoEtapaStatus}
      />
      <QCXObservacaoGeralEtapasFollowUpDialog
        parentModel={values}
        list={values.etapas}
        status={dialogObservacaoGeraLEtapasFollowUpStatus}
        handleStatus={handleConsultObservacaoGeraLEtapasFollowUpStatus}
      />
    </>
  );
}

export default FollowUpListPhases;
