import { FaturaData } from './planilhasPage.types';
import { followUpAPI } from '../../../../features/follow-up/followUpAPI';
import { MediaType } from '../../../../utils/api/api-utils';

const createFollowUps = async (faturas: FaturaData[], importador: { id: number }, servico: { id: number }) => {
  const followUps: { id: number | null; numero: string | undefined; alreadyExists: boolean; hasError?: boolean }[] = [];

  for (const fatura of faturas) {
    try {
      const { faturapo } = fatura;
      const payload = {
        numero: faturapo,
        importador,
        servico,
        overridesTipoMascara: true,
      };
      const formData = new FormData();
      const blob = new Blob([JSON.stringify(payload)], {
        type: MediaType.APPLICATION_JSON,
      });
      formData.append('data', blob);
      const meta = { metadata: [] };
      const jsonMeta = JSON.stringify(meta);
      const blobMeta = new Blob([jsonMeta], {
        type: MediaType.APPLICATION_JSON,
      });
      formData.append('metadata', blobMeta);
      const followUpResult = await followUpAPI.register(formData);
      followUps.push(followUpResult.data);
    } catch (error: any) {
      const { hasError, message } = error.response.data;
      let followUp = {
        id: null,
        numero: fatura.faturapo,
        alreadyExists: false,
        hasError: false,
      };
      if (message == 'número do follow up já cadastrado') {
        followUp.alreadyExists = true;
        followUps.push(followUp);
      } else {
        followUp.hasError = true;
        followUps.push(followUp);
      }
    }
  }

  return followUps;
};

const planilhasPageHelpers = {
  createFollowUps,
};

export default planilhasPageHelpers;
