import React, { useMemo } from 'react';
import _ from 'lodash';
import QCXReadOnlyTextField from './QCXReadOnlyTextField';
import useFieldViewerState from '../../utils/hooks/form/field/useFieldViewerState';

export default function QCXMultilineTextFieldViewer({
  id,
  key,
  label,
  name,
  defaultValue = '',
  disableHelperText = false,
  disabled,
  ...restProps
}) {
  const state = useFieldViewerState();

  const computedValue = useMemo(() => {
    if (!name) {
      return '-';
    }

    const value = _.get(state, name);

    return value || defaultValue || '';
  }, [
    state,
    defaultValue,
  ]);

  return (
    <QCXReadOnlyTextField
      multiline
      id={id}
      key={key}
      label={label}
      value={computedValue}
      disableHelperText={disableHelperText}
      disabled={disabled}
      {...restProps}
    />
  );
}
