import React, { forwardRef, useMemo } from 'react';
import {
  IconButton,
  InputAdornment,
  makeStyles,
  Tooltip,
} from '@material-ui/core';
import {
  CloudUpload as CloudUploadIcon,
} from '@material-ui/icons';
import _, { isFunction } from 'lodash';
import { useFormState } from 'react-final-form';
import QCXFinalField from '../final-field/QCXFinalField';
import QCXInputFile from './QCXInputFile';
import i18n from '../../i18n';

const useStyles = makeStyles(() => ({
  input: {
    display: 'none',
  },
}));

const QCXFinalFieldWithRef = forwardRef((props, ref) => (
  <QCXFinalField
    {...props}
    innerRef={ref}
  />
));

const QCXInputFileWithRef = forwardRef((props, ref) => (
  <QCXInputFile
    {...props}
    forwardedRef={ref}
  />
));

export default function QCXFinalInputFile({
  id,
  key,
  label = i18n.t('com.muralis.qcx.upload'),
  name,
  accept,
  tooltipTitle = i18n.t('com.muralis.qcx.arquivo.uploadArquivo'),
  fileGetter,
  fieldProps = {},
  disabled,
  required,
  inputRef,
  children,
  ...restProps
}) {
  const classes = useStyles();

  const { values } = useFormState();

  const file = useMemo(() => {
    const object = _.get(values, name);

    if (isFunction(fileGetter)) {
      return fileGetter(object);
    }

    if (object?.length > 0) {
      return object[0];
    }

    return undefined;
  }, [
    name,
    values,
  ]);

  return (
    <>
      <QCXFinalFieldWithRef
        id={id}
        key={key}
        ref={inputRef}
        className={classes.input}
        label={label}
        name={name}
        type="file"
        accept={accept}
        disabled={disabled}
        required={required}
        {...fieldProps}
        {...restProps}
      >
        {({
          input: {
            name: inputName,
            value,
            type,
            onChange,
            ...restInputProps
          },
          innerRef,
          ...restComponentProps
        }) => (
          <QCXInputFileWithRef
            label={label}
            name={inputName}
            type={type}
            onChange={({ target }) => {
              onChange(target.files);
            }}
            required={required}
            inputProps={{
              required,
              ...restInputProps,
            }}
            ref={innerRef}
            {...restComponentProps}
          />
        )}
      </QCXFinalFieldWithRef>
      {children(
        file,
        () => (
          <Tooltip
            title={tooltipTitle}
            arrow
          >
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label
              htmlFor={id}
            >
              <InputAdornment position="end">
                <IconButton
                  color="primary"
                  component="span"
                  disabled={disabled}
                >
                  <CloudUploadIcon
                    color={disabled ? 'disabled' : 'primary'}
                  />
                </IconButton>
              </InputAdornment>
            </label>
          </Tooltip>
        )
      )}
    </>
  );
}
