import { createFilterOptions } from '@material-ui/lab/useAutocomplete';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useFormState } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchAllAsync,
  selectCFOPOperacoesFiscais,
} from '../../features/cfop-operacoes-fiscais/cfopOperacoesFiscaisSlice';
import QCXAutocompleteSelect from '../autocomplete-select/QCXAutocompleteSelect';

export default function QCXSelectCFOPOperacoesFiscaisAutocomplete({
  label,
  name,
  initialValues,
  controlled = false,
  ...restProps
}) {
  const dispatch = useDispatch();
  const list = useSelector(selectCFOPOperacoesFiscais);
  const { values } = useFormState();

  const localValue = useMemo(() => initialValues ?? values, [values, initialValues]);

  useEffect(() => {
    if (!controlled) {
      dispatch(fetchAllAsync());
    }
  }, [controlled]);

  const [defaultValue, setDefaultValue] = useState('');
  useEffect(() => {
    const value = list.find((item) => item?.id === _.get(localValue, name)?.id);
    if (value) {
      setDefaultValue(value);
    } else {
      setDefaultValue({ id: null, detalhe: '' });
    }
  }, [list, localValue]);

  const filterOptions = createFilterOptions({
    stringify: (item) => `${item?.code} - ${item?.detalhe}`,
  });
  return (
    <QCXAutocompleteSelect
      label={label}
      id={name}
      name={name}
      options={list}
      filterOptions={filterOptions}
      defaultValue={defaultValue}
      getOptionLabel={(item) => (item?.code && item?.detalhe ? `${item?.code} - ${item?.detalhe}` : '')}
      getOptionValue={(item) => item}
      {...restProps}
    />
  );
}
