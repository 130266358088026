import React, { useMemo } from 'react';
import _ from 'lodash';
import { InfoOutlined as InfoOutlinedIcon } from '@material-ui/icons';
import QCXReadOnlyTextField from './QCXReadOnlyTextField';
import useFieldViewerState from '../../utils/hooks/form/field/useFieldViewerState';
import QCXInputAdornment from '../input-adornment/QCXInputAdornment';

export default function QCXTextFieldViewer({
  id,
  key,
  label,
  name,
  defaultValue = '-',
  disableHelperText = false,
  disabled,
  state,
  ...restProps
}) {
  const defaultState = useFieldViewerState();

  const computedState = useMemo(() => state || defaultState, [state, defaultState]);

  const computedValue = useMemo(() => {
    if (!name) {
      return '-';
    }

    if (Array.isArray(name)) {
      const value = name.map((n) => _.get(computedState, n)).filter((v) => !!v).join(' - ');

      return value || defaultValue || '';
    }

    const value = _.get(computedState, name);

    return value || defaultValue || '';
  }, [
    computedState,
    defaultValue,
  ]);

  return (
    <QCXReadOnlyTextField
      id={id}
      key={key}
      label={label}
      value={computedValue}
      disableHelperText={disableHelperText}
      disabled={disabled}
      InputProps={{
        endAdornment: (
          <QCXInputAdornment
            position="end"
            style={{ paddingRight: '12px' }}
          >
            <InfoOutlinedIcon
              htmlColor="#31278385"
              fontSize="small"
            />
          </QCXInputAdornment>
        ),
      }}
      {...restProps}
    />
  );
}
