import { createFilterOptions } from '@material-ui/lab/useAutocomplete';
import _, { isFunction } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchAllAsync,
  selectRegimesTributacao,
  selectConfigControl,
} from '../../features/regime-tributacao/regimeTributacaoSlice';
import { isAutoLoadControl } from '../../utils/store/store-utils';
import QCXAutocompleteSelect from '../autocomplete-select/QCXAutocompleteSelect';

export default function QCXSelectRegimeTributacaoAutocomplete({
  label,
  name,
  initialValues,
  configureFilterPredicate,
  ...restProps
}) {
  const dispatch = useDispatch();

  const list = useSelector(selectRegimesTributacao);
  const configControl = useSelector(selectConfigControl);

  const filteredList = useMemo(() => {
    if (isFunction(configureFilterPredicate)) {
      const filterPredicate = configureFilterPredicate();
      const filteredValues = list.filter(filterPredicate);

      return filteredValues;
    }

    return list;
  }, [list, configureFilterPredicate]);

  useEffect(() => {
    if (isAutoLoadControl(configControl)) {
      dispatch(fetchAllAsync());
    }
  }, [configControl]);

  const [defaultValue, setDefaultValue] = useState('');

  useEffect(() => {
    const value = filteredList.find((item) => item?.id === _.get(initialValues, name));
    if (value) {
      setDefaultValue(value);
    } else {
      setDefaultValue({ id: null, description: '' });
    }
  }, [list, initialValues]);

  const filterOptions = createFilterOptions({
    stringify: (item) => `${item?.code} - ${item?.description}`,
  });

  return (
    <QCXAutocompleteSelect
      label={label}
      id={name}
      name={name}
      options={filteredList}
      filterOptions={filterOptions}
      defaultValue={defaultValue}
      getOptionLabel={(item) => (
        item?.code && item?.description
          ? `${item?.code} - ${item?.description}`
          : ''
      )}
      {...restProps}
    />
  );
}
