import {
  ReponseType,
} from '../../utils/api/api-utils';
import {
  doGetQCXRequest,
  doPostQCXRequest,
} from '../../utils/api/domain/domain-api-utils';
import {
  QCX_STORAGE_ENDPOINT,
  QCX_DOWNLOAD_ENDPOINT,
  QCX_FILES_ENDPOINT,
} from '../../utils/api/domain/domain-endpoint-utils';

async function downloadFileById(id, queryParams = [], config = {}) {
  return doGetQCXRequest(
    [
      QCX_STORAGE_ENDPOINT,
      QCX_DOWNLOAD_ENDPOINT,
      id
    ],
    queryParams,
    {
      ...config,
      responseType: ReponseType.ARRAY_BUFFER,
    }
  );
}

async function downloadMultipleFilesBy(data, queryParams = [], config = {}) {
  return doPostQCXRequest(
    [
      QCX_STORAGE_ENDPOINT,
      QCX_DOWNLOAD_ENDPOINT,
      QCX_FILES_ENDPOINT
    ],
    data,
    queryParams,
    {
      ...config,
      responseType: ReponseType.ARRAY_BUFFER,
    }
  );
}

const downloaderAPI = {
  downloadFileById,
  downloadMultipleFilesBy,
};

export {
  downloaderAPI,
  downloadFileById,
  downloadMultipleFilesBy,
};
