import { MediaType } from '../../utils/api/api-utils';
import {
  doGetQCXRequest,
  doPostQCXRequest,
  doPutQCXActivateRequest,
  doPutQCXDuplicarRequest,
  doPutQCXInactivateRequest,
  doPutQCXRequest,
} from '../../utils/api/domain/domain-api-utils';
import {
  FOLLOW_UP_ENDPOINT,
  ETAPAS_ENDPOINT,
  QCX_STORAGE_ENDPOINT,
  QCX_EMAIL_ENDPOINT,
  FILTER_ENDPOINT,
  PROCESS_XML_ENDPOINT,
  QCX_FATURAMENTO_ENDPOINT,
  TRANSFERIR_ENDPOINT,
  DESTINO_ENDPOINT,
  ORIGEM_ENDPOINT,
} from '../../utils/api/domain/domain-endpoint-utils';

async function fetchAll() {
  return doGetQCXRequest([
    FOLLOW_UP_ENDPOINT
  ]);
}

async function fetchByFilter(queryParams = []) {
  return doGetQCXRequest(
    [
      FOLLOW_UP_ENDPOINT,
      FILTER_ENDPOINT,
    ],
    queryParams
  );
}

async function fetchPreviewByFilter(queryParams = []) {
  return doGetQCXRequest(
    [
      FOLLOW_UP_ENDPOINT,
      'preview'
    ],
    queryParams
  );
}

async function fetchById(id) {
  return doGetQCXRequest([
    FOLLOW_UP_ENDPOINT,
    id,
  ]);
}

async function fetchAllEtapasById(id) {
  return doGetQCXRequest([
    FOLLOW_UP_ENDPOINT,
    ETAPAS_ENDPOINT,
    id,
  ]);
}

async function register(data, queryParams = [], config = {}) {
  return doPostQCXRequest(
    [FOLLOW_UP_ENDPOINT],
    data,
    queryParams,
    {
      ...config,
      headers: {
        'Content-Type': MediaType.MULTIPART_FORM_DATA,
      },
    }
  );
}

async function save(data, queryParams = [], config = {}) {
  return doPutQCXRequest(
    [FOLLOW_UP_ENDPOINT],
    data,
    queryParams,
    {
      ...config,
      headers: {
        'Content-Type': MediaType.MULTIPART_FORM_DATA,
      },
    }
  );
}

async function addDocumentTo(receiver, data, queryParams = [], config = {}) {
  return doPostQCXRequest(
    [
      FOLLOW_UP_ENDPOINT,
      QCX_STORAGE_ENDPOINT,
      receiver.id
    ],
    data,
    queryParams,
    {
      ...config,
      headers: {
        'Content-Type': MediaType.MULTIPART_FORM_DATA,
      },
    }
  );
}

async function sendEmailBy(data, queryParams = [], config = {}) {
  return doPostQCXRequest(
    [
      FOLLOW_UP_ENDPOINT,
      QCX_EMAIL_ENDPOINT,
    ],
    data,
    queryParams,
    config
  );
}

async function activateById(id) {
  return doPutQCXActivateRequest(
    [FOLLOW_UP_ENDPOINT],
    id
  );
}

async function inactivateById(id) {
  return doPutQCXInactivateRequest(
    [FOLLOW_UP_ENDPOINT],
    id
  );
}

async function duplicarById(id, subPath = [], data = null) {
  return doPutQCXDuplicarRequest(
    [FOLLOW_UP_ENDPOINT],
    id,
    subPath,
    data
  );
}

async function processXml(receiver, data, queryParams = [], config = {}) {
  return doPutQCXRequest(
    [
      FOLLOW_UP_ENDPOINT,
      PROCESS_XML_ENDPOINT,
      receiver.id
    ],
    data,
    queryParams,
    {
      ...config,
      headers: {
        'Content-Type': MediaType.MULTIPART_FORM_DATA,
      },
    }
  );
}

async function fetchAllByFaturamentoId(id) {
  return doGetQCXRequest([
    QCX_FATURAMENTO_ENDPOINT,
    TRANSFERIR_ENDPOINT,
    ORIGEM_ENDPOINT,
    id,
  ]);
}

async function fetchAllByImportadorFaturamentoId(id) {
  return doGetQCXRequest([
    QCX_FATURAMENTO_ENDPOINT,
    TRANSFERIR_ENDPOINT,
    DESTINO_ENDPOINT,
    id,
  ]);
}

const followUpAPI = {
  fetchAll,
  fetchByFilter,
  fetchById,
  fetchAllEtapasById,
  register,
  save,
  addDocumentTo,
  sendEmailBy,
  activateById,
  inactivateById,
  duplicarById,
  processXml,
  fetchAllByFaturamentoId,
  fetchAllByImportadorFaturamentoId,
  fetchPreviewByFilter,
};

export {
  followUpAPI,
  fetchAll,
  fetchByFilter,
  fetchById,
  fetchAllEtapasById,
  register,
  save,
  addDocumentTo,
  sendEmailBy,
  activateById,
  inactivateById,
  duplicarById,
  processXml,
  fetchAllByFaturamentoId,
  fetchAllByImportadorFaturamentoId,
  fetchPreviewByFilter,
};
