const CADASTROS_GERAIS_MODULE = ['cadastros', 'gerais'];
const CADASTROS_OPERACIONAIS_MODULE = ['cadastros', 'operacionais'];
const CADASTROS_FINANCEIROS_MODULE = ['cadastros', 'financeiros'];
const CADASTROS_TABELAS_ADUANEIRAS_MODULE = ['cadastros', 'tabelas-aduaneiras'];
const IMPORTACAO_MODULE = ['importacao'];
const EXPORTACAO_MODULE = ['exportacao'];
const FINANCEIRO_MODULE = ['financeiro'];

export {
  CADASTROS_GERAIS_MODULE,
  CADASTROS_OPERACIONAIS_MODULE,
  CADASTROS_FINANCEIROS_MODULE,
  CADASTROS_TABELAS_ADUANEIRAS_MODULE,
  IMPORTACAO_MODULE,
  EXPORTACAO_MODULE,
  FINANCEIRO_MODULE,
};
