const selectDeclaracoesUnicasExportacao = (state) => state.declaracaoUnicaExportacao.list;
const selectDeclaracaoUnicaExportacao = (state) => state.declaracaoUnicaExportacao.model;
const selectRelatedMercadoriaModel = (state) => state.declaracaoUnicaExportacao.related.model.mercadoria;
const selectRelatedDuplicataModel = (state) => state.declaracaoUnicaExportacao.related.model.duplicata;
const selectRelatedRegistroModel = (state) => state.declaracaoUnicaExportacao.related.model.registro;
const selectRelatedSelectionAvailableItemList = (state) =>
  state.declaracaoUnicaExportacao.related.list.selectionAvailableItem;
const selectRelatedSelectionChangedItemList = (state) =>
  state.declaracaoUnicaExportacao.related.list.selectionChangedItem;
const selectRelatedSelectionItemList = (state) => state.declaracaoUnicaExportacao.related.list.selectionItem;
const selectRelatedTransferenciaItemModel = (state) => state.declaracaoUnicaExportacao.related.model.transferenciaItem;
const selectRelatedItensXmlDanfeMetadata = (state) => state.declaracaoUnicaExportacao.related.itensXmlDanfeMetadata;
const selectConditionalSteps = (state) => state.declaracaoUnicaExportacao.config.conditionalSteps;
const selectRelatedModelFollowUp = (state) => state.declaracaoUnicaExportacao.related.model.followUp;

const selectStatus = (state) => state.declaracaoUnicaExportacao.status;
const selectRefresh = (state) => state.declaracaoUnicaExportacao.refresh;
function selectMode(state) {
  return state.declaracaoUnicaExportacao.mode.main;
}
const selectBackgroundMode = (state) => state.declaracaoUnicaExportacao.mode.background;
const selectSubMode = (state) => state.declaracaoUnicaExportacao.mode.sub;
const selectSubBackgroundMode = (state) => state.declaracaoUnicaExportacao.mode.subBackground;
const selectType = (state) => state.declaracaoUnicaExportacao.type;
const selectHistoryModel = (state) => state.declaracaoUnicaExportacao.historyModel;
const selectError = (state) => state.declaracaoUnicaExportacao.error;
const selectResponse = (state) => state.declaracaoUnicaExportacao.response;

const declaracaoUnicaExportacaoSelectors = {
  selectDeclaracoesUnicasExportacao,
  selectDeclaracaoUnicaExportacao,
  selectRelatedMercadoriaModel,
  selectRelatedDuplicataModel,
  selectRelatedRegistroModel,
  selectStatus,
  selectRefresh,
  selectMode,
  selectBackgroundMode,
  selectSubMode,
  selectSubBackgroundMode,
  selectType,
  selectHistoryModel,
  selectError,
  selectResponse,
  selectRelatedSelectionAvailableItemList,
  selectRelatedSelectionChangedItemList,
  selectRelatedSelectionItemList,
  selectRelatedTransferenciaItemModel,
  selectRelatedItensXmlDanfeMetadata,
  selectConditionalSteps,
  selectRelatedModelFollowUp,
};

export {
  declaracaoUnicaExportacaoSelectors,
  selectDeclaracoesUnicasExportacao,
  selectDeclaracaoUnicaExportacao,
  selectRelatedMercadoriaModel,
  selectRelatedDuplicataModel,
  selectRelatedRegistroModel,
  selectStatus,
  selectRefresh,
  selectMode,
  selectBackgroundMode,
  selectSubMode,
  selectSubBackgroundMode,
  selectType,
  selectHistoryModel,
  selectError,
  selectResponse,
  selectRelatedSelectionAvailableItemList,
  selectRelatedSelectionChangedItemList,
  selectRelatedSelectionItemList,
  selectRelatedTransferenciaItemModel,
  selectRelatedItensXmlDanfeMetadata,
  selectConditionalSteps,
  selectRelatedModelFollowUp,
};
