import { useMemo } from "react";
import { UseMutateFunction } from "react-query";
import { validateQueryParams } from "../../../../../utils/api/api-utils";
import {
  QueriesTypes,
  useDeleteRequest,
  useGetRequest,
  usePostRequest,
  usePutRequest,
} from "../../../../common/hooks/queries";
import { ServicoCover, ServicoProposta } from "../common/propostaServicos.type";
import propostasServicos from "./propostasServicos";

// UpdateServico
export const useUpdateServicoProposta = (): [
  UseMutateFunction<any, unknown, { [key: string]: any }>,
  boolean,
  boolean
] => {
  const { isLoading, isError, mutate } = usePutRequest(
    QueriesTypes.ServicoProposta
  );

  return [mutate, isLoading, isError];
};

// UpdateServicoForCliente

// CreateServico
export const useCreateServicoProposta = (): [
  UseMutateFunction<
    any,
    unknown,
    {
      requestPayload: {
        [key: string]: any;
      };
      queryParams?:
        | {
            clientId?: string | number;
            step?: number;
          }
        | undefined;
    }
  >,
  boolean,
  boolean
] => {
  const { isLoading, isError, mutate } = usePostRequest(
    QueriesTypes.ServicoProposta
  );

  return [mutate, isLoading, isError];
};

// CreateServicoForCliente

// DeleteServico
export const useDeleteValorServicoProposta = (): [
  UseMutateFunction<
    any,
    unknown,
    {
      id?: string | number;
      nome?: string;
      clienteId?: number;
    }
  >,
  boolean,
  boolean
] => {
  const { isLoading, isError, mutate } = useDeleteRequest(
    QueriesTypes.ServicoProposta
  );

  return [mutate, isLoading, isError];
};

// ListByName
export const useListServicoPropostaById = (
  id: number | undefined,
  queryParams: string | undefined
): [ServicoProposta[], boolean, boolean] => {
  const {
    data: propostasServicosData,
    isError,
    isLoading,
  } = useGetRequest(QueriesTypes.ServicoProposta, id, queryParams);

  const propostasServicos: ServicoProposta[] = useMemo(() => {
    if (!id) {
      return [];
    }
    if (propostasServicosData && !isError) {
      return propostasServicosData;
    } else {
      return [];
    }
  }, [propostasServicosData, isError, id]);

  return [propostasServicos, isLoading, isError];
};
