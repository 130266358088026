import { validateQueryParams } from '../../utils/api/api-utils';
import {
  doGetQCXRequest,
} from '../../utils/api/domain/domain-api-utils';
import { FILTER_ENDPOINT, FUNDAMENTACAO_ESPECIFICA_ENDPOINT } from '../../utils/api/domain/domain-endpoint-utils';

async function fetchAll() {
  return doGetQCXRequest([
    FUNDAMENTACAO_ESPECIFICA_ENDPOINT
  ]);
}

async function fetchById(id) {
  return doGetQCXRequest([
    FUNDAMENTACAO_ESPECIFICA_ENDPOINT,
    id
  ]);
}

async function fetchByFilter(params = []) {
  const validatedParams = validateQueryParams(params);

  if (validatedParams.length) {
    return doGetQCXRequest([
      FUNDAMENTACAO_ESPECIFICA_ENDPOINT,
      FILTER_ENDPOINT
    ], validatedParams);
  }

  return { data: [] };
}

const fundamentacaoEspecificaAPI = {
  fetchAll,
  fetchById,
  fetchByFilter,
};

export {
  fundamentacaoEspecificaAPI,
  fetchAll,
  fetchById,
  fetchByFilter,
};
