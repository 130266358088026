import { isStrictEquals } from '../general-utils';

const FATURAMENTO_POR_ETAPA = 'POR_ETAPA';
const FATURAMENTO_POR_DESPESA_RECEITA = 'POR_DESPESA_RECEITA';

const isFaturamentoAutomaticoBy = (values) => (
  !!values?.automatico
);

const isFaturamentoPorEtapa = (value) => (
  isStrictEquals(FATURAMENTO_POR_ETAPA, value)
);

const isFaturamentoPorDespesaReceita = (value) => (
  isStrictEquals(FATURAMENTO_POR_DESPESA_RECEITA, value)
);

const ModeloFaturamentoUtils = Object.freeze({
  POR_ETAPA: (
    FATURAMENTO_POR_ETAPA
  ),
  POR_DESPESA_RECEITA: (
    FATURAMENTO_POR_DESPESA_RECEITA
  ),
  isFaturamentoAutomatico: (
    isFaturamentoAutomaticoBy
  ),
  isPorEtapa: (
    isFaturamentoPorEtapa
  ),
  isPorDespesaReceita: (
    isFaturamentoPorDespesaReceita
  ),
});

export default ModeloFaturamentoUtils;
