import { createFilterOptions } from '@material-ui/lab/useAutocomplete';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  selectConfigControl,
  selectRecintosAduaneiros,
  fetchAllAsync,
} from '../../features/recinto-aduaneiro/recintoAduaneiroSlice';
import { isAutoLoadControl } from '../../utils/store/store-utils';
import QCXAutocompleteSelect from '../autocomplete-select/QCXAutocompleteSelect';

export default function QCXSelectRecintoAduaneiroAutocomplete({
  label,
  name,
  initialValues,
  staticValues,
  autoLoad,
  ...restProps
}) {
  const dispatch = useDispatch();

  const list = staticValues || useSelector(selectRecintosAduaneiros);
  const map = new Map(list.map((item) => [item?.id, item]));
  const uniqueList = [...map.values()];
  const configControl = useSelector(selectConfigControl);

  const [defaultValue, setDefaultValue] = useState('');

  useEffect(() => {
    if (isAutoLoadControl(configControl) || autoLoad) {
      dispatch(fetchAllAsync());
    }
  }, []);

  useEffect(() => {
    const value = list.find((item) => item?.id === _.get(initialValues, name));
    if (value) {
      setDefaultValue(value);
    } else {
      setDefaultValue({ id: null, description: '' });
    }
  }, [list, initialValues]);

  const filterOptions = createFilterOptions({
    stringify: (item) => `${item?.description} - ${item?.code}`,
  });

  return (
    <QCXAutocompleteSelect
      label={label}
      id={name}
      name={name}
      options={uniqueList}
      filterOptions={filterOptions}
      defaultValue={defaultValue}
      getOptionLabel={(item) => (item?.code && item?.description ? `${item?.description} - ${item?.code}` : '')}
      {...restProps}
    />
  );
}
