/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
import {
  Grid,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import QCXFormTitle from '../../../../../../../components/form-title/QCXFormTitle';
import QCXFormStepWizard from '../../../../../../../shared-components/form-step-wizard/QCXFormStepWizard';
import ContainerBondManager from '../../../../../../../shared-components/dados-embarque/bond-managers/ContainerBondManager';
import { selectMode } from '../../../api/dadosEmbarqueSelectors';
import { isConsultMode } from '../../../../../../../utils/store/store-utils';

export default function DadosContainerStepForm({
  initialValues,
  handleSubmit,
  isLocked,
  controlComponentProps,
  ...restProps
}) {
  const mode = useSelector(selectMode);

  const isConsult = useMemo(() => (
    isConsultMode(mode)
  ), [mode]);

  return (
    <QCXFormStepWizard
      initialValues={initialValues}
      onSubmit={handleSubmit}
      controlComponentProps={controlComponentProps}
      disablePristine
      {...restProps}
    >
      {() => (
        <>
          <QCXFormTitle
            title="Dados do Container:"
          />
          <Grid
            container
            spacing={2}
            style={{ marginTop: '1rem' }}
          >
            <Grid
              item
              container
              alignContent="flex-start"
              xs={12}
            >
              <Grid
                item
                xs={12}
              >
                <ContainerBondManager isConsult={isConsult} />
              </Grid>
            </Grid>

          </Grid>
        </>
      )}
    </QCXFormStepWizard>
  );
}
