import { Grid } from '@material-ui/core';
import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import QCXFinalForm from '../../shared-components/final-form/QCXFinalForm';
import QCXFinalTextField from '../../shared-components/final-text-field/QCXFinalTextField';
import QCXFinalSelectPerfilAutocomplete from '../../shared-components/select-perfil/QCXFinalSelectPerfilAutocomplete';
import { required as requiredValidator } from '../../utils/validators/field/validator';

export default function QCXUsuarioForm({
  initialValues,
  handleSubmit,
  handleChangeToConsult,
  handleChangeToUpdate,
  handleCancelUpdate,
  isConsult,
  isUpdate,
  authInfo = {},
  requiredRoles = [],
  ...restProps
}) {
  const { t } = useTranslation();

  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const handlePasswordChange = () => (value) => {
    setPassword(value);
  };
  const handlePasswordConfirmationChange = () => (value) => {
    setPasswordConfirmation(value);
  };
  const validOrEmptyPassword = (value) => {
    if ((_.isUndefined(value) || _.isNull(value) || _.isEmpty(value)) && !passwordConfirmation) {
      return undefined;
    }
    return requiredValidator(value);
  };
  const validOrEmptyConfirmationPassword = (value) => {
    if ((_.isUndefined(value) || _.isNull(value) || _.isEmpty(value)) && !password) {
      return undefined;
    }
    return requiredValidator(value);
  };
  const listeners = [
    { name: 'senha', fn: handlePasswordChange },
    { name: 'confirmacaoSenha', fn: handlePasswordConfirmationChange },
  ];
  return (
    <>
      <QCXFinalForm
        isConsult={isConsult}
        isUpdate={isUpdate}
        initialValues={initialValues}
        handleSubmitValues={handleSubmit}
        handleChangeToUpdate={handleChangeToUpdate}
        handleCancelUpdate={handleCancelUpdate}
        authInfo={authInfo}
        requiredRoles={requiredRoles}
        onChangeListeners={listeners}
        {...restProps}
      >
        {({ values }) => (
          <>
            <Grid item xs={12} sm={8} md={6}>
              <QCXFinalTextField
                id="text-field-nome"
                key="text-field-nome"
                name="nome"
                label={t('com.muralis.qcx.nome')}
                disabled={isConsult}
                validate={requiredValidator}
                required
              />
            </Grid>
            <Grid item xs={12} sm={8} md={6}>
              <QCXFinalTextField
                id="text-field-nome-resumido"
                key="text-field-nome-resumido"
                name="nomeResumido"
                label={t('com.muralis.qcx.nomeResumido')}
                maxLength={14}
                disabled={isConsult}
                validate={requiredValidator}
                required
              />
            </Grid>
            <Grid item xs={12} sm={8} md={6}>
              <QCXFinalTextField
                id="text-field-email"
                key="text-field-email"
                name="email"
                label={t('com.muralis.qcx.email')}
                type="email"
                disabled={isConsult}
                validate={requiredValidator}
                required
              />
            </Grid>
            <Grid item xs={12} sm={8} md={3}>
              <QCXFinalTextField
                id="text-field-password"
                key="text-field-password"
                name="senha"
                label={t('com.muralis.qcx.senha')}
                type="password"
                disabled={isConsult}
                validate={validOrEmptyPassword}
                required={!isUpdate}
              />
            </Grid>
            <Grid item xs={12} sm={8} md={3}>
              <QCXFinalTextField
                id="text-field-password-confirm"
                key="text-field-password-confirm"
                name="confirmacaoSenha"
                label={t('com.muralis.qcx.confirmarSenha')}
                type="password"
                disabled={isConsult}
                validate={validOrEmptyConfirmationPassword}
                required={!isUpdate}
              />
            </Grid>
            <Grid item xs={12} sm={8} md={6}>
              <QCXFinalTextField
                id="text-field-cargo"
                key="text-field-cargo"
                name="attributes.cargo[0]"
                label={t('com.muralis.qcx.cargo')}
                disabled={isConsult}
              />
            </Grid>
            <Grid item xs={12} sm={8} md={3}>
              <QCXFinalTextField
                id="text-field-vigencia"
                key="text-field-vigencia"
                name="attributes.vigencia[0]"
                label={t('com.muralis.qcx.vigencia')}
                disabled={isConsult}
              />
            </Grid>
            <Grid item xs={12} sm={8} md={3}>
              <QCXFinalSelectPerfilAutocomplete
                id="select-field-perfil-autocomplete"
                key="select-field-perfil-autocomplete"
                name="perfil"
                label={t('com.muralis.qcx.perfis.labelSingular')}
                disabled={isConsult}
                fieldProps={{ validate: requiredValidator }}
                initialValues={values}
                required
              />
            </Grid>
          </>
        )}
      </QCXFinalForm>
    </>
  );
}
