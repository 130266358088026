/* eslint-disable no-unused-vars */
import { Grid, makeStyles } from '@material-ui/core';
import createDecorator from 'final-form-focus';
import { isFunction } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Form } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import QCXFocusLock from '../../components/focus-lock/QCXFocusLock';
import { focusLockActions } from '../../features/focus-lock/focusLockSlice';
import QCXButton from '../button/QCXButton';
import QCXForm from '../form/QCXForm';
import QCXForceRestartFinalFormValues from './QCXForceRestartFinalFormValues';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 'inherit',
    padding: theme.spacing(2),
  },
}));

export default function QCXFinalForm({
  handleSubmitValues,
  handleAlternativeSave,
  handleChangeToUpdate,
  handleCancelUpdate,
  initialValues = {},
  validate,
  isCreate,
  isConsult,
  isUpdate,
  children,
  onChangeListeners = [],
  focusLockProps = {},
  debugOn,
  authInfo = {},
  requiredRoles = [],
  controlButtons = {
    create: {},
    edit: {},
    save: {},
    cancel: {},
  },
  disablePristine = false,
  alternativeSave = false,
  ...restProps
}) {
  const { t } = useTranslation();

  const classes = useStyles();

  const dispatch = useDispatch();

  const focusOnErrors = createDecorator();

  const [isSubmitted, setIsSubmitted] = useState(undefined);

  const createPermission = useMemo(
    () =>
      requiredRoles.every((usrRole) => {
        const role = `${usrRole}-incluir`;
        return authInfo?.roles?.includes(role);
      }),
    [authInfo, requiredRoles]
  );

  const updatePermission = useMemo(
    () =>
      requiredRoles.every((usrRole) => {
        const role = `${usrRole}-alterar`;
        return authInfo?.roles?.includes(role);
      }),
    [authInfo, requiredRoles]
  );

  const decorators = useMemo(() => [focusOnErrors], [focusOnErrors]);

  const isRestartFormValues = useMemo(() => !isSubmitted && isConsult, [isConsult, isSubmitted]);

  const handleInternalChangeToUpdate = useCallback(() => {
    handleChangeToUpdate();
    setIsSubmitted(false);
  }, [setIsSubmitted, handleChangeToUpdate]);

  const handleInternalSubmitValues = useCallback(
    (...args) => {
      setIsSubmitted(true);

      if (isFunction(handleSubmitValues)) {
        handleSubmitValues(...args);
      }
    },
    [setIsSubmitted, handleSubmitValues]
  );

  useEffect(() => {
    const handleFocusLock = () => {
      if (isConsult) {
        dispatch(focusLockActions.unlock());

        return;
      }
      if (isCreate || isUpdate) {
        dispatch(focusLockActions.lock());
      }
    };

    handleFocusLock();
  }, [isCreate, isConsult, isUpdate]);

  return (
    <QCXFocusLock disabled={isConsult} {...focusLockProps}>
      <Form
        className={classes.root}
        initialValues={initialValues}
        onSubmit={handleInternalSubmitValues}
        decorators={decorators}
        validate={validate}
        {...restProps}
      >
        {({
          form,
          initialValues: initialFormValues,
          values,
          handleSubmit,
          pristine,
          submitting,
          submitSucceeded,
          ...restFinalFormProps
        }) => (
          <QCXForm onSubmit={handleSubmit} debugOn={debugOn}>
            <QCXForceRestartFinalFormValues restart={isRestartFormValues} />
            {onChangeListeners.map((item) => (
              <OnChange name={item.name}>{item.fn(form, values, initialFormValues)}</OnChange>
            ))}
            <Grid container spacing={2}>
              {typeof children === 'function'
                ? children({
                    form,
                    initialValues: initialFormValues,
                    values,
                    pristine,
                    submitSucceeded,
                    submitting,
                    ...restFinalFormProps,
                  })
                : children}
            </Grid>
            <Grid container item xs={12} justify="flex-end" spacing={0} style={{ marginTop: '1rem' }}>
              {!isUpdate && !isConsult && !controlButtons?.create?.hidden && (
                <Grid item>
                  <QCXButton
                    color="secondary"
                    {...(alternativeSave
                      ? {
                          onClick: handleAlternativeSave,
                        }
                      : {
                          type: 'submit',
                        })}
                    tooltip
                    tooltipDescription={createPermission ? '' : t('com.muralis.qcx.validacao.usuarioSemAutorizacao')}
                    disabled={submitting || controlButtons?.create?.disabled || !createPermission}
                  >
                    {controlButtons?.create?.description || t('com.muralis.qcx.acoes.cadastrar')}
                  </QCXButton>
                </Grid>
              )}
              {isConsult && !controlButtons?.edit?.hidden && (
                <Grid item>
                  <QCXButton
                    color="secondary"
                    onClick={handleInternalChangeToUpdate}
                    disabled={controlButtons?.edit?.disabled || !updatePermission}
                    tooltip
                    tooltipDescription={updatePermission ? '' : t('com.muralis.qcx.validacao.usuarioSemAutorizacao')}
                  >
                    {controlButtons?.edit?.description || t('com.muralis.qcx.acoes.alterar')}
                  </QCXButton>
                </Grid>
              )}
              {isUpdate && !controlButtons?.save?.hidden && (
                <>
                  {!controlButtons?.cancel?.hidden && (
                    <Grid item>
                      <QCXButton
                        variant="outlined"
                        onClick={handleCancelUpdate}
                        disabled={submitting || controlButtons?.cancel?.disabled}
                      >
                        {controlButtons?.cancel?.description || t('com.muralis.qcx.acoes.cancelar')}
                      </QCXButton>
                    </Grid>
                  )}
                  <Grid item>
                    <QCXButton
                      color="secondary"
                      {...(alternativeSave
                        ? {
                            onClick: handleAlternativeSave,
                          }
                        : {
                            type: 'submit',
                          })}
                      disabled={
                        (pristine && !disablePristine) ||
                        submitting ||
                        controlButtons?.save?.disabled ||
                        !updatePermission
                      }
                      tooltip
                      tooltipDescription={updatePermission ? '' : t('com.muralis.qcx.validacao.usuarioSemAutorizacao')}
                    >
                      {controlButtons?.save?.description || t('com.muralis.qcx.acoes.salvar')}
                    </QCXButton>
                  </Grid>
                </>
              )}
            </Grid>
          </QCXForm>
        )}
      </Form>
    </QCXFocusLock>
  );
}
