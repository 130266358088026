import React, { useMemo } from 'react';
import BaseSelectWithOptions from '../base-components/BaseSelectWithOptions';

function TipoDesovaSelect({ ...props }) {
  const list = useMemo(() => [
    {
      id: 'Desova por Referência', label: 'Desova por Referência', value: 'REFERENCIA',
    }, {
      id: 'Desova por Volume', label: 'Desova por Volume', value: 'VOLUMES',
    }
  ], []);

  return (
    <BaseSelectWithOptions
      list={list}
      {...props}
    />
  );
}

export default TipoDesovaSelect;
