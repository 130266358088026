import React, {
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import {
  useHistory,
  useParams,
} from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';
import { isEmpty } from 'lodash';
import {
  changeToConsultMode,
  changeToCreateMode,
  changeToUpdateMode,
  failure,
  fetchByIdAsync,
  loading,
  resetModel,
  selectModeloInformacoesComplementares,
  selectMode,
  setError,
  setModel,
  setResponse,
  success,
  addToList,
  updateOnList,
  changeModeTo,
} from '../../../../../features/modelo-informacoes-complementares/modeloInformacoesComplementaresSlice';
import {
  isCreateMode,
  isConsultMode,
  isUpdateMode,
  isNoneMode,
} from '../../../../../utils/store/store-utils';
import { register, save } from '../../../../../features/modelo-informacoes-complementares/modeloInformacoesComplementaresAPI';
import QCXRegistrationFormPageTemplate from '../../../../../templates/registration-form-page/QCXRegistrationFormPageTemplate';

import QCXModeloInformacoesComplementaresForm from '../../../../../components/modelo-informacoes-complementares/QCXModeloInformacoesComplementaresForm';
import useAutoChangeMode from '../../../../../utils/hooks/mode/useAutoChangeMode';
import { getStringOrUndefined, parseToBoolean, parseToString } from '../../../../../utils/general/parse-utils';

export default function ModeloInformacoesComplementaresRegistrationPage({ authInfo = {} }) {
  const { t } = useTranslation();
  const { id } = useParams();

  const history = useHistory();

  const dispatch = useDispatch();

  const mode = useSelector(selectMode);

  const modeloInformacoesComplementares = useSelector(selectModeloInformacoesComplementares);

  const isNone = useMemo(() => (
    isNoneMode(mode)
  ), [mode]);

  const isCreate = useMemo(() => (
    isCreateMode(mode)
  ), [mode]);

  const isConsult = useMemo(() => (
    isConsultMode(mode)
  ), [mode]);

  const isUpdate = useMemo(() => (
    isUpdateMode(mode)
  ), [mode]);

  const fetchById = useCallback((modeloInformacoesComplementaresId) => (
    dispatch(fetchByIdAsync(modeloInformacoesComplementaresId))
  ), []);

  const handleChangeToCreate = () => {
    dispatch(changeToCreateMode());
  };

  const handleChangeToConsult = () => {
    dispatch(changeToConsultMode());
  };

  const handleChangeToUpdate = () => {
    dispatch(changeToUpdateMode());
  };

  useAutoChangeMode((currentMode) => (
    dispatch(changeModeTo(currentMode))
  ), [isUpdate]);

  useEffect(() => {
    if (!isEmpty(id)) {
      dispatch(fetchByIdAsync(id));
      handleChangeToConsult();
    }
  }, [id]);

  const handleCancelUpdate = () => {
    if (id) {
      fetchById(id);
    }
    handleChangeToConsult();
  };

  const normalize = useCallback((unnormalizedData) => {
    const normalizedData = {
      ...unnormalizedData,
      description: parseToString(unnormalizedData?.description),
      uso: parseToString(unnormalizedData?.uso),
      referenciaCliente: parseToBoolean(unnormalizedData?.referenciaCliente),
      transportadora: parseToBoolean(unnormalizedData?.transportadora),
      quadroResumoAdicoes: parseToBoolean(unnormalizedData?.quadroResumoAdicoes),
      recintoAlfandegado: parseToBoolean(unnormalizedData?.recintoAlfandegado),
      fundamentoLegalIi: parseToBoolean(unnormalizedData?.fundamentoLegalIi),
      fundamentoLegalIpi: parseToBoolean(unnormalizedData?.fundamentoLegalIpi),
      fundamentoLegalIcms: parseToBoolean(unnormalizedData?.fundamentoLegalIcms),
      despachantesAduaneiros: parseToBoolean(unnormalizedData?.despachantesAduaneiros),
      complemento: parseToString(unnormalizedData?.complemento),
    };

    return normalizedData;
  }, []);

  const unnormalize = useCallback((normalizedData) => {
    const unnormalizedData = {
      ...normalizedData,
      id: normalizedData?.id,
      description: getStringOrUndefined(normalizedData?.description),
      uso: getStringOrUndefined(normalizedData?.uso),
      referenciaCliente: parseToBoolean(normalizedData?.referenciaCliente),
      transportadora: parseToBoolean(normalizedData?.transportadora),
      quadroResumoAdicoes: parseToBoolean(normalizedData?.quadroResumoAdicoes),
      recintoAlfandegado: parseToBoolean(normalizedData?.recintoAlfandegado),
      fundamentoLegalIi: parseToBoolean(normalizedData?.fundamentoLegalIi),
      fundamentoLegalIpi: parseToBoolean(normalizedData?.fundamentoLegalIpi),
      fundamentoLegalIcms: parseToBoolean(normalizedData?.fundamentoLegalIcms),
      despachantesAduaneiros: parseToBoolean(normalizedData?.despachantesAduaneiros),
      complemento: getStringOrUndefined(normalizedData?.complemento),
    };

    return unnormalizedData;
  }, []);

  const handleDispatchSetModel = useCallback((data) => {
    const normalizedData = normalize(data);

    dispatch(
      setModel(normalizedData)
    );
  }, [normalize]);

  const create = async (data) => {
    const executeDebounced = debounce(async () => {
      try {
        const response = await register(data);

        if (response?.status === 201) {
          dispatch(resetModel());

          const created = response?.data;

          handleDispatchSetModel(created);
          dispatch(addToList({ data: created }));

          const handleResultWithDebounce = debounce(() => {
            history.push(t('com.muralis.qcx.url.moduloOperacionaisModelosInformacoesComplementares'));

            dispatch(success());
            dispatch(setResponse({
              status: response.status,
              data: created,
              message: t('com.muralis.qcx.mensagem.modeloInformacoesComplementaresCadastrado', { modeloInformacoes: data?.description }),
            }));
          }, 500);

          handleResultWithDebounce();
        }
      } catch (error) {
        const defaultErrorMessage = t('com.muralis.qcx.erro.erroCadastrarModeloInformacoesComplementares', { modeloInformaca: data?.description });
        const errorMessage = error?.response?.data?.message
          ? `${defaultErrorMessage}: ${error?.response?.data?.message}.`
          : `${defaultErrorMessage}.`;

        dispatch(failure());
        dispatch(setError({
          message: errorMessage,
        }));
      }
    }, 500);

    dispatch(loading());
    executeDebounced();
  };

  const update = async (data) => {
    const executeDebounced = debounce(async () => {
      try {
        const response = await save(data);

        if (response?.status === 200) {
          const handleResultWithDebounce = debounce(() => {
            handleChangeToConsult();
            dispatch(success());

            const saved = response?.data;

            dispatch(setResponse({
              status: response.status,
              data: saved,
              message: t('com.muralis.qcx.mensagem.modeloInformacoesComplementaresSalvo', { modeloInformacao: saved?.description }),
            }));

            dispatch(setModel(saved));
            dispatch(updateOnList({ data: saved }));
            dispatch(fetchByIdAsync(saved?.id));
          }, 500);

          handleResultWithDebounce();
          handleChangeToConsult();
        }
      } catch (error) {
        const defaultErrorMessage = t('com.muralis.qcx.erro.erroSalvarModeloInformacoesComplementares', { modeloInformaca: data?.description });
        const errorMessage = error?.response?.data?.message
          ? `${defaultErrorMessage}: ${error?.response?.data?.message}.`
          : `${defaultErrorMessage}.`;

        dispatch(failure());
        dispatch(setError({
          message: errorMessage,
        }));
      }
    }, 500);

    dispatch(loading());
    executeDebounced();
  };

  const handleSubmit = async (data) => {
    const normalizedData = normalize(data);

    if (isUpdate) {
      await update(normalizedData);
    } else {
      await create(normalizedData);
    }
  };

  const model = useMemo(() => (
    !isCreate
      ? unnormalize(modeloInformacoesComplementares)
      : {}
  ), [
    isCreate,
    modeloInformacoesComplementares,
    unnormalize,
  ]);

  const actionName = useMemo(() => {
    if (isCreate || isNone) {
      return t('com.muralis.qcx.acoes.novo');
    }
    if (isConsult) {
      return t('com.muralis.qcx.acoes.visualizar');
    }
    return t('com.muralis.qcx.acoes.alterar');
  }, [isNone, isCreate, isConsult]);

  const breadcrumbs = useMemo(() => ([
    {
      link: {
        to: '/',
        name: t('com.muralis.qcx.inicio'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.cadastros'),
      },
    },
    {
      link: {
        to: t('com.muralis.qcx.url.moduloOperacionais'),
        name: t('com.muralis.qcx.operacionais'),
      },
    },
    {
      link: {
        to: t('com.muralis.qcx.url.moduloOperacionaisModelosInformacoesComplementares'),
        name: t('com.muralis.qcx.modeloInformacaoComplementar.labelPlural'),
      },
    },
    {
      text: {
        name: actionName,
      },
    },
  ]), [actionName]);

  const pageTitle = useMemo(() => (
    isNone || isCreate
      ? t('com.muralis.qcx.modeloInformacaoComplementar.novoModeloInformacoesComplementares')
      : t('com.muralis.qcx.modeloInformacaoComplementar.modeloInformacoesComplementaresExistente', { ID: modeloInformacoesComplementares?.nome || modeloInformacoesComplementares?.id || '-' })
  ), [isNone, isCreate, modeloInformacoesComplementares]);

  return (
    <QCXRegistrationFormPageTemplate
      pageTitle={pageTitle}
      breadcrumbs={breadcrumbs}
      isCreate={isCreate}
      isConsult={isConsult}
      isUpdate={isUpdate}
      handleChangeToCreate={handleChangeToCreate}
      handleChangeToConsult={handleChangeToConsult}
      handleChangeToUpdate={handleChangeToUpdate}
      handleCancelUpdate={handleCancelUpdate}
      authInfo={authInfo}
    >
      {(formProps) => (
        <QCXModeloInformacoesComplementaresForm
          initialValues={model}
          handleSubmit={handleSubmit}
          handleChangeModel={handleDispatchSetModel}
          authInfo={authInfo}
          requiredRoles={['modelo-informacoes-complementares']}
          {...formProps}
        />
      )}
    </QCXRegistrationFormPageTemplate>
  );
}
