import { createFilterOptions } from '@material-ui/lab/useAutocomplete';
import _ from 'lodash';
import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
} from 'react';
import { getOptionByValueInList } from '../../utils/general/general-utils';
import QCXAutocompleteSelect from '../autocomplete-select/QCXAutocompleteSelect';

const list = [
  {
    label: 'SEM VINCULACAO',
    value: 'SEM_VINCULACAO',
  },
  {
    label: 'COM VINCULACAO COM INFLUENCIA PRECO',
    value: 'COM_VINCULACAO_COM_INFLUENCIA_PRECO',
  },
  {
    label: 'COM VINCULACAO SEM INFLUENCIA PRECO',
    value: 'COM_VINCULACAO_SEM_INFLUENCIA_PRECO',
  },
];

export default function QCXSelectVinculoCompradorVendedorAutocomplete({
  label,
  name,
  initialValues,
  ...restProps
}) {
  const [defaultValue, setDefaultValue] = useState('');

  useEffect(() => {
    const defaultInitialValue = _.get(initialValues, name);

    const option = list.find((item) => item?.value === defaultInitialValue);

    if (option) {
      setDefaultValue(option);
    } else {
      setDefaultValue('');
    }
  }, [list, initialValues]);

  const filterOptions = useMemo(() => (
    createFilterOptions({
      stringify: (option) => (
        option.label + option.value
      ),
    })
  ), []);

  const getOptionLabel = useCallback((item) => (
    item?.label || ''
  ), []);

  const getOptionValue = useCallback((item) => (
    item?.value || ''
  ), []);

  return (
    <QCXAutocompleteSelect
      label={label}
      id={name}
      name={name}
      options={list}
      filterOptions={filterOptions}
      defaultValue={defaultValue}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      {...restProps}
    />
  );
}

function getVinculoCompradorVendedorLabelByValue(value) {
  const foundOption = getOptionByValueInList(list, value);
  return foundOption && foundOption?.label;
}

export {
  getVinculoCompradorVendedorLabelByValue,
};
