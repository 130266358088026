import { SingletonUtils } from '../general-utils';

const REINICIA = 'REINICIA';
const NAO_REINICIA = 'NAO_REINICIA';

const isNaoReinicia = (value) => (
  NAO_REINICIA === value
);

const isReinicia = (value) => (
  REINICIA === value
);

const parseFromSingletonList = (singletonList = []) => (
  SingletonUtils.fromList(
    isReinicia,
    NAO_REINICIA,
    singletonList
  )
);

const parseToSingletonList = (value) => (
  SingletonUtils.toList(
    isNaoReinicia,
    value
  )
);

const ControleContagemUtils = {
  REINICIA,
  NAO_REINICIA,
  isNaoReinicia,
  isReinicia,
  parseFromSingletonList,
  parseToSingletonList,
};

export default ControleContagemUtils;
