import PageLayout from "../../../../common/layouts/pageLayout";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import { useParams } from "react-router-dom";
import {
  Button,
  Divider,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import FormHeader from "../../../../common/components/forms/formHeader";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { selectUnidadeSelecionada } from "../../../../../features/usuario-logado/usuarioLogadoSlice";
import LoadingIndicator from "../../../../common/components/loadingIndicator";
import FormHeaderMedium from "../../../../common/components/forms/formHeaderMedium";
import DataTable from "../../../../common/components/dataTable";
import {
  GridActionsCellItem,
  GridCellParams,
  GridColDef,
  GridValueFormatterParams,
} from "@mui/x-data-grid";
import { ModalProposta } from "../propostasNewPage/components/firstPage";
import SelectStyled from "../../../../common/components/input/selectStyled";
import DeleteIcon from "@mui/icons-material/Delete";
import { brlFormatter } from "../../../../common/utils/formatters/currencyFormatters";
import { ServicoCover, ServicoProposta } from "../common/propostaServicos.type";
import {
  useCreateServicoProposta,
  useDeleteValorServicoProposta,
  useListServicoPropostaById,
  useUpdateServicoProposta,
} from "./propostasServicos.hooks";
import {
  useListAllServicoProposta,
  useListPropostaMercadoria,
} from "../common/hooks";
import { useListClientes } from "../../../../common/hooks/useListClientes";

const PropostasServicos = () => {
  const globalClient = {
    id: 0,
    value: "Global",
  };
  const [selectedClient, setSelectedClient] = useState(globalClient);
  const [clientes] = useListClientes();

  const [servicos, isLoading] = useListAllServicoProposta(selectedClient.id);
  const [createServico] = useCreateServicoProposta();
  const [deleteServico] = useDeleteValorServicoProposta();
  const [updateServico] = useUpdateServicoProposta();

  const [selectedServico, setSelectedServico] = useState<ServicoCover>();
  const [valoresServicoSelecionado] = useListServicoPropostaById(
    selectedServico?.id,
    selectedClient.id === 0 ? undefined : `clienteId=${selectedClient.id}`
  );

  const [novoServico, setNovoServico] = useState<Partial<ServicoProposta>>({});

  const [mercadorias, isLoadingMercadorias, hasErrorInMercadorias] =
    useListPropostaMercadoria();

  const valoresColumns: GridColDef[] = [
    {
      field: "nome",
      headerName: "Nome",
      flex: 10,
      headerClassName: "header-theme",
    },
    {
      field: "valor",
      headerName: "Valor",
      flex: 2,
      headerClassName: "header-theme",
      align: "right",
      headerAlign: "right",
      valueFormatter: (params: GridValueFormatterParams<any>) => {
        return brlFormatter.format(params.value);
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Ações",
      headerClassName: "header-theme",
      // @ts-ignore
      getActions: (params: any) => [
        <GridActionsCellItem
          icon={
            <DeleteIcon
              color={isValorCliente(params.row.nome) ? "error" : "disabled"}
            />
          }
          label="Deletar"
          onClick={() =>
            isValorCliente(params.row.nome)
              ? handleValorServicoDeletion(params.row.nome)
              : () => {}
          }
          showInMenu
        />,
      ],
    },
  ];

  const isValorCliente = (nomeValor: string) => {
    if (selectedClient.id === 0) {
      return true;
    }

    const valor = valoresServicoSelecionado.find(
      (v) => v.nomeValor === nomeValor
    )!;

    return valor ? valor.clienteId !== undefined : false;
  };

  const isServicoCliente = (id: number) => {
    if (selectedClient.id === 0) {
      return true;
    }

    const valor = valoresServicoSelecionado.find((v) => v.id === id)!;

    return valor ? valor.clienteId !== undefined : false;
  };

  const servicosColumns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      flex: 1,
      headerClassName: "header-theme",
      type: "number",
    },
    {
      field: "servico",
      headerName: "Serviço",
      flex: 6,
      headerClassName: "header-theme",
    },
    {
      field: "modal",
      headerName: "Modal",
      flex: 2,
      headerClassName: "header-theme",
    },
    {
      field: "mercadoria",
      headerName: "Mercadoria",
      flex: 3,
      headerClassName: "header-theme",
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Ações",
      headerClassName: "header-theme",
      // @ts-ignore
      getActions: (params: any) => [
        <GridActionsCellItem
          icon={
            <DeleteIcon
              color={isServicoCliente(params.row.id) ? "error" : "disabled"}
            />
          }
          label="Deletar"
          onClick={
            isServicoCliente(params.row.id)
              ? () => handleServicoDeletion(params.row.id)
              : () => {}
          }
          showInMenu
        />,
      ],
    },
  ];

  const modais: ModalProposta[] = [
    {
      id: 1,
      name: "Aéreo",
    },
    {
      id: 2,
      name: "Ferroviário",
    },
    {
      id: 3,
      name: "Marítimo",
    },
    {
      id: 4,
      name: "Rodoviário",
    },
    {
      id: 5,
      name: "N/A",
    },
  ];

  const handleServicoAddition = async () => {
    if (
      !novoServico?.nome ||
      !novoServico?.mercadoria ||
      !novoServico?.modal ||
      !novoServico?.valor ||
      !novoServico.nomeValor
    ) {
      return;
    }

    let payload: Omit<ServicoProposta, "id"> = novoServico as ServicoProposta;

    let query = {
      clientId: selectedClient.id,
    };

    query.clientId !== 0
      ? createServico({ requestPayload: payload, queryParams: query })
      : createServico({ requestPayload: payload });

    setNovoServico({});
  };

  const handleServicoDeletion = async (id: number) => {
    deleteServico({ id });
  };

  const handleValorServicoDeletion = async (nomeValor: string) => {
    deleteServico({
      id: selectedServico!.id,
      nome: nomeValor,
      clienteId: selectedClient.id,
    });
  };

  const handleServicoEdition = async () => {
    updateServico({ id: selectedServico!.id, ...novoServico });
  };

  const onServicoCellClick = (params: GridCellParams) => {
    const selectedServico = servicos.find((s) => s.id == params.row.id)!;

    setSelectedServico(selectedServico);
    setNovoServico(selectedServico);
  };

  const selectedMercadoria = useMemo(() => {
    const mercadoria = mercadorias.find(
      (m) => m.nome === novoServico?.mercadoria
    );
    if (mercadoria) {
      const selected = { id: mercadoria?.id, value: mercadoria?.nome };
      return selected;
    }

    return null;
  }, [novoServico?.mercadoria, mercadorias]);

  const selectedModal = useMemo(() => {
    const modal = modais.find((m) => m.name === novoServico?.modal);
    if (modal) {
      const selected = { id: modal.id, value: modal.name };
      return selected;
    }

    return null;
  }, [novoServico?.modal]);

  return (
    <PageLayout
      title={"Propostas - Mercadorias"}
      icon={<HistoryEduIcon color={"secondary"} />}
    >
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <Grid container spacing={2} padding={2}>
          <FormHeader marginTop="0px">Cadastro de Serviços</FormHeader>
          <Grid item xs={4}>
            <SelectStyled
              key={"ClientSelect"}
              controlledValue={selectedClient}
              options={clientes.map((cliente) => {
                return { id: cliente.id, value: cliente.description };
              })}
              onChangeAction={(e, value) => {
                if (value === null) {
                  setSelectedClient(globalClient);
                } else {
                  setSelectedClient(value as any);
                }
              }}
              label={"Cliente"}
            ></SelectStyled>
          </Grid>
          <FormHeaderMedium>Serviços Cadastrados</FormHeaderMedium>
          <Grid item xs={12}>
            <DataTable
              onCellClick={onServicoCellClick}
              columns={servicosColumns}
              rows={
                servicos.map((servico) => ({
                  id: servico.id,
                  servico: servico.nome,
                  mercadoria: servico.mercadoria,
                  modal: servico.modal,
                })) ?? []
              }
            ></DataTable>
          </Grid>
          <FormHeaderMedium>Valores do Serviço</FormHeaderMedium>

          <Grid item xs={12}>
            {valoresServicoSelecionado.length > 0 ? (
              <DataTable
                columns={valoresColumns}
                rows={
                  valoresServicoSelecionado.map((item, index) => ({
                    id: index,
                    nome: item.nomeValor,
                    valor: item.valor,
                  })) ?? []
                }
              ></DataTable>
            ) : (
              <Typography>
                Selecione um serviço acima para visualizar os valores
              </Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <Divider></Divider>
          </Grid>

          <FormHeader>
            {selectedServico ? "Editar serviço" : "Novo serviço"}
          </FormHeader>
          <Grid item xs={4}>
            <FormControl fullWidth required>
              <TextField
                id="name"
                label="Nome"
                type="text"
                size="small"
                value={novoServico?.nome ?? ""}
                onChange={(e) =>
                  setNovoServico({ ...novoServico, nome: e.target.value })
                }
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth required>
              <SelectStyled
                key={novoServico?.mercadoria?.toString()}
                controlledValue={selectedMercadoria}
                options={mercadorias.map((mercadoria) => {
                  return { id: mercadoria.id, value: mercadoria.nome };
                })}
                onChangeAction={(e, value) => {
                  setNovoServico({ ...novoServico, mercadoria: value?.value });
                }}
                label={"Mercadoria"}
              ></SelectStyled>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth required>
              <SelectStyled
                key={novoServico?.modal?.toString()}
                controlledValue={selectedModal}
                options={modais.map((modal) => {
                  return { id: modal.id, value: modal.name };
                })}
                onChangeAction={(e, value) =>
                  setNovoServico({ ...novoServico, modal: value?.value })
                }
                label={"Modal"}
              ></SelectStyled>
            </FormControl>
          </Grid>
          <FormHeaderMedium>Valores do Serviço</FormHeaderMedium>
          <Grid item xs={8}>
            <FormControl fullWidth required>
              <TextField
                id="name-valor"
                label="Nome"
                type="text"
                size="small"
                value={novoServico.nomeValor ?? ""}
                onChange={(e) =>
                  setNovoServico({ ...novoServico, nomeValor: e.target.value })
                }
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl fullWidth required>
              <TextField
                id="valor"
                label="Valor"
                type="number"
                size="small"
                value={novoServico.valor ?? ""}
                onChange={(e) =>
                  setNovoServico({
                    ...novoServico,
                    valor: Number(e.target.value),
                  })
                }
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <Button
              disabled={false}
              variant="contained"
              color="secondary"
              fullWidth
              onClick={handleServicoAddition}
            >
              Incluir Valor
            </Button>
          </Grid>
          <Grid item xs={12} textAlign={"right"}>
            {selectedServico && isServicoCliente(selectedServico.id) && (
              <Button
                variant="contained"
                color="secondary"
                sx={{ marginRight: "10px" }}
                onClick={() => {
                  setSelectedServico(undefined);
                  setNovoServico({});
                }}
              >
                Cancelar
              </Button>
            )}
            {selectedServico && isServicoCliente(selectedServico.id) && (
              <Button
                variant="contained"
                color="secondary"
                disabled={
                  !novoServico?.nome ||
                  !novoServico?.mercadoria ||
                  !novoServico?.modal
                }
                onClick={handleServicoEdition}
              >
                Salvar
              </Button>
            )}
          </Grid>
        </Grid>
      )}
    </PageLayout>
  );
};

export default PropostasServicos;
