import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import {
  CONSULT_MODE,
  CREATE_MODE,
  UPDATE_MODE,
  REVERSAL_MODE,
  LOCKED_MODE,
  NONE_MODE,
  BACKGROUND_CREATE_MODE,
  BACKGROUND_UPDATE_MODE,
  BACKGROUND_DELETE_MODE,
  BACKGROUND_REVERSAL_MODE,
  NONE_BACKGROUND_MODE,
  SUB_CREATE_MODE,
  SUB_CONSULT_MODE,
  SUB_UPDATE_MODE,
  NONE_SUB_MODE,
  BACKGROUND_CONSULT_MODE,
  NONE_SUB_BACKGROUND_MODE,
  SUB_BACKGROUND_CREATE_MODE,
  SUB_BACKGROUND_CONSULT_MODE,
  SUB_BACKGROUND_UPDATE_MODE,
  SUB_BACKGROUND_DELETE_MODE,
} from '../mode';
import { NO_REFRESH, REFRESH } from '../refresh';
import {
  ALTERNATIVE_LOADING_STATUS,
  FAILURE_STATUS,
  IDLE_STATUS,
  LOADING_STATUS,
  PREPARING_ACTION_STATUS,
  SUCCESS_STATUS,
} from '../status';
import {
  fetchAllAsync,
  fetchByIdAsync,
  fetchAllNonFaturadoAndLiquidadoAsync,
  fetchAllSolicitadasAsync,
} from './solicitacaoPagamentoThunks';

import i18n from '../../i18n';

const initialState = {
  status: IDLE_STATUS,
  mode: {
    main: NONE_MODE,
    background: NONE_BACKGROUND_MODE,
    sub: NONE_SUB_MODE,
    subBackground: NONE_SUB_BACKGROUND_MODE,
    lastSubBackground: undefined,
  },
  type: '',
  refresh: NO_REFRESH,
  error: null,
  response: {
    status: null,
    message: null,
    data: null,
  },
  config: {
  },
  list: [],
  model: {
    code: undefined,
    followUp: undefined,
    tipo: undefined,
  },
  related: {
    report: {},
    model: {
      mercadoria: {},
      registro: {},
      followUp: {},
      balances: {},
    },
    list: {
      selectionAvailableItem: [],
      selectionChangedItem: [],
      selectionItem: [],
    },
  },
  historyModel: {
    last: {},
  },
};

const solicitacaoPagamentoSlice = createSlice({
  name: 'solicitacaoPagamento',
  initialState,
  reducers: {
    changeStatusTo: (state, action) => {
      state.status = action.payload.status;
    },
    loading: (state) => {
      state.status = LOADING_STATUS;
    },
    success: (state) => {
      state.status = SUCCESS_STATUS;
    },
    failure: (state) => {
      state.status = FAILURE_STATUS;
    },
    preparingAction: (state) => {
      state.status = PREPARING_ACTION_STATUS;
    },
    resetStatus: (state) => {
      state.status = IDLE_STATUS;
    },
    refresh: (state) => {
      state.refresh = REFRESH;
    },
    resetRefresh: (state) => {
      state.refresh = NO_REFRESH;
    },
    changeModeTo: (state, action) => {
      state.mode.main = action.payload;
    },
    changeToCreateMode: (state) => {
      state.mode.main = CREATE_MODE;
    },
    changeToConsultMode: (state) => {
      state.mode.main = CONSULT_MODE;
    },
    changeToUpdateMode: (state) => {
      state.mode.main = UPDATE_MODE;
    },
    changeToReversalMode: (state) => {
      state.mode.main = REVERSAL_MODE;
    },
    changeToLockedMode: (state) => {
      state.mode.main = LOCKED_MODE;
    },
    resetMode: (state) => {
      state.mode.main = NONE_MODE;
    },
    changeToBackgroundCreateMode: (state) => {
      state.mode.background = BACKGROUND_CREATE_MODE;
    },
    changeToBackgroundConsultMode: (state) => {
      state.mode.background = BACKGROUND_CONSULT_MODE;
    },
    changeToBackgroundUpdateMode: (state) => {
      state.mode.background = BACKGROUND_UPDATE_MODE;
    },
    changeToBackgroundDeleteMode: (state) => {
      state.mode.background = BACKGROUND_DELETE_MODE;
    },
    changeToBackgroundReversalMode: (state) => {
      state.mode.background = BACKGROUND_REVERSAL_MODE;
    },
    resetBackgroundMode: (state) => {
      state.mode.background = NONE_BACKGROUND_MODE;
    },
    setRelatedReportModel: (state, action) => {
      state.related.model.report = {
        ...action.payload,
      };
    },
    resetRelatedReportModel: (state) => {
      state.related.model.report = initialState.related.model.report;
    },
    changeToSubCreateMode: (state) => {
      state.mode.sub = SUB_CREATE_MODE;
    },
    changeToSubConsultMode: (state) => {
      state.mode.sub = SUB_CONSULT_MODE;
    },
    changeToSubUpdateMode: (state) => {
      state.mode.sub = SUB_UPDATE_MODE;
    },
    resetSubMode: (state) => {
      state.mode.sub = NONE_SUB_MODE;
    },
    changeToSubBackgroundCreateMode: (state) => {
      state.mode.lastSubBackground = state.mode.subBackground;
      state.mode.subBackground = SUB_BACKGROUND_CREATE_MODE;
    },
    changeToSubBackgroundConsultMode: (state) => {
      state.mode.lastSubBackground = state.mode.subBackground;
      state.mode.subBackground = SUB_BACKGROUND_CONSULT_MODE;
    },
    changeToSubBackgroundUpdateMode: (state) => {
      state.mode.lastSubBackground = state.mode.subBackground;
      state.mode.subBackground = SUB_BACKGROUND_UPDATE_MODE;
    },
    changeToSubBackgroundDeleteMode: (state) => {
      state.mode.lastSubBackground = state.mode.subBackground;
      state.mode.subBackground = SUB_BACKGROUND_DELETE_MODE;
    },
    resetSubBackgroundMode: (state) => {
      state.mode.lastSubBackground = state.mode.subBackground;
      state.mode.subBackground = NONE_SUB_BACKGROUND_MODE;
    },
    changeToLastSubBackgroundMode: (state) => {
      if (state.mode.lastSubBackground) {
        state.mode.subBackground = state.mode.lastSubBackground;
      }
    },
    changeTypeTo: (state, action) => {
      state.type = action.payload;
    },
    changeLastHistoryModel: (state, action) => {
      state.historyModel.last = action.payload;
    },
    resetHistoryModel: (state) => {
      state.historyModel = {};
    },
    resetLastHistoryModel: (state) => {
      state.historyModel.last = {};
    },
    setModel: (state, action) => {
      state.model = {
        ...action.payload,
      };
    },
    setProcesso: (state, action) => {
      state.model.followUp = action.payload;
    },
    setTipo: (state, action) => {
      state.model.tipo = action.payload;
    },
    updateSelectedMercadoria: (state) => {
      const currentProdutoPartnumber = state.related.model?.mercadoria?.produto?.partnumber;
      if (!_.isEmpty(currentProdutoPartnumber)) {
        const { mercadorias } = state.model;
        const mercadoria = mercadorias.find((item) => (
          item?.produto?.partnumber === currentProdutoPartnumber));

        state.related.model.mercadoria = mercadoria;
      }
    },
    resetModel: (state) => {
      state.model = initialState.model;
    },
    addToList: (state, action) => {
      state.list = [...state.list, action.payload.data];
    },
    updateOnList: (state, action) => {
      state.list = state.list.map((current) => {
        if (current.id === action.payload.data?.id) {
          return action.payload.data;
        }
        return current;
      });
    },
    setRelatedMercadoriaModel: (state, action) => {
      state.related.model.mercadoria = {
        ...action.payload,
      };
    },
    resetRelatedMercadoriaModel: (state) => {
      state.related.model.mercadoria = {};
    },
    setRelatedDuplicataModel: (state, action) => {
      state.related.model.duplicata = {
        ...action.payload,
      };
    },
    resetRelatedDuplicataModel: (state) => {
      state.related.model.duplicata = {};
    },
    setRelatedRegistroModel: (state, action) => {
      state.related.model.registro = {
        ...action.payload,
      };
    },
    resetRelatedRegistroModel: (state) => {
      state.related.model.registro = {};
    },
    setResponse: (state, action) => {
      state.response = action.payload;
    },
    clearResponse: (state) => {
      state.response = {
        status: null,
        message: null,
        data: null,
      };
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    clearError: (state) => {
      state.error = initialState.error;
    },
    setRelatedModelFollowUp: (state, action) => {
      state.related.model.followUp = action.payload;
    },
    resetRelatedModelFollowUp: (state) => {
      state.related.model.followUp = initialState.related.model.followUp;
    },
    setRelatedSelectionAvailableItemList: (state, action) => {
      state.related.list.selectionAvailableItem = action.payload;
    },
    resetRelatedSelectionAvailableItemList: (state) => {
      state.related.list.selectionAvailableItem = [];
    },
    setRelatedSelectionChangedItemList: (state, action) => {
      state.related.list.selectionChangedItem = action.payload;
    },
    resetRelatedSelectionChangedItemList: (state) => {
      state.related.list.selectionChangedItem = [];
    },
    setRelatedSelectionItemList: (state, action) => {
      state.related.list.selectionItem = action.payload;
    },
    resetRelatedSelectionItemList: (state) => {
      state.related.list.selectionItem = [];
    },
    setRelatedTransferenciaItem: (state, action) => {
      state.related.model.transferenciaItem = action.payload;
    },
    resetRelatedTransferenciaItem: (state) => {
      state.related.model.transferenciaItem = initialState.related.model.transferenciaItem;
    },
    setRelatedItensXmlDanfeMetadata: (state, action) => {
      state.related.itensXmlDanfeMetadata = action.payload;
    },
    resetRelatedItensXmlDanfeMetadata: (state) => {
      state.related.itensXmlDanfeMetadata = initialState.related.itensXmlDanfeMetadata;
    },
    addItemToChangedSelectionList: (state, action) => {
      const item = action.payload;

      if (state.related.list.selectionChangedItem?.indexOf(item) === -1) {
        state.related.list.selectionChangedItem = [
          ...state.related.list.selectionChangedItem,
          item,
        ];
      }
    },
    removeItemFromChangedSelectionList: (state, action) => {
      state.related.list.selectionChangedItem = state.related.list.selectionChangedItem
        ?.filter((item) => (
          item !== action.payload
        ));
    },
    setBalances: (state, action) => {
      state.related.model.balances = {
        ...action.payload,
      };
    },
    resetBalances: (state) => {
      state.related.model.balances = initialState.related.model.balances;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchByIdAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(fetchByIdAsync.fulfilled, (state, action) => {
        if (action.payload.response.status === 200) {
          state.model = {
            ...action.payload.response.data,
          };
          state.status = SUCCESS_STATUS;
        }

        if (action.payload.response.status === 204) {
          state.status = FAILURE_STATUS;
        }
      })
      .addCase(fetchByIdAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroCarregarSolicitacaoPagamento', { erro: action.error.message }),
        };
      })
      .addCase(fetchAllAsync.pending, (state) => {
        state.status = ALTERNATIVE_LOADING_STATUS;
      })
      .addCase(fetchAllAsync.fulfilled, (state, action) => {
        state.status = IDLE_STATUS;
        state.list = action.payload.data;
      })
      .addCase(fetchAllAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroCarregarListaSolicitacaoPagamento', { erro: action.error.message }),
        };
        state.refresh = NO_REFRESH;
      })
      .addCase(fetchAllNonFaturadoAndLiquidadoAsync.pending, (state) => {
        state.status = ALTERNATIVE_LOADING_STATUS;
      })
      .addCase(fetchAllNonFaturadoAndLiquidadoAsync.fulfilled, (state, action) => {
        state.status = IDLE_STATUS;
        state.list = action.payload.data;
      })
      .addCase(fetchAllNonFaturadoAndLiquidadoAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroCarregarListaSolicitacaoPagamento', { erro: action.error.message }),
        };
        state.refresh = NO_REFRESH;
      })
      .addCase(fetchAllSolicitadasAsync.pending, (state) => {
        state.status = ALTERNATIVE_LOADING_STATUS;
      })
      .addCase(fetchAllSolicitadasAsync.fulfilled, (state, action) => {
        state.status = IDLE_STATUS;
        state.list = action.payload.data;
      })
      .addCase(fetchAllSolicitadasAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroCarregarListaSolicitacaoAprovacaoPagamento', { erro: action.error.message }),
        };
        state.refresh = NO_REFRESH;
      });
  },
});

const {
  changeStatusTo,
  loading,
  success,
  failure,
  preparingAction,
  resetStatus,
  refresh,
  resetRefresh,
  changeModeTo,
  changeToCreateMode,
  changeToConsultMode,
  changeToUpdateMode,
  changeToTransferMode,
  changeToReversalMode,
  changeToLockedMode,
  resetMode,
  changeToBackgroundCreateMode,
  changeToBackgroundConsultMode,
  changeToBackgroundUpdateMode,
  changeToBackgroundDeleteMode,
  resetBackgroundMode,
  changeToSubConsultMode,
  changeToSubUpdateMode,
  changeToSubCreateMode,
  resetSubMode,
  changeToSubBackgroundCreateMode,
  changeToSubBackgroundConsultMode,
  changeToSubBackgroundUpdateMode,
  changeToSubBackgroundDeleteMode,
  changeToBackgroundTransferMode,
  changeToBackgroundReversalMode,
  changeToLastSubBackgroundMode,
  resetSubBackgroundMode,
  changeTypeTo,
  resetType,
  changeLastHistoryModel,
  resetHistoryModel,
  resetLastHistoryModel,
  setModel,
  setProcesso,
  setTipo,
  resetModel,
  addToList,
  updateOnList,
  setRelatedMercadoriaModel,
  updateSelectedMercadoria,
  resetRelatedMercadoriaModel,
  setRelatedDuplicataModel,
  resetRelatedDuplicataModel,
  setRelatedRegistroModel,
  resetRelatedRegistroModel,
  setResponse,
  setResponseMessage,
  clearResponse,
  setError,
  clearError,
  setRelatedModelFollowUp,
  resetRelatedModelFollowUp,
  setRelatedSelectionAvailableItemList,
  resetRelatedSelectionAvailableItemList,
  setRelatedSelectionChangedItemList,
  resetRelatedSelectionChangedItemList,
  setRelatedSelectionItemList,
  resetRelatedSelectionItemList,
  setRelatedTransferenciaItem,
  resetRelatedTransferenciaItem,
  setRelatedItensXmlDanfeMetadata,
  resetRelatedItensXmlDanfeMetadata,
  addItemToChangedSelectionList,
  removeItemFromChangedSelectionList,
  setRelatedReportModel,
  resetRelatedReportModel,
  setBalances,
  resetBalances,
} = solicitacaoPagamentoSlice.actions;

const solicitacaoPagamentoActions = solicitacaoPagamentoSlice.actions;

export {
  solicitacaoPagamentoSlice,
  solicitacaoPagamentoActions,
  changeStatusTo,
  loading,
  success,
  failure,
  preparingAction,
  resetStatus,
  refresh,
  resetRefresh,
  changeModeTo,
  changeToCreateMode,
  changeToConsultMode,
  changeToUpdateMode,
  changeToTransferMode,
  changeToReversalMode,
  changeToLockedMode,
  resetMode,
  changeToBackgroundCreateMode,
  changeToBackgroundConsultMode,
  changeToBackgroundUpdateMode,
  changeToBackgroundDeleteMode,
  changeToBackgroundTransferMode,
  changeToBackgroundReversalMode,
  resetBackgroundMode,
  changeToSubConsultMode,
  changeToSubUpdateMode,
  changeToSubCreateMode,
  resetSubMode,
  changeToSubBackgroundCreateMode,
  changeToSubBackgroundConsultMode,
  changeToSubBackgroundUpdateMode,
  changeToSubBackgroundDeleteMode,
  changeToLastSubBackgroundMode,
  resetSubBackgroundMode,
  changeTypeTo,
  resetType,
  changeLastHistoryModel,
  resetHistoryModel,
  resetLastHistoryModel,
  setModel,
  setProcesso,
  setTipo,
  resetModel,
  addToList,
  updateOnList,
  setRelatedMercadoriaModel,
  updateSelectedMercadoria,
  resetRelatedMercadoriaModel,
  setRelatedDuplicataModel,
  resetRelatedDuplicataModel,
  setRelatedRegistroModel,
  resetRelatedRegistroModel,
  setResponse,
  setResponseMessage,
  clearResponse,
  setError,
  clearError,
  setRelatedModelFollowUp,
  resetRelatedModelFollowUp,
  setRelatedSelectionAvailableItemList,
  resetRelatedSelectionAvailableItemList,
  setRelatedSelectionChangedItemList,
  resetRelatedSelectionChangedItemList,
  setRelatedSelectionItemList,
  resetRelatedSelectionItemList,
  setRelatedTransferenciaItem,
  resetRelatedTransferenciaItem,
  setRelatedItensXmlDanfeMetadata,
  resetRelatedItensXmlDanfeMetadata,
  addItemToChangedSelectionList,
  removeItemFromChangedSelectionList,
  setRelatedReportModel,
  resetRelatedReportModel,
  setBalances,
  resetBalances,
};

export default solicitacaoPagamentoSlice.reducer;
