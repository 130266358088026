import Keycloak from 'keycloak-js';
import { storeJWT } from '../services/keycloakJWTStorageApi';
import { KEYCLOAK_TOKEN_TIMEOUT } from '../../../App';

export const getKeycloakJWTKey = async (keycloak: Keycloak.KeycloakInstance) => {
  await keycloak.updateToken(KEYCLOAK_TOKEN_TIMEOUT);
  const jwt = await storeJWT(keycloak.token!);
  localStorage.setItem('jwtKey', jwt);
  return jwt;
};
