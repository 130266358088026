import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { fetchById as fetchEtapaFollowUpId } from '../../features/etapa/etapaAPI';
import {
  addEtapaModeloFollowUp,
  changeToSubConsultMode,
  changeToSubCreateMode,
  changeToSubUpdateMode,
  removeEtapaModeloFollowUpById,
  resetRelatedEtapaModeloFollowUpModel,
  resetSubMode,
  selectRelatedEtapaModeloFollowUpModel,
  selectSubMode,
  setRelatedEtapaModeloFollowUpModel,
  updateEtapaModeloFollowUp,
} from '../../features/modelo-follow-up/modeloFollowUpSlice';
import QCXFinalFormWizard from '../../shared-components/final-form-wizard/QCXFinalFormWizard';
import { isNoneSubMode, isSubConsultMode, isSubCreateMode, isSubUpdateMode } from '../../utils/store/store-utils';
import QCXEtapaModeloFollowUpFinalFormManager from '../etapas-modelo-de-followup/QCXEtapaModeloFollowUpFinalFormManager';
import QCXModeloDeFolloupTecnicalDataForm from './QCXModeloFollowUpTecnicalDataForm';

export default function QCXModeloFollowUpWizardFinalForm({
  handleSubmit,
  handleAlternativeSave,
  handleChangeToPreparingAction,
  model,
  handleChangeModel,
  handleChangeToCreate,
  handleChangeToConsult,
  handleChangeToUpdate,
  handleCancelUpdate,
  handleChangeToBackgroundCreate,
  handleResetBackgroundMode,
  isCreate,
  isConsult,
  isUpdate,
  isBackgroundCreate,
  isPreparingAction,
  authInfo = {},
  requiredRoles = [],
  ...restProps
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const etapaModeloFollowUp = useSelector(selectRelatedEtapaModeloFollowUpModel);
  const subMode = useSelector(selectSubMode);

  const isSubNone = useMemo(() => isNoneSubMode(subMode), [subMode]);

  const isSubCreate = useMemo(() => isSubCreateMode(subMode), [subMode]);

  const isSubConsult = useMemo(() => isSubConsultMode(subMode), [subMode]);

  const isSubUpdate = useMemo(() => isSubUpdateMode(subMode), [subMode]);

  const checkIfIsAlternativeSaveByStep = (currentStep) => currentStep === 1;

  const normalizeEtapaModeloFollowUp = (data) => ({
    ...data,
  });

  const unnormalizeEtapaModeloFollowUp = (data) => ({
    ...data,
  });

  const handleNewEtapaModeloFollowUp = () => {
    dispatch(changeToSubCreateMode());
  };

  const handleCancelEtapaModeloFollowUpCreate = () => {
    dispatch(resetSubMode());
  };

  const handleCancelEtapaModeloFollowUpUpdate = () => {
    dispatch(changeToSubConsultMode());
  };

  const handleTabelaEtapaModeloFollowUpRowClick = ({ row }) => {
    if (!(isSubUpdate || isSubCreate) && etapaModeloFollowUp?.id !== row?.id) {
      dispatch(setRelatedEtapaModeloFollowUpModel(row));
      dispatch(changeToSubConsultMode());
    }
  };

  const handleEditEtapaModeloFollowUp = () => {
    dispatch(changeToSubUpdateMode());
  };

  const handleClearEtapaModeloFollowUpForm = () => {
    dispatch(resetRelatedEtapaModeloFollowUpModel());
  };

  const handleRemoveEtapaModeloFollowUp = () => {
    dispatch(removeEtapaModeloFollowUpById({ data: etapaModeloFollowUp }));
    handleClearEtapaModeloFollowUpForm();
  };

  const etapaModeloFollowUpList = useMemo(() => {
    const orderedEtapas = model?.etapas
      ?.filter((item) => item.active === true)
      .sort((a, b) => {
        if (a.ordem === b.ordem) {
          if (a.justAdded) {
            return -1; // etapa recem adicionada tem prioridade para se manter naquela ordem
          }
          return 1;
        }

        return a.ordem - b.ordem;
      });

    const etapasComOrdensCorrigidas = orderedEtapas?.map((item, index) => ({
      ...item,
      ordem: index + 1,
      justAdded: false,
    }));

    return etapasComOrdensCorrigidas ?? [];
  }, [model]);

  // Essa é a função chamada ao dar o adicionar na etapa!
  const handleEtapaModeloFollowUpSubmit = async (data) => {
    const normalizedData = normalizeEtapaModeloFollowUp(data);
    const { id } = normalizedData?.etapaAtual;
    const response = await fetchEtapaFollowUpId(id);
    const normalizedEtapa = response?.data;

    let normalizedEtapaBase;

    if (normalizedData?.etapaBase?.id) {
      const { id: baseId } = normalizedData?.etapaBase;
      const responseBaseId = await fetchEtapaFollowUpId(baseId);
      normalizedEtapaBase = responseBaseId?.data;
    }

    let normalizedOrdem;
    if (!data.ordem) {
      const listaEtapas = [...etapaModeloFollowUpList];
      const ordered = listaEtapas.sort((a, b) => a.ordem - b.ordem);
      const biggestOrder = ordered[etapaModeloFollowUpList.length - 1];
      normalizedOrdem = (biggestOrder?.ordem ?? 0) + 1;
    } else {
      normalizedOrdem = data.ordem;
    }

    const fullNormalizedData = {
      ...normalizedData,
      etapaAtual: normalizedEtapa,
      etapaBase: normalizedEtapaBase,
      ordem: Number(normalizedOrdem),
      justAdded: true, // propriedade usada somente no front para ordenar as etapas
      active: true,
    };

    if (isSubCreate) {
      dispatch(addEtapaModeloFollowUp({ data: fullNormalizedData }));
    } else if (isSubUpdate) {
      dispatch(updateEtapaModeloFollowUp({ data: fullNormalizedData }));
    }
  };

  const etapaModeloFollowUpModel = useMemo(
    () => unnormalizeEtapaModeloFollowUp(etapaModeloFollowUp),
    [unnormalizeEtapaModeloFollowUp, etapaModeloFollowUp]
  );

  const wizardSteps = [t('com.muralis.qcx.dadosTecnicos'), t('com.muralis.qcx.etapa.labelPlural')];

  return (
    <QCXFinalFormWizard
      steps={wizardSteps}
      initialValues={model}
      handleChangeValues={handleChangeModel}
      isCreate={isCreate}
      isConsult={isConsult}
      isUpdate={isUpdate}
      isBackgroundCreate={isBackgroundCreate}
      isSubNone={isSubNone}
      isSubCreate={isSubCreate}
      isSubConsult={isSubConsult}
      isSubUpdate={isSubUpdate}
      handleSubmit={handleSubmit}
      checkIfIsAlternativeSaveByStep={checkIfIsAlternativeSaveByStep}
      handleAlternativeSave={handleAlternativeSave}
      handleChangeToCreate={handleChangeToCreate}
      handleChangeToBackgroundCreate={handleChangeToBackgroundCreate}
      handleChangeToConsult={handleChangeToConsult}
      handleChangeToUpdate={handleChangeToUpdate}
      handleCancelUpdate={handleCancelUpdate}
      handleResetBackgroundMode={handleResetBackgroundMode}
      {...restProps}
    >
      {(formProps) => (
        <QCXModeloDeFolloupTecnicalDataForm
          key="ficha-dados-tecnicos-modelo-follow-up"
          isCreate={isCreate}
          isConsult={isConsult}
          isUpdate={isUpdate}
          authInfo={authInfo}
          requiredRoles={requiredRoles}
          {...formProps}
        />
      )}
      {({ controlComponentProps }) => (
        <QCXEtapaModeloFollowUpFinalFormManager
          key="ficha-etapas-modelo-follow-up"
          isCreate={isCreate}
          isConsult={isConsult}
          isUpdate={isUpdate}
          isSubNone={isSubNone}
          isSubCreate={isSubCreate}
          isSubConsult={isSubConsult}
          isSubUpdate={isSubUpdate}
          model={etapaModeloFollowUpModel}
          list={etapaModeloFollowUpList}
          handleSubmit={handleEtapaModeloFollowUpSubmit}
          handleNew={handleNewEtapaModeloFollowUp}
          handleCancelCreate={handleCancelEtapaModeloFollowUpCreate}
          handleCancelUpdate={handleCancelEtapaModeloFollowUpUpdate}
          handleRowClick={handleTabelaEtapaModeloFollowUpRowClick}
          handleRemove={handleRemoveEtapaModeloFollowUp}
          handleEdit={handleEditEtapaModeloFollowUp}
          handleClearForm={handleClearEtapaModeloFollowUpForm}
          controlComponentProps={controlComponentProps}
          authInfo={authInfo}
          requiredRoles={requiredRoles}
        />
      )}
    </QCXFinalFormWizard>
  );
}
