import { Grid } from '@material-ui/core';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import QCXFormSubtitle from '../../../../../../components/form-title/QCXFormSubtitle';
import DesviosBondManager from './DesviosBondManager';
import {
  selectMode,
} from '../../../../../../features/follow-up/followUpSelectors';
import { isConsultMode } from '../../../../../../utils/store/store-utils';

function FollowUpListDesvios() {
  const { t } = useTranslation();
  const mode = useSelector(selectMode);

  const isConsult = useMemo(() => (
    isConsultMode(mode)
  ), [mode]);

  return (
    <>
      <Grid container>
        <Grid style={{ paddingTop: '2rem' }} item xs={12}>
          <QCXFormSubtitle title={t('com.muralis.qcx.listaFollowUp.desvioD')} />
        </Grid>
        <Grid style={{ padding: '1rem 0 1rem 0' }} item xs={12}>
          <DesviosBondManager isConsult={isConsult} />
        </Grid>
      </Grid>
    </>
  );
}

export default FollowUpListDesvios;
