import { isEmpty, reverse } from 'lodash';
import { padStart } from '../../../general/general-utils';
import { MASK_DIGITS, MASK_NUMERIC_DECIMAL_AMERICAN, MASK_NUMERIC_DECIMAL_BRAZILIAN } from './mask-types';

const formatNumeral = (
  value,
  config = {
    prefix: '',
    decimals: 2,
    decimalSeparator: '.',
    thousantSeparator: ',',
    allowNegative: false,
  }
) => {
  const { prefix, decimals, decimalSeparator, thousantSeparator, allowNegative } = config;
  if (!value && isEmpty(value)) return '';

  const normalizedValue = String(value).replaceAll(thousantSeparator, '').replace(decimalSeparator, thousantSeparator);

  const sanitizedValue = normalizedValue.replace(/[^\d\\,\\.]/g, '');

  const fixedNumber = Number(sanitizedValue).toFixed(decimals);
  const onlyNumbers = String(fixedNumber).replace(/[^\d]/g, '');

  const reversedDigits = reverse(onlyNumbers.split(''));

  const hasDecimals = decimals > 0;

  const formattedNumeral = reversedDigits.reduce((previous, current, index, array) => {
    if (hasDecimals && index === decimals) {
      return `${current}${decimalSeparator}${previous}`;
    }
    if (index > decimals && current === '0' && array.length - 1 === index) {
      return previous;
    }
    const isAfterDecimals = index + 1 > Math.max(decimals + 1, 0);

    if (isAfterDecimals && (index + 1 - Math.max(decimals + 1, 0)) % 3 === 0) {
      return `${current}${thousantSeparator}${previous}`;
    }
    return `${current}${previous}`;
  }, '');

  if (isEmpty(formattedNumeral)) return '';

  const finalPrefix =
    allowNegative && Number(normalizedValue) < 0
      ? `${prefix}${'-'}${formattedNumeral}`
      : `${prefix}${formattedNumeral}`;

  return finalPrefix;
};

const formatDigits = (value) => {
  if (!value && isEmpty(value)) return '';

  const onlyNumbers = String(value).replace(/[^\d]/g, '');

  return onlyNumbers;
};

const configureNumeralFomatter =
  (config) =>
  (scale = 2) =>
  (value) =>
    formatNumeral(value, {
      ...config,
      decimals: scale,
    });

const formatBrazilianNumericDecimal = (scale = 2, allowNegative = false) =>
  configureNumeralFomatter({
    prefix: '',
    decimalSeparator: ',',
    thousantSeparator: '.',
    allowNegative,
  })(scale);

const formatBrazilianNumericDecimalWithoutSeparator = (scale = 2, allowNegative = false) =>
  configureNumeralFomatter({
    prefix: '',
    decimalSeparator: ',',
    thousantSeparator: '',
    allowNegative,
  })(scale);

const formatAmericanNumericDecimal = (scale = 2, allowNegative = false) =>
  configureNumeralFomatter({
    prefix: '',
    decimalSeparator: '.',
    thousantSeparator: ',',
    allowNegative,
  })(scale);

const formatters = [
  {
    types: MASK_NUMERIC_DECIMAL_BRAZILIAN,
    fn: formatBrazilianNumericDecimal,
  },
  {
    types: MASK_NUMERIC_DECIMAL_AMERICAN,
    fn: formatAmericanNumericDecimal,
  },
  {
    types: MASK_DIGITS,
    fn: formatDigits,
  },
];

const formatCpf = (value = '') => {
  if (value && value?.length === 11) {
    const cpf = value.replace(/^([\d]{3})([\d]{3})([\d]{3})([\d]{2})$/g, '$1.$2.$3-$4');
    return cpf;
  }
  return value;
};

const formatCnpj = (value = '', isFormaReduzida = false) => {
  if (value && value?.length === 14) {
    const cnpj = value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5');

    if (isFormaReduzida === true) {
      return cnpj.split('/')[1];
    }

    return cnpj;
  }
  return value;
};

const formatCpfOrCnpj = (value = '') => formatCnpj(formatCpf(value));

const formatCnpjOrCnpjValidate = (cnpj, cpf) => (String(cnpj).length > 11 ? formatCnpj(cnpj) : formatCpf(cpf));

const formatCep = (value = '') => {
  if (value && value?.length === 8) {
    const cpf = value.replace(/^([\d]{5})([\d]{3})$/, '$1-$2');
    return cpf;
  }
  return value;
};

const formatTelefoneFixo = (value = '') => {
  switch (value?.length) {
    // 1111-1111
    case 8: {
      return value.replace(/^([\d]{4})([\d]{4})$/, '$1-$2');
    }
    // (11) 1111-1111
    case 10: {
      return value.replace(/^([\d]{2})([\d]{4})([\d]{4})$/, '($1) $2-$3');
    }
    // +55 (11) 1111-1111
    case 12: {
      return value.replace(/^([\d]{2})([\d]{2})([\d]{4})([\d]{4})$/, '+$1 ($2) $3-$4');
    }
    default: {
      return value;
    }
  }
};

const formatTelefoneCelular = (value = '') => {
  switch (value?.length) {
    // 91111-1111
    case 9: {
      return value.replace(/^([\d]{5})([\d]{4})$/, '$1-$2');
    }
    // (11) 91111-1111
    case 11: {
      return value.replace(/^([\d]{2})([\d]{5})([\d]{4})$/, '($1) $2-$3');
    }
    // +55 (11) 91111-1111
    case 13: {
      return value.replace(/^([\d]{2})([\d]{2})([\d]{5})([\d]{4})$/, '+$1 ($2) $3-$4');
    }
    default: {
      return value;
    }
  }
};

const formatTelefone = (value = '') => {
  switch (value?.length) {
    case 8:
    case 10:
    case 12: {
      return formatTelefoneFixo(value);
    }
    case 9:
    case 11:
    case 13: {
      return formatTelefoneCelular(value);
    }
    default: {
      return value;
    }
  }
};

const formatRegistroDespachante = (value = '') => {
  if (value && value?.length === 7) {
    const registro = value.replace(/([\w\d]{2})([\w\d]{2})([\w\d]{3})/g, '$1.$2.$3');
    return registro;
  }
  return value;
};

const formatGrauPlanoConta = (value = '') => {
  if (value && value?.length === 3) {
    const registro = value.replace(/([\d]{1})([\d]{1})([\d]{1})/g, '$1.$2.$3');
    return registro;
  }

  if (value && value?.length === 2) {
    const registro = value.replace(/([\d]{1})([\d]{1})/g, '$1.$2');
    return registro;
  }

  if (value && value?.length === 1) {
    const registro = value.replace(/([\d]{1})/g, '$1');
    return registro;
  }

  return value;
};

const formatNotZero = (value = '') => {
  if (value) {
    const result = String(value).match(/^[1-9]\d*$/);
    return result;
  }
  return value;
};

const formatAgencia = (value = '') => {
  if (value && value?.length === 5) {
    const agencia = value.replace(/^([\d]{4})([\d]{1})$/, '$1-$2');
    return agencia;
  }
  return value;
};

const formatCFOPCode = (value = '') => {
  const paddedCode = padStart(value, 4, 0);
  const formattedCode = paddedCode.replace(/(\d{1})(\d{3})/g, '$1.$2');
  return formattedCode;
};

const formatContaCorrente = (value = '') => {
  if (value && value?.length > 4) {
    const contaCorrente = value.replace(/^([\d]+)([\d]{1})$/, '$1-$2');
    return contaCorrente;
  }
  return value;
};

const formatNumeroConhecimentoMaster = (value = '') => {
  if (value && value?.length > 10) {
    const formatted = value.replace(/^([A-z0-9]{3})([A-z0-9]{4})([A-z0-9]{4})$/, '$1-$2-$3');
    return formatted;
  }
  return value;
};

const formatNumeroConhecimentoMaster12 = (value = '') => {
  if (value && value?.length > 10) {
    const formatted = value.replace(/^([A-z0-9]{4})([A-z0-9]{4})([A-z0-9]{4})$/, '$1-$2-$3');
    return formatted;
  }
  return value;
};

// -XX.XXXXXXX ou XX.XXXXXXX
const formatLatitudeLongitude = (value = '') => {
  if (value && value?.length >= 9 && value?.length <= 10) {
    const formattedLatitudeOrLongitude = value.replace(/(^-?)([\d]{2})(\d{7})$/g, '$1$2.$3');
    return formattedLatitudeOrLongitude;
  }
  return value;
};

const formatCpfOrCnpjOrEmpty = (value = '') => {
  if (value && value?.length < 11) {
    return '';
  }

  return formatCnpj(formatCpf(value));
};

const formatNcmSubItemRemoveDash = (value = '') => value.replace(/^--|^-/, '').trim();

const formatLINumber = (value = '') => {
  if (value && value.length === 10) {
    // Define the regular expression pattern for XX/XXXXXXX-X
    const pattern = /^(\d{2})(\d{7})(\d)$/;

    // Replace the matched pattern with the formatted string
    const formattedLINumber = value.replace(pattern, '$1/$2-$3');
    return formattedLINumber;
  }
  return value; // Return the original value if it doesn't match the pattern or has an incorrect length
};

export default formatters;

export {
  formatNumeral,
  formatLINumber,
  formatBrazilianNumericDecimal,
  formatBrazilianNumericDecimalWithoutSeparator,
  formatAmericanNumericDecimal,
  formatCpf,
  formatCnpj,
  formatCpfOrCnpj,
  formatCep,
  formatTelefone,
  formatTelefoneFixo,
  formatTelefoneCelular,
  formatRegistroDespachante,
  formatGrauPlanoConta,
  formatNotZero,
  formatCFOPCode,
  formatAgencia,
  formatContaCorrente,
  formatNumeroConhecimentoMaster,
  formatNumeroConhecimentoMaster12,
  formatLatitudeLongitude,
  formatCpfOrCnpjOrEmpty,
  formatCnpjOrCnpjValidate,
  formatNcmSubItemRemoveDash,
};
