import { isEmpty } from 'lodash';
import { useContext, useMemo } from 'react';
import ReadOnlyFormContext from '../../../../contexts/components/registration-page/ReadOnlyFormContext';

export default function useReadOnlyForm(defaultReadOnlyFormProps = {}) {
  const contextValue = useContext(ReadOnlyFormContext);

  const readOnlyFormProps = useMemo(() => (
    isEmpty(defaultReadOnlyFormProps)
      ? {
        disableUpdate: contextValue?.disableUpdate,
      }
      : defaultReadOnlyFormProps
  ), [
    contextValue,
    defaultReadOnlyFormProps
  ]);

  return readOnlyFormProps;
}
