import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import {
  formatDateISOString,
  formatStringToDate,
} from "../../utils/parsers/dateParsers";
import { useEffect, useMemo } from "react";

type DatePickerStyledProps = {
  format?: string;
  placeholder?: string;
  disablePast?: boolean;
  disableFuture?: boolean;
  label?: string;
  onChange: (date: string | null) => void;
  value: string | null;
};

const DatePickerStyled = ({
  format = "dd/MM/yyyy",
  placeholder = "dd/mm/aaaa",
  disablePast = false,
  disableFuture = false,
  value,
  onChange,
  ...restProps
}: DatePickerStyledProps) => {
  const handleChange = (
    date: MaterialUiPickersDate,
    value?: string | null | undefined
  ): void => {
    if (date) {
      onChange(
        formatDateISOString(new Date(date as any).toString(), "YYYY-MM-DD")
      );
    } else {
      onChange(null);
    }
  };

  const dateValue = useMemo(() => {
    if (value === null) {
      return value;
    }

    return formatStringToDate(value, "YYYY-MM-DD");
  }, [value]);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        value={dateValue}
        onChange={handleChange}
        InputLabelProps={{
          shrink: true,
          style: {
            textTransform: "uppercase",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            // width: '100%',
          },
        }}
        emptyLabel=""
        format={format}
        placeholder={placeholder}
        inputVariant="outlined"
        size="small"
        fullWidth
        disablePast={disablePast}
        disableFuture={disableFuture}
        {...restProps}
      />
    </MuiPickersUtilsProvider>
  );
};

export default DatePickerStyled;
