import { Grid, makeStyles } from '@material-ui/core';
import React, { useMemo } from 'react';
import { useFormState } from 'react-final-form';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { selectRegimesTributacao } from '../../features/regime-tributacao/regimeTributacaoSlice';
import QCXFinalPercentualField from '../final-percentual-field/QCXFinalPercentualField';
import QCXSelectRegimeTributacaoAutocomplete from '../select-regime-tributacao/QCXSelectRegimeTributacaoAutocomplete';
import QCXFinalCheckboxField from '../final-checkbox-field/QCXFinalCheckboxField';
import {
  configureSpecificRegimeTributacaoReducaoMatcher,
} from '../select-regime-tributacao/regimeTributacaoUtils';
import QCXFinalTextField from '../final-text-field/QCXFinalTextField';

const useStyles = makeStyles(() => ({
  gridFields: {
    paddingBottom: '16px',
  },
  ipiCheckBox: {
    marginBottom: '16px',
  },
}));

export default function QCXIPICommonFormGroup({
  modes,
}) {
  const { t } = useTranslation();

  const classes = useStyles();

  const { values } = useFormState();

  const {
    isConsult,
    isSubConsult,
    isSubNone,
  } = modes;

  const regimesTributacao = useSelector(selectRegimesTributacao);

  const regimeTributacao = useMemo(() => (
    values?.ipi?.regimeTributacao?.id
  ), [values]);

  const ipiNaoTributavel = useMemo(() => (
    values?.ipi?.ipiNaoTributavel
  ), [values]);

  const isSelectedRegimeTributacaoReducao = useMemo(() => (
    configureSpecificRegimeTributacaoReducaoMatcher(
      regimesTributacao
    )(
      regimeTributacao
    )
  ), [
    regimeTributacao,
    regimesTributacao,
  ]);

  return (
    <>
      {!ipiNaoTributavel && (
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
        >
          <QCXSelectRegimeTributacaoAutocomplete
            id="select-field-regime-tributacao-ipi"
            key="select-field-regime-tributacao-ipi"
            name="ipi.regimeTributacao.id"
            label={t('com.muralis.qcx.regimeTributacao')}
            initialValues={values}
            disabled={isConsult || isSubConsult || isSubNone}
          />
        </Grid>
      )}
      <Grid
        item
        className={classes.ipiCheckBox}
        xs={12}
        sm={12}
        md={6}
        lg={6}
      >
        <QCXFinalCheckboxField
          id="checkbox-ipi-nao-tributavel-field"
          key="checkbox-ipi-nao-tributavel-field"
          name="ipi.ipiNaoTributavel"
          label={t('com.muralis.qcx.naoTributavel')}
          disabled={isConsult || isSubConsult || isSubNone}
        />
      </Grid>
      {!ipiNaoTributavel && (
        <>
          <Grid
            item
            xs={12}
            sm={12}
            md={isSelectedRegimeTributacaoReducao ? 3 : 6}
            lg={isSelectedRegimeTributacaoReducao ? 3 : 6}
          >
            <QCXFinalTextField
              id="text-field-numero-ipi-ipi"
              key="text-field-numero-ipi-ipi"
              name="ipi.notaComplementarTipi"
              label={t('com.muralis.qcx.notaComplementarIPI')}
              disabled={isConsult || isSubConsult || isSubNone}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={isSelectedRegimeTributacaoReducao ? 6 : 12}
            md={isSelectedRegimeTributacaoReducao ? 3 : 6}
            lg={isSelectedRegimeTributacaoReducao ? 3 : 6}
            className={classes.gridFields}
          >
            <QCXFinalPercentualField
              id="aliquota-ad-valorem-field-ipi"
              key="aliquota-ad-valorem-field-ipi"
              name="ipi.aliquota"
              label={t('com.muralis.qcx.ADValoremPercentual')}
              scale={7}
              disabled={isConsult || isSubConsult || isSubNone}
            />
          </Grid>
          {isSelectedRegimeTributacaoReducao && (
            <>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={6}
                className={classes.twin}
              >
                <QCXFinalPercentualField
                  id="aliquota-reduzida-field-ipi"
                  key="aliquota-reduzida-field-ipi"
                  name="ipi.reduzida"
                  label={t('com.muralis.qcx.alioquoteReduzidaPercentual')}
                  scale={7}
                  disabled={isConsult || isSubConsult || isSubNone}
                />
              </Grid>
            </>
          )}
        </>
      )}
    </>
  );
}
