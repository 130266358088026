import { Grid } from '@material-ui/core';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchAllAsync,
  selectStatus,
  selectModelosNumerario,
} from '../../features/modelo-numerario/modeloNumerarioSlice';
import QCXSimpleSelectOnePageTemplate from '../../templates/simple-select-one-page/QCXSimpleSelectOnePageTemplate';
import { isAlternativeLoadingStatus } from '../../utils/store/store-utils';

export default function QCXSelectOneModeloNumerarioTable({ ...props }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const status = useSelector(selectStatus);
  const modelosNumerario = useSelector(selectModelosNumerario);

  const modelosNumerariosFiltrados = useMemo(() => modelosNumerario.filter((modelo) => modelo.active === true), [modelosNumerario]);

  const isAlternativeLoading = useMemo(() => (
    isAlternativeLoadingStatus(status)
  ), [status]);

  useEffect(() => {
    dispatch(fetchAllAsync());
  }, []);

  const filterProps = [
    'id',
    'description',
  ];

  const {
    title = t('com.muralis.qcx.numerario.selecioneModeloNumerario'),
    filterProp = 'description',
    columns = [
      {
        field: 'id',
        headerName: t('com.muralis.qcx.codigo'),
        headerAlign: 'center',
        align: 'center',
        flex: 50,
      },
      {
        field: 'description', headerName: t('com.muralis.qcx.numerario.modeloNumerario'), flex: 300,
      },
      {
        field: 'active', headerName: t('com.muralis.qcx.estado.ativo'), type: 'boolean', flex: 50,
      },
    ],
    selected = null,
    handleChange,
    isConsult,
    ...restProps
  } = props;
  return (
    <>
      <Grid container direction="row" style={{ marginTop: '0.7rem' }}>
        <QCXSimpleSelectOnePageTemplate
          title={title}
          tableColumns={columns}
          tableData={modelosNumerariosFiltrados}
          filterProp={filterProp}
          isLoading={isAlternativeLoading}
          isConsult={isConsult}
          handleSetSelected={handleChange}
          selected={selected}
          multipleFilter
          filterProps={filterProps}
          {...restProps}
        />
      </Grid>
    </>
  );
}
