import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import { useParams } from "react-router-dom";
import {
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import {
  GridActionsCellItem,
  GridCellParams,
  GridColDef,
  GridValueFormatterParams,
} from "@mui/x-data-grid";
import { useMemo, useState } from "react";
import DataTable from "../../../../common/components/dataTable";
import FormHeader from "../../../../common/components/forms/formHeader";
import FormHeaderMedium from "../../../../common/components/forms/formHeaderMedium";
import SelectStyled from "../../../../common/components/input/selectStyled";
import LoadingIndicator from "../../../../common/components/loadingIndicator";
import PageLayout from "../../../../common/layouts/pageLayout";
import { brlFormatter } from "../../../../common/utils/formatters/currencyFormatters";
import DeleteIcon from "@mui/icons-material/Delete";
import { useListAllCadastroProposta } from "../common/hooks";
import {
  CadastroCover,
  CadastroProposta,
} from "../common/propostasCadastros.type";
import {
  useCreateCadastroProposta,
  useDeleteCadastroProposta,
  useListCadastroPropostaById,
  useUpdateCadastroProposta,
} from "./propostasCadastros.hooks";
import { useListClientes } from "../../../../common/hooks/useListClientes";

const PropostasCadastros = () => {
  const globalClient = {
    id: 0,
    value: "Global",
  };
  const [selectedClient, setSelectedClient] = useState(globalClient);
  const [clientes] = useListClientes();

  const [cadastros, isLoading] = useListAllCadastroProposta(selectedClient.id);
  const [createCadastro] = useCreateCadastroProposta();
  const [deleteCadastro] = useDeleteCadastroProposta();
  const [updateCadastro] = useUpdateCadastroProposta();

  const [selectedCadastro, setSelectedCadastro] = useState<CadastroCover>();
  const [valoresCadastroSelecionado] = useListCadastroPropostaById(
    selectedCadastro?.id,
    selectedClient.id === 0 ? undefined : `clienteId=${selectedClient.id}`
  );

  const [novoCadastro, setNovoCadastro] = useState<Partial<CadastroProposta>>(
    {}
  );

  const valoresColumns: GridColDef[] = [
    {
      field: "nome",
      headerName: "Nome",
      flex: 10,
      headerClassName: "header-theme",
    },
    {
      field: "valor",
      headerName: "Valor",
      flex: 2,
      headerClassName: "header-theme",
      align: "right",
      headerAlign: "right",
      valueFormatter: (params: GridValueFormatterParams<any>) => {
        return brlFormatter.format(params.value);
      },
    },
    {
      field: "isConditional",
      headerName: "Atualização Condicional",
      flex: 3,
      headerClassName: "header-theme",
      type: "boolean",
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Ações",
      headerClassName: "header-theme",
      // @ts-ignore
      getActions: (params: any) => [
        <GridActionsCellItem
          icon={
            <DeleteIcon
              color={isValorCliente(params.row.nome) ? "error" : "disabled"}
            />
          }
          label="Deletar"
          onClick={() =>
            isValorCliente(params.row.nome)
              ? handleValorServicoDeletion(params.row.nome)
              : () => {}
          }
          showInMenu
        />,
      ],
    },
  ];

  const isValorCliente = (nomeValor: string) => {
    if (selectedClient.id === 0) {
      return true;
    }

    const valor = valoresCadastroSelecionado.find(
      (c) => c.nomeValor === nomeValor
    )!;

    return valor ? valor.clienteId !== undefined : false;
  };

  const isCadastroCliente = (id: number) => {
    if (selectedClient.id === 0) {
      return true;
    }

    const valor = valoresCadastroSelecionado.find((c) => c.id === id)!;

    return valor ? valor.clienteId !== undefined : false;
  };

  const servicosColumns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      flex: 1,
      headerClassName: "header-theme",
      type: "number",
    },
    {
      field: "cadastro",
      headerName: "Cadastro",
      flex: 5,
      headerClassName: "header-theme",
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Ações",
      headerClassName: "header-theme",
      // @ts-ignore
      getActions: (params: any) => [
        <GridActionsCellItem
          icon={
            <DeleteIcon
              color={isCadastroCliente(params.row.id) ? "error" : "disabled"}
            />
          }
          label="Deletar"
          onClick={
            isCadastroCliente(params.row.id)
              ? () => handleServicoDeletion(params.row.id)
              : () => {}
          }
          showInMenu
        />,
      ],
    },
  ];

  const handleCadastroAddition = async () => {
    if (
      !novoCadastro?.nome ||
      !novoCadastro?.valor ||
      !novoCadastro.nomeValor
    ) {
      return;
    }

    let payload: Omit<CadastroProposta, "id"> =
      novoCadastro as CadastroProposta;

    let query = {
      clientId: selectedClient.id,
    };

    query.clientId !== 0
      ? createCadastro({ requestPayload: payload, queryParams: query })
      : createCadastro({ requestPayload: payload });

    setNovoCadastro({ isConditional: false });
  };

  const handleServicoDeletion = async (id: number) => {
    deleteCadastro({ id });
  };

  const handleValorServicoDeletion = async (nomeValor: string) => {
    deleteCadastro({
      id: selectedCadastro!.id,
      nome: nomeValor,
      clienteId: selectedClient.id,
    });
  };

  const handleCadastroEdition = async () => {
    updateCadastro({ id: selectedCadastro!.id, ...novoCadastro });
  };

  const onServicoCellClick = (params: GridCellParams) => {
    const selectedCadastro = cadastros.find((c) => c.id == params.row.id)!;

    setSelectedCadastro(selectedCadastro);
    setNovoCadastro(selectedCadastro);
  };

  const rowsValues = useMemo(
    () =>
      valoresCadastroSelecionado.map((item, index) => ({
        id: index,
        nome: item.nomeValor,
        valor: item.valor,
        isConditional: item.isConditional,
      })) ?? [],
    [valoresCadastroSelecionado]
  );

  const checked = useMemo(
    () => novoCadastro.isConditional,
    [novoCadastro, novoCadastro.isConditional]
  );

  return (
    <PageLayout
      title={"Propostas - Mercadorias"}
      icon={<HistoryEduIcon color={"secondary"} />}
    >
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <Grid container spacing={2} padding={2}>
          <FormHeader marginTop="0px">Cadastros</FormHeader>
          <Grid item xs={4}>
            <SelectStyled
              key={"ClientSelect"}
              controlledValue={selectedClient}
              options={clientes.map((cliente) => {
                return { id: cliente.id, value: cliente.description };
              })}
              onChangeAction={(e, value) => {
                if (value === null) {
                  setSelectedClient(globalClient);
                } else {
                  setSelectedClient(value as any);
                }
              }}
              label={"Cliente"}
            ></SelectStyled>
          </Grid>
          <FormHeaderMedium>Cadastrados</FormHeaderMedium>
          <Grid item xs={12}>
            <DataTable
              onCellClick={onServicoCellClick}
              columns={servicosColumns}
              rows={
                cadastros.map((cadastro: CadastroCover, index) => ({
                  id: cadastro.id ?? index,
                  cadastro: cadastro.nome,
                })) ?? []
              }
            ></DataTable>
          </Grid>
          <FormHeaderMedium>Valores do Serviço</FormHeaderMedium>

          <Grid item xs={12}>
            {valoresCadastroSelecionado.length > 0 ? (
              <DataTable columns={valoresColumns} rows={rowsValues}></DataTable>
            ) : (
              <Typography>
                Selecione um serviço acima para visualizar os valores
              </Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <Divider></Divider>
          </Grid>

          <FormHeader>
            {selectedCadastro ? "Editar serviço" : "Novo serviço"}
          </FormHeader>
          <Grid item xs={12}>
            <FormControl fullWidth required>
              <TextField
                id="name"
                label="Nome"
                type="text"
                size="small"
                value={novoCadastro?.nome ?? ""}
                onChange={(e) =>
                  setNovoCadastro({ ...novoCadastro, nome: e.target.value })
                }
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormControl>
          </Grid>
          <FormHeaderMedium>Valores do Serviço</FormHeaderMedium>
          <Grid item xs={6}>
            <FormControl fullWidth required>
              <TextField
                id="name-valor"
                label="Nome"
                type="text"
                size="small"
                value={novoCadastro.nomeValor ?? ""}
                onChange={(e) =>
                  setNovoCadastro({
                    ...novoCadastro,
                    nomeValor: e.target.value,
                  })
                }
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl fullWidth required>
              <TextField
                id="valor"
                label="Valor"
                type="number"
                size="small"
                value={novoCadastro.valor ?? ""}
                onChange={(e) =>
                  setNovoCadastro({
                    ...novoCadastro,
                    valor: Number(e.target.value),
                  })
                }
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={!!checked}
                  onChange={(e) =>
                    setNovoCadastro({
                      ...novoCadastro,
                      isConditional: !novoCadastro.isConditional,
                    })
                  }
                  color="secondary"
                />
              }
              label="Atualização Condicional"
            />
          </Grid>
          <Grid item xs={2}>
            <Button
              disabled={false}
              variant="contained"
              color="secondary"
              fullWidth
              onClick={handleCadastroAddition}
            >
              Incluir Valor
            </Button>
          </Grid>
          <Grid item xs={12} textAlign={"right"}>
            {selectedCadastro && isCadastroCliente(selectedCadastro.id) && (
              <Button
                variant="contained"
                color="secondary"
                sx={{ marginRight: "10px" }}
                onClick={() => {
                  setSelectedCadastro(undefined);
                  setNovoCadastro({});
                }}
              >
                Cancelar
              </Button>
            )}
            {selectedCadastro && isCadastroCliente(selectedCadastro.id) && (
              <Button
                variant="contained"
                color="secondary"
                disabled={!novoCadastro?.nome}
                onClick={handleCadastroEdition}
              >
                Salvar
              </Button>
            )}
          </Grid>
        </Grid>
      )}
    </PageLayout>
  );
};

export default PropostasCadastros;
