import { Grid } from '@material-ui/core';
import _ from 'lodash';
import {
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import QCXFinalFormDialog from '../../shared-components/dialog/QCXFinalFormDialog';
import QCXFinalPercentualField from '../../shared-components/final-percentual-field/QCXFinalPercentualField';
import {
  CLOSED_STATUS,
  isOpenStatus,
} from '../../utils/hooks/form/dialog/formDialogUtils';
import QCXFinalSelectDescOrJuros from './QCXFinalSelectDescOrJuros';
import QCXFinalAlternativeCurrencyField from '../../shared-components/final-currency-field/QCXFinalAlternativeCurrencyField';
import QCXSFinalSelectPercOrReal from './QCXFinalSelectPercOrReal';

export default function QCXApplyJurosDialogForm({
  handleOnSubmit,
  handleFormStatus,
  formDialogStatus,
  initialValues,
}) {
  const { t } = useTranslation();

  useEffect(() => {
  }, [initialValues]);

  const isFormDialogOpen = useMemo(() => (
    isOpenStatus(formDialogStatus)
  ), [formDialogStatus]);

  const handleCloseFormDialogNew = useCallback(() => {
    handleFormStatus(CLOSED_STATUS);
    (_.debounce(() => {
    }, 200))();
  }, [
    handleFormStatus,
  ]);

  return (
    <QCXFinalFormDialog
      key="form-dialog-relatorio-conta-pagar-receber"
      id="form-dialog-relatorio-conta-pagar-receber"
      title="Aplicar Juros ou Desconto"
      dialogActionsProps={{
        button: {
          submit: {
            description: 'Aplicar',
            size: 'small',
            color: 'secondary',
          },
          cancel: {
            description: t('com.muralis.qcx.acoes.cancelar'),
            size: 'small',
          },
        },
      }}
      open={isFormDialogOpen}
      initialValues={initialValues}
      onSubmit={handleOnSubmit}
      onClose={handleCloseFormDialogNew}
    >
      {({ values }) => (
        <>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
            >
              <QCXFinalSelectDescOrJuros
                label="Desconto ou Juros"
                name="tipo"
                required
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
            >
              <QCXSFinalSelectPercOrReal
                label="Formato do Valor"
                name="formato"
                required
              />
            </Grid>
            {(values.formato === 'Percentual') && (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
              >
                <QCXFinalPercentualField
                  name="desconto"
                  label="Valor"
                  required
                />
              </Grid>
            )}
            {(values.formato === 'Real') && (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
              >
                <QCXFinalAlternativeCurrencyField
                  name="desconto"
                  label="Valor"
                  required
                />
              </Grid>
            )}
          </Grid>
        </>
      )}
    </QCXFinalFormDialog>
  );
}
