import { UseMutateFunction } from "react-query";
import { QueriesTypes, usePutRequest } from "./queries";

export const useUpdateKeycloakProfilePermissions = () : [
  UseMutateFunction<any, unknown, { [key: string]: any }>,
  boolean,
  boolean
] => {
  const { isLoading, isError, mutate } = usePutRequest(
    QueriesTypes.PerfilAdicionarPermissao
  );

  return [mutate, isLoading, isError];
};
