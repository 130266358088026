import React from 'react';
import Menu from '@mui/material/Menu';

export default function QCXBasicMenu({
  anchorEl,
  handleClose,
  open,
  children,
  ...restProps
}) {
  return (
    <>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        {...restProps}
      >
        {children}
      </Menu>
    </>
  );
}
