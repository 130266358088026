import React from 'react';
import QCXFixedAppBar from '../app-bar/QCXFixedAppBar';

export default function QCXNavigatorTitle({ children, ...restProps }) {
  return (
    <QCXFixedAppBar {...restProps}>
      {children}
    </QCXFixedAppBar>
  );
}
