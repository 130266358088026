import { SetStateAction } from 'jotai';
import { PaginationBulletProps } from '../paginationLayout';

/**
 * It takes in an active number and a state setter function, and then it sets the state of the bullet
 * props to be active or inactive based on the active number
 * @param {number} activeNumber - number - The number of the bullet that is active
 * @param stateSetter - (update: SetStateAction<PaginationBulletProps[]>) => never
 */
const changeBulletProps = (
  activeNumber: number,
  stateSetter: (update: SetStateAction<PaginationBulletProps[]>) => never
) => {
  stateSetter((prev) => {
    const newState = [...prev];
    newState.filter((bullet) => {
      if (bullet.number === activeNumber) {
        bullet.active = true;
      } else {
        bullet.active = false;
      }
      return bullet;
    });
    return newState;
  });
};

export { changeBulletProps };
