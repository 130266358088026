import React from 'react';

const ColoredLogoIcon = (props: any) => (
  <svg
    viewBox="26.522 25.971 256 49.018"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <defs>
      <style>{'.prefix__st2{fill:#b2b2b2}'}</style>
    </defs>
    <path
      d="M80.01 46.88V33.621c0-4.217-3.416-7.65-7.63-7.65H34.172c-4.217 0-7.65 3.433-7.65 7.65v26.731c0 4.217 3.433 7.65 7.65 7.65h18.13l-9.553-7.65h-8.577V33.621H72.38v7.139l7.63 6.12zm0 0"
      fill="#312783"
    />
    <path
      d="M70.144 53.952c-11.683.426-18.715-4.79-24.65-10.572 0 13.749 12.247 24.562 24.65 24.697v6.911L87.659 60.98 70.144 46.972v6.98zm0 0"
      fill="#f39200"
    />
    <path
      className="prefix__st2"
      d="M113.51 64.953c.81.56 1.338.916 1.587 1.058.373.21.873.454 1.502.727l-1.8 3.632c-.906-.44-1.804-.962-2.688-1.566s-1.505-1.06-1.86-1.366c-1.43.621-3.223.93-5.374.93-3.185 0-5.694-.83-7.537-2.489-2.172-1.963-3.259-4.721-3.259-8.278 0-3.45.952-6.13 2.854-8.044 1.903-1.91 4.559-2.869 7.97-2.869 3.479 0 6.166.934 8.058 2.801 1.893 1.868 2.84 4.54 2.84 8.02-.003 3.106-.766 5.584-2.293 7.444zm-4.96-3.32c.516-.922.771-2.307.771-4.146 0-2.115-.394-3.628-1.178-4.533s-1.871-1.36-3.252-1.36c-1.285 0-2.329.462-3.131 1.388-.802.923-1.2 2.368-1.2 4.331 0 2.287.39 3.895 1.171 4.818.781.926 1.853 1.388 3.217 1.388.436 0 .852-.043 1.242-.128-.543-.526-1.402-1.019-2.574-1.488l1.016-2.328c.571.106 1.019.234 1.338.387.32.152.94.553 1.867 1.2.217.156.455.312.714.472zM132.705 68.013h-5.432v-2.46c-.81 1.011-1.626 1.729-2.453 2.158-.823.43-1.839.643-3.039.643-1.6 0-2.857-.48-3.766-1.438-.909-.959-1.367-2.432-1.367-4.427v-9.666h5.847v8.35c0 .954.177 1.629.529 2.03.351.4.848.6 1.487.6.696 0 1.264-.267 1.708-.803.444-.532.664-1.49.664-2.875v-7.306h5.818v15.194zM134.252 47.05h5.819v3.962h-5.819V47.05zm0 5.78h5.819v15.183h-5.819V52.83zM152.918 62.063l5.534.628c-.305 1.154-.805 2.152-1.501 2.997-.696.845-1.584 1.498-2.666 1.966s-2.457.7-4.125.7c-1.612 0-2.954-.15-4.026-.451-1.072-.298-1.995-.785-2.765-1.456a7.404 7.404 0 01-1.818-2.364c-.44-.905-.657-2.105-.657-3.6 0-1.562.267-2.86.803-3.9a6.993 6.993 0 011.6-2.05c.679-.603 1.374-1.054 2.088-1.348 1.132-.465 2.588-.7 4.36-.7 2.477 0 4.366.444 5.669 1.332 1.299.887 2.211 2.183 2.737 3.89l-5.478.728c-.17-.65-.483-1.136-.937-1.466-.455-.33-1.062-.493-1.825-.493-.962 0-1.743.344-2.336 1.036-.596.689-.894 1.733-.894 3.135 0 1.246.294 2.194.887 2.84.59.646 1.346.969 2.258.969.763 0 1.402-.195 1.924-.586.522-.394.912-.997 1.168-1.807z"
    />
    <path
      className="prefix__st2"
      d="M158.427 47.05h5.947v10.82l4.345-5.044h7.164l-5.446 5.318 5.761 9.865h-6.556l-3.074-6.088-2.19 2.14v3.948h-5.947V47.05zM197.672 59.433l5.676 1.714c-.38 1.59-.983 2.922-1.8 3.99a7.823 7.823 0 01-3.052 2.418c-1.214.543-2.762.816-4.64.816-2.28 0-4.14-.33-5.584-.994-1.445-.663-2.691-1.824-3.738-3.493-1.047-1.668-1.573-3.802-1.573-6.4 0-3.469.923-6.131 2.766-7.995 1.845-1.863 4.455-2.794 7.827-2.794 2.641 0 4.714.533 6.227 1.601 1.512 1.069 2.634 2.71 3.369 4.917l-5.72 1.271c-.198-.639-.411-1.108-.628-1.402a3.699 3.699 0 00-1.33-1.143 3.832 3.832 0 00-1.758-.401c-1.47 0-2.592.589-3.373 1.771-.592.877-.887 2.25-.887 4.125 0 2.322.351 3.916 1.058 4.778s1.697 1.292 2.975 1.292c1.239 0 2.176-.347 2.808-1.043.632-.692 1.093-1.704 1.377-3.028z"
    />
    <path
      className="prefix__st2"
      d="M203.196 60.462c0-2.314.78-4.224 2.343-5.726 1.562-1.502 3.674-2.25 6.333-2.25 3.039 0 5.339.88 6.89 2.644 1.25 1.42 1.875 3.17 1.875 5.247 0 2.336-.774 4.25-2.322 5.74-1.548 1.491-3.692 2.237-6.425 2.237-2.44 0-4.413-.622-5.918-1.86-1.85-1.53-2.776-3.543-2.776-6.032zm5.836-.014c0 1.352.273 2.354.823 3.003s1.236.973 2.067.973c.837 0 1.526-.32 2.066-.959.54-.639.809-1.665.809-3.074 0-1.317-.27-2.293-.816-2.94-.544-.642-1.215-.965-2.017-.965-.848 0-1.548.327-2.101.98-.554.653-.831 1.647-.831 2.982zM221.29 52.83h5.431v2.215c.785-.923 1.573-1.583 2.368-1.974s1.757-.586 2.886-.586c1.21 0 2.173.213 2.88.643.706.43 1.284 1.068 1.732 1.917.912-.99 1.746-1.665 2.499-2.024s1.679-.536 2.783-.536c1.626 0 2.9.483 3.812 1.452.913.97 1.37 2.482 1.37 4.54v9.536h-5.832v-8.651c0-.685-.135-1.197-.397-1.53-.39-.526-.874-.785-1.452-.785-.685 0-1.236.249-1.655.742-.418.497-.628 1.292-.628 2.39v7.834h-5.832v-8.364c0-.667-.04-1.118-.114-1.36a1.869 1.869 0 00-.646-.922 1.716 1.716 0 00-1.076-.352c-.67 0-1.221.252-1.65.756-.43.504-.647 1.335-.647 2.489v7.75h-5.832V52.83zM265.188 61.864H253.52c.103.934.358 1.63.756 2.088.56.656 1.296.987 2.2.987a3.29 3.29 0 001.63-.43c.316-.181.653-.5 1.016-.958l5.733.529c-.877 1.526-1.935 2.62-3.174 3.28-1.239.664-3.017.994-5.332.994-2.013 0-3.593-.284-4.746-.852-1.154-.568-2.109-1.466-2.869-2.702-.756-1.235-1.136-2.684-1.136-4.352 0-2.375.76-4.295 2.28-5.762 1.519-1.466 3.62-2.2 6.297-2.2 2.173 0 3.89.33 5.148.986 1.256.657 2.215 1.612 2.875 2.862s.987 2.875.987 4.874v.656zm-5.917-2.786c-.114-1.126-.416-1.932-.91-2.418-.489-.486-1.135-.728-1.937-.728-.923 0-1.665.366-2.216 1.1-.351.459-.575 1.14-.67 2.046h5.733z"
    />
    <path
      className="prefix__st2"
      d="M263.609 52.83h6.904l2.41 4.231 2.809-4.231h6.418l-5.18 7.235 5.553 7.948h-6.791l-2.808-4.888-3.295 4.888h-6.304l5.516-7.948-5.232-7.235z"
    />
  </svg>
);

ColoredLogoIcon.displayName = 'ColoredLogo';
ColoredLogoIcon.muiName = 'SvgIcon';

export default ColoredLogoIcon;
