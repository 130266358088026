import { Grid } from '@material-ui/core';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, useFormState } from 'react-final-form';
import { useSelector } from 'react-redux';
import { isFunction } from 'lodash';
import i18n from '../../i18n';
import QCXFinalBondManagerOld from '../../shared-components/final-gerenciador-relacionamento/QCXFinalBondManagerOld';
import TipoDocumentoDueUtils from '../../shared-components/select-tipo-documento-due/TipoDocumentoDueUtils';
import QCXFinalTextField from '../../shared-components/final-text-field/QCXFinalTextField';
import QCXFinalNumericDecimalField from '../../shared-components/final-numeric-decimal-field/QCXFinalNumericDecimalField';
import { selectTiposDocumentoDue } from '../../features/tipo-documento-due/tipoDocumentoDueSlice';
import QCXFormSubtitle from '../form-title/QCXFormSubtitle';
import QCXFormListenerManager from '../../shared-components/form-listener/QCXFormListenerManager';

export default function QCXOperacaoExportacaoFormGroup({
  title = i18n.t('com.muralis.qcx.operacaoExportacaoVinculadaImportacaoAnteriorReexportacao'),
  fieldProps = {
    operacaoExportacao: {
      tipoDocumentoDue: {
        id: 'select-field-tipo-documento-due',
        key: 'select-field-tipo-documento-due',
        name: 'ignorableFields.operacaoConsignacaoVinculada.tipoDocumento.id',
        reducerConfig: {
          selectList: selectTiposDocumentoDue,
        },
      },
      numeroDocumento: {
        id: 'text-field-numero-documento',
        key: 'text-field-numero-documento',
        name: 'ignorableFields.operacaoConsignacaoVinculada.numeroDocumento',
      },
      itemDue: {
        id: 'text-field-item-due',
        key: 'text-field-item-due',
        name: 'ignorableFields.operacaoConsignacaoVinculada.itemDue',
      },
      numeroProcessoAdm: {
        id: 'text-field-numero-processo-adm',
        key: 'text-field-numero-processo-adm',
        name: 'ignorableFields.operacaoConsignacaoVinculada.numeroProcessoAdm',
      },
      quantidadeEstatisticaAssociada: {
        id: 'numeric-decimal-quantidade-estatistica-associada-field',
        key: 'numeric-decimal-quantidade-estatistica-associada-field',
        name: 'ignorableFields.operacaoConsignacaoVinculada.quantidadeEstatisticaAssociada',
      },
    },
  },
  disabled,
  isParentConsult,
  listProps,
  formProps,
  reducerConfig,
  handleAdd,
  handleUpdate,
  handleAlreadyExists,
  children,
}) {
  const { t } = useTranslation();
  const form = useForm();
  const { values } = useFormState();

  const [isTipoDocumentoDUE, setTipoDocumentoDUE] = useState(false);
  const [isTipoDocumentoDI, setTipoDocumentoDI] = useState(false);

  const { selectList } = fieldProps?.operacaoExportacao?.tipoDocumentoDue?.reducerConfig;

  const tiposDocumentoDue = useSelector(selectList);

  const operacaoExportacaoDecorator = useCallback(
    (tools) => {
      const finalBondManagerForm = tools?.formUtils?.form;

      finalBondManagerForm.change(fieldProps?.operacaoExportacao?.quantidadeEstatisticaAssociada?.name, '');
    },
    [fieldProps]
  );

  const onTipoDocumentoDueChange = useCallback(
    () => (value) => {
      if (value) {
        const isTipoDue = TipoDocumentoDueUtils.isDUE(tiposDocumentoDue)(value);

        // verifica se é DI pelo id da opção selecionada
        // não utilizei o utils pois precisava de um ajuste rapido
        // e não tinha tempo de interpretar o código das utils
        const isTipoDI = value === 469149;

        if (isTipoDue) {
          setTipoDocumentoDUE(true);
          return;
        }

        if (isTipoDI) {
          setTipoDocumentoDI(true);
          return;
        }
      }

      form.change(fieldProps?.operacaoExportacao?.itemDue.name, undefined);
      setTipoDocumentoDUE(false);
    },
    [form, tiposDocumentoDue, fieldProps]
  );

  const onChangeListeners = useMemo(
    () => [
      {
        name: fieldProps.operacaoExportacao.tipoDocumentoDue.name,
        fn: onTipoDocumentoDueChange,
      },
    ],
    [fieldProps, onTipoDocumentoDueChange]
  );

  return (
    <>
      <QCXFormListenerManager form={form} values={values} onChangeListeners={onChangeListeners} />
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <QCXFormSubtitle
          title={title}
          boxProps={{
            pt: 1,
          }}
        />
      </Grid>
      <QCXFinalBondManagerOld
        isParentConsult={isParentConsult}
        listProps={listProps}
        formProps={formProps}
        reducerConfig={reducerConfig}
        handleAdd={handleAdd}
        handleUpdate={handleUpdate}
        handleAlreadyExists={handleAlreadyExists}
        {...{
          onClearDecorator: operacaoExportacaoDecorator,
          onAddDecorator: operacaoExportacaoDecorator,
        }}
      >
        {({ loading }) => (
          <>
            {isFunction(children)
              ? children({
                  ...fieldProps,
                  loading,
                  disabled,
                })
              : children}
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <QCXFinalTextField
                {...fieldProps.operacaoExportacao.numeroDocumento}
                label={t('com.muralis.qcx.documento.numeroDocumento')}
                disabled={disabled || loading}
              />
            </Grid>
            {(isTipoDocumentoDUE || isTipoDocumentoDI) && (
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <QCXFinalTextField
                  {...fieldProps.operacaoExportacao.itemDue}
                  label={t('com.muralis.qcx.dueExportacao.ItemDUE')}
                  disabled={disabled || loading}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <QCXFinalTextField
                {...fieldProps.operacaoExportacao.numeroProcessoAdm}
                label={t('com.muralis.qcx.numeroProcessoADM')}
                disabled={disabled || loading}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <QCXFinalNumericDecimalField
                {...fieldProps.operacaoExportacao.quantidadeEstatisticaAssociada}
                label={t('com.muralis.qcx.quantidade.quantidadeEstatisticaAssociada')}
                disabled={disabled || loading}
                scale={5}
              />
            </Grid>
          </>
        )}
      </QCXFinalBondManagerOld>
    </>
  );
}
