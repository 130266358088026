import { MatcherConfigureUtils, sortCollectionBy } from '../../utils/general/general-utils';

const TIPO_DOCUMENTO_DUE_DSE_CODE = 'TDD01';
const TIPO_DOCUMENTO_DUE_DSE_FORMULARIO_CODE = 'TDD02';
const TIPO_DOCUMENTO_DUE_DUE_CODE = 'TDD03';
const TIPO_DOCUMENTO_DUE_RE_CODE = 'TDD04';

const FILTER_TIPO_DOCUMENTO_DUE_CONSIGNACAO = 'consignacao';
const FILTER_TIPO_DOCUMENTO_DUE_TEMPORARIA = 'temporaria';

const isTipoDocumentoDueDSE = (
  MatcherConfigureUtils.getSpecificByCode(
    TIPO_DOCUMENTO_DUE_DSE_CODE
  )
);

const isTipoDocumentoDueDSEFormulario = (
  MatcherConfigureUtils.getSpecificByCode(
    TIPO_DOCUMENTO_DUE_DSE_FORMULARIO_CODE
  )
);

const isTipoDocumentoDueDUE = (
  MatcherConfigureUtils.getSpecificByCode(
    TIPO_DOCUMENTO_DUE_DUE_CODE
  )
);

const isTipoDocumentoDueRE = (
  MatcherConfigureUtils.getSpecificByCode(
    TIPO_DOCUMENTO_DUE_RE_CODE
  )
);

const sortTipoDocumentoDueListByDescription = (list = []) => (
  sortCollectionBy(
    list,
    'description'
  )
);

const TipoDocumentoDueUtils = Object.freeze({
  DSE: (
    TIPO_DOCUMENTO_DUE_DSE_CODE
  ),
  DSE_FORMULARIO: (
    TIPO_DOCUMENTO_DUE_DSE_FORMULARIO_CODE
  ),
  DUE: (
    TIPO_DOCUMENTO_DUE_DUE_CODE
  ),
  RE: (
    TIPO_DOCUMENTO_DUE_RE_CODE
  ),
  FILTER_CONSIGNACAO: (
    FILTER_TIPO_DOCUMENTO_DUE_CONSIGNACAO
  ),
  FILTER_TEMPORARIA: (
    FILTER_TIPO_DOCUMENTO_DUE_TEMPORARIA
  ),
  isNotaDSE: (
    isTipoDocumentoDueDSE
  ),
  isDSEFormulario: (
    isTipoDocumentoDueDSEFormulario
  ),
  isDUE: (
    isTipoDocumentoDueDUE
  ),
  isRE: (
    isTipoDocumentoDueRE
  ),
  sortByDescription: (
    sortTipoDocumentoDueListByDescription
  ),
});

export default TipoDocumentoDueUtils;
