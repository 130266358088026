const endpoint = process.env.REACT_APP_KEYCLOAK_JWT_STORAGE_ENDPOINT!;

const storeJWT = async (jwt: string) => {
  const response = await fetch(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': process.env.REACT_APP_KEYCLOAK_JWT_STORAGE_KEY!,
    },
    body: JSON.stringify(jwt),
  });
  const data = await response.text();
  return data;
};

export { storeJWT };
