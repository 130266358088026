import { Grid } from '@material-ui/core';
import React, {
  useMemo,
  useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import QCXFinalCheckboxField from '../../shared-components/final-checkbox-field/QCXFinalCheckboxField';
import QCXFinalAlternativeCurrencyField from '../../shared-components/final-currency-field/QCXFinalAlternativeCurrencyField';
import QCXFinalForm from '../../shared-components/final-form/QCXFinalForm';
import QCXSelectMoedaAutocomplete from '../../shared-components/final-select-moeda-field/QCXSelectMoedaAutocomplete';
import QCXFinalTextField from '../../shared-components/final-text-field/QCXFinalTextField';
import QCXSelectBancoAutocompleteField from '../../shared-components/select-banco/QCXSelectBancoAutocompleteField';
import { formatAgencia, formatContaCorrente } from '../../utils/hooks/form/field/formatters';
import { parseAgencia, parseContaCorrente } from '../../utils/hooks/form/field/parsers';
import {
  validateAgencia,
  validateContaCorrente,
  required as requiredValidator,
} from '../../utils/validators/field/validator';

export default function QCXContaBancariaForm({
  isConsult,
  isUpdate,
  initialValues,
  handleSubmit,
  handleChangeToConsult,
  handleChangeToUpdate,
  handleCancelUpdate,
  ...restProps
}) {
  const { t } = useTranslation();

  const handleControlarSaldoChange = useCallback((form) => (value) => {
    if (!value) {
      form.change('moeda', undefined);
      form.change('saldoInicial', undefined);
    }
  }, []);

  const listenerList = useMemo(() => ([
    {
      name: 'controlarSaldo',
      fn: handleControlarSaldoChange,
    },
  ]), [handleControlarSaldoChange]);

  return (
    <>
      <QCXFinalForm
        isConsult={isConsult}
        isUpdate={isUpdate}
        initialValues={initialValues}
        handleSubmitValues={handleSubmit}
        handleChangeToUpdate={handleChangeToUpdate}
        handleCancelUpdate={handleCancelUpdate}
        onChangeListeners={listenerList}
        {...restProps}
      >
        {({ values }) => (
          <>
            {(isConsult || isUpdate) && (
              <Grid item xs={12} sm={3} md={2} lg={2}>
                <QCXFinalTextField
                  id="text-field-code"
                  key="text-field-code"
                  name="id"
                  label={t('com.muralis.qcx.codigo')}
                  disabled
                />
              </Grid>
            )}
            <Grid
              item
              xs={12}
              {...((isConsult || isUpdate)
                ? { sm: 9, md: 6, lg: 6 }
                : { sm: 12, md: 6, lg: 7 }
              )}
            >
              <QCXFinalTextField
                id="text-field-description"
                key="text-field-description"
                name="description"
                label={t('com.muralis.qcx.descricao')}
                maxLength={30}
                disabled={isConsult}
                validate={requiredValidator}
                required
              />
            </Grid>
            <Grid
              item
              xs={12}
              {...((isConsult || isUpdate)
                ? { sm: 12, md: 4, lg: 4 }
                : { sm: 12, md: 6, lg: 5 }
              )}
            >
              <QCXSelectBancoAutocompleteField
                id="autocomplete-select-banco-field"
                key="autocomplete-select-banco-field"
                name="banco.id"
                label={t('com.muralis.qcx.banco.label')}
                initialValues={values}
                fieldProps={{ validate: requiredValidator }}
                disabled={isConsult}
                required
              />
            </Grid>
            <Grid
              item
              xs={12}
              {...((isConsult || isUpdate)
                ? { sm: 6, md: 5, lg: 3 }
                : { sm: 6, md: 5, lg: 3 }
              )}
            >
              <QCXFinalTextField
                id="text-field-agencia"
                key="text-field-agencia"
                name="agencia"
                label={t('com.muralis.qcx.banco.agencia')}
                validate={validateAgencia}
                parse={parseAgencia}
                format={formatAgencia}
                formatOnBlur
                maxLength={6}
                disabled={isConsult}
              />
            </Grid>
            <Grid
              item
              xs={12}
              {...((isConsult || isUpdate)
                ? { sm: 6, md: 7, lg: 5 }
                : { sm: 6, md: 7, lg: 4 }
              )}
            >
              <QCXFinalTextField
                id="text-field-conta-corrente"
                key="text-field-conta-corrente"
                name="contaCorrente"
                label={t('com.muralis.qcx.banco.contaCorrente')}
                validate={validateContaCorrente}
                parse={parseContaCorrente}
                format={formatContaCorrente}
                formatOnBlur
                maxLength={11}
                disabled={isConsult}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
            >
              <QCXFinalCheckboxField
                id="checkbox-field-controlar-saldo"
                key="checkbox-field-controlar-saldo"
                name="controlarSaldo"
                label={t('com.muralis.qcx.banco.controlarSaldo')}
                disabled={isConsult}
              />
            </Grid>
            {values?.controlarSaldo && (
              <>
                <Grid
                  item
                  xs={12}
                  {...((isConsult || isUpdate)
                    ? { sm: 7, md: 4, lg: 4 }
                    : { sm: 7, md: 4, lg: 5 }
                  )}
                >
                  <QCXSelectMoedaAutocomplete
                    id="autocomplete-select-moeda-field"
                    key="autocomplete-select-moeda-field"
                    name="moeda.id"
                    label={t('com.muralis.qcx.moeda.label')}
                    initialValues={values}
                    fieldProps={{ validate: requiredValidator }}
                    disabled={isConsult || !values?.controlarSaldo}
                    required
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={5}
                  md={3}
                >
                  <QCXFinalAlternativeCurrencyField
                    id="numeric-decimal-saldo-inicial-field"
                    key="numeric-decimal-saldo-inicial-field"
                    name="saldoInicial"
                    label={t('com.muralis.qcx.conta.saldoInicial')}
                    scale={2}
                    disabled={isConsult || !values?.controlarSaldo}
                  />
                </Grid>
              </>
            )}
          </>
        )}
      </QCXFinalForm>
    </>
  );
}
