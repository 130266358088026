import { isArray, isArrayLikeObject } from 'lodash';
import {
  sortCollectionBy,
  isStrictEquals,
} from '../general-utils';

const WARNING_FLAG = '[WARNING]';
const NUMERO_CONHECIMENTO_HOUSE_FLAG = '[NUMERO_CONHECIMENTO_HOUSE]';
const NUMERO_CONHECIMENTO_MASTER_FLAG = '[NUMERO_CONHECIMENTO_MASTER]';
const NUMERO_FATURA_FLAG = '[NUMERO_FATURA]';
const RESTRICAO_FINANCEIRA_FLAG = '[RESTRICAO_FINANCEIRA]';
const VENCIMENTO_RADAR_FLAG = '[VENCIMENTO_RADAR]';
const VENCIMENTO_PROCURACAO_FLAG = '[VENCIMENTO_PROCURACAO]';

const NOOP_WARNING = 'noopWarning';
const WARNING_ON_CREATING = 'warningOnCreating';
const WARNING_ON_UPDATING = 'warningOnUpdating';

const CONFIRMACAO_REGRA_VALIDADO = 'VALIDO';
const CONFIRMACAO_REGRA_NAO_VALIDADO = 'NAO_VALIDO';
const CONFIRMACAO_REGRA_PENDENTE = 'PENDENTE';

const CORRIGIDO = 'CORRIGIDO';
const NAO_CORRIGIDO = 'NAO_CORRIGIDO';

const IGNORAR_ERRO_FATURA = 'IGNORAR_ERRO_FATURA';
const NAO_IGNORAR_ERRO_FATURA = 'NAO_IGNORAR_ERRO_FATURA';

const STATUS_FATURA_NAO_INTEGRADO = 'NAO_INTEGRADO';
const STATUS_FATURA_INTEGRADO = 'INTEGRADO';
const STATUS_FATURA_ERRO = 'ERRO';
const STATUS_FATURA_CONCLUIDO = 'CONCLUIDO';

const VALIDATION_PROPERTY_NAME_FLAG_PAIRS = [
  {
    propertyName: 'numeroConhecimentoMasterValido',
    flag: NUMERO_CONHECIMENTO_MASTER_FLAG,
  },
  {
    propertyName: 'numeroConhecimentoHouseValido',
    flag: NUMERO_CONHECIMENTO_HOUSE_FLAG,
  },
  {
    propertyName: 'restricaoFinanceiraValido',
    flag: RESTRICAO_FINANCEIRA_FLAG,
  },
  {
    propertyName: 'vencimentoRadarValido',
    flag: VENCIMENTO_RADAR_FLAG,
  },
  {
    propertyName: 'vencimentoProcuracaoValido',
    flag: VENCIMENTO_PROCURACAO_FLAG,
  },
  {
    propertyName: 'numeroFaturaValido',
    flag: NUMERO_FATURA_FLAG,
  },
];

const hasWarningFlag = (message = '') => (
  String(message).includes(WARNING_FLAG)
);
const hasNumeroConhecimentoHouseFlag = (message = '') => (
  String(message).includes(NUMERO_CONHECIMENTO_HOUSE_FLAG)
);
const hasNumeroConhecimentoMasterFlag = (message = '') => (
  String(message).includes(NUMERO_CONHECIMENTO_MASTER_FLAG)
);
const hasNumeroFaturaFlag = (message = '') => (
  String(message).includes(NUMERO_FATURA_FLAG)
);
const hasRestricaoFinanceiraFlag = (message = '') => (
  String(message).includes(RESTRICAO_FINANCEIRA_FLAG)
);
const hasVencimentoRadarFlag = (message = '') => (
  String(message).includes(VENCIMENTO_RADAR_FLAG)
);
const hasVencimentoProcuracaoFlag = (message = '') => (
  String(message).includes(VENCIMENTO_PROCURACAO_FLAG)
);
const FLAG_MATCHERS = [
  {
    flag: NUMERO_CONHECIMENTO_HOUSE_FLAG,
    match: hasNumeroConhecimentoHouseFlag,
  },
  {
    flag: NUMERO_CONHECIMENTO_MASTER_FLAG,
    match: hasNumeroConhecimentoMasterFlag,
  },
  {
    flag: NUMERO_FATURA_FLAG,
    match: hasNumeroFaturaFlag,
  },
  {
    flag: RESTRICAO_FINANCEIRA_FLAG,
    match: hasRestricaoFinanceiraFlag,
  },
  {
    flag: VENCIMENTO_RADAR_FLAG,
    match: hasVencimentoRadarFlag,
  },
  {
    flag: VENCIMENTO_PROCURACAO_FLAG,
    match: hasVencimentoProcuracaoFlag,
  },
];
const getMatchedFlagBy = (message = '') => {
  const [matched] = FLAG_MATCHERS.filter((flagMatcher) => (
    flagMatcher.match(message)
  ));

  return matched?.flag;
};
const getMessageWithoutFlag = (message = '', flag = WARNING_FLAG) => (
  String(message).replace(flag, '')
);

const isWarningOnCreating = (value) => (
  WARNING_ON_CREATING === value
);
const isWarningOnUpdating = (value) => (
  WARNING_ON_UPDATING === value
);
const isWarningOperation = (value) => (
  isWarningOnCreating(value) || isWarningOnUpdating(value)
);

const isConfirmacaoRegraValidada = (value) => (
  CONFIRMACAO_REGRA_NAO_VALIDADO === value
);
const isConfirmacaoRegraNaoValidada = (value) => (
  CONFIRMACAO_REGRA_VALIDADO === value
);
const isConfirmacaoRegraPendente = (value) => (
  CONFIRMACAO_REGRA_PENDENTE === value
);
const getValidatedResultIfPendingBy = (message = '') => {
  const foundPropertyFlagPair = VALIDATION_PROPERTY_NAME_FLAG_PAIRS.find(
    (propertyFlagPair) => {
      const flag = getMatchedFlagBy(message);

      return flag === propertyFlagPair?.flag;
    }
  );

  return {
    result: {
      [foundPropertyFlagPair?.propertyName]: CONFIRMACAO_REGRA_VALIDADO,
    },
    message: getMessageWithoutFlag(
      message,
      foundPropertyFlagPair?.flag
    ),
  };
};

const sortEtapasBySequencia = (etapas) => (
  sortCollectionBy(
    etapas,
    'sequencia'
  )
);
const isCorrigido = (type) => {
  const value = isArray(type) ? type[0] : type;
  return isStrictEquals(CORRIGIDO, value);
};

const normalizeCorrigidoFollowUp = (type) => {
  if (isCorrigido(type)) {
    return CORRIGIDO;
  }

  return NAO_CORRIGIDO;
};

const unnormalizeCorrigidoFollowUp = (type) => {
  if (isCorrigido(type)) {
    return [CORRIGIDO];
  }

  return [];
};

const isIgnorarErroFatura = (type) => {
  const value = isArray(type) ? type[0] : type;
  return isStrictEquals(IGNORAR_ERRO_FATURA, value);
};

const normalizeIgnorarErroFatura = (type) => {
  if (isIgnorarErroFatura(type)) {
    return IGNORAR_ERRO_FATURA;
  }

  return NAO_IGNORAR_ERRO_FATURA;
};

const unnormalizeIgnorarErroFatura = (type) => {
  if (isIgnorarErroFatura(type)) {
    return [IGNORAR_ERRO_FATURA];
  }

  return [];
};

const parseXmlDanfeListToFormData = (files) => {
  const formData = new FormData();

  if (isArrayLikeObject(files)) {
    files.forEach((file) => {
      formData.append('files[]', file);
    });
  }

  return formData;
};

const FollowUpUtils = {
  WARNING_FLAG,
  NUMERO_CONHECIMENTO_HOUSE_FLAG,
  NUMERO_CONHECIMENTO_MASTER_FLAG,
  NUMERO_FATURA_FLAG,
  RESTRICAO_FINANCEIRA_FLAG,
  VENCIMENTO_RADAR_FLAG,
  VENCIMENTO_PROCURACAO_FLAG,
  NOOP_WARNING,
  WARNING_ON_CREATING,
  WARNING_ON_UPDATING,
  CORRIGIDO,
  NAO_CORRIGIDO,
  IGNORAR_ERRO_FATURA,
  NAO_IGNORAR_ERRO_FATURA,
  STATUS_FATURA_NAO_INTEGRADO,
  STATUS_FATURA_INTEGRADO,
  STATUS_FATURA_ERRO,
  STATUS_FATURA_CONCLUIDO,
  hasWarningFlag,
  hasNumeroConhecimentoHouseFlag,
  hasNumeroConhecimentoMasterFlag,
  hasNumeroFaturaFlag,
  hasRestricaoFinanceiraFlag,
  hasVencimentoRadarFlag,
  hasVencimentoProcuracaoFlag,
  isWarningOperation,
  isWarningOnCreating,
  isWarningOnUpdating,
  getMessageWithoutFlag,
  CONFIRMACAO_REGRA_VALIDADO,
  CONFIRMACAO_REGRA_NAO_VALIDADO,
  isConfirmacaoRegraValidada,
  isConfirmacaoRegraNaoValidada,
  isConfirmacaoRegraPendente,
  getValidatedResultIfPendingBy,
  sortEtapasBySequencia,
  normalizeCorrigidoFollowUp,
  unnormalizeCorrigidoFollowUp,
  normalizeIgnorarErroFatura,
  unnormalizeIgnorarErroFatura,
  parseXmlDanfeListToFormData,
};

export default FollowUpUtils;
