import { Grid } from '@material-ui/core';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import QCXFinalForm from '../../shared-components/final-form/QCXFinalForm';
import QCXFinalOnblurMaskedField from '../../shared-components/final-onblur-masked-field/QCXFinalOnblurMaskedField';
import QCXFinalTextField from '../../shared-components/final-text-field/QCXFinalTextField';
import { padStart } from '../../utils/general/general-utils';
import { numberOnly, parseSiglaMoeda } from '../../utils/hooks/form/field/parsers';
import useValidators from '../../utils/hooks/useValidators';
import { required as requiredValidator, validMoedaCode } from '../../utils/validators/field/validator';

export default function QCXMoedaForm({
  isConsult,
  isUpdate,
  initialValues,
  handleSubmit,
  handleChangeToUpdate,
  handleCancelUpdate,
  authInfo = {},
  requiredRoles = [],
  ...restProps
}) {
  const { t } = useTranslation();

  const codeValidators = useMemo(() => (
    useValidators([
      requiredValidator,
      validMoedaCode,
    ])
  ), []);

  return (
    <QCXFinalForm
      isConsult={isConsult}
      isUpdate={isUpdate}
      initialValues={initialValues}
      handleSubmitValues={handleSubmit}
      handleChangeToUpdate={handleChangeToUpdate}
      handleCancelUpdate={handleCancelUpdate}
      authInfo={authInfo}
      requiredRoles={requiredRoles}
      {...restProps}
    >
      {({ values }) => (
        <>
          <Grid item xs={12} sm={2} md={2}>
            <QCXFinalOnblurMaskedField
              id="text-field-code"
              key="text-field-code"
              name="code"
              label={t('com.muralis.qcx.codigo')}
              maxLength={3}
              parse={numberOnly}
              initialValues={values}
              format={(value) => padStart(value, 3, '0')}
              validate={codeValidators}
              disabled={isConsult || isUpdate}
              required
            />
          </Grid>
          <Grid item xs={12} sm={8} md={8}>
            <QCXFinalTextField
              id="text-field-description"
              key="text-field-description"
              name="description"
              label={t('com.muralis.qcx.descricao')}
              maxLength={40}
              disabled={isConsult || isUpdate}
              validate={requiredValidator}
              required
            />
          </Grid>
          <Grid item xs={12} sm={2} md={2}>
            <QCXFinalOnblurMaskedField
              id="text-field-sigla"
              key="text-field-sigla"
              name="sigla"
              label={t('com.muralis.qcx.sigla')}
              maxLength={3}
              format={(value) => value}
              parse={parseSiglaMoeda}
              disabled={isConsult}
            />
          </Grid>
        </>
      )}
    </QCXFinalForm>
  );
}
