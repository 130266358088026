const SUCCESS_SEVERITY = 'success';
const INFO_SEVERITY = 'info';
const WARNING_SEVERITY = 'warning';
const ERROR_SEVERITY = 'error';

export {
  SUCCESS_SEVERITY,
  INFO_SEVERITY,
  WARNING_SEVERITY,
  ERROR_SEVERITY,
};
