import React, { useEffect, useState } from 'react';
import { createFilterOptions } from '@material-ui/lab/useAutocomplete';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllAsync, selectDocumentosInstrucaoDespacho } from '../../features/documento-instrucao-despacho/documentoInstrucaoDespachoSlice';
import QCXAutocompleteSelect from '../autocomplete-select/QCXAutocompleteSelect';

export default function QCXSelectDocumentoInstrucaoAutocomplete({
  label,
  name,
  initialValues,
  ...restProps
}) {
  const dispatch = useDispatch();

  const list = useSelector(selectDocumentosInstrucaoDespacho);

  const [defaultValue, setDefaultValue] = useState('');

  useEffect(() => {
    dispatch(fetchAllAsync());
  }, []);

  useEffect(() => {
    const value = list.find((item) => item?.id === _.get(initialValues, name));
    if (value) {
      setDefaultValue(value);
    } else {
      setDefaultValue({ id: null, description: '' });
    }
  }, [list, initialValues]);

  const filterOptions = createFilterOptions({
    stringify: (item) => (
      `${item?.code} - ${item?.description}`
    ),
  });

  return (
    <QCXAutocompleteSelect
      label={label}
      id={name}
      name={name}
      options={list}
      filterOptions={filterOptions}
      defaultValue={defaultValue}
      getOptionLabel={(item) => (
        item?.code && item?.description
          ? `${item?.code} - ${item?.description}`
          : ''
      )}
      {...restProps}
    />
  );
}
