import moment from 'moment';
import { useForm } from 'react-final-form';
import { useCallback } from 'react';
import QCXDateTimePicker from '../date-time-picker/QCXDateTimePicker';
import QCXFinalField from '../final-field/QCXFinalField';

export default function QCXFinalDateTimePickerField({
  name,
  label,
  format = 'DD/MM/YYYY HH:mm',
  placeholder = 'DD/MM/YYYY hh:mm',
  disablePast = false,
  disableFuture = false,
  minDate = moment('1900-01-01').toDate(),
  ...restProps
}) {
  const form = useForm();

  const handleDoubleClick = useCallback(() => {
    form.change(name, moment());
  }, []);

  return (
    <QCXFinalField
      fullWidth
      name={name}
      render={({ input: { name: inputName, value, onChange, ...restInput }, meta, ...restComponentProps }) => {
        const showError = ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched;

        return (
          <QCXDateTimePicker
            onDoubleClick={handleDoubleClick}
            name={inputName}
            label={label}
            value={value === '' ? null : value}
            onChange={onChange}
            format={format}
            placeholder={placeholder}
            error={showError}
            inputProps={restInput}
            helperText={showError ? meta.error || meta.submitError : undefined}
            disablePast={disablePast}
            disableFuture={disableFuture}
            minDate={minDate}
            {...restComponentProps}
          />
        );
      }}
      {...restProps}
    />
  );
}
