import MenuIcon from '@material-ui/icons/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import NotificationsIcon from '@mui/icons-material/Notifications';
import {
  AppBar,
  Badge,
  Box,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  Link,
  Menu,
  MenuItem,
  Select,
  SelectChangeEvent,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import { useKeycloak } from '@react-keycloak/web';
import { useAtom } from 'jotai';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  selectUnidadesDeNegocioAssociadas,
  selectUnidadeSelecionada,
  setUnidadeSelecionada,
} from '../../../features/usuario-logado/usuarioLogadoSlice';
import { isDrawerOpenAtom } from '../atoms/atoms';
import ColoredLogoIcon from './logo';

interface IUnidadesDeNegocioAssociadas {
  code: string;
  description: string;
  id: number;
  pessoa: {
    nome: string;
    nomeResumido: string;
  };
  sigla: string;
}

// const useStyles = makeStyles(() => ({
//   grow: {
//     flexGrow: 1,
//   },
//   logo: {
//     height: '2rem',
//     width: '10.5rem',
//     marginLeft: '2px',
//     marginTop: '5px',
//   },
//   logoIcon: {
//     height: '2rem',
//   },
// }));

const TopBar = () => {
  const dispatch = useDispatch();

  const [isDrawerOpen, setIsDrawerOpen] = useAtom(isDrawerOpenAtom);

  const handleDrawerStateChange = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  // Keycloak
  const { keycloak, initialized } = useKeycloak();
  const logout = () => {
    localStorage.clear();
    keycloak?.logout({ redirectUri: window.location.origin });
  };

  // Ignoring Typescript alert for keycloak and react router because
  // packages are not updated to support typescript

  // @ts-ignore
  // prettier-ignore
  const userName = keycloak.tokenParsed?.given_name + ' ' + keycloak.tokenParsed?.family_name;
  // @ts-ignore
  const role = keycloak.tokenParsed?.assignedRole;

  const history = useHistory();

  const list: IUnidadesDeNegocioAssociadas[] = useSelector(
    selectUnidadesDeNegocioAssociadas
  );
  const selectedBusiness = useSelector(selectUnidadeSelecionada);

  const handleChangeBusiness = (e: SelectChangeEvent<any>) => {
    if (e.target.value) {
      dispatch(setUnidadeSelecionada(e.target.value));
      localStorage.setItem('tenant', e.target.value);
    } else {
      dispatch(setUnidadeSelecionada(null));
    }
    history.push('/');
  };

  // AppBar Menu handlers
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorElement(null);
  };
  const isMenuOpen = Boolean(anchorElement);

  /**
   * It's a function that takes an event as an argument and prevents the default action of the event,
   * then pushes the user to the home page
   */
  const navigateToHome = (
    e:
      | React.MouseEvent<HTMLAnchorElement, MouseEvent>
      | React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => {
    e.preventDefault();
    history.push('/');
  };

  return (
    <AppBar
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      position="fixed"
      style={{
        padding: '5px 0px 6px',
        backgroundColor: '#fff',
      }}
    >
      <Toolbar variant="dense">
        <IconButton
          aria-label="Menu"
          color="primary"
          sx={{ display: { xl: 'none', xs: 'block' } }}
          style={{ marginRight: '10px' }}
          onClick={handleDrawerStateChange}
        >
          <MenuIcon />
        </IconButton>
        <Link href="" onClick={(e) => navigateToHome(e)}>
          <ColoredLogoIcon
            width={'10.5rem'}
            height={'2rem'}
            style={{ margin: '7px 0 0 -12px' }}
          />
        </Link>

        <Box sx={{ flexGrow: 1 }} />
        <FormControl sx={{ m: 1, width: 400 }} size="small">
          <InputLabel
            id="demo-simple-select-label"
            style={{ fontSize: '14px' }}
          >
            UNIDADE DE NEGÓCIO
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="UNIDADE DE NEGÓCIO"
            defaultValue={selectedBusiness}
            onChange={handleChangeBusiness}
            style={{ fontSize: '14px', padding: '0px' }}
          >
            {list.map((item) => (
              <MenuItem value={item.id}>
                {item.pessoa.nomeResumido + ' - ' + item.id}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Tooltip arrow title={'Notificações'}>
          <IconButton color="primary">
            {/* <Badge badgeContent={1} color="error"> */}
            <NotificationsIcon />
            {/* </Badge> */}
          </IconButton>
        </Tooltip>
        <Tooltip arrow title={'Conta'}>
          <IconButton color="primary" onClick={handleProfileMenuOpen}>
            <AccountCircleIcon />
          </IconButton>
        </Tooltip>
      </Toolbar>
      <Menu
        anchorEl={anchorElement}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        id={'account-menu'}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        <Typography
          style={{ padding: '0 8px 0 15px' }}
          fontWeight={'bold'}
          color={'primary'}
        >
          {userName}
        </Typography>
        <Typography
          style={{ padding: '0 8px 0 15px' }}
          fontWeight={'bold'}
          color={'primary'}
        >
          {role}
        </Typography>
        <Divider
          variant="middle"
          style={{ marginTop: '2px', marginBottom: '2px' }}
        />
        <MenuItem onClick={handleMenuClose}>Minha Conta</MenuItem>
        <MenuItem onClick={logout}>Sair</MenuItem>
      </Menu>
    </AppBar>
  );
};

export default TopBar;
