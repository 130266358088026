import { getOptionByValueInList } from '../../utils/general/general-utils';
import i18n from '../../i18n';

const FRETE_POR_CONTA_DO_EMITENTE = 'FRETE_POR_CONTA_DO_EMITENTE';
const FRETE_POR_CONTA_DO_DESTINATARIO = 'FRETE_POR_CONTA_DO_DEST';

// Originalmente a constante FRETE_POR_CONTA_DO_DESTINATARIO realmente
// tinha o valor "FRETE_POR_CONTA_DO_DESTINATARIO", mas por descuido meu
// eu criei o campo correspondende no banco como um varchar(30), que não
// é suficiente. A correção mais simples foi diminuir o tamanho da string.

const FRETE_POR_CONTA_LIST = [
  {
    label: i18n.t('com.muralis.qcx.frete.emitente').toUpperCase(),
    value: FRETE_POR_CONTA_DO_EMITENTE,
  },
  {
    label: i18n.t('com.muralis.qcx.frete.destinatario').toUpperCase(),
    value: FRETE_POR_CONTA_DO_DESTINATARIO,
  },
];

const getFreteLabelByValue = (value) => {
  const foundOption = getOptionByValueInList(FRETE_POR_CONTA_LIST, value);
  return foundOption && foundOption?.label;
};

export {
  FRETE_POR_CONTA_DO_EMITENTE,
  FRETE_POR_CONTA_DO_DESTINATARIO,
  FRETE_POR_CONTA_LIST,
  getFreteLabelByValue,
};
