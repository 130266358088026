import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import QCXBox from '../../shared-components/box/QCXBox';
import QCXFinalCheckboxField from '../../shared-components/final-checkbox-field/QCXFinalCheckboxField';
import QCXFinalNumericIntegerField from '../../shared-components/final-numeric-integer-field/QCXFinalNumericIntegerField';
import QCXFinalTextField from '../../shared-components/final-text-field/QCXFinalTextField';
import QCXFormStepWizard from '../../shared-components/form-step-wizard/QCXFormStepWizard';
import QCXSelectAnaliseDesempenhoModuloAutocomplete from '../../shared-components/select-analise-desempenho-modulo/QCXSelectAnaliseDesempenhoModuloAutocomplete';
import QCXSelectEtapaAutocomplete from '../../shared-components/select-etapa/QCXSelectEtapaAutocomplete';
import QCXSelectTipoModeloFollowUpAutocomplete from '../../shared-components/select-tipo-modelo-follow-up/QCXSelectTipoModeloFollowUpAutocomplete';
import { required as requiredValidator } from '../../utils/validators/field/validator';

export default function QCXModeloFollowUpTecnicalDataForm({
  initialValues,
  handleSubmit,
  isCreate,
  isConsult,
  controlComponentProps,
  authInfo = {},
  requiredRoles = [],
}) {
  const { t } = useTranslation();

  return (
    <QCXFormStepWizard
      initialValues={initialValues}
      onSubmit={handleSubmit}
      controlComponentProps={controlComponentProps}
      authInfo={authInfo}
      requiredRoles={requiredRoles}
    >
      {({ values }) => (
        <>
          <Typography variant="h6" color="primary">
            <QCXBox fontWeight="fontWeightBold" pt={3}>
              {t('com.muralis.qcx.dadosTecnicos').toUpperCase()}
            </QCXBox>
          </Typography>
          <Grid container spacing={2} style={{ marginTop: '1rem' }}>
            {!isCreate && (
              <Grid item xs={12} sm={4} md={2}>
                <QCXFinalTextField
                  id="text-field-id-despachante"
                  key="text-field-id-despachante"
                  name="id"
                  label={t('com.muralis.qcx.codigo')}
                  disabled
                />
              </Grid>
            )}
            <Grid item xs={12} sm={isCreate ? 12 : 8} md={isCreate ? 6 : 7}>
              <QCXFinalTextField
                id="text-field-nome-modelo-follow-up"
                key="text-field-nome-despachante"
                name="description"
                label={t('com.muralis.qcx.nomeModelo')}
                maxLength={40}
                validate={requiredValidator}
                disabled={isConsult}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <QCXFinalTextField
                id="text-field-sigla-modelo-follow-up"
                key="text-field-sigla-modelo-follow-up"
                name="sigla"
                label={t('com.muralis.qcx.sigla')}
                maxLength={10}
                disabled={isConsult}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <QCXFinalNumericIntegerField
                id="text-field-dias-conclusao-modelo-follow-up"
                key="text-field-dias-conclusao-modelo-follow-up"
                name="diasConclusao"
                label={t('com.muralis.qcx.diasConclusao')}
                allowNegative={false}
                maxLength={3}
                validate={requiredValidator}
                disabled={isConsult}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <QCXSelectEtapaAutocomplete
                id="autocomplete-select-field-etapa-de-conclusao"
                key="autocomplete-select-field-etapa-de-conclusao"
                name="etapaConclusao"
                label={t('com.muralis.qcx.etapa.etapaConclusao')}
                initialValues={values}
                filterPredicate={(item) => item?.active}
                fieldProps={{
                  validate: requiredValidator,
                }}
                disabled={isConsult}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <QCXSelectTipoModeloFollowUpAutocomplete
                select
                id="select-field-tipo-de-modelo-follow-up"
                key="select-field-tipo-de-modelo-follow-up"
                name="tipo"
                label={t('com.muralis.qcx.tipoDeModelo')}
                initialValues={values}
                fieldProps={{
                  validate: requiredValidator,
                }}
                disabled={isConsult}
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <QCXFinalCheckboxField
                id="checkbox-field-analise-desempenho-modelo-follow-up"
                key="checkbox-field-analise-desempenho-modelo-follow-up"
                name="hasAnaliseDesempenho"
                label={t('com.muralis.qcx.analiseDesempenho')}
                disabled={isConsult}
              />
            </Grid>
            {values?.hasAnaliseDesempenho && (
              <Grid item xs={12} sm={6} md={3}>
                <QCXSelectAnaliseDesempenhoModuloAutocomplete
                  id="autocomplete-select-field-analise-de-desempenho"
                  key="autocomplete-select-field-analise-de-desempenho"
                  name="analiseDesempenho"
                  label={t('com.muralis.qcx.modulo')}
                  initialValues={values}
                  fieldProps={{
                    validate: requiredValidator,
                  }}
                  disabled={isConsult}
                  required
                />
              </Grid>
            )}
          </Grid>
        </>
      )}
    </QCXFormStepWizard>
  );
}
