import React, {
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import {
  useHistory,
  useParams,
} from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';
import { isEmpty } from 'lodash';
import {
  changeToConsultMode,
  changeToCreateMode,
  changeToUpdateMode,
  failure,
  fetchByIdAsync,
  loading,
  resetModel,
  selectContaBancaria,
  selectMode,
  setError,
  setModel,
  setResponse,
  success,
  addToList,
  updateOnList,
  changeModeTo,
} from '../../../../../features/conta-bancaria/contaBancariaSlice';
import {
  isCreateMode,
  isConsultMode,
  isUpdateMode,
  isNoneMode,
} from '../../../../../utils/store/store-utils';
import { register, save } from '../../../../../features/conta-bancaria/contaBancariaAPI';
import QCXRegistrationFormPageTemplate from '../../../../../templates/registration-form-page/QCXRegistrationFormPageTemplate';
import useAutoChangeMode from '../../../../../utils/hooks/mode/useAutoChangeMode';
import { getTipoModeloLabelByValue } from '../../../../../shared-components/select-tipo-modelo/QCXFinalSelectTipoModeloAutocomplete';
import { getStringOrUndefined, parseToBoolean } from '../../../../../utils/general/parse-utils';
import QCXContaBancariaForm from '../../../../../components/conta-bancaria/QCXContaBancariaForm';
import { normalizeDigits, normalizeNumeral, unnormalizeNumeral } from '../../../../../utils/general/general-utils';
import { formatAgencia, formatBrazilianNumericDecimal, formatContaCorrente } from '../../../../../utils/hooks/form/field/formatters';

export default function ContaBancariaRegistrationPage({ authInfo = {} }) {
  const { t } = useTranslation();

  const { id } = useParams();

  const history = useHistory();

  const dispatch = useDispatch();

  const mode = useSelector(selectMode);

  const contaBancaria = useSelector(selectContaBancaria);

  const isNone = useMemo(() => (
    isNoneMode(mode)
  ), [mode]);

  const isCreate = useMemo(() => (
    isCreateMode(mode)
  ), [mode]);

  const isConsult = useMemo(() => (
    isConsultMode(mode)
  ), [mode]);

  const isUpdate = useMemo(() => (
    isUpdateMode(mode)
  ), [mode]);

  const fetchById = useCallback((contaBancariaId) => (
    dispatch(fetchByIdAsync(contaBancariaId))
  ), []);

  const handleChangeToCreate = () => {
    dispatch(changeToCreateMode());
  };

  const handleChangeToConsult = () => {
    dispatch(changeToConsultMode());
  };

  const handleChangeToUpdate = () => {
    dispatch(changeToUpdateMode());
  };

  useAutoChangeMode((currentMode) => (
    dispatch(changeModeTo(currentMode))
  ), [isUpdate]);

  useEffect(() => {
    if (!isEmpty(id)) {
      dispatch(fetchByIdAsync(id));
      handleChangeToConsult();
    }
  }, [id]);

  const handleCancelUpdate = () => {
    if (id) {
      fetchById(id);
    }
    handleChangeToConsult();
  };

  const normalize = useCallback((unnormalizedData) => {
    const normalizedData = {
      ...unnormalizedData,
      description: getStringOrUndefined(unnormalizedData?.description),
      banco: unnormalizedData?.banco?.id
        ? unnormalizedData?.banco
        : undefined,
      agencia: normalizeDigits(unnormalizedData?.agencia),
      contaCorrente: normalizeDigits(unnormalizedData?.contaCorrente),
      controlarSaldo: parseToBoolean(unnormalizedData?.controlarSaldo),
      moeda: unnormalizedData?.moeda?.id
        ? unnormalizedData?.moeda
        : undefined,
      saldoInicial: normalizeNumeral(unnormalizedData?.saldoInicial),
    };

    return normalizedData;
  }, [
    getStringOrUndefined,
    parseToBoolean,
    normalizeNumeral,
  ]);

  const unnormalize = useCallback((normalizedData) => {
    const unnormalizedData = {
      ...normalizedData,
      id: normalizedData?.id,
      description: getStringOrUndefined(normalizedData?.description),
      banco: normalizedData?.banco?.id
        ? normalizedData?.banco
        : undefined,
      agencia: formatAgencia(normalizedData?.agencia),
      contaCorrente: formatContaCorrente(normalizedData?.contaCorrente),
      controlarSaldo: parseToBoolean(normalizedData?.controlarSaldo),
      moeda: normalizedData?.moeda?.id
        ? normalizedData?.moeda
        : undefined,
      saldoInicial: unnormalizeNumeral(
        normalizedData?.saldoInicial,
        formatBrazilianNumericDecimal(2)
      ),
    };

    return unnormalizedData;
  }, [
    getStringOrUndefined,
    parseToBoolean,
    unnormalizeNumeral,
    formatBrazilianNumericDecimal,
  ]);

  const handleDispatchSetModel = useCallback((data) => {
    const normalizedData = normalize(data);

    dispatch(
      setModel(normalizedData)
    );
  }, [normalize]);

  const create = async (data) => {
    const executeDebounced = debounce(async () => {
      try {
        const response = await register(data);

        if (response?.status === 201) {
          dispatch(resetModel());

          const created = response?.data;

          handleDispatchSetModel(created);
          dispatch(addToList({ data: created }));

          const handleResultWithDebounce = debounce(() => {
            history.push(t('com.muralis.qcx.url.modulosFinanceirosContasBancarias'));

            dispatch(success());
            dispatch(setResponse({
              status: response.status,
              data: created,
              message: t('com.muralis.qcx.mensagem.contaBancariaCadastrada', { descricao: data?.description }),
            }));
          }, 500);

          handleResultWithDebounce();
        }
      } catch (error) {
        const defaultErrorMessage = t('com.muralis.qcx.erro.erroCadastrarContaBancaria', { descricao: data?.description });
        const errorMessage = error?.response?.data?.message
          ? `${defaultErrorMessage}: ${error?.response?.data?.message}.`
          : `${defaultErrorMessage}.`;

        dispatch(failure());
        dispatch(setError({
          message: errorMessage,
        }));
      }
    }, 500);

    dispatch(loading());
    executeDebounced();
  };

  const update = async (data) => {
    const executeDebounced = debounce(async () => {
      try {
        const response = await save(data);

        if (response?.status === 200) {
          const handleResultWithDebounce = debounce(() => {
            handleChangeToConsult();
            dispatch(success());

            const saved = response?.data;

            dispatch(setResponse({
              status: response.status,
              data: saved,
              message: t('com.muralis.qcx.mensagem.contaBancariaSalva', { descricao: saved?.description }),
            }));

            dispatch(setModel(saved));
            dispatch(updateOnList({ data: saved }));
            dispatch(fetchByIdAsync(saved?.id));
          }, 500);

          handleResultWithDebounce();
          handleChangeToConsult();
        }
      } catch (error) {
        const defaultErrorMessage = t('com.muralis.qcx.erro.erroSalvarContaBancaria', { descricao: data?.description });
        const errorMessage = error?.response?.data?.message
          ? `${defaultErrorMessage}: ${error?.response?.data?.message}.`
          : `${defaultErrorMessage}.`;

        dispatch(failure());
        dispatch(setError({
          message: errorMessage,
        }));
      }
    }, 500);

    dispatch(loading());
    executeDebounced();
  };

  const handleSubmit = async (data) => {
    const normalizedData = normalize(data);

    if (isUpdate) {
      await update(normalizedData);
    } else {
      await create(normalizedData);
    }
  };

  const model = useMemo(() => (
    !isCreate
      ? unnormalize(contaBancaria)
      : {}
  ), [
    isCreate,
    contaBancaria,
    unnormalize,
  ]);

  const actionName = useMemo(() => {
    if (isCreate || isNone) {
      return t('com.muralis.qcx.acoes.nova');
    }
    if (isConsult) {
      return t('com.muralis.qcx.acoes.visualizar');
    }
    return t('com.muralis.qcx.acoes.alterar');
  }, [isNone, isCreate, isConsult]);

  const breadcrumbs = useMemo(() => ([
    {
      link: {
        to: '/',
        name: t('com.muralis.qcx.inicio'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.cadastros'),
      },
    },
    {
      link: {
        to: t('com.muralis.qcx.url.moduloCadastrosFinanceiros'),
        name: t('com.muralis.qcx.financeiro.labelPlural'),
      },
    },
    {
      link: {
        to: t('com.muralis.qcx.url.modulosFinanceirosContasBancarias'),
        name: t('com.muralis.qcx.contaBancaria.labelPlural'),
      },
    },
    {
      text: {
        name: actionName,
      },
    },
  ]), [actionName]);

  const pageTitle = useMemo(() => (
    isNone || isCreate
      ? t('com.muralis.qcx.contaBancaria.novaContaBancaria')
      : t('com.muralis.qcx.contaBancaria.contaBancariaExistente', { id: contaBancaria?.description || contaBancaria?.id || '-' })
  ), [
    isNone,
    isCreate,
    contaBancaria,
    getTipoModeloLabelByValue,
  ]);

  return (
    <QCXRegistrationFormPageTemplate
      pageTitle={pageTitle}
      breadcrumbs={breadcrumbs}
      isCreate={isCreate}
      isConsult={isConsult}
      isUpdate={isUpdate}
      handleChangeToCreate={handleChangeToCreate}
      handleChangeToConsult={handleChangeToConsult}
      handleChangeToUpdate={handleChangeToUpdate}
      handleCancelUpdate={handleCancelUpdate}
      authInfo={authInfo}
    >
      {(formProps) => (
        <QCXContaBancariaForm
          isCreate={isCreate}
          initialValues={model}
          handleSubmit={handleSubmit}
          handleChangeModel={handleDispatchSetModel}
          authInfo={authInfo}
          requiredRoles={['conta-bancaria']}
          {...formProps}
        />
      )}
    </QCXRegistrationFormPageTemplate>
  );
}
