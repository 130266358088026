import { createFilterOptions } from '@material-ui/lab/useAutocomplete';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchAllAsync,
  selectModelosNotaFiscal,
  selectConfigControl,
  resetList,
} from '../../features/modelo-nota-fiscal/modeloNotaFiscalSlice';
import { isAutoLoadControl } from '../../utils/store/store-utils';
import QCXAutocompleteSelect from '../autocomplete-select/QCXAutocompleteSelect';

export default function QCXSelectModeloNotaFiscalAutocomplete({
  label,
  name,
  initialValues,
  ...restProps
}) {
  const dispatch = useDispatch();

  const list = useSelector(selectModelosNotaFiscal);
  const configControl = useSelector(selectConfigControl);

  const [defaultValue, setDefaultValue] = useState('');

  useEffect(() => {
    if (isAutoLoadControl(configControl)) {
      dispatch(fetchAllAsync());
    }
  }, [configControl]);

  useEffect(() => {
    const value = list.find((item) => item?.id === _.get(initialValues, name));

    if (value) {
      setDefaultValue(value);
      return;
    }

    setDefaultValue({ id: null, description: '' });
  }, [list, initialValues]);

  useEffect(() => (
    () => (
      dispatch(resetList())
    )
  ), []);

  const filterOptions = createFilterOptions({
    stringify: (item) => item?.description,
  });

  return (
    <QCXAutocompleteSelect
      label={label}
      id={name}
      name={name}
      options={list}
      filterOptions={filterOptions}
      defaultValue={defaultValue}
      getOptionLabel={(item) => item?.description}
      {...restProps}
    />
  );
}
