import _ from 'lodash';
import { formatBrazilianNumericDecimal } from '../../hooks/form/field/formatters';
import { isStrictEquals, padLeadingZeros, unnormalizeNumeral } from '../general-utils';

const STATUS_SOLICITADO = 'SOLICITADO';
const STATUS_CANCELADO = 'CANCELADO';
const STATUS_FATURADO = 'FATURADO';
const STATUS_AGUARDANDO_VALIDACAO_NOTA = 'AGUARDANDO_VALIDACAO_NOTA';

const isStatusSolicitado = (key) => (
  isStrictEquals(key, STATUS_SOLICITADO)
);

const isStatusCancelado = (key) => (
  isStrictEquals(key, STATUS_CANCELADO)
);

const isStatusFaturado = (key) => (
  isStrictEquals(key, STATUS_FATURADO)
);

const isStatusAguardandoValidacaoNota = (key) => (
  isStrictEquals(key, STATUS_AGUARDANDO_VALIDACAO_NOTA)
);

const getQuantidadeFrom = (values) => (
  _.get(values, 'quantidade') || 0
);

const getTotalMoedaFrom = (values) => {
  const totalMoeda = _.get(values, 'totalMoeda');
  const formatted = unnormalizeNumeral(
    totalMoeda,
    formatBrazilianNumericDecimal(2)
  );

  return formatted;
};

const getTotalRealFrom = (values) => {
  const totalReal = _.get(values, 'totalReal');
  const formatted = unnormalizeNumeral(
    totalReal,
    formatBrazilianNumericDecimal(2)
  );

  return formatted;
};

const formatProcessosCapas = (capas, max) => {
  const quantidadeCapas = capas.length;
  const capasExistentes = quantidadeCapas > 0;

  if (capasExistentes) {
    const mapProcessoCapas = capas
      .map((capa) => capa?.followUp?.numero)
      .slice(0, (
        max && max > 0
          ? max
          : quantidadeCapas
      ));

    const formatProcessosCapasString = mapProcessoCapas.join(' | ');

    return quantidadeCapas > max
      ? `${formatProcessosCapasString}...`
      : formatProcessosCapasString;
  }

  return undefined;
};

const formatIdentificacao = (identificacao) => {
  if (!(identificacao?.sequencial || identificacao?.ano)) {
    return undefined;
  }

  const {
    sequencial,
    ano,
  } = identificacao;

  const sequencialWithLeadingZeros = padLeadingZeros(sequencial, 4);
  const formattedIdentificacao = `${sequencialWithLeadingZeros}/${ano}`;

  return formattedIdentificacao;
};

const FaturamentoUtils = Object.freeze({
  SOLICITADO: STATUS_SOLICITADO,
  CANCELADO: STATUS_CANCELADO,
  FATURADO: STATUS_FATURADO,
  NOTA_FISCAL_EMITIDA: STATUS_AGUARDANDO_VALIDACAO_NOTA,
  isSolicitado: (
    isStatusSolicitado
  ),
  isCancelado: (
    isStatusCancelado
  ),
  isFaturado: (
    isStatusFaturado
  ),
  isAguardandoValidacaoNota: (
    isStatusAguardandoValidacaoNota
  ),
  formatProcessosCapas,
  formatIdentificacao,
  getQuantidadeFrom,
  getTotalRealFrom,
  getTotalMoedaFrom,
});

export default FaturamentoUtils;
