const selectData = (state) => state.downloader.data;
const selectMetadata = (state) => state.downloader.metadata;
const selectConfigControl = (state) => state.downloader.config.control;
const selectStatus = (state) => state.downloader.status;
const selectError = (state) => state.downloader.error;
const selectResponse = (state) => state.downloader.response;

const downloaderSelectors = {
  selectData,
  selectMetadata,
  selectConfigControl,
  selectStatus,
  selectError,
  selectResponse,
};

export {
  downloaderSelectors,
  selectData,
  selectMetadata,
  selectConfigControl,
  selectStatus,
  selectError,
  selectResponse,
};
