import {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { saveAs } from 'file-saver';
import _ from 'lodash';
import { isSuccessStatus } from '../../store/store-utils';
import {
  FAILURE_STATUS,
  IDLE_STATUS,
  LOADING_STATUS,
  SUCCESS_STATUS,
} from '../../../features/status';

export default function useGenericDownloader({
  autoDownload = false,
}) {
  const [status, setStatus] = useState(IDLE_STATUS);
  const [metadata, setMetadata] = useState();
  const [data, setData] = useState();

  const isSuccess = useMemo(() => (
    isSuccessStatus(status)
  ), [status]);

  const availableAutoDownload = useMemo(() => (
    autoDownload && isSuccess && data
  ), [
    data,
    isSuccess,
    autoDownload,
  ]);

  const blob = useMemo(() => (
    data
      ? new Blob([data])
      : undefined
  ), [data]);

  const downloadFileById = useCallback(
    async (fetcher, handlers) => {
      const { onSuccess, onNoContent, onError } = handlers;

      if (!_.isFunction(fetcher)) {
        throw new Error('The "fetcher" parameter must be a function.');
      }

      setStatus(LOADING_STATUS);

      const response = await fetcher()
        .catch((...args) => {
          if (_.isFunction(onError)) {
            setStatus(FAILURE_STATUS);
            onError(args);
          }
        });

      if (response?.status === 200) {
        setData(response?.data);

        const contentDisposition = response?.headers['content-disposition'] || '';

        const [matched] = String(contentDisposition)
          .match(/filename[^;=\n]*=(?:(\\?['"])(.*?)\1|(?:[^\s]+'.*?')?([^;\n]*))/g);

        const fileName = matched && _.isString(matched)
          ? matched.replace(/filename=|"/g, '')
          : undefined;

        const [name, extension] = fileName && _.isString(fileName)
          ? fileName.split('.')
          : undefined;

        setMetadata({
          headers: response?.headers,
          fileName,
          name,
          extension,
        });

        setStatus(SUCCESS_STATUS);

        if (_.isFunction(onSuccess)) {
          onSuccess(metadata, response);
        }

        return;
      }

      if (response?.status === 204) {
        if (_.isFunction(onError)) {
          setStatus(IDLE_STATUS);
          onNoContent();

          return;
        }
      }

      setStatus(IDLE_STATUS);
    },
    []
  );

  const saveCurrentFile = useCallback(() => {
    if (isSuccessStatus(status) && blob) {
      saveAs(blob, metadata.fileName);

      setStatus(IDLE_STATUS);
      setMetadata(undefined);
      setData(undefined);
    }
  }, [
    blob,
    status,
    metadata,
  ]);

  useEffect(() => {
    if (availableAutoDownload) {
      saveCurrentFile();
      setStatus(IDLE_STATUS);
    }
  }, [
    availableAutoDownload,
    saveCurrentFile,
  ]);

  const downloader = useMemo(() => ({
    data,
    status,
    availableAutoDownload,
    saveCurrentFile,
    downloadFileById,
  }), [
    data,
    status,
    availableAutoDownload,
    saveCurrentFile,
    downloadFileById,
  ]);

  return downloader;
}
