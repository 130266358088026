import {
  makeStyles,
  Paper,
} from '@material-ui/core';
import React, { useMemo } from 'react';
import QCXBox from '../box/QCXBox';

const useStyles = makeStyles(() => ({
  cardPreview: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignContent: 'center',
    width: '160px',
    height: '210px',
    padding: '1',
    borderRadius: '2px',
    boxShadow: 'none',
    userSelect: 'none',
    '&::-webkit-user-select': 'none',
    '&::-ms-user-select': 'none',
  },
  cardTopBox: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    padding: '8px 0px 0px 0px',
  },
  cardMiddleBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
  },
  cardIconBox: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    padding: '0px 0px 4px 0px',
    fontSize: 46,
    maxHeight: '50px',
  },
  cardActionsBox: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignContent: 'center',
    maxHeight: '40px',
    boxShadow: '0px 0px 8px 0px #ccc, 0px 0px 0px 0px #ccc',
  },
}));

function QCXFlatCard({
  style,
  children,
  ...restProps
}) {
  const classes = useStyles();

  return (
    <Paper
      style={style}
      className={classes.cardPreview}
      {...restProps}
    >
      {children}
    </Paper>
  );
}

QCXFlatCard.TopBox = function ({
  children,
  ...restProps
}) {
  const classes = useStyles();

  return (
    <QCXBox
      className={classes.cardTopBox}
      component="div"
      {...restProps}
    >
      {children}
    </QCXBox>
  );
};

QCXFlatCard.MiddleBox = function ({
  children,
  ...restProps
}) {
  const classes = useStyles();

  return (
    <QCXBox
      className={classes.cardMiddleBox}
      component="div"
      {...restProps}
    >
      {children}
    </QCXBox>
  );
};

QCXFlatCard.IconBox = function ({
  children,
  ...restProps
}) {
  const classes = useStyles();

  return (
    <QCXBox
      className={classes.cardIconBox}
      component="div"
      {...restProps}
    >
      {children}
    </QCXBox>
  );
};

QCXFlatCard.ContentBox = function ({
  enableScrollbar = false,
  children,
  ...restProps
}) {
  const useContentBoxStyles = makeStyles(() => ({
    cardContentBox: {
      display: 'flex',
      padding: '4px 8px 4px 8px',
      justifyContent: 'center',
      alignContent: 'center',
      maxHeight: '64px',
      ...(enableScrollbar
        ? {
          overflowY: 'scroll',
          '&::-webkit-scrollbar': {
            width: '0.5em',
            height: '0.8em',
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: '#f2f2f2',
            borderRadius: '25px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#b2b2b2f1',
            borderRadius: '25px',
          },
        } : {}
      ),
    },
  }));

  const classes = useMemo(() => (
    useContentBoxStyles()
  ), [useContentBoxStyles]);

  return (
    <QCXBox
      className={classes.cardContentBox}
      component="div"
      {...restProps}
    >
      {children}
    </QCXBox>
  );
};

QCXFlatCard.ActionsBox = function ({
  children,
  ...restProps
}) {
  const classes = useStyles();

  return (
    <QCXBox
      className={classes.cardActionsBox}
      component="div"
      {...restProps}
    >
      {children}
    </QCXBox>
  );
};

export default QCXFlatCard;
