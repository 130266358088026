import { useMemo } from "react";
import { UseMutateFunction } from "react-query";
import {
  QueriesTypes,
  useDeleteRequest,
  useGetRequest,
} from "../../../common/hooks/queries";
import { PropostaCover } from "./propostas.types";

/**
 * Fetches the Propostas from the API
 * @returns An array with the propostas, isLoading and isError.
 */
export const usePropostas = (): [PropostaCover[], boolean, boolean] => {
  const {
    data: propostasData,
    isError,
    isLoading,
  } = useGetRequest(QueriesTypes.Propostas);

  const propostas: PropostaCover[] = useMemo(() => {
    if (propostasData && !isError) {
      return propostasData;
    } else {
      return [];
    }
  }, [propostasData, isError]);

  return [propostas, isLoading, isError];
};

export const useDeleteProposta = (): [
  UseMutateFunction<
    any,
    unknown,
    {
      id?: string | number;
      nome?: string;
      clienteId?: number;
    }
  >,
  boolean,
  boolean
] => {
  const { isLoading, isError, mutate } = useDeleteRequest(
    QueriesTypes.Propostas
  );

  return [mutate, isLoading, isError];
};
