import PivotTableChartIcon from '@mui/icons-material/PivotTableChart';
import ExtensionIcon from '@mui/icons-material/Extension';
import RttIcon from '@mui/icons-material/Rtt';
import CardsGridSubmenu from '../../../../common/components/cardsGrid/cardsGridSubmenu';
import BasicLayout from '../../../../common/layouts/basicLayout';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import { CardsGridItem } from '../../../../common/components/cardsGrid/cardsGrid';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import { Calculate } from '@mui/icons-material';

const cover: CardsGridItem = {
  title: 'Escrita',
  icon: RttIcon,
  path: '/integracao/totvs-easy',
};

const gridItems: CardsGridItem[] = [
  {
    title: 'Despesas',
    icon: RequestQuoteIcon,
    path: '/integracao/totvs-easy/escrita/despesas',
  },
  {
    title: 'DANFE',
    icon: ReceiptLongIcon,
    path: '/integracao/totvs-easy/escrita/danfe',
  },
  {
    title: 'Numerários',
    icon: Calculate,
    path: '/integracao/totvs-easy/escrita/numerarios',
  },
];

const TotvsEscritaPage = () => {
  return (
    <BasicLayout title={'TOTVS / Easy'} icon={<ExtensionIcon color={'secondary'} />}>
      <CardsGridSubmenu cover={cover} items={gridItems}></CardsGridSubmenu>
    </BasicLayout>
  );
};

export default TotvsEscritaPage;
