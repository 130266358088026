import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { validate } from 'uuid';
import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';
import { isEmpty, isFunction } from 'lodash';
import {
  addToList,
  changeToBackgroundCreateMode,
  changeToBackgroundDeleteMode,
  changeToConsultMode,
  changeToCreateMode,
  changeToSubConsultMode,
  changeToSubCreateMode,
  changeToUpdateMode,
  failure,
  loading,
  preparingAction,
  resetBackgroundMode,
  resetModel,
  resetRelatedMercadoriaModel,
  setError,
  setModel,
  setRelatedMercadoriaModel,
  setResponse,
  success,
  updateOnList,
  updateSelectedMercadoria,
} from '../../../../features/fatura/faturaSlice';
import {
  selectBackgroundMode,
  selectFatura,
  selectMode,
  selectStatus,
  selectSubMode,
} from '../../../../features/fatura/faturaSelectors';
import QCXFaturaWizardFinalForm from '../../../../components/fatura/QCXFaturaWizardFinalForm';
import {
  isCreateMode,
  isBackgroundCreateMode,
  isConsultMode,
  isUpdateMode,
  isPreparingActionStatus,
  isBackgroundDeleteMode,
  isNoneMode,
  isLoadingStatus,
  isIdleStatus,
  isSubCreateMode,
  isSubUpdateMode,
  isSubConsultMode,
  isNoneSubMode,
  isFailureStatus,
} from '../../../../utils/store/store-utils';
import { AUTO_LOAD_CONTROL, IDLE_CONTROL } from '../../../../features/config-control';
import { changeControlTo as changeControlCatalogoProduto } from '../../../../features/catalogo-produtos/catalogoProdutosSlice';
import { changeControlTo as changeControlPaisTo } from '../../../../features/pais/paisSlice';
import { changeControlTo as changeControlNaladiShTo } from '../../../../features/naladi-sh/naladiShSlice';
import { changeControlTo as changeControlNaladiNccaTo } from '../../../../features/naladi-ncca/naladiNccaSlice';
import { changeControlTo as changeControlUnidadeMedidaTo } from '../../../../features/unidade-medida/unidadeDeMedidaSlice';
import { changeControlTo as changeControlNcmTo } from '../../../../features/ncm-subitem/ncmSubitemSlice';
import { register, save } from '../../../../features/fatura/faturaAPI';
import {
  forceUnnormalizeNumeral,
  isValid,
  normalizeData,
  normalizeNumeral,
  unnormalizeNumeral,
} from '../../../../utils/general/general-utils';
import { formatBrazilianNumericDecimal } from '../../../../utils/hooks/form/field/formatters';
import QCXRegistrationFormPageTemplate from '../../../../templates/registration-form-page/QCXRegistrationFormPageTemplate';
import { fetchByIdAsync } from '../../../../features/fatura/faturaThunks';
import { updateFaturaOcrMetadata } from '../../../../utils/general/fatura/faturaUtils';
import { clienteActions } from '../../../../features/cliente/clienteSlice';

export default function FaturaRegistrationPage({ authInfo = {} }) {
  const { t } = useTranslation();

  const params = useParams();

  const history = useHistory();

  const dispatch = useDispatch();

  const [isCreated, setIsCreated] = useState(false);

  const status = useSelector(selectStatus);
  const mode = useSelector(selectMode);
  const backgroundMode = useSelector(selectBackgroundMode);
  const subMode = useSelector(selectSubMode);
  const fatura = useSelector(selectFatura);

  const isPreparingAction = useMemo(() => isPreparingActionStatus(status), [status]);

  const isLoading = useMemo(() => isLoadingStatus(status), [status]);

  const isIdle = useMemo(() => isIdleStatus(status), [status]);

  const isFailure = useMemo(() => isFailureStatus(status), [status]);

  const isNone = useMemo(() => isNoneMode(mode), [mode]);

  const isCreate = useMemo(() => isCreateMode(mode), [mode]);

  const isConsult = useMemo(() => isConsultMode(mode), [mode]);

  const isUpdate = useMemo(() => isUpdateMode(mode), [mode]);

  const isBackgroundCreate = useMemo(() => isBackgroundCreateMode(backgroundMode), [backgroundMode]);

  const isBackgroundDelete = useMemo(() => isBackgroundDeleteMode(backgroundMode), [backgroundMode]);

  const isSubNone = useMemo(() => isNoneSubMode(subMode), [subMode]);

  const isSubCreate = useMemo(() => isSubCreateMode(subMode), [subMode]);

  const isSubConsult = useMemo(() => isSubConsultMode(subMode), [subMode]);

  const isSubUpdate = useMemo(() => isSubUpdateMode(subMode), [subMode]);

  useEffect(() => {
    dispatch(clienteActions.changeControlTo(IDLE_CONTROL));

    dispatch(changeControlCatalogoProduto(AUTO_LOAD_CONTROL));
    dispatch(changeControlPaisTo(AUTO_LOAD_CONTROL));
    dispatch(changeControlNcmTo(AUTO_LOAD_CONTROL));
    dispatch(changeControlNaladiShTo(AUTO_LOAD_CONTROL));
    dispatch(changeControlNaladiNccaTo(AUTO_LOAD_CONTROL));
    dispatch(changeControlUnidadeMedidaTo(AUTO_LOAD_CONTROL));

    return () => {
      dispatch(clienteActions.resetControl());
    };
  }, []);

  const handleChangeToPreparingAction = useCallback(() => {
    dispatch(preparingAction());
  }, []);

  const handleChangeToCreate = useCallback(() => {
    dispatch(changeToCreateMode());
  }, []);

  const handleChangeToBackgroundCreate = useCallback(() => {
    dispatch(changeToBackgroundCreateMode());
  }, []);

  const handleChangeToBackgroundDelete = useCallback(() => {
    dispatch(changeToBackgroundDeleteMode());
  }, []);

  const handleChangeToConsult = useCallback(() => {
    dispatch(changeToConsultMode());
  }, []);

  const handleChangeToUpdate = useCallback((additional = {}) => {
    dispatch(changeToUpdateMode());

    if (!isEmpty(additional) && isFunction(additional?.callback)) {
      additional.callback();
    }
  }, []);

  const handleResetBackgroundMode = useCallback(() => {
    dispatch(resetBackgroundMode());
  }, []);

  const fetchById = useCallback((faturaId) => dispatch(fetchByIdAsync(faturaId)), []);

  useEffect(() => {
    const handleFetchById = () => {
      if (params?.id && !isEmpty(params?.id)) {
        fetchById(params?.id);
        handleChangeToConsult();
      }
    };

    handleFetchById();
  }, [params, fetchById, handleChangeToConsult]);

  const handleCancelUpdate = useCallback(
    (additional = {}) => {
      const currentId = isBackgroundCreate ? fatura?.id : params?.id;

      if (currentId) {
        fetchById(currentId);
      }

      handleChangeToConsult();

      if (!isEmpty(additional) && isFunction(additional?.callback)) {
        additional.callback();
      }
    },
    [params, fatura, fetchById, isBackgroundCreate, handleChangeToConsult]
  );

  const normalize = useCallback(
    (unnormalizedData) => {
      const normalizedMercadorias = unnormalizedData?.mercadorias || [];

      const {
        importador,
        paisProcedencia,
        urfDespacho,
        urfEntrada,
        pesoBruto,
        pesoLiquido,
        data,
        incotermCondicaoVenda,
        mle,
        despesa,
        desconto,
        frete,
        seguro,
        exportador,
        fabricante,
        paisOrigem,
        valorTotalMcvMoeda,
        valorTotalMcvReal,
        ...restUnnormalizedData
      } = unnormalizedData;

      const normalizedData = {
        ...restUnnormalizedData,
        data: normalizeData(data),
        importador: importador?.id ? importador : null,
        paisProcedencia: paisProcedencia?.id ? paisProcedencia : null,
        urfDespacho: urfDespacho?.id ? urfDespacho : null,
        urfEntrada: urfEntrada?.id ? urfEntrada : null,
        pesoBruto: normalizeNumeral(pesoBruto),
        pesoLiquido: normalizeNumeral(pesoLiquido),
        incotermCondicaoVenda: incotermCondicaoVenda?.id ? incotermCondicaoVenda : null,
        mle: {
          ...mle,
          moeda: mle?.moeda?.id ? mle?.moeda : null,
          valorMoeda: normalizeNumeral(mle?.valorMoeda),
          valorReal: normalizeNumeral(mle?.valorReal),
        },
        desconto: {
          ...desconto,
          moeda: desconto?.moeda?.id ? desconto?.moeda : null,
          valorMoeda: normalizeNumeral(desconto?.valorMoeda),
          valorReal: normalizeNumeral(desconto?.valorReal),
        },
        despesa: {
          ...despesa,
          moeda: despesa?.moeda?.id ? despesa?.moeda : null,
          valorMoeda: normalizeNumeral(despesa?.valorMoeda),
          valorReal: normalizeNumeral(despesa?.valorReal),
        },
        frete: {
          ...frete,
          moeda: frete?.moeda?.id ? frete?.moeda : null,
          valorMoedaCollect: normalizeNumeral(frete?.valorMoedaCollect),
          valorRealCollect: normalizeNumeral(frete?.valorRealCollect),
          valorMoedaPrepaid: normalizeNumeral(frete?.valorMoedaPrepaid),
          valorRealPrepaid: normalizeNumeral(frete?.valorRealPrepaid),
          valorFreteNacionalMoeda: normalizeNumeral(frete?.valorFreteNacionalMoeda),
          valorFreteNacionalReal: normalizeNumeral(frete?.valorFreteNacionalReal),
        },
        seguro: {
          ...seguro,
          moeda: seguro?.moeda?.id ? seguro?.moeda : null,
          valorMoeda: normalizeNumeral(seguro?.valorMoeda),
          valorReal: normalizeNumeral(seguro?.valorReal),
          valorPercentual: normalizeNumeral(seguro?.valorPercentual),
        },
        exportador: exportador?.id ? exportador : null,
        fabricante: fabricante?.id ? fabricante : null,
        paisOrigem: paisOrigem?.id ? paisOrigem : null,
        mercadorias: normalizedMercadorias,
        valorTotalMcvMoeda: normalizeNumeral(valorTotalMcvMoeda),
        valorTotalMcvReal: normalizeNumeral(valorTotalMcvReal),
      };
      updateFaturaOcrMetadata(normalizedData);

      return normalizedData;
    },
    [fatura, validate]
  );

  const unnormalize = useCallback(
    (normalizedData) => {
      const { pesoBruto, pesoLiquido, mle, despesa, desconto, frete, seguro, mercadorias } = normalizedData;

      return {
        ...normalizedData,
        pesoBruto: isValid(pesoBruto)
          ? forceUnnormalizeNumeral(pesoBruto, formatBrazilianNumericDecimal(7))
          : undefined,
        pesoLiquido: isValid(pesoLiquido)
          ? forceUnnormalizeNumeral(pesoLiquido, formatBrazilianNumericDecimal(7))
          : undefined,
        mle: {
          ...mle,
          valorMoeda: isValid(mle?.valorMoeda)
            ? unnormalizeNumeral(mle?.valorMoeda, formatBrazilianNumericDecimal(2))
            : undefined,
          valorReal: isValid(mle?.valorReal)
            ? unnormalizeNumeral(mle?.valorReal, formatBrazilianNumericDecimal(2))
            : undefined,
        },
        desconto: {
          ...desconto,
          moeda: desconto?.moeda?.id ? desconto?.moeda : undefined,
          valorMoeda: isValid(desconto?.valorMoeda)
            ? unnormalizeNumeral(desconto?.valorMoeda, formatBrazilianNumericDecimal(2))
            : undefined,
          valorReal: isValid(desconto?.valorReal)
            ? unnormalizeNumeral(desconto?.valorReal, formatBrazilianNumericDecimal(2))
            : undefined,
        },
        despesa: {
          ...despesa,
          valorMoeda: isValid(despesa?.valorMoeda)
            ? unnormalizeNumeral(despesa?.valorMoeda, formatBrazilianNumericDecimal(2))
            : undefined,
          valorReal: isValid(despesa?.valorReal)
            ? unnormalizeNumeral(despesa?.valorReal, formatBrazilianNumericDecimal(2))
            : undefined,
        },
        frete: {
          ...frete,
          moeda: frete?.moeda?.id ? frete?.moeda : undefined,
          valorMoedaPrepaid: isValid(frete?.valorMoedaPrepaid)
            ? unnormalizeNumeral(frete?.valorMoedaPrepaid, formatBrazilianNumericDecimal(2))
            : undefined,
          valorRealPrepaid: isValid(frete?.valorRealPrepaid)
            ? unnormalizeNumeral(frete?.valorRealPrepaid, formatBrazilianNumericDecimal(2))
            : undefined,
          valorFreteNacionalMoeda: isValid(frete?.valorFreteNacionalMoeda)
            ? unnormalizeNumeral(frete?.valorFreteNacionalMoeda, formatBrazilianNumericDecimal(2))
            : undefined,
          valorFreteNacionalReal: isValid(frete?.valorFreteNacionalReal)
            ? unnormalizeNumeral(frete?.valorFreteNacionalReal, formatBrazilianNumericDecimal(2))
            : undefined,
          valorMoedaCollect: isValid(frete?.valorMoedaCollect)
            ? unnormalizeNumeral(frete?.valorMoedaCollect, formatBrazilianNumericDecimal(2))
            : undefined,
          valorRealCollect: isValid(frete?.valorRealCollect)
            ? unnormalizeNumeral(frete?.valorRealCollect, formatBrazilianNumericDecimal(2))
            : undefined,
        },
        seguro: {
          ...seguro,
          moeda: seguro?.moeda?.id ? seguro?.moeda : undefined,
          valorMoeda: isValid(seguro?.valorMoeda)
            ? unnormalizeNumeral(seguro?.valorMoeda, formatBrazilianNumericDecimal(2))
            : undefined,
          valorReal: isValid(seguro?.valorReal)
            ? unnormalizeNumeral(seguro?.valorReal, formatBrazilianNumericDecimal(2))
            : undefined,
          valorPercentual: isValid(seguro?.valorPercentual)
            ? unnormalizeNumeral(seguro?.valorPercentual, formatBrazilianNumericDecimal(4))
            : undefined,
        },
        mercadorias,
        valorTotalMcvMoeda: isValid(normalizedData?.valorTotalMcvMoeda)
          ? unnormalizeNumeral(normalizedData?.valorTotalMcvMoeda, formatBrazilianNumericDecimal(2))
          : undefined,
      };
    },
    [unnormalizeNumeral]
  );

  const handleDispatchSetModel = useCallback(
    (data) => {
      dispatch(setModel(normalize(data)));
    },
    [normalize]
  );

  const createByStep = async (data, step, next) => {
    const isFirstStep = step === 0;
    const isLastStep = step === 3;

    const executeDebounced = debounce(async () => {
      try {
        if (isFirstStep && !isCreated) {
          const response = await register(data, [{ name: 'STEP', value: step + 1 }]);

          if (response?.status === 201) {
            next();
            dispatch(resetModel());
            dispatch(success());

            const created = response?.data;

            handleDispatchSetModel(created);
            dispatch(addToList({ data: created }));
            dispatch(fetchByIdAsync(created?.id));

            setIsCreated(true);
          }
        } else if (isLastStep) {
          const response = await save(data, [{ name: 'STEP', value: step + 1 }]);

          if (response?.status === 200) {
            dispatch(resetModel());

            const handleResultWithDebounce = debounce(() => {
              history.push(`/importacao/faturas?q=${data?.id} | ${data?.numero}`);
              dispatch(success());

              const saved = response?.data;

              dispatch(setModel(saved));

              dispatch(
                setResponse({
                  status: response.status,
                  data: saved,
                  message: t('com.muralis.qcx.mensagem.faturaRegistrada', { numero: saved?.numero }),
                })
              );

              dispatch(updateOnList({ data: saved }));
            }, 500);

            handleResultWithDebounce();
          }
        } else {
          const response = await save(data, [{ name: 'STEP', value: step + 1 }]);

          if (response?.status === 200) {
            if (isCreate) {
              next();
            } else {
              handleChangeToConsult();
            }

            const saved = response?.data;

            dispatch(success());
            dispatch(setModel(saved));
            dispatch(updateOnList({ data: saved }));
            dispatch(fetchByIdAsync(saved?.id));
          }
        }
      } catch (error) {
        dispatch(failure());
        let errorMessage = t('com.muralis.qcx.erro.erroSalvarFatura');
        if (error?.response && error?.response?.data) {
          errorMessage = t('com.muralis.qcx.erro.erroSalvarFaturaMensagem', { erro: error?.response.data.message });
        }
        dispatch(
          setError({
            message: errorMessage,
          })
        );
      }
    }, 500);

    dispatch(loading());
    executeDebounced();
  };

  const update = async (data, step) => {
    const executeDebounced = debounce(async () => {
      try {
        const response = await save(data, [{ name: 'STEP', value: step + 1 }]);

        if (response?.status === 200) {
          const handleResultWithDebounce = debounce(() => {
            handleChangeToConsult();
            dispatch(success());

            const saved = response?.data;

            dispatch(
              setResponse({
                status: response.status,
                data: saved,
                message: t('com.muralis.qcx.mensagem.faturaSalva', { numero: saved?.numero }),
              })
            );

            dispatch(setModel(saved));
            dispatch(updateSelectedMercadoria());
            dispatch(updateOnList({ data: saved }));
            dispatch(fetchByIdAsync(saved?.id));
          }, 500);

          handleResultWithDebounce();
        }
      } catch ({ response }) {
        dispatch(failure());
        dispatch(
          setError({
            message: t('com.muralis.qcx.erro.erroSalvarFaturaMensagem', {
              erro: `${data?.numero}: ${response?.data?.message}`,
            }),
          })
        );
      }
    }, 500);

    dispatch(loading());
    executeDebounced();
  };

  const handleSubmit = async (data, step, next) => {
    const normalizedData = normalize(data);
    if (isUpdate && !isBackgroundCreate) {
      await update(normalizedData, step);
      handleChangeToConsult();
    } else {
      await createByStep(normalizedData, step, next);
    }
  };

  const handleAlternativeSave = async (event, step, next) => {
    if (event && !isEmpty(event)) {
      event.stopPropagation();
    }

    const normalizedData = normalize(fatura);

    if (isUpdate) {
      await update(normalizedData, step);
    } else {
      await createByStep(normalizedData, step, next);
    }
  };

  const handleMercadoria = async (data, callback) => {
    const callCallbackIfValid = (...args) => {
      if (isFunction(callback)) {
        callback(...args);
      }
    };

    if (isSubCreate) {
      const unnormalizedData = {
        ...fatura,
        mercadorias: [...(fatura?.mercadorias || []), data],
      };
      const normalizedData = normalize(unnormalizedData);

      const executeDebounced = debounce(async () => {
        try {
          const response = await save(normalizedData, [{ name: 'STEP', value: 4 }]);

          if (response?.status === 200) {
            const handleResultWithDebounce = debounce(() => {
              dispatch(success());

              const saved = response?.data;

              const lastItemAdded = saved?.mercadorias?.length;

              dispatch(
                setResponse({
                  status: response.status,
                  data: saved,
                  message: t('com.muralis.qcx.mensagem.itemAdicionadoMercadoriaFatura', {
                    item: lastItemAdded,
                    numero: saved?.numero,
                  }),
                })
              );

              dispatch(resetRelatedMercadoriaModel());
              dispatch(changeToSubCreateMode());
              dispatch(fetchByIdAsync(saved?.id));
            }, 500);

            handleResultWithDebounce();

            callCallbackIfValid();
          }
        } catch ({ response }) {
          const itemNumber = normalizedData?.mercadorias?.length;

          dispatch(failure());
          dispatch(
            setError({
              message: t('com.muralis.qcx.erro.erroAdicionarMercadoriaFatura', {
                item: itemNumber,
                numero: fatura?.numero,
                mensagem: response?.data?.message,
              }),
            })
          );
        }
      }, 500);

      dispatch(loading());
      executeDebounced();

      return;
    }

    if ((isSubNone || isSubConsult) && isBackgroundDelete) {
      const unnormalizedData = {
        ...fatura,
        mercadorias: [...(fatura?.mercadorias.filter((current) => current?.id !== data?.id) || [])],
      };
      const normalizedData = normalize(unnormalizedData);

      const executeDebounced = debounce(async () => {
        try {
          const response = await save(normalizedData, [{ name: 'STEP', value: 4 }]);

          if (response?.status === 200) {
            const handleResultWithDebounce = debounce(() => {
              dispatch(success());

              const saved = response?.data;

              dispatch(
                setResponse({
                  status: response.status,
                  data: saved,
                  message: t('com.muralis.qcx.mensagem.itemRemovidoListaMercadoriaFatura', {
                    item: data?.item,
                    numero: saved?.numero,
                  }),
                })
              );

              dispatch(fetchByIdAsync(saved?.id));

              callCallbackIfValid(saved?.mercadorias);
            }, 500);

            handleResultWithDebounce();
          }
        } catch ({ response }) {
          dispatch(failure());
          dispatch(
            setError({
              message: t('com.muralis.qcx.erro.erroRemoverMercadoriaFatura', {
                item: data?.item,
                numero: fatura?.numero,
                mensagem: response?.data?.message,
              }),
            })
          );
        } finally {
          handleResetBackgroundMode();
        }
      }, 500);

      dispatch(loading());
      executeDebounced();

      return;
    }

    if (isSubUpdate) {
      dispatch(setRelatedMercadoriaModel(data));

      const unnormalizedData = {
        ...fatura,
        mercadorias: [...(fatura?.mercadorias.map((current) => (current?.id === data?.id ? data : current)) || [])],
      };
      const normalizedData = normalize(unnormalizedData);

      const executeDebounced = debounce(async () => {
        try {
          const response = await save(normalizedData, [{ name: 'STEP', value: 4 }]);

          if (response?.status === 200) {
            const handleResultWithDebounce = debounce(() => {
              dispatch(success());

              const saved = response?.data;

              dispatch(
                setResponse({
                  status: response.status,
                  data: saved,
                  message: t('com.muralis.qcx.mensagem.itemListaMercadoriaFaturaSalvo', {
                    item: data?.item,
                    numero: saved?.numero,
                  }),
                })
              );

              if (isUpdate) {
                handleChangeToConsult();
              }

              dispatch(changeToSubConsultMode());
              dispatch(fetchByIdAsync(saved?.id));
            }, 500);

            handleResultWithDebounce();
          }
        } catch ({ response }) {
          dispatch(failure());
          dispatch(
            setError({
              message: t('com.muralis.qcx.erro.erroSalvarItemMercadoriaFatura', {
                item: data?.item,
                numero: fatura?.numero,
                mensagem: response?.data?.message,
              }),
            })
          );
        }
      }, 500);

      dispatch(loading());
      executeDebounced();
    }
  };

  const refreshSelectedModel = useCallback(() => {
    if (!isEmpty(fatura) && fatura?.id) {
      fetchById(fatura?.id);
    }
  }, [fatura, isUpdate, fetchById, handleChangeToConsult]);

  const model = useMemo(
    () =>
      isCreated || !isCreate
        ? unnormalize(fatura)
        : {
            numero: fatura.numero,
            importador: {
              id: fatura.importador.id,
            },
            followUp: fatura.followUp,
          },
    [isCreate, isCreated, fatura, unnormalize]
  );

  const actionName = useMemo(() => {
    if (isCreate || isBackgroundCreate || isNone) return t('com.muralis.qcx.acoes.novo');
    if (isConsult) return t('com.muralis.qcx.acoes.visualizar');
    return t('com.muralis.qcx.acoes.alterar');
  }, [isNone, isCreate, isConsult, isBackgroundCreate]);

  const breadcrumbs = useMemo(
    () => [
      {
        link: {
          to: '/',
          name: t('com.muralis.qcx.inicio'),
        },
      },
      {
        link: {
          to: t('com.muralis.qcx.url.moduloImportacao'),
          name: t('com.muralis.qcx.importacao.label'),
        },
      },
      {
        link: {
          to: t('com.muralis.qcx.url.importacaoFaturas'),
          name: t('com.muralis.qcx.fatura.labelPlural'),
        },
      },
      {
        text: {
          name: `${isCreate ? t('com.muralis.qcx.registroManual') : t('com.muralis.qcx.registro')}`,
        },
      },
      {
        text: {
          name: actionName,
        },
      },
    ],
    [isCreate, actionName]
  );

  const pageTitle = useMemo(
    () =>
      isNone || isCreate || isBackgroundCreate
        ? t('com.muralis.qcx.fatura.novoRegistroFatura')
        : t('com.muralis.qcx.fatura.faturaSelecionadaIdentificacao', {
            identificacao: fatura?.numero || fatura?.code || '-',
          }),
    [isNone, isCreate, isBackgroundCreate, fatura]
  );

  return (
    <QCXRegistrationFormPageTemplate
      pageTitle={pageTitle}
      breadcrumbs={breadcrumbs}
      isIdle={isIdle}
      isLoading={isLoading}
      isFailure={isFailure}
      isCreate={isCreate}
      isConsult={isConsult}
      isUpdate={isUpdate}
      isBackgroundCreate={isBackgroundCreate}
      isBackgroundDelete={isBackgroundDelete}
      isPreparingAction={isPreparingAction}
      handleAlternativeSave={handleAlternativeSave}
      handleChangeToPreparingAction={handleChangeToPreparingAction}
      handleChangeToCreate={handleChangeToCreate}
      handleChangeToBackgroundCreate={handleChangeToBackgroundCreate}
      handleChangeToBackgroundDelete={handleChangeToBackgroundDelete}
      handleChangeToConsult={handleChangeToConsult}
      handleChangeToUpdate={handleChangeToUpdate}
      handleCancelUpdate={handleCancelUpdate}
      handleResetBackgroundMode={handleResetBackgroundMode}
      showSubtitle={false}
      authInfo={authInfo}
    >
      {(formProps) => (
        <QCXFaturaWizardFinalForm
          model={model}
          handleChangeModel={handleDispatchSetModel}
          handleSubmit={handleSubmit}
          refreshSelectedModel={refreshSelectedModel}
          handleMercadoria={handleMercadoria}
          authInfo={authInfo}
          requiredRoles={['importacao-fatura']}
          {...formProps}
        />
      )}
    </QCXRegistrationFormPageTemplate>
  );
}
