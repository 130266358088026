import {
  Grid,
} from '@material-ui/core';
import { isArray, isEmpty, isEqual } from 'lodash';
import {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import QCXFormTitle from '../../components/form-title/QCXFormTitle';
import QCXDataGridControl from '../../shared-components/data-grid-control/QCXDataGridControl';
import i18n from '../../i18n';

function QCXSimpleSelectManyPageTemplate({
  isLoading,
  isConsult,
  title = i18n.t('com.muralis.qcx.formulario'),
  tableColumns,
  tableData,
  controlButtons = [],
  selected,
  filterProp = 'code',
  filterProps = [],
  filterPropGetter,
  multipleFilter = false,
  showFullData = false,
  disableFullObject = false,
  clearSelection = false,
  handleSetSelected,
}) {
  const [localData, setLocalData] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);

  useEffect(() => {
    setLocalData([...tableData]);
  }, [
    tableData,
    setLocalData,
  ]);

  useEffect(() => {
    if (isArray(selected) && !isEmpty(selected)) {
      const selectedIds = selected.map((item) => item.id);

      const orderedSelectedIds = selectedIds.sort();
      const orderedSelectionModel = selectionModel.sort();

      const isChanged = !isEqual(
        orderedSelectedIds,
        orderedSelectionModel
      );

      if (isChanged) {
        setSelectionModel(selectedIds);
      }
    }

    if (clearSelection) {
      setSelectionModel([]);
    }
  }, [
    selected,
    clearSelection,
    setSelectionModel,
    selectionModel,
  ]);

  const handleFilterBySelection = useCallback((selection) => {
    if (disableFullObject) {
      const filtered = localData
        .map((item) => ({
          id: item?.id,
        }))
        .filter((item) => (
          selection.includes(item.id)
        ));

      return filtered;
    }

    const filtered = localData
      .filter((item) => (
        selection.includes(item.id)
      ));

    return filtered;
  }, [
    localData,
    disableFullObject,
  ]);

  const handleOnModelChange = useCallback((model) => {
    if (isArray(localData) && localData.length) {
      const filtered = handleFilterBySelection(model.selectionModel);

      handleSetSelected(filtered);
    }
  }, [
    localData,
    handleSetSelected,
    handleFilterBySelection,
  ]);

  const isSelectedValid = useMemo(() => (
    selected && selected.length
  ), [selected]);

  const selectedTableData = useMemo(() => (
    isConsult && isSelectedValid ? selected : localData
  ), [
    isConsult,
    selected,
    localData,
    isSelectedValid,
  ]);

  const rows = useMemo(() => (
    showFullData ? localData : selectedTableData
  ), [
    showFullData,
    localData,
    selectedTableData
  ]);

  return (
    <>
      <Grid
        item
        xs={12}
      >
        <QCXFormTitle
          title={title}
        />
      </Grid>
      <Grid
        item
        xs={12}
        direction="row"
        style={{
          marginTop: '0.7rem',
        }}
      >
        <QCXDataGridControl
          columns={tableColumns}
          rows={rows}
          loading={isLoading}
          selectionModel={selectionModel}
          multipleFilter={multipleFilter}
          controlButtons={controlButtons}
          componentsProps={{ checkbox: { disabled: isConsult } }}
          onSelectionModelChange={handleOnModelChange}
          filterProps={filterProps}
          filterProp={filterProp}
          filterPropGetter={filterPropGetter}
          disableSelectionOnClick
          disableColumnSelector
          checkboxSelection
        />
      </Grid>
    </>
  );
}

export default QCXSimpleSelectManyPageTemplate;
