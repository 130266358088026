import { SingletonUtils } from '../general-utils';

const ISENTO = 'ISENTA';
const NAO_ISENTO = 'NAO_ISENTA';

const isNaoIsento = (value) => (
  NAO_ISENTO === value
);

const isIsento = (value) => (
  ISENTO === value
);

const parseFromSingletonList = (singletonList = []) => (
  SingletonUtils.fromList(
    isIsento,
    NAO_ISENTO,
    singletonList
  )
);

const parseToSingletonList = (value) => (
  SingletonUtils.toList(
    isNaoIsento,
    value
  )
);

const AfrmmUtils = {
  ISENTO,
  NAO_ISENTO,
  isNaoIsento,
  isIsento,
  parseFromSingletonList,
  parseToSingletonList,
};

export default AfrmmUtils;
