import { Grid, Stack, Typography } from "@mui/material";
import FeedbackIcon from "@mui/icons-material/Feedback";
import { useTheme } from "@material-ui/core";

const MessageBadge = ({
  message,
}: {
  message: string | null | undefined | string[];
}) => {
  const theme = useTheme();
  return (
    <>
      {message && (
        <Grid item sm={12}>
          {Array.isArray(message) ? (
            <Stack
              direction={"row"}
              border={`1px solid ${theme.palette.primary.main}`}
              borderRadius={2}
              py={2}
              pl={2}
              alignItems={"center"}
            >
              <FeedbackIcon color="primary" />
              <Stack direction={"column"}>
                {message.map((message) => (
                  <Typography
                    ml={1}
                    color={theme.palette.primary.main}
                    textTransform={"uppercase"}
                    fontSize={12}
                  >
                    {message}
                  </Typography>
                ))}
              </Stack>
            </Stack>
          ) : (
            <Stack
              direction={"row"}
              border={`1px solid ${theme.palette.primary.main}`}
              borderRadius={2}
              py={2}
              pl={2}
              alignItems={"center"}
            >
              <FeedbackIcon color="primary" />
              <Typography
                ml={1}
                color={theme.palette.primary.main}
                textTransform={"uppercase"}
                fontSize={12}
              >
                {message}
              </Typography>
            </Stack>
          )}
        </Grid>
      )}
    </>
  );
};

export default MessageBadge;
