import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import QCXFocusLock from '../../components/focus-lock/QCXFocusLock';

export default function QCXDialog({
  open,
  onClose,
  children,
  ...restProps
}) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      disableAutoFocus
      disableEnforceFocus
      disableRestoreFocus
      disableScrollLock
      {...restProps}
    >
      <QCXFocusLock
        disabled={!open}
      >
        {children}
      </QCXFocusLock>
    </Dialog>
  );
}
