import { validateQueryParams } from '../../utils/api/api-utils';
import {
  doDeleteQCXRequest,
  doGetQCXRequest,
  doPostQCXRequest,
  doPutQCXInactivateRequest,
  doPutQCXRequest,
} from '../../utils/api/domain/domain-api-utils';
import { FILTER_ENDPOINT, PERFIL_ENDPOINT } from '../../utils/api/domain/domain-endpoint-utils';

export async function fetchAll() {
  return doGetQCXRequest([
    PERFIL_ENDPOINT
  ]);
}
export async function fetchByFilter(params = []) {
  const validatedParams = validateQueryParams(params);
  if (validatedParams.length) {
    return doGetQCXRequest([
      PERFIL_ENDPOINT, FILTER_ENDPOINT
    ], validatedParams);
  }
  return { data: [] };
}

export async function fetchById(id) {
  return doGetQCXRequest([
    PERFIL_ENDPOINT,
    id
  ]);
}

export async function register(data, queryParams = []) {
  return doPostQCXRequest(
    [PERFIL_ENDPOINT],
    data,
    queryParams
  );
}

export async function save(data, queryParams = []) {
  return doPutQCXRequest(
    [PERFIL_ENDPOINT],
    data,
    queryParams
  );
}

export async function deleteById(id) {
  return doDeleteQCXRequest(
    [PERFIL_ENDPOINT, id]
  );
}
export async function inactivateById(id) {
  return doPutQCXInactivateRequest(
    [PERFIL_ENDPOINT],
    id
  );
}
