import React from 'react';
import { BRAZILIAN } from '../../utils/hooks/form/field/mask-types';
import QCXFinalPercentualField from '../final-percentual-field/QCXFinalPercentualField';
import QCXTextField from '../text-field/QCXTextField';

export default function QCXFinalGRISField({
  name,
  label,
  type,
  required,
  helperText,
  validate,
  intl = BRAZILIAN,
  scale = 2,
  children,
  ...restProps
}) {
  return (
    <QCXFinalPercentualField
      name={name}
      type={type}
      label={label}
      required={required}
      component={QCXTextField}
      validate={validate}
      intl={intl}
      scale={scale}
      {...restProps}
    />
  );
}
