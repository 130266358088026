import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import { lock, unlock } from '../../features/focus-lock/focusLockSlice';
import QCXDialog from './QCXDialog';
import QCXDialogTitle from './QCXDialogTitle';
import QCXDialogContent from './QCXDialogContent';
import QCXDialogContentText from './QCXDialogContentText';
import QCXDialogActions from './QCXDialogActions';
import QCXButton from '../button/QCXButton';
import i18n from '../../i18n';

const DEFAULT_BUTTON_GROUP_OPTIONS = {
  confirm: {
    variant: 'contained',
    color: 'secondary',
    description: i18n.t('com.muralis.qcx.acoes.confirmar'),
  },
  cancel: {
    variant: 'outlined',
    color: 'default',
    description: i18n.t('com.muralis.qcx.acoes.cancelar'),
  },
};

export default function QCXConfirmDialog({
  open,
  title,
  content,
  renderMiddleMessage = () => {},
  endContent,
  onConfirm,
  onSecondaryConfirm = undefined,
  onClose,
  buttonSize = 'small',
  buttonGroupOptions = DEFAULT_BUTTON_GROUP_OPTIONS,
  disableBackdropClick = true,
  disableRestoreFocus = true,
  disableEnforceFocus = true,
  ...restProps
}) {
  const dispatch = useDispatch();

  const buttonGroupOptionsMounted = useMemo(
    () => ({
      ...DEFAULT_BUTTON_GROUP_OPTIONS,
      ...buttonGroupOptions,
      confirm: {
        ...DEFAULT_BUTTON_GROUP_OPTIONS?.confirm,
        ...buttonGroupOptions?.confirm,
      },
      secondaryConfirm: {
        ...DEFAULT_BUTTON_GROUP_OPTIONS?.secondaryConfirm,
        ...buttonGroupOptions?.secondaryConfirm,
      },
      cancel: {
        ...DEFAULT_BUTTON_GROUP_OPTIONS?.cancel,
        ...buttonGroupOptions?.cancel,
      },
    }),
    [buttonGroupOptions]
  );

  useEffect(() => {
    const handleFocusMode = () => {
      if (open) {
        dispatch(unlock());
      }
    };

    handleFocusMode();
  }, [open]);

  const handleInternalClose = useCallback(
    (...args) => {
      onClose(...args);
      dispatch(lock());
    },
    [onClose]
  );

  return (
    <QCXDialog
      key="modal-confirm-dialog"
      id="modal-confirm-dialog"
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      disableBackdropClick={disableBackdropClick}
      disableRestoreFocus={disableRestoreFocus}
      disableEnforceFocus={disableEnforceFocus}
      {...restProps}
    >
      {title && <QCXDialogTitle id="alert-dialog-title">{title}</QCXDialogTitle>}
      {content && (
        <QCXDialogContent>
          <QCXDialogContentText id="alert-dialog-content-description">{content}</QCXDialogContentText>
          {renderMiddleMessage && _.isFunction(renderMiddleMessage) && (
            <QCXDialogContentText id="alert-dialog-middle-content-description">
              {renderMiddleMessage()}
            </QCXDialogContentText>
          )}
          {endContent && (
            <QCXDialogContentText id="alert-dialog-end-content-description" fontWeightBold>
              {endContent}
            </QCXDialogContentText>
          )}
        </QCXDialogContent>
      )}
      <QCXDialogActions>
        <QCXButton
          variant="outlined"
          onClick={handleInternalClose}
          color="default"
          size={buttonSize}
          {...buttonGroupOptionsMounted?.cancel}
        >
          {buttonGroupOptionsMounted?.cancel?.description || i18n.t('com.muralis.qcx.acoes.cancelar')}
        </QCXButton>
        {onSecondaryConfirm && (
          <QCXButton
            variant="outlined"
            onClick={onSecondaryConfirm}
            color="primary"
            size={buttonSize}
            {...buttonGroupOptionsMounted?.cancel}
          >
            {buttonGroupOptionsMounted?.secondaryConfirm?.description || ''}
          </QCXButton>
        )}
        <QCXButton
          variant="contained"
          onClick={onConfirm}
          color="secondary"
          size={buttonSize}
          autoFocus
          {...buttonGroupOptionsMounted?.confirm}
        >
          {buttonGroupOptionsMounted?.confirm?.description || i18n.t('com.muralis.qcx.acoes.confirmar')}
        </QCXButton>
      </QCXDialogActions>
    </QCXDialog>
  );
}
