import { Grid, makeStyles } from '@material-ui/core';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Block as BlockIcon, Delete as DeleteIcon } from '@material-ui/icons';
import { isEmpty } from 'lodash';
import QCXButton from '../../shared-components/button/QCXButton';
import { SUB_CONSULT_MODE, SUB_UPDATE_MODE } from '../../features/mode';

const useStyles = makeStyles((theme) => ({
  controlButton: {
    marginRight: theme.spacing(1),
    '& .MuiButton-startIcon': {
      marginRight: '4px',
    },
  },
}));

const DEFAULT_BUTTON_GROUP_OPTIONS = {
  remove: {
    visibleOn: [SUB_CONSULT_MODE],
  },
};

export default function QCXCustomFormControlButtonsGroup({
  isCreate,
  isConsult,
  isUpdate,
  isSubNone,
  isSubCreate,
  isSubConsult,
  isSubUpdate,
  submitting,
  pristine,
  options = DEFAULT_BUTTON_GROUP_OPTIONS,
  handleEdit,
  handleCancelUpdate,
  handleCancelCreate,
  handleRemoveByForm,
  handleBackToList,
  hideRigthButtons = false,
  disabled,
}) {
  const { t } = useTranslation();
  const classes = useStyles();

  const defaultButtonGroupOptions = useMemo(
    () => (options && !isEmpty(options) ? options : DEFAULT_BUTTON_GROUP_OPTIONS),
    [options]
  );

  const isRemoveButtonVisibleOnlySubNoneOrSubConsultMode = useMemo(
    () => (isSubNone || isSubConsult) && defaultButtonGroupOptions?.remove?.visibleOn?.includes(SUB_CONSULT_MODE),
    [isSubNone, isSubConsult, defaultButtonGroupOptions]
  );

  const isRemoveButtonVisibleOnlySubUpdateMode = useMemo(
    () => isSubUpdate && defaultButtonGroupOptions?.remove?.visibleOn?.includes(SUB_UPDATE_MODE),
    [defaultButtonGroupOptions, isSubUpdate]
  );

  return (
    <Grid container spacing={2} justify="space-between" style={{ marginTop: '1.5rem' }}>
      <Grid item>
        {isSubCreate && !isConsult && (
          <QCXButton
            className={classes.controlButton}
            variant="outlined"
            startIcon={<BlockIcon />}
            disabled={disabled || isConsult}
            onClick={handleCancelCreate}
          >
            {t('com.muralis.qcx.acoes.cancelar')}
          </QCXButton>
        )}
        {isSubCreate && !isConsult && (
          <QCXButton
            className={classes.controlButton}
            color="secondary"
            type="submit"
            disabled={disabled || submitting || isConsult}
          >
            {t('com.muralis.qcx.acoes.adicionar')}
          </QCXButton>
        )}
        {(isCreate || isConsult || isUpdate) && isSubConsult && (
          <QCXButton
            className={classes.controlButton}
            color="secondary"
            onClick={handleEdit}
            disabled={disabled || !(isCreate || isConsult || isUpdate)}
          >
            {t('com.muralis.qcx.acoes.alterar')}
          </QCXButton>
        )}
        {isSubUpdate && (
          <QCXButton
            className={classes.controlButton}
            variant="outlined"
            startIcon={<BlockIcon />}
            onClick={handleCancelUpdate}
            disabled={disabled}
          >
            {t('com.muralis.qcx.acoes.cancelar')}
          </QCXButton>
        )}
        {isSubUpdate && (
          <QCXButton
            className={classes.controlButton}
            color="secondary"
            type="submit"
            disabled={disabled || submitting || pristine}
          >
            {t('com.muralis.qcx.acoes.salvar')}
          </QCXButton>
        )}
        {isRemoveButtonVisibleOnlySubNoneOrSubConsultMode && (
          <QCXButton
            className={classes.controlButton}
            variant="outlined"
            startIcon={<DeleteIcon color="inherit" />}
            style={{
              fontWeight: 550,
            }}
            disabled={disabled || !isSubConsult}
            onClick={handleRemoveByForm}
          >
            {t('com.muralis.qcx.acoes.remover')}
          </QCXButton>
        )}
      </Grid>
      {!hideRigthButtons && (
        <Grid item>
          {isRemoveButtonVisibleOnlySubUpdateMode && (
            <QCXButton
              className={classes.controlButton}
              variant="outlined"
              startIcon={<DeleteIcon color="inherit" />}
              style={{
                fontWeight: 550,
              }}
              onClick={handleRemoveByForm}
              fullWidth
              disabled={disabled}
            >
              {t('com.muralis.qcx.acoes.remover')}
            </QCXButton>
          )}
          {isSubConsult && (
            <QCXButton
              className={classes.controlButton}
              variant="outlined"
              onClick={handleBackToList}
              fullWidth
              disabled={disabled}
            >
              {t('com.muralis.qcx.acoes.voltar')}
            </QCXButton>
          )}
        </Grid>
      )}
    </Grid>
  );
}
