import { MatcherConfigureUtils } from '../../utils/general/general-utils';

const SITUACAO_ESPECIAL_DESPACHO_DUE_A_POSTERIORI_CODE = '2001';
const SITUACAO_ESPECIAL_DESPACHO_EMBARQUE_ANTECIPADO_CODE = '2002';
const SITUACAO_ESPECIAL_DESPACHO_SEM_SAIDA_DA_MERCADORIA_DO_PAIS_CODE = '2003';

const isSituacaoEspecialDespachoDUEPosteriori = (
  MatcherConfigureUtils.getSpecificByCode(
    SITUACAO_ESPECIAL_DESPACHO_DUE_A_POSTERIORI_CODE
  )
);

const isSituacaoEspecialDespachoEmbarqueAntecipado = (
  MatcherConfigureUtils.getSpecificByCode(
    SITUACAO_ESPECIAL_DESPACHO_EMBARQUE_ANTECIPADO_CODE
  )
);

const isSituacaoEspecialDespachoSemSaidaMercadoriaPais = (
  MatcherConfigureUtils.getSpecificByCode(
    SITUACAO_ESPECIAL_DESPACHO_SEM_SAIDA_DA_MERCADORIA_DO_PAIS_CODE
  )
);

const SituacaoEspecialDespachoUtils = Object.freeze({
  DUE_A_POSTERIORI: (
    SITUACAO_ESPECIAL_DESPACHO_DUE_A_POSTERIORI_CODE
  ),
  EMBARQUE_ANTECIPADO: (
    SITUACAO_ESPECIAL_DESPACHO_EMBARQUE_ANTECIPADO_CODE
  ),
  SEM_SAIDA_DA_MERCADORIA_DO_PAIS: (
    SITUACAO_ESPECIAL_DESPACHO_SEM_SAIDA_DA_MERCADORIA_DO_PAIS_CODE
  ),
  isDUEPosteriori: (
    isSituacaoEspecialDespachoDUEPosteriori
  ),
  isEmbarqueAntecipado: (
    isSituacaoEspecialDespachoEmbarqueAntecipado
  ),
  isSemSaidaMercadoriaPais: (
    isSituacaoEspecialDespachoSemSaidaMercadoriaPais
  ),
});

export default SituacaoEspecialDespachoUtils;
