import { validateQueryParams } from '../../utils/api/api-utils';
import {
  doGetQCXRequest,
} from '../../utils/api/domain/domain-api-utils';
import { FILTER_ENDPOINT, TIPO_DOCUMENTO_DUE_ENDPOINT } from '../../utils/api/domain/domain-endpoint-utils';

export async function fetchAll() {
  return doGetQCXRequest([
    TIPO_DOCUMENTO_DUE_ENDPOINT
  ]);
}

export async function fetchById(id) {
  return doGetQCXRequest([
    TIPO_DOCUMENTO_DUE_ENDPOINT,
    id
  ]);
}

export async function fetchByFilter(
  params = []
) {
  const validatedQueryParams = validateQueryParams(params);

  return doGetQCXRequest([
    TIPO_DOCUMENTO_DUE_ENDPOINT,
    FILTER_ENDPOINT
  ], validatedQueryParams);
}
