import { Grid } from '@material-ui/core';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  fetchAllAsync, selectDespachantes, selectStatus,
} from '../../features/despachante/despachanteSlice';
import QCXSimpleSelectManyPageTemplate from '../../templates/simple-select-many-page/QCXSimpleSelectManyPageTemplate';
import { isAlternativeLoadingStatus } from '../../utils/store/store-utils';

export default function QCXSelectManyDespachantesTable({ ...props }) {
  const { t } = useTranslation();
  const
    {
      title = t('com.muralis.qcx.despachante.selecionarDespachante'),
      filterProp = 'description',
      columns = [
        {
          field: 'id',
          headerName: t('com.muralis.qcx.codigo'),
          headerAlign: 'center',
          align: 'center',
          type: 'number',
          flex: 0.3,
        },
        {
          field: 'nome',
          headerName: t('com.muralis.qcx.despachante.nomeDespachante'),
          type: 'string',
          flex: 2,
          valueGetter: ({ row }) => (
            row?.nome
          ),
        },
        {
          field: 'active',
          headerName: t('com.muralis.qcx.estado.ativo'),
          type: 'boolean',
          flex: 0.5,
        },
      ],
      selected,
      handleChange,
      isConsult,
    } = props;

  const dispatch = useDispatch();

  const despachantes = useSelector(selectDespachantes);
  const status = useSelector(selectStatus);

  const isAlternativeLoading = useMemo(() => (
    isAlternativeLoadingStatus(status)
  ), [status]);

  useEffect(() => {
    dispatch(fetchAllAsync());
  }, []);

  return (
    <>
      <Grid container direction="row" style={{ marginTop: '0.7rem' }}>
        <QCXSimpleSelectManyPageTemplate
          title={title}
          tableColumns={columns}
          tableData={despachantes}
          filterProp={filterProp}
          isLoading={isAlternativeLoading}
          isConsult={isConsult}
          handleSetSelected={handleChange}
          selected={selected}
        />
      </Grid>
    </>
  );
}
