import {
  Grid,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import QCXBox from '../../shared-components/box/QCXBox';
import QCXFinalCheckboxField from '../../shared-components/final-checkbox-field/QCXFinalCheckboxField';
import QCXFinalRadioField from '../../shared-components/final-radio-field/QCXFinalRadioField';
import QCXFinalTextField from '../../shared-components/final-text-field/QCXFinalTextField';
import QCXFormStepWizard from '../../shared-components/form-step-wizard/QCXFormStepWizard';

export default function QCXModeloFaturamentoAgendaForm({
  initialValues,
  handleSubmit,
  isConsult,
  controlComponentProps,
  ...restProps
}) {
  const { t } = useTranslation();

  return (
    <QCXFormStepWizard
      initialValues={initialValues}
      onSubmit={handleSubmit}
      controlComponentProps={controlComponentProps}
      {...restProps}
    >
      {({ values }) => (
        <>
          <Typography variant="h6" color="primary">
            <QCXBox fontWeight="fontWeightBold" pt={3}>
              {t('com.muralis.qcx.agenda').toUpperCase()}
            </QCXBox>
          </Typography>
          <Grid container spacing={2} style={{ marginTop: '1rem' }}>
            <Grid
              item
              xs={12}
              sm={4}
              md={3}
            >
              <QCXFinalRadioField
                id="checkbox-frequencia-diario"
                key="checkbox-frequencia-diario"
                name="agenda.frequencia"
                label={t('com.muralis.qcx.frequencia.diario')}
                value="DIARIO"
                disabled={isConsult || !values?.automatico}
                required
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              md={3}
            >
              <QCXFinalRadioField
                id="checkbox-frequencia-semanal"
                key="checkbox-frequencia-semanal"
                name="agenda.frequencia"
                label={t('com.muralis.qcx.frequencia.semanal')}
                value="SEMANAL"
                disabled={isConsult || !values?.automatico}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              md={3}
            >
              <QCXFinalRadioField
                id="checkbox-frequencia-mensal"
                key="checkbox-frequencia-mensal"
                name="agenda.frequencia"
                label={t('com.muralis.qcx.frequencia.mensal')}
                value="MENSAL"
                disabled={isConsult || !values?.automatico}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={3}
            >
              <QCXFinalCheckboxField
                id="checkbox-processo-a-processo"
                key="checkbox-processo-a-processo"
                name="agenda.processoAProcesso"
                label={t('com.muralis.qcx.processoAProcesso')}
                disabled={isConsult || !values?.automatico}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginBottom: '2rem', paddingTop: '0.7rem' }}>
            <Grid item xs={12}>
              <Typography variant="h6" color="primary">
                <QCXBox fontWeight="fontWeightBold" pt={3}>
                  {t('com.muralis.qcx.formaEnvioRecebimento').toUpperCase()}
                </QCXBox>
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
            >
              <QCXFinalCheckboxField
                id="checkbox-ftp"
                key="checkbox-ftp"
                name="agenda.ftp"
                label={t('com.muralis.qcx.ftp')}
                disabled
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
            >
              <QCXFinalCheckboxField
                id="checkbox-web-service"
                key="checkbox-web-service"
                name="agenda.webService"
                label={t('com.muralis.qcx.webService')}
                disabled
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
            >
              <QCXFinalTextField
                id="text-field-ftp"
                key="text-field-ftp"
                name="agenda.enderecoFtp"
                label={t('com.muralis.qcx.enderecoFTP')}
                disabled
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
            >
              <QCXFinalTextField
                id="text-field-web-service"
                key="text-field-web-service"
                name="agenda.enderecoWebService"
                label={t('com.muralis.qcx.enderecoWebService')}
                disabled
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
            >
              <QCXFinalCheckboxField
                id="checkbox-email"
                key="checkbox-email"
                name="agenda.email"
                label={t('com.muralis.qcx.email')}
                disabled
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
            >
              <QCXFinalCheckboxField
                id="checkbox-diretorio-local"
                key="checkbox-diretorio-local"
                name="agenda.diretorioLocal"
                label={t('com.muralis.qcx.diretorioLocal')}
                disabled
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
            >
              <QCXFinalTextField
                id="text-field-email"
                key="text-field-email"
                name="agenda.enderecoEmail"
                label={t('com.muralis.qcx.enderecoEmail')}
                maxLength={20}
                disabled
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
            >
              <QCXFinalTextField
                id="text-field-diretorio-local"
                key="text-field-diretorio-local"
                name="agenda.enderecoDiretorioLocal"
                label={t('com.muralis.qcx.enderecoDiretorioLocal')}
                disabled
              />
            </Grid>
          </Grid>
        </>
      )}
    </QCXFormStepWizard>
  );
}
