import { createFilterOptions } from '@material-ui/lab/useAutocomplete';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import QCXAutocompleteSelect from '../autocomplete-select/QCXAutocompleteSelect';
import { DANFE_UF_LIST } from './danfeUfUtils';

const filterOptions = createFilterOptions({
  stringify: (item) => item,
});

/**
 * Dropdown feito especialmente para os formulários relacionados à danfe, que
 * aceita os campos de UF baseado na sigla da UF, mais a sigla 'EX' para
 * estrangeiro. Este dropdown trabalha diretamente com a sigla da uf, sem usar
 * objetos.
 */
export default function QCXSelectDanfeUfAutocomplete({
  label,
  name,
  initialValues,
  ...restProps
}) {
  const [defaultValue, setDefaultValue] = useState('');
  useEffect(() => {
    const value = DANFE_UF_LIST.find((item) => item === _.get(initialValues, name));
    if (value) {
      setDefaultValue(value);
    }
  }, [initialValues]);
  return (
    <QCXAutocompleteSelect
      label={label}
      id={name}
      name={name}
      options={DANFE_UF_LIST}
      filterOptions={filterOptions}
      defaultValue={defaultValue}
      getOptionLabel={(item) => item}
      getOptionValue={(item) => item}
      {...restProps}
    />
  );
}
