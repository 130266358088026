import { isArray } from 'lodash';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ncmSubitemAPI } from '../../../features/ncm-subitem/ncmSubitemAPI';
import { nveActions } from '../../../features/nve/nveSlice';
import { isStrictEquals } from '../general-utils';

const TIPO_FATURA = 'FATURA';
const TIPO_DUIMP = 'DUIMP';

const isTipoDuimp = (type) => {
  const value = isArray(type) ? type[0] : type;
  return isStrictEquals(TIPO_DUIMP, value);
};

const isTipoFatura = (type) => (
  isStrictEquals(TIPO_FATURA, type)
);

const normalizeTipoCatalogoProdutos = (type) => {
  if (isTipoDuimp(type)) {
    return TIPO_DUIMP;
  }

  return TIPO_FATURA;
};

const unnormalizeTipoCatalogoProdutos = (type) => {
  if (isTipoDuimp(type)) {
    return [TIPO_DUIMP];
  }

  return [];
};

export default function useFilterNveBy({
  ncmList,
  ncm,
}) {
  const dispatch = useDispatch();

  const ncmExists = useCallback(() => {
    if (ncm && ncmList) {
      return ncmList.some((ncmItem) => (
        isStrictEquals(ncmItem?.id, ncm?.id)
      ));
    }
    return false;
  },
  [ncm, ncmList]);

  const filterNve = useCallback(async () => {
    const ncmExistsValue = ncmExists();
    let selectedNcm;
    if (ncm && ncmExistsValue) {
      const ncmSubitemResponse = await ncmSubitemAPI.fetchById(ncm?.id);
      selectedNcm = ncmSubitemResponse?.data;
    }
    dispatch(nveActions.fetchByFilterAsync([{ name: 'code', value: selectedNcm?.code }]));
  }, [
    ncm,
    ncmList,
  ]);

  useEffect(() => {
    filterNve();
  }, [filterNve]);

  return filterNve;
}

export {
  TIPO_DUIMP,
  TIPO_FATURA,
  isTipoDuimp,
  isTipoFatura,
  useFilterNveBy,
  normalizeTipoCatalogoProdutos,
  unnormalizeTipoCatalogoProdutos,
};
