import React from 'react';

export default function QCXInputFile({
  id,
  key,
  name,
  accept,
  disabled,
  required,
  ref,
  forwardedRef,
  ...restProps
}) {
  return (
    <input
      id={id}
      key={key}
      name={name}
      accept={accept}
      disabled={disabled}
      required={required}
      ref={ref || forwardedRef}
      {...restProps}
      type="file"
    />
  );
}
