import { isEmpty } from 'lodash';
import debounce from 'lodash/debounce';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { register, save } from '../../../../../features/tabela-sda/tabelaSdaAPI';
import {
  addToList,
  changeToConsultMode,
  changeToUpdateMode,
  failure,
  fetchByIdAsync,
  loading,
  resetModel,
  selectTabelaSda,
  selectMode,
  setError,
  setModel,
  setResponse,
  success,
  updateOnList,
  selectBackgroundMode,
  selectStatus,
  changeToBackgroundCreateMode,
  resetBackgroundMode,
  preparingAction,
  changeToCreateMode,
} from '../../../../../features/tabela-sda/tabelaSdaSlice';
import {
  isBackgroundCreateMode,
  isConsultMode,
  isCreateMode,
  isNoneMode,
  isPreparingActionStatus,
  isUpdateMode,
} from '../../../../../utils/store/store-utils';
import { normalizeNumeral, unnormalizeNumeral } from '../../../../../utils/general/general-utils';
import { formatBrazilianNumericDecimal } from '../../../../../utils/hooks/form/field/formatters';
import QCXTabelaSdaWizardFinalForm from '../../../../../components/tabela-sda/QCXTabelaSdaWizardFinalForm';

import QCXRegistrationFormPageTemplate from '../../../../../templates/registration-form-page/QCXRegistrationFormPageTemplate';

export default function TabelaSDARegistrationPage({ authInfo = {} }) {
  const { t } = useTranslation();

  const { id } = useParams();

  const history = useHistory();

  const dispatch = useDispatch();

  const [isCreated, setIsCreated] = useState(false);

  const mode = useSelector(selectMode);
  const backgroundMode = useSelector(selectBackgroundMode);
  const status = useSelector(selectStatus);

  const tabelaSda = useSelector(selectTabelaSda);

  const isNone = useMemo(() => (
    isNoneMode(mode)
  ), [mode]);

  const isCreate = useMemo(() => (
    isCreateMode(mode)
  ), [mode]);

  const isConsult = useMemo(() => (
    isConsultMode(mode)
  ), [mode]);

  const isUpdate = useMemo(() => (
    isUpdateMode(mode)
  ), [mode]);

  const isBackgroundCreate = useMemo(() => (
    isBackgroundCreateMode(backgroundMode)
  ), [backgroundMode]);

  const isPreparingAction = useMemo(() => (
    isPreparingActionStatus(status)
  ), [status]);

  const handleChangeToCreate = () => {
    dispatch(changeToCreateMode());
  };

  const handleChangeToBackgroundCreate = () => {
    dispatch(changeToBackgroundCreateMode());
  };

  const handleResetBackgroundMode = () => {
    dispatch(resetBackgroundMode());
  };

  const handleChangeToPreparingAction = () => {
    dispatch(preparingAction());
  };

  const handleChangeToConsult = () => {
    dispatch(changeToConsultMode());
  };

  const handleChangeToUpdate = () => {
    dispatch(changeToUpdateMode());
  };

  useEffect(() => {
    if (!isEmpty(id)) {
      dispatch(fetchByIdAsync(id));
      handleChangeToConsult();
    }
  }, [id]);

  const handleCancelUpdate = () => {
    const currentId = isBackgroundCreate ? tabelaSda?.id : id;

    if (currentId) {
      dispatch(fetchByIdAsync(currentId));
    }
    handleChangeToConsult();
  };

  const normalize = useCallback((unnormalizeData) => {
    const unnormalizedData = {
      ...unnormalizeData,
      percentual: normalizeNumeral(
        unnormalizeData?.percentual
      ),
      valor: {
        minimo: normalizeNumeral(
          unnormalizeData?.valor?.minimo
        ),
        maximo: normalizeNumeral(
          unnormalizeData?.valor?.maximo
        ),
      },
      percentualContribuicaoAssistencial: normalizeNumeral(
        unnormalizeData?.percentualContribuicaoAssistencial
      ),
    };

    return unnormalizedData;
  }, []);

  const unnormalize = useCallback((normalizedData) => {
    const unnormalizedData = {
      ...normalizedData,
      percentual: unnormalizeNumeral(
        normalizedData?.percentual,
        formatBrazilianNumericDecimal(2)
      ),
      valor: {
        minimo: unnormalizeNumeral(
          normalizedData?.valor?.minimo,
          formatBrazilianNumericDecimal(2)
        ),
        maximo: unnormalizeNumeral(
          normalizedData?.valor?.maximo,
          formatBrazilianNumericDecimal(2)
        ),
      },
      percentualContribuicaoAssistencial: unnormalizeNumeral(
        normalizedData?.percentualContribuicaoAssistencial,
        formatBrazilianNumericDecimal(2)
      ),
    };

    return unnormalizedData;
  }, []);

  const handleDispatchSetModel = useCallback((data) => {
    dispatch(setModel(normalize(data)));
  }, [normalize]);

  const createByStep = async (data, step, next) => {
    const isFirstStep = step === 0;
    const isLastStep = step === 1;

    const executeDebounced = debounce(async () => {
      try {
        if (isFirstStep && !isCreated) {
          const response = await register(data);

          if (response?.status === 201) {
            next();
            dispatch(resetModel());
            dispatch(success());

            const created = response?.data;

            handleDispatchSetModel(created);
            dispatch(addToList({ data: created }));

            setIsCreated(true);
          }
        } else if (isLastStep) {
          if (isEmpty(data?.despachantes)) {
            throw Error(t('com.muralis.qcx.validacao.necessarioDespachanteParaProsseguir'));
          }

          const response = await save(data);

          if (response?.status === 200) {
            dispatch(resetModel());

            const handleResultWithDebounce = debounce(() => {
              history.push(t('com.muralis.qcx.url.modulosGeraisTabelaSDA'));

              dispatch(success());

              const saved = response?.data;

              dispatch(setResponse({
                status: response.status,
                data: saved,
                message: t('com.muralis.qcx.mensagem.tabelaSDACadastrada', { modelo: saved?.description }),
              }));
            }, 500);

            handleResultWithDebounce();
          }
        } else {
          const response = await save(data);

          if (response?.status === 200) {
            if (isCreate) {
              next();
            } else {
              handleChangeToConsult();
            }
            const saved = response?.data;

            dispatch(success());
            dispatch(setModel(saved));
            dispatch(updateOnList({ data: saved }));
          }
        }
      } catch (error) {
        const errorMessage = !isEmpty(error?.response)
          ? t('com.muralis.qcx.mensagem.erroSalvarDadosTabelaSDAEspecifico', { tabela: data?.description, erro: error?.response?.data?.message })
          : t('com.muralis.qcx.erro.erroSalvarDadosTabelaSDA', { erro: error?.message });
        dispatch(failure());
        dispatch(setError({
          message: errorMessage,
        }));
      }
    }, 500);

    dispatch(loading());
    executeDebounced();
  };

  const update = async (data, step, forceRefresh = false) => {
    const isStepDespachante = step === 1;
    const executeDebounced = debounce(async () => {
      try {
        if (isStepDespachante && isEmpty(data?.despachantes)) {
          throw Error(t('com.muralis.qcx.validacao.necessarioDespachanteParaProsseguir'));
        }

        const response = await save(data);

        if (response?.status === 200) {
          const handleResultWithDebounce = debounce(() => {
            handleChangeToConsult();
            dispatch(success());

            const saved = response?.data;

            dispatch(setResponse({
              status: response.status,
              data: saved,
              message: t('com.muralis.qcx.mensagem.tabelaSDAAlteracoesSalvas', { tabela: saved?.description }),
            }));

            if (forceRefresh) {
              dispatch(fetchByIdAsync(data?.id));
            } else {
              dispatch(setModel(saved));
            }

            handleChangeToConsult();
            dispatch(updateOnList({ data: saved }));
          }, 500);

          handleResultWithDebounce();
        }
      } catch (error) {
        const errorMessage = !isEmpty(error?.response)
          ? t('com.muralis.qcx.erro.erroSalvarDadosTabelaSDAEspecifico', { tabela: data?.description, erro: error?.response?.data?.message })
          : t('com.muralis.qcx.erro.erroSalvarDadosTabelaSDA', { erro: error?.message });
        dispatch(failure());
        dispatch(setError({
          message: errorMessage,
        }));
      }
    }, 500);

    dispatch(loading());
    executeDebounced();
  };

  const handleSubmit = async (data, step, next) => {
    const normalizedData = normalize(data);

    if (isUpdate && !isBackgroundCreate) {
      const forceRefresh = step === 0;
      await update(normalizedData, step, forceRefresh);
    } else {
      await createByStep(normalizedData, step, next);
    }
  };

  const handleAlternativeSave = async (event, step, next) => {
    if (event) {
      event.stopPropagation();
    }

    const normalizedData = normalize(tabelaSda);

    if (isUpdate) {
      await update(normalizedData);
    } else {
      createByStep(normalizedData, step, next);
    }
  };

  const model = useMemo(() => (
    isCreated || !isCreate
      ? unnormalize(tabelaSda)
      : {}
  ), [isCreate, isCreated, tabelaSda, unnormalize]);

  const actionName = useMemo(() => {
    if (isCreate || isNone) return t('com.muralis.qcx.acoes.nova');
    if (isConsult) return t('com.muralis.qcx.acoes.visualizar');
    return t('com.muralis.qcx.acoes.alterar');
  }, [isNone, isCreate, isConsult]);

  const breadcrumbs = useMemo(() => ([
    {
      link: {
        to: '/',
        name: t('com.muralis.qcx.inicio'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.cadastros'),
      },
    },
    {
      link: {
        to: t('com.muralis.qcx.url.moduloCadastrosGerais'),
        name: t('com.muralis.qcx.gerais'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.servico.labelPlural'),
      },
    },
    {
      link: {
        to: t('com.muralis.qcx.url.modulosGeraisTabelaSDA'),
        name: t('com.muralis.qcx.tabelaSDA.label'),
      },
    },
    {
      text: {
        name: actionName,
      },
    },
  ]), [actionName]);

  const pageTitle = useMemo(() => (
    isNone || isCreate || isBackgroundCreate
      ? t('com.muralis.qcx.tabelaSDA.novaTabelaSDA')
      : t('com.muralis.qcx.tabelaSDA.tabelaSDAExistente', { ID: tabelaSda?.description || tabelaSda?.id || '-' })
  ), [isNone, isCreate, isBackgroundCreate, tabelaSda]);

  const breadcrumbsProps = {
    maxItems: 6,
  };

  return (
    <QCXRegistrationFormPageTemplate
      pageTitle={pageTitle}
      breadcrumbs={breadcrumbs}
      breadcrumbsProps={breadcrumbsProps}
      isCreate={isCreate}
      isConsult={isConsult}
      isUpdate={isUpdate}
      isBackgroundCreate={isBackgroundCreate}
      isPreparingAction={isPreparingAction}
      handleAlternativeSave={handleAlternativeSave}
      handleChangeToPreparingAction={handleChangeToPreparingAction}
      handleChangeToCreate={handleChangeToCreate}
      handleChangeToBackgroundCreate={handleChangeToBackgroundCreate}
      handleChangeToConsult={handleChangeToConsult}
      handleChangeToUpdate={handleChangeToUpdate}
      handleCancelUpdate={handleCancelUpdate}
      handleResetBackgroundMode={handleResetBackgroundMode}
      showSubtitle={false}
      authInfo={authInfo}
    >
      {(formProps) => (
        <QCXTabelaSdaWizardFinalForm
          model={model}
          handleChangeModel={handleDispatchSetModel}
          handleSubmit={handleSubmit}
          authInfo={authInfo}
          requiredRoles={['tabela-sda']}
          {...formProps}
        />
      )}
    </QCXRegistrationFormPageTemplate>
  );
}
