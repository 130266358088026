import { UseMutateFunction } from "react-query";
import { usePostRequest, QueriesTypes } from "../../../../common/hooks/queries";

export const useCreateProposta = (
  finishCallback: () => void
): [
  UseMutateFunction<
    any,
    unknown,
    {
      requestPayload: {
        [key: string]: any;
      };
      queryParams?:
        | {
            clientId?: string | number;
            step?: number;
          }
        | undefined;
    }
  >,
  boolean,
  boolean
] => {
  const { isLoading, isError, mutate } = usePostRequest(
    QueriesTypes.Propostas,
    { onSettledCallback: finishCallback }
  );

  return [mutate, isLoading, isError];
};
