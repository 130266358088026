import { Box, Button, PropTypes } from "@mui/material";
import {
  DataGrid,
  GridRowsProp,
  GridColDef,
  GridToolbar,
  GridCellParams,
} from "@mui/x-data-grid";
import { SvgIconComponent } from "@mui/icons-material";
import { GridRowParams } from "@material-ui/data-grid";

export interface DataTableExtraAction {
  icon: SvgIconComponent;
  onClick: () => void;
  label: string;
  color:
    | "inherit"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning";
}

const localeText = {
  // Root
  noRowsLabel: "Nenhuma linha",
  noResultsOverlayLabel: "Nenhum resultado encontrado.",
  errorOverlayDefaultLabel: "Um erro ocorreu.",

  // Density selector toolbar button text
  toolbarDensity: "Densidade",
  toolbarDensityLabel: "Densidade",
  toolbarDensityCompact: "Compacto",
  toolbarDensityStandard: "Padrão",
  toolbarDensityComfortable: "Confortável",

  // Columns selector toolbar button text
  toolbarColumns: "Colunas",
  toolbarColumnsLabel: "Selecionar colunas",

  // Filters toolbar button text
  toolbarFilters: "Filtros",
  toolbarFiltersLabel: "Mostrar filtros",
  toolbarFiltersTooltipHide: "Esconder filtros",
  toolbarFiltersTooltipShow: "Mostrar filtros",
  toolbarFiltersTooltipActive: (count: number) =>
    count !== 1 ? `${count} filtros ativos` : `${count} filtro ativo`,

  // Quick filter toolbar field
  toolbarQuickFilterPlaceholder: "Buscar…",
  toolbarQuickFilterLabel: "Buscar",
  toolbarQuickFilterDeleteIconLabel: "Limpar busca",

  // Export selector toolbar button text
  toolbarExport: "Exportar",
  toolbarExportLabel: "Exportar",
  toolbarExportCSV: "Baixar como CSV",
  toolbarExportPrint: "Imprimir",
  toolbarExportExcel: "Baixar como Excel",

  // Columns panel text
  columnsPanelTextFieldLabel: "Buscar coluna",
  columnsPanelTextFieldPlaceholder: "Título da coluna",
  columnsPanelDragIconLabel: "Reordenar coluna",
  columnsPanelShowAllButton: "Mostrar tudo",
  columnsPanelHideAllButton: "Esconder tudo",

  // Filter panel text
  filterPanelAddFilter: "Adicionar filtro",
  filterPanelDeleteIconLabel: "Deletar",
  filterPanelLinkOperator: "Operador lógico",
  filterPanelOperators: "Operador", // TODO v6: rename to filterPanelOperator
  filterPanelOperatorAnd: "E",
  filterPanelOperatorOr: "Ou",
  filterPanelColumns: "Colunas",
  filterPanelInputLabel: "Valor",
  filterPanelInputPlaceholder: "Filtrar valor",

  // Filter operators text
  filterOperatorContains: "contem",
  filterOperatorEquals: "é igual a",
  filterOperatorStartsWith: "começa com",
  filterOperatorEndsWith: "termina com",
  filterOperatorIs: "é",
  filterOperatorNot: "não é",
  filterOperatorAfter: "é depois de",
  filterOperatorOnOrAfter: "é em ou depois de",
  filterOperatorBefore: "é antes de",
  filterOperatorOnOrBefore: "é em ou antes de",
  filterOperatorIsEmpty: "está vazio",
  filterOperatorIsNotEmpty: "não está vazio",
  filterOperatorIsAnyOf: "é qualquer um de",

  // Filter values text
  filterValueAny: "qualquer",
  filterValueTrue: "verdadeiro",
  filterValueFalse: "falso",

  // Column menu text
  columnMenuLabel: "Menu",
  columnMenuShowColumns: "Mostrar colunas",
  columnMenuFilter: "Filtrar",
  columnMenuHideColumn: "Esconder",
  columnMenuUnsort: "Desordenar",
  columnMenuSortAsc: "Ordenar crescente",
  columnMenuSortDesc: "Ordenar decrescente",

  // Column header text
  columnHeaderFiltersTooltipActive: (count: number) =>
    count !== 1 ? `${count} filtros ativos` : `${count} filtro ativo`,
  columnHeaderFiltersLabel: "Mostrar filtros",
  columnHeaderSortIconLabel: "Ordenar",

  // Rows selected footer text
  footerRowSelected: (count: number) =>
    count !== 1
      ? `${count.toLocaleString()} linhas selecionadas`
      : `${count.toLocaleString()} linha selecionada`,

  // Total row amount footer text
  footerTotalRows: "Linhas totais:",

  // Total visible row amount footer text
  footerTotalVisibleRows: (visibleCount: number, totalCount: number) =>
    `${visibleCount.toLocaleString()} de ${totalCount.toLocaleString()}`,

  // Checkbox selection text
  checkboxSelectionHeaderName: "Seleção de checkbox",
  checkboxSelectionSelectAllRows: "Selecionar todas as linhas",
  checkboxSelectionUnselectAllRows: "Desmarcar todas as linhas",
  checkboxSelectionSelectRow: "Selecionar linha",
  checkboxSelectionUnselectRow: "Desmarcar linha",

  // Boolean cell text
  booleanCellTrueLabel: "verdadeiro",
  booleanCellFalseLabel: "falso",

  // Actions cell more text
  actionsCellMore: "mais",

  // Column pinning text
  pinToLeft: "Fixar à esquerda",
  pinToRight: "Fixar à direita",
  unpin: "Desafixar",

  // Tree Data
  treeDataGroupingHeaderName: "Grupo",
  treeDataExpand: "Expandir",
  treeDataCollapse: "Recolher",

  // Grouping columns
  groupingColumnHeaderName: "Grupo",
  groupColumn: (name: string) => `Agrupar por ${name}`,
  unGroupColumn: (name: string) => `Parar de agrupar por ${name}`,

  // Master/detail
  detailPanelToggle: "Alternar painel de detalhes",
  expandDetailPanel: "Expandir painel de detalhes",
  collapseDetailPanel: "Recolher painel de detalhes",

  // Used core components translation keys
  MuiTablePagination: {
    labelRowsPerPage: "Linhas por página:",
    labelDisplayedRows: ({
      from,
      to,
      count,
    }: {
      from: number;
      to: number;
      count: number;
    }) => `${from} - ${to} de ${count}`,
  },

  // Row reordering text
  rowReorderingHeaderName: "Reordenar",

  // Aggregation
  aggregationMenuItemHeader: "Agregação",
  aggregationFunctionLabelSum: "Soma",
  aggregationFunctionLabelAvg: "Média",
  aggregationFunctionLabelMin: "Mínimo",
  aggregationFunctionLabelMax: "Máximo",
  aggregationFunctionLabelSize: "Tamanho",
};

const DataTable = ({
  columns,
  rows,
  extraActions,
  onCellDoubleClick,
  onCellClick,
  onRowClick,
}: {
  columns: GridColDef[];
  rows: GridRowsProp;
  extraActions?: DataTableExtraAction[];
  onCellDoubleClick?: (params: GridCellParams) => void;
  onCellClick?: (params: GridCellParams) => void;
  onRowClick?: (params: GridRowParams) => void;
}) => {
  return (
    <Box
      sx={{
        height: "auto",
        overflow: "auto",
        width: "100%",
        "& .header-theme": {
          backgroundColor: "#f5f5f5",
        },
      }}
    >
      {extraActions && (
        <Box
          style={{ marginBottom: "10px", width: "100%", textAlign: "right" }}
        >
          {extraActions.map((action, index) => (
            <Button
              fullWidth
              variant="contained"
              sx={{
                width: "17%",
                marginRight:
                  extraActions.length > 1 && index < extraActions.length - 1
                    ? "10px"
                    : "0px",
              }}
              color={action.color}
              onClick={action.onClick}
              startIcon={<action.icon />}
            >
              {action.label}
            </Button>
          ))}
        </Box>
      )}
      <DataGrid
        onRowClick={onRowClick as any}
        onCellDoubleClick={onCellDoubleClick}
        onCellClick={onCellClick}
        autoHeight={true}
        density="compact"
        components={{
          Toolbar: GridToolbar,
        }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
        columns={columns}
        rows={rows}
        localeText={localeText}
      ></DataGrid>
    </Box>
  );
};

export default DataTable;
