import {
  Grid, Hidden, makeStyles,
} from '@material-ui/core';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import QCXFormTitle from '../form-title/QCXFormTitle';
import QCXFinalCheckboxField from '../../shared-components/final-checkbox-field/QCXFinalCheckboxField';
import QCXFinalNumericDecimalField from '../../shared-components/final-numeric-decimal-field/QCXFinalNumericDecimalField';
import QCXSelectClienteAutocomplete from '../../shared-components/final-select-cliente-field/QCXSelectClienteAutocomplete';
import QCXSelectIncotermCondicaoVendaAutocomplete from '../../shared-components/final-select-incoterm-condicao-venda-field/QCXSelectIncotermCondicaoVendaAutocomplete';
import QCXFormStepWizard from '../../shared-components/form-step-wizard/QCXFormStepWizard';
import QCXSelectPaisAutocomplete from '../../shared-components/select-pais/QCXSelectPaisAutocomplete';
import QCXSelectUnidadeLocalRFBAutocomplete from '../../shared-components/select-unidade-local-rfb/QCXSelectUnidadeLocalRFBAutocomplete';
import { required } from '../../utils/validators/field/validator';
import QCXControlBoxModeloInformacaoComplementar from '../../shared-components/modelo-informacao-complementar/QCXFinalControlBoxModeloInformacaoComplementar';
import { isCalculadaStatus } from '../../utils/general/operations/operationUtils';
import QCXAloneInlineBoxWrapper from '../../shared-components/alone-inline-box-wrapper/QCXAloneInlineBoxWrapper';
import QCXFinalTextField from '../../shared-components/final-text-field/QCXFinalTextField';

const useStyles = makeStyles((theme) => ({
  gridFields: {
    paddingBottom: '16px',
  },
  fakeFields: {
    height: '40px !important',
    paddingBottom: '16px',
  },
  statusLi: {
    [theme.breakpoints.up('md')]: {
      paddingTop: '40px',
      paddingRight: '8px',
    },
    [theme.breakpoints.down('md')]: {
      marginBottom: '0px',
    },
  },
}));

export default function QCXLicencaImportacaoImportadorForm({
  initialValues,
  handleSubmit,
  isConsult,
  isLocked,
  controlComponentProps,
  ...restProps
}) {
  const { t } = useTranslation();

  const classes = useStyles();

  const hasStatus = useCallback((values) => (
    values?.atributosAdicionais.status
  ), []);

  const modeloInformacaoComplementarFieldProps = useMemo(() => (
    {
      rootName: 'atributosAdicionais',
      meta: {
        visible: {
          informacoesComplementaresCompleta: (
            isCalculadaStatus(initialValues?.calculada)
          ),
        },
      },
      modeloInformacaoComplementar: {
        name: 'modeloInformacaoComplementar.id',
        label: t('com.muralis.qcx.importador.label.modeloInformacaoComplementar'),
      },
      informacoesComplementares: {
        name: 'informacoesComplementares',
        label: t('com.muralis.qcx.importador.label.informacoesComplementares'),
        maxLength: 3900,
        rows: 15,
        helperText: t('com.muralis.qcx.mensagem.calculoLI'),
      },
      informacoesComplementaresCompleta: {
        name: 'informacoesComplementaresCompleta',
        label: t('com.muralis.qcx.importador.label.informacoesComplementares'),
        maxLength: 3900,
        rows: 15,
        helperText: t('com.muralis.qcx.mensagem.modeloInformacoesComplementares'),
      },
      referenciaCliente: {
        id: 'checkbox-field-referencia-cliente',
        key: 'checkbox-field-referencia-cliente',
        name: 'atributosAdicionais.referenciaCliente',
        label: t('com.muralis.qcx.referenciaCliente'),
      },
      transportadora: {
        id: 'checkbox-field-transportadora',
        key: 'checkbox-field-transportadora',
        name: 'atributosAdicionais.transportadora',
        label: t('com.muralis.qcx.transportadora.labelPlural'),
      },
      quadroResumoAdicoes: {
        id: 'checkbox-field-quadro-resumo-adicoes',
        key: 'checkbox-field-quadro-resumo-adicoes',
        name: 'atributosAdicionais.quadroResumoAdicoes',
        label: t('com.muralis.qcx.quadroResumoAdicoes'),
      },
      recintoAlfandegado: {
        id: 'checkbox-field-recinto-alfandegado',
        key: 'checkbox-field-recinto-alfandegado',
        name: 'atributosAdicionais.recintoAlfandegado',
        label: t('com.muralis.qcx.recintoAlfandegado'),
      },
      fundamentoLegalIi: {
        id: 'checkbox-field-fundamento-legal-ii',
        key: 'checkbox-field-fundamento-legal-ii',
        name: 'atributosAdicionais.fundamentoLegalIi',
        label: t('com.muralis.qcx.legislacao.fundamentoLegalII'),
      },
      fundamentoLegalIpi: {
        id: 'checkbox-field-fundamento-legal-ipi',
        key: 'checkbox-field-fundamento-legal-ipi',
        name: 'atributosAdicionais.fundamentoLegalIpi',
        label: t('com.muralis.qcx.legislacao.fundamentoLegalIPI'),
      },
      fundamentoLegalIcms: {
        id: 'checkbox-field-fundamento-legal-icms',
        key: 'checkbox-field-fundamento-legal-icms',
        name: 'atributosAdicionais.fundamentoLegalIcms',
        label: t('com.muralis.qcx.legislacao.fundamentoLegalICMS'),
      },
      despachantesAduaneiros: {
        id: 'checkbox-field-despachantes-aduaneiros',
        key: 'checkbox-field-despachantes-aduaneiros',
        name: 'atributosAdicionais.despachantesAduaneiros',
        label: t('com.muralis.qcx.despachante.despachantesAduaneiros'),
      },
    }
  ), [initialValues]);

  return (
    <QCXFormStepWizard
      initialValues={initialValues}
      onSubmit={handleSubmit}
      controlComponentProps={controlComponentProps}
      {...restProps}
    >
      {({ values }) => (
        <>
          <QCXFormTitle title={t('com.muralis.qcx.importador.fichaImportador')} />
          <Grid
            container
            spacing={2}
            style={{ marginTop: '1rem' }}
          >
            {hasStatus(values) && (
              <QCXAloneInlineBoxWrapper>
                {(fieldProps) => (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                  >
                    <QCXFinalTextField
                      id="text-field-status"
                      key="text-field-status"
                      name="atributosAdicionais.status"
                      label={t('com.muralis.qcx.status')}
                      disabled
                      {...fieldProps}
                    />
                  </Grid>
                )}
              </QCXAloneInlineBoxWrapper>
            )}
            <Grid
              item
              container
              xs={12}
              sm={12}
              md={6}
              lg={6}
            >
              <Grid
                item
                className={classes.gridFields}
                xs={12}
                sm={12}
                md={12}
                lg={12}
              >
                <QCXSelectClienteAutocomplete
                  id="select-field-importador"
                  key="select-field-importador"
                  name="importador.id"
                  label={t('com.muralis.qcx.importador.label')}
                  initialValues={values}
                  filter={{
                    importador: true,
                  }}
                  validate={required}
                  disabled
                  required
                />
              </Grid>
              <Grid
                item
                className={classes.gridFields}
                xs={12}
                sm={12}
                md={12}
                lg={12}
              >
                <QCXSelectUnidadeLocalRFBAutocomplete
                  id="select-field-urf-despacho"
                  key="select-field-urf-despacho"
                  name="urfDespacho.id"
                  label={t('com.muralis.qcx.fatura.URFdespacho')}
                  initialValues={values}
                  disabled={isConsult}
                />
              </Grid>
              <Grid
                item
                className={classes.gridFields}
                xs={12}
                sm={12}
                md={12}
                lg={12}
              >
                <QCXFinalNumericDecimalField
                  id="text-number-peso-liquido-field"
                  key="text-number-peso-liquido-field"
                  name="pesoLiquido"
                  label={t('com.muralis.qcx.pesoLiquido')}
                  scale={7}
                  disabled={isConsult}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
              >
                <QCXFinalCheckboxField
                  id="checkbox-ratear-peso-liquido-itens-field"
                  key="checkbox-ratear-peso-liquido-itens-field"
                  name="rateiaPesoLiquidoItem"
                  label={t('com.muralis.qcx.ratearPesoLiquidoItens')}
                  disabled={isConsult}
                />
              </Grid>
            </Grid>
            <Grid
              item
              container
              xs={12}
              sm={12}
              md={6}
              lg={6}
            >
              <Grid
                item
                className={classes.gridFields}
                xs={12}
                sm={12}
                md={12}
                lg={12}
              >
                <QCXSelectPaisAutocomplete
                  id="select-field-pais-procedencia"
                  key="select-field-pais-procedencia"
                  name="paisProcedencia.id"
                  label={t('com.muralis.qcx.paisProcedencia')}
                  initialValues={values}
                  disabled={isConsult}
                />
              </Grid>
              <Grid
                item
                className={classes.gridFields}
                xs={12}
                sm={12}
                md={12}
                lg={12}
              >
                <QCXSelectUnidadeLocalRFBAutocomplete
                  id="select-field-urf-entrada"
                  key="select-field-urf-entrada"
                  name="urfEntrada.id"
                  label={t('com.muralis.qcx.fatura.URFentrada')}
                  initialValues={values}
                  disabled={isConsult}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
              >
                <QCXSelectIncotermCondicaoVendaAutocomplete
                  id="select-field-incoterm"
                  key="select-field-incoterm"
                  name="incotermCondicaoVenda.id"
                  label={t('com.muralis.qcx.incoterm')}
                  initialValues={values}
                  disabled={isConsult}
                />
              </Grid>
              <Hidden
                smDown
              >
                <Grid
                  item
                  className={classes.fakeFields}
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                />
              </Hidden>
            </Grid>
            <QCXControlBoxModeloInformacaoComplementar
              id="control-box-modelo-informacoes-complementares"
              key="control-box-modelo-informacoes-complementares"
              fieldProps={modeloInformacaoComplementarFieldProps}
              disabledFields={isConsult}
              renderMiddleFields={({ fieldProps, disabledFields }) => (
                <>
                  <Grid item xs={12} sm={12} md={4} xl={3}>
                    <QCXFinalCheckboxField
                      {...fieldProps?.referenciaCliente}
                      disabled={disabledFields}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} xl={3}>
                    <QCXFinalCheckboxField
                      {...fieldProps?.transportadora}
                      disabled={disabledFields}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} xl={3}>
                    <QCXFinalCheckboxField
                      {...fieldProps?.quadroResumoAdicoes}
                      disabled={disabledFields}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} xl={3}>
                    <QCXFinalCheckboxField
                      {...fieldProps?.recintoAlfandegado}
                      disabled={disabledFields}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} xl={3}>
                    <QCXFinalCheckboxField
                      {...fieldProps?.fundamentoLegalIi}
                      disabled={disabledFields}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} xl={3}>
                    <QCXFinalCheckboxField
                      {...fieldProps?.fundamentoLegalIpi}
                      disabled={disabledFields}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} xl={3}>
                    <QCXFinalCheckboxField
                      {...fieldProps?.fundamentoLegalIcms}
                      disabled={disabledFields}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} xl={3}>
                    <QCXFinalCheckboxField
                      {...fieldProps?.despachantesAduaneiros}
                      disabled={disabledFields}
                    />
                  </Grid>
                </>
              )}
            />
          </Grid>
        </>
      )}
    </QCXFormStepWizard>
  );
}
