import { Grid } from '@material-ui/core';
import React, { useCallback, useMemo } from 'react';
import { v4 as uuid } from 'uuid';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import QCXFinalBondManagerOld from '../../shared-components/final-gerenciador-relacionamento/QCXFinalBondManagerOld';
import QCXFinalTextField from '../../shared-components/final-text-field/QCXFinalTextField';
import { vinculoLPCODUEActions, vinculoLPCODUESelectors } from '../../features/vinculo-lpco-due/vinculoLPCODUESlice';
import { isStrictEquals } from '../../utils/general/general-utils';
import QCXFormSubtitle from '../form-title/QCXFormSubtitle';

export default function QCXLPCOFormGroup({ isParentConsult, disabled, title = i18n.t('com.muralis.qcx.LPCO') }) {
  const { t } = useTranslation();

  const lpcoListProps = useMemo(
    () => ({
      name: 'due.lpcos',
      columns: [
        {
          field: 'lpco',
          headerName: t('com.muralis.qcx.LPCO'),
          headerAlign: 'left',
          align: 'left',
          flex: 100,
          valueGetter: ({ row }) => row?.lpco || '-',
        },
      ],
    }),
    []
  );

  const lpcoFormProps = useMemo(
    () => ({
      rootName: 'ignorableFields.lpcoDue',
      fields: [
        {
          name: 'lpco',
          label: t('com.muralis.qcx.LPCO'),
        },
      ],
    }),
    []
  );

  const lpcoReducerConfig = useMemo(
    () => ({
      selectors: {
        selectStatus: vinculoLPCODUESelectors.selectStatus,
        selectMode: vinculoLPCODUESelectors.selectMode,
        selectModel: vinculoLPCODUESelectors.selectModel,
      },
      actions: {
        loading: vinculoLPCODUEActions.loading,
        resetStatus: vinculoLPCODUEActions.resetStatus,
        changeToUpdateMode: vinculoLPCODUEActions.changeToUpdateMode,
        changeToCreateMode: vinculoLPCODUEActions.changeToCreateMode,
        resetMode: vinculoLPCODUEActions.resetMode,
        setModel: vinculoLPCODUEActions.setModel,
        resetModel: vinculoLPCODUEActions.resetModel,
      },
    }),
    []
  );

  const handleAddLPCO = useCallback(async (currentVinculo, currentVinculos, handleSuccessAddVinculo) => {
    const vinculo = {
      id: uuid(),
      lpco: currentVinculo?.lpco,
    };

    const updatedVinculos = [...currentVinculos, vinculo];

    handleSuccessAddVinculo(updatedVinculos);
  }, []);

  const handleUpdateLPCO = useCallback(async (currentVinculo, currentVinculos, handleSuccessUpdateVinculo) => {
    const updatedVinculo = {
      ...currentVinculo,
    };

    const updatedVinculos =
      currentVinculos?.map((existingVinculo) =>
        isStrictEquals(existingVinculo?.id, updatedVinculo?.id) ? updatedVinculo : existingVinculo
      ) || currentVinculos;

    handleSuccessUpdateVinculo(updatedVinculos);
  }, []);

  const handleLPCOAlreadyExists = useCallback(
    (currentVinculo, currentVinculosList) =>
      currentVinculosList.some((vinculo) => isStrictEquals(vinculo?.lpco, currentVinculo?.lpco)),
    []
  );

  return (
    <>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <QCXFormSubtitle
          title={title}
          boxProps={{
            pt: 1,
            pb: 1,
          }}
        />
      </Grid>
      <QCXFinalBondManagerOld
        isParentConsult={isParentConsult}
        listProps={lpcoListProps}
        formProps={lpcoFormProps}
        reducerConfig={lpcoReducerConfig}
        handleAdd={handleAddLPCO}
        handleUpdate={handleUpdateLPCO}
        handleAlreadyExists={handleLPCOAlreadyExists}
      >
        {({ loading }) => (
          <>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <QCXFinalTextField
                id="text-field-lpco"
                key="text-field-lpco"
                name="ignorableFields.lpcoDue.lpco"
                label={t('com.muralis.qcx.LPCO')}
                disabled={disabled || loading}
              />
            </Grid>
          </>
        )}
      </QCXFinalBondManagerOld>
    </>
  );
}
