import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createFilterOptions } from '@material-ui/lab';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import QCXAutocompleteSelect from '../../../../../../shared-components/autocomplete-select/QCXAutocompleteSelect';
import { fetchAllAsync, selectDesvios } from '../../../../registrations/general/desvios/api/desvioSlice';

function DesviosAutoComplete({
  initialValues,
  name,
  ...restProps
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();


  const [defaultValue, setDefaultValue] = useState('');

  useEffect(() => {
    dispatch(fetchAllAsync());
  }, []);

  const desvios = useSelector(selectDesvios);

  const activatedDesvios = useMemo(() => desvios.filter((d) => d.active === true), [desvios]);

  const getOptionLabel = useCallback(
    (item) => item.description,
    []
  );

  const getOptionValue = useCallback(
    (item) => item,
    []
  );

  useEffect(() => {
    const value = activatedDesvios.find((item) => item?.id === _.get(initialValues, name));
    if (value) {
      setDefaultValue(value);
    } else {
      setDefaultValue({ id: 0, description: '' });
    }
  }, [activatedDesvios, initialValues]);

  const filterOptions = createFilterOptions({
    stringify: (item) => `${item.value}`,
  });
  
  return (
    <QCXAutocompleteSelect
      label={t('com.muralis.qcx.listaFollowUp.desvios')}
      id={name}
      name={name}
      options={activatedDesvios}
      defaultValue={defaultValue}
      filterOptions={filterOptions}
      getOptionValue={getOptionValue}
      getOptionLabel={getOptionLabel}
      {...restProps}
    />
  );
}

export default DesviosAutoComplete;
