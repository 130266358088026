import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function QCXCircularProgress({
  color = 'inherit',
  ...restProps
}) {
  return (
    <CircularProgress
      color={color}
      {...restProps}
    />
  );
}
