import { getOptionByValueInList } from '../../utils/general/general-utils';
import i18n from '../../i18n';

const TIPO_MASCARA_FOLLOW_UP_CODIGO_VIA_TRANSPORTE_SIGLA_UNIDADE_SEQUENCIAL_ANO = 'CODIGO_VIA_TRANSPORTE_SIGLA_UNIDADE_SEQUENCIAL_ANO';
const TIPO_MASCARA_FOLLOW_UP_SIGLA_SERVICO_TRACO_SEQUENCIAL_TRACO_ANO = 'SIGLA_SERVICO_TRACO_SEQUENCIAL_TRACO_ANO';
const TIPO_MASCARA_FOLLOW_UP_SEQUENCIAL_TRACO_ANO = 'SEQUENCIAL_TRACO_ANO';
const TIPO_MASCARA_FOLLOW_UP_SIGLA_SERVICO_TRACO_SEQUENCIAL_BARRA_ANO = 'SIGLA_SERVICO_TRACO_SEQUENCIAL_BARRA_ANO';
const TIPO_MASCARA_FOLLOW_UP_PRODUTO_SIGLA_UNIDADE_SIGLA_SERVICO_TRACO_SEQUENCIAL_BARRA_ANO = 'PRODUTO_SIGLA_UNIDADE_SIGLA_SERVICO_TRACO_SEQUENCIAL_BARRA_ANO';
const TIPO_MASCARA_FOLLOW_UP_MANUALMENTE = 'MANUALMENTE';

const TIPO_MASCARA_FOLLOW_UP_LIST = [
  {
    label: i18n.t('com.muralis.qcx.codigoViaTransporteSiglaUnidadeSequencialAno'),
    value: TIPO_MASCARA_FOLLOW_UP_CODIGO_VIA_TRANSPORTE_SIGLA_UNIDADE_SEQUENCIAL_ANO,
  },
  {
    label: i18n.t('com.muralis.qcx.siglaServicoSequencialAno'),
    value: TIPO_MASCARA_FOLLOW_UP_SIGLA_SERVICO_TRACO_SEQUENCIAL_TRACO_ANO,
  },
  {
    label: i18n.t('com.muralis.qcx.sequencialAno'),
    value: TIPO_MASCARA_FOLLOW_UP_SEQUENCIAL_TRACO_ANO,
  },
  {
    label: i18n.t('com.muralis.qcx.siglaServicoSequencialAnoTracoBarra'),
    value: TIPO_MASCARA_FOLLOW_UP_SIGLA_SERVICO_TRACO_SEQUENCIAL_BARRA_ANO,
  },
  {
    label: i18n.t('com.muralis.qcx.produtoSiglaUnidadeNegocioSiglaServicoSequencialAno'),
    value: TIPO_MASCARA_FOLLOW_UP_PRODUTO_SIGLA_UNIDADE_SIGLA_SERVICO_TRACO_SEQUENCIAL_BARRA_ANO,
  },
  {
    label: i18n.t('com.muralis.qcx.manualFormatoLivre'),
    value: TIPO_MASCARA_FOLLOW_UP_MANUALMENTE,
  },
];

const isTipoMascaraCodigoViaTransporteSiglaUnidadeSequencialAno = (value) => (
  TIPO_MASCARA_FOLLOW_UP_CODIGO_VIA_TRANSPORTE_SIGLA_UNIDADE_SEQUENCIAL_ANO === value
);

const isTipoMascaraSiglaServicoTracoSequencialTracoAno = (value) => (
  TIPO_MASCARA_FOLLOW_UP_SIGLA_SERVICO_TRACO_SEQUENCIAL_TRACO_ANO === value
);

const isTipoMascaraSequencialTracoAno = (value) => (
  TIPO_MASCARA_FOLLOW_UP_SEQUENCIAL_TRACO_ANO === value
);

const isTipoMascaraSiglaServicoTracoSequencialBarraAno = (value) => (
  TIPO_MASCARA_FOLLOW_UP_SIGLA_SERVICO_TRACO_SEQUENCIAL_BARRA_ANO === value
);

const isTipoMascaraProdutoSiglaUnidadeSiglaServicoTracoSequencialBarraAno = (value) => (
  TIPO_MASCARA_FOLLOW_UP_PRODUTO_SIGLA_UNIDADE_SIGLA_SERVICO_TRACO_SEQUENCIAL_BARRA_ANO === value
);

const isTipoMascaraManual = (value) => (
  TIPO_MASCARA_FOLLOW_UP_MANUALMENTE === value
);

const getTipoMascaraFollowUpLabelByValue = (value) => {
  const foundOption = getOptionByValueInList(TIPO_MASCARA_FOLLOW_UP_LIST, value);
  return foundOption && foundOption?.label;
};

const TipoMascaraFollowUpUtils = {
  LIST: TIPO_MASCARA_FOLLOW_UP_LIST,
  CODIGO_VIA_TRANSPORTE_SIGLA_UNIDADE_SEQUENCIAL_ANO: (
    TIPO_MASCARA_FOLLOW_UP_CODIGO_VIA_TRANSPORTE_SIGLA_UNIDADE_SEQUENCIAL_ANO
  ),
  SIGLA_SERVICO_TRACO_SEQUENCIAL_TRACO_ANO: (
    TIPO_MASCARA_FOLLOW_UP_SIGLA_SERVICO_TRACO_SEQUENCIAL_TRACO_ANO
  ),
  SEQUENCIAL_TRACO_ANO: (
    TIPO_MASCARA_FOLLOW_UP_SEQUENCIAL_TRACO_ANO
  ),
  SIGLA_SERVICO_TRACO_SEQUENCIAL_BARRA_ANO: (
    TIPO_MASCARA_FOLLOW_UP_SIGLA_SERVICO_TRACO_SEQUENCIAL_BARRA_ANO
  ),
  SIGLA_UNIDADE_SIGLA_SERVICO_TRACO_SEQUENCIAL_BARRA_ANO: (
    TIPO_MASCARA_FOLLOW_UP_PRODUTO_SIGLA_UNIDADE_SIGLA_SERVICO_TRACO_SEQUENCIAL_BARRA_ANO
  ),
  MANUALMENTE: (
    TIPO_MASCARA_FOLLOW_UP_MANUALMENTE
  ),
  isCodigoViaTransporteSiglaUnidadeSequencialAno: (
    isTipoMascaraCodigoViaTransporteSiglaUnidadeSequencialAno
  ),
  isSiglaServicoTracoSequencialTracoAno: (
    isTipoMascaraSiglaServicoTracoSequencialTracoAno
  ),
  isSequencialTracoAno: (
    isTipoMascaraSequencialTracoAno
  ),
  isSiglaServicoTracoSequencialBarraAno: (
    isTipoMascaraSiglaServicoTracoSequencialBarraAno
  ),
  isProdutoSiglaUnidadeSiglaServicoTracoSequencialBarraAno: (
    isTipoMascaraProdutoSiglaUnidadeSiglaServicoTracoSequencialBarraAno
  ),
  isManual: (
    isTipoMascaraManual
  ),
  getLabelByValue: (
    getTipoMascaraFollowUpLabelByValue
  ),
};

export {
  TIPO_MASCARA_FOLLOW_UP_LIST,
  TIPO_MASCARA_FOLLOW_UP_CODIGO_VIA_TRANSPORTE_SIGLA_UNIDADE_SEQUENCIAL_ANO,
  TIPO_MASCARA_FOLLOW_UP_SIGLA_SERVICO_TRACO_SEQUENCIAL_TRACO_ANO,
  TIPO_MASCARA_FOLLOW_UP_SEQUENCIAL_TRACO_ANO,
  TIPO_MASCARA_FOLLOW_UP_SIGLA_SERVICO_TRACO_SEQUENCIAL_BARRA_ANO,
  TIPO_MASCARA_FOLLOW_UP_PRODUTO_SIGLA_UNIDADE_SIGLA_SERVICO_TRACO_SEQUENCIAL_BARRA_ANO,
  TIPO_MASCARA_FOLLOW_UP_MANUALMENTE,
};

export {
  isTipoMascaraCodigoViaTransporteSiglaUnidadeSequencialAno,
  isTipoMascaraSiglaServicoTracoSequencialTracoAno,
  isTipoMascaraSequencialTracoAno,
  isTipoMascaraSiglaServicoTracoSequencialBarraAno,
  isTipoMascaraProdutoSiglaUnidadeSiglaServicoTracoSequencialBarraAno,
  isTipoMascaraManual,
  getTipoMascaraFollowUpLabelByValue,
};

export default TipoMascaraFollowUpUtils;
