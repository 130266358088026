import {
  Checkbox,
  Grid,
  Typography,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import QCXBox from '../../shared-components/box/QCXBox';
import QCXDataGridControl from '../../shared-components/data-grid-control/QCXDataGridControl';
// https://stackoverflow.com/questions/64104554/can-i-initialize-the-checkbox-selection-in-a-material-ui-datagrid
// https://stackoverflow.com/questions/66424752/get-row-item-on-checkbox-selection-in-react-material-ui-datagrid
// https://stackoverflow.com/questions/66828464/material-ui-disable-multiple-row-selection-in-datagrid
import i18n from '../../i18n';

function QCXSimpleSelectOnePageTemplate({
  title = i18n.t('com.muralis.qcx.selecioneRegistro'),
  tableColumns,
  tableData,
  isConsult,
  filterProp = 'code',
  isLoading,
  handleSetSelected,
  selected = null,
  multipleFilter = false,
  filterProps = [],
  ...restProps
}) {
  const [localData, setLocalData] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);
  const [lastSelectedRow, setLastSelectedRow] = useState(null);

  useEffect(() => {
    setLocalData(tableData);
  }, [tableData]);

  useEffect(() => {
    if (selected?.id) {
      setSelectionModel([selected?.id]);
    }
  }, [selected]);

  const handleOnRowSelect = (item) => {
    setLastSelectedRow(item);
    if (item?.isSelected) {
      handleSetSelected(item?.data);
    } else {
      handleSetSelected(null);
    }
  };

  const handleOnModelChange = () => {
    if (lastSelectedRow?.isSelected === false) {
      setSelectionModel([]);
    } else {
      setSelectionModel([selected?.id]);
    }
  };

  const customCheckbox = (props) => (
    <Checkbox
      {...props}
      disabled={isConsult}
    />
  );

  return (
    <>
      <Typography variant="h6" color="primary">
        <QCXBox fontWeight="fontWeightBold" pt={3}>
          {title.toUpperCase()}
        </QCXBox>
      </Typography>
      <Grid container direction="row" style={{ marginTop: '0.7rem' }}>
        <QCXDataGridControl
          columns={tableColumns}
          rows={isConsult && selected ? [].concat([selected]) : localData}
          filterProp={filterProp}
          checkboxSelection
          disableSelectionOnClick
          onRowSelected={handleOnRowSelect}
          disableColumnSelector
          onSelectionModelChange={handleOnModelChange}
          selectionModel={selectionModel}
          loading={isLoading}
          components={{ Checkbox: customCheckbox }}
          multipleFilter={multipleFilter}
          filterProps={filterProps}
          {...restProps}
        />
      </Grid>
    </>
  );
}

export default QCXSimpleSelectOnePageTemplate;
