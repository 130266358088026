import React, {
  useMemo,
} from 'react';
import { Form } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import createDecorator from 'final-form-focus';
import { isFunction } from 'lodash';
import QCXForm from '../form/QCXForm';

export default function QCXFinalFormBase({
  initialValues = {},
  onSubmit,
  validate,
  children,
  onChangeListeners = [],
  disableFocusOnErrors = false,
  debugOn = false,
  ...restProps
}) {
  const focusOnErrors = createDecorator();

  const decorators = useMemo(() => (
    [
      ...(disableFocusOnErrors ? [] : [focusOnErrors]),
    ]
  ), [
    focusOnErrors,
  ]);

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      decorators={decorators}
      validate={validate}
      {...restProps}
    >
      {({
        form,
        values,
        handleSubmit,
        pristine,
        submitting,
        submitSucceeded,
        ...restFinalFormProps
      }) => (

        <QCXForm
          onSubmit={handleSubmit}
          debugOn={debugOn}
        >
          {onChangeListeners.map((item) => (
            <OnChange name={item.name}>
              {item.fn(form, values)}
            </OnChange>
          ))}
          {(isFunction(children)
            ? children({
              form,
              values,
              pristine,
              submitSucceeded,
              submitting,
              ...restFinalFormProps,
            }) : children
          )}
        </QCXForm>
      )}
    </Form>
  );
}
