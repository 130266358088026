import { createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';
import { MediaType } from '../../utils/api/api-utils';
import {
  activateById,
  fetchAll,
  fetchById,
  inactivateById,
  register,
  save,
  send,
  baixarXmlNfe,
  baixarPdfDanfe,
  fetchByFilter,
} from './danfeAPI';

function stringToDate(date) {
  const m = moment(date, 'YYYY/MM/DD hh:mm:ss');
  const d = m.toDate();
  return d;
}

const fetchAllAsync = createAsyncThunk('danfe/fetchAll', async () => {
  const response = await fetchAll();
  const list = response.data;
  if (Array.isArray(list)) {
    // Coloca as danfes mais recentes no início.
    list.sort((a, b) => stringToDate(b.insertionDate) - stringToDate(a.insertionDate));
  }
  return {
    response: {
      status: response.status,
      data: list ?? response.data,
    },
  };
});

const fetchByIdAsync = createAsyncThunk('danfe/fetchById', async (id) => {
  const response = await fetchById(id);
  return {
    response: {
      status: response.status,
      data: response.data,
    },
  };
});

const registerAsync = createAsyncThunk('danfe/register', async (data, queryParams) => {
  const response = await register(data, queryParams);
  return {
    response: {
      status: response.status,
      data: response.data,
    },
  };
});

const saveAsync = createAsyncThunk('danfe/save', async (data, queryParams) => {
  const response = await save(data, queryParams);
  return {
    response: {
      status: response.status,
      data: response.data,
    },
  };
});

const activateByIdAsync = createAsyncThunk('danfe/activateById', async (id) => {
  const { status, data } = await activateById(id);
  return { response: { status, data } };
});

const inactivateByIdAsync = createAsyncThunk(
  'danfe/inactivateById',
  async (id) => {
    const { status, data } = await inactivateById(id);
    return { response: { status, data } };
  }
);

const sendDanfeAsync = createAsyncThunk(
  'danfe/sendDanfeAsync',
  async (id) => {
    try {
      const { status, data } = await send(id);
      return { response: { status, data } };
    } catch (e) {
      const { response } = e;
      if (response?.data?.message) {
        throw Error(response.data.message);
      } else {
        throw e;
      }
    }
  }
);

const baixarXmlAsync = createAsyncThunk(
  'danfe/baixarXmlAsync',
  async (id) => {
    try {
      // Infelizmente, o axios não baixa o arquivo automaticamente,
      // embora os headers estejam corretos.
      const { data } = await baixarXmlNfe(id);

      const xmlData = new Blob([data], { type: MediaType.APPLICATION_XML });

      // Esperamos que data seja um Blob, pois a config da request especifica que é.
      const url = window.URL.createObjectURL(xmlData);
      window.open(url); // <- Deve abrir um prompt para salvar.
    } catch (e) {
      const { response } = e;

      if (response?.data && response?.data instanceof Blob) {
        const blob = response?.data;

        const parsedData = await new Promise((resolve) => {
          const fr = new FileReader();

          fr.onload = ({ target }) => (
            resolve(JSON.parse(target?.result))
          );

          fr.readAsText(blob);
        });

        throw Error(parsedData?.message);
      } else {
        throw e;
      }
    }
  }
);

const printDanfeAsync = createAsyncThunk(
  'danfe/printDanfeAsync',
  async (id) => {
    try {
      // Infelizmente, o axios não baixa o arquivo automaticamente,
      // embora os headers estejam corretos.
      const { data } = await baixarPdfDanfe(id);

      const xmlData = new Blob([data], { type: MediaType.APPLICATION_PDF });

      // Esperamos que data seja um Blob, pois a config da request especifica que é.
      const url = window.URL.createObjectURL(xmlData);
      window.open(url); // <- Deve abrir um prompt para salvar.
    } catch (e) {
      const { response } = e;

      if (response?.data && response?.data instanceof Blob) {
        const blob = response?.data;

        const parsedData = await new Promise((resolve) => {
          const fr = new FileReader();

          fr.onload = ({ target }) => (
            resolve(JSON.parse(target?.result))
          );

          fr.readAsText(blob);
        });

        throw Error(parsedData?.message);
      } else {
        throw e;
      }
    }
  }
);

const fetchByFilterAsync = createAsyncThunk(
  'danfe/fetchByFilter',
  async (params) => {
    const { data } = await fetchByFilter(params);
    return { data };
  }
);

export {
  fetchAllAsync,
  fetchByIdAsync,
  registerAsync,
  saveAsync,
  activateByIdAsync,
  inactivateByIdAsync,
  sendDanfeAsync,
  baixarXmlAsync,
  printDanfeAsync,
  fetchByFilterAsync,
};
