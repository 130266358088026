import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import {
  Alert,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Snackbar,
} from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { ChangeEvent, FormEvent, useMemo, useState } from "react";
import DataTable from "../../../../common/components/dataTable";
import FileDragAndDrop from "../../../../common/components/fileDragAndDrop";
import FormHeader from "../../../../common/components/forms/formHeader";
import FormHeaderMedium from "../../../../common/components/forms/formHeaderMedium";
import { useUnidadeNegocioGuard } from "../../../../common/hooks/useUnidadeNegocioGuard";
import PageLayout from "../../../../common/layouts/pageLayout";
import {
  useCreateFaturaTotvs,
  useListAllCorrespondencias,
} from "../totvs.hooks";
import TotvsLeituraPageError from "./components/totvsLeituraPageError";
import TotvsLeituraPageResult, {
  TotvsResponse,
} from "./components/totvusLeituraPageResult";
import { useVerifyCorrelacaoImportador } from "./totvsLeituraPage.hooks";
import { TXTResult, processTXTFile } from "./utils/processTXTFile";

const TotvsLeituraPage = () => {
  useUnidadeNegocioGuard();
  const [file, setFile] = useState<File>();
  const [txtData, setTxtData] = useState<TXTResult>();
  const [response, setResponse] = useState<TotvsResponse>();
  const [correspondencias, isCorrespondenciasLoading, isCorrespondenciasError] =
    useListAllCorrespondencias();
  const [leituraErrors, leituraWarnings, processedResult] =
    useVerifyCorrelacaoImportador(txtData, correspondencias);
  const codigoImportador = useMemo(
    () =>
      processedResult?.faturas?.[Object.keys(processedResult?.faturas ?? {})[0]]
        .codigoImportador,
    [processedResult]
  );
  const [createFaturaTotvs, isCreating, hasErrorCreating] =
    useCreateFaturaTotvs();
  const [open, setOpen] = useState(false);
  const [faturas, setFaturas] = useState<string>();

  const handleFileChange = (
    event: ChangeEvent<HTMLInputElement>,
    file: File
  ) => {
    setFile(file);
  };

  // Simple

  const processFile = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      if (file) {
        const reader = new FileReader();
        reader.onload = (event) => {
          const text = event.target?.result;
          const result = processTXTFile(text as string);
          setTxtData(result);
        };
        reader.readAsText(file);
      }
      setResponse(undefined);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async () => {
    if (processedResult && codigoImportador) {
      createFaturaTotvs({
        requestPayload: processedResult,
        queryParams: { clientId: codigoImportador },
      });
      setFaturas(Object.keys(processedResult.faturas ?? {}).toString());
      setOpen(true);
      setTxtData(undefined);
      setResponse(undefined);
      setFile(undefined);
    }
  };

  const faturasColumns = useMemo(() => {
    return [
      { field: "numeroDaFatura", headerName: "N° da Fatura", flex: 1 },
      { field: "codigoImportador", headerName: "CNPJ Importador", flex: 1 },
      { field: "codigoExportador", headerName: "Cód. Exportador", flex: 1 },
      { field: "codigoFabricante", headerName: "Cód. Fabricante", flex: 1 },
      {
        field: "codigoTransportadorInternacional",
        headerName: "Cód. Transportador Internacional",
        flex: 1,
      },
      {
        field: "codigoLocalEmbarque",
        headerName: "Cód. Local Embarque",
        flex: 1,
      },
      { field: "codigoAgenteCarga", headerName: "Cód. Agente Carga", flex: 1 },
      { field: "numeroItens", headerName: "N° de Itens", flex: 1 },
    ] as GridColDef[];
  }, []);

  const faturasData = useMemo(() => {
    return Object.keys(txtData?.faturas ?? {}).map((key, index) => {
      if (txtData?.faturas) {
        return {
          id: index,
          numeroDaFatura: key,
          codigoImportador: txtData.itdi?.cnpj,
          // codigoImportador: txtData.faturas[key].codigoImportador,
          codigoExportador: txtData.faturas[key].codigoExportador,
          codigoFabricante: txtData.faturas[key].codigoFabricante,
          codigoTransportadorInternacional:
            txtData.faturas[key].codigoTransportadorInternacional,
          numeroItens: txtData?.faturas[key].numeroItens ?? 0,
          codigoLocalEmbarque: txtData.faturas[key].codigoLocalEmbarque,
          codigoAgenteCarga: txtData.faturas[key].codigoAgenteCarga,
        };
      } else {
        return {};
      }
    });
  }, [txtData]);

  function handleClose(event: any): void {
    setOpen(false);
  }

  return (
    <PageLayout title={"Leitura"} icon={<HistoryEduIcon color={"secondary"} />}>
      <>
        <form action="" onSubmit={processFile}>
          <Grid container spacing={2}>
            <FormHeader>Documentos a serem criados / editados</FormHeader>
            <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox checked disabled color="secondary" />}
                label="Fatura (PO)"
              />
              <FormControlLabel
                control={<Checkbox checked disabled color="secondary" />}
                label="Catálogo de Produtos"
              />
            </Grid>
            <FormHeaderMedium>Seleção de arquivo</FormHeaderMedium>
            <FileDragAndDrop
              accept={".txt"}
              onChangeAction={handleFileChange}
            ></FileDragAndDrop>
            <Grid item xs={12} textAlign="right">
              <Button type="submit" color="secondary" variant="contained">
                Importar arquivo
              </Button>
            </Grid>
            {response && <TotvsLeituraPageResult response={response} />}
          </Grid>
        </form>
        {txtData && (
          <Grid container spacing={2}>
            <FormHeader>Revisão dos dados</FormHeader>
            <FormHeaderMedium>Faturas</FormHeaderMedium>
            <Grid item xs={12}>
              <DataTable
                columns={faturasColumns}
                rows={faturasData}
              ></DataTable>
            </Grid>
            {(leituraErrors.length > 0 || leituraWarnings.length > 0) && (
              <TotvsLeituraPageError
                errors={leituraErrors}
                warnings={leituraWarnings}
              />
            )}
          </Grid>
        )}
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} textAlign="right">
            <Button
              color="secondary"
              variant="contained"
              style={{ margin: "0 10px 0 0" }}
              onClick={() => {
                setTxtData(undefined);
                setFile(undefined);
              }}
            >
              Cancelar
            </Button>
            <Button
              disabled={
                leituraErrors.length > 0 ||
                Object.keys(processedResult ?? {}).length === 0
              }
              color="secondary"
              variant="contained"
              onClick={handleSubmit}
            >
              Salvar
            </Button>
          </Grid>
        </Grid>
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert
            onClose={handleClose}
            severity={hasErrorCreating ? "error" : "success"}
            sx={{ width: "100%" }}
          >
            {hasErrorCreating
              ? "Erro ao criar fatura!"
              : `Faturas ${faturas} criadas!`}
          </Alert>
        </Snackbar>
      </>
    </PageLayout>
  );
};

export default TotvsLeituraPage;
