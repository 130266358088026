import React, { useCallback, useMemo } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { v4 as uuid } from 'uuid';
import { useDispatch } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import { isArrayLikeObject, isUndefined } from 'lodash';
import QCXAloneInlineBoxWrapper from '../../shared-components/alone-inline-box-wrapper/QCXAloneInlineBoxWrapper';
import QCXFinalForm from '../../shared-components/final-form/QCXFinalForm';
import QCXFinalBondManagerOld from '../../shared-components/final-gerenciador-relacionamento/QCXFinalBondManagerOld';
import QCXSelectClienteAutocomplete from '../../shared-components/final-select-cliente-field/QCXSelectClienteAutocomplete';
import QCXFinalTextField from '../../shared-components/final-text-field/QCXFinalTextField';
import QCXSelectServicoAutocomplete from '../../shared-components/select-servico/QCXSelectServicoAutocomplete';
import { isStrictEquals } from '../../utils/general/general-utils';
import {
  required,
  validateNumeroConhecimentoMasterAereo,
  validateNumeroConhecimentoMasterAereoExpo,
} from '../../utils/validators/field/validator';
import QCXMoveFocusInside from '../focus-lock/QCXMoveFocusInside';
import QCXFormSubtitle from '../form-title/QCXFormSubtitle';
import {
  vinculoReferenciaClienteFollowUpActions,
  vinculoReferenciaClienteFollowUpSelectors,
} from '../../features/vinculo-referencia-cliente-follow-up/vinculoReferenciaClienteFollowUpSlice';
import { clienteAPI } from '../../features/cliente/clienteAPI';
import { servicoActions } from '../../features/servico/servicoSlice';
import { servicoAPI } from '../../features/servico/servicoAPI';
import {
  VIA_TRANSPORTE_MARITIMA_CODE,
  VIA_TRANSPORTE_FLUVIAL_CODE,
  VIA_TRANSPORTE_LACUSTRE_CODE,
  isSomeViaTransporteByCode,
  isViaTransporteAereaByCode,
} from '../../shared-components/select-via-transporte/viaTransporteUtils';
import {
  formatNumeroConhecimentoMaster,
  formatNumeroConhecimentoMaster12,
} from '../../utils/hooks/form/field/formatters';
import { parseNumeroConhecimentoMaster, parseToAlphanumericOnly } from '../../utils/hooks/form/field/parsers';
import QCXFinalCheckboxField from '../../shared-components/final-checkbox-field/QCXFinalCheckboxField';
import QCXInfoAlert from '../../shared-components/alert/QCXInfoAlert';
import AfrmmUtils from '../../utils/general/afrmm/AfrmmUtils';
import useCurrentUnidadeNegocio from '../../utils/hooks/user/useCurrentUnidadeNegocio';
import TipoMascaraFollowUpUtils from '../../shared-components/select-tipo-mascara-follow-up/tipoMascaraFollowUpUtils';
import { TipoProdutoUtils } from '../../shared-components/select-tipo-produto/tipoProdutoUtils';
import QCXFinalDropzoneField from '../../shared-components/dropzone/QCXFinalDropzoneField';
import QCXSelectTransportadoraAutocomplete from '../../shared-components/select-transportadora/QCXSelectTransportadoraAutocomplete';
import { parseToNumber } from '../../utils/general/parse-utils';
import {
  vinculoAnexoFaturaFollowUpActions,
  vinculoAnexoFaturaFollowUpSelectors,
} from '../../features/vinculo-anexo-fatura-follow-up/vinculoAnexoFaturaFollowUpSlice';

const useStyles = makeStyles(() => ({
  gridFields: {
    paddingBottom: '16px',
  },
}));

export default function QCXFollowUpExportacaoFinalForm({
  isCreate,
  isConsult,
  isUpdate,
  initialValues,
  handleSubmit,
  handleChangeToUpdate,
  handleCancelUpdate,
  authInfo = {},
  requiredRoles = [],
  ...restProps
}) {
  const { t } = useTranslation();

  const classes = useStyles();

  const dispatch = useDispatch();

  const currentUnidadeNegocio = useCurrentUnidadeNegocio();

  const isTipoMascaraFollowUpManual = useMemo(() => {
    if (isCreate) {
      return TipoMascaraFollowUpUtils.isManual(currentUnidadeNegocio?.tipoMascaraFollowUp);
    }

    const tipoMascaraFollowUp = initialValues?.followUpSequencial?.tipoMascaraFollowUp;

    if ((isConsult || isUpdate) && tipoMascaraFollowUp) {
      return TipoMascaraFollowUpUtils.isManual(tipoMascaraFollowUp);
    }

    return true;
  }, [isCreate, isUpdate, isConsult, initialValues, currentUnidadeNegocio]);

  const getViaTransporteCodeFrom = useCallback((values) => values?.viaTransporte?.code, []);

  const isViaTransporteAereoBy = useCallback(
    (values) => isViaTransporteAereaByCode(getViaTransporteCodeFrom(values)),
    [getViaTransporteCodeFrom]
  );

  const isRequiredAfrmmBy = useCallback(
    (values) =>
      isSomeViaTransporteByCode(getViaTransporteCodeFrom(values), [
        VIA_TRANSPORTE_MARITIMA_CODE,
        VIA_TRANSPORTE_FLUVIAL_CODE,
        VIA_TRANSPORTE_LACUSTRE_CODE,
      ]),
    [getViaTransporteCodeFrom]
  );

  const referenciaClienteReducerConfig = useMemo(
    () => ({
      selectors: {
        selectStatus: vinculoReferenciaClienteFollowUpSelectors.selectStatus,
        selectMode: vinculoReferenciaClienteFollowUpSelectors.selectMode,
        selectModel: vinculoReferenciaClienteFollowUpSelectors.selectModel,
      },
      actions: {
        loading: vinculoReferenciaClienteFollowUpActions.loading,
        resetStatus: vinculoReferenciaClienteFollowUpActions.resetStatus,
        changeToUpdateMode: vinculoReferenciaClienteFollowUpActions.changeToUpdateMode,
        changeToCreateMode: vinculoReferenciaClienteFollowUpActions.changeToCreateMode,
        resetMode: vinculoReferenciaClienteFollowUpActions.resetMode,
        setModel: vinculoReferenciaClienteFollowUpActions.setModel,
        resetModel: vinculoReferenciaClienteFollowUpActions.resetModel,
      },
    }),
    []
  );

  const referenciaClienteFormProps = useMemo(
    () => ({
      rootName: 'ignorableFields.referenciaCliente',
      fields: [
        {
          name: 'referencia',
          label: t('com.muralis.qcx.referenciaCliente'),
        },
      ],
    }),
    []
  );

  const referenciaClienteListProps = useMemo(
    () => ({
      name: 'referencias',
      columns: [
        {
          field: 'referencia',
          headerName: t('com.muralis.qcx.referencia'),
          headerAlign: 'left',
          align: 'left',
          flex: 140,
          valueGetter: ({ row }) => row?.referencia || '',
        },
      ],
    }),
    []
  );

  const handleAddReferenciaCliente = useCallback(async (currentVinculo, currentVinculos, handleSuccessAddVinculo) => {
    const vinculo = {
      id: uuid(),
      referencia: currentVinculo?.referencia,
    };

    const updatedVinculos = [...currentVinculos, vinculo];

    handleSuccessAddVinculo(updatedVinculos);
  }, []);

  const handleUpdateReferenciaCliente = useCallback(
    async (currentVinculo, currentVinculos, handleSuccessUpdateVinculo) => {
      const updatedVinculo = {
        ...currentVinculo,
        referencia: currentVinculo?.referencia,
      };

      const updatedVinculos =
        currentVinculos?.map((existingVinculo) =>
          isStrictEquals(existingVinculo?.id, updatedVinculo?.id) ? updatedVinculo : existingVinculo
        ) || currentVinculos;

      handleSuccessUpdateVinculo(updatedVinculos);
    },
    []
  );

  const handleReferenciaClienteAlreadyExists = useCallback(
    (currentVinculo, currentVinculosList) =>
      currentVinculosList.some(
        (vinculo) =>
          isStrictEquals(vinculo?.referencia, currentVinculo?.referencia) &&
          !isStrictEquals(vinculo?.id, currentVinculo?.id)
      ),
    []
  );

  const anexoFaturaReducerConfig = useMemo(
    () => ({
      selectors: {
        selectStatus: vinculoAnexoFaturaFollowUpSelectors.selectStatus,
        selectMode: vinculoAnexoFaturaFollowUpSelectors.selectMode,
        selectModel: vinculoAnexoFaturaFollowUpSelectors.selectModel,
      },
      actions: {
        loading: vinculoAnexoFaturaFollowUpActions.loading,
        resetStatus: vinculoAnexoFaturaFollowUpActions.resetStatus,
        changeToUpdateMode: vinculoAnexoFaturaFollowUpActions.changeToUpdateMode,
        changeToCreateMode: vinculoAnexoFaturaFollowUpActions.changeToCreateMode,
        resetMode: vinculoAnexoFaturaFollowUpActions.resetMode,
        setModel: vinculoAnexoFaturaFollowUpActions.setModel,
        resetModel: vinculoAnexoFaturaFollowUpActions.resetModel,
      },
    }),
    []
  );

  const anexoFaturaListProps = useMemo(
    () => ({
      name: 'arquivos',
      columns: [
        {
          field: 'fileName',
          headerName: 'Nome',
          headerAlign: 'left',
          align: 'left',
          flex: 140,
          valueGetter: ({ row }) => {
            if (row?.documento?.nome && row?.fatura?.length) {
              const fileSizeInMb = parseToNumber(row?.fatura[0]?.size) / 1024 ** 2;

              if (fileSizeInMb < 1) {
                const fileSizeInKb = fileSizeInMb * 1024;
                return `${row?.documento?.nome} (~ ${fileSizeInKb.toFixed(0)} KB)`;
              }

              return `${row?.documento?.nome} (~ ${fileSizeInMb.toFixed(2)} MB)`;
            }

            return row?.documento?.nome || '';
          },
        },
      ],
    }),
    [initialValues]
  );

  const handleFormatNumeroConhecimentoMasterBy = useCallback((fieldName, form, values) => {
    if (isViaTransporteAereoBy(values?.servico) && values?.numeroConhecimentoMaster?.length > 10) {
      const parsedNumeroConhecimentoMaster = parseNumeroConhecimentoMaster(values?.numeroConhecimentoMaster);

      const formattedNumeroConhecimentoMaster = formatNumeroConhecimentoMaster(parsedNumeroConhecimentoMaster);

      form.change(fieldName, formattedNumeroConhecimentoMaster);

      return;
    }

    if (!isViaTransporteAereoBy(values?.servico) && values?.numeroConhecimentoMaster?.length > 10) {
      const parsedNumeroConhecimentoMaster = parseToAlphanumericOnly(values?.numeroConhecimentoMaster);

      form.change(fieldName, parsedNumeroConhecimentoMaster);
    }
  }, []);

  const handleExportadorIdChange = useCallback(
    (form, values) => async (value) => {
      if (value) {
        const fetchServicosByExportador = async () => {
          const response = await clienteAPI.fetchServicosByClienteId(value, [
            {
              name: 'produto',
              value: TipoProdutoUtils.EXPORTACAO,
            },
          ]);

          if (response.status === 200) {
            const foundServicos = isArrayLikeObject(response?.data) ? response.data : [];

            dispatch(servicoActions.setList(foundServicos));

            const currentServicoId = values?.servico?.id;

            if (currentServicoId) {
              const foundCurrentServicoInCliente = foundServicos.find(
                (foundServico) => foundServico.id === currentServicoId
              );

              const clienteHasNotCurrentServico = isUndefined(foundCurrentServicoInCliente);

              if (clienteHasNotCurrentServico) {
                form.change('servico', undefined);
              }
            }
          }
        };

        fetchServicosByExportador();

        return;
      }

      if (!(isConsult || isUpdate) && !value) {
        form.change('servico', undefined);

        dispatch(servicoActions.resetList());
      }
    },
    [isConsult, isUpdate]
  );

  const handleServicoIdChange = useCallback(
    (form, values) => async (value) => {
      if (!isConsult || isUpdate) {
        if (value) {
          const response = await servicoAPI.fetchById(value);

          if (response.status === 200) {
            const foundServico = response.data;

            form.change('servico', foundServico);

            const changeTaxaAfrmmToUndefinedIfNonRequired = () => {
              if (!isRequiredAfrmmBy(foundServico)) {
                form.change('taxaAfrmm', undefined);
              }
            };

            handleFormatNumeroConhecimentoMasterBy('numeroConhecimentoMaster', form, {
              ...values,
              servico: foundServico,
            });
            changeTaxaAfrmmToUndefinedIfNonRequired();
          }

          return;
        }

        form.change('servico', undefined);
        form.change('taxaAfrmm', undefined);
      }
    },
    [isConsult, isUpdate, isRequiredAfrmmBy, handleFormatNumeroConhecimentoMasterBy]
  );

  const listeners = useMemo(
    () => [
      {
        name: 'importador.id', // FIXME: Alterar para 'cliente'
        fn: handleExportadorIdChange,
      },
      {
        name: 'servico.id',
        fn: handleServicoIdChange,
      },
    ],
    [handleExportadorIdChange, handleServicoIdChange]
  );

  const formControlButtons = useMemo(
    () => ({
      create: {
        description: t('com.muralis.qcx.acoes.abrir').toUpperCase(),
      },
    }),
    []
  );

  return (
    <QCXFinalForm
      isConsult={isConsult}
      isUpdate={isUpdate}
      initialValues={initialValues}
      handleSubmitValues={handleSubmit}
      handleChangeToUpdate={handleChangeToUpdate}
      handleCancelUpdate={handleCancelUpdate}
      onChangeListeners={listeners}
      controlButtons={formControlButtons}
      authInfo={authInfo}
      requiredRoles={requiredRoles}
      disablePristine
      {...restProps}
    >
      {({ values }) => (
        <>
          <Grid item container spacing={2}>
            <Grid
              item
              xs={12}
              style={{
                marginTop: '0.5rem',
                marginBottom: '0.5rem',
              }}
            >
              {isTipoMascaraFollowUpManual && isCreate && (
                <QCXInfoAlert>
                  <Trans i18nKey="com.muralis.qcx.unidadeNegocio.configuracaoUnidadeNegocioNumeroProcesso">
                    <strong>{{ termo: t('com.muralis.qcx.manualmente') }}</strong>
                  </Trans>
                </QCXInfoAlert>
              )}
              {!isTipoMascaraFollowUpManual && isCreate && (
                <QCXInfoAlert>
                  <Trans i18nKey="com.muralis.qcx.numeroProcessoAutomaticoUnidadeNegocioAtual">
                    <strong>{{ termo: t('com.muralis.qcx.automaticamente') }}</strong>
                  </Trans>
                </QCXInfoAlert>
              )}
            </Grid>
            {(isTipoMascaraFollowUpManual || !isCreate) && (
              <QCXAloneInlineBoxWrapper
                gridBreakpoints={{
                  sm: 12,
                  md: 6,
                }}
              >
                {(fieldProps) => (
                  <QCXMoveFocusInside>
                    <QCXFinalTextField
                      id="text-field-numero-processo"
                      key="text-field-numero-processo"
                      name="numero"
                      label={t('com.muralis.qcx.numeroProcesso')}
                      validate={required}
                      disabled={isConsult || !isTipoMascaraFollowUpManual}
                      helperText={
                        !isTipoMascaraFollowUpManual && (isConsult || isUpdate)
                          ? t('com.muralis.qcx.mensagem.sequencialGeradoAutomaticamente')
                          : undefined
                      }
                      required
                      {...fieldProps}
                    />
                  </QCXMoveFocusInside>
                )}
              </QCXAloneInlineBoxWrapper>
            )}
            <Grid item className={classes.gridFields} xs={12} sm={12} md={6}>
              <QCXSelectClienteAutocomplete
                id="autocomplete-select-exportador-field"
                key="autocomplete-select-exportador-field"
                name="importador.id" // FIXME: Alterar para 'cliente'
                label={t('com.muralis.qcx.exportador.label')}
                initialValues={values}
                filter={{
                  exportador: true,
                }}
                fieldProps={{ validate: required }}
                disabled={isConsult || isUpdate}
                required
              />
            </Grid>
            <Grid item className={classes.gridFields} xs={12} sm={12} md={6}>
              <QCXSelectServicoAutocomplete
                id="autocomplete-select-servico-field"
                key="autocomplete-select-servico-field"
                name="servico.id"
                label={t('com.muralis.qcx.servico.labelSingular')}
                initialValues={values}
                fieldProps={{ validate: required }}
                disabled={isConsult || isUpdate}
                required
              />
            </Grid>
            <Grid item className={classes.gridFields} xs={12} sm={12} md={6}>
              <QCXFinalTextField
                id="text-field-numero-conhecimento-master"
                key="text-field-numero-conhecimento-master"
                name="numeroConhecimentoMaster"
                label={t('com.muralis.qcx.numeroConhecimentoMaster')}
                {...(isViaTransporteAereoBy(values?.servico)
                  ? {
                      format: formatNumeroConhecimentoMaster12,
                      parse: parseToAlphanumericOnly,
                      maxLength: 12,
                    }
                  : {
                      parse: parseToAlphanumericOnly,
                      maxLength: 18,
                    })}
                disabled={isConsult}
              />
            </Grid>
            <Grid item className={classes.gridFields} xs={12} sm={12} md={6}>
              <QCXFinalTextField
                id="text-field-numero-conhecimento-house"
                key="text-field-numero-conhecimento-house"
                name="numeroConhecimentoHouse"
                label={t('com.muralis.qcx.numeroConhecimentoHouse')}
                maxLength={18}
                disabled={isConsult}
              />
            </Grid>
            <Grid item className={classes.gridFields} xs={12} sm={6} md={6}>
              <QCXSelectTransportadoraAutocomplete
                id="autocomplete-select-transportadora-field"
                key="autocomplete-select-transportadora-field"
                name="transportadora"
                label={t('com.muralis.qcx.transportadora.labelPlural')}
                initialValues={values}
                disabled={isConsult}
              />
            </Grid>
            {isRequiredAfrmmBy(values?.servico) && (
              <Grid item xs={12} sm={6} md={6}>
                <QCXFinalCheckboxField
                  id="text-field-afrmm-isento"
                  key="text-field-afrmm-isento"
                  label={t('com.muralis.qcx.AFRMMIsento')}
                  name="taxaAfrmm"
                  value={AfrmmUtils.ISENTO}
                  disabled={isConsult}
                />
              </Grid>
            )}

            <Grid item xs={12}>
              <QCXFormSubtitle title={t('com.muralis.qcx.referenciasCliente')} />
            </Grid>
            <QCXFinalBondManagerOld
              isParentConsult={isConsult}
              reducerConfig={referenciaClienteReducerConfig}
              listProps={referenciaClienteListProps}
              formProps={referenciaClienteFormProps}
              controlButtonsGridProps={{
                add: {
                  xs: 12,
                  sm: 4,
                  md: 4,
                },
                update: {
                  xs: 6,
                  sm: 2,
                  md: 2,
                },
                clear: {
                  xs: 6,
                  sm: 2,
                  md: 2,
                },
              }}
              handleAdd={handleAddReferenciaCliente}
              handleUpdate={handleUpdateReferenciaCliente}
              handleAlreadyExists={handleReferenciaClienteAlreadyExists}
            >
              {({ loading }) => (
                <Grid item className={classes.customGridFields} xs={12} sm={8} md={8}>
                  <QCXFinalTextField
                    id="text-field-vinculo-referencia-cliente"
                    key="text-field-vinculo-referencia-cliente"
                    name="ignorableFields.referenciaCliente.referencia"
                    label={t('com.muralis.qcx.referenciaCliente')}
                    maxLength={100}
                    disabled={isConsult || loading}
                  />
                </Grid>
              )}
            </QCXFinalBondManagerOld>
            <Grid item xs={12}>
              <QCXFormSubtitle title={t('com.muralis.qcx.followUpExportacao.vinculoDanfeXmlPlural')} />
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                marginTop: '0.5rem',
                marginBottom: '0.5rem',
              }}
            >
              {isCreate && (
                <QCXInfoAlert>
                  <Trans i18nKey="com.muralis.qcx.mensagem.aberturaProcessoVinculoArquivos">
                    <span />
                    <strong />
                    <span />
                  </Trans>
                </QCXInfoAlert>
              )}
              {!isCreate && (
                <QCXInfoAlert>
                  <Trans i18nKey="com.muralis.qcx.mensagem.aberturaProcessoProcessamentoUnicoArquivosXml">
                    <span />
                    <strong />
                    <span />
                    <strong />
                  </Trans>
                </QCXInfoAlert>
              )}
            </Grid>
            <Grid item xs={12}>
              <QCXFinalDropzoneField
                id="final-dropzone-multiple-file-field"
                key="final-dropzone-multiple-file-field"
                name="arquivosXmlDanfe"
                options={{
                  accept: {
                    'application/xml': ['.xml'],
                    'text/xml': ['.xml'],
                  },
                }}
                disabled={!isCreate}
              />
            </Grid>

            <Grid item xs={12}>
              <QCXFormSubtitle title="Documentos" />
            </Grid>
            <QCXFinalBondManagerOld
              isParentConsult={isConsult}
              listProps={anexoFaturaListProps}
              reducerConfig={anexoFaturaReducerConfig}
              readOnlyMode
              forceShowDelete
            />
          </Grid>
        </>
      )}
    </QCXFinalForm>
  );
}
