import React from 'react';
import {
  BRAZILIAN,
} from '../../utils/hooks/form/field/mask-types';
import { useNumericMask } from '../../utils/hooks/form/field/useFieldMask';
import QCXFinalFieldWrapper from '../final-field/QCXFinalFieldWrapper';
import QCXTextField from '../text-field/QCXTextField';

export default function QCXFinalNumericDecimalField({
  name,
  label,
  type,
  maxLength,
  required,
  validate,
  intl = BRAZILIAN,
  scale = 2,
  formatOnBlur = true,
  children,
  ...restProps
}) {
  const [format, parse] = useNumericMask({
    intl,
    scale,
  });

  return (
    <QCXFinalFieldWrapper
      name={name}
      type={type}
      label={label}
      maxLength={maxLength}
      required={required}
      component={QCXTextField}
      validate={validate}
      format={format}
      parse={parse}
      formatOnBlur={formatOnBlur}
      {...restProps}
    />
  );
}
