import { QueriesTypes, useGetRequest } from "./queries";

export const useGenerateLIXMLReport = (idLi: string, tipo:string) => {
  const {
    data,
    isError,
    isLoading,
  } = useGetRequest(QueriesTypes.LicencaImportacaoXMLReport, idLi, `TIPO=${tipo}`);

  return {data};
};
