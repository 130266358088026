import { IconButton } from '@material-ui/core';
import {
  Search as PageviewIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { userHasDeleteRole } from '../../../../../components/perfil/perfil-utils';
import CFOPOperacaoFiscalContext from '../../../../../contexts/registrations/cfop-operacao-fiscal/CFOPOperacaoFiscalContext';
import {
  activateByIdAsync,
  fetchAllAsync,
  inactivateByIdAsync,
  resetBackgroundMode,
  resetMode,
  resetModel,
  selectCFOPOperacoesFiscais,
} from '../../../../../features/cfop-operacoes-fiscais/cfopOperacoesFiscaisSlice';
import {
  ALTERNATIVE_LOADING_STATUS,
} from '../../../../../features/status';
import QCXSimpleConsultPageTemplate from '../../../../../templates/simple-consult-page/QCXSimpleConsultPageTemplate';

export default function CFOPOperacaoFiscalConsultPage({ authInfo = {} }) {
  const { t } = useTranslation();

  const history = useHistory();

  const {
    status,
  } = useContext(CFOPOperacaoFiscalContext);

  const dispatch = useDispatch();

  const operacoesFiscais = useSelector(selectCFOPOperacoesFiscais);

  const isAlternativeLoading = useMemo(() => status === ALTERNATIVE_LOADING_STATUS, [status]);

  const fetchAll = useCallback(() => {
    dispatch(fetchAllAsync());
  }, []);

  const resetModes = useCallback(() => {
    dispatch(resetMode());
    dispatch(resetBackgroundMode());
  }, []);

  const resetModels = useCallback(() => {
    dispatch(resetModel());
  }, []);

  const resetDependencies = useCallback(() => {
  }, []);

  useEffect(() => {
    fetchAll();
    resetModes();
    resetModels();
    resetDependencies();
  }, []);

  const handleActivateInactivate = async (event, id) => {
    event.preventDefault();

    const { active } = operacoesFiscais.find(
      (item) => item.id === id
    );

    const isActivation = !active;

    if (isActivation) {
      dispatch(activateByIdAsync(id));
    } else {
      dispatch(inactivateByIdAsync(id));
    }
  };

  const handleClickOnEditButton = useCallback(
    (_event, id) => (
      history.push(
        t('com.muralis.qcx.url.moduloOperacionaisDetalhes', { id })
      )
    ), [history]
  );
  const breadcrumbs = [
    {
      link: {
        to: '/',
        name: t('com.muralis.qcx.inicio'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.cadastros'),
      },
    },
    {
      link: {
        to: t('com.muralis.qcx.url.moduloOperacionais'),
        name: t('com.muralis.qcx.operacionais'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.catalogoProdutosMercadoria'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.CFOP.label'),
      },
    },
  ];
  const columns = [
    {
      field: 'code',
      headerName: t('com.muralis.qcx.codigo'),
      headerAlign: 'center',
      align: 'center',
      type: 'string',
      flex: 35,
      valueGetter: ({ row }) => (
        row?.code
      ),
    },
    {
      field: 'detalhe',
      headerName: t('com.muralis.qcx.descricao'),
      type: 'string',
      valueGetter: ({ row }) => (
        row?.detalhe
      ),
      flex: 145,
    },
    {
      field: 'actions',
      headerName: t('com.muralis.qcx.acoes.label'),
      headerAlign: 'center',
      align: 'center',
      flex: 50,
      renderCell: ({ row: { id, active } }) => (
        <>
          {userHasDeleteRole(authInfo, 'operacao-fiscal') && (
          <IconButton
            key={`stw-active${id}`}
            value={active}
            name={`stw-active-${id}`}
            onClick={(event) => handleActivateInactivate(event, id)}
          >
            <DeleteIcon color="error" />
          </IconButton>
          )}
          <IconButton
            key={`btn-view-${id}`}
            name={`btn-view-${id}`}
            onClick={(event) => handleClickOnEditButton(event, id)}
          >
            <PageviewIcon color="primary" />
          </IconButton>
        </>
      ),
    }
  ];

  const templateProps = {
    page: {
      title: t('com.muralis.qcx.CFOP.labelExtenso'),
      breadcrumbs,
    },
    control: {
      buttonNew: {
        description: t('com.muralis.qcx.CFOP.novoCFOP'),
        path: t('com.muralis.qcx.url.moduloOperacionaisCFOPNovo'),
      },
    },
    table: {
      columns,
    },
  };

  const filterPropGetter = useCallback(
    (operacaoFiscal) => operacaoFiscal.nome,
    []
  );

  return (
    <QCXSimpleConsultPageTemplate
      pageProps={templateProps.page}
      controlProps={templateProps.control}
      tableProps={templateProps.table}
      tableData={operacoesFiscais}
      isLoading={isAlternativeLoading}
      filterPropGetter={filterPropGetter}
      authInfo={authInfo}
      requiredRoles={['operacao-fiscal']}
    />
  );
}
