import { createFilterOptions } from '@material-ui/lab/useAutocomplete';
import _ from 'lodash';
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import QCXAutocompleteSelect from '../autocomplete-select/QCXAutocompleteSelect';
import TIPO_LOGRADOURO_LIST from './TipoLogradouroUtils';

export default function QCXSelectTipoLogradouroAutocomplete({ label, name, initialValues, ...restProps }) {
  const [defaultValue, setDefaultValue] = useState('');

  const { t } = useTranslation();

  useEffect(() => {
    const value = TIPO_LOGRADOURO_LIST.find((item) => item?.value === _.get(initialValues, name));

    if (value) {
      setDefaultValue(value);
    } else {
      setDefaultValue('');
    }
  }, [initialValues]);

  const filterOptions = useMemo(
    () =>
      createFilterOptions({
        stringify: (option) => option.label + option.value,
      }),
    []
  );

  const getOptionLabel = useCallback((item) => t(item?.label) || '', []);

  const getOptionValue = useCallback((item) => item?.value || '', []);

  return (
    <QCXAutocompleteSelect
      label={label}
      id={name}
      name={name}
      options={TIPO_LOGRADOURO_LIST}
      filterOptions={filterOptions}
      defaultValue={defaultValue}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      {...restProps}
    />
  );
}
