import { ReponseType } from '../../utils/api/api-utils';
import {
  doGetQCXRequest,
  doPostQCXRequest,
  doPutQCXActivateRequest,
  doPutQCXDuplicarRequest,
  doPutQCXInactivateRequest,
  doPutQCXRequest,
} from '../../utils/api/domain/domain-api-utils';
import {
  FOLLOW_UP_ENDPOINT,
  IMPORTADOR_ENDPOINT,
  NUMERARIO_IMPORTACAO_ENDPOINT,
  QCX_CANCELAMENTO_ENDPOINT,
  QCX_EMAIL_ENDPOINT,
  QCX_RELATORIO_ENDPOINT,
  QCX_TITULOS_ENDPOINT,
  QCX_ESTORNO_ENDPOINT,
  NOTIFICAR_FILA_ENDPOINT,
} from '../../utils/api/domain/domain-endpoint-utils';

async function fetchAll() {
  return doGetQCXRequest([
    NUMERARIO_IMPORTACAO_ENDPOINT
  ]);
}

async function fetchById(id) {
  return doGetQCXRequest([
    NUMERARIO_IMPORTACAO_ENDPOINT,
    id,
  ]);
}

async function filterFollowUpsBy(data, query = []) {
  if (!data?.importador?.id) {
    return Promise.reject();
  }

  return doGetQCXRequest(
    [
      NUMERARIO_IMPORTACAO_ENDPOINT,
      FOLLOW_UP_ENDPOINT,
      IMPORTADOR_ENDPOINT,
      data?.importador?.id,
    ],
    query
  );
}

async function register(data, queryParams = [], config = {}) {
  return doPostQCXRequest(
    [NUMERARIO_IMPORTACAO_ENDPOINT],
    data,
    queryParams,
    config
  );
}

async function save(data, queryParams = [], config = {}) {
  return doPutQCXRequest(
    [NUMERARIO_IMPORTACAO_ENDPOINT],
    data,
    queryParams,
    config
  );
}

async function generateReportById(id) {
  return doGetQCXRequest(
    [
      NUMERARIO_IMPORTACAO_ENDPOINT,
      QCX_RELATORIO_ENDPOINT,
      id
    ],
    [],
    {
      responseType: ReponseType.ARRAY_BUFFER,
    }
  );
}

async function sendEmailBy(data, queryParams = [], config = {}) {
  return doPostQCXRequest(
    [
      NUMERARIO_IMPORTACAO_ENDPOINT,
      QCX_EMAIL_ENDPOINT,
    ],
    data,
    queryParams,
    config
  );
}

async function cancelarSolicitacaoById(id, config = {}) {
  return doPutQCXRequest(
    [
      NUMERARIO_IMPORTACAO_ENDPOINT,
      QCX_CANCELAMENTO_ENDPOINT,
      id
    ],
    {},
    [],
    config
  );
}

async function duplicarById(id, subPath = [], data = null) {
  return doPutQCXDuplicarRequest(
    [NUMERARIO_IMPORTACAO_ENDPOINT],
    id,
    subPath,
    data
  );
}

async function activateById(id) {
  return doPutQCXActivateRequest(
    [NUMERARIO_IMPORTACAO_ENDPOINT],
    id
  );
}

async function inactivateById(id) {
  return doPutQCXInactivateRequest(
    [NUMERARIO_IMPORTACAO_ENDPOINT],
    id
  );
}

async function reverseMovimentacao(data, queryParams = [], config = {}) {
  return doPostQCXRequest(
    [
      NUMERARIO_IMPORTACAO_ENDPOINT,
      QCX_TITULOS_ENDPOINT,
      QCX_ESTORNO_ENDPOINT
    ],
    data,
    queryParams,
    config
  );
}

async function notifyNumerario(id){
  return doPostQCXRequest(
    [NUMERARIO_IMPORTACAO_ENDPOINT, NOTIFICAR_FILA_ENDPOINT, id],
    {}
  );
}

const numerarioImportacaoAPI = {
  fetchAll,
  fetchById,
  filterFollowUpsBy,
  register,
  save,
  generateReportById,
  sendEmailBy,
  cancelarSolicitacaoById,
  duplicarById,
  activateById,
  inactivateById,
  reverseMovimentacao,
  notifyNumerario,
};

export {
  numerarioImportacaoAPI,
  fetchAll,
  fetchById,
  filterFollowUpsBy,
  register,
  save,
  generateReportById,
  sendEmailBy,
  cancelarSolicitacaoById,
  duplicarById,
  activateById,
  inactivateById,
  reverseMovimentacao,
  notifyNumerario,
};
