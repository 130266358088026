import React from 'react';
import { useIntegerParser } from '../../utils/hooks/form/field/useFieldParse';
import QCXFinalFieldWrapper from '../final-field/QCXFinalFieldWrapper';
import QCXTextField from '../text-field/QCXTextField';

export default function QCXFinalNumericIntegerField({
  name,
  label,
  maxLength,
  required = false,
  validate = undefined,
  allowNegative = true,
  children = undefined,
  ...restProps
}) {
  const parse = useIntegerParser({
    allowNegative,
  });

  return (
    <QCXFinalFieldWrapper
      name={name}
      label={label}
      maxLength={maxLength}
      required={required}
      component={QCXTextField}
      validate={validate}
      parse={parse}
      {...restProps}
    />
  );
}
