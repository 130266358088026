import { createFilterOptions } from '@material-ui/lab/useAutocomplete';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import QCXAutocompleteSelect from '../autocomplete-select/QCXAutocompleteSelect';
import { FRETE_POR_CONTA_LIST } from './fretePorContaUtils';

const filterOptions = createFilterOptions({
  stringify: (item) => `${item?.code} - ${item?.description}`,
});

export default function QCXSelectFretePorContaAutocomplete({
  label,
  name,
  initialValues,
  ...restProps
}) {
  const getOptionLabelFunc = useCallback((item) => (
    item?.label
      ? item?.label
      : ''
  ), []);

  const getOptionValueFunc = useCallback((item) => (
    item?.value
      ? item?.value
      : ''
  ), []);

  const [defaultValue, setDefaultValue] = useState('');
  useEffect(() => {
    const value = FRETE_POR_CONTA_LIST.find((item) => item?.value === _.get(initialValues, name));
    if (value) {
      setDefaultValue(value);
    }
  }, [initialValues]);

  return (
    <QCXAutocompleteSelect
      label={label}
      id={name}
      name={name}
      options={FRETE_POR_CONTA_LIST}
      filterOptions={filterOptions}
      defaultValue={defaultValue}
      getOptionLabel={getOptionLabelFunc}
      getOptionValue={getOptionValueFunc}
      {...restProps}
    />
  );
}
