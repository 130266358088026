import React from 'react';
import QCXFormListener from './QCXFormListener';

export default function QCXFormListenerManager({
  form,
  values,
  onChangeListeners,
}) {
  return (
    <>
      {onChangeListeners.map((listener) => (
        <QCXFormListener name={listener?.name}>
          {listener.fn(form, values)}
        </QCXFormListener>
      ))}
    </>
  );
}
