import { getOptionByValueInList } from '../../utils/general/general-utils';
import i18n from '../../i18n';

const FORMATO_IMPRESSAO_PAISAGEM = 'LAYOUT_PAISAGEM';
const FORMATO_IMPRESSAO_RETRATO = 'LAYOUT_RETRATO';

// Outros formatos válidos para enviar na nf-e são "simplificado" e "nfc-e", mas
// não disponibilizamos estes itens como opções.

const FORMATO_IMPRESSAO_DANFE_LIST = [
  {
    label: i18n.t('com.muralis.qcx.formatoImpressao.paisagem').toUpperCase(),
    value: FORMATO_IMPRESSAO_PAISAGEM,
  },
  {
    label: i18n.t('com.muralis.qcx.formatoImpressao.retrato').toUpperCase(),
    value: FORMATO_IMPRESSAO_RETRATO,
  },
];

const getFormatoImpressaoDanfeByValue = (value) => {
  const foundOption = getOptionByValueInList(FORMATO_IMPRESSAO_DANFE_LIST, value);
  return foundOption && foundOption?.label;
};

export {
  FORMATO_IMPRESSAO_PAISAGEM,
  FORMATO_IMPRESSAO_RETRATO,
  FORMATO_IMPRESSAO_DANFE_LIST,
  getFormatoImpressaoDanfeByValue,
};
