import React, { useCallback, useEffect, useMemo } from 'react';
import { debounce, isArray, isEmpty } from 'lodash';
import { useForm } from 'react-final-form';
import { isValid } from '../../utils/general/general-utils';
import QCXControlButtonsPagination from './QCXControlButtonsPagination';

export default function QCXFormPaginationControl({
  initialValues,
  list,
  autoLoadItem,
  currentItemNumber,
  isLoading,
  isUpdate,
  isSubConsult,
  isSubUpdate,
  isSubCreate,
  isSubBackgroundCreate,
  isSubBackgroundConsult,
  isSubBackgroundUpdate,
  isSubBackgroundDelete,
  isLastModeBackgroundDelete,
  defaultItem = undefined,
  ignoreDefaultItem,
  loadInitialItem,
  controlButtonsPaginationProps = {
    paginationInfoField: {},
    buttonGroup: {
      first: {},
      next: {},
      previous: {},
      last: {},
    },
  },
  handleSetLoadInitialItem,
  handleSetCurrentItemNumber,
  handleSetIgnoreDefaultItem,
  handleChangeModel,
  handleChangeItemDecorator,
  handleLoadingStatus,
  handleResetStatus,
  handleChangeToConsult,
  handleChangeToSubConsult,
  handleInternalClearForm,
  controlled = false,
  autoFormReset = true,
}) {
  const form = useForm();

  useEffect(() => {
    if (!isLastModeBackgroundDelete && (autoFormReset || !isSubCreate)) {
      form.reset(initialValues);
    }
  }, [form, autoFormReset, isSubCreate, initialValues, isLastModeBackgroundDelete]);

  const hasItems = useMemo(() => isArray(list) && !isEmpty(list), [list]);

  const addedItemsQuantity = useMemo(() => list.length, [list]);

  const itemsPaginationInfo = useMemo(
    () => `${currentItemNumber || '-'} / ${addedItemsQuantity}`,
    [currentItemNumber, addedItemsQuantity]
  );

  const handleChangeItemByNumber = useCallback(
    (itemNumber) => {
      const currentItemIndex = itemNumber - 1;
      const currentItem = list[currentItemIndex];

      if (currentItem && !isEmpty(currentItem) && !isSubBackgroundDelete) {
        if (!(isSubBackgroundConsult || isSubCreate)) {
          handleChangeModel(currentItem);
        }

        if (!(isSubBackgroundCreate || isSubBackgroundUpdate || controlled)) {
          if (isUpdate) {
            handleChangeToConsult();
          }
          handleChangeToSubConsult();
        }
      }
    },
    [
      list,
      controlled,
      isUpdate,
      isSubConsult,
      isSubCreate,
      isSubBackgroundCreate,
      isSubBackgroundConsult,
      isSubBackgroundUpdate,
      isSubBackgroundDelete,
      handleChangeModel,
      handleChangeToConsult,
      handleChangeToSubConsult,
    ]
  );

  const handleNextItem = useCallback(() => {
    handleLoadingStatus();
    handleSetIgnoreDefaultItem(true);

    const executeDebouncedNext = debounce(() => {
      if (hasItems) {
        const nextItemNumber = Math.max(1, currentItemNumber) + 1;
        const nextItemIndex = nextItemNumber - 1;
        const nextItem = list[nextItemIndex];

        if (nextItem && !isEmpty(nextItem)) {
          handleInternalClearForm();
          handleChangeItemDecorator();

          handleSetCurrentItemNumber(nextItemNumber);
          handleChangeModel(nextItem);
        }
      }

      handleResetStatus();
    }, 500);

    executeDebouncedNext();
  }, [
    list,
    hasItems,
    currentItemNumber,
    handleChangeModel,
    handleLoadingStatus,
    handleSetCurrentItemNumber,
    handleSetIgnoreDefaultItem,
    handleInternalClearForm,
    handleChangeItemDecorator,
  ]);

  const handlePreviousItem = useCallback(() => {
    handleLoadingStatus();
    handleSetIgnoreDefaultItem(true);

    const executeDebouncedPrevious = debounce(() => {
      if (hasItems) {
        const previousItemNumber = currentItemNumber - 1;
        const previousItemIndex = previousItemNumber - 1;
        const previousItem = list[previousItemIndex];

        if (previousItem && !isEmpty(previousItem)) {
          handleInternalClearForm();
          handleChangeItemDecorator();

          handleSetCurrentItemNumber(previousItemNumber);
          handleChangeModel(previousItem);
        }
      }

      handleResetStatus();
    }, 500);

    executeDebouncedPrevious();
  }, [
    list,
    hasItems,
    currentItemNumber,
    handleChangeModel,
    handleLoadingStatus,
    handleSetCurrentItemNumber,
    handleSetIgnoreDefaultItem,
    handleInternalClearForm,
    handleChangeItemDecorator,
  ]);

  const handleFirstItem = useCallback(() => {
    handleLoadingStatus();
    handleSetIgnoreDefaultItem(true);

    const executeDebouncedPrevious = debounce(() => {
      if (hasItems) {
        const firstItemNumber = 1;
        const firstItemIndex = 0;
        const firstItem = list[firstItemIndex];

        if (firstItem && !isEmpty(firstItem)) {
          handleInternalClearForm();
          handleChangeItemDecorator();

          handleSetCurrentItemNumber(firstItemNumber);
          handleChangeModel(firstItem);
        }
      }

      handleResetStatus();
    }, 500);

    executeDebouncedPrevious();
  }, [
    list,
    hasItems,
    handleChangeModel,
    handleLoadingStatus,
    handleSetCurrentItemNumber,
    handleSetIgnoreDefaultItem,
    handleInternalClearForm,
    handleChangeItemDecorator,
  ]);

  const handleLastItem = useCallback(() => {
    handleLoadingStatus();
    handleSetIgnoreDefaultItem(true);

    const executeDebouncedPrevious = debounce(() => {
      if (hasItems) {
        const lastItemNumber = addedItemsQuantity;
        const lastItemIndex = lastItemNumber - 1;
        const lastItem = list[lastItemIndex];

        if (lastItem && !isEmpty(lastItem)) {
          handleInternalClearForm();
          handleChangeItemDecorator();

          handleSetCurrentItemNumber(lastItemNumber);
          handleChangeModel(lastItem);
        }
      }

      handleResetStatus();
    }, 500);

    executeDebouncedPrevious();
  }, [
    list,
    hasItems,
    addedItemsQuantity,
    handleChangeModel,
    handleLoadingStatus,
    handleSetCurrentItemNumber,
    handleSetIgnoreDefaultItem,
    handleInternalClearForm,
    handleChangeItemDecorator,
  ]);

  useEffect(() => {
    const handleLoadInitialItem = () => {
      if (isValid(currentItemNumber) && loadInitialItem) {
        handleSetCurrentItemNumber(currentItemNumber);
        handleChangeItemByNumber(currentItemNumber);
        handleSetLoadInitialItem(false);
      }
    };

    handleLoadInitialItem();
  }, [loadInitialItem, currentItemNumber, handleChangeItemByNumber, handleSetLoadInitialItem]);

  useEffect(() => {
    if (isValid(defaultItem) && autoLoadItem) {
      const adjustedDefaultItem = list?.findIndex((data) => data?.item === defaultItem) + 1;

      handleSetCurrentItemNumber(adjustedDefaultItem);
      handleSetIgnoreDefaultItem(false);
      handleChangeItemByNumber(adjustedDefaultItem);
    }
  }, [list, defaultItem, autoLoadItem, handleSetCurrentItemNumber, handleChangeItemByNumber]);

  useEffect(() => {
    const checkIfNeededToAdjustCurrentItemNumber = () => {
      const itemNumber = (ignoreDefaultItem ? false : defaultItem) || currentItemNumber;
      const validItemNumber = Math.max(1, itemNumber);

      if (addedItemsQuantity && validItemNumber) {
        const newCurrentItemNumber = validItemNumber > addedItemsQuantity ? addedItemsQuantity : validItemNumber;

        handleSetCurrentItemNumber(newCurrentItemNumber);
      }
    };

    checkIfNeededToAdjustCurrentItemNumber();
  }, [addedItemsQuantity, defaultItem, currentItemNumber, handleSetCurrentItemNumber, ignoreDefaultItem]);

  return (
    <QCXControlButtonsPagination
      metadata={{
        paginationInfoField: {
          ...controlButtonsPaginationProps?.paginationInfoField,
          value: itemsPaginationInfo,
          hiddenValue: controlButtonsPaginationProps?.paginationInfoField?.hiddenValue,
        },
        buttonGroup: {
          first: controlButtonsPaginationProps?.buttonGroup?.first,
          next: controlButtonsPaginationProps?.buttonGroup?.next,
          previous: controlButtonsPaginationProps?.buttonGroup?.previous,
          last: controlButtonsPaginationProps?.buttonGroup?.last,
        },
        current: currentItemNumber,
        quantity: addedItemsQuantity,
      }}
      isSubConsult={isSubConsult}
      isSubUpdate={isSubUpdate}
      isSubCreate={isSubCreate}
      handleFirstItem={handleFirstItem}
      handlePreviousItem={handlePreviousItem}
      handleNextItem={handleNextItem}
      handleLastItem={handleLastItem}
      disabled={isLoading}
    />
  );
}
