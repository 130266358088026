/**
 * It takes a date string and a format string and returns a formatted date string
 * @param {string} dateString - The date string to format.
 * @param {"DD/MM/YYYY" | "DD/MM/YY" | "DD-MM-YYYY" | "DD-MM-YY" | "YYYY-MM-DD"} format - "DD/MM/YYYY" | "DD/MM/YY" |
 * "DD-MM-YYYY" | "DD-MM-YY" | "YYYY-MM-DD"
 * @returns A function that takes a date string and a format string and returns a formatted date
 * string.
 */
export const formatDateISOString = (
  dateString: string,
  format: "DD/MM/YYYY" | "DD/MM/YY" | "DD-MM-YYYY" | "DD-MM-YY" | "YYYY-MM-DD"
): string => {
  const date = new Date(dateString);
  if (isNaN(date.getTime())) {
    throw new Error("Invalid date string");
  }
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear().toString();
  const shortYear = year.slice(-2);
  switch (format) {
    case "DD/MM/YYYY":
      return `${day}/${month}/${year}`;
    case "DD/MM/YY":
      return `${day}/${month}/${shortYear}`;
    case "DD-MM-YYYY":
      return `${day}-${month}-${year}`;
    case "DD-MM-YY":
      return `${day}-${month}-${shortYear}`;
    case "YYYY-MM-DD":
      return `${year}-${month}-${day}`;
    default:
      throw new Error("Invalid format");
  }
};

export const formatStringToDate = (
  dateString: string,
  format: "DD/MM/YYYY" | "DD/MM/YY" | "DD-MM-YYYY" | "DD-MM-YY" | "YYYY-MM-DD"
): Date => {
  switch (format) {
    case "YYYY-MM-DD":
      const [year, month, day] = dateString.split("-");
      return new Date(Number(year), Number(month) - 1, Number(day));

    default:
      throw new Error("Invalid format");
  }
};
