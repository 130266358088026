import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { Button, Grid } from '@mui/material';
import { useKeycloak } from '@react-keycloak/web';
import { SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchAllAsync as fetchAllClientesAsync,
  selectClientes,
} from '../../../../../../features/cliente/clienteSlice';
import { selectUnidadeSelecionada } from '../../../../../../features/usuario-logado/usuarioLogadoSlice';
import ErrorBadge from '../../../../../common/components/forms/errorBadge';
import FormHeaderMedium from '../../../../../common/components/forms/formHeaderMedium';
import SelectStyled from '../../../../../common/components/input/selectStyled';
import LoadingIndicator from '../../../../../common/components/loadingIndicator';
import { QueriesTypes, useGetRequest } from '../../../../../common/hooks/queries';
import { useUnidadeNegocioGuard } from '../../../../../common/hooks/useUnidadeNegocioGuard';
import PageLayout from '../../../../../common/layouts/pageLayout';
import { ClientData } from '../../../../../common/types/clientData';
import { FollowUp } from '../../../../../common/types/followUp';
import { useDanfeCapa, useDanfeItens, useListAllCorrespondencias, useNumerario } from '../../totvs.hooks';
import { CorrespondenciaImportadorTotvs } from '../../totvs.types';
import { generateNumerarioTXT, verifyNumerariosCorrelations } from './totvsEscritaNumerariosPage.helpers';

const TotvsEscritaNumerariosPage = () => {
  useUnidadeNegocioGuard();
  const dispatch = useDispatch();
  const [error, setError] = useState<string | string[] | undefined>();
  const [processo, setProcesso] = useState<string | undefined>(undefined);
  const [cliente, setCliente] = useState<number | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const clientes: ClientData[] = useSelector(selectClientes);
  const [correlacoesImportadores, isCorrelacoesImportadoresLoading, isCorrelacoesImportadoresError] =
    useListAllCorrespondencias();
  const {
    data: followUpsData,
    isLoading: isFollowUpsDataLoading,
    isError: isFollowUpsDataError,
  } = useGetRequest(QueriesTypes.FollowUps);
  const numerarios = useNumerario(processo);

  useEffect(() => {
    if (!correlacoesImportadores || !cliente) {
      setError(undefined);
      return;
    }

    const clienteCorrespondencia = correlacoesImportadores.find(
      (correlacao: CorrespondenciaImportadorTotvs) => correlacao.quickcomexId === cliente?.toString()
    );

    if (!clienteCorrespondencia) {
      setError('Cliente não possui correspondência no TOTVS. Criar a correspondência antes de prosseguir.');
    } else {
      setError(undefined);
    }

    return undefined;
  }, [cliente, correlacoesImportadores]);

  const processos = useMemo(() => {
    if (!followUpsData || typeof followUpsData != 'object') {
      return [];
    }
    const processosFilteredByClient = followUpsData.filter((followUp: FollowUp) => followUp.importador.id === cliente);
    const processos: string[] = processosFilteredByClient.map((followUp: FollowUp) => followUp.numero);
    return processos.filter((processo, index) => {
      return processos.indexOf(processo) === index;
    });
  }, [followUpsData, cliente]);

  const handleReportGeneration = async () => {
    if (numerarios) {
      const correlation = correlacoesImportadores.find(
        (correlacao: CorrespondenciaImportadorTotvs) => correlacao.quickcomexId === cliente?.toString()
      );
      try {
        const errors = verifyNumerariosCorrelations(numerarios, correlation);
        if (errors.length > 0) {
          setError(errors);
        } else {
          generateNumerarioTXT(numerarios);
        }
      } catch (e) {
        console.log(e);
      }
      setIsLoading(false);
    }
  };

  const handleSubmit = () => {
    setError(undefined);
    setIsLoading(true);
    handleReportGeneration();
  };

  const clearData = () => {
    setCliente(undefined);
    setProcesso(undefined);
    setIsLoading(false);
  };

  const handleClienteChange = (
    event: SyntheticEvent<Element, Event>,
    value: {
      id: string | number | undefined;
      value: string | undefined;
    } | null
  ) => {
    const selectedClient = clientes.find((clientes) => clientes.id === value?.id);
    setCliente(selectedClient?.id);
    setProcesso(undefined);
  };

  useEffect(() => {
    // Ignore typescript due to older version of redux-toolkit
    // TODO: Update redux-toolkit to typescript
    /* @ts-ignore */
    dispatch(fetchAllClientesAsync());
  }, []);

  return (
    <PageLayout title={'TOTVS - Escrita de Numerários'} icon={<InsertDriveFileIcon color={'secondary'} />}>
      {isLoading || isFollowUpsDataLoading ? (
        <LoadingIndicator message={isLoading ? 'Gerando relatório' : 'Carregando processos...'} />
      ) : (
        <Grid container spacing={2}>
          <FormHeaderMedium>Selecione as informações para gerar o arquivo</FormHeaderMedium>
          <Grid item sm={6}>
            <SelectStyled
              options={clientes.map((cliente, index) => ({
                id: cliente.id,
                value: cliente.description + ' - ' + cliente.pessoa?.cnpj,
              }))}
              onChangeAction={handleClienteChange}
              label={'Cliente'}
            ></SelectStyled>
          </Grid>
          <Grid item sm={6}>
            <SelectStyled
              options={processos.map((processo, index) => ({
                id: index,
                value: processo,
              }))}
              label={'Processo'}
              onChangeAction={(event, newValue) => {
                setProcesso(newValue?.value);
              }}
            />
          </Grid>
          <ErrorBadge error={error} />
          <Grid item sm={12} textAlign={'right'}>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleSubmit}
              disabled={!cliente || !processo || isLoading || !!error}
            >
              Gerar
            </Button>
          </Grid>
        </Grid>
      )}
    </PageLayout>
  );
};

export default TotvsEscritaNumerariosPage;
