import { isEmpty, isEqual, isString } from 'lodash';
import { useCallback, useMemo } from 'react';
import {
  RELACAO_FABRICANTE_DESCONHECIDO,
  RELACAO_FABRICANTE_EXPORTADOR,
  RELACAO_FABRICANTE_NAO_EXPORTADOR,
} from '../../../components/relacao-fornecedor/relacao-fornecedor-utils';
import { fetchById as fetchNcmSubItemById } from '../../../features/ncm-subitem/ncmSubitemAPI';
import {
  UNIDADE_DE_MEDIDA_PECA,
  UNIDADE_DE_MEDIDA_QUILOGRAMA_LIQUIDO,
  UNIDADE_DE_MEDIDA_UNIDADE,
} from '../../../features/unidade-medida/unidadeDeMedidaUtils';
import { formatBrazilianNumericDecimal } from '../../hooks/form/field/formatters';
import { normalizeNumeral, unnormalizeNumeral } from '../general-utils';
import { TIPO_DUE } from './faturaUtils';

export default function useMercadoriaListenersGroup({
  modes,
  formProps,
  subscribeToFilling,
  isSobrescreveDadosPartnumber,
  getTipoCalculoValue,
}) {
  const { isSubCreate, isSubUpdate, isConsult, isFillCommonDataMode } = modes;

  const { faturaValues, listCatalogo, unidadeMedidaList, setIsFirstFormLoadValues, isFirstFormLoadValues, rootName } =
    formProps;

  const especificacaoFieldName = useMemo(() => (rootName ? `${rootName}.especificacao` : 'especificacao'), [rootName]);

  const handleQuantidadeOnBlur = useCallback(
    (form, values) => () => {
      const valorUnitarioMoedaProduto = normalizeNumeral(values?.valorUnitarioMoeda);
      const quantidade = normalizeNumeral(values?.quantidade);
      const ID_UNIDADE_MEDIDA_UNIDADE = 200624;
      const ID_UNIDADE_MEDIDA_PECA = 355110;

      const tipoCalculo = values?.tipoCalculo;
      let valorCalculo = getTipoCalculoValue(tipoCalculo);

      if (!valorCalculo) {
        form.change('tipoCalculo', 'QUANTIDADE_X_VALOR');
        valorCalculo = 1;
      }
      const valorTotalMoedaCalculado = ((valorUnitarioMoedaProduto * quantidade) / valorCalculo).toFixed(2);

      if (
        (values?.ncm?.unidadeDeMedida?.description === UNIDADE_DE_MEDIDA_UNIDADE ||
          values?.ncm?.unidadeDeMedida?.description === UNIDADE_DE_MEDIDA_PECA) &&
        (values?.unidadeDeMedida?.id === ID_UNIDADE_MEDIDA_UNIDADE ||
          values?.unidadeDeMedida?.id === ID_UNIDADE_MEDIDA_PECA)
      ) {
        const formattedQuantidade = unnormalizeNumeral(quantidade, formatBrazilianNumericDecimal(5));
        form.change('quantidadeEstatistica', formattedQuantidade);
      }

      const formattedValorTotalMoedaCalculado = unnormalizeNumeral(
        valorTotalMoedaCalculado,
        formatBrazilianNumericDecimal(2)
      );

      form.change('valorTotalMoeda', formattedValorTotalMoedaCalculado);

      if (!values?.pesoLiquidoUnitario || values?.due) {
        return;
      }

      const pesoLiquidoUnitario = normalizeNumeral(values?.pesoLiquidoUnitario);

      const novoPesoLiquido = normalizeNumeral(pesoLiquidoUnitario) * quantidade;

      // se o valor arredondado dos pesos liquidos unitarios for o mesmo,
      // não faz a alteração
      const pesoLiquidoUnitarioFromPesoLiquido = normalizeNumeral(values?.pesoLiquido) / quantidade;
      if (pesoLiquidoUnitarioFromPesoLiquido.toFixed(7) === pesoLiquidoUnitario.toFixed(7)) {
        return;
      }

      form.change('pesoLiquido', unnormalizeNumeral(novoPesoLiquido, formatBrazilianNumericDecimal(7)));
    },
    [getTipoCalculoValue]
  );

  const handleValorUnitarioMoedaProdutoOnBlur = useCallback(
    (form, values) => {
      const valorUnitarioMoedaProduto = normalizeNumeral(values?.valorUnitarioMoeda);
      const quantidade = normalizeNumeral(values?.quantidade);

      const tipoCalculo = values?.tipoCalculo;

      let valorCalculo = getTipoCalculoValue(tipoCalculo);

      if (!valorCalculo) {
        form.change('tipoCalculo', 'QUANTIDADE_X_VALOR');
        valorCalculo = 1;
      }

      const valorTotalMoedaCalculado = ((valorUnitarioMoedaProduto * quantidade) / valorCalculo).toFixed(2);

      const formattedValorTotalMoedaCalculado = unnormalizeNumeral(
        valorTotalMoedaCalculado,
        formatBrazilianNumericDecimal(2)
      );
      form.change('valorTotalMoeda', formattedValorTotalMoedaCalculado);
    },
    [getTipoCalculoValue]
  );

  const handleValorTotalMoedaOnBlur = useCallback(
    (form, values) => () => {
      const valorTotalMoeda = normalizeNumeral(values?.valorTotalMoeda);
      const quantidade = normalizeNumeral(values?.quantidade);

      const tipoCalculo = values?.tipoCalculo;
      let valorCalculo = getTipoCalculoValue(tipoCalculo);
      if (!valorCalculo) {
        form.change('tipoCalculo', 'QUANTIDADE_X_VALOR');
        valorCalculo = 1;
      }
      const valorUnitarioMoedaCalculado = ((valorTotalMoeda / quantidade) * valorCalculo).toFixed(7);

      const formattedValorUnitarioMoedaCalculado = unnormalizeNumeral(
        valorUnitarioMoedaCalculado,
        formatBrazilianNumericDecimal(7)
      );

      form.change('valorUnitarioMoeda', formattedValorUnitarioMoedaCalculado);
    },
    [getTipoCalculoValue]
  );

  const handleRelacaoFornecedorChange = useCallback(
    (form, values) => (value) => {
      if (isSubCreate || isSubUpdate) {
        if (isFillCommonDataMode && isFirstFormLoadValues) {
          setIsFirstFormLoadValues(false);
          return;
        }
        if (value === RELACAO_FABRICANTE_EXPORTADOR) {
          form.change('paisOrigem', undefined);
          form.change('fabricante', undefined);
          form.change('fabricante.id', values?.exportador?.id);
          return;
        }
        if (value === RELACAO_FABRICANTE_NAO_EXPORTADOR) {
          form.change('paisOrigem', undefined);
          form.change('fabricante', undefined);
          return;
        }
        if (value === RELACAO_FABRICANTE_DESCONHECIDO) {
          form.change('fabricante', undefined);
          return;
        }

        form.restart();
        form.initialize({
          ...values,
          relacao: undefined,
          exportador: undefined,
          fabricante: undefined,
          paisOrigem: undefined,
        });
      }
    },
    [isFirstFormLoadValues, isFillCommonDataMode, isSubCreate, isSubUpdate, setIsFirstFormLoadValues]
  );

  const handleExportadorIdChange = useCallback(
    (form, values) => (value) => {
      if (values?.relacao === RELACAO_FABRICANTE_EXPORTADOR) {
        form.change('fabricante', undefined);
        form.change('fabricante.id', value);
        return;
      }
      if (values?.relacao === RELACAO_FABRICANTE_NAO_EXPORTADOR) {
        if (values?.fabricante?.id !== value) {
          return;
        }
        form.change('fabricante', undefined);
        return;
      }
      if (values?.relacao === RELACAO_FABRICANTE_DESCONHECIDO) {
        form.change('fabricante', undefined);
        return;
      }
      form.change('exportador', undefined);
    },
    []
  );

  const handleEspecificacaoChange = useCallback(
    (form) => (value) => {
      if (!isEmpty(value)) {
        const blockedCharactersPattern = /[\u0300-\u036f\u00b4\u0060\u0026\u0023\u007e\u005e]/g;
        const parsed = String(value).normalize('NFD').replace(blockedCharactersPattern, '');

        form.change(especificacaoFieldName, parsed);
      }
    },
    [especificacaoFieldName]
  );

  const handleUnidadeMedidaOnChange = useCallback(
    (form, values) => (value) => {
      const unidade = unidadeMedidaList.find((u) => u.id === value);

      form.reset({
        ...values,
        unidadeDeMedida: unidade ?? { id: value },
      });
    },
    [unidadeMedidaList]
  );

  const handleUnidadeMedidaEstatisticaChange = useCallback(
    (form, values) => (value) => {
      const foundndUnidade = unidadeMedidaList.find((item) => item.id === value.id);
      const unidadeMedidaDescription = foundndUnidade?.description?.toUpperCase();

      if (!foundndUnidade) {
        return;
      }

      let isUnidade = false;
      let isQuilo = false;
      let isPeca = false;

      if (isString(unidadeMedidaDescription)) {
        isUnidade = unidadeMedidaDescription === UNIDADE_DE_MEDIDA_UNIDADE;
        isQuilo = unidadeMedidaDescription === UNIDADE_DE_MEDIDA_QUILOGRAMA_LIQUIDO;
        isPeca = unidadeMedidaDescription === UNIDADE_DE_MEDIDA_PECA;
      }

      let quantidadeEstatisticaValue = values?.quantidadeEstatistica;

      if ((isUnidade && values?.unidadeDeMedida?.code === '11') || isPeca) {
        quantidadeEstatisticaValue = values?.quantidade;
      }
      if (isQuilo) {
        quantidadeEstatisticaValue = values?.pesoLiquido;
      }
      form.reset({
        ...values,
        quantidadeEstatistica: quantidadeEstatisticaValue,
      });
    },
    [unidadeMedidaList]
  );

  const handlePesoLiquidoChange = useCallback(
    (form, values) => () => {
      const value = normalizeNumeral(values?.pesoLiquido);

      if (values?.ncm?.unidadeDeMedida?.description === UNIDADE_DE_MEDIDA_QUILOGRAMA_LIQUIDO) {
        form.change('quantidadeEstatistica', unnormalizeNumeral(value, formatBrazilianNumericDecimal(7)));
      }

      const quantidade = normalizeNumeral(values?.quantidade);
      if (quantidade !== 0 && normalizeNumeral(values?.pesoLiquidoUnitario) !== value / quantidade) {
        form.change('pesoLiquidoUnitario', unnormalizeNumeral(value / quantidade, formatBrazilianNumericDecimal(7)));
      }
    },
    []
  );

  // essa handler é chamada quando o input do seletor de produtos é alterado
  const handlePartnumberSelectorOnchange = useCallback(
    (form, values) => (value) => {
      const unsubscribeFromFilling = subscribeToFilling();

      const existingCatalogoProduto = listCatalogo.find((item) => item.partnumber === value);

      const suggestedRelacaoFornecedor = {
        relacao: faturaValues?.relacao,
        exportador: faturaValues?.exportador?.id
          ? {
              id: faturaValues?.exportador?.id,
            }
          : undefined,
        fabricante: faturaValues?.fabricante?.id
          ? {
              id: faturaValues?.fabricante?.id,
            }
          : undefined,
        paisOrigem: faturaValues?.paisOrigem?.id
          ? {
              id: faturaValues?.paisOrigem?.id,
            }
          : undefined,
      };

      const currentRelacaoFornecedor = {
        relacao: values?.relacao,
        exportador: values?.exportador?.id
          ? {
              id: values?.exportador?.id,
            }
          : undefined,
        fabricante: values?.fabricante?.id
          ? {
              id: values?.fabricante?.id,
            }
          : undefined,
        paisOrigem: values?.paisOrigem?.id
          ? {
              id: values?.paisOrigem?.id,
            }
          : undefined,
      };

      if (existingCatalogoProduto && !isEmpty(existingCatalogoProduto)) {
        const relatedRelacaoFornecedorCatalogoProduto = {
          relacao: existingCatalogoProduto?.relacao,
          exportador: existingCatalogoProduto?.exportador?.id ? existingCatalogoProduto?.exportador : undefined,
          fabricante: existingCatalogoProduto?.fabricante?.id ? existingCatalogoProduto?.fabricante : undefined,
          paisOrigem: existingCatalogoProduto?.paisOrigem?.id ? existingCatalogoProduto?.paisOrigem : undefined,
        };

        const restOfRelatedFieldsCatalogoProduto = {
          partnumber: existingCatalogoProduto?.partnumber,
          produto: {
            id: existingCatalogoProduto?.id,
          },
          descricaoResumida: existingCatalogoProduto?.apelido || existingCatalogoProduto?.descricaoResumida,
          pesoLiquidoUnitario: unnormalizeNumeral(
            existingCatalogoProduto?.pesoLiquido,
            formatBrazilianNumericDecimal(7)
          ),
          valorUnitarioMoeda: unnormalizeNumeral(
            existingCatalogoProduto?.valorUnitarioMoeda,
            formatBrazilianNumericDecimal(5)
          ),
          unidadeDeMedida: existingCatalogoProduto?.unidadeDeMedida?.id
            ? existingCatalogoProduto?.unidadeDeMedida
            : undefined,
          ncm: existingCatalogoProduto?.ncm?.id ? existingCatalogoProduto?.ncm : undefined,
          naladiNcca: existingCatalogoProduto?.naladiNcca?.id ? existingCatalogoProduto?.naladiNcca : undefined,
          naladiSh: existingCatalogoProduto?.naladiSh?.id ? existingCatalogoProduto?.naladiSh : undefined,
          tipoCalculo: existingCatalogoProduto?.tipoCalculo || 'QUANTIDADE_X_VALOR',
          icms: unnormalizeNumeral(existingCatalogoProduto?.icms, formatBrazilianNumericDecimal(2)),
          destaqueNcm: existingCatalogoProduto?.destaqueNcm,
          adicionais: {
            ...values?.adicionais,
            numeroSerie: existingCatalogoProduto?.numeroSerie,
            marca: existingCatalogoProduto?.marca,
            modelo: existingCatalogoProduto?.modelo,
            dataValidade: existingCatalogoProduto?.dataValidade,
            anoFabricacao: `${existingCatalogoProduto?.anoFabricacao}`,
            lote: existingCatalogoProduto?.lote,
            especificacao: existingCatalogoProduto?.especificacao,
            especificacaoCompleta: existingCatalogoProduto?.especificacaoCompleta,
          },
        };

        const {
          partnumber,
          produto,
          descricaoResumida,
          pesoLiquidoUnitario,
          valorUnitarioMoeda,
          unidadeDeMedida,
          ncm,
          naladiNcca,
          naladiSh,
          tipoCalculo,
          icms,
          destaqueNcm,
          adicionais,
        } = restOfRelatedFieldsCatalogoProduto;

        form.change('partnumber', partnumber);
        form.change('produto', produto);
        form.change('descricaoResumida', descricaoResumida);
        form.change('pesoLiquidoUnitario', pesoLiquidoUnitario);
        form.change('valorUnitarioMoeda', valorUnitarioMoeda);
        form.change('valorTotalMoeda', '');
        form.change('unidadeDeMedida', unidadeDeMedida);
        form.change('ncm', ncm);
        form.change('naladiNcca', naladiNcca);
        form.change('naladiSh', naladiSh);
        form.change('tipoCalculo', tipoCalculo);
        form.change('icms', icms);
        form.change('destaqueNcm', destaqueNcm);
        form.change('adicionais', adicionais);

        if (
          !isEqual(suggestedRelacaoFornecedor, relatedRelacaoFornecedorCatalogoProduto) &&
          !isSobrescreveDadosPartnumber
        ) {
          form.change('relacao', relatedRelacaoFornecedorCatalogoProduto.relacao);
          form.change('exportador', relatedRelacaoFornecedorCatalogoProduto.exportador);
          form.change('fabricante', relatedRelacaoFornecedorCatalogoProduto.fabricante);
          form.change('paisOrigem', relatedRelacaoFornecedorCatalogoProduto.paisOrigem);

          unsubscribeFromFilling();

          return;
        }

        if (
          !isEqual(suggestedRelacaoFornecedor, relatedRelacaoFornecedorCatalogoProduto) &&
          !isEqual(suggestedRelacaoFornecedor, currentRelacaoFornecedor) &&
          isSobrescreveDadosPartnumber
        ) {
          form.change('relacao', suggestedRelacaoFornecedor.relacao);
          form.change('exportador', suggestedRelacaoFornecedor.exportador);
          form.change('fabricante', suggestedRelacaoFornecedor.fabricante);
          form.change('paisOrigem', suggestedRelacaoFornecedor.paisOrigem);

          unsubscribeFromFilling();

          return;
        }

        unsubscribeFromFilling();

        return;
      }

      form.change('partnumberAutocompleteSelector', value);
      form.change('partnumber', value);

      if (values?.produto?.id) {
        form.change('produto', undefined);
        form.change('descricaoResumida', '');
        form.change('pesoLiquidoUnitario', '');
        form.change('valorUnitarioMoeda', '');
        form.change('valorTotalMoeda', '');
        form.change('unidadeDeMedida', undefined);
        form.change('ncm', undefined);
        form.change('naladiNcca', undefined);
        form.change('naladiSh', undefined);
        form.change('quantidadeEstatistica', '');
        form.change('tipoCalculo', 'QUANTIDADE_X_VALOR');
        form.change('icms', '');
        form.change('destaqueNcm', '');
        form.change('adicionais.numeroSerie', '');
        form.change('adicionais.marca', '');
        form.change('adicionais.modelo', '');
        form.change('adicionais.dataValidade', '');
        form.change('adicionais.anoFabricacao', '');
        form.change('adicionais.lote', '');
        form.change('adicionais.especificacao', '');

        if (!faturaValues?.atributosAdicionais?.tipo === TIPO_DUE) {
          form.resetFieldState('icms');
          form.resetFieldState('destaqueNcm');
          form.resetFieldState('adicionais.numeroSerie');
          form.resetFieldState('adicionais.marca');
          form.resetFieldState('adicionais.modelo');
          form.resetFieldState('adicionais.dataValidade');
          form.resetFieldState('adicionais.anoFabricacao');
          form.resetFieldState('adicionais.lote');
        }

        form.resetFieldState('descricaoResumida');
        // form.resetFieldState('pesoLiquidoUnitario');
        form.resetFieldState('quantidadeEstatistica');
        form.resetFieldState('tipoCalculo');
        form.resetFieldState('valorTotalMoeda');
        form.resetFieldState('valorUnitarioMoeda');
        form.resetFieldState('adicionais.especificacao');
      }

      if (!isEqual(suggestedRelacaoFornecedor, currentRelacaoFornecedor) && isFillCommonDataMode) {
        form.change('relacao', suggestedRelacaoFornecedor.relacao);
        form.change('exportador', suggestedRelacaoFornecedor.exportador);
        form.change('fabricante', suggestedRelacaoFornecedor.fabricante);
        form.change('paisOrigem', suggestedRelacaoFornecedor.paisOrigem);
      }

      unsubscribeFromFilling();
    },
    [faturaValues, listCatalogo, isFillCommonDataMode, subscribeToFilling, isSobrescreveDadosPartnumber]
  );

  const handlePartnumberAutocompleteOnchange = useCallback(
    (form, values) => (value) => {
      if (value === values?.partnumber) {
        return;
      }

      const unsubscribeFromFilling = subscribeToFilling();
      const quantidade = normalizeNumeral(values?.quantidade);

      const existingCatalogoProduto = listCatalogo.find((item) => item.partnumber === value);

      const suggestedRelacaoFornecedor = {
        relacao: faturaValues?.relacao,
        exportador: faturaValues?.exportador?.id
          ? {
              id: faturaValues?.exportador?.id,
            }
          : undefined,
        fabricante: faturaValues?.fabricante?.id
          ? {
              id: faturaValues?.fabricante?.id,
            }
          : undefined,
        paisOrigem: faturaValues?.paisOrigem?.id
          ? {
              id: faturaValues?.paisOrigem?.id,
            }
          : undefined,
      };

      const currentRelacaoFornecedor = {
        relacao: values?.relacao,
        exportador: values?.exportador?.id
          ? {
              id: values?.exportador?.id,
            }
          : undefined,
        fabricante: values?.fabricante?.id
          ? {
              id: values?.fabricante?.id,
            }
          : undefined,
        paisOrigem: values?.paisOrigem?.id
          ? {
              id: values?.paisOrigem?.id,
            }
          : undefined,
      };

      if (existingCatalogoProduto) {
        const relatedRelacaoFornecedorCatalogoProduto = {
          relacao: existingCatalogoProduto?.relacao,
          exportador: existingCatalogoProduto?.exportador?.id ? existingCatalogoProduto?.exportador : undefined,
          fabricante: existingCatalogoProduto?.fabricante?.id ? existingCatalogoProduto?.fabricante : undefined,
          paisOrigem: existingCatalogoProduto?.paisOrigem?.id ? existingCatalogoProduto?.paisOrigem : undefined,
        };

        const restOfRelatedFieldsCatalogoProduto = {
          partnumber: existingCatalogoProduto?.partnumber,
          produto: {
            id: existingCatalogoProduto?.id,
          },
          descricaoResumida: existingCatalogoProduto?.apelido || existingCatalogoProduto?.descricaoResumida,
          pesoLiquidoUnitario: unnormalizeNumeral(
            existingCatalogoProduto?.pesoLiquido,
            formatBrazilianNumericDecimal(7)
          ),
          valorUnitarioMoeda: unnormalizeNumeral(
            existingCatalogoProduto?.valorUnitarioMoeda,
            formatBrazilianNumericDecimal(5)
          ),
          unidadeDeMedida: existingCatalogoProduto?.unidadeDeMedida?.id
            ? existingCatalogoProduto?.unidadeDeMedida
            : undefined,
          ncm: existingCatalogoProduto?.ncm?.id ? existingCatalogoProduto?.ncm : undefined,
          naladiNcca: existingCatalogoProduto?.naladiNcca?.id ? existingCatalogoProduto?.naladiNcca : undefined,
          naladiSh: existingCatalogoProduto?.naladiSh?.id ? existingCatalogoProduto?.naladiSh : undefined,
          tipoCalculo: existingCatalogoProduto?.tipoCalculo || 'QUANTIDADE_X_VALOR',
          icms: unnormalizeNumeral(existingCatalogoProduto?.icms, formatBrazilianNumericDecimal(2)),
          destaqueNcm: existingCatalogoProduto?.destaqueNcm,
          adicionais: {
            ...values?.adicionais,
            numeroSerie: existingCatalogoProduto?.numeroSerie,
            marca: existingCatalogoProduto?.marca,
            modelo: existingCatalogoProduto?.modelo,
            dataValidade: existingCatalogoProduto?.dataValidade,
            anoFabricacao: existingCatalogoProduto?.anoFabricacao ? `${existingCatalogoProduto?.anoFabricacao}` : null,
            lote: existingCatalogoProduto?.lote,
            especificacao: existingCatalogoProduto?.especificacao,
            especificacaoCompleta: existingCatalogoProduto?.especificacaoCompleta,
          },
        };

        const {
          partnumber,
          produto,
          descricaoResumida,
          pesoLiquidoUnitario,
          valorUnitarioMoeda,
          unidadeDeMedida,
          ncm,
          naladiNcca,
          naladiSh,
          tipoCalculo,
          icms,
          destaqueNcm,
          adicionais,
        } = restOfRelatedFieldsCatalogoProduto;

        const novoPesoLiquido = normalizeNumeral(pesoLiquidoUnitario) * quantidade;

        form.change('partnumber', partnumber);
        form.change('produto', produto);
        form.change('descricaoResumida', descricaoResumida);
        form.change('pesoLiquidoUnitario', pesoLiquidoUnitario);
        form.change('pesoLiquido', unnormalizeNumeral(novoPesoLiquido, formatBrazilianNumericDecimal(7)));
        form.change('valorUnitarioMoeda', valorUnitarioMoeda);
        form.change('valorTotalMoeda', '');
        form.change('unidadeDeMedida', unidadeDeMedida);
        form.change('ncm', ncm);
        form.change('naladiNcca', naladiNcca);
        form.change('naladiSh', naladiSh);
        form.change('tipoCalculo', tipoCalculo);
        form.change('icms', icms);
        form.change('destaqueNcm', destaqueNcm);
        form.change('adicionais', adicionais);

        if (
          !isEqual(suggestedRelacaoFornecedor, relatedRelacaoFornecedorCatalogoProduto) &&
          !isSobrescreveDadosPartnumber
        ) {
          form.change('relacao', relatedRelacaoFornecedorCatalogoProduto.relacao);
          form.change('exportador', relatedRelacaoFornecedorCatalogoProduto.exportador);
          form.change('fabricante', relatedRelacaoFornecedorCatalogoProduto.fabricante);
          form.change('paisOrigem', relatedRelacaoFornecedorCatalogoProduto.paisOrigem);

          unsubscribeFromFilling();

          return;
        }

        if (
          !isEqual(suggestedRelacaoFornecedor, relatedRelacaoFornecedorCatalogoProduto) &&
          !isEqual(suggestedRelacaoFornecedor, currentRelacaoFornecedor) &&
          isSobrescreveDadosPartnumber
        ) {
          form.change('relacao', suggestedRelacaoFornecedor.relacao);
          form.change('exportador', suggestedRelacaoFornecedor.exportador);
          form.change('fabricante', suggestedRelacaoFornecedor.fabricante);
          form.change('paisOrigem', suggestedRelacaoFornecedor.paisOrigem);

          unsubscribeFromFilling();

          return;
        }

        unsubscribeFromFilling();

        return;
      }

      form.change('partnumberAutocompleteSelector', value);
      form.change('partnumber', value);

      if (values?.produto?.id) {
        form.change('produto', undefined);
        form.change('descricaoResumida', '');
        form.change('pesoLiquido', '');
        form.change('pesoLiquidoUnitario', '');
        form.change('valorUnitarioMoeda', '');
        form.change('valorTotalMoeda', '');
        form.change('unidadeDeMedida', undefined);
        form.change('ncm', undefined);
        form.change('naladiNcca', undefined);
        form.change('naladiSh', undefined);
        form.change('quantidadeEstatistica', '');
        form.change('tipoCalculo', 'QUANTIDADE_X_VALOR');
        form.change('icms', '');
        form.change('destaqueNcm', '');
        form.change('adicionais.numeroSerie', '');
        form.change('adicionais.marca', '');
        form.change('adicionais.modelo', '');
        form.change('adicionais.dataValidade', '');
        form.change('adicionais.anoFabricacao', '');
        form.change('adicionais.lote', '');
        form.change('adicionais.especificacao', '');

        if (!faturaValues?.atributosAdicionais?.tipo === TIPO_DUE) {
          form.resetFieldState('icms');
          form.resetFieldState('destaqueNcm');
          form.resetFieldState('adicionais.numeroSerie');
          form.resetFieldState('adicionais.marca');
          form.resetFieldState('adicionais.modelo');
          form.resetFieldState('adicionais.dataValidade');
          form.resetFieldState('adicionais.anoFabricacao');
          form.resetFieldState('adicionais.lote');
        }

        form.resetFieldState('descricaoResumida');
        // Essa função em tese era usada para limpar os campos do formulário.
        // porém ela estava causando uma quebra ao entrar na tela
        // possivelmente ela aciona uma validação que não existe
        // remover os campos resolveu o problema, cuidado ao voltar
        // form.resetFieldState('pesoLiquido');
        // form.resetFieldState('pesoLiquidoUnitario');
        form.resetFieldState('quantidadeEstatistica');
        form.resetFieldState('tipoCalculo');
        form.resetFieldState('valorTotalMoeda');
        form.resetFieldState('valorUnitarioMoeda');
        form.resetFieldState('adicionais.especificacao');
      }

      if (!isEqual(suggestedRelacaoFornecedor, currentRelacaoFornecedor) && isFillCommonDataMode) {
        form.change('relacao', suggestedRelacaoFornecedor.relacao);
        form.change('exportador', suggestedRelacaoFornecedor.exportador);
        form.change('fabricante', suggestedRelacaoFornecedor.fabricante);
        form.change('paisOrigem', suggestedRelacaoFornecedor.paisOrigem);
      }

      unsubscribeFromFilling();
    },
    [faturaValues, listCatalogo, isFillCommonDataMode, subscribeToFilling, isSobrescreveDadosPartnumber]
  );

  const ncmSubitemOnChange = useCallback(
    (form) => async (value) => {
      if (!value) {
        return;
      }
      const responseNcmSubItem = await fetchNcmSubItemById(value);
      if (responseNcmSubItem?.status === 200 && !isEmpty(responseNcmSubItem) && responseNcmSubItem?.data) {
        const findNcm = responseNcmSubItem?.data;
        form.change('ncm.unidadeDeMedida.description', findNcm?.unidadeDeMedida?.description || '');
      }
    },
    [isConsult]
  );

  const handlePesoLiquidoUnitarioOnchange = useCallback(
    (form, values) => () => {
      const value = values?.pesoLiquidoUnitario;

      if (!value || !values?.quantidade) {
        return;
      }

      const quantidade = normalizeNumeral(values?.quantidade);
      const pesoUnitario = normalizeNumeral(value);

      // se o valor arredondado dos pesos liquidos unitarios for o mesmo,
      // não faz a alteração
      const pesoLiquidoUnitarioFromPesoLiquido = normalizeNumeral(values?.pesoLiquido) / quantidade;
      if (pesoLiquidoUnitarioFromPesoLiquido.toFixed(7) === pesoUnitario.toFixed(7)) {
        return;
      }

      const novoPesoLiquido = pesoUnitario * quantidade;

      if (novoPesoLiquido === normalizeNumeral(values?.pesoLiquido)) {
        return;
      }

      form.change('pesoLiquido', unnormalizeNumeral(novoPesoLiquido, formatBrazilianNumericDecimal(7)));
    },
    []
  );

  const handleDescontoPercentualOnBlur = useCallback(
    (form, values) => () => {
      const desconto = normalizeNumeral(values?.valorDescontoMoeda);
      const descPercentual = normalizeNumeral(values?.percentualDesconto);
      const valorTotal = normalizeNumeral(values?.valorTotalMoeda);

      if (desconto !== (valorTotal * descPercentual) / 100) {
        form.change(
          'valorDescontoMoeda',
          unnormalizeNumeral((valorTotal * descPercentual) / 100, formatBrazilianNumericDecimal(2))
        );
      }
    },
    []
  );

  const handleDescontoValorOnBlur = useCallback(
    (form, values) => () => {
      const desconto = normalizeNumeral(values?.valorDescontoMoeda);
      const descPercentual = normalizeNumeral(values?.percentualDesconto);
      const valorTotal = normalizeNumeral(values?.valorTotalMoeda);

      if (descPercentual !== (desconto / valorTotal) * 100) {
        form.change(
          'percentualDesconto',
          unnormalizeNumeral((desconto / valorTotal) * 100, formatBrazilianNumericDecimal(2))
        );
      }
    },
    []
  );

  const onChangeListeners = useMemo(
    () => [
      {
        name: 'relacao',
        fn: handleRelacaoFornecedorChange,
      },
      {
        name: 'exportador.id',
        fn: handleExportadorIdChange,
      },
      {
        name: especificacaoFieldName,
        fn: handleEspecificacaoChange,
      },
      {
        name: 'unidadeDeMedida.id',
        fn: handleUnidadeMedidaOnChange,
      },
      {
        name: 'ncm.unidadeDeMedida',
        fn: handleUnidadeMedidaEstatisticaChange,
      },
      {
        name: 'partNumberSelector',
        fn: handlePartnumberSelectorOnchange,
      },
      {
        name: 'partnumberAutocompleteSelector',
        fn: handlePartnumberAutocompleteOnchange,
      },
      {
        name: 'ncm.id',
        fn: ncmSubitemOnChange,
      },
    ],
    [
      handleRelacaoFornecedorChange,
      handleExportadorIdChange,
      handleEspecificacaoChange,
      handleUnidadeMedidaOnChange,
      handlePesoLiquidoChange,
      handlePartnumberSelectorOnchange,
      handlePartnumberAutocompleteOnchange,
      handlePesoLiquidoUnitarioOnchange,
      ncmSubitemOnChange,
      especificacaoFieldName,
    ]
  );

  const onBlurObjectHandlers = useMemo(
    () => ({
      handleValorTotalMoedaOnBlur,
      handleValorUnitarioMoedaProdutoOnBlur,
      handleQuantidadeOnBlur,
      handlePesoLiquidoOnBlur: handlePesoLiquidoChange, // alteração de nome para não precisar alterar interno, pode mudar se souver o q esta fazendo
      handlePesoLiquidoUnitarioOnBlur: handlePesoLiquidoUnitarioOnchange,
      handleDescontoPercentualOnBlur,
      handleDescontoValorOnBlur,
    }),
    [
      handleValorTotalMoedaOnBlur,
      handleValorUnitarioMoedaProdutoOnBlur,
      handleQuantidadeOnBlur,
      handlePesoLiquidoChange,
      handlePesoLiquidoUnitarioOnchange,
    ]
  );

  return [onBlurObjectHandlers, onChangeListeners];
}
