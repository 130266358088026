import { isFunction } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import {
  CLOSED_STATUS,
  OPEN_STATUS,
  isDoneStatus,
  isFailStatus,
} from './formDialogUtils';

export default function useFormDialogAsync(callback, deps = []) {
  const [status, setStatus] = useState(CLOSED_STATUS);
  const [savedArgs, setSavedArgs] = useState([]);

  useEffect(() => {
    let unmounted = false;

    if (!unmounted) {
      if (isDoneStatus(status) && isFunction(callback)) {
        callback.call(this, ...savedArgs);
        setSavedArgs([]);
        setStatus(CLOSED_STATUS);
      }

      if (isFailStatus(status)) {
        setStatus(OPEN_STATUS);
      }
    }

    return () => {
      unmounted = true;
    };
  }, [status, callback, savedArgs]);

  const composedCallback = useCallback((...args) => {
    setSavedArgs(args);
    setStatus(OPEN_STATUS);
  }, deps);

  return [
    composedCallback,
    status,
    setStatus,
  ];
}
