import { getOptionByValueInList } from '../general-utils';
import i18n from '../../../i18n';

const PROCESSO_ABERTO = 'PROCESSO_ABERTO';
const FATURA_AGUARDANDO_PROCESSAMENTO = 'FATURA_AGUARDANDO_PROCESSAMENTO';
const FATURA_PROCESSAMENTO = 'FATURA_PROCESSAMENTO';
const FATURA_PROCESSADA = 'FATURA_PROCESSADA';
const FATURA_ERRO = 'FATURA_ERRO';
const CE_BLOQUEADO = 'CE_BLOQUEADO';
const PROCESSO_CANCELADO = 'PROCESSO_CANCELADO';
const AGUARDANDO_CONFERENCIA_DI = 'AGUARDANDO_CONFERENCIA_DI';
const AGUARDANDO_LIBERACAO_REGISTRO_LI = 'AGUARDANDO_LIBERACAO_REGISTRO_LI';
const AGUARDANDO_LIBERACAO_REGISTRO_DI = 'AGUARDANDO_LIBERACAO_REGISTRO_DI';
const LI_XML_RECUPERADO = 'LI_XML_RECUPERADO';
const LI_EXTRATO_RECUPERADO = 'LI_EXTRATO_RECUPERADO';
const LI_ACOMPANHAMENTO_ENCERRADO = 'LI_ACOMPANHAMENTO_ENCERRADO';
const LI_DRAFT_SISCOMEX_SALVO = 'LI_DRAFT_SISCOMEX_SALVO';
const DI_XML_RECUPERADO = 'DI_XML_RECUPERADO';
const DI_EXTRATO_RECUPERADO = 'DI_EXTRATO_RECUPERADO';
const DI_COMPROVANTE_RECUPERADO = 'DI_COMPROVANTE_RECUPERADO';
const DI_PARAMETRIZACAO_CONCLUIDA = 'DI_PARAMETRIZACAO_CONCLUIDA';
const CHEGADA_CARGA_CONCLUIDA = 'CHEGADA_CARGA_CONCLUIDA';
const DANFE_EMITIDA = 'DANFE_EMITIDA';
const ICMS_EMITIDO = 'ICMS_EMITIDO';
const DUE_REGISTRADA = 'DUE_REGISTRADA';
const DUE_ACOMPANHAMENTO_ENCERRADO = 'DUE_ACOMPANHAMENTO_ENCERRADO';
const FATURADO = 'FATURADO';
const AGUARDANDO_VINCULACAO_ATO_CONCESSORIO_DRAWBACK_ISENCAO = 'AGUARDANDO_VINCULACAO_ATO_CONCESSORIO_DRAWBACK_ISENCAO';
const ALTERACAO_DA_UNIDADE_RFB = 'ALTERACAO_DA_UNIDADE_RFB';
const ALTERACAO_DUE = 'ALTERACAO_DUE';
const BLOQUEIO_EMBARQUE = 'BLOQUEIO_EMBARQUE';
const CARGA_APRESENTADA_PARA_DESPACHO = 'CARGA_APRESENTADA_PARA_DESPACHO';
const CONCLUSAO_ANALISE_FISCAL = 'CONCLUSAO_ANALISE_FISCAL';
const CONCLUSAO_DA_CONFERENCIA_ADUANA = 'CONCLUSAO_DA_CONFERENCIA_ADUANA';
const DEFERIMENTO_DE_LPCO = 'DEFERIMENTO_DE_LPCO';
const DEFERIMENTO_SOLICITACAO_RETIFICACAO = 'DEFERIMENTO_SOLICITACAO_RETIFICACAO';
const DESBLOQUEIO_EMBARQUE = 'DESBLOQUEIO_EMBARQUE';
const DESVINCULACAO_ATO_CONCESSORIO_DRAWBACK_ISENCAO = 'DESVINCULACAO_ATO_CONCESSORIO_DRAWBACK_ISENCAO';
const DISTRIBUICAO = 'DISTRIBUICAO';
const EM_ANALISE_FISCAL = 'EM_ANALISE_FISCAL';
const EMBARQUE_ANTECIPADO_COM_PENDENCIA_LPCO = 'EMBARQUE_ANTECIPADO_COM_PENDENCIA_LPCO';
const EMBARQUE_ANTECIPADO_PENDENTE_DE_AUTORIZACAO = 'EMBARQUE_ANTECIPADO_PENDENTE_DE_AUTORIZACAO';
const EXIGENCIA_FISCAL_LIBERADA = 'EXIGENCIA_FISCAL_LIBERADA';
const EXIGENCIA_FISCAL_REGISTRADA = 'EXIGENCIA_FISCAL_REGISTRADA';
const INCLUSAO_DE_EXIGENCIA_FISCAL_POS_DESEMBARACO = 'INCLUSAO_DE_EXIGENCIA_FISCAL_POS_DESEMBARACO';
const INDEFERIMENTO_SOLICITACAO_CANCELAMENTO = 'INDEFERIMENTO_SOLICITACAO_CANCELAMENTO';
const INDEFERIMENTO_SOLICITACAO_RETIFICACAO = 'INDEFERIMENTO_SOLICITACAO_RETIFICACAO';
const INTERROMPIDA = 'INTERROMPIDA';
const LIBERACAO_DE_EXIGENCIA_FISCAL_POS_DESEMBARACO = 'LIBERACAO_DE_EXIGENCIA_FISCAL_POS_DESEMBARACO';
const LIBERADA_SEM_CONFERENCIA_ADUANEIRA = 'LIBERADA_SEM_CONFERENCIA_ADUANEIRA';
const MOVIMENTACAO_EQUIPE_ANALISE = 'MOVIMENTACAO_EQUIPE_ANALISE';
const REDISTRIBUICAO = 'REDISTRIBUICAO';
const REGISTRADA = 'REGISTRADA';
const RESULTADO_VINCULACAO_ATO_CONCESSORIO_DRAWBACK_ISENCAO = 'RESULTADO_VINCULACAO_ATO_CONCESSORIO_DRAWBACK_ISENCAO';
const SELECAO_DA_SOLICITACAO_DE_CANCELAMENTO_PARA_ANALISE_FISCAL =
  'SELECAO_DA_SOLICITACAO_DE_CANCELAMENTO_PARA_ANALISE_FISCAL';
const SELECAO_DA_SOLICITACAO_DE_RETIFICACAO_PARA_ANALISE_FISCAL =
  'SELECAO_DA_SOLICITACAO_DE_RETIFICACAO_PARA_ANALISE_FISCAL';
const SELECIONADA_PARA_FISCALIZACAO = 'SELECIONADA_PARA_FISCALIZACAO';
const SOLICITACAO_ANALISE = 'SOLICITACAO_ANALISE';
const SOLICITACAO_CANCELAMENTO = 'SOLICITACAO_CANCELAMENTO';
const SOLICITACAO_DISTRIBUICAO = 'SOLICITACAO_DISTRIBUICAO';
const SOLICITACAO_REDISTRIBUICAO = 'SOLICITACAO_REDISTRIBUICAO';
const SOLICITACAO_RETIFICACAO = 'SOLICITACAO_RETIFICACAO';
const AVERBADA_SEM_DIVERGENCIA = 'AVERBADA_SEM_DIVERGENCIA';
const CANCELADA_PELA_ADUANA = 'CANCELADA_PELA_ADUANA';
const CANCELADA_PELA_ADUANA_A_PEDIDO_DO_EXPORTADOR = 'CANCELADA_PELA_ADUANA_A_PEDIDO_DO_EXPORTADOR';
const CANCELADA_PELO_EXPORTADOR = 'CANCELADA_PELO_EXPORTADOR';
const CANCELADA_POR_EXPIRACAO_DE_PRAZO = 'CANCELADA_POR_EXPIRACAO_DE_PRAZO';
const CANCELAMENTO_SOLICITACAO_CANCELAMENTO = 'CANCELAMENTO_SOLICITACAO_CANCELAMENTO';
const CANCELAMENTO_SOLICITACAO_RETIFICACAO = 'CANCELAMENTO_SOLICITACAO_RETIFICACAO';
const CARGA_COMPLETAMENTE_EXPORTADA = 'CARGA_COMPLETAMENTE_EXPORTADA';
const DEFERIMENTO_SOLICITACAO_CANCELAMENTO = 'DEFERIMENTO_SOLICITACAO_CANCELAMENTO';
const DESEMBARACADA = 'DESEMBARACADA';
const DESEMBARACADA_COM_PENDENCIA_LPCO = 'DESEMBARACADA_COM_PENDENCIA_LPCO';
const EMBARQUE_ANTECIPADO_AUTORIZADO = 'EMBARQUE_ANTECIPADO_AUTORIZADO';
const DI_XML_NAO_RECUPERADO_NENHUM_DADO_RETORNADO_DO_SISCOMEX = 'DI_XML_NAO_RECUPERADO_NENHUM_DADO_RETORNADO_DO_SISCOMEX';
const DI_XML_JA_RECUPERADO = 'DI_XML_JA_RECUPERADO';

const LIST = [
  {
    label: 'com.muralis.qcx.followUpStatus.processoAberto',
    value: PROCESSO_ABERTO,
  },
  {
    label: 'com.muralis.qcx.fatura.faturaAguardandoProcessamento',
    value: FATURA_AGUARDANDO_PROCESSAMENTO,
  },
  {
    label: 'com.muralis.qcx.fatura.faturaProcessamento',
    value: FATURA_PROCESSAMENTO,
  },
  {
    label: 'com.muralis.qcx.fatura.faturaProcessada',
    value: FATURA_PROCESSADA,
  },
  {
    label: 'com.muralis.qcx.erro.erroProcessamentoFatura',
    value: FATURA_ERRO,
  },
  {
    label: 'com.muralis.qcx.fatura.CEBloqueado',
    value: CE_BLOQUEADO,
  },
  {
    label: 'com.muralis.qcx.followUpStatus.processoCancelado',
    value: PROCESSO_CANCELADO,
  },
  {
    label: 'com.muralis.qcx.aguardandoConferenciaDI',
    value: AGUARDANDO_CONFERENCIA_DI,
  },
  {
    label: 'com.muralis.qcx.aguardandoLiberacaoRegistroLI',
    value: AGUARDANDO_LIBERACAO_REGISTRO_LI,
  },
  {
    label: 'com.muralis.qcx.aguardandoLiberacaoRegistroDI',
    value: AGUARDANDO_LIBERACAO_REGISTRO_DI,
  },
  {
    label: 'com.muralis.qcx.registroLIRecuperado',
    value: LI_XML_RECUPERADO,
  },
  {
    label: 'com.muralis.qcx.extratoLIRecuperado',
    value: LI_EXTRATO_RECUPERADO,
  },
  {
    label: 'com.muralis.qcx.acompanhamentoLIEncerrado',
    value: LI_ACOMPANHAMENTO_ENCERRADO,
  },
  {
    label: 'com.muralis.qcx.DANFE.DANFEemitida',
    value: DANFE_EMITIDA,
  },
  {
    label: 'com.muralis.qcx.mensagem.draftLISalvoSiscomex',
    value: LI_DRAFT_SISCOMEX_SALVO,
  },
  {
    label: 'com.muralis.qcx.registroDIRecuperado',
    value: DI_XML_RECUPERADO,
  },
  {
    label: 'com.muralis.qcx.extratoDIRecuperado',
    value: DI_EXTRATO_RECUPERADO,
  },
  {
    label: 'com.muralis.qcx.comprovanteDIRecuperado',
    value: DI_COMPROVANTE_RECUPERADO,
  },
  {
    label: 'com.muralis.qcx.DIDUIMP.parametrizacaoDIConcluida',
    value: DI_PARAMETRIZACAO_CONCLUIDA,
  },
  {
    label: 'com.muralis.qcx.carga.chegadaCargaConcluida',
    value: CHEGADA_CARGA_CONCLUIDA,
  },
  {
    label: 'com.muralis.qcx.ICMS.ICMSEmitido',
    value: ICMS_EMITIDO,
  },
  {
    label: 'com.muralis.qcx.dueExportacao.DUERegistrada',
    value: DUE_REGISTRADA,
  },
  {
    label: 'com.muralis.qcx.dueExportacao.acompanhamentoDueEncerrado',
    value: DUE_ACOMPANHAMENTO_ENCERRADO,
  },
  {
    label: 'com.muralis.qcx.faturado',
    value: FATURADO,
  },
  {
    label: 'com.muralis.qcx.followUpStatus.aguardandoVinculacaoAtoConcessorioDrawbackIsencao',
    value: AGUARDANDO_VINCULACAO_ATO_CONCESSORIO_DRAWBACK_ISENCAO,
  },
  {
    label: 'com.muralis.qcx.followUpStatus.alteracaoDaUnidadeRfb',
    value: ALTERACAO_DA_UNIDADE_RFB,
  },
  {
    label: 'com.muralis.qcx.followUpStatus.alteracaoDue',
    value: ALTERACAO_DUE,
  },
  {
    label: 'com.muralis.qcx.followUpStatus.bloqueioEmbarque',
    value: BLOQUEIO_EMBARQUE,
  },
  {
    label: 'com.muralis.qcx.followUpStatus.cargaApresentadaParaDespacho',
    value: CARGA_APRESENTADA_PARA_DESPACHO,
  },
  {
    label: 'com.muralis.qcx.followUpStatus.conclusaoAnaliseFiscal',
    value: CONCLUSAO_ANALISE_FISCAL,
  },
  {
    label: 'com.muralis.qcx.followUpStatus.conclusaoDaConferenciaAduana',
    value: CONCLUSAO_DA_CONFERENCIA_ADUANA,
  },
  {
    label: 'com.muralis.qcx.followUpStatus.deferimentoDeLpco',
    value: DEFERIMENTO_DE_LPCO,
  },
  {
    label: 'com.muralis.qcx.followUpStatus.deferimentoSolicitacaoRetificacao',
    value: DEFERIMENTO_SOLICITACAO_RETIFICACAO,
  },
  {
    label: 'com.muralis.qcx.followUpStatus.desbloqueioEmbarque',
    value: DESBLOQUEIO_EMBARQUE,
  },
  {
    label: 'com.muralis.qcx.followUpStatus.desvinculacaoAtoConcessorioDrawbackIsencao',
    value: DESVINCULACAO_ATO_CONCESSORIO_DRAWBACK_ISENCAO,
  },
  {
    label: 'com.muralis.qcx.followUpStatus.distribuicao',
    value: DISTRIBUICAO,
  },
  {
    label: 'com.muralis.qcx.followUpStatus.emAnaliseFiscal',
    value: EM_ANALISE_FISCAL,
  },
  {
    label: 'com.muralis.qcx.followUpStatus.embarqueAntecipadoComPendenciaLpco',
    value: EMBARQUE_ANTECIPADO_COM_PENDENCIA_LPCO,
  },
  {
    label: 'com.muralis.qcx.followUpStatus.embarqueAntecipadoPendenteDeAutorizacao',
    value: EMBARQUE_ANTECIPADO_PENDENTE_DE_AUTORIZACAO,
  },
  {
    label: 'com.muralis.qcx.followUpStatus.exigenciaFiscalLiberada',
    value: EXIGENCIA_FISCAL_LIBERADA,
  },
  {
    label: 'com.muralis.qcx.followUpStatus.exigenciaFiscalRegistrada',
    value: EXIGENCIA_FISCAL_REGISTRADA,
  },
  {
    label: 'com.muralis.qcx.followUpStatus.inclusaoDeExigenciaFiscalPosDesembaraco',
    value: INCLUSAO_DE_EXIGENCIA_FISCAL_POS_DESEMBARACO,
  },
  {
    label: 'com.muralis.qcx.followUpStatus.indeferimentoSolicitacaoCancelamento',
    value: INDEFERIMENTO_SOLICITACAO_CANCELAMENTO,
  },
  {
    label: 'com.muralis.qcx.followUpStatus.indeferimentoSolicitacaoRetificacao',
    value: INDEFERIMENTO_SOLICITACAO_RETIFICACAO,
  },

  {
    label: 'com.muralis.qcx.followUpStatus.interrompida',
    value: INTERROMPIDA,
  },
  {
    label: 'com.muralis.qcx.followUpStatus.liberacaoExigenciaFiscalPosDesembaraco',
    value: LIBERACAO_DE_EXIGENCIA_FISCAL_POS_DESEMBARACO,
  },
  {
    label: 'com.muralis.qcx.followUpStatus.liberadaSemConferenciaAduaneira',
    value: LIBERADA_SEM_CONFERENCIA_ADUANEIRA,
  },
  {
    label: 'com.muralis.qcx.followUpStatus.movimentacaoEquipeAnalise',
    value: MOVIMENTACAO_EQUIPE_ANALISE,
  },
  {
    label: 'com.muralis.qcx.followUpStatus.redistribuicao',
    value: REDISTRIBUICAO,
  },
  {
    label: 'com.muralis.qcx.followUpStatus.registrada',
    value: REGISTRADA,
  },
  {
    label: 'com.muralis.qcx.followUpStatus.resultadoVinculacaoAtoConcessorioDrawbackIsencao',
    value: RESULTADO_VINCULACAO_ATO_CONCESSORIO_DRAWBACK_ISENCAO,
  },
  {
    label: 'com.muralis.qcx.followUpStatus.selecaoDaSolicitacaoDeCancelamentoParaAnaliseFiscal',
    value: SELECAO_DA_SOLICITACAO_DE_CANCELAMENTO_PARA_ANALISE_FISCAL,
  },
  {
    label: 'com.muralis.qcx.followUpStatus.selecaoDaSolicitacaoDeRetificacaoParaAnaliseFiscal',
    value: SELECAO_DA_SOLICITACAO_DE_RETIFICACAO_PARA_ANALISE_FISCAL,
  },
  {
    label: 'com.muralis.qcx.followUpStatus.selecionadaParaFiscalizacao',
    value: SELECIONADA_PARA_FISCALIZACAO,
  },
  {
    label: 'com.muralis.qcx.followUpStatus.solicitacaoAnalise',
    value: SOLICITACAO_ANALISE,
  },
  {
    label: 'com.muralis.qcx.followUpStatus.solicitacaoCancelamento',
    value: SOLICITACAO_CANCELAMENTO,
  },
  {
    label: 'com.muralis.qcx.followUpStatus.solicitacaoDistribuicao',
    value: SOLICITACAO_DISTRIBUICAO,
  },
  {
    label: 'com.muralis.qcx.followUpStatus.solicitacaoRedistribuicao',
    value: SOLICITACAO_REDISTRIBUICAO,
  },
  {
    label: 'com.muralis.qcx.followUpStatus.solicitacaoRetificacao',
    value: SOLICITACAO_RETIFICACAO,
  },
  {
    label: 'com.muralis.qcx.followUpStatus.averbadaSemDivergencia',
    value: AVERBADA_SEM_DIVERGENCIA,
  },
  {
    label: 'com.muralis.qcx.followUpStatus.canceladaPelaAduana',
    value: CANCELADA_PELA_ADUANA,
  },
  {
    label: 'com.muralis.qcx.followUpStatus.canceladaPelaAduanaPedidoExportador',
    value: CANCELADA_PELA_ADUANA_A_PEDIDO_DO_EXPORTADOR,
  },
  {
    label: 'com.muralis.qcx.followUpStatus.canceladaPeloExportador',
    value: CANCELADA_PELO_EXPORTADOR,
  },
  {
    label: 'com.muralis.qcx.followUpStatus.canceladaPorExpiracaoDePrazo',
    value: CANCELADA_POR_EXPIRACAO_DE_PRAZO,
  },
  {
    label: 'com.muralis.qcx.followUpStatus.cancelamentoSolicitacaoCancelamento',
    value: CANCELAMENTO_SOLICITACAO_CANCELAMENTO,
  },
  {
    label: 'com.muralis.qcx.followUpStatus.cancelamentoSolicitacaoRetificacao',
    value: CANCELAMENTO_SOLICITACAO_RETIFICACAO,
  },
  {
    label: 'com.muralis.qcx.followUpStatus.cargaCompletamenteExportada',
    value: CARGA_COMPLETAMENTE_EXPORTADA,
  },
  {
    label: 'com.muralis.qcx.followUpStatus.deferimentoSolicitacaoCancelamento',
    value: DEFERIMENTO_SOLICITACAO_CANCELAMENTO,
  },
  {
    label: 'com.muralis.qcx.followUpStatus.desembaracada',
    value: DESEMBARACADA,
  },
  {
    label: 'com.muralis.qcx.followUpStatus.desembaracadaComPendenciaLPCO',
    value: DESEMBARACADA_COM_PENDENCIA_LPCO,
  },
  {
    label: 'com.muralis.qcx.followUpStatus.embarqueAntecipadoAutorizado',
    value: EMBARQUE_ANTECIPADO_AUTORIZADO,
  },
  {
    label: 'com.muralis.qcx.followUpStatus.diXmlNaoRecuperadoNenhumDadoRetornadoDoSiscomex',
    value: DI_XML_NAO_RECUPERADO_NENHUM_DADO_RETORNADO_DO_SISCOMEX,
  },
  {
    label: 'com.muralis.qcx.followUpStatus.diXmlJaRecuperado',
    value: DI_XML_JA_RECUPERADO,
  },
];

const isProcessoAberto = (value) => PROCESSO_ABERTO === value;
const isFaturaAguardandoProcessamento = (value) => FATURA_AGUARDANDO_PROCESSAMENTO === value;
const isFaturaEmProcessamento = (value) => FATURA_PROCESSAMENTO === value;
const isFaturaProcessada = (value) => FATURA_PROCESSADA === value;
const isErroProcessamentoFatura = (value) => FATURA_ERRO === value;
const isCeBloqueado = (value) => CE_BLOQUEADO === value;
const isProcessoCancelado = (value) => PROCESSO_CANCELADO === value;
const isAguardandoConferenciaDi = (value) => AGUARDANDO_CONFERENCIA_DI === value;
const isAguardandoLiberacaoRegistroLi = (value) => AGUARDANDO_LIBERACAO_REGISTRO_LI === value;
const isAguardandoLiberacaoRegistroDi = (value) => AGUARDANDO_LIBERACAO_REGISTRO_DI === value;
const isRegistroLiRecuperado = (value) => LI_XML_RECUPERADO === value;
const isExtratoLiRecuperado = (value) => LI_EXTRATO_RECUPERADO === value;
const isParametrizacaoDiConcluida = (value) => DI_PARAMETRIZACAO_CONCLUIDA === value;
const isAcompanhamentoLiEncerrado = (value) => LI_ACOMPANHAMENTO_ENCERRADO === value;
const isRegistroDiRecuperado = (value) => DI_XML_RECUPERADO === value;
const isExtratoDiRecuperado = (value) => DI_EXTRATO_RECUPERADO === value;
const isComprovanteDiRecuperado = (value) => DI_COMPROVANTE_RECUPERADO === value;
const isChegadaCargaConcluida = (value) => CHEGADA_CARGA_CONCLUIDA === value;
const isIcmsEmitido = (value) => ICMS_EMITIDO === value;
const isDueRegistrada = (value) => DUE_REGISTRADA === value;
const isAcompanhamentoDueEncerrado = (value) => DUE_ACOMPANHAMENTO_ENCERRADO === value;
const isFaturado = (value) => FATURADO === value;


const getLabelByValue = (value) => {
  const foundOption = getOptionByValueInList(LIST, value);
  return foundOption && foundOption?.label;
};

const FollowUpStatusUtils = {
  LIST,
  isProcessoAberto,
  isFaturaAguardandoProcessamento,
  isFaturaEmProcessamento,
  isFaturaProcessada,
  isErroProcessamentoFatura,
  isCeBloqueado,
  isProcessoCancelado,
  isAguardandoConferenciaDi,
  isAguardandoLiberacaoRegistroLi,
  isAguardandoLiberacaoRegistroDi,
  isRegistroLiRecuperado,
  isExtratoLiRecuperado,
  isParametrizacaoDiConcluida,
  isAcompanhamentoLiEncerrado,
  isRegistroDiRecuperado,
  isExtratoDiRecuperado,
  isComprovanteDiRecuperado,
  isChegadaCargaConcluida,
  isIcmsEmitido,
  isDueRegistrada,
  isAcompanhamentoDueEncerrado,
  isFaturado,
  getLabelByValue,
};

export default FollowUpStatusUtils;
