import { createFilterOptions } from '@material-ui/lab/useAutocomplete';
import _, { isFunction } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllAsync, selectEmpresasEstrangeiras } from '../../features/empresa-estrangeira/empresaEstrangeiraSlice';
import QCXAutocompleteSelect from '../autocomplete-select/QCXAutocompleteSelect';

export default function QCXSelectEmpresaEstrangeiraAutocomplete({
  label,
  name,
  initialValues,
  filterPredicate,
  ...restProps
}) {
  const dispatch = useDispatch();
  const list = useSelector(selectEmpresasEstrangeiras);

  const [filteredEmpresasEstrangeiras, setFilteredEmpresasEstrangeiras] = useState([]);

  useEffect(() => {
    dispatch(fetchAllAsync());
  }, []);

  useEffect(() => {
    if (isFunction(filterPredicate)) {
      const filtered = list.filter(filterPredicate);
      setFilteredEmpresasEstrangeiras(filtered);
    }
  }, [list, filterPredicate]);

  const [defaultValue, setDefaultValue] = useState(null);

  useEffect(() => {
    const value = list.find((item) => item.id === _.get(initialValues, name));
    if (value) {
      setDefaultValue(value);
    } else {
      setDefaultValue(null);
    }
  }, [list, initialValues, name]);

  const filterOptions = createFilterOptions({
    stringify: (item) => `${item?.id} - ${
      item?.pessoaJuridica?.nomeResumido
      || item?.pessoaJuridica?.nome
      || item?.description || ''
    }`,
  });

  return (
    <QCXAutocompleteSelect
      label={label}
      id={name}
      name={name}
      options={filteredEmpresasEstrangeiras}
      filterOptions={filterOptions}
      defaultValue={defaultValue}
      getOptionLabel={(item) => (item?.id ? `${item?.id} - ${
        item?.pessoaJuridica?.nomeResumido
        || item?.pessoaJuridica?.nome
        || item?.description || ''
      }` : '')}
      {...restProps}
    />
  );
}
