import { Grid, makeStyles } from '@material-ui/core';
import React, {
  useMemo,
} from 'react';
import { isFunction } from 'lodash';

const useStyles = makeStyles((theme) => ({
  children: {
    [theme.breakpoints.up('md')]: {
      paddingRight: '8px',
    },
  },
  siblingBox: {
    [theme.breakpoints.up('md')]: {
      paddingRight: '8px',
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: '16px',
    },
  },
}));

export default function QCXAloneInlineBoxWrapper({
  hiddenSiblings = false,
  renderSiblings = undefined,
  gridBreakpoints = {
    md: 6,
  },
  children,
}) {
  const classes = useStyles();

  const childrenProps = useMemo(() => (
    {
      className: classes.children,
    }
  ), [classes]);

  const siblingProps = useMemo(() => (
    {
      className: classes.siblingBox,
    }
  ), [classes]);

  if (!isFunction(children)) {
    throw new Error('Children must be a function that returns a component.');
  }

  return (
    <Grid
      item
      container
      xs={12}
      sm={12}
      md={12}
      lg={12}
    >
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        {...gridBreakpoints}
      >
        {(
          isFunction(children)
          && children(childrenProps)
        )}
      </Grid>
      {isFunction(renderSiblings) && !hiddenSiblings && (
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          {...gridBreakpoints}
        >
          {renderSiblings(siblingProps)}
        </Grid>
      )}
    </Grid>
  );
}
