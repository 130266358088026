import React, { useEffect } from 'react';
import { useForm, useFormState } from 'react-final-form';

export default function QCXForceRestartFinalFormValues({
  restart,
}) {
  const form = useForm();
  const { initialValues } = useFormState();

  useEffect(() => {
    if (restart) {
      form.restart(initialValues);
    }
  }, [
    form,
    restart,
    initialValues,
  ]);

  return (<></>);
}
