import React from 'react';
import { useTranslation } from 'react-i18next';
import HomeIcon from '@material-ui/icons/Home';
import QCXMainLayout from '../../../components/main-layout/QCXMainLayout';

export default function HomePage({ authInfo = {} }) {
  const { t } = useTranslation();

  return (
    <QCXMainLayout
      pageTitle={t('com.muralis.qcx.paginaInicial')}
      pageIcon={<HomeIcon />}
      disableFocusLock={false}
      authInfo={authInfo}
    />
  );
}
