import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash';
import QCXFinalFormWizard from '../../shared-components/final-form-wizard/QCXFinalFormWizard';
import {
  addModeloLancamento,
  addPeriodo,
  changeToSubConsultMode,
  changeToSubCreateMode,
  changeToSubUpdateMode,
  lockSelectedModeloLancamento,
  removeModeloLancamentoById,
  removePeriodoById,
  resetLockedModeloLancamentoModel,
  resetRelatedModeloLancamentoModel,
  resetRelatedPeriodoModel,
  resetSubMode,
  selectRelatedModeloLancamentoModel,
  selectRelatedTabelaPeriodoModel,
  selectSubMode,
  setClientes,
  setRelatedModeloLancamentoModel,
  setRelatedTabelaPeriodoModel,
  updateModeloLancamento,
  updatePeriodo,
} from '../../features/tabela-armazenagem/tabelaArmazenagemSlice';
import {
  isNoneSubMode,
  isSubConsultMode,
  isSubCreateMode,
  isSubUpdateMode,
} from '../../utils/store/store-utils';
import QCXTabelaArmazenagemDadosTecnicosForm from './QCXTabelaArmazenagemDadosTecnicosForm';
import QCXTabelaPeriodoFinalFormManager from '../tabela-periodo/QCXTabelaPeriodoFinalFormManager';
import QCXDespesaFinalFormManager from '../modelo-lancamento/QCXDespesaFinalFormManager';
import QCXSelectManyClienteFormTableManager from '../cliente/QCXSelectManyClienteFormTableManager';
import { forceUnnormalizeNumeral, isValid, normalizeNumeral } from '../../utils/general/general-utils';
import { formatBrazilianNumericDecimal } from '../../utils/hooks/form/field/formatters';

export default function QCXTabelaArmazenagemWizardFinalForm({
  handleSubmit,
  handleAlternativeSave,
  model,
  handleChangeModel,
  handleChangeToConsult,
  handleChangeToUpdate,
  handleCancelUpdate,
  isConsult,
  isUpdate,
  authInfo = {},
  requiredRoles = [],
  ...restProps
}) {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const tabelaPeriodo = useSelector(selectRelatedTabelaPeriodoModel);
  const modeloLancamento = useSelector(selectRelatedModeloLancamentoModel);
  const subMode = useSelector(selectSubMode);

  const clientes = useMemo(() => (
    model?.clientes || []
  ), [model]);

  const isSubNone = useMemo(() => (
    isNoneSubMode(subMode)
  ), [subMode]);

  const isSubCreate = useMemo(() => (
    isSubCreateMode(subMode)
  ), [subMode]);

  const isSubConsult = useMemo(() => (
    isSubConsultMode(subMode)
  ), [subMode]);

  const isSubUpdate = useMemo(() => (
    isSubUpdateMode(subMode)
  ), [subMode]);

  const checkIfIsAlternativeSaveByStep = (currentStep) => (
    [1, 2, 3].includes(currentStep)
  );

  const normalizeModeloLancamento = (data) => (
    cloneDeep({
      ...data,
      despesaReceita: data?.despesaReceita,
      moeda: data?.moeda,
      tipoCalculo: data?.tipoCalculo,
      valor: normalizeNumeral(data?.valor),
      tipo: data?.tipo,
    })
  );

  const unnormalizeModeloLancamento = (data) => ({
    ...data,
    despesaReceita: data?.despesaReceita,
    moeda: data?.moeda,
    tipoCalculo: data?.tipoCalculo,
    valor: isValid(data?.valor)
      ? forceUnnormalizeNumeral(
        data?.valor,
        formatBrazilianNumericDecimal(2)
      )
      : undefined,
    tipo: data?.tipo,
  });

  const handleNewModeloLancamento = () => {
    dispatch(changeToSubCreateMode());
  };

  const handleCancelModeloLancamentoCreate = () => {
    dispatch(resetSubMode());
  };

  const handleCancelModeloLancamentoUpdate = () => {
    dispatch(changeToSubConsultMode());
    dispatch(resetLockedModeloLancamentoModel());
  };

  const handleRemoveModeloLancamento = () => {
    dispatch(removeModeloLancamentoById({ data: modeloLancamento }));
  };

  const handleModelosLancamentoRowClick = ({ row }) => {
    if (!isSubUpdate && !isSubCreate && (modeloLancamento?.id !== row?.id)) {
      dispatch(setRelatedModeloLancamentoModel(row));
      dispatch(changeToSubConsultMode());
      dispatch(resetLockedModeloLancamentoModel());
    }
  };

  const handleEditModeloLancamento = () => {
    dispatch(changeToSubUpdateMode());
    dispatch(lockSelectedModeloLancamento());
  };

  const handleClearModeloLancamentoForm = () => {
    dispatch(resetLockedModeloLancamentoModel());
    dispatch(resetRelatedModeloLancamentoModel());
    dispatch(resetSubMode());
  };

  const handleModeloLancamentoSubmit = (data) => {
    const normalizedData = normalizeModeloLancamento(data);

    if (isSubCreate) {
      dispatch(addModeloLancamento({ data: normalizedData }));
    } else if (isSubUpdate) {
      dispatch(updateModeloLancamento({ data: normalizedData }));
    }
  };

  const normalizeTabelaPeriodo = (data) => ({
    ...data,
    code: data?.code,
    description: data?.description,
    intervalo: data?.intervalo,
    periodo: data?.periodo,
    porcentagem: normalizeNumeral(data?.porcentagem),
    active: true,
  });

  const unnormalizeTabelaPeriodo = (data) => ({
    ...data,
    code: data?.code,
    description: data?.description,
    intervalo: data?.intervalo,
    periodo: data?.periodo,
    porcentagem: isValid(data?.porcentagem)
      ? forceUnnormalizeNumeral(
        data?.porcentagem,
        formatBrazilianNumericDecimal(2)
      )
      : undefined,
    active: true,
  });

  const handleNewPeriodo = () => {
    dispatch(changeToSubCreateMode());
  };

  const handleCancelPeriodoCreate = () => {
    dispatch(resetSubMode());
  };

  const handleCancelPeriodoUpdate = () => {
    dispatch(changeToSubConsultMode());
  };

  const handleRemovePeriodo = () => {
    dispatch(removePeriodoById({ data: tabelaPeriodo }));
  };

  const handleTabelaPeriodosRowClick = ({ row }) => {
    if (!isSubUpdate && !isSubCreate && (tabelaPeriodo?.id !== row?.id)) {
      dispatch(setRelatedTabelaPeriodoModel(row));
      dispatch(changeToSubConsultMode());
    }
  };

  const handleEditPeriodo = () => {
    dispatch(changeToSubUpdateMode());
  };

  const handleClearPeriodoForm = () => {
    dispatch(resetRelatedPeriodoModel());
  };

  const handlePeriodoSubmit = (data) => {
    const normalizedData = normalizeTabelaPeriodo(data);

    if (isSubCreate) {
      dispatch(addPeriodo({ data: normalizedData }));
    } else if (isSubUpdate) {
      dispatch(updatePeriodo({ data: normalizedData }));
    }
  };

  const handleSetSelectedClientes = (data) => {
    dispatch(setClientes(data));
  };

  const modeloLancamentoModel = useMemo(() => (
    !isSubCreate
      ? unnormalizeModeloLancamento(modeloLancamento)
      : {
        valor: '',
      }
  ), [isSubCreate, modeloLancamento, unnormalizeModeloLancamento]);

  const tabelaPeriodoModel = useMemo(() => (
    !isSubCreate
      ? unnormalizeTabelaPeriodo(tabelaPeriodo)
      : {}
  ), [isSubCreate, tabelaPeriodo, unnormalizeTabelaPeriodo]);

  const wizardSteps = [
    t('com.muralis.qcx.dadosTecnicos'),
    t('com.muralis.qcx.tabelaPeriodos'),
    t('com.muralis.qcx.despesa.labelPlural'),
    t('com.muralis.qcx.cliente.labelPlural'),
  ];

  return (
    <QCXFinalFormWizard
      steps={wizardSteps}
      initialValues={model}
      handleChangeValues={handleChangeModel}
      isConsult={isConsult}
      isUpdate={isUpdate}
      isSubNone={isSubNone}
      isSubCreate={isSubCreate}
      isSubConsult={isSubConsult}
      isSubUpdate={isSubUpdate}
      handleSubmit={handleSubmit}
      checkIfIsAlternativeSaveByStep={checkIfIsAlternativeSaveByStep}
      handleAlternativeSave={handleAlternativeSave}
      handleChangeToUpdate={handleChangeToUpdate}
      handleCancelUpdate={handleCancelUpdate}
      {...restProps}
    >
      {(formProps) => (
        <QCXTabelaArmazenagemDadosTecnicosForm
          key="dados-tecnicos-tabela-armazenagem"
          isConsult={isConsult}
          isUpdate={isUpdate}
          authInfo={authInfo}
          requiredRoles={requiredRoles}
          {...formProps}
        />
      )}
      {({ controlComponentProps }) => (
        <QCXTabelaPeriodoFinalFormManager
          key="tabela-periodo-tabela-armazenagem"
          isConsult={isConsult}
          isUpdate={isUpdate}
          isSubNone={isSubNone}
          isSubCreate={isSubCreate}
          isSubConsult={isSubConsult}
          isSubUpdate={isSubUpdate}
          initialValues={tabelaPeriodoModel}
          list={model?.periodos}
          handleSubmit={handlePeriodoSubmit}
          handleNew={handleNewPeriodo}
          handleCancelCreate={handleCancelPeriodoCreate}
          handleCancelUpdate={handleCancelPeriodoUpdate}
          handleRemove={handleRemovePeriodo}
          handleRowClick={handleTabelaPeriodosRowClick}
          handleEdit={handleEditPeriodo}
          handleClearForm={handleClearPeriodoForm}
          controlComponentProps={controlComponentProps}
          authInfo={authInfo}
          requiredRoles={requiredRoles}
        />
      )}
      {({ controlComponentProps }) => (
        <QCXDespesaFinalFormManager
          key="modelo-lancamento-despesa-tabela-armazenagem"
          isConsult={isConsult}
          isUpdate={isUpdate}
          isSubNone={isSubNone}
          isSubCreate={isSubCreate}
          isSubConsult={isSubConsult}
          isSubUpdate={isSubUpdate}
          model={modeloLancamentoModel}
          list={model?.modelosLancamento}
          handleSubmit={handleModeloLancamentoSubmit}
          handleNew={handleNewModeloLancamento}
          handleCancelCreate={handleCancelModeloLancamentoCreate}
          handleCancelUpdate={handleCancelModeloLancamentoUpdate}
          handleRemove={handleRemoveModeloLancamento}
          handleRowClick={handleModelosLancamentoRowClick}
          handleEdit={handleEditModeloLancamento}
          handleClearForm={handleClearModeloLancamentoForm}
          authInfo={authInfo}
          requiredRoles={requiredRoles}
          controlComponentProps={controlComponentProps}
        />
      )}
      {({ controlComponentProps, ...restFormProps }) => (
        <QCXSelectManyClienteFormTableManager
          list={clientes}
          isConsult={isConsult}
          handleChange={handleSetSelectedClientes}
          controlComponentProps={controlComponentProps}
          authInfo={authInfo}
          requiredRoles={requiredRoles}
          {...restFormProps}
        />
      )}
    </QCXFinalFormWizard>
  );
}
