import React, {
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import {
  useParams,
} from 'react-router';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  isEmpty,
  isFunction,
} from 'lodash';
import {
  followUpExportacaoActions,
} from '../../../../features/follow-up-exportacao/followUpExportacaoSlice';
import followUpExportacaoSelectors from '../../../../features/follow-up-exportacao/followUpExportacaoSelectors';
import followUpExportacaoThunks from '../../../../features/follow-up-exportacao/followUpExportacaoThunks';
import {
  isCreateMode,
  isBackgroundCreateMode,
  isConsultMode,
  isUpdateMode,
  isBackgroundDeleteMode,
  isLoadingStatus,
  isIdleStatus,
  isFailureStatus,
  isLockedMode,
} from '../../../../utils/store/store-utils';
import QCXActionsManagerPageTemplate from '../../../../templates/actions-manager-page/QCXActionsManagerPageTemplate';
import useAutoChangeMode from '../../../../utils/hooks/mode/useAutoChangeMode';
import QCXFollowUpFieldsViewerManager from '../../../../components/follow-up/QCXFollowUpFieldsViewerManager';
import QCXFollowUpExportacaoTimelineManager from '../../../../components/follow-up-exportacao/QCXFollowUpExportacaoTimelineManager';

export default function FollowUpExportacaoTimelineManagerPage({ authInfo = {} }) {
  const { t } = useTranslation();
  const params = useParams();

  const dispatch = useDispatch();

  const status = useSelector(
    followUpExportacaoSelectors.selectStatus
  );
  const mode = useSelector(
    followUpExportacaoSelectors.selectMode
  );
  const backgroundMode = useSelector(
    followUpExportacaoSelectors.selectBackgroundMode
  );
  const followUpExportacao = useSelector(
    followUpExportacaoSelectors.selectFollowUp
  );

  const isLoading = useMemo(() => (
    isLoadingStatus(status)
  ), [status]);

  const isIdle = useMemo(() => (
    isIdleStatus(status)
  ), [status]);

  const isFailure = useMemo(() => (
    isFailureStatus(status)
  ), [status]);

  const isCreate = useMemo(() => (
    isCreateMode(mode)
  ), [mode]);

  const isConsult = useMemo(() => (
    isConsultMode(mode)
  ), [mode]);

  const isUpdate = useMemo(() => (
    isUpdateMode(mode)
  ), [mode]);

  const isLocked = useMemo(() => (
    isLockedMode(mode)
  ), [mode]);

  const isBackgroundCreate = useMemo(() => (
    isBackgroundCreateMode(backgroundMode)
  ), [backgroundMode]);

  const isBackgroundDelete = useMemo(() => (
    isBackgroundDeleteMode(backgroundMode)
  ), [backgroundMode]);

  const handleChangeToPreparingAction = useCallback(() => {
    dispatch(
      followUpExportacaoActions.preparingAction()
    );
  }, []);

  const handleChangeToCreate = useCallback(() => {
    dispatch(
      followUpExportacaoActions.changeToCreateMode()
    );
  }, []);

  const handleChangeToBackgroundCreate = useCallback(() => {
    dispatch(
      followUpExportacaoActions.changeToBackgroundCreateMode()
    );
  }, []);

  const handleChangeToBackgroundDelete = useCallback(() => {
    dispatch(
      followUpExportacaoActions.changeToBackgroundDeleteMode()
    );
  }, []);

  const handleChangeToConsult = useCallback(() => {
    dispatch(
      followUpExportacaoActions.changeToConsultMode()
    );
  }, []);

  const handleChangeToUpdate = useCallback((additional = {}) => {
    dispatch(
      followUpExportacaoActions.changeToUpdateMode()
    );

    if (!isEmpty(additional) && isFunction(additional?.callback)) {
      additional.callback();
    }
  }, []);

  const handleResetBackgroundMode = useCallback(() => {
    dispatch(
      followUpExportacaoActions.resetBackgroundMode()
    );
  }, []);

  const fetchById = useCallback((followUpId) => {
    dispatch(
      followUpExportacaoThunks.fetchByIdAsync(followUpId)
    );
  }, []);

  const model = useMemo(() => (
    followUpExportacao
  ), [followUpExportacao]);

  const breadcrumbs = useMemo(() => ([
    {
      link: {
        to: '/',
        name: t('com.muralis.qcx.inicio'),
      },
    },
    {
      link: {
        to: t('com.muralis.qcx.url.moduloExportacao'),
        name: t('com.muralis.qcx.exportacao.label'),
      },
    },
    {
      link: {
        to: t('com.muralis.qcx.url.exportacaoFollowUp'),
        name: t('com.muralis.qcx.followUpExportacao.label'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.timeline.label'),
      },
    },
  ]), []);

  const pageTitle = useMemo(() => (
    t('com.muralis.qcx.timeline.timelineFollowUp', {
      numero: model?.numero || '-',
    })
  ), [model]);

  useAutoChangeMode((currentMode) => (
    dispatch(
      followUpExportacaoActions.changeModeTo(currentMode)
    )
  ), [isUpdate]);

  useEffect(() => {
    const handleFetchById = () => {
      if (!isEmpty(params?.id)) {
        fetchById(params?.id);

        handleChangeToConsult();
      }
    };

    handleFetchById();
  }, [
    params,
    handleChangeToConsult,
  ]);

  return (
    <QCXActionsManagerPageTemplate
      pageTitle={pageTitle}
      breadcrumbs={breadcrumbs}
      isIdle={isIdle}
      isLoading={isLoading}
      isFailure={isFailure}
      isCreate={isCreate}
      isConsult={isConsult}
      isUpdate={isUpdate}
      isLocked={isLocked}
      isBackgroundCreate={isBackgroundCreate}
      isBackgroundDelete={isBackgroundDelete}
      subtitle={{
        show: true,
        text: t('com.muralis.qcx.dadosProcesso'),
      }}
      handleChangeToPreparingAction={handleChangeToPreparingAction}
      handleChangeToCreate={handleChangeToCreate}
      handleChangeToBackgroundCreate={handleChangeToBackgroundCreate}
      handleChangeToBackgroundDelete={handleChangeToBackgroundDelete}
      handleChangeToConsult={handleChangeToConsult}
      handleChangeToUpdate={handleChangeToUpdate}
      handleResetBackgroundMode={handleResetBackgroundMode}
      authInfo={authInfo}
    >
      {() => (
        <>
          <QCXFollowUpFieldsViewerManager
            model={model}
          />
          <QCXFollowUpExportacaoTimelineManager
            model={model}
          />
        </>
      )}
    </QCXActionsManagerPageTemplate>
  );
}
