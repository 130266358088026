import { useKeycloak } from "@react-keycloak/web";
import { PropostaData } from "../../pages/comercial/propostas/propostasNewPage/propostasNewPage";
import axios from "axios";

const baseUrl = process.env.REACT_APP_QCX_PROPOSTAS_BASE_URL!;

const getAllPropostas = async (unidadeId: number) => {
  const response = await fetch(`${baseUrl!}/${unidadeId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    },
  });
  const data = await response.json();
  return data;
};

const uploadFile = async (
  file: File,
  unidadeId: number,
  propostaId: string,
  fileName: string,
  token: string
) => {
  let formData = new FormData();
  formData.append("file", file);

  const response = await axios.put(
    `${baseUrl}/files/${propostaId}/${fileName}`,
    formData,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
        quickcomexTenant: unidadeId,
      },
    }
  );

  const { url } = response.data;
  return { url };
};

const updateProposta = async (proposta: PropostaData, unidadeId: number) => {
  const response = await fetch(`${baseUrl!}/${unidadeId}/${proposta.id}`, {
    method: "PUT",
    body: JSON.stringify(proposta),
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "'GET,PUT,POST,DELETE,PATCH,OPTIONS'",
      "Content-Type": "application/json",
    },
  });
  const data = await response.text();
  return data;
};

export { getAllPropostas, uploadFile, updateProposta };
