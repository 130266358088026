import { Grid } from '@material-ui/core';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { AUTO_LOAD_CONTROL } from '../../features/config-control';
import { paisActions } from '../../features/pais/paisSlice';
import { estadoActions } from '../../features/estado/estadoSlice';
import { cidadeActions } from '../../features/cidade/cidadeSlice';
import QCXFinalCheckboxField from '../../shared-components/final-checkbox-field/QCXFinalCheckboxField';
import QCXFinalForm from '../../shared-components/final-form/QCXFinalForm';
import QCXFinalTextField from '../../shared-components/final-text-field/QCXFinalTextField';
import QCXSelectCidadeAutocomplete from '../../shared-components/select-cidade/QCXSelectCidadeAutocomplete';
import QCXSelectEstadoAutocomplete from '../../shared-components/select-estado/QCXSelectEstadoAutocomplete';
import QCXSelectPaisAutocomplete from '../../shared-components/select-pais/QCXSelectPaisAutocomplete';
import { required as requiredValidator, validPostalCode } from '../../utils/validators/field/validator';
import QCXFinalOnblurMaskedField from '../../shared-components/final-onblur-masked-field/QCXFinalOnblurMaskedField';
import { formatCep } from '../../utils/hooks/form/field/formatters';
import { parseToOnlyNumbersWithMaxDigits } from '../../utils/hooks/form/field/parsers';

export default function QCXEmpresaEstrangeiraForm({
  isConsult,
  isUpdate,
  initialValues,
  handleSubmit,
  handleChangeToUpdate,
  handleCancelUpdate,
  ...restProps
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(paisActions.changeControlTo(AUTO_LOAD_CONTROL));
  }, []);

  const isBrasilSelected = (value) => value === 1;

  const isBrasilSelectedBy = (values) => isBrasilSelected(values?.endereco?.cidade?.estado?.pais?.id);

  const paisOnchange = (form, values) => (value) => {
    const isCurrentBrazilSelected = isBrasilSelected(value);
    const postalCode = values?.endereco?.cep;
    const isEmptyPostaCode = !postalCode;

    form.change('endereco.cidade.id', undefined);
    form.change('endereco.estado.id', undefined);
    dispatch(estadoActions.fetchByFilterAsync([{ name: 'pais', value }]));
    dispatch(cidadeActions.fetchByFilterAsync([{ name: 'pais', value }]));
    form.resetFieldState('endereco.cep');
    if (isEmptyPostaCode) {
      return;
    }

    const postalCodeString = String(postalCode);
    if (!isCurrentBrazilSelected) {
      const sanitizedPostalCode = postalCodeString.replace('-', '');
      form.change('endereco.cep', sanitizedPostalCode);
      return;
    }

    if (postalCodeString.length <= 9) {
      form.change('endereco.cep', formatCep(postalCodeString));
      return;
    }

    const ajustedPostalCode = postalCodeString.slice(0, 8);
    form.change('endereco.cep', formatCep(ajustedPostalCode));
  };

  const estadoOnchange = (form) => (value) => {
    form.change('endereco.cidade.id', undefined);
    form.change('endereco.cidade.estado.id', value);
    dispatch(cidadeActions.fetchByFilterAsync([{ name: 'estado', value }]));
  };

  const listenerList = [
    { name: 'endereco.pais.id', fn: paisOnchange },
    { name: 'endereco.estado.id', fn: estadoOnchange },
  ];

  return (
    <QCXFinalForm
      isConsult={isConsult}
      isUpdate={isUpdate}
      initialValues={initialValues}
      handleSubmitValues={handleSubmit}
      handleChangeToUpdate={handleChangeToUpdate}
      handleCancelUpdate={handleCancelUpdate}
      onChangeListeners={listenerList}
      {...restProps}
    >
      {({ values }) => (
        <>
          {(isConsult || isUpdate) && (
            <Grid item xs={12} sm={4} md={2}>
              <QCXFinalTextField
                id="text-field-code"
                key="text-field-code"
                name="id"
                label={t('com.muralis.qcx.codigo')}
                disabled
              />
            </Grid>
          )}
          <Grid item xs={12} sm={8} md={6}>
            <QCXFinalTextField
              name="pessoaJuridica.nome"
              label={t('com.muralis.qcx.empresa.razaoSocial')}
              disabled={isConsult}
              maxLength={50}
              validate={requiredValidator}
              required
            />
          </Grid>
          <Grid item xs={12} sm={4} md={isConsult || isUpdate ? 4 : 6}>
            <QCXFinalTextField
              name="pessoaJuridica.nomeResumido"
              label={t('com.muralis.qcx.nomeResumido')}
              validate={requiredValidator}
              maxLength={30}
              disabled={isConsult}
              required
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <QCXSelectPaisAutocomplete
              id="select-endereco-pais"
              key="select-endereco-pais"
              name="endereco.pais.id"
              label={t('com.muralis.qcx.endereco.pais')}
              fieldProps={{ validate: requiredValidator }}
              initialValues={values}
              disabled={isConsult}
              required
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <QCXSelectEstadoAutocomplete
              id="select-endereco-estado"
              key="select-endereco-estado"
              name="endereco.estado.id"
              label={t('com.muralis.qcx.endereco.estado')}
              initialValues={values}
              disabled={isConsult}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <QCXSelectCidadeAutocomplete
              id="select-endereco-cidade"
              key="select-endereco-cidade"
              name="endereco.cidade.id"
              label={t('com.muralis.qcx.endereco.cidade')}
              initialValues={values}
              disabled={isConsult}
            />
          </Grid>
          <Grid item xs={12} sm={3} md={2}>
            <QCXFinalOnblurMaskedField
              id="text-field-endereco-cep"
              key="text-field-endereco-cep"
              name="endereco.cep"
              label={
                isBrasilSelectedBy(values)
                  ? t('com.muralis.qcx.endereco.CEP')
                  : t('com.muralis.qcx.endereco.codigoPostal')
              }
              {...(isBrasilSelectedBy(values)
                ? {
                    format: formatCep,
                    parse: parseToOnlyNumbersWithMaxDigits(8),
                    validate: validPostalCode(8, 9),
                    maxLength: 9,
                  }
                : {
                    format: (value) => value,
                    parse: parseToOnlyNumbersWithMaxDigits(10),
                    validate: validPostalCode(5, 10),
                    maxLength: 10,
                  })}
              disabled={isConsult}
            />
          </Grid>
          <Grid item xs={12} sm={isConsult || isUpdate ? 5 : 9} md={3}>
            <QCXFinalTextField
              id="text-field-endereco-bairro"
              key="text-field-endereco-bairro"
              label={t('com.muralis.qcx.endereco.bairro')}
              name="endereco.bairro"
              maxLength={70}
              disabled={isConsult}
            />
          </Grid>
          <Grid item xs={12} sm={isConsult || isUpdate ? 5 : 5} md={4}>
            <QCXFinalTextField
              id="text-field-endereco-logradouro"
              key="text-field-endereco-logradouro"
              label={t('com.muralis.qcx.endereco.logradouro')}
              name="endereco.logradouro"
              maxLength={150}
              validate={requiredValidator}
              disabled={isConsult}
              required
            />
          </Grid>
          <Grid item xs={12} sm={3} md={2}>
            <QCXFinalTextField
              id="text-field-endereco-numero"
              key="text-field-endereco-numero"
              label={t('com.muralis.qcx.endereco.numero')}
              name="endereco.numero"
              maxLength={10}
              validate={requiredValidator}
              disabled={isConsult}
              required
            />
          </Grid>
          <Grid item xs={12} sm={isConsult || isUpdate ? 4 : 4} md={3}>
            <QCXFinalTextField
              id="text-field-endereco-complemento"
              key="text-field-endereco-complemento"
              label={t('com.muralis.qcx.endereco.complemento')}
              name="endereco.complemento"
              maxLength={50}
              disabled={isConsult}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <QCXFinalCheckboxField
              name="importador"
              label={t('com.muralis.qcx.importador.label')}
              disabled={isConsult}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <QCXFinalCheckboxField
              name="exportador"
              label={t('com.muralis.qcx.exportador.label')}
              disabled={isConsult}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <QCXFinalCheckboxField
              name="fabricante"
              label={t('com.muralis.qcx.fabricante.label')}
              disabled={isConsult}
            />
          </Grid>
        </>
      )}
    </QCXFinalForm>
  );
}
