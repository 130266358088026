const selectStatus = (state) => state.vinculoFollowUpCapaFaturamento.status;
const selectMode = (state) => state.vinculoFollowUpCapaFaturamento.mode.main;
const selectModel = (state) => state.vinculoFollowUpCapaFaturamento.model;

const vinculoFollowUpCapaFaturamentoSelectors = {
  selectStatus,
  selectMode,
  selectModel,
};

export {
  selectStatus,
  selectMode,
  selectModel,
  vinculoFollowUpCapaFaturamentoSelectors,
};
