import React from 'react';
import { ListItemIcon } from '@material-ui/core';

export default function QCXListItem({ children, ...restProps }) {
  return (
    <ListItemIcon {...restProps}>
      {children}
    </ListItemIcon>
  );
}
