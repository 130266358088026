import { createFilterOptions } from '@material-ui/lab/useAutocomplete';
import _, { isFunction } from 'lodash';
import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
} from 'react';
import { useFormState } from 'react-final-form';
import QCXAutocompleteSelect from '../autocomplete-select/QCXAutocompleteSelect';
import { TIPO_DOCUMENTO_CHEGADA_CARGA_LIST } from './tipoDocumentoChegadaCargaUtils';

export default function QCXSelectTipoDocumentoChegadaCargaAutocomplete({
  label,
  name,
  configureFilterPredicate,
  ...restProps
}) {
  const { values } = useFormState();

  const [defaultValue, setDefaultValue] = useState('');

  const list = useMemo(() => {
    if (isFunction(configureFilterPredicate)) {
      const filterPredicate = configureFilterPredicate(values);
      const filteredValues = TIPO_DOCUMENTO_CHEGADA_CARGA_LIST.filter(filterPredicate);

      return filteredValues;
    }

    return TIPO_DOCUMENTO_CHEGADA_CARGA_LIST;
  }, [
    values,
    configureFilterPredicate,
  ]);

  useEffect(() => {
    const value = TIPO_DOCUMENTO_CHEGADA_CARGA_LIST
      .find((item) => (
        item?.value === _.get(values, name)
      ));
    if (value) {
      setDefaultValue(value);
    } else {
      setDefaultValue('');
    }
  }, [values]);

  const filterOptions = useMemo(() => (
    createFilterOptions({
      stringify: (option) => (
        option.label + option.value
      ),
    })
  ), []);

  const getOptionLabel = useCallback((item) => (
    item?.label || ''
  ), []);

  const getOptionValue = useCallback((item) => (
    item?.value || ''
  ), []);

  return (
    <QCXAutocompleteSelect
      label={label}
      id={name}
      name={name}
      options={list}
      filterOptions={filterOptions}
      defaultValue={defaultValue}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      {...restProps}
    />
  );
}
