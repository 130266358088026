/* eslint-disable import/extensions */
/* eslint-disable no-unused-vars */
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import App from './App';
import store from './app/store';
import keycloak from './components/keycloak';
import './i18n';

// Styles
import './css/styles.css';

const eventLogger = (event, error) => {
  // console.log('onKeycloakEvent', event, error);
};

const tokenLogger = (tokens) => {
  // console.log('onKeycloakTokens', tokens);
};

const initOptions = { pkceMethod: 'S256' };

ReactDOM.render(
  <Provider store={store}>
    <ReactKeycloakProvider authClient={keycloak} initOptions={initOptions} onEvent={eventLogger} onTokens={tokenLogger}>
      <App />
    </ReactKeycloakProvider>
  </Provider>,
  document.getElementById('root')
);
