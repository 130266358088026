import { isEmpty } from 'lodash';
import { SingletonUtils } from '../general-utils';

const ETAPA_PRIVADA = 'SIM';
const ETAPA_NAO_PRIVADA = 'NAO';

const TIPO_DOCUMENTO_PRIVADO = 'SIM';
const TIPO_DOCUMENTO_NAO_PRIVADO = 'NAO';

const isEtapaNaoPrivada = (value) => (
  ETAPA_NAO_PRIVADA === value
);

const isEtapaPrivada = (value) => (
  ETAPA_PRIVADA === value
);

const isTipoDocumentoNaoPrivado = (value) => (
  TIPO_DOCUMENTO_NAO_PRIVADO === value
);

const isTipoDocumentoPrivado = (value) => (
  TIPO_DOCUMENTO_PRIVADO === value
);

const parseToSingletonList = (validate, value, defaultValue) => (
  SingletonUtils.toList(
    validate,
    value,
    defaultValue
  )
);

const parseToSingletonEtapaList = (value) => (
  parseToSingletonList(
    isEmpty,
    value,
    ETAPA_NAO_PRIVADA
  )
);

const parseToSingletonTipoDocumentoList = (value) => (
  parseToSingletonList(
    isEmpty,
    value,
    TIPO_DOCUMENTO_NAO_PRIVADO
  )
);

const getSingletonEtapaListBy = (value) => (
  SingletonUtils.getValueOrDefault(
    isEtapaPrivada,
    value,
    []
  )
);

const getSingletonTipoDocumentoListBy = (value) => (
  SingletonUtils.getValueOrDefault(
    isTipoDocumentoPrivado,
    value,
    []
  )
);

const PerfilUtils = {
  ETAPA_PRIVADA,
  ETAPA_NAO_PRIVADA,
  TIPO_DOCUMENTO_PRIVADO,
  TIPO_DOCUMENTO_NAO_PRIVADO,
  isEtapaPrivada,
  isEtapaNaoPrivada,
  isTipoDocumentoPrivado,
  isTipoDocumentoNaoPrivado,
  parseToSingletonEtapaList,
  parseToSingletonTipoDocumentoList,
  getSingletonEtapaListBy,
  getSingletonTipoDocumentoListBy,
};

export default PerfilUtils;
