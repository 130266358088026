import { isStrictEquals, SingletonUtils } from '../general-utils';

const PUBLICO = 'PUBLICO';
const NAO_PUBLICO = 'NAO_PUBLICO';

const TIPO_DOCUMENTO_XML_DANFE_CODE = 'TD12';
const TIPO_DOCUMENTO_FATURA_CODE = 'TD16';

const isNaoPublico = (value) => (
  NAO_PUBLICO === value
);

const isPublico = (value) => (
  PUBLICO === value
);

const isTipoDocumentoXmlDanfe = (value) => (
  isStrictEquals(TIPO_DOCUMENTO_XML_DANFE_CODE, value)
);

const parseFromSingletonList = (singletonList = []) => (
  SingletonUtils.fromList(
    isPublico,
    NAO_PUBLICO,
    singletonList
  )
);

const parseToSingletonList = (value) => (
  SingletonUtils.toList(
    isNaoPublico,
    value
  )
);

// FIXME: Remover esse método e utilizar o genérico MatcherConfigureUtils
const configureGeneralTipoDocumentoMatcherWith = (list) => {
  const isSomeTipoDocumentoConfiguredById = (id, codesToCompare = []) => {
    const foundTipoDocumento = list.find((tipoDocumento) => (
      tipoDocumento?.id === id
    ));

    return codesToCompare.includes(foundTipoDocumento?.code);
  };

  return isSomeTipoDocumentoConfiguredById;
};

const TipoDocumentoUtils = {
  PUBLICO,
  NAO_PUBLICO,
  TIPO_DOCUMENTO_XML_DANFE_CODE,
  TIPO_DOCUMENTO_FATURA_CODE,
  isNaoPublico,
  isPublico,
  isTipoDocumentoXmlDanfe,
  parseFromSingletonList,
  parseToSingletonList,
  configureGeneralTipoDocumentoMatcherWith,
};

export default TipoDocumentoUtils;
