import React from 'react';
import { DialogTitle } from '@material-ui/core';

export default function QCXDialogTitle({
  key,
  id,
  children,
  ...restProps
}) {
  return (
    <DialogTitle
      key={key}
      id={id}
      {...restProps}
    >
      {children}
    </DialogTitle>
  );
}
