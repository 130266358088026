import { UseMutateFunction } from "react-query";
import {
  QueriesTypes,
  usePostRequestFormData,
} from "../../../../common/hooks/queries";

export const useCreateFollowUpAssessoria = ({
  onSettled,
}: {
  onSettled: (payload: any) => void;
}): [
  UseMutateFunction<
    any,
    unknown,
    {
      requestFormData: FormData;
    }
  >,
  boolean,
  boolean
] => {
  const { isLoading, isError, mutate } = usePostRequestFormData(
    QueriesTypes.FollowUps,
    { onSettledCallback: onSettled }
  );

  return [mutate, isLoading, isError];
};
