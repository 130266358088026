import { useContext, useMemo } from 'react';
import PaginatorActionContext from '../../../contexts/components/paginator/PaginatorActionContext';

export default function usePaginatorActionState() {
  const contextValue = useContext(PaginatorActionContext);

  const paginatorAction = useMemo(() => ({
    current: contextValue?.current,
    NEXT: contextValue?.NEXT,
    PREVIOUS: contextValue?.PREVIOUS,
    isNext: contextValue?.isNext,
    isPrevious: contextValue?.isPrevious,
  }), [contextValue]);

  return paginatorAction;
}
