const MOEDA_BRASIL_CODE = '790';
const MOEDA_EUA_CODE = '220';

const MoedaUtils = Object.freeze({
  BRASIL: (
    MOEDA_BRASIL_CODE
  ),
  EUA: (
    MOEDA_EUA_CODE
  ),
});

export default MoedaUtils;
