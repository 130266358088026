import React, {
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import {
  useHistory,
  useParams,
} from 'react-router';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash/debounce';
import { isEmpty } from 'lodash';
import {
  changeToBackgroundCreateMode,
  changeToConsultMode,
  changeToCreateMode,
  changeToUpdateMode,
  failure,
  fetchByIdAsync,
  loading,
  preparingAction,
  resetBackgroundMode,
  resetModel,
  selectBackgroundMode,
  selectUnidadeNegocio,
  selectMode,
  selectStatus,
  setError,
  setModel,
  setResponse,
  success,
  addToList,
  updateOnList,
} from '../../../../../features/unidade-negocio/unidadeNegocioSlice';
import {
  isCreateMode,
  isBackgroundCreateMode,
  isConsultMode,
  isUpdateMode,
  isPreparingActionStatus,
  isNoneMode,
} from '../../../../../utils/store/store-utils';
import { register, save } from '../../../../../features/unidade-negocio/unidadeNegocioAPI';
import QCXRegistrationFormPageTemplate from '../../../../../templates/registration-form-page/QCXRegistrationFormPageTemplate';
import {
  normalizeData,
  normalizeDigits,
  normalizeNumeral,
  unnormalizeNumeral,
} from '../../../../../utils/general/general-utils';
import QCXUnidadeNegocioFinalForm from '../../../../../components/unidade-negocio/QCXUnidadeNegocioFinalForm';
import {
  formatBrazilianNumericDecimal,
  formatCep,
  formatCnpj,
  formatCpf,
  formatTelefone,
} from '../../../../../utils/hooks/form/field/formatters';
import ControleContagemUtils from '../../../../../utils/general/controle-contagem/ControleContagemUtils';
import { TIPO_MASCARA_FOLLOW_UP_MANUALMENTE } from '../../../../../shared-components/select-tipo-mascara-follow-up/tipoMascaraFollowUpUtils';
import { MediaType } from '../../../../../utils/api/api-utils';

export default function UnidadeNegocioRegistrationPage({ authInfo }) {
  const { t } = useTranslation();

  const { id } = useParams();

  const history = useHistory();

  const dispatch = useDispatch();

  const status = useSelector(selectStatus);
  const mode = useSelector(selectMode);
  const backgroundMode = useSelector(selectBackgroundMode);
  const unidadeNegocio = useSelector(selectUnidadeNegocio);

  const isNone = useMemo(() => (
    isNoneMode(mode)
  ), [mode]);

  const isCreate = useMemo(() => (
    isCreateMode(mode)
  ), [mode]);

  const isBackgroundCreate = useMemo(() => (
    isBackgroundCreateMode(backgroundMode)
  ), [backgroundMode]);

  const isConsult = useMemo(() => (
    isConsultMode(mode)
  ), [mode]);

  const isUpdate = useMemo(() => (
    isUpdateMode(mode)
  ), [mode]);

  const isPreparingAction = useMemo(() => (
    isPreparingActionStatus(status)
  ), [status]);

  const handleChangeToPreparingAction = useCallback(() => {
    dispatch(preparingAction());
  }, []);

  const handleChangeToCreate = useCallback(() => {
    dispatch(changeToCreateMode());
  }, []);

  const handleChangeToBackgroundCreate = useCallback(() => {
    dispatch(changeToBackgroundCreateMode());
  }, []);

  const handleChangeToConsult = useCallback(() => {
    dispatch(changeToConsultMode());
  }, []);

  const handleChangeToUpdate = useCallback(() => {
    dispatch(changeToUpdateMode());
  }, []);

  const handleResetBackgroundMode = useCallback(() => {
    dispatch(resetBackgroundMode());
  }, []);

  useEffect(() => {
    if (!isEmpty(id)) {
      dispatch(fetchByIdAsync(id));
      handleChangeToConsult();
    }
  }, [
    id,
    handleChangeToConsult,
  ]);

  const parseToFormData = useCallback((data) => {
    const {
      logo,
      certificadoA1,
      ...restData
    } = data;

    const jsonData = {
      ...restData,
      ...(logo?.id
        ? {
          logo,
        } : {}
      ),
      ...(certificadoA1?.id
        ? {
          certificadoA1,
        } : {}
      ),
    };

    const json = JSON.stringify(jsonData);
    const blob = new Blob([json], {
      type: MediaType.APPLICATION_JSON,
    });

    const fileLogo = logo?.length > 0
      ? logo[0]
      : undefined;

    const fileCertificado = certificadoA1?.length > 0
      ? certificadoA1[0]
      : undefined;

    const formData = new FormData();

    formData.append('data', blob);

    if (fileLogo) {
      formData.append('fileLogo', fileLogo);
    }

    if (fileCertificado) {
      formData.append('fileCertificado', fileCertificado);
    }

    return formData;
  }, []);

  const handleCancelUpdate = useCallback(() => {
    const currentId = isBackgroundCreate ? unidadeNegocio?.id : id;

    if (currentId) {
      dispatch(fetchByIdAsync(currentId));
    }
    handleChangeToConsult();
  }, [
    id,
    unidadeNegocio,
    isBackgroundCreate,
    handleChangeToConsult,
  ]);

  const normalize = useCallback((unnormalizedData) => {
    const normalizePessoaByTipo = (pessoa) => {
      if (pessoa?.tipo === 'PessoaJuridica') {
        return {
          ...pessoa,
          cnpj: normalizeDigits(pessoa?.cnpj),
          cnae: pessoa?.cnae,
        };
      }

      if (pessoa?.tipo === 'PessoaFisica') {
        return {
          ...pessoa,
          cpf: normalizeDigits(pessoa?.cpf),
          dataNascimento: normalizeData(pessoa?.dataNascimento),
        };
      }

      return {};
    };

    const normalizedPessoa = normalizePessoaByTipo(
      unnormalizedData?.pessoa
    );

    const normalizedData = {
      ...unnormalizedData,
      percentualIss: normalizeNumeral(unnormalizedData?.percentualIss),
      pessoa: normalizedPessoa,
      endereco: {
        ...unnormalizedData?.endereco,
        cep: normalizeDigits(unnormalizedData?.endereco?.cep),
      },
      contatoNfe: {
        ...unnormalizedData?.contatoNfe,
        telefone: normalizeDigits(unnormalizedData?.contatoNfe?.telefone),
      },
      tipoMascaraFollowUp: unnormalizedData?.tipoMascaraFollowUp,
      reiniciaContagemAnualmente: ControleContagemUtils.parseFromSingletonList(
        unnormalizedData?.reiniciaContagemAnualmente
      ),
      reiniciaContagemTipoProduto: ControleContagemUtils.parseFromSingletonList(
        unnormalizedData?.reiniciaContagemTipoProduto
      ),
      reiniciaContagemTipoServico: ControleContagemUtils.parseFromSingletonList(
        unnormalizedData?.reiniciaContagemTipoServico
      ),
      codigoServico: unnormalizedData?.codigoServico
        ? normalizeNumeral(unnormalizedData?.codigoServico)
        : null,
      aliquotaIrrf: normalizeNumeral(unnormalizedData?.aliquotaIrrf),
      aliquotaIr: normalizeNumeral(unnormalizedData?.aliquotaIr),
      descricaoServicoRps: unnormalizedData?.descricaoServicoRps,
      regimeTributarioEspecial: unnormalizedData?.regimeTributarioEspecial,
      regimeTributario: unnormalizedData?.regimeTributario,
      tipoLogradouro: unnormalizedData?.endereco?.tipoLogradouro,
      senhaCertificadoA1: unnormalizedData?.senhaCertificadoA1,
      certificadoA1: unnormalizedData?.certificadoA1,
      logo: unnormalizedData?.logo,
      simplesNacional: unnormalizedData?.simplesNacional,
    };

    return normalizedData;
  }, []);

  const unnormalize = useCallback((normalizedData) => {
    const unnormalizePessoaByTipo = (pessoa) => {
      if (pessoa?.tipo === 'PessoaJuridica') {
        return {
          ...pessoa,
          cnpj: formatCnpj(pessoa?.cnpj),
          cnae: pessoa?.cnae,
        };
      }

      if (pessoa?.tipo === 'PessoaFisica') {
        return {
          ...pessoa,
          cpf: formatCpf(pessoa?.cpf),
          dataNascimento: pessoa?.dataNascimento,
        };
      }

      return {};
    };

    const unnormalizedPessoa = unnormalizePessoaByTipo(
      normalizedData?.pessoa
    );

    const unnormalizedData = {
      ...normalizedData,
      percentualIss: unnormalizeNumeral(
        normalizedData?.percentualIss,
        formatBrazilianNumericDecimal(2)
      ),
      pessoa: unnormalizedPessoa,
      endereco: {
        ...normalizedData?.endereco,
        cep: formatCep(normalizedData?.endereco?.cep),
        tipoLogradouro: normalizedData?.tipoLogradouro,
      },
      discriminacaoServico: normalizedData?.discriminacaoServico,
      contatoNfe: {
        ...normalizedData?.contatoNfe,
        telefone: formatTelefone(normalizedData?.contatoNfe?.telefone),
      },
      tipoMascaraFollowUp: normalizedData?.tipoMascaraFollowUp,
      reiniciaContagemAnualmente: ControleContagemUtils.parseToSingletonList(
        normalizedData?.reiniciaContagemAnualmente
      ),
      reiniciaContagemTipoProduto: ControleContagemUtils.parseToSingletonList(
        normalizedData?.reiniciaContagemTipoProduto
      ),
      reiniciaContagemTipoServico: ControleContagemUtils.parseToSingletonList(
        normalizedData?.reiniciaContagemTipoServico
      ),
      descricaoServicoRps: normalizedData?.descricaoServicoRps,
      codigoServico: normalizedData?.codigoServico
        ? unnormalizeNumeral(normalizedData?.codigoServico)
        : null,
      aliquotaIrrf: unnormalizeNumeral(
        normalizedData?.aliquotaIrrf,
        formatBrazilianNumericDecimal(2)
      ),
      aliquotaIr: unnormalizeNumeral(
        normalizedData?.aliquotaIr,
        formatBrazilianNumericDecimal(2)
      ),
      regimeTributarioEspecial: normalizedData?.regimeTributarioEspecial,
      regimeTributario: normalizedData?.regimeTributario,
      senhaCertificadoA1: normalizedData?.senhaCertificadoA1,
      certificadoA1: normalizedData?.certificadoA1,
      logo: normalizedData?.logo,
      simplesNacional: normalizedData?.simplesNacional,
    };

    return unnormalizedData;
  }, []);

  const handleDispatchSetModel = useCallback((data) => {
    dispatch(
      setModel(
        normalize(data)
      )
    );
  }, [normalize]);

  const create = useCallback(async (data) => {
    const executeDebounced = debounce(async () => {
      try {
        const formData = parseToFormData(data);

        const response = await register(formData);

        if (response?.status === 201) {
          dispatch(resetModel());

          const created = response?.data;

          handleDispatchSetModel(created);
          dispatch(addToList({ data: created }));

          const handleResultWithDebounce = debounce(() => {
            history.push(t('com.muralis.qcx.url.moduloGeraisUnidadeNegocio'));

            dispatch(success());
            dispatch(setResponse({
              status: response.status,
              data: created,
              message: t('com.muralis.qcx.mensagem.unidadeNegocioCadastrada', { unidade: data?.pessoa?.nomeResumido }),
            }));
          }, 500);

          handleResultWithDebounce();
        }
      } catch (error) {
        dispatch(failure());
        let errorMessage = t('com.muralis.qcx.erro.erroSalvarUnidadeNegocio');
        if (error?.response && error?.response?.data) {
          errorMessage = t('com.muralis.qcx.erro.erroSalvarUnidadeNegocioMensagem', { erro: error?.response.data.message });
        }
        dispatch(setError({
          message: errorMessage,
        }));
      }
    }, 500);

    dispatch(loading());
    executeDebounced();
  }, [
    history,
    handleDispatchSetModel,
  ]);

  const update = useCallback(async (data) => {
    const executeDebounced = debounce(async () => {
      try {
        const formData = parseToFormData(data);

        const response = await save(formData);

        if (response?.status === 200) {
          const handleResultWithDebounce = debounce(() => {
            handleChangeToConsult();
            dispatch(success());

            const saved = response?.data;

            dispatch(setResponse({
              status: response.status,
              data: saved,
              message: t('com.muralis.qcx.mensagem.unidadeNegocioCadastrada',
                { unidade: saved?.pessoa?.nomeResumido }),
            }));

            dispatch(setModel(saved));
            dispatch(updateOnList({ data: saved }));
            dispatch(fetchByIdAsync(saved?.id));
          }, 500);

          handleResultWithDebounce();
          handleChangeToConsult();
        }
      } catch ({ response }) {
        dispatch(failure());
        dispatch(setError({
          message: t('com.muralis.qcx.erro.erroSalvarUnidadeNegocioPessoaMensagem', { unidade: data?.pessoa?.nomeResumido, mensagem: response?.data?.message }),
        }));
      }
    }, 500);

    dispatch(loading());
    executeDebounced();
  }, [
    handleChangeToConsult,
  ]);

  const handleSubmit = useCallback(async (data) => {
    const normalizedData = normalize(data);

    if (isUpdate && !isBackgroundCreate) {
      await update(normalizedData);
    } else {
      await create(normalizedData);
    }
  }, [
    isUpdate,
    isBackgroundCreate,
    normalize,
    create,
    update,
  ]);

  const model = useMemo(() => (
    !isCreate
      ? unnormalize(unidadeNegocio)
      : {
        pessoa: {
          tipo: 'PessoaJuridica',
        },
        tipoMascaraFollowUp: TIPO_MASCARA_FOLLOW_UP_MANUALMENTE,
      }
  ), [
    isCreate,
    unidadeNegocio,
    unnormalize,
  ]);

  const actionName = useMemo(() => {
    if (isCreate || isBackgroundCreate || isNone) {
      return t('com.muralis.qcx.acoes.novo');
    }

    if (isConsult) {
      return t('com.muralis.qcx.acoes.visualizar');
    }

    return t('com.muralis.qcx.acoes.alterar');
  }, [
    isNone,
    isCreate,
    isConsult,
    isBackgroundCreate,
  ]);

  const breadcrumbs = useMemo(() => ([
    {
      link: {
        to: '/',
        name: t('com.muralis.qcx.inicio'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.cadastros'),
      },
    },
    {
      link: {
        to: t('com.muralis.qcx.url.moduloCadastrosGerais'),
        name: t('com.muralis.qcx.gerais'),
      },
    },
    {
      link: {
        to: t('com.muralis.qcx.url.moduloGeraisUnidadeNegocio'),
        name: t('com.muralis.qcx.unidadeNegocio.labelPlural'),
      },
    },
    {
      text: {
        name: actionName,
      },
    },
  ]), [actionName]);

  const pageTitle = useMemo(() => (
    isNone || isCreate || isBackgroundCreate
      ? t('com.muralis.qcx.unidadeNegocio.novaUnidadeNegocio') : t('com.muralis.qcx.unidadeNegocio.unidadeNegocioIdentificacao', { identificacao: unidadeNegocio?.pessoa?.nomeResumido || unidadeNegocio?.id || '-' })
  ), [isNone, isCreate, isBackgroundCreate, unidadeNegocio]);

  return (
    <QCXRegistrationFormPageTemplate
      pageTitle={pageTitle}
      breadcrumbs={breadcrumbs}
      isCreate={isCreate}
      isConsult={isConsult}
      isUpdate={isUpdate}
      isBackgroundCreate={isBackgroundCreate}
      isPreparingAction={isPreparingAction}
      handleChangeToPreparingAction={handleChangeToPreparingAction}
      handleChangeToCreate={handleChangeToCreate}
      handleChangeToBackgroundCreate={handleChangeToBackgroundCreate}
      handleChangeToConsult={handleChangeToConsult}
      handleChangeToUpdate={handleChangeToUpdate}
      handleCancelUpdate={handleCancelUpdate}
      handleResetBackgroundMode={handleResetBackgroundMode}
      authInfo={authInfo}
    >
      {(formProps) => (
        <QCXUnidadeNegocioFinalForm
          initialValues={model}
          handleChangeModel={handleDispatchSetModel}
          handleSubmit={handleSubmit}
          authInfo={authInfo}
          requiredRoles={['unidade-de-negocio']}
          {...formProps}
        />
      )}
    </QCXRegistrationFormPageTemplate>
  );
}
