import QCXFinalTextField from '../final-text-field/QCXFinalTextField';

export default function QCXTextFieldDominioNcmExportacao({
  id,
  key,
  name,
  label,
  maxLength,
  helperText,
  list,
  ...restProps
}) {
  return (
    <QCXFinalTextField
      id={id}
      key={key}
      name={name}
      label={label}
      maxLength={maxLength}
      helperText={helperText}
      {...restProps}
    />
  );
}
