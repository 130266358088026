import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchAll, fetchById } from './tratamentoTributarioAPI';

const fetchAllAsync = createAsyncThunk(
  'tratamentoTributario/fetchAll',
  async () => {
    const { data } = await fetchAll();
    return { data };
  }
);

const fetchByIdAsync = createAsyncThunk(
  'tratamentoTributario/fetchById',
  async (data) => {
    const response = await fetchById(data);
    return {
      response: {
        status: response.status,
        data: response.data,
      },
    };
  }
);

const tratamentoTributarioThunks = {
  fetchAllAsync,
  fetchByIdAsync,
};

export {
  tratamentoTributarioThunks,
  fetchAllAsync,
  fetchByIdAsync,
};
