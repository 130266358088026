import { IconButton } from '@material-ui/core';
import {
  Delete as DeleteIcon,
  Search as PageviewIcon,
  ViewList as ViewListIcon,
} from '@material-ui/icons';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import CatalogoProdutosContext from '../../../../../contexts/registrations/catalogo-produtos/CatalogoProdutosContext';
import {
  activateByIdAsync,
  changeToCreateMode,
  fetchAllAsync,
  inactivateByIdAsync,
  resetBackgroundMode,
  resetMode,
  resetModel,
  resetRelatedEspecificacaoModel,
  resetSubMode,
  selectCatalogosProdutos,
} from '../../../../../features/catalogo-produtos/catalogoProdutosSlice';
import {
  ALTERNATIVE_LOADING_STATUS,
} from '../../../../../features/status';
import {
  changeControlTo as changeControlPaisTo,
} from '../../../../../features/pais/paisSlice';
import {
  changeControlTo as changeControlNaladiShTo,
} from '../../../../../features/naladi-sh/naladiShSlice';
import {
  changeControlTo as changeControlNaladiNccaTo,
} from '../../../../../features/naladi-ncca/naladiNccaSlice';
import {
  changeControlTo as changeControlUnidadeMedidaTo,
} from '../../../../../features/unidade-medida/unidadeDeMedidaSlice';
import {
  changeControlTo as changeControlNcmTo,
} from '../../../../../features/ncm-subitem/ncmSubitemSlice';
import {
  changeControlTo as changeControlCfopOperacoesFiscais,
} from '../../../../../features/cfop-operacoes-fiscais/cfopOperacoesFiscaisSlice';
import { IDLE_CONTROL } from '../../../../../features/config-control';
import QCXSimpleConsultPageTemplate from '../../../../../templates/simple-consult-page/QCXSimpleConsultPageTemplate';
import { userHasDeleteRole } from '../../../../../components/perfil/perfil-utils';

export default function CatalogoProdutosConsultPage({ authInfo = {} }) {
  const { t } = useTranslation();

  const history = useHistory();

  const {
    status,
  } = useContext(CatalogoProdutosContext);

  const dispatch = useDispatch();

  const catalogosProdutos = useSelector(selectCatalogosProdutos);

  const isAlternativeLoading = useMemo(() => status === ALTERNATIVE_LOADING_STATUS, [status]);

  const fetchAll = async () => {
    dispatch(fetchAllAsync());
  };

  const resetModes = useCallback(() => {
    dispatch(resetMode());
    dispatch(resetBackgroundMode());
    dispatch(resetSubMode());
  }, []);

  const resetModels = useCallback(() => {
    dispatch(resetModel());
    dispatch(resetRelatedEspecificacaoModel());
  }, []);

  const resetDependencies = useCallback(() => {
    dispatch(changeControlPaisTo(IDLE_CONTROL));
    dispatch(changeControlNcmTo(IDLE_CONTROL));
    dispatch(changeControlNaladiShTo(IDLE_CONTROL));
    dispatch(changeControlNaladiNccaTo(IDLE_CONTROL));
    dispatch(changeControlUnidadeMedidaTo(IDLE_CONTROL));
    dispatch(changeControlCfopOperacoesFiscais(IDLE_CONTROL));
  }, []);

  useEffect(() => {
    fetchAll();
    resetModes();
    resetModels();
    resetDependencies();
  }, []);

  const handleActivateInactivate = async (event, id) => {
    event.preventDefault();

    const { active } = catalogosProdutos.find(
      (item) => item.id === id
    );

    const isActivation = !active;

    if (isActivation) {
      dispatch(activateByIdAsync(id));
    } else {
      dispatch(inactivateByIdAsync(id));
    }
  };

  const handleClickOnButtonNew = useCallback((event) => {
    event.stopPropagation();
    dispatch(changeToCreateMode());
  }, []);

  const handleClickOnEditButton = useCallback(
    (_event, id) => (
      history.push(
        t('com.muralis.qcx.url.moduloOperacionaisCatalogosProdutosDetalhes', { id })
      )
    ), [history]
  );
  const breadcrumbs = [
    {
      link: {
        to: '/',
        name: t('com.muralis.qcx.codigo'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.cadastros'),
      },
    },
    {
      link: {
        to: t('com.muralis.qcx.url.moduloOperacionais'),
        name: t('com.muralis.qcx.operacionais'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.catalogoProdutosMercadoria'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.principal'),
      },
    },
  ];
  const columns = [
    {
      field: 'partnumber',
      headerName: t('com.muralis.qcx.item.partNumber'),
      type: 'string',
      flex: 150,
      valueGetter: ({ row }) => (
        row?.partnumber
      ),
    },

    {
      field: 'apelido',
      headerName: t('com.muralis.qcx.apelido'),
      type: 'string',
      flex: 145,
      valueGetter: ({ row }) => (
        row?.apelido
      ),
    },
    {
      field: 'ncm',
      headerName: t('com.muralis.qcx.NCM.label'),
      hide: true,
      type: 'string',
      flex: 145,
      valueGetter: ({ row }) => (
        row?.ncm?.code + row?.ncm?.description
      ),
    },
    {
      field: 'clientes',
      headerName: t('com.muralis.qcx.nomeEmpresaClientes'),
      hide: true,
      type: 'string',
      flex: 145,
      valueGetter: ({ row }) => row?.clientes?.map((item) => item?.pessoa?.nome).join(', ')
      ,
    },
    {
      field: 'id',
      headerName: t('com.muralis.qcx.acoes.label'),
      flex: 150,
      align: 'center',
      renderCell: ({ row: { id, active } }) => (
        <>
          {userHasDeleteRole(authInfo, 'catalogo-produtos') && (
          <IconButton
            key={`stw-active${id}`}
            value={active}
            name={`stw-active-${id}`}
            onClick={(event) => handleActivateInactivate(event, id)}
          >
            <DeleteIcon color="error" />
          </IconButton>
          )}
          <IconButton
            key={`btn-view-${id}`}
            name={`btn-view-${id}`}
            onClick={(event) => handleClickOnEditButton(event, id)}
          >
            <PageviewIcon color="primary" />
          </IconButton>
        </>
      ),
    }
  ];

  const templateProps = {
    page: {
      title: t('com.muralis.qcx.catalogoProdutos.label'),
      icon: <ViewListIcon />,
      breadcrumbs,
    },
    control: {
      buttonNew: {
        description: t('com.muralis.qcx.catalogoProdutos.novoCatalogoProduto'),
        path: t('com.muralis.qcx.url.moduloOperacionaisCatalogosProdutosNovo'),
        onClick: handleClickOnButtonNew,
      },
    },
    table: {
      columns,
    },
  };

  const filterPropGetter = useCallback(
    (item) => item.description,
    []
  );

  return (
    <QCXSimpleConsultPageTemplate
      pageProps={templateProps.page}
      controlProps={templateProps.control}
      tableProps={templateProps.table}
      tableData={catalogosProdutos}
      isLoading={isAlternativeLoading}
      filterPropGetter={filterPropGetter}
      authInfo={authInfo}
      requiredRoles={['catalogo-produtos']}
    />
  );
}
