const PAIS_BRASIL_CODE = '105';

const configurePaisGetterByCode = (paises) => {
  const getPaisByCode = (paisCode) => {
    const foundPais = paises
      .find((pais) => (
        pais?.code === paisCode
      ));

    return foundPais;
  };

  return getPaisByCode;
};

export {
  PAIS_BRASIL_CODE,
};

export {
  configurePaisGetterByCode,
};
