import { isEmpty } from 'lodash';
import debounce from 'lodash/debounce';
import React, {
  useMemo,
  useEffect,
  useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  useParams,
  useHistory,
} from 'react-router';
import {
  save,
  register,
} from '../../../../../features/dispositivo-legal/dispositivoLegalAPI';
import {
  loading,
  success,
  failure,
  setModel,
  addToList,
  setError,
  resetModel,
  selectMode,
  setResponse,
  selectStatus,
  updateOnList,
  fetchByIdAsync,
  preparingAction,
  changeToUpdateMode,
  changeToCreateMode,
  changeToConsultMode,
  resetBackgroundMode,
  selectBackgroundMode,
  selectDispositivoLegal,
  changeToBackgroundCreateMode,
} from '../../../../../features/dispositivo-legal/dispositivoLegalSlice';
import {
  isNoneMode,
  isCreateMode,
  isUpdateMode,
  isConsultMode,
  isBackgroundCreateMode,
  isPreparingActionStatus,
} from '../../../../../utils/store/store-utils';
import QCXDispositivoLegalForm from '../../../../../components/dispositivo-legal/QCXDispositivoLegalForm';
import QCXRegistrationFormPageTemplate from '../../../../../templates/registration-form-page/QCXRegistrationFormPageTemplate';

export default function DispositivoLegalRegistrationPage({ authInfo = {} }) {
  const { t } = useTranslation();
  const { id } = useParams();

  const history = useHistory();

  const dispatch = useDispatch();

  const mode = useSelector(selectMode);
  const status = useSelector(selectStatus);
  const backgroundMode = useSelector(selectBackgroundMode);
  const dispositivoLegal = useSelector(selectDispositivoLegal);

  const isNone = useMemo(() => (
    isNoneMode(mode)
  ), [mode]);

  const isCreate = useMemo(() => (
    isCreateMode(mode)
  ), [mode]);

  const isBackgroundCreate = useMemo(() => (
    isBackgroundCreateMode(backgroundMode)
  ), [backgroundMode]);

  const isConsult = useMemo(() => (
    isConsultMode(mode)
  ), [mode]);

  const isUpdate = useMemo(() => (
    isUpdateMode(mode)
  ), [mode]);

  const isPreparingAction = useMemo(() => (
    isPreparingActionStatus(status)
  ), [status]);

  const handleChangeToPreparingAction = () => {
    dispatch(preparingAction());
  };

  const handleChangeToCreate = () => {
    dispatch(changeToCreateMode());
  };

  const handleChangeToBackgroundCreate = () => {
    dispatch(changeToBackgroundCreateMode());
  };

  const handleChangeToConsult = () => {
    dispatch(changeToConsultMode());
  };

  const handleChangeToUpdate = () => {
    dispatch(changeToUpdateMode());
  };

  const handleResetBackgroundMode = () => {
    dispatch(resetBackgroundMode());
  };

  useEffect(() => {
    if (!isEmpty(id)) {
      dispatch(fetchByIdAsync(id));
      handleChangeToConsult();
    }
  }, [id]);

  const handleCancelUpdate = () => {
    const currentId = isBackgroundCreate ? dispositivoLegal?.id : id;

    if (currentId) {
      dispatch(fetchByIdAsync(currentId));
    }

    handleChangeToConsult();
  };

  const normalize = useCallback((unnormalizedData) => {
    const normalizedData = {
      ...unnormalizedData,
    };

    return normalizedData;
  }, []);

  const unnormalize = useCallback((normalizedData) => {
    const unnormalizedData = {
      ...normalizedData,
    };

    return unnormalizedData;
  }, []);

  const handleDispatchSetModel = useCallback((data) => {
    dispatch(setModel(normalize(data)));
  }, [normalize]);

  const create = async (data) => {
    const executeDebounced = debounce(async () => {
      try {
        const response = await register(data);

        if (response?.status === 201) {
          dispatch(resetModel());

          const created = response?.data;

          handleDispatchSetModel(created);
          dispatch(addToList({ data: created }));

          const handleResultWithDebounce = debounce(() => {
            history.push('/cadastros/operacionais/dispositivo-legal');

            dispatch(success());
            dispatch(setResponse({
              status: response.status,
              data: created,
              message: t('com.muralis.qcx.mensagem.dispositivoLegalCadastrado', { dispositivoLegal: data?.description }),
            }));
          }, 500);

          handleResultWithDebounce();
        }
      } catch (error) {
        dispatch(failure());
        let errorMessage = t('com.muralis.qcx.erro.erroSalvarDispositivoLegal');
        if (error?.response && error?.response?.data) {
          errorMessage = t('com.muralis.qcx.erro.erroSalvarDispositivoLegalMensagem', { erro: error?.response.data.message });
        }
        dispatch(setError({
          message: errorMessage,
        }));
      }
    }, 500);

    dispatch(loading());
    executeDebounced();
  };

  const update = async (data) => {
    const executeDebounced = debounce(async () => {
      try {
        const response = await save(data);

        if (response?.status === 200) {
          const handleResultWithDebounce = debounce(() => {
            handleChangeToConsult();
            dispatch(success());

            const saved = response?.data;

            dispatch(setResponse({
              status: response.status,
              data: saved,
              message: t('com.muralis.qcx.mensagem.dispositivoLegalSalvo', { dispositivoLegal: saved?.description }),
            }));

            dispatch(setModel(saved));
            dispatch(updateOnList({ data: saved }));
            dispatch(fetchByIdAsync(saved?.id));
          }, 500);

          handleResultWithDebounce();
          handleChangeToConsult();
        }
      } catch ({ response }) {
        dispatch(failure());
        dispatch(setError({
          message: t('com.muralis.qcx.erro.erroSalvarDispositivoLegalEspecifico', { dispositivoLegal: data?.description, erro: response?.data?.message }),
        }));
      }
    }, 500);

    dispatch(loading());
    executeDebounced();
  };

  const handleSubmit = async (data) => {
    const normalizedData = normalize(data);

    if (isUpdate && !isBackgroundCreate) {
      await update(normalizedData);
    } else {
      await create(normalizedData);
    }
  };

  const model = useMemo(() => (
    !isCreate
      ? unnormalize(dispositivoLegal)
      : {}
  ), [isCreate, dispositivoLegal, unnormalize]);

  const actionName = useMemo(() => {
    if (isCreate || isBackgroundCreate || isNone) return t('com.muralis.qcx.acoes.novo');
    if (isConsult) return t('com.muralis.qcx.acoes.visualizar');
    return t('com.muralis.qcx.acoes.alterar');
  }, [isNone, isCreate, isConsult, isBackgroundCreate]);

  const breadcrumbs = useMemo(() => ([
    {
      link: {
        to: '/',
        name: t('com.muralis.qcx.inicio'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.cadastros'),
      },
    },
    {
      link: {
        to: t('com.muralis.qcx.url.moduloOperacionais'),
        name: t('com.muralis.qcx.operacionais'),
      },
    },
    {
      link: {
        to: '/cadastros/operacionais/dispositivo-legal',
        name: t('com.muralis.qcx.dispositivoLegal.label'),
      },
    },
    {
      text: {
        name: actionName,
      },
    },
  ]), [actionName]);

  const pageTitle = useMemo(() => (
    isNone || isCreate || isBackgroundCreate
      ? t('com.muralis.qcx.dispositivoLegal.novoDispositivoLegal')
      : t('com.muralis.qcx.dispositivoLegal.dispositivoLegalExistente', { ID: dispositivoLegal?.id || '-' })
  ), [isNone, isCreate, isBackgroundCreate, dispositivoLegal]);

  return (
    <QCXRegistrationFormPageTemplate
      pageTitle={pageTitle}
      breadcrumbs={breadcrumbs}
      isCreate={isCreate}
      isConsult={isConsult}
      isUpdate={isUpdate}
      isBackgroundCreate={isBackgroundCreate}
      isPreparingAction={isPreparingAction}
      handleChangeToPreparingAction={handleChangeToPreparingAction}
      handleChangeToCreate={handleChangeToCreate}
      handleChangeToBackgroundCreate={handleChangeToBackgroundCreate}
      handleChangeToConsult={handleChangeToConsult}
      handleChangeToUpdate={handleChangeToUpdate}
      handleCancelUpdate={handleCancelUpdate}
      handleResetBackgroundMode={handleResetBackgroundMode}
      authInfo={authInfo}
    >
      {(formProps) => (
        <QCXDispositivoLegalForm
          initialValues={model}
          handleChangeModel={handleDispatchSetModel}
          handleSubmit={handleSubmit}
          authInfo={authInfo}
          requiredRoles={['dispositivo-legal']}
          {...formProps}
        />
      )}
    </QCXRegistrationFormPageTemplate>
  );
}
