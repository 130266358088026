import { useCallback } from "react";
import { useVerifyPermission } from "./useVerifyPermission";

//Return true if user has any of the informed permissions
export const useVerifyAnyPermission = (): [
  (permissions: string[]) => boolean
] => {
  const [verifyPermission] = useVerifyPermission();

  const verifyHasAnyPermission = useCallback(
    (permissions: string[]) => {
      let hasPermission = false;

      permissions.forEach((per) => {
        if (verifyPermission(per)) {
          hasPermission = true;
          return;
        }
      });
      return hasPermission;
    },
    [verifyPermission]
  );

  return [verifyHasAnyPermission];
};
