import i18n from '../../i18n';
import { getOptionByValueInList } from '../../utils/general/general-utils';

const TIPO_PRODUTO_IMPORTACAO = 'IMPORTACAO';
const TIPO_PRODUTO_EXPORTACAO = 'EXPORTACAO';
const TIPO_PRODUTO_ASSESSORIA = 'ASSESSORIA';
const TIPO_PRODUTO_LI = 'LI';
const TIPO_PRODUTO_FATURA = 'FATURA';

const TIPO_PRODUTO_LIST = [
  {
    label: i18n.t('com.muralis.qcx.exportacao.label'),
    value: TIPO_PRODUTO_EXPORTACAO,
  },
  {
    label: i18n.t('com.muralis.qcx.importacao.label'),
    value: TIPO_PRODUTO_IMPORTACAO,
  },
  {
    label: 'Assessoria',
    value: TIPO_PRODUTO_ASSESSORIA,
  },
  {
    label: 'LI',
    value: TIPO_PRODUTO_LI,
  },
  {
    label: 'Fatura',
    value: TIPO_PRODUTO_FATURA,
  },
];

const isTipoProdutoImportacao = (tipoProduto) => tipoProduto === TIPO_PRODUTO_IMPORTACAO;

const isTipoProdutoExportacao = (tipoProduto) => tipoProduto === TIPO_PRODUTO_EXPORTACAO;

const isTipoProdutoAssessoria = (tipoProduto) => tipoProduto === TIPO_PRODUTO_ASSESSORIA;

const isTipoProdutoLI = (tipoProduto) => tipoProduto === TIPO_PRODUTO_LI;

const isTipoProdutoFatura = (tipoProduto) => tipoProduto === TIPO_PRODUTO_FATURA;

function getTipoProdutoLabelByValue(value) {
  const foundOption = getOptionByValueInList(TIPO_PRODUTO_LIST, value);
  return foundOption && foundOption?.label;
}

const TipoProdutoUtils = Object.freeze({
  LIST: TIPO_PRODUTO_LIST,
  IMPORTACAO: TIPO_PRODUTO_IMPORTACAO,
  EXPORTACAO: TIPO_PRODUTO_EXPORTACAO,
  ASSESSORIA: TIPO_PRODUTO_ASSESSORIA,
  LI: TIPO_PRODUTO_LI,
  FATURA: TIPO_PRODUTO_FATURA,
  isImportacao: isTipoProdutoImportacao,
  isExportacao: isTipoProdutoExportacao,
  isAssessoria: isTipoProdutoAssessoria,
  isLI: isTipoProdutoLI,
  isFatura: isTipoProdutoFatura,
  getLabelByValue: getTipoProdutoLabelByValue,
});

export {
  TipoProdutoUtils,
  TIPO_PRODUTO_LIST,
  TIPO_PRODUTO_IMPORTACAO,
  TIPO_PRODUTO_EXPORTACAO,
  TIPO_PRODUTO_ASSESSORIA,
  TIPO_PRODUTO_LI,
  TIPO_PRODUTO_FATURA,
  isTipoProdutoImportacao,
  isTipoProdutoExportacao,
  isTipoProdutoAssessoria,
  getTipoProdutoLabelByValue,
};
