import React, { useMemo } from 'react';
import BaseSelectWithOptions from '../base-components/BaseSelectWithOptions';

function AutorizacaoEmbarqueSelect({ ...props }) {
  const list = useMemo(() => [
    {
      id: 'Sim', label: 'Sim', value: 'SIM',
    }, {
      id: 'Não', label: 'Não', value: 'NAO',
    }
  ], []);

  return (
    <BaseSelectWithOptions
      list={list}
      {...props}
    />
  );
}

export default AutorizacaoEmbarqueSelect;
