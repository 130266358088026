import {
  IconButton,
  InputAdornment,
  Tooltip,
} from '@material-ui/core';
import {
  RemoveCircle as RemoveCircleIcon,
} from '@material-ui/icons';
import _, { isFunction } from 'lodash';
import React, { useCallback } from 'react';
import { useForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import QCXTextField from '../text-field/QCXTextField';
import QCXFinalInputFile from './QCXFinalInputFile';

const QCXFinalInputFileWithRef = React.forwardRef((props, ref) => (
  <QCXFinalInputFile
    {...props}
    ref={ref}
  />
));

export default function QCXFinalCustomInputFile({ ...props }) {
  const { t } = useTranslation();

  const {
    id,
    key,
    name,
    label,
    accept,
    tooltipTitle,
    fileGetter,
    fileNameGetter,
    defaultSelectionText = t('com.muralis.qcx.arquivo.nenhumArquivoSelecionado'),
    disableRemoveControl = false,
    inputRef,
    required,
    disabled,
    ...restProps
  } = props;

  const form = useForm();

  const getValue = useCallback((file) => {
    if (isFunction(fileNameGetter)) {
      const fileName = fileNameGetter(file) || defaultSelectionText;

      return fileName;
    }

    const defaultText = file?.name || defaultSelectionText;

    return defaultText;
  }, [fileNameGetter]);

  const handleRemoveLoadedFile = useCallback((event) => {
    if (isFunction(event)) {
      event?.stopPropagation();
    }

    form.change(name, undefined);
  }, [form]);

  return (
    <>
      <QCXFinalInputFileWithRef
        id={id}
        key={key}
        label={label}
        name={name}
        accept={accept}
        tooltipTitle={tooltipTitle}
        fileGetter={fileGetter}
        disabled={disabled}
        required={required}
        inputRef={inputRef}
        {...restProps}
      >
        {(file, renderControlButton) => (
          <QCXTextField
            id={`read-only-text-field-${id}`}
            key={`read-only-text-field-${id}`}
            name={name}
            label={label}
            value={getValue(file)}
            InputProps={{
              required,
              readOnly: true,
              endAdornment: _.isUndefined(file) || disableRemoveControl
                ? renderControlButton()
                : (
                  <Tooltip
                    title={t('com.muralis.qcx.arquivo.removerArquivoSelecionado')}
                    arrow
                  >
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="remove selected file"
                        onClick={handleRemoveLoadedFile}
                        disabled={disabled}
                      >
                        <RemoveCircleIcon color={disabled ? 'disabled' : 'primary'} />
                      </IconButton>
                    </InputAdornment>
                  </Tooltip>
                ),
            }}
            required={required}
            disabled={disabled}
          />
        )}
      </QCXFinalInputFileWithRef>
    </>
  );
}
