import { createFilterOptions } from '@material-ui/lab/useAutocomplete';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllAsync, selectEmbarcacoes } from '../../features/embarcacoes/embarcacoesSlice';
import QCXAutocompleteSelect from '../autocomplete-select/QCXAutocompleteSelect';

export default function QCXSelectEmbarcacaoAutocomplete({ label, name, hideCode, initialValues, ...restProps }) {
  const dispatch = useDispatch();

  const [defaultValue, setDefaultValue] = useState('');

  const list = useSelector(selectEmbarcacoes);

  useEffect(() => {
    dispatch(fetchAllAsync());
  }, []);

  useEffect(() => {
    const value = list.find((item) => item?.id === _.get(initialValues, name)?.id);

    if (value) {
      setDefaultValue(value);
    } else {
      setDefaultValue({ id: null, description: '' });
    }
  }, [list, initialValues]);

  const filterOptions = createFilterOptions({
    stringify: (item) => (hideCode ? `${item?.description}` : `${item?.code} - ${item?.description}`),
  });

  const getOptionLabel = (item) => {
    if (!item) return '-';
    if (!item?.description) return '-';
    if (!hideCode && !item?.code) return '-';

    return hideCode ? `${item?.description}` : `${item?.code} - ${item?.description}`;
  };

  return (
    <QCXAutocompleteSelect
      label={label}
      id={name}
      name={name}
      options={list}
      filterOptions={filterOptions}
      defaultValue={defaultValue}
      getOptionLabel={(item) => getOptionLabel(item)}
      getOptionValue={(item) => item}
      {...restProps}
    />
  );
}
