import React, { useCallback, useMemo } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useForm, useFormState } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { Info as InfoIcon } from '@material-ui/icons';
import {
  CompareArrows as CompareArrowsIcon,
} from '@mui/icons-material';
import { Grid, Tooltip } from '@material-ui/core';
import {
  selectRelatedSelectionLicencaImportacaoList,
  selectRelatedSelectionItemList,
  selectRelatedSelectionChangedItemList,
  selectRelatedTransferenciaItemModel,
  selectRelatedSelectionAvailableItemList,
} from '../../features/licenca-importacao/licencaImportacaoSelectors';
import {
  setRelatedSelectionItemList,
  addItemToChangedSelectionList,
  removeItemFromChangedSelectionList,
  setRelatedSelectionAvailableItemList,
  setRelatedTransferenciaItemModel,
  resetRelatedTransferenciaItemModel,
} from '../../features/licenca-importacao/licencaImportacaoSlice';
import QCXDataGridControl from '../../shared-components/data-grid-control/QCXDataGridControl';
import QCXFinalFormDialog from '../../shared-components/dialog/QCXFinalFormDialog';
import useFormDialogSync from '../../utils/hooks/form/dialog/useFormDialogSync';
import { CLOSED_STATUS, isOpenStatus, SUBMITTING_STATUS } from '../../utils/hooks/form/dialog/formDialogUtils';
import QCXButton from '../../shared-components/button/QCXButton';
import { normalizeNumeral, unnormalizeNumeral } from '../../utils/general/general-utils';
import { formatBrazilianNumericDecimal } from '../../utils/hooks/form/field/formatters';
import QCXSelectModoTransferenciaFaturaAutocomplete from '../../shared-components/select-modo-transferencia-fatura/QCXSelectTipoFaturaAutocomplete';
import { maxValue, required } from '../../utils/validators/field/validator';
import QCXFinalNumericDecimalField from '../../shared-components/final-numeric-decimal-field/QCXFinalNumericDecimalField';
import ModoTransferenciaFaturaUtils from '../../shared-components/select-modo-transferencia-fatura/modoTransferenciaFaturaUtils';
import useValidators from '../../utils/hooks/useValidators';
import QCXBox from '../../shared-components/box/QCXBox';
import { FaturaUtils } from '../../utils/general/fatura/faturaUtils';

export default function QCXLicencaImportacaoTransferenciaDataGridManager({
  isBackgroundTransfer,
  rows = [],
  ...restProps
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const form = useForm();
  const { values } = useFormState();

  const relatedTransferenciaItemModel = useSelector(selectRelatedTransferenciaItemModel);
  const relatedSelectionLicencaImportacaoList = useSelector(
    selectRelatedSelectionLicencaImportacaoList
  );
  const relatedSelectionAvailableItemList = useSelector(selectRelatedSelectionAvailableItemList);
  const relatedSelectionItemList = useSelector(selectRelatedSelectionItemList);
  const relatedSelectionChangedItemList = useSelector(selectRelatedSelectionChangedItemList);
  const isEmptySelectionItemList = useMemo(() => (
    !relatedSelectionItemList || (
      _.isArrayLikeObject(relatedSelectionItemList)
      && _.isEmpty(relatedSelectionItemList)
    )
  ), [relatedSelectionItemList]);

  const handleSelectionModelChange = useCallback(({ selectionModel }) => {
    if (isBackgroundTransfer) {
      dispatch(setRelatedSelectionItemList(selectionModel));

      const mappedItems = rows
        .filter((item) => (
          selectionModel?.includes(item?.id)
        ))
        .map((item) => ({
          quantidade: item?.saldoAtual,
          pesoLiquido: item?.proprietario?.pesoLiquido,
          modo: ModoTransferenciaFaturaUtils.POR_QUANTIDADE,
          ...item?.transferencia,
          itemOriginal: {
            id: item?.id,
            quantidade: item?.saldoInicial,
            pesoLiquido: item?.proprietario?.pesoLiquido,
            fatura: item?.fatura,
          },
        }));

      form.change('itens', mappedItems);

      const isSelectedItemBy = (id) => (
        selectionModel?.indexOf(id) !== -1
      );

      const isModifiedItemBy = (id) => (
        relatedSelectionChangedItemList?.indexOf(id) !== -1
      );

      const hasAnyModifiedAndNotSelectedItem = rows?.some((selectedItem) => (
        isModifiedItemBy(selectedItem?.id) && !isSelectedItemBy(selectedItem?.id)
      ));

      if (hasAnyModifiedAndNotSelectedItem) {
        const updatedSelectionAvailableItemList = relatedSelectionAvailableItemList
          ?.map((mercadoria) => {
            if (isModifiedItemBy(mercadoria?.id) && !isSelectedItemBy(mercadoria?.id)) {
              dispatch(
                removeItemFromChangedSelectionList(
                  mercadoria?.id
                )
              );

              return {
                ...mercadoria,
                transferencia: undefined,
              };
            }

            return mercadoria;
          });

        dispatch(
          setRelatedSelectionAvailableItemList(
            updatedSelectionAvailableItemList
          )
        );
      }
    }
  }, [
    form,
    rows,
    isBackgroundTransfer,
    relatedSelectionChangedItemList,
    relatedSelectionAvailableItemList,
  ]);

  const handleModoTransferenciaChange = useCallback((formControl, formValues) => (value) => {
    const pesoLiquidoValue = formValues?.itemOriginal?.pesoLiquido;
    const quantidadeValue = formValues?.itemOriginal?.quantidade;

    if (ModoTransferenciaFaturaUtils.isPorQuantidade(value)) {
      const formattedPesoLiquido = unnormalizeNumeral(
        pesoLiquidoValue,
        formatBrazilianNumericDecimal(7)
      );

      formControl.change('pesoLiquido', formattedPesoLiquido);
      return;
    }

    if (ModoTransferenciaFaturaUtils.isPorPesoLiquido(value)) {
      const formattedQuantidade = unnormalizeNumeral(
        quantidadeValue,
        formatBrazilianNumericDecimal(5)
      );

      formControl.change('quantidade', formattedQuantidade);
      return;
    }

    formControl.change('quantidade', quantidadeValue);
    formControl.change('pesoLiquido', pesoLiquidoValue);
  }, []);

  const handleQuantidadeChange = useCallback((formControl, formValues) => (value) => {
    const quantidadeOriginal = formValues?.itemOriginal?.quantidade;
    const pesoLiquidoOriginal = formValues?.itemOriginal?.pesoLiquido;

    if (value) {
      const quantidadeAtual = normalizeNumeral(value);
      const pesoLiquidoEquivalente = FaturaUtils.calculatePesoLiquidoEquivalenteBy(
        quantidadeAtual,
        quantidadeOriginal,
        pesoLiquidoOriginal
      );

      const formattedPesoLiquidoEquivalente = unnormalizeNumeral(
        pesoLiquidoEquivalente,
        formatBrazilianNumericDecimal(7)
      );

      formControl.change('pesoLiquidoEquivalente', formattedPesoLiquidoEquivalente);

      return;
    }

    const formattedPesoLiquido = unnormalizeNumeral(
      0,
      formatBrazilianNumericDecimal(7)
    );

    formControl.change('pesoLiquidoEquivalente', formattedPesoLiquido);
  }, []);

  const changeListeners = useMemo(() => (
    [
      {
        name: 'modo',
        fn: handleModoTransferenciaChange,
      },
      {
        name: 'quantidade',
        fn: handleQuantidadeChange,
      }
    ]
  ), [
    handleModoTransferenciaChange,
    handleQuantidadeChange,
  ]);

  const [
    handleAlterarModoTransferenciaClick,
    formDialogAlterarModoTransferenciaStatus,
    handleFormDialogAlterarModoTransferenciaStatus,
  ] = useFormDialogSync(
    async (event, data) => {
      if (_.isFunction(event?.stopPropagation)) {
        event.stopPropagation();

        const quantidadeTotal = data?.saldoAtual;
        const quantidadeParcial = normalizeNumeral(
          data?.transferencia?.quantidade
          || data?.saldoAtual
        );
        const pesoLiquidoTotal = data?.proprietario?.pesoLiquido;

        const pesoLiquidoEquivalente = FaturaUtils.calculatePesoLiquidoEquivalenteBy(
          quantidadeParcial,
          quantidadeTotal,
          pesoLiquidoTotal
        );

        dispatch(
          setRelatedTransferenciaItemModel({
            modo: (
              data?.transferencia?.modo
              || ModoTransferenciaFaturaUtils.POR_QUANTIDADE
            ),
            quantidade: unnormalizeNumeral(
              (
                data?.transferencia?.quantidade
                || data?.saldoAtual
              ),
              formatBrazilianNumericDecimal(5)
            ),
            pesoLiquidoEquivalente: unnormalizeNumeral(
              pesoLiquidoEquivalente,
              formatBrazilianNumericDecimal(7)
            ),
            pesoLiquido: unnormalizeNumeral(
              (
                data?.transferencia?.pesoLiquido
                || data?.proprietario?.pesoLiquido
              ),
              formatBrazilianNumericDecimal(7)
            ),
            itemOriginal: {
              id: data?.id,
              numero: data?.proprietario?.item,
              quantidade: data?.saldoAtual,
              pesoLiquido: data?.proprietario?.pesoLiquido,
              fatura: data?.fatura,
            },
          })
        );
      }
    },
    []
  );

  const handleAlterarModoTransferenciaByDataSubmit = useCallback((data) => {
    handleFormDialogAlterarModoTransferenciaStatus(SUBMITTING_STATUS);

    const updatedItemsFormState = values?.itens?.map((currentItem) => (
      currentItem?.itemOriginal?.id === data?.itemOriginal?.id
        ? data
        : currentItem
    ));

    form.change('itens', updatedItemsFormState);

    const isPristineItem = (
      normalizeNumeral(data?.quantidade) === data?.itemOriginal?.quantidade
      && normalizeNumeral(data?.pesoLiquido) === data?.itemOriginal?.pesoLiquido
    );

    if (!isPristineItem) {
      dispatch(
        addItemToChangedSelectionList(
          data?.itemOriginal?.id
        )
      );
    }

    const isPreviousChanged = (
      relatedSelectionChangedItemList?.indexOf(
        data?.itemOriginal?.id
      ) !== -1
    );

    const isUndoPreviousChangedItem = (
      isPristineItem && isPreviousChanged
    );

    if (isUndoPreviousChangedItem) {
      dispatch(
        removeItemFromChangedSelectionList(
          data?.itemOriginal?.id
        )
      );
    }

    const updatedSelectionAvailableItemList = relatedSelectionAvailableItemList
      ?.map((mercadoria) => (
        mercadoria?.id === data?.itemOriginal?.id
          ? {
            ...mercadoria,
            transferencia: {
              modo: data?.modo,
              quantidade: data?.quantidade,
              pesoLiquido: data?.pesoLiquido,
            },
          } : mercadoria
      ));

    dispatch(
      setRelatedSelectionAvailableItemList(
        updatedSelectionAvailableItemList
      )
    );

    (_.debounce(() => {
      dispatch(resetRelatedTransferenciaItemModel());
    }, 200))();
  }, [
    rows,
    form,
    values,
    relatedSelectionLicencaImportacaoList,
    relatedSelectionChangedItemList,
    handleFormDialogAlterarModoTransferenciaStatus,
  ]);

  const handleCloseFormDialogAlterarModoTransferencia = useCallback(() => {
    handleFormDialogAlterarModoTransferenciaStatus(CLOSED_STATUS);

    (_.debounce(() => {
      dispatch(resetRelatedTransferenciaItemModel());
    }, 200))();
  }, [handleFormDialogAlterarModoTransferenciaStatus]);

  const isFormDialogAlterarModoTransferenciaOpen = useMemo(() => (
    isOpenStatus(formDialogAlterarModoTransferenciaStatus)
  ));

  const formDialogAlterarModoTransferenciaContent = useMemo(() => (
    t('com.muralis.qcx.mensagem.altereQuantidadeTransferenciaLicencaImportacao', { item: relatedTransferenciaItemModel?.itemOriginal?.numero || '###', licencaNumero: relatedTransferenciaItemModel?.itemOriginal?.licencaImportacao?.numero || '###' })
  ), [relatedTransferenciaItemModel]);

  const columns = useMemo(() => ([
    {
      field: 'fatura.atributosAdicionais.processo',
      headerName: t('com.muralis.qcx.seqLI'),
      headerAlign: 'center',
      align: 'center',
      flex: 120,
      valueGetter: ({ row }) => (
        row?.fatura?.atributosAdicionais?.processo
      ),
    },
    {
      field: 'fatura.atributosAdicionais.registro',
      headerName: t('com.muralis.qcx.numeroLI'),
      headerAlign: 'center',
      align: 'center',
      flex: 120,
      valueGetter: ({ row }) => (
        row?.fatura?.atributosAdicionais?.registro || '-'
      ),
    },
    {
      field: 'item',
      headerName: t('com.muralis.qcx.item.numeroItem'),
      headerAlign: 'center',
      align: 'center',
      flex: 100,
      valueGetter: ({ row }) => row?.proprietario?.item,
    },
    {
      field: 'exportador',
      headerName: t('com.muralis.qcx.exportador.label'),
      headerAlign: 'left',
      align: 'left',
      flex: 180,
      valueGetter: ({ row }) => (
        row?.proprietario?.exportador?.pessoaJuridica?.nome
      ),
    },
    {
      field: 'partnumber',
      headerName: t('com.muralis.qcx.item.partNumber'),
      flex: 130,
      valueGetter: ({ row }) => (
        row?.proprietario?.partnumber || t('com.muralis.qcx.validacao.naoPreenchido').toUpperCase()
      ),
    },
    {
      field: 'quantidade',
      headerName: t('com.muralis.qcx.quantidade.label'),
      headerAlign: 'center',
      align: 'center',
      flex: 120,
      renderCell: ({ row }) => {
        const quantidadeTotalInicial = row?.saldoInicial;
        const quantidadeTotalDisponivel = row?.saldoAtual;
        const quantidadeParcial = normalizeNumeral(row?.transferencia?.quantidade);
        const pesoLiquidoTotal = row?.proprietario?.pesoLiquido;

        const formattedQuantidadeTotalDisponivel = unnormalizeNumeral(
          quantidadeTotalDisponivel,
          formatBrazilianNumericDecimal(5)
        );

        if (quantidadeTotalInicial === quantidadeTotalDisponivel && !quantidadeParcial) {
          return formattedQuantidadeTotalDisponivel;
        }

        const pesoLiquidoEquivalente = FaturaUtils.calculatePesoLiquidoEquivalenteBy(
          quantidadeParcial,
          quantidadeTotalDisponivel,
          pesoLiquidoTotal
        );

        const formattedQuantidadeTotalInicial = unnormalizeNumeral(
          quantidadeTotalInicial,
          formatBrazilianNumericDecimal(5)
        );

        const formattedQuantidadeParcial = unnormalizeNumeral(
          quantidadeParcial,
          formatBrazilianNumericDecimal(5)
        );

        const formattedPesoLiquidoEquivalente = unnormalizeNumeral(
          pesoLiquidoEquivalente,
          formatBrazilianNumericDecimal(7)
        );

        const isChangedQuantidadeItem = (
          ModoTransferenciaFaturaUtils.isPorQuantidade(row?.transferencia?.modo)
          && quantidadeTotalDisponivel !== quantidadeParcial
        );

        const formattedQuantidade = isChangedQuantidadeItem
          ? formattedQuantidadeParcial
          : formattedQuantidadeTotalDisponivel;

        const helperText = isChangedQuantidadeItem
          ? t('com.muralis.qcx.pesoLiquidoRelativo', { pesoLiquido: formattedPesoLiquidoEquivalente })
          : t('com.muralis.qcx.quantidadeInicial', { quantidadeInicial: formattedQuantidadeTotalInicial });

        return (
          <>
            <QCXBox
              pr={1}
            >
              {formattedQuantidade}
            </QCXBox>
            <Tooltip
              title={helperText}
              arrow
            >
              <InfoIcon
                color="disabled"
                fontSize="small"
              />
            </Tooltip>
          </>
        );
      },
    },
    {
      field: 'valorUnitarioMoeda',
      headerName: t('com.muralis.qcx.valorUnitario'),
      headerAlign: 'right',
      align: 'right',
      flex: 120,
      valueGetter: ({ row }) => (
        unnormalizeNumeral(
          row?.proprietario?.valorUnitarioMoeda,
          formatBrazilianNumericDecimal(2)
        ) || '-'
      ),
    },
    {
      field: 'actions',
      headerName: t('com.muralis.qcx.acoes.label'),
      headerAlign: 'center',
      align: 'center',
      flex: 100,
      renderCell: ({ row }) => (
        <>
          <QCXButton
            key={`btn-change-values-${row?.proprietario?.id}`}
            name={`btn-change-values-${row?.proprietario?.id}`}
            variant="text"
            color="secondary"
            size="small"
            tooltipDescription={t('com.muralis.qcx.acoes.alterarQuantidade')}
            tooltip
            onClick={(event) => {
              handleAlterarModoTransferenciaClick(
                event,
                row
              );
            }}
            disabled={(
              isEmptySelectionItemList
              || !relatedSelectionItemList.includes(row?.proprietario?.id)
            )}
          >
            <CompareArrowsIcon />
          </QCXButton>
        </>
      ),
    },
  ]), [
    isEmptySelectionItemList,
    relatedSelectionItemList,
  ]);

  return (
    <>
      <QCXDataGridControl
        checkboxSelection
        columns={columns}
        rows={rows}
        selectionModel={relatedSelectionItemList}
        onSelectionModelChange={handleSelectionModelChange}
        {...restProps}
      />
      <QCXFinalFormDialog
        key="form-dialog-alterar-modo-transferencia"
        id="form-dialog-alterar-modo-transferencia"
        title={t('com.muralis.qcx.acoes.alterarQuantidade')}
        content={formDialogAlterarModoTransferenciaContent}
        dialogActionsProps={{
          button: {
            submit: {
              description: t('com.muralis.qcx.acoes.confirmar'),
              size: 'small',
            },
            cancel: {
              description: t('com.muralis.qcx.acoes.cancelar'),
              size: 'small',
            },
          },
        }}
        open={isFormDialogAlterarModoTransferenciaOpen}
        initialValues={relatedTransferenciaItemModel}
        onSubmit={handleAlterarModoTransferenciaByDataSubmit}
        onClose={handleCloseFormDialogAlterarModoTransferencia}
        formProps={{
          onChangeListeners: changeListeners,
        }}
      >
        {({ values: initialValuesModoTransferencia }) => (
          <Grid
            item
            container
            xs={12}
            spacing={2}
          >
            {false && (
              <Grid
                item
                xs={12}
              >
                <QCXSelectModoTransferenciaFaturaAutocomplete
                  id="select-field-modo-transferencia-licenca-importacao"
                  key="select-field-modo-transferencia-licenca-importacao"
                  name="modo"
                  label={t('com.muralis.qcx.acoes.transferencia.transferirPor')}
                  initialValues={initialValuesModoTransferencia}
                  fieldProps={{
                    validate: required,
                  }}
                  required
                />
              </Grid>
            )}
            {ModoTransferenciaFaturaUtils.isPorQuantidade(
              initialValuesModoTransferencia?.modo
            ) && (
              <>
                <Grid
                  item
                  xs={12}
                >
                  <QCXFinalNumericDecimalField
                    id="numeric-decimal-quantidade-field"
                    key="numeric-decimal-quantidade-field"
                    name="quantidade"
                    label={t('com.muralis.qcx.quantidade.label')}
                    scale={5}
                    validate={useValidators([
                      required,
                      maxValue(
                        Number(
                          initialValuesModoTransferencia?.itemOriginal?.quantidade
                        ).toFixed(5)
                      )
                    ])}
                    required={required && (
                      Number.isNaN(Number(initialValuesModoTransferencia?.quantidadeParcial))
                      || Number(initialValuesModoTransferencia?.quantidadeParcial) <= 0
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <QCXFinalNumericDecimalField
                    id="text-number-peso-liquido-equivalente-field"
                    key="text-number-peso-liquido-equivalente-field"
                    name="pesoLiquidoEquivalente"
                    label={t('com.muralis.qcx.pesoLiquido')}
                    scale={7}
                    disabled
                    helperText={t('com.muralis.qcx.mensagem.equivalenteQuantidadeAtual')}
                  />
                </Grid>
              </>
            )}
            {ModoTransferenciaFaturaUtils.isPorPesoLiquido(
              initialValuesModoTransferencia?.modo
            ) && (
              <Grid
                item
                xs={12}
              >
                <QCXFinalNumericDecimalField
                  id="text-number-peso-liquido-field"
                  key="text-number-peso-liquido-field"
                  name="pesoLiquido"
                  label={t('com.muralis.qcx.pesoLiquido')}
                  scale={7}
                  validate={useValidators([
                    required,
                    maxValue(
                      Number(
                        initialValuesModoTransferencia?.itemOriginal?.pesoLiquido
                      ).toFixed(7)
                    )
                  ])}
                  required
                />
              </Grid>
            )}
          </Grid>
        )}
      </QCXFinalFormDialog>
    </>
  );
}
