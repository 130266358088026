import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import QCXFinalPercentualField from '../final-percentual-field/QCXFinalPercentualField';
import QCXFinalTextField from '../final-text-field/QCXFinalTextField';
import QCXFinalAlternativeCurrencyField from '../final-currency-field/QCXFinalAlternativeCurrencyField';

const useStyles = makeStyles(() => ({
  gridFields: {
    paddingBottom: '16px',
  },
}));

export default function QCXDemonstracaoCoeficienteReducaoFormGroup({
  intl,
  modes,
  submitSucceeded,
}) {
  const { t } = useTranslation();
  const classes = useStyles();

  const {
    isConsult,
    isSubNone,
    isSubConsult,
  } = modes;

  return (
    <>
      <Grid
        item
        className={classes.gridFields}
        xs={12}
        sm={6}
        md={6}
        lg={6}
      >
        <QCXFinalTextField
          id="text-field-identificacao-demonstrativo-coeficiente-reducao-ii"
          key="text-field-identificacao-demonstrativo-coeficiente-reducao-ii"
          name="ii.identificacao"
          label={t('com.muralis.qcx.identificacao')}
          disabled={isConsult || isSubConsult || isSubNone}
        />
      </Grid>
      <Grid
        item
        className={classes.gridFields}
        xs={12}
        sm={6}
        md={6}
        lg={6}
      >
        <QCXFinalPercentualField
          id="coeficiente-reducao-demonstrativo-coeficiente-reducao-ii"
          key="coeficiente-reducao-demonstrativo-coeficiente-reducao-ii"
          name="ii.coeficienteReducao"
          label={t('com.muralis.qcx.coficienteReducao')}
          disabled={isConsult || isSubConsult || isSubNone}
          scale={7}
        />
      </Grid>
      <Grid
        item
        className={classes.gridFields}
        xs={12}
        sm={6}
        md={6}
        lg={6}
      >
        <QCXFinalAlternativeCurrencyField
          id="currency-valor-dolar-coeficiente-reducao-ii"
          key="currency-valor-dolar-coeficiente-reducao-ii"
          name="ii.valorDolar"
          label={t('com.muralis.qcx.moeda.valorDolar')}
          disabled={isConsult || isSubConsult || isSubNone}
          submitSucceeded={submitSucceeded}
          scale={7}
        />
      </Grid>
      <Grid
        item
        className={classes.gridFields}
        xs={12}
        sm={6}
        md={6}
        lg={6}
      >
        <QCXFinalAlternativeCurrencyField
          id="currency-valor-reais-coeficiente-reducao-ii"
          key="currency-valor-reais-coeficiente-reducao-ii"
          name="ii.valorReal"
          label={t('com.muralis.qcx.moeda.valorReal')}
          intl={intl}
          disabled
          submitSucceeded={submitSucceeded}
          adorned
          scale={7}
        />
      </Grid>
      <Grid
        item
        className={classes.gridFields}
        xs={12}
        sm={6}
        md={6}
        lg={6}
      >
        <QCXFinalAlternativeCurrencyField
          id="currency-imposto-calculado-coeficiente-reducao-ii"
          key="currency-imposto-calculado-coeficiente-reducao-ii"
          name="ii.valorDevidoReal"
          label={t('com.muralis.qcx.imposto.impostoDevidoCalculado')}
          intl={intl}
          disabled
          submitSucceeded={submitSucceeded}
          adorned
          scale={7}
        />
      </Grid>
      <Grid
        item
        className={classes.gridFields}
        xs={12}
        sm={6}
        md={6}
        lg={6}
      >
        <QCXFinalAlternativeCurrencyField
          id="currency-imposto-recolher-coeficiente-reducao-ii"
          key="currency-imposto-recolher-coeficiente-reducao-ii"
          name="ii.valorRecolherReal"
          label={t('com.muralis.qcx.imposto.impostoRecolher')}
          intl={intl}
          disabled
          submitSucceeded={submitSucceeded}
          adorned
          scale={7}
        />
      </Grid>
    </>
  );
}
