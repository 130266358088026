import { createFilterOptions } from '@material-ui/lab/useAutocomplete';
import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import QCXAutocompleteSelect from '../autocomplete-select/QCXAutocompleteSelect';
import i18n from '../../i18n';

const filterOptions = createFilterOptions({
  stringify: (item) => item,
});

const OPTIONS = [
  i18n.t('com.muralis.qcx.expressao.sim').toUpperCase(),
  i18n.t('com.muralis.qcx.expressao.nao').toUpperCase()
];

/**
 * Dropdown que só tem as opções SIM e NÃO.
 * Originalmente este componente usava booleans como valores, mas algo no componente
 * de autocomplete não funcionava com uma opção com valor false.
 * Todo campo que usar este componente precisará ser desnormalizado para usar as
 * strings 'SIM e 'NÃO' ao invés de booleans no formulário.
 */
export default function QCXSelectBoolean({
  label,
  name,
  initialValues,
  ...restProps
}) {
  const [defaultValue, setDefaultValue] = useState('');
  useEffect(() => {
    const yesOrNo = OPTIONS.find((item) => item === _.get(initialValues, name));
    if (yesOrNo) {
      setDefaultValue(yesOrNo);
    }
  }, [initialValues]);

  return (
    <QCXAutocompleteSelect
      label={label}
      id={name}
      name={name}
      options={OPTIONS}
      filterOptions={filterOptions}
      defaultValue={defaultValue}
      getOptionLabel={(item) => item}
      getOptionValue={(item) => item}
      {...restProps}
    />
  );
}
