import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IDLE_CONTROL } from '../config-control';
import {
  CONSULT_MODE,
  CREATE_MODE,
  NONE_BACKGROUND_MODE,
  NONE_MODE,
  NONE_SUB_MODE,
  UPDATE_MODE,
} from '../mode';
import {
  ALTERNATIVE_LOADING_STATUS,
  FAILURE_STATUS,
  IDLE_STATUS,
  LOADING_STATUS,
  SUCCESS_STATUS,
} from '../status';
import {
  fetchAll,
} from './ncmExportacaoAPI';
import i18n from '../../i18n';

const initialState = {
  status: IDLE_STATUS,
  mode: {
    main: NONE_MODE,
    background: NONE_BACKGROUND_MODE,
    sub: NONE_SUB_MODE,
  },
  error: null,
  response: {
    status: null,
    message: null,
    data: null,
  },
  list: [],
  config: { control: IDLE_CONTROL },
  model: {
    id: '',
    code: '',
    description: '',
    active: null,
    atributo: '',
  },
  related: {
    model: {
      atributo: {},
    },
  },
};

const fetchAllAsync = createAsyncThunk(
  'ncmExportacao/fetchAll',
  async () => {
    const { data } = await fetchAll();
    return { data };
  }
);

const ncmExportacaoSlice = createSlice({
  name: 'ncmExportacao',
  initialState,
  reducers: {
    changeStatusTo: (state, action) => {
      state.status = action.payload.status;
    },
    loading: (state) => {
      state.status = LOADING_STATUS;
    },
    success: (state) => {
      state.status = SUCCESS_STATUS;
    },
    failure: (state) => {
      state.status = FAILURE_STATUS;
    },
    resetStatus: (state) => {
      state.status = IDLE_STATUS;
    },
    changeToCreateMode: (state) => {
      state.mode.main = CREATE_MODE;
    },
    changeToConsultMode: (state) => {
      state.mode.main = CONSULT_MODE;
    },
    changeToUpdateMode: (state) => {
      state.mode.main = UPDATE_MODE;
    },
    resetMode: (state) => {
      state.mode.main = NONE_MODE;
    },
    setModel: (state, action) => {
      state.model = action.payload;
    },
    resetModel: (state) => {
      state.model = initialState.model;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllAsync.pending, (state) => {
        state.status = ALTERNATIVE_LOADING_STATUS;
      })
      .addCase(fetchAllAsync.fulfilled, (state, action) => {
        state.status = IDLE_STATUS;
        state.list = action.payload.data;
      })
      .addCase(fetchAllAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.carregarListaNcm', { erro: action.error.message }),
        };
      });
  },
});

const {
  changeStatusTo,
  loading,
  success,
  failure,
  resetStatus,
  addToList,
  updateOnList,
  setResponse,
  setResponseMessage,
  clearResponse,
  setError,
  resetModel,
  changeControlTo,
  setConfig,
  resetList,
  changeToCreateMode,
  changeToConsultMode,
  changeToUpdateMode,
  resetMode,
  changeToBackgroundCreateMode,
  resetBackgroundMode,
  changeToSubCreateMode,
  changeToSubConsultMode,
  changeToSubUpdateMode,
  resetSubMode,
  removeAtributoById,
  resetRelatedAtributoModel,
  setRelatedAtributoModel,
} = ncmExportacaoSlice.actions;

const selectListNcm = (state) => state.ncmExportacao.list;
const selectStatus = (state) => state.ncmExportacao.status;
const selectMode = (state) => state.ncmExportacao.mode.main;
const selectModel = (state) => state.ncmExportacao.model;
const selectError = (state) => state.ncmExportacao.error;
const selectResponse = (state) => state.ncmExportacao.response;
const selectNcm = (state) => state.ncmExportacao.model;
const selectConfigControl = (state) => state.ncmExportacao.config.control;

const ncmExportacaoSelectors = {
  selectStatus,
  selectMode,
  selectModel,
};
const ncmExportacaoActions = {
  ...ncmExportacaoSlice.actions,
  fetchAllAsync,
  selectListNcm,
  selectMode,
  selectStatus,
  selectError,
  selectResponse,
  selectNcm,
  selectConfigControl,
};

export {
  ncmExportacaoSlice,
  changeStatusTo,
  loading,
  success,
  failure,
  resetStatus,
  addToList,
  updateOnList,
  setResponse,
  setResponseMessage,
  clearResponse,
  setError,
  selectMode,
  resetModel,
  fetchAllAsync,
  changeControlTo,
  setConfig,
  ncmExportacaoActions,
  resetList,
  selectListNcm,
  selectNcm,
  selectStatus,
  selectError,
  selectResponse,
  selectConfigControl,
  changeToCreateMode,
  changeToConsultMode,
  changeToUpdateMode,
  resetMode,
  changeToBackgroundCreateMode,
  resetBackgroundMode,
  changeToSubCreateMode,
  changeToSubConsultMode,
  changeToSubUpdateMode,
  resetSubMode,
  removeAtributoById,
  resetRelatedAtributoModel,
  ncmExportacaoSelectors,
  setRelatedAtributoModel,
};

export default ncmExportacaoSlice.reducer;
