import { Grid } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { cidadeActions } from '../../features/cidade/cidadeSlice';
import { AUTO_LOAD_CONTROL } from '../../features/config-control';
import { selectViasTransporte } from '../../features/via-transporte/viaTransporteSlice';
import { estadoActions } from '../../features/estado/estadoSlice';
import { paisActions } from '../../features/pais/paisSlice';
import QCXFinalForm from '../../shared-components/final-form/QCXFinalForm';
import QCXFinalOnblurMaskedField from '../../shared-components/final-onblur-masked-field/QCXFinalOnblurMaskedField';
import QCXFinalTextField from '../../shared-components/final-text-field/QCXFinalTextField';
import QCXSelectCidadeAutocomplete from '../../shared-components/select-cidade/QCXSelectCidadeAutocomplete';
import QCXSelectEstadoAutocomplete from '../../shared-components/select-estado/QCXSelectEstadoAutocomplete';
import QCXSelectPaisAutocomplete from '../../shared-components/select-pais/QCXSelectPaisAutocomplete';
import QCXSelectViaTransporteAutocomplete from '../../shared-components/select-via-transporte/QCXSelectViaTransporteAutocomplete';
import { formatCnpj } from '../../utils/hooks/form/field/formatters';
import { parseCnpj } from '../../utils/hooks/form/field/parsers';
import { required as requiredValidator, validCnpj } from '../../utils/validators/field/validator';
import QCXFinalCheckboxField from '../../shared-components/final-checkbox-field/QCXFinalCheckboxField';

export default function QCXTransportadorInternacionalForm({
  handleSubmit,
  handleChangeToConsult,
  handleChangeToUpdate,
  handleCancelUpdate,
  isConsult,
  initialValues,
  isUpdate,
  ...restProps
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const viasTransporte = useSelector(selectViasTransporte);

  const [showCodigo, setShowCodigo] = useState(false);

  useEffect(() => {
    dispatch(
      paisActions.changeControlTo(AUTO_LOAD_CONTROL)
    );
  }, []);

  const handlePaisChange = (form) => (value) => {
    form.change('endereco.cidade.id', undefined);
    form.change('endereco.cidade.estado.id', undefined);
    form.change('endereco.cidade.estado.pais.id', value);

    dispatch(estadoActions.fetchByFilterAsync([{ name: 'pais', value }]));
  };

  const handleEstadoChange = (form) => (value) => {
    form.change('endereco.cidade.id', undefined);
    form.change('endereco.cidade.estado.id', value);

    dispatch(cidadeActions.fetchByFilterAsync([{ name: 'estado', value }]));
  };

  const handleCidadeChange = (form) => (value) => {
    form.change('endereco.cidade.id', value);
  };

  const viaDeTransporteOnchange = () => (value) => {
    const selectedViaTransporte = viasTransporte.find((item) => item.id === value);
    setShowCodigo(selectedViaTransporte && selectedViaTransporte?.description?.toUpperCase() === 'AEREA');
  };

  const validOrEmptyCnpj = (value) => {
    if (_.isUndefined(value) || _.isNull(value) || _.isEmpty(value)) {
      return undefined;
    }
    return validCnpj(value);
  };

  const listeners = [
    { name: 'endereco.cidade.estado.pais.id', fn: handlePaisChange },
    { name: 'endereco.cidade.estado.id', fn: handleEstadoChange },
    { name: 'endereco.cidade.id', fn: handleCidadeChange },
    { name: 'viaTransporte.id', fn: viaDeTransporteOnchange }
  ];

  const isPaisFilled = useCallback((values) => (
    !!values?.endereco?.cidade?.estado?.pais?.id
  ), []);

  return (
    <>
      <QCXFinalForm
        isConsult={isConsult}
        isUpdate={isUpdate}
        initialValues={initialValues}
        handleSubmitValues={handleSubmit}
        handleChangeToUpdate={handleChangeToUpdate}
        handleCancelUpdate={handleCancelUpdate}
        onChangeListeners={listeners}
        {...restProps}
      >
        {({ values }) => (
          <>
            <Grid item xs={12} sm={4} md={3}>
              <QCXSelectViaTransporteAutocomplete
                id="select-field-via-transporte"
                key="select-field-via-transporte"
                name="viaTransporte.id"
                label={t('com.muralis.qcx.transporte.viaTransporte')}
                required
                validate={requiredValidator}
                disabled={isConsult}
                initialValues={values}
              />
            </Grid>
            {showCodigo
              && (
              <Grid item xs={12} sm={8} md={3}>
                <QCXFinalTextField
                  id="text-field-codigo"
                  key="text-field-codigo"
                  name="code"
                  label={t('com.muralis.qcx.codigo')}
                  disabled={isConsult}
                  validate={requiredValidator}
                />
              </Grid>
              )}
            <Grid item xs={12} sm={showCodigo ? 6 : 8} md={showCodigo ? 6 : 9}>
              <QCXFinalTextField
                id="text-field-razao-social"
                key="text-field-razao-social"
                name="pessoaJuridica.nome"
                label={t('com.muralis.qcx.empresa.razaoSocial')}
                required
                disabled={isConsult}
                validate={requiredValidator}
              />
            </Grid>
            <Grid item xs={12} sm={showCodigo ? 6 : 12} md={8}>
              <QCXFinalTextField
                id="text-field-apelido"
                key="text-field-apelido"
                name="description"
                label={t('com.muralis.qcx.apelido')}
                disabled={isConsult}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <QCXFinalOnblurMaskedField
                id="masked-text-field-pessoa-cnpj"
                key="masked-text-field-pessoa-cnpj"
                name="pessoaJuridica.cnpj"
                label={t('com.muralis.qcx.empresa.CNPJ')}
                maxLength={18}
                format={formatCnpj}
                parse={parseCnpj}
                validate={validOrEmptyCnpj}
                disabled={isConsult}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <QCXSelectPaisAutocomplete
                id="select-endereco-pais"
                key="select-endereco-pais"
                name="endereco.cidade.estado.pais.id"
                label={t('com.muralis.qcx.endereco.pais')}
                initialValues={values}
                disabled={isConsult}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <QCXSelectEstadoAutocomplete
                id="select-endereco-estado"
                key="select-endereco-estado"
                name="endereco.cidade.estado.id"
                label={t('com.muralis.qcx.endereco.estado')}
                initialValues={values}
                disabled={isConsult}
                required={isPaisFilled(values)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <QCXSelectCidadeAutocomplete
                id="autocomplete-field-cidade"
                key="autocomplete-field-cidade"
                name="endereco.cidade.id"
                label={t('com.muralis.qcx.endereco.cidade')}
                initialValues={values}
                disabled={isConsult}
                required={isPaisFilled(values)}
              />
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <QCXFinalTextField
                id="text-field-endereco-logradouro"
                key="text-field-endereco-logradouro"
                label={t('com.muralis.qcx.endereco.logradouro')}
                name="endereco.logradouro"
                maxLength={150}
                disabled={isConsult}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <QCXFinalTextField
                id="text-field-endereco-numero"
                key="text-field-endereco-numero"
                label={t('com.muralis.qcx.endereco.numero')}
                name="endereco.numero"
                maxLength={10}
                disabled={isConsult}
              />
            </Grid>
            <Grid item xs={12}>
              <QCXFinalCheckboxField
                id="checkbox-agente-transportador"
                key="checkbox-agente-transportador"
                name="agenteTransportador"
                label={t('com.muralis.qcx.transportador.agenteTransportador')}
                disabled={isConsult}
              />
            </Grid>
          </>
        )}
      </QCXFinalForm>
    </>
  );
}
