import { IconButton } from '@material-ui/core';
import {
  DirectionsBoat,
  Search as PageviewIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import EmbarcacaoContext from '../../../../../contexts/registrations/embarcacao/EmbarcacaoContext';
import {
  changeControlTo as changeControlPaisTo,
  resetList as resetPaisList,
} from '../../../../../features/pais/paisSlice';
import {
  activateByIdAsync,
  fetchAllAsync,
  inactivateByIdAsync,
  resetBackgroundMode,
  resetMode, resetModel,
  selectEmbarcacoes,
} from '../../../../../features/embarcacoes/embarcacoesSlice';
import {
  ALTERNATIVE_LOADING_STATUS,
} from '../../../../../features/status';
import QCXSimpleConsultPageTemplate from '../../../../../templates/simple-consult-page/QCXSimpleConsultPageTemplate';
import { IDLE_CONTROL } from '../../../../../features/config-control';
import { userHasDeleteRole } from '../../../../../components/perfil/perfil-utils';

export default function EmbarcacaoConsultPage({ authInfo = {} }) {
  const { t } = useTranslation();
  const history = useHistory();

  const {
    status,
  } = useContext(EmbarcacaoContext);

  const dispatch = useDispatch();

  const embarcacoes = useSelector(selectEmbarcacoes);

  const isAlternativeLoading = useMemo(() => status === ALTERNATIVE_LOADING_STATUS, [status]);

  const fetchAll = async () => {
    dispatch(fetchAllAsync());
  };

  const resetModes = useCallback(() => {
    dispatch(resetMode());
    dispatch(resetBackgroundMode());
  }, []);

  const resetModels = useCallback(() => {
    dispatch(resetModel());
  }, []);

  const resetDependencies = useCallback(() => {
    dispatch(changeControlPaisTo(IDLE_CONTROL));
    dispatch(resetPaisList());
  }, []);

  useEffect(() => {
    fetchAll();
    resetModes();
    resetModels();
    resetDependencies();
  }, []);

  const handleActivateInactivate = async (event, id) => {
    event.preventDefault();

    const { active } = embarcacoes.find(
      (item) => item.id === id
    );

    const isActivation = !active;

    if (isActivation) {
      dispatch(activateByIdAsync(id));
    } else {
      dispatch(inactivateByIdAsync(id));
    }
  };

  const handleClickOnEditButton = useCallback(
    (_event, id) => (
      history.push(
        t('com.muralis.qcx.url.moduloOperacionaisEmbarcacaoDetalhes', { id })
      )
    ), [history]
  );

  const breadcrumbs = [
    {
      link: {
        to: '/',
        name: t('com.muralis.qcx.inicio'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.cadastros'),
      },
    },
    {
      link: {
        to: t('com.muralis.qcx.url.moduloOperacionais'),
        name: t('com.muralis.qcx.operacionais'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.embarcacao.labelPlural'),
      },
    },
  ];

  const columns = [
    {
      field: 'code',
      headerName: t('com.muralis.qcx.codigo'),
      headerAlign: 'center',
      align: 'center',
      type: 'string',
      flex: 60,
      valueGetter: ({ row }) => (
        row?.id
      ),
    },
    {
      field: 'description',
      headerName: t('com.muralis.qcx.nome'),
      type: 'string',
      flex: 145,
      valueGetter: ({ row }) => (
        row?.description
      ),
    },
    {
      field: 'pais',
      headerName: t('com.muralis.qcx.endereco.pais'),
      type: 'string',
      flex: 165,
      valueGetter: ({ row }) => `${row?.pais?.code} - ${row?.pais?.description}`,
    },
    {
      field: 'id',
      headerName: t('com.muralis.qcx.acoes.label'),
      flex: 100,
      align: 'center',
      renderCell: ({ row: { id, active } }) => (
        <>
          {userHasDeleteRole(authInfo, 'embarcacao') && (
          <IconButton
            key={`stw-active${id}`}
            value={active}
            name={`stw-active-${id}`}
            onClick={(event) => handleActivateInactivate(event, id)}
          >
            <DeleteIcon color="error" />
          </IconButton>
          )}
          <IconButton
            key={`btn-view-${id}`}
            name={`btn-view-${id}`}
            onClick={(event) => handleClickOnEditButton(event, id)}
          >
            <PageviewIcon color="primary" />
          </IconButton>
        </>
      ),
    }
  ];

  const templateProps = {
    page: {
      title: t('com.muralis.qcx.embarcacao.labelPlural'),
      icon: <DirectionsBoat />,
      breadcrumbs,
    },
    control: {
      buttonNew: {
        description: t('com.muralis.qcx.embarcacao.novaEmbarcacao'),
        path: t('com.muralis.qcx.url.moduloOperacionaisEmbarcacaoNova'),
      },
    },
    table: {
      columns,
    },
  };

  const filterPropGetter = useCallback(
    (embarcacao) => embarcacao.nome,
    []
  );

  return (
    <QCXSimpleConsultPageTemplate
      pageProps={templateProps.page}
      controlProps={templateProps.control}
      tableProps={templateProps.table}
      tableData={embarcacoes}
      isLoading={isAlternativeLoading}
      filterPropGetter={filterPropGetter}
      buttonNewText={t('com.muralis.qcx.acoes.nova').toUpperCase()}
      authInfo={authInfo}
      requiredRoles={['embarcacao']}
    />
  );
}
