import { useCallback } from "react";
import {
  normalizeOptanteSimplesNacional,
  normalizeReter,
} from "../../../../utils/general/cliente/clienteUtils";
import {
  normalizeData,
  normalizeDigits,
  normalizeNumeral,
} from "../../../../utils/general/general-utils";
import TipoCalculoSeguroUtils from "../../../../utils/general/tipo-calculo-seguro/TipoCalculoSeguroUtils";
import { numberOnly } from "../../../../utils/hooks/form/field/parsers";
import { validate } from "uuid";

export const useNormalizeClienteData = (): [(clientRawdata: any) => any] => {
  const isPessoaFisica = (tipoPessoa: string) =>
    tipoPessoa === "PESSOA_FISICA" ||
    tipoPessoa === "FISICAS_DOMICILIADAS_NO_EXTERIOR";

  const normalize = useCallback(
    (unnormalizedData: any) => {
      const normalizedPessoaData = {
        ...unnormalizedData?.pessoa,
        tipo: isPessoaFisica(unnormalizedData?.tipoPessoa)
          ? "PessoaFisica"
          : "PessoaJuridica",
        cnae: unnormalizedData?.pessoa?.cnae?.id
          ? unnormalizedData?.pessoa?.cnae
          : null,
        cpf: unnormalizedData?.pessoa?.cpf
          ? numberOnly(unnormalizedData?.pessoa?.cpf)
          : null,
        cnpj: unnormalizedData?.pessoa?.cnpj
          ? numberOnly(unnormalizedData?.pessoa?.cnpj)
          : null,
      };

      const vencimentoRadar = normalizeData(unnormalizedData?.vencimentoRadar);
      const vencimentoProcuracao = normalizeData(
        unnormalizedData?.vencimentoProcuracao
      );
      const normalizedOptanteSimplesNacional = normalizeOptanteSimplesNacional(
        unnormalizedData?.optanteSimplesNacional
      );

      const normalizedData = {
        ...unnormalizedData,
        endereco: {
          ...unnormalizedData?.endereco,
          cep: unnormalizedData?.endereco?.cep
            ? normalizeDigits(unnormalizedData?.endereco?.cep)
            : null,
        },
        pessoa: normalizedPessoaData,
        contatos: (unnormalizedData?.contatos || []).map((item: any) => ({
          ...item,
          id: validate(item?.id) ? null : item?.id,
          contato: {
            ...item?.contato,
            telefone: item?.contato?.telefone
              ? normalizeDigits(item?.contato?.telefone)
              : undefined,
            celular: item?.contato?.celular
              ? normalizeDigits(item?.contato?.celular)
              : undefined,
          },
        })),
        contaBancariaImpostosEstaduais: unnormalizedData
          ?.contaBancariaImpostosEstaduais?.id
          ? unnormalizedData?.contaBancariaImpostosEstaduais
          : undefined,
        contaBancariaImpostosFederais: unnormalizedData
          ?.contaBancariaImpostosFederais?.id
          ? unnormalizedData?.contaBancariaImpostosFederais
          : undefined,
        contaBancariaPagamentoDespesas: unnormalizedData
          ?.contaBancariaPagamentoDespesas?.id
          ? unnormalizedData?.contaBancariaPagamentoDespesas
          : undefined,
        vencimentoRadar,
        vencimentoProcuracao,
        description: unnormalizedData?.pessoa.nome,
        despachantes: unnormalizedData?.despachantes?.length
          ? unnormalizedData?.despachantes
          : null,
        unidadesDeNegocio: unnormalizedData?.unidadesDeNegocio?.length
          ? unnormalizedData?.unidadesDeNegocio
          : null,
        servicos: unnormalizedData?.servicos?.length
          ? unnormalizedData?.servicos
          : null,
        planoDeConta: unnormalizedData?.planoDeConta?.id
          ? unnormalizedData?.planoDeConta
          : null,
        moeda: unnormalizedData?.moeda?.id ? unnormalizedData?.moeda : null,
        tipoCalculoSeguro: TipoCalculoSeguroUtils.parseFromSingletonList(
          unnormalizedData?.tipoCalculoSeguro
        ),
        valorPercentualSeguro: normalizeNumeral(
          unnormalizedData?.valorPercentualSeguro
        ),
        tipoEmpresa: { ...unnormalizedData?.tipoEmpresa },
        limiteCredito: normalizeNumeral(unnormalizedData?.limiteCredito),
        optanteSimplesNacional: normalizedOptanteSimplesNacional,
        certificadoDigitalDanfeSenhaConfirma: undefined, // Campo de confirmação somente, não enviamos
        certificadoDigitalDanfeSenha:
          unnormalizedData?.certificadoDigitalDanfeSenha || undefined,
        valorMinimo: normalizeNumeral(unnormalizedData?.valorMinimo),
        tipoLogradouro: unnormalizedData?.endereco?.tipoLogradouro,
        aliquotaPis: normalizeNumeral(unnormalizedData?.aliquotaPis),
        aliquotaCofins: normalizeNumeral(unnormalizedData?.aliquotaPis),
        aliquotaCsll: normalizeNumeral(unnormalizedData?.aliquotaPis),
        indicadorInscricaoEstadual:
          unnormalizedData?.indicadorInscricaoEstadual,
        diasParaVencimento: normalizeNumeral(
          unnormalizedData?.diasParaVencimento
        ),
        diaLimiteParaFaturamento: normalizeNumeral(
          unnormalizedData?.diaLimiteParaFaturamento
        ),
        tipoRetencao: unnormalizedData?.tipoRetencao,
        reter: normalizeReter(unnormalizedData?.reter),
      };

      return normalizedData;
    },
    [normalizeNumeral]
  );

  return [normalize];
};
