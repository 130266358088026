import React from 'react';
import { DialogContent } from '@material-ui/core';

export default function QCXDialogContent({
  key,
  id,
  children,
  ...restProps
}) {
  return (
    <DialogContent
      key={key}
      id={id}
      {...restProps}
    >
      {children}
    </DialogContent>
  );
}
