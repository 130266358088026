import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import QCXFinalCheckboxField from '../../shared-components/final-checkbox-field/QCXFinalCheckboxField';
import QCXFinalNumericIntegerField from '../../shared-components/final-numeric-integer-field/QCXFinalNumericIntegerField';
import QCXFinalTextField from '../../shared-components/final-text-field/QCXFinalTextField';
import QCXFormStepWizard from '../../shared-components/form-step-wizard/QCXFormStepWizard';
import QCXSelectBoolean from '../../shared-components/select-boolean/QCXSelectBoolean';
import QCXSelectFormatoImpressaoDanfeAutocomplete from '../../shared-components/select-formato-impressao-danfe/QCXSelectFormatoImpressaoDanfeAutocomplete';
import QCXFormTitle from '../form-title/QCXFormTitle';
import CSTBondManager from './CSTBondManager';

const GridContainer = ({ children }) => (
  <Grid container spacing={2} style={{ marginTop: '1rem' }}>
    {children}
  </Grid>
);

export default function QCXModeloDanfeBasicasForm({
  isConsult,
  isUpdate,
  initialValues,
  handleSubmit,
  handleChangeToUpdate,
  handleCancelUpdate,
  controlComponentProps,
}) {
  const { t } = useTranslation();
  const listenerList = [];

  return (
    <QCXFormStepWizard
      isConsult={isConsult}
      isUpdate={isUpdate}
      initialValues={initialValues}
      handleSubmitValues={handleSubmit}
      handleChangeToUpdate={handleChangeToUpdate}
      handleCancelUpdate={handleCancelUpdate}
      onChangeListeners={listenerList}
      onSubmit={handleSubmit}
      controlComponentProps={controlComponentProps}
    >
      {({ values }) => (
        <>
          <QCXFormTitle title={t('com.muralis.qcx.impressao')} />
          <GridContainer>
            <Grid item md={6}>
              <QCXFinalTextField
                id="nome-modelo"
                key="nome-modelo"
                name="nome"
                label="Nome do Modelo"
                initialValues={values}
                disabled={isConsult}
                required
              />
            </Grid>
            <Grid item md={6}>
              <QCXSelectFormatoImpressaoDanfeAutocomplete
                id="select-formato-impressao"
                key="select-formato-impressao"
                name="formatoImpressao"
                label={t('com.muralis.qcx.formatoImpressao.label')}
                initialValues={values}
                disabled={isConsult}
                required
              />
            </Grid>
          </GridContainer>
          <QCXFormTitle title={t('com.muralis.qcx.agrupamentos')} />
          <GridContainer>
            <Grid item md={6}>
              <QCXSelectBoolean
                id="select-agrupar-exportador"
                key="select-agrupar-exportador"
                name="agruparExportadorFabricante"
                label={t('com.muralis.qcx.agruparExportadorFabricante')}
                initialValues={values}
                disabled={isConsult}
                required
              />
            </Grid>
            <Grid item md={6}>
              <QCXSelectBoolean
                id="select-agrupar-cfop"
                key="select-agrupar-cfop"
                name="agruparCfop"
                label={t('com.muralis.qcx.agruparCFOP')}
                initialValues={values}
                disabled={isConsult}
                required
              />
            </Grid>
            <Grid item md={6}>
              <QCXSelectBoolean
                id="select-agrupar-aliquota-icms"
                key="select-agrupar-aliquota-icms"
                name="agruparAliquotaIcms"
                label={t('com.muralis.qcx.agruparAlioquotaICMS')}
                initialValues={values}
                disabled={isConsult}
                required
              />
            </Grid>
          </GridContainer>
          <QCXFormTitle title={t('com.muralis.qcx.valores')} />
          <GridContainer>
            <Grid item md={6}>
              <QCXSelectBoolean
                id="select-exibir-cif"
                key="select-exibir-cif"
                name="exibirCif"
                label={t('com.muralis.qcx.exibirCIFIICampoUnitario')}
                initialValues={values}
                disabled={isConsult}
                required
              />
            </Grid>
            <Grid item md={6}>
              <QCXFinalNumericIntegerField
                id="quantidade-casas"
                key="quantidade-casas"
                name="quantidadeCasasDecimais"
                label={t('com.muralis.qcx.quantidadeCasasDecimaisValorUnitario')}
                initialValues={values}
                disabled={isConsult}
                maxLength={1}
                required
              />
            </Grid>
          </GridContainer>
          <QCXFormTitle title={t('com.muralis.qcx.valorDespesasAcessorias')} />
          <GridContainer>
            <Grid item md={2}>
              <QCXFinalCheckboxField
                id="despesa-pis"
                key="despesa-pis"
                name="despesasAcessoriasPis"
                label={t('com.muralis.qcx.PISPlus')}
                initialValues={values}
                disabled={isConsult}
              />
            </Grid>
            <Grid item md={2}>
              <QCXFinalCheckboxField
                id="despesa-cofins"
                key="despesa-cofins"
                name="despesasAcessoriasCofins"
                label={t('com.muralis.qcx.COFINSPlus')}
                initialValues={values}
                disabled={isConsult}
              />
            </Grid>
            <Grid item md={2}>
              <QCXFinalCheckboxField
                id="despesa-siscomex"
                key="despesa-siscomex"
                name="despesasAcessoriasTaxaSiscomex"
                label={t('com.muralis.qcx.taxaSISCOMEXPLus')}
                initialValues={values}
                disabled={isConsult}
              />
            </Grid>
            <Grid item md={2}>
              <QCXFinalCheckboxField
                id="despesa-afrmm"
                key="despesa-afrmm"
                name="despesasAcessoriasAfrmm"
                label={t('com.muralis.qcx.AFRMMPlus')}
                initialValues={values}
                disabled={isConsult}
              />
            </Grid>
            <Grid item md={4}>
              <QCXFinalCheckboxField
                id="despesa-outras"
                key="despesa-outras"
                name="despesasAcessoriasOutrasDespesasAduaneiras"
                label={t('com.muralis.qcx.outrasDespesasAduaneiras')}
                initialValues={values}
                disabled={isConsult}
              />
            </Grid>
          </GridContainer>
          <QCXFormTitle title="Não Exibir Campos:" />
          <GridContainer>
            <Grid item md={3}>
              <QCXFinalCheckboxField
                id="oculta-base-imposto"
                key="oculta-base-imposto"
                name="baseImpostoEhOculto"
                label="Base/Impostos com valor zero"
                initialValues={values}
                disabled={isConsult}
              />
            </Grid>
            <Grid item md={3}>
              <QCXFinalCheckboxField
                id="oculta-frete-internacional"
                key="oculta-frete-internacional"
                name="freteInternacionalEhOculto"
                label="Frete Internacional"
                initialValues={values}
                disabled={isConsult}
              />
            </Grid>
            <Grid item md={2}>
              <QCXFinalCheckboxField
                id="oculta-frete"
                key="oculta-frete"
                name="freteEhOculto"
                label="Frete"
                initialValues={values}
                disabled={isConsult}
              />
            </Grid>
            <Grid item md={2}>
              <QCXFinalCheckboxField
                id="oculta-seguro"
                key="oculta-seguro"
                name="seguroEhOculto"
                label="Seguro"
                initialValues={values}
                disabled={isConsult}
              />
            </Grid>
            <Grid item md={2}>
              <QCXFinalCheckboxField
                id="oculta-ipi"
                key="oculta-ipi"
                name="ipiEhOculto"
                label="IPI"
                initialValues={values}
                disabled={isConsult}
              />
            </Grid>
          </GridContainer>
          <QCXFormTitle title="Total da Nota Igual:" />
          <GridContainer>
            <Grid item md={3}>
              <QCXFinalCheckboxField
                id="valor-total-produtos"
                key="valor-total-produtos"
                name="totalIncluiValorProdutos"
                label="Valor Total dos Produtos"
                initialValues={values}
                disabled={isConsult}
              />
            </Grid>
            <Grid item md={3}>
              <QCXFinalCheckboxField
                id="inclui-icms"
                key="inclui-icms"
                name="totalIncluiICMS"
                label="+ ICMS"
                initialValues={values}
                disabled={isConsult}
              />
            </Grid>
            <Grid item md={3}>
              <QCXFinalCheckboxField
                id="inclui-despesas-assessorias"
                key="inclui-despesas-assessorias"
                name="totalIncluiDespesasAssessorias"
                label="+ Despesas Acessórias"
                initialValues={values}
                disabled={isConsult}
              />
            </Grid>
            <Grid item md={3}>
              <QCXFinalCheckboxField
                id="inclui-ipi"
                key="inclui-ipi"
                name="totalIncluiIPI"
                label="+ IPI"
                initialValues={values}
                disabled={isConsult}
              />
            </Grid>
          </GridContainer>
          <QCXFormTitle title="Preenchimento CST:" />
          <GridContainer>
            <CSTBondManager isParentConsult={isConsult} />
          </GridContainer>
        </>
      )}
    </QCXFormStepWizard>
  );
}
