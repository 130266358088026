import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { ncmSubitemAPI } from '../../../features/ncm-subitem/ncmSubitemAPI';

export default function useAtributosListenersGroup({
  modes,
  actions,
  formProps = {},
  rootName = '',
}) {
  const dispatch = useDispatch();

  const {
    isConsult,
    isSubConsult,
    isSubCreate,
    isSubUpdate,
  } = modes;

  const {
    nveActions,
    nveAtributoActions,
    nveEspecificacaoActions,
  } = actions;

  const {
    ncm,
    ncmList,
    especificacao,
  } = formProps;

  const fields = useMemo(() => {
    const obj = {
      nve: 'nve.id',
      atributo: 'atributo.id',
      especificacao: 'especificacao.id',
    };

    const keys = Object.keys(obj);

    const result = keys.reduce((previous, currentKey) => (
      {
        ...previous,
        [currentKey]: {
          name: rootName
            ? `${rootName}.${obj[currentKey]}`
            : obj[currentKey],
        },
      }
    ), {});

    return result;
  }, [
    rootName
  ]);

  const nivelOnChange = useCallback((form) => (value) => {
    form.change(fields?.nve?.name, value);
    form.change(fields?.atributo?.name, undefined);
    form.change(fields?.especificacao?.name, undefined);

    form.resetFieldState(fields?.nve?.name);
    form.resetFieldState(fields?.atributo?.name);
    form.resetFieldState(fields?.especificacao?.name);

    dispatch(nveAtributoActions.fetchByFilterAsync([{ name: 'nve', value }]));
  }, [
    fields,
    nveAtributoActions,
  ]);

  const atributoOnChange = useCallback((form) => (value) => {
    form.change(fields?.atributo?.name, value);
    form.change(fields?.especificacao?.name, undefined);

    form.resetFieldState(fields?.atributo?.name);
    form.resetFieldState(fields?.especificacao?.name);

    dispatch(nveEspecificacaoActions.fetchByFilterAsync([{ name: 'atributo', value }]));
  }, [
    fields,
    nveEspecificacaoActions,
  ]);

  const especificacaoOnChange = useCallback((form) => async (value) => {
    if (isConsult || isSubConsult || isSubUpdate || isSubCreate) {
      form.change(fields?.especificacao?.name, value);

      form.resetFieldState(fields?.especificacao?.name);
      if (especificacao && !(isSubUpdate || isSubCreate)) {
        const ncmSubitemResponse = await ncmSubitemAPI.fetchById(ncm?.id);
        const selectedNcm = ncmSubitemResponse?.data;

        dispatch(nveActions.fetchByFilterAsync([{ name: 'code', value: selectedNcm?.code }]));
        dispatch(nveAtributoActions.fetchByFilterAsync([{ name: 'nve', value: especificacao?.nve?.id }]));
        dispatch(nveEspecificacaoActions.fetchByFilterAsync([{ name: 'atributo', value: especificacao?.atributo?.id }]));
      }
    }
  }, [
    ncm,
    fields,
    ncmList,
    isConsult,
    nveActions,
    isSubCreate,
    isSubUpdate,
    isSubConsult,
    especificacao,
    nveAtributoActions,
    nveEspecificacaoActions,
  ]);

  const changeListeners = useMemo(() => ([
    {
      name: fields?.nve?.name,
      fn: nivelOnChange,
    },
    {
      name: fields?.atributo?.name,
      fn: atributoOnChange,
    },
    {
      name: fields?.especificacao?.name,
      fn: especificacaoOnChange,
    },
  ]), [
    fields,
    nivelOnChange,
    atributoOnChange,
    especificacaoOnChange,
  ]);

  return changeListeners;
}
