import React from 'react';
import {
  Breadcrumbs, Chip, emphasize, makeStyles, Typography, withStyles,
} from '@material-ui/core';
import {
  NavigateNext as NavigateNextIcon,
} from '@material-ui/icons';
import { Link } from 'react-router-dom';

const StyledBreadcrumb = withStyles((theme) => ({
  root: {
    backgroundColor: 'inherit',
    height: theme.spacing(3),
    fontWeight: theme.typography.fontWeightNormal,
    borderRadius: '0.3rem',
    opacity: '.75',
    '&:hover, &:focus': {
      backgroundColor: 'transparent',
      opacity: '.5',
      cursor: 'pointer',
    },
    '&:active': {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(theme.palette.grey[300], 0.12),
    },
  },
}))(Chip);

const useStyles = makeStyles((theme) => ({
  navigateIcon: {
    color: theme.palette.primary.light,
  },
}));

export default function QCXBreadcrumbs({ data = [], maxItems = 5, ...restProps }) {
  const classes = useStyles();

  const pathComponents = data.map((path, index) => {
    let component = null;

    if (path && path?.link) {
      const {
        link: {
          to,
          name,
        },
      } = path;

      component = (
        <StyledBreadcrumb
          component={Link}
          key={`breadcrumb-link-path${index + 1}`}
          label={name}
          to={to}
          {...restProps}
        />
      );
    }

    if (path && path?.text) {
      const {
        text: {
          name,
        },
      } = path;

      component = (
        <StyledBreadcrumb
          component={Typography}
          key={`breadcrumb-path${index + 1}`}
          label={name}
          disabled
        />
      );
    }

    return component;
  });

  return (
    <Breadcrumbs
      separator={
        <NavigateNextIcon className={classes.navigateIcon} fontSize="small" />
      }
      aria-label="breadcrumb"
      maxItems={maxItems}
    >
      {pathComponents}
    </Breadcrumbs>
  );
}
