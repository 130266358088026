import { Typography } from '@material-ui/core';
import React from 'react';
import QCXBox from '../../shared-components/box/QCXBox';

export default function QCXFormTitle({
  title,
  boxProps = {
    pt: 5,
  },
  ...restProps
}) {
  return (
    <Typography
      variant="h6"
      color="primary"
      {...restProps}
    >
      <QCXBox
        fontWeight="fontWeightBold"
        {...boxProps}
      >
        {String(title).toUpperCase()}
      </QCXBox>
    </Typography>
  );
}
