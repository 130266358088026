import { makeStyles, Typography } from '@material-ui/core';
import { isNumber } from 'lodash';
import React, { useMemo } from 'react';

const commonStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  paddingRight: '14px',
  fontWeight: 'bold',
};

export default function QCXRemaningCharacters({
  color = 'secondary',
  max,
  current,
  realignPosition = false,
  visible = false,
  ...restProps
}) {
  const classes = makeStyles((theme) => ({
    rootPrimary: {
      ...commonStyle,
      [theme.breakpoints.down('xs')]: {
        marginTop: !realignPosition
          ? '4px'
          : '9px',
      },
      [theme.breakpoints.up('sm')]: {
        marginTop: !realignPosition
          ? '4px'
          : '0px',
      },
    },
    rootSecondary: {
      ...commonStyle,
      [theme.breakpoints.down('xs')]: {
        marginTop: '4px',
      },
      [theme.breakpoints.up('sm')]: {
        marginTop: '-19px',
      },
    },
  }))();

  const remaningCharacters = useMemo(() => {
    const maxNumber = Number(max);
    const currentNumber = Number(current);

    if (maxNumber && maxNumber > 0 && isNumber(currentNumber)) {
      return maxNumber - currentNumber;
    }

    return undefined;
  }, [
    max,
    current,
  ]);

  const textInfo = useMemo(() => (
    max > 0
    && current > 0
    && remaningCharacters !== undefined
      ? `${current} / ${max}` // `RESTAM ${remaningCharacters} / ${max}`
      : '\xa0'
  ), [
    max,
    current,
    remaningCharacters,
  ]);

  const rootClassName = useMemo(() => (
    realignPosition
      ? classes.rootSecondary
      : classes.rootPrimary
  ), [realignPosition, classes]);

  return (
    <>
      {visible && (
        <Typography
          className={rootClassName}
          variant="caption"
          color={color}
          {...restProps}
        >
          {textInfo}
        </Typography>
      )}
    </>
  );
}
