import { Grid } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import QCXFinalForm from '../../../../../../../shared-components/final-form/QCXFinalForm';
import QCXFinalTextField from '../../../../../../../shared-components/final-text-field/QCXFinalTextField';
import {
  required as requiredValidator,
} from '../../../../../../../utils/validators/field/validator';

export default function QCXDesvioFinalForm({
  isCreate,
  isConsult,
  isUpdate,
  model,
  handleSubmit,
  authInfo = {},
  requiredRoles = [],
  ...restProps
}) {
  const { t } = useTranslation();

  return (
    <QCXFinalForm
      isCreate={isCreate}
      isConsult={isConsult}
      isUpdate={isUpdate}
      initialValues={model}
      handleSubmitValues={handleSubmit}
      authInfo={authInfo}
      requiredRoles={requiredRoles}
      {...restProps}
    >
      {() => (
        <>
          <Grid
            item
            xs={12}
            sm={2}
          >
            <QCXFinalTextField
              id="final-text-field-id"
              key="text-field-id"
              label={t('com.muralis.qcx.codigo')}
              name="code"
              disabled={isConsult}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={10}
          >
            <QCXFinalTextField
              id="text-field-description"
              key="text-field-description"
              label={t('com.muralis.qcx.desvio.nomeDesvio')}
              name="description"
              maxLength={40}
              validate={requiredValidator}
              disabled={isConsult}
              required
            />
          </Grid>
        </>
      )}
    </QCXFinalForm>
  );
}
