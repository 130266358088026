import { makeStyles } from '@material-ui/core';
import {
  Box, Grow, ListItem, Typography,
} from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon,
} from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  listItem: {
    boxShadow: `0px 4px 4px -2px rgb(0 0 0 / 20%),
  0px 4px 5px 0px rgb(0 0 0 / 14%), 4px 2px 9px 2px rgb(0 0 0 / 12%)!important`,
    background: '#ffffff!important',
    height: '170px!important',
    textAlign: 'center!important',
    borderRadius: '5px!important',
    margin: '8px!important',
    border: '1px solid #f39200!important',
    transition: 'all 0.3s ease!important',
    fontWeight: 800,
    display: 'flex!important',
    flexDirection: 'column!important',
    justifyContent: 'center!important',
    alignItems: 'center!important',
  },
  iconMenu: {
    color: '#f39200!important',
    width: '60px!important',
    height: '60px!important',
  },
  expandMoreIcon: {
    color: '#f39200!important',
    width: '34px!important',
    height: '34px!important',
  },
  listItemText: {
    fontSize: '12px!important',
    textTransform: 'uppercase!important',
    fontWeight: 500,
    lineHeight: 1.2,
    fontFamily: 'Arial!important',
    marginTop: theme.spacing(1),
  },
  activeListItem: {
    background: '#312783!important',
    height: '250px!important',
  },
  active: {
    display: 'flex!important',
  },
  noActive: {
    display: 'none!important',
    transformOrigin: '0 0 0!important',
  },
}));

export default function QCXCardMenuWithSubmenu({
  title, link, icon, timeout, isActiveOpenMenu, ...restProps
}) {
  const classes = useStyles();
  const key = title.replaceAll(' ', '-').toLowerCase();
  const Icon = icon;
  return (
    <Grow in={isActiveOpenMenu || !isActiveOpenMenu} timeout={500}>
      <ListItem
        button
        key={key}
        {...restProps}
        className={`${classes.listItem} ${isActiveOpenMenu ? classes.activeListItem : ''}`}
      >
        <Box>
          <Icon
            className={classes.iconMenu}
          />
        </Box>
        {isActiveOpenMenu ? null : (
          <Typography
            classes={{ root: classes.listItemText }}
          >
            {title}
          </Typography>
        )}
        {isActiveOpenMenu ? <ExpandMoreIcon className={classes.expandMoreIcon} style={{ transform: 'scaleY(-1)', transition: '0.5s' }} /> : <ExpandMoreIcon className={classes.expandMoreIcon} />}
      </ListItem>
    </Grow>
  );
}
