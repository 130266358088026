const selectFollowUps = (state) => state.followUpExportacao.list;
const selectFollowUp = (state) => state.followUpExportacao.model;
const selectRelatedDuplicataModel = (state) => (
  state.followUpExportacao.related.model.duplicata
);
const selectRelatedEmailModel = (state) => (
  state.followUpExportacao.related.model.email
);
const selectHistoryModel = (state) => state.followUpExportacao.historyModel;
const selectStatus = (state) => state.followUpExportacao.status;
const selectRefresh = (state) => state.followUpExportacao.refresh;
const selectMode = (state) => state.followUpExportacao.mode.main;
const selectBackgroundMode = (state) => state.followUpExportacao.mode.background;
const selectSubMode = (state) => state.followUpExportacao.mode.sub;
const selectConfigControl = (state) => state.followUpExportacao.config.control;
const selectError = (state) => state.followUpExportacao.error;
const selectResponse = (state) => state.followUpExportacao.response;
const selectAlreadyCreated = (state) => (
  state.followUpExportacao.related.model.alreadyCreated
);

const followUpExportacaoSelectors = {
  selectFollowUps,
  selectFollowUp,
  selectRelatedDuplicataModel,
  selectRelatedEmailModel,
  selectHistoryModel,
  selectStatus,
  selectRefresh,
  selectMode,
  selectBackgroundMode,
  selectSubMode,
  selectConfigControl,
  selectError,
  selectResponse,
  selectAlreadyCreated,
};

export default followUpExportacaoSelectors;
