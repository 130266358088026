import {
  Grid,
  // makeStyles,
} from '@material-ui/core';
import React, { useCallback, useMemo } from 'react';
import { useFormState } from 'react-final-form';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { selectRegimesTributacao } from '../../features/regime-tributacao/regimeTributacaoSlice';
import QCXFinalPercentualField from '../final-percentual-field/QCXFinalPercentualField';
import QCXSelectRegimeTributacaoAutocomplete from '../select-regime-tributacao/QCXSelectRegimeTributacaoAutocomplete';
import {
  REGIME_TRIBUTACAO_ISENCAO_CODE,
  REGIME_TRIBUTACAO_REDUCAO_CODE,
  REGIME_TRIBUTACAO_RECOLHIMENTO_INTEGRAL_CODE,
  configureGeneralRegimeTributacaoMatcherWith,
  REGIME_TRIBUTACAO_SUSPENSAO_CODE,
} from '../select-regime-tributacao/regimeTributacaoUtils';
import {
  configureGeneralTipoDeclaracaoMatcherWith,
  TIPO_DECLARACAO_INTERNACAO_DA_ZFM_PE_PRODUTO_ESTRANGEIRO,
  TIPO_DECLARACAO_INTERNACAO_DA_ZFM_PI_PRODUTO_INDUSTRIALIZADO,
} from '../select-tipo-declaracao/tipoDeclaracaoUtils';
import { selectTiposDeclaracao } from '../../features/tipo-declaracao/tipoDeclaracaoSlice';
import QCXSelectFundamentoLegalRegimeTributacaoIiAutocomplete from '../select-fundamento-legal-regime-tributacao-ii/QCXSelectFundamentoLegalRegimeTributacaoIiAutocomplete';

export default function QCXIICommonFormGroup({
  modes,
  parentModel,
}) {
  const { t } = useTranslation();
  const { values } = useFormState();

  const {
    isConsult,
    isSubConsult,
    isSubNone,
  } = modes;

  const regimesTributacao = useSelector(selectRegimesTributacao);
  const tiposDeclaracao = useSelector(selectTiposDeclaracao);

  const regimeTributacao = useMemo(() => (
    values?.ii?.regimeTributacao?.id
  ), [values]);

  const tipoDeclaracao = useMemo(() => (
    parentModel?.atributosAdicionais?.tipoDeclaracao?.id
  ), [parentModel]);

  const isSomeRegimeTributacaoBy = useCallback((codesToCompare) => (
    configureGeneralRegimeTributacaoMatcherWith(
      regimesTributacao
    )(
      regimeTributacao,
      codesToCompare
    )
  ), [
    regimeTributacao,
    regimesTributacao,
  ]);

  const isSomeTipoDeclaracaoBy = useCallback((codesToCompare) => (
    configureGeneralTipoDeclaracaoMatcherWith(
      tiposDeclaracao
    )(
      tipoDeclaracao,
      codesToCompare
    )
  ), [tiposDeclaracao, tipoDeclaracao]);

  return (
    <>
      <Grid
        item
        xs={12}
        sm={6}
        md={6}
        lg={6}
      >
        <QCXSelectRegimeTributacaoAutocomplete
          id="select-field-regime-tributacao-ii"
          key="select-field-regime-tributacao-ii"
          name="ii.regimeTributacao.id"
          label={t('com.muralis.qcx.regimeTributacao')}
          initialValues={values}
          disabled={isConsult || isSubConsult || isSubNone}
        />
      </Grid>
      {(
        isSomeRegimeTributacaoBy([
          REGIME_TRIBUTACAO_RECOLHIMENTO_INTEGRAL_CODE,
          REGIME_TRIBUTACAO_ISENCAO_CODE,
          REGIME_TRIBUTACAO_REDUCAO_CODE,
          REGIME_TRIBUTACAO_SUSPENSAO_CODE,
        ]) && (
          !isSomeTipoDeclaracaoBy(
            [
              TIPO_DECLARACAO_INTERNACAO_DA_ZFM_PI_PRODUTO_INDUSTRIALIZADO,
              TIPO_DECLARACAO_INTERNACAO_DA_ZFM_PE_PRODUTO_ESTRANGEIRO,
            ]
          )
        )
      ) && (
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={6}
        >
          <QCXFinalPercentualField
            id="aliquota-ad-valorem-acordo-tarifario-field-ii"
            key="aliquota-ad-valorem-acordo-tarifario-field-ii"
            name="ii.aliquota"
            label={t('com.muralis.qcx.aliquotaADValoremPercentual')}
            disabled={isConsult || isSubConsult || isSubNone}
          />
        </Grid>
      )}
      {!isSomeRegimeTributacaoBy([
        REGIME_TRIBUTACAO_RECOLHIMENTO_INTEGRAL_CODE,
      ]) && (
        <>
          <Grid
            item
            xs={12}
            sm={(
              !isSomeRegimeTributacaoBy([
                REGIME_TRIBUTACAO_ISENCAO_CODE,
                REGIME_TRIBUTACAO_REDUCAO_CODE,
                REGIME_TRIBUTACAO_SUSPENSAO_CODE,
              ])
                ? 6
                : 12
            )}
            md={6}
            lg={6}
          >
            <QCXSelectFundamentoLegalRegimeTributacaoIiAutocomplete
              id="select-field-fundamento-legal-ii"
              key="select-field-fundamento-legal-ii"
              name="ii.fundamentoLegalRegimeTributacaoIi.id"
              label={t('com.muralis.qcx.legislacao.fundamentoLegal')}
              initialValues={values}
              disabled={isConsult || isSubConsult || isSubNone}
            />
          </Grid>
        </>
      )}
      {(
        isSomeRegimeTributacaoBy([
          REGIME_TRIBUTACAO_REDUCAO_CODE,
          REGIME_TRIBUTACAO_SUSPENSAO_CODE,
        ]) && (
          !isSomeTipoDeclaracaoBy(
            [
              TIPO_DECLARACAO_INTERNACAO_DA_ZFM_PI_PRODUTO_INDUSTRIALIZADO,
              TIPO_DECLARACAO_INTERNACAO_DA_ZFM_PE_PRODUTO_ESTRANGEIRO,
            ]
          )
        )
      ) && (
        <>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            lg={3}
          >
            <QCXFinalPercentualField
              id="percentual-redução-acordo-tarifario-field-ii"
              key="percentual-redução-acordo-tarifario-field-ii"
              name="ii.percentualReducao"
              label={t('com.muralis.qcx.percentualReducao')}
              scale={7}
              disabled={isConsult || isSubConsult || isSubNone}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            lg={3}
          >
            <QCXFinalPercentualField
              id="aliquota-reduzida-acordo-tarifario-field-ii"
              key="aliquota-reduzida-acordo-tarifario-field-ii"
              name="ii.aliquotaReduzida"
              label={t('com.muralis.qcx.alioquoteReduzidaPercentual')}
              scale={7}
              disabled={isConsult || isSubConsult || isSubNone}
            />
          </Grid>
        </>
      )}
    </>
  );
}
