import { Grid, Stack, Typography } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

/**
 * It takes an error prop and returns a Grid component with a Stack component inside of it if the error
 * prop is truthy
 * @param  - `error` - the error message to display
 * @returns A component that displays an error message if the error prop is passed in.
 */
const ErrorBadge = ({
  error,
}: {
  error: string | null | undefined | string[];
}) => {
  return (
    <>
      {error && (
        <Grid item sm={12}>
          {Array.isArray(error) ? (
            <Stack
              direction={"row"}
              border={"1px solid red"}
              borderRadius={2}
              py={2}
              pl={2}
              alignItems={"center"}
            >
              <ErrorOutlineIcon color="error" />
              <Stack direction={"column"}>
                {error.map((error) => (
                  <Typography
                    ml={1}
                    color={"red"}
                    textTransform={"uppercase"}
                    fontSize={12}
                  >
                    {error}
                  </Typography>
                ))}
              </Stack>
            </Stack>
          ) : (
            <Stack
              direction={"row"}
              border={"1px solid red"}
              borderRadius={2}
              py={2}
              pl={2}
              alignItems={"center"}
            >
              <ErrorOutlineIcon color="error" />
              <Typography
                ml={1}
                color={"red"}
                textTransform={"uppercase"}
                fontSize={12}
              >
                {error}
              </Typography>
            </Stack>
          )}
        </Grid>
      )}
    </>
  );
};

export default ErrorBadge;
