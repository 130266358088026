import { useContext, useMemo } from 'react';
import PaginatorStateContext from '../../../contexts/components/paginator/PaginatorStateContext';

export default function usePaginatorState() {
  const contextValue = useContext(PaginatorStateContext);

  const paginatorState = useMemo(() => ({
    page: contextValue?.page,
    defaultPage: contextValue?.defaultPage,
    usedDefaultPage: contextValue?.usedDefaultPage,
    action: contextValue?.action,
    metadata: contextValue?.metadata,
  }), [contextValue]);

  return paginatorState;
}
