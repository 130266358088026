import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { IconButton } from '@material-ui/core';
import {
  AccountBalanceWallet as AccountBalanceWalletIcon,
  Search as PageviewIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import QCXSimpleConsultPageTemplate from '../../../../../templates/simple-consult-page/QCXSimpleConsultPageTemplate';
import PlanoContaContext from '../../../../../contexts/registrations/plano-conta/PlanoContaContext';
import {
  activateByIdAsync,
  changeToConsultMode,
  changeToCreateMode,
  fetchAllAsync,
  inactivateByIdAsync,
  selectPlanosConta,
  resetMode,
  resetModel,
  resetBackgroundMode,
} from '../../../../../features/plano-conta/planoContaSlice';
import {
  ALTERNATIVE_LOADING_STATUS,
} from '../../../../../features/status';
import useOperationController from '../../../../../utils/hooks/store/operation/useOperationController';
import { getTipoModoPlanoContaLabelByValue } from '../../../../../shared-components/final-select-modo-plano-conta/QCXFinalSelectTipoModoPlanoContaAutocomplete';
import { userHasDeleteRole } from '../../../../../components/perfil/perfil-utils';

export default function PlanoContaConsultPage({ authInfo = {} }) {
  const { t } = useTranslation();

  const history = useHistory();

  const {
    status,
  } = useContext(PlanoContaContext);

  const dispatch = useDispatch();

  const planosConta = useSelector(selectPlanosConta);

  const isAlternativeLoading = useMemo(() => status === ALTERNATIVE_LOADING_STATUS, [status]);

  const fetchAll = async () => {
    dispatch(fetchAllAsync());
  };

  const resetModes = useCallback(() => {
    dispatch(resetMode());
    dispatch(resetBackgroundMode());
  }, []);

  const resetModels = useCallback(() => {
    dispatch(resetModel());
  }, []);

  useEffect(() => {
    fetchAll();
    resetModes();
    resetModels();
  }, []);

  const handleActivateInactivate = useOperationController(async (event, id) => {
    event.preventDefault();

    const { active } = planosConta.find(
      (item) => item.id === id
    );

    const isActivation = !active;

    if (isActivation) {
      dispatch(activateByIdAsync(id));
    } else {
      dispatch(inactivateByIdAsync(id));
    }
  });

  const handleClickOnEditButton = useCallback(
    (event, id) => {
      event.stopPropagation();

      dispatch(changeToConsultMode());
      history.push(t('com.muralis.qcx.url.modulosFinanceirosPlanoContasDetalhes', { id }));
    }, [history]
  );

  const breadcrumbs = [
    {
      link: {
        to: '/',
        name: t('com.muralis.qcx.inicio'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.cadastros'),
      },
    },
    {
      link: {
        to: t('com.muralis.qcx.url.moduloCadastrosFinanceiros'),
        name: t('com.muralis.qcx.financeiro.labelPlural'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.planoConta.labelPlural'),
      },
    },
  ];

  const columns = [
    {
      field: 'id',
      headerName: t('com.muralis.qcx.codigo'),
      align: 'center',
      type: 'string',
      flex: 90,
      valueGetter: ({ row }) => (
        row?.id
      ),
    },
    {
      field: 'description',
      headerName: t('com.muralis.qcx.descricao'),
      flex: 290,
      valueGetter: ({ row }) => (
        row?.description
      ),
    },
    {
      field: 'conta',
      headerName: t('com.muralis.qcx.empresa.contaContabil'),
      headerAlign: 'left',
      align: 'left',
      flex: 200,
      valueGetter: ({ row }) => (
        row?.conta
      ),
    },
    {
      field: 'grau',
      headerName: t('com.muralis.qcx.grau'),
      headerAlign: 'center',
      align: 'center',
      flex: 100,
      valueGetter: ({ row }) => (
        row?.grau
      ),
    },
    {
      field: 'modo',
      headerName: t('com.muralis.qcx.modo'),
      headerAlign: 'center',
      align: 'center',
      type: 'string',
      flex: 160,
      valueFormatter: ({ row }) => (
        getTipoModoPlanoContaLabelByValue(row?.modo)
      ),
    },
    {
      field: 'actions',
      headerName: t('com.muralis.qcx.acoes.label'),
      headerAlign: 'center',
      align: 'center',
      flex: 150,
      renderCell: ({ row: { id, active } }) => (
        <>
          {userHasDeleteRole(authInfo, 'plano-de-conta') && (
          <IconButton
            key={`stw-active${id}`}
            value={active}
            name={`stw-active-${id}`}
            onClick={(event) => handleActivateInactivate(event, id)}
          >
            <DeleteIcon color="error" />
          </IconButton>
          )}
          <IconButton
            key={`btn-view-${id}`}
            name={`btn-view-${id}`}
            onClick={(event) => handleClickOnEditButton(event, id)}
          >
            <PageviewIcon color="primary" />
          </IconButton>
        </>
      ),
    },
  ];

  const handleClickOnButtonNew = useCallback((event) => {
    event.stopPropagation();
    dispatch(changeToCreateMode());
  }, []);

  const templateProps = {
    page: {
      title: t('com.muralis.qcx.planoConta.labelPlural'),
      icon: <AccountBalanceWalletIcon />,
      breadcrumbs,
    },
    control: {
      buttonNew: {
        description: t('com.muralis.qcx.planoConta.novoPlanoConta'),
        path: t('com.muralis.qcx.url.modulosFinanceirosPlanoContasNovo'),
        onClick: handleClickOnButtonNew,
      },
    },
    table: {
      columns,
    },
  };

  const filterPropGetter = useCallback(
    (planoConta) => planoConta.description,
    []
  );

  return (
    <QCXSimpleConsultPageTemplate
      pageProps={templateProps.page}
      controlProps={templateProps.control}
      tableProps={templateProps.table}
      tableData={planosConta}
      isLoading={isAlternativeLoading}
      filterPropGetter={filterPropGetter}
      authInfo={authInfo}
      requiredRoles={['plano-de-conta']}
    />
  );
}
