import React, { useMemo } from 'react';
import BaseSelectWithOptions from '../../../../../../../shared-components/dados-embarque/base-components/BaseSelectWithOptions';

function NecessidadeLiSelect({ ...props }) {
  const list = useMemo(() => [
    {
      id: 'Sim', value: true, label: 'Sim',
    }, {
      id: 'Não', value: false, label: 'Não',
    }
  ], []);

  return (
    <BaseSelectWithOptions
      list={list}
      {...props}
    />
  );
}

export default NecessidadeLiSelect;
