import { createFilterOptions } from '@material-ui/lab/useAutocomplete';
import _ from 'lodash';
import React, { useMemo } from 'react';
import QCXAutocompleteSelect from '../autocomplete-select/QCXAutocompleteSelect';
import { FORMATO_IMPRESSAO_DANFE_LIST } from './formatoImpressaoDanfeUtils';

const filterOptions = createFilterOptions({
  stringify: (item) => item?.label,
});

/**
 * Dropdown feito especialmente para o formulário de modelo de danfe.
 */
export default function QCXSelectFormatoImpressaoDanfeAutocomplete({
  label,
  name,
  initialValues,
  ...restProps
}) {
  const defaultValue = useMemo(() => {
    const retratoOuPaisagem = FORMATO_IMPRESSAO_DANFE_LIST
      .find((item) => item?.value === _.get(initialValues, name));

    if (retratoOuPaisagem) {
      return retratoOuPaisagem;
    }
    return null;
  }, [initialValues]);

  return (
    <QCXAutocompleteSelect
      label={label}
      id={name}
      name={name}
      options={FORMATO_IMPRESSAO_DANFE_LIST}
      filterOptions={filterOptions}
      defaultValue={defaultValue}
      getOptionLabel={(item) => item?.label}
      getOptionValue={(item) => item?.value}
      {...restProps}
    />
  );
}
