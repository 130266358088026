import { createAsyncThunk } from '@reduxjs/toolkit';
import { isFunction } from 'lodash';
import { MediaType } from '../../utils/api/api-utils';
import { contasPagarAPI } from './contasPagarAPI';

const fetchAllAsync = createAsyncThunk(
  'contasPagar/fetchAll',
  async () => {
    const { data } = await contasPagarAPI.fetchAll();
    return { data };
  }
);

const generateReportAPagarByDateAsync = createAsyncThunk(
  'contasPagar/generateReportAPagarByDate',
  async (payload) => {
    try {
      const response = await contasPagarAPI.generateReportAPagarByDate([
        {
          name: 'datainicial',
          value: payload?.vencimentoDe,
        },
        {
          name: 'datafinal',
          value: payload?.vencimentoAte,
        }
      ]);

      if (response?.status === 204) {
        const onNoContent = payload?.handlers?.onNoContent;
        if (isFunction(onNoContent)) {
          onNoContent();
          return;
        }
      }

      const xmlData = new Blob([response?.data], { type: MediaType.APPLICATION_PDF });

      const url = window.URL.createObjectURL(xmlData);
      window.open(url);
    } catch (e) {
      const onError = payload?.handlers?.onError;

      if (isFunction(onError)) {
        onError();
      }
    }
  }
);

const solicitacaoPagamentoThunks = {
  fetchAllAsync,
  generateReportAPagarByDateAsync,
};

export {
  solicitacaoPagamentoThunks,
  generateReportAPagarByDateAsync,
  fetchAllAsync,
};
