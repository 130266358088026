import React, { useCallback, useMemo } from 'react';
import _ from 'lodash';
import { useForm, useFormState } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { Info as InfoIcon } from '@material-ui/icons';
import {
  CompareArrows as CompareArrowsIcon,
} from '@mui/icons-material';
import { Grid, Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
  selectRelatedItensXmlDanfeMetadata,
  selectRelatedSelectionChangedItemList,
  selectRelatedSelectionItemList,
  selectRelatedTransferenciaItemModel,
} from '../../features/declaracao-unica-exportacao/declaracaoUnicaExportacaoSelectors';
import {
  setRelatedSelectionItemList,
  setRelatedTransferenciaItem as setRelatedTransferenciaItemModel,
  resetRelatedTransferenciaItem as resetRelatedTransferenciaItemModel,
  removeItemFromChangedSelectionList,
  addItemToChangedSelectionList,
  setRelatedItensXmlDanfeMetadata,
} from '../../features/declaracao-unica-exportacao/declaracaoUnicaExportacaoSlice';
import QCXDataGridControl from '../../shared-components/data-grid-control/QCXDataGridControl';
import QCXFinalFormDialog from '../../shared-components/dialog/QCXFinalFormDialog';
import useFormDialogSync from '../../utils/hooks/form/dialog/useFormDialogSync';
import { CLOSED_STATUS, isOpenStatus, SUBMITTING_STATUS } from '../../utils/hooks/form/dialog/formDialogUtils';
import QCXButton from '../../shared-components/button/QCXButton';
import { normalizeNumeral, unnormalizeNumeral } from '../../utils/general/general-utils';
import { formatBrazilianNumericDecimal } from '../../utils/hooks/form/field/formatters';
import QCXSelectModoTransferenciaFaturaAutocomplete from '../../shared-components/select-modo-transferencia-fatura/QCXSelectTipoFaturaAutocomplete';
import { maxValue, required } from '../../utils/validators/field/validator';
import QCXFinalNumericDecimalField from '../../shared-components/final-numeric-decimal-field/QCXFinalNumericDecimalField';
import ModoTransferenciaFaturaUtils from '../../shared-components/select-modo-transferencia-fatura/modoTransferenciaFaturaUtils';
import useValidators from '../../utils/hooks/useValidators';
import QCXBox from '../../shared-components/box/QCXBox';

export default function QCXImportacaoXmlDanfeDataGridManager({
  isBackgroundTransfer,
  rows = [],
  disabled,
  ...restProps
}) {
  const dispatch = useDispatch();

  const form = useForm();
  const { values } = useFormState();
  const { t } = useTranslation();

  const relatedTransferenciaItemModel = useSelector(selectRelatedTransferenciaItemModel);
  const relatedItensXmlDanfeList = useSelector(selectRelatedItensXmlDanfeMetadata);
  const relatedSelectionItemList = useSelector(selectRelatedSelectionItemList);
  const relatedSelectionChangedItemList = useSelector(selectRelatedSelectionChangedItemList);

  const disableItensXmlDanfeDataGrig = useMemo(() => (
    disabled
  ), [disabled]);

  const isEmptySelectionItemList = useMemo(() => (
    !relatedSelectionItemList || (
      _.isArrayLikeObject(relatedSelectionItemList)
      && _.isEmpty(relatedSelectionItemList)
    )
  ), [relatedSelectionItemList]);

  const handleSelectionModelChange = useCallback(({ selectionModel }) => {
    dispatch(setRelatedSelectionItemList(selectionModel));
    const mappedItems = rows
      .filter((item) => (
        selectionModel?.includes(item?.id)
      ))
      .map((item) => (
        {
          ...item,
          modo: ModoTransferenciaFaturaUtils.POR_QUANTIDADE,
          ...item?.transferencia,
          itemOriginal: {
            id: item?.id,
            quantidade: item?.quantidadeInicial,
            partnumber: item?.partnumber,
            numero: item?.due?.nItem,
            valorUnitarioMoeda: item?.valorUnitarioMoeda,
          },
        }
      ));

    form.change('itensXmlDanfe', mappedItems);

    const isSelectedItemBy = (id) => (
      selectionModel?.indexOf(id) !== -1
    );

    const isModifiedItemBy = (id) => (
      relatedSelectionChangedItemList?.indexOf(id) !== -1
    );

    const hasAnyModifiedAndNotSelectedItem = rows?.some((selectedItem) => (
      isModifiedItemBy(selectedItem?.id) && !isSelectedItemBy(selectedItem?.id)
    ));

    if (hasAnyModifiedAndNotSelectedItem) {
      const updatedSelectionAvailableItemList = relatedItensXmlDanfeList
        ?.map((item) => {
          if (isModifiedItemBy(item?.id) && !isSelectedItemBy(item?.id)) {
            dispatch(
              removeItemFromChangedSelectionList(
                item?.id
              )
            );

            return {
              ...item,
              transferencia: undefined,
            };
          }

          return item;
        });

      dispatch(
        setRelatedItensXmlDanfeMetadata(
          updatedSelectionAvailableItemList
        )
      );
    }
  }, [
    form,
    rows,
    relatedSelectionChangedItemList,
    relatedItensXmlDanfeList,
  ]);

  const handleQuantidadeChange = useCallback((formControl, formValues) => (value) => {
    const valorUnitarioMoeda = formValues?.itemOriginal?.valorUnitarioMoeda;

    if (value) {
      const quantidadeAtual = normalizeNumeral(value);
      const normalizedValorUnitarioMoeda = normalizeNumeral(valorUnitarioMoeda);

      const novoValorTotalMoeda = quantidadeAtual * normalizedValorUnitarioMoeda;

      const formattedNovoValorTotalMoeda = unnormalizeNumeral(
        novoValorTotalMoeda,
        formatBrazilianNumericDecimal(2)
      );

      formControl.change('valorTotalMoeda', formattedNovoValorTotalMoeda);

      return;
    }

    const formattedValorTotalMoeda = unnormalizeNumeral(
      formValues?.valorTotalMoedaInicial,
      formatBrazilianNumericDecimal(2)
    );

    formControl.change('valorTotalMoeda', formattedValorTotalMoeda);
  }, []);

  const changeListeners = useMemo(() => ([
    {
      name: 'quantidade',
      fn: handleQuantidadeChange,
    }
  ]), [handleQuantidadeChange]);

  const [
    handleAlterarModoTransferenciaClick,
    formDialogAlterarModoTransferenciaStatus,
    handleFormDialogAlterarModoTransferenciaStatus,
  ] = useFormDialogSync(
    async (event, data) => {
      if (_.isFunction(event?.stopPropagation)) {
        event.stopPropagation();

        const quantidadeTotal = data?.quantidade;
        const quantidadeParcial = normalizeNumeral(
          data?.transferencia?.quantidade
          || data?.quantidade
        );

        dispatch(
          setRelatedTransferenciaItemModel({
            ...data,
            modo: (
              data?.transferencia?.modo
              || ModoTransferenciaFaturaUtils.POR_QUANTIDADE
            ),
            quantidade: unnormalizeNumeral(
              (
                quantidadeParcial
                || quantidadeTotal
              ),
              formatBrazilianNumericDecimal(5)
            ),
            valorUnitarioMoeda: unnormalizeNumeral(
              data?.valorUnitarioMoeda,
              formatBrazilianNumericDecimal(2)
            ),
            itemOriginal: {
              id: data?.id,
              quantidade: data?.quantidade,
              partnumber: data?.partnumber,
              numero: data?.due?.ndata,
              valorUnitarioMoeda: data?.valorUnitarioMoeda,
            },
            due: {
              ...data?.due,
            },
          })
        );
      }
    },
    []
  );

  const handleAlterarModoTransferenciaByDataSubmit = useCallback((data) => {
    handleFormDialogAlterarModoTransferenciaStatus(SUBMITTING_STATUS);
    const updatedItemsFormState = values?.itensXmlDanfe?.map((currentItem) => (
      currentItem?.itemOriginal?.id === data?.itemOriginal?.id
        ? data
        : currentItem
    ));

    form.change('itensXmlDanfe', updatedItemsFormState);

    const isPristineItem = (
      normalizeNumeral(data?.quantidade) === data?.itemOriginal?.quantidade
    );

    if (!isPristineItem) {
      dispatch(
        addItemToChangedSelectionList(
          data?.itemOriginal?.id
        )
      );
    }

    const isPreviousChanged = (
      relatedSelectionChangedItemList?.indexOf(
        data?.itemOriginal?.id
      ) !== -1
    );

    const isUndoPreviousChangedItem = (
      isPristineItem && isPreviousChanged
    );

    if (isUndoPreviousChangedItem) {
      dispatch(
        removeItemFromChangedSelectionList(
          data?.itemOriginal?.id
        )
      );
    }

    const updatedSelectionAvailableItemList = relatedItensXmlDanfeList
      ?.map((item) => (
        item?.id === data?.itemOriginal?.id
          ? {
            ...item,
            transferencia: {
              modo: data?.modo,
              quantidade: data?.quantidade,
              valorUnitarioMoeda: data?.valorUnitarioMoeda,
              valorTotalMoeda: data?.valorTotalMoeda,
            },
          } : item
      ));

    dispatch(
      setRelatedItensXmlDanfeMetadata(
        updatedSelectionAvailableItemList
      )
    );

    (_.debounce(() => {
      dispatch(resetRelatedTransferenciaItemModel());
    }, 200))();
  }, [
    rows,
    form,
    values,
    relatedSelectionChangedItemList,
    relatedItensXmlDanfeList,
    handleFormDialogAlterarModoTransferenciaStatus,
  ]);

  const handleCloseFormDialogAlterarModoTransferencia = useCallback(() => {
    handleFormDialogAlterarModoTransferenciaStatus(CLOSED_STATUS);

    (_.debounce(() => {
      dispatch(resetRelatedTransferenciaItemModel());
    }, 200))();
  }, [handleFormDialogAlterarModoTransferenciaStatus]);

  const isFormDialogAlterarModoTransferenciaOpen = useMemo(() => (
    isOpenStatus(formDialogAlterarModoTransferenciaStatus)
  ));

  const formDialogAlterarModoTransferenciaContent = useMemo(() => (
    t(
      'com.muralis.qcx.mensagem.alterarQuantidadeTransferenciaItem',
      {
        identificador: relatedTransferenciaItemModel?.due?.nItem || '###',
      }
    )
  ), [relatedTransferenciaItemModel, t]);

  const columns = useMemo(() => ([
    {
      field: 'itemDanfe',
      headerName: t('com.muralis.qcx.DANFE.itemDANFE'),
      headerAlign: 'center',
      align: 'center',
      flex: 100,
      valueGetter: ({ row }) => row?.due?.nItem || '-',
    },
    {
      field: 'partNumber',
      headerName: t('com.muralis.qcx.item.partNumber'),
      headerAlign: 'center',
      align: 'center',
      flex: 100,
      valueGetter: ({ row }) => row?.partnumber || '-',
    },
    {
      field: 'ncm',
      headerName: t('com.muralis.qcx.NCM.NCMSH'),
      headerAlign: 'center',
      align: 'center',
      flex: 90,
      valueGetter: ({ row }) => row?.ncm || '-',
    },
    {
      field: 'pesoLiquido',
      headerName: t('com.muralis.qcx.pesoLiquido'),
      headerAlign: 'center',
      align: 'center',
      flex: 85,
      valueGetter: ({ row }) => row?.pesoLiquido || '-',
    },
    {
      field: 'valorUnitarioMoeda',
      headerName: t('com.muralis.qcx.valorUnitario'),
      headerAlign: 'right',
      align: 'right',
      flex: 105,
      valueFormatter: ({ row: { valorUnitarioMoeda } }) => (
        unnormalizeNumeral(
          valorUnitarioMoeda,
          formatBrazilianNumericDecimal(2)
        ) || '-'
      ),
    },
    {
      field: 'valorTotalMoeda',
      headerName: t('com.muralis.qcx.mercadoria.valorMLE'),
      headerAlign: 'right',
      align: 'right',
      flex: 90,
      renderCell: ({ row }) => {
        const valorTotalMoedaInicial = row?.valorTotalMoedaInicial;
        const valorTotalMoeda = row?.valorTotalMoeda;
        const valorTotalMoedaParcial = normalizeNumeral(row?.transferencia?.valorTotalMoeda);

        const formattedValorTotalMoedaDisponivel = unnormalizeNumeral(
          valorTotalMoeda,
          formatBrazilianNumericDecimal(2)
        );

        if (valorTotalMoedaInicial === valorTotalMoeda && !valorTotalMoedaParcial) {
          return formattedValorTotalMoedaDisponivel;
        }

        const formattedValorTotalMoedaInicial = unnormalizeNumeral(
          valorTotalMoedaInicial,
          formatBrazilianNumericDecimal(2)
        );

        const formattedQuantidadeParcial = unnormalizeNumeral(
          valorTotalMoedaParcial,
          formatBrazilianNumericDecimal(2)
        );

        const isChangedValorTotalMoedaItem = (
          valorTotalMoeda !== valorTotalMoedaParcial
        );

        const formattedQuantidade = isChangedValorTotalMoedaItem
          ? formattedQuantidadeParcial
          : formattedValorTotalMoedaDisponivel;

        const helperText = isChangedValorTotalMoedaItem
          ? t('com.muralis.qcx.valorInicial', { valor: formattedValorTotalMoedaInicial }).toUpperCase()
          : '';

        return (
          <>
            <QCXBox
              pr={1}
            >
              {formattedQuantidade}
            </QCXBox>
            {isChangedValorTotalMoedaItem && (
              <Tooltip
                title={helperText}
                arrow
              >
                <InfoIcon
                  color="disabled"
                  fontSize="small"
                />
              </Tooltip>
            )}
          </>
        );
      },
    },
    {
      field: 'quantidade',
      headerName: t('com.muralis.qcx.quantidade.label'),
      headerAlign: 'center',
      align: 'center',
      flex: 110,
      renderCell: ({ row }) => {
        const quantidadeTotalInicial = row?.quantidadeInicial;
        const quantidadeTotalDisponivel = row?.quantidade;
        const quantidadeParcial = normalizeNumeral(row?.transferencia?.quantidade);

        const formattedQuantidadeTotalDisponivel = unnormalizeNumeral(
          quantidadeTotalDisponivel,
          formatBrazilianNumericDecimal(5)
        );

        if (quantidadeTotalInicial === quantidadeTotalDisponivel && !quantidadeParcial) {
          return formattedQuantidadeTotalDisponivel;
        }

        const formattedQuantidadeTotalInicial = unnormalizeNumeral(
          quantidadeTotalInicial,
          formatBrazilianNumericDecimal(5)
        );

        const formattedQuantidadeParcial = unnormalizeNumeral(
          quantidadeParcial,
          formatBrazilianNumericDecimal(5)
        );

        const isChangedQuantidadeItem = (
          ModoTransferenciaFaturaUtils.isPorQuantidade(row?.transferencia?.modo)
          && quantidadeTotalDisponivel !== quantidadeParcial
        );

        const formattedQuantidade = isChangedQuantidadeItem
          ? formattedQuantidadeParcial
          : formattedQuantidadeTotalDisponivel;

        const helperText = isChangedQuantidadeItem
          ? t('com.muralis.qcx.quantidadeInicial', { quantidadeInicial: formattedQuantidadeTotalInicial }).toUpperCase()
          : '';

        return (
          <>
            <QCXBox
              pr={1}
            >
              {formattedQuantidade}
            </QCXBox>
            <Tooltip
              title={helperText}
              arrow
            >
              <InfoIcon
                color="disabled"
                fontSize="small"
              />
            </Tooltip>
          </>
        );
      },
    },
    {
      field: 'actions',
      headerName: t('com.muralis.qcx.acoes.label'),
      headerAlign: 'center',
      align: 'center',
      flex: 70,
      renderCell: ({ row }) => (
        <>
          <QCXButton
            key={`btn-change-values-${row?.id}`}
            name={`btn-change-values-${row?.id}`}
            variant="text"
            color="secondary"
            size="small"
            tooltipDescription={t('com.muralis.qcx.acoes.alterarQuantidade')}
            tooltip
            onClick={(event) => {
              handleAlterarModoTransferenciaClick(
                event,
                row
              );
            }}
            disabled={(
              isEmptySelectionItemList
              || !relatedSelectionItemList?.includes(row?.id)
              || disableItensXmlDanfeDataGrig
            )}
          >
            <CompareArrowsIcon />
          </QCXButton>
        </>
      ),
    },
  ]), [
    isEmptySelectionItemList,
    relatedSelectionItemList,
    disableItensXmlDanfeDataGrig
  ]);

  console.log(rows);

  return (
    <>
      <QCXDataGridControl
        checkboxSelection
        columns={columns}
        rows={rows}
        selectionModel={relatedSelectionItemList}
        onSelectionModelChange={handleSelectionModelChange}
        componentsProps={{
          checkbox: {
            disabled: disableItensXmlDanfeDataGrig,
          },
        }}
        {...restProps}
      />
      <QCXFinalFormDialog
        key="form-dialog-alterar-modo-transferencia"
        id="form-dialog-alterar-modo-transferencia"
        title={t('com.muralis.qcx.acoes.alterarQuantidade')}
        content={formDialogAlterarModoTransferenciaContent}
        dialogActionsProps={{
          button: {
            submit: {
              description: t('com.muralis.qcx.acoes.confirmar'),
              size: 'small',
            },
            cancel: {
              description: t('com.muralis.qcx.acoes.cancelar'),
              size: 'small',
            },
          },
        }}
        open={isFormDialogAlterarModoTransferenciaOpen}
        initialValues={relatedTransferenciaItemModel}
        onSubmit={handleAlterarModoTransferenciaByDataSubmit}
        onClose={handleCloseFormDialogAlterarModoTransferencia}
        formProps={{
          onChangeListeners: changeListeners,
        }}
      >
        {({ values: initialValuesModoTransferencia }) => (
          <Grid
            item
            container
            xs={12}
            spacing={2}
          >
            {false && (
              <Grid
                item
                xs={12}
              >
                <QCXSelectModoTransferenciaFaturaAutocomplete
                  id="select-field-modo-transferencia-danfe"
                  key="select-field-modo-transferencia-danfe"
                  name="modo"
                  label={t('com.muralis.qcx.transferencia.transferirPor')}
                  initialValues={initialValuesModoTransferencia}
                  fieldProps={{
                    validate: required,
                  }}
                  required
                />
              </Grid>
            )}
            {ModoTransferenciaFaturaUtils.isPorQuantidade(
              initialValuesModoTransferencia?.modo
            ) && (
              <>
                <Grid
                  item
                  xs={12}
                >
                  <QCXFinalNumericDecimalField
                    id="numeric-decimal-quantidade-field"
                    key="numeric-decimal-quantidade-field"
                    name="quantidade"
                    label={t('com.muralis.qcx.quantidade.label')}
                    scale={5}
                    validate={useValidators([
                      required,
                      maxValue(
                        Number(
                          initialValuesModoTransferencia?.itemOriginal?.quantidade
                        ).toFixed(5)
                      )
                    ])}
                    required={required && (
                      Number.isNaN(Number(initialValuesModoTransferencia?.quantidadeParcial))
                      || Number(initialValuesModoTransferencia?.quantidadeParcial) <= 0
                    )}
                  />
                </Grid>
              </>
            )}
          </Grid>
        )}
      </QCXFinalFormDialog>
    </>
  );
}
