import {
  Grid,
  StepButton,
  StepConnector,
  Typography,
} from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import Step from '@material-ui/core/Step';
import Stepper from '@material-ui/core/Stepper';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/styles';
import {
  head,
  last,
  every,
  isArray,
  isFunction,
} from 'lodash';
import React, {
  useCallback,
  useMemo,
  useState,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Fade } from '@mui/material';
import ScrollContainer from 'react-indiana-drag-scroll';
import QCXScrollbar from '../../components/scrollbar/QCXScrollbar';
import QCXButton from '../button/QCXButton';
import WizardContext from '../../contexts/components/wizard/WizardContext';
import { executeFunctionIfValid, isValid } from '../../utils/general/general-utils';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  content: {
    minWidth: '100%',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  steps: {
    backgroundColor: 'transparent',
    width: 'auto',
    paddingLeft: '2px',
    marginLeft: '-10px',
    '&.MuiStepLabel-labelContainer': {
      textAlign: 'left',
      '&.MuiStepLabel-label': {
        lineHeight: '1.2',
      },
    },
  },
  scrollContainer: {
    scrollBehavior: 'smooth',
  },
  scrollShadow: {
    height: '100%',
    position: 'absolute',
    top: 0,
    right: '0',
    fontSize: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    zIndex: 1000,
    boxShadow: '10px 8px 30px 10px rgba(0, 0, 0, 0.4)',
    overflow: 'auto',
    borderRight: '1px solid rgba(0, 0, 0, 0.1)',
    transition: '1s',
  },
  buttonStepActive: {
    backgroundColor: '#F39200!important',
  },
  buttonStep: {
    transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    backgroundColor: 'rgba(0, 0, 0, 0.26)',
    borderRadius: '50%',
    width: '8px',
    height: '8px',
    margin: '0px 2px',
    outline: 'none',
    padding: 0,
    border: 'none',
    '&:hover': {
      cursor: 'pointer',
    },
    zIndex: 1000,
  },
}));

const QCXCustomStepConnector = withStyles({
  active: {
    '& $line': {
      borderColor: '#F39200',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#312783',
    },
  },
  line: {
    borderColor: '#b2b2b2',
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

const QCXCustomTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
  arrow: {
    color: 'rgba(155, 155, 155, 1)',
  },
}))(Tooltip);

export default function QCXFinalFormWizard({
  steps = [],
  optionalSteps = [],
  defaultStep = 0,
  conditionalSteps = [],
  initialValues,
  isCreate,
  skipAutomaticChangeToConsult,
  isBackgroundCreate,
  isConsult,
  isUpdate,
  isSubNone,
  isSubCreate,
  isSubConsult,
  isSubUpdate,
  handleSubmit,
  checkIfIsAlternativeSaveByStep,
  checkIfShowBackButton,
  handleAlternativeSave,
  handleChangeValues,
  handleChangeToCreate,
  handleChangeToBackgroundCreate,
  handleChangeToConsult,
  handleChangeToUpdate,
  handleCancelUpdate,
  handleResetBackgroundMode,
  handleChangeToSubNone,
  handleMetadataUpdate,
  ignoreRulesForSpecialSteps = [],
  disableSteps = false,
  children,
  ...restProps
}) {
  const { t } = useTranslation();
  const classes = useStyles();

  const [currentStep, setCurrentStep] = useState(defaultStep || 0);
  const [completedSteps, setCompletedSteps] = useState({});
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [isScroll, setIsScroll] = useState(false);
  const scrollButtonRight = React.useRef();
  const scrollButtonLeft = React.useRef();
  const [isStep, setIsStep] = useState(false);
  const [scrollContainerWidth, setScrollContainerWidth] = React.useState(0);
  const [scrollContainerElement, setScrollContainerElement] = React.useState(null);
  const [scrollStepElement, setScrollStepElement] = React.useState(null);

  useEffect(() => {
    if (isFunction(handleMetadataUpdate)) {
      handleMetadataUpdate(
        'step',
        currentStep + 1
      );
    }
  }, [
    handleMetadataUpdate,
    currentStep,
  ]);

  const handleStepSkipTooltipClose = () => {
    setIsTooltipOpen(false);
  };

  const handleStepSkipTooltipOpen = () => {
    setIsTooltipOpen(true);
  };

  const colorButtonScrollRight = () => {
    scrollButtonLeft?.current?.classList.remove(classes.buttonStepActive);
    scrollButtonRight?.current?.classList.add(classes.buttonStepActive);
  };

  const colorButtonScrollLeft = () => {
    scrollButtonRight?.current?.classList.remove(classes.buttonStepActive);
    scrollButtonLeft?.current?.classList.add(classes.buttonStepActive);
  };

  const handleStepButtonRight = () => {
    scrollStepElement.scrollLeft = scrollStepElement.scrollWidth;
    colorButtonScrollRight();
  };

  const handleStepButtonLeft = () => {
    scrollStepElement.scrollLeft = 0;
    colorButtonScrollLeft();
  };

  const isAnOptionalStep = useCallback((step) => (
    optionalSteps.indexOf(step) !== -1
  ), [optionalSteps]);

  const isAConditionalStep = useCallback((step) => (
    conditionalSteps.indexOf(step) !== -1
  ), [conditionalSteps]);

  const handleEndScrollStep = () => {
    setIsScroll(false);
    if (scrollStepElement.scrollLeft === 0) {
      colorButtonScrollLeft();
    }
    if ((scrollStepElement.scrollLeft + scrollContainerWidth) >= scrollStepElement.scrollWidth) {
      colorButtonScrollRight();
    }
  };

  const getFirstNonConditionalStepBy = useCallback((currentIndex) => {
    const nonConditionalSteps = steps
      .slice(currentIndex)
      .filter((step) => (
        !isAConditionalStep(step)
      ));

    return head(nonConditionalSteps);
  }, [isAConditionalStep, steps]);

  const getLastNonConditionalStepBy = useCallback((currentIndex) => {
    const nonConditionalSteps = steps
      .slice(0, currentIndex + 1)
      .filter((step) => (
        !isAConditionalStep(step)
      ));

    return last(nonConditionalSteps);
  }, [isAConditionalStep, steps]);

  const getLastNonConditionalStep = useCallback(() => {
    const nonConditionalSteps = steps
      .filter((step) => (
        !isAConditionalStep(step)
      ));

    return last(nonConditionalSteps);
  }, [isAConditionalStep, steps]);

  const getNonConditionalStepBy = useCallback((stepIndex, next = true) => {
    const nextConditionalStepLabel = next
      ? getFirstNonConditionalStepBy(stepIndex)
      : getLastNonConditionalStepBy(stepIndex);

    const nextConditionalStepIndex = steps.indexOf(nextConditionalStepLabel);

    return nextConditionalStepIndex !== -1
      ? nextConditionalStepIndex
      : undefined;
  }, [getFirstNonConditionalStepBy]);

  React.useEffect(() => {
    function updateScroll() {
      setScrollContainerWidth(scrollContainerElement?.scrollWidth || 0);
    }
    window.addEventListener('resize', updateScroll);
  }, []);

  React.useEffect(() => {
    setScrollStepElement(document.querySelector('.scroll-container.indiana-scroll-container'));
  }, []);
  React.useEffect(() => {
    if (scrollStepElement) {
      scrollStepElement.parentNode.style.overflow = '';
      setScrollContainerElement(scrollStepElement.parentNode.parentNode.parentNode);
      setIsStep(scrollStepElement.children[0].children[0].scrollWidth > scrollContainerWidth);
    }

    if (scrollContainerElement) {
      setScrollContainerWidth(scrollContainerElement.scrollWidth);
    }
  }, [scrollStepElement, scrollContainerElement, scrollContainerWidth]);

  const hasMoreThanOneStep = useMemo(() => (
    isArray(steps)
  ), [steps]);

  const totalSteps = useMemo(() => (
    steps?.length
  ), [steps]);

  const totalCompletedSteps = useMemo(() => (
    Object.keys(completedSteps).length
  ), [completedSteps]);

  const finalStep = useMemo(() => (
    steps?.length - 1
  ), [steps]);

  const isLastStep = useMemo(() => {
    if (currentStep === finalStep) {
      return true;
    }

    const lastNonConditionalStep = getLastNonConditionalStep();

    const isCurrentLastNonConditionalStep = (
      currentStep === steps.indexOf(lastNonConditionalStep)
    );

    return isCurrentLastNonConditionalStep;
  }, [
    currentStep,
    finalStep,
    steps,
    getLastNonConditionalStep,
  ]);

  const allStepsCompleted = useMemo(() => (
    totalCompletedSteps === totalSteps
  ));

  const isLastRegistrationStep = useMemo(() => (
    isLastStep
    && !isConsult
    && !isUpdate
  ), [isLastStep, isConsult, isUpdate]);

  const handleStepSkipTooltip = (step) => {
    const isStepOptional = isAnOptionalStep(steps[Math.max(0, step)]);

    if (!isStepOptional) {
      handleStepSkipTooltipClose();
      return;
    }
    if (isStepOptional && isTooltipOpen) {
      handleStepSkipTooltipClose();
      return;
    }
    if (isStepOptional && !isTooltipOpen) {
      handleStepSkipTooltipOpen();
      setTimeout(handleStepSkipTooltipClose, 4000);
    }
  };

  const handleStep = (step) => () => {
    setCurrentStep(step);

    if (isCreate) {
      handleStepSkipTooltip(step);
    }
  };

  const handleCompleteStep = () => {
    const newCompletedSteps = { ...completedSteps };
    newCompletedSteps[currentStep] = true;
    setCompletedSteps(newCompletedSteps);
  };

  const handleNextStep = () => {
    if (isCreate) {
      handleCompleteStep();
    }

    const newCurrentStep = isLastStep
      && !allStepsCompleted
      ? steps.findIndex((step, index) => (
        !(index in completedSteps)
      ))
      : getNonConditionalStepBy(Math.max(0, currentStep + 1)) || currentStep;
    if (isCreate) {
      handleStepSkipTooltip(newCurrentStep);
    }

    setCurrentStep(Math.min(newCurrentStep, finalStep));
  };

  const handleNextStepAndDispatchChanges = (currentValues) => {
    handleNextStep();
    handleChangeValues(currentValues);
  };

  const handlePreviousStep = () => {
    setCurrentStep((previousStep) => {
      const nonConditionalStep = getNonConditionalStepBy(
        Math.max(0, previousStep - 1),
        false
      );

      return nonConditionalStep !== undefined
        ? nonConditionalStep
        : previousStep;
    });
  };

  const handleInternalSubmit = (values) => {
    if (isConsult) {
      handleNextStepAndDispatchChanges(values);
    } else {
      const next = () => handleNextStepAndDispatchChanges(values);
      handleSubmit(values, currentStep, next);
    }
  };

  const handleInternalAlternativeSave = (event) => {
    if (isConsult) {
      handleNextStep();
    } else {
      const next = () => handleNextStep();
      handleAlternativeSave(event, currentStep, next);
    }
  };

  const isCurrentStepEnabled = useCallback((currentStepIndex) => (
    every(
      (steps).slice(0, currentStepIndex),
      (_step, index, array) => (
        (
          completedSteps[Math.max(0, index)]
          || isAnOptionalStep(array[Math.max(0, index)])
        )
      )
    )
  ), [completedSteps, isAnOptionalStep]);

  const isCompletedCurrentStep = useMemo(() => (
    completedSteps[currentStep]
  ), [currentStep, completedSteps]);

  const isCurrentStepRulesIgnorable = useMemo(() => {
    if (isArray(ignoreRulesForSpecialSteps)) {
      const currentStepLabel = steps[currentStep];

      return ignoreRulesForSpecialSteps.includes(currentStepLabel);
    }
    return undefined;
  }, [
    steps,
    currentStep,
    ignoreRulesForSpecialSteps,
  ]);

  useEffect(() => {
    if (isCreate && isCompletedCurrentStep) {
      executeFunctionIfValid(handleChangeToBackgroundCreate);
      // Eu precisei adicionar esta condição porque o form de Danfe permanece
      // no modo de criação por todas as etapas. Não sei se havia um jeito mais
      // correto de implementar esta mudança.
      if (!skipAutomaticChangeToConsult) {
        handleChangeToConsult();
      }
    }
    if (isBackgroundCreate && !isCompletedCurrentStep) {
      handleChangeToCreate();
      executeFunctionIfValid(handleResetBackgroundMode);
    }
  }, [isCreate, isBackgroundCreate, isCompletedCurrentStep]);

  useEffect(() => {
    if (!isCurrentStepRulesIgnorable) {
      if (isFunction(handleChangeToSubNone)) {
        handleChangeToSubNone();
      }
    }
  }, [
    isCurrentStepRulesIgnorable,
    handleChangeToSubNone,
  ]);

  const childComponentProps = useMemo(() => ({
    initialValues,
    handleSubmit: handleInternalSubmit,
    controlComponentProps: {
      isCreate,
      isConsult,
      isUpdate,
      isSubNone,
      isSubCreate,
      isSubConsult,
      isSubUpdate,
      isLastStep,
      isLastRegistrationStep,
      checkIfIsAlternativeSaveByStep,
      checkIfShowBackButton,
      handleAlternativeSave: handleInternalAlternativeSave,
      handleChangeToUpdate,
      handleCancelUpdate,
    },
  }), [
    initialValues,
    handleInternalSubmit,
    isConsult,
    isUpdate,
    isSubNone,
    isSubCreate,
    isSubConsult,
    isSubUpdate,
    isLastStep,
    isLastRegistrationStep,
    checkIfIsAlternativeSaveByStep,
    handleAlternativeSave,
    handleChangeToUpdate,
    handleCancelUpdate,
  ]);

  const activeStepContentChild = useMemo(() => {
    const filteredValidChildren = hasMoreThanOneStep
      ? children?.filter((child) => isValid(child) && child !== false)
      : children;

    if (hasMoreThanOneStep && isFunction(filteredValidChildren[currentStep])) {
      return filteredValidChildren[currentStep](childComponentProps);
    }

    if (isFunction(filteredValidChildren)) {
      return filteredValidChildren(childComponentProps);
    }

    return filteredValidChildren;
  }, [
    children,
    currentStep,
    hasMoreThanOneStep,
    childComponentProps,
  ]);

  const wizard = useMemo(() => ({
    step: {
      current: currentStep,
      ignoreRules: isCurrentStepRulesIgnorable,
      list: steps,
      completeds: completedSteps,
      conditionals: conditionalSteps,
      optionals: optionalSteps,
      specials: ignoreRulesForSpecialSteps,
    },
    next: handleNextStep,
    previous: handlePreviousStep,
  }), [
    currentStep,
    isCurrentStepRulesIgnorable,
    steps,
    completedSteps,
    conditionalSteps,
    optionalSteps,
    ignoreRulesForSpecialSteps,
    handleNextStep,
    handlePreviousStep,
  ]);

  return (

    <WizardContext.Provider
      value={wizard}
    >

      <Grid container className={classes.root}>
        {hasMoreThanOneStep && (
        <QCXScrollbar
          style={{
            height: '90px',
            cursor: 'grab',
          }}
        >
          <ScrollContainer
            className={`scroll-container ${classes.scrollContainer}`}
            onScroll={() => setIsScroll(true)}
            onEndScroll={handleEndScrollStep}
          >
            <Grid
              item
              container
              justify="flex-start"
              spacing={0}
            >
              <Stepper
                nonLinear={isConsult}
                activeStep={currentStep}
                connector={<QCXCustomStepConnector />}
                className={classes.steps}
                {...restProps}
              >
                {steps.map((label, index) => {
                  if (!isAConditionalStep(label)) {
                    const isCurrentStepOptional = isAnOptionalStep(label);
                    const isCurrentStepCompleted = completedSteps[index];
                    const isCurrentStepActive = currentStep === index;
                    const stepButtonComponent = (
                      <StepButton
                        onClick={handleStep(index)}
                        completed={completedSteps[index]}
                        active={isCurrentStepActive}
                        disabled={
                  (
                    disableSteps
                    || (isUpdate && !isCurrentStepRulesIgnorable)
                      || isSubCreate
                      || (isSubConsult && !isCurrentStepRulesIgnorable)
                      || isSubUpdate
                      || ((!isConsult && !isUpdate)
                        && !completedSteps[index]
                        && !(isCurrentStepEnabled(index))
                        && !(isBackgroundCreate || completedSteps[Math.max(0, index - 1)]))
                  )
                }
                        {...(isCreate && isCurrentStepActive && isCurrentStepOptional
                          ? {
                            optional: (
                              <Typography
                                variant="caption"
                                color="textSecondary"
                              >
                                {t('com.muralis.qcx.opcional')}
                              </Typography>
                            ),
                          } : {}
                )}
                      >
                        <Typography
                          color="textPrimary"
                          style={{
                            fontWeight: completedSteps[index]
                              ? 'bold'
                              : 'normal',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {label}
                        </Typography>
                      </StepButton>
                    );
                    return (
                      <Step key={label}>
                        {!isCurrentStepCompleted && isCurrentStepOptional && (
                        <QCXCustomTooltip
                          onClose={handleStepSkipTooltipClose}
                          open={isTooltipOpen}
                          title={(
                            <Grid
                              item
                              container
                              justify="center"
                              alignItems="center"
                              direction="column"
                            >
                              <Typography
                                align="center"
                                variant="subtitle2"
                              >
                                {t('com.muralis.qcx.essaEtapaOpcional')}
                              </Typography>
                              <QCXButton
                                variant="contained"
                                size="small"
                                color="primary"
                                onClick={handleStep(index + 1)}
                              >
                                {t('com.muralis.qcx.pular')}
                              </QCXButton>
                            </Grid>
                    )}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          interactive
                          arrow
                        >
                          {stepButtonComponent}
                        </QCXCustomTooltip>
                        )}
                        {(isCurrentStepCompleted || !isCurrentStepOptional) && stepButtonComponent}
                      </Step>
                    );
                  }
                  return undefined;
                })}
              </Stepper>
              <Fade in={isScroll} timeout={350}>
                <div className={classes.scrollShadow} />
              </Fade>
            </Grid>
          </ScrollContainer>
        </QCXScrollbar>
        )}
        {isStep && (
        <div style={{ margin: '0 auto', display: 'flex' }}>
          <button
            type="button"
            className={`${classes.buttonStepActive} ${classes.buttonStep}`}
            onClick={handleStepButtonLeft}
            aria-label="step"
            ref={scrollButtonLeft}
          />
          <button
            type="button"
            className={classes.buttonStep}
            onClick={handleStepButtonRight}
            aria-label="step"
            ref={scrollButtonRight}
          />
        </div>
        )}

        <Grid item container className={classes.content}>
          {
            activeStepContentChild
          }
        </Grid>
      </Grid>
    </WizardContext.Provider>

  );
}
