import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import QCXFinalFormWizard from '../../../../../../shared-components/final-form-wizard/QCXFinalFormWizard';
import { isValid } from '../../../../../../utils/general/general-utils';
import { parseToNumber } from '../../../../../../utils/general/parse-utils';
import useQueryParam from '../../../../../../utils/hooks/router/useQueryParam';
import {
  isNoneSubMode,
  isSubConsultMode,
  isSubCreateMode,
  isSubUpdateMode,
} from '../../../../../../utils/store/store-utils';
import { selectSubMode } from '../../api/dadosEmbarqueSelectors';
import {
  changeToSubBackgroundConsultMode,
  changeToSubConsultMode,
  changeToSubCreateMode,
  changeToSubUpdateMode,
  resetSubBackgroundMode,
  resetSubMode,
} from '../../api/dadosEmbarqueSlice';
import DadosContainerStepForm from './steps/DadosContainersStepForm';
import DadosEmbarqueBasicasStepForm from './steps/DadosEmbarqueBasicasStepForm';
import CamposAdicionaisStepForm from '../../../../../../shared-components/dados-embarque/form-steps/CamposAdicionaisStepForm';

export default function QCXDadosEmbarqueWizardFinalForm({
  isCreate,
  isConsult,
  isUpdate,
  isBackgroundCreate,
  isSubBackgroundCreate,
  isSubBackgroundConsult,
  isSubBackgroundUpdate,
  handleSubmit,
  handleAlternativeSave,
  model,
  handleChangeModel,
  handleChangeToCreate,
  handleChangeToConsult,
  handleChangeToUpdate,
  handleCancelUpdate,
  handleChangeToBackgroundCreate,
  handleResetBackgroundMode,
  handleMetadataUpdate,
  children,
  ...restProps
}) {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const stepQueryParam = useQueryParam('step');
  const subMode = useSelector(selectSubMode);

  const stepNumberQueryParam = useMemo(
    () => (isValid(stepQueryParam) ? parseToNumber(stepQueryParam) - 1 : undefined),
    [stepQueryParam]
  );

  const isSubNone = useMemo(() => isNoneSubMode(subMode), [subMode]);

  const isSubCreate = useMemo(() => isSubCreateMode(subMode), [subMode]);

  const isSubConsult = useMemo(() => isSubConsultMode(subMode), [subMode]);

  const isSubUpdate = useMemo(() => isSubUpdateMode(subMode), [subMode]);

  const handleChangeToSubCreate = useCallback(() => {
    dispatch(changeToSubCreateMode());
  }, []);

  const handleChangeToSubConsult = useCallback(() => {
    dispatch(changeToSubConsultMode());
  }, []);

  const handleChangeToSubUpdate = useCallback(() => {
    dispatch(changeToSubUpdateMode());
  }, []);

  const handleChangeToSubNone = useCallback(() => {
    if (isSubBackgroundCreate) {
      handleChangeToSubCreate();
      dispatch(resetSubBackgroundMode());

      return;
    }

    if (isSubBackgroundConsult) {
      handleChangeToSubConsult();
      dispatch(resetSubBackgroundMode());

      return;
    }
    if (isSubBackgroundUpdate) {
      handleChangeToSubUpdate();
      dispatch(changeToSubBackgroundConsultMode());

      return;
    }

    dispatch(resetSubMode());
  }, [
    isSubBackgroundCreate,
    isSubBackgroundConsult,
    isSubBackgroundUpdate,
    handleChangeToSubCreate,
    handleChangeToSubConsult,
    handleChangeToSubUpdate,
  ]);

  // const formPaginationControlProps = useMemo(() => ({
  //   autoLoadItem: (
  //     isCalculadaStatus(model?.calculada)
  //     && (
  //       isSubNone
  //       || isSubConsult
  //       || isSubUpdate
  //       || isSubBackgroundCreate
  //     )
  //   ),
  // }), [
  //   model,
  //   isSubNone,
  //   isSubConsult,
  //   isSubBackgroundCreate,
  // ]);

  // Não tenho certeza do que isso estava fazendo
  // useEffect(() => {
  //   const handleQueryParamsMetadataUpdate = () => {
  //     if (isFunction(handleMetadataUpdate)) {
  //       if (isValid(tabNumberQueryParam)) {
  //         handleMetadataUpdate(
  //           'tab',
  //           tabNumberQueryParam
  //         );
  //       }

  //       if (isValid(itemNumberQueryParam)) {
  //         handleMetadataUpdate(
  //           'item',
  //           itemNumberQueryParam
  //         );
  //         return;
  //       }

  //       if (isValid(mercadoria?.item)) {
  //         handleMetadataUpdate(
  //           'item',
  //           mercadoria?.item
  //         );
  //       }
  //     }
  //   };

  //   handleQueryParamsMetadataUpdate();
  // }, [
  //   mercadoria,
  //   tabNumberQueryParam,
  //   itemNumberQueryParam,
  //   handleMetadataUpdate,
  // ]);

  const wizardSteps = useMemo(
    () => [
      t('com.muralis.qcx.basicas'),
      t('com.muralis.qcx.dadosEmbarque.dadosContainersTitulo'),
      t('com.muralis.qcx.dadosEmbarque.camposAdicionaisTitulo'),
    ],
    []
  );

  return (
    <>
      <QCXFinalFormWizard
        steps={wizardSteps}
        defaultStep={stepNumberQueryParam}
        initialValues={model}
        handleChangeValues={handleChangeModel}
        isCreate={isCreate}
        isConsult={isConsult}
        isUpdate={isUpdate}
        isBackgroundCreate={isBackgroundCreate}
        isSubNone={isSubNone}
        isSubCreate={isSubCreate}
        isSubConsult={isSubConsult}
        isSubUpdate={isSubUpdate}
        handleSubmit={handleSubmit}
        handleAlternativeSave={handleAlternativeSave}
        handleChangeToCreate={handleChangeToCreate}
        handleChangeToBackgroundCreate={handleChangeToBackgroundCreate}
        handleChangeToConsult={handleChangeToConsult}
        handleChangeToUpdate={handleChangeToUpdate}
        handleCancelUpdate={handleCancelUpdate}
        handleResetBackgroundMode={handleResetBackgroundMode}
        handleChangeToSubNone={handleChangeToSubNone}
        handleMetadataUpdate={handleMetadataUpdate}
        {...restProps}
      >
        {(formProps) => <DadosEmbarqueBasicasStepForm key="dados-embarque-form" {...formProps} />}
        {(formProps) => <DadosContainerStepForm key="dados-container-form" {...formProps} />}
        {(formProps) => <CamposAdicionaisStepForm key="campos-adicionais-form" isConsult={isConsult} {...formProps} />}
      </QCXFinalFormWizard>
      {children}
    </>
  );
}
