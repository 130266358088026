import { Grid } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import QCXFinalForm from '../../shared-components/final-form/QCXFinalForm';
import QCXFinalTextField from '../../shared-components/final-text-field/QCXFinalTextField';
import { required as requiredValidator } from '../../utils/validators/field/validator';
import QCXSelectContaContabilAutocomplete from '../../shared-components/select-conta-contabil/QCXSelectContaContabilAutocomplete';
import QCXFinalNumericIntegerField from '../../shared-components/final-numeric-integer-field/QCXFinalNumericIntegerField';
import QCXFinalSelectTipoPlanoContaAutocomplete from '../../shared-components/final-select-tipo-plano-conta/QCXFinalSelectTipoPlanoContaAutocomplete';
import QCXFinalSelectTipoModoPlanoContaAutocomplete from '../../shared-components/final-select-modo-plano-conta/QCXFinalSelectTipoModoPlanoContaAutocomplete';

export default function QCXPlanoContaForm({
  isConsult,
  isUpdate,
  initialValues,
  handleSubmit,
  handleChangeToUpdate,
  handleCancelUpdate,
  ...restProps
}) {
  const { t } = useTranslation();

  return (
    <>
      <QCXFinalForm
        isConsult={isConsult}
        isUpdate={isUpdate}
        initialValues={initialValues}
        handleSubmitValues={handleSubmit}
        handleChangeToUpdate={handleChangeToUpdate}
        handleCancelUpdate={handleCancelUpdate}
        {...restProps}
      >
        {({ values }) => (
          <>
            {(isConsult || isUpdate) && (
              <Grid item xs={12} sm={2} md={2}>
                <QCXFinalTextField
                  id="text-field-code"
                  key="text-field-code"
                  name="id"
                  label={t('com.muralis.qcx.codigo')}
                  disabled
                />
              </Grid>
            )}
            <Grid
              item
              xs={12}
              sm={isConsult || isUpdate ? 5 : 6}
              md={isConsult || isUpdate ? 5 : 6}
            >
              <QCXFinalTextField
                name="description"
                label={t('com.muralis.qcx.descricao')}
                maxLength={40}
                disabled={isConsult}
                validate={requiredValidator}
                required
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={isConsult || isUpdate ? 3 : 4}
              md={isConsult || isUpdate ? 3 : 4}
            >
              <QCXFinalTextField
                name="conta"
                label={t('com.muralis.qcx.empresa.contaContabil')}
                maxLength={20}
                disabled={isConsult}
                validate={requiredValidator}
                required
              />
            </Grid>
            <Grid item xs={12} sm={2} md={2}>
              <QCXFinalNumericIntegerField
                id="text-grau-field"
                key="text-grau-field"
                name="grau"
                label={t('com.muralis.qcx.grau')}
                allowNegative={false}
                maxLength={3}
                validate={requiredValidator}
                disabled={isConsult}
                required
              />
            </Grid>
            <Grid item xs={12} sm={5} md={5}>
              <QCXSelectContaContabilAutocomplete
                id="select-field-conta-contabil-superior"
                key="select-field-conta-contabil-superior"
                name="superior.id"
                label={t('com.muralis.qcx.empresa.contaContabilSuperio')}
                initialValues={values}
                disabled={isConsult}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
              <QCXFinalSelectTipoPlanoContaAutocomplete
                id="select-tipo-plano-conta-field"
                key="select-tipo-plano-conta-field"
                name="tipo"
                label={t('com.muralis.qcx.tipo')}
                initialValues={values}
                fieldProps={{ validate: requiredValidator }}
                disabled={isConsult}
                required
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <QCXFinalSelectTipoModoPlanoContaAutocomplete
                id="select-modo-plano-conta-field"
                key="select-modo-plano-conta-field"
                name="modo"
                label={t('com.muralis.qcx.modo')}
                initialValues={values}
                fieldProps={{ validate: requiredValidator }}
                disabled={isConsult}
                required
              />
            </Grid>
          </>
        )}
      </QCXFinalForm>
    </>
  );
}
