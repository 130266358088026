import { Grid } from '@material-ui/core';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import QCXFinalForm from '../../shared-components/final-form/QCXFinalForm';
import QCXFinalTextField from '../../shared-components/final-text-field/QCXFinalTextField';
import QCXSelectMoedaAutocomplete from '../../shared-components/final-select-moeda-field/QCXSelectMoedaAutocomplete';
import { isCurrentOrPastDate, maxValue, required as requiredValidator } from '../../utils/validators/field/validator';
import QCXFinalDatePickerField from '../../shared-components/final-date-picker-field/QCXFinalDatePickerField';
import QCXFinalNumericDecimalField from '../../shared-components/final-numeric-decimal-field/QCXFinalNumericDecimalField';
import QCXFinalPercentualField from '../../shared-components/final-percentual-field/QCXFinalPercentualField';
import { normalizeNumeral, unnormalizeNumeral } from '../../utils/general/general-utils';
import { formatBrazilianNumericDecimal } from '../../utils/hooks/form/field/formatters';
import useValidators from '../../utils/hooks/useValidators';

export default function QCXCotacaoMoedaForm({
  initialValues,
  handleSubmit,
  handleChangeToUpdate,
  handleCancelUpdate,
  isConsult,
  isUpdate,
  ...restProps
}) {
  const { t } = useTranslation();

  const handleCambioOnBlur = (form, values) => () => {
    if (values?.cambio) {
      const cambio = Number(normalizeNumeral(values?.cambio));
      const percentualMarkup = Number(normalizeNumeral(values?.percentualMarkup));

      const valorTaxaAtualizda = cambio + ((cambio * percentualMarkup) / 100);

      form.change('taxaAtualizada', unnormalizeNumeral(valorTaxaAtualizda, formatBrazilianNumericDecimal(5)));
    } else {
      form.change('taxaAtualizada', '');
    }
  };

  const handlePercentualMarkupOnBlur = (form, values) => () => {
    if (values?.cambio) {
      const cambio = Number(normalizeNumeral(values?.cambio));
      const percentualMarkup = Number(normalizeNumeral(values?.percentualMarkup));

      const valorTaxaAtualizda = (cambio + ((cambio * percentualMarkup) / 100)).toFixed(5);

      form.change('taxaAtualizada', unnormalizeNumeral(valorTaxaAtualizda, formatBrazilianNumericDecimal(5)));
    } else {
      form.change('taxaAtualizada', '');
    }
  };

  const dataValidators = useMemo(() => (
    useValidators([
      requiredValidator,
      isCurrentOrPastDate,
    ])
  ), []);

  const taxaCambialValidators = useMemo(() => (
    useValidators([
      requiredValidator,
      maxValue(9.99999),
    ])
  ), []);

  return (
    <>
      <QCXFinalForm
        isConsult={isConsult}
        isUpdate={isUpdate}
        initialValues={initialValues}
        handleSubmitValues={handleSubmit}
        handleChangeToUpdate={handleChangeToUpdate}
        handleCancelUpdate={handleCancelUpdate}
        {...restProps}
      >
        {({ form, values }) => (
          <>
            {(isConsult || isUpdate) && (
              <Grid item xs={12} sm={2} md={4}>
                <QCXFinalTextField
                  name="id"
                  label={t('com.muralis.qcx.codigo')}
                  disabled
                />
              </Grid>
            )}
            <Grid item xs={12} sm={6} md={4}>
              <QCXSelectMoedaAutocomplete
                name="moeda.id"
                label={t('com.muralis.qcx.moeda.label')}
                fieldProps={{ validate: requiredValidator }}
                disabled={isConsult}
                initialValues={values}
                required
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={isConsult || isUpdate ? 4 : 6}
              md={4}
            >
              <QCXFinalDatePickerField
                id="date-picker-data-field"
                key="date-picker-data-field"
                name="data"
                label={t('com.muralis.qcx.cotacaoMoeda.dataCotacao')}
                validate={!isConsult && dataValidators}
                disabled={isConsult}
                disableFuture
                required
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <QCXFinalNumericDecimalField
                id="numeric-decimal-taxa-cambial-field"
                key="numeric-decimal-taxa-cambial-field"
                name="cambio"
                label={t('com.muralis.qcx.cotacaoMoeda.taxaCambial')}
                disabled={isConsult}
                validate={taxaCambialValidators}
                onBlur={handleCambioOnBlur(form, values)}
                scale={5}
                required
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <QCXFinalPercentualField
                id="percentual-markup-field"
                key="percentual-markup-field"
                name="percentualMarkup"
                label={t('com.muralis.qcx.markup')}
                validate={requiredValidator}
                disabled={isConsult}
                onBlur={handlePercentualMarkupOnBlur(form, values)}
                required
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <QCXFinalTextField
                id="numeric-decimal-taxa-atualizada-field"
                key="numeric-decimal-taxa-atualizada-field"
                name="taxaAtualizada"
                label={t('com.muralis.qcx.taxaAtualizada')}
                disabled
              />
            </Grid>
          </>
        )}
      </QCXFinalForm>
    </>
  );
}
