import { useCallback, useEffect, useMemo, useState } from 'react';
import i18n from '../../../../i18n';
import { isFunction } from 'lodash';

export type ConfigurePopUpProps = {
  primaryConfirmCallback: (data: any, refreshCallback?: (args: any) => void) => void;
  secondaryConfirmCallback?: (data: any) => void;
  initialOptions?: { title?: string; message?: string; endMessage?: string };
};

export type OnOpenPopUpCallback = () => { options: any; cleanUp?: () => void };

export default function useConfigurePopUp({
  primaryConfirmCallback,
  secondaryConfirmCallback,
  initialOptions,
}: ConfigurePopUpProps) {
  const DEFAULT_CONFIG = {
    isPopUpOpen: false,
    shouldRunPrimaryConfirmCallback: false,
    shouldRunSecondaryConfirmCallback: false,
    title: i18n.t('com.muralis.qcx.acoes.confirmarOperacao'),
    message: i18n.t('com.muralis.qcx.mensagem.confirmarOperacaoMensagem'),
  };

  const [config, setConfig] = useState({
    ...DEFAULT_CONFIG,
    ...initialOptions,
  });
  const [savedArgs, setSavedArgs] = useState([]);
  const [cleanUpFn, setCleanUpFn] = useState<() => void>();

  const confirmPrimaryAction = useCallback(() => {
    setConfig((previousOperation) => ({
      ...previousOperation,
      shouldRunPrimaryConfirmCallback: true,
    }));
  }, [setConfig]);

  const confirmSecondaryAction = useCallback(() => {
    setConfig((previousOperation) => ({
      ...previousOperation,
      shouldRunSecondaryConfirmCallback: true,
    }));
  }, [setConfig]);

  const reset = useCallback(() => {
    setSavedArgs([]);

    setConfig((previousOperation) => ({
      ...previousOperation,
      isPopUpOpen: false,
      shouldRunPrimaryConfirmCallback: false,
      shouldRunSecondaryConfirmCallback: false,
    }));

    if (isFunction(cleanUpFn)) {
      cleanUpFn();

      setCleanUpFn(undefined);
    }
  }, [setConfig, setSavedArgs, cleanUpFn]);

  const openPopUpCallback = useCallback(
    (payload: { onOpenCallback?: OnOpenPopUpCallback; args?: any }) => {
      const { onOpenCallback, args } = payload || { onOpenCallback: undefined, args: undefined };

      setSavedArgs(args);

      const { options, cleanUp } = onOpenCallback ? onOpenCallback() : { options: {}, cleanUp: () => {} };

      setConfig((previousOperation) => ({
        ...previousOperation,
        ...options,
        isPopUpOpen: true,
      }));

      if (isFunction(cleanUp)) {
        setCleanUpFn(cleanUp);
      }
    },
    [setConfig, setSavedArgs, setCleanUpFn]
  );

  const configObject = useMemo(
    () => ({
      ...config,
      confirmPrimaryAction,
      confirmSecondaryAction,
      reset,
    }),
    [config, confirmPrimaryAction, confirmSecondaryAction, reset]
  );

  useEffect(() => {
    if (config?.shouldRunPrimaryConfirmCallback) {
      primaryConfirmCallback({ ...savedArgs });
      reset();
    }
  }, [config.shouldRunPrimaryConfirmCallback]);

  useEffect(() => {
    if (config?.shouldRunSecondaryConfirmCallback) {
      if (secondaryConfirmCallback == undefined) {
        throw 'Você está tentando chamar a função secundaria de um popUp, mas ela não foi definida!';
      }

      secondaryConfirmCallback({ ...savedArgs });
      reset();
    }
  }, [config.shouldRunSecondaryConfirmCallback]);

  return [openPopUpCallback, configObject] as [
    ({ onOpenCallback, args }: { onOpenCallback?: OnOpenPopUpCallback | undefined; args?: any }) => void,
    typeof configObject
  ];
}
