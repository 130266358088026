import React from 'react';
import { ThemeProvider } from '@material-ui/core';
import quickComexTheme from '../../theme';

export default function QCXThemeProvider({ children }) {
  return (
    <ThemeProvider theme={quickComexTheme}>
      {children}
    </ThemeProvider>
  );
}
