import { createFilterOptions } from '@material-ui/lab/useAutocomplete';
import _, { isFunction } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllAsync, selectTiposDeclaracao } from '../../features/tipo-declaracao/tipoDeclaracaoSlice';
import QCXAutocompleteSelect from '../autocomplete-select/QCXAutocompleteSelect';

export default function QCXSelectTipoDeclaracaoAutocomplete({
  label,
  name,
  initialValues,
  configureFilterPredicate,
  ...restProps
}) {
  const dispatch = useDispatch();

  const list = useSelector(selectTiposDeclaracao);

  const [defaultValue, setDefaultValue] = useState('');

  /* TODO: Criar endpoint para filtrar Tipos de Declaração para IMPORTAÇÃO */
  const filteredList = useMemo(() => {
    if (isFunction(configureFilterPredicate)) {
      const filterPredicate = configureFilterPredicate();
      const filteredValues = list.filter(filterPredicate);

      return filteredValues;
    }

    return list;
  }, [list, configureFilterPredicate]);

  useEffect(() => {
    dispatch(fetchAllAsync());
  }, []);

  useEffect(() => {
    const value = filteredList.find((item) => item?.id === _.get(initialValues, name));
    if (value) {
      setDefaultValue(value);
    } else {
      setDefaultValue({ id: null, description: '' });
    }
  }, [list, initialValues]);

  const filterOptions = createFilterOptions({
    stringify: (option) => `${option?.code} - ${option?.description}`,
  });

  return (
    <QCXAutocompleteSelect
      label={label}
      id={name}
      name={name}
      options={filteredList}
      filterOptions={filterOptions}
      defaultValue={defaultValue}
      getOptionLabel={(item) => (
        item?.code && item?.description
          ? `${item?.code} - ${item?.description}`
          : ''
      )}
      {...restProps}
    />
  );
}
