import _ from 'lodash';
import {
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  CLOSED_STATUS,
  isOpenStatus,
} from '../../utils/hooks/form/dialog/formDialogUtils';
import QCXFinalFormDialog from '../../shared-components/dialog/QCXFinalFormDialog';
import QCXFormListenerManager from '../../shared-components/form-listener/QCXFormListenerManager';
import QCXSelectFollowUpDestinoAutocompleteField from '../../shared-components/select-follow-up/QCXSelectFollowUpDestinoAutocompleteField';
import { followUpDestinoFaturamentoActions } from '../../features/follow-up-destino-faturamento/followUpDestinoFaturamentoSlice';
import QCXSelectFollowUpOrigemAutocompleteField from '../../shared-components/select-follow-up/QCXSelectFollowUpOrigemAutocompleteField';
import { followUpOrigemFaturamentoActions } from '../../features/follow-up-origem-faturamento/followUpOrigemFaturamentoSlice';

const useStyles = makeStyles(() => ({
  rootPaperFullWidth: {
    width: '100% !important',
  },
}));

export default function QCXFaturamentoTransferirSaldoDialogForm({
  handleOnSubmit,
  handleFormStatus,
  formDialogStatus,
  initialValues,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = useStyles();

  const isFormDialogOpen = useMemo(() => (
    isOpenStatus(formDialogStatus)
  ), [formDialogStatus]);

  const handleCloseFormDialog = useCallback(() => {
    handleFormStatus(CLOSED_STATUS);
    (_.debounce(() => {
      dispatch(followUpDestinoFaturamentoActions.resetList());
      dispatch(followUpOrigemFaturamentoActions.resetList());
    }, 200))();
  }, [
    handleFormStatus,
  ]);

  useEffect(() => (
    () => {
      dispatch(followUpDestinoFaturamentoActions.resetList());
      dispatch(followUpOrigemFaturamentoActions.resetList());
    }
  ), []);

  return (
    <QCXFinalFormDialog
      key="form-dialog-transferir-saldo"
      id="form-dialog-transferir-saldo"
      title={t('com.muralis.qcx.transferirSaldo.label')}
      content={t('com.muralis.qcx.preencherCamposContinuar')}
      dialogActionsProps={{
        button: {
          submit: {
            description: t('com.muralis.qcx.acoes.transferir'),
            size: 'small',
          },
          cancel: {
            description: t('com.muralis.qcx.acoes.cancelar'),
            size: 'small',
          },
        },
      }}
      // maxWidth="sm"
      fullWidth
      open={isFormDialogOpen}
      initialValues={initialValues}
      onSubmit={handleOnSubmit}
      onClose={handleCloseFormDialog}
      classes={{
        paperFullWidth: classes.rootPaperFullWidth,
      }}
    >
      {({ values, form }) => (
        <>
          <QCXFormListenerManager
            form={form}
            values={values}
            onChangeListeners={[]}
          />
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
            >
              <QCXSelectFollowUpOrigemAutocompleteField
                key="select-autocomplete-processo-origem-field"
                id="select-autocomplete-processo-origem-field"
                name="origem.id"
                label={t('com.muralis.qcx.numeroProcessoOrigem')}
                controlled
                required
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
            >
              <QCXSelectFollowUpDestinoAutocompleteField
                key="select-autocomplete-processo-destino-field"
                id="select-autocomplete-processo-destino-field"
                name="destino.id"
                label={t('com.muralis.qcx.numeroProcessoDestino')}
                controlled
                required
              />
            </Grid>
          </Grid>
        </>
      )}
    </QCXFinalFormDialog>
  );
}
