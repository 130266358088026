import React from 'react';
import ProcessosInfoForm from './ProcessosInfoForm';
import EtapaForms from './EtapaForms';
import QCXFinalMultilineInputWithChips from '../QCXFinalMultilineInputWIthChips';

function InformacoesSection() {
  return (
    <>
      <QCXFinalMultilineInputWithChips name="processos" label="Números dos Processos:" />
      <ProcessosInfoForm />
      <EtapaForms />
    </>
  );
}

export default InformacoesSection;
