import { useMemo } from 'react';
import usePaginatorActionState from './usePaginatorActionState';
import usePaginatorController from './usePaginatorController';
import usePaginatorState from './usePaginatorState';

export default function usePaginator() {
  const paginatorState = usePaginatorState();
  const paginatorActionState = usePaginatorActionState();
  const paginatorController = usePaginatorController();

  const paginator = useMemo(() => ({
    page: paginatorState.page,
    defaultPage: paginatorState.defaultPage,
    usedDefaultPage: paginatorState.usedDefaultPage,
    action: paginatorActionState,
    metadata: paginatorState.metadata,
    next: paginatorController.next,
    previous: paginatorController.previous,
    setPage: paginatorController.setPage,
    markDefaultPageAsUsed: paginatorController.markDefaultPageAsUsed,
    getMetadata: paginatorController?.getMetadata,
    changeMetadata: paginatorController.changeMetadata,
  }), [
    paginatorState,
    paginatorActionState,
    paginatorController,
  ]);

  return paginator;
}
