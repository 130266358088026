import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { Button, Grid } from '@mui/material';
import { useKeycloak } from '@react-keycloak/web';
import saveAs from 'file-saver';
import { SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllAsync as fetchAllClientesAsync, selectClientes } from '../../../../features/cliente/clienteSlice';
import { selectUnidadeSelecionada } from '../../../../features/usuario-logado/usuarioLogadoSlice';
import ErrorBadge from '../../../common/components/forms/errorBadge';
import FormHeaderMedium from '../../../common/components/forms/formHeaderMedium';
import SelectStyled from '../../../common/components/input/selectStyled';
import LoadingIndicator from '../../../common/components/loadingIndicator';
import { QueriesTypes, useGetRequest } from '../../../common/hooks/queries';
import { useUnidadeNegocioGuard } from '../../../common/hooks/useUnidadeNegocioGuard';
import PageLayout from '../../../common/layouts/pageLayout';
import { ClientData } from '../../../common/types/clientData';
import { FollowUp } from '../../../common/types/followUp';
import { KEYCLOAK_TOKEN_TIMEOUT } from '../../../../App';

const RelatorioAssociatedPage = () => {
  useUnidadeNegocioGuard();
  const dispatch = useDispatch();
  const keycloak = useKeycloak();
  const [error, setError] = useState<string | undefined>();
  const [processo, setProcesso] = useState<string | undefined>(undefined);
  const [cliente, setCliente] = useState<number | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const clientes: ClientData[] = useSelector(selectClientes);
  const selectedUnidade: number = useSelector(selectUnidadeSelecionada);
  const {
    data: followUpsData,
    isLoading: isFollowUpsDataLoading,
    isError: isFollowUpsDataError,
  } = useGetRequest(QueriesTypes.FollowUps);

  const processos = useMemo(() => {
    if (!followUpsData || typeof followUpsData != 'object') {
      return [];
    }

    const processosFilteredByClient = followUpsData.filter((followUp: FollowUp) => followUp.importador.id === cliente);

    const processos: string[] = processosFilteredByClient.map((followUp: FollowUp) => followUp.numero);

    // Remove duplicates
    return processos.filter((processo, index) => {
      return processos.indexOf(processo) === index;
    });
  }, [followUpsData, cliente]);

  const handleReportGeneration = () => {
    keycloak.keycloak.updateToken(KEYCLOAK_TOKEN_TIMEOUT);
    fetch(
      `${process.env.REACT_APP_RELATORIOS_API_BASE_URL}/${process.env.REACT_APP_RELATORIOS_API_ASSOCIATED_ENDPOINT}?importador=${cliente}&processo=${processo}`,
      {
        headers: {
          Authorization: `Bearer ${keycloak.keycloak.token}`,
          quickcomexTenant: selectedUnidade.toString(),
        },
      }
    )
      .then(async (response) => {
        if (!response.ok || response.status === 204) {
          const data = await response.json();
          setError(data.message);
          return Promise.reject(data.message);
        } else {
          return response.blob();
        }
      })
      .then((blob) => {
        saveAs(blob, 'associated.xlsm');
        clearData();
      })
      .catch((error) => {
        console.error(error);
        clearData();
      });
  };

  const handleSubmit = () => {
    setError(undefined);
    setIsLoading(true);
    handleReportGeneration();
  };

  const clearData = () => {
    setCliente(undefined);
    setProcesso(undefined);
    setIsLoading(false);
  };

  const handleClienteChange = (
    event: SyntheticEvent<Element, Event>,
    value: {
      id: string | number | undefined;
      value: string | undefined;
    } | null
  ) => {
    const selectedClient = clientes.find((clientes) => clientes.id === value?.id);
    setCliente(selectedClient?.id);
    setProcesso(undefined);
  };

  useEffect(() => {
    // Ignore typescript due to older version of redux-toolkit
    // TODO: Update redux-toolkit to typescript
    /* @ts-ignore */
    dispatch(fetchAllClientesAsync());
  }, []);

  return (
    <PageLayout title={'Relatório Associated'} icon={<InsertDriveFileIcon color={'secondary'} />}>
      {isLoading || isFollowUpsDataLoading ? (
        <LoadingIndicator message={isLoading ? 'Gerando relatório' : 'Carregando processos...'} />
      ) : (
        <Grid container spacing={2}>
          <FormHeaderMedium>Selecione as informações para gerar o relatório</FormHeaderMedium>
          <Grid item sm={6}>
            <SelectStyled
              options={clientes.map((cliente, index) => ({
                id: cliente.id,
                value: cliente.description + ' - ' + cliente.pessoa?.cnpj,
              }))}
              onChangeAction={handleClienteChange}
              label={'Cliente'}
            ></SelectStyled>
          </Grid>
          <Grid item sm={6}>
            <SelectStyled
              options={processos.map((processo, index) => ({
                id: index,
                value: processo,
              }))}
              label={'Processo'}
              onChangeAction={(event, newValue) => {
                setProcesso(newValue?.value);
              }}
            />
          </Grid>
          <ErrorBadge error={error} />
          <Grid item sm={12} textAlign={'right'}>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleSubmit}
              disabled={!cliente || !processo || isLoading}
            >
              Gerar
            </Button>
          </Grid>
        </Grid>
      )}
    </PageLayout>
  );
};

export default RelatorioAssociatedPage;
