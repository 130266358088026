import { get } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setErrorFeedback, setWarningFeedback } from '../../../../features/feedback/feedbackSlice';
import { ERROR_SEVERITY } from '../../../../features/severity';

export default function useRequiredValidator({ options }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleFeedback = useCallback(({ message }) => {
    if (options?.severity === ERROR_SEVERITY) {
      dispatch(
        setErrorFeedback({
          message: t('com.muralis.qcx.erroMensagem', { mensagem: message }),
        })
      );
      return;
    }

    dispatch(
      setWarningFeedback({
        message: t('com.muralis.qcx.atencaoMensagem', { mensagem: message }),
      })
    );
  }, [options]);

  const validate = useCallback((fields, values) => {
    for (let index = 0; index < fields.length; index += 1) {
      const field = fields[index];

      const propValue = get(
        values,
        field?.name
      );

      if (!propValue) {
        const message = t('com.muralis.qcx.validacao.campoObrigatorioVinculo', { label: field?.label });

        if (options?.showFeedback) {
          handleFeedback({
            message,
          });
        }

        return {
          valid: false,
        };
      }
    }

    return {
      valid: true,
    };
  }, [
    options,
    handleFeedback,
  ]);

  const requiredValidator = useMemo(() => ({
    validate,
  }), [validate]);

  return requiredValidator;
}
