import { isEmpty } from 'lodash';
import moment from 'moment';
import {
  normalizeNumeral,
  normalizeZipCode,
  normalizeDigits,
  padStart,
  unnormalizeNumeral,
  isWeekend,
} from '../../general/general-utils';
import { forceParseToNumber, parseToString } from '../../general/parse-utils';
import i18n from '../../../i18n';
import { numberOnly } from '../../hooks/form/field/parsers';

const required = (value) =>
  value && String(value).trim().length > 0 ? undefined : i18n.t('com.muralis.qcx.validacao.campoObrigatorio');
const requiredNumberNotZero = (value) =>
  forceParseToNumber(value) ? undefined : i18n.t('com.muralis.qcx.validacao.campoObrigatorio');
const minLengthChecker = (min) => (value) => parseToString(value).length >= min ? true : undefined;
const maxLengthChecker = (max) => (value) => parseToString(value).length <= max ? true : undefined;
const minValue = (min) => (value) =>
  normalizeNumeral(value) >= min ? undefined : `Valor mínimo: ${unnormalizeNumeral(min)}`;
const maxValue = (max) => (value) =>
  normalizeNumeral(value) <= max
    ? undefined
    : i18n.t('com.muralis.qcx.validacao.valorMaximo', { valor: unnormalizeNumeral(max) });

const isValidDate = (value) => {
  const date = moment(value);
  if (date.isValid()) {
    return undefined;
  }
  return i18n.t('com.muralis.qcx.validacao.dataInvalida');
};

const isCurrentOrPastDate = (value) => {
  if (value) {
    const date = moment(value);
    if (date.isValid() && date.isSameOrBefore(moment())) {
      return undefined;
    }
    return i18n.t('com.muralis.qcx.validacao.dataInvalida');
  }
  return undefined;
};

const isCurrentOrFutureDate = (value) => {
  if (value) {
    const date = moment(value);
    if (date.isValid() && date.isSameOrAfter(moment(), 'date')) {
      return undefined;
    }
    return i18n.t('com.muralis.qcx.validacao.dataInvalida');
  }
  return undefined;
};

const validCpf = (value) => {
  const cpf = String(value);
  return cpf.length === 11 || cpf.length === 14 ? undefined : i18n.t('com.muralis.qcx.validacao.CPFInvalido');
};

const validCnpj = (value) => {
  const cnpj = String(value);
  return cnpj.length === 14 || cnpj.length === 18 ? undefined : i18n.t('com.muralis.qcx.validacao.CNPJInvalido');
};

const validCpfOrCnpj = (value) => {
  if (!value) {
    return undefined;
  }

  const cpfCnpj = String(value);

  const isCpf = /^[\d]{3}\.?[\d]{3}\.?[\d]{3}-?[\d]{2}$/.test(cpfCnpj) || cpfCnpj.length === 11;
  const isCnpj = /\d{2}\.?\d{3}\.?\d{3}\/?\d{4}-?\d{2}/.test(cpfCnpj) || cpfCnpj.length === 14;

  if (isCpf) {
    return undefined;
  }
  if (!isCpf && cpfCnpj.length < 12) {
    return i18n.t('com.muralis.qcx.validacao.CPFInvalido');
  }
  if (isCnpj) {
    return undefined;
  }
  return i18n.t('com.muralis.qcx.validacao.CNPJInvalido');
};

const validateEmail = (value) => {
  const emailPattern = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g;

  if (emailPattern.test(value)) {
    return undefined;
  }

  return i18n.t('com.muralis.qcx.validacao.emailInformadoValido');
};

const configurePostalCodeValidator = (message) => (min, max) => (value) => {
  const stringValue = String(value);
  return (stringValue.length >= min && stringValue.length <= max) || stringValue.length === 0 ? undefined : message;
};

const validCep = configurePostalCodeValidator(i18n.t('com.muralis.qcx.validacao.CEPInvalido'));

const validPostalCode = configurePostalCodeValidator(i18n.t('com.muralis.qcx.validacao.codigoPostalInvalido'));

const mustBeValidZipCode = (zipCode) =>
  /[0-9]{5}[0-9]{4}/.test(normalizeZipCode(zipCode)) ? undefined : i18n.t('com.muralis.qcx.validacao.zipCodeInvalido');

const validValorMinimo = (value, allValues) => {
  const maximo = normalizeNumeral(allValues?.valor?.maximo);
  const result = !maxValue(maximo)(value);
  return result ? undefined : i18n.t('com.muralis.qcx.validacao.valorMinimoSuperiorMaximo');
};

const validValorMaximo = (value, allValues) => {
  const minimo = normalizeNumeral(allValues?.valor?.minimo);
  const result = !minValue(minimo)(value);
  return result ? undefined : i18n.t('com.muralis.qcx.validacao.valorMaximoInferiorMinimo');
};

const configureNumberNotBetweenMinAndMaxValidator = (min, max) => (value) => {
  const valueNumbers = numberOnly(value);
  const valueLength = String(valueNumbers).trim().length;

  if (valueLength > min && valueLength < max) {
    return i18n.t('com.muralis.qcx.validacao.numeroValido');
  }

  return undefined;
};

const validTelefoneLength = (value) => {
  const minTelefoneLength = 8;
  const telefoneLength = String(value).trim().length;
  if (telefoneLength === 0 || telefoneLength >= minTelefoneLength) {
    return undefined;
  }

  return i18n.t('com.muralis.qcx.validacao.telefoneQuantidadeDigitos', { quantidade: minTelefoneLength });
};

const validTelefoneFixoWithDDDLength = (value) => {
  const minTelefoneLength = 10;
  const telefoneLength = String(value).trim().length;

  if (telefoneLength === 0 || telefoneLength >= minTelefoneLength) {
    return undefined;
  }
  return i18n.t('com.muralis.qcx.validacao.numeroMinimoDigitosDDD', { numeroMinimo: minTelefoneLength });
};

const validTelefoneCelularLength = (value) => {
  const minTelefoneLength = 9;
  const telefoneLength = String(value).trim().length;
  if (telefoneLength === 0 || telefoneLength >= minTelefoneLength) {
    return undefined;
  }

  return i18n.t('com.muralis.qcx.validacao.numeroMinimoDigitos', { numeroMinimo: minTelefoneLength });
};

const validTelefoneCelularWithDDDLength = (value) => {
  const minTelefoneLength = 11;
  const telefoneLength = String(value).trim().length;
  if (telefoneLength === 0 || telefoneLength >= minTelefoneLength) {
    return undefined;
  }

  return i18n.t('com.muralis.qcx.validacao.numeroMinimoDDD', { numeroMinimo: minTelefoneLength });
};

const validCode = (pattern) => (value) => {
  const normalizedCode = normalizeDigits(value);
  return normalizedCode !== pattern ? undefined : i18n.t('com.muralis.qcx.validacao.codigoInvalido');
};

const validCFOPCode = (value) => validCode('0000')(value);

const validMoedaCode = (value) => validCode('000')(value);

const validUnidadeDeMedidaCode = (value) => {
  const paddedCode = padStart(value, 3, '0');
  return validCode('000')(paddedCode);
};

const validateAgencia = (value) => {
  if (isEmpty(value)) {
    return undefined;
  }

  const defaultErrorMessage = i18n.t('com.muralis.qcx.validacao.agenciaQuantidadeDigitos');

  const validateMinDigitsWith4 = minLengthChecker(4);

  return validateMinDigitsWith4(value) ? undefined : defaultErrorMessage;
};

const validateContaCorrente = (value) => {
  if (isEmpty(value)) {
    return undefined;
  }

  const defaultErrorMessage = i18n.t('com.muralis.qcx.validacao.contaCorrenteMinimoDigitos');

  const validateMinDigitsWith4 = minLengthChecker(4);

  return validateMinDigitsWith4(value) ? undefined : defaultErrorMessage;
};

const validateNumeroConhecimentoMasterAereo = (value) => {
  if (isEmpty(value)) {
    return undefined;
  }

  const defaultErrorMessage = i18n.t('com.muralis.qcx.validacao.numeroMasterQuantidadeCaracteres');

  const validateMaxDigitsWith11 = minLengthChecker(11);

  return validateMaxDigitsWith11(value) ? undefined : defaultErrorMessage;
};

const validateNumeroConhecimentoMasterAereoExpo = (value) => {
  if (isEmpty(value)) {
    return undefined;
  }

  const defaultErrorMessage = i18n.t('com.muralis.qcx.validacao.numeroMasterQuantidadeCaracteresExpo');

  const validateMaxDigitsWith11 = minLengthChecker(12);

  return validateMaxDigitsWith11(value) ? undefined : defaultErrorMessage;
};

const validateLatitudeLongitude = (value) => {
  if (!value) {
    return undefined;
  }

  const minLatitudeLongitudeLength = 9;

  const validMinLength = minLengthChecker(minLatitudeLongitudeLength);

  return validMinLength(value)
    ? undefined
    : i18n.t('com.muralis.qcx.validacao.numeroMinimoCaracteres', { numero: minLatitudeLongitudeLength });
};

const validateWeekDay = (value) => {
  if (value) {
    const date = moment(value);
    if (isWeekend(date)) {
      return i18n.t('com.muralis.qcx.validacao.dataInvalidaFinalSemana');
    }
    return undefined;
  }
  return undefined;
};

export {
  required,
  mustBeValidZipCode,
  requiredNumberNotZero,
  validCpf,
  validCnpj,
  validCpfOrCnpj,
  validateEmail,
  validCep,
  maxValue,
  minValue,
  isCurrentOrPastDate,
  isCurrentOrFutureDate,
  isValidDate,
  validValorMinimo,
  validValorMaximo,
  validPostalCode,
  configurePostalCodeValidator,
  minLengthChecker,
  validateAgencia,
  validateContaCorrente,
  configureNumberNotBetweenMinAndMaxValidator,
  validTelefoneLength,
  validTelefoneFixoWithDDDLength,
  validTelefoneCelularLength,
  validTelefoneCelularWithDDDLength,
  validCFOPCode,
  validMoedaCode,
  validUnidadeDeMedidaCode,
  validateNumeroConhecimentoMasterAereo,
  validateNumeroConhecimentoMasterAereoExpo,
  validateLatitudeLongitude,
  validateWeekDay,
};
