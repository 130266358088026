const MOTIVO_NACIONALIZACAO_MERCADORIA_ADMITIDA_ANTERIORMENTE_REGIME_ADUANEIRO_ESPECIAL_ATIPICO_CODE = '51';
const MOTIVO_ADMISSAO_REGIME_ADUANEIRO_ESPECIAL_ATIPICO_CODE = '52';

const getMotivoImportacaoSemCoberturaCambialIdBy = (code) => {
  const configureMatcherWith = (list) => {
    const foundMotivoImportacaoSemCoberturaCambial = (
      list.find((motivoImportacaoSemCoberturaCambial) => (
        motivoImportacaoSemCoberturaCambial?.code === code
      ))
    );

    return foundMotivoImportacaoSemCoberturaCambial?.id;
  };

  return configureMatcherWith;
};

export {
  MOTIVO_NACIONALIZACAO_MERCADORIA_ADMITIDA_ANTERIORMENTE_REGIME_ADUANEIRO_ESPECIAL_ATIPICO_CODE,
  MOTIVO_ADMISSAO_REGIME_ADUANEIRO_ESPECIAL_ATIPICO_CODE,
};

export {
  getMotivoImportacaoSemCoberturaCambialIdBy,
};
