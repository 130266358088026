import { useEffect, useMemo } from "react";
import { QueriesTypes, useGetRequest } from "./queries";
import saveAs from 'file-saver';

export const useGenerateDIXMLReport = (idDi: string, tipo:string) => {
  const {
    data,
    isError,
    isLoading,
  } = useGetRequest(QueriesTypes.DeclaracaoImportacaoXMLReport, idDi, `TIPO=${tipo}`);

  return {data};
};
