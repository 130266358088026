import DeleteIcon from "@mui/icons-material/Delete";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import { Button, Grid } from "@mui/material";
import {
  GridActionsCellItem,
  GridColDef,
  GridRowParams,
} from "@mui/x-data-grid";
import { FormEvent, useMemo, useState } from "react";
import DataTable from "../../../common/components/dataTable";
import FormHeader from "../../../common/components/forms/formHeader";
import SelectStyled from "../../../common/components/input/selectStyled";
import TextFieldStyled from "../../../common/components/input/textFieldStyled";
import { useUnidadeNegocioGuard } from "../../../common/hooks/useUnidadeNegocioGuard";
import PageLayout from "../../../common/layouts/pageLayout";
import {
  useAgentesDeCarga,
  useCfops,
  useCorrelationsTypes,
  useCorrespondenciaGeral,
  useCorrespondenciasImportadores,
  useCreateCorrespondenciaGeral,
  useDeleteCorrespondenciaGeral,
  useDespachantes,
  useDespesasReceitas,
  useDispositivosLegais,
  useFabricantesExportadores,
  useLocaisDeEmbarque,
  useTransportadoras,
  useTransportadoresInternacionais,
} from "./totvs.hooks";
import { useLastPathPart } from "../../../common/hooks/useFindPath";
import LoadingIndicator from "../../../common/components/loadingIndicator";
import ErrorBadge from "../../../common/components/forms/errorBadge";

const TotvsCorrespondenciaDetailsPage = () => {
  useUnidadeNegocioGuard();
  const importadorTotvsId = useLastPathPart();
  const correlationsTypes = useCorrelationsTypes();
  const { exportadores, fabricantes } = useFabricantesExportadores();
  const transportadoresInternacionais = useTransportadoresInternacionais();
  const locaisDeEmbarque = useLocaisDeEmbarque();
  const agentesDeCarga = useAgentesDeCarga();
  const dispositivosLegais = useDispositivosLegais();
  const despesasReceitas = useDespesasReceitas();
  const despachantes = useDespachantes();
  const cfops = useCfops();
  const transportadoras = useTransportadoras();
  const [
    correspondenciasImportadores,
    isCorrespondenciasImportadoresLoading,
    isCorrespondenciasImportadoresError,
  ] = useCorrespondenciasImportadores();
  const [createCorrelacaoGeral, isCreating, hasCreateError] =
    useCreateCorrespondenciaGeral();
  const [deleteCorrespondenciaGeral, isDeleting, hasDeleteError] =
    useDeleteCorrespondenciaGeral();
  const [correlationType, setCorrelationType] = useState<string>();
  const [totvsId, setTotvsId] = useState<string>();
  const [selectedOption, setSelectedOption] =
    useState<{
      id: string | number | undefined;
      value: string | undefined;
    } | null>();
  const [name, setName] = useState<string>();
  const [error, setError] = useState<string>();

  const importador = useMemo(() => {
    return correspondenciasImportadores?.find(
      (item) => item.totvsId == importadorTotvsId
    );
  }, [correspondenciasImportadores, importadorTotvsId]);

  const [correspondenciasGerais, isCorrespondenciasGeraisLoading] =
    useCorrespondenciaGeral(importador?.id?.toString() ?? "");

  const options = useMemo(() => {
    switch (correlationType) {
      case "Exportador":
        return exportadores.map((item: any) => ({
          id: item.id,
          value:
            item.pessoaJuridica.nome + " - " + item.endereco.logradouro ?? "",
        }));
      case "Fabricante":
        return fabricantes.map((item: any) => ({
          id: item.id,
          value:
            item.pessoaJuridica.nome + " - " + item.endereco.logradouro ?? "",
        }));
      case "Transportador Internacional":
        return transportadoresInternacionais?.map((item: any) => ({
          id: item.id,
          value: item.pessoaJuridica.nome + " - " + item.code ?? "",
        }));
      case "Local de Embarque":
        return locaisDeEmbarque?.map((item: any) => ({
          id: item.id,
          value: item.description + " - " + item.sigla ?? "",
        }));
      case "Agente de Carga":
        return agentesDeCarga?.map((item: any) => ({
          id: item.id,
          value: item.description + " - " + item.code ?? "",
        }));
      case "Dispositivo Legal de Exoneração do ICMS":
        return dispositivosLegais?.map((item: any) => ({
          id: item.id,
          value: item.description ?? "",
        }));
      case "Local do Incoterm":
        return locaisDeEmbarque?.map((item: any) => ({
          id: item.id,
          value: item.description + " - " + item.sigla ?? "",
        }));
      case "Despesas/Receitas":
        return despesasReceitas?.map((item: any) => ({
          id: item.id,
          value: item.id + " - " + item.description ?? "",
        }));
      case "Despachante":
        return despachantes?.map((item: any) => ({
          id: item.id,
          value: item.nome + " - " + item.registro ?? "",
        }));
      case "CFOP":
        return cfops?.map((item: any) => ({
          id: item.id,
          value: item.code + " - " + item.detalhe ?? "",
        }));
      case "Transportadora":
        return transportadoras?.map((item: any) => ({
          id: item.id,
          value: item.pessoaJuridica.cnpj + " - " + item.description ?? "",
        }));
      default:
        return [];
    }
  }, [
    correlationType,
    exportadores,
    fabricantes,
    transportadoresInternacionais,
    locaisDeEmbarque,
    agentesDeCarga,
    dispositivosLegais,
    despesasReceitas,
    despachantes,
    cfops,
  ]);

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "tipo",
        headerName: "Tipo de Correspondência",
        flex: 1,
        headerClassName: "header-theme",
      },
      {
        field: "nome",
        headerName: "Nome",
        flex: 2,
        headerClassName: "header-theme",
      },
      {
        field: "codigo",
        headerName: "Cód. QuickComex",
        flex: 1,
        headerClassName: "header-theme",
        type: "string",
      },
      {
        field: "codigoTotvs",
        headerName: "Cód. TOTVS/Easy",
        flex: 1,
        headerClassName: "header-theme",
        type: "string",
      },
      {
        field: "actions",
        type: "actions",
        headerName: "Ações",
        headerClassName: "header-theme",
        getActions: (params: GridRowParams) => [
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Deletar"
            color="error"
            onClick={() => {
              deleteCorrespondenciaGeral({ id: params.id as string });
            }}
          />,
        ],
      },
    ],
    []
  );

  const rows = useMemo(() => {
    const tableRows = (correspondenciasGerais ?? []).map((item, index) => ({
      id: item.id,
      tipo: item.tipo,
      nome: item.nome,
      codigo: item.quickcomexId,
      codigoTotvs: item.totvsId,
    }));
    return tableRows;
  }, [correspondenciasGerais]);

  // Check if a correspondencia of the same type and totvsId already exists in correspondenciasGerais
  const checkIfCorrelationExists = () => {
    if (correlationType && totvsId) {
      const exists = correspondenciasGerais?.find(
        (item) =>
          item.tipo === correlationType && item.totvsId === totvsId.toString()
      );
      return exists;
    }
    return false;
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!checkIfCorrelationExists()) {
      setError("");
      createCorrelacaoGeral({
        requestPayload: {
          correspondenciaImportadorId: importador?.id,
          tipo: correlationType,
          totvsId,
          quickcomexId: selectedOption?.id,
          nome: selectedOption?.value,
        },
      });

      setCorrelationType("");
      setTotvsId("");
      setSelectedOption(null);
    } else {
      setError("Já existe uma correspondência com esse tipo e código TOTVS");
    }
  };

  return (
    <PageLayout
      title={"Correspondência de Dados"}
      icon={<HistoryEduIcon color={"secondary"} />}
    >
      {isCorrespondenciasGeraisLoading ? (
        <LoadingIndicator />
      ) : (
        <Grid container spacing={2}>
          <FormHeader marginTop="0">
            {"Correspondências do importador " + importador?.nome}
          </FormHeader>

          <Grid item sm={12}>
            <DataTable
              // onCellDoubleClick={handleDetailsNavigation}
              columns={columns}
              rows={rows}
            ></DataTable>
          </Grid>
          <Grid item sm={12}>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <FormHeader marginTop="0">Nova Correspondência</FormHeader>
                <Grid item sm={12}>
                  <SelectStyled
                    options={
                      correlationsTypes.map((key, index) => ({
                        id: index,
                        value: key,
                      })) ?? []
                    }
                    label={"Tipo de Correspondência"}
                    onChangeAction={(e, value) =>
                      setCorrelationType(value?.value)
                    }
                  ></SelectStyled>
                </Grid>
                <Grid item sm={12}>
                  <TextFieldStyled
                    value={totvsId}
                    required
                    label={`Código ${correlationType ?? ""} TOTVS / Easy`}
                    onChange={(e) => setTotvsId(e.target.value.trim())}
                  ></TextFieldStyled>
                </Grid>
                <Grid item sm={12}>
                  <SelectStyled
                    options={options}
                    label={`Código ${correlationType ?? ""} QuickComex`}
                    onChangeAction={(e, value) => setSelectedOption(value)}
                  ></SelectStyled>
                </Grid>
                <Grid item sm={12} textAlign="right">
                  <Button
                    disabled={!correlationType || !totvsId || !selectedOption}
                    type="submit"
                    variant="contained"
                    color="secondary"
                  >
                    Salvar
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
          {error && <ErrorBadge error={error}></ErrorBadge>}
        </Grid>
      )}
    </PageLayout>
  );
};

export default TotvsCorrespondenciaDetailsPage;
