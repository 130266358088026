import {
  doGetQCXRequest,
  doPostQCXRequest,
  doPutQCXActivateRequest,
  doPutQCXInactivateRequest,
  doPutQCXRequest,
} from '../../utils/api/domain/domain-api-utils';
import {
  DISPOSITIVO_LEGAL_ENDPOINT,
} from '../../utils/api/domain/domain-endpoint-utils';

export async function fetchAll() {
  return doGetQCXRequest([
    DISPOSITIVO_LEGAL_ENDPOINT
  ]);
}

export async function fetchById(id) {
  return doGetQCXRequest([
    DISPOSITIVO_LEGAL_ENDPOINT,
    id,
  ]);
}

export async function register(data, queryParams = []) {
  return doPostQCXRequest(
    [DISPOSITIVO_LEGAL_ENDPOINT],
    data,
    queryParams
  );
}

export async function save(data, queryParams = []) {
  return doPutQCXRequest(
    [DISPOSITIVO_LEGAL_ENDPOINT],
    data,
    queryParams
  );
}

export async function activateById(id) {
  return doPutQCXActivateRequest(
    [DISPOSITIVO_LEGAL_ENDPOINT],
    id
  );
}

export async function inactivateById(id) {
  return doPutQCXInactivateRequest(
    [DISPOSITIVO_LEGAL_ENDPOINT],
    id
  );
}
