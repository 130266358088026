/* eslint-disable no-unused-vars */
import { Grid } from '@material-ui/core';
import { useForm, useFormState } from 'react-final-form';
import QCXFormSubtitle from '../../components/form-title/QCXFormSubtitle';
import QCXFinalAlternativeCurrencyField from '../final-currency-field/QCXFinalAlternativeCurrencyField';
import QCXFinalDatePickerField from '../final-date-picker-field/QCXFinalDatePickerField';

export default function QCXAfrmmTotaisFormGroup({
  isConsult,
  valorValidators,
  handleAFRMMValorEmReaisOnBlur,
  intl,
  classes,
}) {
  const form = useForm();
  const { values } = useFormState();
  return (
    <>
      <Grid item xs={12}>
        <QCXFormSubtitle title="AFRMM" />
      </Grid>
      <Grid
        item
        container
        justify="flex-end"
        xs={12}
        sm={12}
        md={12}
        lg={12}
      >
        <Grid
          item
          className={classes.moedaInternacionalFieldLeft}
          xs={12}
          sm={12}
          md={4}
          lg={4}
        >
          <QCXFinalAlternativeCurrencyField
            id="currency-valor-moeda-afrmm-field"
            key="currency-valor-moeda-afrmm-field"
            name="totaisAfrmm.valorMoeda"
            label="Valor da AFRMM na Moeda"
          // intl={intl}
            disabled
          />
        </Grid>
        <Grid
          item
          className={classes.moedaInternacionalFieldLeft}
          xs={12}
          sm={12}
          md={4}
          lg={4}
        >
          <QCXFinalAlternativeCurrencyField
            id="currency-valor-real-moeda-afrmm-field"
            key="currency-valor-real-moeda-afrmm-field"
            name="totaisAfrmm.valorReal"
            label="Valor da AFRMM em Reais"
            onBlur={handleAFRMMValorEmReaisOnBlur(form, values, 'frete.moeda.id', 'totaisAfrmm.valorMoeda', 'totaisAfrmm.valorReal', 'totaisAfrmm.valorDolar', 'totaisAfrmm.dataPagamento')}
            adorned
            disabled={isConsult}
            validate={valorValidators}
          />
        </Grid>
      </Grid>
    </>
  );
}
