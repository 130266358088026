import { Grid, IconButton } from '@material-ui/core';
import { createFilterOptions } from '@material-ui/lab/useAutocomplete';
import SearchIcon from '@mui/icons-material/Search';
import { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchByFilterAsync, selectCatalogosProdutos } from '../../features/catalogo-produtos/catalogoProdutosSlice';
import {
  TIPO_FATURA,
} from '../../utils/general/fatura/faturaUtils';
import QCXProdutoAutocomplete from './QCXProdutoAutocomplete';
import useGetFilterByProdutoType from './useGetFiltersByProdutoType.hook';

export default function QCXSelectProdutoAutocomplete({
  label,
  name,
  form,
  initialValues,
  tipo = TIPO_FATURA,
  partnumber,
  ...restProps
}) {
  const dispatch = useDispatch();
  const list = useSelector(selectCatalogosProdutos);
  const filters = useGetFilterByProdutoType(tipo);

  const [inputValue, setInputValue] = useState(' ');

  // Existe um listener no formulario que precisa ser acionado quando o partnumber muda
  // (handlePartnumberSelectorOnchange no arquivo faturaMercadoriaListeners.js)
  // não é possivel aciona-lo manualmente, para forcar a execução tive que mudar o partnumber para ""
  // e depois carregar o novo valor, o preSearch serve para quando o valor não é encontrado
  const [preSearch, setPreSearch] = useState('');

  useEffect(() => {
    if (partnumber) {
      setInputValue(partnumber);
    } else {
      setInputValue('');
    }
  }, [partnumber]);

  const handleSetInputValue = (_event, newInputValue, reason) => {
    if (reason !== 'clear') {
      setInputValue(newInputValue);
      form.change('partnumberAutocompleteSelector', newInputValue);
      form.change('partNumber', newInputValue);
      return;
    }

    form.change('partnumberAutocompleteSelector', undefined);
    form.change('partNumberSelector', undefined);
    form.change('partNumber', undefined);
  };

  const handleFilter = useCallback(
    (filterValue) => {
      setPreSearch(filterValue);
      form.change('partnumberAutocompleteSelector', undefined);

      dispatch(fetchByFilterAsync([...filters, { name: 'partnumber', value: filterValue }]));
    },
    [filters]
  );

  const handleSearch = useCallback(
    (filterValue) => {
      if (filterValue) {
        dispatch(fetchByFilterAsync([...filters, { name: 'partnumber', value: filterValue }, { name: 'search', value: true }]));
      }
    },
    [filters]
  );

  useEffect(() => {
    if (preSearch === '' || list.length !== 1) {
      form.change('partnumberAutocompleteSelector', preSearch);
      return;
    }

    form.change('partnumberAutocompleteSelector', list[0].partnumber);
  }, [list]);

  const filterOptions = createFilterOptions({
    stringify: (option) => option.partnumber,
  });

  return (
    <Grid container alignItems="center">
      <Grid xs={11}>
        <QCXProdutoAutocomplete
          id={name}
          name={name}
          label={label}
          options={list}
          onBlurCallback={(filterValue) => handleFilter(filterValue)}
          filterOptions={filterOptions}
          initialValues={initialValues}
          inputValue={inputValue}
          handleSetInputValue={handleSetInputValue}
          getOptionLabel={(item) => (item?.partnumber || '')}
          form={form}
          {...restProps}
        />
      </Grid>
      <Grid xs={1}>
        <IconButton onClick={() => handleSearch(inputValue)} aria-label="search">
          <SearchIcon />
        </IconButton>
      </Grid>
    </Grid>

  );
}
