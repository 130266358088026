import i18n from '../../i18n';

const TIPO_POR_PROCESSO = 'POR_PROCESSO';
const TIPO_ACUMULADO_DIA = 'ACUMULADO_DIA';

const TIPO_RETENCAO_LIST = [
  {
    label: i18n.t('com.muralis.qcx.tipoRetencao.porProcesso'),
    value: TIPO_POR_PROCESSO,
  },
  {
    label: i18n.t('com.muralis.qcx.tipoRetencao.acumuladoDia'),
    value: TIPO_ACUMULADO_DIA,
  },
];

export default TIPO_RETENCAO_LIST;
