import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
} from 'react';
import { createFilterOptions } from '@material-ui/lab/useAutocomplete';
import _ from 'lodash';
import QCXAutocompleteSelect from '../autocomplete-select/QCXAutocompleteSelect';
import {
  TipoContaLiUtils,
} from './tipoContaUtils';

export default function QCXSelectTipoContaLicencaImportacaoAutocomplete({
  label,
  name,
  initialValues,
  disabled,
  ...restProps
}) {
  const [defaultValue, setDefaultValue] = useState('');

  useEffect(() => {
    const value = TipoContaLiUtils.TIPO_LI_LIST
      .find((item) => (
        item?.value === _.get(initialValues, name)
      ));

    if (value) {
      setDefaultValue(value);
    } else {
      setDefaultValue('');
    }
  }, [initialValues]);

  const filterOptions = useMemo(() => (
    createFilterOptions({
      stringify: (option) => (
        option.label + option.value
      ),
    })
  ), []);

  const getOptionLabel = useCallback((item) => (
    item?.label || ''
  ), []);

  const getOptionValue = useCallback((item) => (
    item?.value || ''
  ), []);

  return (
    <QCXAutocompleteSelect
      label={label}
      id={name}
      name={name}
      options={TipoContaLiUtils.TIPO_LI_LIST}
      filterOptions={filterOptions}
      defaultValue={defaultValue}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      disabled={disabled}
      {...restProps}
    />
  );
}
