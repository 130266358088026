import { Grid, useMediaQuery, useTheme } from '@material-ui/core';
import {
  DescriptionOutlined as DescriptionOutlinedIcon,
  Info as InfoIcon,
  InfoOutlined,
  ListAltOutlined as ListAltOutlinedIcon,
} from '@material-ui/icons';
import { Skeleton } from '@mui/material';
import _ from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { etapaFollowUpSelectors } from '../../features/etapa-follow-up/etapaFollowUpSelectors';
import { etapaFollowUpActions } from '../../features/etapa-follow-up/etapaFollowUpSlice';
import { etapaFollowUpThunks } from '../../features/etapa-follow-up/etapaFollowUpThunks.ts';
import { followUpSelectors } from '../../features/follow-up/followUpSelectors';
import { followUpActions } from '../../features/follow-up/followUpSlice';
import {
  followUpThunks,
  inactivateEtapaByIdAsync,
  reactivateEtapaByIdAsync,
} from '../../features/follow-up/followUpThunks';
import QCXButton from '../../shared-components/button/QCXButton';
import QCXConfirmDialog from '../../shared-components/dialog/QCXConfirmDialog';
import useFormDialogSync from '../../utils/hooks/form/dialog/useFormDialogSync';
import useOperationMiddleware from '../../utils/hooks/operation/middleware/useOperationMiddleware';
import { isAlternativeLoadingStatus, isLoadingStatus, isRefresh } from '../../utils/store/store-utils';
import QCXDownloader from '../downloader/QCXDownloader';
import QCXFormSubtitle from '../form-title/QCXFormSubtitle';
import QCXDataEtapaFollowUpFormDialog from './QCXDataEtapaFollowUpFormDialog';
import QCXDocumentosFollowUpDialogManager from './QCXDocumentosFollowUpDialogManager';
import QCXNovaEtapaFollowUpFormDialog from './QCXNovaEtapaFollowUpFormDialog';
import QCXObservacaoEtapaFollowUpFormDialog from './QCXObservacaoEtapaFollowUpFormDialog';
import QCXObservacaoGeralEtapasFollowUpDialog from './QCXObservacaoGeralEtapasFollowUpDialog';
import QCXTimelineFollowUp from './QCXTimelineFollowUp';

export default function QCXFollowUpTimelineManager({ model }) {
  const { t } = useTranslation();
  const theme = useTheme();

  const dispatch = useDispatch();

  const isXsDownScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const statusFollowUp = useSelector(followUpSelectors.selectStatus);
  const refreshFollowUp = useSelector(followUpSelectors.selectRefresh);
  const statusEtapaFollowUp = useSelector(etapaFollowUpSelectors.selectStatus);
  const refreshEtapaFollowUp = useSelector(etapaFollowUpSelectors.selectRefresh);
  const etapasFollowUp = useSelector(etapaFollowUpSelectors.selectEtapasFollowUp);

  useEffect(() => {
    const handleFetchAllEtapasByFollowUpId = (followUpId) => {
      if (followUpId) {
        dispatch(etapaFollowUpThunks.fetchAllByFollowUpIdAsync(followUpId));
      }
    };

    handleFetchAllEtapasByFollowUpId(model?.id);

    return () => {
      dispatch(etapaFollowUpActions.resetList());
      dispatch(etapaFollowUpActions.resetModel());
    };
  }, [model]);

  const loading = useMemo(
    () =>
      isAlternativeLoadingStatus(statusFollowUp) || isLoadingStatus(statusEtapaFollowUp) || _.isEmpty(etapasFollowUp),
    [statusFollowUp, statusEtapaFollowUp, etapasFollowUp]
  );

  const hasAnyObservations = useMemo(
    () =>
      (etapasFollowUp || []).some(({ observacao }) => observacao && _.isString(observacao) && observacao.length > 0),
    [etapasFollowUp]
  );

  const [handleOpenModalDocumentos, formDialogDocumentosStatus, handleFormDialogDocumentosStatus] = useFormDialogSync();

  const [handleOpenModalNovaEtapa, formDialogEtapaFollowUpStatus, handleFormDialogEtapaFollowUpStatus] =
    useFormDialogSync(() => {
      dispatch(
        etapaFollowUpActions.setModel({
          followUp: {
            id: model?.id,
          },
        })
      );
    }, [model]);

  const [
    handleOpenObservacoesGerais,
    dialogObservacaoGeraLEtapasFollowUpStatus,
    handleConsultObservacaoGeraLEtapasFollowUpStatus,
  ] = useFormDialogSync();

  const [handleAlterarDataConclusaoEtapa, formDialogAlterarDataEtapaStatus, handleFormDialogAlterarDataEtapaStatus] =
    useFormDialogSync(
      (values) => {
        dispatch(
          etapaFollowUpActions.setModel({
            ...values,
            metadata: {
              followUp: {
                id: model?.id,
              },
            },
          })
        );
      },
      [model]
    );

  const [
    handleAlterarObservacaoEtapa,
    formDialogAlterarObservacaoEtapaStatus,
    handleFormDialogAlterarObservacaoEtapaStatus,
  ] = useFormDialogSync(
    (values) => {
      dispatch(
        etapaFollowUpActions.setModel({
          ...values,
          metadata: {
            followUp: {
              id: model?.id,
            },
          },
        })
      );
    },
    [model]
  );

  const [handleInactivateByIdMiddleware, operationOfInactivateById] = useOperationMiddleware(({ id }) => {
    dispatch(inactivateEtapaByIdAsync(id));
  }, []);

  const handleConfigureInactivateById = useCallback(
    (values) => {
      const handleInactivateByIdClick = () => {
        const configureOperation = () => ({
          options: {
            title: t('com.muralis.qcx.confirmarOperacaoDesativacao').toUpperCase(),
            message: t('com.muralis.qcx.mensagem.certezaDesativarEtapa', { etapa: values?.etapa?.description }),
          },
        });

        handleInactivateByIdMiddleware(configureOperation, values);
      };

      return handleInactivateByIdClick;
    },
    [handleInactivateByIdMiddleware]
  );

  const [handleReactivateByIdMiddleware, operationOfReactivateById] = useOperationMiddleware(({ id }) => {
    dispatch(reactivateEtapaByIdAsync(id));
  }, []);

  const handleConfigureReactivateById = useCallback(
    (values) => {
      const handleReactivateByIdClick = () => {
        const configureOperation = () => ({
          options: {
            title: t('com.muralis.qcx.confirmarOperacaoReativacao').toUpperCase(),
            message: t('com.muralis.qcx.mensagem.certezaReativarEtapa', { etapa: values?.etapa?.description }),
          },
        });

        handleReactivateByIdMiddleware(configureOperation, values);
      };

      return handleReactivateByIdClick;
    },
    [handleReactivateByIdMiddleware]
  );

  const handler = useMemo(
    () => ({
      alterarDataConclusaoEtapa: (values) => {
        handleAlterarDataConclusaoEtapa(values);
      },
      alterarObservacaoEtapa: (values) => {
        handleAlterarObservacaoEtapa(values);
      },
      inactivateEtapa: (values) => {
        const callbackInactivate = handleConfigureInactivateById(values);
        callbackInactivate();
      },
      reactivateEtapa: (values) => {
        const callbackReactivate = handleConfigureReactivateById(values);
        callbackReactivate();
      },
    }),
    [
      handleAlterarDataConclusaoEtapa,
      handleAlterarObservacaoEtapa,
      handleConfigureInactivateById,
      handleConfigureReactivateById,
    ]
  );

  useEffect(() => {
    if (isRefresh(refreshFollowUp)) {
      dispatch(followUpThunks.fetchByIdAsync(model?.id));

      dispatch(followUpActions.resetRefresh());
    }
  }, [refreshFollowUp, model]);

  useEffect(() => {
    if (isRefresh(refreshEtapaFollowUp)) {
      dispatch(etapaFollowUpThunks.fetchAllByFollowUpIdAsync(model?.id));

      dispatch(etapaFollowUpActions.resetRefresh());
    }
  }, [refreshEtapaFollowUp, model]);

  return (
    <>
      <Grid item container spacing={2}>
        <Grid
          item
          xs={12}
          style={{
            marginTop: '2rem',
            marginBottom: '1rem',
          }}
        >
          <QCXFormSubtitle title={t('com.muralis.qcx.timelineEtapasFollowUp')} />
        </Grid>

        <Grid item container spacing={4}>
          <QCXTimelineFollowUp list={etapasFollowUp} loading={loading} handler={handler} />
          <Grid item container alignContent="center" justify="center" spacing={2} xs={12}>
            <Grid item lg={1} md={2} sm={3} xs={12}>
              {loading ? (
                <Skeleton variant="rectangular" animation="wave" width="100%" height={40} />
              ) : (
                <QCXButton
                  id="button-follow-up-documentos"
                  key="button-follow-up-documentos"
                  color="secondary"
                  size="small"
                  onClick={handleOpenModalDocumentos}
                  tooltip={!isXsDownScreen}
                  tooltipDescription={t('com.muralis.qcx.documento.labelPlural')}
                  startIcon={isXsDownScreen && <DescriptionOutlinedIcon />}
                  fullWidth
                >
                  {!isXsDownScreen && <DescriptionOutlinedIcon />}
                  {isXsDownScreen && t('com.muralis.qcx.documento.labelPlural')}
                </QCXButton>
              )}
            </Grid>
            <Grid item lg={1} md={2} sm={3} xs={12}>
              {loading ? (
                <Skeleton variant="rectangular" animation="wave" width="100%" height={40} />
              ) : (
                <QCXButton
                  id="button-follow-up-nova-etapa"
                  key="button-follow-up-nova-etapa"
                  color="secondary"
                  size="small"
                  onClick={handleOpenModalNovaEtapa}
                  tooltip={!isXsDownScreen}
                  tooltipDescription={t('com.muralis.qcx.etapa.novaEtapa')}
                  startIcon={isXsDownScreen && <ListAltOutlinedIcon />}
                  fullWidth
                >
                  {!isXsDownScreen && <ListAltOutlinedIcon />}
                  {isXsDownScreen && t('com.muralis.qcx.etapa.novaEtapa')}
                </QCXButton>
              )}
            </Grid>
            <Grid item lg={1} md={2} sm={3} xs={12}>
              {loading ? (
                <Skeleton variant="rectangular" animation="wave" width="100%" height={40} />
              ) : (
                <QCXButton
                  id="button-follow-up-observacoes-gerais"
                  key="button-follow-up-observacoes-gerais"
                  color="primary"
                  size="small"
                  onClick={handleOpenObservacoesGerais}
                  tooltip={!isXsDownScreen}
                  tooltipDescription={t('com.muralis.qcx.observacoesGerais')}
                  startIcon={isXsDownScreen && (hasAnyObservations ? <InfoIcon /> : <InfoOutlined />)}
                  fullWidth
                  disabled={!hasAnyObservations}
                >
                  {!isXsDownScreen && (hasAnyObservations ? <InfoIcon /> : <InfoOutlined />)}
                  {isXsDownScreen && t('com.muralis.qcx.observacoesGerais')}
                </QCXButton>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <QCXDocumentosFollowUpDialogManager
        model={model}
        status={formDialogDocumentosStatus}
        handleStatus={handleFormDialogDocumentosStatus}
      />
      <QCXNovaEtapaFollowUpFormDialog
        parentModel={model}
        status={formDialogEtapaFollowUpStatus}
        handleStatus={handleFormDialogEtapaFollowUpStatus}
      />
      <QCXDataEtapaFollowUpFormDialog
        status={formDialogAlterarDataEtapaStatus}
        handleStatus={handleFormDialogAlterarDataEtapaStatus}
      />
      <QCXObservacaoEtapaFollowUpFormDialog
        status={formDialogAlterarObservacaoEtapaStatus}
        handleStatus={handleFormDialogAlterarObservacaoEtapaStatus}
      />
      <QCXObservacaoGeralEtapasFollowUpDialog
        parentModel={model}
        list={etapasFollowUp}
        status={dialogObservacaoGeraLEtapasFollowUpStatus}
        handleStatus={handleConsultObservacaoGeraLEtapasFollowUpStatus}
      />
      <QCXConfirmDialog
        key="confirm-dialog-inactivate-etapa-follow-up"
        id="confirm-dialog-inactivate-etapa-follow-up"
        open={operationOfInactivateById?.active}
        title={operationOfInactivateById?.title}
        content={operationOfInactivateById?.message}
        endContent={operationOfInactivateById?.endMessage}
        onConfirm={operationOfInactivateById.confirm}
        onClose={operationOfInactivateById?.reset}
      />
      <QCXConfirmDialog
        key="confirm-dialog-reactivate-etapa-follow-up"
        id="confirm-dialog-reactivate-etapa-follow-up"
        open={operationOfReactivateById?.active}
        title={operationOfReactivateById?.title}
        content={operationOfReactivateById?.message}
        endContent={operationOfReactivateById?.endMessage}
        onConfirm={operationOfReactivateById.confirm}
        onClose={operationOfReactivateById?.reset}
      />
      <QCXDownloader />
    </>
  );
}
