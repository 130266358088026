import { Grid } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import { useAtom, PrimitiveAtom } from "jotai";
import { useMemo, useState } from "react";
import DataTable from "../../../../../common/components/dataTable";
import FormHeader from "../../../../../common/components/forms/formHeader";
import { changeBulletProps } from "../../../../../common/layouts/paginationLayout/helpers/paginationLayoutHelpers";
import { PaginationBulletProps } from "../../../../../common/layouts/paginationLayout/paginationLayout";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  PropostaData,
  propostaDataAtom,
  propostasActivePage,
  propostasNewPageBulletProps,
} from "../propostasNewPage";
import { PropostaFile } from "../../common/propostaFile";
import { useCreateProposta } from "../propostaNewPage.hooks";
import { PortoAeroporto } from "../../../../../common/types/portoAeroporto";
import { useHistory } from "react-router-dom";
import FileSubmitForm from "../../common/fileSubmitForm";
import { ContactProfile } from "../../../../../common/types/contactProfile";

export interface PropostaPayload {
  servicos: { id: number; nomeValor: string }[];
  cadastros: { id: number; nomeValor: string }[];
  clienteId: number;
  nomeCliente: string;
  cpfOrCnpj: string;
  locaisDeEmbarque: PortoAeroporto[];
  anexos: PropostaFile[];
  fronteiras: string;
  contatosAdicionais?: ContactProfile[];
}

const ThirdPage = () => {
  // States

  const [propostaData, setPropostaData] =
    useAtom<PrimitiveAtom<PropostaData>>(propostaDataAtom);
  const [activePage, setActivePage] =
    useAtom<PrimitiveAtom<number>>(propostasActivePage);
  const [bulletProps, setBulletProps] = useAtom<
    PrimitiveAtom<PaginationBulletProps[]>
  >(propostasNewPageBulletProps);
  const columns: GridColDef[] = useMemo(() => {
    return [
      {
        field: "data",
        headerName: "Data",
        flex: 1,
        headerClassName: "header-theme",
      },
      {
        field: "tipo",
        headerName: "Tipo de Documento",
        flex: 2,
        headerClassName: "header-theme",
      },
      {
        field: "descricao",
        headerName: "Descrição",
        flex: 3,
        headerClassName: "header-theme",
      },
      {
        field: "validade",
        headerName: "Validade",
        flex: 1,
        headerClassName: "header-theme",
      },
      {
        field: "actions",
        type: "actions",
        headerName: "Ações",
        headerClassName: "header-theme",
        // @ts-ignore
        getActions: (params: any) => [
          <GridActionsCellItem
            icon={<DeleteIcon color="error" />}
            label="Excluir"
            onClick={() => handleDeleteFile(params.id)}
          />,
        ],
      },
    ];
  }, []);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const history = useHistory();

  const handleFinishUpload = (newFile: PropostaFile) => {
    setPropostaData({
      ...propostaData,
      arquivos: [...(propostaData.arquivos ?? []), newFile],
    });
  };

  const handleFinishSubmit = () => {
    setIsSubmitting(false);
    history.push("/comercial/propostas");
  };
  const [createProposta] = useCreateProposta(handleFinishSubmit);

  // Navigation helpers
  const handleSubmit = () => {
    setIsSubmitting(true);

    const cliente = propostaData.cliente;

    const nomeCliente = cliente
      ? cliente?.pessoa?.nome!
      : propostaData.novoClienteNome!;

    const cpfOrCnpj = cliente
      ? cliente?.pessoa?.cpf ?? cliente?.pessoa?.cnpj ?? ""
      : propostaData.novoClienteCPF!;

    const requestPayload: PropostaPayload = {
      nomeCliente,
      cpfOrCnpj,
      clienteId: propostaData.cliente?.id!,
      servicos: propostaData.serviços,
      cadastros: propostaData.cadastros,
      locaisDeEmbarque: propostaData.locais!,
      anexos: propostaData.arquivos ?? [],
      fronteiras: propostaData.fronteiras,
      contatosAdicionais: propostaData.contatosAdicionais,
    };

    createProposta({ requestPayload });
  };
  const handlePrevious = () => {
    changeBulletProps(2, setBulletProps);
    setActivePage(2);
  };

  const handleDeleteFile = (id: number) => {
    setPropostaData((prev) => {
      const newFiles = [...(prev.arquivos ?? [])];
      newFiles.splice(id, 1);
      return { ...prev, arquivos: newFiles };
    });
  };

  return (
    <div style={{ display: activePage == 3 ? "inherit" : "none" }}>
      <FileSubmitForm
        propostaId={propostaData.id}
        isSubmitting={isSubmitting}
        setIsSubmitting={setIsSubmitting}
        uploadedCallback={handleFinishUpload}
      ></FileSubmitForm>
      <Grid container spacing={2}>
        <FormHeader>Documentos Adicionados</FormHeader>
        <Grid item xs={12}>
          <DataTable
            columns={columns}
            rows={
              propostaData.arquivos?.map((arquivo, index) => ({
                id: index,
                data: arquivo.data,
                tipo: arquivo.tipo?.description,
                descricao: arquivo.nome,
                validade: arquivo.validade,
              })) ?? []
            }
          ></DataTable>
        </Grid>
        <Grid item xs={12} textAlign="right">
          <LoadingButton
            loading={isSubmitting}
            variant="contained"
            style={{ marginRight: 10 }}
            onClick={handlePrevious}
            color="secondary"
          >
            Anterior
          </LoadingButton>
          <LoadingButton
            loading={isSubmitting}
            type="submit"
            variant="contained"
            onClick={handleSubmit}
            color="secondary"
          >
            Salvar
          </LoadingButton>
        </Grid>
      </Grid>
    </div>
  );
};

export default ThirdPage;
