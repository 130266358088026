/* eslint-disable import/prefer-default-export */
import {
  doGetQCXRequest,
} from '../../utils/api/domain/domain-api-utils';
import { UNIDADE_NEGOCIO_ENDPOINT } from '../../utils/api/domain/domain-endpoint-utils';

const UNIDADES_ASSOCIADAS_ENDPOINT = 'associadas';
export async function fetchUnidadesDeNegocioAssociadas() {
  return doGetQCXRequest([
    UNIDADE_NEGOCIO_ENDPOINT, UNIDADES_ASSOCIADAS_ENDPOINT
  ]);
}
export async function fetchGrupoById(id) {
  return doGetQCXRequest([
    UNIDADE_NEGOCIO_ENDPOINT,
    id,
  ]);
}
