const CAPITULO_CODE = 'C';
const POSICAO_CODE = 'P';
const SUB_POSICAO_DE_NIVEL_1_CODE = 'SA';
const SUB_POSICAO_DE_NIVEL_2_CODE = 'SB';
const ITEM = 'N';
const SUBITEM = 'U';

const capitulo = {
  code: CAPITULO_CODE,
  validate: (length) => length <= 2,
};

const posicao = {
  code: POSICAO_CODE,
  validate: (length) => length >= 3 && length <= 4,
};

const subPosicaoNivel1 = {
  code: SUB_POSICAO_DE_NIVEL_1_CODE,
  validate: (length) => length === 5,
};

const subPosicaoNivel2 = {
  code: SUB_POSICAO_DE_NIVEL_2_CODE,
  validate: (length) => length === 6,
};

const item = {
  code: ITEM,
  validate: (length) => length === 7,
};

const subItem = {
  code: SUBITEM,
  validate: (length) => length === 8,
};

const nveNiveis = [
  capitulo,
  posicao,
  subPosicaoNivel1,
  subPosicaoNivel2,
  item,
  subItem,
];

const getNveNivel = (nve) => {
  const code = String(nve?.code);
  const codeLength = code.length;

  const [validCode] = nveNiveis
    .map((nivel) => (
      nivel.validate(codeLength)
        ? nivel?.code
        : undefined
    ))
    .filter((nivel) => nivel !== undefined);

  return validCode;
};

const configureNveNivelWith = (list) => {
  const updatedList = list.map((obj) => ({
    ...obj,
    nivel: getNveNivel(obj),
  }));

  return updatedList;
};

export {
  CAPITULO_CODE,
  POSICAO_CODE,
  SUB_POSICAO_DE_NIVEL_1_CODE,
  SUB_POSICAO_DE_NIVEL_2_CODE,
  ITEM,
  SUBITEM,
};

export {
  configureNveNivelWith,
  getNveNivel,
};
