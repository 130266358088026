import { IconButton } from '@material-ui/core';
import {
  Delete as DeleteIcon, Search as PageviewIcon,
} from '@material-ui/icons';
import React, {
  useCallback, useContext, useEffect, useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { userHasDeleteRole } from '../../../../../components/perfil/perfil-utils';
import UnidadeNegocioContext from '../../../../../contexts/registrations/unidade-negocio/UnidadeNegocioContext';
import {
  resetList as resetCidadeList,
} from '../../../../../features/cidade/cidadeSlice';
import {
  resetList as resetGrupoList,
} from '../../../../../features/grupo-unidade-negocio/grupoUnidadeNegocioSlice';
import {
  resetList as resetCnaeList,
} from '../../../../../features/cnae/cnaeSlice';
import { IDLE_CONTROL } from '../../../../../features/config-control';
import {
  resetList as resetEstadoList,
} from '../../../../../features/estado/estadoSlice';
import {
  changeControlTo as changeControlPaisTo,
  resetList as resetPaisList,
} from '../../../../../features/pais/paisSlice';
import {
  activateByIdAsync,
  changeToConsultMode,
  changeToCreateMode,
  fetchAllAsync,
  inactivateByIdAsync,
  resetBackgroundMode,
  resetMode,
  resetModel,
  resetSubMode,
  selectUnidadesNegocio,
} from '../../../../../features/unidade-negocio/unidadeNegocioSlice';
import QCXSimpleConsultPageTemplate from '../../../../../templates/simple-consult-page/QCXSimpleConsultPageTemplate';
import { getLocalidadeOrDefault, unnormalizeNumeral } from '../../../../../utils/general/general-utils';
import { formatBrazilianNumericDecimal, formatCnpj, formatCpf } from '../../../../../utils/hooks/form/field/formatters';
import useOperationController from '../../../../../utils/hooks/store/operation/useOperationController';
import { isAlternativeLoadingStatus } from '../../../../../utils/store/store-utils';

export default function UnidadeNegocioConsultPage({ authInfo }) {
  const { t } = useTranslation();

  const history = useHistory();
  const {
    status,
  } = useContext(UnidadeNegocioContext);

  const dispatch = useDispatch();

  const unidadesNegocio = useSelector(selectUnidadesNegocio);

  const isAlternativeLoading = useMemo(() => (
    isAlternativeLoadingStatus(status)
  ), [status]);

  const fetchAll = useCallback(() => {
    dispatch(fetchAllAsync());
  }, []);

  const resetModes = useCallback(() => {
    dispatch(resetMode());
    dispatch(resetBackgroundMode());
    dispatch(resetSubMode());
  }, []);

  const resetModels = useCallback(() => {
    dispatch(resetModel());
  }, []);

  const resetDependencies = useCallback(() => {
    dispatch(changeControlPaisTo(IDLE_CONTROL));
    dispatch(resetPaisList());
    dispatch(resetEstadoList());
    dispatch(resetCidadeList());
    dispatch(resetCnaeList());
    dispatch(resetGrupoList());
  }, []);

  useEffect(() => {
    fetchAll();
    resetModes();
    resetModels();
    resetDependencies();
  }, []);

  const handleActivateInactivate = useOperationController(
    async (event, id) => {
      event.preventDefault();

      const { active } = unidadesNegocio.find(
        (unidadeNegocio) => unidadeNegocio.id === id
      );

      const isActivation = !active;

      if (isActivation) {
        dispatch(activateByIdAsync(id));
      } else {
        dispatch(inactivateByIdAsync(id));
      }
    }
  );

  const handleClickOnButtonNew = useCallback((event) => {
    event.stopPropagation();
    dispatch(changeToCreateMode());
  }, []);

  const handleClickOnEditButton = useCallback((event, id) => {
    event.stopPropagation();
    dispatch(changeToConsultMode());
    history.push(t('com.muralis.qcx.url.moduloGeraisUnidadeNegocioDetalhes', { id }));
  }, [history]);

  const columns = [
    {
      field: 'id',
      headerAlign: 'center',
      align: 'center',
      headerName: t('com.muralis.qcx.codigo'),
      type: 'string',
      flex: 90,
      valueGetter: ({ row }) => (
        row?.id
      ),
    },
    {
      field: 'pessoa.nome',
      headerName: t('com.muralis.qcx.nomeEmpresa'),
      type: 'string',
      flex: 195,
      valueGetter: ({ row }) => (
        row?.pessoa?.nome
      ),
    },
    {
      field: 'pessoa.cnae',
      headerName: t('com.muralis.qcx.empresa.CNAE'),
      headerAlign: 'center',
      align: 'center',
      type: 'string',
      flex: 170,
      valueGetter: ({ row }) => {
        const { pessoa } = row;
        return pessoa?.tipo === 'PessoaJuridica' ? pessoa?.cnae?.description : t('com.muralis.qcx.validacao.naoPossui');
      },
    },
    {
      field: 'cpfCnpj',
      headerName: t('com.muralis.qcx.CPFCNPJ'),
      headerAlign: 'center',
      type: 'string',
      flex: 170,
      valueGetter: ({ row }) => {
        const { pessoa } = row;
        return pessoa?.tipo === 'PessoaJuridica' ? formatCnpj(pessoa?.cnpj) : formatCpf(pessoa?.cpf);
      },
    },
    {
      field: 'localidade',
      headerName: t('com.muralis.qcx.localizacao'),
      type: 'string',
      flex: 160,
      valueGetter: ({ row }) => (
        getLocalidadeOrDefault(row?.endereco, '-')
      ),
    },
    {
      field: 'percentuais',
      headerName: t('com.muralis.qcx.percentualISS'),
      headerAlign: 'right',
      align: 'right',
      flex: 110,
      valueFormatter: ({ row }) => (
        unnormalizeNumeral(
          row?.percentuais,
          formatBrazilianNumericDecimal(2)
        )
      ),
      valueGetter: ({ row }) => (
        unnormalizeNumeral(
          row?.percentuais,
          formatBrazilianNumericDecimal(2)
        )
      ),
    },
    {
      field: 'actions',
      headerName: t('com.muralis.qcx.acoes.label'),
      headerAlign: 'center',
      align: 'center',
      flex: 120,
      renderCell: ({ row: { id, active } }) => (
        <>
          {userHasDeleteRole(authInfo, 'unidade-de-negocio') && (
          <IconButton
            key={`stw-active${id}`}
            value={active}
            name={`stw-active-${id}`}
            onClick={(event) => handleActivateInactivate(event, id)}
          >
            <DeleteIcon color="error" />
          </IconButton>
          )}
          <IconButton
            key={`btn-view-${id}`}
            name={`btn-view-${id}`}
            onClick={(event) => handleClickOnEditButton(event, id)}
          >
            <PageviewIcon color="primary" />
          </IconButton>
        </>
      ),
    },
  ];

  const breadcrumbs = [
    {
      link: {
        to: '/',
        name: t('com.muralis.qcx.inicio'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.cadastros'),
      },
    },
    {
      link: {
        to: t('com.muralis.qcx.url.moduloCadastrosGerais'),
        name: t('com.muralis.qcx.gerais'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.unidadeNegocio.labelPlural'),
      },
    },
  ];

  const templateProps = {
    page: {
      title: t('com.muralis.qcx.unidadeNegocio.labelPlural'),
      breadcrumbs,
    },
    control: {
      buttonNew: {
        description: t('com.muralis.qcx.unidadeNegocio.novaUnidadeNegocio'),
        path: t('com.muralis.qcx.url.moduloGeraisUnidadeNegocioNova'),
        onClick: handleClickOnButtonNew,
      },
    },
    table: {
      columns,
    },
  };

  const filterProps = [
    'id',
    'pessoa.cpf',
    'pessoa.cnpj',
    'pessoa.nome',
    'pessoa.cnae.description',
    'endereco.cidade.description',
    'endereco.cidade.estado.code',
  ];
  return (
    <QCXSimpleConsultPageTemplate
      pageProps={templateProps.page}
      controlProps={templateProps.control}
      tableProps={templateProps.table}
      tableData={unidadesNegocio}
      isLoading={isAlternativeLoading}
      buttonNewText={t('com.muralis.qcx.acoes.nova').toUpperCase()}
      multipleFilter
      filterProps={filterProps}
      authInfo={authInfo}
      requiredRoles={['unidade-de-negocio']}
    />
  );
}
