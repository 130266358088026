const UNIDADE_DE_MEDIDA_QUILOGRAMA_LIQUIDO = 'QUILOGRAMA LIQUIDO';
const UNIDADE_DE_MEDIDA_UNIDADE = 'UNIDADE';
const UNIDADE_DE_MEDIDA_MIL_UNIDADES = 'MIL UNIDADES';
const UNIDADE_DE_MEDIDA_PARES = 'PARES';
const UNIDADE_DE_MEDIDA_METRO = 'METRO';
const UNIDADE_DE_MEDIDA_METRO_QUADRADO = 'METRO QUADRADO';
const UNIDADE_DE_MEDIDA_METRO_CUBICO = 'METRO CUBICO';
const UNIDADE_DE_MEDIDA_LITRO = 'LITRO';
const UNIDADE_DE_MEDIDA_MEGAWATT_HORA = 'MEGAWATT HORA';
const UNIDADE_DE_MEDIDA_QUILATE = 'QUILATE';
const UNIDADE_DE_MEDIDA_DUZIA = 'DUZIA';
const UNIDADE_DE_MEDIDA_TONEL_METR_LIQ = 'TONEL.METR.LIQ';
const UNIDADE_DE_MEDIDA_GRAMA = 'GRAMA';
const UNIDADE_DE_MEDIDA_BILHAO_DE_UNIDADE_INTERNACIONAL = 'BILHAO DE UNIDADE INTERNACIONAL';
const UNIDADE_DE_MEDIDA_QUILOGRAMA_BRUTO = 'QUILOGRAMA BRUTO';
const UNIDADE_DE_MEDIDA_JOGO = 'JOGO';
const UNIDADE_DE_MEDIDA_PECA = 'PECA';

export {
  UNIDADE_DE_MEDIDA_QUILOGRAMA_LIQUIDO,
  UNIDADE_DE_MEDIDA_UNIDADE,
  UNIDADE_DE_MEDIDA_MIL_UNIDADES,
  UNIDADE_DE_MEDIDA_PARES,
  UNIDADE_DE_MEDIDA_METRO,
  UNIDADE_DE_MEDIDA_METRO_QUADRADO,
  UNIDADE_DE_MEDIDA_METRO_CUBICO,
  UNIDADE_DE_MEDIDA_LITRO,
  UNIDADE_DE_MEDIDA_MEGAWATT_HORA,
  UNIDADE_DE_MEDIDA_QUILATE,
  UNIDADE_DE_MEDIDA_DUZIA,
  UNIDADE_DE_MEDIDA_TONEL_METR_LIQ,
  UNIDADE_DE_MEDIDA_GRAMA,
  UNIDADE_DE_MEDIDA_BILHAO_DE_UNIDADE_INTERNACIONAL,
  UNIDADE_DE_MEDIDA_QUILOGRAMA_BRUTO,
  UNIDADE_DE_MEDIDA_JOGO,
  UNIDADE_DE_MEDIDA_PECA,
};
