import axios from 'axios';
import { isValid } from '../general/general-utils';

const HttpMethod = {
  GET_METHOD: 'GET',
  POST_METHOD: 'POST',
  PUT_METHOD: 'PUT',
  DELETE_METHOD: 'DELETE',
};

const MediaType = {
  ALL: '*/*',
  APPLICATION_ATOM_XML: 'application/atom+xml',
  APPLICATION_CBOR: 'application/cbor',
  APPLICATION_FORM_URLENCODED: 'application/x-www-form-urlencoded',
  APPLICATION_JSON: 'application/json',
  APPLICATION_OCTET_STREAM: 'application/octet-stream',
  APPLICATION_STREAM_JSON: 'application/stream+json',
  APPLICATION_XHTML_XML: 'application/xhtml+xml',
  APPLICATION_XML: 'application/xml',
  APPLICATION_PDF: 'application/pdf',
  APPLICATION_X_PKCS12: 'application/x-pkcs12',
  MULTIPART_FORM_DATA: 'multipart/form-data',
  IMAGE_GIF: 'image/gif',
  IMAGE_JPEG: 'image/jpeg',
  IMAGE_PNG: 'image/png',
  EXCEL: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .xlsx',
};

const ReponseType = {
  ARRAY_BUFFER: 'arraybuffer',
  BLOB: 'blob',
  DOCUMENT: 'document',
  JSON: 'json',
  STREAM: 'stream',
  TEXT: 'text',
};

function buildUrlWithQueryParams(url, params = []) {
  return params?.length
    ? `${url}?${params
        .filter(({ name, value }) => name && value !== undefined)
        .map(({ name, value }) => `${name}=${value}`)
        .join('&')}`
    : url;
}

function validateQueryParams(params = []) {
  return params.filter((item) => item?.name && item?.value);
}

function reducePath(path = []) {
  return `/${path.join('/')}`;
}

const makeRequest = (baseUrl) => (method) => (path) => {
  const endpoint = reducePath(path);
  const url = `${baseUrl}${endpoint}`;

  if (method === HttpMethod.GET_METHOD) {
    return {
      doRequest: axios.get,
      url,
    };
  }

  if (method === HttpMethod.POST_METHOD) {
    return {
      doRequest: axios.post,
      url,
    };
  }

  if (method === HttpMethod.PUT_METHOD) {
    return {
      doRequest: axios.put,
      url,
    };
  }

  if (method === HttpMethod.DELETE_METHOD) {
    return {
      doRequest: axios.delete,
      url,
    };
  }

  return null;
};

async function doGetRequest(makeFullRequest, path = [], queryParams = [], config = {}) {
  const { doRequest, url } = makeFullRequest(HttpMethod.GET_METHOD)(path);

  const finalUrl = buildUrlWithQueryParams(url, queryParams);

  return doRequest(finalUrl, config);
}

async function doPostRequest(makeFullRequest, path = [], data = {}, queryParams = [], config = {}) {
  const { doRequest, url } = makeFullRequest(HttpMethod.POST_METHOD)(path);

  const finalUrl = buildUrlWithQueryParams(url, queryParams);

  return doRequest(finalUrl, data, config);
}

async function doPutRequest(makeFullRequest, path = [], data = null, queryParams = [], config = {}) {
  const { doRequest, url } = makeFullRequest(HttpMethod.PUT_METHOD)(path);

  const finalUrl = buildUrlWithQueryParams(url, queryParams);

  if (!isValid(data)) {
    return doRequest(finalUrl, null, config);
  }

  return doRequest(finalUrl, data, config);
}

async function doDeleteRequest(makeFullRequest, path = []) {
  const { doRequest, url } = makeFullRequest(HttpMethod.DELETE_METHOD)(path);

  return doRequest(url);
}

export {
  HttpMethod,
  MediaType,
  ReponseType,
  makeRequest,
  doGetRequest,
  doPostRequest,
  doPutRequest,
  doDeleteRequest,
  reducePath,
  buildUrlWithQueryParams,
  validateQueryParams,
};
