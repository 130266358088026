import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Grid, makeStyles } from '@material-ui/core';
import {
  DataUsage as DataUsageIcon,
  AccountBalanceWallet as AccountBalanceWalletIcon,
  LocalAtm as LocalAtmIcon,
} from '@material-ui/icons';
import { LocalShipping as LocalShippingIcon } from '@mui/icons-material';
import { GiExpense as ExpenseIcon } from 'react-icons/gi';
import { AiFillBank } from 'react-icons/ai';
import { RiSafe2Line as RiSafe2LineIcon } from 'react-icons/ri';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import { useDispatch } from 'react-redux';
import QCXMainLayout from '../../../../components/main-layout/QCXMainLayout';
import QCXFocusLock from '../../../../components/focus-lock/QCXFocusLock';
import { focusLockActions } from '../../../../features/focus-lock/focusLockSlice';
import { CADASTROS_FINANCEIROS_MODULE } from '../../../../utils/general/modules';
import QCXCardMenu from '../../../../shared-components/card-menu/QCXCardMenu';

const useStyles = makeStyles((theme) => ({
  cardMenuContainer: {
    marginTop: theme.spacing(0),
  },
  cardMenu: {
    width: theme.spacing(100),
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  subNested: {
    paddingLeft: theme.spacing(8),
  },
  active: {
    display: 'flex!important',
  },
}));

export default function FinancialRegistrationsPage({
  authInfo = {},
}) {
  const { t } = useTranslation();

  const userHasAnyRole = (mainRoles = []) => authInfo.roles.some(
    (role) => mainRoles.find((item) => role.startsWith(item))
  );
  const classes = useStyles();

  const dispatch = useDispatch();

  const {
    location: {
      pathname: currentLocationPath,
    },
  } = useHistory();

  const pageTitle = t('com.muralis.qcx.cadastrosFinanceiros');

  useEffect(() => {
    const handleFocusLock = () => {
      const currentPaths = currentLocationPath.split('/');

      const match = (modulePaths) => (
        modulePaths.every(
          (path) => currentPaths.includes(path)
        )
      );

      if (match(CADASTROS_FINANCEIROS_MODULE)) {
        dispatch(focusLockActions.lock());
      }
    };

    handleFocusLock();
  }, [currentLocationPath]);

  const breadcrumbs = [
    {
      link: {
        to: '/',
        name: t('com.muralis.qcx.inicio'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.cadastros'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.financeiro.labelPlural'),
      },
    },
  ];

  return (
    <QCXMainLayout
      pageIcon={<DataUsageIcon />}
      pageTitle={pageTitle}
      breadcrumbs={breadcrumbs}
      authInfo={authInfo}
    >
      <Grid
        className={classes.cardMenuContainer}
        item
        container
        justify="center"
        xs={12}
        sm={12}
        md={12}
        xl={12}
      >
        <QCXFocusLock>
          <Grid container style={{ maxWidth: '1920px' }}>
            {userHasAnyRole(['despesa-receita']) && (
            <Grid
              item
              justify="flex-start"
              xs={6}
              sm={4}
              md={3}
              lg={3}
              xl={2}
              className={classes.active}
            >
              <QCXCardMenu
                title={t('com.muralis.qcx.despesa.despesasReceitas')}
                link={t('com.muralis.qcx.url.modulosFinanceirosDespesaReceita')}
                icon={ExpenseIcon}
              />

            </Grid>
            )}

            {userHasAnyRole(['moeda']) && (
            <Grid
              item
              justify="center"
              xs={6}
              sm={4}
              md={3}
              lg={3}
              xl={2}
              className={classes.active}
            >
              <QCXCardMenu
                title={t('com.muralis.qcx.moeda.labelPlural')}
                link={t('com.muralis.qcx.url.modulosFinanceirosMoeda')}
                icon={MonetizationOnOutlinedIcon}
              />

            </Grid>
            )}

            {userHasAnyRole(['cotacao']) && (
            <Grid
              item
              justify="center"
              xs={6}
              sm={4}
              md={3}
              lg={3}
              xl={2}
              className={classes.active}
            >
              <QCXCardMenu
                title={t('com.muralis.qcx.cotacaoMoeda.labelPlural')}
                link={t('com.muralis.qcx.url.modulosFinanceirosCotacoesMoeda')}
                icon={LocalAtmIcon}
              />

            </Grid>
            )}

            {userHasAnyRole(['banco']) && (
            <Grid
              item
              justify="center"
              xs={6}
              sm={4}
              md={3}
              lg={3}
              xl={2}
              className={classes.active}
            >
              <QCXCardMenu
                title={t('com.muralis.qcx.banco.labelPlural')}
                link={t('com.muralis.qcx.url.modulosFinanceirosBanco')}
                icon={AiFillBank}
              />

            </Grid>
            )}

            {userHasAnyRole(['conta-bancaria']) && (
            <Grid
              item
              justify="center"
              xs={6}
              sm={4}
              md={3}
              lg={3}
              xl={2}
              className={classes.active}
            >
              <QCXCardMenu
                title={t('com.muralis.qcx.contaBancaria.labelPlural')}
                link={t('com.muralis.qcx.url.modulosFinanceirosContasBancarias')}
                icon={RiSafe2LineIcon}
              />

            </Grid>
            )}

            {userHasAnyRole(['plano-de-conta']) && (
            <Grid
              item
              justify="center"
              xs={6}
              sm={4}
              md={3}
              lg={3}
              xl={2}
              className={classes.active}
            >
              <QCXCardMenu
                title={t('com.muralis.qcx.planoConta.labelPlural')}
                link={t('com.muralis.qcx.url.modulosFinanceirosPlanoContas')}
                icon={AccountBalanceWalletIcon}
              />
            </Grid>
            )}
            {userHasAnyRole(['fornecedor-favorecido']) && (
            <Grid
              item
              justify="center"
              xs={6}
              sm={4}
              md={3}
              lg={3}
              xl={2}
              className={classes.active}
            >
              <QCXCardMenu
                title={t('com.muralis.qcx.fornecedor.label')}
                link={t('com.muralis.qcx.url.modulosFinanceirosFornecedor')}
                icon={LocalShippingIcon}
              />
            </Grid>
            )}
          </Grid>
        </QCXFocusLock>
      </Grid>
    </QCXMainLayout>
  );
}
