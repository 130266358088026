const selectEtapaFollowUp = (state) => state.etapaFollowUp.model;
const selectEtapasFollowUp = (state) => state.etapaFollowUp.list;
const selectRelatedFollowUpModel = (state) => state.etapaFollowUp.related.model.etapaFollowUp;
const selectStatus = (state) => state.etapaFollowUp.status;
const selectRefresh = (state) => state.etapaFollowUp.refresh;
const selectMode = (state) => state.etapaFollowUp.mode.main;
const selectBackgroundMode = (state) => state.etapaFollowUp.mode.background;
const selectSubMode = (state) => state.etapaFollowUp.mode.sub;
const selectError = (state) => state.etapaFollowUp.error;
const selectResponse = (state) => state.etapaFollowUp.response;

const etapaFollowUpSelectors = {
  selectEtapaFollowUp,
  selectEtapasFollowUp,
  selectRelatedFollowUpModel,
  selectStatus,
  selectRefresh,
  selectMode,
  selectBackgroundMode,
  selectSubMode,
  selectError,
  selectResponse,
};

export {
  etapaFollowUpSelectors,
  selectEtapaFollowUp,
  selectEtapasFollowUp,
  selectRelatedFollowUpModel,
  selectStatus,
  selectRefresh,
  selectMode,
  selectBackgroundMode,
  selectSubMode,
  selectError,
  selectResponse,
};
