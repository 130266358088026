import { createFilterOptions } from '@material-ui/lab/useAutocomplete';
import _ from 'lodash';
import {
  useEffect,
  useState,
} from 'react';
import { useFormState } from 'react-final-form';
import QCXAutocompleteSelect from '../../autocomplete-select/QCXAutocompleteSelect';

export default function BaseSelectWithOptions({
  label,
  name,
  list,
  ...restProps
}) {
  const [defaultValue, setDefaultValue] = useState('');

  const { initialValues } = useFormState();

  useEffect(() => {
    const value = list.find((item) => item?.id === _.get(initialValues, name));
    if (value) {
      setDefaultValue(value);
    } else {
      setDefaultValue({ id: null, description: '' });
    }
  }, [list, initialValues]);

  const filterOptions = createFilterOptions({
    stringify: (item) => `${item.label}`,
  });

  return (
    <QCXAutocompleteSelect
      label={label}
      id={name}
      name={name}
      options={list}
      filterOptions={filterOptions}
      defaultValue={defaultValue}
      getOptionLabel={(option) => option.label}
      getOptionValue={(option) => option.value}
      {...restProps}
    />
  );
}
