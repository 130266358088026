import { IconButton } from '@material-ui/core';
import {
  Delete as DeleteIcon, Search as PageviewIcon,
} from '@material-ui/icons';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { userHasDeleteRole, userHasRole } from '../../../../../components/perfil/perfil-utils';
import PerfilContext from '../../../../../contexts/registrations/perfil/PerfilContext';
import {
  changeToConsultMode,
  changeToCreateMode,
  fetchAllAsync,
  inactivateByIdAsync,
  resetBackgroundMode,
  resetMode,
  resetModel,
  selectPerfis,
} from '../../../../../features/perfil/perfilSlice';
import { resetList as resetUnidadeNegocioList } from '../../../../../features/unidade-negocio/unidadeNegocioSlice';
import { fetchAllAsync as fetchAllGruposAsync, selectGruposUnidadeNegocio } from '../../../../../features/grupo-unidade-negocio/grupoUnidadeNegocioSlice';
import QCXSimpleConsultPageTemplate from '../../../../../templates/simple-consult-page/QCXSimpleConsultPageTemplate';
import useOperationController from '../../../../../utils/hooks/store/operation/useOperationController';
import { isAlternativeLoadingStatus } from '../../../../../utils/store/store-utils';

export default function PerfilConsultPage({ authInfo = {} }) {
  const { t } = useTranslation();

  const history = useHistory();
  const dispatch = useDispatch();

  const {
    status,
  } = useContext(PerfilContext);

  const perfis = useSelector(selectPerfis);
  const grupos = useSelector(selectGruposUnidadeNegocio);

  const isAlternativeLoading = useMemo(() => (
    isAlternativeLoadingStatus(status)
  ), [status]);

  const [grupoRole, setGrupoRole] = useState(false);
  const fetchAll = useCallback(() => {
    dispatch(fetchAllAsync());
    const userHasGrupoRole = userHasRole(authInfo, 'grupo-incluir');
    setGrupoRole(userHasGrupoRole);
    if (userHasGrupoRole) {
      dispatch(fetchAllGruposAsync());
    }
  }, []);

  const resetModes = useCallback(() => {
    dispatch(resetMode());
    dispatch(resetBackgroundMode());
  }, []);

  const resetModels = useCallback(() => {
    dispatch(resetModel());
  }, []);

  const resetDependencies = useCallback(() => {
    dispatch(resetUnidadeNegocioList());
  }, []);

  useEffect(() => {
    fetchAll();
    resetModes();
    resetModels();
    resetDependencies();
  }, []);

  const handleActivateInactivate = useOperationController(
    async (event, id) => {
      event.preventDefault();

      dispatch(inactivateByIdAsync(id));
    }
  );

  const handleClickOnButtonNew = useCallback((event) => {
    event.stopPropagation();
    dispatch(changeToCreateMode());
  }, []);

  const handleClickOnEditButton = useCallback((event, id) => {
    event.stopPropagation();
    dispatch(changeToConsultMode());
    history.push(t('com.muralis.qcx.url.moduloGeraisPerfisDetalhes', { id }));
  }, [history]);

  const columns = [

    {
      field: 'nome',
      headerName: t('com.muralis.qcx.nome'),
      type: 'string',
      flex: 250,
      valueGetter: ({ row }) => (
        row?.nome
      ),
    },
    {
      field: 'actions',
      headerName: t('com.muralis.qcx.acoes.label'),
      headerAlign: 'center',
      align: 'center',
      flex: 150,
      renderCell: ({ row: { id, active } }) => (
        <>
          {userHasDeleteRole(authInfo, 'perfil') && (
            <IconButton
              key={`stw-active${id}`}
              value={active}
              name={`stw-active-${id}`}
              onClick={(event) => handleActivateInactivate(event, id)}
            >
              <DeleteIcon color="error" />
            </IconButton>
          )}
          <IconButton
            key={`btn-view-${id}`}
            name={`btn-view-${id}`}
            onClick={(event) => handleClickOnEditButton(event, id)}
          >
            <PageviewIcon color="primary" />
          </IconButton>
        </>
      ),
    }
  ];
  if (grupoRole) {
    columns.splice(1, 0, {
      field: 'grupo',
      headerName: t('com.muralis.qcx.grupo'),
      hide: !grupoRole,
      flex: 135,
      valueGetter: ({ row }) => (
        grupos.find((item) => item.id === row?.atributos?.grupo?.[0]?.id)?.description
      ),
    });
  }
  const breadcrumbs = [
    {
      link: {
        to: '/',
        name: t('com.muralis.qcx.inicio'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.cadastros'),
      },
    },
    {
      link: {
        to: t('com.muralis.qcx.url.moduloCadastrosGerais'),
        name: t('com.muralis.qcx.gerais'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.perfis.labelPlural'),
      },
    },
  ];

  const templateProps = {
    page: {
      title: t('com.muralis.qcx.perfis.labelPlural'),
      breadcrumbs,
    },
    control: {
      buttonNew: {
        description: t('com.muralis.qcx.perfis.novoPerfil'),
        path: t('com.muralis.qcx.url.moduloGeraisPerfisNovo'),
        onClick: handleClickOnButtonNew,
      },
    },
    table: {
      columns,
    },
  };

  return (
    <QCXSimpleConsultPageTemplate
      pageProps={templateProps.page}
      controlProps={templateProps.control}
      tableProps={templateProps.table}
      tableData={perfis}
      isLoading={isAlternativeLoading}
      filterProp="nome"
      authInfo={authInfo}
      requiredRoles={['perfil']}
    />
  );
}
