import { useKeycloak } from "@react-keycloak/web";
import { KeycloakResourceAccess, KeycloakTokenParsed } from "keycloak-js";
import { useMemo } from "react";

export interface CustomResources extends KeycloakResourceAccess {
  api: {
    roles: string[];
  };
}

export interface TokenWithAttributes extends KeycloakTokenParsed {
  attributes: {
    modulosAcesso: string[];
  };
}

export const useHasPermission = (permission: string): boolean => {
  const { keycloak } = useKeycloak();

  const hasPermission = useMemo(() => {
    if (!keycloak.tokenParsed) {
      return false;
    }

    const parsedToken = keycloak.tokenParsed as TokenWithAttributes;

    if (!parsedToken.attributes) {
      return false;
    }

    if (parsedToken.attributes.modulosAcesso.includes(permission)) {
      return true;
    }

    if (!parsedToken.resource_access) {
      return false;
    }

    const resources = parsedToken.resource_access as CustomResources;

    if (resources.api.roles.includes(permission)) {
      return true;
    }

    return false;
  }, [keycloak]);

  return hasPermission;
};
