import {
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  makeStyles,
} from "@material-ui/core";
import {
  AddCircle as AddCircleIcon,
  Clear as ClearIcon,
  Delete as DeleteIcon,
  MoreHoriz as MoreHorizIcon,
  Search as PageviewIcon,
  Save as SaveIcon,
} from "@material-ui/icons";
import {} from "lodash";
import { bindMenu, bindTrigger } from "material-ui-popup-state";
import { ReactNode, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import QCXPopupState from "../../../../../components/popup-state/QCXPopupState";
import QCXListItemIcon from "../../../../../shared-components/list-item-icon/QCXListItemIcon";
import QCXCircularProgress from "../../../../../shared-components/progress/QCXCircularProgress";
import StyledButton from "../../../../common/components/Buttons/StyledButton";
import TextFieldStyled from "../../../../common/components/input/textFieldStyled";
import StyledDataGrid from "../../assessoriaFollowupPage/dataGrid/styledDataGrid";
import { GridCellParams, GridColDef } from "@material-ui/data-grid";

const useStyles = makeStyles(() => ({
  dataGridDiv: {
    width: "calc(100% - 0px) !important",
  },
}));

export interface ReferenciaBondManagerProps {
  disabled?: boolean;
  values: string[];
  changeValuesCallback: (newValues: string[]) => void;
}

export default function ReferenciaBondManager({
  values,
  changeValuesCallback,
  disabled,
}: ReferenciaBondManagerProps) {
  const { t } = useTranslation();
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);

  const [newReferencia, setNewReferencia] = useState("");
  const [currentReferencia, setCurrentReferencia] = useState("");
  const [isUpdate, isCreate] = useMemo(
    () => [currentReferencia !== "", currentReferencia === ""],
    [currentReferencia]
  );

  const handleConsultVinculo = (id: string) => {
    setCurrentReferencia(id);
    setNewReferencia(id);
  };

  const handleVincular = () => {
    setIsLoading(true);

    if (values.includes(newReferencia)) {
      //Throw message
      setIsLoading(false);
      return;
    }

    let newValues = [...values, newReferencia];
    if (currentReferencia) {
      newValues = newValues.filter((value) => value != currentReferencia);
    }

    changeValuesCallback(newValues);
    setNewReferencia("");
    setCurrentReferencia("");
    setIsLoading(false);
  };

  const handleRemoveVinculo = (id: string) => {
    setIsLoading(true);
    const newValues = values.filter((value) => value != id);
    changeValuesCallback(newValues);
    setIsLoading(false);
  };

  const handleClearCurrentVinculo = () => {
    setCurrentReferencia("");
    setNewReferencia("");
  };

  const dataGridColumns: GridColDef[] = [
    {
      field: "referencia",
      headerName: t("com.muralis.qcx.referencia"),
      headerAlign: "left",
      align: "left",
      flex: 140,
      valueGetter: ({ row }: any) => row?.referencia || "",
    },
    {
      field: "actions",
      headerName: t("com.muralis.qcx.acoes.label"),
      headerAlign: "center",
      align: "center",
      flex: 90,
      renderCell: ({ row }: GridCellParams) => (
        <QCXPopupState popupId={`popup-menu-bond-manager-${row?.id}`}>
          {(popupState: any) => (
            <>
              <IconButton
                key={`btn-more-options-${row?.id}`}
                name={`btn-more-options-${row?.id}`}
                {...bindTrigger(popupState)}
              >
                <MoreHorizIcon color="secondary" />
              </IconButton>
              <Menu {...bindMenu(popupState)}>
                {
                  <MenuItem
                    key={`btn-consulta-${row?.id}`}
                    onClick={(event) => {
                      popupState.close(event);

                      handleConsultVinculo(row.id);
                    }}
                  >
                    <QCXListItemIcon>
                      <PageviewIcon fontSize="small" />
                    </QCXListItemIcon>
                    <Typography
                      variant="inherit"
                      style={{
                        fontSize: 12,
                      }}
                    >
                      {t("com.muralis.qcx.acoes.consultar").toUpperCase()}
                    </Typography>
                  </MenuItem>
                }
                <MenuItem
                  key={`btn-excluir-${row?.id}`}
                  onClick={(event) => {
                    popupState.close(event);

                    handleRemoveVinculo(row.id);
                  }}
                >
                  <QCXListItemIcon>
                    <DeleteIcon fontSize="small" />
                  </QCXListItemIcon>
                  <Typography
                    variant="inherit"
                    style={{
                      fontSize: 12,
                    }}
                  >
                    {t("com.muralis.qcx.acoes.excluir").toUpperCase()}
                  </Typography>
                </MenuItem>
              </Menu>
            </>
          )}
        </QCXPopupState>
      ),
    },
  ];

  return (
    <>
      <Grid item container spacing={2}>
        <Grid item xs={12} sm={8}>
          <TextFieldStyled
            disabled={disabled}
            value={newReferencia}
            label={"Referência do Cliente"}
            onChange={(e) => setNewReferencia(e.target.value)}
          ></TextFieldStyled>
        </Grid>

        {isCreate && (
          <Grid item xs={12} sm={4} md={4}>
            <StyledButton
              id="control-box-add-button"
              key="control-box-add-button"
              color="secondary"
              onClick={() => handleVincular()}
              disabled={isLoading || disabled}
              fullWidth
              tooltip
              tooltipDescription={t("com.muralis.qcx.acoes.adicionar")}
              startIcon={!isLoading && <AddCircleIcon />}
            >
              {isLoading ? (
                <QCXCircularProgress color="disabled" size={20} />
              ) : (
                (t("com.muralis.qcx.acoes.adicionar") as unknown as ReactNode)
              )}
            </StyledButton>
          </Grid>
        )}
        {isUpdate && (
          <Grid item xs={6} sm={2} md={2}>
            <StyledButton
              color="secondary"
              onClick={() => handleVincular()}
              disabled={isLoading}
              fullWidth
              tooltip
              tooltipDescription={t("com.muralis.qcx.acoes.atualizar")}
            >
              {isLoading ? (
                <QCXCircularProgress color="disabled" size={20} />
              ) : (
                <SaveIcon />
              )}
            </StyledButton>
          </Grid>
        )}
        {isUpdate && (
          <Grid item xs={6} sm={2} md={2}>
            <StyledButton
              variant="outlined"
              onClick={handleClearCurrentVinculo}
              tooltip
              tooltipDescription={t("com.muralis.qcx.acoes.limpar")}
              fullWidth
              disabled={isLoading}
            >
              <ClearIcon />
            </StyledButton>
          </Grid>
        )}
      </Grid>
      <Grid item xs={12}>
        <StyledDataGrid
          columns={dataGridColumns}
          rows={values.map((value) => ({
            id: value,
            referencia: value,
          }))}
          divProps={{
            className: classes?.dataGridDiv,
          }}
        />
      </Grid>
    </>
  );
}
