import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@material-ui/core';
import {
  Search as PageviewIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import QCXSimpleConsultPageTemplate from '../../../../../templates/simple-consult-page/QCXSimpleConsultPageTemplate';
import ServicoContext from '../../../../../contexts/registrations/servico/ServicoContext';
import useOperationController from '../../../../../utils/hooks/store/operation/useOperationController';
import { isAlternativeLoadingStatus } from '../../../../../utils/store/store-utils';
import {
  activateByIdAsync,
  changeToConsultMode,
  changeToCreateMode,
  fetchAllAsync,
  inactivateByIdAsync,
  resetBackgroundMode,
  resetMode,
  resetModel,
  resetSubMode,
  selectServicos,
} from '../../../../../features/servico/servicoSlice';
import { resetList as resetViasTransporte } from '../../../../../features/via-transporte/viaTransporteSlice';
import { resetList as resetTiposDeclaracao } from '../../../../../features/tipo-declaracao/tipoDeclaracaoSlice';
import { resetList as resetUnidadesNegocio } from '../../../../../features/unidade-negocio/unidadeNegocioSlice';
import { resetList as resetModelosNumerario } from '../../../../../features/modelo-numerario/modeloNumerarioSlice';
import { resetList as resetModelosFaturamento } from '../../../../../features/modelo-faturamento/modeloFaturamentoSlice';
import { resetList as resetTabelasSda } from '../../../../../features/tabela-sda/tabelaSdaSlice';
import { resetList as resetModelosFollowUp } from '../../../../../features/modelo-follow-up/modeloFollowUpSlice';
import { TipoProdutoUtils } from '../../../../../shared-components/select-tipo-produto/tipoProdutoUtils';
import { userHasDeleteRole } from '../../../../../components/perfil/perfil-utils';

export default function ServicoConsultPage({ authInfo = {} }) {
  const history = useHistory();
  const { t } = useTranslation();

  const {
    status,
  } = useContext(ServicoContext);

  const dispatch = useDispatch();

  const servicos = useSelector(selectServicos);

  const isAlternativeLoading = useMemo(() => (
    isAlternativeLoadingStatus(status)
  ), [status]);

  const fetchAll = useCallback(() => {
    dispatch(fetchAllAsync());
  }, []);

  const resetModes = useCallback(() => {
    dispatch(resetMode());
    dispatch(resetBackgroundMode());
    dispatch(resetSubMode());
  }, []);

  const resetModels = useCallback(() => {
    dispatch(resetModel());
  }, []);

  const resetDependencies = useCallback(() => {
    dispatch(resetViasTransporte());
    dispatch(resetTiposDeclaracao());
    dispatch(resetUnidadesNegocio());
    dispatch(resetModelosNumerario());
    dispatch(resetModelosFaturamento());
    dispatch(resetTabelasSda());
    dispatch(resetModelosFollowUp());
  }, []);

  useEffect(() => {
    fetchAll();
    resetModes();
    resetModels();
    resetDependencies();
  }, []);

  const handleActivateInactivate = useOperationController(
    async (event, id) => {
      event.preventDefault();

      const { active } = servicos.find(
        (servico) => servico.id === id
      );

      const isActivation = !active;

      if (isActivation) {
        dispatch(activateByIdAsync(id));
      } else {
        dispatch(inactivateByIdAsync(id));
      }
    }
  );

  const handleClickOnButtonNew = useCallback((event) => {
    event.stopPropagation();
    dispatch(changeToCreateMode());
  }, []);

  const handleClickOnEditButton = useCallback((event, id) => {
    event.stopPropagation();
    dispatch(changeToConsultMode());
    history.push(t('com.muralis.qcx.url.moduloGeraisServicosDetalhes', { id }));
  }, [history]);

  const columns = [
    {
      field: 'id',
      headerName: t('com.muralis.qcx.codigo'),
      headerAlign: 'center',
      align: 'center',
      flex: 80,
      valueGetter: ({ row }) => (
        row?.id
      ),
    },
    {
      field: 'nome',
      headerName: t('com.muralis.qcx.nome'),
      flex: 180,
      valueGetter: ({ row }) => (
        row?.nome
      ),
    },

    {
      field: 'sigla',
      headerName: t('com.muralis.qcx.sigla'),
      flex: 90,
      valueGetter: ({ row }) => (
        row?.sigla
      ),
    },
    {
      field: 'produto',
      headerName: t('com.muralis.qcx.produto'),
      flex: 115,
      valueGetter: ({ row }) => (
        TipoProdutoUtils.getLabelByValue(row?.produto) || '-'
      ),
    },
    {
      field: 'leadTimeTotal',
      headerName: t('com.muralis.qcx.leadTimeTotal'),
      flex: 160,
      valueGetter: ({ row }) => (
        row?.leadTimeTotal
      ),
    },
    {
      field: 'viaTransporte',
      headerName: t('com.muralis.qcx.transporte.viaTransporte'),
      type: 'string',
      flex: 170,
      valueGetter: ({ row }) => row?.viaTransporte?.description,
    },
    {
      field: 'actions',
      headerName: t('com.muralis.qcx.acoes.label'),
      headerAlign: 'center',
      align: 'center',
      flex: 96,
      renderCell: ({ row: { id, active } }) => (
        <>
          {' '}
          {userHasDeleteRole(authInfo, 'servico') && (
          <IconButton
            key={`stw-active${id}`}
            value={active}
            name={`stw-active-${id}`}
            onClick={(event) => handleActivateInactivate(event, id)}
          >
            <DeleteIcon color="error" />
          </IconButton>
          )}
          <IconButton
            key={`btn-view-${id}`}
            name={`btn-view-${id}`}
            onClick={(event) => handleClickOnEditButton(event, id)}
          >
            <PageviewIcon color="primary" />
          </IconButton>
        </>
      ),
    },
  ];

  const breadcrumbs = [
    {
      link: {
        to: '/',
        name: t('com.muralis.qcx.inicio'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.cadastros'),
      },
    },
    {
      link: {
        to: t('com.muralis.qcx.url.moduloCadastrosGerais'),
        name: t('com.muralis.qcx.gerais'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.servico.labelPlural'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.principal'),
      },
    },
  ];

  const templateProps = {
    page: {
      title: t('com.muralis.qcx.servico.labelPlural'),
      breadcrumbs,
    },
    control: {
      buttonNew: {
        description: t('com.muralis.qcx.servico.novoServico'),
        path: t('com.muralis.qcx.url.moduloGeraisServicosNovo'),
        onClick: handleClickOnButtonNew,
      },
    },
    table: {
      columns,
    },
  };

  const filterProps = [
    'id',
    'nome',
    'sigla',
    'viaTransporte.description',
  ];

  return (
    <QCXSimpleConsultPageTemplate
      pageProps={templateProps.page}
      controlProps={templateProps.control}
      tableProps={templateProps.table}
      tableData={servicos}
      isLoading={isAlternativeLoading}
      multipleFilter
      filterProps={filterProps}
      authInfo={authInfo}
      requiredRoles={['servico']}
    />
  );
}
