import { Grid } from '@material-ui/core';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import QCXFieldsViewerManager from '../../../../../../components/fields-viewer-manager/QCXFieldsViewerManager';
import { selectMode } from '../../../../../../features/follow-up/followUpSelectors';
import {
  vinculoReferenciaClienteFollowUpActions,
  vinculoReferenciaClienteFollowUpSelectors,
} from '../../../../../../features/vinculo-referencia-cliente-follow-up/vinculoReferenciaClienteFollowUpSlice';
import QCXFinalBondManagerOld from '../../../../../../shared-components/final-gerenciador-relacionamento/QCXFinalBondManagerOld';
import QCXTextFieldViewer from '../../../../../../shared-components/text-field/QCXTextFieldViewer';
import FollowUpStatusUtils from '../../../../../../utils/general/follow-up/FollowUpStatusUtils';
import { formatCnpj } from '../../../../../../utils/hooks/form/field/formatters';
import { isConsultMode } from '../../../../../../utils/store/store-utils';

export default function FollowUpListFieldsViewerManager({ model, ...restProps }) {
  const { t } = useTranslation();

  const mode = useSelector(selectMode);

  const isConsult = useMemo(() => isConsultMode(mode), [mode]);

  const referenciaClienteListProps = useMemo(
    () => ({
      name: 'referencias',
      columns: [
        {
          field: 'referencia',
          headerName: t('com.muralis.qcx.referencia'),
          headerAlign: 'left',
          align: 'left',
          flex: 140,
          valueGetter: ({ row }) => row?.referencia || '',
        },
      ],
    }),
    []
  );

  const referenciaClienteReducerConfig = useMemo(
    () => ({
      selectors: {
        selectStatus: vinculoReferenciaClienteFollowUpSelectors.selectStatus,
        selectMode: vinculoReferenciaClienteFollowUpSelectors.selectMode,
        selectModel: vinculoReferenciaClienteFollowUpSelectors.selectModel,
      },
      actions: {
        loading: vinculoReferenciaClienteFollowUpActions.loading,
        resetStatus: vinculoReferenciaClienteFollowUpActions.resetStatus,
        changeToUpdateMode: vinculoReferenciaClienteFollowUpActions.changeToUpdateMode,
        changeToCreateMode: vinculoReferenciaClienteFollowUpActions.changeToCreateMode,
        resetMode: vinculoReferenciaClienteFollowUpActions.resetMode,
        setModel: vinculoReferenciaClienteFollowUpActions.setModel,
        resetModel: vinculoReferenciaClienteFollowUpActions.resetModel,
      },
    }),
    []
  );

  const formattedValues = useMemo(
    () => ({
      ...model,
      importador: {
        ...model?.importador,
        pessoa: {
          ...model?.importador?.pessoa,
          cnpj: formatCnpj(model?.importador?.pessoa?.cnpj),
        },
      },
      status: FollowUpStatusUtils.getLabelByValue(model?.status)
        ? t(FollowUpStatusUtils.getLabelByValue(model?.status))
        : '-',
    }),
    [model]
  );

  return (
    <QCXFieldsViewerManager values={formattedValues} disableHelperText disabled={isConsult} {...restProps}>
      {(fieldsViewerProps) => (
        <Grid item container spacing={2}>
          <Grid item xs={12} sm={4} md={3} lg={2}>
            <QCXTextFieldViewer
              key="text-field-processo-viewer"
              id="text-field-processo-viewer"
              label={t('com.muralis.qcx.processo')}
              name="numero"
              {...fieldsViewerProps}
            />
          </Grid>
          <Grid item xs={12} sm={8} md={6} lg={4}>
            <QCXTextFieldViewer
              key="text-field-cliente-viewer"
              id="text-field-cliente-viewer"
              label={t('com.muralis.qcx.cliente.label')}
              name="importador.pessoa.nome"
              {...fieldsViewerProps}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3} lg={2}>
            <QCXTextFieldViewer
              key="text-field-cnpj-viewer"
              id="text-field-cnpj-viewer"
              label={t('com.muralis.qcx.empresa.CNPJ')}
              name="importador.pessoa.cnpj"
              {...fieldsViewerProps}
            />
          </Grid>
          <Grid item xs={12} sm={8} md={3} lg={4}>
            <QCXTextFieldViewer
              key="text-field-status-viewer"
              id="text-field-status-viewer"
              label={t('com.muralis.qcx.status')}
              name="status"
              {...fieldsViewerProps}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <QCXTextFieldViewer
              key="text-field-servico"
              id="text-field-servico"
              label={t('com.muralis.qcx.servico.labelSingular')}
              name="servico.description"
              {...fieldsViewerProps}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <QCXTextFieldViewer
              key="text-field-mawb-viewer"
              id="text-field-mawb-viewer"
              label={t('com.muralis.qcx.numeroConhecimentoMaster')}
              name="numeroConhecimentoMaster"
              {...fieldsViewerProps}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <QCXTextFieldViewer
              key="text-field-hawb-viewer"
              id="text-field-hawb-viewer"
              label={t('com.muralis.qcx.numeroConhecimentoHouse')}
              name="numeroConhecimentoHouse"
              {...fieldsViewerProps}
            />
          </Grid>
          <QCXFinalBondManagerOld
            isParentConsult={isConsult}
            listProps={referenciaClienteListProps}
            formProps={{}}
            reducerConfig={referenciaClienteReducerConfig}
            disabled
            onlyShowFieldOnEdit
            hideAddButton
          />
        </Grid>
      )}
    </QCXFieldsViewerManager>
  );
}
