const CLIENTE_IMPORTADOR = 'IMPORTADOR';
const CLIENTE_EXPORTADOR = 'EXPORTADOR';

const ClienteUtils = Object.freeze({
  IMPORTADOR: CLIENTE_IMPORTADOR,
  EXPORTADOR: CLIENTE_EXPORTADOR,
});

export {
  ClienteUtils,
  CLIENTE_EXPORTADOR,
  CLIENTE_IMPORTADOR,
};
