import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { IconButton } from '@material-ui/core';
import {
  LocalAtm as LocalAtmIcon,
  Search as PageviewIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import QCXSimpleConsultPageTemplate from '../../../../../templates/simple-consult-page/QCXSimpleConsultPageTemplate';
import CotacaoMoedaContext from '../../../../../contexts/registrations/cotacao-moeda/CotacaoMoedaContext';
import {
  activateByIdAsync,
  changeToConsultMode,
  changeToCreateMode,
  fetchAllAsync,
  inactivateByIdAsync,
  resetBackgroundMode,
  resetMode,
  resetModel,
  selectCotacoesMoeda,
} from '../../../../../features/cotacao-moeda/cotacaoMoedaSlice';
import {
  ALTERNATIVE_LOADING_STATUS,
} from '../../../../../features/status';
import useOperationController from '../../../../../utils/hooks/store/operation/useOperationController';
import { unnormalizeNumeral } from '../../../../../utils/general/general-utils';
import { formatBrazilianNumericDecimal } from '../../../../../utils/hooks/form/field/formatters';
import { userHasDeleteRole } from '../../../../../components/perfil/perfil-utils';

export default function CotacaoMoedaConsultPage({ authInfo = {} }) {
  const { t } = useTranslation();

  const history = useHistory();

  const {
    status,
  } = useContext(CotacaoMoedaContext);

  const dispatch = useDispatch();

  const cotacoesMoeda = useSelector(selectCotacoesMoeda);

  const isAlternativeLoading = useMemo(() => status === ALTERNATIVE_LOADING_STATUS, [status]);

  const fetchAll = async () => {
    dispatch(fetchAllAsync());
  };

  const resetModes = useCallback(() => {
    dispatch(resetMode());
    dispatch(resetBackgroundMode());
  }, []);

  const resetModels = useCallback(() => {
    dispatch(resetModel());
  }, []);

  useEffect(() => {
    fetchAll();
    resetModes();
    resetModels();
  }, []);

  const handleActivateInactivate = useOperationController(async (event, id) => {
    event.preventDefault();

    const { active } = cotacoesMoeda.find(
      (item) => item.id === id
    );

    const isActivation = !active;

    if (isActivation) {
      dispatch(activateByIdAsync(id));
    } else {
      dispatch(inactivateByIdAsync(id));
    }
  });

  const handleClickOnEditButton = useCallback(
    (event, id) => {
      event.stopPropagation();

      dispatch(changeToConsultMode());
      history.push(t('com.muralis.qcx.url.modulosFinanceirosCotacoesMoedaDetalhes', { id }));
    }, [history]
  );

  const breadcrumbs = [
    {
      link: {
        to: '/',
        name: t('com.muralis.qcx.inicio'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.cadastros'),
      },
    },
    {
      link: {
        to: t('com.muralis.qcx.url.moduloCadastrosFinanceiros'),
        name: t('com.muralis.qcx.financeiro.labelPlural'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.cotacaoMoeda.labelPlural'),
      },
    },
  ];

  const columns = [
    {
      field: 'id',
      headerName: t('com.muralis.qcx.codigo'),
      align: 'center',
      type: 'string',
      flex: 85,
      valueGetter: ({ row }) => (
        row?.id
      ),
    },
    {
      field: 'cambio',
      headerName: t('com.muralis.qcx.cotacaoMoeda.taxaCambial'),
      headerAlign: 'center',
      align: 'center',
      flex: 150,
      valueFormatter: ({ row }) => (
        unnormalizeNumeral(
          row?.cambio,
          formatBrazilianNumericDecimal(5)
        )
      ),
    },
    {
      field: 'moeda.code',
      headerName: t('com.muralis.qcx.codigoMoeda'),
      headerAlign: 'center',
      align: 'center',
      flex: 165,
      valueGetter: ({ row }) => (
        row?.moeda.code
      ),
    },
    {
      field: 'moeda.description',
      headerName: t('com.muralis.qcx.moeda.descricaoMoeda'),
      flex: 155,
      valueGetter: ({ row }) => (
        row?.moeda.description
      ),
    },
    {
      field: 'percentualMarkup',
      headerName: t('com.muralis.qcx.percentualMarkup'),
      headerAlign: 'center',
      flex: 100,
      type: 'float',
      align: 'center',
      valueFormatter: ({ row }) => (
        unnormalizeNumeral(
          row?.percentualMarkup,
          formatBrazilianNumericDecimal(2)
        )
      ),
    },
    {
      field: 'insertionDate',
      headerName: t('com.muralis.qcx.cotacaoMoeda.dataCotacao'),
      headerAlign: 'center',
      align: 'center',
      type: 'dateTime',
      flex: 165,
      valueFormatter: ({ row }) => {
        const dataCriacao = moment(row?.data);
        return dataCriacao.format('DD/MM/YYYY');
      },
    },
    {
      field: 'actions',
      headerName: t('com.muralis.qcx.acoes.label'),
      headerAlign: 'center',
      flex: 120,
      align: 'center',
      renderCell: ({ row: { id, active } }) => (
        <>
          {userHasDeleteRole(authInfo, 'moeda') && (
          <IconButton
            key={`stw-active${id}`}
            value={active}
            name={`stw-active-${id}`}
            onClick={(event) => handleActivateInactivate(event, id)}
          >
            <DeleteIcon color="error" />
          </IconButton>
          )}
          <IconButton
            key={`btn-view-${id}`}
            name={`btn-view-${id}`}
            onClick={(event) => handleClickOnEditButton(event, id)}
          >
            <PageviewIcon color="primary" />
          </IconButton>
        </>
      ),
    },
  ];

  const handleClickOnButtonNew = useCallback((event) => {
    event.stopPropagation();
    dispatch(changeToCreateMode());
  }, []);

  const templateProps = {
    page: {
      title: t('com.muralis.qcx.cotacaoMoeda.labelPlural'),
      icon: <LocalAtmIcon />,
      breadcrumbs,
    },
    control: {
      buttonNew: {
        description: t('com.muralis.qcx.cotacaoMoeda.novaCotacaoMoeda'),
        path: t('com.muralis.qcx.url.modulosFinanceirosCotacoesMoedaNova'),
        onClick: handleClickOnButtonNew,
      },
    },
    table: {
      columns,
    },
  };

  const filterPropGetter = useCallback(
    (cotacaoMoeda) => cotacaoMoeda.description,
    []
  );

  return (
    <QCXSimpleConsultPageTemplate
      pageProps={templateProps.page}
      controlProps={templateProps.control}
      tableProps={templateProps.table}
      tableData={cotacoesMoeda}
      isLoading={isAlternativeLoading}
      filterPropGetter={filterPropGetter}
      buttonNewText={t('com.muralis.qcx.acoes.nova').toUpperCase()}
      authInfo={authInfo}
      requiredRoles={['cotacao']}
    />
  );
}
