import { Grid, Tooltip, Typography } from '@material-ui/core';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import QCXBox from '../../shared-components/box/QCXBox';
import QCXButton from '../../shared-components/button/QCXButton';
import QCXFinalCheckboxField from '../../shared-components/final-checkbox-field/QCXFinalCheckboxField';
import QCXSelectManyClienteTable from '../cliente/QCXSelectManyClienteTable';
import QCXFormTitle from '../form-title/QCXFormTitle';
import { ACESSOS_CADASTROS_OPERACIONAIS, ACESSOS_CADASTROS_OPERACIONAIS_NAME_MAP, userHasRole } from './perfil-utils';

export default function QCXCadastrosOperacionaisTabForm({
  isConsult,
  form,
  values,
  model,
  handleChangeClientes,
  authInfo = {},
}) {
  const { t } = useTranslation();

  const checkAll = () => {
    form.change('permissoesAcesso', _.union(values.permissoesAcesso, ACESSOS_CADASTROS_OPERACIONAIS_NAME_MAP.filter((item) => userHasRole(authInfo, item))));
  };
  const uncheckAll = (formValues) => () => {
    const removed = formValues?.permissoesAcesso.filter(
      (item) => !ACESSOS_CADASTROS_OPERACIONAIS_NAME_MAP.includes(item)
    );
    form.change('permissoesAcesso', removed);
  };
  const checkColum = (column) => () => {
    const checkedValues = values?.permissoesAcesso?.filter((item) => item.endsWith(column));
    const selectedColumn = ACESSOS_CADASTROS_OPERACIONAIS_NAME_MAP.filter(
      (item) => item.endsWith(column)
    ).filter((item) => userHasRole(authInfo, item));
    const checkedColumnValues = checkedValues.filter(
      (item) => ACESSOS_CADASTROS_OPERACIONAIS_NAME_MAP.includes(item)
    );
    if (checkedColumnValues.length) {
      const removed = values?.permissoesAcesso.filter(
        (item) => !selectedColumn.includes(item)
      );
      form.change('permissoesAcesso', removed);
      return;
    }
    form.change('permissoesAcesso', _.union(values.permissoesAcesso, selectedColumn));
  };
  const checkLine = (line) => () => {
    const checkedValues = values?.permissoesAcesso?.filter((item) => item.startsWith(line));
    const selectedLine = ACESSOS_CADASTROS_OPERACIONAIS_NAME_MAP.filter(
      (item) => item.startsWith(line)
    ).filter((item) => userHasRole(authInfo, item));
    const checkedLinesValues = checkedValues.filter(
      (item) => ACESSOS_CADASTROS_OPERACIONAIS_NAME_MAP.includes(item)
    );
    if (checkedLinesValues.length) {
      const removed = values?.permissoesAcesso.filter(
        (item) => !selectedLine.includes(item)
      );
      form.change('permissoesAcesso', removed);
      return;
    }
    form.change('permissoesAcesso', _.union(values.permissoesAcesso, selectedLine));
  };
  return (

    <>
      <QCXSelectManyClienteTable
        title={t('com.muralis.qcx.selecioneClientesPerfil')}
        selected={model?.atributos?.clientes}
        isConsult={isConsult}
        handleChange={handleChangeClientes}
        showFullData
      />
      <QCXFormTitle title={t('com.muralis.qcx.acessosPerfil')} />
      <Grid container spacing={2}>
        <Grid item container spacing={1} xs={12} sm={12} md={8}>

          <Grid item xs={4} sm={4} md={4} />
          <Grid item xs={2} sm={2} md={2}>
            <Typography style={{ cursor: 'pointer' }} disabled={isConsult} onClick={checkColum('-visualizar')} color="primary">
              <QCXBox>
                {t('com.muralis.qcx.acoes.visualizar')}
              </QCXBox>
            </Typography>
          </Grid>
          <Grid item xs={2} sm={2} md={2}>
            <Typography style={{ cursor: 'pointer' }} onClick={checkColum('-incluir')} color="primary">
              <QCXBox>
                {t('com.muralis.qcx.acoes.incluir')}
              </QCXBox>
            </Typography>
          </Grid>
          <Grid item xs={2} sm={2} md={2}>
            <Typography style={{ cursor: 'pointer' }} onClick={checkColum('-alterar')} color="primary">
              <QCXBox>
                {t('com.muralis.qcx.acoes.alterar')}
              </QCXBox>
            </Typography>
          </Grid>
          <Grid item xs={2} sm={2} md={2}>
            <Typography style={{ cursor: 'pointer' }} onClick={checkColum('-excluir')} color="primary">
              <QCXBox>
                {t('com.muralis.qcx.acoes.excluir')}
              </QCXBox>
            </Typography>
          </Grid>
          {ACESSOS_CADASTROS_OPERACIONAIS.map((item) => (
            <>
              <Grid item xs={4} sm={4} md={4}>
                <Typography
                  color="primary"
                  style={isConsult ? {} : { cursor: 'pointer' }}
                  onClick={isConsult ? () => {} : checkLine(item.name)}
                >
                  <QCXBox fontWeight="fontWeightBold">
                    {item.label}
                  </QCXBox>
                </Typography>
              </Grid>
              <Grid item xs={2} sm={2} md={2} style={{ paddingLeft: '0.8vh' }}>
                <Tooltip
                  title={!isConsult && !userHasRole(authInfo, `${item.name}-visualizar`) ? t('com.muralis.qcx.mensagem.concessaoPermissao') : ''}
                  placement="top-start"
                  aria-label={t('com.muralis.qcx.validacao.semPermissao')}
                >
                  <span>
                    <QCXFinalCheckboxField
                      name="permissoesAcesso[]"
                      disabled={isConsult || !userHasRole(authInfo, `${item.name}-visualizar`)}
                      value={`${item.name}-visualizar`}
                      too
                    />
                  </span>
                </Tooltip>
              </Grid>
              <Grid item xs={2} sm={2} md={2} style={{ paddingLeft: '0.8vh' }}>
                <Tooltip
                  title={!isConsult && !userHasRole(authInfo, `${item.name}-incluir`) ? t('com.muralis.qcx.mensagem.concessaoPermissao') : ''}
                  placement="top-start"
                  aria-label={t('com.muralis.qcx.validacao.semPermissao')}
                >
                  <span>
                    <QCXFinalCheckboxField
                      value={`${item.name}-incluir`}
                      name="permissoesAcesso[]"
                      disabled={isConsult || !userHasRole(authInfo, `${item.name}-incluir`)}
                    />
                  </span>
                </Tooltip>
              </Grid>
              <Grid item xs={2} sm={2} md={2} style={{ paddingLeft: '0.8vh' }}>
                <Tooltip
                  title={!isConsult && !userHasRole(authInfo, `${item.name}-alterar`) ? t('com.muralis.qcx.mensagem.concessaoPermissao') : ''}
                  placement="top-start"
                  aria-label={t('com.muralis.qcx.validacao.semPermissao')}
                >
                  <span>
                    <QCXFinalCheckboxField
                      value={`${item.name}-alterar`}
                      name="permissoesAcesso[]"
                      disabled={isConsult || !userHasRole(authInfo, `${item.name}-alterar`)}
                    />
                  </span>
                </Tooltip>
              </Grid>
              <Grid item xs={2} sm={2} md={2} style={{ paddingLeft: '0.8vh' }}>
                <Tooltip
                  title={!isConsult && !userHasRole(authInfo, `${item.name}-excluir`) ? t('com.muralis.qcx.mensagem.concessaoPermissao') : ''}
                  placement="top-start"
                  aria-label={t('com.muralis.qcx.validacao.semPermissao')}
                >
                  <span>
                    <QCXFinalCheckboxField
                      value={`${item.name}-excluir`}
                      name="permissoesAcesso[]"
                      disabled={isConsult || !userHasRole(authInfo, `${item.name}-excluir`)}
                    />
                  </span>
                </Tooltip>
              </Grid>

            </>
          ))}
          <Grid item xs={12} sm={6} md={3} style={{ marginTop: '1rem' }}>
            <QCXButton
              onClick={checkAll}
              color="primary"
              disabled={isConsult}
            >
              {t('com.muralis.qcx.acoes.marcarTodos')}
            </QCXButton>
          </Grid>
          <Grid item xs={12} sm={6} md={3} style={{ marginTop: '1rem' }}>
            <QCXButton
              onClick={uncheckAll(values)}
              color="primary"
              disabled={isConsult}
            >
              {t('com.muralis.qcx.acoes.desmarcarTodos')}
            </QCXButton>
          </Grid>
        </Grid>

      </Grid>
    </>
  );
}
