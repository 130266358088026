import _, { isFunction } from 'lodash';
import React, { useCallback, useEffect, useMemo, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import {
  Receipt as ReceiptIcon,
  MoreHoriz as MoreHorizIcon,
  Cancel as CancelIcon,
  Payment as PaymentIcon,
  CheckCircle as CheckCircleIcon,
  CancelOutlined as CancelOutlinedIcon,
} from '@material-ui/icons';
import { bindTrigger, bindMenu } from 'material-ui-popup-state';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import {
  CLOSED_STATUS,
  DONE_STATUS,
  FAIL_STATUS,
  SUBMITTING_STATUS,
} from '../../../../utils/hooks/form/dialog/formDialogUtils';
import useFormDialogSync from '../../../../utils/hooks/form/dialog/useFormDialogSync';
import {
  resetBackgroundMode,
  resetMode,
  resetModel,
  setRelatedReportModel,
  setRelatedContasReceberItemModel,
  failure,
  loading,
  success,
  refresh as refreshAction,
  resetRefresh,
  changeToBatchApprovalMode,
  setRelatedSelectionContasReceberList,
  resetRelatedSelectionContasReceberList,
} from '../../../../features/contas-receber/contasReceberSlice';
import {
  fetchAllAsync,
  generateReportAReceberByDateAsync,
} from '../../../../features/contas-receber/contasReceberThunks';
import {
  selectMode,
  selectRefresh,
  selectRelatedReportModel,
  selectRelatedContasReceberItemModel,
  selectRelatedSelectionContasReceberList,
  selectContasReceber,
} from '../../../../features/contas-receber/contasReceberSelectors';
import ContasReceberContext from '../../../../contexts/financial/contas-receber/ContasReceberContext';
import { setSuccessFeedback, setErrorFeedback, setWarningFeedback } from '../../../../features/feedback/feedbackSlice';
import { contasReceberAPI } from '../../../../features/contas-receber/contasReceberAPI';
import {
  isAlternativeLoadingStatus,
  isRefresh,
  isNoneMode,
  isApprovalBatchMode,
} from '../../../../utils/store/store-utils';
import {
  formatDate,
  normalizeData,
  unnormalizeNumeral,
  normalizeNumeral,
} from '../../../../utils/general/general-utils';
import { formatBrazilianNumericDecimal } from '../../../../utils/hooks/form/field/formatters';
import contasReceberUtils from '../../../../utils/general/contas-receber/ContasReceberUtils';
import QCXJustifiedActionFormDialog from '../../../../shared-components/dialog/QCXJustifiedActionFormDialog';
import QCXSimpleConsultPageTemplate from '../../../../templates/simple-consult-page/QCXSimpleConsultPageTemplate';
import QCXListItemIcon from '../../../../shared-components/list-item-icon/QCXListItemIcon';
import QCXPopupState from '../../../../components/popup-state/QCXPopupState';
import QCXRelatoriosContasDialogForm from '../../../../components/contas-pagar-receber/QCXRelatoriosContasDialogForm';
import QCXPartialValuesDialogForm from '../../../../components/contas-pagar-receber/QCXPartialValueDialogForm';
import TituloUtils from '../../../../utils/general/titulo/TituloUtils';
import useFormDialogAsync from '../../../../utils/hooks/form/dialog/useFormDialogAsync';
import QCXApplyJurosDialogForm from '../../../../components/contas-pagar-receber/QCXApplyJurosDialogForm';

export default function ContasReceberConsultPage({ authInfo = {} }) {
  const { t } = useTranslation();

  const { status } = useContext(ContasReceberContext);

  const mode = useSelector(selectMode);
  const dispatch = useDispatch();

  const refresh = useSelector(selectRefresh);
  const contasReceber = useSelector(selectContasReceber);
  const relatedContasReceberItem = useSelector(selectRelatedContasReceberItemModel);
  const relatedSelectionContasReceberList = useSelector(selectRelatedSelectionContasReceberList);
  const reportRelatedModel = useSelector(selectRelatedReportModel);

  const isNone = useMemo(() => isNoneMode(mode), [mode]);

  const isApprovalBatch = useMemo(() => isApprovalBatchMode(mode), [mode]);

  const isAlternativeLoading = useMemo(() => isAlternativeLoadingStatus(status), [status]);

  const resetModes = useCallback(() => {
    dispatch(resetMode());
    dispatch(resetBackgroundMode());
  }, []);

  const resetModels = useCallback(() => {
    dispatch(resetModel());
  }, []);

  const isEmptySelectionList = useMemo(
    () =>
      !relatedSelectionContasReceberList ||
      (_.isArrayLikeObject(relatedSelectionContasReceberList) && _.isEmpty(relatedSelectionContasReceberList)),
    [relatedSelectionContasReceberList]
  );

  const fetchAll = useCallback(() => {
    dispatch(fetchAllAsync());
  }, []);

  useEffect(() => {
    fetchAll();
    resetModes();
    resetModels();
  }, []);

  useEffect(() => {
    const checkIfIsRefresh = () => {
      if (isRefresh(refresh)) {
        fetchAll();
        dispatch(resetRefresh());
      }
    };

    checkIfIsRefresh();
  }, [refresh]);

  useEffect(() => {
    if (isNone && !isEmptySelectionList) {
      dispatch(resetRelatedSelectionContasReceberList());
    }
  }, [isNone, isEmptySelectionList]);

  const handleEnableBatchApprovalMode = useCallback((event) => {
    event.stopPropagation();
    dispatch(changeToBatchApprovalMode());
  }, []);

  const handleSelectionModelChange = useCallback(
    ({ selectionModel }) => {
      if (isApprovalBatch) {
        dispatch(setRelatedSelectionContasReceberList(selectionModel));
      }
    },
    [contasReceber, isApprovalBatch]
  );

  const [openValorParcialDialog, valorParcialDialogStatus, setValorParcialDialogStatus] = useFormDialogAsync();

  const [openApplyJurosDialog, applyJurosDialogStatus, setApplyJurosDialogStatus] = useFormDialogAsync();

  const handleConfirmParcialValueApproval = useCallback(
    async (values) => {
      try {
        dispatch(loading());

        const contasReceberFiltrado = contasReceber.map((conta) => ({
          id: conta?.id,
          contaBancaria: conta?.contaBancaria?.id,
          valor: normalizeNumeral(values?.valorParcial),
        }));

        const selectionModelContasReceberList = relatedSelectionContasReceberList.map((itemModel) => {
          const [contasReceberSelecionadas] = contasReceberFiltrado.filter((conta) => conta?.id === itemModel);
          return contasReceberSelecionadas;
        });

        const transferData = selectionModelContasReceberList?.map((contaReceber) => ({
          conta: {
            tipo: {
              description: contasReceberUtils.TITULO_TRANSFERENCIA,
            },
            proprietario: {
              id: contaReceber?.contaBancaria,
            },
          },
          referencias: [
            {
              id: contaReceber?.id,
            },
          ],
          operacao: {
            description: contasReceberUtils.TRANSFERENCIA,
          },
          valor: contaReceber?.valor,
        }));

        const response = await contasReceberAPI.transferencia(transferData);

        if (response?.status === 201) {
          const feedbackMessage = t('com.muralis.qcx.mensagem.loteTransferenciaContasReceber');

          setValorParcialDialogStatus(CLOSED_STATUS);
          dispatch(success());
          dispatch(
            setSuccessFeedback({
              message: feedbackMessage,
            })
          );
          dispatch(refreshAction());
          dispatch(resetMode());
        }
      } catch (error) {
        const errorMessage = error?.message
          ? t('com.muralis.qcx.erro.transferirLoteContasReceberEspecifico', { erro: error?.response?.data?.message })
          : t('com.muralis.qcx.erro.transferirContasReceberLote');
        dispatch(failure());
        dispatch(
          setErrorFeedback({
            message: errorMessage,
          })
        );
      }
    },
    [contasReceber, relatedSelectionContasReceberList]
  );

  const handleConfirmBatchApproval = useCallback(async () => {
    try {
      dispatch(loading());

      const contasReceberFiltrado = contasReceber.map((conta) => {
        const saldo = contasReceberUtils.calculoSaldoContasReceber(conta?.movimentacoes, conta?.valor) * -1;

        return {
          id: conta?.id,
          contaBancaria: conta?.contaBancaria?.id,
          valor: saldo,
        };
      });

      const selectionModelContasReceberList = relatedSelectionContasReceberList.map((itemModel) => {
        const [contasReceberSelecionadas] = contasReceberFiltrado.filter((conta) => conta?.id === itemModel);
        return contasReceberSelecionadas;
      });

      if (selectionModelContasReceberList.length === 1) {
        dispatch(refreshAction());
        openValorParcialDialog();
        return;
      }

      const transferData = selectionModelContasReceberList?.map((contaReceber) => ({
        conta: {
          tipo: {
            description: contasReceberUtils.TITULO_TRANSFERENCIA,
          },
          proprietario: {
            id: contaReceber?.contaBancaria,
          },
        },
        referencias: [
          {
            id: contaReceber?.id,
          },
        ],
        operacao: {
          description: contasReceberUtils.TRANSFERENCIA,
        },
        valor: contaReceber?.valor,
      }));

      const response = await contasReceberAPI.transferencia(transferData);

      if (response?.status === 201) {
        const feedbackMessage = t('com.muralis.qcx.mensagem.loteTransferenciaContasReceber');

        dispatch(success());
        dispatch(
          setSuccessFeedback({
            message: feedbackMessage,
          })
        );
        dispatch(refreshAction());
        dispatch(resetMode());
      }
    } catch (error) {
      const errorMessage = error?.message
        ? t('com.muralis.qcx.erro.transferirLoteContasReceberEspecifico', { erro: error?.response?.data?.message })
        : t('com.muralis.qcx.erro.transferirContasReceberLote');
      dispatch(failure());
      dispatch(
        setErrorFeedback({
          message: errorMessage,
        })
      );
    }
  }, [contasReceber, relatedSelectionContasReceberList]);

  const handleCancelBatchApproval = useCallback((event) => {
    event.stopPropagation();
    dispatch(resetMode());
  }, []);

  const [handleImprimirClick, formDialogImpressaoStatus, handleFormDialogImpressaoStatus] = useFormDialogSync(
    async (event, data) => {
      if (isFunction(event?.stopPropagation)) {
        event.stopPropagation();

        dispatch(
          setRelatedReportModel({
            ...data,
            tipo: contasReceberUtils.CONTAS_RECEBER,
          })
        );
      }
    },
    []
  );

  const handleImprimirByDateSubmit = async (event) => {
    event?.preventDefault?.();
    event?.stopPropagation?.();

    const vencimentoDe = normalizeData(event?.data?.vencimentoDe);
    const vencimentoAte = normalizeData(event?.data?.vencimentoAte);

    const handlers = {
      onNoContent: () => {
        dispatch(failure());

        const infoMessage = t('com.muralis.qcx.mensagem.nenhumRelatorioGerar');

        dispatch(
          setWarningFeedback({
            message: infoMessage,
          })
        );
      },
      onError: () => {
        dispatch(failure());

        const errorMessage = t('com.muralis.qcx.mensagem.naoFoiPossivelGerarRelatorioContas', {
          tipoRelatorio: t('com.muralis.qcx.financeiro.contasReceber'),
        });

        dispatch(
          setErrorFeedback({
            message: errorMessage,
          })
        );
      },
    };

    dispatch(
      generateReportAReceberByDateAsync({
        vencimentoDe,
        vencimentoAte,
        handlers,
      })
    );

    handleFormDialogImpressaoStatus(DONE_STATUS);
  };

  const [handleOpenCancel, dialogCancelStatus, handleConsultCancelStatus] = useFormDialogSync(async (event, data) => {
    if (_.isFunction(event?.stopPropagation)) {
      event.stopPropagation();
      dispatch(setRelatedContasReceberItemModel(data));
    }
  }, []);

  const handleCancelSubmit = useCallback(
    async ({ ...values }) => {
      try {
        dispatch(loading());
        handleConsultCancelStatus(SUBMITTING_STATUS);

        const response = await contasReceberAPI.cancelar({
          id: values?.id,
          justificativa: values?.justificativa,
        });

        if (response?.status === 200) {
          const feedbackMessage = t('com.muralis.qcx.mensagem.contaReceberCancelada');

          handleConsultCancelStatus(DONE_STATUS);
          dispatch(success());
          dispatch(
            setSuccessFeedback({
              message: feedbackMessage,
            })
          );
          dispatch(refreshAction());
        }
      } catch (error) {
        const errorMessage = error?.message
          ? t('com.muralis.qcx.erro.cancelarContasReceberEspecifico', { erro: error?.response?.data?.message })
          : t('com.muralis.qcx.erro.cancelarContasReceber');
        dispatch(
          setErrorFeedback({
            message: errorMessage,
          })
        );
        dispatch(failure());
        handleConsultCancelStatus(FAIL_STATUS);
      }
    },
    [handleConsultCancelStatus]
  );

  function calculateDesconto({ row }) {
    const desconto = (row?.desconto ?? 0) / 100;
    const valorDescontado = row?.valor + row?.valor * desconto;

    const valorFormatado = unnormalizeNumeral(valorDescontado, formatBrazilianNumericDecimal(2)) || 0;

    return valorFormatado;
  }

  const [rowToApplyJuros, setRowToApplyJuros] = useState();

  function handleOpenApplyJuros(event, row) {
    setRowToApplyJuros(row);
    openApplyJurosDialog();
  }

  async function handleApplyJuros(values) {
    try {
      dispatch(loading());
      const sinal = values?.tipo === 'Desconto' ? -1 : 1;
      const formato = values?.formato;

      let desconto = 0;
      if (formato === 'Real') {
        const valorSinal = normalizeNumeral(values?.desconto ?? 0) * sinal;
        const percentual = valorSinal / rowToApplyJuros?.valor;
        desconto = percentual * 100;
      } else {
        desconto = normalizeNumeral(values?.desconto ?? 0) * sinal;
      }

      const payload = { ...rowToApplyJuros, desconto };
      const response = await contasReceberAPI.atualizarJuros(payload);

      if (response?.status === 200) {
        const feedbackMessage = 'Juros Atualizados com sucesso';

        setApplyJurosDialogStatus(CLOSED_STATUS);
        dispatch(success());
        dispatch(
          setSuccessFeedback({
            message: feedbackMessage,
          })
        );
        dispatch(refreshAction());
      }
    } catch (error) {
      const errorMessage = 'Falha ao atualizar os juros!';
      dispatch(
        setErrorFeedback({
          message: errorMessage,
        })
      );
      dispatch(failure());
      setApplyJurosDialogStatus(FAIL_STATUS);
    }
  }

  const columns = useMemo(
    () => [
      {
        field: 'processo',
        headerName: t('com.muralis.qcx.NumeroProcesso'),
        headerAlign: 'center',
        align: 'center',
        type: 'string',
        flex: 150,
        valueGetter: ({ row }) => row?.followUp?.numero || '-',
      },
      {
        field: 'origem',
        headerName: t('com.muralis.qcx.origem'),
        headerAlign: 'center',
        align: 'center',
        type: 'string',
        flex: 180,
        valueGetter: ({ row }) => row?.origem || '-',
      },
      {
        field: 'solicitacao',
        headerName: t('com.muralis.qcx.solicitacao'),
        headerAlign: 'center',
        align: 'center',
        type: 'date',
        flex: 150,
        valueGetter: ({ row }) => formatDate(row?.insertionDate, 'DD/MM/YYYY HH:mm:ss', 'DD/MM/YYYY') || '-',
      },
      {
        field: 'cliente',
        headerName: t('com.muralis.qcx.cliente.label'),
        headerAlign: 'center',
        align: 'center',
        type: 'string',
        flex: 120,
        valueGetter: ({ row }) => row?.followUp?.importador?.pessoa?.nome || '-',
      },
      {
        field: 'valorReceber',
        headerName: t('com.muralis.qcx.valorReceber'),
        headerAlign: 'center',
        align: 'center',
        type: 'numeric',
        flex: 150,
        valueGetter: ({ row }) => unnormalizeNumeral(row?.valor, formatBrazilianNumericDecimal(2)) || '-',
      },
      {
        field: 'desconto',
        headerName: 'Desconto / Juros',
        headerAlign: 'center',
        align: 'center',
        type: 'numeric',
        flex: 150,
        valueGetter: ({ row }) => unnormalizeNumeral(row?.desconto, formatBrazilianNumericDecimal(2, true)) || 0,
      },
      {
        field: 'valorDescontado',
        headerName: 'Valor Real',
        headerAlign: 'center',
        align: 'center',
        type: 'numeric',
        flex: 150,
        valueGetter: calculateDesconto,
      },
      {
        field: 'valorRecebido',
        headerName: t('com.muralis.qcx.valorRecebido'),
        headerAlign: 'center',
        align: 'center',
        type: 'numeric',
        flex: 150,
        valueGetter: ({ row }) =>
          unnormalizeNumeral(
            contasReceberUtils.calculoValorRecebido(row?.movimentacoes),
            formatBrazilianNumericDecimal(2)
          ) || '-',
      },
      {
        field: 'saldo',
        headerName: t('com.muralis.qcx.saldo'),
        headerAlign: 'center',
        align: 'center',
        type: 'numeric',
        flex: 150,
        valueGetter: ({ row }) =>
          unnormalizeNumeral(
            contasReceberUtils.calculoSaldoContasReceber(row?.movimentacoes, row?.valor, row?.desconto),
            formatBrazilianNumericDecimal(2, true)
          ) || '-',
      },
      {
        field: 'status',
        headerName: t('com.muralis.qcx.status'),
        headerAlign: 'center',
        align: 'center',
        type: 'string',
        flex: 150,
        valueGetter: ({ row }) => row?.status || '-',
      },
      {
        field: 'actions',
        headerName: t('com.muralis.qcx.acoes.label'),
        headerAlign: 'center',
        align: 'center',
        flex: 180,
        renderCell: ({ row }) => (
          <>
            <QCXPopupState popupId="popup-menu-contas-receber">
              {(popupState) => (
                <>
                  <IconButton
                    key={`btn-more-options-${row?.id}`}
                    name={`btn-more-options-${row?.id}`}
                    {...bindTrigger(popupState)}
                    disabled={isApprovalBatch}
                  >
                    <MoreHorizIcon color={isApprovalBatch ? 'disabled' : 'secondary'} size={20} />
                  </IconButton>
                  <Menu {...bindMenu(popupState)}>
                    <MenuItem
                      onClick={(event) => {
                        handleOpenCancel(event, row);
                      }}
                      disabled={
                        contasReceberUtils.isRecebido(row?.status) ||
                        contasReceberUtils.isCancelado(row?.status) ||
                        TituloUtils.isOrigemTransferencia(row?.origem)
                      }
                    >
                      <QCXListItemIcon>
                        <CancelIcon fontSize="small" color="error" />
                      </QCXListItemIcon>
                      <Typography
                        variant="inherit"
                        style={{
                          fontSize: 12,
                        }}
                      >
                        {t('com.muralis.qcx.acoes.cancelar').toUpperCase()}
                      </Typography>
                    </MenuItem>
                    <MenuItem
                      onClick={(event) => {
                        handleOpenApplyJuros(event, row);
                      }}
                    >
                      <QCXListItemIcon>
                        <PaymentIcon fontSize="small" />
                      </QCXListItemIcon>
                      <Typography
                        variant="inherit"
                        style={{
                          fontSize: 12,
                        }}
                      >
                        {'Aplicar Juros'.toUpperCase()}
                      </Typography>
                    </MenuItem>
                  </Menu>
                </>
              )}
            </QCXPopupState>
          </>
        ),
      },
    ],
    [isApprovalBatch, handleOpenCancel]
  );

  const breadcrumbs = useMemo(
    () => [
      {
        link: {
          to: '/',
          name: t('com.muralis.qcx.inicio'),
        },
      },
      {
        link: {
          to: t('com.muralis.qcx.url.moduloFinanceiro'),
          name: t('com.muralis.qcx.financeiro.label'),
        },
      },
      {
        text: {
          name: t('com.muralis.qcx.financeiro.contasReceber'),
        },
        default: true,
      },
    ],
    []
  );

  function isFullPayed(valor, movimentacoes) {
    const valorRecebido = contasReceberUtils.calculoValorRecebido(movimentacoes);

    return valorRecebido >= valor;
  }

  const templateProps = useMemo(
    () => ({
      page: {
        title: t('com.muralis.qcx.financeiro.contasReceber'),
        icon: <ReceiptIcon />,
        breadcrumbs,
      },
      control: {
        others: [
          ...(isNone
            ? [
                {
                  description: t('com.muralis.qcx.relatorios.label'),
                  disabled: false,
                  color: 'primary',
                  onClick: handleImprimirClick,
                },
                {
                  description: <CheckCircleIcon size={20} color="white" />,
                  ...(_.isEmpty(contasReceber || [])
                    ? {
                        disabled: true,
                        color: 'disabled',
                      }
                    : {
                        disabled: false,
                        style: {
                          backgroundColor: 'green',
                        },
                      }),
                  onClick: handleEnableBatchApprovalMode,
                },
              ]
            : []),
          ...(isApprovalBatch
            ? [
                {
                  description: t('com.muralis.qcx.acoes.confirmarSelecao'),
                  startIcon: <CheckCircleIcon />,
                  color: 'secondary',
                  tooltip: {
                    description: t('com.muralis.qcx.acoes.confirmarSelecao'),
                  },
                  onClick: handleConfirmBatchApproval,
                  disabled: isEmptySelectionList,
                },
                {
                  description: <CancelOutlinedIcon />,
                  variant: 'outlined',
                  color: 'default',
                  tooltip: {
                    description: t('com.muralis.qcx.acoes.cancelar'),
                  },
                  onClick: handleCancelBatchApproval,
                },
              ]
            : []),
        ],
      },
      table: {
        columns,
        checkboxSelection: isApprovalBatch,
        selectionModel: relatedSelectionContasReceberList,
        onSelectionModelChange: handleSelectionModelChange,
        isRowSelectable: (table) =>
          (table?.row?.status === contasReceberUtils.PENDENTE ||
            !isFullPayed(table?.row?.valor, table?.row?.movimentacoes)) &&
          !TituloUtils.isOrigemTransferencia(table?.row?.origem),
      },
    }),
    [
      breadcrumbs,
      columns,
      isApprovalBatch,
      isEmptySelectionList,
      contasReceber,
      handleCancelBatchApproval,
      handleConfirmBatchApproval,
      handleSelectionModelChange,
      handleEnableBatchApprovalMode,
      relatedSelectionContasReceberList,
    ]
  );

  return (
    <QCXSimpleConsultPageTemplate
      pageProps={templateProps.page}
      controlProps={templateProps.control}
      tableProps={templateProps.table}
      tableData={contasReceber}
      isLoading={isAlternativeLoading}
      authInfo={authInfo}
      requiredRoles={['conta-receber']}
      clearSelection
    >
      <QCXJustifiedActionFormDialog
        handleSubmit={handleCancelSubmit}
        status={dialogCancelStatus}
        handleStatus={handleConsultCancelStatus}
        type={contasReceberUtils.CANCELAR}
        initialValues={relatedContasReceberItem}
      />
      <QCXRelatoriosContasDialogForm
        handleFormStatus={handleFormDialogImpressaoStatus}
        handleOnSubmit={(event) => handleImprimirByDateSubmit(event)}
        formDialogStatus={formDialogImpressaoStatus}
        initialValues={reportRelatedModel}
        typeReport={contasReceberUtils.CONTAS_RECEBER}
      />
      <QCXPartialValuesDialogForm
        handleFormStatus={setValorParcialDialogStatus}
        handleOnSubmit={(values) => handleConfirmParcialValueApproval(values)}
        formDialogStatus={valorParcialDialogStatus}
      />
      <QCXApplyJurosDialogForm
        handleFormStatus={setApplyJurosDialogStatus}
        handleOnSubmit={(values) => handleApplyJuros(values)}
        formDialogStatus={applyJurosDialogStatus}
      />
    </QCXSimpleConsultPageTemplate>
  );
}
