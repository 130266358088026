import { isStrictEquals } from '../general-utils';

// OPERACAO_CALCULO
const CALCULADA_STATUS = 'CALCULADA';
const NAO_CALCULADA_STATUS = 'NAO_CALCULADA';

// OPERACAO_CONFERENCIA
const CONFERIDA_STATUS = 'CONFERIDA';
const NAO_CONFERIDA_STATUS = 'NAO_CONFERIDA';

// OPERACAO_LIBERACAO
const LIBERADA = 'LIBERADA';
const NAO_LIBERADA = 'NAO_LIBERADA';

// INTEGRACAO_SISCOMEX
const INTEGRACAO_SISCOMEX_CONCLUIDO_STATUS = 'CONCLUIDO';
const INTEGRACAO_SISCOMEX_ERRO_STATUS = 'ERRO';

const isLiberadaStatus = (status) => (
  isStrictEquals(LIBERADA, status)
);

const isNaoLiberadaStatus = (status) => (
  isStrictEquals(NAO_LIBERADA, status)
);

const isCalculadaStatus = (status) => (
  isStrictEquals(CALCULADA_STATUS, status)
);

const isNaoCalculadaStatus = (status) => (
  isStrictEquals(NAO_CALCULADA_STATUS, status)
);

const isConferidaStatus = (status) => (
  isStrictEquals(CONFERIDA_STATUS, status)
);

const isNaoConferidaStatus = (status) => (
  isStrictEquals(NAO_CONFERIDA_STATUS, status)
);

const isIntegracaoSiscomexConcluidaStatus = (status) => (
  isStrictEquals(INTEGRACAO_SISCOMEX_CONCLUIDO_STATUS, status)
);

const isIntegracaoSiscomexErroStatus = (status) => (
  isStrictEquals(INTEGRACAO_SISCOMEX_ERRO_STATUS, status)
);

const isIntegradaSiscomex = (registroLi, statusIntegracaoSiscomex) => (
  !!registroLi || isIntegracaoSiscomexConcluidaStatus(
    statusIntegracaoSiscomex
  )
);

const OperationUtils = {
  CALCULADA_STATUS,
  NAO_CALCULADA_STATUS,
  CONFERIDA_STATUS,
  NAO_CONFERIDA_STATUS,
  LIBERADA,
  NAO_LIBERADA,
  isCalculadaStatus,
  isNaoCalculadaStatus,
  isConferidaStatus,
  isNaoConferidaStatus,
  isLiberadaStatus,
  isNaoLiberadaStatus,
};

export {
  OperationUtils,
  CALCULADA_STATUS,
  NAO_CALCULADA_STATUS,
  CONFERIDA_STATUS,
  NAO_CONFERIDA_STATUS,
  LIBERADA,
  NAO_LIBERADA,
  isCalculadaStatus,
  isNaoCalculadaStatus,
  isConferidaStatus,
  isNaoConferidaStatus,
  isLiberadaStatus,
  isNaoLiberadaStatus,
  isIntegracaoSiscomexConcluidaStatus,
  isIntegracaoSiscomexErroStatus,
  isIntegradaSiscomex,
};
