import { Grid } from '@material-ui/core';
import { useCallback, useMemo } from 'react';
import { useFormState } from 'react-final-form';
import {
  exportacaoEstrangeiraDeclaracaoImportacaoActions,
  exportacaoEstrangeiraDeclaracaoImportacaoSelectors,
} from '../../../features/exportacao-estrangeira-declaracao-importacao/exportacaoEstrangeiraDeclaracaoImportacaoSlice';
import QCXFinalBondManagerOld from '../../../shared-components/final-gerenciador-relacionamento/QCXFinalBondManagerOld';
import QCXFinalTextField from '../../../shared-components/final-text-field/QCXFinalTextField';
import QCXFinalNumericDecimalField from '../../../shared-components/final-numeric-decimal-field/QCXFinalNumericDecimalField';
import QCXMoedaDescontoFormGroup from '../../../shared-components/moeda-desconto-form-group/QCXMoedaDescontoFormGroup';
import QCXMoedaDespesaFormGroup from '../../../shared-components/moeda-despesa-form-group/QCXMoedaDespesaFormGroup';
import QCXMoedaFreteFormGroup from '../../../shared-components/moeda-frete-form-group/QCXMoedaFreteFormGroup';
import QCXMoedaMleFormGroup from '../../../shared-components/moeda-mle-form-group/QCXMoedaMleFormGroup';
import QCXMoedaSeguroFormGroup from '../../../shared-components/moeda-seguro-form-group/QCXMoedaSeguroFormGroup';
import { formatDate } from '../../../utils/general/general-utils';
import QCXFormSubtitle from '../../form-title/QCXFormSubtitle';
import MCVMoedaDUEFormGroup from './MCVMoedaDUEFormGroup';
import MCVMoedaFormGroup from './MCVMoedaFormGroup';

export default function QCXFaturaTotaisFormGroup({
  intl,
  classes,
  isConsult,
  valorValidators,
  submitSucceeded,
  onBlurObjectHandlers,
}) {
  const embalagensListProps = useMemo(
    () => ({
      name: 'followUp.danfes',
      columns: [
        {
          field: 'chave',
          headerName: 'Danfe',
          headerAlign: 'center',
          align: 'center',
          flex: 180,
          valueGetter: ({ row }) => row?.chave ?? '-',
        },
        {
          field: 'dataEmissao',
          headerName: 'Data Emissão',
          headerAlign: 'center',
          align: 'center',
          flex: 180,
          valueGetter: ({ row }) => formatDate(row?.dataEmissao) ?? '-',
        },
        {
          field: 'taxaConversao',
          headerName: 'Taxa Conversão',
          headerAlign: 'center',
          align: 'center',
          flex: 180,
          valueGetter: ({ row }) => row?.taxaConversao ?? '-',
        },
      ],
    }),
    []
  );

  const embalagensFormProps = useMemo(
    () => ({
      rootName: 'ignorableFields.danf',
      fields: [
        {
          name: 'chave',
          label: 'Danfe',
        },
        {
          name: 'taxaConversao',
          label: 'Taxa',
        },
      ],
    }),
    []
  );

  const handleUpdateEmbalagem = useCallback(async (currentItem, currentCollection, handleSuccess) => {
    const updatedVinculos =
      currentCollection?.map((existingVinculo) =>
        existingVinculo?.chave === currentItem?.chave ? currentItem : existingVinculo
      ) || currentCollection;

    handleSuccess(updatedVinculos);
  }, []);

  const controlButtonsGridProps = useMemo(
    () => ({
      add: {
        xs: 12,
        sm: 4,
        md: 4,
      },
      update: {
        xs: 12,
        sm: 2,
        md: 2,
      },
      clear: {
        xs: 6,
        sm: 2,
        md: 2,
      },
    }),
    []
  );

  const exportacaoEstrangeiraReducerConfig = useMemo(
    () => ({
      selectors: {
        selectStatus: exportacaoEstrangeiraDeclaracaoImportacaoSelectors.selectStatus,
        selectMode: exportacaoEstrangeiraDeclaracaoImportacaoSelectors.selectMode,
        selectModel: exportacaoEstrangeiraDeclaracaoImportacaoSelectors.selectModel,
      },
      actions: {
        loading: exportacaoEstrangeiraDeclaracaoImportacaoActions.loading,
        resetStatus: exportacaoEstrangeiraDeclaracaoImportacaoActions.resetStatus,
        changeToUpdateMode: exportacaoEstrangeiraDeclaracaoImportacaoActions.changeToUpdateMode,
        changeToCreateMode: exportacaoEstrangeiraDeclaracaoImportacaoActions.changeToCreateMode,
        resetMode: exportacaoEstrangeiraDeclaracaoImportacaoActions.resetMode,
        setModel: exportacaoEstrangeiraDeclaracaoImportacaoActions.setModel,
        resetModel: exportacaoEstrangeiraDeclaracaoImportacaoActions.resetModel,
      },
    }),
    []
  );

  const { values } = useFormState();
  const tipo = values?.atributosAdicionais?.tipo;

  return (
    <>
      <QCXMoedaMleFormGroup
        isConsult={isConsult}
        handleValorNaMoedaOnBlur={onBlurObjectHandlers?.handleValorNaMoedaOnBlur}
        handleMoedaMLEDUEOnBlur={onBlurObjectHandlers?.handleMoedaMLEDUEOnBlur}
        valorValidators={valorValidators}
        submitSucceeded={submitSucceeded}
        intl={intl}
      />
      {tipo === 'DUE' && <MCVMoedaDUEFormGroup intl={intl} />}
      {tipo !== 'DUE' && <MCVMoedaFormGroup intl={intl} />}
      <QCXMoedaDescontoFormGroup
        isConsult={isConsult}
        handleValorNaMoedaOnBlur={onBlurObjectHandlers?.handleValorNaMoedaOnBlur}
        valorValidators={valorValidators}
        submitSucceeded={submitSucceeded}
        intl={intl}
        required={false}
      />
      <QCXMoedaDespesaFormGroup
        isConsult={isConsult}
        handleValorNaMoedaOnBlur={onBlurObjectHandlers?.handleValorNaMoedaOnBlur}
        valorValidators={valorValidators}
        intl={intl}
      />
      <QCXMoedaFreteFormGroup
        isConsult={isConsult}
        handleValorNaMoedaOnBlur={onBlurObjectHandlers?.handleValorNaMoedaOnBlur}
        valorValidators={valorValidators}
        intl={intl}
        classes={classes}
      />
      <QCXMoedaSeguroFormGroup
        isConsult={isConsult}
        handleValorNaMoedaOnBlur={onBlurObjectHandlers?.handleValorNaMoedaOnBlur}
        handleValorNaMoedaSeguro={onBlurObjectHandlers?.handleValorNaMoedaSeguro}
        valorValidators={valorValidators}
        intl={intl}
      />
      {tipo === 'DUE' && (
        <>
          <Grid item xs={12}>
            <QCXFormSubtitle title="Taxas de conversão das danfes" />
          </Grid>

          <QCXFinalBondManagerOld
            isParentConsult={isConsult}
            listProps={embalagensListProps}
            formProps={embalagensFormProps}
            handleUpdate={(currentItem, currentCollection, handleSuccess) =>
              handleUpdateEmbalagem(currentItem, currentCollection, handleSuccess)
            }
            reducerConfig={exportacaoEstrangeiraReducerConfig}
            controlButtonsGridProps={controlButtonsGridProps}
            disabled={isConsult}
            onlyShowFieldOnEdit
            hideAddButton
          >
            <Grid item className={classes.gridFields} xs={12} sm={12} md={4} lg={4}>
              <QCXFinalTextField
                id="numeric-integer-danfe-number-field"
                key="numeric-integer-danfe-number-field"
                name="ignorableFields.danf.chave"
                label="Chave Danfe"
                disabled
              />
            </Grid>
            <Grid item className={classes.gridFields} xs={12} sm={12} md={4} lg={4}>
              <QCXFinalNumericDecimalField
                id="numeric-integer-de-qtdUnidadeEstatistica-field"
                key="numeric-integer-de-qtdUnidadeEstatistica-field"
                name="ignorableFields.danf.taxaConversao"
                label="Taxa de Conversão"
                allowNegative={false}
                disabled={isConsult}
                scale={7}
              />
            </Grid>
          </QCXFinalBondManagerOld>
        </>
      )}
    </>
  );
}
