import { validateQueryParams } from '../../utils/api/api-utils';
import {
  doGetQCXRequest,
} from '../../utils/api/domain/domain-api-utils';
import {
  FILTER_ENDPOINT,
  NCM_EXPORTACAO_ENDPOINT,
  NCM_ATRIBUTO_EXPORTACAO_ENDPOINT,
  NCM_DOMINIO_ATRIBUTO_EXPORTACAO_ENDPOINT,
} from '../../utils/api/domain/domain-endpoint-utils';

export async function fetchAll() {
  return doGetQCXRequest([
    NCM_EXPORTACAO_ENDPOINT
  ]);
}
export async function fetchByFilterAtributo(params = []) {
  const validatedParams = validateQueryParams(params);
  if (validatedParams.length) {
    return doGetQCXRequest([
      NCM_ATRIBUTO_EXPORTACAO_ENDPOINT, FILTER_ENDPOINT
    ], validatedParams);
  }
  return { data: [] };
}

export async function fetchByFilterDominio(params = []) {
  const validatedParams = validateQueryParams(params);
  if (validatedParams.length) {
    return doGetQCXRequest([
      NCM_DOMINIO_ATRIBUTO_EXPORTACAO_ENDPOINT, FILTER_ENDPOINT
    ], validatedParams);
  }
  return { data: [] };
}
