import { Paper, makeStyles } from '@material-ui/core';
import {
  Label as LabelIcon,
  Dns as DnsIcon,
  DataUsage as DataUsageIcon,
  TableChart as TableChartIcon,
  Input as InputIcon,
  Public as PublicIcon,
  Home as HomeIcon,
  AccountBalance as AccountBalanceIcon,
} from '@material-ui/icons';
import { isArray } from 'lodash';
import { useMemo } from 'react';
import { useHistory } from 'react-router';
import QCXLayout from '../../shared-components/layout/QCXLayout';
import {
  CADASTROS_FINANCEIROS_MODULE,
  CADASTROS_GERAIS_MODULE,
  CADASTROS_OPERACIONAIS_MODULE,
  CADASTROS_TABELAS_ADUANEIRAS_MODULE,
  EXPORTACAO_MODULE,
  FINANCEIRO_MODULE,
  IMPORTACAO_MODULE,
} from '../../utils/general/modules';
import QCXNavigator from '../navigator/QCXNavigator';

const useStyles = makeStyles((theme) => ({
  default: {
    width: '100%',
    padding: '2rem',
    background: theme.palette.background.paper,
  },
  outlined: {
    width: '100%',
    padding: '1rem',
    background: 'transparent',
    border: '0',
    boxShadow: 'none',
  },
}));

export default function QCXMainLayout({
  breadcrumbs,
  children,
  pageIcon,
  pageTitle,
  breadcrumbsProps = {
    maxItems: 5,
  },
  disableFocusLock = true,
  authInfo = {},
  ...restProps
}) {
  const classes = useStyles();

  const {
    location: { pathname: currentLocationPath },
  } = useHistory();

  const currentModuleIcon = useMemo(() => {
    const currentPaths = currentLocationPath.split('/');

    const match = (modulePaths) => modulePaths.every((path) => currentPaths.includes(path));

    if (match(CADASTROS_GERAIS_MODULE)) {
      return <LabelIcon />;
    }
    if (match(CADASTROS_OPERACIONAIS_MODULE)) {
      return <DnsIcon />;
    }
    if (match(CADASTROS_FINANCEIROS_MODULE)) {
      return <DataUsageIcon />;
    }
    if (match(CADASTROS_TABELAS_ADUANEIRAS_MODULE)) {
      return <TableChartIcon />;
    }
    if (match(IMPORTACAO_MODULE)) {
      return <InputIcon />;
    }
    if (match(EXPORTACAO_MODULE)) {
      return <PublicIcon />;
    }
    if (match(FINANCEIRO_MODULE)) {
      return <AccountBalanceIcon />;
    }
    if (pageIcon) {
      return pageIcon;
    }
    return <HomeIcon />;
  }, [currentLocationPath]);

  const paperClassName = useMemo(() => {
    if (isArray(breadcrumbs)) {
      const isDefaultPaper = breadcrumbs.length > 3 || breadcrumbs[breadcrumbs.length - 1]?.default;
      if (isDefaultPaper) {
        return classes.default;
      }
      return classes.outlined;
    }
    return classes.outlined;
  }, [breadcrumbs, classes]);

  return (
    <QCXLayout {...restProps}>
      <QCXNavigator
        pageIcon={currentModuleIcon}
        pageTitle={pageTitle}
        breadcrumbs={breadcrumbs}
        breadcrumbsProps={breadcrumbsProps}
        disableFocusLock={disableFocusLock}
        authInfo={authInfo}
      >
        {children && (
          <Paper elevation={3} className={paperClassName}>
            {children}
          </Paper>
        )}
      </QCXNavigator>
    </QCXLayout>
  );
}
