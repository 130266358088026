import { Grid } from '@material-ui/core';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  fetchAllAsync, selectServicos, selectStatus,
} from '../../features/servico/servicoSlice';
import QCXSimpleSelectManyPageTemplate from '../../templates/simple-select-many-page/QCXSimpleSelectManyPageTemplate';
import { isAlternativeLoadingStatus } from '../../utils/store/store-utils';

export default function QCXSelectManyServicosTable({ ...props }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const list = useSelector(selectServicos);
  const status = useSelector(selectStatus);

  const isAlternativeLoading = useMemo(() => (
    isAlternativeLoadingStatus(status)
  ), [status]);

  useEffect(() => {
    dispatch(fetchAllAsync());
  }, []);

  const {
    title = t('com.muralis.qcx.servico.selecioneServicos'),
    filterProp = 'description',
    columns = [
      {
        field: 'id',
        headerName: t('com.muralis.qcx.codigo'),
        headerAlign: 'center',
        align: 'center',
        type: 'number',
        flex: 0.4,
      },
      {
        field: 'sigla',
        headerName: t('com.muralis.qcx.sigla'),
        type: 'string',
        flex: 0.5,
        valueGetter: ({ row }) => (
          row?.sigla
        ),
      },
      {
        field: 'nome',
        headerName: t('com.muralis.qcx.servico.nomeServico'),
        type: 'string',
        flex: 2,
        valueGetter: ({ row }) => (
          row?.nome
        ),
      },
      {
        field: 'viaTransporte',
        headerName: t('com.muralis.qcx.transporte.viaTransporte'),
        type: 'string',
        flex: 1,
        valueGetter: (params) => params.row.viaTransporte.description,
      },
      {
        field: 'active', headerName: t('com.muralis.qcx.estado.ativo'), type: 'boolean', flex: 0.5,
      },
    ],
    selected,
    handleChange,
    isConsult,
  } = props;

  return (
    <>
      <Grid container direction="row" style={{ marginTop: '0.7rem' }}>
        <QCXSimpleSelectManyPageTemplate
          title={title}
          tableColumns={columns}
          tableData={list}
          filterProp={filterProp}
          isLoading={isAlternativeLoading}
          isConsult={isConsult}
          handleSetSelected={handleChange}
          selected={selected}
        />
      </Grid>
    </>
  );
}
