import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { isArray } from 'lodash';
import { v4 as uuid } from 'uuid';
import { isSubCreateMode } from '../../utils/store/store-utils';
import { AUTO_LOAD_CONTROL } from '../config-control';
import {
  BACKGROUND_CREATE_MODE,
  CONSULT_MODE,
  CREATE_MODE,
  NONE_BACKGROUND_MODE,
  NONE_MODE,
  NONE_SUB_MODE,
  SUB_CONSULT_MODE,
  SUB_CREATE_MODE,
  SUB_UPDATE_MODE,
  UPDATE_MODE,
} from '../mode';
import {
  ALTERNATIVE_LOADING_STATUS,
  FAILURE_STATUS,
  IDLE_STATUS,
  LOADING_STATUS,
  SUCCESS_STATUS,
} from '../status';
import {
  activateById, fetchAll,
  fetchByFilter,
  fetchById, inactivateById,
} from './clienteAPI';
import i18n from '../../i18n';

const initialState = {
  status: IDLE_STATUS,
  mode: {
    main: NONE_MODE,
    background: NONE_BACKGROUND_MODE,
    sub: NONE_SUB_MODE,
  },
  error: null,
  response: {
    status: null,
    message: null,
    data: null,
  },
  config: {
    control: AUTO_LOAD_CONTROL,
  },
  list: [],
  model: {
    code: '',
    description: '',
    limiteCredito: '',
    oea: null,
    restricaoFinanceira: null,
    afrmmBaseCalculoIcms: null,
    tipoEmpresa: {
      exportador: null,
      importador: null,
    },
    tipoPessoa: 'PESSOA_JURIDICA',
    endereco: {
      complemento: '',
      numero: '',
      logradouro: '',
      bairro: '',
      cep: '',
      cidade: {
        id: '',
        estado: {
          id: '',
          pais: {
            id: '',
          },
        },
      },
    },
    pessoa: {
      tipo: '',
      nome: '',
      nomeResumido: '',
      cpf: '',
      nascimento: '',
      dataNascimento: '',
      cnpj: '',
      inscricaoEstadual: '',
      inscricaoMunicipal: '',
      cnae: {
        id: '',
      },
    },
    contatoNfe: {
      email: '',
      telefone: '',
    },
    contatos: [],
    vencimentoRadar: '',
    vencimentoProcuracao: '',
    despachantes: [],
    unidadesDeNegocio: [],
    servicos: [],
    planoDeConta: {
      id: '',
    },
    moeda: {
      id: '',
    },
  },
  related: {
    model: {
      contato: {},
    },
  },
};

const fetchAllAsync = createAsyncThunk(
  'cliente/fetchAll',
  async () => {
    const { data } = await fetchAll();
    return { data };
  }
);

const fetchAllAsyncAndProcess = createAsyncThunk(
  'cliente/fetchAllAndProcess',
  async ({ exclude }) => {
    const { data } = await fetchAll();

    if (isArray(exclude)) {
      const processed = data.filter((model) => (
        exclude.indexOf(model?.id) === -1
      ));

      return {
        data: processed,
      };
    }

    return { data };
  }
);

const fetchByIdAsync = createAsyncThunk(
  'cliente/fetchById',
  async (data) => {
    const response = await fetchById(data);
    return {
      response: {
        status: response.status,
        data: response.data,
      },
    };
  }
);

const fetchByFilterAsync = createAsyncThunk(
  'cliente/fetchByFilter',
  async (params) => {
    const { data } = await fetchByFilter(params);
    return { data };
  }
);

const activateByIdAsync = createAsyncThunk(
  'cliente/activateById',
  async (id) => {
    const { status, data } = await activateById(id);
    return { response: { status, data } };
  }
);

const inactivateByIdAsync = createAsyncThunk(
  'cliente/inactivateById',
  async (id) => {
    const { status, data } = await inactivateById(id);
    return { response: { status, data } };
  }
);

const clienteSlice = createSlice({
  name: 'cliente',
  initialState,
  reducers: {
    changeStatusTo: (state, action) => {
      state.status = action.payload.status;
    },
    loading: (state) => {
      state.status = LOADING_STATUS;
    },
    success: (state) => {
      state.status = SUCCESS_STATUS;
    },
    failure: (state) => {
      state.status = FAILURE_STATUS;
    },
    resetStatus: (state) => {
      state.status = IDLE_STATUS;
    },
    changeToCreateMode: (state) => {
      state.mode.main = CREATE_MODE;
    },
    changeToConsultMode: (state) => {
      state.mode.main = CONSULT_MODE;
    },
    changeToUpdateMode: (state) => {
      state.mode.main = UPDATE_MODE;
    },
    resetMode: (state) => {
      state.mode.main = NONE_MODE;
    },
    changeToBackgroundCreateMode: (state) => {
      state.mode.background = BACKGROUND_CREATE_MODE;
    },
    resetBackgroundMode: (state) => {
      state.mode.background = NONE_BACKGROUND_MODE;
    },
    changeToSubCreateMode: (state) => {
      state.mode.sub = SUB_CREATE_MODE;
      state.related.model.contato = {};
    },
    changeToSubConsultMode: (state) => {
      state.mode.sub = SUB_CONSULT_MODE;
    },
    changeToSubUpdateMode: (state) => {
      state.mode.sub = SUB_UPDATE_MODE;
    },
    resetSubMode: (state) => {
      state.mode.sub = NONE_SUB_MODE;
    },
    changeControlTo: (state, action) => {
      state.config.control = action.payload;
    },
    resetControl: (state) => {
      state.config.control = initialState.config.control;
    },
    setModel: (state, action) => {
      const updatedContatosList = action.payload.contatos.map(
        (current) => ((current?.id === null)
          ? {
            ...current,
            id: uuid(),
          }
          : current
        )
      );

      state.model = {
        ...action.payload,
        contatos: updatedContatosList,
      };
    },
    addToList: (state, action) => {
      state.list = [...state.list, action.payload.data];
    },
    updateOnList: (state, action) => {
      state.list = state.list.map((current) => {
        if (current.id === action.payload.data?.id) {
          return action.payload.data;
        }
        return current;
      });
    },
    setList: (state, action) => {
      state.list = action.payload;
    },
    setRelatedContatoModel: (state, action) => {
      state.related.model.contato = {
        ...action.payload,
      };
      state.mode.sub = SUB_CONSULT_MODE;
    },
    addContato: (state, action) => {
      state.model.contatos = [
        ...state.model.contatos,
        {
          ...action.payload.data,
          id: uuid(),
        }
      ];
      state.related.model.contato = {};
      state.mode.sub = NONE_SUB_MODE;
    },
    updateContato: (state, action) => {
      const updated = action.payload.data;
      state.model.contatos = state.model.contatos.map(
        (current) => ((current?.id === updated?.id)
          ? updated : current
        )
      );
      state.mode.sub = SUB_CONSULT_MODE;
    },
    removeContatoById: (state, action) => {
      const { id } = action.payload.data;
      state.model.contatos = state.model.contatos.filter((current) => (
        current?.id !== id
      ));
      state.related.model.contato = initialState.related.model.contato;
      state.mode.sub = NONE_SUB_MODE;
    },
    resetContatos: (state, action) => {
      state.model.contatos = action.payload.data;
      state.related.model.contato = initialState.related.model.contato;
      state.mode.sub = NONE_SUB_MODE;
    },
    resetRelatedContatoModel: (state) => {
      state.related.model.contato = {};

      if (!isSubCreateMode(state.subMode)) {
        state.mode.sub = NONE_SUB_MODE;
      }
    },
    setUnidadesNegocio: (state, action) => {
      state.model.unidadesDeNegocio = [...action.payload];
    },
    setDespachantes: (state, action) => {
      state.model.despachantes = [...action.payload];
    },
    setServicos: (state, action) => {
      state.model.servicos = [...action.payload];
    },
    setResponse: (state, action) => {
      state.response = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    clearResponse: (state) => {
      state.response = {
        status: null,
        message: null,
        data: null,
      };
    },
    clearError: (state) => {
      state.error = initialState.error;
    },
    resetModel: (state) => {
      state.model = initialState.model;
    },
    resetList: (state) => {
      state.list = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllAsync.pending, (state) => {
        state.status = ALTERNATIVE_LOADING_STATUS;
      })
      .addCase(fetchAllAsync.fulfilled, (state, action) => {
        state.status = IDLE_STATUS;
        state.list = action.payload.data;
      })
      .addCase(fetchAllAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroCarregarListaCatalogoProdutosMensagem', { erro: action.error.message }),
        };
      })
      .addCase(fetchAllAsyncAndProcess.pending, (state) => {
        state.status = ALTERNATIVE_LOADING_STATUS;
      })
      .addCase(fetchAllAsyncAndProcess.fulfilled, (state, action) => {
        state.status = IDLE_STATUS;
        state.list = action.payload.data;
      })
      .addCase(fetchAllAsyncAndProcess.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroCarregarListaCatalogoProdutosMensagem', { erro: action.error.message }),
        };
      })
      .addCase(fetchByIdAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(fetchByFilterAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(fetchByIdAsync.fulfilled, (state, action) => {
        if (action.payload.response.status === 200) {
          state.model = {
            ...action.payload.response.data,
          };
          state.status = SUCCESS_STATUS;
        }
      })
      .addCase(fetchByFilterAsync.fulfilled, (state, action) => {
        state.status = IDLE_STATUS;
        state.list = action.payload.data;
      })
      .addCase(fetchByFilterAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroCarregarClienteMensagem', { erro: action.error.message }),
        };
      })
      .addCase(fetchByIdAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroCarregarClienteMensagem', { erro: action.error.message }),
        };
      })
      .addCase(activateByIdAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(activateByIdAsync.fulfilled, (state, action) => {
        state.status = SUCCESS_STATUS;
        state.response.status = action.payload.response.status;
        state.response.data = action.payload.response.data;

        const { description } = action.payload.response.data;
        state.response.message = i18n.t('com.muralis.qcx.mensagem.clienteAtivado', { clinte: description });

        if (state.response.status === 200) {
          state.list = state.list
            .map((current) => (
              current.id === action.payload.response.data.id
                ? action.payload.response.data
                : current
            ));
        }
      })
      .addCase(activateByIdAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroAtivarClienteMensagem', { erro: action.error.message }),
        };
      })
      .addCase(inactivateByIdAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(inactivateByIdAsync.fulfilled, (state, action) => {
        state.status = SUCCESS_STATUS;
        state.response.status = action.payload.response.status;
        state.response.data = action.payload.response.data;

        const { description } = action.payload.response.data;
        state.response.message = i18n.t('com.muralis.qcx.mensagem.clienteInativado', { cliente: description });

        if (state.response.status === 200) {
          state.list = state.list
            .map((current) => (
              current.id === action.payload.response.data.id
                ? action.payload.response.data
                : current
            ));
        }
      })
      .addCase(inactivateByIdAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroInativarClienteMensagem', { erro: action.error.message }),
        };
      });
  },
});

const {
  changeStatusTo,
  loading,
  success,
  failure,
  resetStatus,
  changeToCreateMode,
  changeToConsultMode,
  changeToUpdateMode,
  resetMode,
  changeToBackgroundCreateMode,
  resetBackgroundMode,
  changeToSubCreateMode,
  changeToSubConsultMode,
  changeToSubUpdateMode,
  resetSubMode,
  changeControlTo,
  resetControl,
  setModel,
  resetModel,
  resetList,
  addToList,
  setList,
  updateOnList,
  setRelatedContatoModel,
  addContato,
  updateContato,
  removeContatoById,
  resetRelatedContatoModel,
  setUnidadesNegocio,
  setDespachantes,
  setServicos,
  setResponse,
  setResponseMessage,
  clearResponse,
  setError,
  clearError,
  resetContatos,
} = clienteSlice.actions;

const selectClientes = (state) => state.cliente.list;
const selectStatus = (state) => state.cliente.status;
const selectMode = (state) => state.cliente.mode.main;
const selectBackgroundMode = (state) => state.cliente.mode.background;
const selectSubMode = (state) => state.cliente.mode.sub;
const selectConfigControl = (state) => state.cliente.config.control;
const selectError = (state) => state.cliente.error;
const selectResponse = (state) => state.cliente.response;
const selectCliente = (state) => state.cliente.model;

const selectRelatedContatoModel = (state) => (
  state.cliente.related.model.contato
);
const clienteActions = {
  ...clienteSlice.actions,
  selectClientes,
  selectStatus,
  selectMode,
  selectBackgroundMode,
  selectSubMode,
  selectError,
  selectResponse,
  selectCliente,
  selectRelatedContatoModel,
};

export {
  clienteSlice,
  changeStatusTo,
  loading,
  success,
  failure,
  resetStatus,
  changeToCreateMode,
  changeToConsultMode,
  changeToUpdateMode,
  resetMode,
  changeToBackgroundCreateMode,
  resetBackgroundMode,
  changeToSubCreateMode,
  changeToSubConsultMode,
  changeToSubUpdateMode,
  resetSubMode,
  changeControlTo,
  resetControl,
  setModel,
  resetModel,
  resetList,
  addToList,
  setList,
  updateOnList,
  setRelatedContatoModel,
  addContato,
  updateContato,
  removeContatoById,
  resetRelatedContatoModel,
  resetContatos,
  setUnidadesNegocio,
  setDespachantes,
  setServicos,
  setResponse,
  setResponseMessage,
  clearResponse,
  setError,
  clearError,
  fetchAllAsync,
  fetchAllAsyncAndProcess,
  fetchByIdAsync,
  fetchByFilterAsync,
  activateByIdAsync,
  inactivateByIdAsync,
  selectClientes,
  selectCliente,
  selectRelatedContatoModel,
  selectStatus,
  selectMode,
  selectBackgroundMode,
  selectSubMode,
  selectError,
  selectResponse,
  selectConfigControl,
  clienteActions,
};

export default clienteSlice.reducer;
