import React from 'react';
import QCXFormStepWizard from '../../shared-components/form-step-wizard/QCXFormStepWizard';
import QCXSelectManyUnidadeNegocioTable from './QCXSelectManyUnidadeNegocioTable';

export default function QCXSelectManyUnidadeNegocioFinalFormTable({
  list = [],
  isConsult,
  title,
  filterProp,
  filterPropGetter,
  columns,
  handleSubmit,
  handleChange,
  controlComponentProps,
  authInfo = {},
  requiredRoles = [],
  ...restProps
}) {
  return (
    <QCXFormStepWizard
      onSubmit={handleSubmit}
      controlComponentProps={controlComponentProps}
      authInfo={authInfo}
      requiredRoles={requiredRoles}
      {...restProps}
    >
      {() => (
        <QCXSelectManyUnidadeNegocioTable
          title={title}
          columns={columns}
          filterProp={filterProp}
          filterPropGetter={filterPropGetter}
          selected={list}
          isConsult={isConsult}
          handleChange={handleChange}
        />
      )}
    </QCXFormStepWizard>
  );
}
