import { createAsyncThunk } from '@reduxjs/toolkit';
import { solicitacaoPagamentoAPI } from '../solicitacao-pagamento/solicitacaoPagamentoAPI';

const fetchAllSolicitadasAsync = createAsyncThunk(
  'aprovarRecusarPagamento/solicitadas',
  async () => {
    const { data } = await solicitacaoPagamentoAPI.fetchAllSolicitadas();
    return { data };
  }
);

const aprovarRecusarPagamentoThunks = {
  fetchAllSolicitadasAsync,
};

export {
  aprovarRecusarPagamentoThunks,
  fetchAllSolicitadasAsync,
};
