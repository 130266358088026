import moment from 'moment';
import { useCallback } from 'react';
import { useForm } from 'react-final-form';
import QCXDatePicker from '../date-picker/QCXDatePicker';
import QCXFinalField from '../final-field/QCXFinalField';

export default function QCXFinalDatePickerField({
  name,
  label,
  required,
  validate,
  format = 'DD/MM/YYYY',
  placeholder = 'DD/MM/YYYY',
  disablePast = false,
  disableFuture = false,
  minDate = moment('1900-01-01').toDate(),
  ...restProps
}) {
  const form = useForm();

  const handleDoubleClick = useCallback(
    () => {
      form.change(name, new Date());
    },
    []
  );

  return (
    <QCXFinalField
      fullWidth
      name={name}
      required={required}
      render={({
        input: {
          name: inputName,
          value,
          onChange,
          ...restInput
        },
        meta,
        ...restComponentProps
      }) => {
        const showError = (
          (meta.submitError && !meta.dirtySinceLastSubmit)
          || meta.error
        ) && meta.touched;

        return (
          <QCXDatePicker
            name={inputName}
            label={label}
            value={value === '' ? null : value}
            onChange={onChange}
            format={format}
            placeholder={placeholder}
            error={showError}
            inputProps={restInput}
            helperText={showError ? meta.error || meta.submitError : undefined}
            disablePast={disablePast}
            disableFuture={disableFuture}
            minDate={minDate}
            handleDoubleClick={handleDoubleClick}
            {...restComponentProps}
          />
        );
      }}
      validate={validate}
      {...restProps}
    />
  );
}
