import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import {
  Search as PageviewIcon,
  Block as BlockIcon,
  FileCopy as FileCopyIcon,
  Receipt as ReceiptIcon,
  MoreHoriz as MoreHorizIcon,
  Timeline as TimelineIcon,
  GridOn as TableChartIcon,
} from '@material-ui/icons';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isEmpty, isFunction } from 'lodash';
import { bindTrigger, bindMenu } from 'material-ui-popup-state';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import { isAlternativeLoadingStatus, isRefresh } from '../../../../utils/store/store-utils';
import {
  changeToConsultMode,
  changeToCreateMode,
  resetBackgroundMode,
  resetHistoryModel,
  resetMode,
  resetModel,
  resetRefresh,
  resetSubMode,
  setRelatedDuplicataModel,
} from '../../../../features/follow-up/followUpSlice';
import {
  activateByIdAsync,
  duplicarByIdAsync,
  fetchAllAsync,
  inactivateByIdAsync,
} from '../../../../features/follow-up/followUpThunks';
import {
  selectFollowUp,
  selectFollowUps,
  selectHistoryModel,
  selectRefresh,
  selectRelatedDuplicataModel,
} from '../../../../features/follow-up/followUpSelectors';
import QCXSimpleConsultPageTemplate from '../../../../templates/simple-consult-page/QCXSimpleConsultPageTemplate';
import FollowUpContext from '../../../../contexts/import/follow-up/FollowUpContext';
import QCXListItemIcon from '../../../../shared-components/list-item-icon/QCXListItemIcon';
import QCXFinalFormDialog from '../../../../shared-components/dialog/QCXFinalFormDialog';
import {
  CLOSED_STATUS,
  DONE_STATUS,
  isOpenStatus,
} from '../../../../utils/hooks/form/dialog/formDialogUtils';
import QCXFinalTextField from '../../../../shared-components/final-text-field/QCXFinalTextField';
import { required } from '../../../../utils/validators/field/validator';
import QCXConfirmDialog from '../../../../shared-components/dialog/QCXConfirmDialog';
import useOperationMiddleware from '../../../../utils/hooks/operation/middleware/useOperationMiddleware';
import { userHasCreateRole, userHasDeleteRole } from '../../../../components/perfil/perfil-utils';
import QCXMoveFocusInside from '../../../../components/focus-lock/QCXMoveFocusInside';
import QCXPopupState from '../../../../components/popup-state/QCXPopupState';
import useFormDialogSync from '../../../../utils/hooks/form/dialog/useFormDialogSync';
import { setQuery } from '../../../../features/controller/controllerSlice';
import { formatDate } from '../../../../utils/general/general-utils';
import { servicoActions } from '../../../../features/servico/servicoSlice';
import { transportadoraActions } from '../../../../features/transportadora/transportadoraSlice';
import { IDLE_CONTROL } from '../../../../features/config-control';
import { formatCnpj } from '../../../../utils/hooks/form/field/formatters';
import FollowUpStatusUtils from '../../../../utils/general/follow-up/FollowUpStatusUtils';
import { clienteActions } from '../../../../features/cliente/clienteSlice';

export default function FollowUpConsultPage({ authInfo = {} }) {
  const { t } = useTranslation();
  const history = useHistory();

  const {
    status,
  } = useContext(FollowUpContext);

  const dispatch = useDispatch();

  const refresh = useSelector(selectRefresh);
  const followUp = useSelector(selectFollowUp);
  const declaracoesImportacao = useSelector(selectFollowUps);
  const duplicataRelatedModel = useSelector(selectRelatedDuplicataModel);
  const historyModel = useSelector(selectHistoryModel);

  const isAlternativeLoading = useMemo(() => (
    isAlternativeLoadingStatus(status)
  ), [status]);

  const fetchAll = useCallback(() => {
    dispatch(fetchAllAsync());
  }, []);

  const resetModes = useCallback(() => {
    dispatch(resetMode());
    dispatch(resetBackgroundMode());
    dispatch(resetSubMode());
  }, []);

  const resetModels = useCallback(() => {
    dispatch(resetModel());
    dispatch(resetHistoryModel());
  }, []);

  useEffect(() => {
    fetchAll();

    resetModes();
    resetModels();
  }, []);

  const configureDependencies = useCallback(() => {
    dispatch(clienteActions.changeControlTo(IDLE_CONTROL));
    dispatch(servicoActions.changeControlTo(IDLE_CONTROL));
    dispatch(transportadoraActions.changeControlTo(IDLE_CONTROL));
  }, []);

  useEffect(() => {
    const checkIfIsRefresh = () => {
      if (isRefresh(refresh)) {
        dispatch(fetchAllAsync());
        dispatch(resetRefresh());
      }
    };

    checkIfIsRefresh();
  }, [refresh]);

  useEffect(() => {
    const checkIfExistsQuery = () => {
      if (!isEmpty(historyModel) && historyModel?.last?.numero) {
        dispatch(
          setQuery(historyModel?.last?.numero)
        );
      }
    };

    checkIfExistsQuery();
  }, [historyModel]);

  const handleConsultarTimelineClick = useCallback(
    (event, data) => {
      if (isFunction(event?.stopPropagation)) {
        event.stopPropagation();

        dispatch(changeToConsultMode());

        history.push(
          t('com.muralis.qcx.url.importacaoFollowUpTimeline', { id: data?.id })
        );
      }
    },
    [history]
  );

  const handleConsultarListClick = useCallback(
    (event, data) => {
      if (isFunction(event?.stopPropagation)) {
        event.stopPropagation();

        dispatch(changeToConsultMode());

        history.push(
          `/importacao/follow-up/${data.id}/lista`
        );
      }
    },
    [history]
  );

  const handleConsultarClick = useCallback(
    (event, data) => {
      if (isFunction(event?.stopPropagation)) {
        event.stopPropagation();

        configureDependencies();

        dispatch(changeToConsultMode());

        history.push(
          t('com.muralis.qcx.url.importacaoFollowUpDetalhes', { id: data?.id })
        );
      }
    },
    [
      history,
      configureDependencies,
    ]
  );

  const [
    handleDuplicarClick,
    formDialogDuplicacaoStatus,
    handleFormDialogDuplicacaoStatus,
  ] = useFormDialogSync(
    async (event, data) => {
      if (isFunction(event?.stopPropagation)) {
        event.stopPropagation();

        dispatch(
          setRelatedDuplicataModel({
            id: data?.id,
            meta: {
              from: {
                numero: data?.numero,
              },
            },
          })
        );
      }
    },
    []
  );

  const handleDuplicarByProcessoSubmit = useCallback((values) => {
    dispatch(
      duplicarByIdAsync({
        id: values?.id,
        meta: {
          from: {
            numero: values?.meta?.from?.numero,
          },
        },
        payload: {
          atributosAdicionais: {
            tipo: values?.atributosAdicionais?.tipo,
            numero: values?.atributosAdicionais?.numero,
          },
        },
      })
    );

    handleFormDialogDuplicacaoStatus(DONE_STATUS);
  }, [handleFormDialogDuplicacaoStatus]);

  const handleCloseFormDialogDuplicacao = useCallback(() => (
    handleFormDialogDuplicacaoStatus(CLOSED_STATUS)
  ), [handleFormDialogDuplicacaoStatus]);

  const isFormDialogDuplicacaoOpen = useMemo(() => (
    isOpenStatus(formDialogDuplicacaoStatus)
  ));

  const formDialogDuplicacaoContent = useMemo(() => (
    t('com.muralis.qcx.mensagem.preenchaNumeroNovoProcessoDuplicarFollowUp', { numero: duplicataRelatedModel?.meta?.from?.numero })
  ), [duplicataRelatedModel]);

  const [
    handleActivateOrInactivateByIdClickMiddleware,
    operationOfActivateOrInactivateById,
  ] = useOperationMiddleware(
    async (data) => {
      const isInactivation = data?.active;

      if (isInactivation) {
        dispatch(inactivateByIdAsync(data?.id));

        return;
      }

      dispatch(activateByIdAsync(data?.id));
    },
    [declaracoesImportacao]
  );

  const handleConfigureActivateOrInactivateByIdClick = useCallback(
    (row, popupState) => {
      const {
        id,
        atributosAdicionais,
        active,
      } = row;

      const handleActivateOrInactivateByIdClick = (event) => {
        if (isFunction(event?.stopPropagation)) {
          popupState.close(event);

          event.stopPropagation();

          const configureOperation = () => ({
            options: {
              title: t('com.muralis.qcx.acoes.confirmarOperacaoExclusao').toUpperCase(),
              message: (
                t('com.muralis.qcx.acoes.confirmacaoAcaoDI', { acao: active ? t('com.muralis.qcx.acoes.excluir') : t('com.muralis.qcx.acoes.recuperar'), numero: atributosAdicionais?.numero })
              ),
            },
          });

          const dataOfOperationActivateOrInactivate = {
            id,
            active,
          };

          handleActivateOrInactivateByIdClickMiddleware(
            configureOperation,
            dataOfOperationActivateOrInactivate
          );
        }
      };

      return handleActivateOrInactivateByIdClick;
    },
    [handleActivateOrInactivateByIdClickMiddleware]
  );

  const handleButtonNewClick = useCallback((event) => {
    event.stopPropagation();

    configureDependencies();

    dispatch(changeToCreateMode());

    history.push(t('com.muralis.qcx.url.importacaoFollowUpNovo'));
  }, [
    history,
    followUp,
    configureDependencies,
  ]);

  const columns = useMemo(() => ([
    {
      field: 'id',
      headerName: t('com.muralis.qcx.processo'),
      headerAlign: 'center',
      align: 'center',
      flex: 200,
      valueGetter: ({ row }) => (row?.numero || '-'),
    },
    {
      field: 'importador',
      headerName: t('com.muralis.qcx.cliente.label'),
      flex: 120,
      valueGetter: ({ row }) => (
        row?.importador?.pessoa?.nome?.split(' ')[0] || '-'
      ),
    },
    {
      field: 'importador.cnpj',
      headerName: t('com.muralis.qcx.empresa.CNPJ'),
      flex: 140,
      valueGetter: ({ row }) => (
        formatCnpj(row?.importador?.pessoa?.cnpj) || '-'
      ),
    },
    {
      field: 'servico',
      headerName: t('com.muralis.qcx.servico.labelSingular'),
      flex: 160,
      valueGetter: ({ row }) => (
        row?.servico?.description || '-'
      ),
    },
    {
      field: 'insertionDate',
      headerName: t('com.muralis.qcx.abertura'),
      headerAlign: 'center',
      align: 'center',
      type: 'date',
      flex: 100,
      valueGetter: ({ row }) => (
        formatDate(
          row?.insertionDate,
          'DD/MM/YYYY HH:mm:ss',
          'DD/MM/YYYY'
        ) || '-'
      ),
    },
    {
      field: 'status',
      headerName: t('com.muralis.qcx.status'),
      flex: 120,
      valueGetter: ({ row }) => FollowUpStatusUtils.getLabelByValue(row?.status)
          ? t(FollowUpStatusUtils.getLabelByValue(row?.status))
          : '-',
    },
    {
      field: 'actions',
      headerName: t('com.muralis.qcx.acoes.label'),
      headerAlign: 'center',
      align: 'center',
      flex: 120,
      renderCell: ({
        row: {
          id,
          atributosAdicionais,
          active,
        },
      }) => (
        <>
          <Tooltip
            title="Visualizar Lista"
            arrow
          >
            <IconButton
              key={`btn-lista-view-${id}`}
              name={`btn-lista-view-${id}`}
              onClick={(event) => {
                handleConsultarListClick(event, {
                  id,
                });
              }}
            >
              <TableChartIcon
                color="secondary"
                size={20}
              />
            </IconButton>
          </Tooltip>
          <Tooltip title={t('com.muralis.qcx.visualizarTimeline')} arrow>
            <IconButton
              key={`btn-timeline-view-${id}`}
              name={`btn-timeline-view-${id}`}
              onClick={(event) => {
                handleConsultarTimelineClick(event, {
                  id,
                });
              }}
            >
              <TimelineIcon
                color="secondary"
                size={20}
              />
            </IconButton>
          </Tooltip>
          <QCXPopupState popupId="popup-menu-follow-up">
            {(popupState) => (
              <>
                <Tooltip
                  title={t('com.muralis.qcx.maisOpcoes')}
                  arrow
                >
                  <IconButton
                    key={`btn-more-options-${id}`}
                    name={`btn-more-options-${id}`}
                    {...bindTrigger(popupState)}
                  >
                    <MoreHorizIcon
                      color="secondary"
                      size={20}
                    />
                  </IconButton>
                </Tooltip>
                <Menu {...bindMenu(popupState)}>
                  <MenuItem
                    onClick={(event) => {
                      popupState.close(event);

                      handleConsultarClick(event, {
                        id,
                      });
                    }}
                  >
                    <QCXListItemIcon>
                      <PageviewIcon fontSize="small" />
                    </QCXListItemIcon>
                    <Typography
                      variant="inherit"
                      style={{
                        fontSize: 12,
                      }}
                    >
                      {t('com.muralis.qcx.acoes.consultar').toUpperCase()}
                    </Typography>
                  </MenuItem>
                  {userHasCreateRole(authInfo, 'importacao-follow-up') && (
                    <>
                      <MenuItem
                        onClick={(event) => {
                          popupState.close(event);
                          handleDuplicarClick(event, {
                            id,
                            numero: atributosAdicionais?.numero,
                          });
                        }}
                        disabled
                      >
                        <QCXListItemIcon>
                          <FileCopyIcon
                            fontSize="small"
                            color="disabled"
                          />
                        </QCXListItemIcon>
                        <Typography
                          variant="inherit"
                          style={{
                            fontSize: 12,
                          }}
                        >
                          {t('com.muralis.qcx.acoes.duplicar').toUpperCase()}
                        </Typography>
                      </MenuItem>
                    </>
                  )}
                  {userHasDeleteRole(authInfo, 'importacao-follow-up') && (
                    <>
                      <MenuItem
                        onClick={(
                          handleConfigureActivateOrInactivateByIdClick(
                            {
                              id,
                              atributosAdicionais,
                              active,
                            },
                            popupState
                          )
                        )}
                        disabled
                      >
                        <QCXListItemIcon>
                          <BlockIcon
                            fontSize="small"
                            color="disabled"
                          />
                        </QCXListItemIcon>
                        <Typography
                          variant="inherit"
                          style={{
                            fontSize: 12,
                          }}
                        >
                          {t('com.muralis.qcx.acoes.cancelar').toUpperCase()}
                        </Typography>
                      </MenuItem>
                    </>
                  )}
                </Menu>
              </>
            )}
          </QCXPopupState>
        </>
      ),
    }
  ]), [
    handleDuplicarClick,
    handleConsultarClick,
    handleConsultarTimelineClick,
    handleConfigureActivateOrInactivateByIdClick,
  ]);

  const breadcrumbs = [
    {
      link: {
        to: '/',
        name: t('com.muralis.qcx.inicio'),
      },
    },
    {
      link: {
        to: t('com.muralis.qcx.url.moduloImportacao'),
        name: t('com.muralis.qcx.importacao.label'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.followup.label'),
      },
      default: true,
    },
  ];

  const templateProps = {
    page: {
      title: t('com.muralis.qcx.followup.label'),
      icon: <ReceiptIcon />,
      breadcrumbs,
    },
    control: {
      buttonNew: {
        description: t('com.muralis.qcx.followup.novoRegistroFollowUp'),
        onClick: handleButtonNewClick,
      },
    },
    table: {
      columns,
    },
  };

  return (
    <QCXSimpleConsultPageTemplate
      pageProps={templateProps.page}
      controlProps={templateProps.control}
      tableProps={templateProps.table}
      tableData={declaracoesImportacao}
      isLoading={isAlternativeLoading}
      authInfo={authInfo}
      requiredRoles={['importacao-follow-up']}
    >
      <QCXFinalFormDialog
        key="form-dialog-duplicacao-follow-up"
        id="form-dialog-duplicacao-follow-up"
        title={t('com.muralis.qcx.followup.duplicacaoDeclaracaoImportacaoFollowUp')}
        content={formDialogDuplicacaoContent}
        dialogActionsProps={{
          button: {
            submit: {
              description: t('com.muralis.qcx.acoes.duplicar'),
              size: 'small',
            },
            cancel: {
              description: t('com.muralis.qcx.acoes.cancelar'),
              size: 'small',
            },
          },
        }}
        open={isFormDialogDuplicacaoOpen}
        initialValues={duplicataRelatedModel}
        onSubmit={handleDuplicarByProcessoSubmit}
        onClose={handleCloseFormDialogDuplicacao}
      >
        {() => (
          <QCXMoveFocusInside>
            <QCXFinalTextField
              name="atributosAdicionais.numero"
              label={t('com.muralis.qcx.processo')}
              validate={required}
              required
            />
          </QCXMoveFocusInside>
        )}
      </QCXFinalFormDialog>
      <QCXConfirmDialog
        key="confirm-dialog-activate-inactivate-follow-up"
        id="confirm-dialog-activate-inactivate-follow-up"
        open={operationOfActivateOrInactivateById?.active}
        title={operationOfActivateOrInactivateById?.title}
        content={operationOfActivateOrInactivateById?.message}
        endContent={operationOfActivateOrInactivateById?.endMessage}
        onConfirm={operationOfActivateOrInactivateById.confirm}
        onClose={operationOfActivateOrInactivateById?.reset}
      />
    </QCXSimpleConsultPageTemplate>
  );
}
