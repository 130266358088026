interface ITDI {
  date: string;
  time: string;
  cnpj: string;
  userName: string;
  receiver: string;
}

interface FTDI {
  quantidadeDeProcessosTransmitidos: string;
}

interface Item {
  acordoAladi?: string;
  acordoTarifarioDoII?: string;
  aliquotaAcordoII?: string;
  aliquotaAdvaloremII?: string;
  aliquotaAntidumping?: string;
  aliquotaDoIcms?: string;
  aliquotaDoIPI?: string;
  aliquotaPercentualDeReduçãoII?: string;
  aliquotaReduzidaDoIPI?: string;
  aliquotaReduzidaII?: string;
  anoAtoLegalAntidumping?: string;
  anoAtoLegalDoIPI?: string;
  anoAtoLegalII?: string;
  anoExNaladiNcca?: string;
  anoExNaladiSh?: string;
  anoExNbmSh?: string;
  anoExNcm?: string;
  aplicacaoMercadoria?: string;
  baseDeCclculoAntidumping?: string;
  classeCodigoOnu?: string;
  codigoDeliveryNote?: string;
  codigoDispositivoLegalDeExoneracaoDoIcms?: string;
  codigoDoAgenteDoCambio?: string;
  codigoDoProduto?: string;
  codigoExportador?: string | null;
  codigoExportadorSap?: string | null;
  codigoFabricante?: string | null;
  codigoOnu?: string;
  codigoPaisDeOrigem?: string;
  codigoPaisDeProcedencia?: string;
  codigoTaxaDeJuros?: string;
  codigoUnidadeDeMedida?: string;
  codigoUnidadeDeMedidaAntidumping?: string;
  codigoUnidadeDeMedidaII?: string;
  codigoUnidadeDeMedidaIPI?: string;
  cogidoPaisDeAquisição?: string;
  condicaoDaMercadoria?: string;
  dataDaFatura?: string;
  descricaoDetalhadaDaMercadoria?: string;
  exAtoLegalAntidumping?: string;
  exII?: string;
  exIPI?: string;
  exNaladiNcca?: string;
  exNaladiSh?: string;
  exNbmSh?: string;
  exNcm?: string;
  fabricanteExportadorProdutor?: string;
  flagNecessitaDeLi?: string;
  fundamentoLegalDoII?: string;
  identificacao?: string;
  incoterm?: string;
  indicadoDeMultimodal?: string;
  indicadorCondicaoDaMercadoria?: string;
  indicadorDePeriodicidadeMensalDiaria?: string;
  indicadorDeTaxaDeJuros?: string;
  instituicaoFinanceira?: string;
  localDoIncoterm?: string;
  metodoValoracaoAduaneira?: string;
  modalidadeDePagamento?: string;
  moedaNegociada?: string;
  motivoSemCobertura?: string;
  naladiNcca?: string;
  naladiSh?: string;
  numeroDoItemDaFatura?: string;
  ncmSh?: string;
  notaComplementarNoTIPI?: string;
  numeroAtoLegalAntidumping?: string;
  numeroAtoLegalII?: string;
  numeroAtoLegalIPI?: string;
  numeroDaFatura?: string;
  numeroDaLi?: string;
  numeroDaLI?: string;
  numeroDeParcelas?: string;
  numeroDoAtoConcessorio?: string;
  numeroDoPedido?: string;
  numeroDoRofBacen?: string;
  numeroExNaladiNcca?: string;
  numeroExNaladiSh?: string;
  numeroExNbmSh?: string;
  numeroExNcm?: string;
  orgaoEmissorAtoLegalAntidumping?: string;
  orgaoEmissorAtoLegalDoIPI?: string;
  orgaoEmissorAtoLegalII?: string;
  orgaoEmissorExNaladiNcca?: string;
  orgaoEmissorExNaladiSh?: string;
  orgaoEmissorExNbmSh?: string;
  orgaoEmissorExNcm?: string;
  pagamentoAntecipado?: string;
  pagamentoAVista?: string;
  percentualDeReduçãoDoIPI?: string;
  percentualDesconto?: string;
  periodicidade?: string;
  pesoLiquido?: string | null;
  quantidade?: string;
  quantidadeAntidumping?: string;
  quantidadeII?: string;
  reducaoDaAliquotaDoIcms?: string;
  regimeDeTributacaoDoII?: string;
  regimeDeTributacaoDoIPI?: string;
  sequenciaItemDoProcesso?: string;
  taxaDeJuros?: string;
  textoCodigoOnu?: string;
  tipoAtoLegalAntidumping?: string;
  tipoAtoLegalII?: string;
  tipoAtoLegalIPI?: string;
  tipoDeCalculoValorDoItem?: string;
  tipoDeCambio?: string;
  tipoExNaladiNcca?: string;
  tipoExNaladiSh?: string;
  tipoExNbmSh?: string;
  tipoExNcm?: string;
  tipoRecepiente?: string;
  urfDeEntradaNoPais?: string;
  valorCoberturaAcimaDe360Dias?: string;
  valorDoDesconto?: string;
  valorEspecificoAntidumping?: string;
  valorEspecificoDoIPI?: string;
  valorTotal?: string;
  valorUnitario?: string;
  viaDeTransporte?: string;
}

interface FabricanteExportador {
  tipo: "Fabricante" | "Exportador";
  razaoSocial?: string;
  logradouro?: string;
  complemento?: string;
  numero?: string;
  codigoDoPais?: string;
  codigoDoFabricanteExportador?: string;
  codigoExportadorSap?: string | null;
}

interface Fatura {
  aplicacaoMercadoria?: string;
  codigoBandeiraVeiculo?: string;
  codigoConsignatario?: string;
  codigoExportador?: string | null;
  codigoFabricante?: string | null;
  codigoImportador?: string;
  codigoLocalEmbarque?: string;
  codigoPaisDeAquisicao?: string;
  codigoPaisDeOrigem?: string;
  codigoPaisProcedencia?: string;
  codigoTransportadorInternacional?: string;
  codigoURFdeDespacho?: string;
  condicaoMercadoria?: string;
  dataChegadaCarga?: string;
  dataDoProcesso?: string;
  dataEmissaoDocumentoCarga?: string;
  fabricantesExportadores?: FabricanteExportador[];
  fabricanteExportadorProdutor?: string;
  indicadorCambio?: string;
  indicadorCondicaoMercadoria?: string;
  indicadorConsignatario?: string;
  indicadorFUNDAP?: string;
  indicadorMultimodal?: string;
  indicadorProcesso?: string;
  metodoValoracaoAduaneira?: string;
  modalidadeDespacho?: string;
  nomeVeiculoTransporte?: string;
  numeroDaFatura?: string;
  numeroDocumentoCargaHouse?: string;
  numeroDocumentoCargaMaster?: string;
  numeroManifesto?: string;
  pesoBruto?: string;
  pesoLiquido?: string | null;
  placaDoVeiculo?: string;
  referenciaDoCliente?: string;
  tipoDeDeclaracao?: string;
  tipoDocumentoCarga?: string;
  tipoManifesto?: string;
  utilizacaoDaCarga?: string;
  viaTransporte?: string;
  vinculacaoImportadorExportador?: string;
  codigoAgenteCarga?: string;
  urfEntradaPais?: string;
  recintoAlfandegado?: string;
  numeroItens?: string;
  icms?: string;
  reducaoIcms?: string;
  moedaNegociada?: string;
  valorMercadoriaNegociada?: string | null;
  moedaFrete?: string;
  valorFretePrepaid?: string;
  valorFreteCollect?: string;
  valorFreteTerritorioNacional?: string;
  moedaSeguro?: string;
  tipoSeguro?: string;
  percentualSeguro?: string;
  valordoSeguro?: string;
  moedaCarga?: string;
  valorCarga?: string;
  incoterm?: string;
  codigoLocalIncoterm?: string;
  tipoCoberturaCambio?: string;
  codigoAgenteCambio?: string;
  percentualComissaoAgente?: string;
  modalidadePagamento?: string;
  indicadorTaxaJuros?: string;
  codigoTaxaJuros?: string;
  taxaJuros?: string;
  numeroParcelas?: string;
  periodicidade?: string;
  valorTotal?: string;
  instituicaoFinanceira?: string;
  siglaMoedaNegociada?: string;
  valorCambio?: string;
  numeroRofBacen?: string;
  motivoCambioSemCobertura?: string;
  pagamentoAntecipado?: string;
  pagamentoAVista?: string;
  periodicidadeMensalDiaria?: string;
  identificacaoCrt?: string;
  cadigoDeBarra?: string;
  setorLocalArmazenagem?: string;
  itens?: Item[];
}

interface Faturas {
  [key: string]: Fatura;
}

export interface TXTResult {
  itdi?: ITDI;
  faturas?: Faturas;
  ftdi?: FTDI;
}

let faturas: Faturas = {};
let faturaAtual = "";
let result: TXTResult = {};

const splitTextIntoLines = (text: string) => {
  return text.split("\n");
};

const processVinculacaoImportadorExportador = (line: string) => {
  switch (line) {
    case "1":
      return "NÃO TEM VINCULAÇÃO";
    case "2":
      return "VINCULAÇÃO SEM INFLUÊNCIA";
    case "3":
      return "VINCULAÇÃO COM INFLUÊNCIA";
    default:
      return "";
  }
};

const processFabricanteExportadorProdutor = (line: string) => {
  switch (line) {
    case "1":
      return "EXPORTADOR_FABRICANTE";
    case "2":
      return "EXPORTADOR_NAO_FABRICANTE";
    case "3":
      return "FABRICANTE_DESCONHECIDO";
    default:
      return "";
  }
};

const processModalidadeDespacho = (line: string) => {
  switch (line) {
    case "1":
      return "NORMAL";
    case "2":
      return "ANTECIPADO";
    case "3":
      return "SIMPLIFICADO";
    case "4":
      return "ANTECIPADO / SIMPLIFICADO";
    default:
      return "";
  }
};

const processCondicaoMercadoria = (line: string) => {
  switch (line) {
    case "1":
      return "MATERIAL USADO";
    case "2":
      return "ENCOMENDA";
    default:
      return "";
  }
};

const processAplicacaoMercadoria = (line: string) => {
  switch (line) {
    case "1":
      return "CONSUMO";
    case "2":
      return "REVENDA";
    default:
      return "";
  }
};

const processTipoManifesto = (line: string) => {
  switch (line) {
    case "1":
      return "MANIFESTO DE CARGA";
    case "2":
      return "TERMO DE ENTRADA";
    case "3":
      return "DTA";
    case "4":
      return "MIC/DTA";
    default:
      return "";
  }
};

const processTipoDocumentoCarga = (line: string) => {
  switch (line) {
    case "1":
      return "AWB";
    case "2":
      return "HOUSE AWB";
    case "3":
      return "DSIC";
    case "4":
      return "B/L";
    case "5":
      return "HOUSE B/L";
    case "6":
      return "CONHECIMENTO DE TRANSPORTE";
    case "7":
      return "RWB";
    case "8":
      return "HOUSE RWB";
    case "9":
      return "TIF/DTA";
    case "10":
      return "NÃO EXISTE";
    default:
      return "";
  }
};

const processUtilizacaoDaCarga = (line: string) => {
  switch (line) {
    case "1":
      return "TOTAL";
    case "2":
      return "PARCIAL";
    case "3":
      return "MAIS DE UM";
    default:
      return "";
  }
};

const processTipoSeguro = (line: string) => {
  switch (line) {
    case "P":
      return "PERCENTUAL";
    case "A":
      return "VALOR NAS ADIÇÕES";
    case "V":
      return "VALOR";
    default:
      return "";
  }
};

const processTipoDeCalculoValorDoItem = (line: string) => {
  switch (line) {
    case "1":
      return "QUANTIDADE X VALOR";
    case "2":
      return "QUANTIDADE X VALOR / 100";
    case "3":
      return "QUANTIDADE X VALOR / 1000";
    case "4":
      return "PESO LÍQUIDO X VALOR";
    case "5":
      return "QUANTIDADE X VALOR ESPECIAL";
    default:
      return "";
  }
};

const processAcordoTarifarioDoII = (line: string) => {
  switch (line) {
    case "1":
      return "MERCOSUL";
    case "2":
      return "ALADI";
    case "3":
      return "OMC";
    case "4":
      return "SGPC";
    case "5":
      return "NADA";
    default:
      return "";
  }
};

const processTipoDeCambio = (line: string) => {
  switch (line) {
    case "1":
      return "ATE 180 DIAS";
    case "2":
      return "DE 181 A 360 DIAS";
    case "3":
      return "ACIMA DE 361 DIAS";
    case "4":
      return "SEM COBERTURA";
    default:
      return "";
  }
};

const processITDI = (line: string) => {
  let basicInfo: ITDI = {
    date: line.substring(4, 10),
    time: line.substring(10, 16),
    cnpj: line.substring(16, 30),
    userName: line.substring(30, 50).trim(),
    receiver: line.substring(50, 90).trim(),
  };
  result.itdi = basicInfo;
};

const processCAPI = (line: string) => {
  let fatura: Fatura = {
    numeroDaFatura: line.substring(4, 19).trim(),
    referenciaDoCliente: line.substring(19, 39).trim(),
    dataDoProcesso: line.substring(39, 45).trim(),
    tipoDeDeclaracao: line.substring(45, 47).trim(),
    indicadorConsignatario: line.substring(47, 48).trim(),
    codigoImportador: line.substring(48, 52).trim(),
    codigoConsignatario: line.substring(52, 56).trim(),
    indicadorFUNDAP: line.substring(56, 57).trim(),
    indicadorCambio: line.substring(57, 58).trim(),
    codigoExportador: validateString(line.substring(58, 62).trim(), true),
    codigoFabricante: validateString(line.substring(62, 66).trim(), true),
    codigoPaisDeOrigem: line.substring(66, 69).trim(),
    codigoURFdeDespacho: line.substring(69, 76).trim(),
    codigoPaisDeAquisicao: line.substring(76, 79).trim(),
    indicadorProcesso: line.substring(79, 80).trim(),
    vinculacaoImportadorExportador: processVinculacaoImportadorExportador(
      line.substring(80, 81).trim()
    ),
    metodoValoracaoAduaneira: line.substring(81, 83).trim(),
    indicadorCondicaoMercadoria: line.substring(83, 84).trim(),
    fabricanteExportadorProdutor: processFabricanteExportadorProdutor(
      line.substring(84, 85).trim()
    ),
    modalidadeDespacho: processModalidadeDespacho(
      line.substring(85, 86).trim()
    ),
    condicaoMercadoria: processCondicaoMercadoria(
      line.substring(86, 87).trim()
    ),
    aplicacaoMercadoria: processAplicacaoMercadoria(
      line.substring(87, 88).trim()
    ),
    viaTransporte: line.substring(88, 90).trim(),
    indicadorMultimodal: line.substring(90, 91).trim(),
    codigoTransportadorInternacional: line.substring(91, 95).trim(),
    placaDoVeiculo: line.substring(95, 110).trim(),
    nomeVeiculoTransporte: line.substring(110, 140).trim(),
    codigoBandeiraVeiculo: line.substring(140, 143).trim(),
    tipoManifesto: processTipoManifesto(line.substring(143, 144).trim()),
    numeroManifesto: line.substring(144, 159).trim(),
    tipoDocumentoCarga: processTipoDocumentoCarga(
      line.substring(159, 161).trim()
    ),
    numeroDocumentoCargaMaster: line.substring(161, 172).trim(),
    numeroDocumentoCargaHouse: line.substring(172, 183).trim(),
    dataEmissaoDocumentoCarga: line.substring(183, 189).trim(),
    codigoLocalEmbarque: line.substring(189, 199).trim(),
    utilizacaoDaCarga: processUtilizacaoDaCarga(
      line.substring(199, 200).trim()
    ),
    dataChegadaCarga: line.substring(200, 206).trim(),
    pesoLiquido: validateString(line.substring(206, 221).trim()),
    pesoBruto: line.substring(221, 236).trim(),
    codigoPaisProcedencia: line.substring(236, 239).trim(),
  };
  faturaAtual = fatura.numeroDaFatura ?? "";
  faturas[fatura.numeroDaFatura!] = fatura;
};

const processCAP2 = (line: string) => {
  const numeroFatura = line.substring(4, 19).trim();
  let fatura: Fatura = {
    codigoAgenteCarga: line.substring(19, 23).trim(),
    urfEntradaPais: line.substring(23, 30).trim(),
    recintoAlfandegado: line.substring(30, 37).trim(),
    numeroItens: line.substring(37, 40).trim(),
    icms: line.substring(40, 47).trim(),
    reducaoIcms: line.substring(47, 54).trim(),
    moedaNegociada: line.substring(54, 57).trim(),
    valorMercadoriaNegociada: validateString(line.substring(57, 72).trim()),
    moedaFrete: line.substring(72, 75).trim(),
    valorFretePrepaid: line.substring(75, 90).trim(),
    valorFreteCollect: line.substring(90, 105).trim(),
    valorFreteTerritorioNacional: line.substring(105, 120).trim(),
    moedaSeguro: line.substring(120, 123).trim(),
    tipoSeguro: processTipoSeguro(line.substring(123, 124).trim()),
    percentualSeguro: line.substring(124, 131).trim(),
    valordoSeguro: line.substring(131, 146).trim(),
    moedaCarga: line.substring(146, 149).trim(),
    valorCarga: line.substring(149, 164).trim(),
    incoterm: line.substring(164, 167).trim(),
    codigoLocalIncoterm: line.substring(167, 177).trim(),
    tipoCoberturaCambio: line.substring(177, 178).trim(),
    codigoAgenteCambio: line.substring(178, 182).trim(),
    percentualComissaoAgente: line.substring(182, 188).trim(),
    modalidadePagamento: line.substring(188, 190).trim(),
    indicadorTaxaJuros: line.substring(190, 191).trim(),
    codigoTaxaJuros: line.substring(191, 195).trim(),
    taxaJuros: line.substring(195, 210).trim(),
    numeroParcelas: line.substring(210, 213).trim(),
    periodicidade: line.substring(213, 216).trim(),
    valorTotal: line.substring(216, 231).trim(),
    instituicaoFinanceira: line.substring(231, 233).trim(),
    siglaMoedaNegociada: line.substring(233, 237).trim(),
  };
  faturas[numeroFatura] = { ...faturas[numeroFatura], ...fatura };
};

const processCAP3 = (line: string) => {
  const numeroFatura = line.substring(4, 19).trim();
  let fatura: Fatura = {
    valorCambio: line.substring(19, 39).trim(),
    numeroRofBacen: line.substring(39, 47).trim(),
    motivoCambioSemCobertura: line.substring(47, 49).trim(),
    pagamentoAntecipado: line.substring(49, 64).trim(),
    pagamentoAVista: line.substring(64, 79).trim(),
    periodicidadeMensalDiaria: line.substring(79, 80).trim(),
    identificacaoCrt: line.substring(80, 91).trim(),
    cadigoDeBarra: line.substring(91, 102).trim(),
    setorLocalArmazenagem: line.substring(102, 105).trim(),
  };
  faturas[numeroFatura] = { ...faturas[numeroFatura], ...fatura };
};

const processITEA = (line: string) => {
  const numeroDaFatura = line.substring(4, 19).trim();
  const item: Item = {
    codigoDoProduto: line.substring(19, 39).trim(),
    quantidade: line.substring(39, 54).trim(),
    codigoUnidadeDeMedida: line.substring(54, 56).trim(),
    pesoLiquido: validateString(line.substring(56, 68).trim()),
    valorUnitario: line.substring(68, 80).trim(),
    tipoDeCalculoValorDoItem: processTipoDeCalculoValorDoItem(
      line.substring(80, 81).trim()
    ),
    percentualDesconto: line.substring(81, 86).trim(),
    valorDoDesconto: line.substring(86, 96).trim(),
    flagNecessitaDeLi: line.substring(96, 98).trim(),
    fabricanteExportadorProdutor: processFabricanteExportadorProdutor(
      line.substring(98, 99).trim()
    ),
    codigoExportador: line.substring(99, 103).trim(),
    codigoFabricante: line.substring(103, 107).trim(),
    codigoPaisDeOrigem: line.substring(107, 110).trim(),
    cogidoPaisDeAquisição: line.substring(110, 113).trim(),
    ncmSh: line.substring(113, 123).trim(),
    exNcm: line.substring(123, 126).trim(),
    tipoExNcm: line.substring(126, 135).trim(),
    orgaoEmissorExNcm: line.substring(135, 144).trim(),
    anoExNcm: line.substring(144, 148).trim(),
    numeroExNcm: line.substring(148, 154).trim(),
    numeroDoPedido: line.substring(154, 164).trim(),
    exNbmSh: line.substring(164, 167).trim(),
    tipoExNbmSh: line.substring(167, 176).trim(),
    orgaoEmissorExNbmSh: line.substring(176, 185).trim(),
    anoExNbmSh: line.substring(185, 189).trim(),
    numeroExNbmSh: line.substring(189, 195).trim(),
    naladiSh: line.substring(195, 205).trim(),
    exNaladiSh: line.substring(205, 208).trim(),
    tipoExNaladiSh: line.substring(208, 217).trim(),
    orgaoEmissorExNaladiSh: line.substring(217, 226).trim(),
    anoExNaladiSh: line.substring(226, 230).trim(),
    numeroExNaladiSh: line.substring(230, 236).trim(),
    sequenciaItemDoProcesso: line.substring(236, 240).trim(),
  };
  faturas[numeroDaFatura] = {
    ...faturas[numeroDaFatura],
    itens: [...(faturas[numeroDaFatura].itens ?? []), item],
  };
};

const processITEB = (line: string) => {
  const numeroDaFatura = line.substring(4, 19).trim();
  const item: Item = {
    naladiNcca: line.substring(19, 29).trim(),
    exNaladiNcca: line.substring(29, 32).trim(),
    tipoExNaladiNcca: line.substring(32, 41).trim(),
    orgaoEmissorExNaladiNcca: line.substring(41, 50).trim(),
    anoExNaladiNcca: line.substring(50, 54).trim(),
    numeroExNaladiNcca: line.substring(54, 60).trim(),
    metodoValoracaoAduaneira: line.substring(60, 62).trim(),
    codigoDispositivoLegalDeExoneracaoDoIcms: line.substring(62, 66).trim(),
    indicadorCondicaoDaMercadoria: line.substring(66, 67).trim(),
    condicaoDaMercadoria: processCondicaoMercadoria(
      line.substring(67, 68).trim()
    ),
    aplicacaoMercadoria: processAplicacaoMercadoria(
      line.substring(68, 69).trim()
    ),
    aliquotaDoIcms: line.substring(69, 75).trim(),
    reducaoDaAliquotaDoIcms: line.substring(75, 81).trim(),
    moedaNegociada: line.substring(81, 84).trim(),
    incoterm: line.substring(84, 87).trim(),
    localDoIncoterm: line.substring(87, 97).trim(),
    regimeDeTributacaoDoII: line.substring(97, 98).trim(),
    fundamentoLegalDoII: line.substring(98, 100).trim(),
    acordoTarifarioDoII: processAcordoTarifarioDoII(
      line.substring(100, 101).trim()
    ),
    acordoAladi: line.substring(101, 104).trim(),
    tipoAtoLegalII: line.substring(104, 113).trim(),
    orgaoEmissorAtoLegalII: line.substring(113, 122).trim(),
    anoAtoLegalII: line.substring(122, 126).trim(),
    numeroAtoLegalII: line.substring(126, 132).trim(),
    aliquotaAdvaloremII: line.substring(132, 139).trim(),
    aliquotaAcordoII: line.substring(139, 146).trim(),
    aliquotaReduzidaII: line.substring(146, 153).trim(),
    aliquotaPercentualDeReduçãoII: line.substring(153, 160).trim(),
    numeroDoItemDaFatura: line.substring(160, 172).trim(),
    codigoUnidadeDeMedidaII: line.substring(172, 174).trim(),
    quantidadeII: line.substring(174, 177).trim(),
    regimeDeTributacaoDoIPI: line.substring(177, 178).trim(),
    tipoAtoLegalIPI: line.substring(178, 187).trim(),
    orgaoEmissorAtoLegalDoIPI: line.substring(187, 196).trim(),
    anoAtoLegalDoIPI: line.substring(196, 200).trim(),
    numeroAtoLegalIPI: line.substring(200, 206).trim(),
    notaComplementarNoTIPI: line.substring(206, 208).trim(),
    aliquotaDoIPI: line.substring(208, 215).trim(),
    aliquotaReduzidaDoIPI: line.substring(215, 222).trim(),
    percentualDeReduçãoDoIPI: line.substring(222, 229).trim(),
    numeroDaLI: line.substring(229, 239).trim(),
  };
  faturas[numeroDaFatura] = {
    ...faturas[numeroDaFatura],
    itens: faturas[numeroDaFatura].itens!.map((i, index) => {
      if (index === faturas[numeroDaFatura].itens!.length - 1) {
        return { ...i, ...item };
      }
      return i;
    }),
  };
};

const processITEC = (line: string) => {
  const numeroDaFatura = line.substring(4, 19).trim();
  const item: Item = {
    valorEspecificoDoIPI: line.substring(19, 31).trim(),
    codigoUnidadeDeMedidaIPI: line.substring(31, 33).trim(),
    numeroDaFatura: line.substring(33, 36).trim(),
    exAtoLegalAntidumping: line.substring(36, 39).trim(),
    tipoAtoLegalAntidumping: line.substring(39, 49).trim(),
    orgaoEmissorAtoLegalAntidumping: line.substring(49, 59).trim(),
    anoAtoLegalAntidumping: line.substring(59, 63).trim(),
    numeroAtoLegalAntidumping: line.substring(63, 69).trim(),
    aliquotaAntidumping: line.substring(69, 76).trim(),
    baseDeCclculoAntidumping: line.substring(76, 91).trim(),
    valorEspecificoAntidumping: line.substring(91, 106).trim(),
    codigoUnidadeDeMedidaAntidumping: line.substring(106, 108).trim(),
    quantidadeAntidumping: line.substring(108, 111).trim(),
    tipoDeCambio: processTipoDeCambio(line.substring(111, 112).trim()),
    codigoDoAgenteDoCambio: line.substring(112, 116).trim(),
    modalidadeDePagamento: line.substring(122, 124).trim(),
    indicadorDeTaxaDeJuros: line.substring(124, 125).trim(),
    codigoTaxaDeJuros: line.substring(125, 129).trim(),
    taxaDeJuros: line.substring(129, 139).trim(),
    numeroDeParcelas: line.substring(139, 142).trim(),
    periodicidade: line.substring(142, 145).trim(),
    valorTotal: line.substring(145, 158).trim(),
    instituicaoFinanceira: line.substring(158, 160).trim(),
    valorCoberturaAcimaDe360Dias: line.substring(160, 172).trim(),
    numeroDoRofBacen: line.substring(172, 180).trim(),
    motivoSemCobertura: line.substring(180, 182).trim(),
    pagamentoAntecipado: line.substring(182, 194).trim(),
    pagamentoAVista: line.substring(194, 206).trim(),
    indicadorDePeriodicidadeMensalDiaria: line.substring(206, 207).trim(),
    exII: line.substring(207, 210).trim(),
    exIPI: line.substring(210, 213).trim(),
    tipoRecepiente: line.substring(213, 215).trim(),
    identificacao: line.substring(215, 224).trim(),
    viaDeTransporte: line.substring(224, 226).trim(),
    codigoPaisDeProcedencia: line.substring(226, 229).trim(),
    urfDeEntradaNoPais: line.substring(229, 236).trim(),
    indicadoDeMultimodal: line.substring(236, 237).trim(),
  };
  faturas[numeroDaFatura] = {
    ...faturas[numeroDaFatura],
    itens: faturas[numeroDaFatura].itens!.map((i, index) => {
      if (index === faturas[numeroDaFatura].itens!.length - 1) {
        return { ...i, ...item };
      }
      return i;
    }),
  };
};

const processITED = (line: string) => {
  const numeroDaFatura = line.substring(4, 19).trim();
  const item: Item = {
    pesoLiquido: validateString(line.substring(19, 38).trim()),
    numeroDaFatura: line.substring(38, 53).trim(),
    numeroDoItemDaFatura: line.substring(53, 57).trim(),
    numeroDaLi: line.substring(57, 67).trim(),
    dataDaFatura: line.substring(67, 73).trim(),
    codigoOnu: line.substring(73, 77).trim(),
    classeCodigoOnu: line.substring(77, 80).trim(),
    textoCodigoOnu: line.substring(80, 180).trim(),
    codigoDeliveryNote: line.substring(180, 230).trim(),
    codigoExportadorSap: line.substring(230, 240).trim(),
  };
  faturas[numeroDaFatura] = {
    ...faturas[numeroDaFatura],
    itens: faturas[numeroDaFatura].itens!.map((i, index) => {
      if (index === faturas[numeroDaFatura].itens!.length - 1) {
        return { ...i, ...item };
      }
      return i;
    }),
  };
};

const processDP = (line: string) => {
  const numeroDaFatura = line.substring(4, 19).trim();
  const item: Item = {
    numeroDoAtoConcessorio: line.substring(23, 39).trim(),
    descricaoDetalhadaDaMercadoria: line.substring(39, 240).trim(),
  };
  faturas[numeroDaFatura] = {
    ...faturas[numeroDaFatura],
    itens: faturas[numeroDaFatura].itens!.map((i, index) => {
      if (index === faturas[numeroDaFatura].itens!.length - 1) {
        const newDescription = `${i.descricaoDetalhadaDaMercadoria ?? ""} ${
          item.descricaoDetalhadaDaMercadoria
        }`.trim();
        return {
          ...i,
          ...item,
          descricaoDetalhadaDaMercadoria: newDescription,
        };
      }
      return i;
    }),
  };
};

const processAG4A = (line: string) => {
  const fabricante: FabricanteExportador = {
    tipo: "Exportador",
    razaoSocial: line.substring(4, 64).trim(),
    logradouro: line.substring(64, 144).trim(),
    complemento: line.substring(144, 214).trim(),
    numero: line.substring(214, 220).trim(),
    codigoDoPais: line.substring(220, 223).trim(),
    codigoDoFabricanteExportador: line.substring(223, 227).trim(),
    codigoExportadorSap: line.substring(227, 237).trim(),
  };
  faturas[faturaAtual] = {
    ...faturas[faturaAtual],
    fabricantesExportadores: [
      ...(faturas[faturaAtual].fabricantesExportadores ?? []),
      fabricante,
    ],
  };
};

const processAG4B = (line: string) => {
  const fabricante: FabricanteExportador = {
    tipo: "Fabricante",
    razaoSocial: line.substring(4, 64).trim(),
    logradouro: line.substring(64, 144).trim(),
    complemento: line.substring(144, 214).trim(),
    numero: line.substring(214, 220).trim(),
    codigoDoPais: line.substring(220, 223).trim(),
    codigoDoFabricanteExportador: line.substring(223, 227).trim(),
    codigoExportadorSap: line.substring(227, 237).trim(),
  };
  faturas[faturaAtual] = {
    ...faturas[faturaAtual],
    fabricantesExportadores: [
      ...(faturas[faturaAtual].fabricantesExportadores ?? []),
      fabricante,
    ],
  };
};

const processFTDI = (line: string) => {
  let finalInfo: FTDI = {
    quantidadeDeProcessosTransmitidos: line.substring(4, 8).trim(),
  };
  result.ftdi = finalInfo;
};

const processLine = (line: string) => {
  const tag = line.substring(0, 4);
  const dpRegex = /DP\d\d/gm;
  const dp = line.match(dpRegex);
  if (tag === "ITDI") {
    return processITDI(line);
  }
  if (tag == "CAPI") {
    return processCAPI(line);
  }
  if (tag == "CAP2") {
    return processCAP2(line);
  }
  if (tag == "CAP3") {
    return processCAP3(line);
  }
  if (tag == "ITEA") {
    return processITEA(line);
  }
  if (tag == "ITEB") {
    return processITEB(line);
  }
  if (tag == "ITEC") {
    return processITEC(line);
  }
  if (tag == "ITED") {
    return processITED(line);
  }
  if (dp) {
    return processDP(line);
  }
  if (tag == "AG4A") {
    return processAG4A(line);
  }
  if (tag == "AG4B") {
    return processAG4B(line);
  }
  if (tag == "FTDI") {
    return processFTDI(line);
  }
};

const validateString = (text: string, nullable?: boolean) => {
  if (text.length > 0) {
    return text.replace("�", "");
  } else {
    return nullable ? null : "0";
  }
};

const processTXTFile = (text: string) => {
  faturas = {};
  faturaAtual = "";
  result = {};
  const lines = splitTextIntoLines(text);
  lines.forEach((line) => {
    processLine(line);
  });
  result.faturas = faturas;
  return result;
};

export { processTXTFile, splitTextIntoLines, processITDI };
