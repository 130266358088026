import { isFunction } from 'lodash';
import React from 'react';
import FieldViewerContext from '../../contexts/components/field-viewer/FieldViewerContext';

export default function QCXFieldsViewerManager({
  values,
  children,
  ...restProps
}) {
  return (
    <FieldViewerContext.Provider
      value={values}
    >
      {
        isFunction(children)
          ? children(restProps)
          : children
      }
    </FieldViewerContext.Provider>
  );
}
