import Keycloak from '../../../components/keycloak/index';
import { doDeleteRequest, doGetRequest, doPostRequest, doPutRequest, makeRequest, MediaType } from '../api-utils';
import {
  QCX_ACTIVATE_ENDPOINT,
  QCX_API_BASE_URL,
  QCX_CALCULATE_ENDPOINT,
  QCX_CONFERIR_ENDPOINT,
  QCX_DUPLICAR_ENDPOINT,
  QCX_INACTIVATE_ENDPOINT,
  QCX_LIBERAR_ENDPOINT,
  QCX_RETRANSMITIR_ENDPOINT,
} from './domain-endpoint-utils';

const AUTHORIZATION_PREFIX = 'bearer ';
const makeQCXRequest = makeRequest(QCX_API_BASE_URL);
const KEYCLOAK_TOKEN_TIMEOUT = 10;

async function doGetQCXRequest(path = [], queryParams = [], config = {}) {
  const tenant = localStorage.getItem('tenant');
  return doGetRequest(makeQCXRequest, path, queryParams, {
    ...config,
    headers: {
      quickcomexTenant: tenant,
      Authorization: AUTHORIZATION_PREFIX + Keycloak.token,
      'Content-Type': MediaType.APPLICATION_JSON,
      ...config.headers,
    },
  });
}

async function doPostQCXRequest(path = [], data, queryParams = [], config = {}) {
  await Keycloak.updateToken(KEYCLOAK_TOKEN_TIMEOUT);
  const tenant = localStorage.getItem('tenant');
  return doPostRequest(makeQCXRequest, path, data, queryParams, {
    ...config,
    headers: {
      ...config.headers,
      Authorization: AUTHORIZATION_PREFIX + Keycloak.token,
      quickcomexTenant: tenant,
      'Content-Type': MediaType.APPLICATION_JSON,
    },
  });
}

async function doPutQCXRequest(path = [], data = null, queryParams = [], config = {}) {
  const tenant = localStorage.getItem('tenant');
  await Keycloak.updateToken(KEYCLOAK_TOKEN_TIMEOUT);
  return doPutRequest(makeQCXRequest, path, data, queryParams, {
    ...config,
    headers: {
      ...config.headers,
      Authorization: AUTHORIZATION_PREFIX + Keycloak.token,
      quickcomexTenant: tenant,
      'Content-Type': MediaType.APPLICATION_JSON,
    },
  });
}

async function doDeleteQCXRequest(path = [], config = {}) {
  const tenant = localStorage.getItem('tenant');
  return doDeleteRequest(makeQCXRequest, path, {
    ...config,
    headers: {
      ...config.headers,
      Authorization: AUTHORIZATION_PREFIX + Keycloak.token,
      quickcomexTenant: tenant,
      'Content-Type': MediaType.APPLICATION_JSON,
    },
  });
}

async function doPutQCXActivateRequest(path = [], id, data = null, queryParams = [], config = {}) {
  const tenant = localStorage.getItem('tenant');
  return doPutQCXRequest([...path, QCX_ACTIVATE_ENDPOINT, id], data, queryParams, {
    ...config,
    headers: {
      quickcomexTenant: tenant,
      Authorization: AUTHORIZATION_PREFIX + Keycloak.token,
      'Content-Type': MediaType.APPLICATION_JSON,
      ...config.headers,
    },
  });
}

async function doPutQCXInactivateRequest(path = [], id, data = null, queryParams = [], config = {}) {
  const tenant = localStorage.getItem('tenant');
  return doPutQCXRequest([...path, QCX_INACTIVATE_ENDPOINT, id], data, queryParams, {
    ...config,
    headers: {
      quickcomexTenant: tenant,
      Authorization: AUTHORIZATION_PREFIX + Keycloak.token,
      'Content-Type': MediaType.APPLICATION_JSON,
      ...config.headers,
    },
  });
}

async function doPutQCXCalculateRequest(path = [], id, subPath = [], data = null, queryParams = [], config = {}) {
  const tenant = localStorage.getItem('tenant');
  return doPutQCXRequest([...path, QCX_CALCULATE_ENDPOINT, id, ...subPath], data, queryParams, {
    ...config,
    headers: {
      quickcomexTenant: tenant,
      Authorization: AUTHORIZATION_PREFIX + Keycloak.token,
      'Content-Type': MediaType.APPLICATION_JSON,
      ...config.headers,
    },
  });
}

async function doPutQCXConferirRequest(path = [], id, subPath = [], config = {}) {
  return doPutQCXRequest([...path, QCX_CONFERIR_ENDPOINT, id, ...subPath], config);
}

async function doPutQCXDuplicarRequest(path = [], id, subPath = [], data = null, queryParams = [], config = {}) {
  return doPutQCXRequest([...path, QCX_DUPLICAR_ENDPOINT, id, ...subPath], data, queryParams, config);
}

async function doPutQCXRetificarRequest(path = [], id, subPath = [], data = null, queryParams = [], config = {}) {
  return doPutQCXRequest([...path, 'retificar', id, ...subPath], data, queryParams, config);
}

async function doPutQCXLiberarRequest(path = [], id, subPath = [], config = {}) {
  return doPutQCXRequest([...path, QCX_LIBERAR_ENDPOINT, id, ...subPath], config);
}

async function doPutQCXRetransmitirRequest(path = [], id, subPath = [], config = {}) {
  return doPutQCXRequest([...path, QCX_RETRANSMITIR_ENDPOINT, id, ...subPath], config);
}

export {
  doGetQCXRequest,
  doPostQCXRequest,
  doPutQCXRequest,
  doDeleteQCXRequest,
  doPutQCXActivateRequest,
  doPutQCXInactivateRequest,
  doPutQCXCalculateRequest,
  doPutQCXConferirRequest,
  doPutQCXDuplicarRequest,
  doPutQCXLiberarRequest,
  doPutQCXRetificarRequest,
  doPutQCXRetransmitirRequest,
};
