import debounce from 'lodash/debounce';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import QCXArmazemWizardFinalForm from '../../../../../components/armazem/QCXArmazemWizardFinalForm';
import {
  addToList,
  changeToBackgroundCreateMode,
  changeToConsultMode,
  changeToCreateMode,
  changeToUpdateMode,
  failure,
  fetchByIdAsync,
  loading,
  preparingAction,
  resetBackgroundMode,
  resetModel,
  selectArmazem,
  selectBackgroundMode,
  selectMode,
  selectStatus,
  setError,
  setModel,
  setResponse, success, updateOnList,
} from '../../../../../features/armazens/armazemSlice';
import { register, save } from '../../../../../features/armazens/armazensAPI';
import QCXRegistrationFormPageTemplate from '../../../../../templates/registration-form-page/QCXRegistrationFormPageTemplate';
import { normalizeZipCode } from '../../../../../utils/general/general-utils';
import { formatCep } from '../../../../../utils/hooks/form/field/formatters';
import {
  isBackgroundCreateMode,
  isConsultMode,
  isCreateMode,
  isNoneMode,
  isPreparingActionStatus,
  isUpdateMode,
} from '../../../../../utils/store/store-utils';

export default function ArmazemRegistrationPage({ authInfo = {} }) {
  const { t } = useTranslation();

  const { id } = useParams();

  const history = useHistory();

  const dispatch = useDispatch();

  const [isCreated, setIsCreated] = useState(false);

  const mode = useSelector(selectMode);
  const backgroundMode = useSelector(selectBackgroundMode);
  const status = useSelector(selectStatus);

  const armazem = useSelector(selectArmazem);

  const isNone = useMemo(() => (
    isNoneMode(mode)
  ), [mode]);

  const isCreate = useMemo(() => (
    isCreateMode(mode)
  ), [mode]);

  const isConsult = useMemo(() => (
    isConsultMode(mode)
  ), [mode]);

  const isUpdate = useMemo(() => (
    isUpdateMode(mode)
  ), [mode]);

  const isBackgroundCreate = useMemo(() => (
    isBackgroundCreateMode(backgroundMode)
  ), [backgroundMode]);

  const isPreparingAction = useMemo(() => (
    isPreparingActionStatus(status)
  ), [status]);

  const handleChangeToCreate = () => {
    dispatch(changeToCreateMode());
  };

  const handleChangeToBackgroundCreate = () => {
    dispatch(changeToBackgroundCreateMode());
  };

  const handleResetBackgroundMode = () => {
    dispatch(resetBackgroundMode());
  };

  const handleChangeToPreparingAction = () => {
    dispatch(preparingAction());
  };

  const handleChangeToConsult = () => {
    dispatch(changeToConsultMode());
  };

  const handleChangeToUpdate = () => {
    dispatch(changeToUpdateMode());
  };

  useEffect(() => {
    if (id) {
      dispatch(fetchByIdAsync(id));
      handleChangeToConsult();
    }
  }, [id]);

  const handleCancelUpdate = () => {
    const currentId = isBackgroundCreate ? armazem?.id : id;

    if (currentId) {
      dispatch(fetchByIdAsync(currentId));
    }
    handleChangeToConsult();
  };

  const normalize = useCallback((data) => {
    const {
      endereco: {
        cep,
        ...restEndereco
      },
      ...restData
    } = data;
    return {
      endereco: {
        cep: normalizeZipCode(cep),
        ...restEndereco,
      },
      ...restData,
    };
  }, []);

  const unnormalize = useCallback((normalizedData) => {
    const unnormalizedData = {
      ...normalizedData,
      endereco: {
        ...normalizedData?.endereco,
        cep: formatCep(normalizedData?.endereco?.cep),
      },
    };

    return unnormalizedData;
  }, []);

  const handleDispatchSetModel = useCallback((data) => {
    dispatch(setModel(normalize(data)));
  }, [normalize]);

  const createByStep = async (data, step, next) => {
    const isFirstStep = step === 0;
    const isLastStep = step === 1;

    const executeDebounced = debounce(async () => {
      try {
        if (isFirstStep && !isCreated) {
          const response = await register(data);

          if (response?.status === 201) {
            next();
            dispatch(resetModel());
            dispatch(success());

            const created = response?.data;

            handleDispatchSetModel(created);
            dispatch(addToList({ data: created }));

            setIsCreated(true);
          }
        } else if (isLastStep) {
          const response = await save(data);

          if (response?.status === 200) {
            dispatch(resetModel());

            const handleResultWithDebounce = debounce(() => {
              history.push(t('com.muralis.qcx.url.moduloOperacionaisArmazens'));

              dispatch(success());

              const saved = response?.data;

              dispatch(setResponse({
                status: response.status,
                data: saved,
                message: t('com.muralis.qcx.mensagem.armazemCadastrado', { armazem: saved?.pessoa?.nomeResumido }),
              }));

              dispatch(updateOnList({ data: saved }));
            }, 500);

            handleResultWithDebounce();
          }
        } else {
          const response = await save(data);

          if (response?.status === 200) {
            next();
            const saved = response?.data;

            dispatch(success());
            dispatch(setModel(saved));
            dispatch(updateOnList({ data: saved }));
          }
        }
      } catch ({ response }) {
        dispatch(failure());
        dispatch(setError({
          message: t('com.muralis.qcx.erro.erroSalvarArmazem', { erro: response.data.message }),
        }));
      }
    }, 500);

    dispatch(loading());
    executeDebounced();
  };

  const update = async (data) => {
    const executeDebounced = debounce(async () => {
      try {
        const response = await save(data);

        if (response?.status === 200) {
          const handleResultWithDebounce = debounce(() => {
            handleChangeToConsult();
            dispatch(success());

            const saved = response?.data;

            dispatch(setResponse({
              status: response.status,
              data: saved,
              message: t('com.muralis.qcx.mensagem.armazemSalvo', { armazem: saved?.pessoa?.nomeResumido }),
            }));

            dispatch(setModel(saved));
            dispatch(updateOnList({ data: saved }));
          }, 500);

          handleResultWithDebounce();
        }
      } catch ({ response }) {
        dispatch(failure());
        dispatch(setError({
          message: t('com.muralis.qcx.erro.erroSalvarArmazemEspecifico', { armazem: data?.pessoa?.nomeResumido, erro: response?.data?.message }),
        }));
      }
    }, 500);

    dispatch(loading());
    executeDebounced();
  };

  const handleSubmit = async (data, step, next) => {
    const normalizedData = normalize(data);

    if (isUpdate && !isBackgroundCreate) {
      await update(normalizedData);
      handleChangeToConsult();
    } else {
      await createByStep(normalizedData, step, next);
    }
  };

  const handleAlternativeSave = async (event, step, next) => {
    if (event) {
      event.stopPropagation();
    }

    const normalizedData = normalize(armazem);

    if (isUpdate) {
      await update(normalizedData);
    } else {
      createByStep(normalizedData, step, next);
    }
  };

  const model = useMemo(() => (
    isCreated || !isCreate
      ? unnormalize(armazem)
      : {}
  ), [isCreate, isCreated, armazem, unnormalize]);

  const actionName = useMemo(() => {
    if (isCreate || isBackgroundCreate || isNone) return t('com.muralis.qcx.acoes.novo');
    if (isConsult) return t('com.muralis.qcx.acoes.visualizar');
    return t('com.muralis.qcx.acoes.alterar');
  }, [isNone, isCreate, isConsult, isBackgroundCreate]);

  const breadcrumbs = useMemo(() => ([
    {
      link: {
        to: '/',
        name: t('com.muralis.qcx.inicio'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.cadastros'),
      },
    },
    {
      link: {
        to: t('com.muralis.qcx.url.moduloOperacionais'),
        name: t('com.muralis.qcx.operacionais'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.armazem.labelPlural'),
      },
    },
    {
      link: {
        to: t('com.muralis.qcx.url.moduloOperacionaisArmazens'),
        name: t('com.muralis.qcx.principal'),
      },
    },
    {
      text: {
        name: actionName,
      },
    },
  ]), [actionName]);

  const pageTitle = useMemo(() => (
    isNone || isCreate || isBackgroundCreate
      ? t('com.muralis.qcx.armazem.novoArmazem')
      : t('com.muralis.qcx.armazem.armazemExistente', { ID: armazem?.code })
  ), [isNone, isCreate, isBackgroundCreate, armazem]);

  const breadcrumbsProps = {
    maxItems: 6,
  };

  return (
    <QCXRegistrationFormPageTemplate
      pageTitle={pageTitle}
      breadcrumbs={breadcrumbs}
      breadcrumbsProps={breadcrumbsProps}
      isCreate={isCreate}
      isConsult={isConsult}
      isUpdate={isUpdate}
      isBackgroundCreate={isBackgroundCreate}
      isPreparingAction={isPreparingAction}
      handleAlternativeSave={handleAlternativeSave}
      handleChangeToPreparingAction={handleChangeToPreparingAction}
      handleChangeToCreate={handleChangeToCreate}
      handleChangeToBackgroundCreate={handleChangeToBackgroundCreate}
      handleChangeToConsult={handleChangeToConsult}
      handleChangeToUpdate={handleChangeToUpdate}
      handleCancelUpdate={handleCancelUpdate}
      handleResetBackgroundMode={handleResetBackgroundMode}
      showSubtitle={false}
      authInfo={authInfo}
    >
      {(formProps) => (
        <QCXArmazemWizardFinalForm
          model={model}
          handleChangeModel={handleDispatchSetModel}
          handleSubmit={handleSubmit}
          authInfo={authInfo}
          requiredRoles={['armazem']}
          {...formProps}
        />
      )}
    </QCXRegistrationFormPageTemplate>
  );
}
