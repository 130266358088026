import { createFilterOptions } from '@material-ui/lab/useAutocomplete';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormState } from 'react-final-form';
import {
  fetchAllAsync,
  selectOrgaosAnuentes,
} from '../../features/orgao-anuente/orgaoAnuenteSlice';
import QCXAutocompleteSelect from '../autocomplete-select/QCXAutocompleteSelect';

export default function QCXSelectOrgaoAnuenteAutocomplete({
  label,
  name,
  initialValues,
  ...restProps
}) {
  const dispatch = useDispatch();

  const { values } = useFormState();

  const localInitialValues = useMemo(() => (initialValues ?? values), [values, initialValues]);

  const list = useSelector(selectOrgaosAnuentes);

  useEffect(() => {
    dispatch(fetchAllAsync());
  }, []);

  const [defaultValue, setDefaultValue] = useState('');

  useEffect(() => {
    const value = list.find((item) => item?.id === _.get(localInitialValues, name));
    if (value) {
      setDefaultValue(value);
    } else {
      setDefaultValue({ id: null, description: '' });
    }
  }, [list, localInitialValues]);

  const filterOptions = createFilterOptions({
    stringify: (item) => item?.description,
  });

  return (
    <QCXAutocompleteSelect
      label={label}
      id={name}
      name={name}
      options={list}
      filterOptions={filterOptions}
      defaultValue={defaultValue}
      getOptionLabel={(item) => (
        item?.description || ''
      )}
      {...restProps}
    />
  );
}
