import { Grid } from '@material-ui/core';
import { isFunction } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchAllAsync,
  selectClientes,
  selectStatus,
  resetList,
} from '../../features/cliente/clienteSlice';
import QCXSimpleSelectManyPageTemplate from '../../templates/simple-select-many-page/QCXSimpleSelectManyPageTemplate';
import { isAlternativeLoadingStatus } from '../../utils/store/store-utils';

export default function QCXSelectManyClienteTable({ ...props }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const clientes = useSelector(selectClientes);
  const status = useSelector(selectStatus);

  const isAlternativeLoading = useMemo(() => (
    isAlternativeLoadingStatus(status)
  ), [status]);

  const {
    title = t('com.muralis.qcx.cliente.selecioneClientes'),
    filterProp = 'description',
    columns = [
      {
        field: 'id',
        headerName: t('com.muralis.qcx.codigo'),
        headerAlign: 'center',
        align: 'center',
        type: 'string',
        flex: 50,
        valueGetter: ({ row }) => (
          row?.id
        ),
      },
      {
        field: 'description',
        headerName: t('com.muralis.qcx.cliente.label'),
        type: 'string',
        flex: 300,
        valueGetter: ({ row }) => (
          row?.description
        ),
      },
      {
        field: 'active', headerName: t('com.muralis.qcx.estado.ativo'), type: 'boolean', flex: 50,
      },
    ],
    selected,
    handleChange,
    isConsult,
    configureFilterPredicate,
    ...restProps
  } = props;

  const filteredClientesList = useMemo(() => {
    if (isFunction(configureFilterPredicate)) {
      const filterPredicate = configureFilterPredicate();
      const filteredValues = clientes.filter(filterPredicate);

      return filteredValues;
    }

    return clientes;
  }, [clientes, configureFilterPredicate]);

  useEffect(() => {
    dispatch(fetchAllAsync());
  }, []);

  useEffect(() => (
    () => {
      dispatch(resetList());
    }
  ), []);

  return (
    <>
      <Grid container direction="row" style={{ marginTop: '0.7rem' }}>
        <QCXSimpleSelectManyPageTemplate
          title={title}
          tableColumns={columns}
          tableData={filteredClientesList}
          filterProp={filterProp}
          isLoading={isAlternativeLoading}
          isConsult={isConsult}
          handleSetSelected={handleChange}
          selected={selected}
          {...restProps}
        />
      </Grid>
    </>
  );
}
