import React, { useCallback, useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash/debounce';
import { isEmpty } from 'lodash';
import {
  changeToConsultMode,
  changeToCreateMode,
  changeToUpdateMode,
  failure,
  fetchByIdAsync,
  loading,
  preparingAction,
  resetModel,
  selectTipoDocumento,
  selectMode,
  selectStatus,
  setError,
  setModel,
  setResponse,
  success,
  addToList,
  updateOnList,
} from '../../../../../features/tipo-documento/tipoDocumentoSlice';
import {
  isCreateMode,
  isConsultMode,
  isUpdateMode,
  isPreparingActionStatus,
  isNoneMode,
} from '../../../../../utils/store/store-utils';
import { register, save } from '../../../../../features/tipo-documento/tipoDocumentoAPI';
import QCXRegistrationFormPageTemplate from '../../../../../templates/registration-form-page/QCXRegistrationFormPageTemplate';
import QCXTipoDocumentoFinalForm from '../../../../../components/tipo-documento/QCXTipoDocumentoFinalForm';
import TipoDocumentoUtils from '../../../../../utils/general/tipo-documento/TipoDocumentoUtils';

export default function TipoDocumentoRegistrationPage({ authInfo }) {
  const { t } = useTranslation();
  const { id } = useParams();

  const history = useHistory();

  const dispatch = useDispatch();

  const status = useSelector(selectStatus);
  const mode = useSelector(selectMode);
  const tipoDocumento = useSelector(selectTipoDocumento);

  const isNone = useMemo(() => isNoneMode(mode), [mode]);

  const isCreate = useMemo(() => isCreateMode(mode), [mode]);

  const isConsult = useMemo(() => isConsultMode(mode), [mode]);

  const isUpdate = useMemo(() => isUpdateMode(mode), [mode]);

  const isPreparingAction = useMemo(() => isPreparingActionStatus(status), [status]);

  const handleChangeToPreparingAction = () => {
    dispatch(preparingAction());
  };

  const handleChangeToCreate = () => {
    dispatch(changeToCreateMode());
  };

  const handleChangeToConsult = () => {
    dispatch(changeToConsultMode());
  };

  const handleChangeToUpdate = () => {
    dispatch(changeToUpdateMode());
  };

  useEffect(() => {
    if (!isEmpty(id)) {
      dispatch(fetchByIdAsync(id));
      handleChangeToConsult();
    }
  }, [id]);

  const handleCancelUpdate = () => {
    const currentId = tipoDocumento?.id;

    if (currentId) {
      dispatch(fetchByIdAsync(currentId));
    }

    handleChangeToConsult();
  };

  const normalize = useCallback(
    (unnormalizedData) => ({
      ...unnormalizedData,
      publico: TipoDocumentoUtils.parseFromSingletonList(unnormalizedData?.publico || []),
    }),
    []
  );

  const unnormalize = useCallback(
    (normalizedData) => ({
      ...normalizedData,
      ignorableFields: {
        nomeExemplo: normalizedData?.nomePadrao ? `<numero_processo>_${normalizedData?.nomePadrao}.pdf` : '',
      },
      publico: TipoDocumentoUtils.parseToSingletonList(normalizedData?.publico),
    }),
    []
  );

  const handleDispatchSetModel = useCallback(
    (data) => {
      dispatch(setModel(normalize(data)));
    },
    [normalize]
  );

  const create = async (data) => {
    const executeDebounced = debounce(async () => {
      try {
        const response = await register(data);

        if (response?.status === 201) {
          dispatch(resetModel());

          const created = response?.data;

          handleDispatchSetModel(created);
          dispatch(addToList({ data: created }));

          const handleResultWithDebounce = debounce(() => {
            history.push(t('com.muralis.qcx.url.moduloGeraisTipoDocumentos'));

            dispatch(success());
            dispatch(
              setResponse({
                status: response.status,
                data: created,
                message: t('com.muralis.qcx.mensagem.tipoDocumentoCadastrado', { documento: data?.description }),
              })
            );
          }, 500);

          handleResultWithDebounce();
        }
      } catch (error) {
        const errorMessage =
          error?.response && error?.response?.data && error?.response.data.message
            ? t('com.muralis.qcx.erro.erroSalvarTipoDocumentoEspecifico', { erro: error?.response.data.message })
            : t('com.muralis.qcx.erro.erroSalvarTipoDocumento');

        dispatch(failure());
        dispatch(
          setError({
            message: errorMessage,
          })
        );
      }
    }, 500);

    dispatch(loading());
    executeDebounced();
  };

  const update = async (data) => {
    const executeDebounced = debounce(async () => {
      try {
        const response = await save(data);

        if (response?.status === 200) {
          const handleResultWithDebounce = debounce(() => {
            handleChangeToConsult();
            dispatch(success());

            const saved = response?.data;

            dispatch(
              setResponse({
                status: response.status,
                data: saved,
                message: t('com.muralis.qcx.mensagem.tipoDocumentoSalvo', { documento: saved?.description }),
              })
            );

            dispatch(setModel(saved));
            dispatch(updateOnList({ data: saved }));
            dispatch(fetchByIdAsync(saved?.id));
          }, 500);

          handleResultWithDebounce();
          handleChangeToConsult();
        }
      } catch ({ response }) {
        dispatch(failure());
        dispatch(
          setError({
            message: t('com.muralis.qcx.erro.erroSalvarTipoDocumentoEspecifico', { erro: response?.data?.message }),
          })
        );
      }
    }, 500);

    dispatch(loading());
    executeDebounced();
  };

  const handleSubmit = async (data) => {
    const normalizedData = normalize(data);

    if (isUpdate) {
      await update(normalizedData);
    } else {
      await create(normalizedData);
    }
  };

  const model = useMemo(
    () => (isConsult || isUpdate ? unnormalize(tipoDocumento) : {}),
    [isConsult, isUpdate, tipoDocumento, unnormalize]
  );

  const actionName = useMemo(() => {
    if (isCreate || isNone) return t('com.muralis.qcx.acoes.novo');
    if (isConsult) return t('com.muralis.qcx.acoes.visualizar');
    return t('com.muralis.qcx.acoes.alterar');
  }, [isNone, isCreate, isConsult]);

  const breadcrumbs = useMemo(
    () => [
      {
        link: {
          to: '/',
          name: t('com.muralis.qcx.inicio'),
        },
      },
      {
        text: {
          name: t('com.muralis.qcx.cadastros'),
        },
      },
      {
        link: {
          to: t('com.muralis.qcx.url.moduloCadastrosGerais'),
          name: t('com.muralis.qcx.gerais'),
        },
      },
      {
        link: {
          to: t('com.muralis.qcx.url.moduloGeraisTipoDocumentos'),
          name: t('com.muralis.qcx.documento.tiposDocumentos'),
        },
      },
      {
        text: {
          name: actionName,
        },
      },
    ],
    [actionName]
  );

  const pageTitle = useMemo(
    () =>
      isNone || isCreate
        ? t('com.muralis.qcx.documento.novoTipoDocumento')
        : t('com.muralis.qcx.documento.tipoDocumentoExistente', { identificacao: tipoDocumento?.description || '-' }),
    [isNone, isCreate, tipoDocumento]
  );

  return (
    <QCXRegistrationFormPageTemplate
      pageTitle={pageTitle}
      breadcrumbs={breadcrumbs}
      isCreate={isCreate}
      isConsult={isConsult}
      isUpdate={isUpdate}
      isPreparingAction={isPreparingAction}
      handleChangeToPreparingAction={handleChangeToPreparingAction}
      handleChangeToCreate={handleChangeToCreate}
      handleChangeToConsult={handleChangeToConsult}
      handleChangeToUpdate={handleChangeToUpdate}
      handleCancelUpdate={handleCancelUpdate}
      authInfo={authInfo}
    >
      {(formProps) => (
        <QCXTipoDocumentoFinalForm
          initialValues={model}
          handleChangeModel={handleDispatchSetModel}
          handleSubmit={handleSubmit}
          authInfo={authInfo}
          requiredRoles={['tipo-documento']}
          {...formProps}
        />
      )}
    </QCXRegistrationFormPageTemplate>
  );
}
