import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import QCXBox from '../../shared-components/box/QCXBox';
import QCXFinalTextField from '../../shared-components/final-text-field/QCXFinalTextField';
import QCXFormStepWizard from '../../shared-components/form-step-wizard/QCXFormStepWizard';
import QCXSelectEtapaAutocomplete from '../../shared-components/select-etapa/QCXSelectEtapaAutocomplete';
import QCXSelectViaTransporteAutocomplete from '../../shared-components/select-via-transporte/QCXSelectViaTransporteAutocomplete';
import { required as requiredValidator } from '../../utils/validators/field/validator';

export default function QCXModeloNumerarioTecnicalDataForm({
  initialValues,
  handleSubmit,
  isConsult,
  isUpdate,
  controlComponentProps,
  ...restProps
}) {
  const { t } = useTranslation();

  return (
    <QCXFormStepWizard
      initialValues={initialValues}
      onSubmit={handleSubmit}
      controlComponentProps={controlComponentProps}
      {...restProps}
    >
      {({ values }) => (
        <>
          <Typography variant="h6" color="primary">
            <QCXBox fontWeight="fontWeightBold" pt={5}>
              {t('com.muralis.qcx.dadosTecnicos').toUpperCase()}
            </QCXBox>
          </Typography>
          <Grid container spacing={2} style={{ marginTop: '1rem' }}>
            {(isConsult || isUpdate) && (
              <Grid item xs={12} sm={4} md={2}>
                <QCXFinalTextField
                  id="text-field-id-servico"
                  key="text-field-id-servico"
                  name="id"
                  label={t('com.muralis.qcx.codigo')}
                  disabled
                />
              </Grid>
            )}
            <Grid item xs={12} sm={!(isConsult || isUpdate) ? 12 : 8} md={!(isConsult || isUpdate) ? 4 : 4}>
              <QCXFinalTextField
                id="text-field-descricao"
                key="text-field-descricao"
                name="description"
                label={t('com.muralis.qcx.nomeModelo')}
                maxLength={40}
                validate={requiredValidator}
                disabled={isConsult}
                required
              />
            </Grid>
            <Grid item xs={12} md={!(isConsult || isUpdate) ? 4 : 3}>
              <QCXSelectViaTransporteAutocomplete
                id="select-field-via-transporte"
                key="select-field-via-transporte"
                name="viaTransporte.id"
                label={t('com.muralis.qcx.transporte.viaTransporte')}
                fieldProps={{ validate: requiredValidator }}
                initialValues={values}
                disabled={isConsult}
              />
            </Grid>
            <Grid item xs={12} md={!(isConsult || isUpdate) ? 4 : 3}>
              <QCXSelectEtapaAutocomplete
                id="select-field-etapa-follow-up"
                key="select-field-etapa-follow-up"
                name="etapa"
                label={t('com.muralis.qcx.etapa.etapaFollowUp')}
                fieldProps={{ validate: requiredValidator }}
                initialValues={values}
                disabled={isConsult}
              />
            </Grid>
          </Grid>
        </>
      )}
    </QCXFormStepWizard>
  );
}
