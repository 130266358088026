const selectFollowUps = (state) => state.followUp.list;
const selectFollowUp = (state) => state.followUp.model;
const selectRelatedDuplicataModel = (state) => (
  state.followUp.related.model.duplicata
);
const selectRelatedEmailModel = (state) => (
  state.followUp.related.model.email
);
const selectHistoryModel = (state) => state.followUp.historyModel;
const selectStatus = (state) => state.followUp.status;
const selectRefresh = (state) => state.followUp.refresh;
const selectMode = (state) => state.followUp.mode.main;
const selectBackgroundMode = (state) => state.followUp.mode.background;
const selectSubMode = (state) => state.followUp.mode.sub;
const selectConfigControl = (state) => state.followUp.config.control;
const selectError = (state) => state.followUp.error;
const selectResponse = (state) => state.followUp.response;

const followUpSelectors = {
  selectFollowUps,
  selectFollowUp,
  selectRelatedDuplicataModel,
  selectRelatedEmailModel,
  selectHistoryModel,
  selectStatus,
  selectRefresh,
  selectMode,
  selectBackgroundMode,
  selectSubMode,
  selectConfigControl,
  selectError,
  selectResponse,
};

export {
  followUpSelectors,
  selectFollowUps,
  selectFollowUp,
  selectRelatedDuplicataModel,
  selectRelatedEmailModel,
  selectHistoryModel,
  selectStatus,
  selectRefresh,
  selectMode,
  selectBackgroundMode,
  selectSubMode,
  selectConfigControl,
  selectError,
  selectResponse,
};
