import { useMemo } from "react";
import { QueriesTypes, useGetRequest } from "./queries";

export const useGenerateDANFEXMLReport = (idDANFE: string) => {
  const { data, isError, isLoading } = useGetRequest(
    QueriesTypes.DanfeDonwloadXML,
    idDANFE
  );

  const result = useMemo(() => {
    if (!isLoading && !isError) {
      return data;
    }
  }, [data, isLoading]);

  return { data: result };
};
