import { Grid, IconButton } from '@material-ui/core';
import { get, debounce, isEmpty } from 'lodash';
import React, {
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { useForm, useFormState } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  v4 as uuid,
} from 'uuid';
import {
  Edit as EditIcon,
  Block as BlockIcon,
  Clear as ClearIcon,
  Search as PageviewIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons';
import QCXAloneInlineBoxWrapper from '../alone-inline-box-wrapper/QCXAloneInlineBoxWrapper';
import QCXButton from '../button/QCXButton';
import {
  WARNING_SEVERITY,
  ERROR_SEVERITY,
} from '../../features/severity';
import { setErrorFeedback, setSuccessFeedback, setWarningFeedback } from '../../features/feedback/feedbackSlice';
import QCXSelectOrgaoAnuenteAutocomplete from '../select-orgao-anuente/QCXSelectOrgaoAnuenteAutocomplete';
import QCXFinalTextField from '../final-text-field/QCXFinalTextField';
import QCXDataGrid from '../data-grid/QCXDataGrid';
import { fetchById as fetchOrgaoAnuenteById } from '../../features/orgao-anuente/orgaoAnuenteAPI';
import {
  loading,
  resetStatus,
  selectStatus,
  selectSubMode,
  changeToSubConsultMode,
  changeToSubCreateMode,
  resetSubMode,
  setCurrent,
  changeToSubUpdateMode,
  selectCurrent,
  resetCurrent,
} from '../../features/vinculo-processo-licenca-importacao/vinculoProcessoLicencaImportacaoSlice';
import {
  isLoadingStatus,
  isNoneSubMode,
  isSubConsultMode,
  isSubCreateMode,
  isSubUpdateMode,
} from '../../utils/store/store-utils';
import QCXCircularProgress from '../progress/QCXCircularProgress';
import useOperationController from '../../utils/hooks/store/operation/useOperationController';
import i18n from '../../i18n';

const DEFAULT_FIELD_PROPS = {
  processo: {
    name: 'processo',
    label: i18n.t('com.muralis.qcx.processoVinculado'),
    maxLength: 20,
  },
  orgaoAnuente: {
    name: 'orgaoAnuente.id',
    label: i18n.t('com.muralis.qcx.orgao.orgaoAnuente'),
  },
};

const DEFAULT_LIST_PROPS = {
  name: 'processosVinculados',
};

const DEFAULT_IGNORABLE_FIELDS_PROPS = {
  rootName: 'ignorableFields.vinculo',
};

export default function QCXFinalControlBoxVinculoProcessoOrgaoAnuente({
  isConsult,
  fieldProps = DEFAULT_FIELD_PROPS,
  listProps = DEFAULT_LIST_PROPS,
}) {
  const dispatch = useDispatch();

  const status = useSelector(selectStatus);
  const subMode = useSelector(selectSubMode);
  const currentUpdating = useSelector(selectCurrent);

  const form = useForm();
  const { values } = useFormState();

  const isLoading = useMemo(() => (
    isLoadingStatus(status)
  ), [status]);

  const isSubNone = useMemo(() => (
    isNoneSubMode(subMode)
  ), [subMode]);

  const isSubCreate = useMemo(() => (
    isSubCreateMode(subMode)
  ), [subMode]);

  const isSubConsult = useMemo(() => (
    isSubConsultMode(subMode)
  ), [subMode]);

  const isSubUpdate = useMemo(() => (
    isSubUpdateMode(subMode)
  ), [subMode]);

  const vinculoIdFieldName = useMemo(() => (
    `${DEFAULT_IGNORABLE_FIELDS_PROPS.rootName}.id`
  ), [
    fieldProps,
  ]);

  const processoFieldName = useMemo(() => {
    const processoSimpleFieldName = (
      fieldProps?.processo?.name
      || DEFAULT_FIELD_PROPS.processo.name
    );

    const fullProcessoFieldName = `${
      DEFAULT_IGNORABLE_FIELDS_PROPS?.rootName
    }.${processoSimpleFieldName}`;

    return fullProcessoFieldName;
  }, [
    fieldProps,
  ]);

  const orgaoAnuenteFieldName = useMemo(() => {
    const orgaoAnuenteSimpleFieldName = (
      fieldProps?.orgaoAnuente?.name
      || DEFAULT_FIELD_PROPS.orgaoAnuente.name
    );

    const fullOrgaoAnuenteFieldName = `${
      DEFAULT_IGNORABLE_FIELDS_PROPS.rootName
    }.${orgaoAnuenteSimpleFieldName}`;

    return fullOrgaoAnuenteFieldName;
  }, [
    fieldProps,
  ]);

  const vinculoListName = useMemo(() => (
    listProps?.name
    || DEFAULT_LIST_PROPS.name
  ), [
    listProps,
  ]);

  const currentVinculo = useMemo(() => (
    get(
      values,
      DEFAULT_IGNORABLE_FIELDS_PROPS.rootName
    )
  ), [
    values,
  ]);

  const currentVinculos = useMemo(() => (
    get(
      values,
      vinculoListName
    ) || []
  ), [
    values,
    vinculoListName
  ]);

  const handleImpediment = useCallback((impediment) => {
    if (impediment?.severity === WARNING_SEVERITY) {
      dispatch(
        setWarningFeedback({
          message: impediment?.message,
        })
      );
      return;
    }
    if (impediment?.severity === ERROR_SEVERITY) {
      dispatch(
        setErrorFeedback({
          message: impediment?.message,
        })
      );
    }
  }, []);

  const handleVincularProcesso = useCallback(async (event) => {
    event?.stopPropagation();

    if (!currentVinculo?.processo) {
      const emptySelectionErrorMessage = i18n.t('com.muralis.qcx.validacao.campoProcessoObrigatorioVinculo');

      handleImpediment({
        severity: WARNING_SEVERITY,
        message: emptySelectionErrorMessage,
      });

      return;
    }

    if (!currentVinculo?.orgaoAnuente?.id) {
      const emptySelectionErrorMessage = i18n.t('com.muralis.qcx.mensagem.nenhumOrgaoAnuenteSelecionado');

      handleImpediment({
        severity: WARNING_SEVERITY,
        message: emptySelectionErrorMessage,
      });

      return;
    }

    dispatch(loading());

    const handleDebouncedVincular = debounce(async () => {
      try {
        const alreadyExistsVinculo = currentVinculos.some((vinculo) => (
          vinculo?.processo === currentVinculo?.processo
          && vinculo?.orgaoAnuente?.id === currentVinculo?.orgaoAnuente?.id
          && vinculo?.id !== currentUpdating?.id
        ));

        if (alreadyExistsVinculo) {
          const alreadyExistsVinculoErrorMessage = (
            i18n.t('com.muralis.qcx.erro.erroExisteVinculoProcessoOrgaoAnuenteAdicionado')
          );

          handleImpediment({
            severity: ERROR_SEVERITY,
            message: alreadyExistsVinculoErrorMessage,
          });

          dispatch(resetStatus());

          return;
        }

        const orgaoAnuenteResponse = await fetchOrgaoAnuenteById(
          currentVinculo?.orgaoAnuente?.id
        );

        if (orgaoAnuenteResponse?.status === 200) {
          const foundOrgaoAnuente = orgaoAnuenteResponse?.data;

          if (isSubUpdate) {
            const updatedVinculo = {
              ...currentVinculo,
              processo: currentVinculo?.processo,
              orgaoAnuente: foundOrgaoAnuente,
            };

            const updatedVinculos = currentVinculos
              ?.map((existingVinculo) => (
                existingVinculo?.id === updatedVinculo?.id
                  ? updatedVinculo
                  : existingVinculo
              )) || currentVinculos;

            form.change(vinculoListName, updatedVinculos);

            dispatch(resetCurrent());
            dispatch(changeToSubConsultMode());
            dispatch(
              setSuccessFeedback({
                message: i18n.t('com.muralis.qcx.mensagem.vinculoProcessoOrgaoAnuenteAtualizado', { processo: updatedVinculo?.processo, orgaoAnuente: updatedVinculo?.orgaoAnuente?.description }),
              })
            );
            return;
          }

          const vinculo = {
            id: uuid(),
            processo: currentVinculo?.processo,
            orgaoAnuente: foundOrgaoAnuente,
          };

          const updatedVinculos = [
            ...currentVinculos,
            vinculo,
          ];

          form.change(vinculoListName, updatedVinculos);
          form.change(DEFAULT_IGNORABLE_FIELDS_PROPS.rootName, undefined);

          dispatch(
            setSuccessFeedback({
              message: i18n.t('com.muralis.qcx.mensagem.vinculoProcessoOrgaoAnuenteAdicionado', { processo: vinculo?.processo, orgaoAnuente: vinculo?.orgaoAnuente?.description }),
            })
          );
        }
      } catch (error) {
        const errorMessage = error?.response?.message
          ? (
            i18n.t('com.muralis.qcx.erro.erroVincularProcesso', { erro: error?.response?.message })
          ) : error?.message;

        handleImpediment({
          severity: ERROR_SEVERITY,
          message: errorMessage,
        });
      } finally {
        dispatch(resetStatus());
      }
    }, 500);

    handleDebouncedVincular();
  }, [
    form,
    currentVinculo,
    currentVinculos,
    handleImpediment,
    currentUpdating,
  ]);

  const handleConsultVinculo = useCallback((data) => (event) => {
    event?.stopPropagation();

    const isNoSameThatCurrent = (
      data?.processo !== currentVinculo?.processo
      && data?.orgaoAnuente?.id !== currentVinculo?.orgaoAnuente?.id
    );

    if (isNoSameThatCurrent && data?.processo && data?.orgaoAnuente?.id) {
      dispatch(changeToSubConsultMode());

      form.change(DEFAULT_IGNORABLE_FIELDS_PROPS.rootName, undefined);

      form.change(vinculoIdFieldName, data?.id);

      form.change(processoFieldName, data?.processo);
      form.change(orgaoAnuenteFieldName, data?.orgaoAnuente?.id);
    }
  }, [
    form,
    currentVinculo,
    vinculoIdFieldName,
    processoFieldName,
    orgaoAnuenteFieldName,
  ]);

  const handleEditVinculo = useCallback((event) => {
    event?.stopPropagation();

    dispatch(changeToSubUpdateMode());
    dispatch(
      setCurrent({
        id: currentVinculo?.id,
      })
    );
  }, [
    currentVinculo,
  ]);

  const handleCancelUpdate = useCallback((event) => {
    event?.stopPropagation();

    dispatch(changeToSubConsultMode());
    dispatch(resetCurrent());
  }, []);

  const handleRemoveVinculo = useOperationController((vinculo) => {
    const updatedVinculos = currentVinculos
      ?.filter((existingVinculos) => (
        existingVinculos?.id !== vinculo?.id
      ));

    form.change(vinculoListName, updatedVinculos);

    dispatch(
      setSuccessFeedback({
        message: i18n.t('com.muralis.qcx.mensagem.processoVinculadoRemovido', { processo: vinculo?.processo }),
      })
    );
  }, [
    currentVinculos,
    currentVinculo,
    vinculoListName,
  ]);

  const handleRemoveSelectedVinculo = useCallback((event) => {
    event?.stopPropagation();

    if (!isEmpty(currentVinculo)) {
      handleRemoveVinculo(currentVinculo);
    }
  }, [
    currentVinculo,
    handleRemoveVinculo,
  ]);

  const handleRemoveVinculoByRowClick = useCallback((data) => (event) => {
    event?.stopPropagation();

    if (!isEmpty(data)) {
      handleRemoveVinculo(data);
    }
  }, [
    currentVinculo,
    handleRemoveVinculo,
  ]);

  const handleClearCurrentVinculo = useCallback((event) => {
    event?.stopPropagation();

    form.change(DEFAULT_IGNORABLE_FIELDS_PROPS.rootName, undefined);

    if (isConsult) {
      dispatch(resetSubMode());
      return;
    }

    dispatch(changeToSubCreateMode());
  }, [form]);

  useEffect(() => {
    const handleChangeMode = () => {
      if (isConsult) {
        dispatch(resetSubMode());
      }
    };

    handleChangeMode();
  }, [isConsult]);

  const dataGridColumns = useMemo(() => (
    [
      {
        field: 'processo',
        headerName: i18n.t('com.muralis.qcx.processoVinculado'),
        headerAlign: 'center',
        align: 'center',
        flex: 160,
        valueGetter: ({ row }) => (
          row?.processo || ''
        ),
      },
      {
        field: 'orgaoAnuente',
        headerName: i18n.t('com.muralis.qcx.orgao.orgaoAnuente'),
        headerAlign: 'left',
        align: 'left',
        flex: 240,
        valueGetter: ({ row }) => (
          row?.orgaoAnuente?.description || ''
        ),
      },
      {
        field: 'actions',
        headerName: i18n.t('com.muralis.qcx.acoes.label'),
        headerAlign: 'center',
        align: 'center',
        flex: 180,
        renderCell: ({ row }) => (
          <>
            <IconButton
              key={`btn-delete-${row?.id}`}
              name={`btn-delete-${row?.id}`}
              onClick={handleRemoveVinculoByRowClick(row)}
              disabled={isConsult}
            >
              <DeleteIcon
                color={(
                  isConsult
                    ? 'disabled'
                    : 'error'
                )}
              />
            </IconButton>
            <IconButton
              key={`btn-view-${row?.id}`}
              name={`btn-view-${row?.id}`}
              onClick={handleConsultVinculo(row)}
            >
              <PageviewIcon
                color="primary"
              />
            </IconButton>
          </>
        ),
      },
    ]
  ), [
    isConsult,
    handleRemoveVinculoByRowClick,
    handleConsultVinculo,
  ]);

  return (
    <>
      <QCXAloneInlineBoxWrapper
        renderSiblings={(boxProps) => (
          <Grid
            item
            container
            spacing={2}
            style={{
              paddingLeft: '8px',
            }}
            {...boxProps}
          >
            {isSubUpdate && (
              <Grid item xs={12} md={4}>
                <QCXButton
                  variant="outlined"
                  startIcon={<BlockIcon />}
                  disabled={!isSubUpdate || isConsult}
                  onClick={handleCancelUpdate}
                  fullWidth
                >
                  {i18n.t('com.muralis.qcx.acoes.cancelar')}
                </QCXButton>
              </Grid>
            )}
            {(!isConsult && isSubConsult) && (
              <Grid item xs={12} md={4}>
                <QCXButton
                  variant="outlined"
                  startIcon={<ClearIcon />}
                  onClick={handleClearCurrentVinculo}
                  fullWidth
                >
                  {i18n.t('com.muralis.qcx.acoes.limpar')}
                </QCXButton>
              </Grid>
            )}
            {isSubConsult && !isConsult && (
              <Grid item xs={12} md={4}>
                <QCXButton
                  variant="outlined"
                  startIcon={(
                    <DeleteIcon
                      color="inherit"
                    />
                  )}
                  style={{
                    fontWeight: 550,
                  }}
                  disabled={!isSubConsult}
                  onClick={handleRemoveSelectedVinculo}
                  fullWidth
                >
                  {i18n.t('com.muralis.qcx.acoes.remover')}
                </QCXButton>
              </Grid>
            )}
          </Grid>
        )}
      >
        {(boxProps) => (
          <Grid
            item
            container
            spacing={2}
            {...boxProps}
          >
            <Grid
              item
              xs={12}
              sm={4}
              md={4}
            >
              <QCXFinalTextField
                {...fieldProps?.processo}
                id="text-field-processo-vinculado"
                key="text-field-processo-vinculado"
                name={processoFieldName}
                label={DEFAULT_FIELD_PROPS.processo.label}
                maxLength={DEFAULT_FIELD_PROPS.processo.maxLength}
                disabled={isConsult || isSubConsult || isLoading}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={5}
              md={5}
            >
              <QCXSelectOrgaoAnuenteAutocomplete
                {...fieldProps?.orgaoAnuente}
                id="autocomplete-field-orgao-anuente"
                key="autocomplete-field-orgao-anuente"
                name={orgaoAnuenteFieldName}
                label={DEFAULT_FIELD_PROPS.orgaoAnuente.label}
                initialValues={values}
                disabled={isConsult || isSubConsult || isLoading}
              />
            </Grid>
            {(isConsult && isSubConsult) && (
              <Grid item xs={12} md={3}>
                <QCXButton
                  variant="outlined"
                  startIcon={<ClearIcon />}
                  onClick={handleClearCurrentVinculo}
                  fullWidth
                >
                  {i18n.t('com.muralis.qcx.acoes.limpar')}
                </QCXButton>
              </Grid>
            )}
            {!isSubConsult && (isSubNone || isSubCreate) && (
              <Grid
                item
                xs={12}
                sm={3}
                md={3}
              >
                <QCXButton
                  id="control-box-modelo-informacao-complementar-add-button"
                  key="control-box-modelo-informacao-complementar-add-button"
                  color="secondary"
                  onClick={handleVincularProcesso}
                  disabled={isConsult || isSubConsult || isLoading}
                  fullWidth
                >
                  {(
                    isLoading
                      ? (
                        <QCXCircularProgress
                          color="disabled"
                          size={20}
                        />
                      ) : i18n.t('com.muralis.qcx.acoes.adicionar')
                  )}
                </QCXButton>
              </Grid>
            )}
            {isSubConsult && !isConsult && (
              <Grid
                item
                xs={12}
                sm={3}
                md={3}
              >
                <QCXButton
                  color="secondary"
                  startIcon={<EditIcon />}
                  onClick={handleEditVinculo}
                  disabled={isConsult}
                  fullWidth
                >
                  {i18n.t('com.muralis.qcx.editar.label')}
                </QCXButton>
              </Grid>
            )}
            {isSubUpdate && (
              <Grid item xs={12} md={3}>
                <QCXButton
                  color="secondary"
                  onClick={handleVincularProcesso}
                  disabled={isConsult || isLoading}
                  fullWidth
                >
                  {(
                    isLoading
                      ? (
                        <QCXCircularProgress
                          color="disabled"
                          size={20}
                        />
                      ) : i18n.t('com.muralis.qcx.acoes.atualizar')
                  )}
                </QCXButton>
              </Grid>
            )}
          </Grid>
        )}
      </QCXAloneInlineBoxWrapper>
      <Grid item xs={12}>
        <QCXDataGrid
          columns={dataGridColumns}
          rows={currentVinculos}
        />
      </Grid>
    </>
  );
}
