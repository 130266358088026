import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import PaginationLayout, {
  PaginationBulletProps,
} from "../../../../common/layouts/paginationLayout/paginationLayout";
import { useCallback, useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { NewAddressFormProps } from "./components/newAddressForm";
import { useDispatch } from "react-redux";
import {
  fetchAllAsync,
  resetBackgroundMode,
  resetMode,
  resetModel,
  resetRelatedContatoModel,
  resetSubMode,
} from "../../../../../features/cliente/clienteSlice";
import { ClientData } from "../../../../common/types/clientData";
import { IDLE_CONTROL } from "../../../../../features/config-control";
import {
  changeControlTo as changeControlPaisTo,
  resetList as resetPaisList,
} from "../../../../../features/pais/paisSlice";
import { resetList as resetEstadoList } from "../../../../../features/estado/estadoSlice";
import { resetList as resetCidadeList } from "../../../../../features/cidade/cidadeSlice";
import { changeControlTo as changeControlNaladiShTo } from "../../../../../features/naladi-sh/naladiShSlice";
import { changeControlTo as changeControlNaladiNccaTo } from "../../../../../features/naladi-ncca/naladiNccaSlice";
import { changeControlTo as changeControlUnidadeMedidaTo } from "../../../../../features/unidade-medida/unidadeDeMedidaSlice";
import { changeControlTo as changeControlNcmTo } from "../../../../../features/ncm-subitem/ncmSubitemSlice";
import { fetchAllAsync as fetchPortoAeroporto } from "../../../../../features/porto-aeroporto/PortoAeroportoSlice";
import { fetchAllAsync as fetchTipoDocumento } from "../../../../../features/tipo-documento/tipoDocumentoSlice";
import { PortoAeroporto } from "../../../../common/types/portoAeroporto";
import { atom, PrimitiveAtom, useAtom } from "jotai";
import FirstPage from "./components/firstPage";
import SecondPage from "./components/secondPage";
import { useHistory } from "react-router";
import { changeBulletProps } from "../../../../common/layouts/paginationLayout/helpers/paginationLayoutHelpers";
import ThirdPage from "./components/thirdPage";
import { v4 as uuid } from "uuid";
import { PropostaFile } from "../common/propostaFile";
import { PropostaFollowUp } from "../../../../common/types/propostaFollowUp";
import { ServicoProposta } from "../common/propostaServicos.type";
import { CadastroProposta } from "../common/propostasCadastros.type";
import { ContactProfile } from "../../../../common/types/contactProfile";

export enum TIPO_PESSOA {
  FISICA = "Pessoa Física",
  JURIDICA = "Pessoa Jurídica",
}

export interface PropostaData {
  id: string;
  data: string;
  fronteiras: string;
  followUps?: PropostaFollowUp[];
  cliente?: ClientData;
  novoClienteNome?: string;
  novoClienteCPF?: string;
  novoClienteTipo?: TIPO_PESSOA;
  novoEndereco?: NewAddressFormProps;
  serviços: ServicoProposta[];
  cadastros: CadastroProposta[];
  locais?: PortoAeroporto[];
  arquivos?: PropostaFile[];
  contatosAdicionais?: ContactProfile[];
}

export const propostaDataAtom = atom<PropostaData>({
  id: uuid(),
  data: new Date().toLocaleString("pt-BR"),
  serviços: [],
  fronteiras: "",
  cadastros: [],
});

export const propostasActivePage = atom<number>(1);

export const propostasNewPageBulletProps = atom<PaginationBulletProps[]>([
  {
    label: "Dados técnicos",
    number: 1,
    active: true,
    isClickable: true,
    onClick: (number: number) => {
      console.log(number);
    },
  },
  {
    label: "Contato",
    number: 2,
    active: false,
    isClickable: false,
    onClick: (number: number) => {
      console.log(number);
    },
  },
  {
    label: "Anexos",
    number: 3,
    active: false,
    isClickable: false,
    onClick: (number: number) => {
      console.log(number);
    },
  },
]);

const PropostasNewPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [activePage, setActivePage] =
    useAtom<PrimitiveAtom<number>>(propostasActivePage);
  const [propostaData, setPropostaData] =
    useAtom<PrimitiveAtom<PropostaData>>(propostaDataAtom);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [bulletProps, setBulletProps] = useAtom<
    PrimitiveAtom<PaginationBulletProps[]>
  >(propostasNewPageBulletProps);

  /* A listener that is listening to the history. It is used to reset the state of the page when the
  user navigates away from the page. */
  const unlisten = history.listen((location, action) => {
    setPropostaData({
      id: uuid(),
      data: new Date().toLocaleString("pt-BR"),
      serviços: [],
      cadastros: [],
      fronteiras: "",
    });
    changeBulletProps(1, setBulletProps);
    setActivePage(1);
    console.log(location, action);
    unlisten();
  });

  const fetchAll = async () => {
    dispatch(fetchAllAsync());
    dispatch(fetchPortoAeroporto());
    dispatch(fetchTipoDocumento());
  };

  const resetModes = useCallback(() => {
    dispatch(resetMode());
    dispatch(resetBackgroundMode());
    dispatch(resetSubMode());
  }, []);

  const resetModels = useCallback(() => {
    dispatch(resetModel());
    dispatch(resetRelatedContatoModel());
  }, []);

  const resetDependencies = useCallback(() => {
    dispatch(changeControlPaisTo(IDLE_CONTROL));
    dispatch(changeControlNcmTo(IDLE_CONTROL));
    dispatch(changeControlNaladiShTo(IDLE_CONTROL));
    dispatch(changeControlNaladiNccaTo(IDLE_CONTROL));
    dispatch(changeControlUnidadeMedidaTo(IDLE_CONTROL));
    dispatch(resetPaisList());
    dispatch(resetEstadoList());
    dispatch(resetCidadeList());
  }, []);

  useEffect(() => {
    fetchAll().then(() => {
      setIsLoading(false);
    });
    resetModes();
    resetModels();
    resetDependencies();
  }, []);

  return (
    <PaginationLayout
      title={"Nova Proposta"}
      icon={<HistoryEduIcon color={"secondary"} />}
      paginationBulletsProps={bulletProps}
    >
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <FirstPage></FirstPage>
          <SecondPage></SecondPage>
          <ThirdPage></ThirdPage>
        </>
      )}
    </PaginationLayout>
  );
};

export default PropostasNewPage;
