import React from 'react';
import QCXCircularProgress from '../progress/QCXCircularProgress';
import QCXBackdrop from './QCXBackdrop';

export default function QCXProgressBackdrop({
  open,
  ...restProps
}) {
  return (
    <QCXBackdrop
      open={open}
      {...restProps}
    >
      <QCXCircularProgress
        color="inherit"
      />
    </QCXBackdrop>
  );
}
