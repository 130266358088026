import { createFilterOptions } from '@material-ui/lab/useAutocomplete';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchAllAsync,
  fetchByFilterAsync,
  selectViasTransporte,
} from '../../features/via-transporte/viaTransporteSlice';
import QCXAutocompleteSelect from '../autocomplete-select/QCXAutocompleteSelect';
import { VIA_TRANSPORTE_IMPORTACAO_FILTER } from './viaTransporteUtils';

export default function QCXSelectViaTransporteAutocomplete({
  label,
  name,
  initialValues,
  tipo = VIA_TRANSPORTE_IMPORTACAO_FILTER,
  disableFilter = false,
  ...restProps
}) {
  const dispatch = useDispatch();
  const list = useSelector(selectViasTransporte);

  useEffect(() => {
    const handleFetch = () => {
      if (disableFilter) {
        dispatch(fetchAllAsync());
      } else {
        const filterParams = [
          {
            name: 'TIPO',
            value: tipo,
          },
        ];

        dispatch(
          fetchByFilterAsync(filterParams)
        );
      }
    };

    handleFetch();
  }, []);

  const [defaultValue, setDefaultValue] = useState('');
  useEffect(() => {
    const value = list.find((item) => item?.id === _.get(initialValues, name));
    if (value) {
      setDefaultValue(value);
    } else {
      setDefaultValue({ id: null, description: '' });
    }
  }, [list, initialValues]);

  const filterOptions = createFilterOptions({
    stringify: (option) => option.description,
  });
  return (
    <QCXAutocompleteSelect
      label={label}
      id={name}
      name={name}
      options={list}
      filterOptions={filterOptions}
      defaultValue={defaultValue}
      getOptionLabel={(item) => (item?.description || '')}
      {...restProps}
    />
  );
}
