import { Typography } from "@material-ui/core";
import React from "react";
import QCXBox from "../../../../shared-components/box/QCXBox";

interface Props {
  title: string;
  boxProps?: any;
}

export default function StyledFormTitle({
  title,
  boxProps = {
    pt: 5,
  },
  ...restProps
}: Props) {
  return (
    <Typography variant="h6" color="primary" {...restProps}>
      <QCXBox fontWeight="fontWeightBold" {...boxProps}>
        {String(title).toUpperCase()}
      </QCXBox>
    </Typography>
  );
}
