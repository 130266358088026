import React from 'react';
import { AppBar } from '@material-ui/core';

export default function QCXAppBar({ children, ...restProps }) {
  return (
    <AppBar {...restProps}>
      {children}
    </AppBar>
  );
}
