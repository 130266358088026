import { IconButton } from '@material-ui/core';
import {
  Delete as DeleteIcon, Search as PageviewIcon,
} from '@material-ui/icons';
import React, {
  useCallback, useContext, useEffect, useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { userHasDeleteRole } from '../../../../../components/perfil/perfil-utils';
import TipoDocumentoContext from '../../../../../contexts/registrations/tipo-documento/TipoDocumentoContext';
import {
  activateByIdAsync,
  changeToConsultMode,
  changeToCreateMode,
  fetchAllAsync,
  inactivateByIdAsync,
  resetMode,
  resetModel,
  selectTiposDocumentos,
} from '../../../../../features/tipo-documento/tipoDocumentoSlice';
import QCXSimpleConsultPageTemplate from '../../../../../templates/simple-consult-page/QCXSimpleConsultPageTemplate';
import TipoDocumentoUtils from '../../../../../utils/general/tipo-documento/TipoDocumentoUtils';
import useOperationController from '../../../../../utils/hooks/store/operation/useOperationController';
import { isAlternativeLoadingStatus } from '../../../../../utils/store/store-utils';

export default function TipoDocumentoConsultPage({ authInfo }) {
  const { t } = useTranslation();
  const history = useHistory();

  const {
    status,
  } = useContext(TipoDocumentoContext);

  const dispatch = useDispatch();

  const tiposDocumentos = useSelector(selectTiposDocumentos);

  const isAlternativeLoading = useMemo(() => (
    isAlternativeLoadingStatus(status)
  ), [status]);

  const fetchAll = useCallback(() => {
    dispatch(fetchAllAsync());
  }, []);

  const resetModes = useCallback(() => {
    dispatch(resetMode());
  }, []);

  const resetModels = useCallback(() => {
    dispatch(resetModel());
  }, []);

  useEffect(() => {
    fetchAll();
    resetModes();
    resetModels();
  }, []);

  const handleActivateInactivate = useOperationController(
    async (event, id) => {
      event.preventDefault();

      const { active } = tiposDocumentos.find(
        (unidadeNegocio) => unidadeNegocio.id === id
      );

      const isActivation = !active;

      if (isActivation) {
        dispatch(activateByIdAsync(id));
      } else {
        dispatch(inactivateByIdAsync(id));
      }
    }
  );

  const handleClickOnButtonNew = useCallback((event) => {
    event.stopPropagation();
    dispatch(changeToCreateMode());
  }, []);

  const handleClickOnEditButton = useCallback((event, id) => {
    event.stopPropagation();
    dispatch(changeToConsultMode());
    history.push(t('com.muralis.qcx.url.moduloGeraisTipoDocumentosDetalhes', { id }));
  }, [history]);

  const columns = [
    {
      field: 'id',
      headerAlign: 'center',
      align: 'center',
      headerName: t('com.muralis.qcx.codigo'),
      type: 'string',
      flex: 90,
      valueGetter: ({ row }) => (
        row?.id
      ),
    },
    {
      field: 'description',
      headerName: t('com.muralis.qcx.documento.tipoDocumento'),
      type: 'string',
      flex: 240,
      valueGetter: ({ row }) => (
        row?.description
      ),
    },
    {
      field: 'publico',
      headerName: t('com.muralis.qcx.publico'),
      type: 'boolean',
      flex: 145,
      valueGetter: ({ row }) => (
        TipoDocumentoUtils.isPublico(row?.publico)
      ),
    },
    {
      field: 'actions',
      headerName: t('com.muralis.qcx.acoes.label'),
      headerAlign: 'center',
      align: 'center',
      flex: 120,
      renderCell: ({ row: { id, active } }) => (
        <>
          {userHasDeleteRole(authInfo, 'tipo-documento') && (
            <IconButton
              key={`menu-item-icon-button-delete-${id}`}
              value={active}
              name={`menu-item-icon-button-delete-${id}`}
              onClick={(event) => handleActivateInactivate(event, id)}
            >
              <DeleteIcon color="error" />
            </IconButton>
          )}
          <IconButton
            key={`menu-item-icon-button-view-${id}`}
            name={`menu-item-icon-button-view-${id}`}
            onClick={(event) => handleClickOnEditButton(event, id)}
          >
            <PageviewIcon color="primary" />
          </IconButton>
        </>
      ),
    },
  ];

  const breadcrumbs = [
    {
      link: {
        to: '/',
        name: t('com.muralis.qcx.inicio'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.cadastros'),
      },
    },
    {
      link: {
        to: t('com.muralis.qcx.url.moduloCadastrosGerais'),
        name: t('com.muralis.qcx.gerais'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.documento.tiposDocumentos'),
      },
    },
  ];

  const templateProps = {
    page: {
      title: t('com.muralis.qcx.documento.tiposDocumentos'),
      breadcrumbs,
    },
    control: {
      buttonNew: {
        description: t('com.muralis.qcx.documento.novoTipoDocumento'),
        path: t('com.muralis.qcx.url.moduloGeraisTipoDocumentosNovo'),
        onClick: handleClickOnButtonNew,
      },
    },
    table: {
      columns,
    },
  };

  return (
    <QCXSimpleConsultPageTemplate
      pageProps={templateProps.page}
      controlProps={templateProps.control}
      tableProps={templateProps.table}
      tableData={tiposDocumentos}
      isLoading={isAlternativeLoading}
      buttonNewText={t('com.muralis.qcx.acoes.novo')}
      authInfo={authInfo}
      requiredRoles={['tipo-documento']}
    />
  );
}
