import { useSelector } from 'react-redux';
import { useMemo } from 'react';

import { selectDeclaracaoImportacao } from '../../features/declaracao-importacao/declaracaoImportacaoSelectors';
import { selectDeclaracaoUnicaExportacao } from '../../features/declaracao-unica-exportacao/declaracaoUnicaExportacaoSelectors';
import { selectFatura } from '../../features/fatura/faturaSelectors';
import { selectLicencaImportacao } from '../../features/licenca-importacao/licencaImportacaoSelectors';
import {
  TIPO_FATURA,
  TIPO_LI,
  TIPO_DUE,
  TIPO_DUIMP,
  TIPO_DI_DUIMP,
} from '../../utils/general/fatura/faturaUtils';

const useGetFilterByProdutoType = (tipo) => {
  const fatura = useSelector(selectFatura);
  const licencaImportacao = useSelector(selectLicencaImportacao);
  const declaracaoImportacao = useSelector(selectDeclaracaoImportacao);
  const declaracaoUnicaExportacao = useSelector(selectDeclaracaoUnicaExportacao);

  const filters = useMemo(() => {
    switch (tipo) {
      case TIPO_FATURA:
        return [{
          name: 'cliente',
          value: fatura?.importador?.id,
        }];
      case TIPO_LI:
        return [{
          name: 'cliente',
          value: licencaImportacao?.importador?.id,
        }];
      case TIPO_DUE:
        return [
          {
            name: 'cliente',
            value: declaracaoUnicaExportacao?.importador?.id,
          },
          {
            name: 'tipo',
            value: TIPO_DUE,
          },
        ];
      case TIPO_DI_DUIMP:
        return [{
          name: 'cliente',
          value: declaracaoImportacao?.importador?.id,
        }];
      case TIPO_DUIMP:
        return [
          {
            name: 'cliente',
            value: declaracaoImportacao?.importador?.id,
          },
          {
            name: 'tipo',
            value: TIPO_DUIMP,
          }
        ];
      default:
        throw new Error('Tipo informado não é valido!');
    }
  }, [tipo]);

  return filters;
};

export default useGetFilterByProdutoType;
