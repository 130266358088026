import { createAsyncThunk } from '@reduxjs/toolkit';
import { notificacaoAPI } from './notificacaoAPI';

const fetchByUnidadeDeNegocioAsync = createAsyncThunk(
  'notificacao/fetchByUnidadeDeNegocio',
  async (id) => {
    const { data } = await notificacaoAPI.fetchByUnidadeDeNegocio(id);
    return { data };
  }
);

const notificacaoThunks = {
  fetchByUnidadeDeNegocioAsync,
};

export {
  notificacaoThunks,
  fetchByUnidadeDeNegocioAsync,
};
