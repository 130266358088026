import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import QCXSelectManyServicosFinalFormTable from '../servico/QCXSelectManyServicosFinalFormTable';
import QCXSelectManyDespachantesFinalFormTable from '../despachante/QCXSelectManyDespachantesFinalFormTable';
import {
  addContato, changeToSubConsultMode,
  changeToSubCreateMode,
  changeToSubUpdateMode,
  removeContatoById,
  resetRelatedContatoModel,
  resetSubMode,
  selectRelatedContatoModel,
  selectSubMode,
  setUnidadesNegocio,
  setRelatedContatoModel,
  updateContato,
  setDespachantes,
  setServicos,
} from '../../features/cliente/clienteSlice';
import QCXFinalFormWizard from '../../shared-components/final-form-wizard/QCXFinalFormWizard';
import {
  isNoneSubMode, isSubConsultMode, isSubCreateMode, isSubUpdateMode,
} from '../../utils/store/store-utils';
import QCXSelectManyUnidadeNegocioFinalFormTable from '../unidade-negocio/QCXSelectManyUnidadeNegocioFinalFormTable';
import QCXClientePersonalDataForm from './QCXClientePersonalDataForm';
import QCXClienteTecnicalDataForm from './QCXClienteTecnicalDataForm';
import QCXContatoFinalFormManager from './QCXContatoFinalFormManager';
import {
  formatTelefoneCelular,
  formatTelefoneFixo,
} from '../../utils/hooks/form/field/formatters';

export default function QCXClienteWizardFinalForm({
  handleSubmit,
  handleAlternativeSave,
  model,
  handleChangeModel,
  handleChangeToCreate,
  handleChangeToConsult,
  handleChangeToUpdate,
  handleCancelUpdate,
  handleChangeToBackgroundCreate,
  handleResetBackgroundMode,
  isConsult,
  isUpdate,
  isBackgroundCreate,
  authInfo = {},
  requiredRoles = [],
  ...restProps
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const subMode = useSelector(selectSubMode);

  const contatoCliente = useSelector(selectRelatedContatoModel);

  const isSubNone = useMemo(() => (
    isNoneSubMode(subMode)
  ), [subMode]);

  const isSubCreate = useMemo(() => (
    isSubCreateMode(subMode)
  ), [subMode]);

  const isSubConsult = useMemo(() => (
    isSubConsultMode(subMode)
  ), [subMode]);

  const isSubUpdate = useMemo(() => (
    isSubUpdateMode(subMode)
  ), [subMode]);

  const handleNewContato = () => {
    dispatch(changeToSubCreateMode());
  };
  const handleCancelContatoCreate = () => {
    dispatch(resetSubMode());
  };

  const handleCancelContatoUpdate = () => {
    dispatch(changeToSubConsultMode());
  };

  const handleClearContatoForm = () => {
    dispatch(resetRelatedContatoModel());
    dispatch(resetSubMode());
  };

  const handleRemoveContato = () => {
    dispatch(removeContatoById({ data: contatoCliente }));
    handleClearContatoForm();
  };

  const handleContatoSubmit = (data) => {
    if (isSubCreate) {
      dispatch(addContato({ data }));
      handleClearContatoForm();
    } else if (isSubUpdate) {
      dispatch(updateContato({ data }));
      dispatch(changeToSubConsultMode());
    }
  };

  const handleContatoRowClick = ({ row }) => {
    if (!isSubUpdate && !isSubCreate && (contatoCliente?.id !== row?.id)) {
      dispatch(setRelatedContatoModel({
        ...row,
        contato: {
          ...row?.contato,
          telefone: formatTelefoneFixo(row?.contato?.telefone),
          celular: formatTelefoneCelular(row?.contato?.celular),
        },
      }));
    }
  };

  const handleEditContato = () => {
    dispatch(changeToSubUpdateMode());
  };

  const checkIfIsAlternativeSaveByStep = (currentStep) => (
    [2, 3, 4, 5].includes(currentStep)
  );
  const handleSetSelectedUnidadesNegocio = (data) => {
    dispatch(setUnidadesNegocio(data));
  };
  const handleSetSelectedDespachantes = (data) => {
    dispatch(setDespachantes(data));
  };
  const handleSetSelectedServicos = (data) => {
    dispatch(setServicos(data));
  };

  const contatoList = useMemo(() => (
    model?.contatos
  ), [model]);

  const wizardSteps = [
    t('com.muralis.qcx.dadosPessoais'),
    t('com.muralis.qcx.dadosTecnicos'),
    t('com.muralis.qcx.contato.labelPlural'),
    t('com.muralis.qcx.unidadeNegocio.labelPlural'),
    t('com.muralis.qcx.despachante.labelPlural'),
    t('com.muralis.qcx.servico.labelPlural'),
  ];

  return (
    <QCXFinalFormWizard
      steps={wizardSteps}
      initialValues={model}
      handleChangeValues={handleChangeModel}
      isConsult={isConsult}
      isUpdate={isUpdate}
      isBackgroundCreate={isBackgroundCreate}
      isSubNone={isSubNone}
      isSubCreate={isSubCreate}
      isSubConsult={isSubConsult}
      isSubUpdate={isSubUpdate}
      handleSubmit={handleSubmit}
      checkIfIsAlternativeSaveByStep={checkIfIsAlternativeSaveByStep}
      handleAlternativeSave={handleAlternativeSave}
      handleChangeToCreate={handleChangeToCreate}
      handleChangeToConsult={handleChangeToConsult}
      handleChangeToUpdate={handleChangeToUpdate}
      handleCancelUpdate={handleCancelUpdate}
      handleChangeToBackgroundCreate={handleChangeToBackgroundCreate}
      handleResetBackgroundMode={handleResetBackgroundMode}
      {...restProps}
    >
      {(formProps) => (
        <QCXClientePersonalDataForm
          key="dados-pessoais-cliente"
          isConsult={isConsult}
          isUpdate={isUpdate}
          authInfo={authInfo}
          requiredRoles={requiredRoles}
          {...formProps}
        />
      )}
      {(formProps) => (
        <QCXClienteTecnicalDataForm
          key="dados-tecnicos-cliente"
          isConsult={isConsult}
          authInfo={authInfo}
          requiredRoles={requiredRoles}
          {...formProps}
        />
      )}
      {({ controlComponentProps }) => (
        <QCXContatoFinalFormManager
          key="contatos-cliente"
          isConsult={isConsult}
          isUpdate={isUpdate}
          isSubNone={isSubNone}
          isSubCreate={isSubCreate}
          isSubConsult={isSubConsult}
          isSubUpdate={isSubUpdate}
          initialValues={contatoCliente}
          list={contatoList}
          handleSubmit={handleContatoSubmit}
          handleNew={handleNewContato}
          handleCancelCreate={handleCancelContatoCreate}
          handleCancelUpdate={handleCancelContatoUpdate}
          handleRemove={handleRemoveContato}
          handleRowClick={handleContatoRowClick}
          handleEdit={handleEditContato}
          handleClearForm={handleClearContatoForm}
          controlComponentProps={controlComponentProps}
          authInfo={authInfo}
          requiredRoles={requiredRoles}
        />
      )}
      {({ controlComponentProps, ...restFormProps }) => (
        <QCXSelectManyUnidadeNegocioFinalFormTable
          key="ficha-unidade-negocio-cliente"
          list={model?.unidadesDeNegocio || []}
          isConsult={isConsult}
          handleChange={handleSetSelectedUnidadesNegocio}
          controlComponentProps={controlComponentProps}
          authInfo={authInfo}
          requiredRoles={requiredRoles}
          {...restFormProps}
        />
      )}
      {({ controlComponentProps, ...restFormProps }) => (
        <QCXSelectManyDespachantesFinalFormTable
          key="ficha-despachante-cliente"
          list={model?.despachantes || []}
          isConsult={isConsult}
          handleChange={handleSetSelectedDespachantes}
          controlComponentProps={controlComponentProps}
          authInfo={authInfo}
          requiredRoles={requiredRoles}
          {...restFormProps}
        />
      )}
      {({ controlComponentProps, ...restFormProps }) => (
        <QCXSelectManyServicosFinalFormTable
          key="ficha-servicos-cliente"
          list={model?.servicos || []}
          isConsult={isConsult}
          handleChange={handleSetSelectedServicos}
          controlComponentProps={controlComponentProps}
          authInfo={authInfo}
          requiredRoles={requiredRoles}
          {...restFormProps}
        />
      )}
    </QCXFinalFormWizard>
  );
}
