import { Link } from '@material-ui/core';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import { Button, Grid } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { CatalogoProdutos, Cliente, Mercadoria } from 'quickcomex-api-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { read, utils } from 'xlsx';
import QCXMoveFocusInside from '../../../../components/focus-lock/QCXMoveFocusInside';
import {
  RELACAO_FABRICANTE_DESCONHECIDO,
  RELACAO_FABRICANTE_EXPORTADOR,
  RELACAO_FABRICANTE_NAO_EXPORTADOR,
} from '../../../../components/relacao-fornecedor/relacao-fornecedor-utils';
import {
  register as registerCatalogoProdutos,
  save as saveCatalogoProdutos,
} from '../../../../features/catalogo-produtos/catalogoProdutosAPI';
import {
  fetchByFilterAsync as fetchCatalogosProdutosByFilterAsync,
  selectCatalogosProdutos,
} from '../../../../features/catalogo-produtos/catalogoProdutosSlice';
import {
  fetchAllAsync as fetchAllCFOPsAsync,
  selectCFOPOperacoesFiscais,
} from '../../../../features/cfop-operacoes-fiscais/cfopOperacoesFiscaisSlice';
import {
  fetchAllAsync as fetchAllEmpresasEstrangeirasAsync,
  selectEmpresasEstrangeiras,
} from '../../../../features/empresa-estrangeira/empresaEstrangeiraSlice';
import { faturaAPI } from '../../../../features/fatura/faturaAPI';
import { setErrorFeedback, setSuccessFeedback } from '../../../../features/feedback/feedbackSlice';
import {
  fetchAllAsync as fetchAllIncotermsAsync,
  selectIncotermCondicoesVenda,
} from '../../../../features/incoterm-condicao-venda/incotermCondicaoVendaSlice';
import { fetchAllAsync as fetchAllMoedasAsync, selectMoedas } from '../../../../features/moeda/moedaSlice';
import {
  fetchAllAsync as fetchAllNaladiNccasAsync,
  selectNaladiNccas,
} from '../../../../features/naladi-ncca/naladiNccaSlice';
import { fetchAllAsync as fetchAllNaladiShsAsync, selectNaladiShs } from '../../../../features/naladi-sh/naladiShSlice';
import { fetchByFilter as fetchNcmByFilter } from '../../../../features/ncm-subitem/ncmSubitemAPI';
import { Nve, fetchAllAsync as fetchAllNvesAsync, selectNves } from '../../../../features/nve/nveSlice';
import { fetchAllAsync as fetchAllPaisesAsync, selectPaises } from '../../../../features/pais/paisSlice';
import {
  fetchAllAsync as fetchAllUnidadesLocaisRFBAsync,
  selectUnidadesLocaisRfb,
} from '../../../../features/unidade-local-rfb/unidadeLocalRfbSlice';
import {
  fetchAllAsync as fetchAllUnidadesDeMedidaAsync,
  selectUnidadesdeMedida,
} from '../../../../features/unidade-medida/unidadeDeMedidaSlice';
import QCXInfoAlert from '../../../../shared-components/alert/QCXInfoAlert';
import QCXFinalCheckboxField from '../../../../shared-components/final-checkbox-field/QCXFinalCheckboxField';
import QCXSelectClienteAutocomplete from '../../../../shared-components/final-select-cliente-field/QCXSelectClienteAutocomplete';
import QCXSelectServicoAutocomplete from '../../../../shared-components/select-servico/QCXSelectServicoAutocomplete';
import { MediaType } from '../../../../utils/api/api-utils';
import { convertExcelTimestampToDateStr } from '../../../../utils/general/dateTime-utils';
import { required } from '../../../../utils/validators/field/validator';
import DataTable from '../../../common/components/dataTable';
import FileDragAndDrop from '../../../common/components/fileDragAndDrop';
import FormHeaderMedium from '../../../common/components/forms/formHeaderMedium';
import PageLayout from '../../../common/layouts/pageLayout';
import ConfirmationModal from './components/confirmationModal';
import ProgressBarModal, { ProgressBarStep } from './components/progressModal';
import planilhasPageHelpers from './planilhasPage.helpers';
import { FaturaData, MercadoriaData } from './planilhasPage.types';

interface BasicFormData {
  arquivo: File;
  cliente: {
    id: number;
  };
  processo: string;
  servico: {
    id: number;
  };
  catalogoProdutos: boolean;
}

const PlanilhasPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [spreadsheetData, setSpreadsheetData] = useState<any[][]>();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentFollowUp, setCurrentFollowUp] = useState<string | null | undefined>(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState<ProgressBarStep>({
    step: 0,
    description: 'Criando Follow Ups',
  });
  const moedas = useSelector(selectMoedas);
  const paises = useSelector(selectPaises);
  const unidadesLocaisRFB = useSelector(selectUnidadesLocaisRfb);
  const incotermsCondicaoVenda = useSelector(selectIncotermCondicoesVenda);
  const unidadesDeMedida = useSelector(selectUnidadesdeMedida);
  const naladisNcca = useSelector(selectNaladiNccas);
  const naladisSh = useSelector(selectNaladiShs);
  const cfops = useSelector(selectCFOPOperacoesFiscais);
  const nves = useSelector(selectNves);
  const empresasEstrangeiras = useSelector(selectEmpresasEstrangeiras);
  const catalogoProdutos = useSelector(selectCatalogosProdutos);

  const URL_MODELO = useMemo(() => process.env.REACT_APP_MODELOS_RELATORIOS_INTEGRACAO_FATURAS_URL, []);

  useEffect(() => {
    if (moedas.length <= 1) {
      dispatch(fetchAllMoedasAsync());
    }
    if (paises.length <= 1) {
      dispatch(fetchAllPaisesAsync());
    }
    if (unidadesLocaisRFB.length <= 1) {
      dispatch(fetchAllUnidadesLocaisRFBAsync());
    }
    if (unidadesDeMedida.length <= 1) {
      dispatch(fetchAllUnidadesDeMedidaAsync());
    }
    if (naladisNcca.length <= 1) {
      dispatch(fetchAllNaladiNccasAsync());
    }
    if (naladisSh.length <= 1) {
      dispatch(fetchAllNaladiShsAsync());
    }
    if (nves.length <= 1) {
      dispatch(fetchAllNvesAsync());
    }
    dispatch(fetchAllCFOPsAsync());
    dispatch(fetchAllIncotermsAsync());
    dispatch(fetchAllEmpresasEstrangeirasAsync());
  }, []);

  const handleFileSubmit = (values: BasicFormData) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const wb = read(event.target?.result);
      const sheets = wb.SheetNames;
      if (sheets.length) {
        const faturasSheet = wb.Sheets[sheets[0]];
        const faturasRange = utils.decode_range(faturasSheet['!ref'] as string);
        faturasRange.s.r = 0; // <-- set to 1 if willing to skip row 0
        faturasSheet['!ref'] = utils.encode_range(faturasRange);
        let faturasRows: any[][] = utils.sheet_to_json(faturasSheet, {
          header: 1,
        });
        const faturas = faturasRows.filter((row) => row.length > 0);
        setSpreadsheetData(faturas);
      }
    };
    reader.readAsArrayBuffer(values.arquivo);
  };

  const faturaHeaders = useMemo(
    () => [
      'FATURA/PO',
      'DATA DA FATURA',
      'PAÍS DE PROCEDÊNCIA',
      'URF DE DESPACHO',
      'URF DE ENTRADA',
      'INCOTERM',
      'MOEDA DO MLE',
      'VALOR DO MLE NA MOEDA',
      'MOEDA DO DESCONTO',
      'VALOR DO DESCONTO NA MOEDA',
      'MOEDA DAS DESPESAS',
      'VALOR DAS DESPESAS NA MOEDA',
      'MOEDA DO FRETE',
      'VALOR DO FRETE PREPAID NA MOEDA',
      'VALOR DO FRETE COLLECT NA MOEDA',
      'VALOR DO FRETE NO NACIONAL NA MOEDA',
      'MOEDA DO SEGURO',
      'VALOR DO SEGURO NA MOEDA',
    ],
    []
  );

  const mercadoriaHeaders = useMemo(
    () => [
      'FATURA/PO',
      'PARTNUMBER',
      'DESCRIÇÃO RESUMIDA',
      'UNIDADE DE MEDIDA',
      'NCM SH',
      'DESTAQUE DO NCM',
      'NALADI SH',
      'NALADI NCCA',
      'ALÍQUOTA ICMS',
      'QUANTIDADE',
      'QUANTIDADE ESTATÍSTICA',
      'PESO LÍQUIDO',
      'PESO LÍQUIDO UNITÁRIO',
      'PESO BRUTO UNITÁRIO',
      'CÓDIGO DO EXPORTADOR',
      'CÓDIGO DO FABRICANTE',
      'VALOR UNITÁRIO NA MOEDA',
      'VALOR TOTAL NA MOEDA',
      'PAÍS DE ORIGEM',
      'NVES',
      'DESCRIÇÃO NFE',
      'QUEBRA AUXILIAR',
      'ESPECIFICAÇÃO DA MERCADORIA',
      'NÚMERO DO ATO CONCESSÓRIO',
      'ITEM DO ATO CONCESSÓRIO',
      'MARCA',
      'NÚMERO DE SÉRIE',
      'MODELO',
      'ANO DE FABRICAÇÃO',
      'LOTE',
      'DATA DE VALIDADE',
      'NECESSITA DE LI',
      'NÚMERO DA FATURA',
      'ITEM DA FATURA',
      'CFOP',
    ],
    []
  );

  const [faturaColumns, itemsColumns] = useMemo(() => {
    const faturaColumns: GridColDef[] = faturaHeaders.map((header, index) => {
      const field = header
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace(/[^\w\s]/gi, '')
        .split(' ')
        .map((word, i) => (i === 0 ? word.toLowerCase() : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()))
        .join('');

      return {
        field: field,
        headerName: header,
        width: 200,
        headerClassName: 'header-theme',
      };
    });

    const itemsColumns: GridColDef[] = mercadoriaHeaders.map((header, index) => {
      const field = header
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace(/[^\w\s]/gi, '')
        .split(' ')
        .map((word, i) => (i === 0 ? word.toLowerCase() : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()))
        .join('');

      return {
        field: field,
        headerName: header,
        width: 200,
        headerClassName: 'header-theme',
      };
    });

    return [faturaColumns, itemsColumns];
  }, [spreadsheetData]);

  const [faturasData, itensData] = useMemo(() => {
    const faturasData: FaturaData[] = [];
    const itensData: MercadoriaData[] = [];
    const alreadyAdded: string[] = [];
    if (faturaColumns?.length > 0 && itemsColumns?.length > 0) {
      const spreadsheetROI = spreadsheetData?.slice(3);
      spreadsheetROI?.forEach((row, outterIndex) => {
        const faturaValues: string[] = row.slice(0, 18);
        const numeroFatura = faturaValues[0];
        const itemValues: string[] = row.slice(17);

        const item = itemValues.reduce((obj: { [key: string]: string | number }, item, index) => {
          const fieldName = itemsColumns[index]?.field;
          obj[fieldName] = item;
          obj.id = outterIndex;
          obj.faturapo = numeroFatura;
          return obj;
        }, {});
        itensData.push(item as unknown as MercadoriaData);

        const fatura = faturaValues.reduce((obj: { [key: string]: string | number }, item, index) => {
          const fieldName = faturaColumns[index]?.field;
          obj[fieldName] = item;
          obj.id = outterIndex;
          return obj;
        }, {});

        if (alreadyAdded.includes(numeroFatura)) {
          return;
        } else {
          alreadyAdded.push(numeroFatura);
          faturasData.push(fatura as unknown as FaturaData);
        }
      });
    }
    return [faturasData, itensData];
  }, [spreadsheetData, faturaColumns, itemsColumns]);

  const [ncms, setNcms] = useState<any[]>([]);

  useEffect(() => {
    const fetchNcms = async () => {
      const ncms: any[] = [];
      for (const mercadoria of itensData) {
        const strippedNcm = mercadoria.ncmSh?.toString().replaceAll('.', '');
        const foundNcm = ncms.find((ncm) => ncm.code === strippedNcm);
        if (foundNcm) {
          continue;
        }
        const ncmResponse = await fetchNcmByFilter([{ name: 'code', value: strippedNcm }]);
        if (ncmResponse.status === 200) {
          const ncm = ncmResponse.data[0];
          ncms.push(ncm);
        }
      }
      setNcms(ncms);
    };
    setIsLoading(true);
    fetchNcms().finally(() => setIsLoading(false));
  }, [itensData]);

  const resetProgressBar = useCallback(() => {
    setCurrentStep({ step: 0, description: 'Criando Follow Ups' });
  }, []);

  const [decisionPromiseResolver, setDecisionPromiseResolver] = useState<() => void>(() => {});

  const handleConfirm = () => {
    console.log(`${currentFollowUp} will be added.`);
    decisionPromiseResolver();
  };

  const handleCancel = () => {
    console.log(`${currentFollowUp} will NOT be added.`);
    decisionPromiseResolver();
  };

  const calculaRelacao = useCallback(
    (
      exportador: { id: number | undefined } | null | number,
      fabricante: { id: number | undefined } | null | number,
      paisOrigem?: { id?: number | null } | null
    ) => {
      const exportadorId = typeof exportador === 'object' ? exportador?.id : exportador;
      const fabricanteId = typeof fabricante === 'object' ? fabricante?.id : fabricante;
      const paisOrigemId = paisOrigem && typeof paisOrigem === 'object' ? paisOrigem.id : undefined;

      if (exportadorId && !fabricanteId && !paisOrigemId) {
        return RELACAO_FABRICANTE_EXPORTADOR;
      } else if (!fabricanteId && paisOrigemId) {
        return RELACAO_FABRICANTE_DESCONHECIDO;
      } else if (fabricanteId !== exportadorId) {
        return RELACAO_FABRICANTE_NAO_EXPORTADOR;
      }
      return RELACAO_FABRICANTE_EXPORTADOR;
    },
    []
  );

  const buscarNve = useCallback(
    (ncm: any, nve: string, nves: Nve[]) => {
      const split = nve.split('-');
      const nivelCode = split[0];
      const atributoCode = split[1];
      const especificacaoCode = split[2];
      const foundNve = nves.find((nve) => nve.code === ncm?.code);
      if (!foundNve) {
        return;
      }
      const foundAtributo = foundNve.atributos.find((atributo) => atributo.code === atributoCode);
      if (!foundAtributo) {
        return;
      }
      const foundEspecificacao = foundAtributo.especificacoes.find(
        (especificacao) => especificacao.code === especificacaoCode
      );

      const payload = {
        id: null,
        nve: foundNve,
        atributo: foundAtributo,
        especificacao: foundEspecificacao,
      };

      return payload;
    },
    [nves]
  );

  const normalize = useCallback(
    (fatura: FaturaData, values: BasicFormData) => {
      const moedaDespesa =
        fatura && fatura?.moedaDasDespesas
          ? moedas.find((moeda) => moeda.code === fatura?.moedaDasDespesas?.toString())
          : undefined;

      const moedaFrete =
        fatura && fatura?.moedaDoFrete
          ? moedas.find((moeda) => moeda.code === fatura?.moedaDoFrete?.toString())
          : undefined;

      const moedaDesconto =
        fatura && fatura?.moedaDoDesconto
          ? moedas.find((moeda) => moeda.code === fatura?.moedaDoDesconto?.toString())
          : undefined;

      const moedaMle =
        fatura && fatura?.moedaDoMle
          ? moedas.find((moeda) => moeda.code === fatura?.moedaDoMle?.toString())
          : undefined;

      const moedaSeguro =
        fatura && fatura?.moedaDoSeguro
          ? moedas.find((moeda) => moeda.code === fatura?.moedaDoSeguro?.toString())
          : undefined;

      const paisProcedencia =
        fatura && fatura?.paisDeProcedencia
          ? paises.find((pais) => pais.code === fatura?.paisDeProcedencia?.toString())
          : undefined;

      const urfDespacho =
        fatura && fatura?.urfDeDespacho
          ? unidadesLocaisRFB.find((urf) => urf.code === fatura?.urfDeDespacho?.toString())
          : undefined;

      const urfEntrada =
        fatura && fatura?.urfDeEntrada
          ? unidadesLocaisRFB.find((urf) => urf.code === fatura?.urfDeEntrada?.toString())
          : undefined;

      const incoterm =
        fatura && fatura?.incoterm
          ? incotermsCondicaoVenda.find((incoterm) => incoterm.code === fatura?.incoterm?.toString())
          : undefined;

      const unnormalizedMercadorias =
        fatura && fatura?.faturapo ? itensData.filter((item) => item.faturapo === fatura?.faturapo) : [];

      let normalizedMercadorias = unnormalizedMercadorias.map((mercadoria) => {
        if (Object.keys(mercadoria).length === 2) {
          return;
        }

        const ncm = ncms.find((ncm) => ncm.code === mercadoria.ncmSh?.toString());
        const unidadeDeMedida = unidadesDeMedida.find(
          (unidade) =>
            unidade.code === mercadoria.unidadeDeMedida?.toString() ||
            unidade.sigla === mercadoria.unidadeDeMedida?.toString() ||
            unidade.description === mercadoria.unidadeDeMedida?.toString()
        );
        const naladiNcca = naladisNcca.find((naladi) => naladi.code == mercadoria.naladiNcca?.toString());
        const naladiSh = naladisSh.find((naladi) => naladi.code == mercadoria.naladiSh?.toString());
        const operacaoFiscal = cfops.find((cfop) => cfop.code.replaceAll('.', '') === mercadoria.cfop?.toString());
        const paisOrigem = paises.find((pais) => pais.code == mercadoria.paisDeOrigem?.toString());

        const exportador = empresasEstrangeiras.find((empresa) => empresa.id == mercadoria.codigoDoExportador);
        const fabricante = empresasEstrangeiras.find((empresa) => empresa.id == mercadoria.codigoDoFabricante);

        const relacao = calculaRelacao(mercadoria.codigoDoExportador, mercadoria.codigoDoFabricante, paisOrigem);

        const unnormalizedNves = mercadoria.nves?.split(';') || [];
        const atributos = unnormalizedNves.map((nve) => buscarNve(ncm, nve, nves));

        const valorTotalMoeda = mercadoria.valorUnitarioNaMoeda * mercadoria.quantidade;

        const normalizedMercadoria = {
          partNumberSelector: mercadoria.partnumber,
          quantidade: mercadoria.quantidade,
          tipoCalculo: 'QUANTIDADE_X_VALOR',
          valorTotalMoeda: isNaN(valorTotalMoeda) ? 0 : valorTotalMoeda,
          ncm,
          unidadeDeMedida,
          valorUnitarioMoeda: mercadoria.valorUnitarioNaMoeda,
          adicionais: {
            especificacao: mercadoria.especificacaoDaMercadoria,
            numeroAtoConcessorio: mercadoria.numeroDoAtoConcessorio,
            itemAtoConcessorio: mercadoria.itemDoAtoConcessorio,
            marca: mercadoria.marca,
            numeroSerie: mercadoria.numeroDeSerie,
            modelo: mercadoria.modelo,
            anoFabricacao: mercadoria.anoDeFabricacao,
            dataValidade: mercadoria.dataDeValidade,
            lote: mercadoria.lote,
          },
          atributos,
          icms: mercadoria.aliquotaIcms,
          destaqueNcm: mercadoria.destaqueDoNcm,
          quantidadeEstatistica: mercadoria.quantidadeEstatistica,
          pesoLiquido: mercadoria.pesoLiquido,
          pesoLiquidoUnitario: mercadoria.pesoLiquidoUnitario
            ? mercadoria.pesoLiquidoUnitario
            : mercadoria.pesoLiquido / mercadoria.quantidade,
          naladiNcca,
          naladiSh,
          descricaoResumida: mercadoria.descricaoNfe,
          partnumber: mercadoria.partnumber,
          relacao,
          ...(exportador != null && { exportador: { id: exportador.id } }),
          ...(fabricante != null && { fabricante: { id: fabricante.id } }),
          quebraAuxiliar: mercadoria.quebraAuxiliar,
          operacaoFiscal,
          ...(paisOrigem != null && { paisOrigem }),
        };

        return normalizedMercadoria;
      });

      normalizedMercadorias = normalizedMercadorias.filter((mercadoria) => mercadoria !== undefined);

      const exportadores = normalizedMercadorias.map((mercadoria) => mercadoria?.exportador?.id);
      const exportadoresAreTheSame = exportadores.every((exportador) => exportador === exportadores[0]);
      const exportador =
        exportadoresAreTheSame && exportadores.length > 0 && exportadores[0] ? { id: exportadores[0] } : null;

      const fabricantes = normalizedMercadorias.map((mercadoria) => mercadoria?.fabricante?.id);
      const fabricantesAreTheSame = fabricantes.every((fabricante) => fabricante === fabricantes[0]);
      const fabricante =
        fabricantesAreTheSame && fabricantes.length > 0 && fabricantes[0] ? { id: fabricantes[0] } : null;

      const paisesDeOrigem = normalizedMercadorias.map((mercadoria) => mercadoria?.paisOrigem?.id);
      const paisesDeOrigemAreTheSame = paisesDeOrigem.every((pais) => pais === paisesDeOrigem[0]);
      const paisOrigem = paisesDeOrigemAreTheSame && paisesDeOrigem.length > 0 ? { id: paisesDeOrigem[0] } : null;

      const relacao = calculaRelacao(exportador, fabricante, paisOrigem);

      const pesoLiquido = normalizedMercadorias.reduce((acc, mercadoria) => {
        return acc + (mercadoria?.pesoLiquido || 0);
      }, 0);

      const valorDoMleNaMoeda = normalizedMercadorias.reduce((acc, mercadoria) => {
        return acc + (mercadoria?.valorTotalMoeda || 0);
      }, 0);

      let payload = {
        active: true,
        rateiaPesoLiquidoItem: false,
        dadosComumFornecedor: true,
        data: convertExcelTimestampToDateStr(fatura?.dataDaFatura),
        numero: fatura?.faturapo,
        ...(incoterm?.id != null && { incotermCondicaoVenda: { id: incoterm?.id } }),
        despesa: {
          valorMoeda: fatura?.valorDasDespesasNaMoeda,
          ...(moedaDespesa?.id != null && { moeda: { id: moedaDespesa.id } }),
        },
        desconto: {
          valorMoeda: fatura?.valorDoDescontoNaMoeda,
          ...(moedaDesconto?.id != null && { moeda: { id: moedaDesconto.id } }),
        },
        exportador,
        fabricante,
        followUp: {
          id: null as number | null,
        },
        frete: {
          valorMoedaCollect: fatura?.valorDoFreteCollectNaMoeda,
          valorMoedaPrepaid: fatura?.valorDoFretePrepaidNaMoeda,
          valorFreteNacionalMoeda: fatura?.valorDoFreteNoNacionalNaMoeda,
          ...(moedaFrete?.id != null && { moeda: { id: moedaFrete.id } }),
        },
        importador: {
          id: values.cliente.id,
        },
        mercadorias: normalizedMercadorias,
        mle: {
          valorMoeda: valorDoMleNaMoeda,
          ...(moedaMle?.id != null && { moeda: { id: moedaMle.id } }),
        },
        relacao,
        seguro: {
          valorMoeda: fatura?.valorDoSeguroNaMoeda,
          ...(moedaSeguro?.id != null && { moeda: { id: moedaSeguro.id } }),
        },
        ...(paisProcedencia?.id != null && {
          paisProcedencia: {
            id: paisProcedencia?.id,
          },
        }),
        ...(paisOrigem?.id != null && {
          paisOrigem: {
            id: paisOrigem?.id,
          },
        }),
        pesoLiquido,
        ...(urfEntrada != null && {
          urfEntrada: {
            id: urfEntrada?.id,
          },
        }),
        ...(urfDespacho != null && {
          urfDespacho: {
            id: urfDespacho?.id,
          },
        }),
      };

      return payload;
    },
    [
      moedas,
      paises,
      unidadesLocaisRFB,
      incotermsCondicaoVenda,
      itensData,
      ncms,
      unidadesDeMedida,
      naladisNcca,
      naladisSh,
      cfops,
    ]
  );

  const filterCatalogoProdutos = useCallback(
    (mercadorias: Mercadoria[], importador: Pick<Cliente, 'id'>) => {
      const toBeUpdated: CatalogoProdutos[] = [];
      const toBeCreated: CatalogoProdutos[] = [];
      const alreadyExists: CatalogoProdutos[] = [];

      for (const mercadoria of mercadorias) {
        const foundCatalogoProduto = catalogoProdutos.find(
          (catalogoProduto) => catalogoProduto.partnumber === mercadoria.partnumber
        );
        if (foundCatalogoProduto && foundCatalogoProduto.clientes.some((cliente) => cliente.id === importador.id)) {
          toBeUpdated.push({
            ...foundCatalogoProduto,
            ...mercadoria,
            descricaoResumida: mercadoria.adicionais.especificacao?.substring(0, 255),
            //@ts-ignore
            clientes: [...foundCatalogoProduto.clientes, importador],
          });
          alreadyExists.push(foundCatalogoProduto);
        } else {
          const catalogoProdutoItem: CatalogoProdutos = {
            partnumber: mercadoria.partnumber,
            apelido: mercadoria.adicionais.especificacao?.substring(0, 255),
            descricaoResumida: mercadoria.adicionais.especificacao?.substring(0, 255),
            unidadeDeMedida: mercadoria.unidadeDeMedida,
            ncm: mercadoria.ncm,
            valorUnitarioMoeda: mercadoria.valorUnitarioMoeda,
            valorUnitarioReal: mercadoria.valorUnitarioReal,
            naladiSh: mercadoria.naladiSh,
            tipoCalculo: 'QUANTIDADE_X_VALOR',
            naladiNcca: mercadoria.naladiNcca,
            icms: mercadoria.icms,
            destaqueNcm: mercadoria.destaqueNcm,
            pesoLiquido: mercadoria.pesoLiquidoUnitario,
            lote: mercadoria.adicionais.lote,
            dataValidade: mercadoria.adicionais.dataValidade,
            marca: mercadoria.adicionais.marca,
            modelo: mercadoria.adicionais.modelo,
            numeroSerie: mercadoria.adicionais.numeroSerie,
            anoFabricacao: mercadoria.adicionais.anoFabricacao,
            exTarifario: false,
            especificacao: mercadoria.adicionais.especificacao,
            especificacaoCompleta: mercadoria.adicionais.especificacaoCompleta,
            relacao: calculaRelacao(mercadoria.exportador, mercadoria.fabricante, mercadoria.paisOrigem),
            exportador: mercadoria.exportador,
            fabricante: mercadoria.fabricante,
            paisOrigem: mercadoria.paisOrigem,
            tipo: 'FATURA',
            //@ts-ignore
            detalhamentoProduto: null,
            statusIntegracao: 'PENDENTE',
            clientes: [
              //@ts-ignore
              importador,
            ],
            especificacoes: [],
            operacaoFiscal: mercadoria.operacaoFiscal,
            likeFilter: false,
            //@ts-ignore
            id: null,
            //@ts-ignore
            insertionDate: null,
            //@ts-ignore
            code: null,
            description: '',
            active: true,
          };
          toBeCreated.push(catalogoProdutoItem);
        }
      }

      return [toBeUpdated, toBeCreated, alreadyExists];
    },
    [catalogoProdutos]
  );

  const handleFaturasSubmit = async (values: BasicFormData) => {
    setIsModalOpen(true);
    const followUpResponse = await planilhasPageHelpers.createFollowUps(faturasData, values.cliente, values.servico);

    setCurrentStep({ step: 1, description: 'Criando Faturas' });
    for (const followUp of followUpResponse) {
      if (followUp.hasError) {
        setIsModalOpen(false);

        dispatch(setErrorFeedback({ message: 'Erro ao criar Follow Up: ' }));
        return;
      }

      if (followUp.alreadyExists) {
        setCurrentFollowUp(followUp.numero);
        setConfirmModalOpen(true);
        await new Promise((resolve) => setDecisionPromiseResolver(() => resolve));
        setConfirmModalOpen(false);
      } else {
        const unnormalizedFatura = faturasData.find((fatura) => fatura?.faturapo === followUp.numero);
        let normalizedFatura = normalize(unnormalizedFatura as FaturaData, values);
        normalizedFatura = { ...normalizedFatura, followUp: { id: followUp.id } };
        const [toBeUpdated, toBeCreated, alreadyExists] = filterCatalogoProdutos(
          normalizedFatura.mercadorias as unknown as Mercadoria[],
          values.cliente
        );
        if (values.catalogoProdutos) {
          for (const catalogoProduto of toBeCreated) {
            if (!catalogoProduto.partnumber) {
              setIsModalOpen(false);
              dispatch(setErrorFeedback({ message: 'Partnumber não informado na mercadoria' }));
              return;
            }
            const catalogoProdutosResponse = await registerCatalogoProdutos(catalogoProduto);
            if (catalogoProdutosResponse.status !== 201) {
              console.log(catalogoProdutosResponse);
              setIsModalOpen(false);

              dispatch(setErrorFeedback({ message: 'Erro ao criar catálogo de produtos' }));
              return;
            }
          }
          for (const catalogoProduto of toBeUpdated) {
            if (!catalogoProduto.partnumber) {
              setIsModalOpen(false);

              dispatch(setErrorFeedback({ message: 'Partnumber não informado na mercadoria' }));
              return;
            }
            const catalogoProdutosResponse = await saveCatalogoProdutos(catalogoProduto);
            if (catalogoProdutosResponse.status !== 200) {
              console.log(catalogoProdutosResponse);
              setIsModalOpen(false);

              dispatch(setErrorFeedback({ message: 'Erro ao salvar catálogo de produtos' }));
              return;
            }
          }
        } else {
          for (const mercadoria of normalizedFatura.mercadorias as unknown as Mercadoria[]) {
            const catalogoProduto = alreadyExists.find(
              (catalogoProduto) => catalogoProduto.partnumber === mercadoria.partnumber
            );
            if (catalogoProduto) {
              mercadoria.unidadeDeMedida = catalogoProduto.unidadeDeMedida || mercadoria.unidadeDeMedida;
              mercadoria.ncm = catalogoProduto.ncm || mercadoria.ncm;
              mercadoria.valorUnitarioMoeda = catalogoProduto.valorUnitarioMoeda || mercadoria.valorUnitarioMoeda;
              mercadoria.valorUnitarioReal = catalogoProduto.valorUnitarioReal || mercadoria.valorUnitarioReal;
              mercadoria.naladiSh = catalogoProduto.naladiSh || mercadoria.naladiSh;
              mercadoria.tipoCalculo = catalogoProduto.tipoCalculo || mercadoria.tipoCalculo;
              mercadoria.naladiNcca = catalogoProduto.naladiNcca || mercadoria.naladiNcca;
              mercadoria.icms = catalogoProduto.icms || mercadoria.icms;
              mercadoria.destaqueNcm = catalogoProduto.destaqueNcm || mercadoria.destaqueNcm;
              mercadoria.pesoLiquido = catalogoProduto.pesoLiquido || mercadoria.pesoLiquido;
              mercadoria.adicionais.lote = catalogoProduto.lote || mercadoria.adicionais.lote;
              mercadoria.adicionais.dataValidade = catalogoProduto.dataValidade || mercadoria.adicionais.dataValidade;
              mercadoria.adicionais.marca = catalogoProduto.marca || mercadoria.adicionais.marca;
              mercadoria.adicionais.modelo = catalogoProduto.modelo || mercadoria.adicionais.modelo;
              mercadoria.adicionais.numeroSerie = catalogoProduto.numeroSerie || mercadoria.adicionais.numeroSerie;
              mercadoria.adicionais.anoFabricacao =
                catalogoProduto.anoFabricacao || mercadoria.adicionais.anoFabricacao;
              mercadoria.adicionais.especificacao =
                catalogoProduto.especificacao || mercadoria.adicionais.especificacao;
              mercadoria.adicionais.especificacaoCompleta =
                catalogoProduto.especificacaoCompleta || mercadoria.adicionais.especificacaoCompleta;
              mercadoria.relacao =
                mercadoria.exportador || mercadoria.fabricante || mercadoria.paisOrigem
                  ? mercadoria.relacao
                  : catalogoProduto.relacao;
              if (!mercadoria.exportador && !mercadoria.fabricante && !mercadoria.paisOrigem) {
                mercadoria.exportador = catalogoProduto.exportador;
                mercadoria.fabricante = catalogoProduto.fabricante;
                mercadoria.paisOrigem = catalogoProduto.paisOrigem;
              }
              mercadoria.operacaoFiscal = catalogoProduto.operacaoFiscal || mercadoria.operacaoFiscal;
            }
          }
        }
        const faturaResponse = await faturaAPI.register(normalizedFatura, [{ name: 'STEP', value: 4 }]);
        if (faturaResponse.status !== 201) {
          console.log(faturaResponse);
          setIsModalOpen(false);

          dispatch(setErrorFeedback({ message: 'Erro ao criar fatura' }));
          return;
        }
      }
    }
    dispatch(setSuccessFeedback({ message: 'Faturas criadas com sucesso' }));
    setIsModalOpen(false);
    resetProgressBar();
  };

  return (
    <PageLayout title={'Planilhas'} icon={<HistoryEduIcon color={'secondary'} />}>
      <>
        <ConfirmationModal
          open={confirmModalOpen}
          message={`O Follow Up ${currentFollowUp} já existe com uma fatura associada. Nenhuma alteração será feita nesse processo.`}
          singleAction
          onConfirm={handleConfirm}
          onCancel={handleCancel}
          title={'Follow Up já existe!'}
        />
        <ProgressBarModal
          title="Importando planilha"
          open={isModalOpen}
          totalSteps={2}
          currentStep={currentStep}
          onClose={() => setIsModalOpen(false)}
        ></ProgressBarModal>
        <Form onSubmit={(values: BasicFormData) => handleFileSubmit(values)}>
          {({ handleSubmit, form, submitting, pristine, values }) => {
            useEffect(() => {
              const params = [];
              const clienteFilter = { name: 'cliente', value: values.cliente?.id };
              params.push(clienteFilter);
              dispatch(fetchCatalogosProdutosByFilterAsync(params));
            }, [values.cliente]);

            return (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Link href={URL_MODELO} target="_blank">
                      <QCXInfoAlert>Clique aqui para fazer o download da planilha modelo</QCXInfoAlert>
                    </Link>
                  </Grid>
                  <FormHeaderMedium>Informações básicas</FormHeaderMedium>
                  <Grid item xs={6}>
                    <QCXMoveFocusInside>
                      <QCXSelectClienteAutocomplete
                        id="autocomplete-select-cliente-field"
                        key="autocomplete-select-cliente-field"
                        name="cliente.id"
                        label={t('com.muralis.qcx.cliente.label')}
                        initialValues={undefined}
                        filter={undefined}
                        required
                      />
                    </QCXMoveFocusInside>
                  </Grid>
                  <Grid item xs={6}>
                    <QCXSelectServicoAutocomplete
                      id="autocomplete-select-servico-field"
                      key="autocomplete-select-servico-field"
                      name="servico.id"
                      label={t('com.muralis.qcx.servico.labelSingular')}
                      initialValues={undefined}
                      fieldProps={{ validate: required }}
                      produto="FATURA"
                      required
                    />
                  </Grid>
                  <FormHeaderMedium>Registros</FormHeaderMedium>
                  <Grid item xs={12} sm={3} md={2}>
                    <QCXFinalCheckboxField
                      name="fatura"
                      label={t('com.muralis.qcx.fatura.labelSingular')}
                      disabled
                      checked
                      value={true}
                      required={undefined}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3} md={2}>
                    <QCXFinalCheckboxField
                      name="catalogoProdutos"
                      label={t('com.muralis.qcx.catalogoProdutos.label')}
                      required={undefined}
                    />
                  </Grid>
                  <FormHeaderMedium>Seleção de arquivo</FormHeaderMedium>
                  <Field name="arquivo" validate={required}>
                    {({ input }) => <FileDragAndDrop input={input} accept={MediaType.EXCEL}></FileDragAndDrop>}
                  </Field>
                  <Grid item xs={12} textAlign="right">
                    <Button type="submit" color="secondary" variant="contained">
                      Importar planilha
                    </Button>
                  </Grid>
                  {spreadsheetData && (
                    <>
                      <FormHeaderMedium>Revisão dos dados</FormHeaderMedium>
                      <FormHeaderMedium>Faturas</FormHeaderMedium>
                      <Grid item xs={12}>
                        <DataTable columns={faturaColumns} rows={faturasData}></DataTable>
                      </Grid>
                      <FormHeaderMedium>Itens</FormHeaderMedium>

                      <Grid item xs={12}>
                        <DataTable columns={itemsColumns} rows={itensData}></DataTable>
                      </Grid>

                      <Grid item xs={12} textAlign="right">
                        <Button color="secondary" variant="contained" style={{ margin: '0 10px 0 0' }}>
                          Voltar
                        </Button>
                        <Button color="secondary" variant="contained" onClick={() => handleFaturasSubmit(values)}>
                          Salvar
                        </Button>
                      </Grid>
                    </>
                  )}
                </Grid>
              </form>
            );
          }}
        </Form>
      </>
    </PageLayout>
  );
};

export default PlanilhasPage;
