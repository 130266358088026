import { useKeycloak } from "@react-keycloak/web";
import { QueriesTypes, usePostRequest, usePutRequest } from "../queries";
import { useUpdateKeycloakProfilePermissions } from "../useUpdateKeycloakProfilePermissions";
import { UseMutateFunction } from "react-query";

export const useCreateCliente = (
  propostaId: string | number,
  closeFormsCallback: () => void
): [
  UseMutateFunction<
    string,
    unknown,
    {
      requestPayload: {
        [key: string]: any;
      };
      queryParams?:
        | {
            clientId?: string | number;
            step?: number;
          }
        | undefined;
    },
    unknown
  >,
  boolean,
  boolean
] => {
  const { keycloak } = useKeycloak();
  const [profileMutation] = useUpdateKeycloakProfilePermissions();
  const {
    mutate: updateProposta,
    isLoading: isLoadingProposta,
    isError: hasErrorProposta,
  } = usePutRequest(QueriesTypes.Propostas);

  const handleSuccess = (response: any) => {
    // Atualiza o role do keycloak para incluir o novo cliente.
    const tokenParsed = keycloak.tokenParsed as any;
    const roleName = tokenParsed.assignedRole;
    const clienteId = JSON.parse(response).id;
    const keycloakPayload = {
      nomePerfil: roleName,
      clienteId,
    };
    profileMutation(keycloakPayload);
    updateProposta({ propostaId, clienteId });
    closeFormsCallback();
  };

  const { mutate, isLoading, isError } = usePostRequest(QueriesTypes.Clientes, {
    onSuccessCallback: handleSuccess,
  });

  return [mutate, isLoading, isError];
};
