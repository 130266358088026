import React, { useCallback, useMemo } from 'react';
import {
  useForm,
} from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectRelatedSelectionItemList,
} from '../../features/fatura/faturaSelectors';
import {
  setRelatedSelectionItemList,
} from '../../features/fatura/faturaSlice';
import QCXDataGridControl from '../../shared-components/data-grid-control/QCXDataGridControl';
import { unnormalizeNumeral } from '../../utils/general/general-utils';
import { formatBrazilianNumericDecimal } from '../../utils/hooks/form/field/formatters';

export default function QCXFaturaEstornoDataGridManager({
  isBackgroundReversal,
  rows = [],
  ...restProps
}) {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const form = useForm();

  const relatedSelectionItemList = useSelector(selectRelatedSelectionItemList);

  const handleSelectionModelChange = useCallback(({ selectionModel }) => {
    if (isBackgroundReversal) {
      dispatch(setRelatedSelectionItemList(selectionModel));

      const mappedItems = rows
        .filter((item) => (
          selectionModel?.includes(item?.id)
        ))
        .map((item) => ({
          id: item?.id,
        }));

      form.change('itens', mappedItems);
    }
  }, [
    form,
    isBackgroundReversal,
  ]);

  const columns = useMemo(() => ([
    {
      field: 'processo',
      headerName: t('com.muralis.qcx.numeroProcesso'),
      headerAlign: 'left',
      align: 'left',
      flex: 120,
      valueGetter: ({ row }) => (
        row?.fatura?.atributosAdicionais?.processo
      ),
    },
    {
      field: 'item',
      headerName: t('com.muralis.qcx.item.numeroItem'),
      headerAlign: 'center',
      align: 'center',
      flex: 100,
      valueGetter: ({ row }) => (
        row?.proprietario?.item
      ),
    },
    {
      field: 'exportador',
      headerName: t('com.muralis.qcx.exportador.label'),
      headerAlign: 'left',
      align: 'left',
      flex: 180,
      valueGetter: ({ row }) => (
        row?.proprietario?.exportador?.pessoaJuridica?.nome
      ),
    },
    {
      field: 'partnumber',
      headerName: t('com.muralis.qcx.item.partNumber'),
      flex: 130,
      valueGetter: ({ row }) => (
        row?.proprietario?.partnumber || t('com.muralis.qcx.validacao.naoPreenchido').toUpperCase()
      ),
    },
    {
      field: 'quantidade',
      headerName: t('com.muralis.qcx.quantidade.label'),
      headerAlign: 'center',
      align: 'center',
      flex: 120,
      valueGetter: ({ row }) => (
        unnormalizeNumeral(
          row?.valor,
          formatBrazilianNumericDecimal(5)
        )
      ),
    },
    {
      field: 'valorUnitarioMoeda',
      headerName: t('com.muralis.qcx.valorUnitario'),
      headerAlign: 'right',
      align: 'right',
      flex: 120,
      valueGetter: ({ row }) => (
        unnormalizeNumeral(
          row?.proprietario?.valorUnitarioMoeda,
          formatBrazilianNumericDecimal(2)
        ) || '-'
      ),
    },
  ]), []);

  return (
    <QCXDataGridControl
      checkboxSelection
      columns={columns}
      rows={rows}
      selectionModel={relatedSelectionItemList}
      onSelectionModelChange={handleSelectionModelChange}
      {...restProps}
    />
  );
}
