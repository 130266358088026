const selectFollowUps = (state) => state.followUpOrigemFaturamento.list;
const selectFollowUp = (state) => state.followUpOrigemFaturamento.model;

const selectStatus = (state) => state.followUpOrigemFaturamento.status;
const selectRefresh = (state) => state.followUpOrigemFaturamento.refresh;
const selectMode = (state) => state.followUpOrigemFaturamento.mode.main;
const selectBackgroundMode = (state) => state.followUpOrigemFaturamento.mode.background;
const selectSubMode = (state) => state.followUpOrigemFaturamento.mode.sub;
const selectConfigControl = (state) => state.followUpOrigemFaturamento.config.control;
const selectError = (state) => state.followUpOrigemFaturamento.error;
const selectResponse = (state) => state.followUpOrigemFaturamento.response;

const followUpOrigemFaturamentoSelectors = {
  selectFollowUps,
  selectFollowUp,
  selectStatus,
  selectRefresh,
  selectMode,
  selectBackgroundMode,
  selectSubMode,
  selectConfigControl,
  selectError,
  selectResponse,
};

export default followUpOrigemFaturamentoSelectors;
