import { Grid, Typography } from '@material-ui/core';
import React from 'react';
// import { GiLockedDoor } from 'react-icons/gi';
import { useTranslation } from 'react-i18next';
import { GoAlert } from 'react-icons/go';
import QCXFormTitle from '../../../components/form-title/QCXFormTitle';
import QCXBox from '../../../shared-components/box/QCXBox';
import QCXButton from '../../../shared-components/button/QCXButton';

export default function ErroSelecaoUnidadeDeNegocio({ handleClickReturn }) {
  const { t } = useTranslation();

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Grid item>
        <GoAlert
          color="secondary"
          style={{
            width: '100%', height: '10vw', minHeight: '150px', color: '#F39200',
          }}
        />
      </Grid>
      <Grid item>
        <QCXFormTitle
          title={t('com.muralis.qcx.unidadeNegocio.unidadeNegocioNaoSelecionada')}
        />
      </Grid>
      <Grid item>
        <Typography variant="h6" color="textSecondary" style={{ textAlign: 'justify', textAlignLast: 'center' }}>
          <QCXBox fontWeight="fontWeightBold" pt={5} pb={5}>
            {t('com.muralis.qcx.mensagem.selecionarUnidadeNegocioAcessoModulo')}
          </QCXBox>
        </Typography>
      </Grid>
      <Grid item>
        <QCXButton
          onClick={handleClickReturn}
          color="primary"
        >
          {t('com.muralis.qcx.acoes.voltar')}
        </QCXButton>
      </Grid>

    </Grid>
  );
}
