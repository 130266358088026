import React from 'react';
import TabContext from '@material-ui/lab/TabContext';

export default function QCXTabContext({
  id,
  key,
  value,
  children,
  ...restProps
}) {
  return (
    <TabContext
      id={id}
      key={key}
      value={value}
      {...restProps}
    >
      {children}
    </TabContext>
  );
}
