import { useTheme } from '@material-ui/core';
import { Avatar, Box, Button, Stack, Typography } from '@mui/material';
import { PaginationBulletProps } from '../paginationLayout';

const PaginationBulletsButton = (bulletProps: PaginationBulletProps) => {
  const theme = useTheme();
  return (
    <Button
      disabled={!bulletProps.isClickable}
      startIcon={
        <Avatar
          sx={{
            width: bulletProps.active ? 20 : 18,
            height: bulletProps.active ? 20 : 18,
            bgcolor: bulletProps.active ? theme.palette.primary.main : 'gray',
            outline: bulletProps.active
              ? `2px solid ${theme.palette.secondary.main}`
              : 'none',
          }}
        >
          <Typography>{bulletProps.number}</Typography>
        </Avatar>
      }
    >
      <Typography
        sx={{
          color: bulletProps.active ? 'black' : 'gray',
        }}
      >
        {bulletProps.label}
      </Typography>
    </Button>
  );
};

const PaginationBullets = ({
  paginationBulletsProps,
}: {
  paginationBulletsProps: PaginationBulletProps[];
}) => {
  return (
    <Stack direction="row" spacing={2}>
      {paginationBulletsProps.map((bulletProps, index) => {
        const isLast = index === paginationBulletsProps.length - 1;
        if (!isLast) {
          return (
            <Stack direction={'row'} spacing={1} alignItems={'center'}>
              <PaginationBulletsButton {...bulletProps} />
              <Box
                width={'3rem'}
                height={'2px'}
                sx={{ backgroundColor: 'gray' }}
              ></Box>
            </Stack>
          );
        } else {
          return PaginationBulletsButton(bulletProps);
        }
      })}
    </Stack>
  );
};

export default PaginationBullets;
