import React, {
  useCallback,
  useMemo,
} from 'react';
import _, { isFunction } from 'lodash';
import {
  useTheme,
  useMediaQuery,
  Grid,
} from '@material-ui/core';
import {
  ArrowForwardIos as ArrowForwardIcon,
  ArrowBackIos as ArrowBackIcon,
} from '@material-ui/icons';
import { Skeleton } from '@mui/material';
import QCXIconButton from '../button/QCXIconButton';
import QCXPaginator, { withPaginator } from './QCXPaginator';
import QCXTimelinePaginatorContainer from './QCXTimelinePaginatorContainer';
import usePaginatorController from '../../utils/hooks/paginator/usePaginatorController';
import usePaginatorState from '../../utils/hooks/paginator/usePaginatorState';
import QCXTimelinePaginatorFragment from './QCXTimelinePaginatorFragment';

function QCXTimelinePaginator({
  list = [],
  orderer = {
    sort: undefined,
  },
  controlButtonsProps = {
    next: {},
    back: {},
  },
  loading = false,
  children,
}) {
  const theme = useTheme();

  const isXsDownScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const isSmDownScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMdDownScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isMlUpScreen = useMediaQuery('(min-width:1512px)');
  const isCustomMdAndLgScreen = useMediaQuery('(min-width:1088px) and (max-width:1279px)');
  const isLgDownScreen = useMediaQuery(theme.breakpoints.down('lg'));

  const paginatorState = usePaginatorState();
  const paginatorController = usePaginatorController();

  const computedList = useMemo(() => {
    if (loading) {
      return new Array(5);
    }

    if (isFunction(orderer?.sort)) {
      return orderer.sort(list);
    }

    return list;
  }, [
    list,
    orderer,
    loading,
  ]);

  const totalListSize = useMemo(() => (
    computedList?.length || 0
  ), [computedList]);

  const readjustPageByGroupSize = useCallback((number) => {
    if (totalListSize > 0) {
      const isAvailableDefaultPage = (
        !paginatorState.usedDefaultPage && paginatorState.defaultPage
      );

      if (isAvailableDefaultPage) {
        paginatorController.markDefaultPageAsUsed();

        return;
      }

      paginatorController.setPage((previousPage) => {
        const calculatedPageNumber = Math.ceil(totalListSize / number) - 1;

        return (
          Math.min(
            previousPage,
            Math.max(
              calculatedPageNumber,
              0
            )
          )
        );
      });
    }
  }, [
    totalListSize,
    paginatorState,
    paginatorController,
  ]);

  const sizeOflistGroup = useMemo(() => {
    if (isXsDownScreen) {
      readjustPageByGroupSize(1);

      return 1;
    }

    if (isSmDownScreen) {
      readjustPageByGroupSize(2);

      return 2;
    }

    if (isMdDownScreen) {
      readjustPageByGroupSize(3);

      return 3;
    }

    if ((isLgDownScreen && !isMlUpScreen) || isCustomMdAndLgScreen) {
      readjustPageByGroupSize(4);

      return 4;
    }

    readjustPageByGroupSize(5);

    return 5;
  }, [
    isLgDownScreen,
    isMlUpScreen,
    isMdDownScreen,
    isSmDownScreen,
    isXsDownScreen,
    isCustomMdAndLgScreen,
    readjustPageByGroupSize,
  ]);

  const computedListGroups = useMemo(() => (
    _.chunk(
      computedList,
      sizeOflistGroup
    ) || []
  ), [
    sizeOflistGroup,
    computedList,
  ]);

  const currentListGroup = useMemo(() => {
    const isValidList = (
      _.isArrayLikeObject(computedListGroups)
    );

    if (isValidList) {
      const hasListGroup = (
        computedListGroups[paginatorState.page]?.length > 0
      );

      const listGroup = hasListGroup
        ? computedListGroups[paginatorState.page]
        : [];

      if (isFunction(orderer?.sort)) {
        return orderer.sort(listGroup);
      }

      return listGroup;
    }

    return [];
  }, [
    orderer,
    paginatorState,
    computedListGroups,
  ]);

  const disableNextButton = useMemo(() => (
    paginatorState.page === computedListGroups.length - 1
  ), [
    paginatorState,
    computedListGroups,
  ]);

  const disableBackButton = useMemo(() => (
    paginatorState.page === 0
  ), [paginatorState]);

  return (
    <Grid
      item
      container
      justify="space-evenly"
      xs={12}
    >
      <QCXPaginator.Controller>
        <Grid
          item
          container
          direction="row"
          alignContent="center"
          justify="center"
          xs={1}
        >
          <Grid
            item
            container
            alignContent="center"
            justify="center"
            xs={12}
          >
            {loading
              ? (
                <Skeleton
                  variant="circular"
                  animation="wave"
                  width={59}
                  height={59}
                />
              ) : (
                <QCXIconButton
                  color="inherit"
                  tooltipProps={{
                    title: 'Voltar',
                  }}
                  {...controlButtonsProps?.back}
                  onClick={paginatorController.previous}
                  disableTooltip={disableBackButton}
                  disabled={disableBackButton}
                >
                  {({ disabled }) => (
                    <ArrowBackIcon
                      color={disabled ? 'disabled' : 'secondary'}
                      fontSize="large"
                    />
                  )}
                </QCXIconButton>
              )}
          </Grid>
        </Grid>
      </QCXPaginator.Controller>
      <QCXTimelinePaginatorContainer
        list={currentListGroup}
        loading={loading}
      >
        {(data, paginator) => (
          <QCXTimelinePaginatorFragment
            meta={data}
          >
            {children(data, paginator)}
          </QCXTimelinePaginatorFragment>
        )}
      </QCXTimelinePaginatorContainer>
      <QCXPaginator.Controller>
        <Grid
          item
          container
          alignContent="center"
          justify="center"
          direction="row"
          xs={1}
        >
          <Grid
            item
            container
            alignContent="center"
            justify="center"
            xs={12}
          >
            {loading
              ? (
                <Skeleton
                  variant="circular"
                  animation="wave"
                  width={59}
                  height={59}
                />
              ) : (
                <QCXIconButton
                  onClick={paginatorController.next}
                  tooltipProps={{
                    title: 'Avançar',
                  }}
                  {...controlButtonsProps?.next}
                  disableTooltip={disableNextButton}
                  disabled={disableNextButton}
                >
                  {({ disabled }) => (
                    <ArrowForwardIcon
                      color={disabled ? 'disabled' : 'secondary'}
                      fontSize="large"
                    />
                  )}
                </QCXIconButton>
              )}
          </Grid>
        </Grid>
      </QCXPaginator.Controller>
    </Grid>
  );
}

export default withPaginator(QCXTimelinePaginator);
