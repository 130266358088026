const VALOR_FIXO_CODE = 'CTC3';
const PORCENTAGEM_SOBRE_CIF_CODE = 'CTC1';
const PORCENTAGEM_SOBRE_VMLE_CODE = 'CTC2';
const PORCENTAGEM_SOBRE_FRETE_CODE = 'CTC5';

export {
  VALOR_FIXO_CODE,
  PORCENTAGEM_SOBRE_CIF_CODE,
  PORCENTAGEM_SOBRE_VMLE_CODE,
  PORCENTAGEM_SOBRE_FRETE_CODE,
};
