import React from 'react';
import { TabList } from '@material-ui/lab';

export default function QCXTabList({
  id,
  key,
  indicatorColor = 'primary',
  textColor = 'primary',
  onChange,
  children,
  scrollButtons = 'auto',
  variant = 'standard',
  ...restProps
}) {
  return (
    <TabList
      id={id}
      key={key}
      scrollButtons={scrollButtons}
      variant={variant}
      indicatorColor={indicatorColor}
      textColor={textColor}
      onChange={onChange}
      {...restProps}
    >
      {children}
    </TabList>
  );
}
