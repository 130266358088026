import { DynamicFeed } from "@mui/icons-material";
import PageLayout from "../../../common/layouts/pageLayout";
import { GridColDef, GridRowData } from "@material-ui/data-grid";
import StyledDataGridControl, {
  ControlButtonProps,
} from "./dataGrid/styledDataGridControl";
import { AddCircle } from "@material-ui/icons";
import { useEffect, useMemo, useState } from "react";
import ActionsButtonCell from "./ActionsButtonCell";
import { useHistory } from "react-router";
import { useListFollowUpsAssessoria } from "./hooks/useListFollowupsAssessoria";
import LoadingIndicator from "../../../common/components/loadingIndicator";
import { useRouteQuery } from "../../../common/hooks/useRouteQuery";

const AssessoriaFollowupPage = () => {
  const history = useHistory();

  const query = useRouteQuery();
  const [initialQuery, setInitialQuery] = useState("");

  useEffect(() => {
    const code = query.get("code");

    if (code != null) {
      setInitialQuery(code);
    } else {
      setInitialQuery("");
    }

    return () => {};
  }, [query]);

  const [followUps, isLoadingFU] = useListFollowUpsAssessoria();

  const isLoading = useMemo<boolean>(() => isLoadingFU, [isLoadingFU]);

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "processo",
        headerName: "Processo",
        flex: 1,
        headerClassName: "header-theme",
        valueGetter: ({ row }) => {
          return row?.processo ?? "-";
        },
      },
      {
        field: "cliente",
        headerName: "Cliente",
        flex: 1,
        headerClassName: "header-theme",
        valueGetter: ({ row }) => row?.cliente ?? "-",
      },
      {
        field: "cnpj",
        headerName: "CNPJ",
        flex: 1,
        headerClassName: "header-theme",
        valueGetter: ({ row }) => row?.cnpj ?? "-",
      },
      {
        field: "servico",
        headerName: "Serviço",
        flex: 1,
        headerClassName: "header-theme",
        valueGetter: ({ row }) => row?.servico ?? "-",
      },
      {
        field: "abertura",
        headerName: "Abertura",
        flex: 1,
        headerClassName: "header-theme",
        valueGetter: ({ row }) => row?.abertura ?? "-",
      },
      {
        field: "actions",
        headerName: "Ações",
        headerAlign: "center",
        align: "center",
        renderCell: ({ row: { id, atributosAdicionais, active } }) => (
          <ActionsButtonCell id={id}></ActionsButtonCell>
        ),
      },
    ],
    []
  );

  const controlButtons: ControlButtonProps[] = [
    {
      onClick: () => {
        history.push("/assessoria/followup/novo");
      },
      description: "NOVO",
      tooltip: true,
      tooltipDescription: "Novo Registro de Follow-up",
      startIcon: <AddCircle></AddCircle>,
    },
  ];

  return (
    <PageLayout title={"FollowUp"} icon={<DynamicFeed color={"secondary"} />}>
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <StyledDataGridControl
          controlButtons={controlButtons}
          columns={columns}
          rows={followUps.map((fu) => ({
            id: fu.id,
            processo: fu.numero,
            cliente: fu.importador.pessoa.nome,
            cnpj: fu.importador.pessoa.cnpj,
            servico: fu.servico.nome,
            abertura: fu.insertionDate,
            status: fu.status,
          }))}
          filterPropGetter={undefined}
          entityPluralName={undefined}
          defaultQuerySearch={initialQuery}
          showInactiveRows={false}
        ></StyledDataGridControl>
      )}
    </PageLayout>
  );
};

export default AssessoriaFollowupPage;
