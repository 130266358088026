import React from 'react';
import QCXFormStepWizard from '../../shared-components/form-step-wizard/QCXFormStepWizard';
import QCXSelectOneTabelaSdaTable from './QCXSelectOneTabelaSdaTable';

export default function QCXSelectOneTabelaSdaFinalFormTable({
  selected = null,
  isConsult,
  handleSubmit,
  handleChange,
  title,
  filterProp,
  columns,
  controlComponentProps,
  authInfo = {},
  requiredRoles = [],
  ...restProps
}) {
  return (
    <QCXFormStepWizard
      onSubmit={handleSubmit}
      controlComponentProps={controlComponentProps}
      authInfo={authInfo}
      requiredRoles={requiredRoles}
      {...restProps}
    >
      {() => (
        <QCXSelectOneTabelaSdaTable
          title={title}
          columns={columns}
          filterProp={filterProp}
          selected={selected}
          isConsult={isConsult}
          handleChange={handleChange}
        />
      )}
    </QCXFormStepWizard>
  );
}
