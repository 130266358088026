import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { padStart } from '../../utils/general/general-utils';
import { formatCFOPCode } from '../../utils/hooks/form/field/formatters';
import { IDLE_CONTROL } from '../config-control';
import {
  BACKGROUND_CREATE_MODE,
  CONSULT_MODE,
  CREATE_MODE,
  NONE_BACKGROUND_MODE,
  NONE_MODE,
  UPDATE_MODE,
} from '../mode';
import {
  ALTERNATIVE_LOADING_STATUS,
  FAILURE_STATUS,
  IDLE_STATUS,
  LOADING_STATUS,
  PREPARING_ACTION_STATUS,
  SUCCESS_STATUS,
} from '../status';
import { fetchAll, fetchById, activateById, inactivateById } from './cfopOperacoesFiscaisAPI';
import i18n from '../../i18n';

interface CFOP {
  id: string;
  insertionDate: string | null;
  code: string;
  active: boolean | null;
  detalhe?: string;
  importacao: boolean | null;
  exportacao: boolean | null;
  nome: string | null;
}

interface CFOPState {
  status: string;
  mode: {
    main: string;
    background: string;
  };
  error: {
    message: string;
  } | null;
  response: {
    status: number | null;
    message: string | null;
    data: string | null;
  };
  config: {
    control: string;
  };
  list: CFOP[];
  model: CFOP;
}

const initialState: CFOPState = {
  status: IDLE_STATUS,
  mode: {
    main: NONE_MODE,
    background: NONE_BACKGROUND_MODE,
  },
  error: null,
  response: {
    status: null,
    message: null,
    data: null,
  },
  config: {
    control: IDLE_CONTROL,
  },
  list: [],
  model: {
    id: '',
    nome: '',
    active: null,
    code: '',
    importacao: null,
    exportacao: null,
    insertionDate: null,
  },
};

const fetchAllAsync = createAsyncThunk('cfopOperacaoFiscal/fetchAll', async () => {
  const { data } = await fetchAll();
  return { data };
});

const fetchByIdAsync = createAsyncThunk('cfopOperacaoFiscal/fetchById', async (data) => {
  const response = await fetchById(data);
  return {
    response: {
      status: response.status,
      data: response.data,
    },
  };
});

const activateByIdAsync = createAsyncThunk('cfopOperacaoFiscal/activateById', async (id) => {
  const { status, data } = await activateById(id);
  return { response: { status, data } };
});

const inactivateByIdAsync = createAsyncThunk('cfopOperacaoFiscal/inactivateById', async (id) => {
  const { status, data } = await inactivateById(id);
  return { response: { status, data } };
});

const cfopOperacaoFiscalSlice = createSlice({
  name: 'cfopOperacaoFiscal',
  initialState,
  reducers: {
    changeStatusTo: (state, action) => {
      state.status = action.payload.status;
    },
    loading: (state) => {
      state.status = LOADING_STATUS;
    },
    success: (state) => {
      state.status = SUCCESS_STATUS;
    },
    failure: (state) => {
      state.status = FAILURE_STATUS;
    },
    preparingAction: (state) => {
      state.status = PREPARING_ACTION_STATUS;
    },
    resetStatus: (state) => {
      state.status = IDLE_STATUS;
    },
    changeToCreateMode: (state) => {
      state.mode.main = CREATE_MODE;
    },
    changeToConsultMode: (state) => {
      state.mode.main = CONSULT_MODE;
    },
    changeToUpdateMode: (state) => {
      state.mode.main = UPDATE_MODE;
    },
    resetMode: (state) => {
      state.mode.main = NONE_MODE;
    },
    changeToBackgroundCreateMode: (state) => {
      state.mode.background = BACKGROUND_CREATE_MODE;
    },
    resetBackgroundMode: (state) => {
      state.mode.background = NONE_BACKGROUND_MODE;
    },
    addToList: (state, action) => {
      state.list = [...state.list, action.payload.data];
    },
    updateOnList: (state, action) => {
      state.list = state.list.map((current) => {
        if (current.id === action.payload.data?.id) {
          return action.payload.data;
        }
        return current;
      });
    },
    setResponse: (state, action) => {
      state.response = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    clearResponse: (state) => {
      state.response = {
        status: null,
        message: null,
        data: null,
      };
    },
    setModel: (state, action) => {
      state.model = {
        ...action.payload,
      };
    },
    resetModel: (state) => {
      state.model = initialState.model;
    },
    setConfig: (state, action) => {
      state.config = action.payload;
    },
    changeControlTo: (state, action) => {
      state.config.control = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllAsync.pending, (state) => {
        state.status = ALTERNATIVE_LOADING_STATUS;
      })
      .addCase(fetchAllAsync.fulfilled, (state, action) => {
        state.status = IDLE_STATUS;
        const cfopOperacoesFiscaisList = action.payload.data;
        const updatedCfopOperacoesFiscaisList = cfopOperacoesFiscaisList.map((operacaoFiscal: CFOP) => {
          const unormalizedCode =
            operacaoFiscal?.code.length !== 4 ? padStart(operacaoFiscal?.code, 4, '0') : operacaoFiscal?.code;

          const updatedOperacaoFiscal = {
            ...operacaoFiscal,
            code: formatCFOPCode(unormalizedCode),
          };

          return updatedOperacaoFiscal;
        });

        state.list = updatedCfopOperacoesFiscaisList;
      })
      .addCase(fetchAllAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroCarregarListaCFOP', { erro: action.error.message }),
        };
      })
      .addCase(fetchByIdAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(fetchByIdAsync.fulfilled, (state, action) => {
        if (action.payload.response.status === 200) {
          const normalizedData = action.payload.response.data;
          const { code } = normalizedData;

          const unormalizedCode = code.length !== 4 ? padStart(code, 4, '0') : code;

          const unnormalizedData = {
            ...normalizedData,
            code: formatCFOPCode(unormalizedCode),
          };

          state.model = unnormalizedData;
          state.status = SUCCESS_STATUS;
        }
      })
      .addCase(fetchByIdAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroCarregarCFOP', { erro: action.error.message }),
        };
      })
      .addCase(activateByIdAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(activateByIdAsync.fulfilled, (state, action) => {
        state.status = SUCCESS_STATUS;
        state.response.status = action.payload.response.status;
        state.response.data = action.payload.response.data;

        const { nome } = action.payload.response.data;
        state.response.message = i18n.t('com.muralis.qcx.mensagem.CFOPAtivado', { nome });

        if (state.response.status === 200) {
          state.list = state.list.map((current) =>
            current.id === action.payload.response.data.id ? action.payload.response.data : current
          );
        }
      })
      .addCase(activateByIdAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroAtivarCFOP', { erro: action.error.message }),
        };
      })
      .addCase(inactivateByIdAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(inactivateByIdAsync.fulfilled, (state, action) => {
        state.status = SUCCESS_STATUS;
        state.response.status = action.payload.response.status;
        state.response.data = action.payload.response.data;

        const { code } = action.payload.response.data;
        state.response.message = i18n.t('com.muralis.qcx.mensagem.CFOPInativado', { code });

        if (state.response.status === 200) {
          state.list = state.list.map((current) =>
            current.id === action.payload.response.data.id ? action.payload.response.data : current
          );
        }
      })
      .addCase(inactivateByIdAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroInativarCFOP', { erro: action.error.message }),
        };
      });
  },
});

const {
  changeStatusTo,
  loading,
  success,
  failure,
  preparingAction,
  resetStatus,
  changeControlTo,
  changeToConsultMode,
  changeToCreateMode,
  changeToUpdateMode,
  resetMode,
  changeToBackgroundCreateMode,
  resetBackgroundMode,
  setConfig,
  addToList,
  updateOnList,
  setResponse,
  clearResponse,
  setError,
  setModel,
  resetModel,
} = cfopOperacaoFiscalSlice.actions;

const selectStatus = (state: any) => state.cfopOperacaoFiscal.status;
const selectMode = (state: any) => state.cfopOperacaoFiscal.mode.main;
const selectCFOPOperacaoFiscal = (state: any) => state.cfopOperacaoFiscal.model as CFOP;
const selectCFOPOperacoesFiscais = (state: any) => state.cfopOperacaoFiscal.list as CFOP[];
const selectError = (state: any) => state.cfopOperacaoFiscal.error;
const selectResponse = (state: any) => state.cfopOperacaoFiscal.response;
const selectConfigControl = (state: any) => state.cfopOperacaoFiscal.config.control;
const selectBackgroundMode = (state: any) => state.cfopOperacaoFiscal.mode.background;

const cfopOperacaoFiscalActions = {
  ...cfopOperacaoFiscalSlice.actions,
  selectConfigControl,
  selectCFOPOperacoesFiscais,
  selectStatus,
  selectError,
  selectResponse,
  selectCFOPOperacaoFiscal,
  fetchAllAsync,
};
export {
  cfopOperacaoFiscalSlice,
  changeStatusTo,
  loading,
  success,
  failure,
  preparingAction,
  resetStatus,
  changeControlTo,
  changeToConsultMode,
  changeToCreateMode,
  changeToUpdateMode,
  resetMode,
  changeToBackgroundCreateMode,
  resetBackgroundMode,
  setConfig,
  addToList,
  updateOnList,
  setResponse,
  clearResponse,
  setError,
  setModel,
  resetModel,
  fetchAllAsync,
  fetchByIdAsync,
  activateByIdAsync,
  inactivateByIdAsync,
  cfopOperacaoFiscalActions,
  selectCFOPOperacoesFiscais,
  selectMode,
  selectCFOPOperacaoFiscal,
  selectStatus,
  selectError,
  selectResponse,
  selectConfigControl,
  selectBackgroundMode,
};

export default cfopOperacaoFiscalSlice.reducer;
