import { useCallback, useEffect, useMemo, useState } from 'react';
import * as XLSX from 'xlsx';
import { useSelector } from 'react-redux';
import { selectUnidadeSelecionada } from '../../../../features/usuario-logado/usuarioLogadoSlice';
import { useKeycloak } from '@react-keycloak/web';
import { KEYCLOAK_TOKEN_TIMEOUT } from '../../../../App';

export type SheetConfig = {
  name: string;
  formatedHeaders: string[];
  headers: string[];
};

export type SpreadsheetConfig = {
  dataFetchEndpoint: string;
  dataSaveEndpoint: string;
  sheets: { [key: string]: SheetConfig };
  fileNamePrefix: string;
  requestMethod: 'PUT' | 'POST';
  requestBody: any;
};

type SpreadsheetStatus = {
  loading: boolean;
  message: string;
  severtiy: 'success' | 'error' | 'info';
};

interface SpreadsheetData {
  [key: string]: any;
}

/**
 * The function `filterJsonHeaders` filters a JSON array based on a given list of headers.
 * @param {any} json - The `json` parameter is an array of objects. Each object represents a row of
 * data in a spreadsheet.
 * @param {string[]} headers - An array of strings representing the headers that you want to filter
 * from the JSON data.
 * @returns The function `filterJsonHeaders` returns an array of objects, where each object contains
 * only the key-value pairs that have keys matching the values in the `headers` array.
 */
const filterJsonHeaders = (json: any, headers: string[]) => {
  return json.map((item: any) => {
    const filteredItem: SpreadsheetData = {};
    Object.keys(item).forEach((key) => {
      if (headers.includes(key)) {
        filteredItem[key] = item[key];
      }
    });
    return filteredItem;
  });
};

/**
 * The function converts decimal separators in a JSON object from "." to ",".
 * @param {any} json - The `json` parameter is an array of objects. Each object represents a row of
 * data in a spreadsheet.
 * @returns The function `convertDecimalSeparators` returns a modified version of the input `json`
 * array. The decimal separators in the values of the array are replaced from "." to ",".
 */
const convertDecimalSeparators = (json: any) => {
  return json.map((item: any) => {
    const filteredItem: SpreadsheetData = {};
    Object.keys(item).forEach((key) => {
      if (typeof item[key] === 'string') {
        filteredItem[key] = item[key].replace('.', ',');
      } else {
        filteredItem[key] = item[key];
      }
    });
    return filteredItem;
  });
};

/**
 * The `useSpreadsheet` function is a custom hook in TypeScript that provides functionality for
 * generating and importing spreadsheets, as well as managing the status of these operations.
 * @param {SpreadsheetConfig} config - The `config` parameter is an object that contains the
 * configuration for the spreadsheet operations. It has the following properties:
 * @returns The `useSpreadsheet` function returns an object with three properties:
 */
export const useSpreadsheet = (config: SpreadsheetConfig) => {
  const { keycloak } = useKeycloak();
  const [status, setStatus] = useState<SpreadsheetStatus | null>({
    loading: false,
    message: 'Ocioso',
    severtiy: 'info',
  });
  const selectedUnidade: number = useSelector(selectUnidadeSelecionada);

  const generateSpreadsheet = useCallback(
    async (reqQuery: string, fileNameSuffix: string) => {
      setStatus({
        loading: true,
        message: 'Gerando planilha...',
        severtiy: 'info',
      });

      try {
        keycloak.updateToken(KEYCLOAK_TOKEN_TIMEOUT);
        const { token } = keycloak;

        const requestHeaders: HeadersInit = new Headers();
        requestHeaders.append('Authorization', `Bearer ${token}`);
        requestHeaders.append('quickcomexTenant', selectedUnidade.toString());

        const response = await fetch(`${config.dataFetchEndpoint}?${reqQuery}`, {
          method: 'GET',
          headers: requestHeaders,
        });

        if (response.ok) {
          const spreadSheetJson = await response.json();
          const workbook = XLSX.utils.book_new();

          // Loop through each sheet and process its data
          Object.keys(config.sheets).forEach((sheetKey) => {
            const sheetConfig = config.sheets[sheetKey];
            let sheetData = spreadSheetJson[sheetKey];
            let filteredSheetData = filterJsonHeaders(sheetData, sheetConfig.headers);
            filteredSheetData = convertDecimalSeparators(filteredSheetData);

            const worksheet = XLSX.utils.json_to_sheet(filteredSheetData, {
              header: sheetConfig.headers,
            });

            XLSX.utils.sheet_add_aoa(worksheet, [sheetConfig.formatedHeaders], {
              origin: { c: 0, r: 0 },
            });

            XLSX.utils.book_append_sheet(workbook, worksheet, sheetConfig.name);
          });

          XLSX.writeFile(workbook, `${config.fileNamePrefix} - ${fileNameSuffix}.xlsx`);
          setStatus({
            loading: false,
            message: 'Planilha gerada com sucesso',
            severtiy: 'success',
          });
        } else {
          const error = await response.json();
          setStatus({
            loading: false,
            message: 'Erro ao gerar planilha: ' + error.message,
            severtiy: 'error',
          });
        }
      } catch (error: any) {
        setStatus({
          loading: false,
          message: 'Erro ao gerar planilha: ' + error.message,
          severtiy: 'error',
        });
      }
    },
    [config, keycloak, selectedUnidade]
  );

  const importSpreadsheet = useCallback(
    async (config: SpreadsheetConfig, file: File) => {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('payload', JSON.stringify(config.requestBody));

      setStatus({
        loading: true,
        message: 'Importando planilha...',
        severtiy: 'info',
      });

      try {
        keycloak.updateToken(KEYCLOAK_TOKEN_TIMEOUT);
        const { token } = keycloak;

        const requestHeaders: HeadersInit = new Headers();
        requestHeaders.append('Authorization', `Bearer ${token}`);
        requestHeaders.append('quickcomexTenant', selectedUnidade.toString());

        const response = await fetch(config.dataSaveEndpoint, {
          method: config.requestMethod,
          headers: requestHeaders,
          body: formData,
        });

        if (response.ok) {
          setStatus({
            loading: false,
            message: 'Planilha importada com sucesso',
            severtiy: 'success',
          });
        } else {
          const error = await response.json();
          setStatus({
            loading: false,
            message: 'Erro ao importar planilha: ' + error.message,
            severtiy: 'error',
          });
        }
      } catch (error: any) {
        setStatus({
          loading: false,
          message: 'Erro ao importar planilha: ' + error.message,
          severtiy: 'error',
        });
      }
    },
    [config]
  );

  return { generateSpreadsheet, importSpreadsheet, status };
};
