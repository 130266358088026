import { getOptionByValueInList } from '../../utils/general/general-utils';
import i18n from '../../i18n';

const MODALIDADE_DESPACHO_NORMAL = 'NORMAL';
const MODALIDADE_DESPACHO_ANTECIPADA = 'ANTECIPADA';
const MODALIDADE_DESPACHO_ENTREGA_FRACIONADA = 'ENTREGA_FRACIONADA';
const MODALIDADE_DESPACHO_ANTECIPADO_COM_ENTREGA_FRACIONADA = 'ANTECIPADO_COM_ENTREGA_FRACIONADA';
const MODALIDADE_DESPACHO_SOBRE_AGUAS_OEA = 'SOBRE_AGUAS_OEA';

const MODALIDADE_DESPACHO_LIST = [
  {
    label: i18n.t('com.muralis.qcx.despacho.normal').toUpperCase(),
    value: MODALIDADE_DESPACHO_NORMAL,
  },
  {
    label: i18n.t('com.muralis.qcx.despacho.antecipada').toUpperCase(),
    value: MODALIDADE_DESPACHO_ANTECIPADA,
  },
  {
    label: i18n.t('com.muralis.qcx.despacho.entregaFracionada').toUpperCase(),
    value: MODALIDADE_DESPACHO_ENTREGA_FRACIONADA,
  },
  {
    label: i18n.t('com.muralis.qcx.despacho.antecipadoEntregaFracionada').toUpperCase(),
    value: MODALIDADE_DESPACHO_ANTECIPADO_COM_ENTREGA_FRACIONADA,
  },
  {
    label: i18n.t('com.muralis.qcx.despacho.sobreAguasOEA').toUpperCase(),
    value: MODALIDADE_DESPACHO_SOBRE_AGUAS_OEA,
  },
];

const isModalidadeDespachoNormal = (value) => (
  MODALIDADE_DESPACHO_NORMAL === value
);

const isModalidadeDespachoAntecipada = (value) => (
  MODALIDADE_DESPACHO_ANTECIPADA === value
);

const isModalidadeDespachoEntregaFracionada = (value) => (
  MODALIDADE_DESPACHO_ENTREGA_FRACIONADA === value
);

const isModalidadeDespachoAntecipadaComEntregaFracionada = (value) => (
  MODALIDADE_DESPACHO_ANTECIPADO_COM_ENTREGA_FRACIONADA === value
);

const isModalidadeDespachoSobreAguasOea = (value) => (
  MODALIDADE_DESPACHO_SOBRE_AGUAS_OEA === value
);

const getModalidadeDespachoLabelByValue = (value) => {
  const foundOption = getOptionByValueInList(MODALIDADE_DESPACHO_LIST, value);
  return foundOption && foundOption?.label;
};

export {
  MODALIDADE_DESPACHO_NORMAL,
  MODALIDADE_DESPACHO_ANTECIPADA,
  MODALIDADE_DESPACHO_ENTREGA_FRACIONADA,
  MODALIDADE_DESPACHO_ANTECIPADO_COM_ENTREGA_FRACIONADA,
  MODALIDADE_DESPACHO_SOBRE_AGUAS_OEA,
  MODALIDADE_DESPACHO_LIST,
};

export {
  isModalidadeDespachoNormal,
  isModalidadeDespachoAntecipada,
  isModalidadeDespachoEntregaFracionada,
  isModalidadeDespachoAntecipadaComEntregaFracionada,
  isModalidadeDespachoSobreAguasOea,
  getModalidadeDespachoLabelByValue,
};
