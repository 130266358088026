import { isFunction } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import store from '../../../../app/store';
import {
  activateOperation,
  resetOperation,
  selectOperation,
} from '../../../../features/controller/controllerSlice';

const { dispatch } = store;

export default function useOperationController(callback, deps = []) {
  const operation = useSelector(selectOperation);
  const [savedArgs, setSavedArgs] = useState([]);

  useEffect(() => {
    let unmounted = false;

    if (!unmounted) {
      if (operation?.authorized && isFunction(callback)) {
        callback.call(this, ...savedArgs);
        setSavedArgs([]);
        dispatch(resetOperation());
      }
    }

    return () => {
      unmounted = true;
    };
  }, [operation, callback, savedArgs]);

  const composedCallback = useCallback((...args) => {
    setSavedArgs(args);
    dispatch(activateOperation());
  }, deps);

  return composedCallback;
}
