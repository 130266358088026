import { Grid } from '@material-ui/core';
import { v4 as uuid } from 'uuid';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  vinculoReferenciaClienteFollowUpActions,
  vinculoReferenciaClienteFollowUpSelectors,
} from '../../../features/vinculo-referencia-cliente-follow-up/vinculoReferenciaClienteFollowUpSlice';
import QCXFinalDatePickerField from '../../final-date-picker-field/QCXFinalDatePickerField';
import QCXFinalBondManagerOld from '../../final-gerenciador-relacionamento/QCXFinalBondManagerOld';
import QCXFinalTextField from '../../final-text-field/QCXFinalTextField';
import { isStrictEquals } from '../../../utils/general/general-utils';
import QCXFinalNumericDecimalField from '../../final-numeric-decimal-field/QCXFinalNumericDecimalField';
import TipoContainerSelect, { convertTipoContainerValueToLabel } from '../selects/TipoContainerSelect';

function ContainerBondManager({ isConsult }) {
  const { t } = useTranslation();

  const dadosContainerReducerConfig = useMemo(
    () => ({
      selectors: {
        selectStatus: vinculoReferenciaClienteFollowUpSelectors.selectStatus,
        selectMode: vinculoReferenciaClienteFollowUpSelectors.selectMode,
        selectModel: vinculoReferenciaClienteFollowUpSelectors.selectModel,
      },
      actions: {
        loading: vinculoReferenciaClienteFollowUpActions.loading,
        resetStatus: vinculoReferenciaClienteFollowUpActions.resetStatus,
        changeToUpdateMode: vinculoReferenciaClienteFollowUpActions.changeToUpdateMode,
        changeToCreateMode: vinculoReferenciaClienteFollowUpActions.changeToCreateMode,
        resetMode: vinculoReferenciaClienteFollowUpActions.resetMode,
        setModel: vinculoReferenciaClienteFollowUpActions.setModel,
        resetModel: vinculoReferenciaClienteFollowUpActions.resetModel,
      },
    }),
    []
  );

  const dadosContainerFormProps = useMemo(
    () => ({
      rootName: 'ignorableFields.container',
      fields: [
        {
          name: 'tipoContainer',
          label: t('com.muralis.qcx.dadosEmbarque.containers.Tipo'),
        },
        {
          name: 'numeracao',
          label: t('com.muralis.qcx.dadosEmbarque.containers.Numeracao'),
        },
        {
          name: 'tara',
          label: t('com.muralis.qcx.dadosEmbarque.containers.Tara'),
        },
        {
          name: 'lacre',
          label: t('com.muralis.qcx.dadosEmbarque.containers.Lacre'),
        },
        {
          name: 'peso',
          label: t('com.muralis.qcx.dadosEmbarque.containers.PesoContainer'),
        },
        {
          name: 'devolucaoContainer',
          label: t('com.muralis.qcx.dadosEmbarque.containers.DevolucaoContainer'),
          required: false,
        },
        {
          name: 'freeTime',
          label: t('com.muralis.qcx.dadosEmbarque.containers.FreeTimeContainer'),
          required: false,
        },
      ],
    }),
    []
  );

  const dadosContainerListProps = useMemo(
    () => ({
      name: 'containers',
      columns: [
        {
          field: 'tipoContainer',
          headerName: 'Tipo de Container',
          headerAlign: 'left',
          align: 'left',
          flex: 140,
          valueGetter: ({ row }) => convertTipoContainerValueToLabel(row?.tipoContainer),
        },
        {
          field: 'numeracao',
          headerName: t('com.muralis.qcx.dadosEmbarque.containers.Numeracao'),
          headerAlign: 'left',
          align: 'left',
          flex: 140,
          valueGetter: ({ row }) => row?.numeracao || '-',
        },
        {
          field: 'tara',
          headerName: 'Tara',
          headerAlign: 'left',
          align: 'left',
          flex: 140,
          valueGetter: ({ row }) => row?.tara || '-',
        },
        {
          field: 'peso',
          headerName: 'Peso',
          headerAlign: 'left',
          align: 'left',
          flex: 140,
          valueGetter: ({ row }) => row?.peso || '-',
        },
        {
          field: 'devolucaoContainer',
          headerName: t('com.muralis.qcx.dadosEmbarque.containers.Devolucao'),
          headerAlign: 'left',
          align: 'left',
          flex: 140,
          valueGetter: ({ row }) => row?.devolucaoContainer || '-',
        },
        {
          field: 'freeTime',
          headerName: 'Free Time',
          headerAlign: 'left',
          align: 'left',
          flex: 140,
          valueGetter: ({ row }) => row?.freeTime || '-',
        },
      ],
    }),
    []
  );

  const handleAddReferenciaCliente = useCallback(async (currentVinculo, currentVinculos, handleSuccessAddVinculo) => {
    const vinculo = {
      id: uuid(),
      ...currentVinculo,
    };

    const updatedVinculos = [...currentVinculos, vinculo];

    handleSuccessAddVinculo(updatedVinculos);
  }, []);

  const handleUpdateReferenciaCliente = useCallback(
    async (currentVinculo, currentVinculos, handleSuccessUpdateVinculo) => {
      const updatedVinculo = {
        ...currentVinculo,
      };

      const updatedVinculos =
        currentVinculos?.map((existingVinculo) =>
          isStrictEquals(existingVinculo?.id, updatedVinculo?.id) ? updatedVinculo : existingVinculo
        ) || currentVinculos;

      handleSuccessUpdateVinculo(updatedVinculos);
    },
    []
  );

  // TODO: implementar validação se já existe
  const handleReferenciaClienteAlreadyExists = useCallback(() => false, []);

  return (
    <>
      <QCXFinalBondManagerOld
        isParentConsult={isConsult}
        reducerConfig={dadosContainerReducerConfig}
        listProps={dadosContainerListProps}
        formProps={dadosContainerFormProps}
        controlButtonsGridProps={{
          add: {
            xs: 12,
          },
          update: {
            xs: 3,
          },
          clear: {
            xs: 3,
          },
        }}
        handleAdd={handleAddReferenciaCliente}
        handleUpdate={handleUpdateReferenciaCliente}
        handleAlreadyExists={handleReferenciaClienteAlreadyExists}
      >
        {({ loading }) => (
          <>
            <Grid item xs={3}>
              <TipoContainerSelect
                id="tipo-select"
                key="tipo-select"
                name="ignorableFields.container.tipoContainer"
                label={t('com.muralis.qcx.dadosEmbarque.containers.Tipo')}
                disabled={isConsult || loading}
              />
            </Grid>
            <Grid item xs={3}>
              <QCXFinalTextField
                id="numeracao-text-field"
                key="numeracao-text-field"
                name="ignorableFields.container.numeracao"
                label={t('com.muralis.qcx.dadosEmbarque.containers.Numeracao')}
                disabled={isConsult || loading}
              />
            </Grid>
            <Grid item xs={3}>
              <QCXFinalNumericDecimalField
                id="tara-text-field"
                key="tara-text-field"
                name="ignorableFields.container.tara"
                label={t('com.muralis.qcx.dadosEmbarque.containers.Tara')}
                disabled={isConsult || loading}
              />
            </Grid>
            <Grid item xs={3}>
              <QCXFinalTextField
                id="lacre-text-field"
                key="lacre-text-field"
                name="ignorableFields.container.lacre"
                label={t('com.muralis.qcx.dadosEmbarque.containers.Lacre')}
                disabled={isConsult || loading}
              />
            </Grid>
            <Grid item xs={4}>
              <QCXFinalNumericDecimalField
                id="peso-text-field"
                key="peso-text-field"
                name="ignorableFields.container.peso"
                label={t('com.muralis.qcx.dadosEmbarque.containers.Peso')}
                disabled={isConsult || loading}
              />
            </Grid>
            <Grid item xs={2}>
              <QCXFinalDatePickerField
                id="devolucao-date-picker-field"
                key="devolucao-date-picker-field"
                name="ignorableFields.container.devolucaoContainer"
                label={t('com.muralis.qcx.dadosEmbarque.containers.Devolucao')}
                disabled={isConsult}
              />
            </Grid>
            <Grid item xs={2}>
              <QCXFinalDatePickerField
                id="free-time-date-picker-field"
                key="free-time-date-picker-field"
                name="ignorableFields.container.freeTime"
                label={t('com.muralis.qcx.dadosEmbarque.containers.FreeTime')}
                disabled={isConsult}
              />
            </Grid>
          </>
        )}
      </QCXFinalBondManagerOld>
    </>
  );
}

export default ContainerBondManager;
