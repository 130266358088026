import {
  Grid,
  Typography,
} from '@material-ui/core';
import React, {
  useMemo,
  useCallback,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import QCXFormTitle from '../form-title/QCXFormTitle';
import QCXFinalAlternativeCurrencyField from '../../shared-components/final-currency-field/QCXFinalAlternativeCurrencyField';
import QCXFinalOnblurMaskedField from '../../shared-components/final-onblur-masked-field/QCXFinalOnblurMaskedField';
import QCXFinalSelectTipoSindicatoAutocomplete from '../../shared-components/final-select-tipo-sindicato-field/QCXFinalSelectTipoSindicatoAutocomplete';
import QCXFinalTextField from '../../shared-components/final-text-field/QCXFinalTextField';
import QCXFormStepWizard from '../../shared-components/form-step-wizard/QCXFormStepWizard';
import QCXFinalSelectTipoCalculoSobreAutocomplete from '../../shared-components/select-calculo-sobre/QCXFinalSelectTipoCalculoSobreAutocomplete';
import QCXFinalSelectTipoPeriodoAutocomplete from '../../shared-components/select-periodo/QCXFinalSelectTipoPeriodoAutocomplete';
import QCXFinalCertificateInputFile from '../../shared-components/input-file/QCXFinalCertificateInputFile';
import {
  formatCep,
  formatCpfOrCnpj,
  formatRegistroDespachante,
  formatTelefoneFixo,
} from '../../utils/hooks/form/field/formatters';
import { BRAZILIAN } from '../../utils/hooks/form/field/mask-types';
import {
  parseCpfOrCnpj,
  parseRegistroDespachante,
  parseTelefoneFixoWithDDD,
  parseToOnlyNumbersWithMaxDigits,
} from '../../utils/hooks/form/field/parsers';
import useValidators from '../../utils/hooks/useValidators';
import {
  configureNumberNotBetweenMinAndMaxValidator,
  maxValue,
  required as requiredValidator,
  validCep,
  validCpfOrCnpj,
  validTelefoneFixoWithDDDLength,
} from '../../utils/validators/field/validator';
import { MediaType } from '../../utils/api/api-utils';
import QCXSelectEstadoAutocomplete from '../../shared-components/select-estado/QCXSelectEstadoAutocomplete';
import QCXSelectCidadeAutocomplete from '../../shared-components/select-cidade/QCXSelectCidadeAutocomplete';
import { paisActions, paisSelectors } from '../../features/pais/paisSlice';
import { estadoActions } from '../../features/estado/estadoSlice';
import { cidadeActions } from '../../features/cidade/cidadeSlice';

export default function QCXDespachanteTecnicalDataForm({
  initialValues,
  handleSubmit,
  isCreate,
  isConsult,
  isUpdate,
  controlComponentProps,
  authInfo = {},
  requiredRoles = [],
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [currentPais] = useSelector(paisSelectors.selectPaises);

  useEffect(() => {
    if (isCreate) {
      const PAIS_BRASIL_CODE = '105';

      dispatch(
        paisActions.fetchByFilterAsync([{
          name: 'code',
          value: PAIS_BRASIL_CODE,
        }])
      );
    }

    return () => {
      dispatch(
        paisActions.resetList()
      );
    };
  }, [isCreate]);

  useEffect(() => {
    if (isCreate && currentPais?.id) {
      dispatch(
        estadoActions.fetchByFilterAsync([{
          name: 'pais',
          value: currentPais?.id,
        }])
      );
    }
  }, [
    isCreate,
    currentPais,
  ]);

  useEffect(() => {
    if ((isConsult || isUpdate) && initialValues?.endereco?.cidade?.estado?.pais?.id) {
      dispatch(
        estadoActions.fetchByFilterAsync([{
          name: 'pais',
          value: initialValues?.endereco?.cidade?.estado?.pais?.id,
        }])
      );
    }
  }, [
    isConsult,
    isUpdate,
    initialValues,
  ]);

  const handleEstadoChange = useCallback((form) => (value) => {
    form.change('endereco.cidade.id', undefined);
    form.change('endereco.cidade.estado.id', value);

    dispatch(
      cidadeActions.fetchByFilterAsync([{
        name: 'estado',
        value,
      }])
    );
  }, []);

  const handleCidadeChange = useCallback((form) => (value) => {
    form.change('endereco.cidade.id', value);
  }, []);

  const listeners = useMemo(() => ([
    {
      name: 'endereco.cidade.estado.id',
      fn: handleEstadoChange,
    },
    {
      name: 'endereco.cidade.id',
      fn: handleCidadeChange,
    },
  ]), [
    handleEstadoChange,
    handleCidadeChange,
  ]);

  const validateCpfCnpj = useMemo(() => (
    useValidators([requiredValidator, validCpfOrCnpj])
  ), []);

  const validateCep = useMemo(() => (
    useValidators([
      requiredValidator,
      validCep(8, 9),
    ])
  ), []);

  const telefoneValidators = useMemo(() => (
    useValidators([
      requiredValidator,
      validTelefoneFixoWithDDDLength,
      configureNumberNotBetweenMinAndMaxValidator(10, 12),
    ])
  ), []);

  const limiteRecolhimentoValidators = useMemo(() => (
    useValidators([
      requiredValidator,
      maxValue(999999.99),
    ])
  ), [requiredValidator, validCpfOrCnpj]);

  const certificadoDigitalGetter = useCallback((values) => {
    if (!values?.id && values?.length > 0) {
      return values[0];
    }

    return values;
  }, []);

  const certificadoDigitalNameGetter = useCallback((file) => {
    if (file?.id) {
      return file?.nome;
    }

    return file?.name;
  }, []);

  const internalInitialValues = useMemo(() => (
    isCreate && currentPais && currentPais?.id
      ? {
        ...initialValues,
        endereco: {
          ...initialValues?.endereco,
          cidade: {
            ...initialValues?.endereco?.cidade,
            estado: {
              ...initialValues?.endereco?.cidade?.estado,
              pais: currentPais,
            },
          },
        },
      } : initialValues
  ), [
    isCreate,
    currentPais,
    initialValues,
  ]);

  return (
    <QCXFormStepWizard
      initialValues={internalInitialValues}
      onSubmit={handleSubmit}
      onChangeListeners={listeners}
      controlComponentProps={controlComponentProps}
      authInfo={authInfo}
      requiredRoles={requiredRoles}
    >
      {({ values }) => (
        <>
          <Typography variant="h6" color="primary">
            <QCXFormTitle title={t('com.muralis.qcx.dadosTecnicos')} />
          </Typography>
          <Grid container spacing={2} style={{ marginTop: '1rem' }}>
            {!isCreate && (
              <Grid
                item
                xs={12}
                sm={3}
                md={2}
              >
                <QCXFinalTextField
                  id="text-field-id-despachante"
                  key="text-field-id-despachante"
                  name="id"
                  label={t('com.muralis.qcx.codigo')}
                  disabled
                />
              </Grid>
            )}
            <Grid
              item
              xs={12}
              sm={6}
              md={isCreate ? 6 : 7}
            >
              <QCXFinalTextField
                id="text-field-nome-despachante"
                key="text-field-nome-despachante"
                name="nome"
                label={t('com.muralis.qcx.nome')}
                maxLength={50}
                validate={requiredValidator}
                disabled={isConsult}
                required
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={3}
              md={3}
            >
              <QCXFinalOnblurMaskedField
                id="masked-text-registro-despachante-field"
                key="masked-text-registro-despachante-field"
                name="registro"
                label={t('com.muralis.qcx.registro')}
                maxLength={7}
                format={formatRegistroDespachante}
                parse={parseRegistroDespachante}
                validate={requiredValidator}
                disabled={isConsult}
                required
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={3}
              md={3}
            >
              <QCXFinalOnblurMaskedField
                id="masked-text-cpf-cnpj-despachante-field"
                key="masked-text-cpf-cnpj-despachante-field"
                name="cpfCnpj"
                label={t('com.muralis.qcx.CPFCNPJ')}
                format={formatCpfOrCnpj}
                parse={parseCpfOrCnpj}
                validate={validateCpfCnpj}
                disabled={isConsult}
                required
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={3}
              md={2}
            >
              <QCXFinalTextField
                id="text-field-endereco-cep"
                key="text-field-endereco-cep"
                name="endereco.cep"
                label={t('com.muralis.qcx.endereco.CEP')}
                format={formatCep}
                parse={parseToOnlyNumbersWithMaxDigits(8)}
                validate={validateCep}
                maxLength={9}
                disabled={isConsult}
                required
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={5}
            >
              <QCXFinalTextField
                id="text-field-endereco-logradouro"
                key="text-field-endereco-logradouro"
                name="endereco.logradouro"
                label={t('com.muralis.qcx.endereco.logradouro')}
                maxLength={150}
                validate={requiredValidator}
                disabled={isConsult}
                required
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={3}
              md={2}
            >
              <QCXFinalTextField
                id="text-field-endereco-numero"
                key="text-field-endereco-numero"
                name="endereco.numero"
                label={t('com.muralis.qcx.endereco.numero')}
                maxLength={10}
                validate={requiredValidator}
                disabled={isConsult}
                required
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={isCreate ? 5 : 4}
              md={3}
            >
              <QCXFinalTextField
                id="text-field-endereco-complemento"
                key="text-field-endereco-complemento"
                name="endereco.complemento"
                label={t('com.muralis.qcx.endereco.complemento')}
                maxLength={50}
                disabled={isConsult}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={isCreate ? 7 : 5}
              md={3}
            >
              <QCXFinalTextField
                id="text-field-endereco-bairro"
                key="text-field-endereco-bairro"
                name="endereco.bairro"
                label={t('com.muralis.qcx.endereco.bairro')}
                maxLength={70}
                validate={requiredValidator}
                disabled={isConsult}
                required
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
            >
              <QCXSelectEstadoAutocomplete
                name="endereco.cidade.estado.id"
                label={t('com.muralis.qcx.endereco.estado')}
                fieldProps={{ validate: requiredValidator }}
                disabled={isConsult}
                required
                initialValues={values}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
            >
              <QCXSelectCidadeAutocomplete
                name="endereco.cidade.id"
                label={t('com.muralis.qcx.endereco.cidade')}
                fieldProps={{ validate: requiredValidator }}
                initialValues={values}
                disabled={isConsult}
                required
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
            >
              <QCXFinalOnblurMaskedField
                id="text-field-contato-telefone"
                key="text-field-contato-telefone"
                label={t('com.muralis.qcx.telefone')}
                name="telefone"
                format={formatTelefoneFixo}
                parse={parseTelefoneFixoWithDDD}
                validate={telefoneValidators}
                maxLength={12}
                disabled={isConsult}
                required
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={isCreate ? 4 : 3}
            >
              <QCXFinalTextField
                id="input-senha-siscomex"
                key="input-senha-siscomex"
                name="senhaSiscomex"
                label={t('com.muralis.qcx.despachante.senhaSiscomex')}
                type="password"
                maxLength={15}
                validate={requiredValidator}
                disabled={isConsult}
                required
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              md={isCreate ? 4 : 3}
            >
              <QCXFinalAlternativeCurrencyField
                id="currency-limite-recolhimento-field"
                key="currency-limite-recolhimento-field"
                name="limiteRecolhimento"
                label={t('com.muralis.qcx.despachante.limiteRecolhimento')}
                intl={BRAZILIAN}
                adorned
                scale={2}
                validate={limiteRecolhimentoValidators}
                disabled={isConsult}
                required
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              md={isCreate ? 4 : 3}
            >
              <QCXFinalSelectTipoPeriodoAutocomplete
                id="select-periodo-field"
                key="select-periodo-field"
                label={t('com.muralis.qcx.periodo')}
                name="periodo"
                initialValues={values}
                fieldProps={{ validate: requiredValidator }}
                disabled={isConsult}
                required
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              md={3}
            >
              <QCXFinalSelectTipoCalculoSobreAutocomplete
                id="select-sobre-field"
                key="select-sobre-field"
                label={t('com.muralis.qcx.sobre')}
                name="sobre"
                initialValues={values}
                fieldProps={{ validate: requiredValidator }}
                disabled={isConsult}
                required
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              md={3}
            >
              <QCXFinalSelectTipoSindicatoAutocomplete
                id="text-field-tipo-sindicato"
                key="text-field-tipo-sindicato"
                name="sindicato"
                label={t('com.muralis.qcx.despachante.sindicato')}
                initialValues={values}
                fieldProps={{ validate: requiredValidator }}
                disabled={isConsult}
                required
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={8}
              md={6}
            >
              <QCXFinalCertificateInputFile
                id="upload-input-certificado"
                key="upload-input-certificado"
                name="certificadoDigital"
                label={t('com.muralis.qcx.certificado.certificadoDigital')}
                tooltipTitle={t('com.muralis.qcx.certificado.carregarCertificadoDigital')}
                accept={MediaType.ALL}
                fileGetter={certificadoDigitalGetter}
                fileNameGetter={certificadoDigitalNameGetter}
                disableRemoveControl
                disabled={isConsult}
              />
            </Grid>
          </Grid>
        </>
      )}
    </QCXFormStepWizard>
  );
}
