import {
  doGetQCXRequest,
  doPostQCXRequest,
  doPutQCXActivateRequest,
  doPutQCXInactivateRequest,
  doPutQCXRequest,
} from '../../utils/api/domain/domain-api-utils';
import { CFOP_OPERACAO_FISCAL_ENDPOINT } from '../../utils/api/domain/domain-endpoint-utils';

export async function fetchAll() {
  return doGetQCXRequest([
    CFOP_OPERACAO_FISCAL_ENDPOINT
  ]);
}

export async function fetchById(id) {
  return doGetQCXRequest([
    CFOP_OPERACAO_FISCAL_ENDPOINT,
    id
  ]);
}

export async function register(data) {
  return doPostQCXRequest(
    [CFOP_OPERACAO_FISCAL_ENDPOINT],
    data
  );
}

export async function save(data) {
  return doPutQCXRequest(
    [CFOP_OPERACAO_FISCAL_ENDPOINT],
    data
  );
}

export async function activateById(id) {
  return doPutQCXActivateRequest(
    [CFOP_OPERACAO_FISCAL_ENDPOINT],
    id
  );
}

export async function inactivateById(id) {
  return doPutQCXInactivateRequest(
    [CFOP_OPERACAO_FISCAL_ENDPOINT],
    id
  );
}
