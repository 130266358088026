const VIA_TRANSPORTE_ROOT_FIELD_NAME = 'atributosAdicionais.transporte.viaTransporte';
const MULTIMODAL_TRANSPORTE_ROOT_FIELD_NAME = 'atributosAdicionais.transporte.multimodal';
const TIPO_DOCUMENTO_CHEGADA_CARGA_TRANSPORTE_ROOT_FIELD_NAME = 'atributosAdicionais.transporte.documentoChegadaCarga.tipoDocumentoChegadaCarga';
const IDENTIFICACAO_DOCUMENTO_CHEGADA_CARGA_TRANSPORTE_ROOT_FIELD_NAME = 'atributosAdicionais.transporte.documentoChegadaCarga.identificacaoDocumentoChegadaCarga';
const EMBARCACAO_TRANSPORTE_ROOT_FIELD_NAME = 'atributosAdicionais.transporte.embarcacao';
const TRANSPORTADOR_INTERNACIONAL_TRANSPORTE_ROOT_FIELD_NAME = 'atributosAdicionais.transporte.transportadorInternacional.transportador';
const PAIS_TRANSPORTADOR_INTERNACIONAL_TRANSPORTE_ROOT_FIELD_NAME = 'atributosAdicionais.transporte.transportadorInternacional.pais';
const PLACA_VEICULO_TRANSPORTE_ROOT_FIELD_NAME = 'atributosAdicionais.transporte.placaVeiculo';
const IDENTIFICACAO_CRT_TRANSPORTE_ROOT_FIELD_NAME = 'atributosAdicionais.transporte.identificacaoCrt';
const CE_MERCANTE_TRANSPORTE_ROOT_FIELD_NAME = 'atributosAdicionais.transporte.ceMercante';
const CATEGORIA_TIPO_CONHECIMENTO_TRANSPORTE_ROOT_FIELD_NAME = 'atributosAdicionais.transporte.tipoConhecimento.categoriaTipoConhecimento';
const ID_HOUSE_TRANSPORTE_ROOT_FIELD_NAME = 'atributosAdicionais.transporte.idHouse';
const ID_MASTER_TRANSPORTE_ROOT_FIELD_NAME = 'atributosAdicionais.transporte.idMaster';
const CODIGO_BARRAS_TRANSPORTE_ROOT_FIELD_NAME = 'atributosAdicionais.transporte.codigoBarras';
const DATA_POSTAGEM_TRANSPORTE_ROOT_FIELD_NAME = 'atributosAdicionais.transporte.dataPostagem';
const PORTO_AEROPORTO_TRANSPORTE_ROOT_FIELD_NAME = 'atributosAdicionais.transporte.portoAeroporto';
const UTILIZACAO_TRANSPORTE_ROOT_FIELD_NAME = 'atributosAdicionais.transporte.utilizacao';
const DATA_EMBARQUE_TRANSPORTE_ROOT_FIELD_NAME = 'atributosAdicionais.transporte.dataEmbarque';

const CE_MERCANTE_VIA_TRANSPORTE_MARITIMA_VALUE = 'CEMERCANTE31032008';

const TRANSPORTE_ROOT_FIELD_NAMES = [
  VIA_TRANSPORTE_ROOT_FIELD_NAME,
  MULTIMODAL_TRANSPORTE_ROOT_FIELD_NAME,
  TIPO_DOCUMENTO_CHEGADA_CARGA_TRANSPORTE_ROOT_FIELD_NAME,
  IDENTIFICACAO_DOCUMENTO_CHEGADA_CARGA_TRANSPORTE_ROOT_FIELD_NAME,
  EMBARCACAO_TRANSPORTE_ROOT_FIELD_NAME,
  TRANSPORTADOR_INTERNACIONAL_TRANSPORTE_ROOT_FIELD_NAME,
  PAIS_TRANSPORTADOR_INTERNACIONAL_TRANSPORTE_ROOT_FIELD_NAME,
  PLACA_VEICULO_TRANSPORTE_ROOT_FIELD_NAME,
  IDENTIFICACAO_CRT_TRANSPORTE_ROOT_FIELD_NAME,
  CE_MERCANTE_TRANSPORTE_ROOT_FIELD_NAME,
  CATEGORIA_TIPO_CONHECIMENTO_TRANSPORTE_ROOT_FIELD_NAME,
  ID_HOUSE_TRANSPORTE_ROOT_FIELD_NAME,
  ID_MASTER_TRANSPORTE_ROOT_FIELD_NAME,
  CODIGO_BARRAS_TRANSPORTE_ROOT_FIELD_NAME,
  DATA_POSTAGEM_TRANSPORTE_ROOT_FIELD_NAME,
  PORTO_AEROPORTO_TRANSPORTE_ROOT_FIELD_NAME,
  UTILIZACAO_TRANSPORTE_ROOT_FIELD_NAME,
  DATA_EMBARQUE_TRANSPORTE_ROOT_FIELD_NAME,
];

export {
  VIA_TRANSPORTE_ROOT_FIELD_NAME,
  MULTIMODAL_TRANSPORTE_ROOT_FIELD_NAME,
  TIPO_DOCUMENTO_CHEGADA_CARGA_TRANSPORTE_ROOT_FIELD_NAME,
  IDENTIFICACAO_DOCUMENTO_CHEGADA_CARGA_TRANSPORTE_ROOT_FIELD_NAME,
  EMBARCACAO_TRANSPORTE_ROOT_FIELD_NAME,
  TRANSPORTADOR_INTERNACIONAL_TRANSPORTE_ROOT_FIELD_NAME,
  PAIS_TRANSPORTADOR_INTERNACIONAL_TRANSPORTE_ROOT_FIELD_NAME,
  PLACA_VEICULO_TRANSPORTE_ROOT_FIELD_NAME,
  IDENTIFICACAO_CRT_TRANSPORTE_ROOT_FIELD_NAME,
  CE_MERCANTE_TRANSPORTE_ROOT_FIELD_NAME,
  CATEGORIA_TIPO_CONHECIMENTO_TRANSPORTE_ROOT_FIELD_NAME,
  ID_HOUSE_TRANSPORTE_ROOT_FIELD_NAME,
  ID_MASTER_TRANSPORTE_ROOT_FIELD_NAME,
  CODIGO_BARRAS_TRANSPORTE_ROOT_FIELD_NAME,
  DATA_POSTAGEM_TRANSPORTE_ROOT_FIELD_NAME,
  PORTO_AEROPORTO_TRANSPORTE_ROOT_FIELD_NAME,
  UTILIZACAO_TRANSPORTE_ROOT_FIELD_NAME,
  DATA_EMBARQUE_TRANSPORTE_ROOT_FIELD_NAME,
  CE_MERCANTE_VIA_TRANSPORTE_MARITIMA_VALUE,
  TRANSPORTE_ROOT_FIELD_NAMES,
};
