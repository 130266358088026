import { useTheme } from "@material-ui/core";
import DeleteIcon from "@mui/icons-material/Delete";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import { Container, Typography } from "@mui/material";
import {
  GridActionsCellItem,
  GridCellParams,
  GridColDef,
} from "@mui/x-data-grid";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import DataTable from "../../../../common/components/dataTable";
import { useNormalizeClienteData } from "../../../../common/hooks/clientes/normalizeClientFormData";
import { useCreateCliente } from "../../../../common/hooks/clientes/useCreateClient";
import { QueriesTypes } from "../../../../common/hooks/queries";
import PageLayout from "../../../../common/layouts/pageLayout";
import { PropostaFollowUp } from "../../../../common/types/propostaFollowUp";
import { formatDateISOString } from "../../../../common/utils/parsers/dateParsers";
import { useGetPropostaById } from "../propostasDetails/propostasDetails.hook";
import NewClienteWizardForm from "./components/newClienteWizardForm";
import PropostasFollowUpForm from "./components/propostasFollowUpForm";
import { useDeleteFollowUp } from "./propostasFollowUp.hook";
import { useDispatch } from "react-redux";
import { ContactProfile } from "../../../../common/types/contactProfile";
import { useAddClientContacts } from "../../../../common/hooks/clientes/useAddClientContacts";

const PropostasFollowUpPage = () => {
  const params: { id: string } = useParams();
  const { id } = params;
  const [proposta, isLoading, hasError] = useGetPropostaById(id);
  const client = useQueryClient();

  const [editingPayload, setEditingPayload] = useState<PropostaFollowUp>();

  const isNewCliente = useMemo(() => !proposta.clienteId, [proposta.clienteId]);

  const [deleteFollowUp] = useDeleteFollowUp(() => {
    client.invalidateQueries(QueriesTypes.Propostas);
  });

  const handleDeleteFollowUp = (id: string) => {
    deleteFollowUp({ id, propostaId: params.id });
  };

  const handleFollowUpEdition = ({ row }: GridCellParams) => {
    const { data, dataAlerta, status, comentario, id } = row;

    const payload: PropostaFollowUp = {
      id,
      data: new Date(data).toISOString().slice(0, 10),
      status: status,
      dataAlerta: dataAlerta ? new Date(data).toISOString().slice(0, 10) : null,
      comentario: comentario,
    };
    setEditingPayload({ ...payload });
  };

  const clearEditingCallback = () => {
    setEditingPayload(undefined);
  };

  const theme = useTheme();

  const [shouldShowRegisteForm, setShouldShowRegisteForm] = useState(false);

  const columns: GridColDef[] = [
    {
      field: "data",
      headerName: "Data",
      flex: 1,
      headerClassName: "header-theme",
      type: "string",
      valueGetter: ({ value }) =>
        value && formatDateISOString(value.toString(), "YYYY-MM-DD"),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      headerClassName: "header-theme",
    },
    {
      field: "dataAlerta",
      headerName: "Alerta",
      flex: 1,
      headerClassName: "header-theme",
      valueGetter: ({ value }) =>
        value
          ? formatDateISOString(value.toString(), "YYYY-MM-DD")
          : "Não notificar",
    },
    {
      field: "comentario",
      headerName: "Comentário",
      flex: 2,
      headerClassName: "header-theme",
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Ações",
      headerClassName: "header-theme",
      // @ts-ignore
      getActions: (params: any) => [
        <GridActionsCellItem
          icon={<DeleteIcon color="error" />}
          label="Excluir"
          onClick={() => handleDeleteFollowUp(params.row.id)}
        />,
      ],
    },
  ];

  const [normalizeClientData] = useNormalizeClienteData();

  const [createCliente, isCreatingCliente, hasErrorOnClienteCreate] =
    useCreateCliente(id, () => {});

  const [addContatos, isAddingContatos, hasErrorOnAddContatos] =
    useAddClientContacts();

  const [savedCliente, setSavedCliente] = useState({});

  function handleClienteSubmit(
    values: any,
    currentStep: number,
    next: () => void
  ) {
    const cliente = normalizeClientData(values);
    setSavedCliente(cliente);
    if (currentStep === 0) {
      createCliente({ requestPayload: cliente, queryParams: { step: 1 } });
      next();
    }
  }

  const [contatosAdicionais, setContatosAdicionais] =
    useState<ContactProfile[] | undefined>();

  useEffect(() => {
    setContatosAdicionais(proposta.contatosAdicionais);
  }, [proposta.contatosAdicionais]);

  function handleRemoveContato(id: number) {
    const filtred =
      contatosAdicionais?.filter((contato) => contato.id !== id) ?? [];

    setContatosAdicionais([...filtred]);
  }

  function handleAddContact(newContact: ContactProfile) {
    setContatosAdicionais([...(contatosAdicionais ?? []), newContact]);
  }

  function handleSubmitContatos() {
    addContatos({
      requestPayload: { ...savedCliente, contatos: contatosAdicionais },
      queryParams: { step: 2 },
    });
    setShouldShowRegisteForm(false);
  }

  return (
    <PageLayout
      title={"Follow-ups"}
      icon={<HistoryEduIcon color={"secondary"} />}
    >
      <PropostasFollowUpForm
        clearEditingCallback={clearEditingCallback}
        newClientCallback={(value) => setShouldShowRegisteForm(value)}
        editingPayload={editingPayload}
        isNewCliente={isNewCliente}
      ></PropostasFollowUpForm>

      {shouldShowRegisteForm ? (
        <Container style={{ display: "flex", padding: "0 1rem 1rem 1rem" }}>
          <NewClienteWizardForm
            requiredRoles={["cliente"]}
            handleSubmit={handleClienteSubmit}
            handleAlternativeSave={undefined}
            model={{
              pessoa: { nome: proposta.nomeCliente },
            }}
            handleChangeModel={(values: any) => {}}
            handleChangeToCreate={undefined}
            handleChangeToConsult={undefined}
            handleChangeToUpdate={undefined}
            handleCancelUpdate={undefined}
            handleChangeToBackgroundCreate={undefined}
            handleResetBackgroundMode={undefined}
            addCallback={handleAddContact}
            removeCallback={handleRemoveContato}
            initialValues={contatosAdicionais}
            submitContatos={handleSubmitContatos}
            isConsult={undefined}
            isUpdate={undefined}
            isBackgroundCreate={undefined}
            authInfo={{}}
          />
        </Container>
      ) : (
        <></>
      )}

      <Typography
        textAlign={`left`}
        fontSize={20}
        fontWeight="bold"
        color={theme.palette.primary.main}
        margin={"40px 0 10px 0"}
      >
        {"Consultar Follow-ups".toUpperCase()}
      </Typography>
      {isLoading || hasError ? (
        <div></div>
      ) : (
        <DataTable
          onCellDoubleClick={(params) => handleFollowUpEdition(params)}
          columns={columns}
          rows={proposta.followUps.map((followUp) => ({
            ...followUp,
          }))}
        ></DataTable>
      )}
    </PageLayout>
  );
};

export default PropostasFollowUpPage;
