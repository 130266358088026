/* eslint-disable @typescript-eslint/no-unused-vars */
import { createFilterOptions } from '@material-ui/lab/useAutocomplete';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllAsync, selectServicos, selectConfigControl } from '../../features/servico/servicoSlice';
import { isAutoLoadControl } from '../../utils/store/store-utils';
import QCXAutocompleteSelect from '../autocomplete-select/QCXAutocompleteSelect';

export default function QCXSelectServicoAutocomplete({ label, name, initialValues, produto, ...restProps }) {
  const dispatch = useDispatch();

  let list = useSelector(selectServicos);

  if (produto) {
    list = list.filter((item) => item?.produto === produto);
  }

  const configControl = useSelector(selectConfigControl);

  useEffect(() => {
    if (isAutoLoadControl(configControl)) {
      dispatch(fetchAllAsync());
    }
  }, [configControl]);

  const [defaultValue, setDefaultValue] = useState('');

  useEffect(() => {
    const value = list.find((item) => item?.id === _.get(initialValues, name));
    if (value) {
      setDefaultValue(value);
    } else {
      setDefaultValue({ id: null, description: '' });
    }
  }, [initialValues]);

  const filterOptions = createFilterOptions({
    stringify: (item) => `${item?.id} - ${item?.sigla} - ${item?.nome}`,
  });

  return (
    <QCXAutocompleteSelect
      label={label}
      id={name}
      name={name}
      options={list}
      filterOptions={filterOptions}
      defaultValue={defaultValue}
      getOptionLabel={(item) =>
        item?.code && item?.sigla && item?.nome ? `${item?.id} - ${item?.sigla} - ${item?.nome}` : ''
      }
      {...restProps}
    />
  );
}
