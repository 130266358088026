/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
import { Grid, makeStyles } from '@material-ui/core';
import React, { useCallback, useMemo } from 'react';
import { useFormState } from 'react-final-form';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import QCXFormSubtitle from '../../components/form-title/QCXFormSubtitle';
import { selectRegimesTributacao } from '../../features/regime-tributacao/regimeTributacaoSlice';
import QCXFinalPercentualField from '../final-percentual-field/QCXFinalPercentualField';
import QCXSelectRegimeTributacaoAutocomplete from '../select-regime-tributacao/QCXSelectRegimeTributacaoAutocomplete';
import {
  REGIME_TRIBUTACAO_ISENCAO_CODE,
  REGIME_TRIBUTACAO_REDUCAO_CODE,
  REGIME_TRIBUTACAO_SUSPENSAO_CODE,
  REGIME_TRIBUTACAO_IMUNIDADE_CODE,
  REGIME_TRIBUTACAO_RECOLHIMENTO_INTEGRAL_CODE,
  configureGeneralRegimeTributacaoMatcherWith,
} from '../select-regime-tributacao/regimeTributacaoUtils';
import QCXFinalTextField from '../final-text-field/QCXFinalTextField';
import QCXSelectTipoAtoLegalAutocomplete from '../select-tipo-ato-legal/QCXSelectTipoAtoLegalAutocomplete';
import QCXSelectOrgaoEmissorAutocomplete from '../select-orgao-emissor/QCXSelectOrgaoEmissorAutocomplete';
import QCXFinalDatePickerField from '../final-date-picker-field/QCXFinalDatePickerField';
import QCXSelectTipoAcordoTarifarioAutocomplete from '../select-tipo-acordo-tarifario/QCXSelectTipoAcordoTarifarioAutocomplete';
import QCXFinalAlternativeCurrencyField from '../final-currency-field/QCXFinalAlternativeCurrencyField';
import QCXDemonstracaoCoeficienteReducaoFormGroup from './QCXDemonstracaoCoeficienteReducaoFormGroup';
import {
  configureGeneralTipoDeclaracaoMatcherWith,
  TIPO_DECLARACAO_ADMISSAO_EM_ALC_AREA_DE_LIVRE_COMERCIO,
  TIPO_DECLARACAO_ADMISSAO_EM_DAD_DEPOSITO_ADUANEIRO_DE_DISTRIBUICAO,
  TIPO_DECLARACAO_ADMISSAO_EM_DE_DAF,
  TIPO_DECLARACAO_ADMISSAO_EM_EIZOF_ENTREPOSTO_INTERNACIONAL_DA_ZFM,
  TIPO_DECLARACAO_ADMISSAO_EM_ENTREPOSTO_ADUANEIRO,
  TIPO_DECLARACAO_ADMISSAO_EM_ENTREPOSTO_INDUSTRIAL,
  TIPO_DECLARACAO_ADMISSAO_EM_LOJA_FRANCA,
  TIPO_DECLARACAO_ADMISSAO_EM_ZPE_ZONA_DE_PROCESSAMENTO_DE_EXPORTACAO,
  TIPO_DECLARACAO_ADMISSAO_NA_ZFM_ZONA_FRANCA_DE_MANAUS,
  TIPO_DECLARACAO_ADMISSAO_TEMPORARIA,
  TIPO_DECLARACAO_CONSUMO_E_ADMISSAO_TEMPORARIA,
  TIPO_DECLARACAO_INTERNACAO_DA_ZFM_PE_PRODUTO_ESTRANGEIRO,
  TIPO_DECLARACAO_INTERNACAO_DA_ZFM_PI_PRODUTO_INDUSTRIALIZADO,
} from '../select-tipo-declaracao/tipoDeclaracaoUtils';
import { selectTiposDeclaracao } from '../../features/tipo-declaracao/tipoDeclaracaoSlice';
import QCXSelectFundamentoLegalRegimeTributacaoIiAutocomplete from '../select-fundamento-legal-regime-tributacao-ii/QCXSelectFundamentoLegalRegimeTributacaoIiAutocomplete';
import QCXSelectMotivoAdmissaoTemporariaAutocomplete from '../select-motivo-admissao-temporaria/QCXSelectMotivoAdmissaoTemporariaAutocomplete';
import QCXSelectAcordoAladiAutocomplete from '../select-acordo-aladi/QCXSelectAcordoAladiAutocomplete';

const useStyles = makeStyles((theme) => ({
  gridFields: {
    paddingBottom: '16px',
  },
  twin: {
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '16px',
    },
  },
  lastGridFields: {
    [theme.breakpoints.down('xs')]: {
      paddingBottom: '16px',
    },
  },
}));

export default function QCXIIFormGroup({ intl, modes, submitSucceeded, parentModel }) {
  const { t } = useTranslation();

  const classes = useStyles();

  const { values } = useFormState();

  const regimesTributacao = useSelector(selectRegimesTributacao);
  const tiposDeclaracao = useSelector(selectTiposDeclaracao);

  const tipoDeclaracao = useMemo(() => parentModel?.atributosAdicionais?.tipoDeclaracao?.id, [parentModel]);

  const regimeTributacao = useMemo(() => values?.ii?.regimeTributacao?.id, [values]);

  const isSomeRegimeTributacaoBy = useCallback(
    (codesToCompare) =>
      configureGeneralRegimeTributacaoMatcherWith(regimesTributacao)(regimeTributacao, codesToCompare),
    [regimeTributacao, regimesTributacao]
  );

  const isSomeTipoDeclaracaoBy = useCallback(
    (codesToCompare) => configureGeneralTipoDeclaracaoMatcherWith(tiposDeclaracao)(tipoDeclaracao, codesToCompare),
    [tiposDeclaracao, tipoDeclaracao]
  );

  const configureRegimeTributacaoFilterPredicateWith = useCallback(() => {
    const regimeTributacaoFilters = (() => [
      REGIME_TRIBUTACAO_IMUNIDADE_CODE,
      REGIME_TRIBUTACAO_ISENCAO_CODE,
      REGIME_TRIBUTACAO_REDUCAO_CODE,
    ])();

    const regimeTributacaoFilterPredicate = (regime) => regimeTributacaoFilters.includes(regime?.code);

    return regimeTributacaoFilterPredicate;
  }, []);

  const { isConsult, isSubNone, isSubConsult } = modes;

  return (
    <>
      <Grid item xs={12} sm={6} md={6} lg={6}>
        <QCXSelectRegimeTributacaoAutocomplete
          id="select-field-regime-tributacao-ii"
          key="select-field-regime-tributacao-ii"
          name="ii.regimeTributacao.id"
          label={t('com.muralis.qcx.regimeTributacao')}
          disabled={isConsult || isSubConsult || isSubNone}
          initialValues={values}
          configureFilterPredicate={
            isSomeTipoDeclaracaoBy([
              TIPO_DECLARACAO_INTERNACAO_DA_ZFM_PI_PRODUTO_INDUSTRIALIZADO,
              TIPO_DECLARACAO_INTERNACAO_DA_ZFM_PE_PRODUTO_ESTRANGEIRO,
            ]) && configureRegimeTributacaoFilterPredicateWith
          }
        />
      </Grid>
      {!isSomeRegimeTributacaoBy([REGIME_TRIBUTACAO_RECOLHIMENTO_INTEGRAL_CODE]) && (
        <>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <QCXSelectFundamentoLegalRegimeTributacaoIiAutocomplete
              id="select-field-fundamento-legal-ii"
              key="select-field-fundamento-legal-ii"
              name="ii.fundamentoLegalRegimeTributacaoIi.id"
              label={t('com.muralis.qcx.legislacao.fundamentoLegal')}
              disabled={isConsult || isSubConsult || isSubNone}
              initialValues={values}
            />
          </Grid>
        </>
      )}
      {isSomeRegimeTributacaoBy([REGIME_TRIBUTACAO_SUSPENSAO_CODE]) &&
        isSomeTipoDeclaracaoBy([
          TIPO_DECLARACAO_ADMISSAO_EM_ALC_AREA_DE_LIVRE_COMERCIO,
          TIPO_DECLARACAO_ADMISSAO_EM_DAD_DEPOSITO_ADUANEIRO_DE_DISTRIBUICAO,
          TIPO_DECLARACAO_ADMISSAO_EM_EIZOF_ENTREPOSTO_INTERNACIONAL_DA_ZFM,
          TIPO_DECLARACAO_ADMISSAO_EM_ENTREPOSTO_ADUANEIRO,
          TIPO_DECLARACAO_CONSUMO_E_ADMISSAO_TEMPORARIA,
          TIPO_DECLARACAO_ADMISSAO_EM_DE_DAF,
          TIPO_DECLARACAO_ADMISSAO_EM_ENTREPOSTO_INDUSTRIAL,
          TIPO_DECLARACAO_ADMISSAO_EM_LOJA_FRANCA,
          TIPO_DECLARACAO_ADMISSAO_EM_ZPE_ZONA_DE_PROCESSAMENTO_DE_EXPORTACAO,
          TIPO_DECLARACAO_ADMISSAO_NA_ZFM_ZONA_FRANCA_DE_MANAUS,
          TIPO_DECLARACAO_ADMISSAO_TEMPORARIA,
        ]) && (
          <>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <QCXSelectMotivoAdmissaoTemporariaAutocomplete
                id="select-field-motivo-admissao-temporaria-ii"
                key="select-field-motivo-admissao-temporaria-ii"
                name="ii.motivoAdmissaoTemporaria.id"
                label={t('com.muralis.qcx.motivoAdmissaoTemporaria')}
                disabled={isConsult || isSubConsult || isSubNone}
                initialValues={values}
              />
            </Grid>
          </>
        )}

      {!isSomeTipoDeclaracaoBy([
        TIPO_DECLARACAO_INTERNACAO_DA_ZFM_PI_PRODUTO_INDUSTRIALIZADO,
        TIPO_DECLARACAO_INTERNACAO_DA_ZFM_PE_PRODUTO_ESTRANGEIRO,
      ]) && (
        <>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <QCXFormSubtitle title={t('com.muralis.qcx.exTarifario')} />
          </Grid>
          <Grid item container xs={12} sm={6} md={6} lg={6}>
            <Grid item className={classes.gridFields} xs={12} sm={12} md={12} lg={12}>
              <QCXFinalTextField
                id="text-field-numero-ex-tarifario-ii"
                key="text-field-numero-ex-tarifario-ii"
                name="ii.numero"
                label={t('com.muralis.qcx.numero')}
                disabled={isConsult || isSubConsult || isSubNone}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <QCXSelectOrgaoEmissorAutocomplete
                id="select-field-orgao-emissor-ex-tarifario-ii"
                key="select-field-orgao-emissor-ex-tarifario-ii"
                name="ii.orgaoEmissorAtoLegalExTarifario.id"
                label={t('com.muralis.qcx.orgao.orgaoEmissor')}
                disabled={isConsult || isSubConsult || isSubNone}
                initialValues={values}
              />
            </Grid>
          </Grid>
          <Grid item container xs={12} sm={6} md={6} lg={6}>
            <Grid item className={classes.gridFields} xs={12} sm={12} md={12} lg={12}>
              <QCXSelectTipoAtoLegalAutocomplete
                id="select-field-tipo-ato-legal-ex-tarifario-ii"
                key="select-field-tipo-ato-legal-ex-tarifario-ii"
                name="ii.tipoAtoLegalExTarifario.id"
                label={t('com.muralis.qcx.atoLegal')}
                disabled={isConsult || isSubConsult || isSubNone}
                initialValues={values}
              />
            </Grid>
            <Grid item className={classes.lastGridFields} xs={12} sm={6} md={6} lg={6}>
              <QCXFinalTextField
                id="text-field-numero-do-ato-ex-tarifario-ii"
                key="text-field-numero-do-ato-ex-tarifario-ii"
                name="ii.numeroAtoExTarifario"
                label={t('com.muralis.qcx.numeroAto')}
                disabled={isConsult || isSubConsult || isSubNone}
              />
            </Grid>
            <Grid item className={classes.twin} xs={12} sm={6} md={6} lg={6}>
              <QCXFinalDatePickerField
                id="date-picker-ano-ex-tarifario-field-ii"
                key="date-picker-ano-ex-tarifario-field-ii"
                name="ii.anoExTarifario"
                label={t('com.muralis.qcx.ano')}
                views={['year']}
                format="YYYY"
                placeholder="YYYY"
                disabled={isConsult || isSubConsult || isSubNone}
              />
            </Grid>
          </Grid>
        </>
      )}
      {isSomeRegimeTributacaoBy([
        REGIME_TRIBUTACAO_REDUCAO_CODE,
        REGIME_TRIBUTACAO_RECOLHIMENTO_INTEGRAL_CODE,
        REGIME_TRIBUTACAO_SUSPENSAO_CODE,
      ]) &&
        !isSomeTipoDeclaracaoBy([
          TIPO_DECLARACAO_INTERNACAO_DA_ZFM_PI_PRODUTO_INDUSTRIALIZADO,
          TIPO_DECLARACAO_INTERNACAO_DA_ZFM_PE_PRODUTO_ESTRANGEIRO,
        ]) && (
          <>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <QCXFormSubtitle title={t('com.muralis.qcx.tarifa.acordoTarifario')} />
            </Grid>
            <Grid item container xs={12} sm={6} md={6} lg={6}>
              <Grid item className={classes.gridFields} xs={12} sm={3} md={3} lg={3}>
                <QCXSelectTipoAcordoTarifarioAutocomplete
                  id="select-field-tipo-acordo-tarifario-ii"
                  key="select-field-tipo-acordo-tarifario-ii"
                  name="ii.tipoAcordoTarifario"
                  label={t('com.muralis.qcx.tipo')}
                  disabled={isConsult || isSubConsult || isSubNone}
                  initialValues={values}
                />
              </Grid>
              <Grid item className={classes.twin} xs={12} sm={9} md={9} lg={9}>
                <QCXSelectAcordoAladiAutocomplete
                  id="select-field-acordo-aladi"
                  key="select-field-acordo-aladi"
                  name="ii.acordoAladi"
                  label="Acordo Aladi"
                  disabled={isConsult || isSubConsult || isSubNone}
                  initialValues={values}
                />
              </Grid>
              <Grid item className={classes.lastGridFields} xs={12} sm={6} md={6} lg={6}>
                <QCXSelectOrgaoEmissorAutocomplete
                  id="select-field-orgao-emissor-acordo-tarifario-ii"
                  key="select-field-orgao-emissor-acordo-tarifario-ii"
                  name="ii.orgaoEmissorAtoLegalAcordoTarifario.id"
                  label={t('com.muralis.qcx.orgao.orgaoEmissor')}
                  disabled={isConsult || isSubConsult || isSubNone}
                  initialValues={values}
                />
              </Grid>
              <Grid item className={classes.twin} xs={12} sm={6} md={6} lg={6}>
                <QCXFinalTextField
                  id="text-field-numero-do-ato-acordo-tarifario-ii"
                  key="text-field-numero-do-ato-acordo-tarifario-ii"
                  name="ii.numeroAtoAcordoTarifario"
                  label={t('com.muralis.qcx.numeroAto')}
                  disabled={isConsult || isSubConsult || isSubNone}
                />
              </Grid>
            </Grid>
            <Grid item container xs={12} sm={6} md={6} lg={6}>
              <Grid item className={classes.gridFields} xs={12} sm={12} md={12} lg={12}>
                <QCXSelectTipoAtoLegalAutocomplete
                  id="select-field-tipo-ato-legal-acordo-tarifario-ii"
                  key="select-field-tipo-ato-legal-acordo-tarifario-ii"
                  name="ii.tipoAtoLegalAcordoTarifario.id"
                  label={t('com.muralis.qcx.atoLegal')}
                  disabled={isConsult || isSubConsult || isSubNone}
                  initialValues={values}
                />
              </Grid>
              <Grid item className={classes.lastGridFields} xs={12} sm={6} md={6} lg={6}>
                <QCXFinalDatePickerField
                  id="date-picker-ano-acordo-tarifario-ii"
                  key="date-picker-ano-acordo-tarifario-ii"
                  name="ii.anoAcordoTarifario"
                  label={t('com.muralis.qcx.ano')}
                  views={['year']}
                  format="YYYY"
                  placeholder="YYYY"
                  disabled={isConsult || isSubConsult || isSubNone}
                />
              </Grid>
              <Grid item className={classes.twin} xs={12} sm={6} md={6} lg={6}>
                <QCXFinalTextField
                  id="text-field-ex-acordo-tarifario-ii"
                  key="text-field-ex-acordo-tarifario-ii"
                  name="ii.ex"
                  label={t('com.muralis.qcx.ex')}
                  disabled={isConsult || isSubConsult || isSubNone}
                />
              </Grid>
            </Grid>
          </>
        )}
      <Grid item xs={12} sm={6} md={6} lg={6}>
        <QCXFinalAlternativeCurrencyField
          id="currency-valor-base-calculo-real-acordo-tarifario-field-ii"
          key="currency-valor-base-calculo-real-acordo-tarifario-field-ii"
          name="ii.baseCalculoReal"
          label={t('com.muralis.qcx.baseCalculoReais')}
          intl={intl}
          disabled
          submitSucceeded={submitSucceeded}
          adorned
          scale={7}
        />
      </Grid>
      {!isSomeTipoDeclaracaoBy([
        TIPO_DECLARACAO_INTERNACAO_DA_ZFM_PI_PRODUTO_INDUSTRIALIZADO,
        TIPO_DECLARACAO_INTERNACAO_DA_ZFM_PE_PRODUTO_ESTRANGEIRO,
      ]) && (
        <Grid
          item
          xs={values?.ii?.tipoAcordoTarifario ? 6 : 12}
          sm={values?.ii?.tipoAcordoTarifario ? 3 : 6}
          md={values?.ii?.tipoAcordoTarifario ? 3 : 6}
          lg={values?.ii?.tipoAcordoTarifario ? 3 : 6}
        >
          <QCXFinalPercentualField
            id="aliquota-ad-valorem-acordo-tarifario-field-ii"
            key="aliquota-ad-valorem-acordo-tarifario-field-ii"
            name="ii.aliquota"
            label={t('com.muralis.qcx.aliquotaADValorem')}
            disabled={isConsult || isSubConsult || isSubNone}
          />
        </Grid>
      )}
      {values?.ii?.tipoAcordoTarifario && (
        <Grid item xs={6} sm={3} md={3} lg={3}>
          <QCXFinalPercentualField
            id="aliquota-acordo-acordo-tarifario-field-ii"
            key="aliquota-acordo-acordo-tarifario-field-ii"
            name="ii.aliquotaAcordo"
            label={t('com.muralis.qcx.aliquotaAcordo')}
            disabled={isConsult || isSubConsult || isSubNone}
          />
        </Grid>
      )}
      <Grid item xs={12} sm={6} md={6} lg={6}>
        <QCXFinalAlternativeCurrencyField
          id="valor-devido-reais-tarifario-field-ii"
          key="valor-devido-reais-tarifario-field-ii"
          name="ii.valorDevidoReal"
          label={t('com.muralis.qcx.valorDevidoReais')}
          disabled
          adorned
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={6}>
        <QCXFinalAlternativeCurrencyField
          id="valor-recolher-reais-tarifario-field-ii"
          key="valor-recolher-tarifario-field-ii"
          name="ii.valorRecolherReal"
          label={t('com.muralis.qcx.valorRecolherReais')}
          disabled
          adorned
        />
      </Grid>
      {isSomeRegimeTributacaoBy([REGIME_TRIBUTACAO_SUSPENSAO_CODE]) &&
        isSomeTipoDeclaracaoBy([TIPO_DECLARACAO_CONSUMO_E_ADMISSAO_TEMPORARIA]) && (
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <QCXFinalAlternativeCurrencyField
              id="recolher-permanecia-field-ii"
              key="recolher-permanecia-field-ii"
              name="ii.recolherPermanencia"
              label={t('com.muralis.qcx.valorSuspensoReais')}
              disabled
              adorned
            />
          </Grid>
        )}
      {isSomeRegimeTributacaoBy([REGIME_TRIBUTACAO_REDUCAO_CODE, REGIME_TRIBUTACAO_SUSPENSAO_CODE]) &&
        !isSomeTipoDeclaracaoBy([
          TIPO_DECLARACAO_INTERNACAO_DA_ZFM_PI_PRODUTO_INDUSTRIALIZADO,
          TIPO_DECLARACAO_INTERNACAO_DA_ZFM_PE_PRODUTO_ESTRANGEIRO,
        ]) && (
          <>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <QCXFinalPercentualField
                id="percentual-redução-acordo-tarifario-field-ii"
                key="percentual-redução-acordo-tarifario-field-ii"
                name="ii.percentualReducao"
                label={t('com.muralis.qcx.percentualReducao')}
                disabled={isConsult || isSubConsult || isSubNone}
                scale={7}
              />
            </Grid>
          </>
        )}
      {isSomeRegimeTributacaoBy([REGIME_TRIBUTACAO_REDUCAO_CODE, REGIME_TRIBUTACAO_SUSPENSAO_CODE]) &&
        !isSomeTipoDeclaracaoBy([
          TIPO_DECLARACAO_INTERNACAO_DA_ZFM_PI_PRODUTO_INDUSTRIALIZADO,
          TIPO_DECLARACAO_INTERNACAO_DA_ZFM_PE_PRODUTO_ESTRANGEIRO,
        ]) && (
          <>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <QCXFinalPercentualField
                id="aliquota-reduzida-acordo-tarifario-field-ii"
                key="aliquota-reduzida-acordo-tarifario-field-ii"
                name="ii.aliquotaReduzida"
                label={t('com.muralis.qcx.aliquotaReduzida')}
                disabled={isConsult || isSubConsult || isSubNone}
                scale={7}
              />
            </Grid>
          </>
        )}
      {isSomeTipoDeclaracaoBy([
        TIPO_DECLARACAO_INTERNACAO_DA_ZFM_PI_PRODUTO_INDUSTRIALIZADO,
        TIPO_DECLARACAO_INTERNACAO_DA_ZFM_PE_PRODUTO_ESTRANGEIRO,
      ]) &&
        isSomeRegimeTributacaoBy([REGIME_TRIBUTACAO_ISENCAO_CODE, REGIME_TRIBUTACAO_REDUCAO_CODE]) && (
          <>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <QCXFormSubtitle title={t('com.muralis.qcx.demonstrativoCoeficienteReducao')} />
            </Grid>
            <QCXDemonstracaoCoeficienteReducaoFormGroup intl={intl} modes={modes} submitSucceeded={submitSucceeded} />
          </>
        )}
    </>
  );
}
