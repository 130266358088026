import React from 'react';
import { useTranslation } from 'react-i18next';
import { MediaType } from '../../utils/api/api-utils';
import QCXFinalCustomInputFile from './QCXFinalCustomInputFile';

export default function QCXFinalCertificateInputFile({ ...props }) {
  const { t } = useTranslation();

  const {
    id,
    key,
    label = t('com.muralis.qcx.certificado.label'),
    name,
    accept = MediaType.APPLICATION_X_PKCS12,
    tooltipTitle = t('com.muralis.qcx.certificado.carregarCertificado'),
    fileGetter,
    fileNameGetter,
    disableRemoveControl,
    disabled,
    ...restProps
  } = props;

  return (
    <QCXFinalCustomInputFile
      id={id}
      key={key}
      name={name}
      label={label}
      accept={accept}
      tooltipTitle={tooltipTitle}
      fileGetter={fileGetter}
      fileNameGetter={fileNameGetter}
      disableRemoveControl={disableRemoveControl}
      disabled={disabled}
      {...restProps}
    />
  );
}
