import { createSlice } from '@reduxjs/toolkit';
import {
  NONE_MODE,
  CREATE_MODE,
  CONSULT_MODE,
  UPDATE_MODE,
} from '../mode';
import {
  IDLE_STATUS,
  SUCCESS_STATUS,
  LOADING_STATUS,
  FAILURE_STATUS,
} from '../status';

const initialState = {
  status: IDLE_STATUS,
  mode: {
    main: NONE_MODE,
  },
  model: {
    tipoEmbalagem: {
      id: null,
    },
    quantidade: null,
    marcacao: '',
    numeracao: '',
  },
};

const danfeVolumesSlice = createSlice({
  name: 'danfeVolumes',
  initialState,
  reducers: {
    changeStatusTo: (state, action) => {
      state.status = action.payload.status;
    },
    loading: (state) => {
      state.status = LOADING_STATUS;
    },
    success: (state) => {
      state.status = SUCCESS_STATUS;
    },
    failure: (state) => {
      state.status = FAILURE_STATUS;
    },
    resetStatus: (state) => {
      state.status = IDLE_STATUS;
    },
    changeToCreateMode: (state) => {
      state.mode.main = CREATE_MODE;
    },
    changeToConsultMode: (state) => {
      state.mode.main = CONSULT_MODE;
    },
    changeToUpdateMode: (state) => {
      state.mode.main = UPDATE_MODE;
    },
    resetMode: (state) => {
      state.mode.main = NONE_MODE;
    },
    setModel: (state, action) => {
      state.model = action.payload;
    },
    resetModel: (state) => {
      state.model = initialState.model;
    },
  },
});

const {
  changeStatusTo,
  loading,
  success,
  failure,
  resetStatus,
  changeToConsultMode,
  changeToCreateMode,
  changeToUpdateMode,
  resetMode,
  setModel,
  resetModel,
} = danfeVolumesSlice.actions;

const danfeVolumesActions = {
  ...danfeVolumesSlice.actions,
};

const selectStatus = (state) => state.danfeVolumes.status;
const selectMode = (state) => state.danfeVolumes.mode.main;
const selectModel = (state) => state.danfeVolumes.model;

const danfeVolumesSelectors = {
  selectStatus,
  selectMode,
  selectModel,
};

export {
  danfeVolumesSlice,
  changeStatusTo,
  loading,
  success,
  failure,
  resetStatus,
  changeToConsultMode,
  changeToCreateMode,
  changeToUpdateMode,
  resetMode,
  setModel,
  resetModel,
  danfeVolumesActions,
  selectStatus,
  selectMode,
  selectModel,
  danfeVolumesSelectors,
};

export default danfeVolumesSlice.reducer;
