import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.secondary.main,
  },
}));

export default function QCXBackdrop({
  open,
  children,
  ...restProps
}) {
  const classes = useStyles();

  return (
    <Backdrop
      className={classes.backdrop}
      open={open}
      {...restProps}
    >
      {children}
    </Backdrop>
  );
}
