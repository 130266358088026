import { Grid } from '@material-ui/core';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import QCXFinalForm from '../../shared-components/final-form/QCXFinalForm';
import QCXFinalNumericIntegerField from '../../shared-components/final-numeric-integer-field/QCXFinalNumericIntegerField';
import QCXFinalTextField from '../../shared-components/final-text-field/QCXFinalTextField';
import FormHeaderMedium from '../../ts/common/components/forms/formHeaderMedium';
import useValidators from '../../utils/hooks/useValidators';
import { required as requiredValidator, validUnidadeDeMedidaCode } from '../../utils/validators/field/validator';

export default function QCXUnidadeDeMedidaForm({
  isConsult,
  isUpdate,
  initialValues,
  handleSubmit,
  handleChangeToUpdate,
  handleCancelUpdate,
  listProps = {
    rootName: 'aliases',
  },
  ...restProps
}) {
  const { t } = useTranslation();

  const codeValidators = useMemo(() => useValidators([requiredValidator, validUnidadeDeMedidaCode]), []);

  return (
    <QCXFinalForm
      isConsult={isConsult}
      isUpdate={isUpdate}
      initialValues={initialValues}
      handleSubmitValues={handleSubmit}
      handleChangeToUpdate={handleChangeToUpdate}
      handleCancelUpdate={handleCancelUpdate}
      {...restProps}
    >
      <Grid item xs={12} sm={8} md={2}>
        <QCXFinalNumericIntegerField
          id="text-field-code"
          key="text-field-code"
          name="code"
          label={t('com.muralis.qcx.codigo')}
          allowNegative={false}
          maxLength={3}
          validate={codeValidators}
          disabled={isConsult || isUpdate}
          required
        />
      </Grid>
      <Grid item xs={12} sm={8} md={7}>
        <QCXFinalTextField
          name="description"
          label={t('com.muralis.qcx.descricao')}
          maxLength={40}
          disabled={isConsult}
          validate={requiredValidator}
          required
        />
      </Grid>
      <Grid item xs={12} sm={8} md={3}>
        <QCXFinalTextField name="sigla" label={t('com.muralis.qcx.sigla')} maxLength={10} disabled={isConsult} />
      </Grid>
      <FormHeaderMedium>Apelidos</FormHeaderMedium>
      <Grid item xs={12} md={8}>
        <QCXFinalTextField
          id="text-field-description"
          key="text-field-description"
          name="apelido.description"
          label={t('com.muralis.qcx.descricao')}
          required={false}
          disabled={isConsult}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <QCXFinalTextField
          id="text-field-sigla"
          key="text-field-sigla"
          name="apelido.sigla"
          label={t('com.muralis.qcx.sigla')}
          required={false}
          disabled={isConsult}
        />
      </Grid>
    </QCXFinalForm>
  );
}
