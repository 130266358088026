import { createAsyncThunk } from '@reduxjs/toolkit';
import { DEFAULT_TIPO_DI_DUIMP_PARAMETER } from '../../../../../utils/general/fatura/faturaUtils';
import * as custeiosAPI from './custeiosAPI';
import { faturaAPI } from '../../../../../features/fatura/faturaAPI';
import { followUpAPI } from '../../../../../features/follow-up/followUpAPI';

const fetchAllFromFaturaAsync = createAsyncThunk('custeios/fetchAllFromFatura', async () => {
  const { data } = await custeiosAPI.fetchByFilter([
    // DEFAULT_TIPO_DI_DUIMP_PARAMETER,
  ]);
  return { data };
});

const fetchAllAsync = createAsyncThunk('custeios/fetchAll', async () => {
  const { data } = await custeiosAPI.fetchAll();
  return { data };
});

const fetchByIdAsync = createAsyncThunk('custeios/fetchById', async (data: number | string) => {
  const response = await custeiosAPI.fetchById(data);
  let faturaResponse;
  if (response.status == 200) {
    faturaResponse = await faturaAPI.fetchById(response?.data?.fatura?.id);
  }
  return {
    response: {
      status: response.status,
      data: { ...response.data, fatura: faturaResponse.data },
    },
  };
});

const activateByIdAsync = createAsyncThunk('custeios/activateById', async (id: number | string) => {
  const { status, data } = await custeiosAPI.activateById(id);
  return { response: { status, data } };
});

const inactivateByIdAsync = createAsyncThunk('custeios/inactivateById', async (id: number | string) => {
  const { status, data } = await custeiosAPI.inactivateById(id);
  return { response: { status, data } };
});

const custeiosThunks = {
  fetchAllFromFaturaAsync,
  fetchByIdAsync,
  fetchAllAsync,
  activateByIdAsync,
  inactivateByIdAsync,
};

export {
  custeiosThunks,
  fetchAllFromFaturaAsync,
  fetchByIdAsync,
  fetchAllAsync,
  activateByIdAsync,
  inactivateByIdAsync,
};
