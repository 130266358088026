import React from 'react';
import { Field } from 'react-final-form';

export default function QCXFinalField({
  name,
  component,
  render,
  children,
  validate,
  format,
  parse,
  ...restProps
}) {
  return (
    <>
      {component && (
        <Field
          name={name}
          component={component}
          validate={validate}
          format={format}
          parse={parse}
          {...restProps}
        />
      )}
      {render && (
        <Field
          name={name}
          render={render}
          validate={validate}
          format={format}
          parse={parse}
          {...restProps}
        />
      )}
      {children && typeof children === 'function' && (
        <Field
          name={name}
          validate={validate}
          format={format}
          parse={parse}
          {...restProps}
        >
          {children}
        </Field>
      )}
    </>
  );
}

export function checkErrorOnChange({
  meta: {
    submitError,
    dirtySinceLastSubmit,
    error,
    touched,
    modified,
  },
}) {
  return !!(((submitError && !dirtySinceLastSubmit) || error) && (touched || modified));
}
