import { createFilterOptions } from '@material-ui/lab/useAutocomplete';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import QCXAutocompleteSelect from '../autocomplete-select/QCXAutocompleteSelect';

export default function QCXSelectFornecedorAutocomplete({
  label,
  name,
  initialValues,
  filterPredicate,
  ...restProps
}) {
  const list = [];
  const [defaultValue, setDefaultValue] = useState(null);

  useEffect(() => {
    const value = list.find((item) => item.id === _.get(initialValues, name));
    if (value) {
      setDefaultValue(value);
    } else {
      setDefaultValue(null);
    }
  }, [list, initialValues, name]);

  const filterOptions = createFilterOptions({
    stringify: (item) => `${item?.id} - ${
      item
      || item
      || ''
    }`,
  });

  return (
    <QCXAutocompleteSelect
      label={label}
      id={name}
      name={name}
      options={[]}
      filterOptions={filterOptions}
      defaultValue={defaultValue}
      getOptionLabel={(item) => (item?.id ? `${item?.id} - ${
        item
        || item
        || ''
      }` : '')}
      {...restProps}
    />
  );
}
