import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DespesaReceitaContext from '../../../../../contexts/registrations/despesa-receita/DespesaReceitaContext';
import {
  clearError,
  clearResponse,
  resetStatus,
  selectError,
  selectResponse,
  selectStatus,
} from '../../../../../features/despesa-receita/despesaReceitaSlice';
import QCXPageTemplate from '../../../../../templates/page/QCXPageTemplate';
import { isFailureStatus, isLoadingStatus, isSuccessStatus } from '../../../../../utils/store/store-utils';

export default function DespesaReceitaPage({ children }) {
  const dispatch = useDispatch();

  const status = useSelector(selectStatus);
  const error = useSelector(selectError);
  const response = useSelector(selectResponse);

  const isLoading = useMemo(() => (
    isLoadingStatus(status)
  ), [status]);

  const [successSnackbar, setSuccessSnackbar] = useState({
    open: false,
    message: '',
  });

  const [errorSnackbar, setErrorSnackbar] = useState({
    open: false,
    message: '',
  });

  const handleOpenSuccessSnackbar = useCallback((message) => {
    setSuccessSnackbar({
      open: true,
      message,
    });
  }, []);

  const handleOpenErrorSnackbar = useCallback((message) => {
    setErrorSnackbar({
      open: true,
      message,
    });
  }, []);

  const handleCloseSuccessSnackbar = useCallback(() => {
    setSuccessSnackbar({
      ...successSnackbar,
      open: false,
    });
  }, []);

  const handleCloseErrorSnackbar = useCallback(() => {
    setErrorSnackbar({
      ...errorSnackbar,
      open: false,
    });
  }, []);

  useEffect(() => {
    if (isFailureStatus(status) && error && error.message) {
      handleOpenErrorSnackbar(error.message);
      dispatch(resetStatus());
      dispatch(clearError());
    } else if (isSuccessStatus(status) && response && response.message) {
      handleOpenSuccessSnackbar(response.message);
      dispatch(resetStatus());
      dispatch(clearResponse());
    }
  }, [response, status, error]);

  const providerData = useMemo(() => ({
    status,
    isLoading,
    successSnackbar,
    errorSnackbar,
    handleCloseSuccessSnackbar,
    handleCloseErrorSnackbar,
  }), [
    status,
    isLoading,
    successSnackbar,
    errorSnackbar,
    handleCloseSuccessSnackbar,
    handleCloseErrorSnackbar,
  ]);

  return (
    <DespesaReceitaContext.Provider
      value={providerData}
    >
      <QCXPageTemplate
        contextComponent={DespesaReceitaContext}
      >
        {children}
      </QCXPageTemplate>
    </DespesaReceitaContext.Provider>
  );
}
