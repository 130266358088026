import React from 'react';

const NegativeLogoOnlyIcon = (props) => (
  <svg viewBox="26.522 25.971 178.14 138.08" {...props}>
    <defs>
      <style type="text/css">
        {
          '\n\t.st0{fill:#312783;}\n\t.st1{fill:#F39200;}\n'
        }
      </style>
    </defs>
    <g transform="matrix(1, 0, 0, 1, -33.877724, -199.809433)">
      <path
        className="st0"
        d="M211.07,284.68v-37.35c0-11.88-9.62-21.55-21.49-21.55H81.95c-11.88,0-21.55,9.67-21.55,21.55v75.3 c0,11.88,9.67,21.55,21.55,21.55h51.07l-26.91-21.55H81.95v-75.3h107.63v20.11L211.07,284.68z M211.07,284.68"
        style={{
          fill: 'rgb(255, 255, 255)',
        }}
      />
      <path
        className="st1"
        d="M183.28,304.6c-32.91,1.2-52.72-13.49-69.44-29.78c0,38.73,34.5,69.19,69.44,69.57v19.47l49.34-39.46 l-49.34-39.46V304.6z M183.28,304.6"
        style={{
          fill: 'rgb(255, 255, 255)',
        }}
      />
    </g>
  </svg>
);

NegativeLogoOnlyIcon.displayName = 'NegativeLogoOnlyIcon';
NegativeLogoOnlyIcon.muiName = 'SvgIcon';

export default NegativeLogoOnlyIcon;
