import { getOptionByValueInList } from '../../utils/general/general-utils';
import i18n from '../../i18n';

const ABERTURA_PROCESSO = 'ABERTURA_PROCESSO';
const CATALOGO_PRODUTO = 'CATALOGO_PRODUTO';
const CCT = 'CCT';
const DANFE_EMISSAO = 'DANFE_EMISSAO';
const DATA_FATURAMENTO = 'DATA_FATURAMENTO';
const DECLARACAO_ICMS = 'DECLARACAO_ICMS';
const DESEMBARACO = 'DESEMBARACO';
const DI_CONSULTA = 'DI_CONSULTA';
const DI_PARAMETRIZACAO = 'DI_PARAMETRIZACAO';
const DI_REGISTRO = 'DI_REGISTRO';
const DI_RETIFICACAO = 'DI_RETIFICACAO';
const DUE_ACOMPANHAMENTO = 'DUE_ACOMPANHAMENTO';
const DUE_REGISTRO = 'DUE_REGISTRO';
const EMISSAO_ICMS = 'EMISSAO_ICMS';
const EMISSAO_NFSE = 'EMISSAO_NFSE';
const ENTREGA_MERCADORIA = 'ENTREGA_MERCADORIA';
const HISTORICO_CLIENTE = 'HISTORICO_CLIENTE';
const LI_ACOMPANHAMENTO = 'LI_ACOMPANHAMENTO';
const LI_EXTRATO = 'LI_EXTRATO';
const LI_RECUPERACAO = 'LI_RECUPERACAO';
const LI_REGISTRO = 'LI_REGISTRO';
const SISCARGA_MANTRA = 'SISCARGA_MANTRA';

// Eventos relacionados à DUE --------------------------------------------------
const AGUARDANDO_VINCULACAO_ATO_CONCESSORIO_DRAWBACK_ISENCAO = 'AGUARDANDO_VINCULACAO_ATO_CONCESSORIO_DRAWBACK_ISENCAO';
const ALTERACAO_DA_UNIDADE_RFB = 'ALTERACAO_DA_UNIDADE_RFB';
const ALTERACAO_DUE = 'ALTERACAO_DUE';
const BLOQUEIO_EMBARQUE = 'BLOQUEIO_EMBARQUE';
const CARGA_APRESENTADA_PARA_DESPACHO = 'CARGA_APRESENTADA_PARA_DESPACHO';
const CONCLUSAO_ANALISE_FISCAL = 'CONCLUSAO_ANALISE_FISCAL';
const CONCLUSAO_DA_CONFERENCIA_ADUANA = 'CONCLUSAO_DA_CONFERENCIA_ADUANA';
const DEFERIMENTO_DE_LPCO = 'DEFERIMENTO_DE_LPCO';
const DEFERIMENTO_SOLICITACAO_RETIFICACAO = 'DEFERIMENTO_SOLICITACAO_RETIFICACAO';
const DESBLOQUEIO_EMBARQUE = 'DESBLOQUEIO_EMBARQUE';
const DESVINCULACAO_ATO_CONCESSORIO_DRAWBACK_ISENCAO = 'DESVINCULACAO_ATO_CONCESSORIO_DRAWBACK_ISENCAO';
const DISTRIBUICAO = 'DISTRIBUICAO';
const EM_ANALISE_FISCAL = 'EM_ANALISE_FISCAL';
const EMBARQUE_ANTECIPADO_COM_PENDENCIA_LPCO = 'EMBARQUE_ANTECIPADO_COM_PENDENCIA_LPCO';
const EMBARQUE_ANTECIPADO_PENDENTE_DE_AUTORIZACAO = 'EMBARQUE_ANTECIPADO_PENDENTE_DE_AUTORIZACAO';
const EXIGENCIA_FISCAL_LIBERADA = 'EXIGENCIA_FISCAL_LIBERADA';
const EXIGENCIA_FISCAL_REGISTRADA = 'EXIGENCIA_FISCAL_REGISTRADA';
const INCLUSAO_DE_EXIGENCIA_FISCAL_POS_DESEMBARACO = 'INCLUSAO_DE_EXIGENCIA_FISCAL_POS_DESEMBARACO';
const INDEFERIMENTO_SOLICITACAO_CANCELAMENTO = 'INDEFERIMENTO_SOLICITACAO_CANCELAMENTO';
const INDEFERIMENTO_SOLICITACAO_RETIFICACAO = 'INDEFERIMENTO_SOLICITACAO_RETIFICACAO';
const INTERROMPIDA = 'INTERROMPIDA';
const LIBERACAO_DE_EXIGENCIA_FISCAL_POS_DESEMBARACO = 'LIBERACAO_DE_EXIGENCIA_FISCAL_POS_DESEMBARACO';
const LIBERADA_SEM_CONFERENCIA_ADUANEIRA = 'LIBERADA_SEM_CONFERENCIA_ADUANEIRA';
const MOVIMENTACAO_EQUIPE_ANALISE = 'MOVIMENTACAO_EQUIPE_ANALISE';
const REDISTRIBUICAO = 'REDISTRIBUICAO';
const REGISTRADA = 'REGISTRADA';
const RESULTADO_VINCULACAO_ATO_CONCESSORIO_DRAWBACK_ISENCAO = 'RESULTADO_VINCULACAO_ATO_CONCESSORIO_DRAWBACK_ISENCAO';
const SELECAO_DA_SOLICITACAO_DE_CANCELAMENTO_PARA_ANALISE_FISCAL = 'SELECAO_DA_SOLICITACAO_DE_CANCELAMENTO_PARA_ANALISE_FISCAL';
const SELECAO_DA_SOLICITACAO_DE_RETIFICACAO_PARA_ANALISE_FISCAL = 'SELECAO_DA_SOLICITACAO_DE_RETIFICACAO_PARA_ANALISE_FISCAL';
const SELECIONADA_PARA_FISCALIZACAO = 'SELECIONADA_PARA_FISCALIZACAO';
const SOLICITACAO_ANALISE = 'SOLICITACAO_ANALISE';
const SOLICITACAO_CANCELAMENTO = 'SOLICITACAO_CANCELAMENTO';
const SOLICITACAO_DISTRIBUICAO = 'SOLICITACAO_DISTRIBUICAO';
const SOLICITACAO_REDISTRIBUICAO = 'SOLICITACAO_REDISTRIBUICAO';
const SOLICITACAO_RETIFICACAO = 'SOLICITACAO_RETIFICACAO';
// Eventos que sinalizam o fim da consulta da DUE:
const AVERBADA_SEM_DIVERGENCIA = 'AVERBADA_SEM_DIVERGENCIA';
const CANCELADA_PELA_ADUANA = 'CANCELADA_PELA_ADUANA';
const CANCELADA_PELA_ADUANA_A_PEDIDO_DO_EXPORTADOR = 'CANCELADA_PELA_ADUANA_A_PEDIDO_DO_EXPORTADOR';
const CANCELADA_PELO_EXPORTADOR = 'CANCELADA_PELO_EXPORTADOR';
const CANCELADA_POR_EXPIRACAO_DE_PRAZO = 'CANCELADA_POR_EXPIRACAO_DE_PRAZO';
const CANCELAMENTO_SOLICITACAO_CANCELAMENTO = 'CANCELAMENTO_SOLICITACAO_CANCELAMENTO';
const CANCELAMENTO_SOLICITACAO_RETIFICACAO = 'CANCELAMENTO_SOLICITACAO_RETIFICACAO';
const CARGA_COMPLETAMENTE_EXPORTADA = 'CARGA_COMPLETAMENTE_EXPORTADA';
const DEFERIMENTO_SOLICITACAO_CANCELAMENTO = 'DEFERIMENTO_SOLICITACAO_CANCELAMENTO';
const DESEMBARACADA = 'DESEMBARACADA';
const DESEMBARACADA_COM_PENDENCIA_LPCO = 'DESEMBARACADA_COM_PENDENCIA_LPCO';
const EMBARQUE_ANTECIPADO_AUTORIZADO = 'EMBARQUE_ANTECIPADO_AUTORIZADO';
// Fim dos eventos relacionados à DUE --------------------------------------------------

const EVENTO_ETAPA_LIST = [
  {
    label: i18n.t('com.muralis.qcx.aberturaDoProcesso'),
    value: ABERTURA_PROCESSO,
  },
  {
    label: i18n.t('com.muralis.qcx.licencaImportacao.acompanhamentoStatusLI'),
    value: LI_ACOMPANHAMENTO,
  },
  {
    label: i18n.t('com.muralis.qcx.dueExportacao.acompanhamentoDUE'),
    value: DUE_ACOMPANHAMENTO,
  },
  {
    label: i18n.t('com.muralis.qcx.catalogoProdutos.label'),
    value: CATALOGO_PRODUTO,
  },
  {
    label: i18n.t('com.muralis.qcx.consultaSituacaoCargasAereasCCT'),
    value: CCT,
  },
  {
    label: i18n.t('com.muralis.qcx.consultaSituacaoCargaAereaMaritima'),
    value: SISCARGA_MANTRA,
  },
  {
    label: i18n.t('com.muralis.qcx.emissaoDANFE'),
    value: DANFE_EMISSAO,
  },
  {
    label: i18n.t('com.muralis.qcx.imposto.emissaoICMS'),
    value: EMISSAO_ICMS,
  },
  {
    label: i18n.t('com.muralis.qcx.NFSE.emissaoNFSe'),
    value: EMISSAO_NFSE,
  },
  {
    label: 'Entrega de Mercadoria',
    value: ENTREGA_MERCADORIA,
  },
  {
    label: i18n.t('com.muralis.qcx.imposto.declaracaoICMS'),
    value: DECLARACAO_ICMS,
  },
  {
    label: 'Desembaraço',
    value: DESEMBARACO,
  },
  {
    label: i18n.t('com.muralis.qcx.parametrizacaoDIDUIMP'),
    value: DI_PARAMETRIZACAO,
  },
  {
    label: i18n.t('com.muralis.qcx.licencaImportacao.recuperacaoExtratoLI'),
    value: LI_EXTRATO,
  },
  {
    label: i18n.t('com.muralis.qcx.recuperacaoHistoricoCliente'),
    value: HISTORICO_CLIENTE,
  },
  {
    label: i18n.t('com.muralis.qcx.recuperacaoRegistroDIDUIMP'),
    value: DI_CONSULTA,
  },
  {
    label: i18n.t('com.muralis.qcx.licencaImportacao.recuperacaoRegistroLI'),
    value: LI_RECUPERACAO,
  },
  {
    label: i18n.t('com.muralis.qcx.registroDIDUIMP'),
    value: DI_REGISTRO,
  },
  {
    label: i18n.t('com.muralis.qcx.dueExportacao.registroDUE'),
    value: DUE_REGISTRO,
  },
  {
    label: i18n.t('com.muralis.qcx.licencaImportacao.registroLI'),
    value: LI_REGISTRO,
  },
  {
    label: i18n.t('com.muralis.qcx.retificacaoDIDUIMP'),
    value: DI_RETIFICACAO,
  },
  {
    label: 'Data de Faturamento',
    value: DATA_FATURAMENTO,
  },
  {
    label: 'DUE - Aguardando Vinculação Ato Concessório Drawback Isenção',
    value: AGUARDANDO_VINCULACAO_ATO_CONCESSORIO_DRAWBACK_ISENCAO,
  },
  {
    label: 'DUE - Alteração da Unidade RFB',
    value: ALTERACAO_DA_UNIDADE_RFB,
  },
  {
    label: 'DUE - Alteração DUE',
    value: ALTERACAO_DUE,
  },
  {
    label: 'DUE - Bloqueio de Embarque',
    value: BLOQUEIO_EMBARQUE,
  },
  {
    label: 'DUE - Carga Apresentada para Despacho',
    value: CARGA_APRESENTADA_PARA_DESPACHO,
  },
  {
    label: 'DUE - Conclusão Análise Fiscal',
    value: CONCLUSAO_ANALISE_FISCAL,
  },
  {
    label: 'DUE - Conclusão da Conferência Aduana',
    value: CONCLUSAO_DA_CONFERENCIA_ADUANA,
  },
  {
    label: 'DUE - Deferimento de LPCO',
    value: DEFERIMENTO_DE_LPCO,
  },
  {
    label: 'DUE - Deferimento Solicitação Retificação',
    value: DEFERIMENTO_SOLICITACAO_RETIFICACAO,
  },
  {
    label: 'DUE - Desbloqueio de Embarque',
    value: DESBLOQUEIO_EMBARQUE,
  },
  {
    label: 'DUE - Desvinculação Ato Concessório Drawback Isenção',
    value: DESVINCULACAO_ATO_CONCESSORIO_DRAWBACK_ISENCAO,
  },
  {
    label: 'DUE - Distribuição',
    value: DISTRIBUICAO,
  },
  {
    label: 'DUE - Em Análise Fiscal',
    value: EM_ANALISE_FISCAL,
  },
  {
    label: 'DUE - Embarque Antecipado com Pendência LPCO',
    value: EMBARQUE_ANTECIPADO_COM_PENDENCIA_LPCO,
  },
  {
    label: 'DUE - Embarque Antecipado Pendente de Autorização',
    value: EMBARQUE_ANTECIPADO_PENDENTE_DE_AUTORIZACAO,
  },
  {
    label: 'DUE - Exigência Fiscal Liberada',
    value: EXIGENCIA_FISCAL_LIBERADA,
  },
  {
    label: 'DUE - Exigência Fiscal Registrada',
    value: EXIGENCIA_FISCAL_REGISTRADA,
  },
  {
    label: 'DUE - Inclusão de Exigência Fiscal pós Desembaraço',
    value: INCLUSAO_DE_EXIGENCIA_FISCAL_POS_DESEMBARACO,
  },
  {
    label: 'DUE - Indeferimento Solicitação Cancelamento',
    value: INDEFERIMENTO_SOLICITACAO_CANCELAMENTO,
  },
  {
    label: 'DUE - Indeferimento Solicitação Retificação',
    value: INDEFERIMENTO_SOLICITACAO_RETIFICACAO,
  },
  {
    label: 'DUE - Interrompida',
    value: INTERROMPIDA,
  },
  {
    label: 'DUE - Liberação de Exigência Fiscal pós Desembaraço',
    value: LIBERACAO_DE_EXIGENCIA_FISCAL_POS_DESEMBARACO,
  },
  {
    label: 'DUE - Liberada sem Conferência Aduaneira',
    value: LIBERADA_SEM_CONFERENCIA_ADUANEIRA,
  },
  {
    label: 'DUE - Movimentação Equipe Análise',
    value: MOVIMENTACAO_EQUIPE_ANALISE,
  },
  {
    label: 'DUE - Redistribuição',
    value: REDISTRIBUICAO,
  },
  {
    label: 'DUE - Registrada',
    value: REGISTRADA,
  },
  {
    label: 'DUE - Resultado Vinculação Ato Concessório Drawback Isenção',
    value: RESULTADO_VINCULACAO_ATO_CONCESSORIO_DRAWBACK_ISENCAO,
  },
  {
    label: 'DUE - Seleção da Solicitação de Cancelamento para Análise Fiscal',
    value: SELECAO_DA_SOLICITACAO_DE_CANCELAMENTO_PARA_ANALISE_FISCAL,
  },
  {
    label: 'DUE - Seleção da Solicitação de Retificação para Análise Fiscal',
    value: SELECAO_DA_SOLICITACAO_DE_RETIFICACAO_PARA_ANALISE_FISCAL,
  },
  {
    label: 'DUE - Selecionada para Fiscalização',
    value: SELECIONADA_PARA_FISCALIZACAO,
  },
  {
    label: 'DUE - Solicitação Análise',
    value: SOLICITACAO_ANALISE,
  },
  {
    label: 'DUE - Solicitação Cancelamento',
    value: SOLICITACAO_CANCELAMENTO,
  },
  {
    label: 'DUE - Solicitação Distribuição',
    value: SOLICITACAO_DISTRIBUICAO,
  },
  {
    label: 'DUE - Solicitação Redistribuição',
    value: SOLICITACAO_REDISTRIBUICAO,
  },
  {
    label: 'DUE - Solicitação Retificação',
    value: SOLICITACAO_RETIFICACAO,
  },
  {
    label: 'DUE - Averbada sem Divergência',
    value: AVERBADA_SEM_DIVERGENCIA,
  },
  {
    label: 'DUE - Cancelada pela Aduana',
    value: CANCELADA_PELA_ADUANA,
  },
  {
    label: 'DUE - Cancelada pela Aduana a Pedido do Exportador',
    value: CANCELADA_PELA_ADUANA_A_PEDIDO_DO_EXPORTADOR,
  },
  {
    label: 'DUE - Cancelada pelo Exportador',
    value: CANCELADA_PELO_EXPORTADOR,
  },
  {
    label: 'DUE - Cancelada por Expiração de Prazo',
    value: CANCELADA_POR_EXPIRACAO_DE_PRAZO,
  },
  {
    label: 'DUE - Cancelamento Solicitação Cancelamento',
    value: CANCELAMENTO_SOLICITACAO_CANCELAMENTO,
  },
  {
    label: 'DUE - Cancelamento Solicitação Retificação',
    value: CANCELAMENTO_SOLICITACAO_RETIFICACAO,
  },
  {
    label: 'DUE - Carga Completamente Exportada',
    value: CARGA_COMPLETAMENTE_EXPORTADA,
  },
  {
    label: 'DUE - Deferimento Solicitação Cancelamento',
    value: DEFERIMENTO_SOLICITACAO_CANCELAMENTO,
  },
  {
    label: 'DUE - Desembaracada',
    value: DESEMBARACADA,
  },
  {
    label: 'DUE - Desembaracada com Pendência LPCO',
    value: DESEMBARACADA_COM_PENDENCIA_LPCO,
  },
  {
    label: 'DUE - Embarque Antecipado Autorizado',
    value: EMBARQUE_ANTECIPADO_AUTORIZADO,
  },
];

const getEventoEtapaLabelByValue = (value) => {
  const foundOption = getOptionByValueInList(EVENTO_ETAPA_LIST, value);
  return foundOption && foundOption?.label;
};

export {
  CATALOGO_PRODUTO,
  CCT,
  DANFE_EMISSAO,
  DATA_FATURAMENTO,
  DECLARACAO_ICMS,
  DI_CONSULTA,
  DI_PARAMETRIZACAO,
  DI_REGISTRO,
  DI_RETIFICACAO,
  DUE_ACOMPANHAMENTO,
  DUE_REGISTRO,
  EMISSAO_NFSE,
  ENTREGA_MERCADORIA,
  EVENTO_ETAPA_LIST,
  HISTORICO_CLIENTE,
  LI_ACOMPANHAMENTO,
  LI_EXTRATO,
  LI_RECUPERACAO,
  LI_REGISTRO,
  SISCARGA_MANTRA,
  AGUARDANDO_VINCULACAO_ATO_CONCESSORIO_DRAWBACK_ISENCAO,
  ALTERACAO_DA_UNIDADE_RFB,
  ALTERACAO_DUE,
  BLOQUEIO_EMBARQUE,
  CARGA_APRESENTADA_PARA_DESPACHO,
  CONCLUSAO_ANALISE_FISCAL,
  CONCLUSAO_DA_CONFERENCIA_ADUANA,
  DEFERIMENTO_DE_LPCO,
  DEFERIMENTO_SOLICITACAO_RETIFICACAO,
  DESBLOQUEIO_EMBARQUE,
  DESVINCULACAO_ATO_CONCESSORIO_DRAWBACK_ISENCAO,
  DISTRIBUICAO,
  EM_ANALISE_FISCAL,
  EMBARQUE_ANTECIPADO_COM_PENDENCIA_LPCO,
  EMBARQUE_ANTECIPADO_PENDENTE_DE_AUTORIZACAO,
  EXIGENCIA_FISCAL_LIBERADA,
  EXIGENCIA_FISCAL_REGISTRADA,
  INCLUSAO_DE_EXIGENCIA_FISCAL_POS_DESEMBARACO,
  INDEFERIMENTO_SOLICITACAO_CANCELAMENTO,
  INDEFERIMENTO_SOLICITACAO_RETIFICACAO,
  INTERROMPIDA,
  LIBERACAO_DE_EXIGENCIA_FISCAL_POS_DESEMBARACO,
  LIBERADA_SEM_CONFERENCIA_ADUANEIRA,
  MOVIMENTACAO_EQUIPE_ANALISE,
  REDISTRIBUICAO,
  REGISTRADA,
  RESULTADO_VINCULACAO_ATO_CONCESSORIO_DRAWBACK_ISENCAO,
  SELECAO_DA_SOLICITACAO_DE_CANCELAMENTO_PARA_ANALISE_FISCAL,
  SELECAO_DA_SOLICITACAO_DE_RETIFICACAO_PARA_ANALISE_FISCAL,
  SELECIONADA_PARA_FISCALIZACAO,
  SOLICITACAO_ANALISE,
  SOLICITACAO_CANCELAMENTO,
  SOLICITACAO_DISTRIBUICAO,
  SOLICITACAO_REDISTRIBUICAO,
  SOLICITACAO_RETIFICACAO,
  AVERBADA_SEM_DIVERGENCIA,
  CANCELADA_PELA_ADUANA,
  CANCELADA_PELA_ADUANA_A_PEDIDO_DO_EXPORTADOR,
  CANCELADA_PELO_EXPORTADOR,
  CANCELADA_POR_EXPIRACAO_DE_PRAZO,
  CANCELAMENTO_SOLICITACAO_CANCELAMENTO,
  CANCELAMENTO_SOLICITACAO_RETIFICACAO,
  CARGA_COMPLETAMENTE_EXPORTADA,
  DEFERIMENTO_SOLICITACAO_CANCELAMENTO,
  DESEMBARACADA,
  DESEMBARACADA_COM_PENDENCIA_LPCO,
  EMBARQUE_ANTECIPADO_AUTORIZADO,
};

export { getEventoEtapaLabelByValue };
