import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  activateById,
  fetchAll,
  fetchById,
  inactivateById,
  register,
  save,
} from './modeloDanfeAPI';

const fetchAllAsync = createAsyncThunk('modeloDanfe/fetchAll', async () => {
  const response = await fetchAll();
  return {
    response: {
      status: response.status,
      data: response.data,
    },
  };
});

const fetchByIdAsync = createAsyncThunk('modeloDanfe/fetchById', async (id) => {
  const response = await fetchById(id);
  return {
    response: {
      status: response.status,
      data: response.data,
    },
  };
});

const registerAsync = createAsyncThunk('modeloDanfe/register', async (data, queryParams) => {
  const response = await register(data, queryParams);
  return {
    response: {
      status: response.status,
      data: response.data,
    },
  };
});

const saveAsync = createAsyncThunk('modeloDanfe/save', async (data, queryParams) => {
  const response = await save(data, queryParams);
  return {
    response: {
      status: response.status,
      data: response.data,
    },
  };
});

const activateByIdAsync = createAsyncThunk('modeloDanfe/activateById', async (id) => {
  const { status, data } = await activateById(id);
  return { response: { status, data } };
});

const inactivateByIdAsync = createAsyncThunk(
  'modeloDanfe/inactivateById',
  async (id) => {
    const { status, data } = await inactivateById(id);
    return { response: { status, data } };
  }
);

export {
  fetchAllAsync,
  fetchByIdAsync,
  registerAsync,
  saveAsync,
  activateByIdAsync,
  inactivateByIdAsync,
};
