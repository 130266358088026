import Chip from '@material-ui/core/Chip';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { Field, FieldRenderProps, useForm, useFormState } from 'react-final-form';

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(0.5),
  },
  textField: {
    paddingBottom: theme.spacing(4), // Adjust spacing as per your requirement
  },
}));

interface Props {
  name: string;
  label: string;
}

const QCXFinalMultilineInputWithChips: React.FC<Props> = ({ name, label }) => {
  const classes = useStyles();

  const form = useForm();
  const { values } = useFormState();

  const handleBlur = (
    event: React.FocusEvent<HTMLInputElement>,
    inputOnBlur: (inputEvent: React.FocusEvent<HTMLInputElement>) => void,
    value: string
  ) => {
    if (!value) {
      return;
    }

    inputOnBlur(event); // calling final form's onBlur

    // use regex to separe words by comma or space
    const regex = /[, ]+/;
    const newValues = value.trim().split(regex);

    if (newValues.length > 0) {
      const oldValues = values[name] ?? [];
      form.change(name, [...oldValues, ...newValues]);
    }

    form.change(`${name}InputValue`, '');
  };

  const handleDelete = (chipToDelete: string) => () => {
    const newChips = (values[name] ?? [])?.filter((chip: string) => chip !== chipToDelete);
    form.change(name, newChips);
  };

  return (
    <>
      <Field
        name={`${name}InputValue`}
        render={({ input, meta }: FieldRenderProps<string, HTMLInputElement>) => (
          <TextField
            {...(input as Partial<TextFieldProps>)}
            label={label.toUpperCase()}
            variant="outlined"
            fullWidth
            error={Boolean(meta.touched && meta.error)}
            helperText={meta.touched ? meta.error : ''}
            onBlur={(event) => handleBlur(event as React.FocusEvent<HTMLInputElement>, input.onBlur, input.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {(values[name] ?? []).map((chip: string) => (
                    <Chip key={chip} label={chip} className={classes.chip} onDelete={handleDelete(chip)} size="small" />
                  ))}
                </InputAdornment>
              ),
            }}
            className={classes.textField}
          />
        )}
      />
    </>
  );
};

export default QCXFinalMultilineInputWithChips;
