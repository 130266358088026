import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FollowUpExportacaoContext from '../../../../contexts/export/follow-up/FollowUpExportacaoContext';
import followUpExportacaoSelectors from '../../../../features/follow-up-exportacao/followUpExportacaoSelectors';
import {
  followUpExportacaoActions,
} from '../../../../features/follow-up-exportacao/followUpExportacaoSlice';
import QCXPageTemplate from '../../../../templates/page/QCXPageTemplate';
import { isFailureStatus, isLoadingStatus, isSuccessStatus } from '../../../../utils/store/store-utils';

export default function FollowUpExportacaoPage({ children }) {
  const dispatch = useDispatch();

  const status = useSelector(
    followUpExportacaoSelectors.selectStatus
  );
  const error = useSelector(
    followUpExportacaoSelectors.selectError
  );
  const response = useSelector(
    followUpExportacaoSelectors.selectResponse
  );

  const modeSelectors = useMemo(() => ({
    selectMode: followUpExportacaoSelectors.selectMode,
    selectSubMode: followUpExportacaoSelectors.selectSubMode,
    selectBackgroundMode: followUpExportacaoSelectors.selectBackgroundMode,
  }), [followUpExportacaoSelectors]);

  const isLoading = useMemo(() => (
    isLoadingStatus(status)
  ), [status]);

  const [successSnackbar, setSuccessSnackbar] = useState({
    open: false,
    message: '',
  });

  const [errorSnackbar, setErrorSnackbar] = useState({
    open: false,
    message: '',
  });

  const handleOpenSuccessSnackbar = useCallback((message) => {
    setSuccessSnackbar({
      open: true,
      message,
    });
  }, []);

  const handleOpenErrorSnackbar = useCallback((message) => {
    setErrorSnackbar({
      open: true,
      message,
    });
  }, []);

  const handleCloseSuccessSnackbar = useCallback(() => {
    setSuccessSnackbar({
      ...successSnackbar,
      open: false,
    });
  }, []);

  const handleCloseErrorSnackbar = useCallback(() => {
    setErrorSnackbar({
      ...errorSnackbar,
      open: false,
    });
  }, []);

  useEffect(() => {
    const handleStatus = () => {
      if (isFailureStatus(status) && error && error.message) {
        handleOpenErrorSnackbar(error.message);
        dispatch(followUpExportacaoActions.resetStatus());
        dispatch(followUpExportacaoActions.clearError());

        return;
      }
      if (isSuccessStatus(status) && response && response.message) {
        handleOpenSuccessSnackbar(response.message);
        dispatch(followUpExportacaoActions.resetStatus());
        dispatch(followUpExportacaoActions.clearResponse());
      }
    };

    handleStatus();
  }, [response, status, error]);

  const providerData = useMemo(() => ({
    status,
    isLoading,
    successSnackbar,
    errorSnackbar,
    handleCloseSuccessSnackbar,
    handleCloseErrorSnackbar,
  }), [
    status,
    isLoading,
    successSnackbar,
    errorSnackbar,
  ]);

  return (
    <FollowUpExportacaoContext.Provider
      value={providerData}
    >
      <QCXPageTemplate
        contextComponent={FollowUpExportacaoContext}
        modeSelectors={modeSelectors}
      >
        {children}
      </QCXPageTemplate>
    </FollowUpExportacaoContext.Provider>
  );
}
