import { Typography } from '@material-ui/core';
import React from 'react';
import QCXBox from '../../shared-components/box/QCXBox';

export default function QCXFormSubtitle({
  title,
  boxProps = {},
  ...restProps
}) {
  return (
    <Typography
      variant="subtitle1"
      color="primary"
      {...restProps}
    >
      <QCXBox
        fontWeight="fontWeightBold"
        {...boxProps}
      >
        {String(title).toUpperCase()}
      </QCXBox>
    </Typography>
  );
}
