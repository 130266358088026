import PageLayout from "../../../../common/layouts/pageLayout";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import { Button, FormControl, Grid, TextField } from "@mui/material";
import FormHeader from "../../../../common/components/forms/formHeader";
import { useMemo, useState } from "react";
import FormHeaderMedium from "../../../../common/components/forms/formHeaderMedium";
import DataTable from "../../../../common/components/dataTable";
import { GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  useCreatePropostaMercadoria,
  useDeletePropostaMercadoria,
  useUpdatePropostaMercadoria,
} from "./propostasMercadorias.hook";
import { MercadoriaProposta } from "../common/mercadoriaProposta.type";
import LoadingIndicator from "../../../../common/components/loadingIndicator";
import { useQueryClient } from "react-query";
import { Edit } from "@material-ui/icons";
import { useListPropostaMercadoria } from "../common/hooks";

const PropostasMercadorias = () => {
  const [isEditing, setIsEditing] = useState(false);

  const [editingId, setEditingId] = useState(0);
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");

  const [createMercadoria, isCreating, hasCreateError] =
    useCreatePropostaMercadoria();
  const [deleteMercadoria, isDeleting, hasDeleteError] =
    useDeletePropostaMercadoria();
  const [updateMercadoria, isUpdating, hasUpdateError] =
    useUpdatePropostaMercadoria();

  const [mercadorias, isLoading, hasLoadError] = useListPropostaMercadoria();

  const buttonDisabled = useMemo(() => {
    return !name || !description;
  }, [name, description]);

  const handleMercadoriaAddition = () => {
    const payload: Omit<MercadoriaProposta, "id"> = {
      nome: name,
      descricao: description,
    };

    createMercadoria({ requestPayload: payload });

    setName("");
    setDescription("");
  };

  const handleSaveMercadoria = () => {
    const payload: MercadoriaProposta = {
      id: editingId,
      nome: name,
      descricao: description,
    };

    updateMercadoria(payload);
    setName("");
    setDescription("");
    setIsEditing(false);
    setEditingId(0);
  };

  const handleMercadoriaEdition = (id: number) => {
    const dataToUpdate: MercadoriaProposta = mercadorias.find(
      (mercadoria) => mercadoria.id === id
    )!;

    setEditingId(id);
    setName(dataToUpdate.nome);
    setDescription(dataToUpdate.descricao);
    setIsEditing(true);
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      flex: 1,
      headerClassName: "header-theme",
      type: "number",
    },
    {
      field: "mercadoria",
      headerName: "Mercadoria",
      flex: 4,
      headerClassName: "header-theme",
    },
    {
      field: "descricao",
      headerName: "Descrição",
      flex: 7,
      headerClassName: "header-theme",
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Ações",
      headerClassName: "header-theme",
      // @ts-ignore
      getActions: (params: any) => [
        <GridActionsCellItem
          icon={
            <Edit
              color={
                params.row.mercadoria == "Cadastros" ? "disabled" : "action"
              }
            />
          }
          label="Deletar"
          disabled={params.row.mercadoria == "Cadastros"}
          onClick={() => handleMercadoriaEdition(params.row.id)}
        />,
        <GridActionsCellItem
          icon={
            <DeleteIcon
              color={
                params.row.mercadoria == "Cadastros" ? "disabled" : "error"
              }
            />
          }
          label="Deletar"
          disabled={params.row.mercadoria == "Cadastros"}
          onClick={() => deleteMercadoria({ id: params.row.id })}
        />,
      ],
    },
  ];

  return (
    <PageLayout
      title={"Propostas - Mercadorias"}
      icon={<HistoryEduIcon color={"secondary"} />}
    >
      {isLoading ? (
        <LoadingIndicator></LoadingIndicator>
      ) : (
        <Grid container spacing={4} padding={2}>
          <FormHeader marginTop="0px">Mercadorias de Propostas</FormHeader>
          <FormHeaderMedium>Nova mercadoria</FormHeaderMedium>
          <Grid item xs={6}>
            <FormControl fullWidth required>
              <TextField
                id="name"
                label="Nome"
                type="text"
                size="small"
                value={name}
                onChange={(e) => setName(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth required>
              <TextField
                id="description"
                label="Descrição"
                type="text"
                size="small"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} textAlign={"right"}>
            <Button
              disabled={buttonDisabled}
              variant="contained"
              color="secondary"
              onClick={() => {
                isEditing ? handleSaveMercadoria() : handleMercadoriaAddition();
              }}
            >
              {isEditing ? "Salvar" : "Adicionar"}
            </Button>
          </Grid>
          <FormHeaderMedium>Mercadorias Cadastradas</FormHeaderMedium>
          <Grid item xs={12}>
            <DataTable
              columns={columns}
              rows={
                mercadorias.map((mercadoria) => ({
                  id: mercadoria.id,
                  mercadoria: mercadoria.nome,
                  descricao: mercadoria.descricao,
                })) ?? []
              }
            ></DataTable>
          </Grid>
        </Grid>
      )}
    </PageLayout>
  );
};

export default PropostasMercadorias;
