import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { IDLE_CONTROL } from '../config-control';
import {
  FAILURE_STATUS,
  IDLE_STATUS,
  LOADING_STATUS,
  SUCCESS_STATUS,
} from '../status';
import {
  downloadFileByIdAsync,
  downloadMultipleFilesByAsync,
} from './downloaderThunks';
import i18n from '../../i18n';

const initialState = {
  status: IDLE_STATUS,
  error: null,
  response: {
    status: null,
    message: null,
    data: null,
  },
  config: {
    control: IDLE_CONTROL,
  },
  data: undefined,
  metadata: {},
};

const downloaderSlice = createSlice({
  name: 'downloader',
  initialState,
  reducers: {
    changeStatusTo: (state, action) => {
      state.status = action.payload.status;
    },
    loading: (state) => {
      state.status = LOADING_STATUS;
    },
    success: (state) => {
      state.status = SUCCESS_STATUS;
    },
    failure: (state) => {
      state.status = FAILURE_STATUS;
    },
    resetStatus: (state) => {
      state.status = IDLE_STATUS;
    },
    setConfig: (state, action) => {
      state.config = action.payload;
    },
    changeControlTo: (state, action) => {
      state.config.control = action.payload;
    },
    setData: (state, action) => {
      state.data = {
        ...action.payload,
      };
    },
    resetData: (state) => {
      state.data = initialState.data;
    },
    setMetadata: (state, action) => {
      state.metadata = {
        ...action.payload,
      };
    },
    resetMetadata: (state) => {
      state.metadata = initialState.metadata;
    },
    setResponse: (state, action) => {
      state.response = action.payload;
    },
    clearResponse: (state) => {
      state.response = {
        status: null,
        message: null,
        data: null,
      };
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    clearError: (state) => {
      state.error = initialState.error;
    },
    reset: (state) => {
      state.data = initialState.data;
      state.metadata = initialState.metadata;
      state.response = initialState.response;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(downloadFileByIdAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(downloadFileByIdAsync.fulfilled, (state, action) => {
        const {
          headers,
          data,
        } = action.payload;

        state.response = action.payload;
        state.data = data;

        const contentDisposition = headers['content-disposition'] || '';

        const [matched] = String(contentDisposition)
          .match(/filename[^;=\n]*=(?:(\\?['"])(.*?)\1|(?:[^\s]+'.*?')?([^;\n]*))/g);

        const fileName = matched && _.isString(matched)
          ? matched.replace(/filename=|"/g, '')
          : undefined;

        const [name, extension] = fileName && _.isString(fileName)
          ? fileName.split('.')
          : undefined;

        state.metadata = {
          headers,
          fileName,
          name,
          extension,
        };

        state.status = SUCCESS_STATUS;
      })
      .addCase(downloadFileByIdAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.downloadArquivo', { erro: action.error.message }),
        };
      })
      .addCase(downloadMultipleFilesByAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(downloadMultipleFilesByAsync.fulfilled, (state, action) => {
        const {
          headers,
          data,
        } = action.payload;

        state.response = action.payload;
        state.data = data;

        const contentDisposition = headers['content-disposition'] || '';

        const [matched] = String(contentDisposition)
          .match(/filename[^;=\n]*=(?:(\\?['"])(.*?)\1|(?:[^\s]+'.*?')?([^;\n]*))/g);

        const fileName = matched
          ? String(matched).replace(/filename=|"/g, '')
          : undefined;

        const [name, extension] = fileName && _.isString(fileName)
          ? fileName.split('.')
          : undefined;

        state.metadata = {
          headers,
          fileName,
          name,
          extension,
        };

        state.status = SUCCESS_STATUS;
      })
      .addCase(downloadMultipleFilesByAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.downloadArquivos', { erro: action.error.message }),
        };
      });
  },
});

const {
  changeStatusTo,
  loading,
  success,
  failure,
  resetStatus,
  setConfig,
  changeControlTo,
  setData,
  resetData,
  setMetadata,
  resetMetadata,
  setResponse,
  clearResponse,
  setError,
  clearError,
  reset,
} = downloaderSlice.actions;

const downloaderActions = downloaderSlice.actions;

export {
  downloaderSlice,
  downloaderActions,
  changeStatusTo,
  loading,
  success,
  failure,
  resetStatus,
  setConfig,
  changeControlTo,
  setData,
  resetData,
  setMetadata,
  resetMetadata,
  setResponse,
  clearResponse,
  setError,
  clearError,
  reset,
};

export default downloaderSlice.reducer;
