/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { IconButton, Grid, Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
  Search as PageviewIcon,
  Delete as DeleteIcon,
  Print as PrintIcon,
  Code as CodeIcon,
  DoneAll as DoneAllIcon,
  FileCopy as FileCopyIcon,
  Receipt as ReceiptIcon,
  MoreHoriz as MoreHorizIcon,
  SyncAlt as SyncAltIcon,
  RotateLeft as RotateLeftIcon,
  ConfirmationNumber as ConfirmationNumberIcon,
  CheckCircle as CheckCircleIcon,
  CancelOutlined as CancelOutlinedIcon,
  GridOn,
} from '@material-ui/icons';
import { FaCalculator as FaCalculatorIcon } from 'react-icons/fa';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import _, { isArrayLikeObject, isEmpty, isFunction } from 'lodash';
import { bindTrigger, bindMenu } from 'material-ui-popup-state';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import { saveAs } from 'file-saver';
import {
  isAlternativeLoadingStatus,
  isNoneMode,
  isRefresh,
  isReversalMode,
  isTransferMode,
} from '../../../../utils/store/store-utils';
import { IDLE_CONTROL } from '../../../../features/config-control';
import {
  changeToConsultMode,
  changeToCreateMode,
  changeTypeTo,
  loading,
  refresh as refreshList,
  resetBackgroundMode,
  resetHistoryModel,
  resetMode,
  resetModel,
  resetRelatedMercadoriaModel,
  resetSubBackgroundMode,
  resetSubMode,
  resetType,
  setProcesso,
  setRelatedDuplicataModel,
  setRelatedRegistroModel,
  resetRelatedRegistroModel,
  failure,
  setError,
  success,
  setResponse,
  resetRefresh,
  resetRelatedSelectionLicencaImportacaoList,
  changeToTransferMode,
  setRelatedSelectionLicencaImportacaoList,
  changeToReversalMode,
  changeToBackgroundTransferMode,
  changeToBackgroundReversalMode,
  setRelatedTransferenciaModel,
  setRelatedSelectionAvailableItemList,
  setRelatedEstornoModel,
  setRelatedReportModel,
  resetRelatedReportModel,
} from '../../../../features/licenca-importacao/licencaImportacaoSlice';
import { followUpActions } from '../../../../features/follow-up/followUpSlice';
import { setSuccessFeedback, setErrorFeedback, setWarningFeedback } from '../../../../features/feedback/feedbackSlice';
import {
  activateByIdFromFaturaAsync,
  calculateByIdFromFaturaAsync,
  conferirByIdFromFaturaAsync,
  duplicarByIdFromFaturaAsync,
  fetchAllFromFaturaAsync,
  gerarSubstitutivaAsync,
  inactivateByIdFromFaturaAsync,
} from '../../../../features/licenca-importacao/licencaImportacaoThunks';
import {
  selectLicencasImportacao,
  selectRefresh,
  selectRelatedReportModel,
  selectRelatedDuplicataModel,
  selectRelatedRegistroModel,
  selectHistoryModel,
  selectRelatedSelectionLicencaImportacaoList,
  selectMode,
} from '../../../../features/licenca-importacao/licencaImportacaoSelectors';
import { ReportUtils } from '../../../../utils/general/report/reportUtils';
import { setQuery } from '../../../../features/controller/controllerSlice';
import { changeControlTo as changeControlPaisTo } from '../../../../features/pais/paisSlice';
import { changeControlTo as changeControlNaladiShTo } from '../../../../features/naladi-sh/naladiShSlice';
import { changeControlTo as changeControlUnidadeMedidaTo } from '../../../../features/unidade-medida/unidadeDeMedidaSlice';
import { changeControlTo as changeControlNcmTo } from '../../../../features/ncm-subitem/ncmSubitemSlice';
import QCXFinalRadioField from '../../../../shared-components/final-radio-field/QCXFinalRadioField';
import QCXSimpleConsultPageTemplate from '../../../../templates/simple-consult-page/QCXSimpleConsultPageTemplate';
import LicencaImportacaoContext from '../../../../contexts/import/licenca-importacao/LicencaImportacaoContext';
import { formatCnpj } from '../../../../utils/hooks/form/field/formatters';
import { formatDate } from '../../../../utils/general/general-utils';
import QCXListItemIcon from '../../../../shared-components/list-item-icon/QCXListItemIcon';
import QCXFinalFormDialog from '../../../../shared-components/dialog/QCXFinalFormDialog';
import useFormDialogAsync from '../../../../utils/hooks/form/dialog/useFormDialogAsync';
import {
  CLOSED_STATUS,
  OPEN_STATUS,
  DONE_STATUS,
  FAIL_STATUS,
  isOpenStatus,
  SUBMITTING_STATUS,
} from '../../../../utils/hooks/form/dialog/formDialogUtils';
import QCXFinalTextField from '../../../../shared-components/final-text-field/QCXFinalTextField';
import { required } from '../../../../utils/validators/field/validator';
import {
  isCalculadaStatus,
  isConferidaStatus,
  isNaoCalculadaStatus,
  OperationUtils,
  // isIntegracaoSiscomexConcluidaStatus,
  isIntegracaoSiscomexErroStatus,
  isIntegradaSiscomex,
} from '../../../../utils/general/operations/operationUtils';
import {
  FATURA_LI_META_TYPE,
  isFaturaLiMetaType,
  isGeneratedLiMetaType,
} from '../../../../features/licenca-importacao/licencaImportacaoUtils';
import useFormDialogSync from '../../../../utils/hooks/form/dialog/useFormDialogSync';
import { isTipoFatura, isTipoLi, TIPO_LI } from '../../../../utils/general/fatura/faturaUtils';
import QCXConfirmDialog from '../../../../shared-components/dialog/QCXConfirmDialog';
import useOperationMiddleware from '../../../../utils/hooks/operation/middleware/useOperationMiddleware';
import { userHasDeleteRole, userHasUpdateRole } from '../../../../components/perfil/perfil-utils';
import useGenericDownloader from '../../../../utils/hooks/downloader/useGenericDownloader';
import QCXGenericDownloader from '../../../../components/downloader/QCXGenericDownloader';
import QCXMoveFocusInside from '../../../../components/focus-lock/QCXMoveFocusInside';
import QCXPopupState from '../../../../components/popup-state/QCXPopupState';
import { licencaImportacaoAPI } from '../../../../features/licenca-importacao/licencaImportacaoAPI';
import QCXSelectFollowUpImportacaoAutocompleteField from '../../../../shared-components/select-follow-up/QCXSelectFollowUpImportacaoAutocompleteField';
import { followUpAPI } from '../../../../features/follow-up/followUpAPI';
import { mercadoriaMovimentacaoAPI } from '../../../../features/mercadoria-movimentacao/mercadoriaMovimentacaoAPI';
import { TipoContaLiUtils } from '../../../../shared-components/select-tipo-conta/tipoContaUtils';

import QCXSelectTipoContaLicencaImportacaoAutocomplete from '../../../../shared-components/select-tipo-conta/QCXSelectTipoContaLicencaImportacaoAutocomplete';
import { clienteActions } from '../../../../features/cliente/clienteSlice';
import { useGenerateLIXMLReport } from '../../../../ts/common/hooks/useGenerateLIXMLReport.ts';
import { useHasPermission } from '../../../../ts/common/hooks/useHasPermission/useHasPermission.ts';
import { GERARXML } from '../../../../ts/common/hooks/useHasPermission/permissionsEnum.ts';
import QCXSelectServicoAutocomplete from '../../../../shared-components/select-servico/QCXSelectServicoAutocomplete';
import QCXSelectClienteAutocomplete from '../../../../shared-components/final-select-cliente-field/QCXSelectClienteAutocomplete';
import { MediaType } from '../../../../utils/api/api-utils';

export default function LicencaImportacaoConsultPage({ authInfo = {} }) {
  const { t } = useTranslation();

  const [idLi, setIdLi] = useState(null);
  const [tipoLi, setTipoLi] = useState(null);
  const [hasDownloaded, setHasDownloaded] = useState(false);
  const { data: reportData } = useGenerateLIXMLReport(idLi, tipoLi);

  const canGenerateRegistro = useHasPermission(GERARXML.REGISTROLI);
  const canGenerateSubstitutiva = useHasPermission(GERARXML.SUBSTITUTIVALI);

  const memoizedReportData = useMemo(() => reportData, [reportData]);
  useEffect(() => {
    if (memoizedReportData && !hasDownloaded) {
      saveAs(memoizedReportData, `${idLi ?? 'DI'} - ${tipoLi}.xml`);
      setHasDownloaded(true);
    }
  }, [memoizedReportData]);

  const history = useHistory();

  const genericDownloader = useGenericDownloader({
    autoDownload: true,
  });

  const { status } = useContext(LicencaImportacaoContext);

  const dispatch = useDispatch();

  const mode = useSelector(selectMode);
  const refresh = useSelector(selectRefresh);
  const licencasImportacao = useSelector(selectLicencasImportacao);
  const duplicataRelatedModel = useSelector(selectRelatedDuplicataModel);
  const registroRelatedModel = useSelector(selectRelatedRegistroModel);
  const reportRelatedModel = useSelector(selectRelatedReportModel);
  const historyModel = useSelector(selectHistoryModel);
  const relatedSelectionLicencaImportacaoList = useSelector(selectRelatedSelectionLicencaImportacaoList);

  const pageRole = useMemo(() => 'importacao-li-lpco', []);

  const isAlternativeLoading = useMemo(() => isAlternativeLoadingStatus(status), [status]);

  const isNone = useMemo(() => isNoneMode(mode), [mode]);

  const isTransfer = useMemo(() => isTransferMode(mode), [mode]);

  const isReversal = useMemo(() => isReversalMode(mode), [mode]);

  const isEmptySelectionList = useMemo(
    () =>
      !relatedSelectionLicencaImportacaoList ||
      (_.isArrayLikeObject(relatedSelectionLicencaImportacaoList) && _.isEmpty(relatedSelectionLicencaImportacaoList)),
    [relatedSelectionLicencaImportacaoList]
  );

  const fetchAll = useCallback(() => {
    dispatch(fetchAllFromFaturaAsync());
  }, []);

  const resetModes = useCallback(() => {
    dispatch(resetMode());
    dispatch(resetBackgroundMode());
    dispatch(resetSubMode());
    dispatch(resetSubBackgroundMode());
  }, []);

  const resetModels = useCallback(() => {
    dispatch(resetModel());
    dispatch(resetRelatedMercadoriaModel());
    dispatch(resetHistoryModel());
  }, []);

  const resetTypes = useCallback(() => {
    dispatch(resetType());
  }, []);

  const resetDependencies = useCallback(() => {
    dispatch(changeControlPaisTo(IDLE_CONTROL));
    dispatch(changeControlNcmTo(IDLE_CONTROL));
    dispatch(changeControlNaladiShTo(IDLE_CONTROL));
    dispatch(changeControlUnidadeMedidaTo(IDLE_CONTROL));
  }, []);

  const configureDependencies = useCallback(() => {
    dispatch(clienteActions.changeControlTo(IDLE_CONTROL));
  }, []);

  useEffect(() => {
    fetchAll();

    resetModes();
    resetModels();
    resetTypes();
    resetDependencies();
  }, []);

  useEffect(() => {
    const checkIfIsRefresh = () => {
      if (isRefresh(refresh)) {
        dispatch(fetchAllFromFaturaAsync());
        dispatch(resetRefresh());
      }
    };

    checkIfIsRefresh();
  }, [refresh]);

  useEffect(() => {
    const checkIfExistsQuery = () => {
      if (!isEmpty(historyModel) && historyModel?.last?.processo) {
        dispatch(setQuery(historyModel?.last?.processo));
      }
    };

    checkIfExistsQuery();
  }, [historyModel]);

  useEffect(() => {
    if (isNone && !isEmptySelectionList) {
      dispatch(resetRelatedSelectionLicencaImportacaoList());
    }
  }, [isNone, isEmptySelectionList]);

  const handleConsultarClick = useCallback(
    (event, data) => {
      if (isFunction(event?.stopPropagation)) {
        event.stopPropagation();

        configureDependencies();

        dispatch(changeTypeTo(data?.metaFields?.type));
        dispatch(changeToConsultMode());

        if (isFaturaLiMetaType(data?.metaFields?.type)) {
          history.push(t('com.muralis.qcx.url.importacaoLicencaImportacaoDetalhes', { id: data?.id }));
          return;
        }

        if (isGeneratedLiMetaType(data?.metaFields?.type)) {
          history.push(
            t('com.muralis.qcx.url.importacaoLicencaImportacaoDetalhesOrigem', {
              id: data?.id,
              faturaId: data?.metaFields?.fatura?.id,
            })
          );
        }
      }
    },
    [configureDependencies, history]
  );

  const [handleImprimirClick, formDialogImpressaoStatus, handleFormDialogImpressaoStatus] = useFormDialogSync(
    async (event, data) => {
      if (isFunction(event?.stopPropagation)) {
        event.stopPropagation();
        dispatch(
          setRelatedReportModel({
            ...data,
            tipo: ReportUtils.DRAFT_LI,
          })
        );
      }
    },
    []
  );

  const handleGerarXMLById = useCallback((event, values) => {
    setTipoLi(values.licencaImportacao.tipo);
    setIdLi(values.licencaImportacao.id);
    setHasDownloaded(false);
  }, []);

  const handleImprimirByIdSubmit = useCallback(
    (values) => {
      handleFormDialogImpressaoStatus(SUBMITTING_STATUS);

      const getTipoRelatorioLabel = (tipo) => {
        if (ReportUtils.isDraftLi(tipo)) {
          return t('com.muralis.qcx.licencaImportacao.DraftLI');
        }
        return '';
      };

      const tipoRelatorioLabel = getTipoRelatorioLabel(values?.tipo);

      const fetcher = async () => {
        if (ReportUtils.isDraftLi(values?.tipo)) {
          return licencaImportacaoAPI.generateReportById(values?.licencaImportacao?.id);
        }
        return undefined;
      };

      const handlers = {
        onSuccess: () => {
          dispatch(resetRelatedReportModel());
          handleFormDialogImpressaoStatus(DONE_STATUS);
          const successMessage = t('com.muralis.qcx.mensagem.relatorioTipoGerado', {
            tipoRelatorio: tipoRelatorioLabel,
            processo: values?.licencaImportacao?.processo,
          });

          dispatch(
            setSuccessFeedback({
              message: successMessage,
            })
          );
        },
        onNoContent: () => {
          handleFormDialogImpressaoStatus(OPEN_STATUS);
          const infoMessage = t('com.muralis.qcx.mensagem.naoFoiPossivelGerarRelatorio', {
            tipoRelatorio: tipoRelatorioLabel,
            processo: values?.licencaImportacao?.processo,
          });

          dispatch(
            setErrorFeedback({
              message: infoMessage,
            })
          );
        },
        onError: (reason) => {
          handleFormDialogImpressaoStatus(FAIL_STATUS);
          const errorMessage = t('com.muralis.qcx.erro.erroGerarRelatorio', {
            tipoRelatorio: tipoRelatorioLabel,
            processo: values?.licencaImportacao?.processo,
            mensagem: `${reason?.response && reason?.response?.data ? `: ${reason?.response?.data?.message}.` : '.'}`,
          });

          dispatch(
            setErrorFeedback({
              message: errorMessage,
            })
          );
        },
      };

      genericDownloader.downloadFileById(fetcher, handlers);
    },
    [handleFormDialogImpressaoStatus]
  );

  const handleCloseFormDialogImpressao = useCallback(
    () => handleFormDialogImpressaoStatus(CLOSED_STATUS),
    [handleFormDialogImpressaoStatus]
  );

  const isFormDialogImpressaoOpen = useMemo(() => isOpenStatus(formDialogImpressaoStatus), [formDialogImpressaoStatus]);

  const formDialogImpressaoContent = useMemo(
    () =>
      t('com.muralis.qcx.selecioneAbaixoTipoRelatorioGerarProcesso', {
        processo: reportRelatedModel?.licencaImportacao?.processo || '#######',
      }),
    [isFormDialogImpressaoOpen, reportRelatedModel]
  );

  const formDialogImpressaoSubmittingFeedback = useMemo(
    () =>
      t('com.muralis.qcx.gerandoRelatorioLI', {
        processo: reportRelatedModel?.licencaImportacao?.processo || '#######',
      }),
    [reportRelatedModel]
  );

  const [handleCalcularByIdClickMiddleware, operationOfCalcularById] = useOperationMiddleware(async (data) => {
    dispatch(calculateByIdFromFaturaAsync(data));
  }, []);

  const handleConfigureCalcularByIdClick = useCallback(
    (row, popupState) => {
      const { id, calculada, atributosAdicionais, metaFields } = row;

      const handleCalcularByIdClick = (event) => {
        if (isFunction(event?.stopPropagation)) {
          popupState.close(event);

          event.stopPropagation();

          const isSelectedLicencaImportacaoNaoCalculada = isNaoCalculadaStatus(calculada);

          const configureOperation = () => ({
            options: {
              title: t('com.muralis.qcx.acoes.confirmarOperacaoCalculo'),
              message: isSelectedLicencaImportacaoNaoCalculada
                ? t('com.muralis.qcx.mensagem.calcularLIGerarNovas', { processo: atributosAdicionais?.processo })
                : t('com.muralis.qcx.mensagem.recalcularLI', { processo: atributosAdicionais?.processo }),
              endMessage: t('com.muralis.qcx.acoes.confirmacaoAcao', {
                acao: isSelectedLicencaImportacaoNaoCalculada
                  ? t('com.muralis.qcx.acoes.calculo')
                  : t('com.muralis.qcx.acoes.recalculo'),
              }),
            },
          });

          const dataOfOperationCalcular = {
            id: isSelectedLicencaImportacaoNaoCalculada ? id : metaFields?.fatura?.id,
            meta: {
              processo: atributosAdicionais?.processo,
              calculada,
            },
          };

          handleCalcularByIdClickMiddleware(configureOperation, dataOfOperationCalcular);
        }
      };

      return handleCalcularByIdClick;
    },
    [handleCalcularByIdClickMiddleware]
  );

  const [handleConferirByIdClickMiddleware, operationOfConferirById] = useOperationMiddleware(async (data) => {
    dispatch(
      conferirByIdFromFaturaAsync({
        id: data?.id,
        fatura: {
          id: data?.metaFields?.fatura?.id,
        },
      })
    );
  }, []);

  const handleConfigureConferirByIdClick = useCallback(
    (row, popupState) => {
      const { id, atributosAdicionais, metaFields } = row;

      const handleConferirByIdClick = (event) => {
        if (isFunction(event?.stopPropagation)) {
          popupState.close(event);

          event.stopPropagation();

          const configureOperation = () => ({
            options: {
              title: t('com.muralis.qcx.acoes.confirmarOperacaoConferencia').toUpperCase(),
              message: t('com.muralis.qcx.mensagem.confirmarOperacaoLIConferida', {
                processo: atributosAdicionais?.processo,
              }),
              endMessage: t('com.muralis.qcx.mensagem.certezaDesejaConferila'),
            },
          });

          const dataOfOperationConferir = {
            id,
            metaFields,
          };

          handleConferirByIdClickMiddleware(configureOperation, dataOfOperationConferir);
        }
      };

      return handleConferirByIdClick;
    },
    [handleConferirByIdClickMiddleware]
  );

  const [handleDuplicarClick, formDialogDuplicacaoStatus, handleFormDialogDuplicacaoStatus] = useFormDialogSync(
    async (event, data) => {
      if (isFunction(event?.stopPropagation)) {
        event.stopPropagation();

        if (isFaturaLiMetaType(data?.metaFields?.type)) {
          dispatch(
            setRelatedDuplicataModel({
              id: data?.id,
              atributosAdicionais: {
                tipo: TIPO_LI,
              },
              meta: {
                type: data?.metaFields?.type,
                from: {
                  processo: data?.processo,
                },
              },
            })
          );

          return;
        }

        if (isGeneratedLiMetaType(data?.metaFields?.type)) {
          dispatch(
            setRelatedDuplicataModel({
              id: data?.id,
              fatura: {
                id: data?.metaFields?.fatura?.id,
              },
              atributosAdicionais: {
                tipo: TIPO_LI,
              },
              meta: {
                type: data?.metaFields?.type,
                from: {
                  processo: data?.processo,
                },
              },
            })
          );
        }
      }
    },
    []
  );

  const [handleActivateOrInactivateByIdClickMiddleware, operationOfActivateOrInactivateById] = useOperationMiddleware(
    async (data) => {
      const isInactivation = data?.active;

      if (isInactivation) {
        dispatch(inactivateByIdFromFaturaAsync(data?.id));

        return;
      }

      dispatch(activateByIdFromFaturaAsync(data?.id));
    },
    [licencasImportacao]
  );

  const handleConfigureActivateOrInactivateByIdClick = useCallback(
    (row, popupState) => {
      const { id, atributosAdicionais, active } = row;

      const handleActivateOrInactivateByIdClick = (event) => {
        if (isFunction(event?.stopPropagation)) {
          popupState.close(event);

          event.stopPropagation();

          const configureOperation = () => ({
            options: {
              title: t('com.muralis.qcx.acoes.confirmarOperacaoExclusao').toUpperCase(),
              message: t('com.muralis.qcx.mensagem.confirmarOperacaoLIConferida', {
                processo: atributosAdicionais?.processo,
                acao: active ? t('com.muralis.qcx.estado.excluida') : t('com.muralis.qcx.estado.recuperada'),
              }),
              endMessage: t('com.muralis.qcx.acoes.confirmacaoAcao', {
                acao: active
                  ? t('com.muralis.qcx.excluila').toLowerCase()
                  : t('com.muralis.qcx.recuperala').toLowerCase(),
              }),
            },
          });

          const dataOfOperationActivateOrInactivate = {
            id,
            active,
          };

          handleActivateOrInactivateByIdClickMiddleware(configureOperation, dataOfOperationActivateOrInactivate);
        }
      };

      return handleActivateOrInactivateByIdClick;
    },
    [handleActivateOrInactivateByIdClickMiddleware]
  );

  const [
    handleAlterarNumeroRegistroSiscomexClick,
    formDialogAlterarNumeroRegistroSiscomexStatus,
    handleFormDialogAlterarNumeroRegistroSiscomexStatus,
  ] = useFormDialogSync(async (event, data) => {
    if (isFunction(event?.stopPropagation)) {
      event.stopPropagation();

      dispatch(
        setRelatedRegistroModel({
          id: data?.id,
          registro: data?.registro,
        })
      );
    }
  }, []);

  const handleAlterarNumeroRegistroSiscomexSubmit = useCallback(
    async (values) => {
      try {
        const responseOfFetch = await licencaImportacaoAPI.fetchById(values?.id);

        if (responseOfFetch?.status === 200) {
          const updated = {
            ...responseOfFetch?.data,
            registro: values?.registro,
          };

          const responseOfSave = await licencaImportacaoAPI.save(updated);

          if (responseOfSave?.status === 200) {
            handleFormDialogAlterarNumeroRegistroSiscomexStatus(DONE_STATUS);
            dispatch(resetRelatedRegistroModel());
            dispatch(refreshList());

            dispatch(success());
            dispatch(
              setResponse({
                status: responseOfSave.status,
                data: updated,
                message: t('com.muralis.qcx.mensagem.registroLISalvo', { processo: updated?.processo }),
              })
            );
          }
        }
      } catch (error) {
        handleFormDialogAlterarNumeroRegistroSiscomexStatus(FAIL_STATUS);

        let errorMessage = t('com.muralis.qcx.erro.erroSalvarNumeroRegistroLI');
        if (error?.response && error?.response?.data) {
          errorMessage = t('com.muralis.qcx.erro.erroSalvarNumeroRegistroLIEspecifico', {
            erro: error?.response?.data?.message,
          });
        }

        dispatch(failure());
        dispatch(
          setError({
            message: errorMessage,
          })
        );
      }
    },
    [handleFormDialogAlterarNumeroRegistroSiscomexStatus]
  );

  const handleCloseFormDialogAlterarNumeroRegistroSiscomex = useCallback(() => {
    handleFormDialogAlterarNumeroRegistroSiscomexStatus(CLOSED_STATUS);
    dispatch(resetRelatedRegistroModel());
  }, [handleFormDialogAlterarNumeroRegistroSiscomexStatus]);

  const isFormDialogAlteracaoNumeroRegistroSiscomexOpen = useMemo(() =>
    isOpenStatus(formDialogAlterarNumeroRegistroSiscomexStatus)
  );

  const formDialogAlteracaoNumeroRegistroSiscomexContent = useMemo(
    () => t('com.muralis.qcx.mensagem.numeroRegistroLISiscomex'),
    []
  );

  const handleDuplicarByProcessoSubmit = useCallback(
    (values) => {
      if (isTipoFatura(values?.atributosAdicionais?.tipo)) {
        dispatch(
          duplicarByIdFromFaturaAsync({
            id: values?.id,
            meta: {
              type: values?.meta?.type,
              from: {
                processo: values?.meta?.from?.processo,
              },
            },
            payload: {
              atributosAdicionais: {
                tipo: values?.atributosAdicionais?.tipo,
              },
            },
          })
        );
      }

      if (isTipoLi(values?.atributosAdicionais?.tipo)) {
        dispatch(
          duplicarByIdFromFaturaAsync({
            id: values?.id,
            fatura: {
              id: values?.fatura?.id,
            },
            meta: {
              type: values?.meta?.type,
              from: {
                processo: values?.meta?.from?.processo,
              },
            },
            payload: {
              followUp: {
                id: values?.followUp?.id,
              },
              atributosAdicionais: {
                tipo: values?.atributosAdicionais?.tipo,
              },
            },
          })
        );
      }

      handleFormDialogDuplicacaoStatus(DONE_STATUS);
    },
    [handleFormDialogDuplicacaoStatus]
  );

  const handleCloseFormDialogDuplicacao = useCallback(
    () => handleFormDialogDuplicacaoStatus(CLOSED_STATUS),
    [handleFormDialogDuplicacaoStatus]
  );

  const isFormDialogDuplicacaoOpen = useMemo(() => isOpenStatus(formDialogDuplicacaoStatus));

  const formDialogDuplicacaoContent = useMemo(
    () =>
      t('com.muralis.qcx.mensagem.preenchaProcessoDuplicarLI', {
        processo: duplicataRelatedModel?.meta?.from?.processo,
      }),
    [duplicataRelatedModel]
  );

  const [handleButtonNewClick, formDialogNewStatus, handleFormDialogNewStatus] = useFormDialogAsync(
    (event) => {
      event.stopPropagation();

      configureDependencies();

      dispatch(changeTypeTo(FATURA_LI_META_TYPE));
      dispatch(changeToCreateMode());

      history.push(t('com.muralis.qcx.url.importacaoLicencaImportacaoNovo'));
    },
    [configureDependencies, history]
  );

  const parseToFormData = useCallback((data) => {
    const { novosArquivos, ...restData } = data;
    const novoArquivoMeta = {
      idioma: null,
      totalItens: null,
      number: null,
      date: null,
    };
    const novosArquivosMeta = [];

    const formData = new FormData();

    if (isArrayLikeObject(novosArquivos)) {
      novosArquivos.forEach((arquivo) => {
        if (arquivo?.fatura?.length > 0) {
          formData.append('files[]', arquivo?.fatura[0]);
          const meta = _.clone(novoArquivoMeta);
          meta.idioma = arquivo?.idioma || null;
          meta.totalItens = arquivo?.totalItens || null;
          meta.number = arquivo?.numeroFatura || null;
          meta.date = arquivo?.dataFatura || null;
          novosArquivosMeta.push(meta);
        }
      });
    }

    if (!isArrayLikeObject(restData?.arquivos)) {
      restData.arquivos = [];
    }

    const json = JSON.stringify(restData);
    const blob = new Blob([json], {
      type: MediaType.APPLICATION_JSON,
    });

    formData.append('data', blob);

    const meta = { metadata: novosArquivosMeta };
    const jsonMeta = JSON.stringify(meta);
    const blobMeta = new Blob([jsonMeta], {
      type: MediaType.APPLICATION_JSON,
    });

    formData.append('metadata', blobMeta);

    return formData;
  }, []);

  const handleProcessoNewSubmit = useCallback(
    async (values) => {
      try {
        dispatch(loading());

        const clienteId = values?.cliente?.id;
        const servicoId = values?.servico?.id;

        const newFollowUp = {
          numero: values?.processo,
          importador: {
            id: clienteId,
          },
          servico: {
            id: servicoId,
          },
        };
        try {
          const formData = parseToFormData(newFollowUp);
          const response = await followUpAPI.register(formData);
          if (response?.status === 201) {
            const created = response?.data;
            dispatch(
              setProcesso({
                processo: created.numero,
                followUp: created,
              })
            );

            handleFormDialogNewStatus(DONE_STATUS);
            dispatch(success());
          }
        } catch (error) {
          handleFormDialogNewStatus(FAIL_STATUS);
          let errorMessage = t('com.muralis.qcx.erro.erroSalvarNumeroRegistroLI');
          if (error?.response && error?.response?.data) {
            errorMessage = t('com.muralis.qcx.erro.erroSalvarNumeroRegistroLIEspecifico', {
              erro: error?.response?.data?.message,
            });
          }
          dispatch(failure());
          dispatch(
            setError({
              message: errorMessage,
            })
          );
        }
      } catch (error) {
        handleFormDialogNewStatus(FAIL_STATUS);

        let errorMessage = t('com.muralis.qcx.erro.erroCarregarProcesso');
        if (error?.response && error?.response?.data) {
          errorMessage = t('com.muralis.qcx.erro.erroCarregarProcessoEspecifico', {
            erro: error?.response?.data?.message,
          });
        }

        dispatch(failure());
        dispatch(
          setError({
            message: errorMessage,
          })
        );
      }
    },
    [handleFormDialogNewStatus]
  );

  const handleCloseFormDialogNew = useCallback(
    () => handleFormDialogNewStatus(CLOSED_STATUS),
    [handleFormDialogNewStatus]
  );

  const isFormDialogNewOpen = useMemo(() => isOpenStatus(formDialogNewStatus), [formDialogNewStatus]);

  const isCurrentNotCalculated = useCallback((current) => !OperationUtils.isCalculadaStatus(current?.calculada), []);

  const hasDifferentImporters = useCallback((current, index, array) => {
    if (index === 0) {
      return false;
    }

    const pivot = array[0];

    return current?.importador?.id !== pivot?.importador?.id;
  }, []);

  const hasNoItemsAdded = useCallback(
    (current) => _.isArrayLikeObject(current?.mercadorias) && current?.mercadorias?.length === 0,
    []
  );

  const hasBalanceToTransfer = useCallback((conta) => conta?.saldoAtual > 0, []);

  const canBeTransactionReversed = useCallback(
    (movimentacao) => movimentacao?.estornada === 'NAO_ESTORNADA' && movimentacao?.tipo === 'SAIDA',
    []
  );

  const hasTransactionsToReverse = useCallback(
    (conta) =>
      conta?.movimentacoes && conta?.movimentacoes?.length > 0
        ? conta?.movimentacoes?.some(canBeTransactionReversed)
        : false,
    [canBeTransactionReversed]
  );

  const handleEnableTransferMode = useCallback((event) => {
    event.stopPropagation();
    dispatch(changeToTransferMode());
  }, []);

  const [handleContinueToTransfer, formDialogContinueToTransferStatus, handleFormDialogContinueToTransferStatus] =
    useFormDialogAsync(() => {
      dispatch(changeToBackgroundTransferMode());

      history.push(t('com.muralis.qcx.url.importacaoLicencaImportacaoTransferencia'));
    }, [history]);

  const handleContinueToTransferSubmit = useCallback(
    async (values) => {
      dispatch(loading());

      handleFormDialogContinueToTransferStatus(SUBMITTING_STATUS);

      const tipoOrigem = values.tipo.description;

      const mappedSelectionLicencaImportacaoList = licencasImportacao.filter((licencaImportacao) =>
        relatedSelectionLicencaImportacaoList.includes(licencaImportacao?.id)
      );

      const reducerToContaItemList = (previous, selectedLicencaImportacao) => {
        const adaptToContaItem = (item) => ({
          tipo: {
            description: tipoOrigem,
          },
          proprietario: {
            id: item.id,
          },
        });

        const items = selectedLicencaImportacao?.mercadorias?.map(adaptToContaItem) || [];

        return [...previous, ...items];
      };

      const contaItemList = mappedSelectionLicencaImportacaoList?.reduce(reducerToContaItemList, []);

      let suspendExecution = false;

      const response = await mercadoriaMovimentacaoAPI.fetchBalancesByOwners(contaItemList).catch((reason) => {
        const errorMessage = t('com.muralis.qcx.erro.erroConsultarSaldoMercadoriasLI', {
          mensagem: reason?.response && reason?.response?.data ? `: ${reason?.response?.data?.message}.` : '.',
        });

        handleFormDialogContinueToTransferStatus(FAIL_STATUS);

        dispatch(failure());
        dispatch(
          setErrorFeedback({
            message: errorMessage,
          })
        );

        suspendExecution = true;
      });

      if (suspendExecution) {
        return;
      }

      if (response?.status === 200) {
        const contasEncontradas = response?.data;

        const noBalanceToTransfer = contasEncontradas?.every((conta) => !hasBalanceToTransfer(conta));

        if (noBalanceToTransfer) {
          dispatch(failure());

          handleFormDialogContinueToTransferStatus(FAIL_STATUS);

          dispatch(
            setErrorFeedback({
              message: t('com.muralis.qcx.mensagem.nenhumItemLISaldoInsuficiente'),
            })
          );

          return;
        }

        const mappedMinifiedSelectionLicencaImportacaoList = mappedSelectionLicencaImportacaoList?.map(
          (selectedLicencaImportacao) => ({
            id: selectedLicencaImportacao?.id,
            atributosAdicionais: {
              processo: selectedLicencaImportacao?.atributosAdicionais?.processo,
            },
            importador: {
              id: selectedLicencaImportacao?.importador?.id,
              pessoa: {
                nome: selectedLicencaImportacao?.importador?.pessoa?.nome,
              },
            },
            mercadorias: selectedLicencaImportacao?.mercadorias?.map(
              (itemFromSelectedLicencaImportacao) => itemFromSelectedLicencaImportacao?.id
            ),
          })
        );

        const adaptToItemLicencaImportacao = (conta) => {
          const relatedLicencaImportacao = mappedMinifiedSelectionLicencaImportacaoList?.find(
            (selectedLicencaImportacao) => selectedLicencaImportacao?.mercadorias?.includes(conta?.proprietario?.id)
          );

          return {
            ...conta,
            id: conta?.proprietario?.id,
            tipo: {
              description: conta.tipo.description,
            },
            proprietario: conta?.proprietario,
            saldoInicial: conta?.saldoInicial,
            saldoAtual: conta?.saldoAtual,
            fatura: {
              id: relatedLicencaImportacao?.id,
              atributosAdicionais: {
                tipo: relatedLicencaImportacao?.atributosAdicionais?.tipo,
                processo: relatedLicencaImportacao?.atributosAdicionais?.processo,
                registro: relatedLicencaImportacao?.atributosAdicionais?.registro,
              },
            },
          };
        };

        const availableContas = contasEncontradas?.filter(hasBalanceToTransfer);

        const mappedAvailableContaItemList = (availableContas?.map(adaptToItemLicencaImportacao) || []).sort(
          (contaA, contaB) => {
            const primaryFactorA = contaA?.fatura?.numero;
            const primaryFactorB = contaB?.fatura?.numero;

            const secondaryFactorA = contaA?.proprietario?.item;
            const secondaryFactorB = contaB?.proprietario?.item;

            return primaryFactorA === primaryFactorB
              ? secondaryFactorA - secondaryFactorB
              : primaryFactorA - primaryFactorB;
          }
        );

        const { importador: commonImportador } = mappedMinifiedSelectionLicencaImportacaoList[0];

        const payloadFollowUpsCheck = {
          importador: {
            id: commonImportador?.id,
          },
          tipo: {
            description: tipoOrigem,
          },
        };

        const availableFollowUpsResponse = await mercadoriaMovimentacaoAPI
          .fetchAvailableFollowUpsByImporterAndType(payloadFollowUpsCheck)
          .catch((reason) => {
            const errorMessage = t('com.muralis.qcx.erro.erroConsultarProcessoFollowUpTransferencia', {
              mensagem: reason?.response && reason?.response?.data ? `: ${reason?.response?.data?.message}.` : '.',
            });

            handleFormDialogContinueToTransferStatus(FAIL_STATUS);

            dispatch(failure());
            dispatch(
              setErrorFeedback({
                message: errorMessage,
              })
            );

            suspendExecution = true;
          });

        if (suspendExecution) {
          return;
        }

        if (availableFollowUpsResponse?.status === 200) {
          const availableFollowUps = availableFollowUpsResponse?.data;

          if (_.isArrayLikeObject(availableFollowUps) && _.isEmpty(availableFollowUps)) {
            dispatch(failure());

            handleFormDialogContinueToTransferStatus(FAIL_STATUS);

            dispatch(
              setErrorFeedback({
                message: t('com.muralis.qcx.mensagem.naoFoiEncontradoProcessoDispFollowUpLI', {
                  origem: TipoContaLiUtils.getLabelLiByValue(tipoOrigem),
                  importador: commonImportador?.pessoa?.nome,
                }),
              })
            );

            return;
          }

          dispatch(followUpActions.resetControl());
          dispatch(
            followUpActions.setList({
              data: availableFollowUps,
            })
          );

          dispatch(
            setRelatedTransferenciaModel({
              conta: {
                tipo: {
                  description: tipoOrigem,
                },
              },
            })
          );

          dispatch(setRelatedSelectionAvailableItemList(mappedAvailableContaItemList));

          handleFormDialogContinueToTransferStatus(DONE_STATUS);
        }
      }
    },
    [
      licencasImportacao,
      hasBalanceToTransfer,
      relatedSelectionLicencaImportacaoList,
      handleFormDialogContinueToTransferStatus,
    ]
  );

  const handleCloseFormDialogContinueToTransfer = useCallback(
    () => handleFormDialogContinueToTransferStatus(CLOSED_STATUS),
    [handleFormDialogContinueToTransferStatus]
  );

  const isFormDialogContinueToTransferOpen = useMemo(
    () => isOpenStatus(formDialogContinueToTransferStatus),
    [formDialogContinueToTransferStatus]
  );

  const handleConfirmTransfer = useCallback(
    (event) => {
      event.stopPropagation();

      if (isEmptySelectionList) {
        return;
      }

      const mappedLicencasImportacao = licencasImportacao.filter((fatura) =>
        relatedSelectionLicencaImportacaoList.includes(fatura?.id)
      );

      const licencasImportacaoNaoCalculadas = mappedLicencasImportacao.some(isCurrentNotCalculated);

      if (licencasImportacaoNaoCalculadas) {
        dispatch(
          setWarningFeedback({
            message: t('com.muralis.qcx.mensagem.LISelecionadasCalculadasTransferencia'),
          })
        );

        return;
      }

      const importadoresDiferentes = mappedLicencasImportacao.some(hasDifferentImporters);

      if (importadoresDiferentes) {
        dispatch(
          setWarningFeedback({
            message: t('com.muralis.qcx.mensagem.LISelecionadasMesmoImportador'),
          })
        );

        return;
      }

      const licencasImportacaoSemItensAdicionados = mappedLicencasImportacao.some(hasNoItemsAdded);

      if (licencasImportacaoSemItensAdicionados) {
        dispatch(
          setWarningFeedback({
            message: t('com.muralis.qcx.mensagem.LISelecionadasItensAdicionadosTransferencia'),
          })
        );

        return;
      }

      handleContinueToTransfer();
    },
    [
      licencasImportacao,
      isEmptySelectionList,
      isCurrentNotCalculated,
      hasDifferentImporters,
      hasNoItemsAdded,
      handleContinueToTransfer,
      relatedSelectionLicencaImportacaoList,
    ]
  );

  const handleCancelTransfer = useCallback((event) => {
    event.stopPropagation();
    dispatch(resetMode());
  }, []);

  const handleEnableReversalMode = useCallback((event) => {
    event.stopPropagation();
    dispatch(changeToReversalMode());
  }, []);

  const [handleContinueToReversal, formDialogContinueToReversalStatus, handleFormDialogContinueToReversalStatus] =
    useFormDialogAsync(() => {
      dispatch(changeToBackgroundReversalMode());

      history.push(t('com.muralis.qcx.url.importacaoLicencaImportacaoEstorno'));
    }, [history]);

  const handleContinueToReversalSubmit = useCallback(
    async (values) => {
      dispatch(loading());

      handleFormDialogContinueToReversalStatus(SUBMITTING_STATUS);

      const tipoOrigem = values.tipo.description;

      const mappedSelectionLicencaImportacaoList = licencasImportacao.filter((licencaImportacao) =>
        relatedSelectionLicencaImportacaoList.includes(licencaImportacao?.id)
      );

      const reducerToContaItemList = (previous, selectedLicencaImportacao) => {
        const adaptToContaItem = (item) => ({
          tipo: {
            description: tipoOrigem,
          },
          proprietario: {
            id: item.id,
          },
        });

        const items = selectedLicencaImportacao?.mercadorias?.map(adaptToContaItem) || [];

        return [...previous, ...items];
      };

      const contaItemList = mappedSelectionLicencaImportacaoList?.reduce(reducerToContaItemList, []);

      let suspendExecution = false;

      const response = await mercadoriaMovimentacaoAPI.fetchBalancesByOwners(contaItemList).catch((reason) => {
        const errorMessage = t('com.muralis.qcx.erro.erroConsultarSaldoMercadoriaLIEstorno', {
          mensagem: reason?.response && reason?.response?.data ? `: ${reason?.response?.data?.message}.` : '.',
        });

        handleFormDialogContinueToReversalStatus(FAIL_STATUS);

        dispatch(failure());
        dispatch(
          setErrorFeedback({
            message: errorMessage,
          })
        );

        suspendExecution = true;
      });

      if (suspendExecution) {
        return;
      }

      if (response?.status === 200) {
        const contasEncontradas = response?.data;

        const noTransactionsToReverse = contasEncontradas?.every((conta) => !hasTransactionsToReverse(conta));

        if (noTransactionsToReverse) {
          dispatch(failure());

          handleFormDialogContinueToReversalStatus(FAIL_STATUS);

          dispatch(
            setErrorFeedback({
              message: t('com.muralis.qcx.erro.nenhumItemLISelecionadaTransferenciaEstornada', {
                origem: TipoContaLiUtils.getLabelLiByValue(tipoOrigem),
              }),
            })
          );

          return;
        }

        const reduceToItemMovimentacaoList = (previous, current) => {
          const adaptToItemMovimentacao = (movimentacao) => ({
            ...current,
            id: movimentacao?.id,
            tipo: movimentacao?.tipo,
            proprietario: current?.proprietario,
            valor: movimentacao?.valor,
            estornada: movimentacao?.estornada,
            operacao: movimentacao?.operacao,
            fatura: {
              id: movimentacao?.fatura?.id,
              atributosAdicionais: {
                tipo: movimentacao?.fatura?.atributosAdicionais?.tipo,
                processo: movimentacao?.fatura?.atributosAdicionais?.processo,
                registro: movimentacao?.fatura?.atributosAdicionais?.registro,
              },
            },
          });

          const mappedItemMovimentacaoList = current?.movimentacoes
            ?.filter(canBeTransactionReversed)
            ?.map(adaptToItemMovimentacao);

          return [...previous, ...mappedItemMovimentacaoList];
        };

        const availableContas = contasEncontradas?.filter(hasTransactionsToReverse);

        const mappedAvailableContaItemList = availableContas
          ?.reduce(reduceToItemMovimentacaoList, [])
          .sort((contaA, contaB) => {
            const primaryFactorA = contaA?.fatura?.atributosAdicionais?.processo;
            const primaryFactorB = contaB?.fatura?.atributosAdicionais?.processo;

            const secondaryFactorA = contaA?.proprietario?.item;
            const secondaryFactorB = contaB?.proprietario?.item;

            return primaryFactorA === primaryFactorB
              ? secondaryFactorA - secondaryFactorB
              : primaryFactorA - primaryFactorB;
          });

        dispatch(
          setRelatedEstornoModel({
            conta: {
              tipo: {
                description: tipoOrigem,
              },
            },
          })
        );

        dispatch(setRelatedSelectionAvailableItemList(mappedAvailableContaItemList));

        handleFormDialogContinueToReversalStatus(DONE_STATUS);
      }
    },
    [
      licencasImportacao,
      canBeTransactionReversed,
      relatedSelectionLicencaImportacaoList,
      handleFormDialogContinueToReversalStatus,
    ]
  );

  const handleCloseFormDialogContinueToReversal = useCallback(
    () => handleFormDialogContinueToReversalStatus(CLOSED_STATUS),
    [handleFormDialogContinueToReversalStatus]
  );

  const isFormDialogContinueToReversalOpen = useMemo(
    () => isOpenStatus(formDialogContinueToReversalStatus),
    [formDialogContinueToReversalStatus]
  );

  const handleConfirmReversal = useCallback(
    (event) => {
      event.stopPropagation();

      if (isEmptySelectionList) {
        return;
      }

      const mappedLicencasImportacao = licencasImportacao.filter((fatura) =>
        relatedSelectionLicencaImportacaoList.includes(fatura?.id)
      );

      const licencasImportacaoNaoCalculadas = mappedLicencasImportacao.some(isCurrentNotCalculated);

      if (licencasImportacaoNaoCalculadas) {
        dispatch(
          setWarningFeedback({
            message: t('com.muralis.qcx.mensagem.LISelecionadasCalaculadasEstorno'),
          })
        );

        return;
      }

      const isImportadoresDiferentes = mappedLicencasImportacao.some(hasDifferentImporters);

      if (isImportadoresDiferentes) {
        dispatch(
          setWarningFeedback({
            message: t('com.muralis.qcx.mensagem.LISelecionadasMesmoImportador'),
          })
        );

        return;
      }

      handleContinueToReversal();
    },
    [
      licencasImportacao,
      isEmptySelectionList,
      isCurrentNotCalculated,
      hasDifferentImporters,
      relatedSelectionLicencaImportacaoList,
      handleContinueToReversal,
    ]
  );

  const handleCancelReversal = useCallback((event) => {
    event.stopPropagation();
    dispatch(resetMode());
  }, []);

  const handleSelectionModelChange = useCallback(
    ({ selectionModel }) => {
      if (isTransfer || isReversal) {
        dispatch(setRelatedSelectionLicencaImportacaoList(selectionModel));
      }
    },
    [isTransfer, isReversal]
  );

  const handleConsultarListClick = useCallback(
    (event, data) => {
      if (isFunction(event?.stopPropagation)) {
        event.stopPropagation();

        dispatch(followUpActions.changeToConsultMode());

        history.push(`/importacao/follow-up/${data.id}/lista`);
      }
    },
    [history]
  );

  const columns = useMemo(
    () => [
      {
        field: 'id',
        headerName: t('com.muralis.qcx.processo'),
        headerAlign: 'center',
        align: 'center',
        flex: 180,
        valueGetter: ({ row }) => row?.atributosAdicionais?.processo || '-',
      },
      {
        field: 'importador',
        headerName: t('com.muralis.qcx.importador.label'),
        flex: 140,
        valueGetter: ({ row: { importador } }) => importador?.pessoa?.nomeResumido || '-',
      },
      {
        field: 'cnpj',
        headerName: t('com.muralis.qcx.empresa.CNPJ'),
        flex: 80,
        valueGetter: ({ row: { importador } }) => formatCnpj(importador?.pessoa?.cnpj, true) || '-',
      },
      {
        field: 'atributosAdicionais.registro',
        headerName: t('com.muralis.qcx.NumeroLI'),
        flex: 100,
        valueGetter: ({ row }) => row?.atributosAdicionais?.registro || '-',
      },
      {
        field: 'atributosAdicionais.data',
        headerName: t('com.muralis.qcx.data'),
        flex: 100,
        valueGetter: ({ row }) => formatDate(row?.atributosAdicionais?.data) || '-',
      },
      {
        field: 'numeroLiOriginal',
        headerName: 'Nº LI Original',
        flex: 100,
        valueGetter: ({ row }) => row?.numeroLiOriginal || '-',
      },
      {
        field: 'dataLiOriginal',
        headerName: 'Data LI Original',
        flex: 100,
        valueGetter: ({ row }) => formatDate(row?.dataLiOriginal) || '-',
      },
      {
        field: 'status',
        headerName: t('com.muralis.qcx.status'),
        flex: 120,
        valueGetter: ({ row }) => row?.atributosAdicionais?.status || '-',
      },
      {
        field: 'calculada',
        headerName: t('com.muralis.qcx.calculada'),
        headerAlign: 'center',
        align: 'center',
        type: 'boolean',
        flex: 100,
        valueGetter: ({ row }) => isCalculadaStatus(row?.calculada),
      },
      {
        field: 'atributosAdicionais.conferida',
        headerName: t('com.muralis.qcx.conferida'),
        headerAlign: 'center',
        align: 'center',
        type: 'boolean',
        flex: 100,
        valueGetter: ({ row: { atributosAdicionais } }) => isConferidaStatus(atributosAdicionais?.conferida),
      },
      {
        field: 'actions',
        headerName: t('com.muralis.qcx.acoes.label'),
        headerAlign: 'center',
        align: 'center',
        flex: 140,
        renderCell: ({ row: { id, calculada, atributosAdicionais, active, metaFields, numeroLiOriginal } }) => (
          <>
            <QCXPopupState popupId="popup-menu-li">
              {(popupState) => (
                <>
                  <Tooltip title="Visualizar Lista" arrow>
                    <IconButton
                      key={`btn-lista-view-${id}`}
                      name={`btn-lista-view-${id}`}
                      onClick={(event) => {
                        const followUp = atributosAdicionais?.followUp;
                        handleConsultarListClick(event, followUp);
                      }}
                    >
                      <GridOn color="secondary" size={20} />
                    </IconButton>
                  </Tooltip>
                  <IconButton
                    key={`btn-more-options-${id}`}
                    name={`btn-more-options-${id}`}
                    disabled={isTransfer || isReversal}
                    {...bindTrigger(popupState)}
                  >
                    <MoreHorizIcon color={isTransfer || isReversal ? 'disabled' : 'secondary'} size={20} />
                  </IconButton>
                  <Menu {...bindMenu(popupState)}>
                    <MenuItem
                      onClick={(event) => {
                        popupState.close(event);

                        handleConsultarClick(event, {
                          id,
                          metaFields,
                        });
                      }}
                    >
                      <QCXListItemIcon>
                        <PageviewIcon fontSize="small" />
                      </QCXListItemIcon>
                      <Typography
                        variant="inherit"
                        style={{
                          fontSize: 12,
                        }}
                      >
                        {t('com.muralis.qcx.acoes.consultar').toUpperCase()}
                      </Typography>
                    </MenuItem>
                    <MenuItem
                      onClick={(event) => {
                        popupState.close(event);
                        handleImprimirClick(event, {
                          licencaImportacao: {
                            id,
                            processo: atributosAdicionais?.processo,
                          },
                        });
                      }}
                    >
                      <QCXListItemIcon>
                        <PrintIcon color="primary" fontSize="small" />
                      </QCXListItemIcon>
                      <Typography
                        variant="inherit"
                        style={{
                          fontSize: 12,
                        }}
                      >
                        {t('com.muralis.qcx.acoes.imprimir').toUpperCase()}
                      </Typography>
                    </MenuItem>
                    <MenuItem
                      disabled={
                        !canGenerateRegistro ||
                        !(isCalculadaStatus(calculada) && isConferidaStatus(atributosAdicionais?.conferida))
                      }
                      onClick={(event) => {
                        handleGerarXMLById(event, {
                          licencaImportacao: {
                            id,
                            processo: atributosAdicionais?.processo,
                            tipo: 'LI',
                          },
                        });
                      }}
                    >
                      <QCXListItemIcon>
                        <CodeIcon color="primary" fontSize="small" />
                      </QCXListItemIcon>
                      <Typography
                        variant="inherit"
                        style={{
                          fontSize: 12,
                        }}
                      >
                        {'Gerar XML de Registro'.toUpperCase()}
                      </Typography>
                    </MenuItem>
                    <MenuItem
                      disabled={
                        !canGenerateSubstitutiva ||
                        !(
                          isCalculadaStatus(calculada) &&
                          isConferidaStatus(atributosAdicionais?.conferida) &&
                          !!numeroLiOriginal
                        )
                      }
                      onClick={(event) => {
                        handleGerarXMLById(event, {
                          licencaImportacao: {
                            id,
                            processo: atributosAdicionais?.processo,
                            tipo: 'SUBSTITUTIVA',
                          },
                        });
                      }}
                    >
                      <QCXListItemIcon>
                        <CodeIcon color="primary" fontSize="small" />
                      </QCXListItemIcon>
                      <Typography
                        variant="inherit"
                        style={{
                          fontSize: 12,
                        }}
                      >
                        {'Gerar XML Substitutiva'.toUpperCase()}
                      </Typography>
                    </MenuItem>
                    {userHasUpdateRole(authInfo, pageRole) && (
                      <>
                        <MenuItem
                          onClick={handleConfigureCalcularByIdClick(
                            {
                              id,
                              calculada,
                              atributosAdicionais,
                              active,
                              metaFields,
                            },
                            popupState
                          )}
                          disabled={
                            isIntegradaSiscomex(
                              atributosAdicionais?.registro,
                              atributosAdicionais?.integracaoRegistroSiscomex
                            ) || isIntegracaoSiscomexErroStatus(atributosAdicionais?.integracaoRegistroSiscomex)
                          }
                        >
                          <QCXListItemIcon>
                            <FaCalculatorIcon color="green" size={20} />
                          </QCXListItemIcon>
                          <Typography
                            variant="inherit"
                            style={{
                              fontSize: 12,
                            }}
                          >
                            {isNaoCalculadaStatus(calculada)
                              ? t('com.muralis.qcx.acoes.calcular').toUpperCase()
                              : t('com.muralis.qcx.acoes.recalcular').toUpperCase()}
                          </Typography>
                        </MenuItem>
                        <MenuItem
                          onClick={handleConfigureConferirByIdClick(
                            {
                              id,
                              atributosAdicionais,
                              metaFields,
                            },
                            popupState
                          )}
                          disabled={
                            isNaoCalculadaStatus(calculada) || isConferidaStatus(atributosAdicionais?.conferida)
                          }
                        >
                          <QCXListItemIcon>
                            <DoneAllIcon
                              fontSize="small"
                              htmlColor={
                                isNaoCalculadaStatus(calculada) || isConferidaStatus(atributosAdicionais?.conferida)
                                  ? 'rgba(0, 0, 0, 0.38)'
                                  : 'green'
                              }
                            />
                          </QCXListItemIcon>
                          <Typography
                            variant="inherit"
                            style={{
                              fontSize: 12,
                            }}
                          >
                            {t('com.muralis.qcx.acoes.conferir').toUpperCase()}
                          </Typography>
                        </MenuItem>
                        {isCalculadaStatus(calculada) && isConferidaStatus(atributosAdicionais?.conferida) && (
                          <MenuItem
                            onClick={(event) => {
                              popupState.close(event);
                              handleAlterarNumeroRegistroSiscomexClick(event, {
                                id,
                                registro: atributosAdicionais?.registro,
                              });
                            }}
                          >
                            <QCXListItemIcon>
                              <ConfirmationNumberIcon fontSize="small" color="secondary" />
                            </QCXListItemIcon>
                            <Typography
                              variant="inherit"
                              style={{
                                fontSize: 12,
                              }}
                            >
                              {atributosAdicionais?.registro
                                ? t('com.muralis.qcx.acoes.alterarRegistro').toUpperCase()
                                : t('com.muralis.qcx.acoes.informarRegistro').toUpperCase()}
                            </Typography>
                          </MenuItem>
                        )}
                        <MenuItem
                          onClick={(event) => {
                            popupState.close(event);
                            handleDuplicarClick(event, {
                              id,
                              processo: atributosAdicionais?.processo,
                              metaFields,
                            });
                          }}
                        >
                          <QCXListItemIcon>
                            <FileCopyIcon fontSize="small" color="primary" />
                          </QCXListItemIcon>
                          <Typography
                            variant="inherit"
                            style={{
                              fontSize: 12,
                            }}
                          >
                            {t('com.muralis.qcx.acoes.duplicar').toUpperCase()}
                          </Typography>
                        </MenuItem>
                      </>
                    )}
                    <MenuItem
                      disabled={false}
                      onClick={(event) => {
                        dispatch(gerarSubstitutivaAsync({ id, faturaId: metaFields?.fatura?.id }));
                      }}
                    >
                      <QCXListItemIcon>
                        <FileCopyIcon color="primary" fontSize="small" />
                      </QCXListItemIcon>
                      <Typography
                        variant="inherit"
                        style={{
                          fontSize: 12,
                        }}
                      >
                        {'Gerar Substitutiva'.toUpperCase()}
                      </Typography>
                    </MenuItem>
                    {isFaturaLiMetaType(metaFields?.type) && userHasDeleteRole(authInfo, pageRole) && (
                      <MenuItem
                        onClick={handleConfigureActivateOrInactivateByIdClick(
                          {
                            id,
                            atributosAdicionais,
                            active,
                          },
                          popupState
                        )}
                        disabled={!active}
                      >
                        <QCXListItemIcon>
                          <DeleteIcon
                            fontSize="small"
                            color={active && isFaturaLiMetaType(metaFields?.type) ? 'error' : 'disabled'}
                          />
                        </QCXListItemIcon>
                        <Typography
                          variant="inherit"
                          style={{
                            fontSize: 12,
                          }}
                        >
                          {t('com.muralis.qcx.acoes.excluir').toUpperCase()}
                        </Typography>
                      </MenuItem>
                    )}
                  </Menu>
                </>
              )}
            </QCXPopupState>
          </>
        ),
      },
    ],
    [
      isTransfer,
      isReversal,
      handleConsultarClick,
      handleConfigureCalcularByIdClick,
      handleConfigureConferirByIdClick,
      handleAlterarNumeroRegistroSiscomexClick,
      handleDuplicarClick,
      handleConfigureActivateOrInactivateByIdClick,
    ]
  );

  const breadcrumbs = useMemo(
    () => [
      {
        link: {
          to: '/',
          name: t('com.muralis.qcx.inicio'),
        },
      },
      {
        link: {
          to: t('com.muralis.qcx.url.moduloImportacao'),
          name: t('com.muralis.qcx.importacao.label'),
        },
      },
      {
        text: {
          name: t('com.muralis.qcx.licencasImportacaoLI'),
        },
        default: true,
      },
    ],
    []
  );

  const templateProps = useMemo(
    () => ({
      page: {
        title: t('com.muralis.qcx.licencasImportacaoLI'),
        icon: <ReceiptIcon />,
        breadcrumbs,
      },
      control: {
        ...(isNone
          ? {
              buttonNew: {
                description: t('com.muralis.qcx.licencaImportacao.novoRegistroManualLi'),
                onClick: handleButtonNewClick,
              },
            }
          : {}),
        others: [
          ...(isNone
            ? [
                {
                  description: <SyncAltIcon />,
                  color: 'secondary',
                  tooltip: {
                    description: t('com.muralis.qcx.acoes.transferir'),
                  },
                  onClick: handleEnableTransferMode,
                },
                {
                  description: <RotateLeftIcon />,
                  color: 'secondary',
                  tooltip: {
                    description: t('com.muralis.qcx.acoes.estornar'),
                  },
                  onClick: handleEnableReversalMode,
                },
              ]
            : []),
          ...(isTransfer || isReversal
            ? [
                {
                  description: t('com.muralis.qcx.acoes.confirmarSelecao'),
                  startIcon: <CheckCircleIcon />,
                  color: 'secondary',
                  tooltip: {
                    description: t('com.muralis.qcx.acoes.confirmarSelecaoDestino', {
                      destino: isTransfer
                        ? t('com.muralis.qcx.transferencia.envioTransferencia')
                        : t('com.muralis.qcx.estorno.envioEstorno'),
                    }),
                  },
                  onClick: isTransfer ? handleConfirmTransfer : handleConfirmReversal,
                  disabled: isEmptySelectionList,
                },
                {
                  description: <CancelOutlinedIcon />,
                  variant: 'outlined',
                  color: 'default',
                  tooltip: {
                    description: t('com.muralis.qcx.acoes.cancelar'),
                  },
                  onClick: isTransfer ? handleCancelTransfer : handleCancelReversal,
                },
              ]
            : []),
        ],
      },
      table: {
        columns,
        checkboxSelection: isTransfer || isReversal,
        selectionModel: relatedSelectionLicencaImportacaoList,
        onSelectionModelChange: handleSelectionModelChange,
      },
    }),
    [
      columns,
      breadcrumbs,
      isNone,
      isTransfer,
      isReversal,
      isEmptySelectionList,
      relatedSelectionLicencaImportacaoList,
      handleSelectionModelChange,
      handleButtonNewClick,
      handleEnableTransferMode,
      handleConfirmTransfer,
      handleCancelTransfer,
      handleEnableReversalMode,
      handleConfirmReversal,
      handleCancelReversal,
    ]
  );

  return (
    <QCXSimpleConsultPageTemplate
      pageProps={templateProps.page}
      controlProps={templateProps.control}
      tableProps={templateProps.table}
      tableData={licencasImportacao}
      isLoading={isAlternativeLoading}
      authInfo={authInfo}
      requiredRoles={[pageRole]}
    >
      <QCXFinalFormDialog
        key="form-dialog-processo-registro-manual-li"
        id="form-dialog-processo-registro-manual-li"
        title={t('com.muralis.qcx.licencaImportacao.novoRegistroManualLi')}
        content={t('com.muralis.qcx.preencherCampoContinuar')}
        dialogActionsProps={{
          button: {
            submit: {
              description: t('com.muralis.qcx.acoes.continuar'),
              size: 'small',
            },
            cancel: {
              description: t('com.muralis.qcx.acoes.cancelar'),
              size: 'small',
            },
          },
        }}
        open={isFormDialogNewOpen}
        initialValues={{}}
        onSubmit={handleProcessoNewSubmit}
        onClose={handleCloseFormDialogNew}
      >
        {() => (
          <Grid container xs={12} spacing={2}>
            <Grid item xs={12}>
              <QCXMoveFocusInside>
                <QCXFinalTextField
                  id="text-field-processo"
                  key="text-field-processo"
                  name="processo"
                  label={t('com.muralis.qcx.processo')}
                  validate={required}
                />
              </QCXMoveFocusInside>
            </Grid>
            <Grid item xs={12}>
              <QCXSelectClienteAutocomplete
                id="autocomplete-select-cliente-field"
                key="autocomplete-select-cliente-field"
                name="cliente.id"
                label={t('com.muralis.qcx.cliente.label')}
                initialValues={undefined}
              />
            </Grid>
            <Grid item xs={12}>
              <QCXSelectServicoAutocomplete
                id="autocomplete-select-servico-field"
                key="autocomplete-select-servico-field"
                name="servico.id"
                label={t('com.muralis.qcx.servico.labelSingular')}
                initialValues={undefined}
                fieldProps={{ validate: required }}
                produto="LI"
                required
              />
            </Grid>
          </Grid>
        )}
      </QCXFinalFormDialog>
      <QCXFinalFormDialog
        key="form-dialog-alteracao-numero-registro-li"
        id="form-dialog-alteracao-numero-registro-li"
        title={t('com.muralis.qcx.numeroRegistroSiscomexLI')}
        content={formDialogAlteracaoNumeroRegistroSiscomexContent}
        dialogActionsProps={{
          button: {
            submit: {
              description: t('com.muralis.qcx.acoes.salvar'),
              size: 'small',
            },
            cancel: {
              description: t('com.muralis.qcx.acoes.cancelar'),
              size: 'small',
            },
          },
        }}
        open={isFormDialogAlteracaoNumeroRegistroSiscomexOpen}
        initialValues={registroRelatedModel}
        onSubmit={handleAlterarNumeroRegistroSiscomexSubmit}
        onClose={handleCloseFormDialogAlterarNumeroRegistroSiscomex}
      >
        {() => (
          <QCXMoveFocusInside>
            <QCXFinalTextField
              key="numero-registro-field"
              id="numero-registro-field"
              name="registro"
              label={t('com.muralis.qcx.numeroRegistro')}
            />
          </QCXMoveFocusInside>
        )}
      </QCXFinalFormDialog>
      <QCXFinalFormDialog
        key="form-dialog-duplicacao-li"
        id="form-dialog-duplicacao-li"
        title={t('com.muralis.qcx.duplicacaoLicencaImportacaoLI')}
        content={formDialogDuplicacaoContent}
        dialogActionsProps={{
          button: {
            submit: {
              description: t('com.muralis.qcx.acoes.duplicar'),
              size: 'small',
            },
            cancel: {
              description: t('com.muralis.qcx.acoes.cancelar'),
              size: 'small',
            },
          },
        }}
        open={isFormDialogDuplicacaoOpen}
        initialValues={duplicataRelatedModel}
        onSubmit={handleDuplicarByProcessoSubmit}
        onClose={handleCloseFormDialogDuplicacao}
      >
        {() => (
          <QCXMoveFocusInside>
            <QCXSelectFollowUpImportacaoAutocompleteField
              key="select-autocomplete-processo-field"
              id="select-autocomplete-processo-field"
              name="followUp.id"
              label={t('com.muralis.qcx.processo')}
              validate={required}
              required
            />
          </QCXMoveFocusInside>
        )}
      </QCXFinalFormDialog>
      <QCXFinalFormDialog
        key="form-dialog-licenca-importacao-tranferencia"
        id="form-dialog-licenca-importacao-tranferencia"
        title={t('com.muralis.qcx.transferencia.transferenciaDestino')}
        // content="Preencha o campo abaixo para continuar."
        dialogActionsProps={{
          button: {
            submit: {
              description: t('com.muralis.qcx.acoes.continuar'),
              size: 'small',
            },
            cancel: {
              description: t('com.muralis.qcx.acoes.cancelar'),
              size: 'small',
            },
          },
        }}
        open={isFormDialogContinueToTransferOpen}
        initialValues={{
          tipo: {
            description: TipoContaLiUtils.TIPO_LI_DI,
          },
        }}
        onSubmit={handleContinueToTransferSubmit}
        onClose={handleCloseFormDialogContinueToTransfer}
        disablePristine
      >
        {() => (
          <QCXMoveFocusInside>
            <QCXSelectTipoContaLicencaImportacaoAutocomplete
              id="select-field-tipo-conta-licenca-importacao-transferencia"
              key="select-field-tipo-conta-licenca-importacao-transferencia"
              name="tipo.description"
              label={t('com.muralis.qcx.transferencia.destino')}
              fieldProps={{
                validate: required,
              }}
              required
              disabled
            />
          </QCXMoveFocusInside>
        )}
      </QCXFinalFormDialog>
      <QCXFinalFormDialog
        key="form-dialog-licenca-importacao-estorno"
        id="form-dialog-licenca-importacao-estorno"
        title={t('com.muralis.qcx.estorno.origemItens')}
        // content="Preencha o campo abaixo para continuar."
        dialogActionsProps={{
          button: {
            submit: {
              description: t('com.muralis.qcx.acoes.continuar'),
              size: 'small',
            },
            cancel: {
              description: t('com.muralis.qcx.acoes.cancelar'),
              size: 'small',
            },
          },
        }}
        open={isFormDialogContinueToReversalOpen}
        initialValues={{
          tipo: {
            description: TipoContaLiUtils.TIPO_LI_DI,
          },
        }}
        onSubmit={handleContinueToReversalSubmit}
        onClose={handleCloseFormDialogContinueToReversal}
        disablePristine
      >
        {() => (
          <QCXMoveFocusInside>
            <QCXSelectTipoContaLicencaImportacaoAutocomplete
              id="select-field-tipo-conta-licenca-importacao-estorno"
              key="select-field-tipo-conta-licenca-importacao-estorno"
              name="tipo.description"
              label={t('com.muralis.qcx.origem')}
              fieldProps={{
                validate: required,
              }}
              required
              disabled
            />
          </QCXMoveFocusInside>
        )}
      </QCXFinalFormDialog>
      <QCXFinalFormDialog
        key="form-dialog-impressao-li"
        id="form-dialog-impressao-li"
        title={t('com.muralis.qcx.licencaImportacao.relatorioLi')}
        content={formDialogImpressaoContent}
        dialogActionsProps={{
          button: {
            submit: {
              description: t('com.muralis.qcx.acoes.gerar'),
              size: 'small',
            },
            cancel: {
              description: t('com.muralis.qcx.acoes.cancelar'),
              size: 'small',
            },
          },
        }}
        open={isFormDialogImpressaoOpen}
        initialValues={reportRelatedModel}
        onSubmit={handleImprimirByIdSubmit}
        onClose={handleCloseFormDialogImpressao}
        disablePristine
      >
        {() => (
          <Grid container>
            <Grid item xs={12}>
              <QCXFinalRadioField
                id="checkbox-tipo-relatorio-li"
                key="checkbox-tipo-relatorio-li"
                name="tipo"
                label={t('com.muralis.qcx.licencaImportacao.DraftLI')}
                value={ReportUtils.DRAFT_LI}
                required
              />
            </Grid>
          </Grid>
        )}
      </QCXFinalFormDialog>
      <QCXConfirmDialog
        key="confirm-dialog-calculo-li"
        id="confirm-dialog-calculo-li"
        open={operationOfCalcularById?.active}
        title={operationOfCalcularById?.title}
        content={operationOfCalcularById?.message}
        endContent={operationOfCalcularById?.endMessage}
        onConfirm={operationOfCalcularById.confirm}
        onClose={operationOfCalcularById?.reset}
      />
      <QCXConfirmDialog
        key="confirm-dialog-conferencia-li"
        id="confirm-dialog-conferencia-li"
        open={operationOfConferirById?.active}
        title={operationOfConferirById?.title}
        content={operationOfConferirById?.message}
        endContent={operationOfConferirById?.endMessage}
        onConfirm={operationOfConferirById.confirm}
        onClose={operationOfConferirById?.reset}
      />
      <QCXConfirmDialog
        key="confirm-dialog-activate-inactivate-li"
        id="confirm-dialog-activate-inactivate-li"
        open={operationOfActivateOrInactivateById?.active}
        title={operationOfActivateOrInactivateById?.title}
        content={operationOfActivateOrInactivateById?.message}
        endContent={operationOfActivateOrInactivateById?.endMessage}
        onConfirm={operationOfActivateOrInactivateById.confirm}
        onClose={operationOfActivateOrInactivateById?.reset}
      />
      <QCXGenericDownloader status={formDialogImpressaoStatus} message={formDialogImpressaoSubmittingFeedback} />
    </QCXSimpleConsultPageTemplate>
  );
}
