import { Grid } from '@material-ui/core';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { catalogoProdutosActions, selectRelatedEspecificacaoModel } from '../../features/catalogo-produtos/catalogoProdutosSlice';
import { ncmSubitemActions } from '../../features/ncm-subitem/ncmSubitemSlice';
import { nveAtributoActions } from '../../features/nve-atributo/nveAtributoSlice';
import { nveEspecificacaoActions } from '../../features/nve-especificacao/nveEspecificacaoSlice';
import { nveActions } from '../../features/nve/nveSlice';
import QCXFormStepWizard from '../../shared-components/form-step-wizard/QCXFormStepWizard';
import QCXSelectNveAtributoAutocomplete from '../../shared-components/select-nve-atributo/QCXSelectNveAtributoAutocomplete';
import QCXSelectNveEspecificacaoAutocomplete from '../../shared-components/select-nve-especificacao/QCXSelectNveEspecificacaoAutocomplete';
import QCXSelectNveAutocomplete from '../../shared-components/select-nve/QCXSelectNveAutocomplete';
import QCXRegistrationOneToManyPageTemplate from '../../templates/registration-one-to-many-page/QCXRegistrationOneToManyPageTemplate';
import { required as requiredValidator } from '../../utils/validators/field/validator';
import useCatalogoProdutosAtributosListeners from '../../utils/general/catalogo-produtos/catalogoProdutosAtributosListeners';
import { useFilterNveBy } from '../../utils/general/catalogo-produtos/catalogoProdutosUtils';
import QCXFinalSelectDetalhamentoProdutoAutocomplete from '../../shared-components/select-catalogo-produto/QCXFinalSelectDetalhamentoProdutoAutocomplete';
import QCXFormTitle from '../form-title/QCXFormTitle';

export default function QCXAtributoCatalogoProdutoManyToOneFormManager({
  isConsult,
  isUpdate,
  isSubNone,
  isSubCreate,
  isSubConsult,
  isSubUpdate,
  initialValues,
  list,
  handleSubmit,
  handleNew,
  handleEdit,
  handleCancelCreate,
  handleCancelUpdate,
  handleRemove,
  handleRowClick,
  handleClearForm,
  controlComponentProps,
  authInfo = {},
  requiredRoles = [],
  isTipoDUIMP,
}) {
  const { t } = useTranslation();

  const tableProps = useMemo(() => ({
    title: t('com.muralis.qcx.NVE.especificacaoNVEAdicionadas'),
    columns: [
      {
        field: 'especificacao.code',
        headerName: t('com.muralis.qcx.codigo'),
        flex: 150,
        valueGetter: ({ row: { especificacao: { code } } }) => code,
      },
      {
        field: 'especificacao.description',
        headerName: t('com.muralis.qcx.descricao'),
        flex: 600,
        valueGetter: ({ row: { especificacao: { description } } }) => description,
      },

    ],
  }), []);

  const catalogoDeProdutos = useSelector(catalogoProdutosActions.selectCatalogoProdutos);
  const ncmList = useSelector(ncmSubitemActions.selectNcmSubitens);

  const filterNve = useFilterNveBy({
    ncmList,
    ncm: catalogoDeProdutos?.ncm,
  });

  useEffect(() => {
    filterNve();
  }, [filterNve]);

  const especificacao = useSelector(selectRelatedEspecificacaoModel);

  const actions = useMemo(() => ({
    nveActions,
    nveAtributoActions,
    nveEspecificacaoActions,
  }), [
    nveActions,
    nveAtributoActions,
    nveEspecificacaoActions,
  ]);

  const modes = useMemo(() => ({
    isConsult,
    isSubConsult,
    isSubCreate,
    isSubUpdate,
  }), [
    isConsult,
    isSubConsult,
    isSubCreate,
    isSubUpdate,
  ]);

  const formProps = useMemo(() => ({
    ncmList,
    ncm: catalogoDeProdutos?.ncm,
    especificacao,
  }), [
    ncmList,
    catalogoDeProdutos,
    especificacao,
  ]);

  const changeListeners = useCatalogoProdutosAtributosListeners({
    modes,
    actions,
    formProps,
  });

  const localeTableText = useMemo(() => ({
    noRowsLabel: t('com.muralis.qcx.validacao.nenhumAtributoAdicionado'),
  }), []);

  return (
    <QCXFormStepWizard
      initialValues={initialValues}
      onSubmit={handleSubmit}
      controlComponentProps={controlComponentProps}
      onChangeListeners={changeListeners}
      authInfo={authInfo}
      requiredRoles={requiredRoles}
    >
      {({ form, values, submitSucceeded }) => (
        <>
          {isTipoDUIMP && (
            <>
              <QCXFormTitle title={t('com.muralis.qcx.atributos')} />
              <Grid container spacing={2} style={{ marginTop: '1rem' }}>
                <Grid
                  item
                  xs={12}
                  sm={9}
                  md={7}
                >
                  <QCXFinalSelectDetalhamentoProdutoAutocomplete
                    id="select-field-detalhamento-produto"
                    key="select-field-detalhamento-produto"
                    name="detalhamentoProduto"
                    label={t('com.muralis.qcx.detalhamentoProduto')}
                    initialValues={values}
                    fieldProps={{ validate: requiredValidator }}
                    disabled={isConsult}
                    required
                  />
                </Grid>
              </Grid>
            </>
          )}
          {!isTipoDUIMP && (
            <QCXRegistrationOneToManyPageTemplate
              title={t('com.muralis.qcx.atributo')}
              tableProps={tableProps}
              tableData={list}
              isConsult={isConsult}
              isUpdate={isUpdate}
              isSubNone={isSubNone}
              isSubCreate={isSubCreate}
              isSubConsult={isSubConsult}
              isSubUpdate={isSubUpdate}
              submitSucceeded={submitSucceeded}
              handleNew={handleNew}
              handleEdit={handleEdit}
              handleCancelCreate={handleCancelCreate}
              handleCancelUpdate={handleCancelUpdate}
              handleRemove={handleRemove}
              handleClearForm={handleClearForm}
              handleRestartForm={() => form.restart({})}
              handleRowClick={handleRowClick}
              localeTableText={localeTableText}
            >
              <Grid item xs={12} sm={6} md={4}>
                <QCXSelectNveAutocomplete
                  name="nve.id"
                  label={t('com.muralis.qcx.nivel')}
                  disabled={isConsult || isSubConsult || isSubNone}
                  fieldProps={{ validate: requiredValidator }}
                  initialValues={values}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <QCXSelectNveAtributoAutocomplete
                  name="atributo.id"
                  fieldProps={{ validate: requiredValidator }}
                  initialValues={values}
                  label={t('com.muralis.qcx.atributo')}
                  disabled={isConsult || isSubConsult || isSubNone}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <QCXSelectNveEspecificacaoAutocomplete
                  name="especificacao.id"
                  label={t('com.muralis.qcx.especificacao.label')}
                  disabled={isConsult || isSubConsult || isSubNone}
                  fieldProps={{ validate: requiredValidator }}
                  initialValues={values}
                  required
                />
              </Grid>
            </QCXRegistrationOneToManyPageTemplate>
          )}
        </>
      )}
    </QCXFormStepWizard>
  );
}
