const selectFundamentacoesEspecificas = (state) => state.fundamentacaoEspecifica.list;
const selectStatus = (state) => state.fundamentacaoEspecifica.status;
const selectConfigControl = (state) => state.fundamentacaoEspecifica.config.control;
const selectError = (state) => state.fundamentacaoEspecifica.error;
const selectResponse = (state) => state.fundamentacaoEspecifica.response;

const fundamentacaoEspecificaSelectors = {
  selectFundamentacoesEspecificas,
  selectStatus,
  selectError,
  selectResponse,
};

export {
  fundamentacaoEspecificaSelectors,
  selectFundamentacoesEspecificas,
  selectStatus,
  selectConfigControl,
  selectError,
  selectResponse,
};
