import { createFilterOptions } from '@material-ui/lab/useAutocomplete';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchByFilterAsync,
  selectTiposDocumentoDueTemporaria,
  resetList,
} from '../../features/tipo-documento-due-temporaria/tipoDocumentoDueTemporariaSlice';
import QCXAutocompleteSelect from '../autocomplete-select/QCXAutocompleteSelect';
import TipoDocumentoDueUtils from './TipoDocumentoDueUtils';

export default function QCXSelectTipoDocumentoDueTemporariaAutocomplete({
  label,
  name,
  initialValues,
  controlled = false,
  ...restProps
}) {
  const dispatch = useDispatch();

  const list = useSelector(selectTiposDocumentoDueTemporaria);

  const [defaultValue, setDefaultValue] = useState('');

  useEffect(() => {
    if (!controlled) {
      dispatch(fetchByFilterAsync([{
        name: 'tipo',
        value: TipoDocumentoDueUtils.FILTER_TEMPORARIA,
      }]));
    }
  }, [controlled]);

  useEffect(() => {
    const value = list.find((item) => item?.id === _.get(initialValues, name));

    if (value) {
      setDefaultValue(value);
      return;
    }

    setDefaultValue({ id: null, description: '' });
  }, [list, initialValues]);

  useEffect(() => (
    () => (
      dispatch(resetList())
    )
  ), []);

  const filterOptions = useMemo(() => (
    createFilterOptions({
      stringify: (item) => item?.description,
    })
  ), []);

  const sortedList = useMemo(() => (
    TipoDocumentoDueUtils.sortByDescription([...list])
  ), [list]);

  return (
    <QCXAutocompleteSelect
      label={label}
      id={name}
      name={name}
      options={sortedList}
      filterOptions={filterOptions}
      defaultValue={defaultValue}
      getOptionLabel={(item) => item?.description}
      {...restProps}
    />
  );
}
