import React, { useCallback, useMemo } from 'react';
import { Grid, Typography, useTheme } from '@material-ui/core';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  etapaFollowUpActions,
} from '../../features/etapa-follow-up/etapaFollowUpSlice';
import {
  CLOSED_STATUS,
  DONE_STATUS,
  FAIL_STATUS,
  isOpenStatus,
  SUBMITTING_STATUS,
} from '../../utils/hooks/form/dialog/formDialogUtils';
import { etapaFollowUpAPI } from '../../features/etapa-follow-up/etapaFollowUpAPI';
import { etapaFollowUpSelectors } from '../../features/etapa-follow-up/etapaFollowUpSelectors';
import QCXFinalFormDialog from '../../shared-components/dialog/QCXFinalFormDialog';
import QCXMoveFocusInside from '../focus-lock/QCXMoveFocusInside';
import { required } from '../../utils/validators/field/validator';
import QCXSelectEtapaPreviewAutocomplete from '../../shared-components/select-etapa/QCXSelectEtapaPreviewAutocomplete';
import { followUpActions } from '../../features/follow-up/followUpSlice';
import QCXSelectEtapaFollowUpPreviewAutocomplete from '../../shared-components/select-etapa-follow-up/QCXSelectEtapaFollowUpPreviewAutocomplete';

export default function QCXNovaEtapaFollowUpFormDialog({
  parentModel,
  status,
  handleStatus,
}) {
  const { t } = useTranslation();
  const theme = useTheme();

  const dispatch = useDispatch();

  const etapasFollowUp = useSelector(etapaFollowUpSelectors.selectEtapasFollowUp);
  const etapaFollowUp = useSelector(etapaFollowUpSelectors.selectEtapaFollowUp);

  const filterEtapasDisponiveis = useCallback((item) => {
    const found = etapasFollowUp.find(({ etapa }) => (
      etapa?.id === item?.id
    ));

    return found === undefined;
  }, [etapasFollowUp]);

  const handleSubmit = useCallback(async (values) => {
    try {
      dispatch(followUpActions.loading());
      dispatch(etapaFollowUpActions.loading());

      handleStatus(SUBMITTING_STATUS);

      const payload = {
        ...values,
        followUp: {
          id: parentModel?.id,
        },
      };

      const response = await etapaFollowUpAPI.register(
        payload
      );

      if (response.status === 201) {
        const responseMessage = (
          t('com.muralis.qcx.mensagem.etapaAdicionadaProcessoFollowUp', { numero: parentModel?.numero })
        );

        const simplifiedResponse = {
          status: response?.status,
          message: responseMessage,
          data: response?.data,
        };

        dispatch(followUpActions.success());
        dispatch(etapaFollowUpActions.success());
        dispatch(followUpActions.setResponse(simplifiedResponse));

        dispatch(etapaFollowUpActions.refresh());

        handleStatus(DONE_STATUS);
      }
    } catch (error) {
      handleStatus(FAIL_STATUS);

      const isUnknownError = (
        _.isEmpty(error?.response)
        || _.isEmpty(error?.response?.data)
        || _.isEmpty(error?.response?.data?.message)
      );

      const errorMessage = isUnknownError
        ? t('com.muralis.qcx.erro.erroAdicionarEtapaProcessoFollowUp', { numero: parentModel?.numero })
        : t('com.muralis.qcx.erro.erroAdicionarEtapaProcessoFollowUp', { numero: parentModel?.numero, mensagem: error?.response?.data?.message });

      dispatch(followUpActions.failure());
      dispatch(etapaFollowUpActions.failure());
      dispatch(followUpActions.setError({
        message: errorMessage,
      }));
      dispatch(followUpActions.setResponse(error.response));
    }
  }, [
    parentModel,
    handleStatus,
    etapaFollowUpActions,
  ]);

  const handleCloseFormDialog = useCallback(() => (
    handleStatus(CLOSED_STATUS)
  ), [handleStatus]);

  const isFormDialogOpen = useMemo(() => (
    isOpenStatus(status)
  ), [status]);

  const formDialogTitle = useMemo(() => (
    <>
      {t('com.muralis.qcx.etapa.adicionarEtapaProcesso').toUpperCase()}
      <br />
      <Typography
        variant="subtitle1"
      >
        <strong>{parentModel?.numero}</strong>
      </Typography>
    </>
  ), [parentModel]);

  const formDialogContent = useMemo(() => (
    t('com.muralis.qcx.etapa.preenchaCamposAbaixoAdicionarNovaEtapa')
  ), []);

  return (
    <QCXFinalFormDialog
      key="form-dialog-adicionar-etapa-follow-up"
      id="form-dialog-adicionar-etapa-follow-up"
      title={formDialogTitle}
      content={formDialogContent}
      dialogActionsProps={{
        button: {
          submit: {
            description: t('com.muralis.qcx.acoes.adicionar'),
            size: 'small',
          },
          cancel: {
            description: t('com.muralis.qcx.acoes.cancelar'),
            size: 'small',
          },
        },
      }}
      open={isFormDialogOpen}
      initialValues={etapaFollowUp}
      onSubmit={handleSubmit}
      onClose={handleCloseFormDialog}
      PaperProps={{
        style: {
          borderLeft: `6px solid ${theme.palette.secondary.main}`,
        },
      }}
    >
      {() => (
        <Grid
          item
          container
          spacing={2}
          xs={12}
        >
          <Grid
            item
            xs={12}
          >
            <QCXMoveFocusInside>
              <QCXSelectEtapaPreviewAutocomplete
                id="autocomplete-select-field-nova-etapa"
                key="autocomplete-select-field-nova-etapa"
                name="etapa.id"
                label={t('com.muralis.qcx.etapa.label')}
                fieldProps={{
                  validate: required,
                }}
                filterPredicate={filterEtapasDisponiveis}
                required
              />
            </QCXMoveFocusInside>
          </Grid>
          <Grid
            item
            xs={12}
          >
            <QCXSelectEtapaFollowUpPreviewAutocomplete
              id="autocomplete-select-field-etapa-anterior"
              key="autocomplete-select-field-etapa-anterior"
              name="etapaBase.id"
              label={t('com.muralis.qcx.adicionarApos')}
              fieldProps={{
                validate: required,
              }}
              required
            />
          </Grid>
        </Grid>
      )}
    </QCXFinalFormDialog>
  );
}
