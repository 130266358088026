import { useMemo } from "react";
import { UseMutateFunction } from "react-query";
import {
  QueriesTypes,
  useDeleteRequest,
  useGetRequest,
  usePostRequest,
  usePutRequest,
} from "../../../../common/hooks/queries";
import { MercadoriaProposta } from "../common/mercadoriaProposta.type";

export const useCreatePropostaMercadoria = (): [
  UseMutateFunction<
    any,
    unknown,
    {
      requestPayload: {
        [key: string]: any;
      };
      queryParams?:
        | {
            clientId?: string | number;
            step?: number;
          }
        | undefined;
    }
  >,
  boolean,
  boolean
] => {
  const { isLoading, isError, mutate } = usePostRequest(
    QueriesTypes.MercadoriasProposta
  );

  return [mutate, isLoading, isError];
};

export const useDeletePropostaMercadoria = (): [
  UseMutateFunction<
    any,
    unknown,
    {
      id?: string | number;
      nome?: string | undefined;
      clienteId?: number | undefined;
    },
    unknown
  >,
  boolean,
  boolean
] => {
  const { isLoading, isError, mutate } = useDeleteRequest(
    QueriesTypes.MercadoriasProposta
  );

  return [mutate, isLoading, isError];
};

export const useUpdatePropostaMercadoria = (): [
  UseMutateFunction<any, unknown, { [key: string]: any }>,
  boolean,
  boolean
] => {
  const { isLoading, isError, mutate } = usePutRequest(
    QueriesTypes.MercadoriasProposta
  );

  return [mutate, isLoading, isError];
};
