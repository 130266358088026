import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import debounce from 'lodash/debounce';
import { useDispatch, useSelector } from 'react-redux';
import { validate } from 'uuid';
import {
  addToList,
  changeToBackgroundCreateMode,
  changeToConsultMode,
  changeToCreateMode,
  changeToUpdateMode,
  failure,
  fetchByIdAsync,
  loading,
  preparingAction,
  resetBackgroundMode,
  resetModel,
  resetRelatedModeloLancamentoModel,
  resetSubMode,
  selectBackgroundMode,
  selectMode,
  selectModeloFaturamento,
  selectStatus,
  setError,
  setModel,
  setResponse, success, updateOnList,
} from '../../../../../features/modelo-faturamento/modeloFaturamentoSlice';
import { register, save } from '../../../../../features/modelo-faturamento/modeloFaturamentoAPI';
import QCXRegistrationFormPageTemplate from '../../../../../templates/registration-form-page/QCXRegistrationFormPageTemplate';
import QCXModeloFaturamentoWizardFinalForm from '../../../../../components/modelo-faturamento/QCXModeloFaturamentoWizardFinalForm';
import {
  isNoneMode,
  isConsultMode,
  isCreateMode,
  isUpdateMode,
  isBackgroundCreateMode,
  isPreparingActionStatus,
} from '../../../../../utils/store/store-utils';
import { normalizeNumeral } from '../../../../../utils/general/general-utils';
import ModeloFaturamentoUtils from '../../../../../utils/general/modelo-faturamento/ModeloFaturamentoUtils';

export default function ModeloFaturamentoRegistrationPage({ authInfo = {} }) {
  const { t } = useTranslation();

  const { id } = useParams();

  const history = useHistory();

  const dispatch = useDispatch();

  const [isCreated, setIsCreated] = useState(false);

  const status = useSelector(selectStatus);
  const mode = useSelector(selectMode);
  const backgroundMode = useSelector(selectBackgroundMode);
  const modeloFaturamento = useSelector(selectModeloFaturamento);

  const isNone = useMemo(() => (
    isNoneMode(mode)
  ), [mode]);

  const isCreate = useMemo(() => (
    isCreateMode(mode)
  ), [mode]);

  const isConsult = useMemo(() => (
    isConsultMode(mode)
  ), [mode]);

  const isUpdate = useMemo(() => (
    isUpdateMode(mode)
  ), [mode]);

  const isBackgroundCreate = useMemo(() => (
    isBackgroundCreateMode(backgroundMode)
  ), [backgroundMode]);

  const isPreparingAction = useMemo(() => (
    isPreparingActionStatus(status)
  ), [status]);

  const handleChangeToCreate = () => {
    dispatch(changeToCreateMode());
  };

  const handleChangeToConsult = () => {
    dispatch(changeToConsultMode());
    dispatch(resetRelatedModeloLancamentoModel());
    dispatch(resetSubMode());
  };

  const handleChangeToUpdate = () => {
    dispatch(changeToUpdateMode());
  };

  const handleCancelUpdate = () => {
    const currentId = isBackgroundCreate ? modeloFaturamento?.id : id;

    if (currentId) {
      dispatch(fetchByIdAsync(currentId));
    }
    handleChangeToConsult();
  };

  const handleChangeToBackgroundCreate = () => {
    dispatch(changeToBackgroundCreateMode());
  };

  const handleResetBackgroundMode = () => {
    dispatch(resetBackgroundMode());
  };

  const handleChangeToPreparingAction = () => {
    dispatch(preparingAction());
  };

  useEffect(() => {
    if (id) {
      dispatch(fetchByIdAsync(id));
      handleChangeToConsult();
    }
  }, [id]);

  const normalize = (unnormalizedData) => {
    const unnormalizedModelosLancamento = modeloFaturamento?.modelosLancamento || [];

    let agenda = null;
    if (unnormalizedData?.automatico) {
      agenda = {
        ...unnormalizedData?.agenda,
      };
    }

    const normalizedModelosLancamento = unnormalizedModelosLancamento
      ?.map((current) => {
        const isAnUUID = validate(current?.id);
        return isAnUUID ? {
          id: null,
          despesaReceita: current?.despesaReceita?.id
            ? current?.despesaReceita
            : null,
          description: current?.description,
          moeda: current?.moeda,
          tipoCalculo: current?.tipoCalculo,
          valor: normalizeNumeral(current?.valor),
          tipo: current?.tipo,
        } : {
          ...current,
          valor: normalizeNumeral(current?.valor),
          despesaReceita: current?.despesaReceita?.id
            ? current?.despesaReceita
            : null,
        };
      });

    const normalizedData = {
      ...unnormalizedData,
      agenda,
      modelosLancamento: normalizedModelosLancamento,
      etapa: unnormalizedData?.etapa?.id ? unnormalizedData?.etapa : null,
      planoDeConta: unnormalizedData?.planoDeConta?.id ? unnormalizedData?.planoDeConta : null,
    };

    return normalizedData;
  };

  const unnormalize = (normalizedData) => {
    const unnormalizedModelosLancamento = modeloFaturamento?.modelosLancamento || [];

    let tipo = null;
    if (normalizedData?.agenda?.porDespesaReceita) {
      tipo = ModeloFaturamentoUtils.POR_DESPESA_RECEITA;
    }

    if (normalizedData?.agenda?.porEtapa) {
      tipo = ModeloFaturamentoUtils.POR_ETAPA;
    }

    const unnormalizedData = {
      ...normalizedData,
      modelosLancamento: unnormalizedModelosLancamento,
      agenda: {
        ...normalizedData?.agenda,
        tipo,
      },
    };

    return unnormalizedData;
  };

  const handleDispatchSetModel = useCallback((data) => {
    dispatch(setModel(normalize(data)));
  }, [normalize]);

  const createByStep = async (data, step, next) => {
    const isFirstStep = step === 0;
    const isLastStep = step === 2;

    const executeDebounced = debounce(async () => {
      try {
        if (isFirstStep && !isCreated) {
          const response = await register(data);

          if (response?.status === 201) {
            next();
            dispatch(resetModel());
            dispatch(success());

            const created = response?.data;

            handleDispatchSetModel(created);
            dispatch(addToList({ data: created }));

            setIsCreated(true);
          }
        } else if (isLastStep) {
          const response = await save(data);

          if (response?.status === 200) {
            dispatch(resetModel());

            const handleResultWithDebounce = debounce(() => {
              history.push(t('com.muralis.qcx.url.moduloGeraisServicosModelosFaturamento'));

              dispatch(success());

              const saved = response?.data;

              dispatch(setResponse({
                status: response.status,
                data: saved,
                message: t('com.muralis.qcx.mensagem.modeloFaturamentoCadastrado', { modelo: saved?.description }),
              }));

              dispatch(updateOnList({ data: saved }));
            }, 500);

            handleResultWithDebounce();
          }
        } else {
          const response = await save(data);

          if (response?.status === 200) {
            next();
            const saved = response?.data;

            dispatch(success());
            dispatch(setModel(saved));
            dispatch(updateOnList({ data: saved }));
          }
        }
      } catch ({ response }) {
        dispatch(failure());
        dispatch(setError({
          message: t('com.muralis.qcx.erro.erroSalvarModeloFaturamentoEspecifico', { erro: response?.data?.message }),
        }));
      }
    }, 500);

    dispatch(loading());
    executeDebounced();
  };

  const update = async (data, forceRefresh = false) => {
    const executeDebounced = debounce(async () => {
      try {
        const response = await save(data);

        if (response?.status === 200) {
          const handleResultWithDebounce = debounce(() => {
            dispatch(success());

            const saved = response?.data;

            dispatch(setResponse({
              status: response.status,
              data: saved,
              message: t('com.muralis.qcx.mensagem.modeloFaturamentoSalvo', { modelo: saved?.description }),
            }));

            if (forceRefresh) {
              dispatch(fetchByIdAsync(data?.id));
            } else {
              dispatch(setModel(saved));
            }

            dispatch(updateOnList({ data: saved }));
            handleChangeToConsult();
          }, 500);

          handleResultWithDebounce();
        }
      } catch ({ response }) {
        dispatch(failure());
        dispatch(setError({
          message: t('com.muralis.qcx.erro.erroSalvarModeloFaturamento', { modelo: data?.description, erro: response?.data?.message }),
        }));
      }
    }, 500);

    dispatch(loading());
    executeDebounced();
  };

  const handleSubmit = async (data, step, next) => {
    const normalizedData = normalize(data);

    if (isUpdate && !isBackgroundCreate) {
      const forceRefresh = step === 0;
      await update(normalizedData, forceRefresh);
    } else {
      await createByStep(normalizedData, step, next);
    }
  };

  const handleAlternativeSave = async (event, step, next) => {
    if (event) {
      event.stopPropagation();
    }

    const normalizedData = normalize(modeloFaturamento);
    const forceRefresh = true;

    if (isUpdate) {
      await update(normalizedData, forceRefresh);
    } else {
      await createByStep(normalizedData, step, next);
    }
  };

  const model = useMemo(() => (
    isCreated || !isCreate
      ? unnormalize(modeloFaturamento)
      : {}
  ), [isCreate, isCreated, modeloFaturamento, unnormalize]);

  const actionName = useMemo(() => {
    if (isCreate || isBackgroundCreate || isNone) return t('com.muralis.qcx.acoes.novo');
    if (isConsult) return t('com.muralis.qcx.acoes.visualizar');
    return t('com.muralis.qcx.acoes.alterar');
  }, [isNone, isCreate, isConsult]);

  const breadcrumbs = [
    {
      link: {
        to: '/',
        name: t('com.muralis.qcx.inicio'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.cadastros'),
      },
    },
    {
      link: {
        to: t('com.muralis.qcx.url.moduloCadastrosGerais'),
        name: t('com.muralis.qcx.gerais'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.servico.labelPlural'),
      },
    },
    {
      link: {
        to: t('com.muralis.qcx.url.moduloGeraisServicosModelosFaturamento'),
        name: t('com.muralis.qcx.modelosFaturamento'),
      },
    },
    {
      text: {
        name: actionName,
      },
    },
  ];

  const pageTitle = useMemo(() => (
    isNone || isCreate || isBackgroundCreate
      ? t('com.muralis.qcx.faturamento.novoModeloFaturamento')
      : t('com.muralis.qcx.faturamento.modeloFaturamentoExistente', { ID: modeloFaturamento?.description || modeloFaturamento?.id || '-' })
  ), [isUpdate, isConsult, isBackgroundCreate, modeloFaturamento]);

  const breadcrumbsProps = {
    maxItems: 6,
  };

  return (
    <QCXRegistrationFormPageTemplate
      pageTitle={pageTitle}
      breadcrumbs={breadcrumbs}
      breadcrumbsProps={breadcrumbsProps}
      isCreate={isCreate}
      isConsult={isConsult}
      isUpdate={isUpdate}
      isBackgroundCreate={isBackgroundCreate}
      isPreparingAction={isPreparingAction}
      handleAlternativeSave={handleAlternativeSave}
      handleChangeToPreparingAction={handleChangeToPreparingAction}
      handleChangeToCreate={handleChangeToCreate}
      handleChangeToBackgroundCreate={handleChangeToBackgroundCreate}
      handleChangeToConsult={handleChangeToConsult}
      handleChangeToUpdate={handleChangeToUpdate}
      handleCancelUpdate={handleCancelUpdate}
      handleResetBackgroundMode={handleResetBackgroundMode}
      showSubtitle={false}
      authInfo={authInfo}
    >
      {(formProps) => (
        <QCXModeloFaturamentoWizardFinalForm
          model={model}
          handleChangeModel={handleDispatchSetModel}
          handleSubmit={handleSubmit}
          authInfo={authInfo}
          requiredRoles={['modelo-faturamento']}
          {...formProps}
        />
      )}
    </QCXRegistrationFormPageTemplate>
  );
}
