import React from 'react';
import { Link } from 'react-router-dom';
import PrincipalLogoIcon from '../../icons/logo/colored-logo';

export default function QCXNegativeLogo({ enableLink = false, to, ...restProps }) {
  return enableLink ? (
    <Link to={to}>
      <PrincipalLogoIcon {...restProps} />
    </Link>
  ) : (
    <PrincipalLogoIcon {...restProps} />
  );
}
